import { grey, red, blue } from '@material-ui/core/colors';
import { createMuiTheme, darken } from '@material-ui/core/styles';
import { ExpansionPanel } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
const breakpoints = createBreakpoints({});


//A custom theme for this app
export const DarkTheme = createMuiTheme({
    typography: {
        fontFamily: "Helvetica, Arial, Tahoma;",
        h1: {
            [breakpoints.down('sm')]: {
                fontSize: "3em",
            },
            [breakpoints.up('md')]: {
                fontSize: "4rem",
            },
            fontFamily: "Roboto, Helvetica, Arial",
            fontWeight: 700,
            lineHeight: 1.1

        },
        h2: {

            [breakpoints.down('sm')]: {
                fontSize: "2em",
            },
            [breakpoints.up('md')]: {
                fontSize: "3.25rem",
            },
            fontFamily: "Roboto, Helvetica, Arial",
            fontWeight: 700,
            lineHeight: 1.1

        },
        h3: {

            [breakpoints.down('sm')]: {
                fontSize: "1rem",
            },
            [breakpoints.up('md')]: {
                fontSize: "3rem",
            },
            fontFamily: "Roboto, Helvetica, Arial",
            fontWeight: 700,
            lineHeight: 1.1

        },
        h4: {

            [breakpoints.down('sm')]:{
                fontSize: "0.9rem",
                fontWeight: 700,
            },
            [breakpoints.up('md')]: {
                fontSize: "2.8rem",
                fontWeight: 600,
            },
            fontFamily: "Roboto, Helvetica, Arial",

            lineHeight: 1.1

        },
        h5: {
            [breakpoints.down('xs')]:{//<600px
                fontSize: "0.85rem",
            },
            [breakpoints.only('sm')]:{//600px<s<960px
                fontSize: "1.3rem",
            },
            [breakpoints.up('md')]: {//>=960px
                fontSize: "2.5rem",
            },
            fontFamily: "Roboto, Helvetica, Arial",
            fontWeight: 600,
            lineHeight: 1.2

        },
        h6: {
            [breakpoints.down('xs')]: {
                fontSize: "0.8rem",
            },
            [breakpoints.up('md')]: {
                fontSize: "1.5rem",
            },
            fontFamily: "Roboto, Helvetica, Arial",
            fontWeight: 600,
            lineHeight: 1.5

        },
        body1: {
            fontWeight: 400,
            lineHeight: 1.3,
            [breakpoints.down('xs')]:{ //<600px
                fontSize: "1rem",
                fontWeight: 500,
            },
            [breakpoints.only('sm')]:{//600px<s<960px
                fontSize: "1.1rem",
            },
            [breakpoints.up('md')]: {//>=960px
                fontSize: "1.2rem",
            },
            fontFamily: "Helvetica, Arial, Tahoma",


        },

        body2: {
            fontWeight: 400,
            lineHeight: 1.5,
            [breakpoints.down('xs')]:{ //<600px
                fontSize: "0.9rem",
                fontWeight: 500,

            },
            [breakpoints.only('sm')]:{//600px<s<960px
                fontSize: "1rem",
            },
            [breakpoints.up('md')]: {//>=960px
                fontSize: "1.1rem",
            },
            fontFamily: "Helvetica, Arial, Tahoma",


        },

        body3: {
            [breakpoints.down('sm')]:{
                fontSize: "0.65rem",
            },
            [breakpoints.up('md')]: {
                fontSize: "1rem",
            },
            fontFamily: "Helvetica, Arial, Tahoma",
            fontWeight: 400,
            lineHeight: 1.5

        },
        caption: {
            [breakpoints.down('xs')]: {
                fontSize: "0.75rem"
            },
            [breakpoints.up('md')]: {
                fontSize: "1rem",
            }
        }
    },

    palette: {
        type: 'dark',
        primary: {
            main: '#13bbff',
            light: '#4ac3ff',
            dark: '#1983ba'
        },
        // buttonPrimary:{
        //     main:  '#ff33f0' ,
        //     light: '#ff33f0' ,
        //     dark:  '#ff33f0'
        // },
        accent: {
            main: "#f44336",
            light: "#e57373",
            dark: "#d32f2f",
        },
        // buttonSecondary:{
        //     borderColor: '#33ff64' ,
        //     color:  '#33ff64' ,

        // },
        secondary: {
            main: "#f7931e",
            light: "#f5a623",
            dark: "#c07b05"
        },
        // error:{
        //     main: "#f44336",
        // },
        background: {
            default: "#424242",

            secondary: darken("rgba(66, 66, 66, 1)", 0.1)  //Note that this should be changed when background.default changes.

        },
        text: {
            primary: "#9e9e9e",
            secondary: "#d6d6d6",

            disabled: "#666666",
            highlighted: "#ffffff"//,
            //alternate: "#fff",
            // light: "#d6d6d6",
            // normal: "#ececec",
            // dark: "#9e9e9e",
        }

    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '& $notchedOutline': {
                    borderColor: 'rgb(68,179,234)',
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: '#4A90E2',
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                },
                '&$focused $notchedOutline': {
                    borderColor: '#4A90E2',
                    borderWidth: 2,
                },
            }

        },
        MuiCssBaseline: {
            '@global': {
                html: {
                    [breakpoints.only("xs")]: {
                        fontSize: "15px",
                    }
                }
            }
        }
    }
});

// export default DarkTheme;