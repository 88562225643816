import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import StepPackageDetails from './StepPackageDetails';

import SkbButton from '../../../skb_controls/components/SkbButton';
import {
    Grid,
    Typography,
    Link,
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Loading from '../../Loading';
import { useHistory } from "react-router-dom";
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import {getTaskNextActionBy} from '../../../services/TaskService';
import {  getNextRowId} from '../../../Redux/reducers/tasksV2' ;
const styles = makeStyles({
    root : {
        border : '1px solid lightgray',
        padding : '1em',
        margin : '1em auto',

    },
    row : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'space-between',
        margin : '0.2em 0',
        
    },
    leftRow : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'flex-start',
    }
    
})


const RescheduleButtons = ({props,setShouldOpenConfirmForCloseTaskInfoPopup,closeReschedule}) => {
    
   
    function handleOpenConfirmDialog(){
        setShouldOpenConfirmForCloseTaskInfoPopup(true);
    }

    function cancelReschedule(){
        closeReschedule();
    }

    const {row} = styles();
    return(
        <Grid container 
            style={{
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'flex-end',
                marginTop : '1em',
                paddingBottom:'10px'
            }}
            spacing={2}
        >
            <Grid item>
            <SkbButton primary text={<div className={row} 
            onClick={handleOpenConfirmDialog}>
            <Typography variant="body2">Request Reschedule</Typography></div>} />
            </Grid>
            <Grid item>
                <SkbButton onClick={cancelReschedule}  primary text={<div className={row}>
                <Typography variant="body2">Cancel</Typography></div>} />                
            </Grid>
        </Grid>
    )
} 

 export default function RescheduleConsignment(props) {
     console.log('RescheduleConsignment',props)

    const{ isLoading,
        openReschedule,
        setOpenReschedule,
        consignmentDetails,
        loadDropOffDetails,
        loadPickupDetails,
        loadPackageDetails ,
        updatePackage,
        addPackage,
        deletePackage,
        updatePickupDetails,
        updateConsignmentStatus,
        updateDropOffDetails
        }=props;

        const returnRoute = '/stocktasks' ;

    const [pickUpDetails,updateLocalPickupDetails]=useState(null);
    const [dropOffDetails,updateLocalDropOffDetails]=useState(null);
    

    const updateLocalDropOffWindow = (dropOffWindow)=>{
        updateLocalDropOffDetails({DropOffWindow:dropOffWindow});
    }
    useEffect(() => {
        if(consignmentDetails.DeliveryMethod ==="Skybridge courier"){
            var atp;
            var fkr;
            if(!consignmentDetails.IsATPAuthorised || consignmentDetails.IsATPAuthorised ==='false'){
                atp=false;
            }else{
                atp=true;
            }
            if(!consignmentDetails.IsForkliftRequired || consignmentDetails.IsForkliftRequired ==='false'){
                fkr=false;
            }else{
                fkr=true;
            }
            updateLocalPickupDetails({
                IsATPAuthorised: atp,
                IsForkliftRequired:   fkr,
                PickupWindow: {
                    Date: consignmentDetails.PickupWindowDate,
                    ETA:  consignmentDetails.PickupWindowETA,
                }})
        }
        if(consignmentDetails.DeliveryMethod !=="Skybridge courier"){
            updateLocalDropOffDetails({
                DropOffWindow:{
                    Date: consignmentDetails.DropOffWindowDate, 
                    ETA: consignmentDetails.DropOffWindowETA
                } })
        }
    }, [])
/*     const [pickUpDetails,updateLocalPickupDetails]=useState({
         IsATPAuthorised:consignmentDetails.DeliveryMethod ==="Skybridge courier"? consignmentDetails.IsATPAuthorised:false,
         IsForkliftRequired:  consignmentDetails.DeliveryMethod ==="Skybridge courier"? consignmentDetails.IsForkliftRequired:false,
         PickupWindow: {
             Date: consignmentDetails.DeliveryMethod ==="Skybridge courier"? consignmentDetails.PickupWindowDate:null,
             ETA: consignmentDetails.DeliveryMethod ==="Skybridge courier"? consignmentDetails.PickupWindowETA:null,
         }});
    
    const [dropOffDetails,updateDropOffDetails]=useState({
                    Date:consignmentDetails.DeliveryMethod ==="Skybridge courier"?null: consignmentDetails.DropOffWindowDate, 
                    ETA: consignmentDetails.DeliveryMethod ==="Skybridge courier"?null:consignmentDetails.DropOffWindowETA}) */

    const defaultPickupDate=null;
    const defaultDeliveryDate=null;
    const deleteLocalPackage=(row)=>{
        
        var updatedPackage=[...packageDetails];
        console.log('row to delete',row,packageDetails);
         var idx =  updatedPackage.findIndex(i=>i.RowId === row.RowId);
        if(idx>=0) updatedPackage.splice(idx,1); 
        //updatedPackage.splice(row.RowId,1);
        updatePackages([...updatedPackage])
    }
    //const updatePackage =()=>{};
    const [packageDetails,updatePackages]=useState(consignmentDetails.PackageDetails||[]);

var deliveryDestination=  {DeliveryDestination:consignmentDetails.DeliveryDestination,
                        DeliveryAddressLine1: consignmentDetails.DeliveryAddressLine1,
                        DeliveryAddressLine2:consignmentDetails.DeliveryAddressLine2,
                        DeliveryAddressSuburb: consignmentDetails.DeliveryAddressSuburb,
                        DeliveryAddressState:consignmentDetails.DeliveryAddressState,
                        DeliveryAddressPostcode:consignmentDetails.DeliveryAddressPostcode}

    const addLocalPackage=(newPackage)=>{
        var addedPakcge=[...packageDetails];
        //existingPacks.push({...pack, RowId: getNextRowId(stockConsignment.PackageDetails)})
        var newIndex=addedPakcge.length;
        addedPakcge.push({
            RowId: getNextRowId(packageDetails),
            Type: newPackage.Type,
            Qty: newPackage.Qty,
            Length: newPackage.Length,
            Width: newPackage.Width,
            Height: newPackage.Height,
            Weight: newPackage.Weight,
        });
        console.log('addLocalPackage',addedPakcge);
        updatePackages([...addedPakcge])
    }

    const updateLocalPackage=(pack)=>{
        var updatedPackage=[...packageDetails];
        console.log('pack to update',pack,packageDetails);
         var idx =  updatedPackage.findIndex(i=>i.RowId === pack.RowId);
        if(idx>=0) updatedPackage[idx]= pack;
        updatePackages([...updatedPackage]);
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-')+'T00:00:00Z';
    }


  /*   {
        Type: "Pallet", // Pallet or Carton
        Qty: 2,
        Length: 100,
        Width: 100,
        Height: 100,
        Weight: 30,
    } */
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const updateConsignment=(callback)=>{
        
        addPackage(packageDetails);

        if(consignmentDetails.DeliveryMethod ==="Skybridge courier"){      
            if(pickUpDetails.IsATPAuthorised !== consignmentDetails.IsATPAuthorised || 
                pickUpDetails.IsForkliftRequired !== consignmentDetails.IsForkliftRequired ||
                pickUpDetails.PickupWindow.Date !== consignmentDetails.PickupWindowDate ||
                pickUpDetails.PickupWindow.ETA  !== consignmentDetails.PickupWindowETA){
                    console.log('get into submit reschedule to pickup',pickUpDetails)
                    updatePickupDetails({
                        IsATPAuthorised:pickUpDetails.IsATPAuthorised,
                        IsForkliftRequired:pickUpDetails.IsForkliftRequired,
                        PickupWindow:{
                            Date:formatDate(pickUpDetails.PickupWindow.Date),
                            ETA:pickUpDetails.PickupWindow.ETA}
                    });
                }
            
        }

        if(consignmentDetails.DeliveryMethod ==="Contractor drop-off"){
            console.log('dropOffDetailsdropOffDetails',dropOffDetails)
            if(dropOffDetails.DropOffWindow && (dropOffDetails.DropOffWindow.Date !== consignmentDetails.DropOffWindowDate || dropOffDetails.DropOffWindow.ETA !== consignmentDetails.DropOffWindowETA)){
                updateDropOffDetails(
                    {
                        Date:formatDate(dropOffDetails.DropOffWindow.Date),
                        ETA:dropOffDetails.DropOffWindow.ETA
                    }
                );
            }
        }
        callback();
    }

    const handleUpdateStatus=()=>{
        updateConsignmentStatus(
            {newStatus:"Awaiting schedule",
            nextActionBy:getTaskNextActionBy("Awaiting schedule")});
      
    }

  

    

    const submitReschedule=()=>{
        console.log('submitReschedule',consignmentDetails)
        updateConsignment(handleUpdateStatus);
        /* setTimeout(()=>{
            handleUpdateStatus();
        },3000); */
        //updateConsignment(handleUpdateStatus);
 
        enqueueSnackbar(`You have successfully submitted your request for reschedule of return stock task ${consignmentDetails.SubTaskId}.`, { variant: "success" });
         setTimeout(() => {
            setOpenReschedule(false);
            history.push(returnRoute);
        }, 3000); 
        

    }

    //   data from packageDetails to table format
 /*    const tableData = [];
    packageDetails.forEach(function(value,i){
        tableData.push({
            RowId: i,
            Type: value.Type,
            Qty: value.Qty,
            Length: value.Length,
            Width: value.Width,
            Height: value.Height,
            Weight: value.Weight,
        })
    })
 */

    const [shouldOpenConfirmForCloseTaskInfoPopup, setShouldOpenConfirmForCloseTaskInfoPopup] = useState(false);
    const handleOkConfirmClose=()=>{
        submitReschedule();
    }

    const handleCloseDialog=()=>{
        loadPickupDetails();
        loadDropOffDetails();
        setOpenReschedule(false);
    }
    console.log('load pickup details in reschedule',pickUpDetails)
    return (
             
        <Dialog  id="rescheduleConsignment"  aria-labelledby="Reschedule-dialog-title" open={props.openReschedule} fullWidth={true} maxWidth={"lg"} fullScreen={true}>
        <DialogTitle id="Reschedule-dialog-title">Reschedule stock return</DialogTitle>
          <div>
              <StepPackageDetails
                    isLoading={isLoading}
                    pickupDetails={pickUpDetails}
                    loadPickupDetails={loadPickupDetails}
                    packageDetails={packageDetails}
                    loadPackageDetails={loadPackageDetails}
                    addPackage={addLocalPackage}
                    updatePackage={updateLocalPackage}
                    deletePackage={deleteLocalPackage}
                    updatePickupDetails={updateLocalPickupDetails}
                    consignmentMethod={consignmentDetails.DeliveryMethod}
                    dropOffDetails = {dropOffDetails}
                    loadDropOffDetails = {loadDropOffDetails}
                    updateDropOffDetails = {updateLocalDropOffWindow}
                    deliveryDestination = {deliveryDestination}
                    defaultPickupDate = {defaultPickupDate}
                    defaultDeliveryDate = {defaultDeliveryDate}                
                />
                </div>
                <div>
                   
            <RescheduleButtons 
            packageDetails={packageDetails}
            dropOffDetails = {dropOffDetails}
            pickupDetails={pickUpDetails}
            setShouldOpenConfirmForCloseTaskInfoPopup={setShouldOpenConfirmForCloseTaskInfoPopup}
            closeReschedule={handleCloseDialog}/>

            <SkbConfirmDialog
            id="confirm-rechedule-popup-close"
            title={"Request reschedule?"}
            message={"Are you sure you want to reschedule the task?"}
            okButtonIsFirst={false}
            okLabel="Yes"
            cancelLabel="No"
            open={shouldOpenConfirmForCloseTaskInfoPopup}
            openConfirm={setShouldOpenConfirmForCloseTaskInfoPopup}
            okHandler={handleOkConfirmClose}
            cancelHandler={handleCloseDialog}
            
            />
       </div>  
       {isLoading && <Loading />}
    </Dialog>
    )
  
  
} 


 