import {createSelector} from 'reselect';

const selectThreePLImportation = state => state.threePLImportation;

export const selectFilename = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.fileName,
)
export const selectSerialized = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.serialized,
)

/*
CSV_json_data : [],
processingCSV : false,
csv_processing_error : [],
*/

export const selectCSVJsonData = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.CSV_json_data,
)
export const selectCSVJSONDataTable = createSelector(
    [selectThreePLImportation],
    threePLImportation => {
        const result = []
        if(threePLImportation.CSV_json_data !== null) {
            const jsonData = JSON.parse(JSON.stringify(threePLImportation.CSV_json_data));
            jsonData.forEach(item => {
                item.isSerialised = item.isSerialised === 1 ? 'Serialised Stock' : 'Non-Serialised Stock'
                item.processingType = item.processingType === 'individual'
                    ? 'Individual stock items'
                    : item.ProcessingType === 'allocate'
                        ? 'Allocate Job Stock'
                        : 'Locations related to stock items'
                result.push(item)
            })
        }
        return result
    }
)
export const selectProcessingCSV = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.processingCSV,
)
export const selectCsvProcessingError = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.csv_processing_error,
)


export const selectCSVTableData = createSelector(
    [selectThreePLImportation],
    threePLImportation => {
        const obj = {
            col1 : "",
            col2 : "01",
            col3 : threePLImportation.fileName?.name,
            col4 : "Loaded",
            col5 : "",
        }
        return obj;
    }
)


export const selectCSVJsonTableData = createSelector(
    [selectThreePLImportation],
    threePLImportation => {
        const jsonData = Object.assign({}, threePLImportation.CSV_json_data);
        const {data} = jsonData;
        const result = [];
        // switch(jsonData.serialised){
        //     case true:
        //         for(let i = 0 ; i < data.length ; i++){
        //             const row = {
        //                 col1 : data[i]["Serial Number"],
        //                 col2 : data[i]["Contractor ID"],
        //                 col3 : data[i].Condition,
        //                 col4 : data[i].Status,
        //                 col5 : data[i].Location,
        //             }
        //             result.push(row);
        //         }
        //         return result;
        //     case false:
        //         for(let i = 0 ; i < data.length ; i++){
        //             const row = {
        //                 col1 : data[i]["Stock Code"],
        //                 col2 : data[i].Qty,
        //                 col3 : data[i]["Contractor ID"],
        //                 col4 : data[i].Condition,
        //                 col5 : data[i].Status,
        //                 col6 : data[i].Location,
        //             }
        //             result.push(row);
        //         }
        //         return result;
        //     default:
        //         return "Sommething went wrong";
        // }
        for(let i = 0 ; i < data.length ; i++){
            const row = {
                col1 : data[i]["Location"],
                col2 : data[i]["Stock Code"],
                col3 : data[i]["Serial Number"],
                col4 : data[i].Qty,
            }
            result.push(row);
        }
        return result;
    }
)


export const selectCsvDataVisible = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.csv_data_visible,
)

/**
 * three_pl_importation_success : null,
    processing_importation : false,
    three_pl_importation_error : null,
 */
export const selectThreePLImportationSuccess = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.three_pl_importation_success,
)
export const selectProcessingThreePLImportation = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.processing_importation,
)
export const selectThreePLImportationsError = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.three_pl_importation_error,
)

/**
 * request_id : null,

    outcomes : null,
    laoding_outcomes : false,
    outcomes_error : null,
 */
export const selectRequestId = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.request_id,
)


export const selectOutcomes = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.outcomes,
);
export const selectLoadingOutcomes = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.loading_outcomes,
);
export const selectOutcomesError = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.outcomes_error,
);


export const selectWarehouseLocations = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation.warehouse_locations || [],
);
export const selectLoadingWarehouseLocations = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.loading_warehouse_locations,
);
export const selectWarehouseLocationsError = createSelector(
    [selectThreePLImportation],
    threePLImportation => threePLImportation?.warehouse_locations_error,
);