import React,{useState} from 'react';
import {
    Paper,
    Box,
    Grid,
    Typography,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SkbButton from '../../../skb_controls/components/SkbButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ManualAcknowledgement from './ManualAcknowledgement';

import calculateInfoGraphicDays from '../../../utils/ConsignmentTasks/calculateInfoGraphicDays';

const useStyles = makeStyles(theme=>({
    
    columnEnd : {
        display : "flex",
        flexFlow: "column",
        justifyContent:"center",
        alignItems:"flex-end",
        [theme.breakpoints.down(600)] : {
            width : "100%",
        }
    },
    clickAbleLook : {
        cursor : "pointer",
        userSelect : "none",
    },

    infographic : {
        padding : "0.5em",
        margin : "0.5em",
        height : "200px",
        width : "200px",
        border : "3px solid black",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        [theme.breakpoints.down(550)] : {
            height : "150px",
            width : "150px",
        }
    },
    acknowledged : {
        color : "#5ab22e",
        fontSize:"3em",
        fontWeight:800,
        [theme.breakpoints.down(550)] : {
            fontSize : "2em",
            fontWeight : 600,
        }
    },
    cancelled : {
        fontSize:"8em",
        fontWeight:800,
        color : "#f72c25",
        [theme.breakpoints.down(550)] : {
            fontSize : "5em",
            fontWeight : 600,
        }
    },
    awaitingAcknowledgement : {
        color : "#5ab22e",
        fontSize:"8em",
        fontWeight:800,
        [theme.breakpoints.down(550)] : {
            fontSize : "5em",
            fontWeight : 600,
        }
    },
    readyForPickup : {
        color : "#5ab22e",
        fontSize:"3em",
        fontWeight:800,
        [theme.breakpoints.down(550)] : {
            fontSize : "2em",
            fontWeight : 500,
        }
    },
    inTransit : {
        fontSize:"5em",
        [theme.breakpoints.down(550)] : {
            fontSize : "3em",
        }
    },
    statusText : {
        textAlign:"center",
        [theme.breakpoints.down(550)] : {
            fontSize : "0.8em",
        }
    },
    taskDetails : {
        padding : "1em", 
        margin : "0.5em 0",
        [theme.breakpoints.down(550)] : {
            transform : "scale(0.8)",
            padding : "0.1em",
            margin : 0,

        }
    },
    detailsAndLocation : {
        justifyContent:"space-between",
        alignItems:"flex-start",
        [theme.breakpoints.down(600)] : {
            transform : "scale(0.9)",
        }
    },
    infoGraphicAndInfo : {
        display:"flex",
        alignItems : "flex-start",
        justifyContent : "center",
        [theme.breakpoints.down(600)] : {
            
        }
    },
    taskInfoContainer : {
        justifyContent:"space-between",
        width : "100%",
        [theme.breakpoints.down(600)] : {
            justifyContent:"center",
        }
    }
}))

const InfoGraphic = ({_t}) => {
    const classes = useStyles();
    const status = _t?.CompanySubTask?.Status;
    const {
        EstimatedDeliveryDate,
        EstimatedDeliveryEta
    } = _t;



    return(
        <Box>
            <Box
                className={classes.infographic}
            >
                {
                    status === "Acknowledged"
                    &&
                    <Typography variant="h1" >
                        <SkbIcon 
                            icon={CheckIcon} 
                            className={classes.acknowledged}    
                        />
                    </Typography>
                }
                {
                    status === "Cancelled"
                    &&
                    <Typography variant="h1" className={classes.cancelled}>
                        <SkbIcon icon={CloseIcon} style={{color : "#f72c25"}} />
                    </Typography>
                }
                {
                    status === "Awaiting Acknowledgement"
                    &&
                    <Typography variant="h1"  className={classes.awaitingAcknowledgement}>
                        !
                    </Typography>
                }
                {
                    status === "Ready for Pickup"
                    &&
                    <Typography variant="h1" >
                        <SkbIcon icon={DirectionsWalkIcon} className={classes.readyForPickup} />
                    </Typography>
                }
                {
                    (status === "In Transit" || status === "Ready for Dispatch" )
                    &&
                    <Typography variant="h1" className={classes.inTransit}>
                        {calculateInfoGraphicDays(EstimatedDeliveryDate,EstimatedDeliveryEta)}
                    </Typography>
                }
                {
                    status === "Awaiting picking"
                    &&
                    <Typography variant="h1" className={classes.inTransit}>
                        {calculateInfoGraphicDays(EstimatedDeliveryDate,EstimatedDeliveryEta)}
                    </Typography>
                }
                {
                    status === "On Order"
                    &&
                    <Typography variant="h1" className={classes.inTransit}>
                        {calculateInfoGraphicDays(EstimatedDeliveryDate,EstimatedDeliveryEta)}
                    </Typography>
                }
            </Box>
            <Typography variant="body2" className={classes.statusText}>
                {
                    (status === "In Transit" || status === "Ready for Dispatch") 
                    && 
                    "Days till delivery"
                }
                {
                    ( status !== "In Transit" && status !== "Ready for Dispatch" )
                    &&
                    status
                }
            </Typography>
        </Box>
    )
}


const NextAction = ({_t})=>{

    const status = _t?.CompanySubTask?.Status;

    const classes = useStyles();

    return(
        <Box
           className={classes.columnEnd}
        >
            <Typography variant="body1">Next Action By</Typography>
            <Typography variant="body2" color="primary">
                {status === "On Order" && "Skybridge"}
                {status === "Ready for Dispatch" && "Courier"}
                {status === "In Transit" && "Courier"}
                {status === "Ready for Pickup" && "Contractor"}
                {status === "Awaiting Acknowledgement" && "Contractor"}
                {status === "Cancelled" && "None"}
                {status === "Delivered" && "None"}
                {
                    status === "Acknowledged"
                    &&
                    "Jamie D. Mitchell , 6th august 2021, 9:25 pm"
                }
            </Typography>
        </Box>
    )
    
}


const TaskDetails = ({_t}) => {
    const classes = useStyles();
    return(
        <Box className={classes.taskDetails}>
            <Typography variant="h6" style={{textAlign:"center"}}>Task Details</Typography>
            <hr style={{opacity:0.2}}/>
            <Grid container>
                <Grid item style={{width:"50%"}}>
                    <Typography variant="body1" style={{fontWeight:"bold"}}>Delivery Method</Typography>
                </Grid>
                <Grid item style={{width:"50%"}}>
                    <Typography variant="body1" style={{fontWeight:"bold"}}>Pickup Location</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{display:"flex",width:"50%"}}>
                    {
                        _t?.ConsignmentMethod === "Contractor drop-off"
                        &&<SkbIcon icon={DirectionsWalkIcon} />
                    
                    }
                    {
                        _t?.ConsignmentMethod === "Skybridge courier"
                        &&
                        <SkbIcon icon={LocalShippingIcon} />
                    }
                    <Typography variant="body2">{_t?.ConsignmentMethod}</Typography>
                </Grid>
                <Grid item style={{width:"50%"}}>
                    {
                        _t.CompanySubTask?.Status === "Cancelled"
                        ?
                        <Typography>Pickup location Info Not Available.</Typography>
                        :
                        <>
                            <Typography variant="body2">{_t?.FromLocation?.Name}</Typography>
                            <Typography variant="body2">{_t?.FromLocation?.address} {_t?.FromLocation?.Suburb}, {_t?.FromLocation?.Postcode} {_t?.FromLocation?.State}</Typography>
                        </>
                    }
                </Grid>
            </Grid>
            <br />
            <Grid container>
                <Grid item style={{width:"50%"}}>
                    <Typography variant="body1" style={{fontWeight:"bold"}}>Estimated Delivery Date</Typography>
                    {   
                        _t.CompanySubTask?.Status !== "Cancelled"
                        &&
                        <Typography variant="body2">{new Date(_t?.PlannedReturnDate).toTimeString()}</Typography>
                    }
                </Grid>
                <Grid item style={{width:"50%"}}>
                    <Typography variant="body2">Modified : {new Date(_t?.DateModified).toTimeString()}</Typography>
                    <Typography variant="body2">Created  : {new Date(_t?.DateCreated).toTimeString()}</Typography>
                    <Typography variant="body2">Modified By  : {_t?.ModifiedByUser?.Name}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container>
                <Grid item style={{width:"50%"}}>
                    <Typography variant="body1" style={{fontWeight:"bold"}}>Skybridge Notes</Typography>
                    <Typography variant="body2">Sample notes from Skybridge Team</Typography>
                </Grid>
                <Grid item style={{width:"50%"}}>
                    
                </Grid>
            </Grid>
            <hr style={{opacity:0.2}}/>
        </Box>
    )
}

const SingleConsignmentTask = ({_t}) => {
    const classes = useStyles();
    const [detailsOpen,setDetailsOpen] = useState(false);
    const [popUpOpen,setPopupOpen] = useState(false);
    return(
        <Paper elevation={1} style={{width:"100%"}}>
            <Box sx={{padding : "1em",margin:"0.5em 0"}}>
                <Grid 
                    container
                    style={{justifyContent:"space-between"}}

                >
                    <Grid item>
                        <Typography variant="h6">
                            { _t?.ConsignmentMethod === "Contractor drop-off" && <SkbIcon icon={DirectionsWalkIcon} /> }
                            {_t?.ConsignmentMethod === "Skybridge courier" && <SkbIcon icon={LocalShippingIcon} />}
                            {_t.Name}
                        </Typography>
                    </Grid>
                    <Grid item style={{textAlign:"right"}}>
                        <Grid item style={{display:"flex",justifyContent:"flex-end"}}> 
                            <Typography variant="h6">ID : {_t?.CompanySubTask.Id}</Typography>
                            <Typography 
                                variant="body2"
                                onClick={()=>setDetailsOpen(!detailsOpen)}
                                className={classes.clickAbleLook}
                            >
                                {
                                    detailsOpen
                                    ?
                                    <SkbIcon icon={KeyboardArrowUpIcon} />
                                    :
                                    <SkbIcon icon={KeyboardArrowDownIcon} />
                                    
                                }
                            </Typography>
                        </Grid>
                        <Typography variant="body2" style={{color:"purple"}}>{_t?.CompanySubTask?.Status}</Typography>
                        
                    </Grid>
                </Grid>
                <Grid container className={classes.taskInfoContainer}>
                    <Grid item className={classes.infoGraphicAndInfo}>
                        <InfoGraphic 
                            status={_t?.CompanySubTask?.Status} 
                            _t={_t}
                        />
                        <Box className={classes.detailsAndLocation}>
                            <Typography variant="h6">Business Details</Typography>
                            <Typography variant="body1">{_t?.Company?.Name}</Typography>
                            <br />
                            <Typography variant="h6">Delivery Location</Typography>
                            <Typography variant="body1">
                                {_t?.ToLocation.Address}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item className={classes.columnEnd}>
                        <NextAction _t={_t} />
                        <SkbButton 
                            primary
                            text="VIEW"
                            size="small"
                            onClick={()=>setPopupOpen(true)}

                        />
                        {
                            popUpOpen
                            &&
                            <ManualAcknowledgement openPopup={popUpOpen} setPopupOpen={setPopupOpen} _t={_t} />

                        }
                    </Grid>
                </Grid>
            </Box>
            {
                detailsOpen && <TaskDetails _t={_t}/>
            }
        </Paper>

    )
}


export default SingleConsignmentTask;


