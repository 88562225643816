import React,{useState,useCallback, useEffect} from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as moment from 'moment-timezone';

import {triggerValidateStep2} from "./TaskDetailsSchedulePopup";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,

  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import {
    Cloud,
} from '@material-ui/icons'

import {Input} from '@material-ui/core';

import {
    Typography,
    Grid,
    Link,

} from '@material-ui/core';
import {
    makeStyles,
} from '@material-ui/core/styles';

import {useSnackbar } from 'notistack';
import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';
import SkbTextField from '../../../../skb_controls/components/SkbTextField';
import SkbButton from '../../../../skb_controls/components/SkbButton';
// import Loading from '../../../Loading';


import DragAndDropFileUpload from './DragAndDropFileUpload';
import {uploadOneConsignmentLabelFileToBlob, overwriteLabelFileListForConsignment} from "../../../../services/StockService";
import {crypts} from '../../../../utils/crypts';
import { useDispatch, useSelector } from 'react-redux';
import * as  returnsDashboardTaskInfoActions from '../../../../Redux/actions/returnsdashboard';
import {getReturnDashboardUtility} from "./returnDashboardUtility"; 
import filesize from "filesize";

const styles = makeStyles({
    borderRound : {
        border : '1px solid lightgray',
        padding : '0.5em',
        borderRadius : '5px',
    },
    row : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'space-between'
    },
    rowCenter : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
    },
    marginRight : {
        marginRight : '0.5em',
    },
    labelErrorStruct : {
        marginTop : '1em',
        color: "red",
        textAlign: "center"
    },
    
    
})


const ConsignmentDetails = (props) => {
    
    
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); 
    const {consignmentLocationCode,taskId,closeTaskInfoScheduleAndTaskInfoPopup} = props;  
    const [taskInfoScheduleApiSuccessLocalFlag, setTaskInfoScheduleApiSuccessLocalFlag] = React.useState(false); 
   

    const {stepOneWareHouseLocationCode, stepOneAtp, stepOneForkliftRequired, stepTwolabelFiles,  twoStepScenarioStepTwoChangedDataSetFlag,stepTwoCarrierName, stepTwoCarrierNameUrl,stepTwoCannote , stepTwoPickupWindowTimeslot,stepTwoPickupWindowDate ,stepOneValidateFlag, stepTwoValidateFlag, stepTwoValidateFlagErrMessages } = useSelector((state) =>( {  
        //return state.returnsDashboardTaskInfoSchedulePopupChangedData;  

        "stepOneWareHouseLocationCode": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.wareHouseLocationCode!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.wareHouseLocationCode: "",

        "stepOneAtp": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.atp!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.atp: false,
    
        "stepOneForkliftRequired": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.forkLiftRequired!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.forkLiftRequired: false,
       
        "stepTwolabelFiles" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.labelFiles!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.labelFiles: [],
        
        "stepTwoCarrierName" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.carrierName!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.carrierName: "",

        "stepTwoCarrierNameUrl" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.carrierNameUrl!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.carrierNameUrl: "",

         "stepTwoCannote" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.cannote!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.cannote: "",
        
              

       "twoStepScenarioStepTwoChangedDataSetFlag": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedDataSetFlag!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedDataSetFlag:false,

       "stepTwoPickupWindowTimeslot" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowTimeslot!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowTimeslot: "",              

       "stepTwoPickupWindowDate" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowDate!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowDate: "",    
       
       "stepOneValidateFlag": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.validateFlag!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.validateFlag: false,
      
      "stepTwoValidateFlag" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData &&
      state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.validateFlag!==undefined?
      state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.validateFlag:false,
      "stepTwoValidateFlagErrMessages": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData &&
      state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.validateFlagErrMessages!==undefined?
      state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.validateFlagErrMessages:null,
    

    })
    );  

    const {  taskInfoScheduleFinishApiSuccessFlag,taskInfoScheduleFinishApiSuccessMsg , taskInfoScheduleFinishApiErrorFlag, taskInfoScheduleFinishApiErrorMessage, connoteTrackingUrl  } = useSelector((state) => ({
        //return state.returnsDashboardTaskInfo;

        "connoteTrackingUrl": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.TrackingUrl!==undefined )?state.returnsDashboardTaskInfo.taskInfo.TrackingUrl: "",
        
        "taskInfoScheduleFinishApiSuccessFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessFlag)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessFlag):false,
        "taskInfoScheduleFinishApiSuccessMsg": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessMsg)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessMsg):"",
        "taskInfoScheduleFinishApiErrorFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorFlag)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorFlag):false,
        "taskInfoScheduleFinishApiErrorMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorMessage)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorMessage):"",
        


      })
  );

    const handleChangePickupWindTimeSlot = (pkupWinddTimeSlt) => {
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoPickupwindowTimeslot(pkupWinddTimeSlt));
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));

         // for specific error message update
         let paramToValidate = {             
            "stepTwoPickupWindowTimeslot":pkupWinddTimeSlt,
            };
        let chkv2 = triggerValidateStep2(paramToValidate,{"specificFor":"stepTwoPickupWindowTimeslot","getValidateFlag":false});        
        let {validateFlagErrMessagesProvided} = {...chkv2};
        
        let {pickupWindowTimeSlotErrMsg} = {...validateFlagErrMessagesProvided};

         let errStateObj = {   
            "specificForKey":"pickupWindowTimeSlotErrMsg" ,        
            "specificForKeyValue":pickupWindowTimeSlotErrMsg
            } ; 

        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoToUpdateSpecificFeErMsg(errStateObj));

    };

    const updateCarrierUrlWithConoteDataAndDispatch = (stepTwoCannote,carrierNameUrlData) => {

        const updtdCarrConnotUrl = replaceConnoteNumberIfPresent(carrierNameUrlData,"{CONNOTE}",stepTwoCannote);
        if(stepTwoCannote && updtdCarrConnotUrl){
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(updtdCarrConnotUrl));

        }else{
            if(stepTwoCannote===""){
                dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(carrierNameUrlData));
            }
            
        }
    }
   
    const handleChangeCarrierCheck = (carrNmData) => {

        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));
        const rowUrlFrmCarrNm =  getReturnDashboardUtility.findFirstMatchedKeyValueRowFromArrObj(stepTwoLogisticCarrierListRaw,"variableName",carrNmData);       
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierName(carrNmData));
        const carrierNameUrlData =  rowUrlFrmCarrNm && rowUrlFrmCarrNm.variableValue!==undefined?rowUrlFrmCarrNm.variableValue:"";
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(carrierNameUrlData));

        // replace with connote data to carrier name url
        updateCarrierUrlWithConoteDataAndDispatch(stepTwoCannote,carrierNameUrlData);

        // for specific error message update 
        let paramToValidate = {             
            "stepTwoCarrierName":carrNmData,
            };
        let chkv2 = triggerValidateStep2(paramToValidate,{"specificFor":"stepTwoCarrierName","getValidateFlag":false});        
        let {validateFlagErrMessagesProvided} = {...chkv2};
        let {carrierNameErrMsg} = {...validateFlagErrMessagesProvided};
        let errStateObj = {   
            "specificForKey":"carrierNameErrMsg" ,        
            "specificForKeyValue":carrierNameErrMsg
        }  
              
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoToUpdateSpecificFeErMsg(errStateObj));

    };
    
    const getCarrierNameDisplayData = (resp) => {

        if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
          let carrierNameDrpDwnArr = [{"value":"NOTSELECTED","text":"Select a carrier"}];
          resp.map((e,i)=>{
            carrierNameDrpDwnArr.push({"value":e.variableName,"text":e.variableName});
          });
    
          return carrierNameDrpDwnArr;
    
    
        }
    
        return [];
    
      }

    const {stepTwoLogisticCarrierList,stepTwoLogisticCarrierListRaw, stepTwoLogisticCarrierListLoadStatus} = useSelector((state) =>( {  
        //return state.returnsDashboardTaskInfoSchedulePopupChangedData;  
       
        "stepTwoLogisticCarrierList" : state.returnLogisticCarriers && state.returnLogisticCarriers.logisticCarrierList !==undefined ?getCarrierNameDisplayData(state.returnLogisticCarriers.logisticCarrierList): [],
        "stepTwoLogisticCarrierListRaw" : state.returnLogisticCarriers && state.returnLogisticCarriers.logisticCarrierList !==undefined ?state.returnLogisticCarriers.logisticCarrierList: [],
        
       
        
        "stepTwoLogisticCarrierListLoadStatus": state.returnLogisticCarriers && state.returnLogisticCarriers.logisticCarrierListLoadStatus !==undefined?state.returnLogisticCarriers.logisticCarrierListLoadStatus:false,        

      

    })
    );  


    const getPickupWindowtimeSlotDisplayData = (resp) => {

        if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
          let drpDwnWindTimSltArr = [];
          resp.map((e,i)=>{
            drpDwnWindTimSltArr.push({"value":e.variableName,"text":e.variableName});
          });
    
          return drpDwnWindTimSltArr;
    
    
        }
    
        return [];
    
      }
     const {stepTwoTimeslotOptionList, stepTwoTimeslotOptionListLoadStatus} = useSelector((state) =>( {  
        //return state.returnPickupWindowTimeSlotOptions;  
       
        "stepTwoTimeslotOptionList" : state.returnPickupWindowTimeSlotOptions && state.returnPickupWindowTimeSlotOptions.timeslotOptionList !==undefined ?getPickupWindowtimeSlotDisplayData(state.returnPickupWindowTimeSlotOptions.timeslotOptionList): [],
      
        
        "stepTwoTimeslotOptionListLoadStatus": state.returnPickupWindowTimeSlotOptions && state.returnPickupWindowTimeSlotOptions.timeslotOptionListLoadStatus !==undefined?state.returnPickupWindowTimeSlotOptions.timeslotOptionListLoadStatus:false,        

      

    })
    );  

   const makeItReadyForUpload = async (fileObj,consignmentLocationCode,calledFrom) => {

    try{
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoScheduleFlowLoaderFlag(true));

            const idToken = crypts.decrypt(localStorage.getItem('id_token'));
            const filetypeGrabbed = fileObj.type;
            const rawBaseSixtyFourCont =  await getReturnDashboardUtility.toBase64(fileObj);
            const searchPart = `data:${filetypeGrabbed};base64,`;
            const okBaseSixtyFourCont = rawBaseSixtyFourCont.replace(searchPart,"");  
            

            const nameData = fileObj.name;    
            const nameSplit = nameData.split(".");
            const fileExt  = nameSplit[nameSplit.length-1];


            const chkResp = await uploadOneConsignmentLabelFileToBlob(consignmentLocationCode,okBaseSixtyFourCont,fileExt);
            //console.log(`---- ${calledFrom} chkResp of file upload=>>>`,chkResp);  
            return chkResp;           
           

    }
    catch(e){
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoScheduleFlowLoaderFlag(false));

        enqueueSnackbar(`Something went wrong please try again later.`, { variant: 'error',onClose:()=>{
      
        } });  
        

    }
   
   }

   const processFileUpload = async(files, calledFrom) =>{

    for(let i=0; i<files.length; i++){
        let fileObj = files[i]; 

        // same for me
        let chkValid   = await getReturnDashboardUtility.checkfileTypeIsAllowed(fileObj);
       
        if(!(chkValid.fileTypeOkStatus)||!(chkValid.fileSizeOkStatus)){
                // toast error message
                if(!(chkValid.fileTypeOkStatus)){
                    enqueueSnackbar(`Invalid file type upload attempted.`, { variant: 'error',onClose:()=>{
  
                    } }); 
                    
                }
                else if(!(chkValid.fileSizeOkStatus)){
                    enqueueSnackbar(`Maximum 10 MB size of file can be uploaded. `, { variant: 'error',onClose:()=>{
  
                    } });  
                }
            

        }else{
                // upload  file               
                const locFileNameData = fileObj.name;
                const locFileSizeData = fileObj.size;                 
               

                let fileUploadedPathInServer = await makeItReadyForUpload(fileObj, consignmentLocationCode, calledFrom);

                if(fileUploadedPathInServer){
                    dispatch(returnsDashboardTaskInfoActions.setTaskInfoScheduleFlowLoaderFlag(false));                    


                    let labelDataObj = {"localFileName":locFileNameData,"localFileSize":locFileSizeData,"fileUploadedPathInServer":fileUploadedPathInServer};
                    
                    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoLabel(labelDataObj));
                    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));

                    enqueueSnackbar(`Successfully uploaded ${locFileNameData} `, { variant: 'success', autoHideDuration: 1500, onClose:()=>{

                        setTimeout(()=>{
                            timeToRemoveGenericErrMsgForLabel();
                        },1000);
                        
                    } });  
           
                    
                    

                }

        }
    }
    

   }

   const timeToRemoveGenericErrMsgForLabel = function(){
       
            // for specific error message update        
            let errStateObj = {   
            "specificForKey":"labelsErrMsg" ,        
            "specificForKeyValue":undefined
            } ; 

            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoToUpdateSpecificFeErMsg(errStateObj));
   }

    const onDrop = useCallback(files => {        
        
        let calledFrom = "onDrop";
        //console.log("----- calledFrom=>>>>>>>",calledFrom);
        processFileUpload(files,calledFrom);


    }, []);
    const fileUploadBtnOnChange = async ({target:{files}}) => {  
        let calledFrom = "fileUploadBtnOnChange";
         //console.log("----- calledFrom=>>>>>>>",calledFrom);
        processFileUpload(files,calledFrom);        
       
    }

    const callTaskInfoSchedulePopupLabelUplDataSave = async (otherOption) => {

        const idToken = otherOption.idToken?otherOption.idToken:"";
          const consignmentLocationCode = otherOption.idToken?otherOption.consignmentLocationCode:"";
          const labelFileList = otherOption.LabelFileList?otherOption.LabelFileList:[];
          const respChk = await overwriteLabelFileListForConsignment(idToken, consignmentLocationCode, labelFileList).then((datResp)=>{
            
                    // call action to update schedule ddata
                    taskInfoScheduleUpdateApiCallAction();
      
          }).catch((er)=>{
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoScheduleFlowLoaderFlag(false));
            enqueueSnackbar(`Something went wrong please try again!!`, { variant: 'error',onClose:()=>{
      
            } });  
          }).finally(()=>{
            
            
      
          });
      
    }

    const updateDataOnUploadedLabels = function(labfileArList){

        //console.log(" --- Upfdate thhem  =>>>",labfileArList);
        const  idToken = crypts.decrypt(localStorage.getItem('id_token')); 

        if(Array.isArray(labfileArList) && labfileArList.length>0){
            let paramObj = {"idToken":"","consignmentLocationCode":"","labelFileList":[]};

            let labelsAr = [];
            labfileArList.map((e,i)=>{
                labelsAr.push({"LabelName":e.localFileName,"LabelFilePath":e.fileUploadedPathInServer});


            });

            paramObj["labelFileList"] =  labelsAr;
            paramObj["consignmentLocationCode"] =  consignmentLocationCode;
            paramObj["idToken"] =  idToken;

            //console.log(" =========DEAR=>>>>>>",paramObj);
            callTaskInfoSchedulePopupLabelUplDataSave(paramObj);            
            //dispatch(returnsDashboardTaskInfoActions.callTaskInfoSchedulePopupLabelUplDataSave(paramObj));
            
        }

       
    }
    
      

   
    const [cannote,setCannote] = useState("");

   const setCannoteData = (e)=>{
        e.preventDefault();
        const cannoteData  = e.target.value;
        //console.log(" ==== * CALLED setCannoteData=>>",cannoteData);
        
        setCannote(cannoteData);        

    }

    const setCannoteDataOnBlur = (e)=>{
        e.preventDefault();
        const cannoteData  = e.target.value;          
       
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwocannote(cannoteData));
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));          

        // replace with connote data to carrier name url
        const rowUrlFrmCarrNm =  getReturnDashboardUtility.findFirstMatchedKeyValueRowFromArrObj(stepTwoLogisticCarrierListRaw,"variableName",stepTwoCarrierName); 
        const carrierNameUrlData =  rowUrlFrmCarrNm && rowUrlFrmCarrNm.variableValue!==undefined?rowUrlFrmCarrNm.variableValue:"";        
        const updtdCarrConnotUrl = replaceConnoteNumberIfPresent(carrierNameUrlData,"{CONNOTE}",cannoteData);
        if(cannoteData && updtdCarrConnotUrl){
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(updtdCarrConnotUrl));

        }else{
            if(cannoteData===""){
                dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(carrierNameUrlData));
            }
            
        }

         // for specific error message update 
         let paramToValidate = {             
            "stepTwoCannote":cannoteData,
            };
        let chkv2 = triggerValidateStep2(paramToValidate,{"specificFor":"stepTwoCannote","getValidateFlag":false});
        
        let {validateFlagErrMessagesProvided} = {...chkv2};
        let {connoteErrMsg} = {...validateFlagErrMessagesProvided};
        let errStateObj = {   
            "specificForKey":"connoteErrMsg" ,        
            "specificForKeyValue":connoteErrMsg
        }    
              
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoToUpdateSpecificFeErMsg(errStateObj));
        

    }

    const replaceConnoteNumberIfPresent = (urlStr, searchStr, replcWithStr) => {

        let urlWithConnoteData = "";
        if(urlStr && searchStr && replcWithStr){
            urlWithConnoteData =  urlStr.replace(searchStr, replcWithStr);
           
        } 

        return urlWithConnoteData;

    }


    const {
        borderRound,
        row,
        rowCenter,
        marginRight,
        labelErrorStruct

    } = styles();


    const getPickupWindowDate = (dateObj)=>{      
        
        const pickupWindDateSel = getReturnDashboardUtility.grabDateStringInFormat(dateObj,"YYYY-MM-DD");
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoPickupwindowDate(pickupWindDateSel));
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));

         // for specific error message update 
        let paramToValidate = {             
        "stepTwoPickupWindowDate":dateObj,
        };
        let chkv2 = triggerValidateStep2(paramToValidate,{"specificFor":"stepTwoPickupWindowDate","getValidateFlag":false});

        let {validateFlagErrMessagesProvided} = {...chkv2};
        let {pickupWindowDateErrMsg} = {...validateFlagErrMessagesProvided};
        let errStateObj = {   
        "specificForKey":"pickupWindowDateErrMsg" ,        
        "specificForKeyValue":pickupWindowDateErrMsg
        }  

        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoToUpdateSpecificFeErMsg(errStateObj));

    }

    const setInitialDataOfChangebaleControlsToReduxState = () =>{
        //console.log("--------called stepTwolabelFiles-------");
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeableDataSetFlag(true));
        dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchLogisticCarrierList());
        dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchPickupWindowTimeList());
        if(stepTwoCarrierName===""||stepTwoCarrierName==="NOTSELECTED"){
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierName("NOTSELECTED"));
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(""));
        }else{
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierName(stepTwoCarrierName));
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl(connoteTrackingUrl));
        }
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwocannote(stepTwoCannote));

        
       

        
    }

    const taskInfoScheduleLabelSaveRelatedObjConstruct = () => {       
         // change it to false do that  in use effect it doesn't gets called as infinite
         dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoValidateFlag(false));        
         dispatch(returnsDashboardTaskInfoActions.setTaskInfoScheduleFlowLoaderFlag(true));   

        // script to save uploaded labels
        let passPrm  = [];
        if(stepTwolabelFiles && stepTwolabelFiles.length>0){
                stepTwolabelFiles.map((e)=>{
                    let labelDataObj = {"localFileName":e.localFileName,"localFileSize":e.localFileSize,"fileUploadedPathInServer":e.fileUploadedPathInServer};
                    passPrm.push(labelDataObj);
                });

                if(passPrm.length>0){
                    //console.log("---label save related passPrm=>>",passPrm);
                    updateDataOnUploadedLabels(passPrm);
                } 
            }

    }

    const taskInfoScheduleUpdateApiCallAction = () =>{
    
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoScheduleFlowLoaderFlag(true));  
        const paramObjApi1 = {
                "Consignments":[
                    {
                            "LocationCode":consignmentLocationCode, // consignmentLocationCode
                            "ConsignmentStatus":"Awaiting labels", // static and fixed                       
                            "ToLocationCode":stepOneWareHouseLocationCode, // warehouse loc code
                            "ATP":stepOneAtp,
                            "ForkliftRequired":stepOneForkliftRequired,                        
                            "CarrierName":stepTwoCarrierName,
                            "ConnoteNumber":stepTwoCannote,
                            "PickupWindow":{
                                "Date":stepTwoPickupWindowDate,
                                "Timeslot":stepTwoPickupWindowTimeslot
                                },
                                // "DropOffWindow":{
                                // "Date":"2021-10-08",
                                // "Timeslot":"7 am - 1 pm"
                                // },                        
                        
                            "ActivityType":"Schedule" // static and fixed
                            }
    
                  ]
                };

        const successMsgParam = {"message":`Successfully scheduled the return stock task ${taskId}.`};

        // api to update screen 1 and screen 2 related data excluding label for non contractor drop off scenario
        dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToUpdateForScheduleFinish({"bodyParam":paramObjApi1,"successMsgParam":successMsgParam}));



    }

    useEffect(()=>{

                return (()=>{
                    setTaskInfoScheduleApiSuccessLocalFlag(false);
                });

    },[]);

   

    useEffect(()=>{
       //carrier list
       if(twoStepScenarioStepTwoChangedDataSetFlag===false){
        setInitialDataOfChangebaleControlsToReduxState();
       } 

       if(stepTwoCannote){
         setCannote(stepTwoCannote);
       }

       if(stepOneValidateFlag && stepTwoValidateFlag){

                 taskInfoScheduleLabelSaveRelatedObjConstruct();                   
       }

       
       if(taskInfoScheduleFinishApiSuccessFlag===true && !(taskInfoScheduleApiSuccessLocalFlag)){
        setTaskInfoScheduleApiSuccessLocalFlag(true);
        enqueueSnackbar(`${taskInfoScheduleFinishApiSuccessMsg}`, { variant: 'success',onClose:()=>{        
            // trigger task schedule popup close 
            // from  here
            closeTaskInfoScheduleAndTaskInfoPopup();
        }, autoHideDuration: 4000 });
        
      }   
  
       
      if( taskInfoScheduleFinishApiErrorFlag===true ){
        enqueueSnackbar(`${taskInfoScheduleFinishApiErrorMessage}`,  { variant: 'error',autoHideDuration: 2500 });
      }


      
       
    },[twoStepScenarioStepTwoChangedDataSetFlag,stepTwoCannote,stepOneValidateFlag, stepTwoValidateFlag, taskInfoScheduleFinishApiErrorFlag, taskInfoScheduleFinishApiSuccessFlag]);


    return(
        <Grid 
            container 
            direction="column"
            style={{
                width : '100%'
            }}    
        >
            <Grid item container>
                <Typography variant="h6">Carrier Details  </Typography>
            </Grid>
            <Grid item container className={[borderRound,row]} direction="row">
                <Grid item>                   

                    {stepTwoCarrierName && (
                             <SkbSelect Title="" id="stepTwoCarriernamelist" value={stepTwoCarrierName} items={stepTwoLogisticCarrierList} valueChanged={handleChangeCarrierCheck} error={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.carrierNameErrMsg!==undefined?true:false}
                             helperText={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.carrierNameErrMsg!==undefined?stepTwoValidateFlagErrMessages.carrierNameErrMsg:""} />
                    )}

                    {!(stepTwoCarrierName) && (
                             <SkbSelect Title="" id="stepTwoCarriernamelist" value={""} items={stepTwoLogisticCarrierList} valueChanged={handleChangeCarrierCheck}   error={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.carrierNameErrMsg!==undefined?true:false}
                             helperText={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.carrierNameErrMsg!==undefined?stepTwoValidateFlagErrMessages.carrierNameErrMsg:""} />
                    )}
                   


                </Grid>
                <Grid item>
                    <Typography variant="body1">Tracking Website:</Typography>   
                    {(stepTwoCarrierNameUrl && stepTwoCannote) && (
                    <Typography >
                    <Link  style={{"cursor": "pointer"}} rel="noopener noreferrer"  href={stepTwoCarrierNameUrl} target="__blank">  {stepTwoCarrierNameUrl} </Link>
                    </Typography>
                    )}
                     {(stepTwoCarrierNameUrl && !(stepTwoCannote)) && (
                    <Typography >
                        {stepTwoCarrierNameUrl}
                    </Typography>
                    )}                    
                    {!(stepTwoCarrierNameUrl) && (
                    <Typography >{"N/A"}</Typography>
                    )}
                    
                    
                </Grid>
            </Grid>
            <br />
            <Grid item container>
                <Typography variant="h6">Consignment Details</Typography>
            </Grid>
            <Grid item container className={[borderRound,]} direction="column">
                <Grid item direction="row" className={row}>
                    <Grid item container>
                        <Typography variant="body2">
                            Connote #
                        </Typography>
                    </Grid>
                    <Grid item container>

                    <SkbTextField
                                 label=""
                                 type="text"
                                 value={cannote}
                                 onChange={setCannoteData}
                                 onBlur={setCannoteDataOnBlur}
                                 style={{
                                     minWidth : '100%'
                                 }}

                                 error={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.connoteErrMsg!==undefined?true:false}
                                 helperText={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.connoteErrMsg!==undefined?stepTwoValidateFlagErrMessages.connoteErrMsg:""}
                             />
                 
                       
                    </Grid>
                </Grid>
                <Grid item direction="row" className={row}>
                    <Grid item>
                        <Typography variant="body2">
                            Pickup Date
                        </Typography>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                onChange={getPickupWindowDate}
                                // placeholder="22/10/2020"
                                placeholder=""

                                value={stepTwoPickupWindowDate}
                                leftArrowIcon={<ArrowBackIosIcon />}

                                rightArrowIcon={<ArrowForwardIosIcon />}
                                keyboardIcon={<EventIcon />}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                'id': 'date-picker-inline-changedatebtn'
                                }}
                                leftArrowButtonProps = {
                                {
                                    'id': 'date-picker-inline-leftbtn'
                                }
                                }
                                rightArrowButtonProps = {
                                {
                                    'id': 'date-picker-inline-righbtn'
                                }
                                }

                                error={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.pickupWindowDateErrMsg!==undefined?true:false}
                                helperText={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.pickupWindowDateErrMsg!==undefined?stepTwoValidateFlagErrMessages.pickupWindowDateErrMsg:""}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                    {/* {console.log("STEP TWO PICKUP WINDOW TIMESLOT ===> ",stepTwoPickupWindowTimeslot)} */}
                    {/* {console.log("STEP TWO PICKUP WINDOW OPTIONS LIST ===> ",stepTwoTimeslotOptionList)} */}
                    
                    
                    {stepTwoPickupWindowTimeslot && (
                     
                             <SkbSelect 
                                Title="" 
                                id="stepTwoPickupWindtimeSlotlist" 
                                value={stepTwoPickupWindowTimeslot} 
                                items={stepTwoTimeslotOptionList} 
                                valueChanged={handleChangePickupWindTimeSlot} 
                                error={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.pickupWindowTimeSlotErrMsg!==undefined?true:false}
                                helperText={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.pickupWindowTimeSlotErrMsg!==undefined?stepTwoValidateFlagErrMessages.pickupWindowTimeSlotErrMsg:""}
                            />
                       
                    )}

                    {!(stepTwoPickupWindowTimeslot) && (
                                                 
                            
                             <SkbSelect 
                                Title="" 
                                id="stepTwoPickupWindtimeSlotlist" 
                                value={stepTwoPickupWindowTimeslot} 
                                items={stepTwoTimeslotOptionList} 
                                valueChanged={handleChangePickupWindTimeSlot} 
                                error={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.pickupWindowTimeSlotErrMsg!==undefined?true:false}
                                helperText={stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.pickupWindowTimeSlotErrMsg!==undefined?stepTwoValidateFlagErrMessages.pickupWindowTimeSlotErrMsg:""}
                             />
                            
                    )}

                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid item container>
                <Typography variant="h6">
                    Consignment Labels
                </Typography>               
               
            </Grid>
            <Grid item container direction="row" className={rowCenter} >
                <Grid item className={marginRight}>
                    <DragAndDropFileUpload 
                        onDrop={onDrop}
                    />
                   
                    
                </Grid>
                <Grid item className={marginRight}>
                    <Typography>Or</Typography>
                </Grid>
                <Grid item>
                    <Input 
                        type="file"
                        placeholder="Upload Labels"
                        style={{
                            display : 'none'
                        }}
                        onChange={fileUploadBtnOnChange}
                        id="fileuploadbtnnoncondropoffstep2lbl"
                        inputProps={{ multiple: true }} 
                        
                    />
                    <SkbButton 
                        primary 
                        size="medium" 
                        text={
                            "Upload Labels"
                        } 
                        onClick={e=>{
                            document.querySelector("#fileuploadbtnnoncondropoffstep2lbl").click();
                        }}
                    />
                </Grid>
            </Grid>

            <Grid item container className={labelErrorStruct} >
                <Typography variant="h10">
                {stepTwoValidateFlagErrMessages && stepTwoValidateFlagErrMessages.labelsErrMsg!==undefined?stepTwoValidateFlagErrMessages.labelsErrMsg:""}
                </Typography>               
               
            </Grid>
          

            <Grid 
                item 
                direction="column"
                style = {{
                    border :  stepTwolabelFiles && stepTwolabelFiles.length===0?'none':'1px dotted grey',
                    padding : '0.5em',
                    marginTop : '1.5em',
                    fontSize : '0.8em',
                }}
            >

                {/* FOR EACH FILE, ONE OF THIS GRID */}
                {stepTwolabelFiles && stepTwolabelFiles.map((e,i)=>{
               return <Grid 
                    item 
                    className={row}
                    style={{
                        
                        margin : '0.2em auto',
                        padding : '0.1em 0.2em',
                    }}    
                >
                    <Typography 
                        variant="body1"
                        style={{
                            marginRight : '1em'
                        }}    
                    >
                       {e.localFileName}
                        
                    </Typography>
                    <Typography 
                        variant="body2"
                    >
                       {filesize(e.localFileSize)}
                    </Typography>
                </Grid>
           
                    })}
                  
           </Grid>
            
        </Grid>
    )
}

export default ConsignmentDetails;