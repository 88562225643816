// import * as dataSync from '../../services/dataSaverAndTracker';
// import * as changesSync from '../../services/changesSync';
// import { SeverityLevel } from '@microsoft/applicationinsights-web';

import PropTypes from 'prop-types';
import { SkbLogger, ApiService, SeverityLevel } from '../../../services';
import _ from 'lodash';
import * as misc from '../../../utils/misc';
import { crypts } from '../../../utils/crypts';
// const taskConfig = require('../../components/modules/tasks_components/taskConfig.json');

import * as moment from 'moment-timezone';
import React from "react";
import TaskDetailsPopup from './../../../components/modules/stock_components/returns_dashboard/TaskDetailsPopup';
import Link from '@material-ui/core/Link';
import ContractorRepresentativeStructure from './../../../components/modules/stock_components/returns_dashboard/ReturnListContractorRepresentative';

import {ActionTypes} from "./returnsDashboardActionTypes";
import {returnsDashboardListService,getStockReturnSlaDaysService,returnsDashboardListTaskApproveService,getCancelReasonOptionsService ,  returnsDashboardListTaskCancelService  } from "../../../services/returnsdashboard";

/**
 * Actions  related to Returns Dashboard list functionality
 */



/* Returns dashboard task list related start */
 
  export const setStockReturnTaskList = list => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_LIST_LOAD,
      payload: list
    };
  };

  export const setStockReturnTaskListModifiedToRender = list => {
    return {
      type: ActionTypes.SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_MODIFIED,
      payload: list
    };
  };

  export const getUniqueStatusNameFromListToRenderData = (dataList) =>{

    let dataListItemsGroupedOnStatus = [];
    let statusNameAr = [];  let statusNameGroupedCountAr = [];
    let statusNameDataToExcludeAr = ['Delivered','Cancelled'];
    
    dataListItemsGroupedOnStatus =  _.chain(dataList)    
    .groupBy("status")     
    .map((value, key) => (
     
      { "status": key, "statusRelGroupedRecCount": Array.isArray(value)?value.length:0 })).value();
   
    
    if(Array.isArray(dataListItemsGroupedOnStatus) && dataListItemsGroupedOnStatus.length>0){
      
      // remove data having Delivered or Cancelled
      dataListItemsGroupedOnStatus = _.filter(dataListItemsGroupedOnStatus,(fobj)=>{  
        return (statusNameDataToExcludeAr.indexOf(fobj.status)===-1);  
      });
      
      //  arrange the data in descending order on count found 
      dataListItemsGroupedOnStatus = _.orderBy(dataListItemsGroupedOnStatus, ['statusRelGroupedRecCount'],['desc']);

      // Now segrargate the data in two arrays one with status names and and another with count related to status name
      dataListItemsGroupedOnStatus.map((e)=>{
        statusNameAr.push(e.status);
        statusNameGroupedCountAr.push(e.statusRelGroupedRecCount);
      });
      
    }

    let resp = {"pieChartRelStatusNameDataAr":statusNameAr,"pieChartStatusNameGroupedCountDataAr": statusNameGroupedCountAr,"pieChartStatusNameWithCountDataGrouped":dataListItemsGroupedOnStatus};
    return resp;
  }

  export const getUniqueAgeOfReturnColorFromListToRenderData = (dataList) =>{

    let dataListItemsGroupedOnAgeOfRetColorName = [];
    let ageOfRetRangeNameAr = [];  let ageOfRetRangeGroupedCountAr = [];
    let ageOfRetRangeColorCodeAr =  [];
    let statusNameDataToExcludeAr = ['Delivered','Cancelled'];
    
    dataListItemsGroupedOnAgeOfRetColorName =  _.chain(dataList)    
    .groupBy("ageOfRetColorNameString")     
    .map((value, key) => (     
      { 
        "ageOfRetColorNameString": key, 
        "ageOfRetRangeRelGroupedRecCount": Array.isArray(value)?value.length:0 , 
        "ageOfRetColorRangeMin":Array.isArray(value)?value[0].ageOfRetRangeItBelongsObj.min:0, 
        "ageOfRetColorRangeMax":Array.isArray(value)?value[0].ageOfRetRangeItBelongsObj.max:0 ,
        "ageOfRetRangeColorCode": Array.isArray(value)?value[0].ageOfRetColorCodeString:"",
        "ageOfRetColorCodeRangeString": Array.isArray(value)?value[0].ageOfRetColorCodeRangeString:"",
        "ageOfRetColorCodeRank": Array.isArray(value)?value[0].ageOfRetColorCodeRank:99999,
       })).value();
   
    
    if(Array.isArray(dataListItemsGroupedOnAgeOfRetColorName) && dataListItemsGroupedOnAgeOfRetColorName.length>0){
      
      // remove data having Delivered or Cancelled
      dataListItemsGroupedOnAgeOfRetColorName = _.filter(dataListItemsGroupedOnAgeOfRetColorName,(fobj)=>{  
        return (statusNameDataToExcludeAr.indexOf(fobj.status)===-1);  
      });
      
      //  arrange the data in descending order on count found 
      dataListItemsGroupedOnAgeOfRetColorName = _.orderBy(dataListItemsGroupedOnAgeOfRetColorName, ['ageOfRetColorCodeRank'],['asc']);

      // Now segrargate the data in two arrays one with status names and and another with count related to status name
      dataListItemsGroupedOnAgeOfRetColorName.map((e)=>{
      
        ageOfRetRangeNameAr.push(e.ageOfRetColorCodeRangeString);
        ageOfRetRangeGroupedCountAr.push(e.ageOfRetRangeRelGroupedRecCount);
        ageOfRetRangeColorCodeAr.push(e.ageOfRetRangeColorCode);

       
      });
      
    }

    let resp = {
    "pieChartRelAgeOfRetRangeDataAr":ageOfRetRangeNameAr,
    "pieChartAgeOfRetGroupedCountDataAr": ageOfRetRangeGroupedCountAr,
    "pieAgeOfRetRangeColorCodeAr":ageOfRetRangeColorCodeAr,
    "pieChartAgeOfRetRangeWithCountDataGrouped":dataListItemsGroupedOnAgeOfRetColorName
    };
    return resp;
  }



  export const callAsyncFuncToFetchStockReturnTaskList = () => {

    return async (dispatch, getState) => {

      //console.log("######### BINGO ====>>>>",getState() );
      const getStateObj = getState();      
      const returnStockSlaDays = (getStateObj && getStateObj.returnStockSlaDays!==undefined && getStateObj.returnStockSlaDays && getStateObj.returnStockSlaDays.slaDaysList!==undefined && Array.isArray(getStateObj.returnStockSlaDays.slaDaysList))? getStateObj.returnStockSlaDays.slaDaysList: [];      

      dispatch(setTaskListLoaderOn());
      // call service 
      const chkResp = returnsDashboardListService.getStockReturnTaskList({}).then((resp)=>{

          if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){
            const modifiedResp =  modifyStockReturnTaskListResponseForRender(resp.data,returnStockSlaDays);      
            dispatch(setStockReturnTaskList(resp.data));
            dispatch(setStockReturnTaskListModifiedToRender(modifiedResp));   
            const pieStatusReldataObj = getUniqueStatusNameFromListToRenderData(modifiedResp);
            dispatch(setPieChartOnStatusRelatedDataSet(pieStatusReldataObj));

            const pieAgeOfRetReldataObj = getUniqueAgeOfReturnColorFromListToRenderData(modifiedResp);
            dispatch(setPieChartOnAgeOfReturnRelatedDataSet(pieAgeOfRetReldataObj));
           

          }         

        

      }).catch((e)=>{
            console.log("----RDACTE001-----")
      }).finally(()=>{
        dispatch(setTaskListLoaderOff());
      });

    }

  }

  
  
  export const setTaskListLoaderOff = () => {

    return {
      type: ActionTypes.SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_OFF

    };

  }

  export const setTaskListLoaderOn = () => {
    return {
      type: ActionTypes.SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_ON
    }
  }


  export const modifyStockReturnTaskListResponseForRender =  (resp, returnStockSlaDays = []) => {    
        


        if(resp && Array.isArray(resp) && resp.length > 0)
        {
            let respAr = [];            
            resp.map( async (e,i)=>{

              
              let createdDate = (e.DateCreated && e.DateCreated) ? e.DateCreated: null;
              let createdDateObj = new Date(createdDate);

              let todaysDateInUtc =  misc.currentTimeToUTCTimeInString(misc.StringFormat.ServerDateFormat);
              let todaysDateObj =  new Date(todaysDateInUtc);
              let dateDiffInTime = todaysDateObj.getTime() - createdDateObj.getTime();
              let dateDiffInDays =  parseInt(dateDiffInTime / (1000*3600 * 24 ));

              // color wrap based on range if available
              let wrapObjAgeOfReturn = wrapColorBasedOnRange(dateDiffInDays,returnStockSlaDays); 
              let ageOfReturnObj = wrapObjAgeOfReturn.wrapObj;             
              let ageOfRetRangeItBelongsObj = wrapObjAgeOfReturn.rangeItBelongsObj;
              let ageOfRetColorNameString = wrapObjAgeOfReturn.colorNameString;
              let ageOfRetColorCodeString = wrapObjAgeOfReturn.colorCodeString;
              let ageOfRetColorCodeRangeString =   wrapObjAgeOfReturn.colorCodeRangeString;
              let ageOfRetColorCodeRank =   wrapObjAgeOfReturn.colorCodeRank;

              let contractorRepresentativePhone = (e.Company && e.Company.ContractorRepresentativePhone) ? e.Company.ContractorRepresentativePhone: "";
              let contractorRepresentative = (e.Company && e.Company.Representative) ? e.Company.Representative: "";

              let taskId = ( e && e.CompanySubTask && e.CompanySubTask.Id) ? e.CompanySubTask.Id: "";
              let consignmentLocationCode = (e.ConsignmentLocationCode!==undefined && e.ConsignmentLocationCode) ? e.ConsignmentLocationCode: "";

              let contractorObj = {
                "contractorRepresentative":contractorRepresentative,
                "contractorRepresentativePhone":contractorRepresentativePhone,
                "taskId":taskId
              };

             
              
              let taskIdPopupObj = {"taskId":taskId,"consignmentLocationCode":consignmentLocationCode};             

              let rowObj = {};
              rowObj["locationCode"] = consignmentLocationCode;
              //rowObj["taskId"] = <><TaskDetailsPopup returndashlisttaskinfopopupprop={taskIdPopupObj} /></>;
              //rowObj["taskId"] = <><Link color='primary'  style={{"cursor": "pointer"}} className={"taskIdColumnRetDashList"} dataConsignmentLocationCode={consignmentLocationCode} dataTaskId={taskId} >{taskId}</Link></>;
              rowObj["taskId"] = taskId;
              rowObj["contractingEntity"] = (e.Company && e.Company.Name) ? e.Company.Name: "";
              rowObj["contractorRepresentative"] = <div value={contractorObj.contractorRepresentative.toUpperCase()} valueForFiltering={contractorObj.contractorRepresentative}> <ContractorRepresentativeStructure dataContractorRepresentative={contractorObj} /> </div>;
              rowObj["stockLocation"] = (e.FromLocation && e.FromLocation.Name) ? e.FromLocation.Name: "";
              rowObj["typeOfConsignment"] = (e.ConsignmentType) ? e.ConsignmentType: "";
              rowObj["creator"] = (e.CreatedByUser && e.CreatedByUser.Name) ? e.CreatedByUser.Name: "";
              rowObj["ageOfReturn"] = ageOfReturnObj; 
              rowObj["ageInNumberDays"] = dateDiffInDays;
              rowObj["ageOfRetRangeItBelongsObj"] = ageOfRetRangeItBelongsObj; 
              rowObj["ageOfRetColorNameString"] = ageOfRetColorNameString; 
              rowObj["ageOfRetColorCodeString"] = ageOfRetColorCodeString; 
              rowObj["ageOfRetColorCodeRangeString"] = ageOfRetColorCodeRangeString;
              rowObj["ageOfRetColorCodeRank"] = ageOfRetColorCodeRank;

              rowObj["status"] = (e.CompanySubTask && e.CompanySubTask.Status) ? e.CompanySubTask.Status: "";
              rowObj["fullRawDataOfRow"] = e;
              respAr.push(rowObj);

            });            

            return respAr;
        }
        return [];

  }

  export const wrapColorBasedOnRange = (day, returnStockSlaDays) => {

    let wrapObj = "";
    let rangeItBelongsObj = {"min":0, "max":0};
    let colorNameString = "";
    let colorCodeString = "";
    let colorCodeRangeString = "";
    let colorCodeRank = 99999;

    let colorCodeObj = {"YELLOW":"#daa610","GREEN":"#2A9A2A","RED":"#da1515"};
    let colorCodeRankObj = {"GREEN":1,"YELLOW":2,"RED":3};
    let colorNamesTobeConsidered = ["YELLOW","GREEN","RED"];


    if(returnStockSlaDays.length>0){
      for(let i=0; i<(returnStockSlaDays.length); i++){

        let variableName = (returnStockSlaDays[i].variableName)? returnStockSlaDays[i].variableName:"";
        let variableValue = (returnStockSlaDays[i].variableValue)? returnStockSlaDays[i].variableValue:"";
        let variableValueSplit = variableValue? variableValue.split("-"):[];      
        let minValData = variableValueSplit[0];
        let maxValData = variableValueSplit[variableValueSplit.length-1];     
  
  
        if(day>=minValData && day <= maxValData){

              colorNameString=variableName.toUpperCase();

              if(colorNamesTobeConsidered.indexOf(colorNameString)!==-1){
                colorCodeString = colorCodeObj[colorNameString];
                colorCodeRank =     colorCodeRankObj[colorNameString];             
                rangeItBelongsObj["min"] = minValData;
                rangeItBelongsObj["max"] = maxValData;    
                wrapObj = <span style={{color:colorCodeString}}>{day}</span>; 
                
                colorCodeRangeString = `${minValData} ${minValData>=0 && minValData<=1?"day":"days"} to ${maxValData} days`;
                break;
              }else{
                wrapObj = <span>{day}</span>;
                break;
              }

                     
  
        }
  
      }; 
    }
    else{
      wrapObj =  <span>{day}</span>;
    }      
    
    return {
      "wrapObj":wrapObj,
      "rangeItBelongsObj":rangeItBelongsObj, 
      "colorNameString":colorNameString, 
      "colorCodeString":colorCodeString,
      "colorCodeRank": colorCodeRank,
      "colorCodeRangeString": colorCodeRangeString
    };

  }



  export const callAsyncFuncToFetchReturnStockSlaDays =   (otherOption={}) => {

      let dispAsyncFuncToFetchRetListFlag = otherOption.dispAsyncFuncToFetchRetListFlag!==undefined?otherOption.dispAsyncFuncToFetchRetListFlag: false;

      return async (dispatch) =>{

        dispatch(setReturnStockSlaDaysLoaderOn());
                await getStockReturnSlaDaysService.getStockReturnSlaDays({}).then((resp)=>{               
                dispatch(setReturnStockSlaDays(resp.data));
                dispatch(setReturnStockSlaDaysLoaderOff());
        }).then(()=>{

             
             
        }).catch((er)=>{
        }).finally(()=>{
               /*               
                1. As data of  callAsyncFuncToFetchStockReturnTaskList is dependant  on callAsyncFuncToFetchReturnStockSlaDays otherwise callAsyncFuncToFetchStockReturnTaskList is not able to access  tteh state of callAsyncFuncToFetchReturnStockSlaDays
                2. We have passing a flag dispAsyncFuncToFetchRetListFlag  is passed false then callAsyncFuncToFetchStockReturnTaskList wiill not get called 

              */
             if(dispAsyncFuncToFetchRetListFlag===true){
              dispatch(callAsyncFuncToFetchStockReturnTaskList());
            }

        });

      }

  }  

  /* Returns dashboard task list related end */

/* Pie chart on status  related starts  */
export const setPieChartOnStatusRelatedDataSet = objData => {
  return {
    type: ActionTypes.PIE_CHART_ON_STATUS_RELATED_DATA_SET,
    payload: objData
  };
};


/* Pie chart on status  related ends  */

/* Pie chart on age  of return   related starts  */
export const setPieChartOnAgeOfReturnRelatedDataSet = objData => {
  return {
    type: ActionTypes.PIE_CHART_ON_AGEOFRETURN_RELATED_DATA_SET,
    payload: objData
  };
};
/* Pie chart on age of return  related ends  */

/* Return list filter names set related starts */
export const setReturnListStatusActiveFilterData = list => {
  return {
    type: ActionTypes.SET_RETURN_LIST_STATUS_ACTIVE_FILTERS,
    payload: list
  };
};
/* Return list filter names set related ends */


  /* SlaDays related start */
  export const setReturnStockSlaDays = list => {
    return {
      type: ActionTypes.SET_RETURN_STOCK_SLA_DAYS,
      payload: list
    };
  };
  

  export const setReturnStockSlaDaysLoaderOff = () => {

    return {
      type: ActionTypes.SET_RETURN_STOCK_SLA_DAYS_LOADER_OFF

    };

  }

  export const setReturnStockSlaDaysLoaderOn = () => {
    return {
      type: ActionTypes.SET_RETURN_STOCK_SLA_DAYS_LOADER_ON
    }
  }
  /* SlaDays related end */


  /* Task selection from list start */

  export const setTaskSelectedFromReturnsDashboardList = list => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_SELECT,
      payload: list
    };
  };

  /* Task selection from list end */

  /* Task selection approve related start */
  
  export const getUpdateStatusOfSomeRowAfterStatusChange = (originalListData=[],locationCodeStatusApprvAr=[], changeToStatus="") => {

    let fetchLocCodes = [];
    let updatedRowList = [];

    if(Array.isArray(locationCodeStatusApprvAr) && locationCodeStatusApprvAr.length>0){
      locationCodeStatusApprvAr.map((e,i)=>{
        fetchLocCodes.push(e.LocationCode);
  
      });
    }

    if(Array.isArray(originalListData) && originalListData.length>0){

      originalListData.map((ee,ii)=>{

        if((fetchLocCodes).indexOf(ee.ConsignmentLocationCode)>-1){
          let rowToChange = {...ee};
          let companySubTaskNewRef =  {...rowToChange.CompanySubTask};
          companySubTaskNewRef.Status = changeToStatus?changeToStatus:"Awaiting picking";

          rowToChange.CompanySubTask =  companySubTaskNewRef;
          updatedRowList.push(rowToChange);



        }
        else{
          updatedRowList.push(ee);
        }

        
      });


    }


    return updatedRowList;  

  }

  export const callAsyncFuncToApproveTasksSelected = (paramObj) => {   
    return async (dispatch,getState) => {
      dispatch(setTaskListLoaderOn());
      const getStateObj = getState();  

      const returnsList = (getStateObj && getStateObj.returnsDashboardList!==undefined && getStateObj.returnsDashboardList && getStateObj.returnsDashboardList.returnsList!==undefined && Array.isArray(getStateObj.returnsDashboardList.returnsList))? getStateObj.returnsDashboardList.returnsList: [];    

      const returnStockSlaDays = (getStateObj && getStateObj.returnStockSlaDays!==undefined && getStateObj.returnStockSlaDays && getStateObj.returnStockSlaDays.slaDaysList!==undefined && Array.isArray(getStateObj.returnStockSlaDays.slaDaysList))? getStateObj.returnStockSlaDays.slaDaysList: [];   
          
      returnsDashboardListTaskApproveService.approveTask(paramObj).then((resp)=>{
          if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){

            dispatch(setApproveApiSuccessReceiveStatus());

            // Now update the list data
            let locCodeArObj = paramObj.bodyParam.Consignments;
            let getUpdatedListData =  getUpdateStatusOfSomeRowAfterStatusChange(returnsList,locCodeArObj, "Awaiting picking");
            let getUpdatedListDataToDisp =  modifyStockReturnTaskListResponseForRender(getUpdatedListData,returnStockSlaDays);
            
            
             dispatch(setTaskListLoaderOn());
             dispatch(setStockReturnTaskList(getUpdatedListData));
             dispatch(setStockReturnTaskListModifiedToRender(getUpdatedListDataToDisp));
             const pieStatusReldataObj = getUniqueStatusNameFromListToRenderData(getUpdatedListDataToDisp);
             dispatch(setPieChartOnStatusRelatedDataSet(pieStatusReldataObj)); 
             dispatch(setTaskListLoaderOff());

              
          }

      }).catch((err)=>{
        dispatch(setTaskListLoaderOff());
      }).finally(()=>{
        dispatch(setTaskListLoaderOff());
      });

    }

  }

  export const setApproveApiSuccessReceiveStatus = () => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS
    }
  }

  export const setApproveApiSuccessReceiveStatusUnset = () => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS_UNSET
    }
  }
   /* Task selection approve related end */

  /* Task cancel flow from returns  dashhboard list section reason dropdown related starts  */
  export const callAsyncFuncToFetchCancelReasonOptionsList =   (otherOption={}) => {
  

  return async (dispatch) =>{   
    dispatch(setCancelReasonOptionsListLoaderOn());      
    await getCancelReasonOptionsService.getCancelReasonOptions().then((resp)=>{ 
          
        //console.log("******** 
        if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
              dispatch(setCancelReasonOptionsList(resp.data)); 

        }
    }).catch((er)=>{

    }).finally(()=>{
      dispatch(setCancelReasonOptionsListLoaderOff());
        
    });
   
  }

}  

export const setCancelReasonOptionsList = list => {
  return {
    type: ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD,
    payload: list
  };
};

export const setCancelReasonOptionsListUnset = () => {
  return {
    type: ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD_UNSET,    
  };
};

export const setCancelReasonOptionsListLoaderOn = () => {
  return {
    type: ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_ON
  }
}

export const setCancelReasonOptionsListLoaderOff = () => {

  return {
    type: ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_OFF

  };

}

/* Task cancel flow from returns  dashhboard list section reason dropdown related ends  */


  /* Task cancel flow from returns  dashhboard list section submit related start */
  
  

  export const callAsyncFuncToCancelEligibleTasks = (paramObj) => {   
    return async (dispatch,getState) => {
      dispatch(setCancelReasonSubmitLoaderOn());
      const getStateObj = getState();  

      const returnsList = (getStateObj && getStateObj.returnsDashboardList!==undefined && getStateObj.returnsDashboardList && getStateObj.returnsDashboardList.returnsList!==undefined && Array.isArray(getStateObj.returnsDashboardList.returnsList))? getStateObj.returnsDashboardList.returnsList: [];    

      const returnStockSlaDays = (getStateObj && getStateObj.returnStockSlaDays!==undefined && getStateObj.returnStockSlaDays && getStateObj.returnStockSlaDays.slaDaysList!==undefined && Array.isArray(getStateObj.returnStockSlaDays.slaDaysList))? getStateObj.returnStockSlaDays.slaDaysList: [];   
          
      returnsDashboardListTaskCancelService.cancelTask(paramObj).then((resp)=>{
          if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){

            dispatch(setCancelTaskApiSuccessReceiveStatus());

            // Now update the list data
            let locCodeArObj = paramObj.bodyParam.Consignments;
            let getUpdatedListData =  getUpdateStatusOfSomeRowAfterStatusChange(returnsList,locCodeArObj, "Cancelled");
            let getUpdatedListDataToDisp =  modifyStockReturnTaskListResponseForRender(getUpdatedListData,returnStockSlaDays);            
             
             dispatch(setStockReturnTaskList(getUpdatedListData));
             dispatch(setStockReturnTaskListModifiedToRender(getUpdatedListDataToDisp));
             const pieStatusReldataObj = getUniqueStatusNameFromListToRenderData(getUpdatedListDataToDisp);
             dispatch(setPieChartOnStatusRelatedDataSet(pieStatusReldataObj)); 
             

              
          }

      }).catch((err)=>{
        dispatch(setCancelReasonSubmitLoaderOff());
      }).finally(()=>{
        dispatch(setCancelReasonSubmitLoaderOff());
      });

    }

  }

  export const setCancelTaskApiSuccessReceiveStatus = () => {
    return {
      type: ActionTypes.CANCEL_TASK_REASON_SUCCESS
    }
  }

  export const setCancelTaskSuccessReceiveStatusUnset = () => {
    return {
      type: ActionTypes.CANCEL_TASK_REASON_SUCCESS_UNSET
    }
  }


  export const setCancelReasonSubmitLoaderOn = () => {
    return {
      type: ActionTypes.CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_ON
    }
  }
  
  export const setCancelReasonSubmitLoaderOff = () => {  
    return {
      type: ActionTypes.CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_OFF
  
    };
  
  }
  /* Task cancel flow from returns  dashhboard list section submit related end */