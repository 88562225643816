

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';

import ScanBarcode from './ScanBarcode';
import BarcodeImage from './BarcodeImage';
import Product from '../../../../assets/images/product.svg';
import Loading from '../../../../components/Loading';


import { SkbLogger, SeverityLevel } from '../../../../services';

import AddIcon from '@material-ui/icons/Add';


import * as misc from '../../../../utils/misc';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';

import { TaskLogInfo } from '../../../../utils/misc';

function createData(serialnumber, description, status, dateStamp) {
  return { serialnumber, description, status, dateStamp };
}

function descendingComparator(a, b, orderBy) {
  // update the sort order 
  if (orderBy == "serialnumber") {
    orderBy = "dateStamp"
  }
  //console.log('b[orderBy', b[orderBy])
  //console.log('a[orderBy]', a[orderBy])
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'SerialNumber', numeric: false, disablePadding: true, label: 'Serial #' },
  { id: 'Description', numeric: true, disablePadding: false, label: 'Description' },
  { id: 'ItemStatus', numeric: true, disablePadding: false, label: 'Status' }
];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'middle' : 'middle'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{ fontWeight: "bolder", fontSize: "1.2em" }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },

  title: {
    display: "flex",
    flex: '1 1 100%',
    [theme.breakpoints.up('md')]: {
      justifyContent: "space-between"
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start"
    }
  }

}
));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selectedSerialNumbers } = props;
  const [isOpenUpdateDlg, setIsOpenUpdateDlg] = React.useState(false);
  const [isOpenDeleteDlg, setIsOpenDeleteDlg] = React.useState(false);
  const [status, setStatus] = React.useState("Good");

  const openUpdateDlg = () => {
    setIsOpenUpdateDlg(true);
  };

  const handleCloseUpdateDlg = () => {
    setIsOpenUpdateDlg(false);
  };

  const openDeleteDlg = () => {
    setIsOpenDeleteDlg(true);
  };

  const handleCloseDeleteDlg = () => {
    setIsOpenDeleteDlg(false);
  };

  const handleDelete = async () => {
    // selectedSerialNumbers : list of serial numbers that need to update
    await Promise.all(selectedSerialNumbers.map(async (serialNumber) => {
      props.deleteSnItem(serialNumber);

    }))

    props.finishUpdating();
    setIsOpenDeleteDlg(false);
  };

  const handleUpdate = async () => {

    // debugger;
    // selectedSerialNumbers : list of serial numbers that need to update
    // status : the status will update for list of serial numbers
    await Promise.all(selectedSerialNumbers.map(async (serialNumber) => {

      props.updateSnItem(serialNumber, status);

    }))

    props.finishUpdating();
    setIsOpenUpdateDlg(false);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const editMessage = "Are you sure you wish to update the status for Serial # \n\n " + selectedSerialNumbers.join('\n ') + "\nto " + status + "?"
  const deleteMessage = "Are you sure you wish to remove Serial #:\n\n " + selectedSerialNumbers.join('\n ') + "?"

  return (
    <div>

      <div className={classes.title}>

        {numSelected > 0 ? (
          <Typography id="table-num-selected-label" color="inherit" variant="subtitle1" component="div">
            Bulk Action
            <br />
            {numSelected} selected
          </Typography>

        ) : (
            <Typography id="table-title" variant="h6" id="tableTitle" component="div">

            </Typography>
          )}

        {numSelected > 0 ? (
          <skbtab label="Select Field">
            <div>
              <SkbSelect Title="" value={status} items={["Good", "Faulty"]} valueChanged={handleChangeStatus} />
            </div>
          </skbtab>
        )
          :
          <div />
        }

        {numSelected > 0 ? (
          <Tooltip title="Save" onClick={openUpdateDlg}>
            <IconButton id="list-update-button" aria-label="SaveIcon">
              <SaveIcon color='primary' />
            </IconButton>
          </Tooltip>
        )
          :
          <div />
        }

        {numSelected > 0 ? (
          <Tooltip title="Delete" onClick={openDeleteDlg}>
            <IconButton id="list-delete-button" aria-label="delete">
              <DeleteIcon color='primary' />

            </IconButton>
          </Tooltip>
        ) : (
            <div />
          )}
      </div>

      <SkbConfirmDialog
        id="confirm-edit"
        title="Confirm Edit"
        message={editMessage}
        okButtonIsFirst={false}
        okLabel="Yes"
        cancelLabel="No"
        open={isOpenUpdateDlg}
        openConfirm={handleCloseUpdateDlg}
        okHandler={handleUpdate} />

      <SkbConfirmDialog
        id="confirm-deletion"
        title="Confirm Deletion"
        message={deleteMessage}
        okButtonIsFirst={false}
        okLabel="Yes"
        cancelLabel="No"
        open={isOpenDeleteDlg}
        openConfirm={handleCloseDeleteDlg}
        okHandler={handleDelete} />


    </div>
    // </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selectedSerialNumbers: PropTypes.array
};

const useStyles = makeStyles((theme) => ({

  root: {
    //   width: '100%',
    backgroundColor: theme.palette.background.default,
    fontSize: theme.typography.body2.fontSize
  },
  paper: {
    width: '100%',

  },
  table: {
    "& .MuiCheckbox-colorSecondary.Mui-checked":
      theme.palette.type === 'light'
        ? {
          color: theme.palette.primary.dark
        }
        :
        {
          color: darken(theme.palette.primary.dark, 0.25)
        }
    ,
    "& .MuiTableRow-root.Mui-selected":
      theme.palette.type === 'light'
        ? {
          backgroundColor: lighten(theme.palette.primary.light, 0.3)
        }
        : {
          backgroundColor: theme.palette.primary.dark,
        },

    "& .MuiTableRow-root.Mui-selected.MuiTableRow-hover:hover":
      theme.palette.type === 'light'
        ? {
          backgroundColor: theme.palette.primary.light
        }
        : {
          backgroundColor: theme.palette.primary.main
        }
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  footer: {
    display: "flex",

    [theme.breakpoints.up('md')]: {
      justifyContent: "flex-end"
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start"
    }
  }
}));

const scrollToRef = (ref) => {
  //console.log(ref.current.offsetTop);
  setTimeout(() => {
    window.scrollTo(0, 0);

  }, 100);

}

function BarcodeTable(props) {

  const { rows } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('serialnumber');
  const [selected, setSelected] = React.useState([]);
  const [isViewDetail, setIsViewDetail] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const scrollRef = useRef(null);

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.SerialNumber);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  var flag = false;
  const handleClick = (event, name) => {

    if (flag == true) {
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToRef(scrollRef);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const scanBtnClick = async () => {

  };

  const finishUpdating = async () => {

    props.reloadTable()
    setTimeout(() => {
      setSelected([])
    }, 150)


  };

  const handleSerialNumberClick = (e) => {
    flag = true;
    e.preventDefault();
    setIsViewDetail(true)
    props.viewBarcodeImage(e.target.innerText)
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const theme = useTheme();
  return (
    <Paper className={classes.paper}>
      <div className={classes.table}>

        <EnhancedTableToolbar numSelected={selected.length} selectedSerialNumbers={selected} updateSnItem={props.updateSnItem} deleteSnItem={props.deleteSnItem} finishUpdating={finishUpdating} />

        <TableContainer>
          <Table

            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"

          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.SerialNumber);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.SerialNumber)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.SerialNumber}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.Photo ?
                          <a href="#" onClick={handleSerialNumberClick}>
                            {row.SerialNumber}
                          </a>
                          :
                          row.SerialNumber
                        }

                      </TableCell>
                      <TableCell align="middle">{row.Description}</TableCell>
                      <TableCell align="middle">{row.ItemStatus}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                //show maximum one empty row 
                <TableRow style={{ height: (dense ? 33 : 53) * 1 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.footer} id="TableCustomPagination">
          <TablePagination id="table-footer"
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div className={classes.root} id="table-footer">

          </div>
        </div>
        <div backgroundColor={theme.palette.primary.dark} />

        <br />

      </div>

    </Paper>
  );
}


/*
 * component: The ListBarcode is used within StepSerialisedItems to show the list of scanned items (and hold other controls for scanning).
* @param {array}  serialisedItems: the scanned or picked serialised items
                    *       e.g.  [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134"
                    },...]
* @param {function} loadSerialisedItems: a function to load the serialised items.
 * @param {array}  knownSerialNumbers: the serial numbers that we think they may scan, with related stock code/description, 
 * so that we can recognise them.
 *              e.g.  [
                    {
                        SerialNumber: "133434324",
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                    },...]
 * 
* @param {function} addSerialisedItem: a function to save an added item.
* @param {function} updateSerialisedItem: a function to save an updated item.
* @param {function} deleteSerialisedItem: a function to delete an item from the count or consignment.
  * @param {function} OPTIONAL: retrievePhoto: an async function to retrieve the barcode photo for a scanned item (parameter scannedItem).
                    If not provided, the photo is assumed to be available in the property scannedItem.Photo.
 * @param {boolean} isLoading: Is data currently loading?  (used to show loading indicator.)
* @param {object} taskObjectForLogging: an object to be passed to the logger containing relevant task information.
 * @param {text} moduleName: to be used in logging.
 * * @param {text} stepName: to be used in logging.
 */
export default class ListBarcode extends React.Component {
  constructor(props) {
    super(props);
    this.cameraRef = React.createRef();
    this.state = {
      rows: [],
      isLocalHost: (window.location.hostname === "localhost"),
      isOpen: false,
      closeDialog: false,
      isOpenTest1: false,
      isOpenTest2: false,
      isOpenTest3: false,
      results: [],
      scanning: false
      // ,
      // email: "Jane.doe@email.com",
      // _userObject: {
      //   "Full Name"	:"Jane Doe",
      //   "Email Address":"Jane.doe@email.com",
      //   "Identity Type": "Google / Microsoft-Live / Non-Federated" ,
      //   "Address 1":"Unit 4",
      //   "Phone Number":	"+61 400 123 132"
      // } 
    }
  }

  componentDidMount() {
    //var serialisedItems=this.props.currentSubTask.SerialisedItems.map(d => ({ ...d }));
    //this.setState({ rows: , isLoading: false })
    this.props.loadSerialisedItems(); // refresh snitems always when sn step is becoming active
    if (!this.props.serialisedItems) {
      this.setState({ rows: [], isLoading: true });
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.serialisedItems && prevProps.serialisedItems !== this.props.serialisedItems) {
      var serialisedItems = this.props.serialisedItems.map(d => ({ ...d }));

      this.setState({ rows: serialisedItems, isLoading: false })
    }
  }

  _scan = () => {
    this.setState({ scanning: !this.state.scanning })
  }

  _onDetected = result => {
    this.setState({ results: this.state.results.concat([result]) })
  }

  getListBarcodes = async () => {
    // reload list of barcodes 
    var serialisedItems = this.props.serialisedItems.map(d => ({ ...d }));

    this.setState({ rows: serialisedItems, isOpen: false })


  };

  viewBarcodeDetail = async (serialNumber) => {
    setTimeout(() => {
      this.setState({ isOpenBarcodeImage: true, serialNumber: serialNumber })
    }, 20);
  }

  toggleDialog = async () => {
    if (this.state.isOpen) {
      var serialisedItems = this.props.serialisedItems.map(d => ({ ...d }));

      this.setState({ rows: serialisedItems, isOpen: false })


    }
    else {

      SkbLogger.userAuditEvent(this.props.moduleName, misc.getCurrentUserEmail(), TaskLogInfo.category.scanningList, 'Press scan button', misc.LogResult.success,
        '{userObject} has entered the barcode scanning page of the stocktake task at {locationObject} while {networkObject}.'
        , {
          userObject: misc.getUserObject(),
          locationObject: misc.getLocationObject(),
          networkObject: misc.getNetworkObject()
        });


      this.setState({ isOpen: true })

      SkbLogger.userAuditEvent(this.props.moduleName, misc.getCurrentUserEmail(), TaskLogInfo.category.scanPage, 'Open Scan Page', misc.LogResult.success,
        '{userObject} has successfully navigated to the Scan Barcode Page.'
        , {
          userObject: misc.getUserObject()
        });
    }
  }

  closeBarcodeImage = async () => {
    this.setState({ isOpenBarcodeImage: false })
  }

  scanBtnClick = async () => {
  };

  // handleClearDatabase = async () => {

  //   Service.deleteAll("ScannedItem").then(result => {
  //     var rows = []
  //     // if (result != undefined ){
  //       this.getListBarcodes();
  //     // }
  //   })
  // };

  // processAdd=(newItem)=>{
  //    debugger;
  //      alert('Adding: Serial number: '+newItem.serialNumber + ', Status: '+ newItem.status);

  //      this.props.addSnItem(this.props.allTasks, newItem);

  //      var description=newItem.description;
  //      if (description===null||description===undefined||description==='')
  //      {
  //        description=`Unknown with serial ${newItem.serialNumber}`;
  //      }


  //      SkbLogger.userAuditEvent(moduleName, misc.getCurrentUserEmail(), stepName, 'Manual add serialised item', misc.LogResult.success,
  //          '{userObject} has successfully entered {stockObject} at {locationObject} while {networkObject}.'
  //          , {
  //              userObject: misc.getUserObject(),
  //              stockObject: misc.buildStockObjectForLog('Serialised', newItem.stockCode,description),
  //              locationObject: misc.getLocationObject(),
  //              networkObject: misc.getNetworkObject()
  //          });

  //   //   resetCompleteStepStatusOnChange();
  //   //TODO

  //    }



  updateSnItem = (serialNumber, status) => {

    var serialisedItems = this.props.serialisedItems;

    var scannedItem = { ...serialisedItems.find(i => i.SerialNumber === serialNumber) };

    if (scannedItem !== undefined) {
      scannedItem.ItemStatus = status;
      this.props.updateSerialisedItem(scannedItem);
    }

    if (this.props.onChange && typeof (this.props.onChange) === 'function') {
      this.props.onChange();
    }
  }

  deleteSnItem = (serialNumber) => {
    var serialisedItems = this.props.serialisedItems;

    var scannedItem = { ...serialisedItems.find(i => i.SerialNumber === serialNumber) };

    if (scannedItem !== undefined) {
      this.props.deleteSerialisedItem( scannedItem);
    }

    if (this.props.onChange && typeof (this.props.onChange) === 'function') {
      this.props.onChange();
    }
  }



  render() {
    const { forwardedRef, ...rest } = this.props;
    return (
      <div id="serialisedListSection" className="forceScroll">
        <div align="left">
          {/* <Button onClick={this.handleClearDatabase} color="primary">
              Clear Database
            </Button> */}
        </div>
        <BarcodeTable rows={this.props.serialisedItems || []} reloadTable={this.getListBarcodes} viewBarcodeImage={this.viewBarcodeDetail}
          updateSnItem={this.updateSnItem} deleteSnItem={this.deleteSnItem}
        />

        <div align="center" className="ScannIcon">

          <Button variant="contained" color="primary" onClick={this.toggleDialog}>
            <img src={Product} alt="Logo" />Scan
                    </Button>
          <SkbButton id="open-dialog-button" text="Add" primary outlined onClick={this.props.handleClickOpen} startIcon={<AddIcon />} />
        </div>
        {misc.isMobileBrowser() && <div style={{height:100}}>{/*on mobile device, add more blank space to make the button viewable when scroll */}
          
        </div>
        }

        <ScanBarcode serialisedItems={this.props.serialisedItems} 
        loadSerialisedItems={this.props.loadSerialisedItems} 
        knownSerialNumbers={this.props.knownSerialNumbers} 
        addSerialisedItem={this.props.addSerialisedItem} 
         deleteSerialisedItem={this.props.deleteSerialisedItem} 
        isOpen={this.state.isOpen} closeDialog={this.toggleDialog} ref={forwardedRef} onAdd={this.props.onChange} {...rest} />

        <BarcodeImage serialisedItems={this.props.serialisedItems} retrievePhoto={this.props.retrievePhoto} isOpen={this.state.isOpenBarcodeImage} closeDialog={this.closeBarcodeImage} serialNumber={this.state.serialNumber} ref={forwardedRef} {...rest} />
        {this.props.isLoading && <Loading />}
      </div>
    )
  }
}



