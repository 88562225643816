import { Route, MemoryRouter, Switch } from 'react-router';
import React, { useState, useEffect,useReducer } from "react";
import { connect } from 'react-redux';
import * as regi from '../../Redux/actions/register';
import TFACodeVerification from './TFACodeVerification'
import UserTypeSelection from './UserTypeSelection'
import VerifyContractorDetail from './VerifyContractorDetail'
import RegistrationSubmitted from './RegistrationSubmitted'

function MainRegistration(props){

         switch (props.register.RegisterStep) {
              case 0:
                 return (<TFACodeVerification/> )
              case 1: 
                if(props.register.IsSKBUser){
                  return (<RegistrationSubmitted text="Thank you for submitting your registration details.The IT helpdesk has been notified."
                        text2="Please feel free to contact the IT helpdesk using the Call for Assistance button below"/>)
                  //return null;
                }
                else{
                  return(<UserTypeSelection />)
                }
              case 2: 
                  switch(props.register.SelectedUserType){
                    case 1:
                      return (<VerifyContractorDetail />) 
  
                    default: return null; break;
                  }    
              case 3:
                switch(props.register.SelectedUserType){
                  case 1:
                    return (<RegistrationSubmitted text="Thank you for submitting your registration details. Your registration will be processed within the next 10 minutes."
                        text2="Please feel free to contact the Tech Support Team using the Call for Assistance button below if you wish to discuss your registration directly."/>)

                  default: return null; break;
                }    
              default: return null; break;
         }
  }

  const mapStateToProps = state => {
    return { register: state.register};
  };
  

  
  export default connect(mapStateToProps,null)(MainRegistration);
  
  
  
  