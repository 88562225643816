import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SkbButton from './SkbButton';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default ,
    }
}));

const titleBarStyles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth: 300
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
         color: theme.palette.secondary.main,
    },
});

const DialogTitle = withStyles(titleBarStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

/**
 * 
 * @property {message}: message to show (can contain line breaks represented as \n)
 * @property {open}: true: show alert window, false: hide alert window
 * @property {openAlert}: alert window open sate update handler;
 */
export default function SkbAlertDialog({ message, open, openAlert, title = "Alert", okLabel = "Ok" }) {
    //   const [open, setOpen] = React.useState(false);

     const classes = styles();
    const handleClose = () => {
        openAlert(false);
    };
    const okButton=<SkbButton id="ok-button" outlined={true} primary size="medium" text={okLabel ||"Ok"} onClick={handleClose} autoFocus={true}/>;

    var formattedMessageText=""

    if (message!==null && message!==undefined)
    {
        formattedMessageText=message.split('\n').map(text => {
            return <Typography gutterBottom>{text}</Typography>
        });
    }   

    return (
        <Dialog  onClose={handleClose} id="customised-dialog-title" aria-labelledby="customised-dialog-title" open={open} classes={{paper:classes.paper}}>
            <DialogTitle id="customised-dialog-title" onClose={handleClose}>
                {title}
        </DialogTitle>
            <DialogContent id="customised-dialog-content" dividers>
              {formattedMessageText}
            </DialogContent>
            <DialogActions>
                {okButton}
            </DialogActions>

        </Dialog>
    );
}
