import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {LAYOUTCOMPONENT_SIZE } from '../../skb_controls/Themes';
import SkbRegistrationAppBar from './SkbRegistrationAppBar'
import  SkbFooter from  '../../skb_controls/navigation/SkbFooter';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
       // paddingTop:50,
     /*   [theme.breakpoints.between('xs')]: {
        paddingTop: 30
        }, */
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingTop: 46
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 54
        },
        // [theme.breakpoints.up('md')]: {
        //     paddingTop: 30
        // },
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: LAYOUTCOMPONENT_SIZE.Header.Height,
        marginBottom: LAYOUTCOMPONENT_SIZE.Footer.Height, 
        [theme.breakpoints.down('md')]: {

            overflowY: "auto",
            maxHeight:`calc(100vh - ${LAYOUTCOMPONENT_SIZE.Footer.Height + LAYOUTCOMPONENT_SIZE.Header.Height}px)`,
        },
        zIndex: 1,
        //paddingTop:15,
    },
}));

export function RegistrationLayout(props) {
    const { container, children, id } = props;

    const theme = useTheme();
    const classes = useStyles();

    const state = props.state;

    return (
        <div className={classes.root}>
             <CssBaseline /> 
            <SkbRegistrationAppBar {...props}  />
            <main className={classes.content}>
                {children}
            </main>
            <SkbFooter/>
        </div> 
    );
}
