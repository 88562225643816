import React, { useEffect,useState } from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Box,
    Typography,
    Paper,

} from '@material-ui/core';
import {config} from '../../../utils/config';
import {crypts} from '../../../utils/crypts';
import {SkbSelect} from '../../../skb_controls/components/SkbSelect';
import SkbButton from '../../../skb_controls/components/SkbButton';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import {SkbSearchTextField} from "../../../skb_controls/components/SkbSearchTextField"; 
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import { makeStyles } from '@material-ui/core/styles';
import {loadConsignmentTasks,filterTasksByStatus} from '../../../Redux/ConsignmentTasks/actions';
import {
    selectConsignmentTaskList,
    selectConsignmentTasksLoading,
    selectSortOrderValue,
    selectFilterTypeValue,
    selectFilteredTasks,

    selectConsignmentList,
    selectConsignmentListError,
    selectFetchingConsignmentList,

    selectFilteredConsignmentList,
    selectFetchingFilteredConsignmentList,
    selectFilteredConsignmentListError,

    selectSearchingByStockCode,
    selectSearchByStockCodeResults,
    selectSearchByStockCodeError,

    selectSearchingBySerialNumber,
    selectSearchBySerialNumberResults,
    selectSearchBySerialNumberError,

    selectSearchingByConsignmentId,
    selectSearchByConsignmentIdResults,
    selectSearchByConsignmentIdError,



} from '../../../Redux/ConsignmentTasks/selectors';
import {
    fetchConsignmentListStart,
    filterByStatusStart,
    clearFilterStatus,

    searchByStockCodeStart,
    searchBySerialNumberStart,
    searchByConsignmentIdStart,

    clearSearchConsignmentData,
    
} from '../../../Redux/ConsignmentTasks/actions';

import Loading from '../../Loading';
import SingleConsignmentTask from './SingleConsignmentTask';
import SearchConsignmentResults from './SearchConsignmentResults';




const useStyles = makeStyles(theme => ({
    root : {
        marginTop : theme.spacing(2),
    },

    flexCenter : {
        display : "flex",
        justifyConent : "center",
        alignItems : "center",
    },
    textCenter : {
        textAlign : "center",
    },
    newTaskFlex : {
        width : "100%",
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
    },
    searchFilterSort : {
        justifyContent:"center",
        width : "100%",
        flexWrap : "wrap",
        [theme.breakpoints.down(550)] : {
            flexFlow : "column"
        }
    },
    searchBar : {
        width : "30em",
        [theme.breakpoints.down(1300)] : {
            width : "15em",
        },
        [theme.breakpoints.down(550)] : {
            margin : "0.3em 0",
            width : "20em",
            '&:div' : {
                width : "100%",
            }
        },
    }

}))


const NewTask = () => {
    const classes = useStyles();
    return(
        <Paper elevation={1}>
            <Box sx={{
                padding : "1em",
                margin : "0.5em 0",
            }}>
                <Typography variant="h6">New Task</Typography>
                <Grid container className={classes.newTaskFlex}>
                    
                    <Grid item>
                        <SkbIcon icon={ControlPointDuplicateIcon} style={{fontSize:"5em"}}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">Create a new Task</Typography>
                        <Typography variant="body2">E.g add new stock</Typography>
                    </Grid>
                    <Grid item>
                        <SkbButton outlined size="medium" text={"Create"} style={{marginTop:"3em"}}/>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )

}

const ConsignmentTasks = (props) => {
    const {
        // data props
        
    
        // selectors
        sortOrder,
        filterType,

        consignmentList,
        consignmentListError,
        fetchingConsignmentList,
        
        filteredConsignments,
        filtering,
        filterError,

        searchingByStockCode,
        searchByStockCodeResults,
        searchByStockCodeError,

        searchingBySerialNumber,
        searchBySerialNumberResults,
        searchBySerialNumberError,

        searchingByConsignmentId,
        searchByConsignmentIdResults,
        searchByConsignmentIdError,

        //actions 
        fetchConsignmentListStart,
        filter,
        clearFilter,
        searchByStockCode,
        searchBySerialNumber,
        searchByConsignmentId,

        clearConsignmentSearch,


    } = props;

    const classes = useStyles();

    const onSearchConsignment = value => {
        console.log(value);
        if(value === "") {
            clearConsignmentSearch()
        }else{
            searchByStockCode(value);
            searchBySerialNumber(value);
            searchByConsignmentId(value);
        }
        
    }

    useEffect(()=>{
        console.log("authorization" , config.REACT_APP_API_SECRET)
        console.log("X-CustomHeader" , crypts.decrypt(localStorage.getItem('id_token')))
        fetchConsignmentListStart();
        console.log(process.env.REACT_APP_API_URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Box sx={{ width : '100%' }}>
            <Typography variant="h5" className={classes.textCenter}>My Consignments</Typography>
            <Grid
                container
                className={classes.searchFilterSort}
                alignItems = "center"
            >   
                <Grid item style={{alignItems:"center"}}>
                    <SkbIcon icon={FilterListIcon} style={{fontSize:"1em",marginTop : "20px"}}/>
                    <SkbSelect 
                        Title="Filter Tasks"
                        value={filterType}
                        valueChanged={value=>{
                            clearConsignmentSearch();
                            if(value === "All Consignment Tasks"){
                                clearFilter();
                                fetchConsignmentListStart();
                            }else{
                                filter(value);
                            }
                        }} 
                        items={[{
                            value: "All Consignment Tasks",
                            text: "All Consignment Tasks"
                        },
                        {
                            value: "On Order",
                            text: "On Order"
                        },
                        {
                            value: "Ready for Dispatch",
                            text: "Ready for Dispatch"
                        },
                        {
                            value: "Cancelled",
                            text: "Cancelled"
                        },
                        {
                            value: "In Transit",
                            text: "In Transit"
                        },
                        {
                            value : "Awaiting Acknowledgement",
                            text : "Awaiting Acknowledgement",
                        },
                        {
                            value : "Acknowledged",
                            text : "Acknowledged",
                        },
                        {
                            value : "Ready for pickup",
                            text : "Ready for pickup",
                        }
                    ]} 
                        
                    />
                </Grid>
                <Grid alignItems>
                    <SkbSearchTextField 
                        
                        className={classes.searchBar} 
                        onSearch={onSearchConsignment} 
                        placeholder="Consignment ID / Stock Code / Serial Number"
                    />
                </Grid>
                <Grid item style={{alignItems:"center"}}>
                    <SkbIcon icon={FilterListIcon} style={{fontSize:"1em",marginTop : "20px"}}/>
                    <SkbIcon icon={ArrowUpwardSharpIcon} style={{fontSize:"1em",marginTop : "20px"}}/>
                    <SkbSelect 
                        Title="Sort Order"
                        value={sortOrder}
                        items={[
                            {
                                value: "Create Date",
                                text: "Creation Date"
                            },{
                                value: "Last Modified Date",
                                text: "Last Modified Date"
                            },
                            {
                                value: "Task ID",
                                text: "Task ID"
                            },{
                                value: "Task Status",
                                text: "Task Status"
                            },
                        ]}

                    />
                </Grid>
            </Grid>
            {
                consignmentListError
                &&
                <Paper>
                    {JSON.stringify(consignmentListError)}    
                </Paper>
            }
            {
                filterError
                &&
                <Paper>
                    {JSON.stringify(filterError)}
                </Paper>
            }
            <div>
                <SearchConsignmentResults />
                {
                    (!searchByStockCodeResults || !searchBySerialNumberResults || !searchByConsignmentIdResults)
                    &&
                    (
                        filteredConsignments
                        ?
                        (
                            filteredConsignments.map((_item,i)=>(<SingleConsignmentTask _t={_item} key={i} />))
                        )
                        :
                        (
                            consignmentList
                            ? consignmentList.map((_item,i)=>(<SingleConsignmentTask _t={_item} key={i} />))
                            : <Typography variant="h6" align="center">Consignment List is empty</Typography>
                        )
                    )
                }
                <NewTask />
            </div>
            { ( fetchingConsignmentList || filtering ) && <Loading /> }
            { (searchingByStockCode || searchingBySerialNumber || searchingByConsignmentId) && <Loading /> }
        </Box>
    )
}

const mapState = state =>({
    taskList : selectConsignmentTaskList(state),
    loading : selectConsignmentTasksLoading(state),
    sortOrder : selectSortOrderValue(state),
    filterType : selectFilterTypeValue(state),
    filteredTaskList : selectFilteredTasks(state),

    consignmentList : selectConsignmentList(state),
    consignmentListError : selectConsignmentListError(state),
    fetchingConsignmentList : selectFetchingConsignmentList(state),

    filteredConsignments : selectFilteredConsignmentList(state),
    filtering : selectFetchingFilteredConsignmentList(state),
    filterError : selectFilteredConsignmentListError(state),

    searchingByStockCode : selectSearchingByStockCode(state),
    searchByStockCodeResults : selectSearchByStockCodeResults(state),
    searchByStockCodeError : selectSearchByStockCodeError(state),

    searchingBySerialNumber : selectSearchingBySerialNumber(state),
    searchBySerialNumberResults : selectSearchBySerialNumberResults(state),
    searchBySerialNumberError : selectSearchBySerialNumberError(state),

    searchingByConsignmentId : selectSearchingByConsignmentId(state),
    searchByConsignmentIdResults : selectSearchByConsignmentIdResults(state),
    searchByConsignmentIdError : selectSearchByConsignmentIdError(state),
});
const mapDispatch = dispatch => ({
    fetchConsignmentListStart : ()=> dispatch(fetchConsignmentListStart()),
    filterTasks : value => dispatch(filterTasksByStatus(value)),
    filter : status => dispatch(filterByStatusStart(status)),
    clearFilter : () => dispatch(clearFilterStatus()),
    
    searchByStockCode : phrase=>dispatch(searchByStockCodeStart(phrase)),
    searchBySerialNumber : phrase=>dispatch(searchBySerialNumberStart(phrase)),
    searchByConsignmentId : phrase => dispatch(searchByConsignmentIdStart(phrase)),

    clearConsignmentSearch : ()=>dispatch(clearSearchConsignmentData()),
})

export default connect(mapState,mapDispatch)(ConsignmentTasks);