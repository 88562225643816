import React, {useEffect} from 'react';
import '../PieChart.styles.css';
import './ChartByStatus.styles.css';
import {connect} from 'react-redux';
import {
    Pie,
    Chart,

} from 'react-chartjs-2';

import { Grid, Toolbar, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';


// import {
//     selectReturnsListModified,
//     selectStatusPoints,

// } from '../../../../../../Redux/selectors/ReturnsDashboard';

import * as  returnsDashboardListActions from '../../../../../../Redux/actions/returnsdashboard';


const ChartByStatus = ({pieChartRelStatusNameDataAr,pieChartStatusNameGroupedCountDataAr,setReturnListStatusActiveFilterData, setPieChartOnStatusRelatedDataSet})=> {
    
   
    pieChartRelStatusNameDataAr = pieChartRelStatusNameDataAr?pieChartRelStatusNameDataAr: [];
    pieChartStatusNameGroupedCountDataAr = pieChartStatusNameGroupedCountDataAr?pieChartStatusNameGroupedCountDataAr:[];

    const [windowSize, setWindowSize] = React.useState({height:window.innerHeight,width:window.innerWidth});

    let statusRelColorAr = [
        '#2a80b3',//Blue
        '#f16422',//- Orange 
        '#ffcd33',// - Yellow
        '#893674',// - Purple
        '#34a172',// - Green
        '#896836', //Brown
        '#C748F7', //Pink
        '#95F131', //Lime
        '#71FFDE', //Aqua
        '#4AC3FF', // Sky blue  
    ];

    const pieLegendLabelClick =   function(event,legendItem) {
       
        let filtSetAr = [];
        if(legendItem!==undefined && legendItem.text!==undefined && legendItem.text){            
            filtSetAr.push(legendItem.text);
            setReturnListStatusActiveFilterData(filtSetAr);
        }
       
    }

    const pieSegmentClick =   function(event,item) {
       
        const dataIndex = Array.isArray(item) && item.length>0 && item[0].element!==undefined && item[0].element.$context!==undefined && item[0].element.$context.dataIndex!==undefined? item[0].element.$context.dataIndex: null;
        let filtSetAr = [];
        if(dataIndex!==null){
            
            filtSetAr.push(pieChartRelStatusNameDataAr[dataIndex]);
            setReturnListStatusActiveFilterData(filtSetAr);
        }
        
        
    }

    const chartData = {
        labels : pieChartRelStatusNameDataAr,
        datasets : [
            {
                label : pieChartRelStatusNameDataAr,
                data : pieChartStatusNameGroupedCountDataAr,
                backgroundColor : statusRelColorAr,
                borderWidth : 2,
                borderColor : 'white'
            }
        ]
    }
    const options = {
        
        events: ['click'],
        onClick: pieSegmentClick,

        animations: {
            tension: {
              duration: 1000,
              easing: 'linear',
              from: 1,
              to: 0,
              loop: true
            }
        },
        responsive : true,
        plugins : {
            legend : {
                display : true,
                position : 'bottom',
                onClick: pieLegendLabelClick,
                labels: {
                    color: "#3A3A3A",
                    // font : {
                    //     size : 10,                       
                    //     weight : 'normal',
                    // },
                }
                
                
                
            },
            title : {
                text : 'Returns by Status',
                align : 'center',
                display : true,
                font : {
                    size : 22,
                    family  : 'Roboto',
                    weight : 'normal',
                },
                color : '#3A3A3A',
            },
            tooltip:{
                enabled : true,
                caretSize : 10,
            }
        },
        responsive: true,
        maintainAspectRatio: false
        
    }   
    
    useEffect(()=>{
        window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);
        return (()=>{
            // unset redux state data related to it
            setPieChartOnStatusRelatedDataSet({});
        });
    },[]);

    const handleResize = () => {
        //re-render
        setWindowSize({
          height: window.innerHeight,
          width:window.innerWidth
        });
         
      }
      

    return(
        // <div className="pie-chart " 
        // style={{transform:'scale(0.6)'}}
        
        // > 
        //     <Pie 
        //         id="chart-by-status"
        //         height={100}
        //         width={100}
        //         data={chartData}
        //         options={options}
        //     />
        // </div>

        <Grid container spacing={2} alignItems="center">

                        <Grid  item xs={12} sm={12} md={12} zeroMinWidth >                   
                            <Box ml={2} align="center" textAlign="left" height={(windowSize.height*0.4)+'px'}>
                            <Pie
                                align="center"
                                id="chart-by-status"
                                height={'200px'}
                                width={'200px'}
                                data={chartData}
                                options={options}
                            />
                            </Box>
                        </Grid>
                        </Grid>


    )
}

const mapState = state =>({
    pieChartRelStatusNameDataAr : state.returnsDashboardList.pieChartRelStatusNameDataAr,
    pieChartStatusNameGroupedCountDataAr : state.returnsDashboardList.pieChartStatusNameGroupedCountDataAr
   
   
});

const mapDispatchToProps  = (dispatch) =>{
    return {
        setReturnListStatusActiveFilterData: (list) => {
            dispatch(returnsDashboardListActions.setReturnListStatusActiveFilterData(list));
        },
        setPieChartOnStatusRelatedDataSet: (objParam) =>{
            dispatch(returnsDashboardListActions.setPieChartOnStatusRelatedDataSet(objParam));
        }
}

}


export default connect(
    mapState,
    mapDispatchToProps,
)(ChartByStatus);