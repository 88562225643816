import { SkbLogger} from '../../services';
import {Actions} from '../actions/syncConfig'


let initialStatus = {clusterResourceId:'', airResourceId:'', airPlaneModeInterval: 10,retriesResourceId:'',  attemptsErrorOccurs:3,
                     dMDResourceId:'', downloadMetadataD:3 , dADResourceId:'', downloadArtefactsD:3,isLoading:true,loadSyncFailed:false};

 export function syncConfig(state = initialStatus, action) {


    const getValueFromArrary = (arraryObject,keyName) => {
        var returnValue='';
        SkbLogger.logDebugInfo('getValueFromArrary in syncConfig',arraryObject,keyName)
            arraryObject.map((item, i) => {
                {
                    if (item.key === keyName) {
                        returnValue= parseInt(item.value)
                    }
                }
            })
            SkbLogger.logDebugInfo('getValueFromArrary in syncConfig return',returnValue)
            return returnValue;
    }

    const getResourceIdFromArrary = (arraryObject,keyName) => {   
        var returnValue='';
        SkbLogger.logDebugInfo('getResourceIdFromArrary in syncConfig',arraryObject,keyName)
            arraryObject.map((item, i) => {
                {
                    if (item.key === keyName) {
                        returnValue= item.resourceID
                    }
                }
            })
            SkbLogger.logDebugInfo('getResourceIdFromArrary in syncConfig return',returnValue)
            return returnValue;
    }

    switch (action.type) {
         case Actions.UPDATE_LOADING: 
         SkbLogger.logDebugInfo('from UPDATE_LOADING',action.payload)
            return { ...state, 
                isLoading : action.payload        
            }
        case Actions.UPDATE_FAILED: 
        SkbLogger.logDebugInfo('from UPDATE_FAILED',action.payload)
            return { ...state, 
                loadSyncFailed : action.payload        
            }
        case Actions.UPDATE_SINGLE_SYNCCONFIG:
        var temp= { ...state, 
            clusterResourceId : action.payload.clusterResourceId, 
            airResourceId : action.payload.key === "AirplaneModeInterval"? action.payload.resourceID:state.airResourceId,    
            airPlaneModeInterval : action.payload.key === "AirplaneModeInterval"? parseInt(action.payload.value):state.airPlaneModeInterval,   
            retriesResourceId: action.payload.key === "Retries"? action.payload.resourceID:state.retriesResourceId,  
            attemptsErrorOccurs : action.payload.key === "Retries"? parseInt(action.payload.value):state.attemptsErrorOccurs,      
            dMDResourceId :  action.payload.key === "DownloadMetadataInDays"? action.payload.resourceID:state.dMDResourceId,     
            downloadMetadataD :  action.payload.key === "DownloadMetadataInDays"? parseInt(action.payload.value):state.downloadMetadataD,         
            dADResourceId : action.payload.key === "DownloadPhotoInDays"? action.payload.resourceID:state.dADResourceId,     
            downloadArtefactsD : action.payload.key === "DownloadPhotoInDays"? parseInt(action.payload.value):state.downloadArtefactsD,     
            isLoading:false
        }
        return temp;           
        case Actions.UPDATE_SYNCCONFIG: 
        console.log('UPDATE_SYNCCONFIG',action.payload,new Date())
        var temp= { ...state, 
            clusterResourceId : action.clusertId, 
            airResourceId :getResourceIdFromArrary(action.payload,'AirplaneModeInterval'),    
            airPlaneModeInterval :getValueFromArrary(action.payload,'AirplaneModeInterval'),   
            retriesResourceId: getResourceIdFromArrary(action.payload, 'Retries'),
            attemptsErrorOccurs : getValueFromArrary(action.payload,    'Retries'),
            dMDResourceId : getResourceIdFromArrary(action.payload, 'DownloadMetadataInDays'),   
            downloadMetadataD : getValueFromArrary(action.payload, 'DownloadMetadataInDays'),    
            dADResourceId : getResourceIdFromArrary(action.payload,'DownloadPhotoInDays'),    
            downloadArtefactsD : getValueFromArrary(action.payload,'DownloadPhotoInDays'),
            isLoading:false
        }
        return temp;

        case Actions.REFRESH_FROM_LOCALDB: 
        console.log('REFRESH_FROM_LOCALDB',state,action.payload,new Date())
            var temp= { ...state, 
                clusterResourceId : action.clusterId, 
                airResourceId :getResourceIdFromArrary(action.payload,'AirplaneModeInterval'),    
                airPlaneModeInterval :getValueFromArrary(action.payload,'AirplaneModeInterval'),   
                retriesResourceId: getResourceIdFromArrary(action.payload, 'Retries'),
                attemptsErrorOccurs : getValueFromArrary(action.payload,    'Retries'),
                dMDResourceId : getResourceIdFromArrary(action.payload, 'DownloadMetadataInDays'),   
                downloadMetadataD : getValueFromArrary(action.payload, 'DownloadMetadataInDays'),    
                dADResourceId : getResourceIdFromArrary(action.payload,'DownloadPhotoInDays'),    
                downloadArtefactsD : getValueFromArrary(action.payload,'DownloadPhotoInDays'),
                isLoading:false
            }
            return temp 
        default:
            return state;
    }
} 
 