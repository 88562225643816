import React from 'react';
import './css/style.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';

import 'moment-timezone';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});


function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other} className="CustomScanBarPopup">
      {onClose ? (
        
        <Button onClick={onClose} 
          variant="contained"
          color="primary"
          fontSize="large"
          className={classes.button}
          startIcon={<CloseIcon />}
        >
     </Button>
   
        
      ) : null}
      

    </MuiDialogTitle>
  );
});

/*
 * component: The BarcodeImage component displays the image for previous scans.

* @param {array}  serialisedItems: the list of all scanned or picked serialised items
                      *       e.g.  [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134"
                    },...]
  * @param {function} OPTIONAL: retrievePhoto: an async function to retrieve the barcode photo for a scanned item (parameter scannedItem).
                    If not provided, the photo is assumed to be available in the property scannedItem.Photo.
 */
export default class BarcodeImage extends React.PureComponent
{
  constructor(props) {
    super(props);
    this.state={
      description: "",
      lat: "",
      lon: "",
      status: "",
      photoOfBarcode: "",
      photoResourceId:"",
      stockCode: "",
      serialNumber: "",
      dateScanned: "",
    }
    
  }
  async componentDidMount(){
  }



  async componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {

      var serialisedItems=this.props.serialisedItems;

      var scannedItem={...serialisedItems.find(i=>i.SerialNumber===this.props.serialNumber)};

      var photo;     
        
        if (scannedItem != undefined){

          if (!this.props.retrievePhoto)  //This parameter is optional.  
          {
            photo=scannedItem.Photo;  //This is the newer way, with the photo available already, and is the default.  
          }
          else 
          {
            photo= await this.props.retrievePhoto(scannedItem);  //Older way, or optionally at any time to retrieve the photo in a different way.  
          }

          this.setState({ 
            description: scannedItem.Description,
            lat: scannedItem.Latitude,
            lon: scannedItem.Longitude,
            status: scannedItem.ItemStatus,
            photoOfBarcode:photo,
            stockCode: scannedItem.StockCode,
            serialNumber: scannedItem.SerialNumber,
            dateScanned: scannedItem.dateStamp,
          })
         
    }
 
  }
}

  render() {
    return (
      <div >
          <Dialog
              fullScreen = {true}
              open={this.props.isOpen}
              onClose={this.props.closeDialog}
          >
     
                   <DialogTitle id="customized-dialog-title" onClose={this.props.closeDialog}></DialogTitle>

                    <div align = "center"  > 
                    <div className="BarCodeViewImage" >
                        {/* <h4 className="PopupHeading">Barcode Details</h4> */}
                        <h5 className="PopupSubHeading" id = "last_bc_image"><img src={this.state.photoOfBarcode}/> </h5>
                        <h5 className="PopupSubHeading" id = "last_bc_serialnumber">Barcode Serial #: {this.state.serialNumber} </h5>
                        <h5 className="PopupSubHeading" id = "last_bc_description">Description: {this.state.description}</h5>
                        <h5 className="PopupSubHeading" id = "last_bc_status">Status: {this.state.status}</h5>
                        <h5 className="PopupSubHeading" id = "last_bc_stockcode">Barcode Stock Code: {this.state.stockCode}</h5>
                        {/* <h5 className="PopupSubHeading" id = "last_bc_latitude">Barcode Latitude: {this.state.lat}</h5>
                        <h5 className="PopupSubHeading" id = "last_bc_longtitude">Barcode Longitude: {this.state.lon}</h5>
                        <h5 className="PopupSubHeading" id = "last_bc_date">Barcode Scanned Date: <Moment unix>{this.state.dateScanned}</Moment>  </h5> */}
                      </div> 
                    </div>
                     

          </Dialog>
          
        </div>
    );
  }
 
}




