import React from "react";
import PhoneIcon from '@material-ui/icons/Phone';
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


const ContractorRepresentativeStructure = (props)=>{

            const {contractorRepresentative,contractorRepresentativePhone,taskId} = props.dataContractorRepresentative;


            const [open, setOpen] = React.useState(false);
            const anchorRef = React.useRef(null);
            const [selectedIndex, setSelectedIndex] = React.useState(1);


            const handleMenuItemClick = (event, index) => {
                setSelectedIndex(index);
                setOpen(false);
            };

            const handleToggle = () => {
                setOpen((prevOpen) => !prevOpen);
            };

            const handleClose = (event) => {
                if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
                }

                setOpen(false);
            };
 

            return (
                <div>
                <span ref={anchorRef} onClick={handleToggle} style={{"cursor":"pointer"}} >
                    <SkbIcon  icon={PhoneIcon}/>
                    
                </span>
                <span>{contractorRepresentative}</span>
                
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12} sm={12} md={12}>
                 
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="contr-rep-phone-split-button-menu">
                                <MenuItem
                                  key={taskId}                              
                                  selected={1 === selectedIndex}
                                  onClick={(event) => handleMenuItemClick(event, 1)}
                                >                          
                                             <Link href={(contractorRepresentativePhone?"tel:"+contractorRepresentativePhone:contractorRepresentativePhone)}  variant="body2" >{contractorRepresentativePhone}</Link>  

                                </MenuItem>   
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
                </Grid>   

                </div>         
            
            );


};

export default ContractorRepresentativeStructure;