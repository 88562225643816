import React, { useEffect,useState, Suspense } from 'react';
import {connect,useDispatch, useSelector} from 'react-redux';
import {SkbSelect} from '../../../../skb_controls/components/SkbSelect';  
import SkbTable from '../../../../skb_controls/components/SkbTable';  
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import SkbButton from '../../../../skb_controls/components/SkbButton';

import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import Link from '@material-ui/core/Link';
// import { Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';

import Loading from '../../../Loading';

import {useSnackbar } from 'notistack';

import * as  returnsDashboardTaskInfoActions from '../../../../Redux/actions/returnsdashboard';
import * as misc from '../../../../utils/misc';


//import ContractorRepresentativePhoneEmailStructure  from "./ContractorRepresentativePhoneEmailStructure";

const classes = {
  root: {
    flexGrow:1
  },
  paper: {
    padding:20,
    textAlign: "center",
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);




export default function TaskItemAddPopup(props) {
  const [open, setOpen] = React.useState(false);
 

  const {taskitemaddpropdata} = props;
  const fromLocationCode = ( taskitemaddpropdata && taskitemaddpropdata.fromLocationCode ) ? taskitemaddpropdata.fromLocationCode: "";
 
  //console.log(" HEYY=>>dataRow=>>>"," ==fromLocationCode==>>",fromLocationCode," props=>>",props);  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar(); 

  const {stockItemDetailListToDisplay, stockItemDetailFromLocationLoadStatus, taskInfoConsignmentType, taskInfoItemListRendered, stockItemDetailListElementSelected } = useSelector((state) => ({
    //return state.returnsDashboardTaskInfo;
    "stockItemDetailListToDisplay": (state.returnsDashboardTaskInfoAddStockItem &&  state.returnsDashboardTaskInfoAddStockItem.stockItemDetailListToDisplay)? state.returnsDashboardTaskInfoAddStockItem.stockItemDetailListToDisplay:[],
     "stockItemDetailListElementSelected": (state.returnsDashboardTaskInfoAddStockItem &&  state.returnsDashboardTaskInfoAddStockItem.stockItemDetailListElementSelected)? state.returnsDashboardTaskInfoAddStockItem.stockItemDetailListElementSelected:[],
     "stockItemDetailFromLocationLoadStatus": (state.returnsDashboardTaskInfoAddStockItem && state.returnsDashboardTaskInfoAddStockItem.stockItemDetailFromLocationLoadStatus)? state.returnsDashboardTaskInfoAddStockItem.stockItemDetailFromLocationLoadStatus:false,
     "taskInfoConsignmentType":  (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo  && state.returnsDashboardTaskInfo.taskInfo.ConsignmentType)?(state.returnsDashboardTaskInfo.taskInfo.ConsignmentType):"",
     "taskInfoItemListRendered":  (state && state.returnsDashboardTaskInfo!==undefined && state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoItemList!==undefined && Array.isArray(state.returnsDashboardTaskInfo.taskInfoItemList))? state.returnsDashboardTaskInfo.taskInfoItemList: []
  }));

  
 
  const handleClickOpen = () => {

    setOpen(true);
     
      
    };
  const handleClose = () => {
      setOpen(false);
    };

 const dialogOnEnterFunc = async () =>{
      // call action of  task info 
     
  }

  const dialogOnEnteredFunc = async () =>{
    let paramObjectOfLoadStklst = {"paramObj":{"fromLocationCode":fromLocationCode}};   
    dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchTaskItemAddStockList(paramObjectOfLoadStklst));


  } 

  const dialogOnExitedTrackFunc = () =>{
    // unset these redux state data when add stock popup gets closed
    dispatch(returnsDashboardTaskInfoActions.setTaskItemListAddStockItemListElementSelected([])); 
    dispatch(returnsDashboardTaskInfoActions.setTaskItemListAddStockItemList(null));                  
    dispatch(returnsDashboardTaskInfoActions.setTaskItemListAddStockItemListToDisplay([]));      
  }

  const handleAddItem = (evt) => {
    evt.preventDefault();

    let updatedDataWiyhNewdataAdded = [...stockItemDetailListElementSelected,...taskInfoItemListRendered];
    if(stockItemDetailListElementSelected.length===0){
      enqueueSnackbar("Please note that no stock items have been added.", { variant: 'warning' });
      return false;
    }
    else{

      if(stockItemDetailListElementSelected.length===1){
        
        enqueueSnackbar(`Successfully added ${stockItemDetailListElementSelected[0].qtyFaultyQtyGood} ${stockItemDetailListElementSelected[0].unitOfMeasurement} of ${stockItemDetailListElementSelected[0].stockDescription}`, { variant: 'success' });        
      }
      else {
        enqueueSnackbar(`Successfully added ${stockItemDetailListElementSelected.length} stock items to the stock return task.`, { variant: 'success' });     
      }

      dispatch(returnsDashboardTaskInfoActions.setTaskItemListWihItemsSelectedFromAddStockItemList(updatedDataWiyhNewdataAdded));
      dispatch(returnsDashboardTaskInfoActions.setTaskItemListAddStockItemListElementSelected([]));  
      handleClose();
    }  
    
    

  }


  const handleEditChange = () =>{

  }

  const handleDeleteAddStockList = () =>{
  }

  const handleSelectAddStockList = (rows, currentRow) =>{

    let opr='being selected';
    if(!currentRow.tableData.checked) opr='being de-selected';    
   
    
    dispatch(returnsDashboardTaskInfoActions.setTaskItemListAddStockItemListElementSelected(rows));    

  } 

  const handleChangeGood = (e,row,changedValue) =>{
    
    // to update record
    const stockCategoryCode = row && row.stockCategoryCode!==undefined?row.stockCategoryCode:"";
    const stockItemDetailListToDisplayCpy = [...stockItemDetailListToDisplay];
    

    let stockItemDetailListToDisplayUpdAr = [];   

    stockItemDetailListToDisplayCpy.map((data,cntr)=>{

        if(data.stockCategoryCode===stockCategoryCode){

          if(changedValue>=0){
            data.qtyGood = parseInt(changedValue);
            data.qtyFaultyQtyGood = parseInt(changedValue) + parseInt(data.qtyFaulty);  
          }
          else{
            data.qtyGood = 0;
            data.qtyFaultyQtyGood =  parseInt(data.qtyGood) + parseInt(data.qtyFaulty);    
          }
                 
        }

        stockItemDetailListToDisplayUpdAr.push(data);        

      });

      if(changedValue < 0){
         if(e && e.currentTarget && e.currentTarget.value!==undefined){
            e.currentTarget.value = 0;
         }  
      }
      else{
        dispatch(returnsDashboardTaskInfoActions.updateTaskItemListAddStockItemList(stockItemDetailListToDisplayUpdAr));
      }       

  }  

  const handleChangeFaulty = (e,row,changedValue) =>{
    
    // to update record
    const stockCategoryCode = row && row.stockCategoryCode!==undefined?row.stockCategoryCode:"";
    const stockItemDetailListToDisplayCpy = [...stockItemDetailListToDisplay];
    

    let stockItemDetailListToDisplayUpdAr = [];   

    stockItemDetailListToDisplayCpy.map((data,cntr)=>{

        if(data.stockCategoryCode===stockCategoryCode){

          if(changedValue>=0){
            data.qtyFaulty = parseInt(changedValue);
            data.qtyFaultyQtyGood = parseInt(changedValue) + parseInt(data.qtyGood);  
          }
          else{
            data.qtyFaulty = 0;
            data.qtyFaultyQtyGood =  parseInt(data.qtyGood) + parseInt(data.qtyFaulty);    
          }
                 
        }

        stockItemDetailListToDisplayUpdAr.push(data);        

      });

      if(changedValue < 0){
         if(e && e.currentTarget && e.currentTarget.value!==undefined){
            e.currentTarget.value = 0;
         }  
      }
      else{
        dispatch(returnsDashboardTaskInfoActions.updateTaskItemListAddStockItemList(stockItemDetailListToDisplayUpdAr));
      }       

  }  


  // good qty column will  not be editable
  // Return Faulty Stock 
  let taskInfoItemsToAddColumns = taskInfoConsignmentType==="Return Faulty Stock" ? [     
    { title: 'Stock Category Code', field: 'stockCategoryCode', type: 'hidden-id'},
    { title: 'Description', field: 'stockDescription', type: 'read-only-long-text'},
    { title: 'Program', field: 'clientName', type: 'read-only-long-text' },
    { title: 'UOM', field: 'unitOfMeasurement', type: 'read-only-long-text' },
    // { title: 'Good', field: 'qtyGood', type: 'read-only-long-text' },
    { title: 'Faulty', field: 'qtyFaulty', type: 'editable-number', onChange:handleChangeFaulty }
  ] : [     
    { title: 'Stock Category Code', field: 'stockCategoryCode', type: 'hidden-id'},
    { title: 'Description', field: 'stockDescription', type: 'read-only-long-text'},
    { title: 'Program', field: 'clientName', type: 'read-only-long-text' },
    { title: 'UOM', field: 'unitOfMeasurement', type: 'read-only-long-text' },
    { title: 'Good', field: 'qtyGood', type: 'editable-number', onChange:handleChangeGood },
    { title: 'Faulty', field: 'qtyFaulty', type: 'editable-number', onChange:handleChangeFaulty }
  ];  
  
  const taskInfoItemsToAddActions=[
                      {type:'delete',onClick:handleDeleteAddStockList},
                      {type:'select',onClick:handleSelectAddStockList},
                     ]; 

  const taskInfoItemsToAddFilterConfig =  {visible:false,
    individualFiltering:true, 
    individualFilteringSettings:[
        {column:"stockDescription",filteringType:"text"},
        {column:"clientName",filteringType:"text"},
        {column:"unitOfMeasurement",filteringType:"disabled"},
        {column:"qtyFaultyQtyGood",filteringType:"disabled"},
        {column:"qtyGood",filteringType:"disabled"},
        {column:"qtyFaulty",filteringType:"disabled"},
    ]
      };
    


  useEffect(()=>{
    
  
    return (()=>{
     

  });
},[]); 



  return (
    <div>    

      <SkbButton id={`btn-for-taskinfoadditembtnpopup-${fromLocationCode}`} primary size="small" text="ADD"  onClick={handleClickOpen}  />
      <Dialog fullWidth={true} maxWidth={"md"} onEnter={dialogOnEnterFunc}  onEntered={dialogOnEnteredFunc} onExited={dialogOnExitedTrackFunc} aria-labelledby="customized-dialog-title" open={open}>
        {open && (<> 
          <DialogTitle id="customized-dialog-title" onClose={handleClose}  >
        {/* <SkbIcon  icon={DashboardIcon}/>  */} 
        <Box  textAlign="center" >Add Stock  </Box>
        </DialogTitle>
        <DialogContent dividers={true} >
         
          <div className={classes.root}  >   
             <Box mt={2} textAlign="left"  > 
              {taskInfoConsignmentType=="Return Faulty Stock"?"Select stock to add to the faulty return task":"Select stock to add to the return task"} 
             </Box>         

            <Box mt={2}  >
                { stockItemDetailFromLocationLoadStatus && (<Loading />) }
                <Paper className={classes.paper} variant="outlined" >
                  {stockItemDetailListToDisplay.length===0 && (

                        <SkbTable 
                        id={"taskInfoItemsToAddTabId"+fromLocationCode}
                        columns={taskInfoItemsToAddColumns} 
                        dataSource={stockItemDetailListToDisplay} 
                        actions={taskInfoItemsToAddActions} 
                        filter={taskInfoItemsToAddFilterConfig} 
                        options={{
                          emptyDataSourceMessage:'There are no items available to add!',                                          

                        }
                        }



                        />
                  ) }

                   {stockItemDetailListToDisplay.length>0 && (

                        <SkbTable 
                        id={"taskInfoItemsToAddTabId"+fromLocationCode}
                        columns={taskInfoItemsToAddColumns} 
                        dataSource={stockItemDetailListToDisplay} 
                        actions={taskInfoItemsToAddActions} 
                        filter={taskInfoItemsToAddFilterConfig} 
                        options={{
                          emptyDataSourceMessage:'There are no items available to add!',                                          

                        }
                        }



                        />
                  ) }
                
                 
                </Paper>
                            
            </Box>

                
          
          </div>
         
        </DialogContent>
        <DialogActions>  

          {stockItemDetailListToDisplay && stockItemDetailListToDisplay.length>0 && (
                <>
                  <Box  textAlign="right" >
                    <SkbButton id="task-info-update"  primary size="medium" text="Cancel" onClick={handleClose}  />
                  </Box>
                  <Box  textAlign="right" >
                    <SkbButton id="task-info-schedule" primary size="medium" text="Add" onClick={handleAddItem}  />
                  </Box>
                </>

          )}           
                            
         
        </DialogActions>
     
        
        
        </>


        )}
       
      </Dialog>
     

    </div>
  );
}


