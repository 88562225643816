import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Box, Typography, Link } from "@material-ui/core";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
    selectFilename,
    selectRequestId,
    selectOutcomes,
    selectLoadingOutcomes,
    selectOutcomesError,
    selectCSVJSONDataTable,
} from "../../../../../Redux/ThreePLImportation/selectors";
import { fetchOutcomesStart } from "../../../../../Redux/ThreePLImportation/actions";
import SkbTable from "../../../../../skb_controls/components/SkbTable";
import SkbButton from "../../../../../skb_controls/components/SkbButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SkbIcon from "../../../../../skb_controls/components/SkbIcon";
import RefreshIcon from "@material-ui/icons/Refresh";
import Loading from "../../../../Loading";
import { useSnackbar } from "notistack";
import * as utils from "../../../../../utils/misc";

const useStyles = makeStyles((theme) => ({
    file_name: {
        backgroundColor: "#d0e562",
        padding: "0.5em 1em",
        borderRadius: "5px",
        margin: "0.2em auto",

        [theme.breakpoints.down(600)]: {
            textAlign: "center",
        },
    },
    outcome_flex: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        [theme.breakpoints.down(600)]: {
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    buttonLook: {
        margin: 0,
        padding: 0,
        border: "none",
        textDecoration: "none",
        borderRadius: "0px",
        textTransform: "initial",
        color: "#000",
        "&:hover": {
            border: "none",
        },
    },
    refreshButton: {
        backgroundColor: "transparent",
        border: "none",
        textTransform: "initial",
        boxShadow: "none",
        textDecoration: "underline",
        color: "#4ac3ff",
        "&:hover": {
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },
}));

const LoadThreePLImportationOutcomes = ({
    // selectors
    fileName,
    loading,
    outcomes,
    error,
    requestId,
    csvJSONDataTable,

    // actions
    fetchOutcomes,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [outComeList, setOutComeList] = useState([]);

    useEffect(() => {
        fetchOutcomes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let outcomeList = [];
        if (outcomes !== null && outcomes.length >= 1) {
            outcomes.map((item) => {
                let status = "";
                let fileName = "";
                if (item.RequestFilePath !== null && item.RequestFilePath !== "") {
                    const fileNameArr = item.RequestFilePath.split("/");
                    fileName = fileNameArr[1];
                }
                if (item.RequestProgress === 0) {
                    status = "Awaiting Processing";
                } else if (item.RequestProgress > 0 && item.RequestProgress < 100) {
                    status = "Processing";
                } else if (item.RequestProgress === 100) {
                    status = "Completed";
                }
                const obj = {
                    RequestId: item.RequestId,
                    RequestProgress: item.RequestProgress,
                    RequestFilePath: item.RequestFilePath,
                    // ResultFilePath: item.ResultFilePath,
                    SuccessCount: item.SuccessCount,
                    TotalCount: item.ErrorCount + item.SuccessCount,
                    Status: status,
                    FileName: fileName,
                    UploadedDateTime: moment(item.UploadedDateTime).format("DD/MM/YYYY HH:mm"),
                    CompletedDateTime: item.RequestProgress === 100 ? moment(item.LastUpdatedDateTime).format("DD/MM/YYYY HH:mm") : null,
                };
                outcomeList.push(obj);
            });
        }
        setOutComeList(outcomeList);
    }, [outcomes]);

    useEffect(() => {
        if (error) enqueueSnackbar(error?.message, { variant: "error" });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const refresh = () => {
        fetchOutcomes();
    };

    const downloadFile = (e, id, row) => {
        const fileUrl = utils.constructBlobUrl(row.RequestFilePath);
        startDownload(fileUrl);
    };

    const downloadResultFile = (data) => {
        const fileUrl = utils.constructBlobUrl(data.ResultFilePath);
        startDownload(fileUrl);
    };

    const startDownload = (fileUrl) => {
        const link = document.createElement("a");
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const uploadFileColumn = [
        { title: "Hidden Col", field: "RequestId", type: "hidden-id" },
        { title: "File Name", field: "FileName", type: "link", onClickLink: downloadFile },
        // { title: 'File Contains', field: 'IsSerialised', type: 'read-only-short-text'},
        // { title: 'Process Items', field: 'ProcessingType', type: 'read-only-short-text'},
        { title: "Uploaded", field: "UploadedDateTime", type: "read-only-short-text" },
        { title: "Completed", field: "LastUpdatedDateTime", type: "read-only-short-text" },

        { title: "Status", field: "Status", type: "read-only-short-text" },
        {
            title: "Results",
            field: "ErrorCount",
            type: "custom",
            render: (rowData) => (
                <div className="inherit-class">
                    <Typography>
                        {rowData.SuccessCount}/{rowData.TotalCount}
                    </Typography>
                    {/* {rowData.Status === 'Completed' ? (
                    <SkbButton className={classes.buttonLook} onClick={() => downloadResultFile(rowData)} text={`${rowData.ErrorCount} (${rowData.SuccessCount})`} endIcon={<InsertDriveFileIcon style={{color: '#4ac3ff'}} />} outlined />
                    ) : ''} */}
                </div>
            ),
        },
    ];
    return (
        <Box style={{ width: "100%" }}>
            <Grid container className="results" id="return_stock_results">
                <Grid item xs={12} id="taskdetail_title" className={classes.title}>
                    <Typography variant="h5">3PL Importation result</Typography>
                    <Typography>Please see the 3PL Importation results shown below</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid className="stockContain">
                <SkbButton
                    text="Refresh"
                    className={classes.refreshButton}
                    onClick={refresh}
                    style={{ marginBottom: "20px" }}
                    startIcon={<RefreshIcon style={{ color: "#4ac3ff" }} outlined />}
                />
                <SkbTable
                    columns={uploadFileColumn}
                    dataSource={outComeList}
                    id="3pl-importation-table"
                    options={{
                        // selectedIdValues:SelectedMethod,
                        emptyDataSourceMessage: "There are no items available, please upload!",
                    }}
                />
                {loading && <Loading />}
            </Grid>
        </Box>
    );
};

const mapState = (state) => ({
    fileName: selectFilename(state),

    requestId: selectRequestId(state),

    outcomes: selectOutcomes(state),
    loading: selectLoadingOutcomes(state),
    error: selectOutcomesError(state),
    csvJSONDataTable: selectCSVJSONDataTable(state),
});
const mapDispatch = (dispatch) => ({
    fetchOutcomes: () => dispatch(fetchOutcomesStart()),
});

export default connect(mapState, mapDispatch)(LoadThreePLImportationOutcomes);
