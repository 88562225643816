import uuid from 'react-uuid'
import * as moment from 'moment-timezone';

import { crypts } from './crypts'
import { format } from 'date-fns';
export const newUUID = () => {
    let uuId = uuid().replace(/-/ig, '')
    return uuId;
}


var _userEmail = "";

export const getCurrentUserEmail = () => {
    if (!_userEmail) _userEmail = crypts.decrypt(localStorage.getItem('auth_user'))
    return _userEmail;
}

var _userGeoLocation = {
    Latitude: null,
    Longitude: null,
}

//get stock Location object
export const getTaskLocationObject = (task, subTask) => {
    return {
        "Task ID": task.TaskId,
        "Company ID": task.CompanyId,
        "Company Name": task.CompanyName,
        "Task Location": subTask.Address,
    }
}
//gt log entry LocationObject
export const getLocationObject = () => _userGeoLocation;
//gt log entry NetworkObject
export const getNetworkObject = () => {
    return {
        Connectivity: navigator.onLine ? "Online" : "Offline"
    };
}

var _userObject = null;
export const getUserObject = () => { //log entry UserObject
    if (!_userObject) {
        let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
        skbUser = JSON.parse(skbUser);

        let authuser = crypts.decrypt(localStorage.getItem('auth_user'));
        authuser = JSON.parse(authuser)
        var identityType = ''
        if (/^auth0\|/ig.test(authuser.sub)) {
            identityType = 'Non-Federated';
        } else if (/^google-oauth2\|/ig.test(authuser.sub)) {
            identityType = 'Google';
        } else {
            identityType = 'Microsoft-Live';
        }
        _userObject = {
            "Full Name": skbUser.name,
            "Email Address": skbUser.email,
            "Identity Type": identityType,
            "Address 1": skbUser.company.streetAddress,
            "Phone Number": skbUser.phone
        }
    }

    return _userObject;
}

export const buildStockObjectForLog=(stockType, stockCode, stockDescription)=>
{
    return {
    stockType: stockType,
    stockCode: stockCode,
    description: stockDescription
  };
}

export const checkLatLong = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            _userGeoLocation.Latitude = position.coords.latitude;
            _userGeoLocation.Longitude = position.coords.longitude;
        }
            , error => {

            });
    }
}


export const TaskLogInfo = {
    moduleName: "Tasks",
    category:{
        taskList: "Task List",
        preview: "Preview",
        serialisedStock: "Serialised Stock",
        nonSerialisedStock: "Non-serialised Stock",
        review: "Review",
        signoff: "Signoff",
        scanningList: "Scanning List",
        scanPage: "Scan Page",
    }
}

export const LogResult = {
    success: "Success",
    fail: "Fail",
}

export const StringFormat = {
    TaskDateFormat: "DD/MM/YYYY hh:mm A",
    ServerDateFormat: "YYYY-MM-DDTHH:mm:ss.SSS",
}

export function  datetimeStringInUTCToLocalTime(dateString, format){
    // return moment.tz(dateString, format, "Australia/Melbourne").tz(moment.tz.guess()).toDate();
    return moment.utc(dateString, format).tz(moment.tz.guess()).toDate();
}

export function dateAndEtaToOneString(dateString,eta){

    var arr= dateString.split("-");
    var mo =  parseInt(arr[1]) ;
    var dy = parseInt(arr[2]) ;
    var yr = parseInt(arr[0]) ; 
    var dueDate =( dy < 10 ? "0" : "" )+dy+"/"+(mo < 10 ? "0" : "" ) + mo+"/"+yr;
    if(eta){
        return dueDate+" "+eta
    }else{
        return dueDate
    }
    
}

export function datetimeStringInUTCToLocaldatetimeString(dateString, format, dstFormat){
    // return moment.tz(dateString, format, "Australia/Melbourne").tz(moment.tz.guess()).format(format);
    return moment.utc(dateString, format).tz(moment.tz.guess()).format(dstFormat || format);

}

export function currentTimeToUTCTimeInString(format){
    // return moment().tz("Australia/Melbourne").format(format);
    return moment.utc().format(format);
} 

var _isMobileBrowser;
export function isMobileBrowser(){
    //take account of performance, only check mobile browser once
    if(_isMobileBrowser !== undefined) return _isMobileBrowser;

    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        _isMobileBrowser=navigator.userAgent.match(toMatchItem);
        return _isMobileBrowser;
    });


}

//iPhone 5 and 6 running iOS 12 have a problem with scrolling particularly on the serialised items page.  
//There is the outer page, and the grid list within the stepper.  There is a problem (vertical) scrolling that inner section
//when the section is wide enough to need horizontal scrolling.
//This function will detect iOS 12 devices and it can be combined with screen width checking(<=375px) to approximate detection of these devices.
var _isProblemDevice;
export function isProblemDevice(){
    //take account of performance, only check once
    if(_isProblemDevice !== undefined) return _isProblemDevice;

    const toMatch = [
        /iPhone OS 12/i
    ];

    return toMatch.some((toMatchItem) => {
        _isProblemDevice=navigator.userAgent.match(toMatchItem);
        return _isProblemDevice;
    });
}

export function constructBlobUrl(filePath){
    const sasToken = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
     const blobBaseUrl=`https://${process.env.REACT_APP_AZURE_STORAGE_NAME}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINER_NAME}`;
     var blobUrl=blobBaseUrl+'/'+filePath+sasToken;
     return blobUrl;

}