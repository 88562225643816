import React from 'react';
import Typography from '@material-ui/core/Typography';

import InputIcon from '@material-ui/icons/Input';
import SkbIcon from '../../skb_controls/components/SkbIcon';
import SkbTabMenu from '../../skb_controls/navigation/SkbTabMenu';
import CountryVariables from './managevariable_components/CountryVariables'
import EmployeesVariables from './managevariable_components/EmployeesVariables'
import DivisionVariables from './managevariable_components/DivisionVariables'
import { Grid } from '@material-ui/core';

export function ManageVariables() {

    return (
        <div id="managewariable">
            <SkbTabMenu header={<Typography id="form-page-heading" variant="h1"><Grid container><SkbIcon icon={InputIcon} />Manage Variables</Grid></Typography>}>
                <skbtab label="Countries">
                    <CountryVariables />
                </skbtab>
                <skbtab label="Employees">
                    <EmployeesVariables />
                </skbtab>
                <skbtab label="Clients">
                </skbtab>
                <skbtab label="Divisions/Programs">
                    <DivisionVariables />

                </skbtab>

            </SkbTabMenu>
        </div>
    );
}
