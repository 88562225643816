import { all } from 'redux-saga/effects';
import {
    fetchConsignmentListWatcher,
    fetchConsignmentDetailsStartWatcher,
    fetchConsignmentInfoStartWatcher,
    filterConsignmentByStatusStartWatcher,
    overrideConsignmentDetailsActualStartWatcher,
    searchByStockCodeStartWatcher,
    searchBySerialNumberStartWatcher,
    searchByConsignmentIdStartWatcher,
    updateConsignmentStatusStartWatcher,

} from './ConsignmentTasks/sagas';

import {
    readCSVStartWatcher,
    threePLImportationStartWatcher,
    fetchOutcomesStartWatcher,
    fetchLocationsHandlerWatcher,

} from './ThreePLImportation/sagas';

function* rootSaga() {
    yield all([
        fetchConsignmentListWatcher(),
        fetchConsignmentDetailsStartWatcher(),
        fetchConsignmentInfoStartWatcher(),
        filterConsignmentByStatusStartWatcher(),
        overrideConsignmentDetailsActualStartWatcher(),
        searchByStockCodeStartWatcher(),
        searchBySerialNumberStartWatcher(),
        searchByConsignmentIdStartWatcher(),
        updateConsignmentStatusStartWatcher(),

        readCSVStartWatcher(),
        threePLImportationStartWatcher(),
        fetchOutcomesStartWatcher(),
        fetchLocationsHandlerWatcher(),
    ])
}

export default rootSaga;