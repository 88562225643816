import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { SkbLogger } from '../../../../services';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import InputIcon from '@material-ui/icons/Input';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import clsx from 'clsx';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as misc from '../../../../utils/misc';
import TaskDetails from './TaskDetails';



import * as taskActions from '../../../../Redux/actions/tasks';
import Tasks from './Tasks';
const taskConfig = require('../taskConfig.json');
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(0),
        '&>div.MuiGrid-item': {
            alignSelf: 'center',
        }
    },

    taskheader: {

    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end',
    },


    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },

    cardContentRoot: {
        display: "flex",
        alignItems: "center"

    },
    cardContentDetails: {
        flex: '1 0 auto',
    },
    cardContentMedia: {

        width: 150,
        height: 150,
        margin: theme.spacing(1),
        borderRadius: "2px",
        flexGrow: 0,
        [theme.breakpoints.down("xs")]: {
            width: 50,
            height: 50,
        }
    },
    cardContentContent: {
        flexGrow: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),

        }
    },

    cardExpander: {
        display: "flex",
        alignItems: "center",
        // '& button': {
        //     paddingTop: 0
        // }
    },

    headerRoot: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },

    taskStatus: {
        textAlign: "right",
        marginTop: theme.spacing(-3),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(0),
        }
    },

    label: {
        display: 'inline-block',
        width: 60,
    },

    notstarted: {
        color: theme.palette.error.main,
    },
    inprogress: {
        color: theme.palette.warning.main,
    },
    done: {
        color: theme.palette.success.main
    },

    indicator: {
        flexBasis: 0,
        display: "inline-block",
        marginRight: theme.spacing(0.5),
    }
}));

const ButtonText = {
    Open: "Open",
    Reopen: "Reopen",
    Start: "Start"
}

const SubTaskStatus = ({ Status }) => {
    const classes = useStyles();
    const statusStyle = clsx({
        [classes.notstarted]: Status === taskActions.Status.NotStarted,
        [classes.inprogress]: Status === taskActions.Status.InProgress,
        [classes.done]: Status === taskActions.Status.Done

    });

    return (
        <Grid container className={statusStyle} justify="flex-start" alignItems="center" direction="row">
            <Grid item xs={6} className={classes.indicator}>
                <RadioButtonUncheckedIcon />
            </Grid>
            <Grid item xs={6}>
                {Status}
            </Grid>
        </Grid>
    )
}

export function SubTask(props) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openDetailForm, setOpenDetailForm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = useState("");
    const [confirmTitle, setConfirmTitle] = useState("");
    //const [okButtonIsFirst, setOkButtonIsFirst] = useState(true);

    const { TaskData, User, Task, lockTask, Tasks, updateTaskStatusByButtonTextAndChangeTask, refreshCurrentTaskStatusFromSync, changeTask } = props;
    const taskStartTime = misc.datetimeStringInUTCToLocalTime(Task.StartTime, misc.StringFormat.ServerDateFormat);
    const taskEndTime = misc.datetimeStringInUTCToLocaldatetimeString(Task.EndTime, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat);
    
    const isTimerEarilerThanTaskStartTime =(new Date()) < taskStartTime; 
    // SkbLogger.logDebugInfo('tasks', lockTask);
    const getButtonText = () => {
        switch (TaskData.TaskStatus.Status) {
            case taskActions.Status.InProgress:
                return ButtonText.Open;
            case taskActions.Status.Done:
                return ButtonText.Reopen;


            default:
                return ButtonText.Start;
        }
    }

    // open task details and udpate task status
    const openDetail = async () => {
        //changeTask(TaskData, Task); //change focus of currenttask and currentsubtask
        //check current task status
        let taskInfo = await taskActions.getTaskStatusAndLockedbyFromSync(TaskData.resourceID);
       
        //if task status is InProgress, it will only allow the contractor started the task can open task details
        if (taskInfo.status !== taskActions.Status.InProgress || taskInfo.lockedBy === User.id || taskInfo.lockedBy === 0) {
            updateTaskStatusByButtonTextAndChangeTask(Tasks, TaskData, buttonText, Task);
            lockTask(Tasks, { ...TaskData.LockedBy, LockedBy: User.id });
            SkbLogger.logDebugInfo("Task Resource ID", TaskData.resourceID);

            if (buttonText === ButtonText.Start) {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'user open task details', misc.LogResult.success,
                    `{userObject} has started stocktake task for {stockLocationObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });

                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'Task Status changed', misc.LogResult.success,
                    `{stockLocationObject} stocktake task status is changed from "Not Started " to "In Progress".`
                    , {
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                    });
            } else if (buttonText === ButtonText.Open) {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'user open task details', misc.LogResult.success,
                    `{userObject} resumes stocktake task for {stockLocationObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });
            } else {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'user open task details', misc.LogResult.success,
                    `{userObject} re-opened stocktake task for {stockLocationObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });

                    SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'Task Status changed', misc.LogResult.success,
                    `{stockLocationObject} stocktake task status is changed from "Done " to "In Progress".`
                    , {
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                    });
            }

            //history.push('/taskdetails');
            setOpenDetailForm(true);
        } else {//current user is not allowed to open the task
            //refresh store start
            refreshCurrentTaskStatusFromSync(TaskData.resourceID);
            //show alert message
            enqueueSnackbar("A stocktake has already begun at this location. If you believe this is in error, please Call for Assistance.", {
                variant: 'error'
            });

            if (buttonText === ButtonText.Start) {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'user open task details', misc.LogResult.fail,
                    `{userObject} failed to start stocktake task for {stockLocationObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });
            } else if (buttonText === ButtonText.Open) {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'user open task details', misc.LogResult.fail,
                    `{userObject} failed to resume stocktake task for {stockLocationObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });
            } else {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.taskList, 'user open task details', misc.LogResult.fail,
                    `{userObject} failed to re-open stocktake task for {stockLocationObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(Task, TaskData),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });
            }
        }
    }

    const [buttonText, setButtonText] = useState(getButtonText());
    const btnClick = async () => {

        let taskInfo = await taskActions.getTaskStatusAndLockedbyFromSync(TaskData.resourceID);
        if(taskInfo.status != TaskData.TaskStatus.Status){
            enqueueSnackbar("Task has been changed by others.  Please refresh your task list first.", {
                variant: 'error'
            });
            return;
        }

        let steps = await taskActions.loadTaskStepsFromSync(TaskData.resourceID);//load task steps first
        changeTask(TaskData, Task);
        const taskSetting = taskConfig[Task.TaskType];
        if(steps.length !== taskSetting.TaskDetails.Steps.length) {

            enqueueSnackbar("Task steps have not been loaded completely!", {
                variant: 'error'
            });
            return;
        }

        if (!navigator.onLine || buttonText === ButtonText.Reopen) {
            if (buttonText === ButtonText.Reopen) {
                setConfirmMsg(`Please remember to finish the task before ${taskEndTime}. Are you sure you wish to reopen it?`);
                setConfirmTitle("Reopen Task?");
               // setOkButtonIsFirst(false);

            } else {
                setConfirmMsg("You are currently offline. Please note that if anyone else has started a stocktake at this location you may lose your work. Do you wish to proceed?");
                setConfirmTitle("Proceed Offline?");
            }



            setOpenConfirm(true);

            return;
        }
        else {
            openDetail();
        }


    }

    useEffect(() => {
        setButtonText(getButtonText());
        SkbLogger.logDebugInfo("change button text", buttonText)

        //loadTasks();
    }, [TaskData.TaskStatus.Status]);

    useEffect(() => {
       if(TaskData.Steps && TaskData.Steps.length>0 && openDetailForm){
        const taskSetting = taskConfig[Task.TaskType];
        if(taskSetting.TaskDetails.Steps.length == TaskData.Steps.length) //task details page can open only the steps has compled loaded (match with the setting)
            history.push('/taskdetails');
       }

        //loadTasks();
    }, [TaskData.Steps, openDetailForm]);

    return (
        <div>
            <Grid container className={classes.root} id={TaskData.resourceID}>
                <Grid item xs={3} sm={4} >
                    <SubTaskStatus Status={TaskData.TaskStatus.Status} User={User} />
                </Grid>
                <Grid item xs={3} sm={2}>
                    {/* <SkbButton size="small" text={buttonText} primary id={`btn_${TaskData.resourceID}`} disabled={(TaskData.LockedBy && TaskData.LockedBy.LockedBy !== 0 && User.id !== TaskData.LockedBy.LockedBy && TaskData.TaskStatus.Status !== taskActions.Status.NotStarted) ||isTimerEarilerThanTaskStartTime } onClick={btnClick} /> */}
                    <SkbButton size="small" text={buttonText} primary id={`btn_${TaskData.resourceID}`} disabled={isTimerEarilerThanTaskStartTime } onClick={btnClick} />
                </Grid>
                <Grid item xs={6} sm={6}>
                    {TaskData.Address}
                </Grid>
            </Grid>

            <SkbConfirmDialog id="confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm} okHandler={() => openDetail()} 
             okLabel="Yes" cancelLabel="No" okButtonIsFirst={true} okButtonIsHighlighted={true} okButtonIsFocussed={true}/>
        </div>
    );
}

// const calTaskStatusBySubTaskStatus = (task) => {

//     if (task.SubTasks.every(s => s.TaskStatus.Status === taskActions.Status.NotStarted))
//         return taskActions.Status.NotStarted;
//     if (task.SubTasks.every(s => s.TaskStatus.Status === taskActions.Status.Done))
//         return taskActions.Status.Done;
//     return taskActions.Status.InProgress;
// };

const mapStateToProps = (state) => ({
    Tasks: state.tasks,
    User: state.auth.User,
});


const mapDispatchToProps = (dispatch) => {
    return {
        lockTask: (tasks, user) => {
            dispatch(taskActions.updateLockedby(tasks, { ...user, }));
        },
        refreshCurrentTaskStatusFromSync: (taskResourceId) => {
            dispatch(taskActions.refreshCurrentTaskStatusFromSync(taskResourceId));
        },
        updateTaskStatusByButtonTextAndChangeTask: (tasks, subTask, btnText, task) => {
            //DO NOT TO change task status until user make any change on sub tasks
            //if button is "Start" or "Reopen" => "In Progress"
            // dispatch(taskActions.changeCurrentTask(task.resourceID)); //do it in changeTask
            
            // dispatch(taskActions.changeCurrentSubTask(subTask.resourceID));
            
            if (btnText === ButtonText.Start || btnText === ButtonText.Reopen) {
                //SkbLogger.logDebugInfo("button text", btnText);

                dispatch(taskActions.updateTaskStatus(tasks, taskActions.Status.InProgress));
                if( btnText === ButtonText.Reopen)
                    dispatch(taskActions.reopenTask(tasks));
            }

            //dispatch(taskActions.showTaskDetails(true));
        },
        changeTask: (subTask, task) => { //switch between tasks, CurrentTaskResourceId and CurrentSubTaskResourceId
            dispatch(taskActions.changeCurrentTask(task.resourceID));
            
            dispatch(taskActions.changeCurrentSubTask(subTask.resourceID));
            dispatch(taskActions.loadCurrentTaskCurrentStep(false)); //load current step from local db
            return dispatch(taskActions.loadCurrentTaskSteps(false)); //load step info
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubTask)