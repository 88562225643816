import React, { useEffect } from 'react';
import {connect,useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import { Typography,Grid,Link, Paper, Box } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,

  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import DomainIcon from '@material-ui/icons/Domain';
import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';





import SkbTable  from '../../../../skb_controls/components/SkbTable';
import { SkbSwitch } from '../../../../skb_controls/components/SkbSwitch';
import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';
import SkbIcon from '../../../../skb_controls/components/SkbIcon';

import * as  returnsDashboardTaskInfoActions from '../../../../Redux/actions/returnsdashboard';
import Loading from '../../../Loading';
import ContractorRepresentativePhoneEmailStructure  from "./ContractorRepresentativePhoneEmailStructure";

import {triggerValidateStep1} from "./TaskDetailsSchedulePopup";

const styles = makeStyles({
    borderBox : {
        border : '1px solid lightgray',
        borderRadius : '5px',
        padding : '0 0.5em',
    },
    oneRow : {
        display : 'flex',
        
        justifyContent : 'space-between',
        alignItems : 'center',
    }
})





const ShipmentDetails  = (props)=> {
    const {
        borderBox,
        oneRow,

    } = styles();
    const dispatch = useDispatch();

    const {taskinfoschhedulepropdata} = props;
    const taskId = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.taskId ) ? taskinfoschhedulepropdata.taskId: ""; 
   
   

     const getWareHouseDisplayData = (resp) => {

        if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
          let wareHouseListDrpDwnArr = [];
          resp.map((e,i)=>{
            wareHouseListDrpDwnArr.push({"value":e.LocationCode,"text":e.Name});
          });
    
          return wareHouseListDrpDwnArr;    
    
        }
    
        return [];
    
    }

    const getPackageListDispData = (resp) => {
       
        if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
          let pkgListArr = [];
          resp.map((e,i)=>{
            pkgListArr.push(
                {
                "Id":(e.Id!==undefined && e.Id!==null)?e.Id:"",
                "PackageType":(e.PackageType && e.PackageType!==null)?e.PackageType:"",
                "Qty":(e.Qty && e.Qty!==null)?e.Qty:"",
                "LengthCm":(e.LengthCm && e.LengthCm!==null)?e.LengthCm:"",
                 "WidthCm":(e.WidthCm && e.WidthCm!==null)?e.WidthCm:"",
                "HeightCm": (e.HeightCm && e.HeightCm!==null)?e.HeightCm:"",
                "WeightKg": (e.WeightKg && e.WeightKg!==null)?e.WeightKg:""

            });
          });
    
          return pkgListArr;    
    
        }
    
        return [];
    
    }
      
    const handleChangeWareHouseCheck = (evVal) => {   
        
        const paramObjForLocAddrFromCode = {"paramObj":{"locationCode":evVal}};   
        
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));

        //console.log("----- paramObjForLocAddrFromCode=>>>>>>",paramObjForLocAddrFromCode);
        dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchWarehouseAddrBasedOnLocCodeTIPSTwoSteps(paramObjForLocAddrFromCode));

        // for specific error message update
        let paramToValidate = {             
            "stepOneWareHouseLocationCode":evVal,
            };
        let chkv1 = triggerValidateStep1(paramToValidate,{"specificFor":"stepOneWareHouseLocationCode","getValidateFlag":false});        
        let {validateFlagErrMessagesProvided} = {...chkv1};
        
        let {wareHouseLocationCodeErrMsg} = {...validateFlagErrMessagesProvided};

         let errStateObj = {   
            "specificForKey":"wareHouseLocationCodeErrMsg" ,        
            "specificForKeyValue":wareHouseLocationCodeErrMsg
            } ; 

        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepOneToUpdateSpecificFeErMsg(errStateObj));
        
    }

      const {taskInfo,contractorCompanyName, wareHouseLocationCode, toLocationAddressLine1, toLocationAddressLine2, toLocationState, toLocationSuburb,toLocationPostcode,atp, forkliftRequired, pickupWindowDate,pickupWindowTimeslot, packageListToDisplay, fromLocationAddressLine1, fromLocationAddressLine2, fromLocationState, fromLocationSuburb, fromLocationPostcode, connoteNumber,carrierName } = useSelector((state) => ({
        //return state.returnsDashboardTaskInfo;
        "taskInfo": state.returnsDashboardTaskInfo.taskInfo,
        "contractorCompanyName": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.Company!==undefined && state.returnsDashboardTaskInfo.taskInfo.Company && state.returnsDashboardTaskInfo.taskInfo.Company.Name!==undefined )?state.returnsDashboardTaskInfo.taskInfo.Company.Name: "",
        "atp":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.ATP!==undefined)?state.returnsDashboardTaskInfo.taskInfo.ATP: false,
        "forkliftRequired":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.ForkliftRequired!==undefined)?state.returnsDashboardTaskInfo.taskInfo.ForkliftRequired: false,
        "pickupWindowDate":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Date!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Date: "",
        "pickupWindowTimeslot":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Timeslot!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Timeslot: "",

        "connoteNumber":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.ConnoteNumber!==undefined)?state.returnsDashboardTaskInfo.taskInfo.ConnoteNumber: "",
        "carrierName":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.CarrierName!==undefined)?state.returnsDashboardTaskInfo.taskInfo.CarrierName: "",

        "wareHouseLocationCode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.LocationCode)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.LocationCode):"",
        "toLocationAddressLine1": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine1)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine1):"",
        "toLocationAddressLine2": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine2)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine2):"",
       "toLocationState": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.State)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.State):"",
       "toLocationSuburb": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.Suburb)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.Suburb):"",
       "toLocationPostcode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.Postcode)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.Postcode):"",
        "packageListToDisplay":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.Packages!==undefined)?getPackageListDispData(state.returnsDashboardTaskInfo.taskInfo.Packages): [],
        
        "fromLocationAddressLine1": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine1)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine1):"",
        "fromLocationAddressLine2": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine2)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine2):"",
       "fromLocationState": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.State)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.State):"",
       "fromLocationSuburb": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.Suburb)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.Suburb):"",
       "fromLocationPostcode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.Postcode)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.Postcode):"",
       


      })
  );


    const {wareHouseListLoadStatus,wareHouseList, wareHouseListToDisplay} = useSelector((state) =>( {  
        //return state.returnsDashboardWarehouseList;  
        "wareHouseListLoadStatus": state.returnsDashboardWarehouseList.wareHouseListLoadStatus,
        "wareHouseList": state.returnsDashboardWarehouseList.wareHouseList,             
        "wareHouseListToDisplay":  getWareHouseDisplayData(state.returnsDashboardWarehouseList.warehouseList),

        }
    )      
    );


    const {warehouseAddressDataLoadStatus, toLocationAddressLine1AfterSel, toLocationAddressLine2AfterSel, toLocationStateAfterSel, toLocationSuburbAfterSel,toLocationPostcodeAfterSel, wareHouseLocationCodeAfterSel } = useSelector((state) => ({
        //return state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps;
        "warehouseAddressDataLoadStatus": state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressDataLoadStatus,
        "wareHouseLocationCodeAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.LocationCode)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.LocationCode):"",
        "toLocationAddressLine1AfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine1)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine1):"",
        "toLocationAddressLine2AfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine2)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine2):"",
        "toLocationStateAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.State)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.State):"",
        "toLocationSuburbAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Suburb)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Suburb):"",
        "toLocationPostcodeAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Postcode)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Postcode):"",
  
  
      })
  );

      //pickupWindowTimeslot pickupWindowDate

      const {stepOneAtp, twoStepScenarioStepOneChangedDataSetFlag, stepOneForkliftRequired, stepTwoPickupWindowTimeslot,stepTwoPickupWindowDate, stepOneWareHouseLocationCode, stepOneValidateFlagErrMessages} = useSelector((state) =>( {  
        //return state.returnsDashboardTaskInfoSchedulePopupChangedData;  
        "stepOneWareHouseLocationCode": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.wareHouseLocationCode!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.wareHouseLocationCode: "",

        "stepOneAtp": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.atp!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.atp: false,

        "stepOneForkliftRequired": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.forkLiftRequired!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.forkLiftRequired: false,
        

        "twoStepScenarioStepOneChangedDataSetFlag":state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedDataSetFlag!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedDataSetFlag: false,

        "stepTwoPickupWindowTimeslot" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowTimeslot!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowTimeslot: "",              

        "stepTwoPickupWindowDate" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowDate!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowDate: "",  
        
        "stepOneValidateFlagErrMessages": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData &&
        state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.validateFlagErrMessages!==undefined?
        state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.validateFlagErrMessages:null,

        }



    )      
    );



  const setInitialDataOfChangebaleControlsToReduxState = function(){     
   // dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepOneDataChangeableDataSetFlag(true));

    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepOneWarehouseLocChange(wareHouseLocationCode));
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepOneAtpChange(atp));
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepOneForkliftreq(forkliftRequired));

    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoPickupwindowDate(pickupWindowDate));
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoPickupwindowTimeslot(pickupWindowTimeslot));
    
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierName(carrierName));
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepTwocannote(connoteNumber));

    
  }



  const changeStepOneAtpReduxState = function(){
       
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepOneAtpChange(!(stepOneAtp)));
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));
    }

  const changeStepOneForkListReqReduxState = function(){
       
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepRelStepOneForkliftreq(!(stepOneForkliftRequired)));
        dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeActivityByUser(true));
    }



  useEffect(()=>{  

   if(twoStepScenarioStepOneChangedDataSetFlag===false){
        setInitialDataOfChangebaleControlsToReduxState();
   }
  

  },[twoStepScenarioStepOneChangedDataSetFlag]);


  const taskinfoShedulePkgLisColumns = [
    { title: 'Hidden Col', field: 'Id', type: 'hidden-id'},
    { title: 'Packaging Type', field: 'PackageType', type: 'read-only-short-text'},
    { title: 'Qty', field: 'Qty', type: 'read-only-short-text'},
    { title: 'L(cm)', field: 'LengthCm', type: 'read-only-short-text'},
    { title: 'W(cm)', field: 'WidthCm', type: 'read-only-short-text'},
    { title: 'H(cm)', field: 'HeightCm', type: 'read-only-short-text'},
    { title: 'Weight(kg)', field: 'WeightKg', type: 'read-only-short-text'},
 ];


    return(
        <div
            style={{
                width : '100%',
            }}
        >
            {/* <DestinationDetails /> */}
            { (warehouseAddressDataLoadStatus) && (<Loading />) }
            <Grid container  direction="column">
                {console.log("<===== SKYBRIDGE OPTION WAREHOUSE LOCATION CODE =====>",stepOneWareHouseLocationCode)}
                <Typography variant="h6">Destination Details</Typography>
                <Grid 
                    className={[borderBox,oneRow]} 
                    container
                    
                >
                  
                    <Grid item>

                        <SkbSelect 
                            Title="" 
                            id="tischedwarehscheckdropselect2" 
                            value={stepOneWareHouseLocationCode} 
                            items={wareHouseListToDisplay} 
                            valueChanged={handleChangeWareHouseCheck} 

                            error={stepOneValidateFlagErrMessages && stepOneValidateFlagErrMessages.wareHouseLocationCodeErrMsg!==undefined?true:false}
                             helperText={stepOneValidateFlagErrMessages && stepOneValidateFlagErrMessages.wareHouseLocationCodeErrMsg!==undefined?stepOneValidateFlagErrMessages.wareHouseLocationCodeErrMsg:""}
                            
                        />                       

                         {/* Data came from api hitted on change */}
                        { (wareHouseLocationCodeAfterSel) && (
                          <>
                              <Box ml={2} textAlign="left" >
                          <Typography noWrap={false}>
                            {/* 1st line => ToLocation.AddressLine1 */}   
                           
                            {toLocationAddressLine1AfterSel==""?"":toLocationAddressLine1AfterSel}


                          </Typography>

                           <Typography noWrap={false}>
                            {/* 2nd line => ToLocation.AddressLine2 */}                            
                            
                            {toLocationAddressLine2AfterSel==""?"":toLocationAddressLine2AfterSel}


                          </Typography>
                          <Typography noWrap={false}>
                          
                           {/* 3rd line => ToLocation.Suburb FromLocation.State FromLocation.Postcode */}
                           
                           
                            {toLocationSuburbAfterSel==""?"":toLocationSuburbAfterSel}
                           
                            
                            {toLocationStateAfterSel==""?"":" "+toLocationStateAfterSel}
                            
                            
                            {toLocationPostcodeAfterSel==""?"":" "+toLocationPostcodeAfterSel}
                            

                          </Typography>
                      </Box>

                      
                          </>

                    )}
                    {/* Data came from api when popup opens */}
                      { !(wareHouseLocationCodeAfterSel)  && (
                          <>
                              <Box ml={2} textAlign="left" >
                          <Typography noWrap={false}>
                            {/* 1st line => ToLocation.AddressLine1 */}   
                          
                            {toLocationAddressLine1==""?"":toLocationAddressLine1}


                          </Typography>

                           <Typography noWrap={false}>
                            {/* 2nd line => ToLocation.AddressLine2 */}                            
                            
                            {toLocationAddressLine2==""?"":toLocationAddressLine2}


                          </Typography>
                          <Typography noWrap={false}>
                          
                           {/* 3rd line => ToLocation.Suburb FromLocation.State FromLocation.Postcode */}
                           
                            
                            {toLocationSuburb==""?"":toLocationSuburb}
                           
                            
                            {toLocationState==""?"":" "+toLocationState}
                            
                            
                            {toLocationPostcode==""?"":" "+toLocationPostcode}
                            

                          </Typography>
                      </Box>

                      
                          </>

                    )}
                    </Grid>
                    <Grid item>
                        &nbsp;
                    </Grid>
                    
                </Grid>
            </Grid>
            <br />
            {/* <PickupDetails /> */}
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="h6">Pickup Details</Typography>
                </Grid>
                <div className={borderBox}>
                    <Grid item direction="row" className={[oneRow]}>
                        <Grid item >
                            <Typography variant="body1">
                                 {/* 1st line => FromLocation.AddressLine1 */}                                 
                                {fromLocationAddressLine1}  
                            </Typography>
                             <Typography variant="body1">
                                 {/* 2nd line => FromLocation.AddressLine2 */}                                 
                                {fromLocationAddressLine2}  
                            </Typography>
                            <Typography variant="body1">
                                 {/* 3rd line => FromLocation.Suburb FromLocation.State FromLocation.Postcode */}
                            { fromLocationSuburb+" "+fromLocationState+" "+fromLocationPostcode}
                            
                            </Typography>
                            
                        </Grid>
                        <Grid item direction="column">                           

                             <Grid item container direction="row">
                                
                                <Typography variant="body2">
                                <Link  
                                // style={{"cursor":"pointer"}} 
                                
                                >
                                    <SkbIcon  icon={DomainIcon}/>
                                    
                                </Link> &nbsp;
                                {contractorCompanyName?contractorCompanyName:""}
                                </Typography>
                            </Grid>
                            <ContractorRepresentativePhoneEmailStructure  
                                dataContractorDetails={{
                                    "representative":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.Representative!==undefined )?taskInfo.Company.Representative: "",
                                    "contractorRepresentativePhone":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.ContractorRepresentativePhone!==undefined )?taskInfo.Company.ContractorRepresentativePhone: "",
                                    "taskId":taskId,
                                    "representativeEmail":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.RepresentativeEmail!==undefined )?taskInfo.Company.RepresentativeEmail: "",
                                    "mailSubject":`${`Skybridge stock return task ${taskId} query`}`,"implementedIn":"TASK_INFO_SCHEDULE"
                                }} 
                            />
                        </Grid>
                        
                    </Grid>
                    <br/>
                    <Grid item direction="column">
                        <Grid item direction="row" className={oneRow}>
                            <Grid item>
                                <Typography variant="body2">Contractor <Link>ATP</Link> ?</Typography>
                            </Grid>
                            <Grid item>

                            <SkbSwitch  val={stepOneAtp} setVal={changeStepOneAtpReduxState}  primary />
                                
                                
                            </Grid>
                        </Grid>
                        <Grid item direction="row" className={oneRow}>
                            <Grid item>
                                <Typography variant="body2">Truck with forklift required ?</Typography>
                            </Grid>
                            <Grid item>
                           
                            <SkbSwitch  val={stepOneForkliftRequired} setVal={changeStepOneForkListReqReduxState}  primary />
                               
                                       
                            </Grid>
                        </Grid>

                    </Grid>
                    <br />
                    <Grid item direction="row" className={oneRow}>
                        <Grid item>
                            <Typography variant="body2">Pickup Window</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    value={stepTwoPickupWindowDate}
                                    disabled={true}
                                    // placeholder="22/10/2020"
                                    placeholder=""
                                    leftArrowIcon={<ArrowBackIosIcon />}

                                    rightArrowIcon={<ArrowForwardIosIcon />}
                                    keyboardIcon={<EventIcon />}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    'id': 'date-picker-inline-changedatebtn'
                                    }}
                                    leftArrowButtonProps = {
                                    {
                                        'id': 'date-picker-inline-leftbtn'
                                    }
                                    }
                                    rightArrowButtonProps = {
                                    {
                                        'id': 'date-picker-inline-righbtn'
                                    }
                                    }

                                    error={false}
                                    helperText={""}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <SkbSelect 
                                value={stepTwoPickupWindowTimeslot}
                                items={[stepTwoPickupWindowTimeslot]} 
                                disabled={true}
                                
                            />
                        </Grid>
                    </Grid>
                </div>
                
            </Grid>
            <br />
            {/* <PackagingDetails /> */}
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="h6">Packaging Details</Typography>
                </Grid>
                <Grid item className={borderBox}>
                        <SkbTable 

                        columns={taskinfoShedulePkgLisColumns} 
                        dataSource={packageListToDisplay} 


                        />
                    
                </Grid>
            </Grid>
        </div>
        
    )
}


export default ShipmentDetails;