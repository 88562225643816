import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import * as sv from '../../../services/StockService'
import { SkbLoading } from '../../../skb_controls/components/SkbLoading';
import SkbTable from '../../../skb_controls/components/SkbTable';

const useStyles = makeStyles((them) => (
    {
        root: {
            width: '100%'
        }

    }
));


/**
 * components: DestinationSelector is to choose destinations from destination list
 * @param {funciton} loadDestinationData: it's a callback to refresh destinationList and selectedDestination 
 * @param {boolean} IsLoading: to indicate the destinationList is refreshing or not
 * @param {array}  DestinationList: array of data to show in the grid
 *       i.e 
 *       [
 *           {
 *               LocationCode: 'ADM01',
 *               Name: 'Gin Gin Warehouse',
 *               Address: '1 Station St Gin Gin QLD 4567'
 *           }
 *       ]
 * @param {String} SelectedDestination: selected destination's location code
 * @param {Function} selectDestination: it's a callback function, and called when selecting or un-selecting a row
 *      the signature of the callback is fn(event, rowdata)
 * @returns 
 */
 export function DestinationSelector({ loadDestinationData, IsLoading, SelectedDestination, selectDestination,userToken}) {
    const [DestinationList, loadList] = useState([]);

    const classes = useStyles();
    if(typeof(loadDestinationData) === 'undefined') throw("loadDestinationData prop is missing");
    if(typeof(IsLoading) === 'undefined') throw("IsLoading prop is missing");
    if(typeof(SelectedDestination) === 'undefined') throw("SelectedDestination prop is missing");
    if(typeof(selectDestination) === 'undefined') throw("selectDestination prop is missing");

    useEffect(() => {
        async function getWarehouseFromServer(){
             let dt= await sv.loadWarehouseLocations(userToken)
            //let warehouseList =await loadDestinationData();
            loadList(dt); 
        } 
         getWarehouseFromServer()

    }, []);

    const allColumns =[
                   {title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
                   {title:"Stock Location", field:"Name", type: 'read-only-long-text'},
                   {title:"Address", field:"Address", type: 'read-only-long-text'},
               ];

    if (IsLoading)
        return (
            <SkbLoading />
        );
    else return (
        <div className={classes.root} id="stock_destinationselector">
            <SkbTable columns={allColumns} 
                      dataSource={DestinationList} 
                      id="stock_destinationselector_datagrid"
                      actions = {[
                        {
                            type: 'single-select',
                            onClick: selectDestination
                        }
                      ]}
                      options={{selectedIdValues:[SelectedDestination],
                        emptyDataSourceMessage:'There are no destinations available!'}} />

        </div>
    );
}