import createAuth0Client from '@auth0/auth0-spa-js';
import axios from "axios";
import { config } from '../../utils/config';
import {crypts} from '../../utils/crypts';
import { SkbLogger, ApiService } from '../../services';
import * as jwtDecode from 'jwt-decode';
import { Description } from '@material-ui/icons';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
let auth0Client;

export const Actions = {
    CLEAR_LOCAL_USERTOKEN: "CLEAR_LOCAL_USERTOKEN",
    SET_LOGIN_USER: "SET_LOGIN_USER",
    GET_USERINFO_COMPLETED: "GET_USERINFO_COMPLETED",
    GET_USERMODULES_COMPLETED: "GET_USERMODULES_COMPLETED",
    SET_ID_TOKEN: "SET_ID_TOKEN",

    REQUEST_BEGIN: "REQUEST_BEGIN",
    REQUEST_END: "REQUEST_END",
};


export function setLoginUser(user) {
    localStorage.setItem('auth_user', crypts.encrypt(JSON.stringify(user)));
    //SkbLogger.logUserLogIn(user);
    return {
        type: Actions.SET_LOGIN_USER,
        authUser: user,
    };
}



export function sendGetUserRequest(authUser, idToken) {
    const userData ={ "user": authUser.email }  ;
    return (dispatch) => {
        axios({
            method: 'GET', //you can set what request you want to be
            url: process.env.REACT_APP_API_URL + 'users/' + authUser.sub,
            data: {
                auth0UserID: authUser.sub,
                name: authUser.name,
                userLoginName: '',
                company: '',
                userType: 0,
                email: authUser.email,
                emailVerified: 0,
                phone: '',
                managerName: '',
                countryVariableID: 0
            },
            headers: {
                'content-type': 'application/json',
                'authorization': config.REACT_APP_API_SECRET,
                'X-CustomHeader': idToken
            }
        }).then(function (res) {
            //SkbLogger.logDebugInfo('getUser data', res.data);
            SkbLogger.logDebugInfo('sendGetUserRequest: Response', res.data)
            SkbLogger.applicationTrace('sendGetUserRequest',SeverityLevel.Information,'Get user information','Get user information successful ',res.data);
            if (res.data.id !== null) {
                SkbLogger.logDebugInfo('existing user returned with internal id.', res.data);
                //successfully got skb_user
                dispatch(setSkbUser(res.data));
            } else if(res.status>=200 && res.status<=209){  //not 429, 404
                //when there is no data.id, it only means this is a new user "when status not an error".
                SkbLogger.logDebugInfo('no existing user but status ok.', res.status);
                //no error, but user does not exist from API
                //to indicate a new user with user.emailVerified flag for App.js->RegistrationWithTheme ??
                dispatch(setSkbUser( {auth0UserID: authUser.sub, 
                                        name: authUser.name, 
                                        email: authUser.email, 
                                        emailVerified:0,
                                        userType: 0,
                                        countryVariableID: 0} 
                                    )
                        );
            }
            dispatch(requestEnd(""));

        })
        .catch(function (error) {
                // SkbLogger.logDebugInfo('sendGetUserRequest: error Response', error)
                SkbLogger.applicationException('sendGetUserRequest','Get user information','Get user information failed. No skb_user set.',error);
                //error: 4xx, no skb_user
                dispatch(requestEnd(error))
        });
    }

}

export const setSkbUser = user => {
    localStorage.setItem('skb_user', crypts.encrypt(JSON.stringify(user)));
    return {
        type: Actions.GET_USERINFO_COMPLETED,
        user: user
    }
}


export function getUserTokenList(auth0Id,idToken) {
    return() =>{ 
        axios({
            method: 'GET', 
            url: process.env.REACT_APP_API_URL + 'users/' + auth0Id +'/tokens',
            headers: {
                'content-type': 'application/json',
                'authorization': config.REACT_APP_API_SECRET,
                'X-CustomHeader': idToken
            }
        }).then(function (res){
            res.data.map((i) => {
                if (i.tokenName ==="AzureStorageSasEncrypted") {
                    localStorage.setItem('sas_azureStorageEncrypted',i.tokenValue);
                    SkbLogger.logDebugInfo('get sas token from jitterbit',i.tokenValue)
                }
            })
        })
        .catch(function (error) {
            SkbLogger.applicationException('getStorageSASToken','Get Storage SAS Token','Get storage SAS token failed.',error);
        });
    }
}


export function loadReusableSettingsIntoLocalStorage(authUser, idToken){
    //PickupWindowOptions
    axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=PickupWindowOptions',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    }).then( resp => {
        if(resp && resp.data && Array.isArray(resp.data)){
            const toSave=resp.data.map(oneOption => oneOption.variableValue);
            localStorage.setItem('REUSABLE-SETTINGS-PickupWindowOptions',JSON.stringify(toSave));
        }
        
    })


    //DropOffWindowOptions
    axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=DropOffWindowOptions',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    }).then( resp => {
        if(resp && resp.data && Array.isArray(resp.data)){
            const toSave=resp.data.map(oneOption => oneOption.variableValue);
            localStorage.setItem('REUSABLE-SETTINGS-DropOffWindowOptions',JSON.stringify(toSave));
        }
    })


}

/*getUserModules: this action is called after user has successfully logged in, it will user's applicable module list from server side */
export function getUserModules(authUser, idToken) {
   
    return (dispatch) => {
        console.log("getUserModules window.navigator.onLine=", window.navigator.onLine);

        if(!window.navigator.onLine){
            const encryptedLocalModuleList=localStorage.getItem('module_list');
            SkbLogger.logDebugInfo('offline, load module_list from localStorage',encryptedLocalModuleList);
            if(encryptedLocalModuleList){
                const localModuleList=JSON.parse(crypts.decrypt(encryptedLocalModuleList));
                if(localModuleList){
                    dispatch(setUserModules(localModuleList));
                }
            }
        }else{
            try {
                axios({
                    method: 'GET', //you can set what request you want to be
                    url: process.env.REACT_APP_API_URL + 'users/' + authUser.sub + '/modules',
                    data: null,
                    headers: {
                        'content-type': 'application/json',
                        'authorization': config.REACT_APP_API_SECRET,
                        'X-CustomHeader': idToken
        
                    }
                }).then(function (res) {
                    
                    SkbLogger.applicationTrace('getUserModules',SeverityLevel.Information,'Get user modules','Get user modules successful',res);
                    
                    if (res!==null && res!==undefined && res.data!==null && res.data!==undefined && res.data.length>0 
                        && (res.data.length>1 ||(res.data[0].userID!==null && res.data[0].userID!==undefined))) //When there are no results for some reason Jitterbit is sending back a list with one blank item.
                    {
                        // //Everyone gets the Home page module:
                        var defaultHomeModule={id: -1, userID: res.data[0].userID, name: "Home", uRLSuffix: "home", moduleID: -1, description:"Home page", iconName:"Home", ParentModuleId: null};
                        res.data.splice(0,0,defaultHomeModule);
                        //cache user module list for offline
                        SkbLogger.logDebugInfo('cache module_list',res.data);
                        localStorage.setItem('module_list',crypts.encrypt(JSON.stringify(res.data)));
                        dispatch(setUserModules(res.data));
        
                    }
                    else {
                        SkbLogger.applicationTrace('getUserModules',SeverityLevel.Information,'Get user modules','This user has no modules available.',authUser);
        
                        var homeModuleOnlyList=[{id: -1, userID: -1, name: "Home", uRLSuffix: "home", moduleID: -1, description:"Home page", iconName:"Home", ParentModuleId: null}];
                       
                        dispatch(setUserModules(homeModuleOnlyList));
                        
                    }

                    loadReusableSettingsIntoLocalStorage(authUser, idToken);
        
        
                })
                .catch(function (error) {
                        // SkbLogger.logDebugInfo('getUserModules: error Response', error)
                        SkbLogger.applicationException('getUserModules','Get user modules','Get user modules failed (in promise catch)',error);
                        
                        //dispatch(clearLocalUserToken());
                        //sometime the navigator.onLine is not updated, so better to try load again, rather than clear 
                        const encryptedLocalModuleList=localStorage.getItem('module_list');
                        SkbLogger.logDebugInfo('offline, load module_list from localStorage',encryptedLocalModuleList);
                        if(encryptedLocalModuleList){
                            const localModuleList=JSON.parse(crypts.decrypt(encryptedLocalModuleList));
                            if(localModuleList){
                                dispatch(setUserModules(localModuleList));
                            }
                        }
                });
            } catch (err) {
                SkbLogger.applicationException('getUserModules','Get user modules','Get user modules failed (in exception try-catch)',err);
                
                
                //dispatch(clearLocalUserToken()); 
                //sometime the navigator.onLine is not updated, so better to try load again, rather than clear 
                const encryptedLocalModuleList=localStorage.getItem('module_list');
                SkbLogger.logDebugInfo('offline, load module_list from localStorage',encryptedLocalModuleList);
                if(encryptedLocalModuleList){
                    const localModuleList=JSON.parse(crypts.decrypt(encryptedLocalModuleList));
                    if(localModuleList){
                        dispatch(setUserModules(localModuleList));
                    }
                }
            }
        }//end else if
            
    }

}

/**setUserModules: update user modules in redux store */
export const setUserModules = (modules) => {
    return {
        type: Actions.GET_USERMODULES_COMPLETED,
        modules: modules
    }
}

/**setIdToken: save id token which is from Auth0 response in redux store, it will be presented to Jitterbit service for POST/PUT/DELETE request 
 * also the token would be securely cached locally
*/
export const setIdToken = (token) => {
    let t = jwtDecode(token);// the token from Auth0 is JWT format token, decode it for token expiry time
    //console.log('t.exp = ',t.exp);
    let expiresAt = JSON.stringify((t.exp * 1000));// exp is time ticks, convert it to be in milisecond

    //encrypt token and expirtat and save into persistent localstorage 
    localStorage.setItem('id_token', crypts.encrypt(token));
    localStorage.setItem('expires_at', crypts.encrypt(expiresAt));
    return {
        type: Actions.SET_ID_TOKEN,
        IdToken: token
    }
}

/** clearLocalUserToken: user token in local storage and redux store will be clear after logout */
export const clearLocalUserToken = () => {
    //once logoun 
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('auth_user');
    localStorage.removeItem('skb_user');
    return {
        type: Actions.CLEAR_LOCAL_USERTOKEN
    }
}


export const isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let exp = crypts.decrypt(localStorage.getItem('expires_at'));
    //SkbLogger.logDebugInfo("isAuthenticated is called",exp);
    if (!exp) return false;
    let expiresAt = JSON.parse(exp);
    SkbLogger.logDebugInfo("compare token exipiry for isAuthenticated",(new Date().getTime()),expiresAt);
    return new Date().getTime() < expiresAt;
}

export const isIDTokenExpiringSoon = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const currentTime=new Date().getTime();
    let exp = crypts.decrypt(localStorage.getItem('expires_at'));
    if (!exp) return false;
    let expiresAt = JSON.parse(exp);
    //expiring in 4 hours : true
    return ( (currentTime>expiresAt-3600*1000*4) && (currentTime<expiresAt) );
    
}

function requestBegin(){
    return {
        type: Actions.REQUEST_BEGIN,
    }
}

function requestEnd(error){
    return {
        type: Actions.REQUEST_END,
        error: error
    }
}

/**loadTokenfromLocalStorage: attempt to restore user token from local storage, also the token is encrypted in localstorage*/
export const loadTokenfromLocalStorage = () => {


    return (dispatch) => {
        if (!localStorage.getItem('id_token')) {
            //dispatch(requestEnd());
            return;
        }
        if (isAuthenticated()) {
            //if isAuthenticated, load user info in localStorage

            let t = crypts.decrypt(localStorage.getItem('id_token'));
            if (t) {
                console.log('isAuthenticated: set token from cache');
                dispatch(setIdToken(t));
            }
            let authUser = crypts.decrypt(localStorage.getItem('auth_user'));
            if (authUser) {
                dispatch(setLoginUser(JSON.parse(authUser)));
            }

            let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
            if (skbUser) {
                dispatch(setSkbUser(JSON.parse(skbUser)));
            }

            dispatch(requestEnd());

        }else if(localStorage.getItem('id_token') && !navigator.onLine){
            //if offline, ignore the expiry on isAuthenticated flag, but still load user info in localStorage.
            SkbLogger.applicationTrace('Auth',0,'Load Cache'
                                    ,'Auth expired while offline. still loading cache in localStorage.');

            let t = crypts.decrypt(localStorage.getItem('id_token'));
            if (t) {
                console.log('offline: set token from cache');
                dispatch(setIdToken(t));
            }
            let authUser = crypts.decrypt(localStorage.getItem('auth_user'));
            if (authUser) {
                dispatch(setLoginUser(JSON.parse(authUser)));
            }

            let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
            if (skbUser) {
                dispatch(setSkbUser(JSON.parse(skbUser)));
            }

            dispatch(requestEnd());

        }else{

            //clear store if token has already expired, user needs to re-login to the app
            //idtoken expires in 10 hours by default
            //idtoken renew is in App.js
            dispatch(clearLocalUserToken()); 
            dispatch(requestEnd());
            SkbLogger.logUserLogOut(); 

        }
    }
}

/**changePassword: call auth0 api to send change password request email */
export const changePassword=(userEmail)=>{
    return ((dispatch) => {
        dispatch(requestBegin());
        ApiService.changeAuth0Password(userEmail).then((res)=>{
            dispatch(requestEnd(""));
            SkbLogger.userSecurityEventWithUserObject('SkbProfileMenu','User security','Change Password','Success','{UserObject} has successfully initiated the change password process.')
            SkbLogger.applicationTrace('SkbProfileMenu',1,'User profile','User change password successful',res);
        }).catch(error=>{
            SkbLogger.applicationException('SkbProfileMenu','User profile','User change password failed',error);
            SkbLogger.userSecurityEventWithUserObject('SkbProfileMenu','User security','Change Password','Fail','{UserObject} has unsuccessfully initiated the change password process.')
            dispatch(requestEnd(error));
        })

    });
}

