import React from 'react';

import { makeStyles } from '@material-ui/core/styles';




const useStyles = makeStyles(theme => ({
    root: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     maxWidth: 300,
        // },
        maxWidth: 345,
    },

    // formControl: {
    //     margin: theme.spacing(1),
    //     maxWidth: 120,
    // },
    // selectEmpty: {
    //     marginTop: theme.spacing(2),
    // }
    // ,
    media: {
        height:250
        //maxHeight: 200,
     //   width: 150,
        //paddingTop: '56.25%', // 16:9
      },

      middle: {display: 'flex',
             
            justifyContent: 'center'
          }
}));

export function PerformWork() {
    const classes = useStyles();

    return (
        <div>
          Perform Work Module</div>);}