import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core';
import SkbButton from '../../../skb_controls/components/SkbButton';
import {SkbSelect} from '../../../skb_controls/components/SkbSelect';

import SkbTextField from '../../../skb_controls/components/SkbTextField';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    }
}));

const titleBarStyles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth: 300
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
});

const DialogTitle = withStyles(titleBarStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
           
            '& .MuiFormControl-root': {
                margin: theme.spacing(1)
            },

    },

}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

/**
 * 
 * @property {message}: message to show  (can contain line breaks represented as \n)
 * @property {open}: true: show alert window, false: hide alert window
 * @property {openConfirm}: alert window open sate update handler;
 * @property {okHandler}: call back funciton when OK is clicked
 */
export default function DialogAddSerialNumberManually({ title, message, open, openConfirm, okHandler }) {

    const [serialNumber, setSerialNumber] = React.useState('');
    const [status, setStatus] = React.useState('Good');
    const [addEnabled, setAddEnabled] = React.useState(false);

    const handleClickOk = () => {
        
        //Do not close dialog - leave open for the next entry.  The calling code can always close it using the openConfirm function if needed.
        
        if (okHandler) 
        {
            okHandler({serialNumber:serialNumber, status:status});
            // setSerialNumber('');  //for next time
            // setStatus('Good');  //for next time
        }
    };

    const handleClose = () => {
        setSerialNumber('');  //for next time
        setStatus('Good');  //for next time
        openConfirm(false);
    };

    const valueIsPopulated= ()=>
    {
        return (serialNumber!=='' && serialNumber!==undefined && serialNumber!==null 
            && (status==='Good'||status==='Faulty'))

    }

    const onChangeSerial=(e)=>
    {
        
        setSerialNumber(e.target.value);
        
        setAddEnabled(valueIsPopulated());
        
    }

    const onChangeStatus=(eventTargetValue)=>
    {
        setStatus(eventTargetValue);
        
        setAddEnabled(valueIsPopulated());
        
    }

    const theme = useTheme();
    const classes = useStyles();

    const  formattedMessageText=message.split('\n').map(text => {
        return <Typography gutterBottom>{text}</Typography>
    });


    return (
        <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.paper }}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
             </DialogTitle>
            <DialogContent dividers>
            {formattedMessageText}

                <SkbTextField  id="serial-number-input" label="Serial Number" type="text" size="small" outlined value={serialNumber} onChange={(e)=>onChangeSerial(e)} />
                
                <SkbSelect Title="Status" id="status-selector" value={status} items={["Good","Faulty"]} valueChanged={(eventTargetValue)=>onChangeStatus(eventTargetValue)} />

            </DialogContent>
            <DialogActions>
                <SkbButton id="back-button" primary outlined text="Back" size="medium" onClick={handleClose} autoFocus={true} />
                <SkbButton id="add-button" primary size="medium" text="Add" onClick={handleClickOk} 
                disabled={!addEnabled} />

            </DialogActions>

        </Dialog>
    );
}
