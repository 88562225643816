import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import SkbStepper from '../../../../skb_controls/components/SkbStepper';
import { 
    Grid
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import BusinessIcon from '@material-ui/icons/Business';
// importing steps
import SelectSerializedCSV from './steps/SelectCSV';
import LoadThreePLImportationOutcomes from './steps/LoadThreePLImportationOutcomes';
import SubmitCSVForProcessing from './steps/SubmitCSVForProcessing';
import {makeStyles}  from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import {BrowserRouter as Router,Route,Link,Switch } from 'react-router-dom';

import {
    selectRequestId,
    selectCSVJsonData,
    selectCsvProcessingError,
    selectThreePLImportationSuccess,
    
} from '../../../../Redux/ThreePLImportation/selectors';

const useStyles = makeStyles(theme=>({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    title: {
        textAlign: "left",
    },
    safari: {
        height: '-webkit-fill-available',
    },
    avatarIcon: {

        display: 'inline-block',
        verticalAlign: 'middle',
    },
    flexCenter : {
        justifyContent : "center",
        alignItems : "center",
        width : "100%",
    },
    flexEnd : {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonLook : {
        margin : '1em',
        marginRight : '0em',
        marginLeft : '0em',
        padding : "0.4em 0.7em",
        border: "none",
        // borderBottom : "1px solid black",
        textDecoration : "none",
        borderRadius : "0px",
        textTransform: "initial",
        "&:nth-child(1)" : {
            color : "#000",
            paddingLeft: 0,
            // marginLeft : '1em',
            borderRight: "1px solid black"
        },
        "&:nth-child(2)" : {
            color : "#000",
            paddingRight: 0
        },
        "&:hover": {
            border: "none",
            // borderBottom : "1px solid black",
            "&:nth-child(1)" : {
                borderRight: "1px solid black"
            }
        }
    },
    stepContent: {

        overflow: "auto",
        paddingBottom: "80px!important",
        // [theme.breakpoints.up('md')]: {
        //     maxHeight: `calc(100vh - 350px)`,
        // },
        // [theme.breakpoints.down('sm')]: {
        //     maxHeight: `calc(100vh - 350px)`,
        // },
        "& #return_stock_wizard_internal": {
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            padding: '0 0% 2% 0%',
        },
        "& .stockContain": {
            padding: '2%',
            width: '100%',
        }
    },
    stepResults: {
        width: '100%',
        overflow: "auto",
        paddingBottom: "80px!important",
        // [theme.breakpoints.up('md')]: {
        //     maxHeight: `calc(100vh - 350px)`,
        // },
        // [theme.breakpoints.down('sm')]: {
        //     maxHeight: `calc(100vh - 350px)`,
        // },
        "& #return_stock_results": {
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            padding: '0 0% 2% 0%',
        },
        "& .stockContain": {
            padding: '2%',
            width: '100%',
        }
    }
}))

const ThreePLImportation = ({
    // selectors
    requestId,
    jsonData,
    imported,
    csvError
}) => {
    const [isImportationOpen, setIsImportationOpen] = useState(true)
    const [isViewResultOpen, setIsViewResultOpen] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        if(imported) {
            setIsViewResultOpen(true)
            setIsImportationOpen(false)
        }
    }, [imported])

    const onClose = () => {
        setIsImportationOpen(false)
    }
    const openImportation = () => {
        setIsImportationOpen(true)
        setIsViewResultOpen(false)
    }
    const viewResults = () => {
        setIsImportationOpen(false)
        setIsViewResultOpen(true)
    }
    const fileValidate = () => {
        let flag = true
        let msg = ""
        if(csvError !== null && csvError.status === 'error') {
            flag = false
            msg = csvError.message
        } else if (jsonData === null) {
            flag = false
            msg = "Please upload at least one CSV file"
        }
        return {
            isValid: flag,
            validationResult: {
                message: msg,
                title: "Error",
                display: "toast"
            }
        }
    }
    return(
        <>
        
            <Grid container className={classes.root} id="return_stock_wizard_internal">
                <Grid item xs={12} id="taskdetail_title" className={classes.title}>
                    <Typography variant="h5"><SkbIcon classes={{ iconStyle: classes.avatarIcon }} icon={BusinessIcon} />3PL Importation
                    </Typography>
                </Grid>
            </Grid>
            <div
                className={classes.flexCenter}
                style={{marginTop: '15px'}}
            >
                <SkbButton style={{color: isImportationOpen ? '#13bbff' : 'black'}} className={classes.buttonLook} onClick={openImportation} text="Upload File" outlined />
                <SkbButton style={{color: isViewResultOpen ? '#13bbff' : 'black'}} onClick={viewResults} className={classes.buttonLook} text="Results" outlined />
            </div>
            <div>
                {isImportationOpen ? (
                    <Grid container className={classes.stepContent}>
                        <SelectSerializedCSV />
                    </Grid>
                ) : "" }
            </div>
            <div>
                {isViewResultOpen ? (
                    <Grid container className={classes.stepResults}>
                        <LoadThreePLImportationOutcomes />
                    </Grid>
                ) : "" }
            </div>
            
            
        </>
    )

}

const mapState = state =>({
    requestId : selectRequestId(state),
    jsonData : selectCSVJsonData(state),
    imported : selectThreePLImportationSuccess(state),
    csvError : selectCsvProcessingError(state)
})


export default connect(
    mapState,
    null,
)(ThreePLImportation);