import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';


const textFieldStyle = theme => ({
    root: {
        color:theme.palette.text.primary,
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: 18,
          
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: 18,
          },
    }
});

/**
 * multiline text field
 * @property {label}: lable for the text field
 * @property {placeholder}: placeholder for the text field
 * @property {value}: the value pass to the field
 * @property {rows}: how many rows forthe text field 
 * @property {onChange}: onchange handler for the text field
 * @property {size}: szie of fext field, options: 'small', 'medium'
 * @property {type}: html input type, 'password', 'number' etc.
 * @property {readOnly}: true: readonly textbox
 * @property {autoselect} true: when the input element is getting focus, highlight it's value
 */
const SkbTextField = withStyles(textFieldStyle)(props => {
    const { label, classes, readOnly, type, placeholder, size, value, onChange, rows, autoselect, ...other } = props;
    return (
        <TextField className={classes.root}
            variant="standard"
            label={label}
            placeholder={placeholder}
            defaultValue={value}
            value={value}
            onChange={onChange}
            size={size}
            type={type}
            InputProps={{
                readOnly: readOnly,
                shrink: true,
                classes:{input:classes.root},
                onFocus: (e)=>{if(autoselect) e.target.select()}
            }}
            {...other}
        />

    );
});


export default SkbTextField;