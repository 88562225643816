import React from 'react';
import {connect} from 'react-redux';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import SkbTable from '../../../../skb_controls/components/SkbTable';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// importing selectors
import {
    selectFilename,
    selectCSVJsonTableData,
    selectCsvDataVisible,
    selectCSVJsonData,


} from '../../../../Redux/ThreePLImportation/selectors';

// importing actions
import {
    toggleViewCsvData,

} from '../../../../Redux/ThreePLImportation/actions';


const RenderCsvData = ({
    // props
    open,
    handleClose,

    // selectors
    fileName,
    data,
    rawData,

}) => {

    const serialisedColumns = [
        { title: 'Serial Number', field: 'col1', type: 'text'},
        { title: 'Contractor ID', field: 'col2', type: 'text', onChange:()=>{}},
        { title: 'Condition', field: 'col3', type: 'text', onChange:()=>{}},
        { title: 'Status', field: 'col4', type: 'text', onChange:()=>{}},
        { title: 'Location', field: 'col5', type: 'text', onChange:()=>{}},
    ]
    const nonSerialisedColumns = [
        { title: 'Stock Code', field: 'col1', type: 'text'},
        { title: 'Quantity', field: 'col2', type: 'text', onChange:()=>{}},
        { title: 'Contractor Id', field: 'col3', type: 'text', onChange:()=>{}},
        { title: 'Condition', field: 'col4', type: 'text', onChange:()=>{}},
        { title: 'Status', field: 'col5', type: 'text', onChange:()=>{}},
        { title: 'Location', field: 'col6', type: 'text', onChange:()=>{}},
    ]

    return(
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {fileName || "Error"}
          </DialogTitle>
          <DialogContent>
              {
                  rawData
                  &&
                  data
                  &&
                  <SkbTable 
                        columns={rawData?.serialised ? serialisedColumns : nonSerialisedColumns}
                        dataSource={data} 
                    />
              }
            
          </DialogContent>
          <DialogActions>
                <SkbButton onClick={handleClose} size="medium" text={"CLOSE"} primary outlined/>
          </DialogActions>
        </Dialog>
    )
}


const mapState = state =>({
    fileName : selectFilename(state)?.name,
    data : selectCSVJsonTableData(state),
    open : selectCsvDataVisible(state),
    rawData : selectCSVJsonData(state),
})

const mapDispatch = dispatch =>({
    handleClose : ()=>dispatch(toggleViewCsvData()),
})

export default connect(mapState,mapDispatch)(RenderCsvData);