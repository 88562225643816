import { Actions } from '../actions/sync';
import { SkbLogger } from '../../services';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { FormatListBulleted } from '@material-ui/icons';

let initialStatus = { isPhotoSyncing: false, isMetadataSyncing:false, isOnline:true };

export function sync(state = initialStatus, action) {
    //SkbLogger.logDebugInfo("sync reducer ",action.type);
    switch (action.type) {
        case Actions.START_PHOTO_SYNC: 
            SkbLogger.applicationTrace("Data Sync - Flag",SeverityLevel.Information,
                                    "","Set isPhotoSyncing to true",'');
            return { ...state, isPhotoSyncing:true };
        case Actions.FINISH_PHOTO_SYNC: 
            SkbLogger.applicationTrace("Data Sync - Flag",SeverityLevel.Information,
                                    "","Set isPhotoSyncing to false",'');
            return { ...state, isPhotoSyncing:false };  
        case Actions.START_METADATA_SYNC: 
            SkbLogger.applicationTrace("Data Sync - Flag",SeverityLevel.Information,
                                    "","Set isMetadataSyncing to true",'');
            return { ...state, isMetadataSyncing:true };
        case Actions.FINISH_METADATA_SYNC: 
            SkbLogger.applicationTrace("Data Sync - Flag",SeverityLevel.Information,
                                    "","Set isMetadataSyncing to false",'');
            return { ...state, isMetadataSyncing:false };
        case Actions.GO_ONLINE: 
            SkbLogger.applicationTrace("Data Sync - Flag",SeverityLevel.Information,
                                    "","Set isOnline to true",'');
            return { ...state, isOnline:true };
        case Actions.GO_OFFLINE: 
            SkbLogger.applicationTrace("Data Sync - Flag",SeverityLevel.Information,
                                    "","Set isOnline to false",'');
            return { ...state, isOnline:false };
        default:
            return state;
    }
}
