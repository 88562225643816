 import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PhoneIcon from '@material-ui/icons/Phone';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {makeStyles } from '@material-ui/core';

/**
 * 
 * @property {options}: message to show. it is from redux reducer arrary contains phoneOwner and phone.[{"phoneOwner":"CET","phone":"tel:1300787043","phoneText":"1300787043"}];;
 * phoneOwner and phoneText will show in the dropdown as option label. phone will the be real phone to make the phone call.  
 * use callForAssistance redux action to update the options data.
 */

 


export  function CallForAssistance(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);


  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
 
  useEffect(() => {

  }, [props.store.callForAssistance])

  return (
    <div>
    {props.store.callForAssistance.phoneNumbers && props.store.callForAssistance.phoneNumbers.length !==0 &&
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} sm={12} md={12}>
          <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
            <Button startIcon={<PhoneIcon/>}
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              id="btn-callForAssistance"
            >
                Call for Assistance
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {props.store.callForAssistance.phoneNumbers.map((option, index) => (
                        <MenuItem
                          key={option.phone}
                      
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                            {/* <Grid id="containerid" container >
                            <Grid item xs={6} sm={6}>
                            <Typography variant="body2" >
                            {option.phoneOwner}:
                            </Typography> 
                            </Grid>
                            &nbsp; 
                             <Grid item xs={1} sm={1}></Grid> 
                            <Grid item xs={6} sm={6}>
                            <Typography>
                               <Link href={option.phone} variant="body2">
                            {option.phoneText}
                            </Link>
                            </Typography>
                              
                            </Grid>
                          </Grid>   */}
                       {/*    style={{width:250}}  */}
    
                       
                          
                               <Typography variant="body2" style={{width:"100%",textAlign:"start"}}> {option.phoneOwner}  :</Typography>&nbsp; 
                   
                               <Link href={option.phone}  variant="body2" >{option.phoneText}</Link>  
                       
                     
                         {/*  {option.phoneOwner}: &nbsp;<a href={option.phone}>{option.phoneText}</a> */}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid> }
    </div>
  );
} 


const mapStateToProps = state => {
  return { store: state};
};

 

export default connect(mapStateToProps,null)(CallForAssistance);
