import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import AppsIcon from '@material-ui/icons/Apps';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, darken } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Route, MemoryRouter, Switch } from 'react-router';

import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE } from '../Themes';
// import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SkbAppBar from './SkbAppBar'
import { SkbMenuItem } from './SkbMenuItem';
import SkbFooter from './SkbFooter'
import clsx from 'clsx';

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 50
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        },

    },
    drawerContainer: {
        paddingLeft: 0,
        paddingRight: 0
    },
    drawerMini: {
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(4) + 1,
            flexShrink: 0
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            height: `calc(100vh - ${LAYOUTCOMPONENT_SIZE.Footer.Height + LAYOUTCOMPONENT_SIZE.Header.Height}px)`,
            top: LAYOUTCOMPONENT_SIZE.Header.Height,
            bottom: LAYOUTCOMPONENT_SIZE.Footer.Height,
        },
        zIndex: 1,
        overflowY: "auto",
    },
    drawerPaperMini: {
        overflowX: 'hidden',
        width: theme.spacing(4) + 1,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            height: `calc(100vh - ${LAYOUTCOMPONENT_SIZE.Footer.Height + LAYOUTCOMPONENT_SIZE.Header.Height}px)`,
            top: LAYOUTCOMPONENT_SIZE.Header.Height,
            bottom: LAYOUTCOMPONENT_SIZE.Footer.Height,
        },
        zIndex: 1,
        overflowY: "auto",
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: LAYOUTCOMPONENT_SIZE.Header.Height,
        marginBottom: LAYOUTCOMPONENT_SIZE.Footer.Height, 
        [theme.breakpoints.down('md')]: {

            overflowY: "auto",
            maxHeight:`calc(100vh - ${LAYOUTCOMPONENT_SIZE.Footer.Height + LAYOUTCOMPONENT_SIZE.Header.Height}px)`,
        },
        zIndex: 1,
        marginTop:theme.spacing(2)
    },
    button: {
        color: theme.palette.primary.main,
    }
}));

export function SkbLayout(props) {
    const { container, children, id, changeAppTitle, AppTitle} = props;
    var items = children;


    if (items && !Array.isArray(items)) items = [children];

    const theme = useTheme();
    const classes = useStyles();

    // const [state, setState] = React.useState(props.state);
    const state = props.state;

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    let defatulPath = "";
    // console.log('props:', items, items[0].props);
    if (items && items.length > 0) defatulPath = items[0].props["to"];
    const initialEntries = [defatulPath];
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const hideMenu = () => {
        if(mobileOpen) setMobileOpen(!mobileOpen);
    };
    console.log("menu items", items);


    const drawer = (
        <Container component="main" className={classes.drawerContainer} >
            <div className={classes.toolbar} />
            <Divider />
            <List id={id} onClick={hideMenu}>
                {items && items.map((item, index) => {
                    if (!item.props['hideinmenu']) // the item will NOT show in the menue
                        return <SkbMenuItem changeAppTitle={changeAppTitle} key={item.props["text"]} icon={item.props["icon"]} text={item.props["text"]} //clickIcon={() => setOpen(!open)} 
                            to={item.props["to"]} />

                })}

                <Divider />
                {/* <ListItem style={{paddingLeft:4,
        paddingRight:4}} button key={"iconpack"}>
                    <ListItemIcon style={{marginRight:4, minWidth:8}} onClick={() => setOpen(!open)} ><AppsIcon className={classes.button} /></ListItemIcon>
                    <ListItemText >
                        <Link href="https://material-ui.com/components/material-icons/" target="__blank">Material UI Icons</Link>
                    </ListItemText>
                </ListItem> */}
            </List>
        </Container>
    );

    return (
        <div className={classes.root}>
            <MemoryRouter initialIndex="0" initialEntries={initialEntries}>
                <CssBaseline />
                <SkbAppBar {...props} AppTitle={AppTitle} changeAppTitle={changeAppTitle} handleDrawerToggle={handleDrawerToggle} />
                <nav className={clsx({ [classes.drawer]: open, [classes.drawerMini]: !open })} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: clsx({ [classes.drawerPaper]: open, [classes.drawerPaperMini]: !open }),
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: clsx({ [classes.drawerPaper]: open, [classes.drawerPaperMini]: !open }),
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <Switch>
                        {/* <Route exact path="/" component={ListDemo} >
                            <Typography variant="h3">Welcome to StyleGuide
                            </Typography>
                            <Divider />
                        </Route> */}

                        {items && items.map((item, index) => (
                            <Route key={item.props["text"]} exact path={item.props["to"]} component={item.props["component"]} />

                        ))}
                    </Switch>

                </main>
                <SkbFooter />
            </MemoryRouter>
        </div>
    );
}

SkbLayout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.any,
};

//export default ResponsiveDrawer;
