import types from './types';
import {TaskService} from '../../services';


const loadingTasksStart = () =>({
    type : types.TASKS_LIST_START,
});
const tasksLoaded = data =>({
    type : types.TASK_LOADED,
    payload : data,
});
const tasksError = error =>({
    type: types.TASKS_ERROR,
    payload : error,
})

export const loadConsignmentTasks = () => {
    return async dispatch => {
        dispatch(loadingTasksStart());
        try{
            const tasksListData = await TaskService.loadConsignmentTaskList();
            dispatch(tasksLoaded(tasksListData));
        }catch(e){
            console.log(e);
            dispatch(tasksError(e))
        }
    }
}


export const sortByOrder = value =>({
    type : types.SET_SORT_ORDER,
    payload : value,
});

export const filterTasksByStatus = value =>({
    type : types.FILTER_TASKS_BY_STATUS,
    payload : value,
})

export const setSelectedTasksRows = rows =>({
    type : types.SET_SELECTED_TASKS_ROW,
    payload : rows,
})
export const clearSelectedTaskRows = () =>({
    type : types.CLEAR_SELECTED_TASKS_ROW,
});


export const setColumnToChange = colName =>({
    type : types.SET_COLUMN_TO_CHANGE,
    payload : colName,
})
export const changeStatusInStore = status =>({
    type : types.CHANGE_STATUS_IN_STORE,
    payload : status,
})
export const searchStockTasks = word => ({
    type : types.SEARCH_STOCK_TASKS,
    payload : word,
})

export const markStockItemsMissing = () =>({
    type : types.MARK_STOCK_ITEMS_MISSING,
});
export const markStockItemsDOA = () => ({
    type : types.MARK_STOCK_ITEMS_DOA,
});
export const markStockItemsAvailable = () =>({
    type : types.MARK_STOCK_ITEMS_AVAILABLE,
})
export const markStockItems = status =>({
    type : types.MARK_STOCK_ITEMS,
    payload : status,
})



export const fetchConsignmentListStart = () => ({
    type : types.FETCH_CONSIGNMENT_LIST_START,
})
export const fetchConsignmentListSuccess = data => ({
    type : types.FETCH_CONSIGNMENT_LIST_SUCCESS,
    payload : data,
});
export const fetchConsignmentListError = error =>({
    type : types.FETCH_CONSIGNMENT_LIST_ERROR,
    payload : error,
});


export const fetchConsignmentDetailsStart = id => ({
    type : types.FETCH_CONSIGNMENT_DETAILS_START,
    id,
});
export const fetchConsignmentDetailsSuccess = data => ({
    type : types.FETCH_CONSIGNMENT_DETAILS_SUCCESS,
    payload : data,
});
export const fetchConsignmentDetailsError = error =>({
    type : types.FETCH_CONSIGNMENT_DETAILS_ERROR,
    payload : error,
});


export const fetchConsignmentInfoStart = id =>({
    type : types.FETCH_CONSIGNMENT_INFO_START,
    id,
});
export const fetchConsignmentInfoSuccess = data =>({
    type : types.FETCH_CONSIGNMENT_INFO_SUCCESS,
    payload : data,
});
export const fetchConsignmentInfoError = error =>({
    type : types.FETCH_CONSIGNMENT_INFO_ERROR,
    payload : error,
});


export const filterByStatusStart = status =>({
    type : types.FILTER_BY_STATUS_START,
    status,
});
export const filterByStatusSuccess = data =>({
    type : types.FILTER_BY_STATUS_SUCCESS,
    payload : data,
});
export const filterByStatusError = error =>({
    type : types.FILTER_BY_STATUS_ERROR,
    payload : error,
})

export const clearFilterStatus = ()=>({
    type : types.CLEAR_FILTER_STATUS,
})


export const searchBySerialNumberStart = phrase =>({
    type : types.SEARCH_BY_SERIAL_NUMBER_START,
    phrase,
});
export const searchBySerialNumberSuccess = data =>({
    type : types.SEARCH_BY_SERIAL_NUMBER_SUCCESS,
    payload : data,
});
export const searchBySerialNumberError = error =>({
    type : types.SEARCH_BY_SERIAL_NUMBER_ERROR,
    payload : error,
})

export const searchByStockCodeStart = phrase =>({
    type : types.SEARCH_BY_STOCK_CODE_START,
    phrase,
});
export const searchByStockCodeSucess = data =>({
    type : types.SEARCH_BY_STOCK_CODE_SUCCESS,
    payload : data,
})
export const searchByStockCodeError = error =>({
    type : types.SEARCH_BY_STOCK_CODE_ERROR,
    payload : error,
})

export const searchByConsignmentIdStart = phrase =>({
    type : types.SEARCH_BY_CONSIGNMENT_ID_START,
    phrase,
});
export const searchByConsignmentIdSuccess = data =>({
    type : types.SEARCH_BY_CONSIGNMENT_ID_SUCCESS,
    payload : data,
});
export const searchByConsignmentIdError = error =>({
    type : types.SEARCH_BY_CONSIGNMENT_ID_ERROR,
    payload : error,
})


export const updateNonSerialisedItemQuantity = data =>({
    type : types.UPDATE_NON_SERIALISED_ITEM_QTY,
    payload : data,
})


export const overrideConsignmentActualStart = (data,id) =>({
    type : types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_START,
    payload : data,
    id,
});
export const overrideConsignmentActualSuccess = data =>({
    type : types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_SUCCESS,
    payload : data,
});
export const overrideConsignmentActualError = error =>({
    type : types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_ERROR,
    payload : error,
});


export const overrideConsignmentRequestedStart = data =>({
    type : types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_START,
    payload : data,
});
export const overrideConsignmentRequestedSuccess = data =>({
    type : types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_SUCCESS,
    payload : data,
});
export const overrideConsignmentRequestedError = error =>({
    type : types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_ERROR,
    payload : error,
});


export const clearSearchConsignmentData = () =>({
    type : types.CLEAR_SEARCH_CONSIGNMENT_DATA,
})


export const updateConsignmentStatusStart = data =>({
    type : types.UPDATE_CONSIGNMENT_STATUS_START,
    payload : data,
});
export const updateConsignmentStatusSuccess = () =>({
    type : types.UPDATE_CONSIGNMENT_STATUS_SUCCESS,
});
export const updateConsignmentStatusError = error => ({
    type : types.UPDATE_CONSIGNMENT_STATUS_ERROR,
    payload : error,
});


export const searchStockItems = term => ({
    type : types.SEARCH_STOCK_ITEMS,
    payload : term,
});