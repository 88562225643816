import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as taskActions from '../../../../Redux/actions/tasks';
import TaskStepper from './TaskStepper';
import * as assistanceNumber from '../../../../Redux/actions/callForAssistance';

const taskConfig = require('../taskConfig.json');

const useStyles = makeStyles((theme) => ({
    root: {

        marginTop: theme.spacing(2),
        '& >div.MuiGrid-item:not(:first-child)':{
            borderTop: `1px solid ${theme.palette.text.primary}`,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            // '&:last-child':{
            //     borderBottom: `1px solid ${theme.palette.text.primary}`,
    
            // },
        },
    },
    title:{
        textAlign: "center",
    },
    safari: {
        height: '-webkit-fill-available',
    }
}));

export function TaskDetails({ CurrentTask, backToTaskList, updateAssistanceNumbers }) {
    const classes = useStyles();
    const taskSetting = taskConfig[CurrentTask.TaskType];
    const assistanceNumbers = CurrentTask.Programs.map((p) => {
        return {
            phoneOwner: p.ProgramName,
            phone: `tel:${p.PhoneNumber}`,
            phoneText: p.PhoneNumber
        }
    }
    );
    useEffect(() => {
        //loadTasks(); // load task from data sync, comment out in dev stage

        updateAssistanceNumbers(assistanceNumbers);
        return () => {
            updateAssistanceNumbers([]);
        }
    }, []);
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} id="taskdetail_title" className={classes.title} onClick={backToTaskList}>
                <Typography variant="h5">{taskSetting.TaskTitlePrefix} {CurrentTask.TaskId}</Typography>
            </Grid>
            {/* <Grid item xs={12} id="taskdetail_stepview">
                <Typography variant="body1">show step content here</Typography>
            </Grid> */}
            <Grid item xs={12} id="taskdetail_steppers" className={classes.safari}>
                <TaskStepper/>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    Tasks: state.tasks,
    User: state.auth.User,
    CurrentTask: taskActions.getCurrentTask(state.tasks),
    CurrentSubTask: taskActions.getCurrentSubTask(state.tasks) 
});


const mapDispatchToProps = (dispatch) => {
    return {
        backToTaskList: ()=>{dispatch(taskActions.showTaskDetails(false));},
        updateAssistanceNumbers: (e) => dispatch(assistanceNumber.updatePhoneNumber(e)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);