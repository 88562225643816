const consignmentTaskListDummyData = [

    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1816",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Contractor drop-off",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "Ready to dispatch",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },

            
        ]
    },

    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1817",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Skybridge Courier",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "Ready for Pickup",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },

            
        ]
    },

    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1818",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Contractor drop-off",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "In Transit",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },

            
        ]
    },

    

    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1819",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Skybridge Courier",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "Awaiting Acknowledgement",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },

            
        ]
    },
    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1820",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Contractor drop-off",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "Acknowledged",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },
            
        ]
    },
    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1821",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Skybridge Courier",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "In Transit",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },

            
        ]
    },
    {
        "CompletedDate": "",
        "EndTime": "2099-12-31T23:59:59",
        "CompanyName": "Graham John Waters",
        "TaskDescription": "Generic Task for all ReturnStock Consignments",
        "StartTime": "2021-06-15T05:33:43.",
        "TaskId": "302",
        "CompanyTaskStatus": "Not Started",
        "TaskType": "CONSIGNMENT",
        "CompanyId": "104",
        "SubTasks": [
            {
                "SubTaskId": "1822",
                "DisplayName": "23 Teresa Street  Nikenbah QLD 4655",
                "Status": "Not Started",
                "ReferenceID": "158",
                "Type": "Consignment",
                "PickupAddressLine1": "23 Teresa Street",
                "ModifiedBy": "Bodhisatwa Barma",
                "DeliveryMethod": "Skybridge Courier",
                "DeliveryDestination": "SKB Townsville Warehouse",
                "DeliveryAddressSuburb": "West End",
                "DeliveryAddressState": "QLD",
                "CompanyId": "104",
                "DropOffWindowDate": "",
                "SkbNotes": "",
                "PickupAddressPostcode": "4655",
                "PickupWindowETA": "",
                "ReturnType": "Return All Stock",
                "TaskStatus": "Cancelled",
                "PickupAddressLine2": "",
                "DeliveryAddressLine2": "",
                "PickupAddressSuburb": "Nikenbah",
                "PickupWindowDate": "",
                "DeliveryAddressLine1": "C/- Kennard / Millers  52 Bank St",
                "PickupDueday": "2021-08-23T00:00:00",
                "ModifiedDate": "2021-07-30T04:37:05.715",
                "IsForkliftRequired": "false",
                "NextActionBy": "Skybridge",
                "IsATPAuthorised": "false",
                "CompanyName": "Graham John Waters",
                "DateCreated": "2021-07-27T04:36:51.870",
                "DropOffWindowETA": "",
                "DeliveryAddressPostcode": "4101",
                "PickupAddressState": "QLD"
            },

            
        ]
    }
]


export default consignmentTaskListDummyData;