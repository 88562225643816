import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import SkbIcon from '../../../skb_controls/components/SkbIcon'
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { currentTimeToUTCTimeInString, StringFormat } from '../../../utils/misc';
import { SignalCellularNullRounded } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    completeIcon:{
        width: 'auto',
        height: 'auto',
        borderRadius: "2px",
        flexGrow: 0,
        borderStyle:'solid',
        borderColor:theme.palette.text.primary,
        color:theme.palette.success.main
    },
    cancelIcon:{
        width: 'auto',
        height: 'auto',
        borderRadius: "2px",
        flexGrow: 0,
        borderStyle:'solid',
        borderColor:theme.palette.text.primary,
        color:theme.palette.error.main
    },
    dayNumber:{
        width: 'auto',
        height: 'auto',
        borderRadius: "2px",
        textAlign:"center",
        flexGrow: 0,
        fontSize:"8rem",
        borderStyle:'solid',
         [theme.breakpoints.down("xs")]: {
            fontSize:"3rem",
        } 
    },
    redText: {
        color: theme.palette.error.main,
    },

    done: {
        color: theme.palette.success.main,
    },

    overDueDaynumber:{
        width: 'auto',
        height: 'auto',
        borderRadius: "2px",
        textAlign:"center",
        flexGrow: 0,
        color:theme.palette.error.main,
        fontSize:"8rem",
        borderStyle:'solid',
         [theme.breakpoints.down("xs")]: {
            fontSize:"3rem",
        } 
    },
    imageDiv:{
        [theme.breakpoints.down("xs")]: {
           width:"60px"
        },
        width:"220px",
        // paddingLeft:"8px" 
    }
}));



const ConsignmentInfographic =({subTaskStatus,deliveryMethod,pickUpDueday,returnDueDay}) =>{
    const classes = useStyles();
    console.log('subTaskStatus,deliveryMethod,pickUpDueday,returnDueDay',subTaskStatus,deliveryMethod,Date.parse(pickUpDueday),returnDueDay)
    if(subTaskStatus ==="Delivered"){
        return <div id="DeliveredIcon" className={classes.imageDiv}>
        <DoneIcon className={classes.completeIcon}  />
        <Typography style={{textAlign:'center'}} variant='body1'>Delivered</Typography>
        </div>
    }
    if(subTaskStatus ==="Cancelled"){
        return <div  id="CancelledIcon" className={classes.imageDiv}>
          
        <CloseIcon className={classes.cancelIcon}  />
       
        <Typography style={{textAlign:'center'}} variant='body1'>Cancelled</Typography>
        </div>
    }
     if(deliveryMethod ==="Skybridge courier"){
        var days=0;
        
        if(pickUpDueday && pickUpDueday!==""){
            days=Math.round((Date.parse(pickUpDueday)-Date.parse(currentTimeToUTCTimeInString(StringFormat.ServerDateFormat)))/(1000*60*60*24));
        } 
        if(days && days >=0){
            return <div id="pickupIcon" className={classes.imageDiv}>
            <Typography className={classes.dayNumber} >{days?days:0}</Typography>
            <Typography style={{textAlign:'center'}} variant='body1'>days till pickup</Typography>
            </div>
        }else if (days && days<0){
            days=Math.abs(days);
            return <div id="overDueIcon" className={classes.imageDiv}>
            <Typography className={classes.overDueDaynumber} >{days?days:0}</Typography>
            <Typography style={{textAlign:'center'}} className={classes.redText} variant='body1'>days overdue for pickup&nbsp;</Typography>
            </div>
        }else{
            return <div id="scheduleIcon" className={classes.imageDiv}>
            <Typography className={classes.dayNumber} >-</Typography>
            <Typography style={{textAlign:'center'}} variant='body1'>To be scheduled</Typography>
            </div> 
        }
        
    }
    if(deliveryMethod ==="Contractor drop-off"){
        var days=0;
        
        if(returnDueDay && returnDueDay!==""){
            days=Math.round((Date.parse(returnDueDay)-Date.parse(currentTimeToUTCTimeInString(StringFormat.ServerDateFormat)))/(1000*60*60*24));
        } 
        if(days && days >=0){
            return <div id="dropoffIcon" className={classes.imageDiv}>
            <Typography className={classes.dayNumber} >{days?days:0}</Typography>
            <Typography style={{textAlign:'center'}} variant='body1'>days till drop-off</Typography>
            </div>
        }else if (days && days<0){
            days=Math.abs(days);
            return <div id="dropoffDueIcon" className={classes.imageDiv}>
            <Typography className={classes.overDueDaynumber} >{days?days:0}</Typography>
            <Typography style={{textAlign:'center'}} className={classes.redText} variant='body1'>days overdue for drop-off</Typography>
            </div>
        }else{
            return <div id="dropoffScheduleIcon" className={classes.imageDiv}>
            <Typography className={classes.dayNumber} >-</Typography>
            <Typography style={{textAlign:'center'}} variant='body1'>To be scheduled</Typography>
            </div> 
        }

    } 
  }


export default ConsignmentInfographic