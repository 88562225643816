import React, { useEffect, useState } from 'react';

import MaterialTable from 'material-table';

import { tableIcons } from '../_share/tableIcons';

import { Link } from '@material-ui/core';

import _ from 'lodash';

import SkbIcon from '../../../skb_controls/components/SkbIcon';

import {ArrowDropUp, ArrowDropDown} from '@material-ui/icons';

import {useStocktakeTableStyles} from './stocktake/StepStockPreview';

//This function groups the serialised items into names and counts to match the list of non-serialised items.
export function groupSerialisedItems(serialisedItems) {
  let goodItems = _.filter(serialisedItems, { ItemStatus: "Good" });
  let badItems = _.filter(serialisedItems, { ItemStatus: "Faulty" });

  let goodGroupedItems = _.groupBy(goodItems, 'StockCode');
  let badGroupedItems = _.groupBy(badItems, 'StockCode');

  //Group the good items:
  let goodSerialisedItemsCounts = Object.keys(goodGroupedItems).map(k => {
    return {
      StockCode: k,
      Description: goodGroupedItems[k][0].Description,
      //  StockType: goodGroupedItems[k][0].StockType, 
      QtyUnit: goodGroupedItems[k][0].QtyUnit,
      IsSerialised: goodGroupedItems[k][0].IsSerialised ?? 1,
      GoodQty: goodGroupedItems[k].length,
      FaultyQty: badGroupedItems[k] == null ? 0 : badGroupedItems[k].length ?? 0
    };
  });

  //Add any stockitems that only had bad items:
  let badSerialisedItemsCounts = Object.keys(badGroupedItems).filter(x => !Object.keys(goodGroupedItems).includes(x)).map(k => {
    return {
      StockCode: k,
      Description: badGroupedItems[k][0].Description,
      //StockType: badGroupedItems[k][0].StockType, 
      IsSerialised: badGroupedItems[k][0].IsSerialised ?? 1,
      QtyUnit: badGroupedItems[k][0].QtyUnit,
      GoodQty: 0,
      FaultyQty: badGroupedItems[k].length
    };
  });

  let serialisedItemsCounts = goodSerialisedItemsCounts.concat(badSerialisedItemsCounts);

  return serialisedItemsCounts.sort(alphabeticalSort);
}

export function alphabeticalSort(a, b) {
  if (a.Description.toUpperCase() < b.Description.toUpperCase()) {
    return -1;
  }
  else {
    return 1;
  }
}

function groupExpectedItems(expectedItems) {
  let goodItems = _.filter(expectedItems, { Condition: "Good" });  //Different from serialised function.
  let badItems = _.filter(expectedItems, { Condition: "Faulty" });//Different from serialised function.

  let goodGroupedItems = _.groupBy(goodItems, 'StockCode');
  let badGroupedItems = _.groupBy(badItems, 'StockCode');
 
  //Group the good items:
  let goodItemsCounts = Object.keys(goodGroupedItems).map(k => {
    return {
      StockCode: k,
      Description: goodGroupedItems[k][0].Description,
      
      QtyUnit: goodGroupedItems[k][0].QtyUnit,
      IsSerialised: goodGroupedItems[k][0].StockType=== "Serialised" ? 1:0,       //Different from serialised function.
      GoodQty: goodGroupedItems[k][0].Qty,
      FaultyQty: (!badGroupedItems[k]||badGroupedItems[k][0] == null) ? 0 : badGroupedItems[k][0].Qty ?? 0    //Enhanced from serialised function.
    };
  });

  //Add any stockitems that only had bad items:
  let badItemsCounts = Object.keys(badGroupedItems).filter(x => !Object.keys(goodGroupedItems).includes(x)).map(k => {
    return {
      StockCode: k,
      Description: badGroupedItems[k][0].Description,
      
      IsSerialised: badGroupedItems[k][0].IsSerialised=== "Serialised" ? 1:0,     //Different from serialised function.
      QtyUnit: badGroupedItems[k][0].QtyUnit,
      GoodQty: 0,
      FaultyQty: badGroupedItems[k][0].Qty
    };
  });

  let itemsCounts = goodItemsCounts.concat(badItemsCounts);

  return itemsCounts.sort(alphabeticalSort);
}

function appendDiscrepancies(actualItem, expectedItemsGrouped)
{
  var expectedForActual=expectedItemsGrouped.find(e=>e.StockCode===actualItem.StockCode);

  //If no expected item found to match the actual item:
  if (expectedItemsGrouped===[]||!expectedForActual)
  {
    return {
      StockCode: actualItem.StockCode, 
     Description: actualItem.Description, 
     QtyUnit: actualItem.QtyUnit, 
     GoodQty: actualItem.GoodQty, 
     FaultyQty: actualItem.FaultyQty , 
     IsSerialised:actualItem.IsSerialised, 
     DiscrepancyGood:actualItem.GoodQty,
     DiscrepancyFaulty:actualItem.FaultyQty
   };
 
  }
  else {
   
      return {
        StockCode: actualItem.StockCode, 
        Description: actualItem.Description, 
        QtyUnit: actualItem.QtyUnit, 
        GoodQty: actualItem.GoodQty, 
        FaultyQty: actualItem.FaultyQty , 
        IsSerialised:actualItem.IsSerialised, 
        DiscrepancyGood:actualItem.GoodQty-expectedForActual.GoodQty,
        DiscrepancyFaulty:actualItem.FaultyQty-expectedForActual.FaultyQty
      };
}

}

export function mergeExpectedWithActual(actualItems, expectedItems){

  var expectedItemsGrouped=groupExpectedItems(expectedItems);
  
  var actualItemsWithDiscrepanciesAdded=actualItems.map(a=> appendDiscrepancies(a, expectedItemsGrouped));

  //Then there may be some items which were expected but not counted/scanned:
  var expectedItemsNotInActual=expectedItemsGrouped.filter(e=>actualItems.findIndex(a=>a.StockCode===e.StockCode)===-1);

  var expectedItemsNotInActualFormattedForReturn= expectedItemsNotInActual.map(e=> { return {GoodQty: 0, FaultyQty: 0 ,DiscrepancyGood:-e.GoodQty, DiscrepancyFaulty:-e.FaultyQty,StockCode: e.StockCode, Description: e.Description, QtyUnit: e.QtyUnit,  IsSerialised:e.IsSerialised}});

  //Final result:
  return actualItemsWithDiscrepanciesAdded.concat(expectedItemsNotInActualFormattedForReturn).sort(alphabeticalSort);
}


/**
 * component:The Stock Review Step shows a summary of the counted/picked items from previous steps for the contractor to review and correct.  
 * @param {array}  serialisedItems: the counted or scanned serialised items
 *              e.g.  [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134"
                    },...]
 * * @param {array}  nonSerialisedItems: the counted or picked non-serialised items
 *              e.g. [
                    {
                        StockCode: "SKB001",
                        Description: "cable",
                        QtyUnit: "Metre",
                        GoodQty: 45,
                        FaultyQty: 20
                    },...]
 * @param {array}  expectedItems: the quantities that we expected the user to pick or count, including both serialised and non-serialised.
 *              e.g.            []     {
                    StockCode: "EROD0001",
                    Description: "Ericsson Wireless Outdoor Unit",
                    StockType: "Serialised",
                    Condition: "Good", --Note that this is equivalent to ItemStatus in the serialisedItems array.  
                    Qty: 20,
                    QtyUnit: "Each",
                },
                {
                    StockCode: "ERMI0001",
                    Description: "Ericsson Wireless Wall Plate Kit",
                    StockType: "Non-Serialised",
                    Condition: "Faulty",  --Note that this is equivalent to ItemStatus in the serialisedItems array.  
                    Qty: 30,
                    QtyUnit: "Each",
                },...]

 * @param {function} loadSerialisedItems: a function to load the serialised items.
 * @param {function} loadNonserialisedItems: a function to load the nonserialised items.
 * * @param {function} loadExpectedItems: a function to load the expected items.
* @param {array}  columnHeaders: the columnHeaders in order ['Description','UOM','Expected Status','# To Be Returned']

 * @param {function} goToStep: A function to navigate between this step and the related serialised and non-serialised steps in the same process.
 *                              Parameters stepId, selectedStockCode (to be highlighted on arrival at the new step).
 *                  OPTIONAL: If not supplied, there will be no hyperlink for the stock description.  It will just display as plain text.  
 * @param {number} serialisedStepId: The Id of the serialised step, to be used for cross-navigation.  Required only if goToStep provided.
 * @param {number} nonSerialisedStepId: The Id of the non-serialised step, to be used for cross-navigation.  Required only if goToStep provided.
 * @param {text} emptyDataMessage: text to display when there is no data.

 * @returns 
 */
export default function StepStockReview(props) {
  //default values:
  var noDataMessage=props.emptyDataMessage;

  if(!noDataMessage)
  {
      noDataMessage='No results have been recorded so far!';
  }
  var columnNames=props.columnHeaders;

  if (!columnNames)
  {
      columnNames= ['Description','UOM','# Good','# Faulty'];
  }
  //end default values.

  const [data, setData] = useState([]);

  const [columns, setColumns] = useState([]);

  const UpArrow=props=>{return <SkbIcon  icon={ArrowDropUp} style={{ color: '#00cc00', verticalAlign:'middle', marginLeft:'2%', marginRight:'-2%'   }}/>}
  const DownArrow=props=>{return <SkbIcon  icon={ArrowDropDown} style={{ color: '#ff0000' , verticalAlign:'middle', marginLeft:'2%', marginRight:'-2%' }}/>}

  var reducedSizeText={    fontSize:'0.70em'};

  const createTextWithArrow= (text, discrepancyValue)=>{
    var returnValue;
    if (discrepancyValue>0)
    {
      returnValue=<div>{text}<UpArrow/><span  style={reducedSizeText}> {discrepancyValue}</span></div>
    }
    else if (discrepancyValue<0)
    {
      returnValue=<div>{text}<DownArrow/><span style={reducedSizeText}> {Math.abs(discrepancyValue)}</span></div>;
    }
    else {
      returnValue=text;
    }
  
    return returnValue;
    
  }

  useEffect(() => {
    if (!props.serialisedItems) props.loadSerialisedItems();
    if (!props.nonSerialisedItems) props.loadNonSerialisedItems();
    if (!props.expectedItems) props.loadExpectedItems();
  }, []);

  useEffect(() => {
    if (props.serialisedItems && props.nonSerialisedItems) {
      //Making deep copies of the serialised/non-serialised items from the state:
      let serialisedItems = props.serialisedItems.map(i => ({ ...i }));

      let nonSerialisedItemCounts = props.nonSerialisedItems.map(i => ({ ...i }));

      //Adding some default properties that don't exist.
      for (var i = 0; i < serialisedItems.length; i++) {
        serialisedItems[i].IsSerialised = 1;
        serialisedItems[i].QtyUnit = 'Each';
      }

      for (var j = 0; j < nonSerialisedItemCounts.length; j++) {
        nonSerialisedItemCounts[j].IsSerialised = 0;
      }

      
  //Group the serialised items then combine the two lists:
      let serialisedItemsCounts = groupSerialisedItems(serialisedItems);

      let groupedItems = nonSerialisedItemCounts.concat(serialisedItemsCounts);
      let groupedItemsSorted = groupedItems.sort(alphabeticalSort);

      var finalDataList=mergeExpectedWithActual(groupedItemsSorted, props.expectedItems);

      setData(finalDataList);

    }
  }, [props.serialisedItems, props.nonSerialisedItems, props.expectedItems]);


  React.useEffect(() => {

    //set up table columns

    const addFunction=(total, nextValue)=>total+nextValue;

    var goodDiscrepancyTotal=(data.map(d=>d.DiscrepancyGood).reduce(addFunction,0));
    var faultyDiscrepancyTotal=(data.map(d=>d.DiscrepancyFaulty).reduce(addFunction,0));

    var tableColumnsDefinition=[
      { title: 'StockCode', field: 'StockCode', hidden: true, editable: 'never' },
      {
        title: columnNames[0], field: 'Description', hidden: false, editable: 'never', width: '40%', align: 'left',
        render: rowData => (
          props.goToStep===undefined?
          rowData.Description
          :
         ( rowData.IsSerialised === 1 ?
            <Link id={`serialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.serialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
            :
            <Link id={`nonserialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.nonSerialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
            )

        )
    
      },
    
      {
        title: columnNames[1], field: 'QtyUnit', hidden: false, editable: 'never', width: '5%', align: 'right'
    
      },
      {
        title: createTextWithArrow(columnNames[2], goodDiscrepancyTotal), field: 'GoodQty', hidden: false, editable: 'never', width: '10%', align: 'center',
        render: rowData => (
          createTextWithArrow(rowData.GoodQty,rowData.DiscrepancyGood)
         
        )
    
      },
      {
        title: createTextWithArrow(columnNames[3], faultyDiscrepancyTotal), field: 'FaultyQty', hidden: false, editable: 'never', width: '10%', align: 'center',
        render: rowData => (
          createTextWithArrow(rowData.FaultyQty,rowData.DiscrepancyFaulty)
        )
    
      }
    
    ];
    
     setColumns(tableColumnsDefinition); 

  }, [data])


  const classes = useStocktakeTableStyles();

  return (

    <div id="review-table" className={classes.table} >

      <MaterialTable
        icons={tableIcons}
        showTitle={false}
        searchable={false}
        toolbar={false}
        columns={columns}
        data={data}
        padding={'dense'}

        options={{
          toolbar: false,
          showTitle: false,
          search: false,
          padding: 'dense',
          paging: false,
          headerStyle:
          {
            fontWeight: "bolder",
            fontSize: "1.2em"

          },

        }

        }
        localization={{ body: { emptyDataSourceMessage: noDataMessage } }}
      />

    </div>


  );
}


