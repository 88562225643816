import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './Redux/StoreProvider';
import { ThemeProvider, Hidden } from '@material-ui/core';
import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE } from './skb_controls/Themes'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import { Auth0Provider } from "@auth0/auth0-react";
import RequireAuth from './components/auth/requiredAuth'
import SignIn from './components/auth/SignIn';
import Logout from './components/auth/Logout';

import TFACodeVerification from './components/registration/TFACodeVerification';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loading from './components/Loading';
import TermsAndCons from './components/TermsAndCons';
import Crypt from './components/Crypt';
import AzureBlobContainer from './components/AzureBlobContainer';
import { config } from './utils/config';
import { ManageVariables } from './components/modules/ManageVariables';
import TelemetryProvider from '../src/services/telemetry-provider';
import { getAppInsights } from '../src/services/TelemetryService';
import { crypts } from './utils/crypts';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { SkbLogger } from './services';
import SyncSWUnitTest from './components/sync/SyncSWUnitTest';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'grid',
        gridRowGap: 10,
        alignContent: 'start',
        gridTemplateColumns: 'repeat(1, 1fr)',
        height: '100vh',
        gridTemplateAreas:
            `"header"
            "content"
            `,

    },
    content: {
        gridArea: "content",

        top: LAYOUTCOMPONENT_SIZE.Header.Height
    },
}));

//use auth0 Client to replace Auth0Provider (useAuth0) wrapper
//so that we have more control for offline feature
//this auth0 client is to be passed down into other Components (App.js, SkbLayout.js, Logout.js and SignIn.js) through props
const auth0 = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: config.REACT_APP_AUTH0_CLIENTID
});

SkbLogger.logDebugInfo('Auth0Client created', auth0);
const THEME_STORAGE_KEY = "_theme_";

function AppWrapper() {
    const savedTheme = localStorage.getItem(THEME_STORAGE_KEY) === "DarkTheme"? DarkTheme:LightTheme;//get user prefered theme from localstorage
    const [state, setState] = React.useState({
        darkTheme: savedTheme === DarkTheme,
        theme: savedTheme,
    });
    const theme = useTheme();
    const classes = useStyles();

    const changeTheme = name => checked => {
        console.log(name);
        localStorage.setItem(THEME_STORAGE_KEY, checked?"DarkTheme":"LightTheme");
        setState({ ...state, ...{ [name]: checked }, ...{ theme: checked ? DarkTheme : LightTheme } });

    };
    return (
        <ThemeProvider theme={state.theme}>
            <div className={classes.layout}>
                <div className={classes.content}>
                    <App state={state} changeTheme={changeTheme} auth0={auth0} />
                </div>
            </div>
        </ThemeProvider >

    );
}

let appInsights = null;

let isAuth = true;
/*
let exp = crypts.decrypt(localStorage.getItem('expires_at'));
if (!exp) isAuth = false;
let expiresAt = JSON.parse(exp);
isAuth = ( new Date().getTime() < expiresAt);
*/
ReactDOM.render(<StoreProvider>

    <div>
        {/*
            <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN} clientId={config.REACT_APP_AUTH0_CLIENTID} response_type={"id_token"} redirectUri={window.location.origin}>
            */}
        <BrowserRouter>
            <TelemetryProvider instrumentationKey={config.REACT_APP_APPINSIGHTS_IKEY} after={() => { appInsights = getAppInsights() }}>
                <Switch>
                    <Route exact path='/' component={RequireAuth(AppWrapper, auth0)} />
                    <Route exact path='/loading' component={Loading} />
                    <Route exact path='/auth' component={() => <SignIn auth0={auth0} />} />
                    <Route exact path='/logout' component={() => <Logout auth0={auth0} />} />
                    <Route exact path='/termsandcons' component={TermsAndCons} />
                        ((process.env.REACT_APP_ENV_TYPE=='local' || process.env.REACT_APP_ENV_TYPE=='dev')) && (<Route exact path='/crypt' component={Crypt} />)
                        ((process.env.REACT_APP_ENV_TYPE=='local' || process.env.REACT_APP_ENV_TYPE=='dev')) && (<Route exact path='/sw-ut' component={SyncSWUnitTest} />)
                        <Route exact path='/managevariables' component={RequireAuth(ManageVariables, auth0)} />

                </Switch>
            </TelemetryProvider>
        </BrowserRouter>
        {/*
            </Auth0Provider>
            */}
    </div>
</StoreProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
