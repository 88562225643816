import _ from 'lodash';
import { stockConsignment } from '../../Redux/reducers/stockConsignment';

export const validateContractor = (stockConsignment) => {

    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation) && stockConsignment.ReturnConsignmentsCreation.length > 0) { // contractor has been selected, and saved in store
        return {
            isValid: true
        };
    } else {
        return {
            isValid: false,
            validationResult: {
                message: "Please choose at least one contractor.",
                severity: "error",
                display: "toast"
            }
        }
    }
}

export const validateLocation = (stockConsignment) => {

    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation) && stockConsignment.ReturnConsignmentsCreation.length > 0
    && _.find(stockConsignment.ReturnConsignmentsCreation, (c)=>_.isArray(c.Consignments) && c.Consignments.length >0 )) { // contractor has been selected, and saved in store
        return {
            isValid: true
        };
    } else {
        return {
            isValid: false,
            validationResult: {
                message: "Please choose at least one location.",
                severity: "error",
                display: "toast"
            }
        }
    }
}

export const validateDestination = (stockConsignment) => {

    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation) && stockConsignment.ReturnConsignmentsCreation.length > 0
    && _.find(stockConsignment.ReturnConsignmentsCreation, (c)=>_.isArray(c.Consignments) && c.Consignments.length >0 && 
        _.find(c.Consignments, (cm)=>(cm.ToLocationCode || "") !== "")
    )) { // contractor has been selected, and saved in store
        return {
            isValid: true
        };
    } else {
        return {
            isValid: false,
            validationResult: {
                message: "Please choose a destination.",
                severity: "error",
                display: "toast"
            }
        }
    }
}
//returnType All,Faulty,SKU
export const otherInProgressReturnCreation=(stockConsignment,returnType)=>{
    if(Array.isArray(stockConsignment.ReturnConsignmentsCreation) && stockConsignment.ReturnConsignmentsCreation.length >0){
        var otherInProgress = _.find(stockConsignment.ReturnConsignmentsCreation,(i)=>i.InProgressReturnType.toLowerCase() !== returnType.toLowerCase());
        console.log('after loadInprogressConsignmentFromLocalStorage ....',stockConsignment.ReturnConsignmentsCreation);
         
        if(otherInProgress){
            console.log('after loadInprogressConsignmentFromLocalStorage ....otherInProgress',otherInProgress); 
            return otherInProgress.InProgressReturnType
            
        }else{
            return null;
        }
    }else{
        return null
    }
    

}

export const validateMethod = (stockConsignment) => {

    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation) && stockConsignment.ReturnConsignmentsCreation.length > 0
    && _.find(stockConsignment.ReturnConsignmentsCreation, (c)=>_.isArray(c.Consignments) && c.Consignments.length >0 && 
        _.find(c.Consignments, (cm)=>(cm.ReturnMethod || "") !== "")
    )) { // contractor has been selected, and saved in store
        return {
            isValid: true
        };
    } else {
        return {
            isValid: false,
            validationResult: {
                message: "Please choose a method.",
                severity: "error",
                display: "toast"
            }
        }
    }
}
