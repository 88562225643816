import produce from 'immer';
import { SkbLogger, ApiService } from '../../services';

const ConsignmentDataType = {
    ReturnConsignmentsCreation: "ReturnConsignmentsCreation"
}

export const stockConsignment = produce((draft, action) => {
    switch (action.type) {
        case Actions.CONSIGNMENT_REFRESH_BEGIN:
            draft.IsLoading = true;
            break;
        case Actions.CONSIGNMENT_REFRESH_END:
            draft.IsLoading = false;
            break;
        case Actions.CONSIGNMENT_UPDATE_ERROR:
            draft.Error = action.data;
            break;
        case Actions.CONSIGNMENT_CONTRACTOR_ADDED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (action.data.Contractor && action.data.Representative && action.data.CompanyId) {
                    var item = {
                        "Contractor": action.data.Contractor,
                        "Representative": action.data.Representative,
                        "CompanyId": action.data.CompanyId,
                        "InProgressReturnType": action.data.InProgressReturnType,
                        Consignments: []
                    }
                    if (!draft.ReturnConsignmentsCreation) {
                        draft.ReturnConsignmentsCreation = []
                        draft.ReturnConsignmentsCreation.push(item);
                    } else {
                        var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === action.data.CompanyId);
                        if (idx < 0) {
                            draft.ReturnConsignmentsCreation.push(item);
                        }
                    }

                }
            }
            break;
        case Actions.CONSIGNMENT_CONTRACTOR_DELETED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === action.data.CompanyId);
                    if (idx >= 0) {
                        draft.ReturnConsignmentsCreation.splice(idx, 1);
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_ADDED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {
                        for (let item of action.data.ConsignmentsData) {
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {

                                for (let dataItem of item.Consignments) {
                                    let returnConsignmentObject = {
                                        Type: "ReturnStock",
                                        FromLocationName: dataItem.FromLocationName,
                                        FromLocationCode: dataItem.FromLocationCode,
                                        FromLocationAddress: dataItem.FromLocationAddress,
                                        ConsignmentType: dataItem.ConsignmentType,
                                        ConsignmentStatus: "Awaiting picking",  //if it is from contractor should be Awaiting approval 
                                        ToLocationCode: "WHSKBTULLAMARINE",
                                        ToLocationName: "SKB Tullamarine Warehouse"
                                    }
                                    draft.ReturnConsignmentsCreation[idx].Consignments.push(returnConsignmentObject);
                                }


                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_DELETED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {
                        for (let item of action.data.ConsignmentsData) {
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                for (let location of item.Consignments) {
                                    var consignmentIdx = draft.ReturnConsignmentsCreation[idx].Consignments.findIndex(c => c.FromLocationCode === location.FromLocationCode);
                                    if (consignmentIdx >= 0) {
                                        draft.ReturnConsignmentsCreation[idx].Consignments.splice(consignmentIdx, 1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_DESTINATION_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    for (var i = 0; i < draft.ReturnConsignmentsCreation.length; i++) {
                        for (var j = 0; j < draft.ReturnConsignmentsCreation[i].Consignments.length; j++) {
                            draft.ReturnConsignmentsCreation[i].Consignments[j].ToLocationCode = action.data.ToLocationCode;

                            draft.ReturnConsignmentsCreation[i].Consignments[j].ToLocationName = action.data.ToLocationName;

                            draft.ReturnConsignmentsCreation[i].Consignments[j].ToLocationAddress = action.data.ToLocationAddress;

                            if (action.data.IsReturntoClosestWarehouse) {
                                draft.ReturnConsignmentsCreation[i].Consignments[j].IsReturntoClosestWarehouse = action.data.IsReturntoClosestWarehouse;
                            }

                        }

                    }

                }
            }

            break;
        case Actions.CONSIGNMENT_METHOD_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    for (var i = 0; i < draft.ReturnConsignmentsCreation.length; i++) {
                        for (var j = 0; j < draft.ReturnConsignmentsCreation[i].Consignments.length; j++) {
                            draft.ReturnConsignmentsCreation[i].Consignments[j].ReturnMethod = action.data.ReturnMethod;
                        }
                    }

                }
            }
            break;
        case Actions.CONSIGNMENT_TYPE_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {

                        for (let item of action.data.ConsignmentsData) {
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                for (let location of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].ConsignmentType = location.ConsignmentType;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_RETURNEDBYDATE_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    for (var i = 0; i < draft.ReturnConsignmentsCreation.length; i++) {
                        for (var j = 0; j < draft.ReturnConsignmentsCreation[i].Consignments.length; j++) {
                            draft.ReturnConsignmentsCreation[i].Consignments[j].PlannedReturnedByDate = action.data.PlannedReturnedByDate;
                        }
                    }

                }
            }
            break;
        case Actions.CONSIGNMENT_PICKEDUPBYDATE_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    for (var i = 0; i < draft.ReturnConsignmentsCreation.length; i++) {
                        for (var j = 0; j < draft.ReturnConsignmentsCreation[i].Consignments.length; j++) {
                            draft.ReturnConsignmentsCreation[i].Consignments[j].PlannedPickedUpByDate = action.data.PlannedPickedUpByDate;
                        }
                    }

                }
            }
            break;
        case Actions.CONSIGNMENT_SERIALISEDITEMS_ADDED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {

                        for (let item of action.data.ConsignmentsData) {
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                for (let location of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        if (location.FromLocationCode === draft.ReturnConsignmentsCreation[idx].Consignments[i].FromLocationCode) {
                                            if (draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems && Array.isArray(draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems)) {
                                                draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems.push(location.SerialisedItems);
                                            } else {
                                                draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems = location.SerialisedItems;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_SERIALISEDITEMS_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {
                        //loop action consignmentData
                        for (let item of action.data.ConsignmentsData) {
                            //find the company level consignment data in the store
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                //loop action company consignments data
                                for (let consignment of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        if (consignment.FromLocationCode === draft.ReturnConsignmentsCreation[idx].Consignments[i].FromLocationCode) {
                                            //loop the serialised item for the location
                                            for (let sn of consignment.SerialisedItems) {
                                                if (draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems && Array.isArray(draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems)) {
                                                    if (draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems[i].StockCategoryCode === sn.StockCategoryCode &&
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems[i].Condition === sn.Condition) {
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems[i].Qty = sn.Qty;
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems[i].SerialNumber = sn.SerialNumber;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_SERIALISEDITEMS_DELETED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {
                        //loop action consignmentData
                        for (let item of action.data.ConsignmentsData) {
                            //find the company level consignment data in the store
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                //loop action company consignments data
                                for (let consignment of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        if (consignment.FromLocationCode === draft.ReturnConsignmentsCreation[idx].Consignments[i].FromLocationCode) {
                                            //loop the serialised item for the location
                                            for (let sn of consignment.SerialisedItems) {
                                                if (draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems && Array.isArray(draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems)) {
                                                    if (draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems[i].StockCategoryCode === sn.StockCategoryCode) {
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].SerialisedItems.splice(i, 1);

                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_NONSERIALISEDITEMS_ADDED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {

                        for (let item of action.data.ConsignmentsData) {
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                for (let location of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        if (location.FromLocationCode === draft.ReturnConsignmentsCreation[idx].Consignments[i].FromLocationCode) {
                                            if (draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems && Array.isArray(draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems)) {
                                                draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems.push(location.SerialisedItems);
                                            } else {
                                                draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems = location.NonserialisedItems;
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_NONSERIALISEDITEMS_UPDATED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {
                        //loop action consignmentData
                        for (let item of action.data.ConsignmentsData) {
                            //find the company level consignment data in the store
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                //loop action company consignments data
                                for (let consignment of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        if (consignment.FromLocationCode === draft.ReturnConsignmentsCreation[idx].Consignments[i].FromLocationCode) {
                                            //loop the serialised item for the location
                                            for (let sn of consignment.NonserialisedItems) {
                                                if (draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems && Array.isArray(draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems)) {
                                                    if (draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems[i].StockCategoryCode === sn.StockCategoryCode &&
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems[i].Condition === sn.Condition) {
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems[i].Qty = sn.Qty;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_NONSERIALISEDITEMS_DELETED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    if (action.data.ConsignmentsData && Array.isArray(action.data.ConsignmentsData)) {
                        //loop action consignmentData
                        for (let item of action.data.ConsignmentsData) {
                            //find the company level consignment data in the store
                            var idx = draft.ReturnConsignmentsCreation.findIndex(i => i.CompanyId === item.CompanyId);
                            if (idx >= 0) {
                                //loop action company consignments data
                                for (let consignment of item.Consignments) {
                                    for (var i = 0; i < draft.ReturnConsignmentsCreation[idx].Consignments.length; i++) {
                                        if (consignment.FromLocationCode === draft.ReturnConsignmentsCreation[idx].Consignments[i].FromLocationCode) {
                                            //loop the serialised item for the location
                                            for (let sn of consignment.NonserialisedItems) {
                                                if (draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems && Array.isArray(draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems)) {
                                                    if (draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems[i].StockCategoryCode === sn.StockCategoryCode) {
                                                        draft.ReturnConsignmentsCreation[idx].Consignments[i].NonserialisedItems.splice(i, 1);

                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            break;
        case Actions.CONSIGNMENT_CANCEL_STOCKRETURN:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    draft.ReturnConsignmentsCreation = [];
                }
            }
            break;
        case Actions.CONSIGNMENT_SUBUMITTED:
            if (action.data.ConsignmentDataType === ConsignmentDataType.ReturnConsignmentsCreation) {
                if (draft.ReturnConsignmentsCreation && Array.isArray(draft.ReturnConsignmentsCreation)) {
                    draft.ReturnConsignmentsCreation = [];
                }
            }
            break;
        case Actions.CONSIGNMENT_INPROGRESS_LOADED:
            // draft=action.data does not work, probably due to draft being OUTPUT parameter
            // so, avoiding using draft.ReturnConsignmentsCreation=action.data.ReturnConsignmentsCreation for specific type
            //the below way is more generic
            for (var oneProperty in action.data) {
                //console.log('CONSIGNMENT_INPROGRESS_LOADED',{prop:oneProperty,value:action.data[oneProperty]});
                draft[oneProperty] = action.data[oneProperty];
            }
            //draft.ReturnConsignmentsCreation=action.data.ReturnConsignmentsCreation
            break;
        case Actions.CONSIGNMENT_INPROGRESS_DELETED:
            // draft=action.data does not work, probably due to draft being OUTPUT parameter
            // so, avoiding using draft.ReturnConsignmentsCreation=[] for specific type
            //the below way is more generic
            for (var oneProperty in draft) {
                if (oneProperty == 'IsLoading') draft[oneProperty] = false;
                else if (oneProperty == 'Error') draft[oneProperty] = '';
                else draft[oneProperty] = [];
            }
            //draft.ReturnConsignmentsCreation=[]
            break;
        case Actions.CONSIGNMENT_INPROGRESS_SAVE:
            //no change
            break;
        case Actions.STEP_STATUS_UPDATE:
            //no change
            
            if (!draft.StepsStatus) draft.StepsStatus = new Object();
            draft.StepsStatus[action.data.StepName] = action.data.Status;

            break;
        default:
            break;
    }
}, { //initial store data
    IsLoading: false,
    Error: ''
})



export const Actions = {
    CONSIGNMENT_REFRESH_BEGIN: 'CONSIGNMENT_REFRESH_BEGIN',
    CONSIGNMENT_REFRESH_END: 'CONSIGNMENT_REFRESH_END',
    CONSIGNMENT_UPDATE_ERROR: 'CONSIGNMENT_UPDATE_ERROR',
    CONSIGNMENT_CONTRACTOR_ADDED: 'CONSIGNMENT_CONTRACTOR_ADDED',
    CONSIGNMENT_CONTRACTOR_DELETED: 'CONSIGNMENT_CONTRACTOR_DELETED',
    CONSIGNMENT_ADDED: 'CONSIGNMENT_ADDED',
    CONSIGNMENT_DELETED: 'CONSIGNMENT_DELETED',
    CONSIGNMENT_DESTINATION_UPDATED: 'CONSIGNMENT_DESTINATION_UPDATED',
    CONSIGNMENT_METHOD_UPDATED: 'CONSIGNMENT_METHOD_UPDATED',
    CONSIGNMENT_TYPE_UPDATED: 'CONSIGNMENT_TYPE_UPDATED',
    CONSIGNMENT_RETURNEDBYDATE_UPDATED: 'CONSIGNMENT_RETURNEDBYDATE_UPDATED',
    CONSIGNMENT_PICKEDUPBYDATE_UPDATED: 'CONSIGNMENT_PICKEDUPBYDATE_UPDATED',
    CONSIGNMENT_SERIALISEDITEMS_ADDED: 'CONSIGNMENT_SERIALISEDITEMS_ADDED',
    CONSIGNMENT_SERIALISEDITEMS_DELETED: 'CONSIGNMENT_SERIALISEDITEMS_DELETED',
    CONSIGNMENT_SERIALISEDITEMS_UPDATED: 'CONSIGNMENT_SERIALISEDITEMS_UPDATED',
    CONSIGNMENT_NONSERIALISEDITEMS_ADDED: 'CONSIGNMENT_NONSERIALISEDITEMS_ADDED',
    CONSIGNMENT_NONSERIALISEDITEMS_DELETED: 'CONSIGNMENT_NONSERIALISEDITEMS_DELETED',
    CONSIGNMENT_NONSERIALISEDITEMS_UPDATED: 'CONSIGNMENT_NONSERIALISEDITEMS_UPDATED',
    CONSIGNMENT_SUBUMITTED: 'CONSIGNMENT_SUBUMITTED',
    CONSIGNMENT_CANCEL_STOCKRETURN: 'CONSIGNMENT_CANCEL_STOCKRETURN',
    CONSIGNMENT_INPROGRESS_LOADED: 'CONSIGNMENT_INPROGRESS_LOADED',
    CONSIGNMENT_INPROGRESS_DELETED: 'CONSIGNMENT_INPROGRESS_DELETED',
    CONSIGNMENT_INPROGRESS_SAVE: 'CONSIGNMENT_INPROGRESS_SAVE',
    STEP_STATUS_UPDATE: 'STEP_STATUS_UPDATE',
}

