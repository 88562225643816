import { Actions, getSubTaskById, getNextRowId} from '../reducers/tasksV2';
import { TaskService } from '../../services';



/**
 * This function dowloads all CompanyTask and returns all types of tasks unless taskType is specified.
 * @param {*} taskType : optional. e.g. 'STOCKRETURN'. By default, it's '', meaning all taskType.  
 * @returns: array for Tasks (name as per Redux Store but it's actually CompanyTask table in DB). The element object contains SubTasks property usually 
 */
export function loadTaskList(taskType = '') {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        return TaskService.loadTaskList(taskType).then(tasks => {
            console.log('reload task from service',tasks)
            //filter out task with partial data, potential risk is that user cannot see the task. 
            let filteredTask = filterOutTaskWithPartialData(tasks);
            dispatch(((t) => {
                return {
                    type: Actions.TASK_LOADED,
                    data: t
                };
            })(tasks));

            dispatch(updateError(''));
            dispatch(requestHasResponded());


        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}


export function openConsignmentReschedule(trueOrFalse){
    return {
        type: Actions.OPEN_RESCHEDULE_PAGE,
        data:trueOrFalse
    }
}

export function updateTaskTypeFilter(type){
    return {
        type: Actions.TASK_UPDATE_TASKTYPEFILTER,
        data:type
    }
}

export function updateTaskSortBy(srotBy){
    return {
        type: Actions.TASK_UPDATE_TASKSORTBY,
        data:srotBy
    }
}

export function updateTaskSortByOrder(sortByOrder){
    return {
        type: Actions.TASK_UPDATE_TASKSORTBYORDER
        //data:sortByOrder
    }
}


export async function loadTaskStatusAndLockedBy(subTaskId) {
    return   await TaskService.loadTaskStatusAndLockedBy(subTaskId);
  
}

/**
 * updateTaskLockedBy
 * @param {*} taskId: the current task id
 * @param {*} subTaskId , the current subtask id, 
 * @param {*} lockedBy: locked by id, opening a sub task form UI shall check ther locked by is current user or not be set
 */
export function updateTaskLockedBy(taskId, subTaskId, lockedBy) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        dispatch((() => {
            return {
                type: Actions.TASK_UPDATE_LOCKEDBY,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    LockedBy: lockedBy
                }
            };
        })());


        return TaskService.updateTaskLockedBy(subTaskId, lockedBy).then(() => {
            //log
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

/**
 * load available SNs for consignment task 
 * @param {number} taskId :company task id
 * @param {number} subTaskId : company sub task id, it's consignment id here
 */
export function loadConsignmentTaskAvailableSn(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentAvailableSns(refId).then((sns) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_AVAILABLESN_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Items: items
                    }
                };
            })(sns));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function loadConsignmentTaskSnItems(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentSnItems(refId).then((sns) => {
            var converted = convert_PHOTOtoPhoto(sns);
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_SNITEMS_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Items: items
                    }
                };
            })(converted));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function loadConsignmentTaskPreviewItems(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentPreviewItems(refId).then((previewInfo) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_PREVIEW_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Items: items
                    }
                };
            })(previewInfo));

            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function addConsignmentSnItem(taskId, subTaskId, item) {
    return (dispatch, getState) => {
        //dispatch(requestHasSent());
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_ADD_SN_ITEM,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Item: item
                }
            };
        })(item));

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.addSnItemForConsignment(refId, convertPhototo_PHOTO(item)).then((sns) => {
            dispatch(updateError(''));
            //dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            //dispatch(requestHasResponded());
        });

    }
}


export function updateConsignmentSnItem(taskId, stockConsignment, item) {
    return (dispatch, getState) => {
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_UPDATE_SN_ITEM,
                data: {
                    TaskId: taskId,
                    SubtaskId: stockConsignment.SubTaskId,
                    Item: item
                }
            };
        })(item));

        var oldItem = (stockConsignment.SerialisedItems||[]).find(i=>i.SerialNumber === item.SerialNumber);
        return TaskService.deleteSnItemForConsignment(stockConsignment.ReferenceID, convertPhototo_PHOTO(oldItem)).then(async ()=>{
            await TaskService.addSnItemForConsignment(stockConsignment.ReferenceID, convertPhototo_PHOTO(item));
        });
    }
}

export function deleteConsignmentSnItem(taskId, subTaskId, item) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_DEL_SN_ITEM,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Item: item
                }
            };
        })(item));

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.deleteSnItemForConsignment(refId, convertPhototo_PHOTO(item)).then((item) => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function loadConsignmentTaskNonSnItems(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentNonSnItems(refId).then((nonsns) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_NOSNITEMS_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Items: items
                    }
                };
            })(nonsns));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function addConsignmentNonSnItem(taskId, subTaskId, item) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_ADD_NONSN_ITEM,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Item: item
                }
            };
        })(item));

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.addNonSnItemForConsignment(refId, item).then((sns) => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}
export function updateConsignmentNonSnItem(taskId, stockConsignment, item) {
    return (dispatch, getState) => {
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_UPDATE_NONSN_ITEM,
                data: {
                    TaskId: taskId,
                    SubtaskId: stockConsignment.SubTaskId,
                    Item: item
                }
            };
        })(item));

        // var oldItem = (stockConsignment.NonSerialisedItems||[]).find(i=>i.StockCode === item.StockCode);
        // return TaskService.deleteNonSnItemForConsignment(stockConsignment.ReferenceID, oldItem).then(async ()=>{
        //     await TaskService.addNonSnItemForConsignment(stockConsignment.ReferenceID, item);
        // });

        //dispatch(requestHasSent());
        var existingItems = [...(stockConsignment.NonSerialisedItems)||[]];
        var oldItemIndex =  existingItems.findIndex(i=>i.StockCode === item.StockCode);
        if(oldItemIndex>=0) existingItems[oldItemIndex] = item;
        return TaskService.overwriteNonSnItemListForConsignment(stockConsignment.ReferenceID, existingItems).then(async ()=>{
            //dispatch(updateError(''));
            //dispatch(requestHasResponded());
        }).catch(err => {
            //dispatch(updateError(err));
            //dispatch(requestHasResponded());
        });

    }
}

export function loadConsignmentTaskPickupDetails(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentPickupDetails(refId).then((details) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_PICKUPDETAILS_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Details: items
                    }
                };
            })(details));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function loadConsignmentTaskPackageDetails(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentPackageDetails(refId).then((details) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_PACKAGES_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Items: items
                    }
                };
            })(details));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function addConsignmentPackage(taskId, stockConsignment, pack,disableTrafficControl=false) {
    return (dispatch, getState) => {
        if(Array.isArray(pack)){
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_PACKAGES_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: stockConsignment.SubTaskId,
                        Items: items
                    }
                };
            })(pack));
        }else{
            dispatch(((item) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_ADD_PACKAGE,
                    data: {
                        TaskId: taskId,
                        SubtaskId: stockConsignment.SubTaskId,
                        Item: item
                    }
                };
            })(pack));
        }

        let latestConsignments = getSubTaskById(getState().tasksV2, taskId, stockConsignment.SubTaskId) || stockConsignment;
        var existingPacks=[];
        
        if(Array.isArray(pack)){
            existingPacks=[...(latestConsignments.PackageDetails)||[]];
        }
        else{
            existingPacks=[...(stockConsignment.PackageDetails)||[]];
            existingPacks.push({...pack, RowId: getNextRowId(stockConsignment.PackageDetails)})
        }

       
        //existingPacks.push({...pack, RowId: getNextRowId(latestConsignments.PackageDetails)})
        // var oldItemIndex =  existingItems.findIndex(i=>i.StockCode === item.StockCode);
        // if(oldItemIndex>=0) existingItems[oldItemIndex] = item;

        return TaskService.overwritePackageForConsignment(stockConsignment.ReferenceID, existingPacks,disableTrafficControl).then(() => {
            // dispatch(updateError(''));
            // dispatch(requestHasResponded());

        }).catch(err => {
            // dispatch(updateError(err));
            // dispatch(requestHasResponded());
        });
    }
}
export function updateConsignmentPackage(taskId, stockConsignment, pack,disableTrafficControl=false) {
    return (dispatch, getState) => {
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_UPDATE_PACKAGE,
                data: {
                    TaskId: taskId,
                    SubtaskId: stockConsignment.SubTaskId,
                    Item: item
                }
            };
        })(pack));
        var existingPacks = [...(stockConsignment.PackageDetails)||[]];
        // existingPacks.push({...pack, RowId: getNextRowId(stockConsignment.PackageDetails)})
        var idx =  existingPacks.findIndex(i=>i.RowId === pack.RowId);
        if(idx>=0) existingPacks[idx] = {...pack};

        return TaskService.overwritePackageForConsignment(stockConsignment.ReferenceID, existingPacks,disableTrafficControl).then(() => {
            // dispatch(updateError(''));
            // dispatch(requestHasResponded());

        }).catch(err => {
            // dispatch(updateError(err));
            // dispatch(requestHasResponded());
        });

    }
}
export function deleteConsignmentPackage(taskId, stockConsignment, pack,disableTrafficControl=false) {
    return (dispatch, getState) => {
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_DEL_PACKAGE,
                data: {
                    TaskId: taskId,
                    SubtaskId: stockConsignment.SubTaskId,
                    Item: item
                }
            };
        })(pack));

        var existingPacks = [...(stockConsignment.PackageDetails)||[]];
        // existingPacks.push({...pack, RowId: getNextRowId(stockConsignment.PackageDetails)})
        var idx =  existingPacks.findIndex(i=>i.RowId === pack.RowId);
        if(idx>=0) existingPacks.splice(idx, 1);

        return TaskService.overwritePackageForConsignment(stockConsignment.ReferenceID, existingPacks,disableTrafficControl).then(() => {
            // dispatch(updateError(''));
            // dispatch(requestHasResponded());

        }).catch(err => {
            // dispatch(updateError(err));
            // dispatch(requestHasResponded());
        });


    }
}

export function updateConsignmentPickupDetails(taskId, subTaskId, pickupDetails) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_UPDATE_PICKUPDETAILS,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Details: item
                }
            };
        })(pickupDetails));

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.updatePickupDetailsForConsignment(refId, pickupDetails).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}


export function modifyConsignmentTask(taskId, subTaskId, taskData) {
    /**
     *   const taskData = {
    TaskStatus:"Awaiting schedule", 
    ModifiedBy:  user.name , 
    ModifiedDate: new currentTimeToUTCTimeInString(StringFormat.TaskDateFormat), 
    NextActionBy: "Skybridge"
  };
     */
    //TODO
    // return (dispatch,getState)=>{

    // } 

    return (dispatch, getState) => {
        dispatch(requestHasSent());
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_MODIFIED,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    TaskStatus: taskData.TaskStatus,
                    ModifiedBy: taskData.ModifiedBy,
                    ModifiedDate: taskData.ModifiedDate,
                    NextActionBy: taskData.NextActionBy,
                }
            };
        })(taskData));

        return TaskService.modifySubTaskStatus(subTaskId, taskData.TaskStatus, taskData.ModifiedBy).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function commitConsignmentSnItems(taskId, subTaskId, sns) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var converted = convertPhototo_PHOTO(sns);
        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.overwriteSnItemListForConsignment(refId, converted, true).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function commitConsignmentNonSnItems(taskId, subTaskId, noSns) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.overwriteNonSnItemListForConsignment(refId, noSns).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function commitConsignmentPackages(taskId, subTaskId, packs) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.overwritePackageForConsignment(refId, packs).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function requestHasSent() {
    return {
        type: Actions.TASK_REQUEST_SENT
    }
}

export function requestHasResponded() {
    return {
        type: Actions.TASK_REQUEST_RESPONDED
    }
}

export const updateError = (err) => {

    return {
        type: Actions.TASK_UPDATE_ERROR,
        data: err
    }
}


export const convert_PHOTOtoPhoto = (obj) => {
    const from = '_PHOTO';
    const to = 'Photo';
    return renameObjKey(obj, from, to);
}

export const convertPhototo_PHOTO = (obj) => {
    const from = 'Photo';
    const to = '_PHOTO';
    return renameObjKey(obj, from, to);
}


function renameObjKey(obj, from, to) {
    // const from = '_PHOTO'; 
    // const to = 'Photo'
    if (typeof (obj) === 'object') {
        if (Array.isArray(obj)) {

            var res = obj.map((i) => {
                return renameSingleObjKey(i, from, to);
            });
            return res;
        } else {
            return renameSingleObjKey(obj, from, to);
        }
    }


    return obj;// no convert 
}
function renameSingleObjKey(obj, from, to) {
    if (Object.keys(obj).includes(from)) {
        let copy = { ...obj };

        copy[to] = (to === '_PHOTO')?(copy[from]||"").replace("data:image/jpeg;base64,", "") : copy[from];

        delete copy[from];
        return copy;
    }
    return obj;
}

function getSubtaskReferenceId(getState, taskId, subtaskId) {
    try {
        var state = getState().tasksV2;
        var subtask = getSubTaskById(state, taskId, subtaskId);
        if (subtask) return subtask.ReferenceID;

        return subtaskId

    } catch (error) {
        return subtaskId;
    }

}

export function filterOutTaskWithPartialData(tasks) {
    if (!Array.isArray(tasks)) return tasks;

    let filteredRes = tasks.filter(t => {
        if (Array.isArray(t.SubTasks) && t.SubTasks.length > 0) {
            let ret = t.SubTasks.some(s=>{let keys = Object.keys(s);
                if (!keys.includes("TaskStatus") || !keys.includes("Type")) return false;
                return true;
            });
            return ret;
        }
        return false;
    });
    
    return filteredRes;
}


export function loadConsignmentTaskDropOffDetails(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentDropOffDetails(refId).then((details) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_DROPOFFDETAILS_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Details: items
                    }
                };
            })(details));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function updateConsignmentDropOffDetails(taskId, subTaskId, dropOffDetails) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_UPDATE_DROPOFFDETAILS,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Details: item
                }
            };
        })(dropOffDetails));

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.updateDropOffDetailsForConsignment(refId, dropOffDetails).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}


export function loadConsignmentTaskLabelDetails(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadConsignmentLabelDetails(refId).then((details) => {
            dispatch(((items) => {
                return {
                    type: Actions.TASK_CONSIGNMENT_LABELDETAILS_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        Items: items
                    }
                };
            })(details));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function addConsignmentLabel(taskId, subTaskId, label) {
    return (dispatch, getState) => {
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_ADD_LABEL,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Item: item
                }
            };
        })(label));

    }
}

export function deleteConsignmentLabel(taskId, subTaskId, label) {
    return (dispatch, getState) => {
        dispatch(((item) => {
            return {
                type: Actions.TASK_CONSIGNMENT_DEL_LABEL,
                data: {
                    TaskId: taskId,
                    SubtaskId: subTaskId,
                    Item: item
                }
            };
        })(label));

    }
}


export function commitConsignmentLabels(taskId, subTaskId, labels) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());

        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.overwriteLabelsForConsignment(refId, labels).then(() => {
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
        });

    }
}

export function updateTaskStepStatus(taskId, stockConsignment, stepName, status) {
    return (dispatch, getState) => {
        dispatch(((stepName, status) => {
            return {
                type: Actions.TASK_UPDATE_STEP_STATUS,
                data: {
                    TaskId: taskId,
                    SubtaskId: stockConsignment.SubTaskId,
                    StepName: stepName,
                    Status: status
                }
            };
        })(stepName, status));

        var stepsStatus = {...(stockConsignment.StepsStatus||{})};
        stepsStatus[stepName] = status;

        return TaskService.updateTaskStepsStatus(stockConsignment.ReferenceID, stepsStatus).then(async ()=>{
            
        });
    }
}

export function loadTaskStepStatus(taskId, subTaskId) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());


        let refId = getSubtaskReferenceId(getState, taskId, subTaskId);
        return TaskService.loadTaskStepsStatus(refId).then((stepsStatus) => {
            dispatch(((stepsStatus) => {
                return {
                    type: Actions.TASK_STEP_STATUS_LOADED,
                    data: {
                        TaskId: taskId,
                        SubtaskId: subTaskId,
                        StepsStatus: stepsStatus
                    }
                };
            })(stepsStatus));
            dispatch(updateError(''));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err.toString()));
            dispatch(requestHasResponded());
        });

    }
}

export function updateCacnellationReason(taskId, stockConsignment, reason) {
    return (dispatch, getState) => {
        dispatch(((reason) => {
            return {
                type: Actions.TASK_UPDATE_CANCELLATIONREASON,
                data: {
                    TaskId: taskId,
                    SubtaskId: stockConsignment.SubTaskId,
                    Reason: reason
                }
            };
        })(reason));

        return TaskService.updateTaskCancellationReason(stockConsignment.ReferenceID, reason).then(async ()=>{
            
        });
    }
}


