///**************old crypto********************** */
// const Cryptr = require('cryptr');

// const cryptr = new Cryptr('c1647a05-6561-4560-b51c-77e91e3d4365');


// function encrypt(str){
//     return cryptr.encrypt(str);
// }

// function decrypt(str){
//     return cryptr.decrypt(str);
// }


// export const crypts = {
//     encrypt,
//     decrypt
// };

//*****************end old one************* */


const key = 'c1647a05-6561-4560-b51c-77e91e3d4365'

const AES_CONFIG = {
    iv: "cyXDawWx4dl06POjPJzJUw==",
    ks: 256,
    ts:128,
    mode: "ccm",
    }
const ct = {
    iv: "cyXDawWx4dl06POjPJzJUw==",
    v: 1,
    iter: 10000,
    ks: 256,
    ts: 128,
    mode: "ccm",
    adata: "",
    cipher: "aes",
    salt: "McHyE2e4HDg=",
    
  }
function encrypt(str){
    if(!str) return str;
    if(!window.sjcl) return btoa(str); //use base64 instead of NOT SUPPORT
    try {
        var cipherObj =  JSON.parse(window.sjcl.encrypt(key,str, {ks:ct.ks, salt:ct.salt, iv:ct.iv, cipher:ct.cipher, v:ct.v, ts:ct.ts, iter:ct.iter}));
        return cipherObj.ct;
    } catch (error) {
        return null;
    }
    
}

function decrypt(str){
    if(!str) return str;
    if(!window.sjcl) return atob(str); //use base64 instead of NOT SUPPORT

    var cipherObj = {...ct, ct:str}; 
    try {
        let ret =  window.sjcl.decrypt(key, JSON.stringify(cipherObj));
        return ret;
    } catch (error) {
        return null;
    }
    
}


export const crypts = {
    encrypt,
    decrypt
};
