import React from 'react';
import { SkbLogger, SeverityLevel } from '../../../services';

import { Status } from '../../../Redux/actions/tasks';
import { StocktakeSteps } from '../Stocktake';

import { connect } from 'react-redux';

import * as taskActions from '../../../Redux/actions/tasks';

import { getStepFromCurrentSubTaskByStepId } from '../../../Redux/actions/tasks';
import { TaskLogInfo } from '../../../utils/misc';

import {getDataItem}  from '../../../services/dataSaverAndTracker';

import { useSnackbar } from 'notistack';

import StepSerialisedItems from './StepSerialisedItems';


//Some constants for logging:
const moduleName = TaskLogInfo.moduleName;
const stepName = TaskLogInfo.category.serialisedStock;

export function StepSerialisedItemsHusk(props) {

  const { enqueueSnackbar } = useSnackbar();

  function addSerialisedItem(newItem) {

    props.addSerialisedItem(props.allTasks, newItem);

    enqueueSnackbar('Successfully added ' + newItem.SerialNumber + '.', { variant: 'success' });
  }
//two versions, one for scanned and one for manual
//   this.props.addSnItem(this.props.allTasks, 
//     {
//     StockCode : barcodeData.stockCode,
//     Description : barcodeData.stockDescription,
//     SerialNumber : barcodeData.serialNumber,
//     Photo: barcodeData.photoOfBarcode,
//     ItemStatus : barcodeData.status,
//     Latitude: 0,
//     Longitude : 0,
//     dateStamp : currentDate,
//     IsScanned:1

// }

  function updateSerialisedItem(item) {
    props.updateSerialisedItem(props.allTasks, item);

  }

  function deleteSerialisedItem(item) {
   
    props.deleteSerialisedItem(props.allTasks, item);

  }

  function setStepStatusOnChange() {
    var currentStepStatus = getStepFromCurrentSubTaskByStepId(props.allTasks, props.currentSubTask.CurrentStep.StepId).Status;
    var msg = "";
    var syncIsOn = false;
    if (currentStepStatus === Status.NotStarted) {
      msg = "User has started editing the page.  Status changing to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, props.currentSubTask.CurrentStep.StepId, Status.InProgress);

    }
    else if (currentStepStatus === Status.Done) {
      msg = "User is editing the page again after complete.  Status changing back to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, props.currentSubTask.CurrentStep.StepId, Status.InProgress);
    }

    //Serialised step being changed moves Review step back, if already Done:  
    var reviewStepStatus = getStepFromCurrentSubTaskByStepId(props.allTasks, StocktakeSteps.REVIEW).Status;

    if (reviewStepStatus === Status.Done) {
      msg = "User is editing the Serialised page again after complete.  The Review step status is therefore changing back to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, StocktakeSteps.REVIEW, Status.InProgress);
    }

    //Same for signature step
    var signatureStepStatus = getStepFromCurrentSubTaskByStepId(props.allTasks, StocktakeSteps.SIGNATURE).Status;

    if (signatureStepStatus === Status.Done) {
      msg = "User is editing the Serialised page again after complete.  The Signature step status is therefore changing back to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, StocktakeSteps.SIGNATURE, Status.InProgress);

      // Reset signature
      if (props.sync.isOnline && (props.sync.isPhotoSyncing || props.sync.isMetadataSyncing)) {
        syncIsOn = true
      } else {
        syncIsOn = false;
      }
      props.clearSignature(props.allTasks, props.currentSubTask, syncIsOn);
    }



  }

  async function retrievePhoto(scannedItem)
{
    var photoContent = await getDataItem(scannedItem.PhotoResourceId);

    return photoContent? `data:image/png;base64,${photoContent.localArtefactContent}` : null;
}

  return (<StepSerialisedItems
    serialisedItems={props.currentSubTask.SerialisedItems}
    loadSerialisedItems={props.loadCurrentTaskSnItems}
    knownSerialNumbers={props.currentSubTask.ExistingAvailableSNs} 
    addSerialisedItem={addSerialisedItem} updateSerialisedItem={updateSerialisedItem}
    deleteSerialisedItem={deleteSerialisedItem}
    onChange={setStepStatusOnChange}
    retrievePhoto={retrievePhoto}

    isLoading={props.allTasks.isLoading}
    // emptyDataMessage={'No results have been recorded so far!'}

    taskObjectForLogging={props.currentSubTask} moduleName={TaskLogInfo.moduleName}
    stepName={TaskLogInfo.category.serialisedStock}

  // columnHeaders={columnHeaders}

  />);
}


const mapStateToProps = state => ({
  user: state.auth,
  sync: state.sync,
  currentSubTask: taskActions.getCurrentSubTask(state.tasks),
  allTasks: state.tasks
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateTaskStep: (allTasks, stepId, status) => dispatch(taskActions.updateTaskStep(allTasks, stepId, status)),
    
    clearSignature: (allTasks, currentSubTask) => {
      let sig = { ...currentSubTask.SignoffSig, Sig: null, SignoffBy: "" };
      dispatch(taskActions.updateSignoffSig(allTasks, sig));
    },
    updateSerialisedItem: (allTasks, item) => dispatch(taskActions.updateSnItem(allTasks, item)),
    addSerialisedItem: (allTasks, item) => dispatch(taskActions.addSnItem(allTasks, item)),

    deleteSerialisedItem: (allTasks, item) => dispatch(taskActions.deleteSnItem(allTasks, item)),
    loadCurrentTaskSnItems: () => dispatch(taskActions.loadCurrentTaskSnItems(false)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepSerialisedItemsHusk);
