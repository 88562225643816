import * as contractorStockActions from '../../Redux/actions/contractorStock';
import * as consignmentActions from '../../Redux/actions/stockConsignment';
import React from 'react';
import Typography from '@material-ui/core/Typography';

export const getContractorListWithStockSummary = (contractorStock) =>{
    if (!contractorStock|| !contractorStock.StockOnHand )
    {
      return [];
    }
  else {
    //console.log('contractorStock',contractorStock);
    return contractorStock.StockOnHand
            .filter(c=>c.NumberOfLocations!==0)  //&&c.QtyGood+c.QtyFaulty>0
            .map(i => {
              var displayName = i.Contractor;
              var displayComponent=<div value={i.Contractor} valueForFiltering={i.Contractor}>{i.Contractor}</div>;
              if(contractorStock.PendingConsignments){
                for (let index = 0; index < contractorStock.PendingConsignments.length; index++) {
                  const oneConsignment = contractorStock.PendingConsignments[index];
                  if(oneConsignment.RepresentativeId==i.RepresentativeId){
                    displayName = `${i.Contractor} (${oneConsignment.Type} Task: ${oneConsignment.ConsignmentId} - ${oneConsignment.Status})`;
                    displayComponent = <div value={i.Contractor} valueForFiltering={i.Contractor}>{i.Contractor} <Typography style={{fontStyle:'italic'}} color="primary" variant="body2">[{oneConsignment.Type} Task: {oneConsignment.ConsignmentId} - {oneConsignment.Status}]</Typography> </div>;
                    break;
                  }
                  
                }
              }
              return { ...i,ContractorDisplayName:displayName,ContractorDisplayComponent:displayComponent };
            });
  }

}

export const getContractorListWithFaultyStockSummary = (contractorStock) =>{
  if (!contractorStock|| !contractorStock.StockOnHand )
  {
    return [];
  }
else {
  return contractorStock.StockOnHand
          .filter(c=>c.NumberOfLocations!==0) //&&c.QtyFaulty>0
          .map(i => {
            var displayName = i.Contractor;
            var displayComponent=<div value={i.Contractor} valueForFiltering={i.Contractor}>{i.Contractor}</div>;
              if(contractorStock.PendingConsignments){
                for (let index = 0; index < contractorStock.PendingConsignments.length; index++) {
                  const oneConsignment = contractorStock.PendingConsignments[index];
                  if(oneConsignment.RepresentativeId==i.RepresentativeId){
                    displayName = `${i.Contractor} (${oneConsignment.Type} Task: ${oneConsignment.ConsignmentId} - ${oneConsignment.Status})`;
                    displayComponent = <div value={i.Contractor} valueForFiltering={i.Contractor}>{i.Contractor} <Typography style={{fontStyle:'italic'}} color="primary" variant="body2">[{oneConsignment.Type} Task: {oneConsignment.ConsignmentId} - {oneConsignment.Status}]</Typography> </div>;
                    break;
                  }
                  
                }
              }
              return { ...i,ContractorDisplayName:displayName,ContractorDisplayComponent:displayComponent };
          });
}

}

export const getSelectedCompanyIds = (stockConsignment) => {
    if (!stockConsignment|| !stockConsignment.ReturnConsignmentsCreation)
    {
       return [];
    }  
  else {
      var selectedIds=stockConsignment.ReturnConsignmentsCreation.map(c=>c.CompanyId);
      return selectedIds;
  }
}

export const loadContractors = (dispatch, idToken)  => {
    
  dispatch(contractorStockActions.loadContractors(idToken, null));
  dispatch(contractorStockActions.loadPendingConsignment(idToken));
}

export const handleSelectContractor = (dispatch,selectedRow, idToken,inProgressReturnType) => {
   
  const returnConsignmentCreationDataType="ReturnConsignmentsCreation";

    if(selectedRow.tableData.checked)
    {
      var contractorToAdd={
        "ConsignmentDataType": returnConsignmentCreationDataType,
        "Contractor": selectedRow.Contractor,
        "Representative": selectedRow.Representative,
        //"RepresentativeId": selectedRow.RepresentativeId, 
        "CompanyId":selectedRow.CompanyId,
        "InProgressReturnType":inProgressReturnType,
        };

      dispatch(consignmentActions.addContractorToConsignment(contractorToAdd));

      dispatch(contractorStockActions.loadContractorLocations(idToken, selectedRow.CompanyId));
    }
    else
    {
      var toRemove={
        "ConsignmentDataType": returnConsignmentCreationDataType,
        //"RepresentativeId": selectedRow.RepresentativeId, 
        "CompanyId":selectedRow.CompanyId
        };

      dispatch(consignmentActions.delContractorInConsignment(toRemove));
    }
}