/* eslint-disable no-unused-vars */
import React from 'react';
import HomePage from './HomePage'
import {ManageVariables} from './ManageVariables'
import Stocktake from './Stocktake'
import Tasks from './tasks_components/stocktake/Tasks';
//import Tasks from './tasks_components/MyTasks';
import SyncConfig from '../sync/SyncConfig'
import ReturnAllStockWizardInternal from './stock_components/ReturnAllStockWizardInternal';
import ReturnFaultyStockWizardInternal from './stock_components/ReturnFaultyStockWizardInternal';
import StockModuleInternal from './stock_components/StockModuleInternal';

import {PerformWork} from './PerformWork'
import StockTasks from './tasks_components/StockTasks';
import ConsignmentTasks from './tasks_components/ConsignmentTasks';
import ReturnsDashboard from './stock_components/returns_dashboard/ReturnsDashboard';
import PickupDelivery from './stock_components/PickupDelivery';

import ThreePLImportation from './tasks_components/ThreePLImportation/ThreePLImportation';

export function ModuleCreator({moduleName, ...other}){

    if(!moduleName) return (null);

    if(/Home/ig.test(moduleName))
    return (<HomePage {...other}/>);

    if(/ManageVariables/ig.test(moduleName))
    return (<ManageVariables {...other}/>);


    if(/Stocktake/ig.test(moduleName))
    // return (<Stocktake {...other}/>);
    return (<Tasks {...other}/>);

    if(/PerformWork/ig.test(moduleName))
    return (<PerformWork {...other}/>);

    if(/SyncConfig/ig.test(moduleName))
    return (<SyncConfig {...other}/>);

    if(/returnstockinternal/ig.test(moduleName))
    return (<ReturnAllStockWizardInternal {...other}/>);
   
    if(/returnfaultystockinternal/ig.test(moduleName))
    return (<ReturnFaultyStockWizardInternal {...other}/>);

    // if(/stockmodule/ig.test(moduleName))
    // return (<StockModuleInternal {...other}/>);

    if(/stockmodule/ig.test(moduleName))
    return (<StockModuleInternal {...other}/>);

    if(/stocktasks/ig.test(moduleName))
    return (<StockTasks {...other}/>);

    if(/ThreePLImporation/ig.test(moduleName))
    return (<ThreePLImportation {...other}/>);

    if(/returndashboard/ig.test(moduleName))
    return (<ReturnsDashboard {...other}/>);

    if(/consignmentmanagementcontractor/ig.test(moduleName))
        return (<ConsignmentTasks {...other}/>);

    return (null);
}