import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { Link as RouterLink, BrowserRouter } from 'react-router-dom';
import SkbIcon from '../components/SkbIcon';

import Typography from '@material-ui/core/Typography';


import SkbButton from '../components/SkbButton';

//Material UI Card
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(theme => ({
  root: {

    maxWidth: 345,
    maxHeight: 550,
    marginTop: 30,

    [breakpoints.down('sm')]: {
      marginBottom: 30
    },
    [breakpoints.up('md')]: {
      marginBottom: 70
    },

    marginLeft: 20,
    marginRight: 20
    // backgroundColor: state.darkTheme?theme.palette.background.secondary
  },
  topSection:
  {
    display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
    [breakpoints.down('sm')]: {
      height: 55
    },
    [breakpoints.up('md')]: {
      height: 110
    }
  },

  avatar: {

    fontSize: "1.75em"

  },
  avatarIcon: {

    display: 'flex',
  },
  space: {
    [breakpoints.down('sm')]: {
      display: "block",
      width: "1.75em",
    }
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomSection:
  {
    [breakpoints.down('sm')]: {
      height: 50
    },
    [breakpoints.up('md')]: {
      height: 80
    }
  },

  media: {
    height: 250
  },

  middle: {
    display: 'flex',

    justifyContent: 'center',
    paddingBottom: 20
  },
  centreItem: {
    textAlign: "-webkit-center", //"-moz-center"

  }


}));

/**
 * SkbCardsView
 * @param {Array} CardsInfo: Mandatory fields, sample:
 * [{
 *       Name: "All Stock", 
 *       Description: "Return all stock",
 *       ImagePath: "/images/returnStock.jpg", // backgroud image of a card, it's relevant path to the root path of the app
 *       Icon : BusinessIcon, // icon shows on the title bar
 *       NavigationRoute: '/skbcardsviewdemo'
 *   },
 *   {
 *       Name: "Faulty Stock", 
 *       Description: "Return all faulty stock",
 *       ImagePath: "/images/returnStock.jpg",
 *       Icon : BusinessIcon,
 *       NavigationRoute: '/skbcardsviewdemo'
 *   }]
 */
export default function SkbCardsView(props) {

    const classes = useStyles();
    const CardsInfo = props.CardsInfo || [];
   
    const formatCardHeaderDisplayBasedOnLength=(itemName)=>itemName.length>9?    <Typography variant="h5">{itemName}</Typography>
: <Typography variant="h3">{itemName}</Typography>;

    useEffect(() => {

    }, []);

    var getClickFunction= (item)=>{


    if (item.ClickFunction)
    {
      return    e=>item.ClickFunction(e);
    }
    else
    {
        return null;

    }

  }

  var getOtherClickFunction= (item)=>{
    alert(item.ClickFunction);

    if (item.ClickFunction)
    {
      return    e=>e.target.parentElement.parentElement.parentElement.click();
    }
    else
    {
        return null;

    }

  }


  var buildCardActionArea= (item,index)=>{


    if (item.NavigationRoute)
    {
      return    (                            <CardActionArea id={`card-action-area-${index}`}
      component={RouterLink} to={`${item.NavigationRoute}`} 

     
     onClick={getClickFunction(item)}
                                >
   
     <CardMedia
         className={classes.media}
      
         image={`${item.ImagePath}`}

         // image={item.image}

         title={item.Name}
     />
     <CardContent className={classes.bottomSection} >

         <Typography variant="body2" color="textSecondary" component="p">
             {item.Description}
         </Typography>
     </CardContent>

     <div className={classes.middle}>
         <SkbButton id={`go-to-${index}-module-button`} primary size="small" text={item.Name}  ></SkbButton>
     </div>
 </CardActionArea>) ;
    }
    else
    {
        return (                           
        
        <CardActionArea id={`card-action-area-${index}`} name={item.Name}            onClick={getClickFunction(item) }
                                      >
        
       <CardMedia
           className={classes.media}
        
           image={`${item.ImagePath}`}

           // image={item.image}

           title={item.Name}
       />
          <CardContent className={classes.bottomSection} >
         
              <Typography variant="body2" color="textSecondary" component="p">
                  {item.Description}
              </Typography>
          </CardContent>

          <div className={classes.middle}>
         
              <SkbButton id={`go-to-${index}-module-button`} primary size="small" text={item.Name}   ></SkbButton>
          </div>
   </CardActionArea>);

    }

  }

    return (

        <div>
            <Grid container direction="row" id={"cards_container"} justify="space-evenly" alignContent="space-around" className={classes.centreItem}>
                {CardsInfo.map((item, index) => (

                    <Grid item xs={12} lg={4}>
                        <Card className={classes.root} elevation={5} id={`card_${(item.Name ||"").toLowerCase().replace(' ', '_')}`}>
                            <CardHeader className={classes.topSection}


                                title={
                                    <div className={classes.title}>
                                        <Typography variant="h3" className={classes.avatar}><SkbIcon classes={{ iconStyle: classes.avatarIcon }} icon={item.Icon} /></Typography>
                                        {formatCardHeaderDisplayBasedOnLength(item.Name)}
                                        <Typography variant="h3" className={classes.space}>&nbsp;</Typography>
                                    </div>
                                } >
                            </CardHeader>
                            {buildCardActionArea(item,index)}

                        </Card></Grid>

                ))}
            </Grid>

        </div>

    );
}