import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';


const DragAndDropFileUpload = (props) => {
    

    const {

        onDrop,
        accept,

    } = props;

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
    });

    return(
        <div 
            {...getRootProps()}
            style={{
                border : '1px dashed black',
                padding : '0.3em 1em',
                borderRadius : '0.3em',
                width : '25em',
                height : '4em',
                textAlign : 'center',
                marginBottom : '0.2em'
            }}    
        >
            <input className="dropzone-input" {...getInputProps()} />
            <div className="text-center" >
                {isDragActive ? (
                <p className="dropzone-content">DROP THE FILES</p>
                ) : (
                <p>
                    DRAG FILE HERE
                </p>
                )}
            </div>
            <p> .jpeg, .jpg, .png, .pdf, files only</p>
        </div>
    )
}

export default DragAndDropFileUpload;