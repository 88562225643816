/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import { PullDownContent, ReleaseContent, RefreshContent } from "react-js-pull-to-refresh";
import Loading from '../../Loading';
import {crypts} from '../../../utils/crypts';
import { SkbSelect } from '../../../skb_controls/components/SkbSelect';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import SkbPullToRefresh from '../../../skb_controls/components/SkbPullToRefresh';
import { SkbLogger } from '../../../services';
// import { sync } from '../../../Redux/reducers/sync';
import * as taskActions from '../../../Redux/actions/tasksv2';
import * as oldTaskActions from '../../../Redux/actions/tasks';

import { Grid } from '@material-ui/core';
import TaskSummary from './stocktake/TaskSummary';
import TaskSummaryReturnStock from './TaskSummaryReturnStock';
import { useSnackbar } from 'notistack';

import * as assistanceNumber from '../../../Redux/actions/callForAssistance';
import ConsignmentTasks from './ConsignmentTasks';


const useStyles = makeStyles((theme) => ({
    root: {

        marginTop: theme.spacing(2)
    },

    taskheader: {
        alignItems: 'center',
    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end',
        alignSelf:'center'
    },
    tasksfooter: {
        textAlign: 'center',
        height: 200,
        cursor: 'default',
        paddingTop: 5, 
        overscrollBehavior: "contain",
    },
    selecterSize:{
         [theme.breakpoints.down("xs")]: { 
             '& div.MuiFormControl-root': {
                minWidth:'20px',
                maxWidth:'80px'
    
            },
        },
       /*   '& div.MuiPaper-root':{
             maxWidth:'380'
         }   */
        
    },
    icons:{
        alignSelf:'center',
    }
}));


const SortDirection = {
    Ascent: 1,
    Descent: 2
}
const SkbSortIcon = ({ sortByAscent }) => {
    const classes = useStyles();
    if (!sortByAscent) {
        return <div >
            <SkbIcon icon={ArrowDownwardSharpIcon} />
            <SkbIcon icon={SortIcon} />
        </div>
    }  {
        return <div >
            <SkbIcon icon={ArrowUpwardSharpIcon} />
            <SkbIcon icon={SortIcon} />
        </div>
    }
   
}


function Tasks(props) {

    const {ViewType} = props;
    

    console.log("View Type",props?.ViewType)
    
    const SortWithIcon = ({ icon, text, className }) => {
        const classes = useStyles();
        const Icon = icon;
        return (
    
            <Grid item container direction={"row"} className={className}>
                <Grid item className={classes.icons}>
                   {/*  <SkbIcon icon={Icon} /> */}
                    <IconButton size="small" aria-label="close" color="inherit" onClick={props.updateTaskSortByOrder}>
                             <SkbIcon icon={Icon} /> 
                    </IconButton>
                </Grid>
                <Grid id="test2" className={classes.selecterSize}>
                <SkbSelect   Title="Sort Order" value={props.tasksState.taskSortBy} items={[{
                                value: "Create Date",
                                text: "Creation Date"
                            },{
                                value: "Last Modified Date",
                                text: "Last Modified Date"
                            },
                            {
                                value: "Task ID",
                                text: "Task ID"
                            },{
                                value: "Task Status",
                                text: "Task Status"
                            },
                            
                        ]}  
                        valueChanged={props.updateTaskSortBy}  
                      />
                  {/*   <Typography variant="body1">
                        {text}
    
                    </Typography> */}
                </Grid>
            </Grid>
        );
    }
    const FilterWithIcon = ({ icon, text, className }) => {
        const classes = useStyles();
        const Icon = icon;
        return (
    
            <Grid item container direction={"row"} className={className}>
                <Grid  item className={classes.icons}>
                          <SkbIcon icon={Icon} /> 
            
                </Grid>
                <Grid id="test2" className={classes.selecterSize}>
                <SkbSelect   Title="Task Filter" value={props.tasksState.taskTypeFilter} items={[{
                                value: "All Stock Tasks",
                                text: "All Stock Tasks"
                            },
                            {
                                value: "Return All Stock",
                                text: "Stock Task\\Return All Stock"
                            },
                            {
                                value: "Stocktake Task",
                                text: "Stock Task\\Stocktake Task"
                            },
                            {
                                value: "Return Faulty Stock",
                                text: "Stock Task\\Return Faulty Stock Task"
                            },
                            {
                                value: "Return Specified Stock",
                                text: "Stock Task\\Return Specified Stock Task"
                            },
                            
                        ]}  
                        valueChanged={props.updateTaskTypeFilter}   
                      />
                  {/*   <Typography variant="body1">
                        {text}
    
                    </Typography> */}
                </Grid>
            </Grid>
        );
    }
  

    const classes = useStyles();
    const { Tasks, loadTasks, updateAssistanceNumbers, CurrentTask, tasksState, Sync } = props;
    const { enqueueSnackbar } = useSnackbar();
    var syncIsOn = false;
    //const [assistanceNumbers, setAssistanceNumbers] = React.useState([]);

    SkbLogger.logDebugInfo("tasks is called");
    useEffect(() => {
 
        if (!Tasks || Array.isArray(Tasks) || Tasks.length === 0)
        {
            loadTasks();
            //alert(Tasks[0].TaskType);
        } 
        console.log('load tasks filter...',props.tasksState.taskTypeFilter)
        return () => {
            updateAssistanceNumbers(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));
        }
    }, []);


    useEffect(() => {
        if (tasksState.error) enqueueSnackbar(tasksState.error.toString(), {
            variant: 'error'
        });
        //loadTasks();
    }, [tasksState.error]);

    useEffect(() => {
        if (CurrentTask && CurrentTask.Programs) {
            updateAssistanceNumbers(CurrentTask.Programs.map((p) => {
                return {
                    phoneOwner: p.ProgramName,
                    phone: `tel:${p.PhoneNumber}`,
                    phoneText: p.PhoneNumber
                }
            }
            ));
        }
        //loadTasks();
    }, [CurrentTask]);

  const TaskHeader = (props) => {
        const classes = useStyles();

        return (
            <Grid container className={classes.taskheader} >
                
                <Grid xs={5} item >
                <FilterWithIcon icon={FilterListIcon}  className={classes.leftalign} />
                </Grid>
                <Grid xs={2} item >
                    <Typography className={classes.centeralign} variant="h5">My Tasks</Typography>
                </Grid>
                <Grid xs={5} item >
                    <SortWithIcon icon={() => <SkbSortIcon sortByAscent={props.tasksState.taskSortByAscent} />}  className={classes.rightalign} />
                    
                </Grid>
            </Grid>
        );
    }

    if(ViewType === "ConsignmentTasks" ){
        return <ConsignmentTasks {...props} />
    }

    return (
        <div>
            <Grid container className={classes.root} >
                <Grid item xs={12} data-testid="tasksheader">
                    <TaskHeader {...props} />
                </Grid>
                <Grid item xs={12} data-testid="taskscontent">
                    {Array.isArray(props.filteredTasks) && props.filteredTasks.map((t, idx) => {
                        if (t.TaskType==="STOCKRETURN")    //"RETURNSTOCK"
                        {
                        return <TaskSummaryReturnStock 
                        Task={t} changeTask={props.changeTask} modifyConsignmentTask={props.modifyConsignmentTask} Alltasks={props.Tasks}
                        user={props.User} lockedBy={props.lockedBy} loadTaskStatusAndLockedBy={props.loadTaskStatusAndLockedBy}
                        />}
                        else {
                            return <TaskSummary 
                            Task={t} changeTask={props.changeTask} onStatusChange={props.onStatusChange} Alltasks={props.Tasks}
                            />
                                
                        }  
                    })
                    }

                    
                </Grid>
                <Grid item xs={12} data-testid="tasksfooter" className={classes.tasksfooter}>
                    {!tasksState.isLoading && <SkbPullToRefresh //pull down to refresh task
                        pullDownContent={<PullDownContent />}
                        releaseContent={<ReleaseContent />}
                        refreshContent={<RefreshContent />}
                        pullDownThreshold={50}
                        onRefresh={() => {
                            return new Promise((resolve) => {
                                loadTasks();
                                resolve();
                            });
                         
                            // return new Promise((resolve) => {
                            //     if(Sync.isOnline && (Sync.isPhotoSyncing || Sync.isMetadataSyncing)) 
                            //     {
                            //         syncIsOn=true
                            //     } else {
                            //         syncIsOn=false;
                            //     }
                            //     //this is not initial loading, so the second parameter is false
                            //     loadTasks(true,false,syncIsOn);//load server data when network is available
                            //     resolve();
                            //);
                        }}
                        // triggerHeight={50}
                        triggerHeight="auto"
                        //   backgroundColor='white'
                        startInvisible={true}
                    >
                        <div className={classes.tasksfooter}>
                            <Typography variant="body2">
                                End of Task List
                    </Typography>
                            <Typography variant="body2">
                                <i>Pull down to refresh</i>
                            </Typography>
                        </div>
                    </SkbPullToRefresh>}
                </Grid>
            </Grid>
            
            {tasksState.isLoading && <Loading />}
        </div>
    );

}
//All Stock Tasks ,Stock Task\Return  All Stock” or “Stock Task\Stocktake Task” or “Stock Task\Return Faulty Stock Task” or “Stock Task\Return Specified Stock Task”… 

function filterTasksAndSortBy(task){
    var filteredtask;
    var filteredSubtask;
    var clonedTask;
    clonedTask=[];


    if (task.taskTypeFilter ==="All Stock Tasks"){
        filteredtask=[...task.Tasks];
        return filteredtask;
    }else{
         if(Array.isArray(task.Tasks) && task.Tasks.length >0){
             for(let i=0;i<task.Tasks.length;i++){
                 if(task.Tasks[i].TaskType=== "STOCKRETURN"){
                    if(Array.isArray(task.Tasks[i].SubTasks)){
                        filteredSubtask=task.Tasks[i].SubTasks.filter(function(item){
                            return item.ReturnType.toLowerCase() === task.taskTypeFilter.toLowerCase()
                        })
                    }
                 }
                 if(Array.isArray(filteredSubtask) && filteredSubtask.length >0){
                     var  oneTasks= {
                        TaskId: task.Tasks[i].TaskId,
                        TaskType: task.Tasks[i].TaskType, 
                        SubTasks:filteredSubtask

                    }
                    clonedTask.push(oneTasks)
                 }
                 }
             }
             filteredtask=[...clonedTask];
             return filteredtask;
        } 
       
}

function sortTask(task,sortBy,taskSortByAscent){
    var sortedTask;
    if(taskSortByAscent){
        //sort each sub task first
        for(let i =0;i<task.length;i++){
            if(Array.isArray(task[i].SubTasks)){
                task[i].SubTasks.sort(function (a, b) {
                    if(sortBy ==="Create Date"){
                        return  new Date(a.DateCreated) - new Date(b.DateCreated);
                    } else if (sortBy ==="Last Modified Date"){
                       return  new Date(a.ModifiedDate) - new Date(b.ModifiedDate);
                   }else if (sortBy ==="Task Status"){
                       return a.TaskStatus.localeCompare(b.TaskStatus)
                   }else{
                       return  a.SubTaskId - b.SubTaskId;
                   }
                  })
            }
        }

        if(Array.isArray(task) && task.length>0 ){
            task.sort(function (a, b) {
                if(sortBy ==="Create Date"){
                    return  new Date(a.SubTasks[0].DateCreated) - new Date(b.SubTasks[0].DateCreated);
                } else if (sortBy ==="Last Modified Date"){
                return  new Date(a.SubTasks[0].ModifiedDate) - new Date(b.SubTasks[0].ModifiedDate);
            }else if (sortBy ==="Task Status"){
                return a.SubTasks[0].TaskStatus.localeCompare(b.SubTasks[0].TaskStatus)
            }else{
                return  a.TaskId - b.TaskId;
            }
            //console.log('sort subtask..000',a.SubTasks[0].{SubTaskId} , b.SubTasks[0],sortBy)
            //return a.TaskId - b.TaskId;
            
            }) 
        }
            
    }else{
        for(let i =0;i<task.length;i++){
            if(Array.isArray(task[i].SubTasks)){
                task[i].SubTasks.sort(function (a, b) {
                    if(sortBy ==="Create Date"){
                        return   new Date(b.DateCreated)-new Date(a.DateCreated) ;
                    } else if (sortBy ==="Last Modified Date"){
                       return   new Date(b.ModifiedDate)-new Date(a.ModifiedDate) ;
                   }else if (sortBy ==="Task Status"){
                        return b.TaskStatus.localeCompare(a.TaskStatus)
                      
                   }else{
                       return   b.SubTaskId-a.SubTaskId ;
                   }
                  })
            }
        }

        if(Array.isArray(task) && task.length>0 ){
            task.sort(function (a, b) {
                if(sortBy ==="Create Date"){
                    return   new Date(b.SubTasks[0].DateCreated)-new Date(a.SubTasks[0].DateCreated);
                } else if (sortBy ==="Last Modified Date"){
                return   new Date(b.SubTasks[0].ModifiedDate)-new Date(a.SubTasks[0].ModifiedDate);
            }else if (sortBy ==="Task Status"){            
                return b.SubTasks[0].TaskStatus.localeCompare(a.SubTasks[0].TaskStatus)
            }else{
                return   b.TaskId-a.TaskId ;

            }
            //console.log('sort subtask..000',a.SubTasks[0].{SubTaskId} , b.SubTasks[0],sortBy)
            //return a.TaskId - b.TaskId;
            
            }) 
        }
    }
    return task;
}

const mapStateToProps = state => ({
    Tasks: state.tasksV2.Tasks,
    tasksState: state.tasksV2,
    filteredTasks:sortTask(filterTasksAndSortBy(state.tasksV2),state.tasksV2.taskSortBy,state.tasksV2.taskSortByAscent),
    Sync:state.sync,
    CurrentTask: oldTaskActions.getCurrentTask(state.tasks),
    User:state.auth.User

});

const mapDispatchToProps = (dispatch) => {
    return {
        loadTasks: () => {
            dispatch(taskActions.loadTaskList())},
        //showTaskList: () => dispatch(taskActions.showTaskDetails(false)),
        updateAssistanceNumbers: (e) => dispatch(assistanceNumber.updatePhoneNumber(e)),
        changeTask: (taskResourceId) => {
            dispatch(oldTaskActions.changeCurrentTask(taskResourceId));
        },
        updateTaskSortBy: (e) => dispatch(taskActions.updateTaskSortBy(e)),
        updateTaskSortByOrder: () => dispatch(taskActions.updateTaskSortByOrder()),
        updateTaskTypeFilter: (e) => dispatch(taskActions.updateTaskTypeFilter(e)),
        onStatusChange: (allTasks, status) => { dispatch(oldTaskActions.updateStocktakeStatus(allTasks, status)); },
        modifyConsignmentTask:(taskId, subTaskId, taskData) =>{dispatch(taskActions.modifyConsignmentTask(taskId, subTaskId, taskData))},
        lockedBy:(taskId, subTaskId, lockedBy) =>{dispatch(taskActions.updateTaskLockedBy(taskId,subTaskId,lockedBy))},
        loadTaskStatusAndLockedBy:async (subTaskId) =>await taskActions.loadTaskStatusAndLockedBy(subTaskId),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tasks)