import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import SkbTable from '../../../skb_controls/components/SkbTable';
import CloseIcon from '@material-ui/icons/Close';
import {
    Paper,
    Box,
    Grid,
    Typography,
    Container,
    Popover,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {SkbSearchTextField} from "../../../skb_controls/components/SkbSearchTextField"; 
import SkbButton from '../../../skb_controls/components/SkbButton';
import ChangeColumnValue from './ChangeColumnValue';
import Slide from '@material-ui/core/Slide';
import Loading from '../../Loading';
import ItemsTable from './ItemsTable';
// importing selectors
import {
    selectUnMarkedStockTasks,
    selectMarkedStockTasks,
    selectStockTableCols,
    selectSearchedStocks,
    selectChoosenTaskRows,

    selectConsignmentDetails,
    selectFetchingConsignmentDetails,
    selectConsignmentDetailsError,

    selectConsignmentInfo,
    selectFetchingConsignmentInfo,
    selectConsignmentInfoError,

    selectSerialisedItemsTableData,
    selectNonSerialisedItemsTableData,

    selectSerialisedItemsTableData_2,
    selectNonSerialisedItemsTableData_2,

    selectUpdatingConsignmentStatus,
    selectUpdateConsignmentStatusSuccess,
    selectUpdateConsignmentStatusError,

    selectOverriddenConsignmentActual,
    selectLoadingOverrideConsignmentActual,
    selectOverrideConsignmentActualError,

} from '../../../Redux/ConsignmentTasks/selectors';
// importing actions
import {
    setSelectedTasksRows,
    searchStockTasks,

    fetchConsignmentDetailsStart,
    fetchConsignmentInfoStart,

    updateConsignmentStatusStart,

    clearSelectedTaskRows,
    
    
} from '../../../Redux/ConsignmentTasks/actions';
import { useSnackbar } from 'notistack';



const useStyles = makeStyles((theme)=>({

    container : {
        width : "100%",
        height : "100%",
    },
    linkLook : {
        cursor: "pointer",
        userSelect : "none",
        color : "blue",
        textDecoration : "underline",
        transition : "0.3s all",
        letterSpacing : "1px",
    },
    IdAndCloseButtonFlex : {
        display: "flex",
        justifyContent: "space-between",
        width: "12%",
    },
    CloseButton : {
        background: 'red',
        color : '#ffff',
    },
    errorMessage : {
        backgroundColor : "#F13030",
        color : "#ffff",
        padding : "1em 1.5em",
        border : '1px solid #5A0001',
        borderRadius : '5px',
        textAlign : "center",
    }

}))
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const ApproveStockConfirmationPopup = ({open,handleClose,acknowledgeStock})=>{
    
    return(
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Acknowledge Stock arrival ?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Have You recieved all or parts of this consignment ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <SkbButton onClick={handleClose} size="medium" text={"NO"}/>
                <SkbButton onClick={acknowledgeStock} primary size="medium" text={"YES"}/>
                </DialogActions>
            </Dialog>
            </div>
    )
}


const ItemsTableSkb = ({serialisedItems,nonSerialisedItems,demoActions_select,columns}) =>{
    console.log("Serialised items in props", serialisedItems);
    console.log("Non Serialised items in props" , nonSerialisedItems);
    if(serialisedItems && nonSerialisedItems && serialisedItems.length > 0 && nonSerialisedItems.length > 0) {
        return(
            <SkbTable 
                actions={demoActions_select} 
                columns={columns} 
                dataSource={[...serialisedItems,...nonSerialisedItems]}
                options={true}
                filter={{individualFiltering:true, 
                    individualFilteringSettings:[
                        {column:"col1",filteringType:"disabled"},
                        {column:"col2",filteringType:"text"},
                        {column:"col3",filteringType:"text"},
                        {column:"col4",filteringType:"text"},
                        {column:"col5",filteringType:"text"},
                        {column:"col6",filteringType:"selection", preselects:["Awaiting Acknowledgement","Lost","Available","Unavailable"]},
                    ]
                }}
                
            />
        )
    }
    if(serialisedItems && nonSerialisedItems && serialisedItems.length > 0 && nonSerialisedItems.length === 0){
        return(
            <SkbTable 
                actions={demoActions_select} 
                columns={columns} 
                dataSource={[...serialisedItems]}
                options={true}
                filter={{individualFiltering:true, 
                    individualFilteringSettings:[
                        {column:"col1",filteringType:"disabled"},
                        {column:"col2",filteringType:"text"},
                        {column:"col3",filteringType:"text"},
                        {column:"col4",filteringType:"text"},
                        {column:"col5",filteringType:"text"},
                        {column:"col6",filteringType:"selection", preselects:["Awaiting Acknowledgement","Lost","Available","Unavailable"]},
                    ]
                }}
                
            />
        )
    }
    if(serialisedItems && nonSerialisedItems && serialisedItems.length === 0 && nonSerialisedItems.length > 0){
        return(
            <SkbTable 
                actions={demoActions_select} 
                columns={columns} 
                dataSource={[...nonSerialisedItems]}
                options={true}
                filter={{individualFiltering:true, 
                    individualFilteringSettings:[
                        {column:"col1",filteringType:"disabled"},
                        {column:"col2",filteringType:"text"},
                        {column:"col3",filteringType:"text"},
                        {column:"col4",filteringType:"text"},
                        {column:"col5",filteringType:"text"},
                        {column:"col6",filteringType:"selection", preselects:["Awaiting Acknowledgement","Lost","Available","Unavailable"]},
                    ]
                }}
                
            />
        )
    }
    return (
        <SkbTable 
            actions={demoActions_select} 
            columns={columns} 
            dataSource={[...nonSerialisedItems,...serialisedItems]}
            options={true}
            filter={{individualFiltering:true, 
                individualFilteringSettings:[
                    {column:"col1",filteringType:"disabled"},
                    {column:"col2",filteringType:"text"},
                    {column:"col3",filteringType:"text"},
                    {column:"col4",filteringType:"text"},
                    {column:"col5",filteringType:"text"},
                    {column:"col6",filteringType:"selection", preselects:["Awaiting Acknowledgement","Lost","Available","Unavailable"]},
                ]
            }}
            
        />
    );
}

const ManualAchknowledgement = ({
    openPopup,
    _t,
    setPopupOpen,

    // actions,
    setRows,
    searchStockTasks,
    fetchDetails,
    fetchInfo,

    updateConsignmentStatus,
    clearChoosenItems,


    // selectors
    unMarkedStockTasks,
    markedStockTasks,
    columns,
    searchedStocks,
    // choosenStockItems,

    details,
    fetchingDetails,
    detailsError,

    info,
    fetchingInfo,
    infoError,

    serialisedItems,
    nonSerialisedItems,

    updatingConsignmentStatus ,
    updateConsignmentStatusSuccess,
    updateConsignmentStatusError,

    overridenConsignment,
    overridingConsignment,
    overrideError,


}) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [open,setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const idPopOverOpen = Boolean(anchorEl);

    const [openApprove, setOpenApprove] = React.useState(false);
    const [searchTerm,setSearchTerm] = useState("");
    const [stockApproved,setStockApproved] = useState(false);
    const [showTable] = useState(
        "DisplayTableInManualAcknowledgement" in localStorage 
        ?
        JSON.parse(localStorage.getItem("DisplayTableInManualAcknowledgement"))
        :
        null
    )

    const handleClickOpen = () => {
        setOpenApprove(true);
    };

    const handleClose = () => {
        setOpenApprove(false);
    };

    const acknowledgeStock = () => {
        const data = {
            Consignments : [
                {
                    LocationCode : details?.ConsignmentLocationCode,
                    ConsignmentStatus : "Acknowledged",
                    ActivityType : "Approve",
                }
            ]
        }
        updateConsignmentStatus(data);
        setOpenApprove(false);
        // setPopupOpen(false);
        // setStockApproved(true);
    }


    // stuff for the skb table
    const demoActions_select=[
        {type:'select',onClick:(rowData)=>setRows(rowData)},                        
        // {type:'delete',onClick:()=>console.log("delete")},
        // {type:'approve',onClick:()=>console.log("approve")},
        // {type:'cancel',onClick:()=>console.log("cancel")}
    ];

    useEffect(()=>{
        if(openPopup) {
            setOpen(true)
            clearChoosenItems();
            fetchDetails(_t?.ConsignmentLocationCode);
            fetchInfo(_t?.ConsignmentLocationCode);
            
        }
        else{
            setOpen(false);
        } 
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[openPopup])
    useEffect(()=>{
        if(updateConsignmentStatusSuccess)
            enqueueSnackbar(`Successfully Acknowledged Consignment #${_t?.CompanySubTask?.Id}` , {variant:"success"})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[updateConsignmentStatusSuccess])
    // useEffect(()=>{
    //     if(updateConsignmentStatusError)
    //         enqueueSnackbar(`Consignment #${_t?.CompanySubTask?.Id} acknowledge failed | ${updateConsignmentStatusError?.message}` , {variant:"error"})
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[updateConsignmentStatusError])
    
    useEffect(()=>{
        if(stockApproved) enqueueSnackbar(`Successfully Acknowledged stock consignment #${_t?.CompanySubTask?.Id}`,{variant:"success"})
        setStockApproved(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stockApproved])

    useEffect(()=>{
        if(overridenConsignment)
            enqueueSnackbar(
                "Successfully marked selected items",
                {variant : "success"}
            )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[overridenConsignment])

    useEffect(()=>{
        if(overrideError)
            enqueueSnackbar(
                `Overriding consignment error : ${overrideError?.message}`,
                {variant : "error"}
            )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[overrideError])

    return(
        
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            fullScreen
        >
            
            <DialogTitle>
                <Grid container style={{justifyContent:"space-between"}}>
                    <Grid item>
                        <Typography variant="h5">{_t?.Name}</Typography>
                    </Grid>
                    <Grid item
                        
                        className={classes.IdAndCloseButtonFlex}
                    >
                        <Typography 
                            variant="h6" 
                            className={classes.linkLook}
                            onMouseEnter={e=>setAnchorEl(e.currentTarget)}
                            onMouseLeave={()=>setAnchorEl(null)} 

                        >ID : {_t?.CompanySubTask?.Id}</Typography>
                        <Popover
                            sx={{pointerEvents : "none"}}
                            open={idPopOverOpen}
                            onClose={()=>setAnchorEl(null)}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            disableRestoreFocus

                        >
                            <Box sx={{padding:"0.5em"}}>
                                <Typography variant="body2">
                                    {_t?.FromLocation?.Name}
                                    {_t?.FromLocation?.Address}
                                </Typography>
                            </Box>
                        </Popover>
                        <Typography variant="h5"
                            onClick={()=>{
                                setPopupOpen(false);
                            }}
                            style={{cursor:"pointer",userSelect:"none"}}
                        >
                            <SkbIcon icon={CloseIcon} />
                        </Typography>
                    </Grid>

                </Grid>
                <Typography variant="body2">
                    Please see the list of stock within the stock consignment sent to you.
                    If you received the stock, please click the acknowledge button.
                </Typography>
                <br />
                <SkbSearchTextField 
                    value={searchTerm}
                    onChange={e=>{
                        setSearchTerm(e.target.value);
                        searchStockTasks(e.target.value);
                        
                    }}
                    className={classes.paper} 
                    placeholder="Stock description/ Serial #"
                />
            </DialogTitle>
            <DialogContent>
                <ChangeColumnValue />
                {
                    ( showTable && serialisedItems && nonSerialisedItems )
                    &&
                    <SkbTable
                        demoActions_select={demoActions_select}
                        columns={columns}
                        dataSource={[...nonSerialisedItems , ...serialisedItems]}
                    />
                }
                {
                    ( !showTable && serialisedItems && nonSerialisedItems )
                    &&
                    (
                        ( 
                            serialisedItems.length > 0
                            ||
                            nonSerialisedItems.length > 0 
                        )
                        &&
                        <ItemsTable 
                            data={searchedStocks.length > 0 ? searchedStocks : [...nonSerialisedItems, ...serialisedItems]}
                        />
                    )
                }
                {   
                    (
                        _t?.CompanySubTask?.Status === "Awaiting Acknowledgement"
                        ||
                        _t?.CompanySubTask?.Status === "In Transit"
                    )
                    ?
                    <SkbButton 
                        id="medium-primary-button" 
                        primary 
                        size="medium" 
                        text={"Acknowledge"} 
                        style={{
                            display:"block",
                            margin : "0.5em auto",
                        }}
                        onClick={handleClickOpen}
                    />
                    :
                    <SkbButton 
                        id="medium-primary-button" 
                        primary 
                        size="medium" 
                        text={"Acknowledge"} 
                        disabled={true}
                        style={{
                            display:"block",
                            margin : "0.5em auto",
                        }}
                    />
                }
                <ApproveStockConfirmationPopup 
                    handleClose={handleClose}
                    open={openApprove}
                    acknowledgeStock={acknowledgeStock}
                />
                {
                    updateConsignmentStatusError
                    &&
                    updateConsignmentStatusError?.response
                    &&
                    <Typography className={classes.errorMessage} variant="body2">
                        {JSON.stringify(updateConsignmentStatusError)}
                    </Typography>
                }
            </DialogContent>
            {( fetchingInfo || fetchingDetails || updatingConsignmentStatus || overridingConsignment) && <Loading /> }
        </Dialog>
            
    )

}
const mapState = state => ({
    unMarkedStockTasks : selectUnMarkedStockTasks(state),
    markedStockTasks : selectMarkedStockTasks(state),
    columns : selectStockTableCols(state),
    searchedStocks : selectSearchedStocks(state),
    choosenStockItems : selectChoosenTaskRows(state),

    details : selectConsignmentDetails(state),
    fetchingDetails : selectFetchingConsignmentDetails(state),
    detailsError : selectConsignmentDetailsError(state),

    info : selectConsignmentInfo(state),
    fetchingInfo : selectFetchingConsignmentInfo(state),
    infoError : selectConsignmentInfoError(state),

    serialisedItems : selectSerialisedItemsTableData(state),
    nonSerialisedItems : selectNonSerialisedItemsTableData(state),

    // serialisedItems : selectSerialisedItemsTableData_2(state),
    // nonSerialisedItems : selectNonSerialisedItemsTableData_2(state),

    updatingConsignmentStatus : selectUpdatingConsignmentStatus(state),
    updateConsignmentStatusSuccess : selectUpdateConsignmentStatusSuccess(state),
    updateConsignmentStatusError : selectUpdateConsignmentStatusError(state),

    overridenConsignment : selectOverriddenConsignmentActual(state),
    overridingConsignment : selectLoadingOverrideConsignmentActual(state),
    overrideError : selectOverrideConsignmentActualError(state),
});
const mapDispatch = dispatch =>({
    fetchDetails : id => dispatch(fetchConsignmentDetailsStart(id)),
    fetchInfo : id => dispatch(fetchConsignmentInfoStart(id)), 
    setRows : rows=>dispatch(setSelectedTasksRows(rows)),
    searchStockTasks  : word => dispatch(searchStockTasks(word)),

    updateConsignmentStatus : data => dispatch(updateConsignmentStatusStart(data)),
    clearChoosenItems : ()=>dispatch(clearSelectedTaskRows()),
});

export default connect(mapState,mapDispatch)(ManualAchknowledgement);