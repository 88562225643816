import React,{useState} from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { lighten, darken,makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/modules/_share/tableIcons';
import SkbTextField from './SkbTextField';
import SkbButton from './SkbButton';
import AddIcon from '@material-ui/icons/Add';
import { SkbLogger, SeverityLevel } from '../../services';

import FilterIconFiltered from '@material-ui/icons/FilterList';
import FilterIconBlank from '@material-ui/icons/Maximize';
import FilterIconCleared from '@material-ui/icons/FilterListRounded';

import { Link } from '@material-ui/core';
import SkbIcon from './SkbIcon';
import IconButton from '@material-ui/core/IconButton';
import SkbMTableHeader from './skb-m-table-header'; //for overwriting selectAll in table header

const useStyles = makeStyles((theme) => ({

  centredTableColumn: {

    "& .MuiInputBase-input.MuiInput-input":
    {
      textAlign: 'center',
      fontSize: 'inherit'
    }
  },

  table: {

    "& > div > div > div > div": {
      overflowY: "inherit!important"

    },

    //moved to the upper layer component
    /*
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 16px)',
      //margin: theme.spacing(0),

    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 200px)',  //was (100vw - 220px) 
      marginBottom: theme.spacing(2),
    },
    */

      //At the moment this is the same as sm, but keeping it separate in case it needs tuning.  
      [theme.breakpoints.up('md')]: {
        "& .MuiTableRow-root .MuiTableCell-sizeSmall": {
          padding: '6px 8px 6px 8px'    
        }
      },
    

    [theme.breakpoints.down('sm')]: {

      "& .MuiTableRow-root .MuiTableCell-sizeSmall": {
        padding: '6px 8px 6px 8px'    //The "dense" table padding is still too big on a small screen.
      }
    },

    [theme.breakpoints.down('xs')]: {
      //moved to the upper layer component
      //width: 'calc(100vw - 8px)',
      //marginBottom: theme.spacing(0),
      "& .MuiTableRow-root .MuiTableCell-sizeSmall": {
        padding: '6px 2px 6px 2px'    //The "dense" table padding is still too big on a small screen.
      }
    },

    //moved to the upper layer component
    /*
    [theme.breakpoints.down(376)]:  //376px wide or less
      isProblemDevice()?{      //and one of the known problem devices
        width: 'calc(90vw - 8px)'   //Add a gap at the side to allow for scrolling.
      }
      :{},
      */

    "& .MuiTableRow-root .MuiTableCell-root .MuiLink-root":
      theme.palette.type === 'light'
        ? {
            color: theme.palette.primary.light
        }
        : {
          color: theme.palette.primary.main
        },

    "& .MuiTableRow-root .MuiTableCell-root .MuiIconButton-colorInherit":
      theme.palette.type === 'light'
        ? {
            color: theme.palette.primary.light
        }
        : {
            color: theme.palette.primary.main
        },

    "& .MuiToolbar-root .MTableToolbar-actions-450 .MuiIconButton-colorInherit":
      theme.palette.type === 'light'
        ? {
              color: theme.palette.primary.light
          }
          : {
              color: theme.palette.primary.main
          },

//TODO the below css section is not supposed to work as the wildcard * does not fit here
    //it works if i use multiple css sections to replace the * to be something like 95, 97, 98, 101, 131...
    //but i didn't find a way to define it like div[class*="MTableToolbar-highlight-"]
    "& .MuiPaper-root .MTableToolbar-highlight-*":  //tool bar (table top banner) with selected rows
      theme.palette.type === 'light'
        ? {
              color: theme.palette.primary.light,
              backgroundColor: lighten(theme.palette.primary.light,0.85)+"!important"
          }
          : {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.dark+"!important"
          },

//TODO the below css section is not supposed to work as the wildcard * does not fit here
    //it works if i use multiple css sections to replace the * to be something like 97, 99...
    //but i didn't find a way to define it like div[class*="MTableToolbar-highlight-"]
    "& .MuiPaper-root .MuiToolbar-regular .MTableToolbar-actions-* .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root":  //tool bar free buttons
      theme.palette.type === 'light'
        ? {
              color: theme.palette.primary.light,
              backgroundColor: lighten(theme.palette.primary.light,0.85)+"!important"
          }
          : {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.dark+"!important"
          },

    "& .MuiPaper-root .MuiToolbar-gutters":  //tool bar (table top banner) without selected rows
      theme.palette.type === 'light'
      ? {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.background.default
        }
        : {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default
        },

    "& .MuiTableRow-root .MuiTableCell-root .MuiCheckbox-root .MuiIconButton-label .MuiSvgIcon-root":
      {
        fontSize: '1.25em!important'
      },

    "& .MuiTableRow-root .MuiTableCell-paddingNone":
      {
        fontWeight: 'normal!important',
        fontSize: '1em!important'
      },

    "& .MuiTypography-root.MuiTypography-h6":
      {
        fontSize: '0.75em!important'
      },

      "& .MuiTableRow-root .MuiIconButton-root":
      {
        padding: '4px',
        marginLeft: '5px'
      },

      "& .MuiTableRow-root .MuiTablePagination-root .MuiTablePagination-toolbar":
      {
        backgroundColor: theme.palette.background.default,
        textAlign:'center'
      },

      "& .MuiTableRow-root .MuiTableCell-footer .MuiTablePagination-toolbar":
      {
        fontSize:"1.3em"
      },

      "& .MuiTableRow-root .MuiTableCell-footer .MuiTablePagination-toolbar .MuiTypography-caption":
      {
        fontSize:"1.1em"
      },

      "& .MuiTableRow-root .MuiTableCell-root .MuiCheckbox-colorSecondary":
      theme.palette.type === 'light'
        ? {
              color: theme.palette.primary.light,
              //backgroundColor: lighten(theme.palette.primary.light,0.85)
          }
          : {
              color: theme.palette.primary.main,
              //backgroundColor: darken(theme.palette.primary.main,0.85)
          },
      //TODO the below css section only applies on the selection table's checkbox
      //I didn't find a way for the whole rows
      "& .MuiTableRow-root .MuiTableCell-root .Mui-checked:hover":
        theme.palette.type === 'light'
          ? {
            backgroundColor: (lighten(theme.palette.primary.light,0.7))+"!important"
          }
          : {
            backgroundColor: darken(theme.palette.primary.dark,0.2)+"!important"
          },

      //TODO the below css section is trying to change the orange color on the drop-down-filtering
      //but it didn't work as the drop-down-filtering css is not in table theme
      ".MuiCheckbox-colorSecondary":
      theme.palette.type === 'light'
        ? {
              color: theme.palette.primary.light
          }
          : {
              color: theme.palette.primary.main
          },

  },
})

);

var lastSelectedRow;
var idFieldName;
var previousRowsSentToSelectHandler=[];
var currentFilterRowStyleDisplay=false;

function getIdFieldNameFromColumns(skbColumns){
  idFieldName='id';
  for (let index = 0; index < skbColumns.length; index++) {
    const oneSkbColumn = skbColumns[index];
    if(oneSkbColumn.type==='hidden-id'){
      idFieldName=oneSkbColumn.field;
      break;
    }else if(oneSkbColumn.type==='visible-id'){
        idFieldName=oneSkbColumn.field;
        break;
    }else if(oneSkbColumn.type==='id'){
        idFieldName=oneSkbColumn.field;
        break;
    }
  }
  return idFieldName;
}

function convertColumns(skbColumns, classes, filter, skbData, handleFilterDisplay){
  let materialTableColumns = [];  
  if(!skbColumns || !skbColumns.length) return [];
  idFieldName='id';
  for (let index = 0; index < skbColumns.length; index++) {
      const oneSkbColumn = skbColumns[index];
      //console.log('oneSkbColumn',oneSkbColumn);
      if(!oneSkbColumn.field || !oneSkbColumn.title || !oneSkbColumn.type){
          continue;
      }
      let oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field};
      if(oneSkbColumn.type==='hidden-id'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:true,editable:'never'};
          idFieldName=oneSkbColumn.field;
      }else if(oneSkbColumn.type==='visible-id'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,editable:'never'};
          idFieldName=oneSkbColumn.field;
      }else if(oneSkbColumn.type==='id'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,editable:'never'};
          idFieldName=oneSkbColumn.field;
      }else if (oneSkbColumn.type==='read-only-long-text'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,editable:'never',width:'40%',align:'left'};
      }else if (oneSkbColumn.type==='read-only-short-text'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,editable:'never',width:'5%',align:'left'};
      }else if (oneSkbColumn.type==='read-only-text'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,editable:'never',align:'left'};
      }else if (oneSkbColumn.type==='text'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,align:'left'};
      }else if (oneSkbColumn.type==='hidden-text'){
        oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:true};
      }else if (oneSkbColumn.type==='number'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,align:'left'};
      }else if (oneSkbColumn.type==='numberic'){
          oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,align:'left'};
      }else if (oneSkbColumn.type==='link'){
          oneMaterialTableColumn = 
            {title:oneSkbColumn.title,field:oneSkbColumn.field,hidden:false,
              editable:'never',align:'left',with:'40%',onClickLink:oneSkbColumn.onClickLink,
              render: (rowData)=>{
                  const theIdValue=rowData[idFieldName];
                  const theDisplayValue=rowData[oneSkbColumn.field];
                  return (
                      <Link id={'link-for-'+theIdValue} color='primary' onClick={(e)=>{ oneMaterialTableColumn.onClickLink(e,theIdValue,rowData) }} >
                          {theDisplayValue}
                      </Link>

                  );
              }};
      }else if (oneSkbColumn.type==='editable-number'){
          oneMaterialTableColumn = 
            {title:oneSkbColumn.title,field:oneSkbColumn.field,
              type: 'numeric', width:'8%',initialEditValue: 0,hidden:false,align:'center',
              onChange:oneSkbColumn.onChange,
              render: (rowData) => {
              const theValue=rowData[oneSkbColumn.field];
              const numberInputProps = { step: 'any' };  //This is the step size of the number stepper (up-down arrow).  By default it is 1, which means that decimals are regarded as invalid.

              return (

              <SkbTextField id="standard-small" type="number" inputProps={numberInputProps} size="small"
                value={theValue} autoselect={true}
                margin="dense"
                className={classes.centredTableColumn}
                onChange={e => oneMaterialTableColumn.onChange(e, rowData, e.target.value)}
                onBlur={(e) => { e.target.value = parseFloat(e.target.value) }}
      
              />
            )}};
      }else if(oneSkbColumn.type==='custom'){
        oneMaterialTableColumn = {title:oneSkbColumn.title,field:oneSkbColumn.field,render:oneSkbColumn.render};
      }

      //console.log('filter',filter);
      if(filter && filter.visible && filter.filterColumns && filter.filterColumns.length){
          if(filter.filterColumns.includes(oneSkbColumn.field)){
              oneMaterialTableColumn.searchable=true;
          }else{
              oneMaterialTableColumn.searchable=false;
          }
      }

      //setup invividual filtering type
      if (oneSkbColumn.type!=='hidden-id' && oneSkbColumn.type!=='hidden-text'){
        if(filter && filter.individualFiltering){
          var lookupRequiredForSelectionFiltering = true; //by default, using selection style filtering
          var disableIndividualFiltering = false;
          var preselectsRequired = false;
          var passThroughCustomFilteringFunction=false;
          var currentPreselectArray=[];
          if(filter.individualFilteringSettings && Array.isArray(filter.individualFilteringSettings)){
            for (let index = 0; index < filter.individualFilteringSettings.length; index++) {
              const oneSetting = filter.individualFilteringSettings[index];
              //console.log('oneSetting',oneSetting);
              if(oneSetting.column && oneSetting.column===oneSkbColumn.field){
                if(oneSetting.filteringType && oneSetting.filteringType!=="selection" && oneSetting.filteringType!=="custom"){
                  //console.log('lookupRequiredForSelectionFiltering',false);
                  lookupRequiredForSelectionFiltering=false;
                }else if(oneSetting.preselects){
                  preselectsRequired=true;
                  //console.log('oneSetting.preselects',oneSetting.preselects);
                  oneSetting.preselects.forEach(oneSelect => {
                    if(oneSelect!==''){
                      currentPreselectArray.push(oneSelect);
                    }
                  });
                  //console.log('currentPreselectArray',currentPreselectArray);
                  //currentPreselectArray=oneSetting.preselects;
                }
                if(oneSetting.filteringType && oneSetting.filteringType==="disabled"){
                  //console.log('disableIndividualFiltering',true);
                  disableIndividualFiltering = true;
                }
                if(oneSetting.filteringType && oneSetting.filteringType==="custom"){
                  lookupRequiredForSelectionFiltering=false;
                  passThroughCustomFilteringFunction=true;
                  if(oneSkbColumn.customFilterAndSearch){
                    oneMaterialTableColumn.customFilterAndSearch=oneSkbColumn.customFilterAndSearch;
                  }
                  if(oneSkbColumn.filterComponent){
                    oneMaterialTableColumn.filterComponent=oneSkbColumn.filterComponent;
                  } 
                  
                }
                break;
              }
            }
          }
          //console.log('lookupRequiredForSelectionFiltering oneSkbColumn',lookupRequiredForSelectionFiltering, oneSkbColumn);
          if(lookupRequiredForSelectionFiltering){
            var currentLookup={};
            //generate lookup as required by material-table
            for (let i = 0; i < skbData.length; i++) {
              const oneData = skbData[i];
              const oneValue=oneData[oneSkbColumn.field];
              var lookupValue = null;
              //console.log('lookupRequiredForSelectionFiltering oneValue',oneValue);
              if((typeof oneValue) === "object" 
                && oneValue.type && (oneValue.type==="span" || oneValue.type==="div") 
                && (oneValue.props && (oneValue.props.children || oneValue.props.valueForFiltering)) ){
                  if(oneValue.props && oneValue.props.valueForFiltering){  //valueForFiltering has higher priority than children
                    lookupValue=oneValue.props.valueForFiltering; 
                  }else{
                    lookupValue=oneValue.props.children;
                  }
              }else if ((typeof oneValue) === "string" || (typeof oneValue) === "number"){
                lookupValue=oneValue;
              }
              //console.log('lookupRequiredForSelectionFiltering lookupValue',lookupValue);
              if(lookupValue && lookupValue!=='' && !currentLookup[lookupValue]) {
                currentLookup[lookupValue]=lookupValue;  //NO SOLUTION YET: the oneValue (rowData/Component) is different when we introduce category lookupValue
                //TODO: trying to accept values only and wrap them in colour component (like Link)
                //console.log('added lookupValue',lookupValue);
              }
            }
            //console.log('currentLookup',currentLookup);
            //add preselects into lookup in case they(preselects) don't actually have rows
            if(filter.individualFilteringSettings && Array.isArray(filter.individualFilteringSettings)){
              for (let index = 0; index < filter.individualFilteringSettings.length; index++) {
                const oneSetting = filter.individualFilteringSettings[index];
                if(oneSetting.column && oneSetting.column===oneSkbColumn.field){
                  if(oneSetting.preselects){
                    oneSetting.preselects.forEach(oneSelect => {
                      if(oneSelect!=='' && !currentLookup[oneSelect]) {
                        currentLookup[oneSelect]=oneSelect;  
                      }
                    });
                  }
                  break;
                }
              }
            }

            oneMaterialTableColumn.lookup=currentLookup;
          }

          if(preselectsRequired){
            //console.log('preselectsRequired for oneSkbColumn',oneSkbColumn);
            oneMaterialTableColumn.defaultFilter=currentPreselectArray;
          }

          var needsCustomFilterFunctionToUnwrap = false;
          for (let i = 0; i < skbData.length; i++) {
            const oneData = skbData[i];
            const oneValue=oneData[oneSkbColumn.field];
            if((typeof oneValue) === "object" 
              && oneValue.type && (oneValue.type==="span" || oneValue.type==="div") 
              && (oneValue.props && (oneValue.props.children || oneValue.props.valueForFiltering)) ){
                needsCustomFilterFunctionToUnwrap=true;
                break;
            }
          }

          if(needsCustomFilterFunctionToUnwrap && !passThroughCustomFilteringFunction){
            oneMaterialTableColumn.customFilterAndSearch = (term,rowData) => {
              //console.log('customFilterAndSearch starting',term,rowData);
              const rowValue=rowData[oneSkbColumn.field];
              var lookupValue = null;
              if((typeof rowValue) === "object" 
                && rowValue.type && (rowValue.type==="span" || rowValue.type==="div") 
                && (rowValue.props && (rowValue.props.children || rowValue.props.valueForFiltering)) ){
                  if(rowValue.props && rowValue.props.valueForFiltering){  //valueForFiltering has higher priority than children
                    lookupValue=rowValue.props.valueForFiltering; 
                  }else{
                    lookupValue=rowValue.props.children;
                  }
              }else if ((typeof rowValue) === "string" ){
                lookupValue=rowValue;
              }
              //console.log('customFilterAndSearch lookupValue',lookupValue);

              if(Array.isArray(term)){
                if(!lookupValue || term.includes(lookupValue)) {
                  //console.log('customFilterAndSearch returning true.');
                  return true;
                }
                else {
                  //console.log('customFilterAndSearch returning false.');
                  return false;
                }
              }else {
                //string
                if(lookupValue.toUpperCase().includes(term.toUpperCase())){
                  //console.log('customFilterAndSearch returning true.');
                  return true;
                }else{
                  //console.log('customFilterAndSearch returning false.');
                  return false;
                }
              }

              
            };
        }

          if(disableIndividualFiltering){
            oneMaterialTableColumn.filtering=false;
            //blank filter icon on column title
            oneMaterialTableColumn.title=<div><IconButton size='small' aria-label="filtering" id="btn-skbtable-filtering-display" color='primary' onClick={handleFilterDisplay}> <SkbIcon icon={FilterIconBlank}/> </IconButton> {oneMaterialTableColumn.title} </div> ;
          }else{
            //filter icon on column title
            oneMaterialTableColumn.title=<div><IconButton size='small' aria-label="filtering" id="btn-skbtable-filtering-display" color='primary' onClick={handleFilterDisplay}> <SkbIcon icon={FilterIconFiltered} /> </IconButton> {oneMaterialTableColumn.title} </div> ;
          }
        }//end filtering
      }//end if hidden
      
      //console.log('oneMaterialTableColumn',oneMaterialTableColumn);

      //using custom sorter if data contains <span> or <div>
      if(skbData && skbData.length){
        var needsCustomSorterToUnwrap = false;
        for (let k = 0; k < skbData.length; k++) {
          const oneRow = skbData[k];
          const oneValue=oneRow[oneSkbColumn.field];
          if((typeof oneValue) === "object" 
                && oneValue.type && (oneValue.type==="span" || oneValue.type==="div") 
                && (oneValue.props && (oneValue.props.children || oneValue.props.value)) ){
            //console.log('oneValue.props',oneValue.props);
            needsCustomSorterToUnwrap=true;
            break;
          }
        }
        if(needsCustomSorterToUnwrap){
          oneMaterialTableColumn.customSort = (rowA,rowB) => {
                        var valueA=rowA[oneSkbColumn.field];
                        var valueB=rowB[oneSkbColumn.field];
                        var unwrapperValueA=valueA;
                        if(valueA.props && valueA.props.value) unwrapperValueA=valueA.props.value
                        else if(valueA.props && valueA.props.children) unwrapperValueA=valueA.props.children
                        var unwrapperValueB=valueB;
                        if(valueB.props && valueB.props.value) unwrapperValueB=valueB.props.value
                        else if(valueB.props && valueB.props.children) unwrapperValueB=valueB.props.children
                        if(isNaN(unwrapperValueA) || isNaN(unwrapperValueB)){
                          //comparing string
                          if(unwrapperValueA===unwrapperValueB) return 0
                          else if (unwrapperValueA>unwrapperValueB) return 1
                          else return -1;
                        }else{
                          //comparing number
                          if(parseFloat(unwrapperValueA)===parseFloat(unwrapperValueB)) return 0
                          else if (parseFloat(unwrapperValueA)>parseFloat(unwrapperValueB)) return 1
                          else return -1;
                        }
                        
                      };
        }
      }

      materialTableColumns.push(oneMaterialTableColumn);
  } //end for
  return materialTableColumns;
}


function convertActions(skbActions){
  let materialTableActions = [];  
  if(!skbActions || !skbActions.length) return [];
  for (let index = 0; index < skbActions.length; index++) {
      const oneSkbAction = skbActions[index];
      if(!oneSkbAction.onClick || !oneSkbAction.type){
          continue;
      }
      if(oneSkbAction.type==='select' || oneSkbAction.type==='single-select'){
          continue; //to be handled as options
      }
      let oneMaterialTableAction = {icon:oneSkbAction.type,tooltip:oneSkbAction.type,onClick:oneSkbAction.onClick};
      if(oneSkbAction.type==='save'){
          oneMaterialTableAction = {icon:tableIcons.Save,tooltip:oneSkbAction.type,onClick:oneSkbAction.onClick};
      }else if(oneSkbAction.type==='delete'){
          oneMaterialTableAction = {icon:tableIcons.Delete,tooltip:oneSkbAction.type,onClick:oneSkbAction.onClick};
      }else if(oneSkbAction.type==='edit'){
        oneMaterialTableAction = {icon:tableIcons.Edit,tooltip:oneSkbAction.type,onClick:oneSkbAction.onClick};
      }else if(oneSkbAction.type==='approve'){
        oneMaterialTableAction = {icon:tableIcons.CheckCircle,tooltip:oneSkbAction.type,onClick:oneSkbAction.onClick};
      }else if(oneSkbAction.type==='cancel'){
        oneMaterialTableAction = {icon:tableIcons.Cancel,tooltip:oneSkbAction.type,onClick:oneSkbAction.onClick};
    }
      materialTableActions.push(oneMaterialTableAction);
  }
  return materialTableActions;
}

function checkSingleSelect(skbActions){ 
  if(!skbActions || !skbActions.length) return false;
  for (let index = 0; index < skbActions.length; index++) {
      const oneSkbAction = skbActions[index];
      if(!oneSkbAction.onClick || !oneSkbAction.type){
          continue;
      }
      if( oneSkbAction.type==='single-select'){
          return true;
      }
     
  }
  return false;
}

function convertData(skbData,skbColumns,options){
  //console.log('convertData starting');
  let materialTableData = [];  
  lastSelectedRow=null; //re-init
  previousRowsSentToSelectHandler=[]; //re-init
  if(!skbData || !skbData.length){
    //console.log('convertData blank');
    return materialTableData;
  } 
  //error tolerant: when options.selectedIdValues is not an array
  if(options && options.selectedIdValues && typeof(options.selectedIdValues)==='string'){
    if(options.selectedIdValues.length){ //string length
      options.selectedIdValues=[options.selectedIdValues];
    }else{
      options.selectedIdValues=null;
    }
    //console.log('shape selectedIdValues to be',options.selectedIdValues);
  }
  if(!options || !options.selectedIdValues || !Array.isArray(options.selectedIdValues)){
    //console.log('convertData not change');
    materialTableData=skbData;
    return materialTableData;
  }
  idFieldName = getIdFieldNameFromColumns(skbColumns);
  //console.log('convertData idFieldName',idFieldName);
  //console.log('convertData skbData.length',skbData.length);
  const n=skbData.length;
  for (let index = 0; index < n; index++) {
    //console.log('convertData index',index);
    const oneSkbData = skbData[index];
    //console.log('convertData oneSkbData',oneSkbData);
    let oneMaterialTableData = oneSkbData; //JSON.parse(JSON.stringify(oneSkbData));
    if(oneSkbData[idFieldName] && options.selectedIdValues && options.selectedIdValues.length && options.selectedIdValues.includes(oneSkbData[idFieldName])){
      //console.log('convertData if in for');
      oneMaterialTableData.tableData={...oneMaterialTableData.tableData};
      oneMaterialTableData.tableData.checked=true;
      lastSelectedRow=oneMaterialTableData;  //initiate lastSelectedRow as per "options.selectedIdValues"
      previousRowsSentToSelectHandler.push(oneMaterialTableData); //initiate previousRowsSentToSelectHandler as per "options.selectedIdValues"
    }else{
      //sometimes, upper level component may forget to update the checked flag in skbdata 
      //so we update it here
      //console.log('convertData else in for');
      oneMaterialTableData.tableData={...oneMaterialTableData.tableData};
      oneMaterialTableData.tableData.checked=false;
    }
    //console.log('init lastSelectedRow',lastSelectedRow);
    //record disabled flag
    if(options && options.disableRowCondition){
      if(options.disableRowCondition(oneSkbData)){
        oneMaterialTableData.tableData.disabled=true;
      }
    }

    materialTableData.push(oneMaterialTableData);
  }
  //console.log('convertData materialTableData',materialTableData);
  return materialTableData;

}

function convertOptions(theme, filter, options, dataSource , columns, actions){
    var mtOptions;

    var dataLength=0;
    if(dataSource && dataSource.length){
      dataLength=dataSource.length;
    }
    //console.log('dataSource.length',dataLength);
    var columnNum=0;
    if(columns && columns.length){
      columnNum=columns.length;
    }
  
    var filterCellStyleDisplayValue = (!options || !options.filterRowStyleDisplay) ? 'none' : null;

    const sharedMtOptions={
      showTitle:false,
      toolbar:((filter && filter.visible) || (actions && actions[0] && actions[0].type==='select')) ? true :false,
      search: (filter && filter.visible) ? true :false,
      padding: 'dense',
      headerStyle:{fontWeight: "bolder",fontSize: "1.0em", textAlign:"center"},
      paging: (dataLength > 10), 
      pageSizeOptions: [10, 20, 50], 
      pageSize: 10,
      emptyRowsWhenPaging:false,
      showFirstLastPageButtons:false,
      filtering:(filter && filter.individualFiltering) ? true :false,
      actionsColumnIndex:(columnNum+1),
      filterCellStyle:{ display:filterCellStyleDisplayValue },
      rowStyle: rowData => { 
        if(rowData.tableData && rowData.tableData.checked){
          if(theme.palette.type==='light'){
            return {backgroundColor:lighten(theme.palette.primary.light,0.85)};
          }else{
            return {backgroundColor:theme.palette.primary.dark};
          }
        }else{
          if(theme.palette.type==='light'){
            return {backgroundColor:theme.palette.background.default};
          }else{
            return {backgroundColor:theme.palette.background.default};
          }
        }
        
      }
      }
      //console.log('dataSource refreshing options',sharedMtOptions.paging,sharedMtOptions.pageSize,sharedMtOptions.pageSizeOptions);

    const selectHandler=selectionHandler(actions);
    const isSingleSelect=checkSingleSelect(actions);



    if(selectHandler && (options && options.subtableDefinition)) {
      mtOptions={
        ...sharedMtOptions,
        selection:true,
        showTextRowsSelected:(!isSingleSelect && (options && options.selectedIdValues!==null && options.selectedIdValues.length>0) ),
        showSelectAllCheckbox: (!isSingleSelect) 
        };

    }else if(selectHandler && !(options && options.detailTableProps)) {
      mtOptions={
        ...sharedMtOptions,
        selection:true,
        showTextRowsSelected:(!isSingleSelect && (options && options.selectedIdValues!=null && options.selectedIdValues.length>0) ),
        showSelectAllCheckbox: (!isSingleSelect), 
        }

    }else if(options && options.subtableDefinition){
        mtOptions={
          ...sharedMtOptions,
          selection:false
          }
    }else{
        mtOptions={
          ...sharedMtOptions,
            selection:false,
            };
    }

    if(selectHandler && options && options.disableRowCondition){
      mtOptions.selectionProps = rowData => {
        if(options.disableRowCondition(rowData)){
          if(theme.palette.type==='light'){
            return {disabled:true, color:theme.palette.text.disabled};  //theme.palette.primary.light
          }else{
            return {disabled:true, color:theme.palette.text.disabled};  //theme.palette.primary.dark
          }
          
        }else{
          
          return {};
        }
      }
    }

    return mtOptions;

}


export function findCurrentTappedRow(currentRows, previousRows){
  var currentTappedRow=null;
  //console.log('findCurrentTappedRow previousRows',previousRows);
  //console.log('findCurrentTappedRow currentRows',currentRows);
  //detecting currentTappedRow based on the previousRows
  //scenario: selecting
  if(currentRows.length>previousRows.length){
    for (let i = 0; i < currentRows.length; i++) {
      const oneRow = currentRows[i];
      var oneRowFound = false;
      for (let j = 0; j < previousRows.length; j++) {
        const onePreviousRow = previousRows[j];
        if(oneRow.tableData.id===onePreviousRow.tableData.id){
          oneRowFound=true;
          if(oneRow.tableData.checked!==onePreviousRow.tableData.checked){
            currentTappedRow=oneRow;
            currentTappedRow.tableData.checked=true;
            break;
          }
        }
      }//end for j
      if(currentTappedRow) break;
      if(!oneRowFound){
        currentTappedRow=oneRow;
        break;
      }                      
    }
  }
  //scenario: deselecting
  else if(previousRows.length>currentRows.length){
    for (let i = 0; i < previousRows.length; i++) {
      const onePreviousRow = previousRows[i];
      var onePreviousRowFound = false;
      for (let j = 0; j < currentRows.length; j++) {
        const oneRow = currentRows[j];
        if(oneRow.tableData.id===onePreviousRow.tableData.id){
          onePreviousRowFound=true;
          if(oneRow.tableData.checked!==onePreviousRow.tableData.checked){
            currentTappedRow=JSON.parse(JSON.stringify(onePreviousRow));
            currentTappedRow.tableData.checked=false;
            break;
          }
        }
      }//end for j
      if(currentTappedRow) break;
      if(!onePreviousRowFound){
        currentTappedRow=JSON.parse(JSON.stringify(onePreviousRow));
        currentTappedRow.tableData.checked=false;
        break;
      }                      
    }
  }  
  if(!currentTappedRow && currentRows && currentRows.length){
    currentTappedRow=currentRows[0];
  }
  return currentTappedRow;

}

export function findCurrentTappedRows(currentRows, previousRows){
  var currentTappedRows=[];
  //console.log('findCurrentTappedRows previousRows',previousRows);
  //console.log('findCurrentTappedRows currentRows',currentRows);
  //detecting currentTappedRow based on the previousRows
  //scenario: selecting
  if(currentRows.length>previousRows.length){
    for (let i = 0; i < currentRows.length; i++) {
      const oneRow = currentRows[i];
      var oneRowFound = false;
      for (let j = 0; j < previousRows.length; j++) {
        const onePreviousRow = previousRows[j];
        if(oneRow.tableData.id===onePreviousRow.tableData.id){
          oneRowFound=true;
          if(oneRow.tableData.checked!==onePreviousRow.tableData.checked){
            oneRow.tableData.checked=true;
            currentTappedRows.push(oneRow);
          }
        }
      }//end for j
      if(!oneRowFound){
        currentTappedRows.push(oneRow);
      }                      
    }
  }
  //scenario: deselecting
  else if(previousRows.length>=currentRows.length){
    for (let i = 0; i < previousRows.length; i++) {
      const onePreviousRow = previousRows[i];
      var onePreviousRowFound = false;
      for (let j = 0; j < currentRows.length; j++) {
        const oneRow = currentRows[j];
        if(oneRow.tableData.id===onePreviousRow.tableData.id){
          onePreviousRowFound=true;
          if(oneRow.tableData.checked!==onePreviousRow.tableData.checked){
            onePreviousRow.tableData.checked=false;
            currentTappedRows.push(JSON.parse(JSON.stringify(onePreviousRow)));
          }
        }
      }//end for j
      if(!onePreviousRowFound){
        currentTappedRows.push(JSON.parse(JSON.stringify(onePreviousRow)));
      }                      
    }
  }  
  if(!currentTappedRows.length && currentRows && currentRows.length){
    currentTappedRows.push(currentRows[0]);
  }
  return currentTappedRows;

}

var theOneCallBackFunctionForClickAction=null;  //global variable for the dynamic function

const normalSelectHandler = (rows) =>{
  //remove the selected (by selectAll) rows if they are disabled
  for (let index = 0; index < rows.length; index++) {
    let theRow = rows[index];
    if(theRow.tableData && theRow.tableData.checked && theRow.tableData.disabled){
      theRow.tableData.checked=false;
    }
  }

  var currentRows = findCurrentTappedRows(rows,previousRowsSentToSelectHandler);
  previousRowsSentToSelectHandler=rows;  //for next round

  var selectedIDs=[];
  for (let index = 0; index < rows.length; index++) {
    var oneRow = rows[index];
    if(oneRow.tableData && oneRow.tableData.checked){
      selectedIDs.push(oneRow[idFieldName]);
    }
  }

  /*
  var newOptions={}
  if(currentOptions && currentOptions.selectedIdValues){
    newOptions= JSON.parse(JSON.stringify(currentOptions));
  }
  newOptions.selectedIdValues=selectedIDs; 
  setCurrentOptions(newOptions); 
  */
  //call back multiple times: one currentRow at a time.
  for (let index = 0; index < currentRows.length; index++) {
    //console.log('currentRows[index]',index,currentRows[index]);
    theOneCallBackFunctionForClickAction(rows, currentRows[index]); //oneSkbAction.onClick(rows, currentRows[index]);
  }

  return;
  
}

const singleSelectHandler = (rows) => {
  //console.log('singleSelectHandler rows',rows);
  //console.log('singleSelectHandler lastSelectedRow',lastSelectedRow);
  var ret=[];
  //sometimes, rows only return the changing rows (the ticked one and the just unticked one), not all the rows
  //even, rows could be blank if unselected the only selected one.
  //so, do not assume rows always have all the selected/unselected rows
  if(rows && rows.length && rows.length>1){ //if multiple rows selected
    var currentSelectedRow;
    const n=rows.length
    for (let index = 0; index < n; index++) {
      var oneRow = rows[index];
      if(!lastSelectedRow || !lastSelectedRow.tableData || lastSelectedRow.tableData.id!==oneRow.tableData.id){
        currentSelectedRow=oneRow;
        //console.log('currentSelectedRow',currentSelectedRow);
      }
    }
    
    //for next time
    lastSelectedRow=currentSelectedRow; 
    //console.log('set new lastSelectedRow (a)',lastSelectedRow);

    ret=[currentSelectedRow];
    
  }else if(rows && rows.length===1){
    if(rows[0].tableData && rows[0].tableData.checked){
      //for next time
      lastSelectedRow=rows[0];
      //console.log('set new lastSelectedRow (b1)',lastSelectedRow);

      ret=rows;
    }else{
      //for next time
      lastSelectedRow=[];
      //console.log('set new lastSelectedRow (b2)',lastSelectedRow);

      ret=[];
    }
  }else{
    //if rows is null or []
    //for next time
    lastSelectedRow=[];
    //console.log('set new lastSelectedRow (c)',lastSelectedRow);

    ret=[];
  }

  /*
  //for temp refreshing
  var newOptions={}
  if(currentOptions && currentOptions.selectedIdValues){
    newOptions= JSON.parse(JSON.stringify(currentOptions));
  }
  if(ret.length>=1){
    newOptions.selectedIdValues=[ret[0][idFieldName]]; //overwrite
  }else{
    newOptions.selectedIdValues=[];
  }
  //console.log('set newOptions',newOptions);
  setCurrentOptions(newOptions); //refresh to de-select the extra one(s)
  */
  //calling back
  //console.log('singleSelectHandler calling back',ret);
  return theOneCallBackFunctionForClickAction(ret); //oneSkbAction.onClick(ret);

}

  //prepare action function for MaterialTable for different scenarios
  const selectionHandler = (skbActions)=>{ 
    if(!skbActions || !skbActions.length) return null;
    for (let index = 0; index < skbActions.length; index++) {
        const oneSkbAction = skbActions[index];
        if(!oneSkbAction.onClick || !oneSkbAction.type){
            continue;
        }
        if(oneSkbAction.type==='select'){
            theOneCallBackFunctionForClickAction=oneSkbAction.onClick;
            return normalSelectHandler;
        }else if(oneSkbAction.type==='single-select'){
            //wrapping with single select logic
            theOneCallBackFunctionForClickAction=oneSkbAction.onClick;
            return singleSelectHandler;
        }
       
    }
    return null;
  }


/**
* 
* @param {Array} actions For each action, the props "type" and "onClick" are required. 
*                          Currently, type values can be "select", "single-select", "save" and "delete".
*                          For "save" and "delete", onClick is a function with parameters (event, rowData) 
*                          For "select", onClick is a function with parameter (selectedRows, currentRow). 
*                                currentRow, containing {..., tableData:{checked}}, can be used which row is just being selected or de-selected. 
*                          For "single-select", onClick is a function with parameter (selectedRows) 
*                          If both "select" type and other type (e.g. "delete") present in actions, the other type (e.g. "delete") only visible when a row selected.
* @param {Array} columns Each column is an Object in the Array. For each column, the props of "title", "field" and "type" need to be defined. 
*                          Currenlty, the popular "type" values supported are "hidden-id", "read-only-text", "link" and "editable-number". 
*                          You may also find "id", "text" and "number" are easier, and find "read-only-long-text" and "read-only-short-text" useful.
*                          It's required to define one and only one id type column (e.g. 'hidden-id' or 'id'). 
*                          For "editable-number" type, prop "onChange" should be defined as function(event, rowData) to update data.
*                          For "link" type, prop "onClickLink" is mandatory as function(event, idValue, rowData) to handle the action.
*                          If none of them suits, column "type" can be set as "custom", where a "render" property is required along with "title", "field" and "type" (=custom).
*                          The render property is purely material-table's Custom Column Rendering feature. Please check its document for details.
* @param {Array} dataSource The data content in Array of row Object. 
*                             The props of each row Object need to match the "field" in "columns". 
*                             The values of props are usually number or string. 
*                             They also can be numbers/strings wrapped in span/div, 
*                                      e.g. <div value="234">something</div> or <span valueForFiltering='a' style={{color:"red"}}>234</span>
*                                          the value property in the wrap can be the basis for the sorting. 
*                                          the valueForFiltering in the wrap can be the basis fo the individual column filtering 
*                                              (BUT IT DOES NOT WORK WITH filteringType=selection ONLY WITH fileringType=text. Consider using filteringType=custom and building customised filter component for this.)
* @param {Object} filter It has 5 props. 
*                         3 of them for text search bar (at top-right to the main table content): 
*                                        "visible" (true/false for showing/hiding search bar), 
*                                        "text" (string to display on search bar, optional), 
*                                        "filterColumns" (array of columns.field that search bar is searching on, optional)
*                         2 of them for individual column filtering (next to each column heading)
*                                         "individualFiltering" (true/false)
*                                         "individualFilteringSettings" optional array. Each element contains properties "column", "filteringType" and "preselects", for one column's individual filtering setting 
*                                                                             "column" matches columns.field. For the column that has no setting here, the default setting is "selection" with all preselects. 
*                                                                             "filteringType" can be "disabled","selection","text" or "custom". By default, it's "selection"
*                                                                                      if it's "custom", caller can pass filterComponent and customFilterAndSearch properties in columns (one of SkbTable props).
*                                                                                      filterComponent and customFilterAndSearch are purely material-table features. please refer to material-table document for them.
*                                                                                      Basically, filterComponent is a React Component or HTML element to replace the default filter component. its onChange event 
*                                                                                        should call props.onFilterChanged(columnId, rowValue). So that it triggers your customFilterAndSearch(term, row)
*                                                                             "preselects" is an array of pre-select values for "selection" type only, optional. 
* @param {String} id The dom id to be used in div that contains the material-table
* @param {Object} options Used to define optional settings of
*                          "emptyDataSourceMessage": as String to show customised the text when there are no rows, 
*                          "selectedIdValues": as Array of String to sepcify the rows that need to pre-selected, 
*                          "subtableDefinition": as function(rowData), which returns an Object to specify "columns" and "dataSource" 
*                                                  of the collapsible table for one row.
*                          "disableRowCondition": as function(rowData), which returns true to disable the row. 
*                                                                          (Currently, only disable the checkbox. TODO: row colour and action buttons)
* @returns 
*/
export default function SkbTable({ actions, columns, dataSource, filter, id, options }) {
  const classes = useStyles();
  const theme = useTheme();
  const tableRef = React.useRef(null);

  const handleFilterDisplay = (e) => {
    //console.log('handleFilterDisplay');

    if(!options) options={};
    options.filterRowStyleDisplay=currentFilterRowStyleDisplay;

    if(currentFilterRowStyleDisplay){
      options.filterRowStyleDisplay=false;
      currentFilterRowStyleDisplay=false;
      //setCurrentOptions(options); //refresh
      setMtOptions(convertOptions(theme, filter, options, dataSource , columns,actions));
    }else{
      options.filterRowStyleDisplay=true;
      currentFilterRowStyleDisplay=true;
      //setCurrentOptions(options); //refresh
      setMtOptions(convertOptions(theme, filter, options, dataSource , columns,actions));
    }
    //avoid triggering sort function of material-table
    //console.log('handleFilterDisplay currentFilterRowStyleDisplay',currentFilterRowStyleDisplay);
    e.stopPropagation();
    e.preventDefault(); 
  };

  //const [currentOptions, setCurrentOptions] = useState(options);
  //const [currentDataSource, setCurrentDataSource] = useState(dataSource);
  const [mtActions, setMtActions] = useState(convertActions(actions, classes));
  const [mtColumns, setMtColumns] = useState(convertColumns(columns, classes, filter, dataSource,handleFilterDisplay));
  const [mtData, setMtData] = useState(convertData(dataSource, columns, options));
  const [mtOptions, setMtOptions] = useState(convertOptions(theme,filter,options, dataSource , columns,actions));

  //monitoring options (mainly for selected items) to refresh the table
  React.useEffect(() => {
    //console.log('SkbTable useEffect');
    setMtOptions(convertOptions(theme, filter, options, dataSource , columns,actions));
    setMtActions(convertActions(actions, classes));
    setMtColumns(convertColumns(columns, classes, filter, dataSource,handleFilterDisplay));
    setMtData(convertData(dataSource, columns, options));
  }, [options, dataSource, filter , columns, actions]);


  //if(!options) options={};
  //options.filterRowStyleDisplay=currentFilterRowStyleDisplay;

  const currentSelectHandler=selectionHandler(actions);
  //console.log('selectHandler',selectHandler);

  //other fixed props
  var mtToolbar;
  var mtDetailPanel;
  var mtLocalization;
  var mtOnSelectionChange;
  
  mtToolbar={searchPlaceholder:'only search this',searchTooltip:'search here'};
  mtDetailPanel = rowData => {const props = options.subtableDefinition(rowData); return <SkbTable columns={props.columns} dataSource={props.dataSource} /> };
  mtLocalization={ toolbar:{searchPlaceholder:((filter && filter.text)||'Search'), nRowsSelected:'{0} selected'},
                    body: { emptyDataSourceMessage: ((options && options.emptyDataSourceMessage)||'No results have been recorded so far!') },
                    pagination: {labelRowsSelect:"rows per page"}
                  }
  mtOnSelectionChange=currentSelectHandler;

  if(currentSelectHandler && (options && options.subtableDefinition)) {
        //nothing
  }else if(currentSelectHandler && !(options && options.detailTableProps)) {
        mtDetailPanel=null;

  }else if(options && options.subtableDefinition){
        mtToolbar=null;
        mtOnSelectionChange=null;
  }else{
        mtToolbar=null;
        mtOnSelectionChange=null;
        mtDetailPanel=null;
  }

  const enabledDataCount=mtData.filter(oneRow=> { 
                                  if(oneRow.tableData && oneRow.tableData.disabled) {
                                    return false
                                  }else{
                                    return true
                                  }
                                }
                            ).length;


  return (

    <div id={id} className={classes.table} >
    <MaterialTable
        primary
        icons={tableIcons}
        actions={mtActions}
        columns={mtColumns}
        data={mtData}
        padding={'dense'}
        tableRef={tableRef}
        toolbar={mtToolbar}
        options={mtOptions}
        onSelectionChange={mtOnSelectionChange}
        detailPanel={mtDetailPanel}
        localization={mtLocalization}
        components={{
          Header: props => (
            <SkbMTableHeader {...props} enabledDataCount={enabledDataCount} /> //overwrite MTableHeader for better selectAll logic
          ),
        }}
        
    />

    </div>
  );
  
  
}