import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';

import { SkbLoading } from '../../../skb_controls/components/SkbLoading';
import SkbTable from '../../../skb_controls/components/SkbTable';

const useStyles = makeStyles((them) => (
    {
        root: {
            width: '100%'
        }

    }
));

/**
 * components: LocationSelector is to choose locations from locations list
 * @param {funciton} loadLocationData: it's a callback to refresh LocationList, and SelectedLocatons, the HOC is resposible of the implementation which shall load locations for all selected contractors 
 * @param {boolean} IsLoading: to indicate the LocationList is refreshing or not
 * @param {array} DisplayColumns: to define the columns of the grid , and how column is binding with the LocationList
 *          i.e.  [
 *           { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
 *           {title:"", field:"RepresentativeId", type: 'hidden-text'},
 *           {title:"Stock Location", field:"Name", type: 'read-only-long-text'},
 *           {title:"Address", field:"Address", type: 'read-only-long-text'},
 *           {title:"# of Good Stock", field:"QtyofGood", type: 'read-only-long-text'},
 *           {title:"# of Faulty Stock", field:"QtyofFaulty", type: 'read-only-long-text'},
 *
 *       ]
 * @param {array}  LocationList: array of data to show in the grid
 *       i.e 
 *       [
 *           {
 *               LocationCode: 'ADM01',
 *               RepresentativeId: 123,
 *               Name: 'Gin Gin Warehouse',
 *               Address: '1 Station St Gin Gin QLD 4567',
 *               QtyofGood: 10,
 *               QtyofFaulty: 20
 *           }
 *       ]
 * @param {array} SelectedLocations: array of  selected locations
 *      i.e.
 *      [
 *          'ADM01' -- it's vaule of key column in DisplayColumns
 *      ]
 * @param {Function} selectLocation: it's a callback function, and called when selecting or un-selecting a row
 *      the signature of the callback is fn(event, rowdata)
 *      event: it's DOM element envent 
 *      rowdata: it data of current selected/un-selected row, the extra property tableData:checked indicates the selecte status
 * @returns 
 */
export function LocationSelector({ loadLocationData, IsLoading, DisplayColumns, LocationList, SelectedLocations, selectLocation }) {

    const classes = useStyles();

    if(typeof(IsLoading) === 'undefined') throw("IsLoading prop is missing");
    if(typeof(loadLocationData) === 'undefined') throw("loadLocationData prop is missing");
    if(typeof(LocationList) === 'undefined') throw("LocationList prop is missing");
    if(typeof(SelectedLocations) === 'undefined') throw("SelectedLocations prop is missing");
    if(typeof(DisplayColumns) === 'undefined') throw("DisplayColumns prop is missing");
    if(typeof(selectLocation) === 'undefined') throw("selectLocation prop is missing");

    useEffect(() => {
        loadLocationData();

    }, []);

    if (IsLoading)
        return (
            <SkbLoading />
        );
    else return (
        <div className={classes.root} id="stock_locationselector">
            <SkbTable columns={DisplayColumns} dataSource={LocationList} id="stock_locationselector_datagrid"
            actions = {[
                {
                    type: 'select',
                    onClick: selectLocation
                }
            ]}
            options={{selectedIdValues:SelectedLocations, emptyDataSourceMessage:'There are no locations found!'}} />


        </div>
    );
}