import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import SkbStepper from '../../../skb_controls/components/SkbStepper';
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import Loading from '../../Loading';

import { Grid } from '@material-ui/core';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import Typography from '@material-ui/core/Typography';
import { ContractorSelector } from './ContractorSelector';
import { LocationSelector } from './LocationSelector';
import { DestinationSelector } from './DestinationSelector';
import MethodSelector from './MethodSelector';
import { StockReview } from './StockReview'
import BusinessIcon from '@material-ui/icons/Business';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
            '& >div.MuiGrid-item:not(:first-child)': {
                borderTop: `1px solid ${theme.palette.text.primary}`,
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                // '&:last-child':{
                //     borderBottom: `1px solid ${theme.palette.text.primary}`,

                // },
            },
        },
        title: {
            textAlign: "left",
        },
        safari: {
            height: '-webkit-fill-available',
        },
        avatarIcon: {

            display: 'inline-block',
            verticalAlign: 'middle',
        },
        cancelIcon: {
            clear: "none",
            float: "right"
        },

    }
));
/**
 * 
 * @param {object} StepValidators: provide the validators while moving steps between, a validator is callback function triggered from SkbStepper.  for return stock wizard, it shall have four step validators below
 *  validateContractor: to validate contractor(s) selected in Contractor step 
 *  validateLocation: to validate location chosen in Location step
 *  validateDestination: to validate destination provided in destination step
 *  validateMethod: to validate method selected in Method step 
 *  sample of  StepValidators:
 * {
 *      validateContractor: ()=>,
 *      validateLocation: ()=>,
 *      validateDestination: ()=>,
 *      validateMethod: ()=>,
 * 
 * }
 * @param {boolean} isLoading: indicate the store data is refreshing
 * =============================Contractor step properties==========================
 * @param {function} loadContractors:  it's a callback to refresh contractorListWithStockSummary
 * @param {array} contractorListWithStockSummary: array of data to show in Contractor step
 *      e.g.
[
          [{
            "Contractor": "Antennacom",
            "Representative": "Aaron Simpson",
            "RepresentativeId": 123, 
            "CompanyId": 78, 
            "NumberOfLocations": 1,
            "QtyGood": 10,
            "QtyFaulty": 20},
            {
              "Contractor": "TENDER IT! YOU GOT IT - WE'LL SELL IT PTY LTD",
              "Representative": "Jamie D Mitchell",
              "RepresentativeId": 456 , 
              "CompanyId": 9, 
              "NumberOfLocations": 3,
              "QtyGood": 1,
              "QtyFaulty": 150
          }]
 * @param {array} selectedContractorCompanyIds: array of selected contractor representative Ids (int)
 * @param {boolean} showGoodCounts: whether to display the QtyGood counts in the table for each contractor.
 * @param {boolean} showFaultyCounts: whether to display the QtyFaulty counts  in the table for each contractor.
 * 
 * @param {Function} handleSelectContractor: it's a callback function, and called when selecting or un-selecting a row
 -------*      the signature of the callback is fn(event, rowdata)
 -------*      event: it's DOM element envent 
 -------*      rowdata: it data of current selected/un-selected row, the extra property tableData:checked indicates the selecte status
*============================Location step propertis =================================
 * @param {array} LocationDisplayColumns: to define the columns of the grid , and how column is binding with the LocationList
 *          i.e.  [
 *           { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
 *           {title:"", field:"RepresentativeId", type: 'hidden-text'},
 *           {title:"Stock Location", field:"Name", type: 'read-only-long-text'},
 *           {title:"Address", field:"Address", type: 'read-only-long-text'},
 *           {title:"# of Good Stock", field:"QtyofGood", type: 'read-only-long-text'},
 *           {title:"# of Faulty Stock", field:"QtyofFaulty", type: 'read-only-long-text'},
 *
 *       ]
 * @param {array}  LocationList: array of data to show in the grid
 *       i.e 
 *       [
 *           {
 *               LocationCode: 'ADM01',
 *               RepresentativeId: 123,
 *               Name: 'Gin Gin Warehouse',
 *               Address: '1 Station St Gin Gin QLD 4567',
 *               QtyofGood: 10,
 *               QtyofFaulty: 20
 *           }
 *       ]
 * @param {array} SelectedLocations: array of  selected locations
 *      i.e.
 *      [
 *          'ADM01' -- it's vaule of key column in DisplayColumns
 *      ]
 * @param {Function} selectLocation: it's a callback function, and called when selecting or un-selecting a row
 *      the signature of the callback is fn(event, rowdata)
 *      event: it's DOM element envent 
 *      rowdata: it data of current selected/un-selected row, the extra property tableData:checked indicates the selecte status
 * 
 * ======================Destination Step ==============================
 * @param {funciton} loadDestinationData: it's a callback to refresh destinationList and selectedDestination 
 * @param {array}  DestinationList: array of data to show in the grid
 *       i.e 
 *       [
 *           {
 *               LocationCode: 'ADM01',
 *               Name: 'Gin Gin Warehouse',
 *               Address: '1 Station St Gin Gin QLD 4567'
 *           }
 *       ]
 * @param {String} SelectedDestination: selected destination's location code
 * @param {Function} selectDestination: it's a callback function, and called when selecting or un-selecting a row
 *      the signature of the callback is fn(event, rowdata)
 * 
 * =====================Method Step ==================================
 * @param {funciton} loadMethodData: it's a callback to refresh method list,
 * @param {array} MethodDisplayColumns: to define the columns of the grid , and how column is binding with the method list
 *          i.e.  [
 *           { title: '', field: 'MethodCode', type: 'hidden-id' }, //column with hidden-id will not display
 *           {title:"", field:"Method", type: 'text'},
 *       ]
 * @param {array}  MethodList: array of data to show in the grid
 *       i.e 
 *       [
 *           {
 *               Method: 'By the carrier arranged by Skybridge',
 *               MethodCode:'BCS'
 *           },
 *          {
 *               Method: 'By the carrier arranged by contractor',
 *               MethodCode:'BCC'
 *           }
 *       ]
 * @param {string} SelectedMethod: arrary of selected method. Should be one element in array
 *      i.e.
 *      [
 *          {   'BCS' -- it's vaule of MethodCode in DisplayColumns }
 *      ]
 *        
 * @param {function} selectReturnDate : it's a callback function, and called when the return date selected
 * or removed.
 * @param {string} SelectedReturnDate: selected return date
 * 
 * @param {function} selectPickupDate : it's a callback function, and called when the pick up date selected
 * or removed.
 * 
 * @param {string} SelectedPickupDate: selected return date
 * @param {Function} selectMethod: it's a callback function, and called when selecting or un-selecting a row
 *      the signature of the callback is fn(event, rowdata)
 *      event: it's DOM element envent 
 *      rowdata: it data of current selected/un-selected row, the extra property tableData:checked indicates the selecte status
 *
 * ========================Review Step =================================
  * @param {funciton} loadRevievData: it's a callback to refresh ReviewData, the HOC is resposible of the implementation 
 * @param {funciton} loadReviewRowDetails: it's a callback to load row detail data, the return data shall contain the properties which match with the field defined in DisplaySubColumns, i
 *  the HOC is resposible of the implementation, the return data shall contain all properties required in DisplaySubColumns  
 * @param {array} ReviewDisplayColumns: to define the columns of the grid , and how column is binding with the ReviewData
 *          i.e.  [
 *           { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
 *           {title:"Contractor", field:"Contractor", type: 'read-only-long-text'},
 *           {title:"Stock Location", field:"LocationName", type: 'read-only-long-text'},
 *           {title:"# of Good Stock", field:"QtyofGood", type: 'read-only-long-text'},
 *           {title:"# of Faulty Stock", field:"QtyofFaulty", type: 'read-only-long-text'},
 *           {title:"Destination", field:"Destination", type: 'read-only-long-text'},
 *           {title:"Method", field:"Method", type: 'read-only-long-text'},
 *
 *        ]
  * @param {array} ReviewDisplaySubColumns: to define the columns of the grid , and how column is binding with the ReviewData
 *          i.e.  [
 *           {title:"Description", field:"Description", type: 'read-only-long-text'},
 *           {title:"UOM", field:"UNIT", type: 'read-only-long-text'},
 *           {title:"Condition", field:"Condition", type: 'read-only-long-text'},
 *           {title:"Qty/SN", field:"DisplayValue", type: 'read-only-long-text'},
 *
 *        ]
 * @param {array} ReviewData: array of data to show in the grid
 *              i.e 
 *        [
 *           {
 *               LocationCode: 'ADM01',
 *               Contractor: 'Adam Poole Pty. Ltd',
 *               LocationName: 'Gin Gin Warehouse',
 *               Address: '1 Station St Gin Gin QLD 4567',
 *               QtyofGood: 10,
 *               QtyofFaulty: 20,
 *               Destination: 'SKB main warehouse',
 *               Method: 'Carrier Arranged by Skybridge'
 *           }
 *        ]
 * =======================================
 * @param {funciton} stepCompletedCallback: called when stepper step switch to another. 
 *  singature: (step, isCompleted), isCompleted is optional, step is the current step, the stepper isCompleted is used to mark the step is completed or not, if it's the last step,the stepCompletedCallback is responsible to close the step wizard
 * @param {string} ReturnStockTitle: the text show on top the window, i.e. "Retrun AllStock", "Return Faulty Stock", "Return SKUs", "Return Serials" 
 * @param {funciton} cancelCreationHandler: callback function when cancle button is clicked 
 * @param {string} returnRoute: the navigation route when the wizard is closed, default is '/home' when is not provided,
 * @param {object} StepsStatus, it's optional key-value pair object, key is the step name, vlaue is step status, either "Complete" or "InComplete". 
 * @param {string} incompletedStepNames, it's optional,  it's used to untick complete step
*/
export function ReturnStockWizardInternal(props) {

    console.log("ReturnStockWizardInternal", props);

    const { stepCompletedCallback, ReturnStockTitle } = props;

    if (typeof (props.StepValidators) === 'undefined') throw ("StepValidators prop is missing");
    const { validateContractor, validateLocation, validateDestination, validateMethod } = props.StepValidators;
    if (typeof (validateContractor) === 'undefined') throw ("StepValidators.validateContractor prop is missing");
    if (typeof (validateLocation) === 'undefined') throw ("StepValidators.validateLocation prop is missing");
    if (typeof (validateDestination) === 'undefined') throw ("StepValidators.validateDestination prop is missing");
    if (typeof (validateMethod) === 'undefined') throw ("StepValidators.validateMethod prop is missing");

    if (typeof (stepCompletedCallback) === 'undefined') throw ("stepCompletedCallback prop is missing");
    if (typeof (props.IsLoading) === 'undefined') throw ("IsLoading prop is missing");
    if (typeof (props.loadContractors) === 'undefined') throw ("loadContractors prop is missing");
    if (typeof (props.contractorListWithStockSummary) === 'undefined') throw ("contractorListWithStockSummary prop is missing");
    if (typeof (props.selectedContractorCompanyIds) === 'undefined') throw ("selectedContractorCompanyIds prop is missing");
    if (typeof (props.showGoodCounts) === 'undefined') throw ("showGoodCounts prop is missing");
    if (typeof (props.showFaultyCounts) === 'undefined') throw ("showFaultyCounts prop is missing");
    if (typeof (props.handleSelectContractor) === 'undefined') throw ("handleSelectContractor prop is missing");
    if (typeof (props.LocationDisplayColumns) === 'undefined') throw ("LocationDisplayColumns prop is missing");
    if (typeof (props.loadLocationData) === 'undefined') throw ("loadLocationData prop is missing");
    if (typeof (props.LocationList) === 'undefined') throw ("LocationList prop is missing");
    if (typeof (props.SelectedLocations) === 'undefined') throw ("SelectedLocations prop is missing");
    if (typeof (props.selectLocation) === 'undefined') throw ("selectLocation prop is missing");
    if (typeof (props.loadDestinationData) === 'undefined') throw ("loadDestinationData prop is missing");
    //if (typeof (props.DestinationList) === 'undefined') throw ("DestinationList prop is missing");
    if (typeof (props.SelectedDestination) === 'undefined') throw ("SelectedDestination prop is missing");
    if (typeof (props.selectDestination) === 'undefined') throw ("selectDestination prop is missing");
    //if (typeof (props.loadMethodData) === 'undefined') throw ("loadMethodData prop is missing");
    if (typeof (props.MethodDisplayColumns) === 'undefined') throw ("MethodDisplayColumns prop is missing");
    if (typeof (props.MethodList) === 'undefined') throw ("MethodList prop is missing");
    if (typeof (props.selectMethod) === 'undefined') throw ("selectMethod prop is missing");
    if (typeof (props.SelectedMethod) === 'undefined') throw ("SelectedMethod prop is missing");
    if (typeof (props.SelectedPickupDate) === 'undefined') throw ("SelectedPickupDate prop is missing");
    if (typeof (props.selectPickupDate) === 'undefined') throw ("selectPickupDate prop is missing");
    if (typeof (props.SelectedReturnDate) === 'undefined') throw ("SelectedReturnDate prop is missing");
    if (typeof (props.selectReturnDate) === 'undefined') throw ("selectReturnDate prop is missing");
    if (typeof (props.loadRevievData) === 'undefined') throw ("loadRevievData prop is missing");
    if (typeof (props.ReviewDisplayColumns) === 'undefined') throw ("ReviewDisplayColumns prop is missing");
    if (typeof (props.ReviewData) === 'undefined') throw ("ReviewData prop is missing");
    if (typeof (props.loadReviewRowDetails) === 'undefined') throw ("loadReviewRowDetails prop is missing");
    if (typeof (props.ReviewDisplaySubColumns) === 'undefined') throw ("ReviewDisplaySubColumns prop is missing");
    if (typeof (props.ReturnStockTitle) === 'undefined') throw ("ReturnStockTitle prop is missing");

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const cancelCreation = () =>{
        history.push(props.returnRoute || '/home');

        if(typeof(props.cancelCreationHandler) === 'function') props.cancelCreationHandler();
    };

    useEffect(()=>{
        if(props.Error){
            enqueueSnackbar(props.Error, {
                variant: "error"
            });
        }
    }, [props.Error]);
    const classes = useStyles();
    const steps = getWizardSteps(props);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = React.useState("");
    const [confirmTitle, setConfirmTitle] = React.useState("");

    function showConfirmDialog(title, message)
    {
        setConfirmTitle(title);
        setConfirmMsg(message);
   
     // setOkHandler(okHandler); //Ideally would pass in the callback function for processing an OK response.
     setOpenConfirm(true);

    }
    return (
        <>
            <Grid container className={classes.root} id="return_stock_wizard_internal">
                <Grid item xs={12} id="taskdetail_title" className={classes.title}>
                    <Typography variant="h5"><SkbIcon classes={{ iconStyle: classes.avatarIcon }} icon={BusinessIcon} />{ReturnStockTitle}
                        <div className={classes.cancelIcon}><SkbIcon onClick={() => { showConfirmDialog('Please Confirm','Are you sure you want to cancel Return Stock creation?') }} classes={{ iconStyle: classes.avatarIcon }} icon={CancelOutlinedIcon} /></div>
                    </Typography>

                </Grid>
                {/* <Grid item xs={12} id="taskdetail_stepview">
    <Typography variant="body1">show step content here</Typography>
</Grid> */}
                <Grid item xs={12} id="taskdetail_steppers" className={classes.safari}>
                    <SkbStepper Steps={steps} stepCompletedCallback={stepCompletedCallback}
                        NextButtonNameAtLastStep={"Confirm"} incompletedStepNames={props.incompletedStepNames}/>
                </Grid>
            </Grid>

            <SkbConfirmDialog id="Confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm} 
              okHandler={cancelCreation}
                okLabel="Yes" cancelLabel="No" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />
            {props.IsLoading && <Loading/>}
        </>
    );
}

function getWizardSteps(props) {
    const { StepValidators} = props;
    
    const stepsStatus = props.StepsStatus || {};
    const isStepComplete = (stepName) =>stepsStatus[stepName] === "Complete" ;

    const IsLoading = false; // showing loading indicator is done in this component directly

    // properties used in ContractorSelector
    const { loadContractors, contractorListWithStockSummary, selectedContractorCompanyIds, showGoodCounts, showFaultyCounts, handleSelectContractor } = props;

    // properties used in LocationSelector
    const { LocationDisplayColumns, loadLocationData, LocationList, SelectedLocations, selectLocation } = props;

    // properties used in DestinationSelector
    const { loadDestinationData, SelectedDestination, selectDestination,userToken } = props;

    // properties used in MethodSelector
    const { MethodDisplayColumns, MethodList, selectMethod,
        SelectedMethod, SelectedPickupDate, selectPickupDate, SelectedReturnDate, selectReturnDate } = props;

    // propertie used in StockReview

    const { loadRevievData, ReviewDisplayColumns, ReviewData, loadReviewRowDetails, ReviewDisplaySubColumns } = props;

    // properties for step validation
    const { validateContractor, validateLocation, validateDestination, validateMethod } = StepValidators;
    return [
        {
            Title: "Select contractor",
            StepName: "Contractor",
            Completed: isStepComplete("Contractor"),
            Description: "Please select the contractor(s) who are required to return stock.",
            Content: <ContractorSelector isRefreshing={IsLoading} loadContractors={loadContractors} contractorListWithStockSummary={contractorListWithStockSummary}
                selectedContractorCompanyIds={selectedContractorCompanyIds}
                showGoodCounts={showGoodCounts}
                showFaultyCounts={showFaultyCounts}
                handleSelectContractor={handleSelectContractor}
            />,
            StepValidator: validateContractor
        },
        {
            Title: "Select stock locations",
            StepName: "Location",
            Description: "Please select which location the stock returns from.",
            Completed: isStepComplete("Location"),
            Content: <LocationSelector
                loadLocationData={loadLocationData}
                IsLoading={IsLoading}
                DisplayColumns={LocationDisplayColumns}
                LocationList={LocationList}
                SelectedLocations={SelectedLocations}
                selectLocation={selectLocation} />,
            StepValidator: validateLocation
        },
        {
            Title: "Select a return destination",
            StepName: "Destination",
            Completed: isStepComplete("Destination"),
            Description: "Please select which location the stock returns to.",
            Content: <DestinationSelector
                loadDestinationData={loadDestinationData}
                IsLoading={IsLoading}
                //DestinationList={DestinationList}
                SelectedDestination={SelectedDestination}
                selectDestination={selectDestination}
                userToken={userToken}
            />,
            StepValidator: validateDestination
        },
        {
            Title: "Return method and timing",
            StepName: "Method",
            Completed: isStepComplete("Method"),
            Description: "Please select return method.",
            Content: <MethodSelector
                //loadMethodData={loadMethodData}
                IsLoading={IsLoading}
                DisplayColumns={MethodDisplayColumns}
                MethodList={MethodList}
                selectMethod={selectMethod}
                SelectedMethod={SelectedMethod}
                SelectedPickupDate={SelectedPickupDate}
                selectPickupDate={selectPickupDate}
                SelectedReturnDate={SelectedReturnDate}
                selectReturnDate={selectReturnDate} />,
            StepValidator: validateMethod
        },
        {
            Title: "Review",
            StepName: "Review",
            Completed: isStepComplete("Review"),
            Description: "Please review then confirm to create a stock task for the contractor.",
            Content: <StockReview
                IsLoading={IsLoading}
                loadRevievData={loadRevievData}
                DisplayColumns={ReviewDisplayColumns}
                ReviewData={ReviewData}
                loadRowDetails={loadReviewRowDetails}
                DisplaySubColumns={ReviewDisplaySubColumns} />,
            StepValidator: () => { return { isValid: true }; }
        }
    ];
}
