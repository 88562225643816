import React, { useEffect,useState, Suspense, useRef, useCallback } from 'react';
import {connect,useDispatch, useSelector} from 'react-redux';
import {SkbSelect} from '../../../../skb_controls/components/SkbSelect';  
import SkbTable from '../../../../skb_controls/components/SkbTable';  
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import SkbButton from '../../../../skb_controls/components/SkbButton';

import { withStyles,makeStyles  } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import Link from '@material-ui/core/Link';
// import { Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';

import Loading from '../../../Loading';
import TaskItemAddPopup from "./TaskItemAddPopup";
import TaskDetailsSchedulePopup from "./TaskDetailsSchedulePopup";

import {useSnackbar } from 'notistack';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';

import * as  returnsDashboardTaskInfoActions from '../../../../Redux/actions/returnsdashboard';
import * as misc from '../../../../utils/misc';

import {getReturnDashboardUtility} from "./returnDashboardUtility"; 


import ContractorRepresentativePhoneEmailStructure  from "./ContractorRepresentativePhoneEmailStructure";

const classes = {
  root: {
    flexGrow:1
  },
  paper: {
    padding:20,
    textAlign: "center",
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
     
 ".taskDetPopupConsignTypeDrpDwn .MuiFormControl-root":{
  "margin": "0em"
},
   
 ".taskDetPopupConsignTypeDrpDwn .MuiInput-root":{
  "marginTop": "0.25em"
}, 
".taskDetPopupWareHouseDrpDwn .MuiFormControl-root":{
  "margin": "0em"
},
   
 ".taskDetPopupWareHouseDrpDwn .MuiInput-root":{
  "marginTop": "0.25em"
},




  },
})(() => null);


const TaskDetailsPopup = React.forwardRef ((props,refObj) => {
  const dispatch = useDispatch();
 
  //newly added for task info schedule popup
  const popRefScheduleBtnComp = useRef(null);
  const { enqueueSnackbar } = useSnackbar(); 
  const [open, setOpen] = React.useState(false);
  
  const [warehouseDropDownChangeEventFlag, setWarehouseDropDownChangeEventFlag] = React.useState(false);  
  const [taskInfoApiOneUpdateSuccessLocalFlag, setTaskInfoApiOneUpdateSuccessLocalFlag] = React.useState(false); 
  const [shouldOpenConfirmForCloseTaskInfoPopup, setShouldOpenConfirmForCloseTaskInfoPopup] = useState(false);
  const [activityDoneByUserInTaskInfo, setActivityDoneByUserInTaskInfo] = useState(false);
  //newly added for task info schedule popup
  const [taskInfoScheduleBtnPropsObj,setTaskInfoScheduleBtnPropsObj] = useState({});


  const {returndashlisttaskinfopopupprop} = props;
  const taskId = ( returndashlisttaskinfopopupprop && returndashlisttaskinfopopupprop.taskId) ?returndashlisttaskinfopopupprop.taskId: "";
  const consignmentLocationCode = (returndashlisttaskinfopopupprop && returndashlisttaskinfopopupprop.consignmentLocationCode) ? returndashlisttaskinfopopupprop.consignmentLocationCode: "";

  const dataArToCheckAddButtonVisbility =  ["Return Faulty Stock","Return All Stock", "Return Specific Stock"];

  const getConsignmentMethodDisplayData = (resp) => {

    if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
      let consignmentMethodListDrpDwnArr = [];
      resp.map((e,i)=>{
        //consignmentMethodListDrpDwnArr.push({"value":e.Id,"text":e.Description});
        consignmentMethodListDrpDwnArr.push({"value":e.Description,"text":e.Description});
      });

      return consignmentMethodListDrpDwnArr;


    }

    return [];

  }

   const getWareHouseDisplayData = (resp) => {

    if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
      let wareHouseListDrpDwnArr = [];
      resp.map((e,i)=>{
        wareHouseListDrpDwnArr.push({"value":e.LocationCode,"text":e.Name});
      });

      return wareHouseListDrpDwnArr;


    }

    return [];

  }
  
  
  const {taskInfoLoadStatus,taskInfo, consignmentMethod, wareHouseLocationCode, toLocationAddressLine1, toLocationAddressLine2, toLocationState, toLocationSuburb,toLocationPostcode, taskInfoItemList,fromLocationCode, taskInfoConsignmentType, taskInfoUpdateApiOneSuccessFlag, taskInfoUpdateApiOneSuccessMessage, taskInfoUpdateApiTwoSuccessFlag, taskInfoUpdateApiTwoSuccessMessage,taskInfoUpdateApiOneErrorFlag,taskInfoUpdateApiOneErrorMessage,taskInfoUpdateApiTwoErrorFlag, taskInfoUpdateApiTwoErrorMessage, contractorCompanyName, taskInfNonListChangedDataConsMethod, taskInfNonListChangedDataWarehsLocCode, nonListChangeableDataSetFlag , taskInfoPopupStructureShowFlag , connoteTrackingUrl, pickupWindowDate, pickupWindowTimeslot, plannedReturnPickupDate, dropoffWindowDate, dropoffWindowTimeslot, plannedReturnDate , fromLocationAddressLine1, fromLocationAddressLine2, fromLocationState, fromLocationSuburb, fromLocationPostcode, statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess } = useSelector((state) => ({
          //return state.returnsDashboardTaskInfo;
          "taskInfoLoadStatus": state.returnsDashboardTaskInfo.taskInfoLoadStatus,
          "taskInfo": state.returnsDashboardTaskInfo.taskInfo,
          "taskInfoPopupStructureShowFlag":state.returnsDashboardTaskInfo.taskInfoPopupStructureShowFlag,
         
          "taskInfoConsignmentType":  (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo  && state.returnsDashboardTaskInfo.taskInfo.ConsignmentType)?(state.returnsDashboardTaskInfo.taskInfo.ConsignmentType):"",
          "consignmentMethod":   (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo  && state.returnsDashboardTaskInfo.taskInfo.ConsignmentMethod)?(state.returnsDashboardTaskInfo.taskInfo.ConsignmentMethod):"", 
          "wareHouseLocationCode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.LocationCode)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.LocationCode):"",
          "toLocationAddressLine1": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine1)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine1):"",
           "toLocationAddressLine2": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine2)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine2):"",
          "toLocationState": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.State)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.State):"",
          "toLocationSuburb": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.Suburb)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.Suburb):"",
          "toLocationPostcode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.Postcode)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.Postcode):"",
          "taskInfoItemList": state.returnsDashboardTaskInfo.taskInfoItemList,
          "fromLocationCode":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.LocationCode)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.LocationCode):"",
          "taskInfoUpdateApiOneSuccessFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoUpdateApiOneSuccessFlag)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiOneSuccessFlag):false,
          "taskInfoUpdateApiOneSuccessMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoUpdateApiOneSuccessMessage)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiOneSuccessMessage):"",
          "taskInfoUpdateApiOneErrorFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoUpdateApiOneErrorFlag)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiOneErrorFlag):false,
          "taskInfoUpdateApiOneErrorMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoUpdateApiOneErrorMessage)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiOneErrorMessage):"",
          "taskInfoUpdateApiTwoSuccessFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoSuccessFlag)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoSuccessFlag):false,
          "taskInfoUpdateApiTwoSuccessMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoSuccessMessage)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoSuccessMessage):"",          
          "taskInfoUpdateApiTwoErrorFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoErrorFlag)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoErrorFlag):false,
          "taskInfoUpdateApiTwoErrorMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoErrorMessage)?(state.returnsDashboardTaskInfo.taskInfoUpdateApiTwoErrorMessage):"",
          "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess)?(state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess):false,
          "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccessMsg": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccessMsg)?(state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccessMsg):"",  
           "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorFlag)?(state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorFlag):false,
           "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorMsg": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorMsg)?(state.returnsDashboardTaskInfo.statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorMsg):"", 

          "contractorCompanyName": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.Company!==undefined && state.returnsDashboardTaskInfo.taskInfo.Company && state.returnsDashboardTaskInfo.taskInfo.Company.Name!==undefined )?state.returnsDashboardTaskInfo.taskInfo.Company.Name: "",

          "connoteTrackingUrl": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.TrackingUrl!==undefined )?state.returnsDashboardTaskInfo.taskInfo.TrackingUrl: "",


          "nonListChangeableDataSetFlag": state.returnsDashboardTaskInfo.nonListChangeableDataSetFlag,
          "taskInfNonListChangedDataConsMethod": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoNonListChangedData &&  state.returnsDashboardTaskInfo.taskInfoNonListChangedData.consignmentMethod!==undefined)?state.returnsDashboardTaskInfo.taskInfoNonListChangedData.consignmentMethod:"",
          "taskInfNonListChangedDataWarehsLocCode": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoNonListChangedData &&  state.returnsDashboardTaskInfo.taskInfoNonListChangedData.wareHouseLocationCode!==undefined)?state.returnsDashboardTaskInfo.taskInfoNonListChangedData.wareHouseLocationCode:"",

          "pickupWindowDate":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Date!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Date: "",
          "pickupWindowTimeslot":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Timeslot!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Timeslot: "",
          "plannedReturnPickupDate":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PlannedReturnPickupDate!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PlannedReturnPickupDate: "",

          "dropoffWindowDate":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.DropoffWindow && state.returnsDashboardTaskInfo.taskInfo.DropoffWindow.Date!==undefined)?state.returnsDashboardTaskInfo.taskInfo.DropoffWindow.Date: "",

          "dropoffWindowTimeslot":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.DropoffWindow && state.returnsDashboardTaskInfo.taskInfo.DropoffWindow.Timeslot!==undefined)?state.returnsDashboardTaskInfo.taskInfo.DropoffWindow.Timeslot: "",

          "plannedReturnDate":(state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PlannedReturnDate!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PlannedReturnDate: "",

          "fromLocationAddressLine1": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine1)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine1):"",
          "fromLocationAddressLine2": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine2)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine2):"",
          "fromLocationState": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.State)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.State):"",
          "fromLocationSuburb": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.Suburb)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.Suburb):"",
          "fromLocationPostcode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.Postcode)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.Postcode):""


        })
    ); 
  const {consignmentMethodListLoadStatus,consignmentMethodList, consignmentMethodListToDisplay} = useSelector((state) =>( {  
              //return state.returnsDashboardConsignmentMethod;  
              "consignmentMethodListLoadStatus": state.returnsDashboardConsignmentMethod.consignmentMethodListLoadStatus,
              "consignmentMethodList": state.returnsDashboardConsignmentMethod.consignmentMethodList,
              "consignmentMethodListToDisplay":  getConsignmentMethodDisplayData(state.returnsDashboardConsignmentMethod.consignmentMethodList),
             
             
        }
      )      
    );
    const {wareHouseListLoadStatus,wareHouseList, wareHouseListToDisplay} = useSelector((state) =>( {  
              //return state.returnsDashboardWarehouseList;  
              "wareHouseListLoadStatus": state.returnsDashboardWarehouseList.wareHouseListLoadStatus,
              "wareHouseList": state.returnsDashboardWarehouseList.wareHouseList,             
              "wareHouseListToDisplay":  getWareHouseDisplayData(state.returnsDashboardWarehouseList.warehouseList),
             
        }
      )      
    );
   

    const {warehouseAddressDataLoadStatus, toLocationAddressLine1AfterSel, toLocationAddressLine2AfterSel, toLocationStateAfterSel, toLocationSuburbAfterSel,toLocationPostcodeAfterSel } = useSelector((state) => ({
      //return state.returnsDashboardWarehouseAddrBasedOnLocCode;
      "warehouseAddressDataLoadStatus": state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressDataLoadStatus,
      "toLocationAddressLine1AfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCode &&  state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.AddressLine1)? (state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.AddressLine1):"",
      "toLocationAddressLine2AfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCode &&  state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.AddressLine2)? (state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.AddressLine2):"",
      "toLocationStateAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCode &&  state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.State)? (state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.State):"",
      "toLocationSuburbAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCode &&  state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.Suburb)? (state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.Suburb):"",
      "toLocationPostcodeAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCode &&  state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.Postcode)? (state.returnsDashboardWarehouseAddrBasedOnLocCode.warehouseAddressData.Postcode):"",


    })
);
    


  const handleClickOpen = () => {

    setOpen(true);
     
      
    };
  const triggerCloseTaskInfoPopup = () => {
   
    setOpen(false);
  }
  const handleClose = () => {
      if(activityDoneByUserInTaskInfo===true){
        setShouldOpenConfirmForCloseTaskInfoPopup(true);
      }
      else{
        triggerCloseTaskInfoPopup();
      }
      
      
  };

   
  
  const validateApiOneParamDataBeforeTaskInfoUpdate = (dataForTaskInfoUpdateApiOne) => {
     
      let errorTrackAr = [];
      let consignmentMethodLocalDataErMsg = "";
      let warehouseLocationCodeLocalDataErMsg = "";
      let consignmentLocationCodeErMsg = "";

      if(!(dataForTaskInfoUpdateApiOne.consignmentMethodLocalData)){
        consignmentMethodLocalDataErMsg = "Consignment method is not selected";
        errorTrackAr.push(consignmentMethodLocalDataErMsg);
      }

      if(!(dataForTaskInfoUpdateApiOne.warehouseLocationCodeLocalData)){
        warehouseLocationCodeLocalDataErMsg = "Warehouse is not selected";
        errorTrackAr.push(warehouseLocationCodeLocalDataErMsg);
      }

      if(!(dataForTaskInfoUpdateApiOne.consignmentLocationCode)){
        consignmentLocationCodeErMsg = "Consignment location code is missing";
        errorTrackAr.push(consignmentLocationCodeErMsg);
      }

      if(errorTrackAr && errorTrackAr.length>0){
        console.log(" === Task info popup update validate=>",errorTrackAr);
            return false;
      }
      else{
        return true
      }



    }

  const getTaskInfoItemListDataForUpdate = (dataList) => {

    let taskInfoItemListDataAr = [];
    let dataListCpy = [...dataList];
    if(Array.isArray(dataList) && dataList.length>0){
      dataListCpy.map((row,incr)=>{
        taskInfoItemListDataAr.push({
          "StockCategoryCode": row.stockCategoryCode,
          "ClientName": row.clientName,
          "QtyGood": row.qtyGood,
          "QtyFaulty": row.qtyFaulty
        });  
  
      });
  
    }

    return taskInfoItemListDataAr;
    
    

  } 

  const handleTaskInfoUpdate = () => {   

   const dataForTaskInfoUpdateApiOne = {
      "consignmentLocationCode":consignmentLocationCode,
      "warehouseLocationCodeLocalData": taskInfNonListChangedDataWarehsLocCode,
      "consignmentMethodLocalData" : taskInfNonListChangedDataConsMethod

    }
   
    const taskInfoItemListDataParam = getTaskInfoItemListDataForUpdate(taskInfoItemList);
    
    const chkValid1 =  validateApiOneParamDataBeforeTaskInfoUpdate(dataForTaskInfoUpdateApiOne);
    if(chkValid1===true){  

            setTaskInfoApiOneUpdateSuccessLocalFlag(false); // resetting back to false
            const paramObjApi1 = {
              "Consignments":[
                  {
                  "LocationCode": dataForTaskInfoUpdateApiOne.consignmentLocationCode,
                  "ToLocationCode": dataForTaskInfoUpdateApiOne.warehouseLocationCodeLocalData,    
                  "ConsignmentMethod": dataForTaskInfoUpdateApiOne.consignmentMethodLocalData,
                  "ActivityType":"Update"
                  }
                ]
              };


             dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToForTaskInfoUpdateWithApiOne({"bodyParam":paramObjApi1,"forMsgDisp":{"contractorCompanyName":contractorCompanyName,"taskId":taskId}}));

    }

    if(Array.isArray(taskInfoItemListDataParam) && taskInfoItemListDataParam.length>0){

      const paramObjApi2 = taskInfoItemListDataParam;
      dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToForTaskInfoUpdateWithApiTwo({"bodyParam":paramObjApi2,"segmentOneParam":consignmentLocationCode, "forMsgDisp":{"contractorCompanyName":contractorCompanyName,"taskId":taskId}}));

    }
      
  };

 const dialogOnEnterFunc = async () =>{
      // might need later
      unsetAllLocalStateDataRelatedToTaskInfoPopup();
     
  }

  const dialogOnEnteredFunc = async () =>{
     
      dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchConsignmentMethod({}));
      dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchWarehouseList({}));  
      const paramObjTaskInfoFetch = {"paramObj":{"locationCode":consignmentLocationCode}};     
      dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchTaskInfo(paramObjTaskInfoFetch));
     
  }

  const dialogOnExitedTrackFunc = () =>{
      //console.log("----- EXITED 11-----------");
      unsetAllReduxStoreStateDataRelatedToTaskInfoPopup();
      unsetAllLocalStateDataRelatedToTaskInfoPopup();
  }

  const handleChangeWareHouseCheck = (evVal) => { 

    //console.log("===handleChangeWareHouseCheck evVal===>",evVal); 

    setWarehouseDropDownChangeEventFlag(true);
    setActivityDoneByUserInTaskInfo(true);
    const paramObjForLocAddrFromCode = {"paramObj":{"locationCode":evVal}};   
    dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToFetchWarehouseAddrBasedOnLocCode(paramObjForLocAddrFromCode));
    
  }

  

  const handleChangeOfConsignmentMethod = (evVal) => { 
    setActivityDoneByUserInTaskInfo(true);
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoPopupNonListChangeableDataConsMethod(evVal));
  }


  const handleEditChange = () =>{

  }

  const handleDeleteTaskInfoListItem = (e,row) =>{
    setActivityDoneByUserInTaskInfo(true);
    console.log("--- row==>>",row);
    let getUpdatedRec = returnsDashboardTaskInfoActions.removeRowFromRecordListBasedOnaKey(taskInfoItemList,row,"stockCategoryCode");   

    if(getUpdatedRec && getUpdatedRec.length>=0){      
      dispatch(returnsDashboardTaskInfoActions.setTaskItemUpdatedList(getUpdatedRec));
    }

  }
  const handleSelectTaskInfoListItem = () =>{

  }  

  const taskInfoItemListColumns = [     
    { title: 'Stock Category Code', field: 'stockCategoryCode', type: 'hidden-id'},
    { title: 'Description', field: 'stockDescription', type: 'read-only-long-text'},
    { title: 'Program', field: 'clientName', type: 'read-only-long-text' },
    { title: 'UOM', field: 'unitOfMeasurement', type: 'read-only-long-text' },
    { title: '# of Return Stock', field: 'qtyFaultyQtyGood', type: 'read-only-long-text' }
  ];
  
  const taskInfoItemListActions=[
                      {type:'delete',onClick:handleDeleteTaskInfoListItem},
                      {type:'select',onClick:handleSelectTaskInfoListItem},
                     ]; 

  const taskInfoItemListFilterConfig =  {visible:true,
    individualFiltering:true, 
    individualFilteringSettings:[
        {column:"stockDescription",filteringType:"text"},
        {column:"clientName",filteringType:"text"},
        {column:"unitOfMeasurement",filteringType:"disabled"},
        {column:"qtyFaultyQtyGood",filteringType:"disabled"},
    ]
      };
 
  
  const getDaysToDisplay = (createdDate) =>{
    //let createdDate = (e.DateCreated && e.DateCreated) ? e.DateCreated: null;

    createdDate = createdDate ? createdDate : null;
    let createdDateObj = new Date(createdDate);

    let todaysDateInUtc =  misc.currentTimeToUTCTimeInString(misc.StringFormat.ServerDateFormat);
    let todaysDateObj =  new Date(todaysDateInUtc);
    let dateDiffInTime = todaysDateObj.getTime() - createdDateObj.getTime();
    let dateDiffInDays =  parseInt(dateDiffInTime / (1000*3600 * 24 ));


    let createdDateInLocal = createdDate? misc.datetimeStringInUTCToLocaldatetimeString(createdDate, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat):"";
    if(createdDateInLocal){         
          return (createdDateInLocal+`${dateDiffInDays>0?" ("+dateDiffInDays+" Days) ":" (0 Day) "}`);
    }

    return "";
  }
  

  const getPickupDateToDisplayBasedOnCondition = (pickupWindowDate,pickupWindowTimeslot, plannedReturnPickupDate) =>{
   
    let respPkupDt = "";
    pickupWindowDate = pickupWindowDate ? pickupWindowDate : "";
    pickupWindowTimeslot = pickupWindowTimeslot ? pickupWindowTimeslot : "";
    plannedReturnPickupDate = plannedReturnPickupDate ? plannedReturnPickupDate : "";

    if(pickupWindowDate && pickupWindowTimeslot){
      pickupWindowDate = getReturnDashboardUtility.grabDateStringInFormat(pickupWindowDate,"DD/MM/YYYY");
      respPkupDt = pickupWindowDate+" "+pickupWindowTimeslot;
      return respPkupDt;
    }
    else if(plannedReturnPickupDate){

      respPkupDt = getReturnDashboardUtility.grabDateStringInFormat(plannedReturnPickupDate,"DD/MM/YYYY");
      return respPkupDt;
      
    } 

    return respPkupDt;
  }

  const getDropoffDateToDisplayBasedOnCondition = (dropoffWindowDate,dropoffWindowTimeslot, plannedReturnDate) =>{
   
    let respPkupDt = "";
    dropoffWindowDate = dropoffWindowDate ? dropoffWindowDate : "";
    dropoffWindowTimeslot = dropoffWindowTimeslot ? dropoffWindowTimeslot : "";
    dropoffWindowTimeslot = dropoffWindowTimeslot ? dropoffWindowTimeslot : "";

    if(dropoffWindowDate && dropoffWindowTimeslot){
      dropoffWindowDate = getReturnDashboardUtility.grabDateStringInFormat(dropoffWindowDate,"DD/MM/YYYY");
      respPkupDt = dropoffWindowDate+" "+dropoffWindowTimeslot;
      return respPkupDt;
    }
    else if(plannedReturnDate){

      respPkupDt = getReturnDashboardUtility.grabDateStringInFormat(plannedReturnDate,"DD/MM/YYYY");
      return respPkupDt;
      
    } 

    return respPkupDt;
  }

  

  const handleOkConfirmClose = () =>{
    triggerCloseTaskInfoPopup();    
  }

  const unsetAllReduxStoreStateDataRelatedToTaskInfoPopup = function(){
   
    dispatch(returnsDashboardTaskInfoActions.setTaskInfo({}));
    dispatch(returnsDashboardTaskInfoActions.setTaskItemListInit([]));   
    dispatch(returnsDashboardTaskInfoActions.unsetTaskInfoApiStateData());
    dispatch(returnsDashboardTaskInfoActions.setConsignmentMethodList([])); 
    dispatch(returnsDashboardTaskInfoActions.setWarehouseList([])); 
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoPopupStructureShowFlag(false));
  }


  const unsetAllLocalStateDataRelatedToTaskInfoPopup = () => {
   
    setWarehouseDropDownChangeEventFlag(false);
    setTaskInfoApiOneUpdateSuccessLocalFlag(false); 
    setShouldOpenConfirmForCloseTaskInfoPopup(false);
    setActivityDoneByUserInTaskInfo(false);
    setTaskInfoScheduleBtnPropsObj({});
  }

  const triggerClickTopenScheduleButn = function(){
    if(popRefScheduleBtnComp && typeof(popRefScheduleBtnComp)==="object" && popRefScheduleBtnComp.current!==undefined && popRefScheduleBtnComp.current){ 
      popRefScheduleBtnComp.current.click();
    
    }
  }

  //newly added for task info schedule popup
  const clickTaskInfoScheduleHiddenBtn = (e)=> {    
        
    e.preventDefault();   
    
    //  taskInfo.CompanySubTask.Status==="Awaiting schedule" || taskInfo.CompanySubTask.Status==="Awaiting labels" 
    
    let dataTaskId = e.currentTarget.getAttribute("datataskid"); 
    let taskInfoConsignmentType = e.currentTarget.getAttribute("datataskinfoconsignmenttype"); 
    let taskInfoConsignmentMethod = e.currentTarget.getAttribute("datataskinfoconsignmentmethhod");
    let dataConsignmentLocationCode = e.currentTarget.getAttribute("dataconsignmentlocationcode");
    let dataForStatus = e.currentTarget.getAttribute("dataforstatus");
    

   
    let setParmObjSched = {"taskId":dataTaskId,"taskInfoConsignmentType":taskInfoConsignmentType,"taskInfoConsignmentMethod":taskInfoConsignmentMethod,
    "dataConsignmentLocationCode": dataConsignmentLocationCode
    };
    setTaskInfoScheduleBtnPropsObj(setParmObjSched);  

    if(dataForStatus==="Awaiting labels"){
      //need to be removed
      
      // const paramObjApi1 = {
      //   "Consignments":[
      //       {
      //               "LocationCode":dataConsignmentLocationCode, // consignmentLocationCode
      //               "ConsignmentStatus":"Awaiting schedule",                         
      //               "ActivityType":"Reschedule" // static and fixed
      //     }

      //     ]
      //   };

      //   const successMsgParam = {"message":"Success"};

      //   // api to update to awaiting schedule status
      //   dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToUpdateToAwaitingScheduleFromAwaitingLabels({"bodyParam":paramObjApi1,"successMsgParam":successMsgParam}));

        triggerClickTopenScheduleButn(); 


      }else if(dataForStatus==="Awaiting schedule"){
        setTimeout(()=>{
              triggerClickTopenScheduleButn();
         },2500); 
      }
       

  }
 

  const taskInfoCloseFromSchedulePopup = useCallback(()=>{
    triggerCloseTaskInfoPopup();
  });


  useEffect(()=>{


    return (()=>{
      //console.log("---22 EXITED-----");
      unsetAllReduxStoreStateDataRelatedToTaskInfoPopup();
      unsetAllLocalStateDataRelatedToTaskInfoPopup();           
    });

  },[]);

 

  useEffect(()=>{  
   

    if( (taskInfoUpdateApiOneSuccessFlag===true) && !(taskInfoApiOneUpdateSuccessLocalFlag)){
      setTaskInfoApiOneUpdateSuccessLocalFlag(true);
     
      enqueueSnackbar(`${taskInfoUpdateApiOneSuccessMessage}`, { variant: 'success',onClose:()=>{
        //triggerCloseTaskInfoPopup();
      }, autoHideDuration: 1500 });     
    
    }
    
    // related to 2nd api used for update in  task info popup may needed later
    // if(taskInfoUpdateApiTwoSuccessFlag===true && !(taskInfoApiOneUpdateSuccessLocalFlag)){
    //   setTaskInfoApiOneUpdateSuccessLocalFlag(true);
    //   triggerCloseTaskInfoPopup();
    //   enqueueSnackbar(`${taskInfoUpdateApiTwoSuccessMessage}`, { variant: 'success' ,onClose:()=>{
    //     triggerCloseTaskInfoPopup();
    //   },autoHideDuration: 1500 });
      
    // }   

    if( taskInfoUpdateApiOneErrorFlag===true ){
      enqueueSnackbar(`${taskInfoUpdateApiOneErrorMessage}`, { variant: 'error' });
    }
    
    if(taskInfoUpdateApiTwoErrorFlag===true ){
      enqueueSnackbar(`${taskInfoUpdateApiTwoErrorMessage}`, { variant: 'error' });
    }

    //need to be removed
    // if( (statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess===true)){
    //   triggerClickTopenScheduleButn();    
    
    // }

    
   
},[consignmentMethod,wareHouseLocationCode, toLocationAddressLine1, toLocationAddressLine2, toLocationState, toLocationSuburb, toLocationPostcode, toLocationAddressLine1AfterSel, toLocationAddressLine2AfterSel,toLocationStateAfterSel, toLocationSuburbAfterSel, toLocationPostcodeAfterSel, warehouseDropDownChangeEventFlag, taskInfoUpdateApiOneSuccessFlag, taskInfoUpdateApiTwoSuccessFlag,taskInfoUpdateApiTwoSuccessFlag,
  taskInfoUpdateApiTwoErrorFlag, taskInfoUpdateApiOneErrorFlag, 
]); 

  return (
    <div>      
      
      <Link  ref={refObj} id={'link-for-taskinfopopup-'+taskId} color='primary'  style={{"cursor": "pointer", "display":"none"}} onClick={handleClickOpen}>
        {taskId?taskId:""}
      </Link>
      <Dialog fullWidth={true} maxWidth={"md"} onEnter={dialogOnEnterFunc}  onEntered={dialogOnEnteredFunc} onExited={dialogOnExitedTrackFunc} aria-labelledby="customized-dialog-title" open={open}>
        
      { (taskInfoLoadStatus || warehouseAddressDataLoadStatus) && (<Loading />) }
        {open && (
        <> 
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <SkbIcon  icon={DashboardIcon}/> {(taskInfoConsignmentType)?taskInfoConsignmentType: "" } Task Details
        </DialogTitle>
        {!(taskInfoPopupStructureShowFlag) && (
          <>
            <DialogContent>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </>
        )}
        {taskInfoPopupStructureShowFlag && (
        <>
          <DialogContent dividers={true} >         
            <div className={classes.root}  >
            <Box className="MuiGrid-align-items-xs-top">
            
              
              <Paper className={classes.paper} variant="outlined" >

                  <Grid container spacing={2} alignItems="center" style={{marginBlockEnd:'0em', marginBlockStart: '0.25em'}}>

                        <Grid  item xs={6} sm={6} md={6} zeroMinWidth  >                   
                            <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >
                              <Typography  noWrap={false}>
                                  <h3 style={{marginBlockEnd:'0em', marginBlockStart: '0em'}}>Task detail</h3>
                              </Typography>    
                              <Typography  noWrap={false}>              
                                  {(taskInfoConsignmentType)?taskInfoConsignmentType: "" }
                              </Typography>  
                              <Typography  noWrap={false}>
                                  {(taskInfo && taskInfo.CreatedByUser!==undefined && taskInfo.CreatedByUser && taskInfo.CreatedByUser.Name!==undefined )?("Created By: "+taskInfo.CreatedByUser.Name): ""}
                              </Typography>
                              <Typography>  
                                  {(taskInfo && taskInfo.DateCreated!==undefined)?"Created: "+getDaysToDisplay(taskInfo.DateCreated):""}
                              </Typography>  
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} zeroMinWidth   >                             
                            <Box className="MuiGrid-align-items-xs-top" mr={2}  textAlign="right" >
                                  <Typography style={{marginBlockStart: '0em'}}   noWrap={false}>
                                  {taskId}
                                  </Typography>    
                              <Typography className={"taskDetPopupConsignTypeDrpDwn"}  noWrap={false}>
                                  {(taskInfo && taskInfo.CompanySubTask!==undefined && taskInfo.CompanySubTask && taskInfo.CompanySubTask.Status!==undefined )?taskInfo.CompanySubTask.Status: ""}
                                  <br />
                                  {taskInfNonListChangedDataConsMethod && (

                                    <SkbSelect  Title="" id="tskinfoconsignmentmethoddrpdwn1" value={taskInfNonListChangedDataConsMethod} items={consignmentMethodListToDisplay} valueChanged={handleChangeOfConsignmentMethod} />
                                  )}  

                                 {!(taskInfNonListChangedDataConsMethod) && (
                                    <SkbSelect   Title="" id="tskinfoconsignmentmethoddrpdwn1" value={taskInfNonListChangedDataConsMethod} items={consignmentMethodListToDisplay} valueChanged={handleChangeOfConsignmentMethod} />
                                  )} 
                                 
                                  </Typography>    
                                </Box>
                        </Grid>             

                  </Grid>                

                  <Grid container spacing={2} alignItems="center" >

                        <Grid  item xs={6} sm={6} md={6} zeroMinWidth  >                   
                            <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >
                              <Typography noWrap={false}>
                                <h3 style={{marginBlockEnd:'0em', marginBlockStart: '0em'}}> Contractor details</h3>
                              </Typography>    
                            </Box>

                            <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >  
                              <Typography noWrap={false}>  
                                <Link  style={{"cursor":"pointer"}} >
                                    <SkbIcon  icon={BusinessIcon}/>
                                    
                                </Link> &nbsp;
                                <span style={{marginLeft: '1em'}}>{contractorCompanyName?contractorCompanyName:""}</span>
                              </Typography>                         
                            </Box>
                            <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >                                
                                
                                <Typography noWrap={false} align="left"  >
                                     
                                <ContractorRepresentativePhoneEmailStructure  dataContractorDetails={{"representative":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.Representative!==undefined )?taskInfo.Company.Representative: "","contractorRepresentativePhone":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.ContractorRepresentativePhone!==undefined )?taskInfo.Company.ContractorRepresentativePhone: "","taskId":taskId,"representativeEmail":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.RepresentativeEmail!==undefined )?taskInfo.Company.RepresentativeEmail: "","mailSubject":`${`Skybridge stock return task ${taskId} query`}`}} />
                                </Typography>
                              
                                                    
                            </Box>

                        </Grid>
                        <Grid item xs={6} sm={6} md={6} zeroMinWidth   >                             
                            <Box className="MuiGrid-align-items-xs-top" mr={2} textAlign="right" >
                                  <Typography noWrap={false}>
                                  {taskInfo && taskInfo.ConnoteNumber!==undefined && taskInfo.ConnoteNumber && taskInfo.CarrierName!==undefined && taskInfo.CarrierName && connoteTrackingUrl && (
                                    <h3 style={{marginBlockEnd:'0em', marginBlockStart: '0em'}}> Consignment details</h3>
                                  )}
                                  </Typography>    
                                  <Typography noWrap={false}>                                 

                                      {taskInfo && taskInfo.ConnoteNumber!==undefined && taskInfo.ConnoteNumber && taskInfo.CarrierName!==undefined && taskInfo.CarrierName && connoteTrackingUrl && (
                                      <Link  style={{"cursor": "pointer"}} rel="noopener noreferrer"  href={connoteTrackingUrl} target="__blank">  <SkbIcon  icon={LocationOnIcon}/></Link>
                                      )}

                                      {(taskInfo && taskInfo.ConnoteNumber!==undefined && taskInfo.ConnoteNumber && taskInfo.CarrierName!==undefined && taskInfo.CarrierName && connoteTrackingUrl && (
                                      <>
                                      <Link  style={{"cursor": "pointer"}} rel="noopener noreferrer"  href={connoteTrackingUrl} target="__blank">
                                      {taskInfo.ConnoteNumber}
                                      </Link>
                                      </>

                                      )   )}


                                      {(taskInfo && taskInfo.ConnoteNumber!==undefined && taskInfo.ConnoteNumber && !(connoteTrackingUrl) && (
                                          <>
                                          {taskInfo.ConnoteNumber}
                                          </>
                                        
                                      )   )}


                                  </Typography> 

                                  <Typography noWrap={false}> 
                                    {(taskInfo && taskInfo.CarrierName!==undefined && taskInfo.CarrierName )?taskInfo.CarrierName: ""}

                                  </Typography>                           

                                </Box>
                          
                        </Grid>             

                  </Grid>
                
                
                  <Grid container spacing={2} alignItems="center" style={{marginBlockEnd:'2em', marginBlockStart: '0em',}} >

                    <Grid  item xs={6} sm={6} md={6} zeroMinWidth  >                   
                        <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >
                          <Typography noWrap={false}>
                          <h3 style={{marginBlockEnd:'0em', marginBlockStart: '0em'}}> {taskInfNonListChangedDataConsMethod==="Contractor drop-off"?"Drop-off details":"Pickup details"} </h3>
                          </Typography>    
                        </Box>

                        <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >
                          <Typography noWrap={false}>
                            {/* 1st line => FromLocation.AddressLine1 */}
                            {fromLocationAddressLine1}                            
                          </Typography>  
                          <Typography noWrap={false}>
                            {/* 2nd line => FromLocation.AddressLine2 */}
                            {fromLocationAddressLine2}                            
                          </Typography>  
                          <Typography noWrap={false}>
                          
                            {/* 3rd line => FromLocation.Suburb FromLocation.State FromLocation.Postcode */}
                            { fromLocationSuburb+" "+fromLocationState+" "+fromLocationPostcode}

                          </Typography>
                        </Box>

                        <Box className="MuiGrid-align-items-xs-top" ml={2} textAlign="left" >
                          <Typography noWrap={false}>
                            {/* {taskInfNonListChangedDataConsMethod==="Contractor drop-off"} */}

                            {(taskInfNonListChangedDataConsMethod!=="Contractor drop-off") && ((pickupWindowDate && pickupWindowTimeslot)||(plannedReturnPickupDate)) && (
                              <>
                                   Requested Pickup Date: {getPickupDateToDisplayBasedOnCondition(pickupWindowDate,pickupWindowTimeslot, plannedReturnPickupDate)}
                              </>
                            ) }

                            {(taskInfNonListChangedDataConsMethod==="Contractor drop-off") && ((dropoffWindowDate && dropoffWindowTimeslot)||(plannedReturnDate)) && (
                              <>                                   
                                  Requested drop-off date: {getDropoffDateToDisplayBasedOnCondition(dropoffWindowDate,dropoffWindowTimeslot, plannedReturnDate)}
                              </>
                            ) }

                            
                            
                          </Typography>    
                        </Box>



                    </Grid>

                    <Grid  item xs={6} sm={6} md={6} zeroMinWidth  >                   
                      <Box className="MuiGrid-align-items-xs-top" mr={2} textAlign="right" >
                        <Typography noWrap={false}>
                          <h3 style={{marginBlockEnd:'0em', marginBlockStart: '0em'}}> Destination details</h3>
                        </Typography>    
                      </Box>
                      
                      <Box className="MuiGrid-align-items-xs-top" mr={2} textAlign="right" >                    
                    
                        <Typography className="taskDetPopupWareHouseDrpDwn">
                        {taskInfNonListChangedDataWarehsLocCode && (

                              <SkbSelect Title="" id="checkdropselect2" value={taskInfNonListChangedDataWarehsLocCode} items={wareHouseListToDisplay} valueChanged={handleChangeWareHouseCheck} />
                              )}
                              {!(taskInfNonListChangedDataWarehsLocCode) && (

                              <SkbSelect Title="" id="checkdropselect2" value={taskInfNonListChangedDataWarehsLocCode} items={wareHouseListToDisplay} valueChanged={handleChangeWareHouseCheck} />
                              )}    
                        </Typography>
                    
                    </Box>
                  
                    {/* Data came from api hitted on change */}
                    { (warehouseDropDownChangeEventFlag) && (
                          <>
                              <Box mr={2} textAlign="right" >
                          <Typography noWrap={false}>
                            {/* 1st line => ToLocation.AddressLine1 */}   
                            
                            {toLocationAddressLine1AfterSel==""?"":toLocationAddressLine1AfterSel}


                          </Typography>

                            <Typography noWrap={false}>
                            {/* 2nd line => ToLocation.AddressLine2 */}                            
                            
                            {toLocationAddressLine2AfterSel==""?"":toLocationAddressLine2AfterSel}


                          </Typography>
                          <Typography noWrap={false}>
                          
                            {/* 3rd line => ToLocation.Suburb FromLocation.State FromLocation.Postcode */}
                            
                            
                            {toLocationSuburbAfterSel==""?"":toLocationSuburbAfterSel}
                            
                            
                            {toLocationStateAfterSel==""?"":" "+toLocationStateAfterSel}
                            
                            
                            {toLocationPostcodeAfterSel==""?"":" "+toLocationPostcodeAfterSel}
                            

                          </Typography>
                      </Box>

                      
                          </>

                    )}
                    {/* Data came from api when popup opens */}
                      { !(warehouseDropDownChangeEventFlag)  && (
                          <>
                              <Box className="MuiGrid-align-items-xs-top" mr={2} textAlign="right" >
                          <Typography noWrap={false}>
                            {/* 1st line => ToLocation.AddressLine1 */}   
                          
                            {toLocationAddressLine1==""?"":toLocationAddressLine1}


                          </Typography>

                            <Typography noWrap={false}>
                            {/* 2nd line => ToLocation.AddressLine2 */}                            
                            
                            {toLocationAddressLine2==""?"":toLocationAddressLine2}


                          </Typography>
                          <Typography noWrap={false}>
                          
                            {/* 3rd line => ToLocation.Suburb FromLocation.State FromLocation.Postcode */}
                            
                            
                            {toLocationSuburb==""?"":toLocationSuburb}
                            
                            
                            {toLocationState==""?"":" "+toLocationState}
                            
                            
                            {toLocationPostcode==""?"":" "+toLocationPostcode}
                            

                          </Typography>
                      </Box>

                      
                          </>

                    )}
                      
                    </Grid>

            
            
                  </Grid>

      
              </Paper>
            </Box>

            <Box className="MuiGrid-align-items-xs-top" mt={2}  >

                <Paper className={classes.paper} variant="outlined" >
                  {taskInfoItemList.length==0 && (
                            <SkbTable 
                            id={"taskitemlisttblid"}
                            columns={taskInfoItemListColumns} 
                            dataSource={taskInfoItemList} 
                            actions={taskInfoItemListActions} 
                            filter={taskInfoItemListFilterConfig} 
                            
                            
                          
                  />
                  )}
                  {taskInfoItemList.length>0 && (
                        <SkbTable 
                        id={"taskitemlisttblid"}
                        columns={taskInfoItemListColumns} 
                        dataSource={taskInfoItemList} 
                        actions={taskInfoItemListActions} 
                        filter={taskInfoItemListFilterConfig}                        
                        
                      
                    />
                  )}
                  
                </Paper>
                            
            </Box>

            <Box className="MuiGrid-align-items-xs-top" mt={2} textAlign={"center"}  >

              {taskInfoConsignmentType && dataArToCheckAddButtonVisbility.indexOf(taskInfoConsignmentType)>-1 && (
                    <>
                      <TaskItemAddPopup taskitemaddpropdata={{"fromLocationCode":fromLocationCode}} />
                      {/* <SkbButton id="task-info-items-add" primary size="small" text="ADD"  onClick={handleClose}  /> */}
                      </>

              )}                  
                  
            </Box>            
          
          </div>
          </DialogContent>
          <DialogActions>             
              <Box className="MuiGrid-align-items-xs-top"  textAlign="right" >
                  <SkbButton id="task-info-update" disabled={taskInfoLoadStatus} primary size="medium" text="Update" onClick={handleTaskInfoUpdate}  />
                  </Box>
                  <Box  textAlign="right" >
                  <SkbButton dataforstatus={taskInfo && taskInfo.CompanySubTask!==undefined && taskInfo.CompanySubTask && taskInfo.CompanySubTask.Status!==undefined?taskInfo.CompanySubTask.Status:""} dataconsignmentlocationcode={consignmentLocationCode} datataskinfoconsignmentmethhod={taskInfNonListChangedDataConsMethod} datataskid={taskId} datataskinfoconsignmenttype={taskInfoConsignmentType} disabled={(taskInfo && taskInfo.CompanySubTask!==undefined && taskInfo.CompanySubTask && taskInfo.CompanySubTask.Status!==undefined && (taskInfo.CompanySubTask.Status==="Awaiting schedule" || taskInfo.CompanySubTask.Status==="Awaiting labels" )?false:true )} id="task-info-schedule" primary size="medium" text="Schedule" onClick={clickTaskInfoScheduleHiddenBtn}  />
                  {/* newly added for task info schedule popup */}
                  <TaskDetailsSchedulePopup ref={popRefScheduleBtnComp} taskinfoschhedulepropdata={taskInfoScheduleBtnPropsObj} handletaskinfoclosefromschedulepopup={taskInfoCloseFromSchedulePopup} />
              </Box>              
          
          </DialogActions>  
        </>)}
        
       
       
        </>


        )}
       
      </Dialog>
     
      <SkbConfirmDialog
            id="confirm-taskinfo-popup-close"
            title={"Abandon Changes?"}
            message={"If you continue you will lose changes that you have made. Are you sure you wish to continue and lose changes?"}
            okButtonIsFirst={false}
            okLabel="Yes"
            cancelLabel="No"
            open={shouldOpenConfirmForCloseTaskInfoPopup}
            openConfirm={setShouldOpenConfirmForCloseTaskInfoPopup}
            okHandler={handleOkConfirmClose}
            
            />

<GlobalCss />
                
    </div>
  );
})

export default (TaskDetailsPopup);