import React, { useState, useEffect,useReducer } from "react";
import SkbButton from '../../skb_controls/components/SkbButton';
import SkbTextField from '../../skb_controls/components/SkbTextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, darken } from '@material-ui/core/styles';
import * as auth from '../../Redux/reducers';
import { connect } from 'react-redux';
import * as regi from '../../Redux/actions/register'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import {SkbLogger} from '../../services';
import { useSnackbar } from 'notistack';
import * as assistanceNumber from '../../Redux/actions/callForAssistance'
import FullpageLoader from '../FullpageLoader'
import SkbTabMenu from '../../skb_controls/navigation/SkbTabMenu';
import { Input } from "@material-ui/core";
import {crypts} from '../../utils/crypts';


const useStyles = makeStyles((theme) => ({
  textMargin:{
    marginBottom: theme.spacing(3),
  },
  header:{
    marginBottom: theme.spacing(3),
    textAlign:"center",
    paddingTop:'-50px',
  },
  skblink: {
    cursor:"pointer",  
    "& a[class$='Primary']:link":{ 
        color:theme.palette.primary.main
    },

},
}));


function TFACodeVerification(props){
  const classes = useStyles();
  const userData ={ "user": props.user.AuthUser.email  } ;
  const [msg, setMsg] = useState(''); // verification code
  const [errorMsg,setErrorMsg] = useState(''); // code verified error
  const [displayError,setDisplayErrortOpen]=useState(false); // flag to display the error
  const [disableNextButton, SetdisableNextButton] = useState(true); // disable the next button
  const [showLoader,setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleNotifycation = (message, variant) => {
      enqueueSnackbar(message, { 
          variant: variant
      })};

   useEffect(() => {
     if(!props.register.VerificationCodeSent && msg.length ==0){
    props.sendVerificationCode(props.user)
    } 
    if(props.register.allCountryphonePrefix.length ===0){
      props.updatePhone();
    }  
    if(props.register.allCountryphonePrefix.length !==0 && props.register.PhonePrefixList.length === 0){
      props.updateUserPhonePrefix() ;
    }    
    /* if(msg.length ===6 ){
      props.verifyTheCode(props.user, msg)

    } */
    if(props.register.countries.length ===0){
      props.getCountryList()
    }
    if(msg.length ===6 || props.register.CodeVerifiedSuccess){
      SetdisableNextButton(false)
    }else{
      SetdisableNextButton(true)
    }

    SkbLogger.logDebugInfo('from tfa code page',props.register)
  }, [msg]); 

  function resendCode(){
    SkbLogger.applicationTrace('TFACodeVerification',0,'Self Registraction','User clicked the resend verification code link',userData);
    SkbLogger.userSecurityEventWithRegistrationObject('TFACodeVerification','User security','User Registration','Success','{RegistrationObject} has initiated to resend verification code. ')
    props.sendVerificationCode(props.user);
    handleNotifycation('Verification code has been sent to your email.', 'success')

  }

  
  

  const  goNext =()=>{
    if(!props.register.CodeVerifiedSuccess){
      setshowLoader(true);
      SkbLogger.applicationTrace('TFACodeVerification',0,'Self Registraction','User clicked next button',userData);
      props.checSKBkUser(props.user.AuthUser.email );
      props.verifyTheCode(props.user, msg).then((response)=>{
        if(response){
          setErrorMsg('');
          setDisplayErrortOpen(false);
          setshowLoader(false);
          // at this stage if the only one countory then skip type select page.
          if(props.register.countries.length >1 || props.register.IsSKBUser){
            props.goNextStep();
          }else{
            props.updateDefaultPhonePrefix();
            props.updateAssistanceNumbers(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));
            props.goToVerifyDetail();
          }
        }else{
          handleNotifycation('Invalid verification code, please try again. If you continue to see this message, please resend the verification code.', 'error')
          setErrorMsg('invalid verification code')
          setDisplayErrortOpen(true);
          setshowLoader(false);
        }
        
      })
    }else{
      if(props.register.countries.length >1 || props.register.IsSKBUser){
        props.goNextStep();
      }else{
        props.updateDefaultPhonePrefix();
        props.updateAssistanceNumbers(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));
        props.goToVerifyDetail();
      }
    }
    
}  
  
  const enterVerificationCode =(e) =>{
      setErrorMsg('')
      setDisplayErrortOpen(false)
      setMsg(e);
  }

  const clickGoPre = () =>{
    props.goBack();
    SkbLogger.applicationTrace('TFACodeVerification',0,'Self Registraction','User clicked back button',userData);
  }

/*   {style: {fontSize: 30,textAlign:'center'} } */
    return(
         <React.Fragment> 
       {showLoader && <FullpageLoader />}  
        <Container component="main"  maxWidth="md" justify="center" className={classes.containerheader}>
         <Grid container justify="center" className={classes.header}> 
          <Typography id="tfacode-header" paragraph variant="h2" >
                      Enter Verification Code
          </Typography>
         </Grid> 
       {/*   </Container>
         <Container component="main"  maxWidth="md" justify="center"> */}
        <Grid container justify="center" className={classes.textMargin}>
            <Grid item>
            <SkbTextField error={displayError} helperText={errorMsg} id="tfacode-code" inputProps={
              
              {style: {textAlign:'center'} }
            }  
            autoFocus
              onChange={e => enterVerificationCode(e.target.value)}  placeholder="Verification Number" value={msg}  type="number"
               onInput={(e)=>{ e.target.value = e.target.value.toString().slice(0,6)  }}
               />
            </Grid>
        </Grid>        
        <Grid container justify="center" className={classes.textMargin}>
          <Grid item xs={2} sm={2}></Grid>
          <Grid item xs={8} sm={8}>
            <Typography  paragraph variant="body2"  display="block" gutterBottom> 
                    Please enter the verification number that was emailed to you. The code is valid for 15 minutes.
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2}></Grid>
        </Grid>      
      <Grid container className={classes.textMargin}>
        <Grid item xs={6} sm={6} >
        <Grid container justify="center">
          <SkbButton disabled={(props.register.RegisterStep ===0)}  id="tfacode-back-button" name={"Small Primary"}  primary size="small" text="< Back" onClick={() => clickGoPre()} />
        </Grid>
        </Grid>
        <Grid item xs={6} sm={6} >
        <Grid container justify="center">
          <SkbButton disabled={disableNextButton} id="tfacode-next-button" name={"Small Primary"}  primary size="small" text="Next >" onClick={() => goNext()}/>
        </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center" className={classes.textMargin}>
          <Typography  paragraph variant="body2"  display="block" gutterBottom> 
           To resend your verification code <Link id="tfacode-resend" className={classes.skblink} onClick={() => resendCode()}>click here</Link>
            </Typography>
        </Grid>   
     </Container> 
     </React.Fragment>  
    )
}
const mapStateToProps = state => {
  return { user: state.auth,
        register: state.register};
};

 const mapDispatchToProps = (dispatch) =>{
  return {
    sendVerificationCode : vc => dispatch(regi.sendEmailVerificationCode(vc)),
    goNextStep : () => dispatch(regi.goNext()),
    goPreStep : () => dispatch(regi.goBack()),
    verifyTheCode : (a,reg) => dispatch(regi.verifyVerificationCode(a,reg)),
    getCountryList : () => dispatch(regi.getCountries()),
    goToVerifyDetail : () => dispatch(regi.goToVerifyDetailsPage()),
    updatePhone : () => dispatch(regi.getPhoneConfig()),
    updateAssistanceNumbers : (e) => dispatch(assistanceNumber.updatePhoneNumber(e)),
    updateDefaultPhonePrefix : () => dispatch(regi.updateDefaultPhonePrefix()),
    updateUserPhonePrefix : () => dispatch(regi.updateUserPhonePrefix()),
    checSKBkUser : (email) => dispatch(regi.checkIsSKBUser(email))
    
    
  }
} 

export default connect(mapStateToProps,mapDispatchToProps)(TFACodeVerification);



