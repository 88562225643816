/* {IsLoading,DisplayColumns,MethodList,selectMethod,
    SelectedMethod,SelectedPickupDate,selectPickupDate,SelectedReturnDate,selectReturnDate}  */

import { updateConsignmentMethod,updateConsignmentPickUpDate,updateConsignmentReturnDate } from '../../Redux/actions/stockConsignment';
import SkbIcon from '../../skb_controls/components/SkbIcon'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import React from 'react';
import { lighten, darken,makeStyles, useTheme } from '@material-ui/core/styles';

/* const useStyles=makeStyles((theme)=>({
    textIcon:{

    })
}) */


//DisplayColumns
export  const methodDisplayColumns =[
    { title: '', field: 'id', type: 'hidden-id' },
    { title: " ", field: "DisplayText", type: 'read-only-text' }
    ];

    const TruckImageWithText=(text)=>{
        return  <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }} >
            <SkbIcon  icon={LocalShippingIcon} style={{verticalAlign:'middle'}}/>

        <span style={{  textAlign: 'center', verticalAlign: 'middle' }}>
        {text}</span>
        </div>
    }

    const PersonImageWithText=(text)=>{
        return  <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }} >
            <SkbIcon  icon={DirectionsWalkIcon} style={{verticalAlign:'middle'}}/>

        <span style={{  textAlign: 'center', verticalAlign: 'middle' }}>
        {text}</span>
        </div>
    }

//MethodList
export const methodList=[
    {
        id: 'Skybridge courier',  //keep id the same as displayText for SkbTable
        DisplayText:   TruckImageWithText(` Skybridge courier`)
    },
    {
        id: 'Contractor drop-off', //keep id the same as displayText for SkbTable
        DisplayText: PersonImageWithText(` Contractor drop-off`)
    }
]

    const  ConsignmentDataType= {
        ReturnConsignmentsCreation:"ReturnConsignmentsCreation"
    }

//SelectedMethod    
export const getSelectedStockReturnMethod = (stockConsignment) => {
    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation)) {
        for(let data of stockConsignment.ReturnConsignmentsCreation){
            if(data.Consignments && Array.isArray(data.Consignments)){
                for(let item of data.Consignments){
                    if(item.ReturnMethod && item.ReturnMethod != null && item.ReturnMethod != "" ){
                        return  item.ReturnMethod  
                    }else{
                        return []
                    }                           
                }
            }else{
                return []
            }
        }
    }else{
        return []
    }
}
//selectMethod
//consignment dispatch ,selectedMethod=locationMethod id
export const handleReturnSelectMethod= (dispatch,selectedRow) =>  {

    var consignmentData ={
        "ConsignmentDataType": ConsignmentDataType.ReturnConsignmentsCreation,
            "ReturnMethod":(selectedRow[0] ? selectedRow[0].id : '')   //selectedRow could be blank []
    };
    dispatch(updateConsignmentMethod(consignmentData))
    
}

//SelectedPickupDate
export const getPlannedPickedUpByDate=(stockConsignment)=>{
    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation)) {
        for(let data of stockConsignment.ReturnConsignmentsCreation){
            if(data.Consignments && Array.isArray(data.Consignments)){
                for(let item of data.Consignments){
                    if(item.PlannedPickedUpByDate && item.PlannedPickedUpByDate != null && item.PlannedPickedUpByDate != "" ){
                        return  item.PlannedPickedUpByDate  
                    }else{
                        return null
                    }                           
                }
            }
        }
    }
    else{
        return null
    }
}
//SelectedReturnDate
export const getPlannedReturnDate=(stockConsignment)=>{
    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation)) {
        for(let data of stockConsignment.ReturnConsignmentsCreation){
            if(data.Consignments && Array.isArray(data.Consignments)){
                for(let item of data.Consignments){
                    if(item.PlannedReturnedByDate && item.PlannedReturnedByDate != null && item.PlannedReturnedByDate != "" ){
                        return  item.PlannedReturnedByDate  
                    }else{
                        return null
                    }                           
                }
            }
        }
    }
    else{
        return null
    }
}
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
//selectPickupDate
export const handleReturnPickedUpDate=(dispatch,planedPickedUpDate)=>{

    var consignmentData ={
        "ConsignmentDataType": ConsignmentDataType.ReturnConsignmentsCreation,
        "PlannedPickedUpByDate":planedPickedUpDate? formatDate(planedPickedUpDate):null//.toISOString().split('T')[0]
    };
    dispatch(updateConsignmentPickUpDate(consignmentData))
}
//selectReturnDate

 

export const handleReturnDate=(dispatch,planedReturnDate)=>{
    var consignmentData ={
        "ConsignmentDataType": ConsignmentDataType.ReturnConsignmentsCreation,
        "PlannedReturnedByDate":planedReturnDate? formatDate(planedReturnDate):null//.toISOString().split('T')[0]
    };
    dispatch(updateConsignmentReturnDate(consignmentData))
}