import React from 'react';
import {StocktakeSteps} from '../../components/modules/Stocktake';
import {Status} from '../../Redux/actions/tasks';

import {getCurrentSubTask,getPreviewItemsForAllSubTasksOfCurrentTask}  from '../../Redux/actions/tasks';  

function makeErrorMessage(isValid, severity, message, title, display)
{
    return {isValid:isValid, 
            severity: severity,  //'warning' or 'error'
            message: message,
            title: title,
            display: display //'dialog' or 'toast'
        }
}

//This method is used internally by the validateStep method below.  It's not the way the components need to use it.  
export function validateStepInternal(stepId, stockItems, previousStockItems, currentSubTask)
{

    const validationSuccess= {isValid:true//, 
            // severity: '',
            // message: '',
            // title:'',
            //display:''
        };

    let previousStockCodes;
    if (!previousStockItems || previousStockItems.length===0)
    {
        previousStockCodes=[];
    }
    else{
        previousStockCodes=previousStockItems.map(x=>x.StockCode);
    }

    let currentStockCodes=[]; 
   // let missingStockCodes=[]; 
    let missingStockItems=[]; 
    let msg='';
    
    switch(stepId)
    {
        case StocktakeSteps.SERIALISED:
            currentStockCodes=stockItems.map(x=>x.StockCode); 

            missingStockItems=previousStockItems.filter(p=>!currentStockCodes.includes(p.StockCode));

            if(missingStockItems.length>0)
            {
                msg=
                'We believe you have more items to scan.  Can you please check to see if you have any of the following stock: \n' 
                        +missingStockItems.map(m=>m.Description).join(' \n ') +'.  \nDo you still have any of these items that you need to include in the stocktake?';
                return makeErrorMessage(false, 'warning', msg, 'Additional Items?', 'dialog' );
            }
            else
            {
                return validationSuccess;
            }

            break;

        case StocktakeSteps.NON_SERIALISED:
            currentStockCodes=stockItems.filter(i=>(i.GoodQty!=0||i.FaultyQty!=0)) //Only count them if they have a quantity recorded ("0" and 0 are regarded as 0).  
                                        .map(x=>x.StockCode); 

            missingStockItems=previousStockItems.filter(p=>!currentStockCodes.includes(p.StockCode));
            if(missingStockItems.length>0)
            {
               msg=
               'We believe you have more items to count.  Can you please check to see if you have any of the following stock: \n' 
                       +missingStockItems.map(m=>m.Description).join(' \n ') +'.  \nDo you still have any of these items that you need to include in the stocktake?';
                return makeErrorMessage(false, 'warning', msg, 'Additional Items?' , 'dialog' );

            }
            else
            {
                return validationSuccess;
            }
            break;
        case StocktakeSteps.REVIEW:
                //console.log(stockItems);
                var faultySerialisedItems=stockItems.filter(i=>i.ItemStatus==="Faulty"
                                                        && i.SerialNumber!==null) 
                                            .map(x=>x.StockCode); 

                //console.log(faultySerialisedItems);

                var faultyNonserialisedItems=stockItems.filter(i=>i.FaultyQty!=0   // ("0" and 0 are regarded as 0).  
                                                                && (i.SerialNumber===null||i.SerialNumber===undefined) ) 
                                            .map(x=>x.StockCode); 

                console.log(faultyNonserialisedItems);
    
                if(faultySerialisedItems.length===0||faultyNonserialisedItems.length===0)
                {
                   msg='Do you have any faulty stock that you need to count?';
                    return makeErrorMessage(false, 'warning', msg, 'Faulty Stock?' , 'dialog' );
    
                }
                else
                {
                    return validationSuccess;
                }
                break;
        case StocktakeSteps.SIGNATURE:
            //Get the outstanding steps, other than this one:
            var outstandingSteps=currentSubTask.Steps.filter(s=>s.Status!==Status.Done && s.StepId!==StocktakeSteps.SIGNATURE);

            if(outstandingSteps.length>0){
                msg=
                'You haven\'t fully completed all the previous steps.  Please complete all steps before signing off.';
                return makeErrorMessage(false, 'error', msg, 'Signoff' , 'toast' );
            }
            else if(!currentSubTask.SignoffSig || !currentSubTask.SignoffSig.Sig){
                msg=
                'Please save your signature.';
                return makeErrorMessage(false, 'error', msg, 'Signoff' , 'toast' );
            }else{
                return validationSuccess;
            }
            break;
        default:
            return validationSuccess;
            break;

    }

}

//The method to be used in the main components.  It takes the whole sub-task and processes it rather than needing the stock item lists.
export default function validateStep(activeStepId, currentSubTask)
{

    let previewItemsFiltered= currentSubTask.PreviewInfo.filter(i=>i.Qty!=0);  //only show items in list with a non-zero previous quantity

    let previousItems;
    let currentItems;

    if (activeStepId===StocktakeSteps.SERIALISED)
    {
        previousItems=previewItemsFiltered.filter(i=>i.StockType==="Serialised");
        currentItems=currentSubTask.SerialisedItems;
    }
    else if (activeStepId===StocktakeSteps.NON_SERIALISED)
    {
        previousItems=previewItemsFiltered.filter(i=>i.StockType!=="Serialised");
        currentItems=currentSubTask.NonSerialisedItems;
    }
    else if (activeStepId===StocktakeSteps.PREVIEW)
    {
        previousItems=previewItemsFiltered;
        currentItems=[];
    }
    else 
    {
        previousItems=previewItemsFiltered;
        currentItems=currentSubTask.SerialisedItems.concat(currentSubTask.NonSerialisedItems);
    }
    const validationResult=validateStepInternal(activeStepId,currentItems, previousItems, currentSubTask);

    return validationResult;


}