import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SkbStepper from '../../../skb_controls/components/SkbStepper';
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';
import Loading from '../../Loading';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import Typography from '@material-ui/core/Typography';
import StepStockReviewWithCancel from './StepStockReviewWithCancel';
import BusinessIcon from '@material-ui/icons/Business';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import * as pickingAndPacking from '../../../utils/returnstock/pickingAndPacking';
import * as taskActions from '../../../Redux/actions/tasksv2';
import * as returnStockUtils from '../../../utils/returnstock/misc';
import StepConsignmentLabels from './PickupDelivery/StepConsignmentLabels';

const useStyles = makeStyles((theme) => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
            '& >div.MuiGrid-item:not(:first-child)': {
                borderTop: `1px solid ${theme.palette.text.primary}`,
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                // '&:last-child':{
                //     borderBottom: `1px solid ${theme.palette.text.primary}`,

                // },
            },

        },
        title: {
            textAlign: "left",
        },
        safari: {
            height: '-webkit-fill-available',
        },
        avatarIcon: {

            display: 'inline-block',
            verticalAlign: 'middle',
            [theme.breakpoints.down('sm')]: {
               fontSize: 24
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: 30
            },
        },
        cancelIcon: {
            clear: "none",
            float: "right"
        },  
        taskStatus: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            color:  theme.palette.error.main
            // fontSize:'0.8em',
    
        },

    }
));

function checkMissingProperties(expectedProps, actualProps) {
    const missingProps = _.without(expectedProps, ...Object.keys(actualProps));

    if (_.isArray(missingProps) && missingProps.length > 0) return missingProps.toString();

    return "";
}

function enableCancelOrderButton(stockConsignment,userId){
   // return true
    if(stockConsignment.CreatedById && stockConsignment.CreatedById>0 && stockConsignment.CreatedById !==userId){
        return false
    }else{
        return true
    } 
}

/**
 * 
 * @param {*} props HOC needs pass taskId, taskId props, either form HOC or from route like '/:taskId/:consignmentId', consignmentId is sub task id of task 
 * @returns 
 */
export function LabelAndSendSteps(props) {
    const classes = useStyles();
    const { tasks, dispatch, user } = props; //stockConsignment is the current selected consignment
    const params = useParams();
    let taskId = props.taskId;
    let consignmentId = props.consignmentId;
    if (!taskId || !consignmentId) {
        taskId = params.taskId;
        consignmentId = params.consignmentId;
    }
    const subStatusContent=(taskStatusText)=>{
        return <div>
            <Typography variant="body1" className={classes.taskStatus}>{taskStatusText}</Typography>
        </div>
    }
    const task = pickingAndPacking.getTask(tasks, taskId);
    const stockConsignment = pickingAndPacking.getConsignment(tasks, taskId, consignmentId);
    const wizardInfo = {
        DisplayText: `ID: ${stockConsignment.SubTaskId}`,
        Description: returnStockUtils.buildAddressInOneLine(stockConsignment.PickupAddressLine1, 
            stockConsignment.PickupAddressLine2,
            stockConsignment.PickupAddressSuburb,
            stockConsignment.PickupAddressState,
            stockConsignment.PickupAddressPostcode),
       // SubTaskStatus:stockConsignment.TaskStatus,
        SubTaskStatusContent:subStatusContent(stockConsignment.TaskStatus)
    };

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [lastSelectedStockCode, setLastSelectedStockCode] = useState("");
    const [stepToActive, setStepToActive] = React.useState(-1);
    const returnRoute = props.returnRoute || '/home';


    const closeLabelAndSendWindow = () => {
        history.push(returnRoute);
        pickingAndPacking.updateTaskLockedBy(dispatch,task,stockConsignment,0);
    };

    useEffect(() => {
        if (props.Error) {
            enqueueSnackbar(props.Error, {
                variant: "error"
            });
        }
    }, [props.Error]);
//becaue the overview page is same as the picking and packing review page. So we can reuse the pickingAndPacking 
  /*   useEffect(()=>{
        if(!stockConsignment.StepsStatus)
            pickingAndPacking.loadTaskStepStatus(dispatch, task, stockConsignment);
    }, [stockConsignment.StepsStatus]) */
    
    const stepProps = {
        IsLoading: props.IsLoading,
        openReschedule:props.OpenReschedule,
        enableCancelButton:enableCancelOrderButton(stockConsignment ,user.User.id),
        taskObjectForLogging: stockConsignment,
        expectedItems: pickingAndPacking.getExpectedItems(stockConsignment),
        loadExpectedItems: () => { pickingAndPacking.loadExpectedItems(dispatch, task, stockConsignment); },
        goToStep: (stepId) => { setStepToActive(stepId); },
        serialisedItems: pickingAndPacking.getSerialisedItems(stockConsignment),
        loadSerialisedItems: () => { pickingAndPacking.loadSerialisedItems(dispatch, task, stockConsignment); },
        nonSerialisedItems: pickingAndPacking.getNonSerialisedItems(stockConsignment),
        loadNonSerialisedItems: () => { pickingAndPacking.loadNonSerialisedItems(dispatch, task, stockConsignment); },   
        consignmentMethod: stockConsignment.DeliveryMethod,
        stepsStatus: stockConsignment.StepsStatus||{},
        loadPickupDetails:()=>{pickingAndPacking.loadPickupDetails(dispatch,task,stockConsignment);},
        updatePickupDetails:(pickupDetails)=>{pickingAndPacking.updatePickupDetails(dispatch, task, stockConsignment, pickupDetails);},
        setOpenReschedule:(open)=>{pickingAndPacking.setOpenReschedule(dispatch,open);},
        loadDropOffDetails: ()=>{pickingAndPacking.loadDropOffDetails(dispatch,task,stockConsignment);},
        loadPackageDetails:()=>{pickingAndPacking.loadPackageDetails(dispatch,task,stockConsignment);},
        updatePackage:(pack)=>{pickingAndPacking.updatePackage(dispatch,task,stockConsignment,pack,true);},
        deletePackage:(pack)=>{pickingAndPacking.deletePackage(dispatch,task,stockConsignment,pack,true);},
        addPackage:(pack)=>{pickingAndPacking.addPackage(dispatch,task,stockConsignment,pack,true);},
        updateDropOffDetails:(dropOffDetails)=>{pickingAndPacking.updateConsignmentDropOffDetails(dispatch,task,stockConsignment,dropOffDetails);},
        loadLabelDetails:()=>{pickingAndPacking.loadLabelDetails(dispatch,task,stockConsignment);}, 
        updateConsignmentStatus:(newStatusDetails)=>{pickingAndPacking.updateConsignmentStatus(dispatch,task,stockConsignment,user.User,newStatusDetails);}  ,


    }


    const steps = getLabelAndSendSteps(stepProps);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = React.useState("");
    const [confirmTitle, setConfirmTitle] = React.useState("");

    function showConfirmDialog(title, message) {
        setConfirmTitle(title);
        setConfirmMsg(message);

        // setOkHandler(okHandler); //Ideally would pass in the callback function for processing an OK response.
        setOpenConfirm(true);

    }
    useEffect(()=>{
        pickingAndPacking.loadLabelDetails(dispatch,task,stockConsignment);
        pickingAndPacking.loadPackageDetails(dispatch,task,stockConsignment);
        if(document.getElementById("btn_complete")){
            document.getElementById("btn_complete").style.display='none'  
        }
        //document.getElementById("btn_complete").style.display='none'
    },[])


    return (
        <>
            <Grid container className={classes.root} id="label_send_steps">
                <Grid item xs={12} id="label_send_title" className={classes.title}>
                    <Typography variant="h5"><SkbIcon classes={{ iconStyle: classes.avatarIcon }} icon={BusinessIcon} />{stockConsignment.ReturnType}
                        <div className={classes.cancelIcon}><SkbIcon onClick={() => { showConfirmDialog('Please Confirm', 'You haven\'t completed label print and send.  Are you sure you want to close?') }} classes={{ iconStyle: classes.avatarIcon }} icon={CancelOutlinedIcon} /></div>
                    </Typography>

                </Grid>
                <Grid item xs={12} id="label_send_steppers" className={classes.safari}>
                    <SkbStepper WizardInfo={wizardInfo} Steps={steps}  Mode="NonLinear"  />
                </Grid>
            </Grid>

            <SkbConfirmDialog id="Confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm}
                okHandler={closeLabelAndSendWindow}
                okLabel="Yes" cancelLabel="No" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />
            {props.IsLoading && <Loading />}
        </>
    );
}

function getLabelAndSendSteps(props) {
    //shared properties
    const { StepValidators, taskObjectForLogging, expectedItems, loadExpectedItems,enableCancelButton,openReschedule,consignmentMethod } = props;
    const IsLoading = false; // showing loading indicator is done in this component directly

    const moduleName = 'LabelAndSend';

    const previewColumnHeader = ['Description', 'UOM', 'Expected Status', '# To Be Returned'];
    const emptyDataMessage = 'No items for return!';

    // properties used in overview
    const { serialisedItems, loadSerialisedItems ,nonSerialisedItems, loadNonSerialisedItems,goToStep} = props;

    // properties used in StepStockReview
    const stepReviewColumnHeaders = ['Description', 'UOM', '# Good', '# Faulty'];

    //properties used in label details

    const {loadPickupDetails,updatePickupDetails,loadDropOffDetails,updateDropOffDetails,loadLabelDetails,
        updateConsignmentStatus,setOpenReschedule,loadPackageDetails,updatePackage,addPackage,deletePackage}=props;

    //stepsStatus prop
    const {stepsStatus} = props;
    let desc="";
    if(consignmentMethod==="Skybridge courier"){
        desc="Please mark Package Sent after the courier has picked up the stock."
    }else{
        desc="Please mark Package Delivered after the stock has been dropped off."
    }
    
    return [
        {
            Title: "Overview",
            StepName: "Overview",
            Description: "Please take note of all the stock that is expected and ensure it is packaged. If there are any changes, please contact UGL immediately.",
            Content: <StepStockReviewWithCancel
                isLoading={IsLoading}
                enableCancelButton={enableCancelButton}
                serialisedItems={serialisedItems}
                nonSerialisedItems={nonSerialisedItems}
                expectedItems={expectedItems}
                loadSerialisedItems={loadSerialisedItems}
                loadNonSerialisedItems={loadNonSerialisedItems}
                loadExpectedItems={loadExpectedItems}
                columnHeaders={stepReviewColumnHeaders}
                goToStep={goToStep}
                handleCancelOrder={()=>{}}
                emptyDataMessage={"No items have been picked!"}
            />,
            StepValidator: () => { return { isValid: true }; }
        },
        {
            Title: "Consignment labels",
            StepName: "Labels/Dellvery",
            Description:desc ,
            Content: <StepConsignmentLabels
                 isLoading={IsLoading}
                 openReschedule={openReschedule}
                 loadPackageDetails={loadPackageDetails}
                 setOpenReschedule={setOpenReschedule}
                 consignment={taskObjectForLogging}
                 loadPickupDetails={loadPickupDetails}
                 updatePickupDetails={updatePickupDetails}
                 loadDropOffDetails={loadDropOffDetails}
                 updateDropOffDetails={updateDropOffDetails}
                 loadLabelDetails={loadLabelDetails}
                 updateConsignmentStatus={updateConsignmentStatus}
                 updatePackage={updatePackage}
                 addPackage={addPackage}
                 deletePackage={deletePackage}
            />,
            StepValidator: () => { return { isValid: true }; }
            //StepValidator: validatePackage
            
        }
    ];
}


const mapStateToProps = state => ({
    user: state.auth,
    //stockConsignment: taskActions.getCurrentTask(state.tasks),
    tasks: state.tasksV2,
    IsLoading: state.tasksV2.isLoading || false,
    OpenReschedule: state.tasksV2.openReschedule || false,
    Error: state.tasksV2.Error
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LabelAndSendSteps);
