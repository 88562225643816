import { SkbLogger, ApiService, SeverityLevel } from '../../services';
import * as dataSyn from '../../services/dataSaverAndTracker';
import * as changesSync from '../../services/changesSync';
import uuid from 'react-uuid';
import { saveDataItem } from '../../services/DataSyncWebAPI';
import {crypts} from '../../utils/crypts';


export const Actions = {
    UPDATE_SYNCCONFIG: "UPDATE_SYNCCONFIG",
    LOAD_SYNCCONFIG: "LOAD_SYNCCONFIG",
    UPDATE_LOADING:"UPDATE_LOADING",
    REFRESH_FROM_LOCALDB:"REFRESH_FROM_LOCALDB",
    UPDATE_FAILED:"UPDATE_FAILED",
    UPDATE_SINGLE_SYNCCONFIG:"UPDATE_SINGLE_SYNCCONFIG"
};

//itemArraryObject contains resourceID, clusterResourceId,key,value)
/* export function saveSyncConfigChagnes(itemArraryObject){
    SkbLogger.logDebugInfo('from saveSyncConfigChagnes',itemArraryObject)
    itemArraryObject.map((item, i) => {
        {
            if(item.value !== ''){
                saveDataItem(item.resourceID,item.clusterResourceId,item.key,item.value,'METADATA');
            }
        }
    })
} */

export const saveSyncConfigChagnes = (itemArraryObject) =>  (dispatch) =>
  new Promise(function(resolve, reject) {
     /*  itemArraryObject.map((item, i) => { */
    for(let item of itemArraryObject)
        {
            if(item.value !== ''){
                SkbLogger.logDebugInfo('from save sync config',item.resourceID,item.clusterResourceId,item.key,item.value);
                dispatch(updateSingleSyncConfig(item))
                dataSyn.saveDataItem(item.resourceID,item.clusterResourceId,item.key,item.value,'METADATA').then(() =>{
                    
                    resolve();
                        SkbLogger.applicationTrace('syncConfig',SeverityLevel.Information, 'Sync config','Update sync config to sync table successful',itemArraryObject)
                },function(error){
                    SkbLogger.logDebugInfo('catch error from save sync config',error)
                    reject();
                        SkbLogger.applicationException('syncConfig', 'Sync config','Update sync config to sync table failed',itemArraryObject,error)
                })
            }
        }
   

 /*    })  */

  });

/* 
export const saveSyncConfigChagnes = (itemArraryObject) => async (dispatch) => {
    try {
       await  itemArraryObject.map((item, i) => {
            {
                if(item.value !== ''){
                   var ret= dataSyn.saveDataItem(item.resourceID,item.clusterResourceId,item.key,item.value,'METADATA')
                        SkbLogger.logDebugInfo('from save sync config',ret);
                        if(ret){
                            dispatch(updateFailed())
                            
                            SkbLogger.applicationTrace('syncConfig',SeverityLevel.Information, 'Sync config','Update sync config to sync table failed',itemArraryObject)
                        }

                    
                }
            }
        }) 

    } catch (err) {
        SkbLogger.applicationException('syncConfig','Sync config','Update sync config to sync table failed',itemArraryObject)
        dispatch(updateFailed())
    }
  }
 */

//getPhotoDownloadSpeedSetting()

export function getPhotoDownloadSpeed(){
    ApiService.getPhotoDownloadSpeedSetting().then(function (res) {
        if(res.data && res.data.length >0){
            SkbLogger.logDebugInfo('getPhotoDownloadSpeedFromAPI',res.data)
            localStorage.setItem('photoDownloadSpeedSetting',crypts.encrypt(JSON.stringify(res.data)))

            SkbLogger.applicationTrace('SyncConfig', SeverityLevel.Information, 'Get photo download speed Setting', 'Get photo download speed Setting from API successful', res);
        }else{
            SkbLogger.applicationTrace('SyncConfig', SeverityLevel.Information, 'Get photo download speed Setting', 'No photo download speed Setting data from API', res);
        }
    }).catch(function (error) {
            SkbLogger.logDebugInfo('getPhotoDownloadSpeed: error Response', error)
            SkbLogger.applicationException('SyncConfig', 'Get photo download speed Setting', 'Exception from get photo download speed Setting API', error);
    });
}

 export  function loadSyncConfigData() {
    var clusterResourceId='';
    return async (dispatch) => {
        dispatch(updateLoadingStatus(true));
        SkbLogger.logDebugInfo('from loadSyncConfigData',new Date());
        if(navigator.onLine) {
            if(await dataSyn.isReasonableAmountOfDataInLocal()){
                await changesSync.frontendSync();
            }
        }
        var cluster= await dataSyn.queryDataClustersForEntity('SETTING');
        if(!cluster || cluster.length ===0){
            dispatch(initialSyncConfig());
        }else{
            clusterResourceId = cluster[0].resourceID;
            SkbLogger.logDebugInfo('from queryDataItemsForCluster return',cluster,clusterResourceId);
            var response = await  dataSyn.queryDataItemsForCluster(clusterResourceId)
            console.log('called from await response',response)
                 /*  .then(
                        function(response){
                            SkbLogger.logDebugInfo('from queryDataItemsForCluster',response); */
                            if(response && Array.isArray(response) && response.length >0){
                                console.log('called from await response has length',response)
                                dispatch(refreshSyncConfigDataFromLocalDB(response,clusterResourceId));
                                dispatch(updateLoadingStatus(false))
                            }else{
                                console.log('called from await response has no length',response)
                                dispatch(initialSyncConfigSetting(clusterResourceId))
                            }
            }
                   /*  ) */
        }
}
/*         )
        
    };
}  */
// arrary of clusterItem
export function refreshSyncConfigDataFromLocalDB(dataItem,clusterResourceId){
    return{
        type:Actions.REFRESH_FROM_LOCALDB,
        payload:dataItem,
        clusterId : clusterResourceId
    }
}

export function updateSingleSyncConfig(signleSyncConfig){
    return{
        type:Actions.UPDATE_SINGLE_SYNCCONFIG,
        payload:signleSyncConfig
    }
}



export function updateLoadingStatus(trueOrFalse){
    return{
        type:Actions.UPDATE_LOADING,
        payload:trueOrFalse
    }
}

//syncConfigObject contains {clusterResourceId:'', airResourceId:'', airPlaneModeInterval: 1,retriesResourceId:'',  attemptsErrorOccurs:3,
//   dMDResourceId:'', downloadMetadataD:1 , dADResourceId:'', downloadArtefactsD:1};
export function updateSyncConfig(syncConfigObject,clusertId){
    return{
        type:Actions.UPDATE_SYNCCONFIG,
        payload:syncConfigObject,
        clusertId:clusertId
    }
}

export function updateFailed(){
    return{
        type:Actions.UPDATE_FAILED,
        payload:true
    }
}


export const initialSyncConfigSetting = (clusterResourceId) => async (dispatch) => {
    SkbLogger.logDebugInfo('get into initialSyncConfigSetting',clusterResourceId);
    try {
      const airplaneModeIntervalResourceId = ''+uuid().replace(/-/ig,'');
      const retriesResourceId = ''+uuid().replace(/-/ig,'');
      const downloadMetadataInDaysResourceId = ''+uuid().replace(/-/ig,'');
      const downloadPhotoInDaysResourceId = ''+uuid().replace(/-/ig,'');


      const syncConfigObject =[{
            "resourceID":airplaneModeIntervalResourceId,
            "clusterResourceId":clusterResourceId,
            "key":"AirplaneModeInterval",
            "value":10},
        {
            "resourceID":retriesResourceId,
            "clusterResourceId":clusterResourceId,
            "key":"Retries",
            "value":3 
        },
        {
            "resourceID":downloadMetadataInDaysResourceId,
            "clusterResourceId":clusterResourceId,
            "key":"DownloadMetadataInDays",
            "value":3 
        },
        {
            "resourceID":downloadPhotoInDaysResourceId,
            "clusterResourceId":clusterResourceId,
            "key":"DownloadPhotoInDays",
            "value":3 
        }]

         await dataSyn.saveDataItem(airplaneModeIntervalResourceId,clusterResourceId,'AirplaneModeInterval','10','METADATA','Check Airplane mode interval: from one minute to an hour');
         await dataSyn.saveDataItem(retriesResourceId,clusterResourceId,'Retries','3','METADATA','Attempts when an error occurs: from 1 to 10 (default 3)');
         await dataSyn.saveDataItem(downloadMetadataInDaysResourceId,clusterResourceId,'DownloadMetadataInDays','3','METADATA','Download metadata in days: from 1 day to 30 days');
         await dataSyn.saveDataItem(downloadPhotoInDaysResourceId,clusterResourceId,'DownloadPhotoInDays','3','METADATA','Download artefacts in days: from 1 day to 30 days (must be equal or higher than the metadata setting)');
        
         const addedItem = await dataSyn.getDataItem(downloadPhotoInDaysResourceId);

          if(addedItem){
             dispatch(updateSyncConfig(syncConfigObject,clusterResourceId))
      }
    } catch (err) {
        dispatch(updateFailed())
    }
  }


export const initialSyncConfig = () => async (dispatch) => {
    try {
      const clusterResourceId = ''+uuid().replace(/-/ig,'');
      const airplaneModeIntervalResourceId = ''+uuid().replace(/-/ig,'');
      const retriesResourceId = ''+uuid().replace(/-/ig,'');
      const downloadMetadataInDaysResourceId = ''+uuid().replace(/-/ig,'');
      const downloadPhotoInDaysResourceId = ''+uuid().replace(/-/ig,'');

      const syncConfigObject =[{
        "resourceID":airplaneModeIntervalResourceId,
        "clusterResourceId":clusterResourceId,
        "key":"AirplaneModeInterval",
        "value":10},
    {
        "resourceID":retriesResourceId,
        "clusterResourceId":clusterResourceId,
        "key":"Retries",
        "value":3 
    },
    {
        "resourceID":downloadMetadataInDaysResourceId,
        "clusterResourceId":clusterResourceId,
        "key":"DownloadMetadataInDays",
        "value":3 
    },
    {
        "resourceID":downloadPhotoInDaysResourceId,
        "clusterResourceId":clusterResourceId,
        "key":"DownloadPhotoInDays",
        "value":3 
    }]

        SkbLogger.logDebugInfo('before call  addedCluster',syncConfigObject)
      await dataSyn.saveDataCluster(clusterResourceId,'User Sync Settings','SETTING',0);



         await dataSyn.saveDataItem(airplaneModeIntervalResourceId,clusterResourceId,'AirplaneModeInterval','10','METADATA','Check Airplane mode interval: from one minute to an hour');
         await dataSyn.saveDataItem(retriesResourceId,clusterResourceId,'Retries','3','METADATA','Attempts when an error occurs: from 1 to 10 (default 3)');
         await dataSyn.saveDataItem(downloadMetadataInDaysResourceId,clusterResourceId,'DownloadMetadataInDays','3','METADATA','Download metadata in days: from 1 day to 30 days');
         await dataSyn.saveDataItem(downloadPhotoInDaysResourceId,clusterResourceId,'DownloadPhotoInDays','3','METADATA','Download artefacts in days: from 1 day to 30 days (must be equal or higher than the metadata setting)');
        
         const addedItem = await dataSyn.getDataItem(downloadPhotoInDaysResourceId);

          if(addedItem){
             SkbLogger.logDebugInfo('from addeditem',addedItem)
             dispatch(updateSyncConfig(syncConfigObject,clusterResourceId))
      }
    } catch (err) {
        dispatch(updateFailed())
    }
  }

/* export  function initialSyncConfig() {
      return(dispatch) =>{
        const clusterResourceId = uuid().replace(/-/ig, '');
        saveDataCluster(clusterResourceId,'User Sync Settings','SETTING',0);
        dispatch(initialClusterItem(clusterResourceId));

      }
     
}    */ 