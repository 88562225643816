import React, { useEffect,useState, useRef } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import InputIcon from '@material-ui/icons/Input';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';

import Box from '@material-ui/core/Box';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
//import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from "react-js-pull-to-refresh";
import Loading from '../../../Loading';
import {crypts} from '../../../../utils/crypts';

import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import { Grid, Toolbar, Button } from '@material-ui/core';
// import ReturnsDashboardSummary from './ReturnsDashboardSummary';
            
import { useSnackbar } from 'notistack';

//import * as assistanceNumber from '../../../../Redux/actions/callForAssistance';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import SkbTable from '../../../../skb_controls/components/SkbTable';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';
import SkbSelect from '../../../../skb_controls/components/SkbConfirmDialog';

import * as  returnsDashboardListActions from '../../../../Redux/actions/returnsdashboard';
import * as  returnsDashboardTaskInfoActions from '../../../../Redux/actions/returnsdashboard';

// import PieChartWithChartsjs from './PieChart/PieChartWithChartjs';
import TaskDetailsPopup from "./TaskDetailsPopup";
import { Select, MenuItem } from '@material-ui/core';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import Link from '@material-ui/core/Link';


// importing components
import ChartByStatus from './PieChart/ChartByStatus/ChartByStatus.component';
import ChartByAge   from './PieChart/ChartByAge/ChartByAge.component';
import ReturnsDashBoardListCancelPopup from "./ReturnsDashBoardListCancelPopup";



const useStyles = makeStyles((theme) => ({
    root: {

        marginTop: theme.spacing(2)
    },

    returnDashboardHeader: {
        alignItems: 'center',
    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end', 
    },
    tasksfooter: {
        textAlign: 'center',
        height: 200,
        cursor: 'default',
        paddingTop: 5, 
        overscrollBehavior: "contain",
    },
    
    //this is for proper paddings/margins around the SkbTable
    //to be applied by any upper layer components that use SkbTable
  
    table:{
        padding: '1% 2% 4% 2%',

        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 16px)',
            margin: theme.spacing(0),
    
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 200px)',
            marginBottom: theme.spacing(2),
        },
        
    
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100vw - 8px)',
            marginBottom: theme.spacing(0),
        },
        
    
        [theme.breakpoints.down(376)]:  //376px wide or less
            isProblemDevice()?{      //and one of the known problem devices
            width: 'calc(90vw - 8px)'   //Add a gap at the side to allow for scrolling.
            }
            :{},
            

    },

    reset: {
        fontSize: 'medium',
        textAlign: 'center',
        color: theme.palette.primary.light,

    }

}));

var _isProblemDevice;
 function isProblemDevice(){
    //take account of performance, only check once
    if(_isProblemDevice !== undefined) return _isProblemDevice;

    const toMatch = [
        /iPhone OS 12/i
    ];

    return toMatch.some((toMatchItem) => {
        _isProblemDevice=navigator.userAgent.match(toMatchItem);
        return _isProblemDevice;
    });
}



const SortDirection = {
    Ascent: 1,
    Descent: 2
}
const SkbSortIcon = ({ sortDirection }) => {
    if (sortDirection === SortDirection.Descent) {
        return <div>
            <SkbIcon icon={ArrowDownwardSharpIcon} />
            <SkbIcon icon={SortIcon} />
        </div>
    } else if (sortDirection === SortDirection.Ascent) {
        return <div>
            <SkbIcon icon={ArrowUpwardSharpIcon} />
            <SkbIcon icon={SortIcon} />
        </div>
    }
    return null;
}


/** @function TextWithIcon to show text and icon together, icon will be in front of text 
 * @param icon a material icon
 * @param icon text display after the icon
 * @param className style class to apply 
*/
const TextWithIcon = ({ icon, text, className }) => {
    const classes = useStyles();
    const Icon = icon;
    return (

        <Grid item container direction={"row"} className={className}>
            <Grid item>
                <SkbIcon icon={Icon} />
            </Grid>
            <Grid>
                <Typography variant="body1">
                    {text}

                </Typography>
            </Grid>
        </Grid>
    );
}

const ReturnsDashboarddHeader = (props) => {
    const classes = useStyles();

   

    return (
        <Grid container className={classes.returnDashboardHeader} >
            <Grid xs={4} sm={4} md={4} item>
                {/* <TextWithIcon icon={FilterListIcon} text={""} className={classes.leftalign} /> */}
            </Grid>
            <Grid xs={4} sm={4} md={4} item >
                <Typography className={classes.centeralign} variant="h5"> Return Dashboard </Typography>
            </Grid>
            <Grid xs={4} sm={4} md={4} item>
                {/* <TextWithIcon icon={() => <SkbSortIcon sortDirection={SortDirection.Descent} />} text={"Start Date"} className={classes.rightalign} /> */}
            </Grid>
        </Grid> 
        
    );  
}

function ReturnsList(props) {
    const classes = useStyles();   
    let popRef =  useRef(null);
    const popRefTaskListCancelBtnComp =  useRef(null);
    const { enqueueSnackbar } = useSnackbar(); 
    const [shouldOpenConfirmForApproval, setShouldOpenConfirmForApproval] = useState(false);
    //const [approveButtonBeEnableFlag, setApproveButtonBeEnableFlag] = useState(true);
    const [bodyParamStateToApprove, setBodyParamToApprove] = useState(null); 
    const [taskIdsRelatedToApproval, setTaskIdsRelatedToApproval] = useState([]);
    const [confirmApprovalTitle, setConfirmApprovalTitle] = useState("");
    const [confirmApprovalMessage, setConfirmApprovalMessage] = useState("");
    const [approveSuccessMsgToDisplay, setApproveSuccessMsgToDisplay] = useState(""); 
    //   for scenario where task selected is having both status awaiting approval and other statuses 
    const [approveSuccessWarningMsgFlag, setApproveSuccessWarningMsgFlag] = useState("");  
    const [taskIdPopupObj,setTaskIdPopupObj]= useState({});
    const [cancelEligibleRowStateData, setCancelEligibleRowStateData] = useState([]);
   
    // Default status data to be preselected
    const defaultDisplStatusDataForReturnDashList = [
                                    "Awaiting approval",
                                    "Awaiting picking",
                                    "Picking",
                                    "Awaiting schedule",
                                    "Awaiting labels",
                                    "Awaiting pickup",
                                    "Awaiting delivery",
                                    "Awaiting drop-off"
                                ];

    const handleOkConfirmApproval = async () =>{

        //console.log("= bodyParamStateToApprove=====>>>>",bodyParamStateToApprove);
        let paramBodyToPassToAction = bodyParamStateToApprove!==undefined && bodyParamStateToApprove?bodyParamStateToApprove:null;
        // call async function to hit api 
        if(paramBodyToPassToAction){
            // setApproveButtonBeEnableFlag(true);
            paramBodyToPassToAction  = {"bodyParam":paramBodyToPassToAction};
            await props.callAsyncFuncToApproveTasksSelected(paramBodyToPassToAction);  
            setShouldOpenConfirmForApproval(false);     
        }            
        
    }
   

    const selectTaskFromList =(rows, currentRow) => {    

        const rowsSelAr = [];
        if(Array.isArray(rows) && rows.length>0){
            rows.map((eObj,indx)=>{
                let rowsSel = eObj && eObj.fullRawDataOfRow!==undefined ? eObj.fullRawDataOfRow: {};
                
                rowsSelAr.push(rowsSel);
            }); 
            //setApproveButtonBeEnableFlag(false);

        }
        else{
            //setApproveButtonBeEnableFlag(true);
        }        
        props.setTaskSelectedFromReturnsDashboardList(rowsSelAr);
        
    };
   
        
   
    const getAwaitingApprovalRowsIfPresent = (rows) =>{
        //console.log(" Inside=>>> getAwaitingApprovalRowsIfPresent",rows)
        //Awaiting approval
        let rowsFiltered = [];
        if(Array.isArray(rows) && rows.length>0){
             rowsFiltered = rows.filter((obj)=>{
                return obj.CompanySubTask.Status==="Awaiting approval"; //Awaiting approval Delivered
            });        
         
        return rowsFiltered;

        }

        return [];
    }


    const constructDataToApprove =  (rows) =>{       
        
        let dataToApprove = []; 
        

        if(rows!==undefined && Array.isArray(rows) && rows.length>0){
            rows.map((e,i)=>{

                dataToApprove.push(
                    {
                        "LocationCode":e.ConsignmentLocationCode,
                        "ConsignmentStatus":"Awaiting picking", //since  earliier it was Awaiting approval  so on approove iit will become Awaiting picking
                        "ActivityType":"Approve"                       
                    
                    }
                    
                    );
              

            });

        }

        return ({"Consignments":dataToApprove});


    }

    const construcTaskIdToRelatedToApprove =  (rows) =>{         

        let dataToApproveRelRaskIdAr = [];
        if(rows!==undefined && Array.isArray(rows) && rows.length>0){
            rows.map((e,i)=>{              
                dataToApproveRelRaskIdAr.push(e.CompanySubTask.Id);

            });

        }
        return (dataToApproveRelRaskIdAr);

    }

    const handleApprove = (e) =>{
        // reset bodyParamToApprove
        resetLocalTemporaryStateUsedForApproval();    
        const returnsListSelectedTask = props.returnsListSelectedTask;
        
        const awaitingApprovalRows =  getAwaitingApprovalRowsIfPresent(returnsListSelectedTask);        
        const paramBodyToApproveConstructed = (awaitingApprovalRows.length>0)?(constructDataToApprove(awaitingApprovalRows)):null;     
        const taskIdsRelatedToApproval = (awaitingApprovalRows.length>0)?(construcTaskIdToRelatedToApprove(awaitingApprovalRows)):[];

        if(returnsListSelectedTask.length==0){
            enqueueSnackbar('No task has been selected', { variant: 'info' });
            return false;
        }       
        else if(returnsListSelectedTask.length>0 && awaitingApprovalRows.length>0){

            setBodyParamToApprove(paramBodyToApproveConstructed);
            setTaskIdsRelatedToApproval(taskIdsRelatedToApproval);
             

            if(Array(taskIdsRelatedToApproval)){
                if(taskIdsRelatedToApproval.length==1){

                    setConfirmApprovalTitle("Approve task?");
                    setConfirmApprovalMessage(`Are you sure you wish to approve task ${taskIdsRelatedToApproval[0]}?`);
                    setApproveSuccessMsgToDisplay(`Successfully approved the return stock task ${taskIdsRelatedToApproval[0]}`);

                }
                else if(taskIdsRelatedToApproval.length>1){
                    setConfirmApprovalTitle("Approve task?"); 
                    setConfirmApprovalMessage(`Are you sure you wish to approve ${taskIdsRelatedToApproval.length} tasks?`);
                    setApproveSuccessMsgToDisplay(`Successfully approved ${taskIdsRelatedToApproval.length} return stock tasks`);
                }

                if(returnsListSelectedTask.length>0 && awaitingApprovalRows.length!==returnsListSelectedTask.length){
                    setApproveSuccessWarningMsgFlag(<>Please note that a number of tasks not “Awaiting approval” were not approved <br /> as they do not require approval in their current state.</>);
                }

                

                
            }


            setShouldOpenConfirmForApproval(true);
        }
        else if(returnsListSelectedTask.length>0 && awaitingApprovalRows.length===0){
            enqueueSnackbar('No Awaiting approval status task has been selected', { variant: 'info' });
            return false;
        }

    }    


    const getCancelEligibleRowsIfPresent = (rows) => {
        const allowedStatusForCancel =  ["Awaiting approval", "Awaiting picking", "Picking", "Awaiting schedule", "Awaiting labels", "Awaiting pickup", "Awaiting delivery", "Awaiting drop-off"];

        let rowsFiltered = [];
        if(Array.isArray(rows) && rows.length>0){
             rowsFiltered = rows.filter((obj)=>{
                return allowedStatusForCancel.indexOf(obj.CompanySubTask.Status)!=-1; 
            });   
            
        return rowsFiltered;

        }

        return [];

    }

    const triggerClickToOpenTaskListCancelPopup = function(){
        if(popRefTaskListCancelBtnComp && typeof(popRefTaskListCancelBtnComp)==="object" && popRefTaskListCancelBtnComp.current!==undefined && popRefTaskListCancelBtnComp.current){ 
            popRefTaskListCancelBtnComp.current.click();
        
        }
      }

    const handleCancel = (e) =>{
        //alert('cancel clicked');
        // set to local state of cancel to empty array
        resetLocalTemStateUsedInCancelFlow();

        const returnsListSelectedTask = props.returnsListSelectedTask;        
        const rowsEligibleForCancel =  getCancelEligibleRowsIfPresent(returnsListSelectedTask);
        //console.log("--11 rowsEligibleForCancel=>>>",rowsEligibleForCancel);     
       
         let rowAr = [];
        if(rowsEligibleForCancel.length>0){

            rowsEligibleForCancel.map((e)=>{
                let rowObj = {};
                rowObj["locationCode"] = (e.ConsignmentLocationCode!==undefined && e.ConsignmentLocationCode) ? e.ConsignmentLocationCode: "";
                rowObj["taskId"] = ( e && e.CompanySubTask && e.CompanySubTask.Id) ? e.CompanySubTask.Id: "";
                rowObj["contractingEntity"] = (e.Company && e.Company.Name) ? e.Company.Name: "";
                rowObj["contractorRepresentative"] = (e.Company && e.Company.Representative) ? e.Company.Representative: "";
                rowObj["stockLocation"] = (e.FromLocation && e.FromLocation.Name) ? e.FromLocation.Name: "";
                rowObj["cancellationReason"] = "";

                rowAr.push(rowObj);

            });
            //console.log("--33 rowAr=>>>",rowAr);  
            // set to local state
            setCancelEligibleRowStateData(rowAr);

            setTimeout(()=>{
                triggerClickToOpenTaskListCancelPopup();
            },2000);
            
        }else{

            if(returnsListSelectedTask && returnsListSelectedTask.length>0 && rowsEligibleForCancel && rowsEligibleForCancel.length===0 ){
                enqueueSnackbar("Please choose tasks having correct status to be cancelled.", { variant: 'warning' });
            }
            
        }

       
    }
    
    const taskIdLinkClick = (e,id,row)=>{            
            setTaskIdPopupObj({"taskId":row.taskId,"consignmentLocationCode":id});                      
            if(popRef && typeof(popRef)==="object" && popRef.current!==undefined && popRef.current){ popRef.current.click();
            
            }
    }
    //console.log('slaRanges raw props',props.returnStockSlaDays);
    //copy from PieChartWithChartjs
    const returnStockSlaDays=props.returnStockSlaDays;
    var slaRanges = [];
    if(returnStockSlaDays.length>0){
        for(let i=0; i<(returnStockSlaDays.length); i++){
  
          let variableName = (returnStockSlaDays[i].variableName)? returnStockSlaDays[i].variableName:"";
          let variableValue = (returnStockSlaDays[i].variableValue)? returnStockSlaDays[i].variableValue:"";
          let variableValueSplit = variableValue? variableValue.split("-"):[];      
          let minValData = variableValueSplit[0];
          let maxValData = variableValueSplit[variableValueSplit.length-1];     
          let rangeString = `${minValData} ${minValData>=0 && minValData<=1?"day":"days"} to ${maxValData} days`;
          //console.log('slaRanges one ',i,rangeString);
          slaRanges.push(rangeString);
        }; 
      }
    //console.log('slaRanges',slaRanges);
    const [selectedAgeRange,setSelectedAgeRange]= useState("");
    const allColumns =  [
        {title: 'Location Code', field: 'locationCode', type: 'hidden-id' }, //column with hidden-id will not display
        {title:"Task Id", field:"taskId", type: 'link', onClickLink:taskIdLinkClick },
        {title:"Contracting Entity", field:"contractingEntity", type: 'read-only-long-text'},
        {title:"Contractor Representative", field:"contractorRepresentative", type: 'read-only-long-text'},
         {title:"Stock Location", field:"stockLocation", type: 'read-only-long-text'},
         {title:"Type", field:"typeOfConsignment", type: 'read-only-long-text'},
         {title:"Creator", field:"creator", type: 'read-only-long-text'},
        {title:"Age of return", field:"ageOfReturn", type: 'read-only-long-text', 
            filterComponent: (props) => 
            <Select id='return-list-select-age-range' value='' displayEmpty onChange={(e) =>{
                    //customFilterValue=e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.id, e.target.value);}
            } 
            >
                <MenuItem value="" disabled>Select range</MenuItem>
                {
                slaRanges.map( oneMenu => (
                            <MenuItem value={oneMenu}>{oneMenu}</MenuItem>
                        )
                 )
                 }
                
            </Select>
            
            , 
            customFilterAndSearch: (term, row) => {
                const rowValueOrWrap=row['ageOfReturn'];
                    var strRowValue = null;
                    if((typeof rowValueOrWrap) === "object" 
                      && rowValueOrWrap.props.value){  //valueForFiltering has higher priority than children
                          strRowValue=rowValueOrWrap.props.value;
                    }else if((typeof rowValueOrWrap) === "object" 
                      && rowValueOrWrap.props.children){  //valueForFiltering has higher priority than children
                          strRowValue=rowValueOrWrap.props.children;
                    }else{
                          strRowValue=rowValueOrWrap;
                    }
                    console.log('customFilterAndSearch strRowValue',strRowValue);
      
                    if((!term || term==="") ){ //&& (!selectedAgeRange || selectedAgeRange==="")){
                      return true;
                    }else if (term==="0 day to 30 days" ){ //|| selectedAgeRange==="0 day to 30 days"){
                      if(parseFloat(strRowValue)<=30){
                        return true;
                      }else{
                        return false;
                      }
                    }else if (term==="31 days to 90 days" ){ //|| selectedAgeRange==="31 days to 90 days"){
                      if(parseFloat(strRowValue)>30 && parseFloat(strRowValue)<=90){
                        return true;
                      }else{
                        return false;
                      }
                    }else if (term==="91 days to 9999 days" ){ //|| selectedAgeRange==="91 days to 9999 days"){
                      if(parseFloat(strRowValue)>90){
                        return true;
                      }else{
                        return false;
                      }
                    }
              }
        },
        {title:"Status", field:"status", type: 'read-only-long-text'},
      ];
     
   
    const getDataFromList = async () =>{
       await  props.callAsyncFuncToFetchReturnStockSlaDays({"dispAsyncFuncToFetchRetListFlag":true});      
    }

    const resetReduxTemporaryStateUsedForApproval = () => {
         // reset temporary local and redeux state data
         props.setApproveApiSuccessReceiveStatusUnset();
         props.setTaskSelectedFromReturnsDashboardList([]);
        

    }

    const resetOtherReduxStateRelatedToComp = () =>{
        props.setReturnStockSlaDays([]);
        props.setStockReturnTaskList([]);
        props.setStockReturnTaskListModifiedToRender([]);
    }

    const resetLocalTemporaryStateUsedForApproval = () => {
        setBodyParamToApprove(null);
        setTaskIdsRelatedToApproval([]);
        setConfirmApprovalTitle("");
        setConfirmApprovalMessage("");
        setApproveSuccessMsgToDisplay("");
        setApproveSuccessWarningMsgFlag("");       

    }

    const resetLocalTemStateUsedInCancelFlow = () => {
        setCancelEligibleRowStateData([]);
    }


    const callFuncForOtherActivitiesAfterApproveSuccess = () =>{
        // trigger toaster message on success and unset
        enqueueSnackbar(`${approveSuccessMsgToDisplay}`, { variant: 'success' });
        if(approveSuccessWarningMsgFlag){
                    enqueueSnackbar(approveSuccessWarningMsgFlag, { variant: 'warning' });
                    // <>Please note that a number of tasks not “Awaiting approval” were not approved <br /> as they do not require approval in their current state.</>
        }
        resetReduxTemporaryStateUsedForApproval();
        resetLocalTemporaryStateUsedForApproval();  
        //setApproveButtonBeEnableFlag(true);        
    }

    const resetGridFilter = ()=>{
        // reset age of return related filter data in Skbtable         
        setSelectedAgeRange("");

        // reset status related filter data for status in Skbtable
        props.setReturnListStatusActiveFilterData(defaultDisplStatusDataForReturnDashList);


    }
     

    useEffect(()=>{       
        resetGridFilter();

        // will call async action 
        getDataFromList();

        return(()=>{
           
            resetOtherReduxStateRelatedToComp();
            resetReduxTemporaryStateUsedForApproval();
            resetLocalTemporaryStateUsedForApproval(); 
            resetGridFilter();

        });

        
    },[]);  


    useEffect(()=>{

        if(props.approveApiSuccessReceiveStatus===true){
            callFuncForOtherActivitiesAfterApproveSuccess();
        }
       

    }, [props.returnsListModified]);
    

    const handleSelectAgeOfReturnRange= (range) => {
        console.log('handleSelectAgeOfReturnRange range=',range);
        setSelectedAgeRange(range);
        console.log('handleSelectAgeOfReturnRange selectedAgeRange=',selectedAgeRange);
    }
                  
    //console.log('props.returnsListModified',props.returnsListModified);
    return (
        <>           
            
            <Grid   container className={classes.root}  direction='column' justify='space-around'  alignContent='flex-start' spacing={2}>
                <Grid item xs={12} sm={12} md={12}  data-testid="returnslisttasksheader">
                   
                    <Grid container >
                        <Grid  item xs={12} sm={12} md={10} zeroMinWidth  >                   
                               
                                 <Grid container spacing={2} align="Center">               
                                    <Grid  item xs={12} sm={12} md={1} zeroMinWidth >                   
                                        <Box ml={2} textAlign="center" >
                                         
                                        </Box>
                                    </Grid>   
                                    <Grid  item xs={12} sm={12} md={5} zeroMinWidth >                   
                                        <Box ml={2} textAlign="center" >
                                        <ChartByStatus />   
                                        </Box>
                                    </Grid>       
                                    <Grid  item xs={12} sm={12} md={5} zeroMinWidth >                   
                                        <Box ml={2} textAlign="center" >
                                        <ChartByAge selectAgeOfReturnRange={handleSelectAgeOfReturnRange} /> 
                                        </Box>

                                    </Grid>          
                                    <Grid  item xs={12} sm={12} md={1} zeroMinWidth  >   
                                        <Box  textAlign="center" mt={1} >                   
                                        <Typography >
                                            <SkbIcon onClick={resetGridFilter} style={{cursor:"pointer",width:'24px',height:'24px',verticalAlign:'middle'}}  icon={RestorePageIcon}/> 
                                            <Link  onClick={resetGridFilter} className={classes.reset} style={{cursor:"pointer"}} >
                                            Reset
                                            </Link>
                                    
                                        </Typography>
                                        </Box>   
                                    </Grid> 

                    
                                </Grid>

                        </Grid>
                        
                    </Grid>
                </Grid>               
               
                <Grid item xs={12} sm={12} md={12} data-testid="taskscontent"> 
                    <div className={classes.table}>
                            {/* since skbtable is not reflecting redux state data passed to data source so this way it has  been done */}            
                            { (props.returnsListModified.length===0) && (
                                                    <SkbTable   columns={allColumns} 
                                                    dataSource={props.returnsListModified} 
                            
                                                    id="returndash_datagrid"
                            
                                                    actions = {[
                                                        {                                       
                                                            type: 'select',
                                                            onClick: selectTaskFromList
                                                        }  ,
                                                        {type:'approve',onClick:handleApprove},
                                                        {type:'cancel',onClick:handleCancel}                      
                                                    ]}
                                                    
                                                    options={{
                                                        emptyDataSourceMessage:'There are no returns available to show!',                                            
                                                    
                                                    }
                                                    }
                            
                                                    filter={{individualFiltering:false, 
                                                        individualFilteringSettings:[
                                                            {column:"taskId",filteringType:"text"},
                                                            {column:"contractingEntity",filteringType:"text"},
                                                            {column:"contractorRepresentative",filteringType:"text"},
                                                            {column:"stockLocation",filteringType:"text"},
                                                            {column:"typeOfConsignment",filteringType:"text"},
                                                            {column:"creator",filteringType:"text"},
                                                            {column:"ageOfReturn",filteringType:"custom", preselects:selectedAgeRange},
                                                            
                                                            {column:"status",filteringType:"selection", preselects:props.returnsListStatusActiveFilterAr},
                                                        ]
                                                    }} 
                                                                    
                            
                                                    />
                            )}
                            { props.returnsListModified.length>0 && (
                                                    <SkbTable   columns={allColumns} 
                                                    dataSource={props.returnsListModified} 
                            
                                                    id="returndash_datagrid"
                            
                                                    actions = {[
                                                        {                                       
                                                            type: 'select',
                                                            onClick: selectTaskFromList
                                                        }             ,
                                                        {type:'approve',onClick:handleApprove},
                                                        {type:'cancel',onClick:handleCancel}                            
                                                    ]}
                                                    
                                                    options={{
                                                        emptyDataSourceMessage:'There are no returns available to show!',                                          
                                                    
                                                    }
                                                    }
                            
                                                    filter={{individualFiltering:true, 
                                                        individualFilteringSettings:[
                                                            {column:"taskId",filteringType:"text"},
                                                            {column:"contractingEntity",filteringType:"text"},
                                                            {column:"contractorRepresentative",filteringType:"text"},
                                                            {column:"stockLocation",filteringType:"text"},
                                                            {column:"typeOfConsignment",filteringType:"text"},
                                                            {column:"creator",filteringType:"text"},
                                                            {column:"ageOfReturn",filteringType:"custom", preselects:selectedAgeRange},
                                                            
                                                            {column:"status",filteringType:"selection", preselects:props.returnsListStatusActiveFilterAr},
                                                        ]
                                                    }}               
                            
                                                    />

                            )}    

                               
                    </div> 
               
                         
                        
                </Grid>               
                {/* <Grid                    
                item xs={12} sm={12} md={12}  
                    
                > 
                    <Box  textAlign="right" m={2} p={0}  >
                        <SkbButton disabled={approveButtonBeEnableFlag} variant="contained" id="approve-button" primary  text="Approve" size="medium" onClick={handleApprove}  />
                    </Box>                        
                </Grid> */}
            
            </Grid>
            
            <SkbConfirmDialog
            id="confirm-aprrove-approval"
            title={confirmApprovalTitle?confirmApprovalTitle:"Confirm Approval"}
            message={confirmApprovalMessage?confirmApprovalMessage:"Are you sure you want to approve?"}
            okButtonIsFirst={false}
            okLabel="Yes"
            cancelLabel="No"
            open={shouldOpenConfirmForApproval}
            openConfirm={setShouldOpenConfirmForApproval}
            okHandler={handleOkConfirmApproval}
            
            />
            

            { props.returnsListLoadStatus &&  (<Loading />)}
            <TaskDetailsPopup ref={popRef} returndashlisttaskinfopopupprop={taskIdPopupObj} />

            <ReturnsDashBoardListCancelPopup ref={popRefTaskListCancelBtnComp} allselectedrowswithbotheligibleandnoneligiblestatus={props.returnsListSelectedTask} canceleligiblerowdata={cancelEligibleRowStateData} />
            
        </>
    );

}


const mapStateToProps = state => {
        //console.log("@@@@@@@@===Returns  list component redux state=>>>>",state);
                return (
                    {
                        "returnsList": state.returnsDashboardList.returnsList,
                        "returnsListModified": state.returnsDashboardList.returnsListModified,
                        "returnsListLoadStatus": state.returnsDashboardList.returnsListLoadStatus,
                        "returnsListSelectedTask": state.returnsDashboardList.returnsListSelectedTask,
                        "approveApiSuccessReceiveStatus": state.returnsDashboardList.approveApiSuccessReceiveStatus,
                        "returnsListStatusActiveFilterAr": state.returnsDashboardList.returnsListStatusActiveFilterAr,
                        "pieChartSelectedAgeOfRetColor":state.returnsDashboardList.pieChartSelectedAgeOfRetColor,
                        "returnStockSlaDays":state.returnStockSlaDays.slaDaysList,

                

                }
        );
} 

const mapDispatchToProps = (dispatch) => {
    return { 
           
            callAsyncFuncToFetchReturnStockSlaDays : (options) =>{                  
                
                dispatch(returnsDashboardListActions.callAsyncFuncToFetchReturnStockSlaDays(options));
            },

            setTaskSelectedFromReturnsDashboardList : (list) =>{           
                
                dispatch(returnsDashboardListActions.setTaskSelectedFromReturnsDashboardList(list));
            },

            callAsyncFuncToApproveTasksSelected: (paramObj) => {
                 dispatch(returnsDashboardListActions.callAsyncFuncToApproveTasksSelected(paramObj));
            },
            setApproveApiSuccessReceiveStatusUnset: () => {
                 dispatch(returnsDashboardListActions.setApproveApiSuccessReceiveStatusUnset());
            },
            setReturnStockSlaDays: () => {
                dispatch(returnsDashboardListActions.setReturnStockSlaDays([]));
            },
            setStockReturnTaskList: () => {
                dispatch(returnsDashboardListActions.setStockReturnTaskList([]));
                
            },
            setStockReturnTaskListModifiedToRender: () => {                
                dispatch(returnsDashboardListActions.setStockReturnTaskListModifiedToRender([]));  
            },
            setReturnListStatusActiveFilterData: (list) => {
                dispatch(returnsDashboardListActions.setReturnListStatusActiveFilterData(list));
            }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReturnsList)