/*
This "husk" is designed as a holder for a component which was previously connected to Redux and now is not.  The husk provides all the Redux functionality that the
component previously had, as a replacement for previous uses of the component (maintaining the functionality), as an example for future use and as a test harness 
for the disconnected component.

When other pages start using the disconnected component this husk can initially be an example of how to use it.  I expect the husk will be phased out once the disconnected
component is in real use.  

*/

import React from 'react';

import { SkbLogger, SeverityLevel } from '../../../services';
import { connect } from 'react-redux';

import * as taskActions from '../../../Redux/actions/tasks';

import { getStepFromCurrentSubTaskByStepId } from '../../../Redux/actions/tasks';

import { useSnackbar } from 'notistack';

import { Status } from '../../../Redux/actions/tasks';
import { StocktakeSteps } from '../Stocktake';

import { TaskLogInfo } from '../../../utils/misc';

import _ from 'lodash';

import StepNonSerialisedItems from './StepNonSerialisedItems';

//Some constants for logging:
const moduleName = TaskLogInfo.moduleName;
const stepName = TaskLogInfo.category.nonSerialisedStock;

export function StepNonSerialisedItemsHusk(props) {

  const { enqueueSnackbar } = useSnackbar();

  function updateNonSerialisedItem(item) {
    props.updateNonSnItem(props.allTasks, item);

  }

  function addNonSerialisedItem(newItem)
  {
    props.addNonSnItem(props.allTasks, newItem);

      enqueueSnackbar('Successfully added ' + newItem.Description + '.', { variant: 'success' });
  }

  // function addNonSerialisedItem(newItem)
  // {
  //   props.addNonSnItem(props.allTasks, newItem);

  //   SkbLogger.userAuditEvent(moduleName, misc.getCurrentUserEmail(), stepName, 'Add non-serialised item', misc.LogResult.success,
  //     '{userObject} has added {stockObject} at {locationObject} while {networkObject}.'
  //     , {
  //       userObject: misc.getUserObject(),
  //       stockObject: misc.buildStockObjectForLog('Non-Serialised', newItem.StockCode, newItem.Description),
  //       locationObject: misc.getLocationObject(),
  //       networkObject: misc.getNetworkObject()
  //     });

  //     props.setLastSelectedStockCode(newItem.StockCode);
  
  //     setStepStatusOnChange();
  
  //     enqueueSnackbar('Successfully added ' + newItem.Description + '.', { variant: 'success' });
  // }


//   var expectedItems= [
                
//     {
//       StockCode: "UNKNOWN",
//       Description: "To Be Determined",
//       StockType: "Serialised",
//       Condition: "Good",
//       Qty: 5,
//       QtyUnit: "Each",
//   },
//     {
//       StockCode: "ERMT0001",
//       Description: "Ericsson LTSS Universal Flexi Mount 80cm- FB607259",
//       StockType: "Non-Serialised",
//       Condition: "Good",
//       Qty: 99,
//       QtyUnit: "Each",
//   },
//   {
//       StockCode: "ERMT0001",
//       Description: "Ericsson LTSS Universal Flexi Mount 80cm- FB607259",
//       StockType: "Non-Serialised",
//       Condition: "Faulty",
//       Qty: 30,
//       QtyUnit: "Each",
//   },
// {
//       StockCode: "ERMI0003",
//       Description: "Ericsson LTSS RG6 Outdoor Cable - P6CQV7RM",
//       StockType: "Non-Serialised",
//       Condition: "Good",
//       Qty: 5,
//       QtyUnit: "Metre",
//   },
//   {
//     StockCode: "ABC",
//     Description: "Item X",
//     StockType: "Non-Serialised",
//     Condition: "Good",
//     Qty: 20,
//     QtyUnit: "Metre",
// },
// {
//   StockCode: "QRX",
//   Description: "Item Q",
//   StockType: "Non-Serialised",
//   Condition: "Good",
//   Qty: 0,
//   QtyUnit: "Metre",
// },
// {
//   StockCode: "A0001",
//   Description: "Item A",
//   StockType: "Non-Serialised",
//   Condition: "Good",
//   Qty: 0,
//   QtyUnit: "Metre",
// }
// ]
// ;


  function setStepStatusOnChange() {
    var currentStepStatus = getStepFromCurrentSubTaskByStepId(props.allTasks, props.currentSubTask.CurrentStep.StepId).Status;
    //debugger;
    var msg = "";
    var syncIsOn = false;

    if (currentStepStatus === Status.NotStarted) {
      msg = "User has started editing the page.  Status changing to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, props.currentSubTask.CurrentStep.StepId, Status.InProgress);

    }
    else if (currentStepStatus === Status.Done) {
      msg = "User is editing the page again after complete.  Status changing back to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, props.currentSubTask.CurrentStep.StepId, Status.InProgress);
    }

    //Non-serialised step being edited moves Review step back, if already Done:  
    var reviewStepStatus = getStepFromCurrentSubTaskByStepId(props.allTasks, StocktakeSteps.REVIEW).Status;

    if (reviewStepStatus === Status.Done) {
      msg = "User is editing the non-serialised page again after complete.  The Review step status is therefore changing back to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, StocktakeSteps.REVIEW, Status.InProgress);
    }

    //Same for signature step
    var signatureStepStatus = getStepFromCurrentSubTaskByStepId(props.allTasks, StocktakeSteps.SIGNATURE).Status;

    if (signatureStepStatus === Status.Done) {
      msg = "User is editing the Non-serialised page again after complete.  The Signature step status is therefore changing back to In Progress.";
      SkbLogger.applicationTrace(moduleName, SeverityLevel.Info, stepName, msg, props.currentSubTask);

      props.updateTaskStep(props.allTasks, StocktakeSteps.SIGNATURE, Status.InProgress);

      //Reset signature
      if(props.sync.isOnline && (props.sync.isPhotoSyncing || props.sync.isMetadataSyncing)) 
        {
            syncIsOn=true
        } else {
            syncIsOn=false;
        }
      props.clearSignature(props.allTasks, props.currentSubTask,syncIsOn);
    }


  }

  return (<StepNonSerialisedItems
    nonSerialisedItems={props.currentSubTask.NonSerialisedItems} expectedItems={props.currentSubTask.PreviewInfo} 
    loadNonSerialisedItems={props.loadCurrentTaskNonSnItems} loadExpectedItems={props.loadPreviewData}
    addNonSerialisedItem={addNonSerialisedItem} updateNonSerialisedItem={updateNonSerialisedItem}
    onChange={setStepStatusOnChange}
    lastSelectedStockCode={props.currentSubTask.LastSelectedStockCode} setLastSelectedStockCode={props.setLastSelectedStockCode}
    isLoading={props.allTasks.isLoading}
    emptyDataMessage={'No results have been recorded so far!'}

     taskObjectForLogging={props.currentSubTask} moduleName={TaskLogInfo.moduleName} stepName={TaskLogInfo.category.nonSerialisedStock}

    // columnHeaders={columnHeaders}

/>);
}


const mapStateToProps = state => ({
  //user: state.auth,
  sync:state.sync,
  currentTask: taskActions.getCurrentTask(state.tasks),
  currentSubTask: taskActions.getCurrentSubTask(state.tasks),
  allTasks: state.tasks
});

const mapDispatchToProps = (dispatch) => {
  return {

    updateNonSnItem: (allTasks, item) => dispatch(taskActions.updateNonSnItem(allTasks, item)),
    addNonSnItem: (allTasks, item) => dispatch(taskActions.addNonSnItem(allTasks, item)),
    setLastSelectedStockCode: (stockCode) => dispatch(taskActions.updateLastSelectedStockCode(stockCode)),
    updateTaskStep: (allTasks, stepId, status) => dispatch(taskActions.updateTaskStep(allTasks, stepId, status)),

    // deleteNonSnItem: (allTasks, item) => dispatch(taskActions.deleteNonSnItem(allTasks, item))
    // ,
    clearSignature: (allTasks, currentSubTask) => {
      let sig = { ...currentSubTask.SignoffSig, Sig: null, SignoffBy: "" };
      dispatch(taskActions.updateSignoffSig(allTasks, sig));
    },
    loadCurrentTaskNonSnItems: () => dispatch(taskActions.loadCurrentTaskNonSnItems(false)),
    loadPreviewData: () => dispatch(taskActions.loadCurrentTaskPreviewInfo(false)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepNonSerialisedItemsHusk);
