import { Actions } from '../actions/callForAssistance';

let initialStatus = { phoneNumbers: []};

export function callForAssistance(state = initialStatus, action) {
    switch (action.type) {
        case Actions.UPDATE_CALLASSISTANCE_NUMBERS:
            return { ...state, phoneNumbers: action.payload};
        default:
            return state;
    }
}