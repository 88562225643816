import * as tasks from '../../Redux/actions/tasksv2';
import { stockConsignment } from '../../Redux/reducers/stockConsignment';
import { getTaskById, getSubTaskById } from '../../Redux/reducers/tasksV2';

import { currentTimeToUTCTimeInString, StringFormat } from '../misc';
import * as sampleData from '../../Redux/actions/sampleTaskDataForPickingAndPacking';


export const PickingAndPackingStepNames = {
  PREVIEW:"Preview",
  SERIALISED:"Serialised",
  NON_SERIALISED:"Non-Serialised",
  REVIEW:"Review",
  PACKAGE:"Package details"
}

export const StepStatus=
{
  COMPLETE:"Complete",
  INCOMPLETE: "InComplete"
  
}


const _validationSuccess = { isValid: true };

function makeErrorMessage(isValid, severity, message, title, display) {
  return {
    isValid: isValid,
    validationResult: {
      severity: severity,  //'warning' or 'error'
      message: message,
      title: title,
      display: display //'dialog' or 'toast'
    }
  }
}

export const validateSerialised = (stockConsignment) => {
  let currentStockCodes = stockConsignment.SerialisedItems.map(x => x.StockCode);
  let missingStockItems = stockConsignment.PreviewInfo.filter(p => p.StockType === 'Serialised' && !currentStockCodes.includes(p.StockCode));
  let msg = '';

  let summaries = currentStockCodes.map((scode) => {
    return {
      StockCode: scode,
      GoodQty: stockConsignment.SerialisedItems.filter(i => i.StockCode === scode && i.ItemStatus === 'Good').reduce((t, c) => t + 1, 0),
      FaultyQty: stockConsignment.SerialisedItems.filter(i => i.StockCode === scode && i.ItemStatus === 'Faulty').reduce((t, c) => t + 1, 0),
    }
  }) || [];


  let variance = summaries.some(i =>
    i.GoodQty != getQtyByStocktypeAndCondition(stockConsignment.PreviewInfo, i.StockCode, true, true)
    || i.FaultyQty != getQtyByStocktypeAndCondition(stockConsignment.PreviewInfo, i.StockCode, true, false))


  if (variance || missingStockItems.length > 0) {
    msg = 'Please note that the number of serialised stock does not match our records. Please review in step 4.';
    return makeErrorMessage(false, 'warning', msg, "Additional Items", 'toast');
  } else {
    return _validationSuccess;
  }

}


/**
 * 
 * @param {*} previewItems  sample: 
 * [
 * {
                            StockCode: "EROD0001",
                            Description: "Ericsson Wireless Outdoor Unit",
                            StockType: "Serialised",
                            Condition: "Good",
                            Qty: 20,
                            QtyUnit: "Each",
                        },
                      {
                            StockCode: "ERMI0001",
                            Description: "Ericsson Wireless Wall Plate Kit",
                            StockType: "Non-Serialised",
                            Condition: "Faulty",
                            Qty: 30,
                            QtyUnit: "Each",
                        }]
 * @param {*} isSerialised 
 * @param {*} isConditionGood 
 * @returns 
 */
export function getQtyByStocktypeAndCondition(previewItems: [], stockCode: string, isSerialised: Boolean, isConditionGood: Boolean) {
  if (!Array.isArray(previewItems) || previewItems.length === 0) return 0;

  const stockType = isSerialised ? "Serialised" : "Non-Serialised";
  const condition = isConditionGood ? "Good" : "Faulty";

  const items = previewItems.filter(i => i.StockCode === stockCode && i.StockType === stockType && i.Condition === condition);
  if (items.length === 0) return 0;
  if (items.length === 1) return items[0].Qty;
  var qty = items.reduce((f, n) => parseInt(f.Qty) + parseInt(n.Qty));

  return qty;


}

export const validateNonSerialised = (stockConsignment) => {
  let currentStockCodes = stockConsignment.NonSerialisedItems.filter(i => (i.GoodQty !== 0 || i.FaultyQty !== 0)) //Only count them if they have a quantity recorded ("0" and 0 are regarded as 0).
    .map(x => x.StockCode);
  let missingStockItems = stockConsignment.PreviewInfo.filter(p => p.StockType === 'Non-Serialised' && !currentStockCodes.includes(p.StockCode));


  let variance = stockConsignment.NonSerialisedItems.some(i =>
    i.GoodQty != getQtyByStocktypeAndCondition(stockConsignment.PreviewInfo, i.StockCode, false, true)
    || i.FaultyQty != getQtyByStocktypeAndCondition(stockConsignment.PreviewInfo, i.StockCode, false, false))

  let msg = '';
  if (variance || missingStockItems.length > 0) {
    msg = 'Please note that the number of non-serialised stock does not match our records. Please review in step 4.';
    return makeErrorMessage(false, 'warning', msg, "Additional Items", 'toast');
  } else {
    return _validationSuccess;
  }
}

export const validateReview = (stockConsignment) => {
  if (!validateSerialised(stockConsignment).isValid || !validateNonSerialised(stockConsignment).isValid) {
    let msg = 'Please note that the number of stock items does not match our records. Do you want to proceed?';
    return makeErrorMessage(false, 'warning', msg, "Confirm variance?", 'dialog');
  }

  return _validationSuccess;
}

export const validatePackage = (stockConsignment) => {
  let msg = "";
  if ((stockConsignment.DeliveryMethod === "Skybridge courier" && (!stockConsignment.PickupDetails || !stockConsignment.PickupDetails.PickupWindow || !stockConsignment.PickupDetails.PickupWindow.Date || !stockConsignment.PickupDetails.PickupWindow.ETA || !Array.isArray(stockConsignment.PackageDetails) || stockConsignment.PackageDetails.length === 0))
    || (stockConsignment.DeliveryMethod === "Contractor drop-off" &&
      (!stockConsignment.DropOffDetails || !stockConsignment.DropOffDetails.DropOffWindow || !stockConsignment.DropOffDetails.DropOffWindow.Date || !stockConsignment.DropOffDetails.DropOffWindow.ETA || !Array.isArray(stockConsignment.PackageDetails) || stockConsignment.PackageDetails.length === 0))
  )
  
   {
  msg = 'Error submitting packaging details. Please enter the package details of the cartons or pallets you have packed and specify a date and time.';
  return makeErrorMessage(false, 'error', msg, "Additional Items", 'toast');
}


  if(
  stockConsignment.StepsStatus[PickingAndPackingStepNames.PREVIEW]!==StepStatus.COMPLETE
    ||stockConsignment.StepsStatus[PickingAndPackingStepNames.SERIALISED]!==StepStatus.COMPLETE
      ||stockConsignment.StepsStatus[PickingAndPackingStepNames.NON_SERIALISED]!==StepStatus.COMPLETE
        ||stockConsignment.StepsStatus[PickingAndPackingStepNames.REVIEW]!==StepStatus.COMPLETE)
      
  {  
      msg=
      'You haven\'t fully completed all the previous steps.  Please complete all steps before submitting task.';
      return makeErrorMessage(false, 'error', msg, 'Completion' , 'toast' );
  }

// it's last step, it needs to validate the variance as well
return validateReview(stockConsignment);

}


//expecteditem is the reviewitem
export const loadExpectedItems = (dispatch, task, stockConsignment) => {
  loadPreviewData(dispatch, task, stockConsignment);
}

export const setOpenReschedule=(dispatch,open)=>{
   dispatch(tasks.openConsignmentReschedule(open));
}

export const getExpectedItems = (stockConsignment) => {
  return getPreviewInfo(stockConsignment);
}

export const loadPreviewData = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;

  dispatch(tasks.loadConsignmentTaskPreviewItems(taskId, subtaskId));
}
export const getPreviewInfo = (stockConsignment) => {

  return stockConsignment.PreviewInfo;
}

export const getSerialisedItems = (stockConsignment) => {

  return stockConsignment.SerialisedItems;

}
export const loadSerialisedItems = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;

  dispatch(tasks.loadConsignmentTaskSnItems(taskId, subtaskId));
  //if avaialbe sn doesn't exist, load it 
  if (!stockConsignment.ExistingAvailableSNs || stockConsignment.ExistingAvailableSNs.length === 0)
    dispatch(tasks.loadConsignmentTaskAvailableSn(taskId, subtaskId));

}

export const getKnownSerialNumbers = (stockConsignment) => {

  return stockConsignment.ExistingAvailableSNs;

}

export const addSerialisedItem = (dispatch, task, stockConsignment, item) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.addConsignmentSnItem(taskId, subtaskId, item));
}

export const updateSerialisedItem = (dispatch, task, stockConsignment, item) => {
  let taskId = task.TaskId;
  //let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateConsignmentSnItem(taskId, stockConsignment, item));
}
export const deleteSerialisedItem = (dispatch, task, stockConsignment, item) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.deleteConsignmentSnItem(taskId, subtaskId, item));
}
// export const serialisedChangeHandler = (dispatch, stockConsignment) => {
//   //TODO
// }

export const getNonSerialisedItems = (stockConsignment) => {
  return stockConsignment.NonSerialisedItems;
}
export const loadNonSerialisedItems = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.loadConsignmentTaskNonSnItems(taskId, subtaskId));
}

export const addNonSerialisedItem = (dispatch, task, stockConsignment, item) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.addConsignmentNonSnItem(taskId, subtaskId, item));
}

export const updateNonSerialisedItem = (dispatch, task, stockConsignment, item) => {
  let taskId = task.TaskId;
  //let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateConsignmentNonSnItem(taskId, stockConsignment, item));
}

// export const nonSerialisedChangeHandler = (dispatch, stockConsignment) => {

//   //TODO
// }

export const getPickUpDeatails = (stockConsignment) => {
  return stockConsignment.PickupDetails;
}

export const loadPickupDetails = (dispatch, task, stockConsignment) => {

  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.loadConsignmentTaskPickupDetails(taskId, subtaskId));
}

export const loadDropOffDetails = (dispatch, task, stockConsignment) => {

  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.loadConsignmentTaskDropOffDetails(taskId, subtaskId));
}

export const updateConsignmentDropOffDetails = (dispatch, task, stockConsignment, dropOffDetails) => {

  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateConsignmentDropOffDetails(taskId, subtaskId, dropOffDetails));
}


export const getPackageDetails = (stockConsignment) => {
  return stockConsignment.PackageDetails || [];
}

export const loadPackageDetails = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.loadConsignmentTaskPackageDetails(taskId, subtaskId));
}

export const loadLabelDetails = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.loadConsignmentTaskLabelDetails(taskId, subtaskId));
}

export const addPackage = (dispatch, task, stockConsignment, pack,disableTrafficControl=false) => {
  let taskId = task.TaskId;
  console.log('get into addPackage picking and pacing',pack)
  //let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.addConsignmentPackage(taskId, stockConsignment, pack,disableTrafficControl));
}

export const updatePackage = (dispatch, task, stockConsignment, pack,disableTrafficControl=false) => {
  let taskId = task.TaskId;
  //let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateConsignmentPackage(taskId, stockConsignment, pack,disableTrafficControl));
}

export const deletePackage = (dispatch, task, stockConsignment, pack,disableTrafficControl=false) => {
  let taskId = task.TaskId;
  //let subtaskId = stockConsignment.SubTaskId;

  dispatch(tasks.deleteConsignmentPackage(taskId, stockConsignment, pack,disableTrafficControl));
}



export const updatePickupDetails = (dispatch, task, stockConsignment, pickupDetails) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  console.log('updatePickupDetailsupdatePickupDetails',task, stockConsignment, pickupDetails)
  dispatch(tasks.updateConsignmentPickupDetails(taskId, subtaskId, pickupDetails));
}


export const submitConsignments = (dispatch, task, stockConsignment, user) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  const taskData = {
    TaskStatus: "Awaiting schedule",
    ModifiedBy: user.name,
    ModifiedDate: currentTimeToUTCTimeInString(StringFormat.TaskDateFormat),
    NextActionBy: "Skybridge"
  };
  dispatch(tasks.modifyConsignmentTask(taskId, subtaskId, taskData));
}

/* export const sendPackage = (dispatch, task, stockConsignment, user) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  const taskData = {
    TaskStatus: "Awaiting delivery",
    ModifiedBy: user.name,
    ModifiedDate: currentTimeToUTCTimeInString(StringFormat.TaskDateFormat),
    NextActionBy: "Skybridge"
  };
  dispatch(tasks.modifyConsignmentTask(taskId, subtaskId, taskData));
} */

/**
 * 
 * @param {*} newStatusDetails sample:
 *  
if cancelled
       {
            newStatus: "Cancelled",
            nextActionBy: "",
            cancellationReason:"cancel order"
        },
else
    {
           newStatus: "Picking", 
            nextActionBy: "Contractor",
        },

*/

export const updateConsignmentStatus = (dispatch, task, stockConsignment, user, newStatusDetails) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  const taskData = {
    TaskStatus: newStatusDetails.newStatus,
    ModifiedBy: user.name,
    ModifiedDate: currentTimeToUTCTimeInString(StringFormat.TaskDateFormat),
    NextActionBy: newStatusDetails.nextActionBy
  };
  dispatch(tasks.modifyConsignmentTask(taskId, subtaskId, taskData));
  if (newStatusDetails.newStatus === "Cancelled") {
    dispatch(tasks.updateCacnellationReason(taskId, stockConsignment, newStatusDetails.cancellationReason))
  }
}

export const updateTaskLockedBy = (dispatch, task, stockConsignment, lockedBy) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateTaskLockedBy(taskId, subtaskId, lockedBy))
}


export const getTask = (tasks, taskId) => {
  return getTaskById(tasks, taskId);
}

export const getConsignment = (tasks, taskId, consignmentId) => {
  return getSubTaskById(tasks, taskId, consignmentId);
}

export const commitConsignmentSnItems = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.commitConsignmentSnItems(taskId, subtaskId, stockConsignment.SerialisedItems));
}

export const commitConsignmentNonSnItems = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.commitConsignmentNonSnItems(taskId, subtaskId, stockConsignment.NonSerialisedItems));
}

export const commitConsignmentPackages = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.commitConsignmentPackages(taskId, subtaskId, stockConsignment.PackageDetails));
}

export const updateTaskStepStatus = (dispatch, task, stockConsignment, stepName, stepStatus) => {
  let taskId = task.TaskId;
  //let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateTaskStepStatus(taskId, stockConsignment, stepName, stepStatus));
}

export const loadTaskStepStatus = (dispatch, task, stockConsignment) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.loadTaskStepStatus(taskId, subtaskId));
}

export const getDropOffDetails = (stockConsignment) => {

  if (!stockConsignment || !stockConsignment.DropOffDetails) return {};

  return stockConsignment.DropOffDetails.DropOffWindow || {};

}

export const updateDropOffDetails = (dispatch, task, stockConsignment, dropOffDetails) => {
  let taskId = task.TaskId;
  let subtaskId = stockConsignment.SubTaskId;
  dispatch(tasks.updateConsignmentDropOffDetails(taskId, subtaskId, { DropOffWindow: dropOffDetails }));
}

export const getDeliveryDestination = (stockConsignment) => {

  if (!stockConsignment) return null;

  return {
    DeliveryDestination: stockConsignment.DeliveryDestination,
    DeliveryAddressLine1: stockConsignment.DeliveryAddressLine1,
    DeliveryAddressLine2: stockConsignment.DeliveryAddressLine2,
    DeliveryAddressSuburb: stockConsignment.DeliveryAddressSuburb,
    DeliveryAddressState: stockConsignment.DeliveryAddressState,
    DeliveryAddressPostcode: stockConsignment.DeliveryAddressPostcode,
  };

}

export const getDefaultPickupDate = (stockConsignment) => {

  if (!stockConsignment) return null;

  return stockConsignment.PickupDueday;
}

export const getDefaultDeliveryDate = (stockConsignment) => {

  if (!stockConsignment) return null;

  return stockConsignment.ReturnDueDay || stockConsignment.ReturnDueDate;
}

