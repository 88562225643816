import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';
// import InboxIcon from '@material-ui/icons/Inbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
import Typography from '@material-ui/core/Typography';
// import { Route, MemoryRouter } from 'react-router';


import { Link as RouterLink, BrowserRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 4,
        paddingRight: 4,
        alignItems: 'center'

    }
    ,
    icon: {
        // "& > svg":{
        //     color: theme.palette.primary.main,
        // },
        minWidth: theme.spacing(1),
        // marginRight: 4,
        marginTop: 4,
        fontSize: "1.75em",
        // [theme.breakpoints.down("xs")]: {
        //     fontSize: theme.typography.h5.fontSize,
        // },
        
        // [theme.breakpoints.up("xl")]: {
        //     fontSize: "1.75em",
        // }
    },
    text:{
        marginLeft: 5,
    }
}));
/**
 * 
 * @property {icon}: material ui icons for the menuitem, optional 
 * @property {text}: menu item text
 * @property {to}: the path of router 
 * @property {clickIcon}: iconclick handler, optional
 */
export function SkbMenuItem({ icon, text, to, clickIcon, changeAppTitle, ...other }) {
    const classes = useStyles();
    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    const fieldId = (text || "").toLowerCase().replace(' ', '-') + '-menu-item';

    return (
        <ListItem id={fieldId} className={classes.root} button component={renderLink} {...other} onClick={() => changeAppTitle(text)}>
            {icon ? <ListItemIcon className={classes.icon} onClick={clickIcon}>{icon}</ListItemIcon> : null}
            <ListItemText primary={<Typography className={classes.text} variant="body1"> {text}</Typography>} />
        </ListItem>

    );
}

SkbMenuItem.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};