import React, {useEffect} from 'react';
import '../PieChart.styles.css';
import './ChartByAge.styles.css';
import {connect} from 'react-redux';
import {
    Pie,
    
} from 'react-chartjs-2';

import { Grid, Toolbar, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';


// import {
//     selectAgePoints,
// } from '../../../../../../Redux/selectors/ReturnsDashboard';

import * as  returnsDashboardListActions from '../../../../../../Redux/actions/returnsdashboard';


const ChartByAge = ( { 
    pieChartRelAgeOfRetRangeDataAr, pieAgeOfRetRangeColorCodeAr, pieChartAgeOfRetGroupedCountDataAr,pieChartAgeOfRetRangeWithCountDataGrouped, selectAgeOfReturnRange, setPieChartOnAgeOfReturnRelatedDataSet  } )=>{
    const [windowSize, setWindowSize] = React.useState({height:window.innerHeight,width:window.innerWidth});
    //console.log('windowSize',windowSize);    
    const pieLegendLabelClick =   function(event,legendItem) {
       
            let filtSetAr = [];
            if(legendItem!==undefined && legendItem.text!==undefined && legendItem.text){                  
                selectAgeOfReturnRange(legendItem.text);
            }
           
        }
    
    const pieSegmentClick =   function(event,item) {
           
            const dataIndex = Array.isArray(item) && item.length>0 && item[0].element!==undefined && item[0].element.$context!==undefined && item[0].element.$context.dataIndex!==undefined? item[0].element.$context.dataIndex: null;
            let filtSetAr = [];
            if(dataIndex!==null){  
                selectAgeOfReturnRange(pieChartRelAgeOfRetRangeDataAr[dataIndex]);
            }
            
            
        }
    
  
    const chartData = {
        labels : pieChartRelAgeOfRetRangeDataAr,
        datasets : [
            {
                label : pieChartRelAgeOfRetRangeDataAr,
                data : pieChartAgeOfRetGroupedCountDataAr,
                backgroundColor : pieAgeOfRetRangeColorCodeAr,
                borderWidth : 2,
                borderColor : 'white'
            }
        ]
    }
    
    const options = {
        events : ['click'],
        onClick: pieSegmentClick,
        responsive : true,
        plugins : {
            legend : {
                display : true,
                position : 'bottom',
                onClick: pieLegendLabelClick,
                labels: {
                    color: "#3A3A3A",
                    // font : {
                    //     size : 10,                       
                    //     weight : 'normal',
                    // },
                }
                
            },
            title : {
                text : 'Returns by Age',
                align : 'center',
                display : true,
                font : {
                    size : 22,
                    family  : 'Roboto',
                    weight : 'normal',
                    
                },
                color : '#3A3A3A',
            },
            tooltip:{
                enabled : true,
                caretSize : 10,
            }
        },
        responsive: true,
        maintainAspectRatio: false
    }

    const handleResize = () => {
        //re-render
        setWindowSize({
          height: window.innerHeight,
          width:window.innerWidth
        });
        //console.log('windowSize',windowSize);
         
      }
      
    useEffect(()=>{
       window.addEventListener('resize', handleResize);
       window.addEventListener('orientationchange', handleResize);

        return (()=>{
            // unset redux state data related to it
            setPieChartOnAgeOfReturnRelatedDataSet({});
        });
    },[]);

    return(
        // <div className="pie-chart " 
        // style={{transform:'scale(0.6)'}}
        // >
        //     <Pie 
        //         id="chart-by-age"
        //         height={100}
        //         width={100}
        //         data={chartData}
        //         options={options}
        //     />
        // </div>

            <Grid container spacing={2} alignItems="center">

            <Grid  item xs={12} sm={12} md={12} zeroMinWidth >                   
                <Box ml={2} align="center" textAlign="left" height={(windowSize.height*0.4)+'px'}>
                <Pie        align="center"
                            id="chart-by-age"
                            height={'200px'}
                            width={'200px'}
                            data={chartData}
                            options={options}
                        />
                </Box>
            </Grid>
            </Grid>


    )
}


const mapState = state =>({
    pieChartRelAgeOfRetRangeDataAr: state.returnsDashboardList.pieChartRelAgeOfRetRangeDataAr,
    pieAgeOfRetRangeColorCodeAr: state.returnsDashboardList.pieAgeOfRetRangeColorCodeAr,
    pieChartAgeOfRetGroupedCountDataAr: state.returnsDashboardList.pieChartAgeOfRetGroupedCountDataAr,
    pieChartAgeOfRetRangeWithCountDataGrouped: state.returnsDashboardList.pieChartAgeOfRetRangeWithCountDataGrouped,
});

const mapDispatchToProps  = (dispatch) =>{
    return {
       
        setPieChartOnAgeOfReturnRelatedDataSet: (objParam) =>{
            dispatch(returnsDashboardListActions.setPieChartOnAgeOfReturnRelatedDataSet(objParam));
        }
}

};


export default connect(
    mapState,
    mapDispatchToProps,
)(ChartByAge);