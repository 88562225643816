import React, { useContext } from 'react';
import { SkbLayout } from './skb_controls/navigation/SkbLayout';
import { SnackbarProvider } from 'notistack';

import RegistrationWithTheme from './components/registration/RegistrationWithTheme'
import * as auth from './Redux/actions/auth';
import * as callForAssistance from './Redux/actions/callForAssistance';
import * as syncConfig from './Redux/actions/syncConfig';
import { connect } from 'react-redux';
//import { useAuth0 } from "@auth0/auth0-react";
import Loading from './components/Loading';
import { SkbLogger, SeverityLevel } from './services';
import { ModuleCreator } from './components/modules/ModuleCreator';
import { SkbMenuItem } from './skb_controls/navigation/SkbMenuItem';
import { crypts } from './utils/crypts';
import SkbIcon from './skb_controls/components/SkbIcon';

import AssignmentIcon from '@material-ui/icons/Assignment';
import GridOnIcon from '@material-ui/icons/GridOn';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';
import HomeIcon from '@material-ui/icons/Home';
import InputIcon from '@material-ui/icons/Input';
import * as assistanceNumber from './Redux/actions/callForAssistance';
import { useHistory, Link } from "react-router-dom";
import TaskDetails from './components/modules/tasks_components/stocktake/TaskDetails';
import PickingAndPackingSteps from './components/modules/stock_components/PickingAndPackingSteps';
import LabelAndSendSteps from './components/modules/stock_components/LabelAndSendSteps';
import * as misc from './utils/misc';
import * as icons from './utils/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
import ConsignmentTasks from './components/modules/tasks_components/ConsignmentTasks';

/** call for assistance needs to change once the phone numbers are provided */
const assistanceNumbers = [{ "phoneOwner": "Regional & Remote Contractors", "phone": "tel:1300133269", "phoneText": "1300 133 269" }];
//hard code for now. should get this from api

function makeIcon(iconName) {
    return icons.makeIcon(iconName);
    // console.log('from makeIcon', iconName)
    // switch (iconName) {
    //     case 'Assignment':
    //         return AssignmentIcon;
    //     case 'Build':
    //         return BuildIcon;
    //     case 'Create':
    //         return CreateIcon;
    //     case 'Home':
    //         return HomeIcon;
    //     case 'Input':
    //         return InputIcon;
    //     case 'Settings':
    //         return SettingsIcon;
    //     case "ReturnStock":
    //         return BusinessIcon;
    //     default:
    //         return AssignmentIcon;

    // }
}

function generateMenuItem(item, setAppTitle) {
    if (!item.ParentModuleId)
        return (<menuitem text={item.name} icon={<SkbIcon icon={makeIcon(item.iconName)} />} to={`/${item.uRLSuffix}`} component={() => (<ModuleCreator moduleName={item.uRLSuffix} changeAppTitle={setAppTitle} selectedModuleId={item.moduleID} />)}></menuitem>)
    else
        return (<menuitem hideinmenu text={item.name} icon={<SkbIcon icon={makeIcon(item.iconName)} />} to={`/${item.uRLSuffix}`} component={() => (<ModuleCreator moduleName={item.uRLSuffix} changeAppTitle={setAppTitle} />)}></menuitem>)
}


function App(props) {

    const { User, dispatch, auth0, updateAssistanceNumbers } = props; //auth0 client from index.js
    const [open, setOpen] = React.useState(true);
    const [appTitle, setAppTitle] = React.useState("Home");

    const notistackRef = React.createRef();

    const action = key => (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => notistackRef.current.closeSnackbar(key)}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )
    /**
     * sample user Module data in redux store
    [{id: 2, userID: 10, name: "Manage Variables", uRLSuffix: "ManageVariables", moduleID: 1, description:"Description of module", imagePath: "image.jpg"}]
    */
    const { Modules } = User; //retrieve User's modules from store

    let history = useHistory();
    let query = window.location.search;


    //once user has logged in via Auth0, auth0Client is to be used to handle callback and get user/token
    if (query.includes("code=") && query.includes("state=") && !auth.isAuthenticated()) {
        // Process the login state
        auth0.handleRedirectCallback().then((redirectResult) => {
            //clear code and state in URL
            history.push("/");
            auth0.getUser().then(user => {
                //Auth0 has returned the user object, however it's not saved into redux store and local storage
                if (user && !User.AuthUser) {
                    dispatch(auth.setLoginUser(user)) //save Auth user into store and local storage
                    //call Auth0 method to get ID token from Auth0
                    auth0.getIdTokenClaims().then(c => {
                        dispatch(auth.setIdToken(c.__raw)); // save Auth0 ID token into store
                        dispatch(auth.sendGetUserRequest(user, c.__raw)); //get skb user info form Minerva system
                        dispatch(callForAssistance.getPhoneNumberFromAPI());
                        dispatch(syncConfig.getPhotoDownloadSpeed());//get the photo download speed setting from api and save to the localStorage 
                    }).catch(er => {
                        SkbLogger.applicationTrace('Auth', SeverityLevel.Information, 'Home', 'Got auth0 Id token failed', er);
                    });
                }
            });
        }).catch(error => {
            SkbLogger.applicationTrace('Auth', SeverityLevel.Error, 'Home', 'handleRedirectCallback error', error);
            //handleRedirectCallback will failed in IOS12. 
            //use getTokenSilently to get user information for IOS12.
            /*         if(!auth.isAuthenticated()){
                        auth0.getTokenSilently()
                        .then(accessToken => {
                            auth0.getUser().then( user => {
                                if (user && !User.AuthUser) {
                                    dispatch(auth.setLoginUser(user)) //save Auth user into store and local storage
                                    //call Auth0 method to get ID token from Auth0
                                    auth0.getIdTokenClaims().then(c => {
                                        dispatch(auth.setIdToken(c.__raw)); // save Auth0 ID token into store
                                        dispatch(auth.sendGetUserRequest(user, c.__raw)); //get skb user info form Minerva system
                                        dispatch(callForAssistance.getPhoneNumberFromAPI()); 
                                    }).catch(er =>{
                                        SkbLogger.applicationTrace('Auth',SeverityLevel.Information,'Home','Got auth0 Id token failed',er);
                                    });
                                }
                            });
        
                        });
                    } */
        });

    }
    React.useEffect(() => {
        misc.checkLatLong(); //when app is loaded, get user lat and long, this request user to grant permission 
    }, []);
    React.useEffect(() => {
        //SkbLogger.logDebugInfo("Modules", Modules);
        if (User.User) { //skb user has been retrieved from server
            dispatch(auth.getUserModules(User.AuthUser, User.idToken));//sent the request to get user modules list from server 
            dispatch(auth.getUserTokenList(User.AuthUser.sub, User.idToken));
            dispatch(callForAssistance.getPhoneNumberFromAPI());
        }

        SkbLogger.applicationTrace('Auth', SeverityLevel.Information, 'Home', 'App js use effect triggered', User);
    }, [User.User]) //monitor User state change 

    if (auth.isIDTokenExpiringSoon() && navigator.onLine) {
        SkbLogger.applicationTrace('Auth', 0, 'Token', 'ID Token expiring soon. trying to renew...');
        //try to re-get the idtoken if there is network before require auth

        auth0.getTokenSilently()
            .then(accessToken => {
                //call Auth0 method to get ID token from Auth0
                auth0.getIdTokenClaims().then(c => {
                    SkbLogger.applicationTrace('Auth', 0, 'Token', 'ID Token renewed');
                    SkbLogger.logDebugInfo('ID Token', c.__raw);
                    dispatch(auth.setIdToken(c.__raw)); // save Auth0 ID token into store
                    dispatch(callForAssistance.getPhoneNumberFromAPI());
                    dispatch(syncConfig.getPhotoDownloadSpeed());//get the photo download speed setting from api and save to the localStorage 
                })
            });

    }

    SkbLogger.logDebugInfo('state.auth = User', User);
    //in case the previous get user API didn't call
    if (!User.User && User.idToken && !User.error) {
        SkbLogger.logDebugInfo('resending sendGetUserRequest', User.AuthUser);
        dispatch(auth.sendGetUserRequest(User.AuthUser, User.idToken));
    }
    SkbLogger.applicationTrace('Auth', SeverityLevel.Information, 'Home', 'App component rendered', User);
    SkbLogger.logDebugInfo('render App component for different cases based on User.User, idToken, Modules, isLoading:', User.User, User.idToken, Modules, User.isLoading);
    if (User.User && User.User.emailVerified && User.idToken && Array.isArray(Modules)) {

        updateAssistanceNumbers(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));
        return <div>
            <SnackbarProvider ref={notistackRef} action={action}>
                <SkbLayout auth0={auth0} user={props.User} changePassword={props.changePassword} changeAppTitle={setAppTitle} AppTitle={appTitle} state={props.state} changeTheme={props.changeTheme} id="leftnavbar" >

                    {/**hideinmenu,the itme is added in react route, but it will not show in the Minerva navigation menu. it's for internally navigating by route,  i.e. form stocktake view, click one task to open task detail view. programmatically to navigate can use useHistory().push([route anme]). i.e. useHistory().push('/taskdetails') 
                        * 
                       */}

                    {Modules && Modules.map((item, index) => (
                        generateMenuItem(item, setAppTitle)

                    )).concat([
                        <menuitem hideinmenu text='TaskDetails' to='/taskdetails' component={TaskDetails}></menuitem>
                     ,   <menuitem hideinmenu  text='PickingAndPacking' to='/pickingAndPacking/:taskId/:consignmentId' component={PickingAndPackingSteps}></menuitem>
                     , <menuitem hideinmenu  text='LabelAndSend' to='/labelAndSend/:taskId/:consignmentId' component={LabelAndSendSteps}></menuitem>,
                     <menuitem hideinmenu  text='StockConsignment' to='/stockconsignment/:taskId/:subtaskId' component={ ConsignmentTasks }></menuitem>,
                    ])}

                </SkbLayout>
            </SnackbarProvider>
        </div>
    } else if (User.isLoading) {
        SkbLogger.applicationTrace('Auth', SeverityLevel.Information, 'Home', 'Login page is still loading.', User);
        return <div><Loading id='circle-loading-indicator-home' /></div>

    }
    //to remove the !User.User condition below as auth.js indicates a blank new user with emailVerified??
    //else if (!User.isLoading && (!User.User || (!User.User.emailVerified)) && User.idToken) {
    else if (!User.isLoading && User.User && (!User.User.emailVerified) && User.idToken) {
        //if real user.email not verified or new blank user
        SkbLogger.applicationTrace('Auth', 1, 'Home', 'new user or email not verified', User);
        return <div><RegistrationWithTheme user={props.User} state={props.state} changeTheme={props.changeTheme} /></div>
    } else if (User && !User.error) {
        SkbLogger.applicationTrace('Auth', 1, 'Home', 'Temporary blank page.', User);
        //blank page or push to /  or prompt manually refresh?
        return null;
    } else {
        //
        SkbLogger.applicationTrace('Auth', 1, 'Home', 'Skb_user is null with error.', User.error);
        //blank page or push to /  or prompt manually refresh?
        return <p>Network error. Please try again later or <Link id='signout-link-when-error' to='/logout'> Sign Out </Link>. </p>;
    }

}

const mapStateToProps = state => ({
    User: state.auth
});

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (email) => dispatch(auth.changePassword(email)),
        dispatch: dispatch,
        updateAssistanceNumbers: (e) => dispatch(assistanceNumber.updatePhoneNumber(e)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);