import React, { useEffect, useState, forwardRef } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import { connect } from 'react-redux';
import * as variablesActions from '../../../Redux/actions/variables'
import MaterialTable, { MTableToolbar,MTableEditField,MTableEditRow } from 'material-table';

import Loading from '../../Loading';
import { SkbLogger } from '../../../services';
import { tableIcons } from '../_share/tableIcons';
import uuid from 'react-uuid'
import { isRowValidated, cellValidate } from '../_share/tablevalidation';



const useStyles = makeStyles((theme) => ({

    paper: {
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 16px)',
            margin: theme.spacing(0),


        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 200px)',
            marginBottom: theme.spacing(2),
        },


    },
    section: {
        marginTop: theme.spacing(2),
    },
    text: {
        paddingLeft: theme.spacing(1) / 2,
    }


}));

function EmployeeDomain({ user, EmployeeDomains, updateEmployeeDomain, delEmployeeDomain, addEmployeeDomain }) {

    //set up table columns
    const [columns, setColumns] = useState([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'resourceID', field: 'resourceID', hidden: true },
        { title: 'variableGroup', field: 'variableGroup', hidden: true },
        { title: 'parentVariableID', field: 'parentVariableID', hidden: true },

        {
            title: 'Domain Name', field: 'variableName', validate: rowData => cellValidate(rowData.variableName, /^[^\s]{0,50}$/ig, 'Invalid Domain Name'),
            editComponent: props => (
                <MTableEditField
                    autoFocus
                    {...props}
                />
            )
        },
        { title: 'variableValue', field: 'variableValue', hidden: true },
        {
            title: 'Active', field: 'active', type: 'numeric', initialEditValue: 1,
            lookup: { 1: 'Yes', 0: 'No' }
        },
    ]);

    const [data, setData] = useState([...EmployeeDomains]);
    const toolbarRef = React.useRef(null);// generate toobar ref in order that to manipulate view position after page size is changed 
    return (
        <div id="domaintable">
            <MaterialTable
                icons={tableIcons}
                showTitle={false}
                searchable={false}
                toolbar={false}
                columns={columns}
                data={data}
                padding={'dense'}
                components={{
                    Toolbar: props => (
                        <div ref={toolbarRef}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    EditRow: props => <MTableEditRow
                    onKeyDown = {
                        e=>{
                           //override the default behaviour 
                        }
                    }

                        {...props}
                    />
                }}
                onChangeRowsPerPage={() => setTimeout(() => { try { toolbarRef.current.scrollIntoViewIfNeeded(); } catch (e) { } }, 500)}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if (isRowValidated(columns, newData)) {
                                    newData.resourceID = uuid().replace(/-/ig, '');
                                    setData([...data, newData]);
                                    addEmployeeDomain({ Id: null, variableGroup: "EmployeeDomains", parentVariableID: null, variableName: newData.variableName, variableValue: newData.variableValue, active: newData.active, resourceID: newData.resourceID }, user);
                                    resolve();
                                }
                                else reject();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                updateEmployeeDomain(newData, user);
                                resolve();
                            }, 1000)
                        }),
                    //**********currently delete funciton is disabled due to server will NOT actaully detele record */
                    //   onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         const dataDelete = [...data];
                    //         const index = oldData.tableData.id;
                    //         delEmployeeDomain.splice(index, 1);
                    //         delCountry({Id:oldData.id, variableGroup:oldData.variableGroup, parentVariableID:oldData.variableGroup, variableName:oldData.variableName, variableValue:oldData.variableValue, active: oldData.active, resourceID:oldData.resourceID});
                    //         setData([...dataDelete]);

                    //         resolve()
                    //       }, 1000)
                    //     }),
                }}
                options={{ showTitle: false, search: false, actionsColumnIndex: -1 }}
            />
        </div>
    )
}


function EmployeesVariables(props) {

    const { user, variables, getEmployeeDomains, updateEmployeeDomain, delEmployeeDomain, addEmployeeDomain } = props;
    const classes = useStyles();
    useEffect(() => {
        if (!variables.EmployeeDomains || variables.EmployeeDomains.length == 0)
            getEmployeeDomains();
    }, []);

    if (variables.isLoading && (variables.EmployeeDomains.length == 0 || variables.EmployeeDomains.length == 0)) {
        return (<Loading />);
    }
    else {
        return (
            <div>
                <Paper className={classes.paper}>
                    <Typography className={classes.text} variant="h3"> Employee Variables</Typography>
                    <div className={classes.section}>
                        <Typography className={classes.text} variant="h4">Employee Domain Names</Typography>
                        <Typography className={classes.text} variant="body1"> List of email domains that our employees use. This is used to identify when an employee registers to access the application.</Typography>
                        <EmployeeDomain user={user} EmployeeDomains={variables.EmployeeDomains} updateEmployeeDomain={updateEmployeeDomain} addEmployeeDomain={addEmployeeDomain} delEmployeeDomain={delEmployeeDomain} />
                    </div>
                </Paper>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    user: state.auth,
    register: state.register,
    variables: state.variables
});

const mapDispatchToProps = (dispatch) => {
    return {
        getEmployeeDomains: () => dispatch(variablesActions.getEmployeeDomains()),
        updateEmployeeDomain: (c, auth) => dispatch(variablesActions.updateEmployeeDomain(c, auth)),
        addEmployeeDomain: (c, auth) => dispatch(variablesActions.addEmployeeDomain(c, auth)),
        delEmployeeDomain: (c, auth) => dispatch(variablesActions.delEmployeeDomain(c, auth)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployeesVariables);
