
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme, darken } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE } from '../Themes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, BrowserRouter } from 'react-router-dom';

import logoDark from '../Themes/logo-dark.png';
import logoLight from '../Themes/logo-white.png';

import Tooltip from '@material-ui/core/Tooltip';
import { ProfileMenu } from '../components';
import { SkbSwitch } from '../components/SkbSwitch';
import Syncbar from '../../components/sync/Syncbar'
import SkbIcon from '../components/SkbIcon';

import WbSunnyIcon from '@material-ui/icons/WbSunny';

import Brightness3Icon from '@material-ui/icons/Brightness3';
//import SyncServiceWorkerCommunicator from '../../components/sync/SyncServiceWorkerCommunicator';


const useStyles = makeStyles(theme => ({
    appBarContent: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        '& > div.MuiGrid-item': {
            padding: 7,
            [theme.breakpoints.down(358)]: {

                padding: 0
            },

        }
    },
    appBar: {
        position: "fixed",
        top: 0,
        [theme.breakpoints.up('md')]: {
            //   width: `calc(100% - ${drawerWidth}px)`,
            width: `calc(100% )`,
            marginLeft: 0,
            top: LAYOUTCOMPONENT_SIZE.Header.Height,

        },
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.primary,
        // zIndex:-1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            height: 24,
            width: 24,
            '& > .MuiIconButton-label': {
                height: 0,

            }
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },

    },

    nightDayIcon:
    {
        display: "block",
        [theme.breakpoints.down('sm')]: {
            // marginTop: "6px",
            height: 24,
            width: 24
        },
        [theme.breakpoints.up('sm')]: {
            // marginTop: "12px",
            height: 30,
            width: 30
        },
    },
    switchstyle: {
        width: 48,
        [theme.breakpoints.down('sm')]: {
            width: 36
        },
        marginTop: 4
    },
    logoSizing:
    {
        [theme.breakpoints.down('xs')]: {
            height: 17,
            width: 45
        },
        [theme.breakpoints.up('sm')]: {
            height: 34,
            width: 90
        },
        [theme.breakpoints.up('md')]: {
            height: 44,
            width: 169
        },
    },
    themeswitch: {
        alignItems: "center",
        justifyContent: "flex-end",
    },
    textcenter: {
        textAlign: 'left',
    },

    link: {
        textDecorationLine: "none",
    }
}));

export default function SkbAppBar(props) {
    const classes = useStyles();
    const theme = useTheme();
    // const changeTheme = ()=>{
    //     console.log('triggered');
    //     props.changeTheme('darkTheme');
    // }
    const { state, handleDrawerToggle, AppTitle, changeAppTitle } = props;

    const logo = state.darkTheme ? logoLight : logoDark;

    return (

        <AppBar className={classes.appBar}>

            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    id="skblayout_menu_toggle_btn"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon color="primary" />
                </IconButton>
                <Grid container justify="space-between" spacing={3} className={classes.appBarContent}>
                    <Hidden only="xs" implementation="css">
                        <Grid item xs={0} sm={2} md={3} lg={2}>
                            <img src={logo} alt='logo' className={classes.logoSizing}></img>
                        </Grid>
                    </Hidden>
                    <Grid item xs={7} sm={6} md={6} lg={8} className={classes.textcenter} >
                        <Hidden only="xs" implementation="css">
                            <Typography>
                                {/* Project Phoenix */}
                                <Breadcrumbs aria-label="breadcrumb" separator={<Typography variant="h6" color="textPrimary">\</Typography>}>
                                    <RouterLink to="/home" onClick={() => changeAppTitle('Home')} className={classes.link}>
                                        <Typography variant="h6" color="textPrimary"> Minerva</Typography>
                                    </RouterLink>
                                    <Typography variant="h6" color="textSecondary">{AppTitle}</Typography>
                                </Breadcrumbs>
                            </Typography>
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <Typography>
                                {/* Project Phoenix */}
                                <Breadcrumbs aria-label="breadcrumb" separator={<Typography variant="h5" color="textPrimary">\</Typography>}>
                                    <RouterLink to="/home" onClick={() => changeAppTitle('Home')} className={classes.link}>
                                        <Typography variant="h5" color="textPrimary"> Minerva</Typography>
                                    </RouterLink>
                                    <Typography variant="h5" color="textSecondary">{AppTitle}</Typography>
                                </Breadcrumbs>
                            </Typography>
                        </Hidden>                       
                    </Grid>
                    <Grid item xs={5} md={3} sm={4} lg={2}>
                        <div style={{ display: "flex", alignItems: "center" }}>


                            <Grid container alignItems="center" className={classes.themeswitch}>
                                <Grid item> <SkbIcon className={classes.nightDayIcon} icon={WbSunnyIcon} />  </Grid>
                                <Grid item className={classes.switchstyle}><SkbSwitch id="light-dark-toggle" val={state.darkTheme} setVal={props.changeTheme('darkTheme')} showOnOffText={false} primary /></Grid>
                                <Grid item><SkbIcon className={classes.nightDayIcon} icon={Brightness3Icon} /></Grid>
                                <Grid item><ProfileMenu {...props} /></Grid>
                            </Grid>

                        </div></Grid>
                </Grid>
                {/* save theme colours as "global value" (in DOM) for ScanBarcode.js to access */}
                {/* ScanBarcode injects javascript vars to be inline style, which is different to other components*/}
                {/* ScanBarcode cannot use useTheme as it's a class component*/}
                {/* theme colours cannot be easily passed down as props becase dynamic Route is used*/}
                <input type='text' id='hidden-text-theme-palette' value={JSON.stringify(theme.palette)} style={{ display: 'none' }} />

            </Toolbar>

            <Syncbar {...props} />
        </AppBar>

    );
}