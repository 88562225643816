/**
 * Action type related to Returns Dashboard functionality
 */
export const ActionTypes = {
    //*** return dashboard list list related *****
    CONTRACTOR_STOCK_RETURN_TASK_LIST_LOAD : "CONTRACTOR_STOCK_RETURN_TASK_LIST_LOAD",    SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_MODIFIED : "SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_MODIFIED",
    SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_ON: "SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_ON",
    SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_OFF: "SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_OFF",

    SET_RETURN_STOCK_SLA_DAYS: "SET_RETURN_STOCK_SLA_DAYS",    
    SET_RETURN_STOCK_SLA_DAYS_LOADER_ON: "SET_RETURN_STOCK_SLA_DAYS_LOADER_ON",
    SET_RETURN_STOCK_SLA_DAYS_LOADER_OFF: "SET_RETURN_STOCK_SLA_DAYS_LOADER_OFF",
    
    CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS : "CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS",
    CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS_UNSET : "CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS_UNSET",
    CONTRACTOR_STOCK_RETURN_TASK_SELECT : "CONTRACTOR_STOCK_RETURN_TASK_SELECT",
    CONTRACTOR_STOCK_RETURN_TASK_SELECT_UNSET_ALL : "CONTRACTOR_STOCK_RETURN_TASK_SELECT_UNSET_ALL",     
    
    
    //***** task info popup details related *****
    CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW : "CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW",
    CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_ON : "CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_ON",
    CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_OFF : "CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_OFF",
    CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_SET_API_ERROR : "CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_SET_API_ERROR",
    CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_UNSET_API_ERROR : "CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_UNSET_API_ERROR",
    TASK_INFO_NONLIST_CHANGEABLEDATA_UNSET : "TASK_INFO_NONLIST_CHANGEABLEDATA_UNSET",

    TASK_INFO_POPUP_STRUCTURE_SHOW_FLAG: "TASK_INFO_POPUP_STRUCTURE_SHOW_FLAG",

    TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_CONSIGNMENTMETHOD: "TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_CONSIGNMENTMETHOD",
    TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_WAREHOUSELOCCODE: "TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_WAREHOUSELOCCODE",
    TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_SET_FLAG: "TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_SET_FLAG",
    

    CONSIGNMENT_METHOD_LIST_LOAD : "CONSIGNMENT_METHOD_LIST_LOAD",  
    CONSIGNMENT_METHOD_LIST_LOAD_LOADER_ON : "CONSIGNMENT_METHOD_LIST_LOAD_LOADER_ON",    
    CONSIGNMENT_METHOD_LIST_LOAD_LOADER_OFF : "CONSIGNMENT_METHOD_LIST_LOAD_LOADER_OFF",
    CONSIGNMENT_METHOD_LIST_SET_API_ERROR : "CONSIGNMENT_METHOD_LIST_SET_API_ERROR",
    CONSIGNMENT_METHOD_LIST_UNSET_API_ERROR : "CONSIGNMENT_METHOD_LIST_UNSET_API_ERROR",
    CONSIGNMENT_METHOD_LIST_API_DATA_UNSET : "CONSIGNMENT_METHOD_LIST_API_DATA_UNSET",



    WAREHOUSE_LIST_LOAD : "WAREHOUSE_LIST_LOAD",
    WAREHOUSE_LIST_LOAD_LOADER_ON : "WAREHOUSE_LIST_LOAD_LOADER_ON",    
    WAREHOUSE_LIST_LOAD_LOADER_OFF : "WAREHOUSE_LIST_LOAD_LOADER_OFF",
    WAREHOUSE_LIST_LOAD_SET_API_ERROR : "WAREHOUSE_LIST_LOAD_SET_API_ERROR",
    WAREHOUSE_LIST_LOAD_UNSET_API_ERROR : "WAREHOUSE_LIST_LOAD_UNSET_API_ERROR",
    WAREHOUSE_LIST_API_DATA_UNSET : "WAREHOUSE_LIST_API_DATA_UNSET",

    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE : "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_ON : "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_ON",    
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_OFF : "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_OFF",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_SET_API_ERROR : "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_SET_API_ERROR",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_UNSET_API_ERROR : "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_UNSET_API_ERROR",
    WAREHOUSE_ADDR_LOAD_API_DATA_UNSET : "WAREHOUSE_ADDR_LOAD_API_DATA_UNSET",

    CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_SET : "CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_SET",
    CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UNSET: "CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UNSET",
    CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ITEM_SELECT : "CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ITEM_SELECT",
    CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UPDATE : "CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UPDATE", 
    CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ADD_ITEMSELECTEDFROMADDSTOCK : "CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ADD_ITEMSELECTEDFROMADDSTOCK",    




    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS",
    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS_UNSET : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS_UNSET",

    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR",
    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR_UNSET : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR_UNSET",

    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS",

    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS_UNSET : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS_UNSET",

    CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR",

     CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR_UNSET : "CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR_UNSET",

    CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_CLOSE: "CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_CLOSE",
   

    //***** task info popup related add stock screen list related ****
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_ON : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_ON",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_OFF : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_OFF",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_API_DATA_UNSET : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_API_DATA_UNSET",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_SELECTED : "TASK_LOCATION_ONHAND_STOCK_DETAIL_SELECTED",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_SET_API_ERROR : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_SET_API_ERROR",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_UNSET_API_ERROR : "TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_UNSET_API_ERROR",
    TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY_UPDATE: "TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY_UPDATE",

    /* task Info Schedule pre popup open api update related */

    //need to be removed

    // TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS: "TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS",
    // TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS_UNSET: "TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS_UNSET",
    // TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR: "TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR",
    // TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR_UNSET: "TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR_UNSET",


    /**** task info popup schedule feature specific related action types    */
    LOGISTIC_CARRIER_LIST_LOAD : "LOGISTIC_CARRIER_LIST_LOAD",
    LOGISTIC_CARRIER_LIST_LOAD_LOADER_ON : "LOGISTIC_CARRIER_LIST_LOAD_LOADER_ON",    
    LOGISTIC_CARRIER_LIST_LOAD_LOADER_OFF : "LOGISTIC_CARRIER_LIST_LOAD_LOADER_OFF",
    LOGISTIC_CARRIER_LIST_LOAD_SET_API_ERROR : "LOGISTIC_CARRIER_LIST_LOAD_SET_API_ERROR",
    LOGISTIC_CARRIER_LIST_LOAD_UNSET_API_ERROR : "LOGISTIC_CARRIER_LIST_LOAD_UNSET_API_ERROR",
    LOGISTIC_CARRIER_LIST_LOAD_UNSET : "LOGISTIC_CARRIER_LIST_LOAD_UNSET",

    PICKUP_WINDOW_TIME_LIST_LOAD : "PICKUP_WINDOW_TIME_LIST_LOAD",
    PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_ON : "PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_ON",    
    PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_OFF : "PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_OFF",
    PICKUP_WINDOW_TIME_LIST_LOAD_SET_API_ERROR : "PICKUP_WINDOW_TIME_LIST_LOAD_SET_API_ERROR",
    PICKUP_WINDOW_TIME_LIST_LOAD_UNSET_API_ERROR : "PICKUP_WINDOW_TIME_LIST_LOAD_UNSET_API_ERROR",
    PICKUP_WINDOW_TIME_LIST_LOAD_UNSET : "PICKUP_WINDOW_TIME_LIST_LOAD_UNSET",
    
    DROPOFF_WINDOW_TIME_LIST_LOAD : "DROPOFF_WINDOW_TIME_LIST_LOAD",
    DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_ON : "DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_ON",    
    DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_OFF : "DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_OFF",
    DROPOFF_WINDOW_TIME_LIST_LOAD_SET_API_ERROR : "DROPOFF_WINDOW_TIME_LIST_LOAD_SET_API_ERROR",
    DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET_API_ERROR : "DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET_API_ERROR",
    DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET : "DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET",
    
    
    TASK_INFO_SCHEDULE_FINISH_API_SUCCESS : "TASK_INFO_SCHEDULE_FINISH_API_SUCCESS",
    TASK_INFO_SCHEDULE_FINISH_API_SUCCESS_UNSET : "TASK_INFO_SCHEDULE_FINISH_API_SUCCESS_UNSET",
    TASK_INFO_SCHEDULE_FINISH_API_ERROR_SET : "TASK_INFO_SCHEDULE_FINISH_API_ERROR_SET",
    TASK_INFO_SCHEDULE_FINISH_API_ERROR_UNSET : "TASK_INFO_SCHEDULE_FINISH_API_ERROR_UNSET",

    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS: "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_ON: "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_ON",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_OFF: "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_OFF",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_SET_API_ERROR: "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_SET_API_ERROR",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_UNSET_API_ERROR: "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_UNSET_API_ERROR",
    WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_API_DATA_UNSET: "WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_API_DATA_UNSET",

    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_WAREHOUSELOCATIONCODE: "TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_WAREHOUSELOCATIONCODE",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_CHANGEABLEDATA_SET_FLAG:"TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_CHANGEABLEDATA_SET_FLAG",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CHANGEABLEDATA_SET_FLAG:"TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CHANGEABLEDATA_SET_FLAG",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_ATP: "TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_ATP",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_FORKLIFTREQUIRED: "TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_FORKLIFTREQUIRED",
    TASK_INFO_SCHEDULE_POPUP_DATA_CHANGE_ACTIVITY_BY_USER: "TASK_INFO_SCHEDULE_POPUP_DATA_CHANGE_ACTIVITY_BY_USER",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWDATE: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWDATE",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWTIMESLOT: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWTIMESLOT",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME_URL: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME_URL",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CANNOTE: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CANNOTE",
    
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPLOADED_LABEL_SET: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPLOADED_LABEL_SET", 
    TASK_INFO_SCHEDULE_GENERIC_LOADERFLAG: "TASK_INFO_SCHEDULE_GENERIC_LOADERFLAG",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG: "TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG", 
    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG_AND_FE_ERROR: "TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG_AND_FE_ERROR", 
    TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_UPDATE_SPECIFIC_MSG_FE_ERROR: "TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_UPDATE_SPECIFIC_MSG_FE_ERROR", 
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG", 
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG_AND_FE_ERROR: "TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG_AND_FE_ERROR",
    TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPDATE_SPECIFIC_MSG_FE_ERROR:"TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPDATE_SPECIFIC_MSG_FE_ERROR",
        

    TASK_INFO_POPUP_SCHEDULE_CHANGEABLE_DATA_FULL_UNSET: "TASK_INFO_POPUP_SCHEDULE_CHANGEABLE_DATA_FULL_UNSET",

    // Returns dashboard list related Pie chart on Status data related
    PIE_CHART_ON_STATUS_RELATED_DATA_SET: "PIE_CHART_ON_STATUS_RELATED_DATA_SET",

    // Returns dashboard list related Pie chart on Age of return data related
    PIE_CHART_ON_AGEOFRETURN_RELATED_DATA_SET: "PIE_CHART_ON_AGEOFRETURN_RELATED_DATA_SET",

    // Return list filter names set related action type
    SET_RETURN_LIST_STATUS_ACTIVE_FILTERS: "SET_RETURN_LIST_STATUS_ACTIVE_FILTERS",


    // For task info schedule for contractor drop off scenario    
    TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__WAREHOUSE_LOCATIONCODE : 'TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__WAREHOUSE_LOCATIONCODE',
    TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_DATE : 'TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_DATE',
    TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_WINDOW_TIMESLOT : 'TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_WINDOW_TIMESLOT',
    TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__UPLOADED_LABEL_SET : 'TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__UPLOADED_LABEL_SET',
    
    TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_PALLETS : 'TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_PALLETS',
    TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_CARTONS : 'TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_CARTONS',

    FETCH_CONSIGNMENT_DETAILS_START : 'FETCH_CONSIGNMENT_DETAILS_START',
    FETCH_CONSIGNMENT_DETAILS_SUCCESS : 'FETCH_CONSIGNMENT_DETAILS_SUCCESS',
    FETCH_CONSIGNMENT_DETAILS_ERROR : 'FETCH_CONSIGNMENT_DETAILS_ERROR',

    TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_CHANGED : 'TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_CHANGED',
    TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_RESET : 'TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_RESET',

    TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_START :  'TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_START',
    TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_END   :  'TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_END',

    TASK_INFO_POPUP_CONTRACTOR_OPTION_SET_UPLOADED_LABELS : 'TASK_INFO_POPUP_CONTRACTOR_OPTION_SET_UPLOADED_LABELS',

    DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET : 'DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET',
    TASK_INFO_SCHEDULE_CONTRDROPOFF_SCENARIO_VALIDATE_FLAG_AND_FE_ERROR:"TASK_INFO_SCHEDULE_CONTRDROPOFF_SCENARIO_VALIDATE_FLAG_AND_FE_ERROR",
    TASK_INFO_SCHEDULE_CONTRACTOR_DROPOFF_UPDATE_SPECIFIC_MSG_FE_ERROR: "TASK_INFO_SCHEDULE_CONTRACTOR_DROPOFF_UPDATE_SPECIFIC_MSG_FE_ERROR",
    
    //  Task cancel flow from returns  dashhboard list section  related
    CANCEL_REASON_OPTIONS_LIST_LOAD: "CANCEL_REASON_OPTIONS_LIST_LOAD",
    CANCEL_REASON_OPTIONS_LIST_LOAD_UNSET: "CANCEL_REASON_OPTIONS_LIST_LOAD_UNSET",
    CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_ON: "CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_ON",
    CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_OFF: "CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_OFF",

    /* Task cancel flow from returns  dashhboard list section submit related */
    CANCEL_TASK_REASON_SUCCESS: "CANCEL_TASK_REASON_SUCCESS",
    CANCEL_TASK_REASON_SUCCESS_UNSET: "CANCEL_TASK_REASON_SUCCESS_UNSET",
    CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_ON: "CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_ON",
    CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_OFF: "CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_OFF"  

}
