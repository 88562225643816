import React, { useEffect, useState } from 'react';
import DestinationSelector from '../../components/modules/stock_components/DestinationSelector';
import {loadWarehouseLocations} from '../../services/StockService';
import { updateDestination } from '../../Redux/actions/stockConsignment';

//loadDestinationData, IsLoading, DestinationList, SelectedDestination, selectDestination
export async function loadWarehouseLocationsFromServer(idToken){
  return await loadWarehouseLocations(idToken);
}

const  ConsignmentDataType= {
    ReturnConsignmentsCreation:"ReturnConsignmentsCreation"
}

export const getSelectedStockReturnDestination = (stockConsignment) => {
    if (stockConsignment.ReturnConsignmentsCreation && Array.isArray(stockConsignment.ReturnConsignmentsCreation)) {
        for(let data of stockConsignment.ReturnConsignmentsCreation){
            if(data.Consignments && Array.isArray(data.Consignments)){
                for(let item of data.Consignments){
                    if(item.ToLocationCode && item.ToLocationCode != null && item.ToLocationCode != "" ){
                        return  item.ToLocationCode  
                    }else{
                        return []
                    }                           
                }
            }else{
                return []
            }
        }
    }else{
        return []
    }
}

//consignment dispatch ,selectedLocationed=locationCode
export const handleReturnSelectDestination= (dispatch,selectedRow) =>  {
    var consignmentData={
        "ConsignmentDataType": ConsignmentDataType.ReturnConsignmentsCreation,
        "ToLocationCode":(selectedRow[0] ? selectedRow[0].LocationCode : ''), //it returns in a array with one or zero element
        "ToLocationName":(selectedRow[0] ? selectedRow[0].Name : ''), 
        "ToLocationAddress":(selectedRow[0] ? selectedRow[0].Address : ''), 
        "IsReturntoClosestWarehouse":false
        };

    dispatch(updateDestination(consignmentData));
}
  



