const types = {
    TASK_REQUEST_SENT : "TASK_REQUEST_SENT",
    TASK_REQUEST_RESPONDED : "TASK_REQUEST_RESPONDED",
    TASK_UPDATE_ERROR : "TASK_UPDATE_ERROR",

    TASK_LOADED : "TASK_LOADED",
    TASK_CONSIGNMENT_MODIFIED : "TASK_CONSIGNMENT_MODIFIED",
    TASK_CONSIGNMENT_SUBMISSION :"TASK_CONSIGNMENT_SUBMISSION",
    TASK_UPDATE_LOCKEDBY : "TASK_UPDATE_LOCKEDBY",

    TASK_CONSIGNMENT_SNITEMS_LOADED : "TASK_CONSIGNMENT_SNITEMS_LOADED",
    TASK_CONSIGNMENT_NOSNITEMS_LOADED : "TASK_CONSIGNMENT_NOSNITEMS_LOADED",
    TASK_CONSIGNMENT_UPDATE_SN_ITEM : "TASK_CONSIGNMENT_UPDATE_SN_ITEM",
    TASK_CONSIGNMENT_UPDATE_NONSN_ITEM : "TASK_CONSIGNMENT_UPDATE_NONSN_ITEM",

    TASKS_LIST_START : "TASKS_LIST_START",
    TASKS_ERROR : "TASKS_ERROR",

    SET_SORT_ORDER : "SET_SORT_ORDER",
    FILTER_TASKS_BY_STATUS : "FILTER_TASKS_BY_STATUS",

    SET_SELECTED_TASKS_ROW : "SET_SELECTED_TASKS_ROW",
    CLEAR_SELECTED_TASKS_ROW : "CLEAR_SELECTED_TASKS_ROW",
    SET_COLUMN_TO_CHANGE : "SET_COLUMN_TO_CHANGE",
    CHANGE_STATUS_IN_STORE : "CHANGE_STATUS_IN_STORE",
    SEARCH_STOCK_TASKS : "SEARCH_STOCK_TASKS",

    MARK_STOCK_ITEMS_MISSING : "MARK_STOCK_ITEMS_MISSING",
    MARK_STOCK_ITEMS_DOA : "MARK_STOCK_ITEMS_DOA",
    MARK_STOCK_ITEMS_AVAILABLE: "MARK_STOCK_ITEMS_AVAILABLE",
    MARK_STOCK_ITEMS : "MARK_STOCK_ITEMS",

    FETCH_CONSIGNMENT_LIST_START : "FETCH_CONSIGNMENT_LIST_START",
    FETCH_CONSIGNMENT_LIST_SUCCESS : "FETCH_CONSIGNMENT_LIST_SUCCESS",
    FETCH_CONSIGNMENT_LIST_ERROR : "FETCH_CONSIGNMENT_LIST_ERROR",

    FETCH_CONSIGNMENT_DETAILS_START : "FETCH_CONSIGNMENT_DETAILS_START",
    FETCH_CONSIGNMENT_DETAILS_SUCCESS : "FETCH_CONSIGNMENT_DETAILS_SUCCESS",
    FETCH_CONSIGNMENT_DETAILS_ERROR : "FETCH_CONSIGNMENT_DETAILS_ERROR",

    FETCH_CONSIGNMENT_INFO_START : "FETCH_CONSIGNMENT_INFO_START",
    FETCH_CONSIGNMENT_INFO_SUCCESS : "FETCH_CONSIGNMENT_INFO_SUCCESS",
    FETCH_CONSIGNMENT_INFO_ERROR : "FETCH_CONSIGNMENT_INFO_ERROR",

    FILTER_BY_STATUS_START : "FILTER_BY_STATUS_START",
    FILTER_BY_STATUS_SUCCESS : "FILTER_BY_STATUS_SUCCESS",
    FILTER_BY_STATUS_ERROR : "FILTER_BY_STATUS_ERROR",

    CLEAR_FILTER_STATUS : "CLEAR_FILTER_STATUS",

    SEARCH_BY_STOCK_CODE_START : "SEARCH_BY_STOCK_CODE_START",
    SEARCH_BY_STOCK_CODE_SUCCESS : "SEARCH_BY_STOCK_CODE_SUCCESS",
    SEARCH_BY_STOCK_CODE_ERROR : "SEARCH_BY_STOCK_CODE_ERROR",

    SEARCH_BY_SERIAL_NUMBER_START : "SEARCH_BY_SERIAL_NUMBER_START",
    SEARCH_BY_SERIAL_NUMBER_SUCCESS : "SEARCH_BY_SERIAL_NUMBER_SUCCESS",
    SEARCH_BY_SERIAL_NUMBER_ERROR : "SEARCH_BY_SERIAL_NUMBER_ERROR",

    SEARCH_BY_CONSIGNMENT_ID_START : "SEARCH_BY_CONSIGNMENT_ID_START",
    SEARCH_BY_CONSIGNMENT_ID_SUCCESS : "SEARCH_BY_CONSIGNMENT_ID_SUCCESS",
    SEARCH_BY_CONSIGNMENT_ID_ERROR : "SEARCH_BY_CONSIGNMENT_ID_ERROR",

    CLEAR_SEARCH_CONSIGNMENT_DATA : "CLEAR_SEARCH_CONSIGNMENT_DATA",

    UPDATE_NON_SERIALISED_ITEM_QTY : "UPDATE_NON_SERIALISED_ITEM_QTY",

    UPDATE_CONSIGNMENT_STATUS_START : "UPDATE_CONSIGNMENT_STATUS_START",
    UPDATE_CONSIGNMENT_STATUS_SUCCESS : "UPDATE_CONSIGNMENT_STATUS_SUCCESS",
    UPDATE_CONSIGNMENT_STATUS_ERROR : "UPDATE_CONSIGNMENT_STATUS_ERROR",

    OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_START : "OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_START",
    OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_SUCCESS : "OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_SUCCESS",
    OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_ERROR : "OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_ERROR",

    OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_START : "OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_START",
    OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_SUCCESS : "OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_SUCCESS",
    OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_ERROR : "OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_ERROR",

    SEARCH_STOCK_ITEMS : "SEARCH_STOCK_ITEMS",
}


export default types;