import React from 'react';
import { connect } from 'react-redux';
import * as syncActions from '../../Redux/actions/sync';
import {crypts} from '../../utils/crypts';
import * as localDB from '../../utils/localDB';
import { SkbLogger } from '../../services';
import {setupCommunicationWithServiceWorker} from './Syncbar';

var swCommHasSetup = false;

const fakeAuthuser={sub:'sw|1234',email:'test@test.com'};
const authUserInLS=crypts.encrypt(JSON.stringify(fakeAuthuser)); 
const idTokenInLS=crypts.encrypt('fake token'); 
const storageSASLS=crypts.encrypt('fake token');
SkbLogger.logDebugInfo('mock unit testing env for sw');


function SyncSWUnitTest(props) {

    SkbLogger.logDebugInfo('SyncSWUnitTest');

    const {sync,dispatch}=props;


    if(authUserInLS && idTokenInLS && !swCommHasSetup ){
        SkbLogger.logDebugInfo('setupCommunicationWithServiceWorker');
        setupCommunicationWithServiceWorker(sync,dispatch);
    }

    const testUploadLocalClusterChanges = () =>{
        //clear data
        if(document.getElementById('last-mock-api-called').firstChild){
            document.getElementById('last-mock-api-called').removeChild(document.getElementById('last-mock-api-called').firstChild);
        }
        if(document.getElementById('last-post-message-type').firstChild){
            document.getElementById('last-post-message-type').removeChild(document.getElementById('last-post-message-type').firstChild);
        }
        //add local data
        localDB.initForUser('sw|1234');
        const testCluster = {
            resourceID: 'f305824132e3ac1ffd6c66c311ae25',
            name: 'Stocktake Data 1234 Part 1',
            scope: 'User',
            referenceEntity: 'STOCKTAKE',
            referenceID: 1234,
            timestamp: '2020-08-21T04:29:00.612Z',
            version: 0,
            operation: 'I'
        }
        localDB.writeDataClusterAsync(testCluster);
        //check last message and api
        setTimeout(checkUploadLocalClusterChanges,30*1000); //30 sec
    }

    const checkUploadLocalClusterChanges = () =>{
        //check last message and api
        if(document.getElementById('last-mock-api-called').firstChild){
            document.getElementById('result-upload-local-cluster-changes').value='PASSED';
        }
    }

    
    const testUploadLocalItemChanges = () =>{
        //clear data
        if(document.getElementById('last-mock-api-called').firstChild){
            document.getElementById('last-mock-api-called').removeChild(document.getElementById('last-mock-api-called').firstChild);
        }
        if(document.getElementById('last-post-message-type').firstChild){
            document.getElementById('last-post-message-type').removeChild(document.getElementById('last-post-message-type').firstChild);
        }
        //add local data
        localDB.initForUser('sw|1234');
        const testItem = {
            resourceID: 'c311ae25f305824132e3ac1ffd6c66',
            clusterResourceID: 'f305824132e3ac1ffd6c66c311ae25',
            key: 'stocktakeData',
            value: '1234',
            descruption:'testing data',
            timestamp: '2020-08-21T04:29:00.612Z',
            version: 0,
            operation: 'I'
        }
        localDB.writeDataItemAsync(testItem);
        //check last message and api
        setTimeout(checkUploadLocalItemChanges,30*1000); //30 sec
    }

    const checkUploadLocalItemChanges = () =>{
        //check last message and api
        if(document.getElementById('last-mock-api-called').firstChild){
            document.getElementById('result-upload-local-item-changes').nodeValue='PASSED';
        }
    }

    return (
        <div>
            <h3>Test Cases</h3>
        <p> 
            <button id='test-upload-local-cluster-changes' onClick={testUploadLocalClusterChanges}>test upload local cluster changes</button>
            <input id='result-upload-local-cluster-changes' /> 
        </p>
        <p> 
            <button id='test-upload-local-item-changes' onClick={testUploadLocalItemChanges}>test upload local item changes</button>
            <input id='result-upload-local-item-changes' /> 
        </p>
        {/* add more pairs of button-input as above to test service worker */}

        <h3>Mock Api Result</h3>
        <p> <textarea rows='8' cols='100' id='last-mock-api-called'></textarea> </p>
        <h3>Post Message Result</h3>
        <p> <textarea rows='8' cols='100' id='last-post-message-type'></textarea> </p>

        <p></p>
        
        </div>


    );
    
}

const mapStateToProps = state => ({
    sync:state.sync
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SyncSWUnitTest);