import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }

}));

/**
 * 
 * @property {primary}: true: button render as primary button
 * @property {size}: button size: 'small', 'medium', or 'large'
 * @property {text}: button text 
 * @property {disabled}: true: disable button
 * @property {autoFocus}: true: button will be focussed 
 * @property {outlined}: true: show as an outlined button instead of filled (default is false)
 */
export default function SkbButton({ primary, size, text, disabled, onClick, autoFocus, children, outlined = false, ...other }) {

    const classes = useStyles();
    if (primary) {
        if (outlined) {
            return (<Button variant="outlined" disabled={disabled} size={size} color="primary" onClick={onClick} autoFocus={autoFocus} {...other} >
                {children && children}
                {!children && text}
            </Button>);
        }
        else {
            return (<Button variant="contained" disabled={disabled} size={size} color="primary" onClick={onClick} autoFocus={autoFocus} {...other}>
                {children && children}
                {!children && text}
            </Button>);
        }
    }
    else {
        if (outlined) {
            return (
                <Button variant="outlined" disabled={disabled} size={size} color="secondary" autoFocus={autoFocus} onClick={onClick} {...other}>
                    {text}
                </Button>
            );
        }
        else {
            return (
                <Button variant="contained" disabled={disabled} size={size} color="secondary" autoFocus={autoFocus} onClick={onClick} {...other}>
                    {text}
                </Button>
            );
        }
    }
}
