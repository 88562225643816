
import axios from "axios";
import { config } from '../utils/config'; 
import { SkbLogger } from ".";

export function getCountries() {
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=countries',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });
}


export function getCallForAssistancePhoneNumbers(){
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=CallForAssistance',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });    
}

export function getTermAndConditions(){
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=TermsAndConditions',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });    
}

export function getEmployeeDomains() {
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=EmployeeDomains',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });
}
export function getDivisions() {
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=Divisions',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });
}

export function getPrograms() {
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'programs',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });
}

export function getCountryPhones() {
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=phones',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });
}

export function getPhotoDownloadSpeedSetting() {
    return axios({
        method: 'GET', 
        url: process.env.REACT_APP_API_URL + 'managedvariables?group=PhotoDownloadSpeed',
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET
        }
    });
  }

export function getTaxLabels() {
  return axios({
      method: 'GET', 
      url: process.env.REACT_APP_API_URL + 'managedvariables?group=TaxLabels',
      headers: {
          'content-type': 'application/json',
          'authorization': config.REACT_APP_API_SECRET
      }
  });
}

export function addCountry(country,auth) {
    return axios({
        method: 'POST', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + country.resourceID,
        data: country,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function addDivision(division, auth) {
    return axios({
        method: 'POST', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + division.resourceID,
        data: division,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function addProgram(program, auth) {
    return axios({
        method: 'POST', 
        url: process.env.REACT_APP_API_URL + 'programs/' + program.resourceID,
        data:  {
            id: program.id,
            resourceID: program.resourceID,
            name: program.name,
            divisionVariableID: program.divisionVariableID,
            acronym: program.acronym,
            active: program.active,
            
        },
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken,
        }
    });
}

export function addEmployeeDomain(domain, auth) {
    return axios({
        method: 'POST', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + domain.resourceID,
        data: domain,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function updateCountry(country, auth) {
    return axios({
        method: 'PUT', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + country.resourceID,
        data: country,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function updateDivision(division, auth) {
    SkbLogger.logDebugInfo("update division", division);
    return axios({
        method: 'PUT', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + division.resourceID,
        data: {
            active:division.active,
            id:division.id,
            parentVariableID: division.parentVariableID,
            resourceID: division.resourceID,
            variableGroup: division.variableGroup,
            variableName: division.variableName,
            variableValue: division.variableValue
        },
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function updateProgram(program, auth) {
    return axios({
        method: 'PUT', 
        url: process.env.REACT_APP_API_URL + 'programs/' + program.resourceID,
        data:  {
            id: program.id,
            resourceID: program.resourceID,
            name: program.name,
            divisionVariableID: program.divisionVariableID,
            acronym: program.acronym,
            active: program.active,
        },
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function delDivision(division, auth) {
    return axios({
        method: 'DELETE', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + division.resourceID,
        data: division,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
            
        }
    });
}

export function delProgram(program, auth) {
    return axios({
        method: 'DELETE', 
        url: process.env.REACT_APP_API_URL + 'programs/' + program.resourceID,
        data: program,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken

        }
    });
}

export function updateEmployeeDomain(d, auth) {
    return axios({
        method: 'PUT', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + d.resourceID,
        data: d,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

export function updateCountryPhone(phone, auth) {
    return axios({
        method: 'PUT', 
        url: process.env.REACT_APP_API_URL + 'managedvariables/' + phone.resourceID,
        data: {
            id: phone.id,
            resourceID: phone.resourceID,
            variableGroup: phone.variableGroup,
            variableName: "Phone Format",
            variableValue: phone.phPrefix + '|' + phone.landlineLength + '|' + phone.mobileLength + '|' + phone.mobilePrefix,//format setting, separated by '|'
            active: 1,
            parentVariableID: phone.parentVariableID
        },
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :auth.idToken
        }
    });
}

/**changeAuth0Password: call Auth0 API to send change password mail to user */
export function changeAuth0Password(userEmail) {
    return axios({
        method: 'POST', 
        url: 'https://'+process.env.REACT_APP_AUTH0_DOMAIN + '/dbconnections/change_password',
        headers: {
            'content-type': 'application/json',
        },
        data: {
            "client_id": config.REACT_APP_AUTH0_CLIENTID,
            "email": userEmail,
            "connection": "Username-Password-Authentication"
        }
    });
}
