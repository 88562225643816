import React from "react";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PersonIcon from '@material-ui/icons/Person';



const ContractorRepresentativePhoneEmailStructure = (props)=>{    
  
  //console.log("**********---props.dataContractorDetails=>>>>>>>>",props, " props.dataContractorDetails=>>> ",props.dataContractorDetails);            
  const {representative,contractorRepresentativePhone,taskId,representativeEmail,mailSubject,implementedIn} = props.dataContractorDetails;

  // For Phone
  const [openContrInfoPhn, setOpenContrInfoPhn] = React.useState(false);
  const anchorRefTaskInfoContrPhone = React.useRef(null);
  const [selectedIndexContrInfoPhn, setSelectedIndexContrInfoPhn] = React.useState(1);

  const handleTaskInfoContrPhoneMenuItemClick = (event, index) => {
      setSelectedIndexContrInfoPhn(index);
      setOpenContrInfoPhn(false);
  };

  const handleTogglePhone = () => {
    if(contractorRepresentativePhone){
      setOpenContrInfoEml(false);
      setOpenContrInfoPhn((prevOpen) => !prevOpen);
    }
    
  };

  const handleTaskInfoContrPhoneClose = (event) => {
      if (anchorRefTaskInfoContrPhone.current && anchorRefTaskInfoContrPhone.current.contains(event.target)) {
      return;
      }

      setOpenContrInfoPhn(false);
  };


  // For Email
  const [openContrInfoEml, setOpenContrInfoEml] = React.useState(false);
  const anchorRefTaskInfoContrEmail = React.useRef(null);
  const [selectedIndexContrInfoEml, setSelectedIndexContrInfoEml] = React.useState(1);

  const handleTaskInfoContrEmailMenuItemClick = (event, index) => {
      setSelectedIndexContrInfoEml(index);
      setOpenContrInfoEml(false);
  };

  const handleToggleEmail = () => {
    if(representativeEmail && mailSubject){
        setOpenContrInfoPhn(false);
        setOpenContrInfoEml((prevOpen) => !prevOpen);
    }
  };

  const handleTaskInfoContrEmailClose = (event) => {
      if (anchorRefTaskInfoContrPhone.current && anchorRefTaskInfoContrPhone.current.contains(event.target)) {
      return;
      }

      setOpenContrInfoEml(false);
  };


    return (
          <> 
          {implementedIn==="TASK_INFO_SCHEDULE" && (
            <>
              <Grid item container direction="row">
                <Link  ref={anchorRefTaskInfoContrEmail}
                
                // onClick={handleToggleEmail}                 
                // style={{"cursor":"pointer"}}
                
                >
                    <SkbIcon   icon={PersonIcon}/>                          
                </Link>  &nbsp;
                <Typography variant="body2">{representative}</Typography>
            </Grid>

              <Grid item container direction="row">
                <Link ref={anchorRefTaskInfoContrPhone} onClick={handleTogglePhone} style={{"cursor":"pointer"}} >
                  <SkbIcon  icon={PhoneIcon}/>
                </Link> &nbsp;
                <Typography variant="body2">{contractorRepresentativePhone}</Typography>
          </Grid>
                          
              <Grid container direction="column" alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
          
                  <Popper id="popphoneidtinfo" open={openContrInfoPhn} anchorEl={anchorRefTaskInfoContrPhone.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        
                        <Paper>
                          <ClickAwayListener onClickAway={handleTaskInfoContrPhoneClose}>
                            <MenuList id="contr-rep-phone-split-button-menu">
                                <MenuItem
                                  key={"tskinfocontrcinfophnemailphn-"+taskId}                              
                                  selected={1 === selectedIndexContrInfoPhn}
                                  onClick={(event) => handleTaskInfoContrPhoneMenuItemClick(event, 1)}
                                >                          
                                 
                                        <Link href={(contractorRepresentativePhone?"tel:"+contractorRepresentativePhone:contractorRepresentativePhone)}  variant="body2" >{contractorRepresentativePhone}</Link>

                                 

                                </MenuItem>   
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  {/* <Popper id="popemailidtinfo" open={openContrInfoEml} anchorEl={anchorRefTaskInfoContrEmail.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        
                        <Paper>
                          <ClickAwayListener onClickAway={handleTaskInfoContrEmailClose}>
                            <MenuList id="contr-rep-phone-split-button-menu-2">
                                <MenuItem
                                  key={"tskinfocontrcinfophnemaileml-"+taskId}                              
                                  selected={1 === selectedIndexContrInfoEml}
                                  onClick={(event) => handleTaskInfoContrEmailMenuItemClick(event, 1)}
                                >                                           
                                          

                                            <Link href={`${(representativeEmail?`mailto:${representativeEmail}?subject=${mailSubject}`:"")}`}  variant="body2" >{representativeEmail}</Link>
                                         


                                </MenuItem>   
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                 */}
                 
              </Grid>
          </Grid>

            </>
          )}
           {!(implementedIn) && (
            <>
            <Link  ref={anchorRefTaskInfoContrEmail} onClick={handleToggleEmail}  style={{"cursor":"pointer"}} >
              <SkbIcon   icon={EmailIcon}/>                          
            </Link>
            <Link ref={anchorRefTaskInfoContrPhone} onClick={handleTogglePhone} style={{"cursor":"pointer"}} >
              <SkbIcon  icon={PhoneIcon}/>
            </Link> &nbsp;
             {representative}
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
          
                  <Popper id="popphoneidtinfo" open={openContrInfoPhn} anchorEl={anchorRefTaskInfoContrPhone.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        
                        <Paper>
                          <ClickAwayListener onClickAway={handleTaskInfoContrPhoneClose}>
                            <MenuList id="contr-rep-phone-split-button-menu">
                                <MenuItem
                                  key={"tskinfocontrcinfophnemailphn-"+taskId}                              
                                  selected={1 === selectedIndexContrInfoPhn}
                                  onClick={(event) => handleTaskInfoContrPhoneMenuItemClick(event, 1)}
                                >                          
                                 
                                        <Link href={(contractorRepresentativePhone?"tel:"+contractorRepresentativePhone:contractorRepresentativePhone)}  variant="body2" >{contractorRepresentativePhone}</Link>

                                 

                                </MenuItem>   
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <Popper id="popemailidtinfo" open={openContrInfoEml} anchorEl={anchorRefTaskInfoContrEmail.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        
                        <Paper>
                          <ClickAwayListener onClickAway={handleTaskInfoContrEmailClose}>
                            <MenuList id="contr-rep-phone-split-button-menu-2">
                                <MenuItem
                                  key={"tskinfocontrcinfophnemaileml-"+taskId}                              
                                  selected={1 === selectedIndexContrInfoEml}
                                  onClick={(event) => handleTaskInfoContrEmailMenuItemClick(event, 1)}
                                >                                           
                                          

                                            <Link href={`${(representativeEmail?`mailto:${representativeEmail}?subject=${mailSubject}`:"")}`}  variant="body2" >{representativeEmail}</Link>
                                         


                                </MenuItem>   
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                
                 
              </Grid>
          </Grid>

            </>
          )}
          
           
        </> 
      );


};

export default ContractorRepresentativePhoneEmailStructure;