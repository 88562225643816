import React from 'react';

import AddSerialNumberManuallyDialog from './DialogAddSerialNumberManually';

import ListBarcode from './scan_components/ListBarcode';

import {SkbLogger,SeverityLevel} from '../../../services';

import * as misc from '../../../utils/misc';

import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';

import {useStocktakeTableStyles} from './stocktake/StepStockPreview';

/*
 * component: The Serialised Items Step captures serialised items scanned, entered or added to the consignment by the contractor.  
* @param {array}  serialisedItems: the scanned or picked serialised items
                    *       e.g.  [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134"
                    },...]
* @param {function} loadSerialisedItems: a function to load the serialised items.
 * @param {array}  knownSerialNumbers: the serial numbers that we think they may scan, with related stock code/description, 
 * so that we can recognise them.
 *              e.g.  [
                    {
                        SerialNumber: "133434324",
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                    },...]
 * 
* @param {function} addSerialisedItem: a function to save an added item.
* @param {function} updateSerialisedItem: a function to save an updated item.
* @param {function} deleteSerialisedItem: a function to delete an item from the count or consignment.
* @param {function} onChange: a function to trigger external actions when the user makes a change on this page.
 * @param {boolean} isLoading: Is data currently loading?  (used to show loading indicator.)
  * @param {function} OPTIONAL: retrievePhoto: an async function to retrieve the barcode photo for a scanned item (parameter scannedItem).
                    If not provided, the photo is assumed to be available in the property scannedItem.Photo.
* @param {object} taskObjectForLogging: an object to be passed to the logger containing relevant task information.
 * @param {text} moduleName: to be used in logging.
 * * @param {text} stepName: to be used in logging.
 */
export default function StepSerialisedItems(props) {
 
    //These state properties for the validation warning dialog (confirmation) box.
    const [openWarning, setOpenWarning] = React.useState(false);
    const [warningMsg, setWarningMsg] = React.useState("");
    const [warningTitle, setWarningTitle] = React.useState("");
   // const [okHandler, setOkHandler] = React.useState(()=>{alert('original unchanged!');});  //Ideally would pass in the callback function for processing an OK response.

    const [openAddManual, setOpenAddManual] = React.useState(false);

    //These state properties for the validation error dialog (alert) box.
    const [openError, setOpenError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [errorTitle, setErrorTitle] = React.useState("");
    
    //This to hold the pending item for adding while waiting for confirmation from user.  
    const [pendingItemToAdd, setPendingItemToAdd]=React.useState(null);


    const handleClickOpenAdd = () => {
      setOpenAddManual(true);
    };

    function showErrorDialog(title, message)
    {
      setErrorTitle(title);
      setErrorMsg(message);
      setOpenError(true);
    }

    function showWarningDialog(title, message, okHandler)
    {
      setWarningTitle(title);
      setWarningMsg(message);
   
     // setOkHandler(okHandler); //Ideally would pass in the callback function for processing an OK response.
      setOpenWarning(true);

    }


    //Ultimately the validation here should move into a separate class for all error/warning validation.
    function validateManualAdd(newItem){

      var msg="";

      newItem.serialNumber=newItem.serialNumber.trim();
      
      var serialisedItems=props.serialisedItems;

      var found=serialisedItems.some(i=>i.SerialNumber===newItem.serialNumber);
             
        if (found){
          msg = "Attempting to enter a duplicate serial (typed in): "+ newItem.serialNumber+'.';
          SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Verbose, props.stepName, msg, {subTask: props.taskObjectForLogging, stockItem: newItem, userObject: misc.getUserObject()});

          showErrorDialog("Duplicate Serial Number","We have detected that a duplicate serial number has been entered. Please check that you are entering the correct serial number.");
          
        }
        else{
          //look up serial number in known list
          var existingSerialNumbers=props.knownSerialNumbers;

          var inStockItem = existingSerialNumbers.find(item => item.SerialNumber === newItem.serialNumber);
          if (inStockItem != undefined){  //item recognised
            newItem.StockCode=inStockItem.StockCode;
            newItem.Description=inStockItem.Description;

            msg = "Attempting to enter a recognised serial number (typed in): "+ newItem.serialNumber+'.';
            SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Info, props.stepName, msg, {subTask: props.taskObjectForLogging, stockItem: newItem, userObject: misc.getUserObject()});
 
            processAdd(newItem);
          }
          else{  //item not recognised.

              setPendingItemToAdd(newItem);

              msg = "Attempting to enter a unrecognised serial number (typed in): "+ newItem.serialNumber+'.  Awaiting user confirmation.';
              SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Info, props.stepName, msg, {subTask: props.taskObjectForLogging, stockItem: newItem, userObject: misc.getUserObject()});
 
              showWarningDialog("Unknown Serial Number",
              "Unknown serial number detected. This may have occurred due to entering the wrong serial number, or the stock may have been previously lost or transferred.  \n\nAre you sure you want to add this stock to your stock holding?")
             
          }
        }

    }
      
    function processAddPending()
    {
        if (pendingItemToAdd!==null)
        {
          var msg = "User has confirmed the addition of unrecognised serial number (typed in): "+ pendingItemToAdd.serialNumber+'.';
          SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Info, props.stepName, msg, {subTask: props.taskObjectForLogging, stockItem: pendingItemToAdd, userObject: misc.getUserObject()});

          processAdd(pendingItemToAdd);
          setPendingItemToAdd(null);
        }
    }

    function processAdd(newItem){
    //  debugger;

      if(newItem.StockCode===''||newItem.StockCode===null||newItem.StockCode===undefined)
      {
        newItem.StockCode='UNKNOWN';
      }

      var description=newItem.Description;
        if (description===null||description===undefined||description==='')
        {
          description=`Unknown with serial ${newItem.serialNumber}`;
          newItem.Description='To Be Determined';
        }

        var msg = "Attempting to save new item (typed in): "+ newItem.serialNumber+'.';
        SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Verbose, props.stepName, msg, {subTask: props.taskObjectForLogging, stockItem: pendingItemToAdd, userObject: misc.getUserObject()});

        props.addSerialisedItem( { SerialNumber: newItem.serialNumber,
                                          StockCode:newItem.StockCode,
                                          Description: newItem.Description,
                                          ItemStatus: newItem.status,
                                          IsScanned:0});
      

        SkbLogger.userAuditEvent(props.moduleName, misc.getCurrentUserEmail(), props.stepName, 'Manual add serialised item', misc.LogResult.success,
            '{userObject} has successfully entered {stockObject} at {locationObject} while {networkObject}.'
            , {
                userObject: misc.getUserObject(),
                stockObject: misc.buildStockObjectForLog('Serialised', newItem.StockCode,description),
                locationObject: misc.getLocationObject(),
                networkObject: misc.getNetworkObject()
            });

            props.onChange();
     

      }

const classes = useStocktakeTableStyles();

    return (
        <div className={classes.table}>
          <ListBarcode handleClickOpen={handleClickOpenAdd} onChange={props.onChange}
          serialisedItems={props.serialisedItems} loadSerialisedItems={props.loadSerialisedItems} 
          knownSerialNumbers={props.knownSerialNumbers} 
          addSerialisedItem={props.addSerialisedItem} 
          updateSerialisedItem={props.updateSerialisedItem} 
           deleteSerialisedItem={props.deleteSerialisedItem} 
           retrievePhoto={props.retrievePhoto}
           isLoading={props.isLoading}
           taskObjectForLogging={props.currentSubTask} moduleName={props.moduleName}
           stepName={props.stepName}
          ></ListBarcode>

          <AddSerialNumberManuallyDialog id="add-dialog" title="Add Stock Item" message="" open={openAddManual} openConfirm={setOpenAddManual} okHandler={validateManualAdd}  
         noOptionsText='There are no more available items in scope of the stocktake.'/>

        <SkbConfirmDialog id="warning-dialog" message={warningMsg} title={warningTitle} open={openWarning} openConfirm={setOpenWarning} 
              okHandler={processAddPending}
                okLabel="Yes" cancelLabel="No" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />

          <SkbAlertDialog id="error-dialog" title={errorTitle} message={errorMsg} open={openError} openAlert={setOpenError}  />
                              
    </div>
        
          );
          }


          
