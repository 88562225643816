import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as auth from '../Redux/actions/auth';
import * as api from '../services/ApiService';
import * as SkbLogger from '../services/SkbLogger';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider, Hidden } from '@material-ui/core';
import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE } from '../skb_controls/Themes';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: `calc(100vw - ${theme.spacing(6)}px)`,
        maxHeight: '100vh',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(3, 1, 3),
        flexDirection: "column",
    },


    icon: {
        minWidth: theme.spacing(1),
        marginRight: 4,
        marginTop: 4
    }
}));


function TermsAndCons(props) {
    const classes = useStyles();
    const [terms, setTerms] = React.useState('');
    React.useEffect(() => {
        api.getTermAndConditions().then((res) => {

            var data = res.data;
            SkbLogger.logDebugInfo('terms', data[0].variableValue);
            if (Array.isArray(data)) setTerms(data[0].variableValue);
        }).catch((error) => {

        })
    }, [terms]);

    return (
        <ThemeProvider theme={LightTheme}>

            <div className={classes.root}>
                <Typography id="tcheader" paragraph variant="h2">Terms & Conditions</Typography>

                <Typography id="large-body" paragraph variant="body2">
                    {terms}
                </Typography>
            </div>
        </ThemeProvider>
    );
}

export default TermsAndCons;