import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from '../../serviceWorker';
import { StoreProvider } from '../../Redux/StoreProvider';
import { ThemeProvider, Hidden } from '@material-ui/core';
import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE } from '../../skb_controls/Themes'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import { Auth0Provider } from "@auth0/auth0-react";
import RequireAuth from '../auth/requiredAuth'
import SignIn from '../auth/SignIn';
import Logout from '../auth/Logout';

import MainRegistration from './MainRegistration';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loading from '../Loading';
import TermsAndCons from '../TermsAndCons';
import Crypt from '../Crypt';
import { config } from '../../utils/config';
import { RegistrationLayout } from '../layout/RegistrationLayout';
import { SnackbarProvider } from 'notistack';

export default function RegistrationWithTheme({ user, state, changeTheme }) {

    const theme = useTheme();

    return (
        <SnackbarProvider theme={state.theme}>

            {/* <Container maxWidth='sm' align='middle' className={classes.root}> */}

            <RegistrationLayout user={user} state={state} changeTheme={changeTheme} id="leftnavbar" >
                <MainRegistration />
            </RegistrationLayout>
        </SnackbarProvider >

    );
}