import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
//import { useAuth0 } from "@auth0/auth0-react";
import { changeAuth0Password } from '../../services/ApiService';
import * as authActions from '../../Redux/actions/auth';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import SkbAlertDialog from './SkbAlertDialog';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        },
    paper: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        "& li:hover": {
            color: theme.palette.text.highlighted,
        }
    },
    profilebutton:{
        //paddingTop:"0px !important",
        paddingRight:"0px !important",
        paddingLeft: "8px!important",
        //paddingBottom:"8px!important"
    },
    button: {
        color: theme.palette.primary.main,
       
        [theme.breakpoints.down('sm')]: {
            height: 24,
            width: 24
        },
        [theme.breakpoints.up('sm')]: {
            height: 30,
            width: 30
        },
        display: "inline",
    },
    menuicon: {
        color: theme.palette.primary.main,
        display: "block",
        [theme.breakpoints.down('sm')]: {
            height: 25,
            width: 25
        },
        [theme.breakpoints.up('sm')]: {
            height: 50,
            width: 50
        },
        display: "inline",
    },
    
    popper: {

    },
    profileicon:{
        alignSelf: "center",
    },
    alighleft:{
        justifyContent:"flex-end",
        minHeight:25,
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(0),
    }
}));

/** Profile menue items*/
function Profile({ user }) {
    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            <Grid item xs className={classes.profileicon}>
                <Avatar src="{user.AuthUser.picutre}"/>
            </Grid>
            <Grid item xs container direction={"column"}>
                <Grid item xs>
                    <Typography variant="h6">
                        {user.User.name}

                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body2">
                        {user.AuthUser.email}

                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
export function SkbProfileMenu({ user, changePassword, auth0 }) { //auth0 client from index.js through SkbLayout
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    //change password menu item is only avaible for user registered as auth0 account, not for federation users in auth0, if value of sub field starts with "auth0" will be Auth0 accoujnt
    var canChangePwd = false;
    if (user && user.AuthUser) canChangePwd = /^auth0\|/ig.test(user.AuthUser.sub);
    
    const [showChangePwd, setShowChangePwd] = React.useState(canChangePwd);

    const [toShowAbout, setToShowAbout] = React.useState(false);

    const [toShowCompanyLocations, setToShowCompanyLocations] = React.useState(false);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    const { enqueueSnackbar } = useSnackbar();

    const handleNotifycation = (message, variant) => {
        enqueueSnackbar(message, { 
            variant: variant
        })};

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const signOut = event => {
        handleClose(event);
        localStorage.clear();
        sessionStorage.clear();
        auth0.logout();
    }
    const changePwd = event => {
        handleClose(event);
        //
        changePassword(user.AuthUser.email);
        handleNotifycation('A change password email has been sent to your registered email!', 'success')
    }

    const showAbout = async (event) => {
        
        handleClose(event);
        //
        setToShowAbout(true);
    }

    const showCompanyLocations = async (event) => {
        
        if(user.User.company && user.User.company.name){
            handleClose(event);
            //
            setToShowCompanyLocations(true);
        }
        
    }

    const handleAboutDialog = () =>{
        setToShowAbout(false);
    }

    const handleCompanyLocationsDialog = () =>{
        setToShowCompanyLocations(false);
    }

    //when tab key is press, hide the dropdown menu
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open, user]);

    let companyInfoLine1='No Business Name';
    let companyInfoLine2='';
    let locationMessage='No company locations';
    console.log(user);
    if(user && user.User.company && user.User.company.name){
        if(user.User.company.name.length<=30){
            companyInfoLine1=user.User.company.name;
        }else{
            companyInfoLine1=user.User.company.name.substring(0,27)+"...";
        }
        companyInfoLine2="ABN "+user.User.company.taxNumber;
        //locationMessage=user.User.company.postalAddress;
        if(user.User.company.locationSummary && user.User.company.locationSummary.length){
            const arrayLocation=JSON.parse(user.User.company.locationSummary);
            const locationString = arrayLocation.map(element => ' • '+element.LocationCode+' '+element.LocationDescription).join('\n');
            locationMessage=locationString;
        }
    }
    

    return (
        <div className={classes.root}>
            <div>
                {/*profile icons on Minerva app bar */}
                <Tooltip title="profile">
                    <IconButton
                        className={classes.profilebutton}
                        id="profile-menu"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        <AccountCircleOutlinedIcon className={classes.button} />
                    </IconButton>
                </Tooltip>

                {/**dropdwon menu items */}
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem id="profile-menu-profile-option">
                                            <Profile user={user} />
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem  className={classes.alighleft} id="profile-menu-company-info1" onClick={showCompanyLocations}>{companyInfoLine1}</MenuItem>
                                        <MenuItem  className={classes.alighleft} id="profile-menu-company-info2">{companyInfoLine2}</MenuItem>
                                        <Divider />
                                        {showChangePwd && <MenuItem  className={classes.alighleft} id="profile-menu-changepassword-option" onClick={changePwd}>Change Password</MenuItem>}
                                        <MenuItem  className={classes.alighleft} id="profile-menu-about-option" onClick={showAbout}>About</MenuItem>
                                        <MenuItem  className={classes.alighleft} id="profile-menu-logout-option" onClick={signOut}>Log Out</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                

            </div>
 
            <SkbAlertDialog 
                    id="about-dialog" 
                    title="Skybridge Minerva" 
                    message=
{`

Version: ${process.env.REACT_APP_VERSION}
Release Date: ${process.env.REACT_APP_BUILD_DATE}
${process.env.REACT_APP_ENV_TYPE=='prod'?'':'Environment: '+process.env.REACT_APP_ENV_TYPE.toUpperCase()}

`} 
                    open={toShowAbout} 
                    openAlert={handleAboutDialog} 
                   />

                <SkbAlertDialog 
                    id="companylocation-dialog" 
                    title={user.User.company.name+' (ABN '+user.User.company.taxNumber+')'} 
                    message=
{`

${locationMessage}

`} 
                    open={toShowCompanyLocations} 
                    openAlert={handleCompanyLocationsDialog} 
                   />

        </div>
    );
}
