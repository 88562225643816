import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import SkbIcon from '../components/SkbIcon';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box pt={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        position: 'relative',

        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.default,
    },
    appBar: {
        // padding: theme.spacing(0),
        // margin: theme.spacing(0),

        [theme.breakpoints.down(331)]: {
            position: "relative",
            marginTop: theme.spacing(1),
        },
        
        [theme.breakpoints.between(332, 389)]: {
            position: "relative",
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.between(390, 599)]: {
            position: "relative",
            marginTop: theme.spacing(1),
        },        
        // [theme.breakpoints.up('lg')]: {

        //     position: "relative",
        //     marginTop: theme.spacing(2),
        // },
        [theme.breakpoints.up('sm')]: {
            position: "sticky",
            marginTop: theme.spacing(2),
        },
        // [theme.breakpoints.up('390px')]: {
        //     position: "relative",
        //     marginTop: theme.spacing(2),
        // },
        

        
         paddingTop: 4,
        backgroundColor: theme.palette.background.default,

    },
    scroller: {
        width: 0
    }
}));
/**
 * @property {header}: title info of the page, i.e. <SkbTabMenu header={<Typography variant="h1">{<SkbIcon  icon={FontDownloadIcon} />}<b>Typography</b></Typography>}>
 * @property {children}: children , tabs information, it can be any valid html element with attribute "lable", i.e. <item label="Heading"></item> 
 */
export default function SkbTabMenu({ children, header }) {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    let cld = Array.isArray(children) ? children : [children];
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    });
    if (!cld || cld.length === 0) return header;
    return (
        <nav className={classes.root}>

            <AppBar className={classes.appBar} color="transparent">
                {header}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    classes={{ scroller: classes.scroller }}
                >
                    {cld.map((i, idx) => (
                        <Tab label={i.props["label"]} {...a11yProps(idx)} />
                    ))}

                </Tabs>
            </AppBar>
            {cld.map((i, idx) => (
                <TabPanel value={value} index={idx}>
                    {i.props["children"]}
                </TabPanel>
            ))}


        </nav>
    );
}
