import React, { useEffect,  useState } from 'react';

import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import { tableIcons } from '../_share/tableIcons';

import { Link } from '@material-ui/core';

// import { StocktakeSteps } from '../Stocktake';

import * as misc from '../../../utils/misc';

import { alphabeticalSort } from './StepStockReview';
import Loading from '../../Loading';
import {useStocktakeTableStyles} from './stocktake/StepStockPreview';



/**
 * components: The Stock Preview Step displays the stock we expect the contractor to count or pick prior to his doing the count/picking.  
 * @param {array}  previewInfo: the stock preview data.
 *              e.g.  [
 *  {
                  StockCode: "EROD0001",
                  Description: "Ericsson Wireless Outdoor Unit",
                  StockType: "Serialised",
                  Condition: "Good",
                  Qty: 20,
                  QtyUnit: "Each",
              },
              {
                  StockCode: "ERMI0001",
                  Description: "Ericsson Wireless Wall Plate Kit",
                  StockType: "Non-Serialised",
                  Condition: "Faulty",
                  Qty: 30,
                  QtyUnit: "Each",
              },
                    {
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                        StockType: "Serialised",
                        Qty: 100
                    },
                    {
                        StockCode: "SKB001",
                        Description: "Cable Type",
                        StockType: "Non-Serialised",
                        Qty: 200,
                    },...]
 * @param {function} loadPreviewData: it's a callback function to load the data for previewInfo.
* @param {array}  columnHeaders: the columnHeaders in order ['Description','UOM','Expected Status','# To Be Returned']

 * @param {function} goToStep: A function to navigate between the preview step and the related serialised and non-serialised steps in the same process.
 *                              Parameters stepId, selectedStockCode (to be highlighted on arrival at the new step).
 *                  OPTIONAL: If not supplied, there will be no hyperlink for the stock description.  It will just display as plain text.  
 * @param {number} serialisedStepId: The Id of the serialised step, to be used for cross-navigation.  Required only if goToStep provided.
 * @param {number} nonSerialisedStepId: The Id of the non-serialised step, to be used for cross-navigation.  Required only if goToStep provided.
 * @param {text} emptyDataMessage: text to display when there is no data.
 * @param {boolean} isLoading: Is data currently loading?  (used to show loading indicator.)

 * @returns 
 */

export default function StepStockPreview(props) {
  //default values:
  var noDataMessage=props.emptyDataMessage;

  if(!noDataMessage)
  {
      noDataMessage='No previous stock items found!';
  }
  var columnNames=props.columnHeaders;

  if (!columnNames)
  {
      columnNames= ['Description','UOM','Condition','Qty'];
  }
  //end default values.


  useEffect(() => {
    props.loadPreviewData();
  }, []);


  const [data, setData] = useState([]);

  //set up table columns
var tableColumns=[
  { title: 'StockCode', field: 'StockCode', hidden: true, editable: 'never' },
  {
    title: columnNames[0], field: 'Description', hidden: false, editable: 'never', width: '40%', align: 'left',
    render: rowData => (
      props.goToStep===undefined?
      rowData.Description
      :
      (rowData.StockType === "Serialised" ?
        <Link id={`serialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.serialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
        :
        <Link id={`nonserialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.nonSerialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
        )
    )
  },
  // { title: 'Stock Type', field: 'StockType', hidden: false, editable: 'never', width: '20%', align: 'center' },
  { title: columnNames[1], field: 'QtyUnit', hidden: false, editable: 'never', width: '5%', align: 'left' },
  
  { title: columnNames[2], field: 'Condition', hidden: false, editable: 'never', width: '20%', align: 'center' },
  { title: columnNames[3], field: 'Qty', hidden: false, editable: 'never', width: '10%', align: 'center' }
  
];

  const [columns, setColumns] = useState(tableColumns);

  const classes = useStocktakeTableStyles();

  useEffect(() => {
    if (props.previewInfo) {
      let previewItemsFiltered = props.previewInfo.filter(i => i.Qty !== 0);
      let copyOfPreviewItems = previewItemsFiltered.map(d => ({ ...d }))//material table needs to add extra column in the array object,however the array from store is not extendable, clone the array to avoid the issue
      .sort(alphabeticalSort);
      setData(copyOfPreviewItems);
    }
  }, [props.previewInfo]);


  return (

    <div id="preview-table" className={classes.table} >

      <MaterialTable
        icons={tableIcons}
        showTitle={false}
        searchable={false}
        toolbar={false}
        columns={columns}
        data={data}
        padding={'dense'}

        options={{
          toolbar: false,
          showTitle: false,
          search: false,
          padding: 'dense',
          paging: false,

          headerStyle:
          {
            fontWeight: "bolder",
            fontSize: "1.2em",

          }

        }

        }
        localization={{ body: { emptyDataSourceMessage: noDataMessage } }}
      />
      {/* display loading indicaiton when store state is updating  */}
      {props.isLoading && <Loading />}
    </div>

  );
}

