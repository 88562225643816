import types from './types';
import generateRequestID from '../../utils/ThreePLImportation/generateRequestID';

const INITIAL_STATE = {
    fileName : "",
    warehouse_locations: [],
    loading_warehouse_locations: false,
    warehouse_locations_error: null,

    CSV_json_data : null,
    processingCSV : false,
    csv_processing_error : null,
    
    csv_data_visible : false,

    three_pl_importation_success : null,
    processing_importation : false,
    three_pl_importation_error : null,

    request_id : null,

    outcomes : null,
    laoding_outcomes : false,
    outcomes_error : null,    
}



const threePLImportationReducer = (state = INITIAL_STATE , action) => {

    switch(action.type){
        case types.FETCH_OUTCOMES_START:
            return {
                ...state,
                outcomes : null,
                laoding_outcomes : true,
                outcomes_error : null,
            }
        case types.FETCH_OUTCOMES_SUCCESS:
            return {
                ...state,
                outcomes : action.payload,
                laoding_outcomes : false,
            }
        case types.FETCH_OUTCOMES_ERROR:
            return {
                ...state,
                laoding_outcomes : false,
                outcomes_error : action.payload,
            }
                
        case types.CLEAR_REQUEST_ID:
            return {
                ...state,
                request_id : null,
            }
        case types.SET_NEW_REQUEST_ID:

            return {
                ...state,
                request_id : generateRequestID(5)
            }
        case types.THREE_PL_IMPORTATION_START:
            return {
                ...state,
                three_pl_importation_success : null,
                processing_importation : true,
                three_pl_importation_error : null,
            }
        case types.THREE_PL_IMPORTATION_SUCCESS:
            return {
                ...state,
                three_pl_importation_success : action.payload,
                processingCSV: false,
                processing_importation : false,
            }
        case types.THREE_PL_IMPORTATION_ERROR:
            return {
                ...state,
                processing_importation : false,
                processingCSV: false,
                three_pl_importation_error : action.payload,
            }
        case types.FETCH_LOCATIONS:
            return {
                ...state,
                warehouse_locations: [],
                loading_warehouse_locations: true,
                warehouse_locations_error: null,
            }
        case types.FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                warehouse_locations: action.payload,
                loading_warehouse_locations: false,
                warehouse_locations_error: null
            }
        case types.FETCH_LOCATIONS_ERROR:
            return {
                ...state,
                warehouse_locations: [],
                loading_warehouse_locations: false,
                warehouse_locations_error : action.payload
            }
        case types.TOGGLE_VIEW_CSV_DATA:
            return {
                ...state,
                csv_data_visible : !state.csv_data_visible,
            }
        case types.READ_CSV_START:
            return {
                ...state,
                processingCSV : true,
                fileName : action.payload,
                // CSV_json_data : null,
                csv_processing_error : null,
            }
        case types.READ_CSV_SUCCESS:
            return {
                ...state,
                processingCSV : false,
                CSV_json_data : action.payload,
            }
        case types.READ_CSV_ERROR:
            return {
                ...state,
                processingCSV : false,
                csv_processing_error : action.payload
            }
        case types.CLEAR_UPLOAD_ERROR: 
            return {
                ...state,
                csv_processing_error: null,
            }
        case types.CLEAR_UPLOAD_FILE: 
            return {
                ...state,
                fileName: "",
                CSV_json_data: null,
                csv_processing_error: null,
                request_id: null
            }
        case types.SET_PROCESSING:
                return {
                    ...state,
                    fileName: "",
                    processingCSV : action.payload,
                }
        case types.SET_SERIALIZED:
            return {
                ...state,
                serialized : action.payload,
            }
        case types.DELETE_CSV_FILE:
            return {
                ...state,
                // fileName : "",
                // CSV_json_data : null,
                // request_id : null,
                three_pl_importation_success : null,
                three_pl_importation_error : null,
            }
        case types.SELECT_CSV_FILE:

            return {
                ...state,
                fileName : action.payload,
            }

        default:
            return  {
                ...state,
            }
    }


}

export default threePLImportationReducer;
