import React from "react";
//import { useAuth0 } from "@auth0/auth0-react";
import { SkbLogger } from "../../services";

const SignIn = (props) => {
  const {auth0} = props; //auth0 client from index.js
  
     React.useEffect(() => {

      auth0.loginWithRedirect({responeType:"token",redirect_uri: window.location.origin})
      .then( (theToken) => {
         SkbLogger.logDebugInfo('theToken after loginWithRedirect',theToken);
       });
      
    });
  

    return (<div/>);
};

export default SignIn;