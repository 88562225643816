// import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import  StepNonSerialisedItems  from './stock_components/StepNonSerialisedItems';  //This is the default export, not necessarily the one named StepNonSerialisedItems.  
//                                                                 //Most often will be the connect-wrapped one.
// import  StepSerialisedItems  from './StepSerialisedItems';
// import  StepStockPreview  from './StepStockPreview';
// import  StepStockReview  from './StepStockReview';

// import * as actions from '../../Redux/actions/tasks';

// import { connect } from 'react-redux';


export const StocktakeSteps = {
  PREVIEW:1,
  SERIALISED:2,
  NON_SERIALISED:3,
  REVIEW:4,
  SIGNATURE:5
}

// const useStyles = makeStyles(theme => ({
//     root: {
//         // '& .MuiTextField-root': {
//         //     margin: theme.spacing(1),
//         //     maxWidth: 300,
//         // },
//         maxWidth: 345,
//     },

//     // formControl: {
//     //     margin: theme.spacing(1),
//     //     maxWidth: 120,
//     // },
//     // selectEmpty: {
//     //     marginTop: theme.spacing(2),
//     // }
//     // ,
//     media: {
//         height:250
//         //maxHeight: 200,
//      //   width: 150,
//         //paddingTop: '56.25%', // 16:9
//       },

//       middle: {display: 'flex',
             
//             justifyContent: 'center'
//           }
// }));

// export function Stocktake(props) {

   
  

// const currentStep=StocktakeSteps.PREVIEW;
//  //debugger;
//    //const { currentStep, goToStep } = props;
// //    const { Modules } = props.user;
// //  alert(Modules.count);
// //   alert('current step'+currentStep);

// //   let actualCurrentStep=currentStep

// //   if (actualCurrentStep===undefined)
// //     {
// //         actualCurrentStep=StocktakeSteps.REVIEW;
// //         props.goToStep(_mockTaskState,actualCurrentStep);

// //     }

//     const classes = useStyles();

//     var selectedComponent=<StepStockPreview/>;

//     switch(currentStep)
//     {
//       case StocktakeSteps.PREVIEW:
//         selectedComponent=<StepStockPreview/>;
//         break;
//       case StocktakeSteps.SERIALISED:
//         selectedComponent=<StepSerialisedItems/>;
//         break;
//       case StocktakeSteps.NON_SERIALISED:
//         selectedComponent=<StepNonSerialisedItems/>;
//         break;
//       case StocktakeSteps.REVIEW:
//         selectedComponent=<StepStockReview/>;
//         break;
//       case StocktakeSteps.SIGNATURE:
//           selectedComponent=<StepStockReview/>;
//         break;
//       default:
//         selectedComponent=<StepStockPreview/>;
//         break;
//     }

//     return (
//         <div>
//           {selectedComponent}
//           {/* Stocktake Module  */}
//          {/* <StepNonSerialisedItems/> */}
//          {/* <StepStockPreview/>   */}
//           </div>);
// }

//       const mapStateToProps = state => ({
//         wholeTaskState: state,
//          currentStep:state.tasks.Tasks[0].SubTasks[0].CurrentStep.StepId,
//       // user: state.auth.User
//           user:state.auth
//       });
      
//       const mapDispatchToProps = (dispatch) => {
//         return {
//             goToStep:   (taskState,stepId) => {alert('go to step'+stepId);}//dispatch(actions.changeStep(taskState, stepId)),
      
//           //updateTaskStep: () => dispatch(variablesActions.getCountries()),
//           //  addCountry: (c, auth) => dispatch(variablesActions.addCountry(c, auth)),
//           // delCountry: (c, auth) => dispatch(variablesActions.delCountry(c, auth)),
//           // updateCountryPhone: (p, auth) => dispatch(variablesActions.updateCountryPhone(p, auth)),
//           //updateNonSnItem: (item)=>dispatch(actions.updateNonSnItem(item))
//         }
//       }
      
      
//       export default connect(mapStateToProps, mapDispatchToProps)(Stocktake);
      
