import Dexie, { DBCoreRangeType } from 'dexie';
import { SeverityLevel,SkbLogger } from '../services';
import * as localDB from '../utils/localDB';
import uuid from 'react-uuid';
import {crypts} from '../utils/crypts';
import * as syncAPI from './DataSyncWebAPI';
import { config } from '../utils/config';
import axios from "axios";
import { common } from '@material-ui/core/colors';
import { sync } from '../../src/Redux/reducers/sync';

var localDbInitialised = 0;
var auth0UserID='';
var idToken='';
var apiKey='';
var sas_azureStorageKey='';

function initLocalDBIfNotYet(){
    //stop re-init
    if(localDbInitialised) return;

    //localDB is supposed to be initialised after login
    idToken = crypts.decrypt(localStorage.getItem('id_token'));
    apiKey= config.REACT_APP_API_SECRET;
    const authUser = JSON.parse(crypts.decrypt(localStorage.getItem('auth_user')));
    sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
    if(authUser) auth0UserID = authUser.sub;

    if(authUser && auth0UserID){
        SkbLogger.logDebugInfo("init DB...",auth0UserID);
        localDB.initForUser(auth0UserID);
        localDbInitialised=1;
    }
}

export async function frontendSync(){
    //push before pull
    const currentTimestamp=new Date().getTime();
    const lastTimestamp=localStorage.getItem('last-frontendSync-time');
    if(!lastTimestamp || lastTimestamp<currentTimestamp-2*60*1000){  //2 minutes
        SkbLogger.logDebugInfo("start frontendSync",lastTimestamp,currentTimestamp);
        await pushDataToServer();
        await pullDataFromServer();
        localStorage.setItem('last-frontendSync-time',(new Date().getTime()));
    }else{
        SkbLogger.logDebugInfo("skip too freqently frontendSync",lastTimestamp,currentTimestamp);
    }
    
}

export async function pullDataFromServer(){
    initLocalDBIfNotYet();
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    const latestUnchangedClusterVersion = await localDB.getLastestUnchangedCluster();// queryForLastestUnchangedCluster();
    var fetchURLForGetChanges;

    var downloadDaysSettingForChangesSync = 3; //default
    var pastDaysSettingForChangesSync = 1; //default

    const settingClusterArray=await localDB.getDataClusterForEntityName('SETTING');
    if(settingClusterArray && Array.isArray(settingClusterArray) && settingClusterArray.length>0){
        const downloadDaysArray = await localDB.getDataItemsByKeysForCluster(settingClusterArray[0].resourceID,'DownloadMetadataInDays');
        //console.log('getDataItemsByKeyInCluster DownloadMetadataInDays',downloadDaysArray);
        if(downloadDaysArray && Array.isArray(downloadDaysArray) && downloadDaysArray.length>0){
            const downloadDaysValue=parseInt(downloadDaysArray[0].value);
            if(downloadDaysValue && !isNaN(downloadDaysValue) && downloadDaysValue>0){
                //console.log('set downloadDaysSettingForChangesSync',downloadDaysValue);
                downloadDaysSettingForChangesSync=downloadDaysValue;
                pastDaysSettingForChangesSync=downloadDaysValue / 3;
            }
        }
    }

    var startDate=new Date();
    startDate.setDate(new Date().getDate() - pastDaysSettingForChangesSync);
    var endDate=new Date()
    endDate.setDate(new Date().getDate() + downloadDaysSettingForChangesSync);
    const startDateStr=startDate.toISOString();
    const endDateStr=endDate.toISOString();
    //console.log('startDateStr',startDateStr);
    const startYyyymmdd = startDateStr.substring(0,10);
    const endYyyymmdd = endDateStr.substring(0,10);
    //console.log('startYyyymmdd',startYyyymmdd);

    if(latestUnchangedClusterVersion>0){
        //use version to GET
        fetchURLForGetChanges=process.env.REACT_APP_API_URL //envItemsForChangesSync.REACT_APP_API_URL 
                                + 'users/' + auth0UserID //authUserForChangesSync.sub 
                                + '/datacluster_changes?'
                                + 'startdate='+startYyyymmdd
                                + '&enddate='+endYyyymmdd
                                + '&fromversion='+latestUnchangedClusterVersion;
    }else{
        fetchURLForGetChanges=process.env.REACT_APP_API_URL//envItemsForChangesSync.REACT_APP_API_URL 
                                + 'users/' +auth0UserID// authUserForChangesSync.sub 
                                + '/datacluster_changes?'
                                + 'startdate='+startYyyymmdd
                                + '&enddate='+endYyyymmdd
                                + '&fromversion=0';
    }
    //get changes
    var updatedClusters;
    var clustersToBeDeletedByServer=[];
    var clustersToBeIgnoredAsDeletedButNotOwned=[];
    var clustersToBeSaved=[];
        try {
            SkbLogger.logDebugInfo('pullDataFromServer: fetchURLForGetChanges:',fetchURLForGetChanges);
            
            const jsonString = await axios.get(fetchURLForGetChanges);
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Download Data - UI','{response}(dataCluster) has been received through front end sync API.',{response:jsonString});
            /* const jsonString =await skbFetch(fetchURLForGetChanges,{
                                method: 'GET', 
                                headers: {
                                    'content-type': 'application/json',
                                    'authorization': apiKeyForChangesSync,
                                    'X-CustomHeader' :idTokenForChangesSync
                                }
                            }); */
            if(jsonString){
                updatedClusters=jsonString.data;//JSON.parse(jsonString.data);
                if(updatedClusters && Array.isArray(updatedClusters) && updatedClusters.length>0 && updatedClusters[0].resourceID){
                    //sorting by version, so that lower version can be processed ealier
                    updatedClusters.sort( (a, b) => {
                        const aVersion=a.version ? a.version : 0;
                        const bVersion=b.version ? b.version : 0;   
                        if(aVersion>bVersion) return 1;
                        else if(aVersion<bVersion) return -1;
                        else return 0;   
                } );
                    //refresh data as per server returns
                    for (let k = 0; k < updatedClusters.length; k++) {
                        const oneUpdatedCluster = updatedClusters[k];
                        if(oneUpdatedCluster.resourceID){
                            //check un-sent local changes
                            const oneExistingCluster = await localDB.getDataClusterByResourceID(oneUpdatedCluster.resourceID);//getDataClusterWithResourceID(oneUpdatedCluster.resourceID);
                            if (oneExistingCluster && localDB.checkFurtherLocalChange(oneExistingCluster, oneUpdatedCluster)){
                                //do nor overwrite unsent local changes
                                SkbLogger.logDebugInfo('pullDataFromServer: not to overwrite unsent local-changed cluster',oneExistingCluster);
                            }else{
                                if(oneUpdatedCluster.operation && oneUpdatedCluster.operation=='D'){
                                    if(oneExistingCluster && oneExistingCluster.resourceID){
                                        clustersToBeDeletedByServer.push(oneUpdatedCluster);
                                        SkbLogger.logDebugInfo('pullDataFromServer: cluster to be deleted',oneUpdatedCluster);
                                    }else{
                                        clustersToBeIgnoredAsDeletedButNotOwned.push(oneUpdatedCluster.resourceID);
                                        SkbLogger.logDebugInfo('pullDataFromServer: ignored operation D on cluster',oneUpdatedCluster.resourceID);
                                    }
                                    
                                }else{
                                    //we should not update cluster version here, as the items have not been downloaded yet
                                    //instead, we should update the version after items downloaded
                                    /*
                                    var oneUpdatedClusterToSave = JSON.parse(JSON.stringify(oneUpdatedCluster));  //clone
                                    oneUpdatedClusterToSave.operation='';
                                    await localDB.writeDataClusterAsync(oneUpdatedClusterToSave); // saveDataClusterInSW(oneUpdatedClusterToSave);
                                    SkbLogger.logDebugInfo('pullDataFromServer: cluster synced',oneUpdatedClusterToSave);
                                    */
                                   clustersToBeSaved.push(oneUpdatedCluster.resourceID);
                                   SkbLogger.logDebugInfo('pullDataFromServer: cluster marked as to-be-saved',oneUpdatedCluster.resourceID);
                                }   
                            }
                            
                        }
                        
                    }
                    //
     
                    
                }//end if (cluster list)
            }
      
        } catch (error) {
            //SkbLogger.logDebugInfo('get error from getDataFroServerChangeSync',error);
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data - UI'
                            ,'{error} (dataCluster) happened during downloaing through front end sync API.'
                            ,{error:error});

        }
    if(updatedClusters && Array.isArray(updatedClusters) && updatedClusters.length>0 && updatedClusters[0].resourceID){
        for (let i = 0; i < updatedClusters.length; i++) {

            const oneUpdatedCluster = updatedClusters[i];
            if(clustersToBeIgnoredAsDeletedButNotOwned.includes(oneUpdatedCluster.resourceID)){
                SkbLogger.logDebugInfo('pullDataFromServer: ignored items under cluster', oneUpdatedCluster);
                continue;
            }
            if(!oneUpdatedCluster.resourceID) continue;

            //SkbLogger.logDebugInfo('oneUpdatedCluster',oneUpdatedCluster);
            const latestUnchangedItemVersion = await localDB.getLastestUnchangedItemInCluster(oneUpdatedCluster.resourceID);// queryLastestUnchangedItemInCluster(oneUpdatedCluster.resourceID);
            SkbLogger.logDebugInfo('pullDataFromServer: latestUnchangedItemVersion',latestUnchangedItemVersion);

            var fetchURLForGetItemChanges;

            if(latestUnchangedItemVersion>0){
                //use version to GET
                fetchURLForGetItemChanges=process.env.REACT_APP_API_URL //envItemsForChangesSync.REACT_APP_API_URL 
                                        + 'users/' + auth0UserID//authUserForChangesSync.sub 
                                        + '/dataclusters/' + oneUpdatedCluster.resourceID
                                        + '/dataitem_changes?'
                                        + 'fromversion='+latestUnchangedItemVersion;
            }else{
                //GET all 
                fetchURLForGetItemChanges=process.env.REACT_APP_API_URL //envItemsForChangesSync.REACT_APP_API_URL 
                + 'users/' + auth0UserID//authUserForChangesSync.sub 
                                        + '/dataclusters/' + oneUpdatedCluster.resourceID
                                        + '/dataitem_changes?fromversion=0' 
            }
            //get the changes for items in cluster

                try {
                    SkbLogger.logDebugInfo('pullDataFromServer: fetchURLForGetItemChanges',fetchURLForGetItemChanges);
                    const jsonString = await axios.get(fetchURLForGetItemChanges);
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Download Data - UI','{response} (dataItem) has been received through front end sync API.',{response:jsonString});
     /* 
                    const jsonString =await skbFetch(fetchURLForGetItemChanges,{
                                        method: 'GET', 
                                        headers: {
                                            'content-type': 'application/json',
                                            'authorization': apiKeyForChangesSync,
                                            'X-CustomHeader' :idTokenForChangesSync
                                        }
                                    }); */
                    if(jsonString){
                        const updatedItems=jsonString.data;//JSON.parse(jsonString.data);
                        if(updatedItems && Array.isArray(updatedItems) && updatedItems.length>0 && updatedItems[0].resourceID){
                            //sorting by version, so that lower version can be processed ealier
                            updatedItems.sort( (a, b) => {
                                    const aVersion=a.version ? a.version : 0;
                                    const bVersion=b.version ? b.version : 0;   
                                    if(aVersion>bVersion) return 1;
                                    else if(aVersion<bVersion) return -1;
                                    else return 0;   
                            } );
                            //refresh items as per server returns
                            for (let k = 0; k < updatedItems.length; k++) {
                                var oneUpdatedItem = updatedItems[k];
                                if(oneUpdatedItem.resourceID){
                                    const existingItem = await localDB.getDataItemByResourceID(oneUpdatedItem.resourceID);// getDataItemWithResourceID(oneUpdatedItem.resourceID);
                                    if(existingItem && localDB.checkFurtherLocalChange(existingItem, oneUpdatedItem)){
                                        //do not overwrite unsent local changes
                                        SkbLogger.logDebugInfo('pullDataFromServer: not overwriting unsent local-changed item',existingItem);
                                    }else{

                                        if(oneUpdatedItem.operation && oneUpdatedItem.operation=='D'){
                                            await localDB.deleteDataItemRow(oneUpdatedItem.resourceID); //realDeleteDataItemInSW(oneUpdatedItem.resourceID);

                                            SkbLogger.logDebugInfo('pullDataFromServer: item deleted',oneUpdatedItem);
                                        }else{

                                            var oneUpdatedItemToSave = JSON.parse(JSON.stringify(oneUpdatedItem));  //clone
                                            oneUpdatedItemToSave.operation='';
                                            if(oneUpdatedItemToSave.type && oneUpdatedItemToSave.type=='PHOTO'){
                                                
                                                //be careful about localArtefactContent in PHOTO
                                                if(existingItem && existingItem.localArtefactContent){
                                                    //oneUpdatedItemToSave.value is the path from server (jitterbit server may not update blank value)
                                                    if(!oneUpdatedItemToSave.value || oneUpdatedItemToSave.value=='' || oneUpdatedItemToSave.value=='AWAIT_UPLOADING' || existingItem.value==oneUpdatedItemToSave.value ){
                                                        //same path, do not overwrite localArtefactContent
                                                        SkbLogger.logDebugInfo('pullDataFromServer: keep localArtefactContent for the same path/value from server.',oneUpdatedItemToSave.value);
                                                        oneUpdatedItemToSave.localArtefactContent=existingItem.localArtefactContent;
                                                    }else if(!existingItem.value || existingItem.value=='' || existingItem.value=='AWAIT_UPLOADING'){
                                                        //same path, do not overwrite localArtefactContent
                                                        SkbLogger.logDebugInfo('pullDataFromServer: keep localArtefactContent and local path for blank local path/value.',existingItem.value);
                                                        oneUpdatedItemToSave.localArtefactContent=existingItem.localArtefactContent;
                                                        oneUpdatedItemToSave.value=existingItem.value;
                                                    }else{
 
                                                        oneUpdatedItemToSave.localArtefactContent='';
                                                    }
                                                }else{
                                                    SkbLogger.logDebugInfo('pullDataFromServer: no need to deal with localArtefactContent',existingItem);
                                                }

                                                //for PHOTO item, oneUpdatedItemToSave.value is the path (not localArtefactContent) as it's from the server.
                                                await localDB.writeDataItemAsync(oneUpdatedItemToSave);// saveDataItemInSW(oneUpdatedItemToSave, false); //not to mark U back on dataCluster to avoid dead loop
                                                SkbLogger.logDebugInfo('pullDataFromServer: photo item synced',oneUpdatedItemToSave);
             
                                            }else{
                                                await localDB.writeDataItemAsync(oneUpdatedItemToSave);// saveDataItemInSW(oneUpdatedItemToSave, false); //not to mark U back on dataCluster to avoid dead loop
                                                //SkbLogger.logDebugInfo('non-photo item synced',oneUpdatedItemToSave);
                                            }
                                        } // end else: D
                                        
                                    } //end else: existing
                                    
                                }
                            } //end loop for all updated items under one cluster
                            
                        }  //end if valid updatedItem
                        
                        //update cluster version ad items downloaded
                        if(clustersToBeSaved.includes(oneUpdatedCluster.resourceID)){
                            var oneUpdatedClusterToSave = JSON.parse(JSON.stringify(oneUpdatedCluster));  //clone
                            oneUpdatedClusterToSave.operation='';
                            await localDB.writeDataClusterAsync(oneUpdatedClusterToSave); // saveDataClusterInSW(oneUpdatedClusterToSave);
                            SkbLogger.logDebugInfo('pullDataFromServer: cluster synced',oneUpdatedClusterToSave);
                        }
                    }
                
                }catch(error){
                    //SkbLogger.logDebugInfo('get error from save data item',error)
                    SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data - UI'
                            ,'{error} (dataItem) happened during downloading through front end sync API.'
                            ,{error:error});
                }
    
        }//end for - items in clusters
    }//end if  - items in clusters

    //deleting clusters
    for (let j = 0; j < clustersToBeDeletedByServer.length; j++) {
        const oneDeletingCluster = clustersToBeDeletedByServer[j];
        await localDB.deleteDataClusterRow(oneDeletingCluster.resourceID);// realDeleteDataClusterInSW(oneDeletingCluster.resourceID);

        SkbLogger.logDebugInfo('cluster deleted',oneDeletingCluster);
       
    }
    clustersToBeDeletedByServer=[];
    clustersToBeIgnoredAsDeletedButNotOwned=[];

}


export async function pushDataToServer(){
    initLocalDBIfNotYet();
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey; 

    var changedClusters;
    
    try {
   
        changedClusters=await localDB.getLocalChangedDataClusters();// queryLocalDBForClusterChanges();
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Upload Data - UI','{dataObject} (dataCluster) has been uploaded through front end sync API.',{dataObject:changedClusters});

    } catch (error) {
        SkbLogger.logDebugInfo('pushDataToServer: has failed to obtain data to be uploaded.',error);
        SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data - UI'
                            ,'{error} (dataCluster) happened during uploading through front end sync API.'
                            ,{error:error});
    }

    if(changedClusters && Array.isArray(changedClusters) && changedClusters.length>0){

            try {
                const fetchURLForPing=process.env.REACT_APP_API_URL//envItemsForChangesSync.REACT_APP_API_URL 
                            + 'users/' +auth0UserID //authUserForChangesSync.sub 
                            + '/tokens';
                             
                const jsonString   = await axios.get(fetchURLForPing);                    

                if(jsonString){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,
                    'Data Synchronisation','Connect Server','has successfully made connection with {serverEndPoint} from front end for Metadata Changes Sync.',{serverEndPoint:fetchURLForPing});
                
                }
            } catch (error) {
                SkbLogger.logDebugInfo('error from data sync push',error);
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data - UI'
                            ,'failed to make connection with {error} for Metadata Changes Sync. '
                            ,{error:error});  
            }
            
 

        //uploading changedClusters

            try {
                const fetchURLForCluster=process.env.REACT_APP_API_URL//envItemsForChangesSync.REACT_APP_API_URL 
                        + 'users/' +auth0UserID //authUserForChangesSync.sub 
                        + '/datacluster_changes';
                const  jsonString    = await axios.post(fetchURLForCluster,changedClusters);                                 

                //uploading clusterChanges successfully
                SkbLogger.logDebugInfo('response from post data',jsonString);
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,
                'Data Synchronisation','Upload Data - UI','has successfully uploaded cluster list {dataObject} (dataCluster).',{dataObject:changedClusters});
    
                //refresh operation only
                for (let k = 0; k < changedClusters.length; k++) {
                    var oneChangedCluster = changedClusters[k];
                    oneChangedCluster.operation='S'; //was '', now 'S' for partial sync
                    await localDB.writeDataClusterAsync(oneChangedCluster);// saveDataClusterInSW(oneChangedCluster);
                }

               
            }catch (error) {
                SkbLogger.logDebugInfo('get error from post data',error);
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data - UI'
                            ,'failed to upload cluster list {dataObject} with {error}.'
                            ,{ dataObject:changedClusters, error:error});  
            }
    

        //uploading dataItems (if any) in oneChangedCluster
        for (let i = 0; i < changedClusters.length; i++) {

            const oneChangedCluster = changedClusters[i];
            var changedItemsWithoutPhotoContent;
            try {
                changedItemsWithoutPhotoContent=await localDB.getLocalDBForChangesInCluster(oneChangedCluster.resourceID);//queryLocalDBForChangesInCluster(oneChangedCluster.resourceID);
            } catch (error) {
                SkbLogger.logDebugInfo('get eror from get local db for changes',error);
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data - UI'
                ,'has failed to obtain dataItems to be uploaded for dataCluster {dataObject} with {error}.'
                ,{ dataObject:changedClusters, error:error});  

            }
            if(changedItemsWithoutPhotoContent && Array.isArray(changedItemsWithoutPhotoContent) && changedItemsWithoutPhotoContent.length>0){
                //uploading items

                    try {
                        const fetchURLForItem=process.env.REACT_APP_API_URL//envItemsForChangesSync.REACT_APP_API_URL 
                                                + 'users/' +auth0UserID// authUserForChangesSync.sub 
                                                + '/dataclusters/' + oneChangedCluster.resourceID
                                                + '/dataitem_changes';
                        SkbLogger.logDebugInfo('pushDataToServer: fetchURLForItem',fetchURLForItem);
                        const jsonString = axios.post(fetchURLForItem,changedItemsWithoutPhotoContent);
 
                        
                        //uploading clusterChanges successfully
                        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,
                            'Data Synchronisation','Connect Server','has successfully uploaded items {dataObject} for cluster ',{dataObject:changedItemsWithoutPhotoContent});
                        //refresh operation only
                        for (let k = 0; k < changedItemsWithoutPhotoContent.length; k++) {
                            var oneChangedItemWithoutPhotoContent = changedItemsWithoutPhotoContent[k];
                            
                            if(oneChangedItemWithoutPhotoContent.type=='PHOTO'){
                                var oneChangedItemWithPhotoContent =localDB.getDataItemByResourceID(oneChangedItemWithoutPhotoContent.resourceID);// getDataItemWithResourceID(oneChangedItemWithoutPhotoContent.resourceID);
                                oneChangedItemWithPhotoContent.operation='S';
                                await localDB.writeDataItemAsync(oneChangedItemWithPhotoContent);// saveDataItemInSW(oneChangedItemWithPhotoContent,false); 
                            }else{
                                oneChangedItemWithoutPhotoContent.operation='S';  //was '', now 'S' for partial sync
                                await localDB.writeDataItemAsync(oneChangedItemWithoutPhotoContent);// saveDataItemInSW(oneChangedItemWithoutPhotoContent,false); //not to mark U for dataCluster to avoid dead loop
                            }

                            
                        }

                     
                    } catch (error) {
                        //SkbLogger.logDebugInfo('get error from post data',error);
                        SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data - UI'
                        ,'failed to upload item list {dataObject} with {error}.'
                        ,{ dataObject:changedItemsWithoutPhotoContent, error:error});  
                    }
              
                
            }  // end if
        }//end for loop (items)
    }
    

}

