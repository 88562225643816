import React from 'react';



// importing skb components 
import SkbTabMenu from '../../../skb_controls/navigation/SkbTabMenu';

// importing screens 
import StepConsignmentLabels  from './PickupDelivery/StepConsignmentLabels';



const PickupDelivery = () => {


    return(
        <SkbTabMenu>
            <skbtab label="Consignment Labels">
                <StepConsignmentLabels />
            </skbtab>
        </SkbTabMenu>
    )
}


export default PickupDelivery;