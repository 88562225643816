import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const myStyles = makeStyles(them => ({
    list: {
        listStylePosition: "outside",
    },
    clickable: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        }
    }
}));

function unOrderedList(items, onClick, id) {
    const classes = myStyles();
    return (
        <ul id={id} className={classes.list}>
            {items.map((i, idx) => (
                <li key={idx} onClick={onClick} className={clsx({ [classes.clickable]: onClick })} >
                    <Typography variant="subtitle1">{i}</Typography>

                </li>
            ))}
        </ul>
    );
}


function orderedList(items, onClick, id) {
    const classes = myStyles();
    return (
        <ol id={id}>
            {items.map((i, idx) => (
                <li key={idx} onClick={onClick} className={clsx({ [classes.clickable]: onClick })}> <Typography variant="subtitle1">{i}</Typography></li>
            ))}
        </ol>
    );
}
/**
 * 
 * @props {ordered, items, onClick}: 
 * @property {ordered}: boolean, the flag to determine the list is rendered in ordered or un-ordered, ture: ordered list, false: un-ordered list
 * @property {items}: array of strings, items of the list
 * @property {onClick}: event handler when list items is clicked
 */
export function SkbList({ ordered, items, onClick, id }) {
    if (ordered) return orderedList(items, onClick, id);
    else return unOrderedList(items, onClick, id);
}