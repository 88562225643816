import React,{useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Typography,
} from '@material-ui/core';

import SkbButton from '../../../skb_controls/components/SkbButton';
import SkbTable from '../../../skb_controls/components/SkbTable';
import {connect} from 'react-redux';

// actions
import {
    markStockItems,
    updateNonSerialisedItemQuantity,
    overrideConsignmentActualStart,

    clearSelectedTaskRows,

} from '../../../Redux/ConsignmentTasks/actions';
// selectors
import {
    selectChoosenTaskRows,
    selectChoosenNonSerialisedItems,
    selectConsignmentDetails,

    selectOverriddenConsignmentRequested,
    selectLoadingOverrideConsignmentRequested,
    selectOverrideConsignmentRequestedError,


} from '../../../Redux/ConsignmentTasks/selectors';
import Loading from '../../Loading';

const SerializedItems = ({items}) => {

    return(
        <div>
            {
                items.map((item,i)=>(
                    <span key={i}>
                        <Typography variant="body2">{Math.ceil(parseInt(item.col5))} x {item.col2}</Typography>
                    </span>
                ))
            }  
        </div>
    )
}

const ConfirmAvailable = ({
    // selectors
    choosenStockItems,
    nonSerialisedTableData,
    overriding,
    overrideResponse,
    overrideError,

    details,

    // props
    open,
    setOpen,
    setMarked,

    // actions
    markItems,
    updateQty,
    overrideConsignment,
    clearChoosenItems,

}) => {
    // const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const TableColumns = [
        { title: 'Hidden Col', field: 'col1', type: 'hidden-id'},
        { title: 'Description', field: 'col2', type: 'link'},
        { title: 'UOM', field: 'col3', type: 'text', onChange:()=>console.log("col 4 link")},
        { title: 'Qty', field: 'col4', type: 'editable-number', onChange:(e , row, value)=>updateQty({row,value})},
    ] 
    return(<div>
        {/* <SkbButton variant="outlined" onClick={handleClickOpen} text={"Open Missing Popup"} /> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Confirm Available Items ?
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
                Please enter the amount of items available
            </Typography>
            <br />
            <Typography variant="body1">Serialised Items : </Typography>
            <SerializedItems items={choosenStockItems.filter(i=>i.col7 === "serialised")} />
            <br />
            <Typography variant="body1">Non Serialised Items : </Typography>
            <SkbTable 
                columns={TableColumns}
                dataSource={nonSerialisedTableData} 
            />
            <br />
            <Typography variant="body2">Are you sure you wish to continue ?</Typography>
            <br />
          </DialogContent>
          <DialogActions>
                <SkbButton onClick={handleClose} size="medium" text={"NO"} primary outlined/>
                <SkbButton onClick={()=>{
                    markItems("Available");
                    overrideConsignment(choosenStockItems,details?.ConsignmentLocationCode)
                    setMarked(true); 
                    // clearChoosenItems();
                    handleClose();
                    
                    
                       
                }} primary size="medium" text={"YES"}/>
          </DialogActions>
          { overriding && <Loading /> }
        </Dialog>
      </div>);
}

const mapState = state =>({
    choosenStockItems : selectChoosenTaskRows(state),
    nonSerialisedTableData : selectChoosenNonSerialisedItems(state),
    details : selectConsignmentDetails(state),

    overriding : selectLoadingOverrideConsignmentRequested(state),
    overrideResponse : selectOverriddenConsignmentRequested(state),
    overrideError : selectOverrideConsignmentRequestedError(state),
})
const mapDispatch = dispatch =>({
    markItems : status => dispatch(markStockItems(status)),
    updateQty : data => dispatch(updateNonSerialisedItemQuantity(data)),
    overrideConsignment : (data , id)=>dispatch(overrideConsignmentActualStart(data,id)),
    clearChoosenItems : ()=>dispatch(clearSelectedTaskRows()),
})

export default connect(
    mapState,
    mapDispatch,

)(ConfirmAvailable);

