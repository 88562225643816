import React, { useEffect, useState, forwardRef } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import * as variablesActions from '../../../Redux/actions/variables'
import MaterialTable, { MTableToolbar, MTableHeader, MTableEditField, MTableEditRow } from 'material-table';
import { isRowValidated, cellValidate } from '../_share/tablevalidation';
import uuid from 'react-uuid'


import Loading from '../../Loading';
import { SkbLogger } from '../../../services';
import { tableIcons } from '../_share/tableIcons';

const useStyles = makeStyles((theme) => ({

    paper: {
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 16px)',
            margin: theme.spacing(0),
            padding: theme.spacing(0),


        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 200px)',
            padding: theme.spacing(0),
            margin: theme.spacing(0),
            // marginBottom: theme.spacing(2),
        },


    },
    section: {
        marginTop: theme.spacing(2),
    },
    text: {
        paddingLeft: theme.spacing(1) / 2,
    }

}));


function Countries({ user, Countries, updateCountry, delCountry, addCountry }) {

    //set up table columns
    const [columns, setColumns] = useState([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'resourceID', field: 'resourceID', hidden: true },
        { title: 'variableGroup', field: 'variableGroup', hidden: true },
        { title: 'parentVariableID', field: 'parentVariableID', hidden: true },

        {
            title: 'Country', field: 'variableName', validate: rowData => typeof (rowData.variableName) !== 'undefined' && /^[^\s].{0,100}$/ig.test(rowData.variableName) ? true : { helperText: 'Invalid Country', isValid: false }, initialEditValue: '',
            editComponent: props => (
                <MTableEditField
                    autoFocus
                    {...props}
                />
            )
        },
        { title: 'Acronym', field: 'variableValue', validate: rowData => typeof (rowData.variableValue) !== 'undefined' && /^[^\s].{0,20}$/ig.test(rowData.variableValue) ? true : { helperText: 'Invalid Acronym', isValid: false }, initialEditValue: '' },
        {
            title: 'Active', field: 'active', type: 'numeric', initialEditValue: 1,
            lookup: { 1: 'Yes', 0: 'No' }
        },
    ]);

    //country data are from reduct store
    const [data, setData] = useState([...Countries]);

    const toolbarRef = React.useRef(null); // generate toobar ref in order that to manipulate view position after page size is changed 
    return (
        <div id="coutrytable">
            <MaterialTable
                icons={tableIcons}
                showTitle={false}
                searchable={false}
                toolbar={false}
                columns={columns}
                data={data}
                padding={'dense'}
                components={{
                    Toolbar: props => (
                        <div ref={toolbarRef}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    EditRow: props => <MTableEditRow
                    onKeyDown = {
                        e=>{
                           //override the default behaviour 
                        }
                    }

                        {...props}
                    />
                }}
                onChangeRowsPerPage={() => setTimeout(() => { try { toolbarRef.current.scrollIntoViewIfNeeded(); } catch (e) { } }, 500)}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {

                            setTimeout(() => {
                                if (isRowValidated(columns, newData)) {
                                    newData.resourceID = uuid().replace(/-/ig, '');
                                    setData([...data, newData]);
                                    addCountry({ Id: null, variableGroup: "Countries", parentVariableID: null, variableName: newData.variableName, variableValue: newData.variableValue, active: newData.active, resourceID: newData.resourceID }, user);
                                    resolve();
                                }
                                else reject();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                updateCountry(newData, user);
                                resolve();
                            }, 1000)
                        }),
                    //**********currently delete funciton is disabled due to server will NOT actaully detele record */
                    //   onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         const dataDelete = [...data];
                    //         const index = oldData.tableData.id;
                    //         dataDelete.splice(index, 1);
                    //         delCountry({Id:oldData.id, variableGroup:oldData.variableGroup, parentVariableID:oldData.variableGroup, variableName:oldData.variableName, variableValue:oldData.variableValue, active: oldData.active, resourceID:oldData.resourceID});
                    //         setData([...dataDelete]);

                    //         resolve()
                    //       }, 1000)
                    //     }),
                }}
                options={{ showTitle: false, search: false, actionsColumnIndex: -1 }}
            />
        </div>
    );
}

function Phones({ user, CountryPhones, updateCountryPhone }) {

    //set up table columns
    var cols = [
        { title: 'Id', field: 'id', hidden: true },
        { title: 'resourceID', field: 'resourceID', hidden: true },
        { title: 'variableGroup', field: 'variableGroup', hidden: true },
        { title: 'parentVariableID', field: 'parentVariableID', hidden: true },
        { title: 'Country', field: 'country', editable: "never" },
        {
            title: 'Ph Prefix', field: 'phPrefix', hidden: false, validate: rowData => typeof (rowData.phPrefix) !== 'undefined' && /^\+*\d+$/ig.test(rowData.phPrefix) ? true : { helperText: 'Invalid Ph Prefix', isValid: false },
            editComponent: props => (
                <MTableEditField
                    autoFocus
                    {...props}
                />
            )
        },
        { title: 'Landline Length', field: 'landlineLength', type: 'numeric', hidden: false, validate: rowData => typeof (rowData.landlineLength) !== 'undefined' && /(^[1-9]$)|(^[1][0-9]$)/ig.test(rowData.landlineLength) ? true : { helperText: 'Invalid landline length', isValid: false } },
        { title: 'Mobile Length', field: 'mobileLength', type: 'numeric', hidden: false, validate: rowData => typeof (rowData.mobileLength) != 'undefined' && /(^[1-9]$)|(^[1][0-9]$)/ig.test(rowData.mobileLength) ? true : { helperText: 'Invalid mobile length', isValid: false } },
        { title: 'Mobile Prefix', field: 'mobilePrefix', hidden: false, validate: rowData => typeof (rowData.mobilePrefix) !== 'undefined' && /^\+*\d+$/ig.test(rowData.mobilePrefix) ? true : { helperText: 'Invalid Mobile Prefix', isValid: false } },
    ];
    const [columns, setColumns] = useState(cols);

    const [data, setData] = useState([...CountryPhones]);

    React.useEffect(() => {
        setData([...CountryPhones]);
    }, [CountryPhones])

    const toolbarRef = React.useRef(null);// generate toobar ref in order that to manipulate view position after page size is changed 
    return (
        <div id="countryphonestable">
            <MaterialTable
                icons={tableIcons}
                showTitle={false}
                searchable={false}
                toolbar={false}
                columns={columns}
                data={data}
                padding={'dense'}
                components={{
                    Toolbar: props => (
                        <div ref={toolbarRef}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    EditRow: props => <MTableEditRow
                    onKeyDown = {
                        e=>{
                           //override the default behaviour 
                        }
                    }

                        {...props}
                    />
                }}
                onChangeRowsPerPage={() => setTimeout(() => { try { toolbarRef.current.scrollIntoViewIfNeeded(); } catch (e) { } }, 500)}
                editable={{
                    /*****phone record is created automatically when a country is created as server end */
                    //   onRowAdd: newData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         setData([...data, newData]);
                    //         addCountryPhone({Id:null, variableGroup:"Countries", parentVariableID:null, variableName:newData.variableName, variableValue:newData.variableValue, active: newData.active});
                    //         resolve();
                    //       }, 1000)
                    //     }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                updateCountryPhone(newData, user);
                                resolve();
                            }, 1000)
                        }),
                    //**********currently delete funciton is disabled due to server will NOT actaully detele record */
                    //   onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         const dataDelete = [...data];
                    //         const index = oldData.tableData.id;
                    //         dataDelete.splice(index, 1);
                    //         delCountryPhone({Id:oldData.id, variableGroup:oldData.variableGroup, parentVariableID:oldData.variableGroup, variableName:oldData.variableName, variableValue:oldData.variableValue, active: oldData.active});
                    //         setData([...dataDelete]);

                    //         resolve()
                    //       }, 1000)
                    //     }),
                }}
                options={{ showTitle: false, search: false, actionsColumnIndex: -1 }}
            />
        </div>
    );
}

function CountryVariables(props) {

    const { variables, getCountries, user, updateCountry, addCountry, delCountry, updateCountryPhone } = props;
    const classes = useStyles();
    useEffect(() => {
        if (!variables.Countries || variables.Countries.length == 0)
            getCountries();
    }, []);

    if (variables.isLoading && (variables.Countries.length == 0 || variables.CountryPhones.length == 0)) {
        return (<Loading />);
    }
    else {
        return (
            <div>
                <Paper className={classes.paper}>
                    <Typography className={classes.text} Typography variant="h3"> Country Variables</Typography>
                    <div className={classes.section}>
                        <Typography className={classes.text} variant="h4"> Countries</Typography>
                        <Typography className={classes.text} variant="body1"> List of countries that our contractors work in.</Typography>
                        <Countries user={user} Countries={variables.Countries} updateCountry={updateCountry} addCountry={addCountry} delCountry={delCountry} />
                    </div>
                    <div className={classes.section}>
                        <Typography className={classes.text} variant="h4"> Phone Configuration</Typography>
                        <Typography className={classes.text} variant="body1">Configuration of phone number validation for specific countries.</Typography>
                        <Phones user={user} Countries={variables.Countries} CountryPhones={variables.CountryPhones} updateCountryPhone={updateCountryPhone} />
                    </div>

                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth,
    register: state.register,
    variables: state.variables
});

const mapDispatchToProps = (dispatch) => {
    return {
        getCountries: () => dispatch(variablesActions.getCountries()),
        updateCountry: (c, auth) => dispatch(variablesActions.updateCountry(c, auth)),
        addCountry: (c, auth) => dispatch(variablesActions.addCountry(c, auth)),
        delCountry: (c, auth) => dispatch(variablesActions.delCountry(c, auth)),
        updateCountryPhone: (p, auth) => dispatch(variablesActions.updateCountryPhone(p, auth)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CountryVariables);
