import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';

import { SkbLoading } from '../../../skb_controls/components/SkbLoading';
import SkbTable from '../../../skb_controls/components/SkbTable';

const useStyles = makeStyles((them) => (
    {
        root:{
            width:'100%'
        }

    }
));


/**
 * components: StockReview: show stock review data, the HOC have to pass all the props below
 * @param {boolean} IsLoading: IsLoading: to indicate the datasoure is refreshing or not
 * @param {funciton} loadRevievData: it's a callback to refresh ReviewData, the HOC is resposible of the implementation 
 * @param {funciton} loadRowDetails: it's a callback to load row detail data, the return data shall contain the properties which match with the field defined in DisplaySubColumns, i
 *  the HOC is resposible of the implementation, the return data shall contain all properties required in DisplaySubColumns  
 * @param {array} DisplayColumns: to define the columns of the grid , and how column is binding with the ReviewData
 *          i.e.  [
 *           { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
 *           {title:"Contractor", field:"Contractor", type: 'read-only-long-text'},
 *           {title:"Stock Location", field:"LocationName", type: 'read-only-long-text'},
 *           {title:"# of Good Stock", field:"QtyofGood", type: 'read-only-long-text'},
 *           {title:"# of Faulty Stock", field:"QtyofFaulty", type: 'read-only-long-text'},
 *           {title:"Destination", field:"Destination", type: 'read-only-long-text'},
 *           {title:"Method", field:"Method", type: 'read-only-long-text'},
 *
 *        ]
  * @param {array} DisplaySubColumns: to define the columns of the grid , and how column is binding with the ReviewData
 *          i.e.  [
 *           {title:"Description", field:"Description", type: 'read-only-long-text'},
 *           {title:"UOM", field:"UNIT", type: 'read-only-long-text'},
 *           {title:"Condition", field:"Condition", type: 'read-only-long-text'},
 *           {title:"Qty/SN", field:"DisplayValue", type: 'read-only-long-text'},
 *
 *        ]
 * @param {array} ReviewData: array of data to show in the grid
 *              i.e 
 *        [
 *           {
 *               LocationCode: 'ADM01',
 *               Contractor: 'Adam Poole Pty. Ltd',
 *               LocationName: 'Gin Gin Warehouse',
 *               Address: '1 Station St Gin Gin QLD 4567',
 *               QtyofGood: 10,
 *               QtyofFaulty: 20,
 *               Destination: 'SKB main warehouse',
 *               Method: 'Carrier Arranged by Skybridge'
 *           }
 *        ]
  */
export function StockReview({ IsLoading, loadRevievData, DisplayColumns, ReviewData,loadRowDetails, DisplaySubColumns }) {

    if(typeof(IsLoading) === 'undefined') throw("IsLoading prop is missing");
    if(typeof(loadRevievData) === 'undefined') throw("loadRevievData prop is missing");
    if(typeof(ReviewData) === 'undefined') throw("ReviewData prop is missing");
    if(typeof(DisplayColumns) === 'undefined') throw("DisplayColumns prop is missing");
    if(typeof(loadRowDetails) === 'undefined') throw("loadRowDetails prop is missing");
    if(typeof(DisplaySubColumns) === 'undefined') throw("DisplaySubColumns prop is missing");


    const subtableDefinition = (tableRowData) =>{
        var dataSource = loadRowDetails(tableRowData);

        return {
            columns: DisplaySubColumns,
            dataSource: dataSource
        }
    };

    const classes = useStyles();

    useEffect(()=>{
        loadRevievData();
    }, []);
    if (IsLoading) {

        return (<SkbLoading />);
    }

    return (
        <div className={classes.root} id="stock_review">
            <SkbTable columns={DisplayColumns} dataSource={ReviewData} id="stock_review_datagrid" 
            options={{subtableDefinition:subtableDefinition}}
            />

        </div>

    );
}