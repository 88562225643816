import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      position:'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '1000',
      opacity:'1',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,

    },

  }));
export default function FullpageLoader() {
    const classes = useStyles();

    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        function tick() {
            // reset when reaching 100%
            setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (<Backdrop className={classes.backdrop} open={true} invisible={true}>
        {/* <CircularProgress id="primary-loading-icon" variant="determinate" value={progress} color="primary" /> */}
        <CircularProgress id="primary-loading-icon" color="primary" />

    </Backdrop>);
}

