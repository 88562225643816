import React,{useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import SkbIcon from '../../skb_controls/components/SkbIcon';
import SkbTabMenu from '../../skb_controls/navigation/SkbTabMenu';
import { Grid } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import SkbButton from '../../skb_controls/components/SkbButton';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import * as syncConfig from '../../Redux/actions/syncConfig'
import * as notistack from 'notistack';
import { SkbLogger,SeverityLevel } from '../../services';
import uuid from 'react-uuid';
import FullpageLoader from '../FullpageLoader'
import {crypts} from '../../utils/crypts';


const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 16px)',
            margin: theme.spacing(0),

        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 200px)',
            marginBottom: theme.spacing(2),
        },


    },
    section: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
            width:'92%',

        },
        [theme.breakpoints.up('lg')]: {
            width:'97%',
        },
    },

    savebutton:{
        marginTop: theme.spacing(3),
       // marginBottom:theme.spacing(3),
        paddingBottom:theme.spacing(3),
    },
    label:{
        minWidth:'20%',
        maxWidth:'20%',
        alignContent:'flex-start',
        alignItems:'flex-start',
        alignSelf:'flex-start'
    },
    safari: {
        height: '-webkit-fill-available',
    }
}));


  
const AirplaneModeInterMarks = [
    { value: 1, label: '1 minute',},
    { value: 30,label: '30 minutes',},
    { value: 60, label: '60 minutes', },]; 

const AttemptsMarks = [
    { value: 1, label: '1 attempt',},
    { value: 5,label: '5 attempts',},
    { value: 10, label: '10 attempts', },]; 

const MetadataDaysMarks = [
    { value: 1, label: '1 day',},
    { value: 15,label: '15 days',},
    { value: 30, label: '30 days', },]; 

 
  
  function airplaneModeIntervalValuetext(value) {
    return `${value} minutes`;
  }

  function attemptsValuetext(value) {
    return `${value} attempts`;
  }

  function metadataDaysValuetext(value) {
    return `${value} days`;
  }

  function artefactsDaysValuetext(value) {
    return `${value} days`;
  }



function SyncConfig(props) {
    const classes = useStyles();
    const {sc}=props;
    /* const [airplaneModeIntervalValue, setAirplaneModeIntervalValue] = React.useState(20);
    const [attemptsValue, setAttemptsValue] = React.useState(3);
    const [metadataDaysValue, setMetadataValue] = React.useState(5);
    const [artefactsDaysValue, setArtefactsValue] = React.useState(5); */

    const  [ArtefactsDaysMarks, setArtefactsDaysMarks] = React.useState([{ value: 1, label: '1 day',}]); 
    const [airplaneModeIntervalValue, setAirplaneModeIntervalValue] = React.useState(sc.airPlaneModeInterval);
    const [attemptsValue, setAttemptsValue] = React.useState(sc.attemptsErrorOccurs);
    const [metadataDaysValue, setMetadataValue] = React.useState(sc.downloadMetadataD);
    const [artefactsDaysValue, setArtefactsValue] = React.useState(sc.downloadArtefactsD); 
    const { enqueueSnackbar } = notistack.useSnackbar(); 
    const [showLoader,setshowLoader] = React.useState(false);
     /* const { enqueueSnackbar } = useSnackbar();  */
    const handleAirplaneModeIntervalValueChange = (event, newValue) => {
        setAirplaneModeIntervalValue(newValue);
    };
    const handleAttemptsValueChange = (event, newValue) => {
        setAttemptsValue(newValue);
    };
    const handleMetadataDaysValueChange = (event, newValue) => {
        setMetadataValue(newValue);
    };
    const handleArtefactsDaysValueChange = (event, newValue) => {
        setArtefactsValue(newValue);
    };
    

    const handleNotifycation = (message, variant) => {
        enqueueSnackbar(message, {             variant: variant
        })};

    function updateMaxArtefactsDays(){
        SkbLogger.logDebugInfo('get into updateMaxArtefactsDays',metadataDaysValue)
        if(metadataDaysValue && metadataDaysValue ===1){
            setArtefactsDaysMarks([
                { value: 1, label: '1 day',},
                { value: 1, label: '1 day',}]);
            }else if( metadataDaysValue &&  metadataDaysValue ===2){
                setArtefactsDaysMarks([
                    { value: 1, label: '1 day',},
                    { value: metadataDaysValue,label: `${metadataDaysValue} days`,}]);  
            }else if (metadataDaysValue && metadataDaysValue >2){
                setArtefactsDaysMarks([
                    { value: 1, label: '1 day',},
                    { value: Math.ceil(metadataDaysValue/2),label: `${Math.ceil(metadataDaysValue/2)} days`,},
                    { value: metadataDaysValue,label: `${metadataDaysValue} days`,}]);  
            }
    }

    useEffect(() => {
        console.log('called from intisl use effect',sc)
 /*        console.log('called from intisl use effect')
        if(attemptsValue === ""){
            console.log('called from loadSyncConfigData') */
           /*      if(sc.isLoading){ */
                    props.loadSyncConfigData();
            /*     } */
    

  /*       }else{
            syncConfig.updateLoadingStatus(false);
        } */

    }, [])

    useEffect(() => {
        console.log('called from intisl use effect set isloading',sc,new Date())
       
            setAirplaneModeIntervalValue(sc.airPlaneModeInterval);
                setAttemptsValue(sc.attemptsErrorOccurs);
                setMetadataValue(sc.downloadMetadataD);
                setArtefactsValue(sc.downloadArtefactsD);                
            //updateMaxArtefactsDays();
    },[sc.isLoading]) 
    
     useEffect(() => {
        console.log('called from  use effect loadSyncFailed',sc.isLoading,new Date())
        updateMaxArtefactsDays();
        if(sc.loadSyncFailed){
            handleNotifycation('Load Sync configuration failed.', 'error')
        }
    }, [metadataDaysValue,sc.loadSyncFailed])

    function getTempDeviceID(){
        const deviceIDInLS=localStorage.getItem('temp_device_id');
        if(deviceIDInLS){
            const tempDeviceID = crypts.decrypt(deviceIDInLS);
            return tempDeviceID;
        }else{
            //first part of device id is timestamp
            //to allow us to know when users clear the localStorage
            const newDeviceID = ''+((new Date()).getTime())+'-'+(uuid().split('-'))[0];
            const encryptedDeviceID = crypts.encrypt(newDeviceID);
            localStorage.setItem('temp_device_id',encryptedDeviceID);
            const tempDeviceID = crypts.decrypt(encryptedDeviceID);
            return tempDeviceID;
        }
    
    }

    function saveSyncConfigVlue(){
        SkbLogger.logDebugInfo('from saveSyncConfigVlue',sc)
       
        var airplaneModeIntervalValueChanged=false;
        var attemptsValueChanged=false;
        var metadataDaysValueChanged=false;
        var artefactsDaysValueChanged=false;
        const oldairplaneModeInterval=sc.airPlaneModeInterval;
        const oldattemptsValue=sc.attemptsErrorOccurs;
        const oldmetadataDaysValue=sc.downloadMetadataD;
        const oldartefactsDaysValue=sc.downloadArtefactsD;
        setshowLoader(true);
        //force update the artefact days to metadata day if the user differnt touch the artefact day.
        if(parseInt(artefactsDaysValue) >parseInt(metadataDaysValue)){
            setArtefactsValue(parseInt(metadataDaysValue))
            SkbLogger.logDebugInfo('from saveSyncConfigVlue page value',airplaneModeIntervalValue,attemptsValue,metadataDaysValue,artefactsDaysValue)
        }
        
        //syncConfigArraryObject contains resourceID, clusterResourceId,key,value)
        const syncConfigArraryObject=[];

        if(airplaneModeIntervalValue !== parseInt(sc.airPlaneModeInterval)){
             airplaneModeIntervalValueChanged=true;
             
             syncConfigArraryObject.push({
                "resourceID":sc.airResourceId,
                "clusterResourceId":sc.clusterResourceId,
                "key":"AirplaneModeInterval",
                "value":String(airplaneModeIntervalValue)
            })
        }
        if(attemptsValue !== parseInt(sc.attemptsErrorOccurs)){
             attemptsValueChanged=true;
             syncConfigArraryObject.push(
                {
                    "resourceID":sc.retriesResourceId,
                    "clusterResourceId":sc.clusterResourceId,
                    "key":"Retries",
                    "value":String(attemptsValue)
                })
            
        }
        if(metadataDaysValue !== parseInt(sc.downloadMetadataD)){
             metadataDaysValueChanged=true;
             syncConfigArraryObject.push(
                {
                    "resourceID":sc.dMDResourceId,
                    "clusterResourceId":sc.clusterResourceId,
                    "key":"DownloadMetadataInDays",
                    "value":String(metadataDaysValue)
                })
            
        }
        if( parseInt(artefactsDaysValue) >= parseInt(metadataDaysValue)){
            artefactsDaysValueChanged=true;
            syncConfigArraryObject.push(
               {
                   "resourceID":sc.dADResourceId,
                   "clusterResourceId":sc.clusterResourceId,
                   "key":"DownloadPhotoInDays",
                   "value":String(metadataDaysValue)
               }
           )
       }


        if(artefactsDaysValue !== parseInt(sc.downloadArtefactsD) && parseInt(artefactsDaysValue) < parseInt(metadataDaysValue)){
             artefactsDaysValueChanged=true;
             syncConfigArraryObject.push(
                {
                    "resourceID":sc.dADResourceId,
                    "clusterResourceId":sc.clusterResourceId,
                    "key":"DownloadPhotoInDays",
                    "value":String(artefactsDaysValue)
                }
            )
        }

        let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
        skbUser=JSON.parse(skbUser);
        
        let authuser = crypts.decrypt(localStorage.getItem('auth_user'));
         authuser=JSON.parse(authuser)
        var identityType=''
        if(/^auth0\|/ig.test(authuser.sub)){
            identityType = 'Non-Federated'
        }else if(/^google-oauth2\|/ig.test(authuser.sub)){
            identityType = 'Google'
        }else{
            identityType = 'Microsoft-Live'
        }
        let userObject= {
            "Full Name"	:skbUser.name,
            "Email Address":skbUser.email,
            "Identity Type": identityType ,
            "Address 1":skbUser.company.streetAddress,
            "Phone Number":	skbUser.phone
        }  

        //response channel for two-way communication with serviceWorker
        //port 1: for UI 
        //port 2: for Service Worker
        //ref https://felixgerschau.com/how-to-communicate-with-service-workers/
        //ref https://googlechrome.github.io/samples/service-worker/post-message/

        const currentDevice = {emailAddress:skbUser.email,
                                screenResolution:'' + window.screen.width + 'x' + window.screen.height,
                                deviceID:getTempDeviceID()
                        };
 
        SkbLogger.applicationTrace('SyncConfig',SeverityLevel.Verbose,'Sync config','User clicked the save button',syncConfigArraryObject);
        props.saveSyncConfig(syncConfigArraryObject).then(() =>{
            console.log('updated successful');
            handleNotifycation('Sync configuration updated.', 'success')
            if(airplaneModeIntervalValueChanged){
                SkbLogger.userAuditEvent('SyncConfig',skbUser.email,'Data Synchronisation','Client End Configuration Change','Success',
                    `{userObject} has changed [Network Speed Check interval] from ${oldairplaneModeInterval} to ${airplaneModeIntervalValue} on {deviceObject}.`
                    ,{userObject:userObject,deviceObject:currentDevice})
            }
            if(attemptsValueChanged){
                SkbLogger.userAuditEvent('SyncConfig',skbUser.email,'Data Synchronisation','Client End Configuration Change','Success',
                    `{userObject} has changed [Attempts when an error occurs] from ${oldattemptsValue} to ${attemptsValue} on {deviceObject}.`
                    ,{userObject:userObject,deviceObject:currentDevice})
            }
            if(metadataDaysValueChanged){
                SkbLogger.userAuditEvent('SyncConfig',skbUser.email,'Data Synchronisation','Client End Configuration Change','Success',
                    `{userObject} has changed [Download metadata in days] from ${oldmetadataDaysValue} to ${metadataDaysValue} on {deviceObject}.`
                    ,{userObject:userObject,deviceObject:currentDevice})
            }
            if(artefactsDaysValueChanged){
                SkbLogger.userAuditEvent('SyncConfig',skbUser.email,'Data Synchronisation','Client End Configuration Change','Success',
                    `{userObject} has changed [Download artefacts in days] from ${oldartefactsDaysValue} to ${artefactsDaysValue} on {deviceObject}.`
                    ,{userObject:userObject,deviceObject:currentDevice})
            }
            
            setshowLoader(false);
        },function(error){

            SkbLogger.applicationException('SyncConfig','Sync config','User save sync config failed',error);
            handleNotifycation('Sync configuration updated failed.', 'error')
            setshowLoader(false);
        })
        
    }

   
        if (sc.isLoading) {
            return (<div className={classes.safari}><Loading /></div>);
        }else{
           return ( <div id="syncConfig" className={classes.safari}>
               {showLoader && <Loading />}  
        <SkbTabMenu   header={<Typography id="syncConfig-page-heading" variant="h1"><Grid container><SkbIcon  icon={SettingsIcon} />Sync Configuration</Grid></Typography>}>
            <skbtab label="Sync Configuration" id="syncConfig-tab" key="syncConfig-tab">
            <Paper className={classes.paper} id="syncConfig-paper" key="syncConfig-paper">
             <FormControl component="fieldset" className={classes.section} > 
                {/* lable changed from Airplane ModeIn terval to Network Speed Check interval*/}
                     <FormControlLabel className={classes.section} id="formlabel-AirplaneMI" key="formlabel-AirplaneMI"
                    control={
                        <Slider id="discrete-slider-AirplaneMI"
                        style={{marginLeft:20 }}
                        key="discrete-slider-AirplaneMI"
                        //ref={ariId}
                        //defaultValue={airplaneModeIntervalValue}
                        value={airplaneModeIntervalValue}
                        getAriaValueText={airplaneModeIntervalValuetext}
                        //aria-labelledby="discrete-slider-AirplaneMI"
                        step={1}
                        max={60}
                        min={1}
                        valueLabelDisplay="auto"
                        marks={AirplaneModeInterMarks}
                        onChange={handleAirplaneModeIntervalValueChange}
                    />
                    }
                    classes={{ label: classes.label }}
                    label="Network Speed Check interval"
                    labelPlacement = "start"
                    id="label-AirplaneMI"
                />
                <FormControlLabel className={classes.section} id="formlabel-AttemptsEO" key="formlabel-AttemptsEO"
                    control={
                        <Slider id="discrete-slider-AttemptsEO"
                        style={{marginLeft:20 }}
                        key="discrete-slider-AttemptsEO"
                        //ref={retiesId}
                        //defaultValue={attemptsValue}
                        value={attemptsValue}
                        getAriaValueText={attemptsValuetext}
                        //aria-labelledby="discrete-slider-AttemptsEO"
                        step={1}
                        max={10}
                        min={1}
                        valueLabelDisplay="auto"
                        marks={AttemptsMarks}
                        onChange={handleAttemptsValueChange}
                    />
                    }
                    classes={{ label: classes.label }}
                    label="Attempts when an error occurs"
                    labelPlacement = "start"
                    id="label-AttemptsEO"
                />
                <FormControlLabel className={classes.section} id="formlabel-dowoladMD" key="formlabel-dowoladMD"
                    control={
                        <Slider id="discrete-slider-dowoladMD"
                        key="discrete-slider-dowoladMD"
                        style={{marginLeft:20 }}
                        //ref={metaId}
                        //defaultValue={metadataDaysValue}
                        value={metadataDaysValue}
                        getAriaValueText={metadataDaysValuetext}
                        //aria-labelledby="discrete-slider-dowoladMD"
                        step={1}
                        max={30}
                        min={1}
                        valueLabelDisplay="auto"
                        marks={MetadataDaysMarks}
                        onChange={handleMetadataDaysValueChange}
                    />
                    }
                    classes={{ label: classes.label }}
                    label="Download metadata in days"
                    labelPlacement = "start"
                    id="label-dowoladMD"
                />
                <FormControlLabel className={classes.section} id="formlabel-dowoladAD" key="formlabel-dowoladAD"
                    control={
                        <Slider id="discrete-slider-dowoladAD"
                        key="discrete-slider-dowoladAD"
                        style={{marginLeft:20}}
                        //ref={artefactsId}
                        //defaultValue={artefactsDaysValue}
                        value={artefactsDaysValue}
                        max={parseInt(metadataDaysValue)}
                        getAriaValueText={artefactsDaysValuetext}
                        //aria-labelledby="discrete-slider-dowoladAD"
                        step={1}
           
                        min={1}
                        valueLabelDisplay="auto"
                        marks={ArtefactsDaysMarks}
                        onChange={handleArtefactsDaysValueChange}
                    />
                    }
                    classes={{ label: classes.label }}
                    label="Download artefacts in days"
                    labelPlacement = "start"
                    id="label-dowoladAD"

                /> 
                
                
                
                </FormControl> 
                <Grid container  justify="center" className={classes.savebutton}>
                        <SkbButton id="sc-save-button" name={"medium Primary"}  primary size="medium" text="Save" type="submit" onClick={() => saveSyncConfigVlue()}/>
                </Grid>
                
            </Paper>
            </skbtab>
        </SkbTabMenu>
    </div>)
        }
    
}

const mapStateToProps = state => ({
    sc:state.syncConfig
});
const mapDispatchToProps = (dispatch) =>{
    return {
        loadSyncConfigData : () => dispatch(syncConfig.loadSyncConfigData()),
        saveSyncConfig : syncConfigArraryObject => dispatch(syncConfig.saveSyncConfigChagnes(syncConfigArraryObject))
        
      
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(SyncConfig);  