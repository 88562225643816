import axios from "axios";

import {crypts} from '../../utils/crypts';
import { config } from '../../utils/config';

function service() {

    // returns Dashboard  Task Info schedule update Service
    const taskInfoScheduleFinish = async (paramObj) =>{
  
        try {
                const apiEndPoint =  `${process.env.REACT_APP_API_URL}stock/consignment/`;

                const idToken = crypts.decrypt(localStorage.getItem('id_token'));
                const authorization = config.REACT_APP_API_SECRET;
                const bodyParam = (paramObj.bodyParam!==undefined &&  paramObj.bodyParam)? paramObj.bodyParam: {};
                const headerOptions = {
                    "content-type": "application/json",
                    "authorization": authorization,  
                    "X-CustomHeader": idToken
                }; 
                //console.log("==-**********--------CHECK options=>>",headerOptions);  
                
                const resp =   axios({
                    method: 'PATCH', //you can set what request you want to be
                    url: apiEndPoint,
                    data: bodyParam,
                    headers: headerOptions
                });
                return resp;
            }catch (error) {
            console.error(error);
            }
    };
  
    return {taskInfoScheduleFinish};
  
  }
  
  export const taskInfoScheduleFinishApiService  = service(); 
