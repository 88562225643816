import axios from "axios";

import {crypts} from '../../utils/crypts';
import { config } from '../../utils/config';

function service() {

    // get Stock Location On hand Items Detail service to be used for Stock 
    const getStockLocationOnhandItemsDetail = async (param) =>{
  
        try {

                //console.log("********getStockLocationOnhandItemsDetail====>>",param);
                const paramObj = param && param.paramObj!==undefined?param.paramObj:{};
                const fromLocationCode = (paramObj && paramObj.fromLocationCode!==undefined)?paramObj.fromLocationCode:"";
                const apiEndPoint =  `${process.env.REACT_APP_API_URL}stock/location/${fromLocationCode}/onhandstock?detail=true`;                

                const idToken = crypts.decrypt(localStorage.getItem('id_token'));
                const authorization = config.REACT_APP_API_SECRET;
                const headerOptions = {
                    "content-type": "application/json",
                    "authorization": authorization,  
                    "X-CustomHeader": idToken
                };                
                

                const resp =  await axios({
                    method: 'GET', //you can set what request you want to be
                    url: apiEndPoint,
                    data: null,
                    headers: headerOptions
                });
                return resp;
            }catch (error) {
            console.error(error);
            }
    }; 

  
    return {getStockLocationOnhandItemsDetail};
  
  }
  
  export const getStockLocationOnhandItemsDetailService = service(); 
