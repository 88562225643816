import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Paper,
    Grid,
    Checkbox,
    Typography,

} from '@material-ui/core';
import {SkbSelect} from '../../../skb_controls/components/SkbSelect';
import SkbButton from '../../../skb_controls/components/SkbButton';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

// importing selectors
import {
    selectChosenTasksRowCount,
    selectColumnTitleList,
    selectColumnToChange,

} from '../../../Redux/ConsignmentTasks/selectors';
// importing actions
import {
    setColumnToChange,
    changeStatusInStore,
} from '../../../Redux/ConsignmentTasks/actions';


import ConfirmMissingItems from './ConfirmMissingItems';
import ConfirmDeadOnArrival from './ConfirmDeadOnArrival';
import ConfirmAvailable from './ConfirmAvailable';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme=>({
    flexCenter : {
        alignItems : "center",
        justifyContent : "space-between",
    },
    clickLikeButton : {
        transition : "all 0.3s",
        userSelect: "none",
        cursor : "pointer",
        
        "&:hover" : {
            transform : "translateY(-5px)",
        },
        "&:active" : {
            transform : "translateY(5px)",
        },
    }
}));

const ChangeColumnValue = ({

    // selectors
    rowCount,
    colList,
    colToChange,

    // actions
    setColumnToChange,
    changeStatusInStore

}) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [missingPopupOpen,setMissingPopupOpen] = useState(false);
    const [doaPopupOpen,setDoaPopupOpen] = useState(false);
    const [markedAvailablePopupOpen,setMarkedAvailablePopupOpen] = useState(false);

    const [markedAsMissing,setmarkedAsMissing] = useState(false);
    const [markedDOA,setMarkedDOA] = useState(false);
    const [markedAvailable,setMarkedAvailable] = useState(false);

    useEffect(()=>{
        if(markedAsMissing) enqueueSnackbar(`Successfully marked ${rowCount} rows as missing`,{variant:"success"})
        setmarkedAsMissing(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[markedAsMissing])

    useEffect(()=>{
        if(markedDOA) enqueueSnackbar(`Successfully marked ${rowCount} rows as dead on arrival`,{variant:"success"})
        setMarkedDOA(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[markedDOA])

    useEffect(()=>{
        if(markedAvailable) enqueueSnackbar(`Successfully marked ${rowCount} rows as available`,{variant:"success"})
        setMarkedAvailable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[markedAvailable])

    if(!rowCount) return "";
    return(
        <Box  color="primary.contrastText" p={2}>
            <Grid container className={classes.flexCenter}>
                <Grid item style={{display:"flex",alignItems:"center"}}>
                    <Checkbox defaultChecked color="primary" />
                    <Typography variant="body2">{rowCount} line{rowCount > 1 && "s"} selected</Typography>
                </Grid>
                <Grid item>
                    <SkbSelect 
                        value={colToChange}
                        items={[
                            "Available",
                            "Missing From Delivery",
                            "Dead On Arrival",
                        ]}
                        valueChanged={value=>setColumnToChange(value)}
                    />
                </Grid>
                <Grid item>
                    {/* <SkbButton 
                        id="medium-primary-button" 
                        primary 
                        size="medium" 
                        text={<Typography ><SkbIcon icon={SaveIcon} style={{color:"white"}}/></Typography>} 
                        onClick={()=>{
                            changeStatusInStore(colToChange)
                            if(colToChange === "Missing From Delivery") setMissingPopupOpen(true)
                            if(colToChange === "Dead On Arrival") setDoaPopupOpen(true)
                            if(colToChange === "Available") setMarkedAvailablePopupOpen(true);
                        }}
                        disabled={!colToChange}
                    /> */}
                    <Typography 
                        variant="h5" 
                        color={colToChange ? "primary" : "secondary"}
                        className={classes.clickLikeButton}
                        
                    >
                            <SkbIcon 
                                icon={SaveIcon} 
                                onClick={()=>{
                                    // changeStatusInStore(colToChange)
                                    if(colToChange === "Missing From Delivery") setMissingPopupOpen(true)
                                    if(colToChange === "Dead On Arrival") setDoaPopupOpen(true)
                                    if(colToChange === "Available") setMarkedAvailablePopupOpen(true);
                                }}    
                            />
                        </Typography>
                </Grid>
            </Grid>
            <ConfirmMissingItems 
                open={missingPopupOpen}
                setOpen={setMissingPopupOpen}
                setMarked={setmarkedAsMissing}
            />
            <ConfirmDeadOnArrival 
                open={doaPopupOpen}
                setOpen={setDoaPopupOpen}
                setMarked={setMarkedDOA}
            />
            <ConfirmAvailable 
                open={markedAvailablePopupOpen}
                setOpen={setMarkedAvailablePopupOpen}
                setMarked={setMarkedAvailable}
            />
        </Box>
    )
}
const mapState = state =>({
    rowCount : selectChosenTasksRowCount(state),
    colList : selectColumnTitleList(state),
    colToChange : selectColumnToChange(state),
})
const mapDispatch = dispatch=>({
    setColumnToChange : value => dispatch(setColumnToChange(value)),
    changeStatusInStore : value => dispatch(changeStatusInStore(value)),
})  

export default connect(mapState,mapDispatch)(ChangeColumnValue);