import { Actions } from '../actions/variables';
import { SkbLogger } from '../../services';

//sample phone data: {id:1, variableGroup: "Phones ", parentVariableID:1, country:"Australia", phPrefix: "+61", landlineLength:10, mobileLength: 10, mobilePrefix:"04"}

let initialStatus = { isLoading: false, Countries: [], CountryPhones: [], EmployeeDomains: [], Divisions:[], Programs:[] };
export function variables(state = initialStatus, action) {
    switch (action.type) {
        case Actions.REQUTEST_SEND:
            return { ...state, isLoading: true };
        case Actions.REQUEST_END:
            return { ...state, isLoading: false };
        case Actions.COUNTRY_RECIEVED:
            SkbLogger.logDebugInfo("Actions.COUNTRY_RECIEVED", action.Countries);
            return { ...state, Countries: action.Countries, isLoading: false };
        case Actions.PROGRAMS_RECIEVED:
            SkbLogger.logDebugInfo("Actions.PROGRAMS_RECIEVED", action.Programs);
            return { ...state, Programs: action.Programs, isLoading: false };
        case Actions.DIVISIONS_RECIEVED:
            SkbLogger.logDebugInfo("Actions.DIVISIONS_RECIEVED", action.Divisions);
            return { ...state, Divisions: action.Divisions, isLoading: false };           
        case Actions.EMPLOYEEDOMAIN_RECIEVED:
            SkbLogger.logDebugInfo("Actions.EMPLOYEEDOMAIN_RECIEVED", action.Domains);
            return { ...state, EmployeeDomains: action.Domains, isLoading: false };
        case Actions.COUNTRY_ADD:
            let a = { ...state, Countries: [...state.Countries, action.Country] };
            SkbLogger.logDebugInfo("store countries added: ", a);

            return a;
        case Actions.DIVISION_ADD:
            SkbLogger.logDebugInfo("store couDIVISIONntries before: ", action, state);

            let ad = { ...state, Divisions: [...state.Divisions, action.Division] };
            SkbLogger.logDebugInfo("store couDIVISIONntries added: ", ad);

            return ad;    
        case Actions.PROGRAM_ADD:
            let ap = { ...state, Programs: [...state.Programs, action.Program] };
            SkbLogger.logDebugInfo("store countries added: ", ap);

            return ap;    
        case Actions.PROGRAM_UPDATE:
            SkbLogger.logDebugInfo("store program update (BEFORE State): ", state);
            let pr = { ...state, Programs: state.Programs.map(c => c.id === action.Program.id ? action.Program : c) };
            SkbLogger.logDebugInfo("store program updated: ", pr);
            return pr;                           
        case Actions.EMPLOYEEDOMAIN_ADD:
            let da = { ...state, EmployeeDomains: [...state.EmployeeDomains, action.Domain] };
            SkbLogger.logDebugInfo("store Domain added: ", da);

            return da;
        case Actions.COUNTRY_UPDATE:
            SkbLogger.logDebugInfo("store countries update (BEFORE State): ", state);
            let r = { ...state, Countries: state.Countries.map(c => c.id === action.Country.id ? action.Country : c) };
            SkbLogger.logDebugInfo("store countries update: ", r);
            return r;
        case Actions.DIVISION_UPDATE:
            SkbLogger.logDebugInfo("store division update (BEFORE State): ", state);
            let dr = { ...state, Divisions: state.Divisions.map(c => c.id === action.Division.id ? action.Division : c) };
            SkbLogger.logDebugInfo("store division update: ", dr);
            return dr;          
        case Actions.EMPLOYEEDOMAIN_UPDATE:
            SkbLogger.logDebugInfo("store cEMPLOYEEDOMAIN_UPDATE (BEFORE State): ", state);
            let du = { ...state, EmployeeDomains: state.EmployeeDomains.map(c => c.id === action.Domain.id ? action.Domain : c) };
            SkbLogger.logDebugInfo("store EMPLOYEEDOMAIN_UPDATE: ", du);
            return du;
        case Actions.COUNTRY_DELETE:
            let t = { ...state.Countries };
            let d = [];
            if (Array.isArray(t)) {
                var idx = t.indexOf(action.Country);
                d = idx > -1 ? t.splice(idx, 1) : state;
            }
            d = { ...state, Countries: Array.isArray(d) ? d : [] };
            SkbLogger.logDebugInfo("store countries delete: ", d);

            return d;
        case Actions.EMPLOYEEDOMAIN_DELETE:
            let dt = { ...state.EmployeeDomains };
            let dd = [];
            if (Array.isArray(dt)) {
                var idx = dt.indexOf(action.Domain);
                dd = idx > -1 ? dt.splice(idx, 1) : state;
            }
            dd = { ...state, EmployeeDomains: Array.isArray(dd) ? dd : [] };
            SkbLogger.logDebugInfo("store countries delete: ", dd);

            return dd;
        case Actions.COUNTRYPHONE_UPDATE:
            let ph = { ...state, CountryPhones: state.CountryPhones.map(p => p.id === action.Phone.id ? action.Phone : p) };
            SkbLogger.logDebugInfo("store country phone update: ", ph);
            return ph;
        case Actions.COUNTRYPHONE_RECIEVED:
            let phs = { ...state, CountryPhones: action.Phones, isLoading: false };
            SkbLogger.logDebugInfo("store country phone Recevied: ", phs);
            return phs;
        default:
            return state;
    }
}