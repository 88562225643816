import axios from "axios";

import {crypts} from '../../utils/crypts';
import { config } from '../../utils/config';

function service() {

    // Consignment warehouse fetch api to be  used in Destination detail dropdown in Task Info popup
    const getTaskStockWarehouse = async (param) =>{
  
        try {                          
                
                const apiEndPoint =  `${process.env.REACT_APP_API_URL}stock/warehouse`;

                const idToken = crypts.decrypt(localStorage.getItem('id_token'));
                const authorization = config.REACT_APP_API_SECRET; 
               
                const headerOptions = {
                    "content-type": "application/json",
                    "authorization": authorization,  
                    "X-CustomHeader": idToken
                }; 
               
                const resp =  await axios({
                    method: 'GET', //you can set what request you want to be
                    url: apiEndPoint,
                    data: null,
                    headers: headerOptions
                });
                return resp;
            }catch (error) {
            console.error(error);
            }
    }; 

  
    return {getTaskStockWarehouse};
  
  }
  
  export const getTaskStockWarehouseService = service(); 
