import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid, GridList, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import SkbButton from "../../../../../skb_controls/components/SkbButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SkbIcon from "../../../../../skb_controls/components/SkbIcon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SkbSelect } from "../../../../../skb_controls/components/SkbSelect";
import SkbTable from "../../../../../skb_controls/components/SkbTable";

// importing actions
import {
    selectCSVFile,
    readCSVStart,
    clearUploadFile,
    clearCSVError,
    setProcessing,
    deleteCSVFile,
    threePLImportationStart,
    fetchOutcomesStart,
    fetchLocations,
} from "../../../../../Redux/ThreePLImportation/actions";

// importing selectors
import {
    selectFilename,
    selectCSVJsonData,
    selectCSVJSONDataTable,
    selectProcessingCSV,
    selectCsvProcessingError,
    selectWarehouseLocations,
    selectWarehouseLocationsError,
    selectLoadingWarehouseLocations,
} from "../../../../../Redux/ThreePLImportation/selectors";
import { useSnackbar } from "notistack";
import Loading from "../../../../Loading";

const useStyles = makeStyles((theme) => ({
    fileUploadBox: {
        padding: "0.5em 1em",
        minWidth: "400px",
        border: "2px dashed black",
        borderRadius: "5px",
        textAlign: "center",
    },
    containerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down(600)]: {
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
        },
    },
}));

const DragAndDropField = ({ onDrop }) => {
    const classes = useStyles();
    return (
        <div className={classes.fileUploadBox}>
            <Dropzone onDrop={onDrop} noClick>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!isDragActive ? <p>DRAG FILE HERE</p> : <p>DROP FILE HERE</p>}
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    );
};

const SelectCSV = ({
    // actions
    readCSV,
    chooseFile,
    clearUploadFile,
    setProcessing,
    deleteFile,
    submitForProcessing,
    fetchOutcomes,
    fetchLocations,
    // selectors
    fileName,
    csvJSONData,
    csvJSONDataTable,
    processing,
    csvError,
    loadingWarehouseLocations,
    warehouseLocations,
    threePLImportationSuccess,
    clearCSVError,
}) => {
    const ref = useRef();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const [fileContains, setFileContains] = useState('Serialised Stock')
    // const [processItems, setProcessItems] = useState('Individual stock items')
    const [warehouseLocation, setWarehouseLocation] = useState("");
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        if (csvJSONData && !isShow) {
            enqueueSnackbar("File Selection Complete, proceed to next step", { variant: "success" });
            setIsShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csvJSONData]);
    console.log({ csvError });
    useEffect(() => {
        clearCSVError();
        if (csvError?.message) enqueueSnackbar(csvError.message, { variant: "error" });
        if (csvError?.formatError) enqueueSnackbar(csvError.formatError, { variant: "error" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csvError, clearCSVError]);
    useEffect(() => {
        fetchLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const changeWarehouseLocation = (value) => {
        setWarehouseLocation(value);
    };

    // const changeProcessItemsHandle = (value) => {
    //     setProcessItems(value)
    // }

    const onSubmit = () => {
        console.log("🚀 ~ file: SelectCSV.js:136 ~ onSubmit ~ csvJSONData:", csvJSONData)
        if (csvJSONData !== null && csvJSONData.length > 0) {
            submitForProcessing(csvJSONData);
        } else {
            enqueueSnackbar("CSV file is required to be selected", { variant: "error" });
        }
    };

    const deleteFileHandle = (e, row) => {
        row.forEach((item) => {
            deleteFile(item);
        });
    };
    const uploadFileColumn = [
        { title: "Hidden Col", field: "requestId", type: "hidden-id" },
        { title: "File Name", field: "fileName", type: "read-only-short-text" },
        { title: "Status", field: "status", type: "read-only-short-text" },
        // { title: 'File Contains', field: 'isSerialised', type: 'read-only-short-text'},
        // { title: 'Process Items', field: 'processingType', type: 'read-only-short-text'}
    ];
    const demoActions_select = [
        { type: "select", onClick: (rowData) => console.log(rowData) },
        { type: "delete", onClick: deleteFileHandle },
    ];
    const tooltipContent = (
        <React.Fragment>
            <Typography color="inherit" style={{ fontSize: "14px" }}>
                Processing individual stock items ensures that only the items in the uploaded file will be updated. No other calculations surrounding
                the rest of the location will be considered.
            </Typography>
            <br />
            <Typography color="inherit" style={{ fontSize: "14px" }}>
                Processing locations related to stock items will update individual stock items and process the entire location stock. If there are
                items in a location not listed ie. after a stock take, then these items will be marked as Lost. eg. If there are 5 items in the file
                but 6 were in the location, the 6th item would be marked os lost.
            </Typography>
        </React.Fragment>
    );
    useEffect(() => {
        clearUploadFile();
    }, [clearUploadFile]);
    useEffect(() => {
        if (warehouseLocations.length) {
            setWarehouseLocation(warehouseLocations[0].value);
        }
    }, [warehouseLocations]);
    return (
        <>
            <Grid container className={classes.root} id="return_stock_wizard_internal">
                <Grid item xs={12} id="taskdetail_title" className={classes.title}>
                    <Typography variant="h5">Upload 3PL Pslip</Typography>
                    <Typography>Upload a CSV file with all the stock items that need to be imported</Typography>
                </Grid>
            </Grid>
            <Grid className="stockContain" container spacing={2} direction="column">
                <SkbSelect
                    Title="Warehouse: "
                    value={warehouseLocation}
                    items={warehouseLocations}
                    valueChanged={(e) => changeWarehouseLocation(e)}
                />
                {/* <SkbSelect Title="Process Items: " tooltip={tooltipContent} value={processItems} items={["Individual stock items", "Locations related to stock items", "Allocate Job Stock"]} valueChanged={e => changeProcessItemsHandle(e)}/> */}
                <Typography style={{ marginTop: "55px" }} varient="body2">
                    Select CSV File
                </Typography>
                <Grid container className={classes.containerFlex}>
                    <Grid item>
                        <DragAndDropField
                            onDrop={(files) => {
                                const data = {
                                    file: files[0],
                                    warehouseLocation,
                                };
                                readCSV(data);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">OR</Typography>
                    </Grid>
                    <Grid item>
                        <input
                            type="file"
                            id="file-input"
                            style={{ display: "none" }}
                            ref={ref}
                            accept=".xlsx, .xls, .csv"
                            onChange={(e) => {
                                const data = {
                                    file: e.target.files[0],
                                    warehouseLocation,
                                };
                                readCSV(data);
                                setTimeout(() => {
                                    setProcessing(false);
                                }, 1000);
                            }}
                        />
                        <SkbButton
                            primary
                            text={
                                <Typography variant="body2" style={{ color: "#ffff" }}>
                                    <SkbIcon icon={CloudUploadIcon} /> Upload File
                                </Typography>
                            }
                            onClick={() => document.querySelector("#file-input").click()}
                        />
                    </Grid>
                </Grid>
                <br />
                <SkbTable
                    columns={uploadFileColumn}
                    dataSource={csvJSONDataTable}
                    id="stock_threeplimportation_table"
                    actions={demoActions_select}
                    options={{
                        // selectedIdValues:SelectedMethod,
                        emptyDataSourceMessage: "There are no items available, please upload!",
                    }}
                />
                <br />
                <Typography style={{ marginTop: "20px" }} varient="body2">
                    Please download a copy of the file format below
                </Typography>
                <Grid container className={classes.containerFlex}>
                    <SkbButton
                        text={
                            <Typography variant="body2">
                                <SkbIcon icon={CloudDownloadIcon} color="#ffff" />{" "}
                                <a style={{ color: "#000", textDecoration: "none" }} href="/[TEMPLATE] 3PL Pslip.zip">
                                    Template
                                </a>{" "}
                            </Typography>
                        }
                        style={{
                            width: "fit-content",
                            marginTop: "10px",
                        }}
                    />
                    <SkbButton
                        primary
                        text={
                            <Typography variant="body2" style={{ color: "#ffff" }}>
                                Submit
                            </Typography>
                        }
                        style={{
                            width: "fit-content",
                            marginTop: "10px",
                        }}
                        onClick={onSubmit}
                    />
                </Grid>
            </Grid>
            {(processing || loadingWarehouseLocations) && <Loading />}
        </>
    );
};

const mapState = (state) => ({
    fileName: selectFilename(state),
    csvJSONData: selectCSVJsonData(state),
    processing: selectProcessingCSV(state),
    csvError: selectCsvProcessingError(state),
    csvJSONDataTable: selectCSVJSONDataTable(state),
    loadingWarehouseLocations: selectLoadingWarehouseLocations(state),
    warehouseLocations: selectWarehouseLocations(state),
    warehouseLocationsError: selectWarehouseLocationsError(state),
});
const mapDispatch = (dispatch) => ({
    chooseFile: (name) => dispatch(selectCSVFile(name)),
    readCSV: (path) => dispatch(readCSVStart(path)),
    clearCSVError: () => dispatch(clearCSVError()),
    clearUploadFile: () => dispatch(clearUploadFile()),
    setProcessing: (value) => dispatch(setProcessing(value)),
    deleteFile: (file) => dispatch(deleteCSVFile(file)),
    submitForProcessing: (data) => dispatch(threePLImportationStart(data)),
    fetchOutcomes: (uuid) => dispatch(fetchOutcomesStart()),
    setLoadingWarehouseLocations: () => dispatch(selectLoadingWarehouseLocations),
    fetchLocations: () => dispatch(fetchLocations()),
});

export default connect(mapState, mapDispatch)(SelectCSV);
