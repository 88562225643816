import React, { useEffect, useState,forwardRef  } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import Save from '@material-ui/icons/Save';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SkbIcon from '../../../skb_controls/components/SkbIcon';

export const tableIcons = {
  Add: forwardRef((props, ref) => <SkbIcon icon={AddBox} {...props} ref={ref} />),
  Cancel: forwardRef((props, ref) => <SkbIcon icon={Cancel}   {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <SkbIcon icon={Check}   {...props} ref={ref} />),
  CheckCircle: forwardRef((props, ref) => <SkbIcon icon={CheckCircle}   {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <SkbIcon icon={Clear} {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <SkbIcon icon={DeleteOutline} {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <SkbIcon icon={ChevronRight} {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <SkbIcon icon={Edit} {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SkbIcon icon={SaveAlt}{...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <SkbIcon icon={FilterList} {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <SkbIcon icon={FirstPage} {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <SkbIcon icon={LastPage} {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <SkbIcon icon={ChevronRight} {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <SkbIcon icon={ChevronLeft} {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <SkbIcon icon={Clear} {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <SkbIcon icon={Save} {...props} ref={ref} />),
  SaveAlt: forwardRef((props, ref) => <SkbIcon icon={SaveAlt} {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SkbIcon icon={Search} {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <SkbIcon icon={ArrowDownward} {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <SkbIcon icon={Remove} {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <SkbIcon icon={ViewColumn} {...props} ref={ref} />)
  };
