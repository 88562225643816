import React, { useEffect, useRef, useState } from 'react';
import ContractorDropOffOption from './PickingPacking/ContractorDropOffOption';
import SkybridgeCourierOption from './PickingPacking/SkybridgeCourierOption';
import{
    Grid, Typography,
} from '@material-ui/core';
import {
    connect,
} from 'react-redux';

import { changeDeliveryOption } from '../../../Redux/StepPackageDetails/actions';
import { selectDeliveryOptions } from '../../../Redux/StepPackageDetails/selectors';
import { PropertiesPlugin } from '@microsoft/applicationinsights-web';

const SelectDeliveryOption_DEV = ({changeOption}) => {
    return(
        // not working
        <div>
            <select
                onChange={e=>changeOption(e.target.value)}
                
            >
                <option value={0}>Skybridge Courier</option>
                <option value={1} selected>Contractor Drop Off</option>
            </select>
        </div>
    )
}

// @param {boolean} isLoading: Is data currently loading? 
// @param {object} pickupDetails
//e.g. {
                        // IsATPAuthorised: true,
                        // IsForkliftRequired: true,
                        // PickupWindow: {
                        //     Date: "", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
                        //     ETA: "7am - 1pm",
                        // },
// @param {function} loadPickupDetails: A function to load the data for pickupDetails.
// @param {function} updatePickupDetails
//          parameter e.g.  {
                        // IsATPAuthorised: true,
                        // IsForkliftRequired: true,
                        // PickupWindow: {
                        //     Date: "", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
                        //     ETA: "7am - 1pm",
                        // }
// @param {array} packageDetails
//e.g. [                       
                    //     {
                    //         Type: "Pallet", // Pallet or Carton
                    //         Qty: 2,
                    //         Length: 100,
                    //         Width: 100,
                    //         Height: 100,
                    //         Weight: 30,
                    //     },
                    //...
                    // ]
// @param {function} loadPackageDetails : A function to load the data for packageDetails.
// @param {function} addPackage
// with parameter in structure: {
                    //         Type: "Pallet", // Pallet or Carton
                    //         Qty: 2,
                    //         Length: 100,
                    //         Width: 100,
                    //         Height: 100,
                    //         Weight: 30,
                    //     }
// @param {function} updatePackage
//              parameter as for addPackage
// @param {function} deletePackage
//              parameter as for addPackage

//dropOffDetails{Date: "2021-08-16", //date    //A function to load the data for dropOffDetails, if not there.
//                ETA: "7am - 1pm",},
//@param {function} loadDropOffDetails

// @param {function} updateDropOffDetails : A function to update the dropOffDetails.
//      {Date: "2021-08-16", //date
//      ETA: "7am - 1pm",},
// @param {date} defaultPickupDate(=PickupDueday, date, optional)  : If no pickupDetails.PickupWindow.Date, then this is the default (i.e. lower priority than the pickupDetails provided value).  
// @param {date} defaultDeliveryDate(=ReturnDueDay, date, optional) : If no dropOffDetails.Date, then this is the default.  

// @param {object} deliveryDestination:
    //{DeliveryDestination: "SKB Tullamarine Warehouse",
    //DeliveryAddressLine1: "101-105 Keilor Park Drive",
    //DeliveryAddressLine2: "",
    //DeliveryAddressSuburb: "Tullamarine",
    //DeliveryAddressState: "VIC",
    //DeliveryAddressPostcode:"3043"}

// @param {string} consignmentMethod : The consignment or delivery method.  Either "Skybridge courier" or "Contractor drop-off".

export default function StepPackageDetails (props) {
	console.log('StepPackageDetails',props)
	 const {
        isLoading,
        pickupDetails,
        loadPickupDetails,
        packageDetails,
        loadPackageDetails,
        addPackage,
        updatePackage,
        deletePackage,
        updatePickupDetails,
        consignmentMethod,

        defaultPickupDate,
        defaultDeliveryDate,
        dropOffDetails,
        loadDropOffDetails,
        updateDropOffDetails,
        deliveryDestination
    } = props;
  
    useEffect(()=>{
        loadPickupDetails();
        loadPackageDetails();
        loadDropOffDetails();
       // alert(props.pickupDetails.IsATPAuthorised);
    },[]);
  
    switch(consignmentMethod){
        case "Skybridge courier":
            return <SkybridgeCourierOption
                        isLoading={isLoading}
                        pickupDetails={pickupDetails}
                        loadPickupDetails={loadPickupDetails}
                        packageDetails={packageDetails}
                        loadPackageDetails={loadPackageDetails}
                        addPackage={addPackage}
                        updatePackage={updatePackage}
                        deletePackage={deletePackage}
                        updatePickupDetails={updatePickupDetails}
                        consignmentMethod={consignmentMethod}
                        defaultPickupDate={defaultPickupDate}
                        defaultDeliveryDate={defaultDeliveryDate}
                        
                    />
        
        case "Contractor drop-off":
            return <ContractorDropOffOption
                        isLoading={isLoading}
                        pickupDetails={pickupDetails}
                        loadPickupDetails={loadPickupDetails}
                        dropOffDetails = {dropOffDetails}
                        packageDetails={packageDetails}
                        loadPackageDetails={loadPackageDetails}
                        addPackage={addPackage}
                        updatePackage={updatePackage}
                        deletePackage={deletePackage}
                        updatePickupDetails={updatePickupDetails}
                        consignmentMethod={consignmentMethod}
                        updateDropOffDetails={updateDropOffDetails}
                        defaultPickupDate={defaultPickupDate}
                        defaultDeliveryDate={defaultDeliveryDate}
                        deliveryDestination={deliveryDestination}
                    />

        default:
            return(
                <Typography variant="h2">Consignment Method : {consignmentMethod} not Valid</Typography>
            )
    }  
}

