import React, {  useEffect }  from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import SkbButton from '../../skb_controls/components/SkbButton';
import FullpageLoader from '../FullpageLoader';

const useStyles = makeStyles((theme) => ({
  header:{
    marginBottom: theme.spacing(3),
    textAlign:"center"
  },
  text:{
    marginBottom: theme.spacing(3),
    textAlign:"left",
   // padding:theme.spacing(3),
  }
}));

export default function RegistrationSubmitted(props){
  const classes = useStyles();
  const [showLoader,setshowLoader] = React.useState(false);

  const goHomePage =() =>{
    setshowLoader(true);
    window.location.href=window.location.href.replace(/(\/)+$/ig,'')+"/logout"
  }

  useEffect(() => {
    window.scrollTo(0,0);
    localStorage.clear();
  },[])
    return(
      <React.Fragment>
        {showLoader && <FullpageLoader />}  
      <Container component="main"  maxWidth="lg" justify="center">
        <Grid container justify="center" className={classes.header}>
          <Typography  paragraph variant="h2">
            Registration Submitted
          </Typography>
        </Grid>
      </Container>
      <Container component="main"  maxWidth="md" >
        <Grid container justify="center" className={classes.text}>
          <Typography paragraph variant="body2">
            {props.text}
            <br/>
            <br/>
            {props.text2}
          </Typography>
        </Grid>
        <Grid container justify="center" className={classes.textMargin}>
        <SkbButton id="goHomePage" name={"Small Primary"}  primary size="small" text="Continue" onClick={() => goHomePage()}/>  
        </Grid>  
      </Container>
      </React.Fragment>
    )

}





