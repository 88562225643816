import React, { useEffect, lazy } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import InputIcon from '@material-ui/icons/Input';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
//import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from "react-js-pull-to-refresh";
// import Loading from '../../../Loading';
// import {crypts} from '../../../../utils/crypts';

// import SkbIcon from '../../../../skb_controls/components/SkbIcon';
//import SkbPullToRefresh from '../../../../skb_controls/components/SkbPullToRefresh';
// import { SkbLogger } from '../../../../services';
// // import { sync } from '../../../../Redux/reducers/sync';
// import * as taskActions from '../../../../Redux/actions/returnsDashboard';

import { Grid } from '@material-ui/core';

import ReturnsList from './ReturnsList';
            
import { useSnackbar } from 'notistack';

// import * as assistanceNumber from '../../../../Redux/actions/callForAssistance';
// import SkbButton from '../../../../skb_controls/components/SkbButton';
// import SkbTable from '../../../../skb_controls/components/SkbTable';

const useStyles = makeStyles((theme) => ({
    root: {

        marginTop: theme.spacing(2)
    },

    returnDashboardHeader: {
        alignItems: 'center',
    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end', 
    },
    tasksfooter: {
        textAlign: 'center',
        height: 200,
        cursor: 'default',
        paddingTop: 5, 
        overscrollBehavior: "contain",
    }
}));


function ReturnsDashboard(props) {
    // const classes = useStyles();
    // const { Tasks, loadTasks, updateAssistanceNumbers, CurrentTask,Sync } = props;
    // const { enqueueSnackbar } = useSnackbar();

    return (
        <div>         
            <ReturnsList /> 
            
        </div>
    );

}
const mapStateToProps = state => ({
   
});

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(null, null)(ReturnsDashboard)