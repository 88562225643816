import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';

// const iconStyle = theme => (
//     {
//         icon: {
//             "&  svg": {
//                 color: theme.palette.primary.main,
//             }
//         }
//     }
// );

const SkbIcon = withStyles()(props => {
    const { classes, icon, ...other } = props;
    const styles = classes && classes.iconStyle ? classes.iconStyle : {};
    //console.log(classes);
    const Icon = icon;
    return (
        <span className={styles}>
            <Icon fontSize='inherit' color='primary' {...other} />
        </span>
    );
})

export default SkbIcon;