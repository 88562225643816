/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Paper from '@material-ui/core/Paper';

import BusinessIcon from '@material-ui/icons/Business';
import SkbIcon from '../../skb_controls/components/SkbIcon';

import Typography from '@material-ui/core/Typography';


import SkbCardsView from '../../skb_controls/navigation/SkbCardsView';

import { crypts } from '../../utils/crypts';
import { Link as RouterLink, BrowserRouter, useLocation } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { connect } from 'react-redux';
import { SkbLogger } from '../../services';
import * as icons from '../../utils/icons';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as assistanceNumber from '../../Redux/actions/callForAssistance'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(theme => ({
  root: {

    maxWidth: 345,
    maxHeight: 550,
    marginTop: 30,

    [breakpoints.down('sm')]: {
      marginBottom: 30
    },
    [breakpoints.up('md')]: {
      marginBottom: 70
    },

    marginLeft: 20,
    marginRight: 20

  },
  centreItem: {
    textAlign: "-webkit-center", //"-moz-center"

  },

  messageCard:
  {

    [breakpoints.down('sm')]: {
      height: 405
    },
    [breakpoints.up('md')]: {
      height: 490
    }
    // ,
    // textAlign:'justify'
  },
  paper: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "& li:hover": {
      color: theme.palette.text.highlighted,
    },
    appBar: {
      [theme.breakpoints.down('sm')]: {
        position: "relative",

      },
      [theme.breakpoints.up('md')]: {

        position: "sticky",
        top: 60,
      },

      paddingTop: 4,
      backgroundColor: theme.palette.background.default,

    },
  },


}));

// function moduleIsTopLevelOfModuleList(moduleId, moduleList) {
//   var module = moduleList.find(m => m.moduleID === moduleId);
//   return !module.ParentModuleId;

// }

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export function HomePage(props) {  //exporting for testing
  // let query = useQuery();



  // alert("Module name "+query.get("module"));
  // alert("Virtual Module name "+props.virtualModuleName);

  const classes = useStyles();

  /**
   * sample user Module data in redux store
  [{id: 2, userID: 10, name: "Manage Variables", uRLSuffix: "ManageVariables", moduleID: 1, description:"Description of module", imagePath: "image.jpg"}]
  */
  const { Modules } = props.user; //retrieve User's modules from store

  const [moduleList, setModuleList] = React.useState([]);

  const [selectedVirtualModuleId, setSelectedVirtualModuleId] = React.useState(-1);
  const [topLevelModuleName, setTopLevelModuleName] = React.useState("");  //This is the one just below Home.
  const [topLevelModuleIcon, setTopLevelModuleIcon] = React.useState("");

  React.useEffect(() => {

    if (props.selectedModuleId !== undefined && props.selectedModuleId !== -1)
    //This allows us to go directly to a virtual module rather than drilling down through 
    //the cards.  Usually used by the side menu links.

    {
      setSelectedVirtualModuleId(props.selectedModuleId);

      if (topLevelModuleName === "")  //the previous value was blank, so at top of tree.
      {
        //  debugger;
        var selectedModule = Modules.find(m => m.moduleID === props.selectedModuleId);

        if (selectedModule) {
          setTopLevelModuleName(selectedModule.name);
          setTopLevelModuleIcon(selectedModule.iconName);
        }
      }
    }
    else {

      const modulesCopy = [...Modules].filter(module => !module.ParentModuleId && module.name !== 'Home');
      setModuleList(modulesCopy);
    }

  }, []);

  React.useEffect(() => {
    // alert('changed virtual module id'+selectedVirtualModuleId);

    // alert(selectedVirtualModuleId);
    if (selectedVirtualModuleId === -1) {
      const modulesCopy = [...Modules].filter(module => !module.ParentModuleId && module.name !== 'Home');
      setModuleList(modulesCopy);

    }
    else {
      var filteredModuleList = [];
      filteredModuleList = Modules.filter(m => m.ParentModuleId === selectedVirtualModuleId);
      // alert('Length of list' + filteredModuleList.length);
      setModuleList(filteredModuleList);

    }
    
  }, [selectedVirtualModuleId]);


  // var nameOfNewModule = Modules.find(m => m.moduleID === selectedVirtualModuleId).name;

  // if (nameOfNewModule!==undefined)
  // {
  //   props.changeAppTitle(nameOfNewModule);
  // }

  
  //show the call for assistance button when back to home page
  props.updateAssistancePhoneNumber(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));

  SkbLogger.applicationTrace('HomePage', SeverityLevel.Verbose, 'Home Page', 'Loading Home page', props.user.AuthUser.sub);

  SkbLogger.applicationTrace('HomePage', SeverityLevel.Verbose, 'Home Page', `User has ${Modules.length} modules.`, Modules);

  var clickOnVirtualModule = (event) => {

    // alert('current virtual module id'+selectedVirtualModuleId);

    //  setSelectedVirtualModuleId(selectedVirtualModuleId+1);
    // alert('newly selected module id' +(selectedVirtualModuleId+1));

    var cardActionArea = event.target.closest("[id^=card-action-area]");

    var clickedModuleName = cardActionArea.name;

    var selectedModule = Modules.find(m => m.name === clickedModuleName);

    if (selectedModule !== undefined) {
      setSelectedVirtualModuleId(selectedModule.moduleID);

      if (topLevelModuleName === "")  //the previous value was blank, so at top of tree.
      {
        setTopLevelModuleName(selectedModule.name);
        setTopLevelModuleIcon(selectedModule.iconName);
      }

    }

    //  props.changeAppTitle(clickedModuleName); //For some reason doing it here breaks the virtual redirection.

  }

  var clickOnRealModule = (event) => {

    var clickedModuleName = event.target.title;

    props.changeAppTitle(clickedModuleName);

  }




  if (moduleList && moduleList.length === 0  //no modules.  The Home page module has been filtered out.   
    && selectedVirtualModuleId === -1)  //Only if we're on the actual home page, not on a virtual module page under that.
  {
    return (
      <div>
        <Grid container direction="row" justify="space-evenly" alignContent="space-around" className={classes.centreItem}>

          {/* The no access card */}
          <Grid item xs={12} lg={4}> <Card className={classes.root} elevation={5}>

            <CardContent className={classes.messageCard} >

              <Typography variant="h6" component="p" >
                Your access to the system is currently being processed.
              </Typography>

              <Typography variant="body1" component="p" >
                If you still see this message beyond 10 minutes after registering, then please use the Call For Assistance button below or contact Tech Support team directly. 
              </Typography>
            </CardContent>


          </Card></Grid>
        </Grid>
      </div>);

  }
  else {

    let cardsInfo = [];

    moduleList.forEach(module => {

      if (module.isVirtualModule) {
        cardsInfo.push({
          Name: module.name,
          Description: module.description,
          ImagePath: `/images/${module.imagePath}`,
          Icon: icons.makeIcon(module.iconName),

          ClickFunction: (item) => clickOnVirtualModule(item)
        });
      }
      else {
        cardsInfo.push({
          Name: module.name,
          Description: module.description,
          ImagePath: `/images/${module.imagePath}`,
          Icon: icons.makeIcon(module.iconName),
          NavigationRoute: `/${module.uRLSuffix}`,
          ClickFunction: (item) => clickOnRealModule(item)
        });
      }


    });


    if (topLevelModuleName !== undefined && topLevelModuleName !== "") {

      return (<div>
        {/* Selected Module Id: {selectedVirtualModuleId} */}
        <Paper className={classes.paper} > <Typography id="form-page-heading" variant="h1"><Grid container><SkbIcon icon={icons.makeIcon(topLevelModuleIcon)} />{topLevelModuleName}</Grid></Typography></Paper>

        <SkbCardsView CardsInfo={cardsInfo} /></div>);
    }
    else {
      return (<div>
        {/* Selected Module Id: {selectedVirtualModuleId} */}

        <SkbCardsView CardsInfo={cardsInfo} /></div>);
    }
  }
}


const mapStateToProps = state => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateAssistancePhoneNumber: (phoneNumbers) => dispatch(assistanceNumber.updatePhoneNumber(phoneNumbers))

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);