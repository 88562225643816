import { setCallForAssistance } from "./auth";
import { SkbLogger, ApiService, SeverityLevel } from '../../services';
import {crypts} from '../../utils/crypts';
export const Actions = {
    UPDATE_CALLASSISTANCE_NUMBERS: "UPDATE_CALLASSISTANCE_NUMBERS"
};


export function updatePhoneNumber(phoneNumbers) {
    return {
        type: Actions.UPDATE_CALLASSISTANCE_NUMBERS,
        payload: phoneNumbers,
    };
}
//getCallForAssistancePhoneNumbers get phone number from api then save to the localStorage
export function getPhoneNumberFromAPI() {
   
    return (dispatch) => {
            try {
                //{"phoneOwner":"Devices","phone":"tel:123456789","phoneText":"123456789"},
                ApiService.getCallForAssistancePhoneNumbers().then(function (res) {
                    //localStorage.setItem('module_list',crypts.encrypt(JSON.stringify(res.data)));
                    const phoneList = [];
                    if(res.data && res.data.length >0){
                        res.data.map((item)=>{
                            phoneList.push({
                                "phoneOwner":item.variableName,
                                "phone" :'tel:'+ item.variableValue.replace(/\s/g,''),
                                "phoneText": item.variableValue
                            })
                        })
                    }
                    SkbLogger.logDebugInfo('getCallForAssistancePhoneNumbers',phoneList)
                    localStorage.setItem('skb_phoneNumbers',crypts.encrypt(JSON.stringify(phoneList)))
                    SkbLogger.applicationTrace('CallForAssistance', SeverityLevel.Information, 'Get CallForAssistance', 'Get CallForAssistance from API successful', res);
        
                })
                    .catch(function (error) {
                        SkbLogger.logDebugInfo('sendCallForAssistanceRequest: error Response', error)
                        SkbLogger.applicationException('CallForAssistance', 'Get CallForAssistance', 'Exception from get CallForAssistance API', error);
                    });

            } catch (err) {
                SkbLogger.applicationException('getPhoneNumberFromAPI','Get CallForAssistance','Get CallForAssistance failed (in exception try-catch)',err);
  
                }
            }

}