import types from './types';


export const selectCSVFile = fileName => ({
    type : types.SELECT_CSV_FILE,
    payload : fileName,
});
export const deleteCSVFile = file =>({
    type : types.DELETE_CSV_FILE,
    payload: file
});
export const setSerialized = value => ({
    type : types.SET_SERIALIZED,
    payload : value,
})


export const readCSVStart = filePath =>({
    type : types.READ_CSV_START,
    payload : filePath,
});
export const readCSVSuccess = data =>({
    type : types.READ_CSV_SUCCESS,
    payload : data,
});

export const readCSVError = error =>({
    type : types.READ_CSV_ERROR,
    payload : error,
})

export const clearCSVError = () =>({
    type : types.CLEAR_UPLOAD_ERROR,
})

export const clearUploadFile = () => ({
    type : types.CLEAR_UPLOAD_FILE,
})

export const setProcessing = value => ({
    type : types.SET_PROCESSING,
    payload: value
})

export const toggleViewCsvData = ()=>({
    type : types.TOGGLE_VIEW_CSV_DATA,
})



export const threePLImportationStart = data =>({
    type : types.THREE_PL_IMPORTATION_START,
    payload : data,
});
export const threePLImportationSuccess = data =>({
    type : types.THREE_PL_IMPORTATION_SUCCESS,
    payload : data,
});
export const threePLImportationError = error =>({
    type : types.THREE_PL_IMPORTATION_SUCCESS,
    payload : error,
});

export const setNewRequestId = () =>({
    type : types.SET_NEW_REQUEST_ID,
});
export const clearRequestId = () =>({
    type : types.CLEAR_REQUEST_ID,
})

export const fetchOutcomesStart = () =>({
    type : types.FETCH_OUTCOMES_START,
    // payload : requestID,
});
export const fetchOutcomesSuccess = data =>({
    type : types.FETCH_OUTCOMES_SUCCESS,
    payload : data,
});
export const fetchOutcomesError = error =>({
    type : types.FETCH_OUTCOMES_ERROR,
    payload : error,
})

export const fetchLocations = () => ({
    type: types.FETCH_LOCATIONS
});
export const fetchLocationsSuccess = (data) => ({
    type : types.FETCH_LOCATIONS_SUCCESS,
    payload : data,
})
export const fetchLocationsError = (error) => ({
    type : types.FETCH_LOCATIONS_ERROR,
    payload : error,
})