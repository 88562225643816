import { Actions } from '../actions/register';
import { SkbLogger } from '../../services';

/**
 * SelectedCountry:[1],SelectedCountryName:["Australia"],
 * SelectedUserType:'0',UserPhone:'',UserCompanyName:'',UserAddress:'',UserPostalAddress:'',UserTaxNumber:'',SelectPhonePrefix:[]
 * these value will send to serve
 * RegisterStep control the register component render
 *  country Id 1 is the Aus. set as default
 * SelectedUserType: 1 set contractor user type as default
 */

let initialStatus = {
    SelectedCountry: [1], SelectedCountryName: ["Australia"], SelectedUserType: 1, UserPhone: '', UserCompanyName: '', UserAddress: '', UserPostalAddress: '', UserTaxNumber: '', UserName: '',
    VerificationCodeSent: false, CodeVerifiedSuccess: false, VerifyingCode: false,
    RegisterStep: 0, countries: [], IsSKBUser: false,
    PhonePrefixList: [], TextCodeLable: [], allCountryphonePrefix: [], SelectPhonePrefix: [], allTaxLabelList: [], SelectedTaxlabelList: [], SelectedTaxLabel: [], submitError: '',
    submittingRegister: false, skbDomainList: [], PoAddressSameAsSt: true, VerifyingStreetAddress: false, StreetAdIsValid: false, StreetAdIsInvalid: false
};

export function register(state = initialStatus, action) {
    const contriesList = [];
    const SkbUserType = 3;
    const allCountryphonePrefixList = [];

    const getAllPhonePrefix = (e) => {
        e.map((i) =>
            allCountryphonePrefixList.push({
                "countryId": i["parentVariableID"],
                "phonePrefix": i["variableValue"].split("|", 1)[0],
                "landlineLength": i["variableValue"].split("|", 2)[1],
                "mobileLength": i["variableValue"].split("|", 3)[2],
                "mobilePrefix": i["variableValue"].split("|", 4)[3]
            })
        )
        SkbLogger.logDebugInfo("farom getAllPhonePrefix", allCountryphonePrefixList)
        return allCountryphonePrefixList;
    }

    const getTaxLabels = (e) => {
        var temp = [];
        SkbLogger.logDebugInfo("from getTaxLabels start", e);
        e.map((item, i) =>
            temp.push({
                "countryId": item.parentVariableID,
                "text": item.variableName,
                "value": item.variableValue
            })
        )
        SkbLogger.logDebugInfo("from getTaxLabels end", temp);
        return temp;
    }

    const getUsersPhonePrefix = (e) => {
        var temp = [];
        SkbLogger.logDebugInfo("from getUsersPhonePrefix", e);
        e.map((i) => {
            if ((state.allCountryphonePrefix.find(el => el.countryId == i))) {
                temp.push({
                    "countryId": state.allCountryphonePrefix.find(el => el.countryId == i).countryId,
                    "prefix": state.allCountryphonePrefix.find(el => el.countryId == i).phonePrefix,
                    "mobileLength": parseInt(state.allCountryphonePrefix.find(el => el.countryId == i).mobileLength),
                    "landlineLength": parseInt(state.allCountryphonePrefix.find(el => el.countryId == i).landlineLength)
                })
            }
        }
        )
        return temp;
    }

    const updateDefaultValueForPhonePrefix = () => {
        var temp = [];

        SkbLogger.logDebugInfo("country", state.countries, state.SelectedCountry)
        var firstSelectedCountryId = state.SelectedCountry[0];
        temp.push({
            "countryId": state.allCountryphonePrefix.find(el => el.countryId == firstSelectedCountryId).countryId,
            "countryName": state.countries.find(el => el.value == firstSelectedCountryId).text,
            "prefix": state.allCountryphonePrefix.find(el => el.countryId == firstSelectedCountryId).phonePrefix,
            "mobileLength": parseInt(state.allCountryphonePrefix.find(el => el.countryId == firstSelectedCountryId).mobileLength),
            "landlineLength": parseInt(state.allCountryphonePrefix.find(el => el.countryId == firstSelectedCountryId).landlineLength)
        });
        return temp;
    }

    const UpdateToCountryId = (e) => {
        var temp = [];
        e.map((i) => {
            if ((state.countries.find(el => el.text == i))) {
                temp.push(state.countries.find(el => el.text == i).value)
            }
        }

        )
        return temp;
    }
    const getSelectedCountryTaxlabel = (e) => {
        var temp = [];
        e.map((i) => {
            if ((state.allTaxLabelList.find(el => el.countryId == i))) {
                temp.push({
                    "countryId": state.allTaxLabelList.find(el => el.countryId == i).countryId,
                    "taxLabel": state.allTaxLabelList.find(el => el.countryId == i).text,
                    "taxLabelValue": state.allTaxLabelList.find(el => el.countryId == i).value
                })
            }
        }

        )
        return temp;
    }

    const getSelectedTaxlabel = (e) => {
        var temp = [];

        temp.push({
            "countryId": e,
            "taxLabel": state.allTaxLabelList.find(el => el.countryId == e).taxLabel,
            "taxLabelValue": state.allTaxLabelList.find(el => el.countryId == e).taxLabelValue
        })

        return temp;
    }


    const getUserSelectedPrefix = (e) => {

        var temp = [];
        temp.push({
            "countryId": e,
            "prefix": state.PhonePrefixList.find(el => el.countryId == e).prefix,
            "mobileLength": state.PhonePrefixList.find(el => el.countryId == e).mobileLength,
            "landlineLength": state.PhonePrefixList.find(el => el.countryId == e).landlineLength
        })

        return temp;
    }




    const updateCountries = (e) => {
        {
            e.map((item, i) => {
                {
                    if (item.active == 1) {
                        contriesList.push({
                            "value": item.id,
                            "text": item.variableName
                        })
                    }
                }
            })
        }
        return contriesList;
    }



    const checkSkbUser = (email) => {
        SkbLogger.logDebugInfo("checkSkbUser", email, state.skbDomainList)
        email = '@' + email.split("@")[1];
        var resulte = false;
        state.skbDomainList.map((i) => {
            {
                if ((i.active == 1) && i.variableName == email) {
                    resulte = true
                }
            }
        })
        return resulte;
    }

    switch (action.type) {

        case Actions.GETSKBDOMAINS:
            return {
                ...state,
                skbDomainList: action.payload
            }
        case Actions.UPDATE_POADD_SAMEASSTREET:
            return {
                ...state,
                PoAddressSameAsSt: action.payload
            }
        case Actions.UPDATE_DEFAULT_PHONEPREFIX:
            return {
                ...state,
                SelectPhonePrefix: updateDefaultValueForPhonePrefix()
            }
        case Actions.UPDATE_SUBMITT_ERROR:
            return {
                ...state,
                submitError: 'Submit failed. Please try it again or call for assistance.',
                submittingRegister: false
            }
        case Actions.SUBMITTING_REGISTER:
            return {
                ...state,
                submittingRegister: true
            }
        case Actions.GET_USERSELECTEDPHONEPREFIX:
            return {
                ...state,
                SelectPhonePrefix: getUserSelectedPrefix(action.payload)
            }
        case Actions.UPDATE_TAXLABEL:
            return {
                ...state,
                SelectedTaxLabel: getSelectedTaxlabel(action.payload)
            }
        case Actions.UPDATE_TAXNUMBER:
            return {
                ...state,
                UserTaxNumber: action.payload
            }
        case Actions.GET_SELECTEDCOUNTRYTAXLABELLIST:
            return {
                ...state,
                SelectedTaxlabelList: getSelectedCountryTaxlabel(state.SelectedCountry)
            }
        case Actions.UPDATE_PHONE:
            SkbLogger.logDebugInfo("from UPDATE_PHONE", action.payload)
            return {
                ...state,
                UserPhone: action.payload
            }
        case Actions.UPDATE_USERNAME:
            
            return {
                ...state,
                UserName: action.userName
            }
        case Actions.SEND_VERIFICATIONCODE_REQUEST:
            return {
                ...state,
                VerifyingCode: true
            }
        case Actions.SEND_VERIFICATIONCODE_SUCCESS:
            return {
                ...state,
                VerificationCodeSent: true,
            }
        case Actions.SEND_VERIFICATIONCODE_FAILED:
            return {
                ...state,
                VerificationCodeSent: false,
            }
        case Actions.VERIFY_CODE_SUCCESS:
            return {
                ...state,
                CodeVerifiedSuccess: true,
                VerifyingCode: false
            }
        case Actions.VERIFY_CODE_FAILED:
            return {
                ...state,
                CodeVerifiedSuccess: false,
                VerifyingCode: false
            }
        case Actions.GONEXTSTEP:
            return {
                ...state,
                RegisterStep: state.RegisterStep + 1,
            }
        case Actions.GOTOVERIFYDETAILS:
            return {
                ...state,
                RegisterStep: state.RegisterStep + 2,
            }
        case Actions.GOBACK:
            return {
                ...state,
                RegisterStep: state.RegisterStep - 1,
            }
        case Actions.GOTOVERIFICATIONPAGE:
            return {
                ...state,
                RegisterStep: state.RegisterStep - 2,
            }
        case Actions.UPDATE_USER_TYPE:
            return {
                ...state,
                SelectedUserType: action.payload,
            }
        case Actions.GET_COUNTRIES:
            return {
                ...state,
                countries: updateCountries(action.payload)
            }
        case Actions.CHECK_IS_SKBUSER:
            return {
                ...state,
                IsSKBUser: checkSkbUser(action.payload),
                SelectedUserType: (checkSkbUser(action.payload) ? SkbUserType : 1)
            }
        case Actions.UPDATE_COUNTRY:
            SkbLogger.logDebugInfo("from UPDATE_COUNTRY", action.payload)
            return {
                ...state,
                SelectedCountryName: action.payload,
                SelectedCountry: UpdateToCountryId(action.payload)
            }
        case Actions.UPDATE_COMPANYNAME:
            return {
                ...state,
                UserCompanyName: action.payload
            }
        case Actions.UPDATE_STREETADDRESS:
            SkbLogger.logDebugInfo("from UPDATE_STREETADDRESS", action.payload)
            return {
                ...state,
                UserAddress: action.payload
            }
        case Actions.UPDATE_POSTALADDRESS:
            SkbLogger.logDebugInfo("from UPDATE_POSTALADDRESS", action.payload)
            return {
                ...state,
                UserPostalAddress: action.payload
            }
        case Actions.GET_PHONEPREFIX:
            return {
                ...state,
                allCountryphonePrefix: getAllPhonePrefix(action.payload)
            }
        case Actions.UPDATE_USERPHONEPREFIX:
            return {
                ...state,
                PhonePrefixList: getUsersPhonePrefix(state.SelectedCountry)
            }
        case Actions.GET_TAXLABELLIST:
            return {
                ...state,
                allTaxLabelList: getTaxLabels(action.payload)
            }
        default:
            return state;
    }
}