import React, { useEffect,  useState } from 'react';

import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import { tableIcons } from '../../_share/tableIcons';

import { Link } from '@material-ui/core';

// import { StocktakeSteps } from '../Stocktake';

import * as misc from '../../../../utils/misc';

import { alphabeticalSort } from './StepStockReview';
import Loading from '../../../Loading';

export const useStocktakeTableStyles = makeStyles((theme) => ({
  centredTableColumn: {

        "& .MuiInputBase-input.MuiInput-input":
        {
          textAlign: 'center',
          fontSize: 'inherit'
        }
      },
    
    middle: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20
    },


  table: {

    "& > div > div > div > div": {
      overflowY: "inherit!important"
    },

    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 16px)',
      margin: theme.spacing(0),


    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 220px)',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {

      "& .MuiTableCell-sizeSmall": {
        padding: '6px 8px 6px 8px'    //The "dense" table padding is still too big on a small screen.
      }
    },

    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 8px)',
      marginBottom: theme.spacing(0),
      "& .MuiTableCell-sizeSmall": {
        padding: '6px 2px 6px 2px'    //The "dense" table padding is still too big on a small screen.
      }
    },

    [theme.breakpoints.down(376)]:  //376px wide or less
      misc.isProblemDevice()?{      //and one of the known problem devices
        width: 'calc(90vw - 8px)'   //Add a gap at the side to allow for scrolling.  
      }
      :{},

    padding: '4% 2% 4% 2%',

    "& .MuiCheckbox-colorSecondary.Mui-checked":
      theme.palette.type === 'light'
        ? {
          color: theme.palette.primary.dark
        }
        :
        {
          color: darken(theme.palette.primary.dark, 0.25)
        }
    ,
    "& .MuiTableRow-root.Mui-selected":
      theme.palette.type === 'light'
        ? {
          backgroundColor: lighten(theme.palette.primary.light, 0.3)
        }
        : {

          backgroundColor: theme.palette.primary.dark,
        },

    "& .MuiTableRow-root.Mui-selected.MuiTableRow-hover:hover":
      theme.palette.type === 'light'
        ? {
          backgroundColor: theme.palette.primary.light
        }
        : {

          backgroundColor: theme.palette.primary.main
        }



  },

})

);

/**
 * components: The Stock Preview Step displays the stock we expect the contractor to count or pick prior to his doing the count/picking.  
 * @param {array}  previewInfo: the stock preview data.
 *              e.g.  [
                    {
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                        StockType: "Serialised",
                        Qty: 100
                    },
                    {
                        StockCode: "SKB001",
                        Description: "Cable Type",
                        StockType: "Non-Serialised",
                        Qty: 200,
                    },...]
 * @param {function} loadPreviewData: it's a callback function to load the data for previewInfo.
 * @param {function} goToStep: A function to navigate between the preview step and the related serialised and non-serialised steps in the same process.
 *                              Parameters stepId, selectedStockCode (to be highlighted on arrival at the new step)
 * @param {number} serialisedStepId: The Id of the serialised step, to be used for cross-navigation.
 * @param {number} nonSerialisedStepId: The Id of the non-serialised step, to be used for cross-navigation.
 * @param {text} emptyDataMessage: text to display when there is no data.
 * @param {boolean} isLoading: Is data currently loading?  (used to show loading indicator.)

 * @returns 
 */
export default function StepStockPreview(props) {

  //At the moment all the contractor's stock holdings are put against their default location because we don't know where they actually are.
  //Therefore, for any other locations we need to check the stock in the other location sub-tasks as well to compile the list.
  //In the future, this may need to change when we have more accurate tracking of stock location within the contractor's different locations.  
  //let previewItemsFiltered= getPreviewItemsForAllSubTasksOfCurrentTask(props.allTasks).filter(i=>i.Qty!=0); //only show items in list with a non-zero previous quantity
  //currentSubTask.PreviewInfo.filter(i=>i.Qty!=0);  
  useEffect(() => {
    props.loadPreviewData();
  }, []);


  //let copyOfPreviewItems = previewItemsFiltered.map(d => ({ ...d }))  //material table needs to add extra column in the array object,however the array from store is not extendable, clone the array to avoid the issue
  //  .sort(alphabeticalSort);

  const [data, setData] = useState([]);

  //set up table columns


const isMultiLocation=(props.CurrentTask.SubTasks.length>1);

var tableColumns=[
  { title: 'StockCode', field: 'StockCode', hidden: true, editable: 'never' },
  {
    title: 'Description', field: 'Description', hidden: false, editable: 'never', width: '40%', align: 'left',
    render: rowData => (
      rowData.StockType === "Serialised" ?
        <Link id={`serialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.serialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
        :
        <Link id={`nonserialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.nonSerialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
    )
  },
  { title: 'Stock Type', field: 'StockType', hidden: false, editable: 'never', width: '20%', align: 'center' }
  
];

//PHO-1160: This column was going to be hidden for multilocation stocktakes.  They've asked for it to be put back, but leaving this commented 
//in case they change their mind in the near future.  
// if (!isMultiLocation)
// {
  tableColumns= tableColumns.concat([{ title: 'Items', field: 'Qty', hidden: false, editable: 'never', width: '10%', align: 'center' }]);
// }

  const [columns, setColumns] = useState(tableColumns);

  const classes = useStocktakeTableStyles();

  useEffect(() => {
    if (props.previewInfo) {
      let previewItemsFiltered = props.previewInfo.filter(i => i.Qty != 0);
      let copyOfPreviewItems = previewItemsFiltered.map(d => ({ ...d }))//material table needs to add extra column in the array object,however the array from store is not extendable, clone the array to avoid the issue
      .sort(alphabeticalSort);
      setData(copyOfPreviewItems);
    }
  }, [props.previewInfo]);


  return (

    <div id="preview-table" className={classes.table} >

      <MaterialTable
        icons={tableIcons}
        showTitle={false}
        searchable={false}
        toolbar={false}
        columns={columns}
        data={data}
        padding={'dense'}

        options={{
          toolbar: false,
          showTitle: false,
          search: false,
          padding: 'dense',
          paging: false,

          headerStyle:
          {
            fontWeight: "bolder",
            fontSize: "1.2em",

          }

        }

        }
        localization={{ body: { emptyDataSourceMessage: props.emptyDataMessage } }}
      />
      {/* display loading indicaiton when store state is updating  */}
      {props.isLoading && <Loading />}
    </div>

  );
}

