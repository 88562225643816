import React, { useEffect } from 'react';
import { SkbLogger,SeverityLevel } from '../../../../services';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SubTask from './SubTask';
import { Grid } from '@material-ui/core';
import * as misc from '../../../../utils/misc';

import * as taskActions from '../../../../Redux/actions/tasks';
const taskConfig = require('../taskConfig.json');
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },

    taskheader: {

    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end',
    },

    root: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },

    cardContentRoot: {
        display: "flex",
        alignItems: "center"

    },
    cardContentDetails: {
        flex: '1 0 auto',
    },
    cardContentMedia: {

        width: 150,
        height: 150,
        margin: theme.spacing(1),
        borderRadius: "2px",
        flexGrow: 0,
        [theme.breakpoints.down("xs")]: {
            width: 50,
            height: 50,
        }
    },
    cardContentContent: {
        flexGrow: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),

        }
    },

    cardExpander: {
        display: "flex",
        alignItems: "center",
        // '& button': {
        //     paddingTop: 0
        // }
    },

    headerRoot: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },

    taskStatus: {
        textAlign: "right",
        marginTop: theme.spacing(-3),
        //paddingRight: theme.spacing(1),
        // [theme.breakpoints.down(1279)]: {
        //     marginTop: theme.spacing(-3),
        // },
        // [theme.breakpoints.down(959)]: {
        //     marginTop: theme.spacing(-3),
        // },
        // [theme.breakpoints.down(599)]: {
        //     marginTop: theme.spacing(-3),
        // }
    },

    label: {
        display: 'inline-block',
        width: 60,
    },

    notstarted: {
        color: theme.palette.error.main,
    },
    inprogress: {
        color: theme.palette.warning.main,
    },
    done: {
        color: theme.palette.success.main,
    },

    subtasks: {
        margin: theme.spacing(1),
        paddingRight: theme.spacing(2),
        '& >div.MuiGrid-item': {
            borderTop: `1px solid ${theme.palette.text.primary}`,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            '&:last-child': {
                borderBottom: `1px solid ${theme.palette.text.primary}`,

            },
        },

    }
}));


function TaskCard({ Task, TaskStatus, onStatusChange, Alltasks }) {

    const taskSetting = taskConfig[Task.TaskType];
    // const startTime = moment.tz(Task.StartTime, "dd/MM/yy h:mmA", "Australia/Melbourne").format("dd/MM/YY h:mmA");
    const startTime = misc.datetimeStringInUTCToLocaldatetimeString(Task.StartTime, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)
    const endTime = misc.datetimeStringInUTCToLocaldatetimeString(Task.EndTime, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)

    const classes = useStyles();
    const statusStyle = clsx({
        [classes.notstarted]: TaskStatus === taskActions.Status.NotStarted,
        [classes.inprogress]: TaskStatus === taskActions.Status.InProgress,
        [classes.done]: TaskStatus === taskActions.Status.Done

    });

    SkbLogger.logDebugInfo("statusStyle", statusStyle);

    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    React.useEffect(() => {
       console.log("Current stocktake status:" + Task.StocktakeSatus.Status); 
       //if the status is not changed, do not update redux status and sync storage (which can cause data overwritten) 
       if(Task.StocktakeSatus && Task.StocktakeSatus.Status !==TaskStatus)
              onStatusChange(Alltasks, TaskStatus);
       //SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "updateTaskStatus", "trying to update but commented out");

    }, [TaskStatus]);
    return (
        <Card className={classes.root}>
            <CardHeader className={classes.headerRoot}
                action={
                    <div className={classes.cardExpander}>
                        <Typography variant="body1">ID: {Task.TaskId}</Typography>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            id={`expander_${Task.TaskId}`}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                }
                title={<Typography variant="h6">{taskSetting.TaskTitlePrefix} Task</Typography>}
 
            />
            <div className={classes.cardContentRoot}>
                <CardMedia
                    className={classes.cardContentMedia}
                    image={`/images/${taskSetting.Icon}`}
                    title={`${taskSetting.TaskTitlePrefix} Task`}
                />

                <CardContent className={classes.cardContentContent}>

                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} className={classes.taskStatus}>
                            <Typography variant="body3" className={statusStyle}>
                                {TaskStatus}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography pagraph>
                                <Typography variant="h6">
                                    {taskSetting.SummaryTitle1}
                                </Typography>

                                <Typography variant="body1">
                                    {Task.CompanyName}
                                </Typography>
                            </Typography>
                            <Typography paragraph>
                                <Typography variant="h6">
                                    {taskSetting.SummaryTitle2}
                                </Typography>

                                <Typography variant="body1">
                                    <Typography className={classes.label}> Start:</Typography> {startTime}
                                </Typography>
                                <Typography variant="body1">
                                    <Typography className={classes.label}>End:</Typography> {endTime}
                                </Typography>
                            </Typography>
                        </Grid>


                    </Grid>

                </CardContent>


            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>

                <Grid container className={classes.subtasks}>
                    {Task.SubTasks.map((t, idx) => <Grid item xs={12}> <SubTask key={`${Task.resourceID}_${idx}`} TaskData={t} Task={Task} /> </Grid>)}
                </Grid>
            </Collapse>
        </Card>
    );
}

//Expected props: Task, changeTask, onStatusChange, Alltasks
export default function TaskSummary(props) {
    const classes = useStyles();
    const { Task,  changeTask, onStatusChange, Alltasks } = props;

    var TaskStatus=calTaskStatusBySubTaskStatus(Task);
    SkbLogger.logDebugInfo('tasks', Task);
    useEffect(() => {
        //loadTasks();
    }, []);

    return (
        <Grid container className={classes.root} >
            <Grid item xs={12} data-testid="taskheader">
            </Grid>
            <Grid item xs={12} data-testid="taskcontent" onClick={() => { changeTask(Task.resourceID) }}>
                <TaskCard Task={Task} TaskStatus={TaskStatus} Alltasks={Alltasks} onStatusChange={onStatusChange} />
            </Grid>
            <Grid item xs={12} data-testid="taskfooter"></Grid>
        </Grid>
    );
}

const calTaskStatusBySubTaskStatus = (task) => {
    SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus based on", {overallStatus:task.StocktakeSatus ,subtasks:task.SubTasks});

    if (task.SubTasks.every(s => s.TaskStatus.Status === taskActions.Status.NotStarted)){
        SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus result", taskActions.Status.NotStarted);
        return taskActions.Status.NotStarted;
    }
    if (task.SubTasks.every(s => s.TaskStatus.Status === taskActions.Status.Done)){
        SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus result", taskActions.Status.Done);
        return taskActions.Status.Done;
    }
    SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus result", taskActions.Status.InProgress);
    return taskActions.Status.InProgress;
};

