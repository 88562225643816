import React, { useEffect } from 'react';
import './css/style.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';
import SkbAlertDialog from '../../../../skb_controls/components/SkbAlertDialog';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';
import * as taskActions from '../../../../Redux/actions/tasks';
import { connect } from 'react-redux';
import * as misc from '../../../../utils/misc';
// import SkbButton from '../../../skb_controls/components/SkbButton';
// import {SkbSwitch} from '../components/SkbSwitch';
import { NotificationInvalidBarcode } from './NotificationInvalidBarcode';

import {  BrowserMultiFormatReader, DecodeHintType,NotFoundException,BarcodeFormat } from '@zxing/library';
//import {  BrowserMultiFormatReader, DecodeHintType,NotFoundException,BarcodeFormat } from './zxing';
import * as ScanditSDK from "scandit-sdk";
import Quagga from 'quagga'; 

import {userAuditEvent} from '../../../../services/SkbLogger'
import { SkbLogger } from '../../../../services';
import IconButton from '@material-ui/core/IconButton';
import uuid from 'react-uuid';
import {crypts} from '../../../../utils/crypts';
// import axios from "axios";
import { BlobServiceClient} from "@azure/storage-blob";
// import { TramOutlined } from '@material-ui/icons';


var VIDEO_WIDTH = 800;
var VIDEO_HEIGHT = 600;
var SCANAREA_RATIO_VERTICAL = 1.0/3.0;
var SCANAREA_RATIO_HORIZONTAL = 1.0/5.0;
var SCANAREA_NON_SHADOW = 0.85;
var CROSSHAIRS_RATIO = 0.7;
const X_SECDONDS = 7.0;
const PAUSE_MILLI_SEC_FOR_AIMING = 500;
//const SMALL_FACTOR = 0.7;
//const STRETCH_FACTOR = 2.0;

var videoFrameCounter = 0;
var pictureCroppingCounter =0;
var zxingCallingCounter = 0;
var quaggaCallingCounter = 0;
var scanditCallingCounter = 0;
var zxingDetectedCounter = 0;
var quaggaDetectedCounter = 0;
var scanditDetectedCounter = 0;
var zxingBeepCounter = 0;
var quaggaBeepCounter = 0;
var scanditBeepCounter = 0;
var zxingReturnCounter = 0;
var zxingErrorCounter = 0;
var scanditReturnCounter = 0;
var scanditErrorCounter = 0;
var lastDecodeCallingTime = 0;
var lastCroppingDuration = 100;
var currentCroppingDuration = 100;
var lastVideoEventActualStartingTimestamp = 0;
var noDecodingBeforeThisTimestamp = Date.now();
var currentDevice=null;
var videoFramesToSkip=0;


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    }
    
  });

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other} className="CustomScanBarPopup">
        {onClose ? (
          
          <Button onClick={onClose} 
            variant="contained"
            color="primary"
            fontSize="large"
            className={classes.button}
            startIcon={<CloseIcon />}
          >
          </Button>
        ) : null}

      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles((theme) => ({
    root: {
       padding: theme.spacing(2)
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);



/*
 * component: The serial number scanning component.  
* @param {array}  serialisedItems: the scanned or picked serialised items
                    *       e.g.  [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134"
                    },...]
* @param {function} loadSerialisedItems: a function to load the serialised items.
 * @param {array}  knownSerialNumbers: the serial numbers that we think they may scan, with related stock code/description, 
 * so that we can recognise them.
 *              e.g.  [
                    {
                        SerialNumber: "133434324",
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                    },...]
 * 
* @param {function} addSerialisedItem: a function to save an added item.
* @param {function} deleteSerialisedItem: a function to delete an item from the count or consignment.
 */
export default class ScanBarcode extends React.PureComponent {

    constructor(props) {
        super(props);
        var baseWidth = window.innerWidth * SCANAREA_NON_SHADOW;
        if(baseWidth<300) baseWidth=300;
        if(baseWidth>VIDEO_WIDTH) baseWidth=VIDEO_WIDTH;
        this.state={
          photoPath:null, 
          lastSerialNumbers: [], 
          isLocalHost : (window.location.hostname === "localhost"), 
          isTakePhoto :false,
          isLoading : false,
          is2DScan : false,
          isRemindingRotation: false,
          currentSN: '',
          status : "Good",
          stockItems: [],
          codeReader : null,
          scanditScanner: null,
          isOpenDeleteDlg: false,
          confirmMessage: '',
          canShowDeleteButton: false,
          height: window.innerHeight,
          width: window.innerWidth,
          scanAreaWidth: baseWidth ,
          scanAreaHeight: baseWidth * (window.innerWidth>window.innerHeight?SCANAREA_RATIO_HORIZONTAL:SCANAREA_RATIO_VERTICAL),
          crosshairsWidth: baseWidth * CROSSHAIRS_RATIO,
          crosshairsHeight: baseWidth * (window.innerWidth>window.innerHeight?SCANAREA_RATIO_HORIZONTAL:SCANAREA_RATIO_VERTICAL) * CROSSHAIRS_RATIO,
          barcodeFormats: '1D FORMATS',
          scanStyle: 'FAST',
          stretchStyle: 'ORIGIN'

        }
        this.cameraRef = React.createRef();
        //this.classes=useStyles();

       
        //re-render the component for crosshair location when window is being resized
        const handleResize = () => {
          console.log('handleResize');
          var baseWidth = window.innerWidth * SCANAREA_NON_SHADOW;
          if(baseWidth<300) baseWidth=300;
          if(baseWidth>800) baseWidth=800;
          //re-render
          this.setState({
            isRemindingRotation:false,
            height: window.innerHeight,
            width: window.innerWidth,
            scanAreaWidth: baseWidth,
            scanAreaHeight: baseWidth * (window.innerWidth>window.innerHeight?SCANAREA_RATIO_HORIZONTAL:SCANAREA_RATIO_VERTICAL),
            crosshairsWidth: baseWidth * CROSSHAIRS_RATIO,
            crosshairsHeight: baseWidth * (window.innerWidth>window.innerHeight?SCANAREA_RATIO_HORIZONTAL:SCANAREA_RATIO_VERTICAL) * CROSSHAIRS_RATIO,
          });
           
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

    }
  
    openDeleteDlg() {
      this.setState({...this.state, isOpenDeleteDlg:true, confirmMessage: `Are you sure you wish to remove Serial #:\n\n ${ document.getElementById('last_bc_serialnumber').textContent}?`});
      
    };
    handleCloseDeleteDlg(){
      this.setState({...this.state, isOpenDeleteDlg:false, confirmMessage:''});
    };
  //use minHeight and minWidth to force the video to be full screen
  //use the vendor provided Transform to make it at the center
  cameraVideoStyle(themecolors) {

    return {
      WebkitTransform: 'translateX(-50%) translateY(-50%)',
      MozTransform: 'translateX(-50%) translateY(-50%)',
      MsTransform: 'translateX(-50%) translateY(-50%)',
      OTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      minWidth: '100%',
      minHeight: '100%',
      width: 'auto',
      height: 'auto',
    }
  }
  
  crosshairOverlayStyle(themecolors) {
    return  {   
      position: 'fixed',
      top: `${this.state.height/2-this.state.crosshairsHeight*0.5}px`,  //to match width below, considering spacing
      left: `${this.state.width/2-this.state.crosshairsWidth*0.5}px`,  //to match height below, considering spacing
      background: 'rgba(0, 0, 0, 0)',
      padding: '0px',
      width: `${this.state.crosshairsWidth}px`,  //to match crosshair <img/> size and 80% of cropped-image size
      height:`${this.state.crosshairsHeight}px`,  //to match crosshair <img/> size and 80% of cropped-image size
    }
  }
  
  middleLeftOverlayStyle(themecolors) {
    return  {  
      position: 'fixed',
      top:'35%', //match topOverlayStyle
      left:'0',
      background: `${themecolors.toolbarBackgroundColor.replace(',1)',',0.6)')}`,
      width: '5%',
      padding: '5px',
      height:'30%',
    }
  }
  
  middleRightOverlayStyle(themecolors) {
    return  {  
      position: 'fixed',
      top: '35%', //match topOverlayStyle
      right:'0',
      background: `${themecolors.toolbarBackgroundColor.replace(',1)',',0.6)')}`,
      width: '5%',
      padding: '5px',
      height:'30%',
    }
  }
  
  middleOverlayStyle(themecolors) {
    return  {  
      position: 'fixed',
      top: '35%', //match topOverlayStyle
      left: '5%',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      width: '90%',  //match middleLeft and middleRight
      padding: '5px',
      height:'30%',
      border: `3px solid ${themecolors.toolbarBackgroundColor.replace(',1)',',0.3)')}`,
      //borderRadius:'10px',
      //backgroundClip: 'padding-box',
      //backgroundOrigin: 'content-box',
      //boxShadow: '10px #3a3a3a',
    }
  }


  topButtonOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      top: '0',
      background: `${themecolors.toolbarBackgroundColor.replace(',1)',',0.6)')}`,
      color: `${themecolors.secondaryTextColor}`,
      width: '100%',
      padding: '20px',
      height:'10%',
    }
  }

  leftInTheTopButtonOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      left:'0',
      top:'0',
      width: '20%',
      padding: '20px',
    }
  }

  rightInTheTopButtonOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      right:'0',
      top:'0',
      width: '20%',
      padding: '20px',
      display:'flex',
      alignItems:'right',
    }
  }

  middleInTheTopButtonOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      left:'20%',
      top:'0',
      width: '60%',
      padding: '20px',
    }
  }
  
  topOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      top: '10%',
      background: `${themecolors.toolbarBackgroundColor.replace(',1)',',0.6)')}`,
      color: `${themecolors.secondaryTextColor}`,
      width: '100%',
      padding: '20px',
      //height:`${(window.innerHeight-200)/2}px`,
      height:'25%',
    }
  }
  
  leftInTheTopOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      left:'0',
      top:'10%',
      width: '52%',
      padding: '10px',
    }
  }

  rightInTheTopOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      top:'10%',
      right:'0',
      width: '48%',
      padding: '10px',
    }
  }
  
  bottomOverlayStyle(themecolors) {
    return  {
      position: 'fixed',
      bottom: '0',
      background: `${themecolors.toolbarBackgroundColor.replace(',1)',',0.6)')}`,
      color: `${themecolors.secondaryTextColor}`,
      width: '100%',
      padding: '20px',
      height:'35%',
    }
  }

  thumbnailStyle(themecolors){
    return {
      zIndex:10,
    }
  }

  topSectionHeadingStyle(themecolors) {
    return  {
      color: `${themecolors.primaryColor}`,
      fontWeight:'bold'
    }
  } 
  topSectionLineLabelStyle(themecolors) {
    return  {
      color: `${themecolors.primaryColor}`,
      fontWeight:'normal',
      display: 'inline',
      float:'left',
      paddingRight:'5px',
    }
  }
  
  topSectionLineContentStyle(themecolors) {
    return  {
      color: `${themecolors.secondaryTextColor}`,
      fontWeight:'bold',
      display: 'block',
    }
  }

  topSectionSimpleLineStyle(themecolors) {
    return  {
      color: `${themecolors.primaryColor}`,
      fontWeight:'normal',
    }
  }


    async componentDidMount(){
      //if SerialisedItems is not avaialbe, load it, it would load existing SN as well
      if (!this.props.serialisedItems) {
        this.props.loadSerialisedItems();
      }else{
        var items = this.props.knownSerialNumbers;
        this.setState({stockItems: items})

      }

            //console.log("Your items ".items)
 
    }

    isMobileBrowser() {
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];
  
      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });
    }

uuidv4() {
      return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 6 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(6);
      });
}

saveSamplePhotoToBlob(base64PhotoContent){
  try{
      var sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));

      var blobServiceClient = new BlobServiceClient(
          `https://${process.env.REACT_APP_AZURE_STORAGE_NAME}.blob.core.windows.net${sas_azureStorageKey}`
          ); 
          var byteCharacters = atob(base64PhotoContent);
          var contentType = 'image/png';
          var byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var photoSuffix=this.uuidv4();
          photoSuffix=photoSuffix.replace(/-/ig, '');
          var photoName=Date.now()+'_'+photoSuffix;
          var now_utc = new Date().toISOString();
          var yearStr = 'Undecodable_Photo_Samples_'+now_utc.substring(0,4);
          var dayStr = now_utc.substring(0,10);
          var hourStr = now_utc.substring(11,13);
          var newBlobName = `${yearStr}/${dayStr}/${hourStr}/${photoName}.png`;
          console.log('blob name:',newBlobName);

          var containerClient = blobServiceClient.getContainerClient(`${process.env.REACT_APP_AZURE_CONTAINER_NAME}`);
          var blockBlobClient = containerClient.getBlockBlobClient(newBlobName);
          var byteArray = new Uint8Array(byteNumbers);

          
          blockBlobClient.upload(byteArray, byteArray.length).then((resp)=>{
            console.log('blob upload response ',resp._response.status);
          }).catch((err)=>{
            console.log('blob upload error returned.',err);
          });
          
  
      
  }catch(error) {
      console.log('blob upload error.',error);

  }
}

    componentDidUpdate(prevProps, prevState) {
      //console.log("componentDidUpdate")
      //when ExistingAvailableSNs is avaialbe or upated, update state
      if(prevProps.knownSerialNumbers != this.props.knownSerialNumbers && this.props.knownSerialNumbers){
        var items = this.props.knownSerialNumbers;
        this.setState({stockItems: items})
      }

      if (!prevProps.isOpen && this.props.isOpen
        && this.props.serialisedItems) {

        var serialNumbers = [];

        var result=this.props.serialisedItems.map(d => ({ ...d }));


        if (result != undefined && result.length > 0){
            for (var i = result.length -1; i >= 0; i--) {
              if (serialNumbers.length < 5){
                serialNumbers.push(result[i].SerialNumber);
              }
            } 
        }
        this.setState({ lastSerialNumbers: serialNumbers, isLoading: false })

        var that=this;
    
        /*
        ZXing format:
        //industrial barcode
        2: "CODE_39", 4: "CODE_128", 
        //product barcode
        6: "EAN_8", 7: "EAN_13", 
        //2D code
        5: "DATA_MATRIX", 10: "PDF_417", 11: "QR_CODE", 
        */
        //console.log('BarcodeFormat',BarcodeFormat);

        setTimeout(() => {
            
              //detect mobile device to determine contraints
              /*
              var constraints = {
                audio: false,
                video: {
                    width: VIDEO_WIDTH,
                    height: VIDEO_HEIGHT,
                    facingMode: "environment" , //remove exact: "environment" (when isMobileBrowser() ) as some android phones don't support it
                }
              };
              */
             var constraints = {video:true};

              // List cameras .
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Listing camera with {constraints}.",
                                                {constraints:constraints});
              var video = document.getElementById('video');
              //use getuserMedia before enumerateDevices to make sure the permission has been granted
              navigator.mediaDevices
                  .getUserMedia(constraints)
                  .then(stream => {
                    //currentStream = stream;
                    video.srcObject = stream;
                    return navigator.mediaDevices.enumerateDevices();
                  })
                  .then( (devices)=>{
                        var cameraLabels=[];
                        var cameraDeviceIds=[];
                        var cameraIndex=1;
                        var humanReadableLabel='Rear Camera';
                        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Found videoinput (camera) devices {cameraDevices} (raw list).",
                                                {cameraDevices:devices});
                        var sortedDevices=devices.sort( (a,b)=>{
                          var labelA = a.label; 
                          var labelB = b.label; 
                          if (labelA < labelB) {
                            return -1;
                          }
                          if (labelA > labelB) {
                            return 1;
                          }
                          return 0;
                        });
                        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Found videoinput (camera) devices {cameraDevices} (sorted list).",
                                                {cameraDevices:devices});
                        for (let index = 0; index < sortedDevices.length; index++) {
                          var device = sortedDevices[index];
                          if(device.kind==='videoinput')
                          {
                            //filter out the camera that are definetely not to be used
                            if(device.label && device.label.toLowerCase().includes('front')) continue;

                            //list
                            humanReadableLabel=`Rear Camera ${cameraIndex}`;
                            cameraLabels.push(humanReadableLabel);
                            cameraDeviceIds.push({id:device.deviceId,internal:device.label,label:humanReadableLabel });
                            cameraIndex++;
                          }
                        }

                        if(video && video.srcObject){
                          video.srcObject.getTracks().forEach(function(track) {
                            track.stop();
                          });
                        }

                        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Found videoinput (camera) devices {cameraDevices} (filtered list).",
                                                {cameraDevices:cameraDeviceIds});
                        var selectedCameraInLS=localStorage.getItem('selected_scanning_camera');
                        if(selectedCameraInLS){
                          that.setState({selectedCamera:selectedCameraInLS});
                        }
                        if(cameraDeviceIds.length<=1){
                          const onlyOneLabel=cameraDeviceIds[0].label;
                          //if only one camera met the constraints
                          SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Using the only one {cameraLabel}.",
                                          {cameraLabel:onlyOneLabel});
                          that.setState({cameraLabelList:cameraLabels,cameraIdList:cameraDeviceIds,needSelectCamera:false});
                          setTimeout(()=>{
                            this.startCamera(cameraDeviceIds[0].id);
                          },100);

                        }else{
                          //if more camera, show the dialog unless it's been chosen before
                          if(that.state.selectedCamera){
                            //chosen before
                            SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Using the previous selected {cameraLabel}.",
                                          {cameraLabel:that.state.selectedCamera});
                            that.setState({cameraLabelList:cameraLabels,cameraIdList:cameraDeviceIds,needSelectCamera:true});
                            //that.handleCameraSelectionClickOk();
                          }else{
                            //never chosen
                            var firstCamera=cameraLabels[0];
                            if(firstCamera){
                              that.setState({selectedCamera:firstCamera});
                              localStorage.setItem('selected_scanning_camera',firstCamera);
                              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Using the first/default {cameraLabel}.",
                                          {cameraLabel:firstCamera});
                            }
                            that.setState({cameraLabelList:cameraLabels,cameraIdList:cameraDeviceIds,needSelectCamera:true});
                          }
                          setTimeout(()=>{
                            var cameraId = this.getCameraIdfromSelectedCameraInState();
                            this.startCamera(cameraId);
                          },100);
                          
                        }
                        
                        
                  })
                  .catch(err => {
                    SkbLogger.applicationException("Stocktake","Barcode Scanning","Failed to list Video Camera with {error}",{error:err});
                  });
         
        },50);
      }

    }
 
    switch2DScan = (value) =>{

    }

    insertBarcode(barcodeData){
        var currentDate = new Date().getTime();

        this.props.addSerialisedItem(
          {
          StockCode : barcodeData.stockCode,
          Description : barcodeData.stockDescription,
          SerialNumber : barcodeData.serialNumber,
          Photo: barcodeData.photoOfBarcode,
          ItemStatus : barcodeData.status,
          Latitude: 0,
          Longitude : 0,
          dateStamp : currentDate,
          IsScanned:1

      }
      )
  
        var serialNumbers = [];
        var result=this.props.serialisedItems.map(d => ({ ...d }));
        
          if (result != undefined && result.length > 0){
            
            for (var i = result.length -1; i >= 0; i--) {
              if (serialNumbers.length < 5){
                serialNumbers.push(result[i].SerialNumber);
              }
            } 
          }
          this.setState({ lastSerialNumbers: serialNumbers, isLoading: false })

          if(this.props.onAdd && typeof(this.props.onAdd) === 'function')
          {
            this.props.onAdd();
          }
    }  

    checkStrInListCharacters(str){
      if(!str) return true;
      const charList = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz:-.";
      for (const ch of str) {
        if(!charList.includes(ch)){
          return false;
        }
      }
      return true;
    }

	  /* 
    this is for ignoring the last video frame's result if it's the same as the current one.
    this avois videoEventHandler to repeatly trigger the heavy logic when users holding the camera on one barcode
    this is different to the popup dialog of "Duplicate SN", nor the "Last X seconds duplicate"
    */
    isImmediatelyDuplicateSerialNumber(serialNumber){
      var lastBarcode=localStorage.getItem('immediate_last_barcode');
      if(!lastBarcode) return false;
      return (serialNumber==lastBarcode);
    }

    /*
    this is to ignore the same barcode scanned in the past X seconds
    this is to improve UX to avoid too many duplicate sn dialog popping up
    this is different to the below "isImmediatelyDuplicateSerialNumber"
    The reason of the "isImmediatelyDuplicateSerialNumber" still required separatedly is 
      that they are working for different lifecycle.
    */
   toBeIgnoredDueToDuplicatePastXSeconds(serialNumber){ 
    var lastBarcodeStr=localStorage.getItem('barcode_last_x_sec');
    //console.log('lastBarcodeStr',lastBarcodeStr);
    if(!lastBarcodeStr){
      var currentBarcodeObj={value:serialNumber, timestamp:(Date.now())};
      localStorage.setItem('barcode_last_x_sec',JSON.stringify(currentBarcodeObj));
      return false;
    } else{
      var lastBarcodeObj = JSON.parse(lastBarcodeStr);
      if(lastBarcodeObj.value==serialNumber && (Date.now()-lastBarcodeObj.timestamp)<X_SECDONDS*1000){
        //to reset the barcode_last_x_sec or not? it doesn't seem to matter.
        return true;
      }else{
        var currentBarcodeObj={value:serialNumber, timestamp:(Date.now())};
        localStorage.setItem('barcode_last_x_sec',JSON.stringify(currentBarcodeObj));
        return false;
      }

    }

  }

    /*
     scanStyle=ACCURATE: consider SN as reliable if it's the same as the previous decodable frame (double checking)
     scanStyle=FAST: no double checking
     always check character set to make it a reliable SN
    */
    isSerialNumberReliable(serialNumber){
      /* 'list of characters' validation */
      if(!this.checkStrInListCharacters(serialNumber)) return false;

      /* return for the option switch */
      if(this.state.scanStyle=='FAST') return true;

      /* double checking */
      var lastBarcode=localStorage.getItem('last_barcode_to_be_doublechecked');
      console.log('last_barcode_to_be_doublechecked',lastBarcode);
      if(!lastBarcode || (serialNumber && serialNumber!=lastBarcode)) {
        //console.log('not reliable');
        localStorage.setItem('last_barcode_to_be_doublechecked',serialNumber);
        return false;
      }else if(serialNumber==lastBarcode){
        //console.log('reliable');
        localStorage.setItem('last_barcode_to_be_doublechecked','');
        return true;
      }else if(serialNumber){
        //console.log('unexpected not-reliable');
        localStorage.setItem('last_barcode_to_be_doublechecked',serialNumber);
        return false;
      }else{
        //console.log('unexpected');
        return false;
      }
    }

    checkInvalidSerialNumber(serialNumber){

      var serialisedItems=this.props.serialisedItems;

      var found=serialisedItems.some(i=>i.SerialNumber===serialNumber);

      localStorage.setItem('immediate_last_barcode','');
      
      // Service.getScannedItemBySerialNumber("ScannedItem", "serialNo", serialNumber).then(scannedItem => {
        this.setState({...this.state,canShowDeleteButton:false});        
        if (found){
          this.setState({ isDuplicated: true,  isLoading: false });
        }
        else{
          //[3e] verify that the serial number is known serial number int the inventory database
          var originalImage = document.getElementById('original-img');
        
          var inStockItem = undefined;
          if(this.state.stockItems){
            inStockItem = this.state.stockItems.find(item => item.SerialNumber === serialNumber);
          }
          if (inStockItem != undefined){
            document.getElementById('last_bc_serialnumber').textContent = '' + serialNumber;
            document.getElementById('last_bc_description').textContent = '' + inStockItem.Description;
            document.getElementById('last_bc_status').textContent = '' + this.state.status;
            var barcodeData = {
              stockCode : inStockItem.StockCode,
              stockDescription : inStockItem.Description,
              serialNumber : serialNumber,
              photoOfBarcode : originalImage.src,
              status : this.state.status
            }
            this.insertBarcode(barcodeData);

            this.setState({...this.state,canShowDeleteButton:true});        

          }
          else{
            var barcodeData = {
              stockCode : "UNKNOWN",
              stockDescription : "To Be Determined",
              serialNumber : serialNumber,
              photoOfBarcode : originalImage.src,
              status : this.state.status
            }
            this.setState({ isSkybridgeStock: true, barcodeData : barcodeData, isLoading: false })
            
          }
        }
      // });
    }

    videoEventHandler(){

      videoFrameCounter++;

      //do not proceed when more duplicate/unknown diaglog popping up
      if(this.state.isDuplicated){
        return;
      }
      if(this.state.isSkybridgeStock){
        return;
      }
      if(this.state.isRemindingRotation){
        return;
      }
      if(videoFramesToSkip>0){
        //console.log("videoFramesToSkip>0");
        videoFramesToSkip--;
        return;
      }

      /*this part of skipping logic is to give users some time to aim */
      if(Date.now()<noDecodingBeforeThisTimestamp){
        //console.log('skip for user aiming');
        return;
      }

      /* this part of skipping logic is to prevent devices being overloaded and users feeling laggy, especially on slow devices */
      var averageEventHandlingDuration = 2.0 * (lastCroppingDuration+currentCroppingDuration)/2.0;  //assuming the whole eventHandling time is 2 times of cropping time
      var currentTimestamp=Date.now();
      //console.log('laggy skipping',currentTimestamp,lastVideoEventActualStartingTimestamp,averageEventHandlingDuration);
      if((currentTimestamp-lastVideoEventActualStartingTimestamp)<averageEventHandlingDuration){
        //skip if last frame handling has not finished (as per average handling duration)
        //console.log('skip for improve on laggy issue');
        return;
      }
      
      /* skip further logic if last round of decoding has not finished and not timed out */
      var timeoutTime = Date.now()-100;
      //console.log('comparing timestamp',lastDecodeCallingTime,timeoutTime);
      if(lastDecodeCallingTime>timeoutTime){
        //console.log('skipped for lastDecodeCallingTime');
        return;
      }else if(lastDecodeCallingTime>0){
        //console.log('forcing to enter anyway. last decode calling takes too long.');
      }

      //var decodeWiderImg=false;
      //var decodeSmallerImg=false;
      /*
      if(videoFrameCounter % 2 == 1 && this.state.stretchStyle=='STRETCH'){
        
        if(Math.random()>0.5){
          // decoding smaller portion of the image has better chance to deal with surrounding noise 
          //console.log('decodeSmallerImg');
          decodeSmallerImg=true;
        }else{
          // decoding stretched (in horizontal way) image has better chance to deal with narrow and densitive barcode 
          //console.log('decodeWiderImg');
          decodeWiderImg=true;
        }
        
      }else{
        //console.log('decodeOriginalImg');
      }
      */

      lastVideoEventActualStartingTimestamp = Date.now();
      var startCroppingTime=Date.now();
      const SAMPLING_REMAINER = 37;
      const SAMPLING_BASE = 271;
      var video = document.getElementById('video');

      //generate cropped URL data
      var thecanvas = document.getElementById('cropped-canvas');
      /*
      if(decodeWiderImg){
        thecanvas = document.getElementById('cropped-canvas-wider');
      }
      if(decodeSmallerImg){
        thecanvas = document.getElementById('cropped-canvas-smaller');
      }
      */
      if(!thecanvas) return;
      var context = thecanvas.getContext('2d');

      var videoHeight=video.srcObject.getVideoTracks()[0].getSettings().height;
      if(!videoHeight || videoHeight<0) videoHeight=VIDEO_WIDTH;
      var videoWidth=video.srcObject.getVideoTracks()[0].getSettings().width;
      if(!videoWidth || videoWidth<0) videoWidth=VIDEO_HEIGHT;
      //console.log('video size',videoWidth,videoHeight);
      var aimWidth=this.state.scanAreaWidth;  //to match cropped-image size
      var aimHeight=this.state.scanAreaHeight; //to match cropped-image size
      if(aimWidth>videoWidth) aimWidth=videoWidth;
      if(aimHeight>videoHeight) aimHeight=videoHeight;

      var srcX = videoWidth/2-aimWidth/2;
      var srcY = videoHeight/2-aimHeight/2;
      var srcW = aimWidth;
      var srcH = aimHeight;
      /*
      if(decodeSmallerImg){
        srcX = videoWidth/2 - aimWidth/2 + aimWidth*(1-SMALL_FACTOR)/2;
        srcY = videoHeight/2 - aimHeight/2 + aimHeight*(1-SMALL_FACTOR)/2;
        srcW = aimWidth*SMALL_FACTOR;
        srcH = aimHeight*SMALL_FACTOR;
      }
      */
      var destX = 0;
      var destY = 0;
      var destW = aimWidth;
      /*
      if(decodeWiderImg){
        destW = aimWidth*STRETCH_FACTOR;
      }
      */
      var destH = aimHeight;
      if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","video and cropping size info",
                        {videoWidth:videoWidth, videoHeight:videoHeight, aimWidth:aimWidth,aimHeight:aimHeight,srcX:srcX,srcY:srcY});          
      }
      if(videoFrameCounter % 1000 == 30){
        if(this.isMobileBrowser() //phone or tablet
            //&& window.innerHeight>window.innerWidth   //vertical
              && ((quaggaBeepCounter+zxingBeepCounter)/pictureCroppingCounter)<0.005) //low rate
              {
                this.setState({isRemindingRotation:true});
        }
      }
      //console.log('srcX, srcY,srcW,srcH,destX,destY,destW,destH',srcX, srcY,srcW,srcH,destX,destY,destW,destH);
      context.drawImage(video, srcX, srcY,srcW,srcH,destX,destY,destW,destH);
      //context.drawImage(video, 0, 0);
      var dataURL = thecanvas.toDataURL();
      
      //console.log('dataURL.length',dataURL.length);
      //create img
      //var img = document.createElement('img');
      
      /*
      var img = document.getElementById('cropped-img-to-decode');
      if(decodeWiderImg){
        img = document.getElementById('cropped-img-to-decode-wider');
      }
      img.setAttribute('src', dataURL);
      */
      
      var currentDataURL = `${dataURL}`;
      //console.log('set img.src',img);
      pictureCroppingCounter++;
      if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","updated cropped img.src",{videoFrameCounter:videoFrameCounter,pictureCroppingCounter:pictureCroppingCounter});          
      }
      var endCroppingTime=Date.now();
      lastCroppingDuration=currentCroppingDuration;
      currentCroppingDuration=endCroppingTime-startCroppingTime;
      lastDecodeCallingTime=Date.now();
      //console.log('calling barcode lib in 100 ms');
      setTimeout( ()=>{ 

        if (this.state.barcodeLib && this.state.barcodeLib=='Quagga'){
          quaggaCallingCounter++;
          /* **** using quagga ***** */
          var readerlist = ["code_128_reader","code_39_reader"];
          if(!this.state.barcodeFormats || this.state.barcodeFormats=='INDUSTRIAL FORMATS'){
            readerlist = ["code_128_reader","code_39_reader"];
          }else if(this.state.barcodeFormats=='1D FORMATS'){
            readerlist = ["code_128_reader","ean_reader","code_39_reader"];
          }else if(this.state.barcodeFormats=='ALL FORMATS'){
            readerlist = ["code_128_reader","ean_reader","code_39_reader"];
          }else{
            readerlist = ["code_128_reader","code_39_reader"];
          }
          //console.log('calling Quagga with data length:',dataURL.length);
          if(quaggaCallingCounter % 100 == 1){
            SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample data length for calling Quagga",{length:dataURL.length}); 
          }
          lastDecodeCallingTime=Date.now();
          Quagga.decodeSingle({
                decoder: {
                    readers: readerlist // List of active readers
                },
                locate: true, // try to locate the barcode in the image
                src: currentDataURL // or 'data:image/jpg;base64,' + data
            }, (result)=>{  //result.codeResult.code
                  lastDecodeCallingTime=0;
                  if(!result){
                    return;
                  }
                  quaggaDetectedCounter++;

                  //copy the cropped img out for debugging
                  /*
                  var thumbnailImgDecoded = document.getElementById('thumbnail-img-decoded');
                  if(!thumbnailImgDecoded){
                    return;
                  } 
                  thumbnailImgDecoded.setAttribute('src', dataURL);
                  */

                  if(result.codeResult && result.codeResult.code){
                    //var resultText=result.text;
                    var resultText=result.codeResult.code;
                    //SkbLogger.logDebugInfo("decodeFromVideoDevice result",result.codeResult);
                    SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result",{result:result.codeResult});
                        
                    if(result.codeResult.startInfo){
                      var errorThreshold = 0.15;
                      if(!this.state.scanStyle || this.state.scanStyle=='ACCURATE') errorThreshold= 0.15;
                      else errorThreshold= 0.2;
                      if(result.codeResult.startInfo.error>errorThreshold){  
                        //SkbLogger.logDebugInfo("ignored code with error rate too high",result.codeResult.startInfo);
                        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","ignored code with error rate too high",{info:result.codeResult.startInfo});
                        return;
                      }
                    }

                    if(!this.isSerialNumberReliable(resultText)){
                      SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result only scanned once or failed character list checking. not reliable.",{barcode:resultText});
                      return;
                    }
                    if(this.toBeIgnoredDueToDuplicatePastXSeconds(resultText)){
                      SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result ignored due to last x seconds duplicates",{barcode:resultText});
                      return;
                    }
                    if(!this.isImmediatelyDuplicateSerialNumber(resultText)){
                      localStorage.setItem('immediate_last_barcode',resultText);
                      //non duplicate result
                      SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Detected non-duplicate {barcode}",{barcode:result});
                      this.setState({currentSN:resultText});
                      try {
                        //this.beep();
                        this.beepTone(200,1000);
                        quaggaBeepCounter++;
                      } catch (error) { }
                      
                      userAuditEvent("Scanning Module", this.state.email,"Barcode Scanning Page", "Scan Stock",  "Success",  
                          "{userObject} has successfully scanned {serialisedStockObject}",  {
                            userObject: misc.getUserObject(),
                            serialisedStockObject: {"Serial Number": resultText}
                        });
                        //save photo
                        var originalCanvas = document.getElementById('original-canvas');
                        if(!originalCanvas) return;
                        var ctx = originalCanvas.getContext("2d");
                        ctx.drawImage(video, 0,0);
                        ctx.font = "16px Arial";
                        var date = new Date();
                        ctx.shadowOffsetX = 2;
                        ctx.shadowOffsetY = 2;
                        ctx.shadowColor = "rgba(255,255,255,1)";
                        ctx.shadowBlur = 1;
                        ctx.fillText(date.toString(), 10, 20);
                        document.getElementById('original-img').src = originalCanvas.toDataURL("image/jpeg", 0.8);
                        //console.log('original-img src',document.getElementById('original-img').src);

                        noDecodingBeforeThisTimestamp = Date.now() + PAUSE_MILLI_SEC_FOR_AIMING;

                        this.checkInvalidSerialNumber(resultText)
                    }else{
                      SkbLogger.logDebugInfo('duplicate');
                    }
                    
                  }else{
                    //console.log('no barcode detected (only boxes)',result);
                  }

                });
                
                /* **** end quagga ***** */
          }
        else if(this.state.barcodeLib && this.state.barcodeLib=='ZXing'){
           /* **** using ZXing ***** */ 
           
          if(!this.state.codeReader) {
            return;
          }
          zxingCallingCounter++;
          if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
            SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample data length for calling ZXing",{length:currentDataURL.length,zxingCallingCounter:zxingCallingCounter});          
          }
          lastDecodeCallingTime=Date.now();

          //this.state.codeReader.decodeFromImage(img).then((result) => {
          this.state.codeReader.decodeFromImageUrl(currentDataURL).then((result) => {
            zxingReturnCounter++;
            lastDecodeCallingTime=0;
            if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample:finished using (then) cropped image",{videoFrameCounter:videoFrameCounter,zxingReturnCounter:zxingReturnCounter});          
            }
            if(result){
              zxingDetectedCounter++;
              //copy the cropped img out for debugging
              /*
              var thumbnailImgDecoded = document.getElementById('thumbnail-img-decoded');
              if(!thumbnailImgDecoded){
                    return;
              } 
              thumbnailImgDecoded.setAttribute('src', dataURL);
              */

              var resultText=result.text;
              //SkbLogger.logDebugInfo("decodeFromVideoDevice result",resultText);
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result",{barcode:resultText});
                
              if(!this.isSerialNumberReliable(resultText)){
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result only scanned once or failed character list checking. not reliable.",{barcode:resultText});
                return;
              }
              if(this.toBeIgnoredDueToDuplicatePastXSeconds(resultText)){
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result ignored due to last x seconds duplicates",{barcode:resultText});
                return;
              }
              if(!this.isImmediatelyDuplicateSerialNumber(resultText)){
                localStorage.setItem('immediate_last_barcode',resultText);
                //non duplicate result
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Detected non-duplicate {barcode}",{barcode:result});
                this.setState({currentSN:resultText});
                try {
                  //this.beep();
                  zxingBeepCounter++;
                  this.beepTone(200,1000);
                } catch (error) {
                  
                }
                
                userAuditEvent("Scanning Module", this.state.email,"Barcode Scanning Page", "Scan Stock",  "Success",  
                    "{userObject} has successfully scanned {serialisedStockObject}",  {
                      userObject: misc.getUserObject(),
                      serialisedStockObject: {"Serial Number": resultText}
                  });
                  //save photo
                  var originalCanvas = document.getElementById('original-canvas');
                  if(!originalCanvas) return;
                  var ctx = originalCanvas.getContext("2d");
                  ctx.drawImage(video, 0,0);
                  ctx.font = "16px Arial";
                  var date = new Date();
                  ctx.shadowOffsetX = 2;
                  ctx.shadowOffsetY = 2;
                  ctx.shadowColor = "rgba(255,255,255,1)";
                  ctx.shadowBlur = 1;
                  ctx.fillText(date.toString(), 10, 20);
                  document.getElementById('original-img').src = originalCanvas.toDataURL("image/jpeg", 0.8);
                  //console.log('original-img src',document.getElementById('original-img').src);

                  noDecodingBeforeThisTimestamp = Date.now() + PAUSE_MILLI_SEC_FOR_AIMING;
                  
                  this.checkInvalidSerialNumber(resultText)
              }else{
                //SkbLogger.logDebugInfo('duplicate');
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Immediately Duplicate Serial Number",{barcode:resultText});
                
              }
              
            }

          }).catch(err=>{
            zxingErrorCounter++;
            if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample:finished using (catch) cropped image",{videoFrameCounter:videoFrameCounter, zxingErrorCounter:zxingErrorCounter});          
              var photoContent = currentDataURL.replace('data:image/png;base64,','');
              this.saveSamplePhotoToBlob(photoContent);
            }

            if (err instanceof NotFoundException) {
              //continue
              //console.log('No barcode detected');
            }else{
              //console.log('decoding error',err);
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice error",err);
            }
            lastDecodeCallingTime=0;
            
          });
          
          /* **** end ZXing ***** */
        }
        else{
          /******* using Scandit ****** */
          if(!this.state.scanditScanner) { 
            console.log('NO Scandit Scanner');
            //try to reinit

            //scandit scanner
            const scanSettings = new ScanditSDK.ScanSettings({
              enabledSymbologies: [
                ScanditSDK.Barcode.Symbology.EAN8,
                ScanditSDK.Barcode.Symbology.EAN13,
                //ScanditSDK.Barcode.Symbology.UPCA,
                //ScanditSDK.Barcode.Symbology.UPCE,
                ScanditSDK.Barcode.Symbology.CODE128,
                ScanditSDK.Barcode.Symbology.CODE39,
                ScanditSDK.Barcode.Symbology.CODE93,
                //ScanditSDK.Barcode.Symbology.INTERLEAVED_2_OF_5,
              ],
              codeDuplicateFilter: 1000, // Minimum delay between duplicate results
            });
            const imageSettings = {format:2,height:Math.floor(this.state.scanAreaHeight),width:Math.floor(this.state.scanAreaWidth)}; //format 1:RGB_8U, 2: RGBA_8U, 0: GRAY_8U
            
            try {
              const currentScanditScanner = new ScanditSDK.Scanner({imageSettings:imageSettings,scanSettings:scanSettings});
              this.setState({...this.state,  scanditScanner : currentScanditScanner});
              SkbLogger.applicationTraceSub('Barcode Scan',1,'Scandit','ScanditSDK.Scanner','{deviceObject} has initiated Scandit Scanner.',{deviceObject:currentDevice});
            } catch (error) {
              SkbLogger.applicationTraceSub('Barcode Scan',1,'Scandit','ScanditSDK.Scanner','{deviceObject} failed to initiate Scandit Scanner with {error}.',{deviceObject:currentDevice, error:error});
            }

            return;
          }
          if(!this.state.scanditScanner.isReady()) { 
            //console.log('Scandit Scanner is NOT ready',this.state.scanditScanner);
            return;
          }
          if(this.state.scanditScanner.isBusyProcessing()) { 
            //console.log('Scandit Scanner is Busy');
            if(videoFramesToSkip<30) videoFramesToSkip++;  //<30 is to avoid skipping too many
            return;
          }
          scanditCallingCounter++;
          if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
            SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample data length for calling Scandit",{length:currentDataURL.length,scanditCallingCounter:scanditCallingCounter});          
          }
          lastDecodeCallingTime=Date.now();

          var croppedCanvasElement = document.getElementById('cropped-canvas');
          if(!croppedCanvasElement) {
            console.log('no canvas');
            return;
          }
          const theCanvasCtx = croppedCanvasElement.getContext('2d');
          if(!theCanvasCtx) {
            console.log('no canvas context');
            return;
          }
          let theCroppedImageData = theCanvasCtx.getImageData(0, 0, Math.floor(this.state.scanAreaWidth), Math.floor(this.state.scanAreaHeight));
          //console.log('theCroppedImageData',theCroppedImageData);
          //console.log('image setting: ',this.state.scanditScanner.getImageSettings());
          this.state.scanditScanner.processImage(theCroppedImageData.data, false).then((result) => {
            //console.log('result',result);
            scanditReturnCounter++;
            lastDecodeCallingTime=0;
            if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample:finished using (then) cropped image",{videoFrameCounter:videoFrameCounter,scanditReturnCounter:scanditReturnCounter});          
            }
            if(result && result.barcodes && result.barcodes.length){
              scanditDetectedCounter++;
              //copy the cropped img out for debugging
              /*
              var thumbnailImgDecoded = document.getElementById('thumbnail-img-decoded');
              if(!thumbnailImgDecoded){
                    return;
              } 
              thumbnailImgDecoded.setAttribute('src', dataURL);
              */

              var firstBarcode=result.barcodes[0];
              SkbLogger.logDebugInfo("scandit decodeFromVideoDevice firstBarcode",firstBarcode);
              var resultText=firstBarcode.data;
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result",{barcode:resultText});
                
              if(!this.isSerialNumberReliable(resultText)){
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result only scanned once or failed character list checking. not reliable.",{barcode:resultText});
                return;
              }
              if(this.toBeIgnoredDueToDuplicatePastXSeconds(resultText)){
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice result ignored due to last x seconds duplicates",{barcode:resultText});
                return;
              }
              if(!this.isImmediatelyDuplicateSerialNumber(resultText)){
                localStorage.setItem('immediate_last_barcode',resultText);
                //non duplicate result
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Detected non-duplicate {barcode}",{barcode:result});
                this.setState({currentSN:resultText});
                try {
                  //this.beep();
                  scanditBeepCounter++;
                  this.beepTone(200,1000);
                } catch (error) {
                  
                }
                
                userAuditEvent("Scanning Module", this.state.email,"Barcode Scanning Page", "Scan Stock",  "Success",  
                    "{userObject} has successfully scanned {serialisedStockObject}",  {
                      userObject: misc.getUserObject(),
                      serialisedStockObject: {"Serial Number": resultText}
                  });
                  //save photo
                  var originalCanvas = document.getElementById('original-canvas');
                  if(!originalCanvas) return;
                  var ctx = originalCanvas.getContext("2d");
                  ctx.drawImage(video, 0,0);
                  ctx.font = "16px Arial";
                  var date = new Date();
                  ctx.shadowOffsetX = 2;
                  ctx.shadowOffsetY = 2;
                  ctx.shadowColor = "rgba(255,255,255,1)";
                  ctx.shadowBlur = 1;
                  ctx.fillText(date.toString(), 10, 20);
                  document.getElementById('original-img').src = originalCanvas.toDataURL("image/jpeg", 0.8);
                  //console.log('original-img src',document.getElementById('original-img').src);

                  noDecodingBeforeThisTimestamp = Date.now() + PAUSE_MILLI_SEC_FOR_AIMING;
                  
                  this.checkInvalidSerialNumber(resultText)
              }else{
                //SkbLogger.logDebugInfo('duplicate');
                SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Immediately Duplicate Serial Number",{barcode:resultText});
                
              }
              
            }

          }).catch(err=>{
            scanditErrorCounter++;
            if(videoFrameCounter % SAMPLING_BASE == SAMPLING_REMAINER){
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Sample:finished using (catch) cropped image",{videoFrameCounter:videoFrameCounter, scanditErrorCounter:scanditErrorCounter});          
              var photoContent = currentDataURL.replace('data:image/png;base64,','');
              this.saveSamplePhotoToBlob(photoContent);
            }
            
            SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","decodeFromVideoDevice error",err);
            lastDecodeCallingTime=0;
            //ImageSettingsDataMismatch
            /*
            console.log('theCroppedImageData',theCroppedImageData);
            console.log('image setting: ',this.state.scanditScanner.getImageSettings());
            */
           //console.log('err string',err.toString());
            if (err && err.toString().includes('ImageSettingsDataMismatch')){
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","ScanditSDK.ImageSettingsDataMismatch error detail",
                                            {imageSettings:this.state.scanditScanner.getImageSettings(),
                                              imageDataHeight:Math.floor(theCroppedImageData.height),
                                              imageDataWidth:Math.floor(theCroppedImageData.width)});
              //in case the width/height changed
              const newImageSettings = {format:2,
                                        height:Math.floor(this.state.scanAreaHeight),
                                        width:Math.floor(this.state.scanAreaWidth)}; //format 1:RGB_8U, 2: RGBA_8U, 0: GRAY_8U
              this.state.scanditScanner.applyImageSettings(newImageSettings);
            }
            
          });

          /*********end Scandit **** */
        }

        //copy the cropped img out for debugging
        /*
        var thumbnailImg = document.getElementById('thumbnail-img');
        if(!thumbnailImg){
            return;
        } 
        thumbnailImg.setAttribute('src', dataURL);
        */
       
      }, 20 );
    }

//All arguments are optional:

//duration of the tone in milliseconds. Default is 500
//frequency of the tone in hertz. default is 440
//volume of the tone. Default is 1, off is 0.
//type of tone. Possible values are sine, square, sawtooth, triangle, and custom. Default is sine.
//callback to use on end of tone
 beepTone(duration, frequency, volume, type, callback) {
    var audioCtx=this.state.audioCtx;
    if(!audioCtx) return;
    var oscillator = audioCtx.createOscillator();
    var gainNode = audioCtx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);

    if (volume){gainNode.gain.value = volume;}
    if (frequency){oscillator.frequency.value = frequency;}
    if (type){oscillator.type = type;}
    if (callback){oscillator.onended = callback;}

    //oscillator.start(audioCtx.currentTime);
    //oscillator.stop(audioCtx.currentTime + ((duration || 500) / 1000));
    oscillator.start();
    setTimeout( ()=> {
      oscillator.stop();
    },(duration || 500) )
};


     startCamera(chosenDeviceId){
       console.log('scan area',this.state.scanAreaWidth);
        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Starting Camera {cameraDeviceId}.",
                                          {cameraDeviceId:chosenDeviceId});


        if(this.barcodeLib && this.barcodeLib==='ZXing'){
          /*** setting ZXing */
          const hints = new Map();
          hints.set(DecodeHintType.TRY_HARDER, true);
          hints.set(DecodeHintType.CHARACTER_SET,'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz:-.');
          if(!this.state.barcodeFormats || this.state.barcodeFormats=='INDUSTRIAL FORMATS'){
            hints.set(DecodeHintType.POSSIBLE_FORMATS, 
              [BarcodeFormat.CODE_128, BarcodeFormat.CODE_39]);
            hints.set(DecodeHintType.PURE_BARCODE, false);
          }else if(this.state.barcodeFormats=='1D FORMATS'){
            hints.set(DecodeHintType.POSSIBLE_FORMATS, 
              [BarcodeFormat.CODE_128, BarcodeFormat.CODE_39, BarcodeFormat.EAN_13, BarcodeFormat.EAN_8]);
            hints.set(DecodeHintType.PURE_BARCODE, false);
          }else if(this.state.barcodeFormats=='ALL FORMATS'){
            //hints.set(DecodeHintType.POSSIBLE_FORMATS, 
              //[BarcodeFormat.CODE_128, BarcodeFormat.CODE_39, BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE]);
          }else{
            hints.set(DecodeHintType.POSSIBLE_FORMATS, 
              [BarcodeFormat.CODE_128, BarcodeFormat.CODE_39]);
            hints.set(DecodeHintType.PURE_BARCODE, false);
          }
          
          const codeReader = new BrowserMultiFormatReader(hints); //use hints for slightly better performance

          //this.setState({ codeReader : codeReader});
          this.setState({...this.state,  codeReader : codeReader});
          //console.log('after this.setState',this.state);

        }else if(!this.barcodeLib || this.barcodeLib==='Scandit'){
          /**** setting Scandit */
            if(!currentDevice){
              //get currentDevice info for scandit required log
              var tempDeviceID;
              const deviceIDInLS=localStorage.getItem('temp_device_id');
              if(deviceIDInLS){
                  tempDeviceID = crypts.decrypt(deviceIDInLS);
              }else{
                  //first part of device id is timestamp
                  //to allow us to know when users clear the localStorage
                  const newDeviceID = ''+((new Date()).getTime())+'-'+(uuid().split('-'))[0];
                  const encryptedDeviceID = crypts.encrypt(newDeviceID);
                  localStorage.setItem('temp_device_id',encryptedDeviceID);
                  tempDeviceID = crypts.decrypt(encryptedDeviceID);
              }
              const authUserInLS=localStorage.getItem('auth_user');
              const authUser=JSON.parse(crypts.decrypt(authUserInLS));
              currentDevice = {
                      emailAddress:authUser.email,
                      screenResolution:'' + window.screen.width + 'x' + window.screen.height,
                      platform:window.navigator.platform,
                      tempDeviceID:tempDeviceID
              };
            }

            //init Scandit
            const scanditKey=crypts.decrypt(process.env.REACT_APP_SCANDIT_LICENCE_KEY_ENCRYPTED);
            ScanditSDK.configure(scanditKey, {
              engineLocation: "scandit/", // scanit engine files copied to public folder for offline concerns   //"https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",  //"/node_modules/scandit-sdk/build/",
            }).then(() => {
              SkbLogger.applicationTraceSub('Barcode Scan',1,'Scandit','ScanditSDK.configure','{deviceObject} has configured Scandit.',{deviceObject:currentDevice});
              //console.log('ScanditSDK init');

              //scandit scanner
              const scanSettings = new ScanditSDK.ScanSettings({
                enabledSymbologies: [
                  ScanditSDK.Barcode.Symbology.EAN8,
                  ScanditSDK.Barcode.Symbology.EAN13,
                  ScanditSDK.Barcode.Symbology.UPCA,
                  ScanditSDK.Barcode.Symbology.UPCE,
                  ScanditSDK.Barcode.Symbology.CODE128,
                  ScanditSDK.Barcode.Symbology.CODE39,
                  ScanditSDK.Barcode.Symbology.CODE93,
                  ScanditSDK.Barcode.Symbology.INTERLEAVED_2_OF_5,
                ],
                codeDuplicateFilter: 1000, // Minimum delay between duplicate results
              });
              const imageSettings = {format:2,height:Math.floor(this.state.scanAreaHeight),width:Math.floor(this.state.scanAreaWidth)}; //format 1:RGB_8U, 2: RGBA_8U, 0: GRAY_8U
              
              try {
                const currentScanditScanner = new ScanditSDK.Scanner({imageSettings:imageSettings,scanSettings:scanSettings});
                this.setState({...this.state,  scanditScanner : currentScanditScanner});
                SkbLogger.applicationTraceSub('Barcode Scan',1,'Scandit','ScanditSDK.Scanner','{deviceObject} has initiated Scandit Scanner.',{deviceObject:currentDevice});
              } catch (error) {
                SkbLogger.applicationTraceSub('Barcode Scan',1,'Scandit','ScanditSDK.Scanner','{deviceObject} failed to initiate Scandit Scanner with {error}.',{deviceObject:currentDevice, error:error});
              }


            });

            
            
        }

        //some browsers have a limit on the number of AudioContext that can be created
        var audioCtx = new (window.AudioContext || window.webkitAudioContext || window.audioContext);
        this.setState({ audioCtx : audioCtx});

        var video = document.getElementById('video');
        video.addEventListener("timeupdate", this.videoEventHandler.bind(this), true);

        localStorage.setItem('immediate_last_barcode','');
        var constraints = {
          audio: false,
          video: {
              width: VIDEO_WIDTH,
              height: VIDEO_HEIGHT,
              deviceId:chosenDeviceId,
              facingMode: "environment" , //remove exact: "environment" (while isMobileBrowser() ) as some android phones don't support it
          }
        };
        
        SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Opening camera with {constraints}.",
                                                {constraints:constraints});
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          //console.log('media stream',stream);
          if (stream && video.srcObject !== stream) {
            video.srcObject = stream;
            this.setState({ isLoading: false  });
            setTimeout( () =>{
              SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Video Camera is opened as {streamSize}",
                                          {streamSize:{height:stream.getVideoTracks()[0].getSettings().height,
                                                  width:stream.getVideoTracks()[0].getSettings().width}
                                          });
                video.play();  //play after stream ready
            },200
            );
          }
        }).catch(err=>{
          this.setState({ isLoading: false  });
          SkbLogger.applicationException("Stocktake","Barcode Scanning","Failed to open Video Camera with {error}",{error:err});
        });

        noDecodingBeforeThisTimestamp = Date.now() + PAUSE_MILLI_SEC_FOR_AIMING*2;

    }

    scanBtnClick = async () => {
      if (this.state.isLoading == false){
        this.setState({ isTakePhoto: true, isLoading: true  });
      }
    };

    handleCloseDuplicatedDialog = async () => {
      this.setState({ isDuplicated: false  });
    };

    handleRotationDialog = async () => {
      this.setState({ isRemindingRotation: false  });
    };

    handleCloseSkybridgeDialog = async () => {
      this.setState({ isSkybridgeStock: false  });
    };

    handleConfirmSkybridgeStock = async () => {
      this.insertBarcode(this.state.barcodeData);
      document.getElementById('last_bc_serialnumber').textContent = '' + this.state.barcodeData.serialNumber;
      document.getElementById('last_bc_description').textContent = '' + this.state.barcodeData.stockDescription;
      document.getElementById('last_bc_status').textContent = '' + this.state.barcodeData.status;
      this.setState({ isSkybridgeStock: false, canShowDeleteButton:true  });
 
    };

    handleChangeStatus = (value) => {
      this.setState({ status: value  });
    };

    handleChangeLib = (value) => {
      this.setState({ barcodeLib: value  });
    };

    handleDeleteLast = ()=>{
      if(this.state.barcodeData){
        console.log('to delete last sn',this.state.barcodeData.serialNumber);
        var serialisedItems=this.props.serialisedItems;

        var scannedItem={...serialisedItems.find(i=>i.SerialNumber===this.state.barcodeData.serialNumber)};
    
        if (scannedItem!==undefined)
        {
            console.log('deleted last sn',scannedItem);
            this.props.deleteSerialisedItem(scannedItem);
            userAuditEvent("Scanning Module", this.state.email,"Barcode Scanning Page", "Scan Stock",  "Success",  
                          "{userObject} has successfully deleted {serialisedStockObject}",  {
                            userObject: misc.getUserObject(),
                            serialisedStockObject: scannedItem
                        });
            document.getElementById('last_bc_serialnumber').innerHTML = `&nbsp;`;
            document.getElementById('last_bc_description').innerHTML = `&nbsp;`;
            document.getElementById('last_bc_status').innerHTML = ' ';
            this.setState({barcodeData:[], canShowDeleteButton:false});
        }
      }
    };

    handleScanStyleButton = () =>{
      if(!this.state.scanStyle || this.state.scanStyle=='ACCURATE') this.setState({ scanStyle: 'FAST' });
      else if(this.state.scanStyle=='FAST') this.setState({ scanStyle: 'ACCURATE' });
      else this.setState({ scanStyle: 'ACCURATE' });
    }

    
    handleStretchStyleButton = () =>{
      if(!this.state.stretchStyle || this.state.stretchStyle=='STRETCH') this.setState({ stretchStyle: 'ORIGIN' });
      else if(this.state.stretchStyle=='ORIGIN') this.setState({ stretchStyle: 'STRETCH' });
      else this.setState({ stretchStyle: 'STRETCH' });
    }
    

    handleCodeFormatButton = () =>{
      //console.log('handleCodeFormatButton',this.state.barcodeFormats);
      if(!this.state.barcodeFormats || this.state.barcodeFormats=='INDUSTRIAL FORMATS') this.setState({ barcodeFormats: '1D FORMATS' });
      else if(this.state.barcodeFormats=='1D FORMATS') this.setState({ barcodeFormats: 'ALL FORMATS' });
      else if(this.state.barcodeFormats=='ALL FORMATS') this.setState({ barcodeFormats: 'INDUSTRIAL FORMATS' });
      else this.setState({ barcodeFormats: 'INDUSTRIAL FORMATS' });
      //console.log('handleCodeFormatButton',this.state.barcodeFormats);
      
      //restart camera
      var video = document.getElementById('video');
      if(video && video.srcObject){
        video.srcObject.getTracks().forEach(function(track) {
          track.stop();
        });
      }

      this.setState({ isLoading: true  });

      setTimeout(()=>{
        var cameraId = this.getCameraIdfromSelectedCameraInState();
        this.startCamera(cameraId);
      },100);
    }

    handleDialogClose = () =>{
      console.log('handleDialogClose');
      var video = document.getElementById('video');
      video.removeEventListener("timeupdate", this.videoEventHandler, true);
      //this.state.codeReader.reset();

      if(!this.barcodeLib || this.barcodeLib==='Scandit'){
        if(this.state.scanditScanner){
          this.state.scanditScanner.destroy();
          this.state.scanditScanner=null;
        }
      }

      if(video && video.srcObject){
        video.srcObject.getTracks().forEach(function(track) {
          track.stop();
        });
      }
      this.setState({ isLoading: false  });
      this.props.closeDialog();
      SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Last scanning session statistics:",
                                          {videoFrameCounter:videoFrameCounter, pictureCroppingCounter:pictureCroppingCounter,
                                           zxingCallingCounter:zxingCallingCounter, zxingReturnCounter:zxingReturnCounter, zxingDetectedCounter:zxingDetectedCounter, zxingBeepCounter:zxingBeepCounter,zxingErrorCounter:zxingErrorCounter,
                                           quaggaCallingCounter:quaggaCallingCounter, quaggaDetectedCounter:quaggaDetectedCounter, quaggaBeepCounter:quaggaBeepCounter,
                                           scanditCallingCounter:scanditCallingCounter, scanditDetectedCounter:scanditDetectedCounter, scanditBeepCounter:scanditBeepCounter});
    }

    handleCameraSelectionClose(){
      this.handleCameraSelectionClickOk();
    } 

    handleCameraSelectionClickOk(){
      const cameraLabel = this.state.selectedCamera;
      SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Using the selected {cameraLabel}.",
                                          {cameraLabel:cameraLabel});
      setTimeout(()=>{
                var cameraId = this.getCameraIdfromSelectedCameraInState();
                this.startCamera(cameraId);
      },100);
      this.setState({ needSelectCamera: false  });
    }

    handleCameraSelectionChange(v){
      this.setState({selectedCamera:v});
    }

    getCameraIdfromSelectedCameraInState(){
      const cameraLabel = this.state.selectedCamera;
      if(!cameraLabel) {
        return undefined;
      }
      var cameraId=undefined;
      this.state.cameraIdList.forEach(oneDevice => {
        if(oneDevice.label==cameraLabel) cameraId=oneDevice.id;
      });
      return cameraId;
    }

    handleCroppedImgLoading(){
      //console.log('handleCroppedImgLoading');
    }

    handleCameraSelectionChangeOnView(v){
      this.setState({selectedCamera:v});
      localStorage.setItem('selected_scanning_camera',v);
      const cameraLabel = this.state.selectedCamera;
      SkbLogger.applicationTrace("Stocktake",0,"Barcode Scanning","Using the selected {cameraLabel}.",
                                          {cameraLabel:cameraLabel});
      var video = document.getElementById('video');
      if(video && video.srcObject){
        video.srcObject.getTracks().forEach(function(track) {
          track.stop();
        });
      }

      this.setState({ isLoading: true  });

      setTimeout(()=>{
        var cameraId = this.getCameraIdfromSelectedCameraInState();
        this.startCamera(cameraId);
      },100);
    }



    render() {
      var themeColorInDOM = document.getElementById('hidden-text-theme-palette');
      var themePalette = JSON.parse(themeColorInDOM.value);
      //console.log('themePalette',themePalette);

      var themecolors={};
     themecolors.theme=themePalette.type;
     themecolors.toolbarBackgroundColor=themePalette.background.secondary.replace(', 1)',')').replace(')',',1)'); //make sure there is always an alpha: ,1)
     themecolors.secondaryTextColor=themePalette.text.secondary;
     if(themecolors.theme=='dark') themecolors.primaryColor=themePalette.primary.main;
     if(themecolors.theme=='light') themecolors.primaryColor=themePalette.primary.dark;
     //only use dark theme colours for scanning page
     themecolors.primaryColor=themePalette.primary.main;
     themecolors.toolbarBackgroundColor='rgba(0,0,0,1)';
     themecolors.secondaryTextColor='#d6d6d6';

      //overwrite menuitem color
      setTimeout( ()=>{
        try {
          var selectContainerDiv = document.getElementById("scanitem-status-select-container");
          var selectFormControlDiv=selectContainerDiv.firstElementChild;
          var selectRootLabelDiv=selectFormControlDiv.firstElementChild;
          var selectRootInputDiv=selectRootLabelDiv.nextSibling;
          var selectMenuDiv=selectRootInputDiv.firstElementChild;
          selectMenuDiv.style.color=themecolors.secondaryTextColor;
          selectMenuDiv.style.backgroundColor=themecolors.toolbarBackgroundColor;
          var selectNativeInputDiv=selectMenuDiv.nextSibling;
          var selectIconDiv=selectNativeInputDiv.nextSibling;
          selectIconDiv.style.color=themecolors.secondaryTextColor;
        } catch (error) {
          //supress error
        }

        try {
          var selectContainerDiv = document.getElementById("scanitem-camera-select-container");
          var selectFormControlDiv=selectContainerDiv.firstElementChild;
          var selectRootLabelDiv=selectFormControlDiv.firstElementChild;
          var selectRootInputDiv=selectRootLabelDiv.nextSibling;
          var selectMenuDiv=selectRootInputDiv.firstElementChild;
          selectMenuDiv.style.color=themecolors.secondaryTextColor;
          selectMenuDiv.style.backgroundColor=themecolors.toolbarBackgroundColor;
          var selectNativeInputDiv=selectMenuDiv.nextSibling;
          var selectIconDiv=selectNativeInputDiv.nextSibling;
          selectIconDiv.style.color=themecolors.secondaryTextColor;
        } catch (error) {
          //supress error
        }

      },1000);
  

      return (
        <div >
            <Dialog
                fullScreen = {true}
                open={this.props.isOpen}
                onClose={this.handleDialogClose}
            >          
       
            <div  align = "center" >
                      
                      <video id="video" 
                              style={this.cameraVideoStyle(themecolors)} 
                              autoPlay
                              playsInline
                          >
                      </video>
            </div>

            <div style={this.topButtonOverlayStyle(themecolors)}>
              <div style={this.leftInTheTopButtonOverlayStyle(themecolors)}>
                {/*
                <SkbButton 
                                id="btn-close"  
                                primary 
                                text=''
                                startIcon={<CloseIcon />} 
                                onClick = {this.handleDialogClose} />
                */}
                <IconButton aria-label="close" 
                            id="btn-close"  
                            color="primary" 
                            onClick = {this.handleDialogClose} >
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={this.middleInTheTopButtonOverlayStyle(themecolors)}>
                {/*this.state.needSelectCamera && (
                  <div id='scanitem-camera-select-container'>
                  <SkbSelect Title="Camera" 
                            id="camera-selector" value={this.state.selectedCamera || ''} 
                            items={this.state.cameraLabelList || []} 
                            valueChanged={(eventTargetValue)=>this.handleCameraSelectionChangeOnView(eventTargetValue)} />
                  </div>
                ) */}
                {/*
                <SkbButton 
                                id="btn-code-format"  
                                primary 
                                text={this.state.barcodeFormats||'INDUSTRIAL FORMATS'} 
                                onClick = {this.handleCodeFormatButton} />
                                {` `}
                
                <SkbButton 
                                id="btn-code-format"  
                                primary 
                                text={this.state.scanStyle||'ACCURATE'} 
                                onClick = {this.handleScanStyleButton} />
                {` `}
                <SkbButton 
                                id="btn-stretch-ornot"  
                                primary 
                                text={this.state.stretchStyle||'STRETCH'} 
                                onClick = {this.handleStretchStyleButton} />
                */}
              </div>
              <div style={this.rightInTheTopButtonOverlayStyle(themecolors)}>
                {/*
                <SkbButton 
                                id="btn-delete-last"  
                                primary 
                                text=''
                                startIcon={<DeleteIcon />} 
                                onClick = {this.handleDeleteLast} />
                */}
                {this.state.canShowDeleteButton && <IconButton aria-label="delete last" 
                            id="btn-delete-last"  
                            color="primary" 

                            onClick = {this.openDeleteDlg.bind(this)} >
                  <DeleteIcon />
                </IconButton>}
                
              </div>

            </div>

            <div style={this.topOverlayStyle(themecolors)}>

                      <div style={this.leftInTheTopOverlayStyle(themecolors)} align='left'>
                        
                        <h4 style={this.topSectionHeadingStyle(themecolors)}>Last Barcode Details</h4>
                        <div id="last_bc_serialnumber_container">
                          <div style={this.topSectionLineLabelStyle(themecolors)} id = "last_bc_serialnumber_label">Last Serial #: </div> 
      <div style={this.topSectionLineContentStyle(themecolors)} id = "last_bc_serialnumber"> &nbsp; </div>
                        </div>
                        <div id="last_bc_description_container">
                          <div style={this.topSectionLineLabelStyle(themecolors)} id = "last_bc_description_label">Description: </div> 
                          <div style={this.topSectionLineContentStyle(themecolors)} id = "last_bc_description"> &nbsp; </div>
                        </div>
                        <div id="last_bc_status_container">
                          <div style={this.topSectionLineLabelStyle(themecolors)} id = "last_bc_status_label">Condition: </div> 
                          <div style={this.topSectionLineContentStyle(themecolors)} id = "last_bc_status"> {`  `} </div>
                        </div>
                      </div> 

                      <div style={this.rightInTheTopOverlayStyle(themecolors)} align ="right"  >
                        <h4 style={this.topSectionHeadingStyle(themecolors)}>  Scan History (Last 5)</h4>
                        { this.state.lastSerialNumbers.map((serialNumber)=>{
                            return (
                            <div style={this.topSectionSimpleLineStyle(themecolors)} key={'sn-'+serialNumber}>{serialNumber}</div>
                            )
                          })   
                        } 
                    </div> 

            </div>
            <div style={this.middleLeftOverlayStyle(themecolors)} ></div>
            <div style={this.middleOverlayStyle(themecolors)} >
            <div style={this.crosshairOverlayStyle(themecolors)}>
              {/* created from https://www.favicon-generator.org/image-editor/ */}
              {/* encoded from https://www.base64encode.org/ */}
              {/* img size to match around 80% of cropped-image size, considering padding/margin*/}
              <img align='center' width={this.state.crosshairsWidth} height={this.state.crosshairsHeight}
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcIAAACWCAYAAABNcIgQAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpVIqChYRcchQnSyIijhKFYtgobQVWnUwufQLmjQkKS6OgmvBwY/FqoOLs64OroIg+AHi5Oik6CIl/i8ptIjx4Lgf7+497t4BQqPCVLNrAlA1y0jFY2I2tyoGXhFECIPoByRm6on0Ygae4+sePr7eRXmW97k/R6+SNxngE4nnmG5YxBvEM5uWznmfOMxKkkJ8Tjxu0AWJH7kuu/zGueiwwDPDRiY1TxwmFosdLHcwKxkq8TRxRFE1yheyLiuctzirlRpr3ZO/MJTXVtJcpzmCOJaQQBIiZNRQRgUWorRqpJhI0X7Mwz/s+JPkkslVBiPHAqpQITl+8D/43a1ZmJp0k0IxoPvFtj9GgcAu0Kzb9vexbTdPAP8zcKW1/dUGMPtJer2tRY6Avm3g4rqtyXvA5Q4w9KRLhuRIfppCoQC8n9E35YCBWyC45vbW2sfpA5ChrpZvgINDYKxI2ese7+7p7O3fM63+fgAgjHKG20hylQAAAAZiS0dEADoAOgA6/SKirAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+QKDBYzELAQ/rsAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAC0klEQVR42u3dMYrbQBSAYc1mCyNwY51CddSpdlqJHCFX8AV0gVwghe+QWnW66cXWSes0gTiQgFKECRtYg4vIEtb3VYNX1YPnn0USzjIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgD+KfoxFP8a8bqJpwDwejADmi2A6bw7HUgxBCGG1wnY3mAIIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIIQAIISzaaR+qdD53rYEAsE553cS8bqJJAAAAAAAAAABw14p+jEU/elgGZuT1CZgxgum8ORxLMQQhhNUK291gCiCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEACCEsGinfajS+dy1BgLAOuV1E/O6iSYBAAAAAADcSEiHoh/do7h/v7IsezSG2zp3bfb908fqpb+lvbvmGmCa3Xxl0VbFU8IzeHz9pgxfnt7+/Pz04VLgrrkGmGY3fTHChMZvX0tTgGXv5kP699DCwjR+vH83vPT58/cIL11jN2H63fx7j9B7TDCdS/f/nu/dNdcAt9lNAAAAAAAAAAAA+L+KfoxFP/r1CZiRF+phxgim8+ZwLMUQhBBWK2x3gymAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEAKAEMKinfahSudz1xoIAOuU103M6yaaBAAAAAAAAAAAAMC9KvoxFv3oqVGYkfcIYcYIpvPmcCzFEIQQVitsd4MpgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBACgBDCop32oUrnc9caCADrlNdNzOsmmgQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//gNICmdTtDSlZsAAAAASUVORK5CYII='
              />
            </div>
            </div>
            <div style={this.middleRightOverlayStyle(themecolors)} ></div>
            <div style={this.bottomOverlayStyle(themecolors)} >

                    <div align ="center">
                    
                        <skbtab label='Stock Condition'>
                            {/* <div style={this.topSectionSimpleLineStyle(themecolors)}> Stock Condition </div>*/}
                            <div id='scanitem-status-select-container' >
                                <SkbSelect Title="Stock Condition" value="Good" items={["Good", "Faulty"]}   valueChanged={this.handleChangeStatus}  />
                            </div>
                        </skbtab>

                        <skbtab label='Camera'>
                            <div id='scanitem-camera-select-container' >
                            {this.state.needSelectCamera && (
                              <SkbSelect Title="Camera" 
                                        id="camera-selector" value={this.state.selectedCamera || ''} 
                                        items={this.state.cameraLabelList || []} 
                                        valueChanged={(eventTargetValue)=>this.handleCameraSelectionChangeOnView(eventTargetValue)} />
                            ) }
                            </div>
                        </skbtab>
                        

                        {/*
                        <skbtab label='Barcode Library'>
                            <div id='barcode_lib-select-container' >
                                <SkbSelect Title="" value="Scandit" items={["ZXing", "Quagga", "Scandit"]}   valueChanged={this.handleChangeLib}  />
                            </div>
                        </skbtab>
                        */}
                      {/*
                      <div align = "center" style={this.thumbnailStyle(themecolors)} > 
                            <img id="thumbnail-img" width={this.state.scanAreaWidth/4+'px'}  height={this.state.scanAreaHeight/4+'px'}/>
                            {` `}
                            <img id="thumbnail-img-decoded" width={this.state.scanAreaWidth/4+'px'}  height={this.state.scanAreaHeight/4+'px'}/>
                        </div>
                        */}

                      <div  align = "center" >
                          <canvas id="cropped-canvas" width={this.state.scanAreaWidth+'px'}  height={this.state.scanAreaHeight+'px'} style= {{display:'none'}}  ></canvas>
                      </div> 
                      {/*
                      <div  align = "center" >
                          <canvas id="cropped-canvas-wider" width={this.state.scanAreaWidth*STRETCH_FACTOR+'px'}  height={this.state.scanAreaHeight+'px'} style= {{display:'none'}}  ></canvas>
                      </div>
                      <div  align = "center" >
                          <canvas id="cropped-canvas-smaller" width={this.state.scanAreaWidth*SMALL_FACTOR+'px'}  height={this.state.scanAreaHeight*SMALL_FACTOR+'px'} style= {{display:'none'}}  ></canvas>
                      </div>
                      */}
                        {/*
                      <div align = "center"  > 
                            <img id="cropped-img-to-decode"  width={this.state.scanAreaWidth+'px'}  height={this.state.scanAreaHeight+'px'} style= {{display:'none'}} />
                        </div>

                        <div align = "center"  > 
                            <img id="cropped-img-to-decode-wider"  width={this.state.scanAreaWidth*2+'px'}  height={this.state.scanAreaHeight+'px'} style= {{display:'none'}} />
                        </div>
                        */}

                      <div align = "center"  > 
                            <canvas id="original-canvas" width={VIDEO_WIDTH+'px'}  height={VIDEO_HEIGHT+'px'} style= {{display:'none'}}  ></canvas>
                      
                        </div>              

                      <div align = "center"  > 
                      {/*[3f] Apply stamp to image including Stock Description, Date and Time, as well as Serial Number */}
                            <img id="original-img" width={VIDEO_WIDTH+'px'}  height={VIDEO_HEIGHT+'px'}  style= {{display:'none'}}   />
                      </div>

                    </div>
            </div>

          
                  { 
                    this.state.isLoading == true?
                      <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 10}}>
                        <CircularProgress />
                      </div>
                    :
                      <div/>
                  }  

                 
                  <SkbConfirmDialog 
                    id="confirm-skybridge-stock-dialog" 
                    title={"Unknown Serial Number - "  + this.state.currentSN}
                    message="Unknown serial number detected. This may have occurred due to scanning the wrong serial number, or the stock may have been previously lost or transferred.  Are you sure you want to add this stock to your stock holding?"
                    okButtonIsFirst={false}
                    okLabel="Yes"
                    cancelLabel="No"
                    open={this.state.isSkybridgeStock} 
                    openConfirm={this.handleCloseSkybridgeDialog}
                    okHandler={this.handleConfirmSkybridgeStock} />
                  <SkbAlertDialog 
                    id="duplicated-dialog" 
                    title={"Duplicate Serial Number - " + this.state.currentSN} 
                    message="Duplicate serial number. Please verify that you are scanning the correct serial number." 
                    open={this.state.isDuplicated} 
                    openAlert={this.handleCloseDuplicatedDialog} 
                   />
                   <SkbAlertDialog 
                    id="rotation-dialog" 
                    title="Barcode Scanning Tips" 
                    message=
{`1. Avoid partially shadowing the barcode. 
2. Shake your device a little to get the camera refocussed if the barcode is blurry.
3. Try different orientations (Landscape/Portrait) to see which one works better on your device.`} 
                    open={this.state.isRemindingRotation} 
                    openAlert={this.handleRotationDialog} 
                   />
                {/* camera selection dialog - removed */}
                {/*
                <Dialog 
                  disableBackdropClick
                  disableEscapeKeyDown
                  maxWidth="xs"
                  onClose={this.handleCameraSelectionClose.bind(this)} 
                  aria-labelledby="camera-slection-dialog-title" 
                  open={this.state.needSelectCamera} >
                      <DialogTitle id="camera-slection-dialog-title" onClose={this.handleCameraSelectionClose.bind(this)}>
                           
                      </DialogTitle>
                      <DialogContent dividers>
                      
                          <SkbSelect Title="Select camera for barcode" id="camera-selector" value={this.state.selectedCamera} 
                          items={this.state.cameraLabelList} valueChanged={(eventTargetValue)=>this.handleCameraSelectionChange(eventTargetValue)} />

                      </DialogContent>
                      <DialogActions>
                          <SkbButton id="ok-button" primary size="medium" text="Ok" 
                          onClick={this.handleCameraSelectionClickOk.bind(this)}  />

                      </DialogActions>

                </Dialog>
                */}
                <SkbConfirmDialog 
                id="confirm-deletion-current-sn" 
                title="Confirm Deletion" 
                message={this.state.confirmMessage}
                okButtonIsFirst={false}
                okLabel="Yes"
                cancelLabel="No"
                open={this.state.isOpenDeleteDlg} 
                openConfirm={this.handleCloseDeleteDlg.bind(this)}
                okHandler={this.handleDeleteLast.bind(this)} />
            {this.state.isShowInvalidBarcode?
              <NotificationInvalidBarcode />
              :
              <div />
            }
            </Dialog>
           
            
          </div>
      );
    }
  }

 