import React, { useEffect,useState, Suspense , useCallback} from 'react';
import {connect,useDispatch, useSelector} from 'react-redux';
import {SkbSelect} from '../../../../skb_controls/components/SkbSelect';  
import SkbTable from '../../../../skb_controls/components/SkbTable';  
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';

import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import Link from '@material-ui/core/Link';
// import { Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';

import Loading from '../../../Loading';

import {useSnackbar } from 'notistack';

import * as  returnsDashboardTaskInfoActions from '../../../../Redux/actions/returnsdashboard';
import * as misc from '../../../../utils/misc';


//import ContractorRepresentativePhoneEmailStructure  from "./ContractorRepresentativePhoneEmailStructure";
import {getReturnDashboardUtility} from "./returnDashboardUtility"; 


import ShipmentDetails from './ShipmentDetails';
import ConsignmentDetails from './ConsignmentDetails';
import ShipmentDetailsContractorDropOff from './ShipmentDetailsContractorDropOff';

import SkbStepper from '../../../../skb_controls/components/SkbStepper';
//import SkbTabMenu from '../../../../skb_controls/navigation/SkbTabMenu';

import * as returnStockUtils from '../../../../utils/returnstock/misc';



const classes = {
  root: {
    flexGrow:1
  },
  paper: {
    padding:20,
    textAlign: "center",
  }
}

export const triggerValidateStep1 = ({stepOneWareHouseLocationCode, stepOneAtp, stepOneForkliftRequired},otherParam={}) =>{
  let validateStepOneData =  true;
  const {specificFor, getValidateFlag} = {...otherParam};
  let validateFlagErrMessages =  {};
  let retValidResp = {};
  
  
  if(specificFor){
    if(specificFor==="stepOneWareHouseLocationCode"){

      if(!(stepOneWareHouseLocationCode)){
        validateStepOneData = false;
        validateFlagErrMessages["wareHouseLocationCodeErrMsg"] = "Warehouse location is required.";        
      }

      // if we want to get the validateFlag of this control input too otherwise only get the message
      if(getValidateFlag){
          retValidResp["validateFlagProvided"]  = validateStepOneData;         
      }
       
      retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages;       
      return retValidResp;

    }


  }else{
    if(!(stepOneWareHouseLocationCode)){
      validateStepOneData = false;
      validateFlagErrMessages["wareHouseLocationCodeErrMsg"] = "Warehouse location is required.";
    }
  
    if(([true,false].indexOf(stepOneAtp)===-1)){
      validateStepOneData = false;
      validateFlagErrMessages["atpErrMsg"] = "ATP is required.";
    }
    
    if(([true,false].indexOf(stepOneForkliftRequired)===-1)){
      validateStepOneData = false;
      validateFlagErrMessages["forkliftRequiredErrMsg"] = "Fork lift required opinion is required.";
    }
  }

  let flagDataWithErrMsgObj = {
    "validateFlagProvided":validateStepOneData,
    "validateFlagErrMessagesProvided":validateFlagErrMessages
  }


 return flagDataWithErrMsgObj;
  
}


export const validatePckupWindowDate = (dateObj)=>{        

  const todaysDateObj = new   Date();
  const chkWhetherSselDateIsFutureDate = getReturnDashboardUtility.isTheDateGreaterThanTheOtherOne(dateObj,todaysDateObj);
  return chkWhetherSselDateIsFutureDate;

}

export const triggerValidateStep2 = ({stepTwoCarrierName,stepTwoCannote,stepTwoPickupWindowDate,stepTwoPickupWindowTimeslot,stepTwolabelFiles},otherParam={}) =>{
  let validateStepTwoData =  true;
  const {specificFor, getValidateFlag} = {...otherParam};
  let validateFlagErrMessages =  {};
  let retValidResp = {};

  if(specificFor){
    if(specificFor==="stepTwoCarrierName"){

      if(!(stepTwoCarrierName)||(stepTwoCarrierName==="NOTSELECTED")){
        validateStepTwoData = false;
        validateFlagErrMessages["carrierNameErrMsg"] = "Carrier is required";         
      }

      // if we want to update the validateFlag of this step too otherwise only update the message
      if(getValidateFlag){
          retValidResp["validateFlagProvided"]  = validateStepTwoData;         
      }
       
      retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
      return retValidResp;


    }

    if(specificFor==="stepTwoCannote"){

      if(!(stepTwoCannote)){  
        validateStepTwoData = false;
        validateFlagErrMessages["connoteErrMsg"] = "Connote is required";
      }

      // if we want to update the validateFlag of this step too otherwise only update the message
      if(getValidateFlag){
        retValidResp["validateFlagProvided"]  = validateStepTwoData;         
      }
      retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
      
      return retValidResp;
    }


    if(specificFor==="stepTwoPickupWindowDate"){

      if(!(stepTwoPickupWindowDate)){
        validateStepTwoData = false;
        validateFlagErrMessages["pickupWindowDateErrMsg"] = "Date is required";
        
      }
      else{

        let chkValidateWindPickDate = validatePckupWindowDate
        (stepTwoPickupWindowDate);      
    
        if(!(chkValidateWindPickDate)){   
            validateStepTwoData = false;
            validateFlagErrMessages["pickupWindowDateErrMsg"] = "Future date is required.";  
        }
        
      }

      // if we want to update the validateFlag of this step too otherwise only update the message
      if(getValidateFlag){
        retValidResp["validateFlagProvided"]  = validateStepTwoData;         
      }
      retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
      return retValidResp;

    }

    if(specificFor==="stepTwoPickupWindowTimeslot"){
      if(!(stepTwoPickupWindowTimeslot)){
        validateStepTwoData = false;
        validateFlagErrMessages["pickupWindowTimeSlotErrMsg"] = "Time slot is required";
        
      }   

      // if we want to update the validateFlag of this step too otherwise only update the message
      if(getValidateFlag){
        retValidResp["validateFlagProvided"]  = validateStepTwoData;         
      }
      retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
      return retValidResp;

    }

    if(specificFor==="stepTwolabelFiles"){
      if(!(stepTwolabelFiles)||(stepTwolabelFiles && stepTwolabelFiles.length===0)){
        validateStepTwoData = false;
        validateFlagErrMessages["labelsErrMsg"] = "Label is required";

      
        
      }   

      // if we want to update the validateFlag of this step too otherwise only update the message
      if(getValidateFlag){
          retValidResp["validateFlagProvided"]  = validateStepTwoData;         
      }
      retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
      return retValidResp;

    }

  }
  else{

       if(!(stepTwoCarrierName)||(stepTwoCarrierName==="NOTSELECTED")){
      validateStepTwoData = false;
      validateFlagErrMessages["carrierNameErrMsg"] = "Carrier is required.";
    }

    if(!(stepTwoCannote)){
      validateStepTwoData = false;
      validateFlagErrMessages["connoteErrMsg"] = "Connote is required.";
    }
   

    
    if(!(stepTwoPickupWindowDate)){
      validateStepTwoData = false;
      validateFlagErrMessages["pickupWindowDateErrMsg"] = "Date is required.";
    }
    else{
     let chkValidateWindPickDate = validatePckupWindowDate
      (stepTwoPickupWindowDate);      

      if(!(chkValidateWindPickDate)){
        validateStepTwoData = false;
        validateFlagErrMessages["pickupWindowDateErrMsg"] = "Future date is required.";
      }
    }

     if(!(stepTwoPickupWindowTimeslot)){
      validateStepTwoData = false;
      validateFlagErrMessages["pickupWindowTimeSlotErrMsg"] = "Time slot is required.";
    }

     if(!(stepTwolabelFiles)||(stepTwolabelFiles && stepTwolabelFiles.length===0)){
      validateStepTwoData = false;
      validateFlagErrMessages["labelsErrMsg"] = "Label is required.";
    }  

  }

  let flagDataWithErrMsgObj = {
    "validateFlagProvided":validateStepTwoData,
    "validateFlagErrMessagesProvided":validateFlagErrMessages
  }

 

 return flagDataWithErrMsgObj;
}



// STEPPER COMPONENT
const StepperComponent = (propsData) => {

  const {taskinfoschhedulepropdata, handlepopupclosefornoncontractordropoff} = propsData;
  const taskId = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.taskId ) ? taskinfoschhedulepropdata.taskId: "";
  const consignmentLocationCode = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.dataConsignmentLocationCode ) ? taskinfoschhedulepropdata.dataConsignmentLocationCode: "";


 const dispatch =   useDispatch();

  const {stepOneWareHouseLocationCode,stepOneAtp,stepOneForkliftRequired, stepTwolabelFiles,  stepTwoCarrierName, stepTwoCannote , stepTwoPickupWindowTimeslot,stepTwoPickupWindowDate } = useSelector((state) =>( {  
    //return state.returnsDashboardTaskInfoSchedulePopupChangedData;  

    "stepOneWareHouseLocationCode": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.wareHouseLocationCode!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.wareHouseLocationCode: "",

    "stepOneAtp": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.atp!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.atp: false,

    "stepOneForkliftRequired": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.forkLiftRequired!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepOneChangedData.forkLiftRequired: false,
  
        "stepTwolabelFiles" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.labelFiles!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.labelFiles: [],
        
        "stepTwoCarrierName" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.carrierName!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.carrierName: "",


        "stepTwoCannote" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.cannote!==undefined ?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.cannote: "",

   
      "stepTwoPickupWindowTimeslot" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowTimeslot!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowTimeslot: "",              

      "stepTwoPickupWindowDate" : state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowDate!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.twoStepScenarioStepTwoChangedData.pickupWindowDate: "",  


  })
  ); 
  
  
  const {  taskInfoScheduleFinishApiSuccessFlag,taskInfoScheduleFinishApiSuccessMsg , taskInfoScheduleFinishApiErrorFlag, taskInfoScheduleFinishApiErrorMessage  } = useSelector((state) => ({
    //return state.returnsDashboardTaskInfo;
    
    "taskInfoScheduleFinishApiSuccessFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessFlag)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessFlag):false,
    "taskInfoScheduleFinishApiSuccessMsg": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessMsg)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessMsg):"",
    "taskInfoScheduleFinishApiErrorFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorFlag)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorFlag):false,
    "taskInfoScheduleFinishApiErrorMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorMessage)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorMessage):"",
    


  })
);


const { fromLocationAddressLine1, fromLocationAddressLine2, fromLocationState, fromLocationSuburb, fromLocationPostcode    } = useSelector((state) => ({
  //return state.returnsDashboardTaskInfo;
  
  "fromLocationAddressLine1": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine1)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine1):"",
  "fromLocationAddressLine2": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine2)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.AddressLine2):"",
 "fromLocationState": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.State)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.State):"",
 "fromLocationSuburb": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.Suburb)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.Suburb):"",
 "fromLocationPostcode": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.FromLocation && state.returnsDashboardTaskInfo.taskInfo.FromLocation.Postcode)? (state.returnsDashboardTaskInfo.taskInfo.FromLocation.Postcode):"",
 


})
);

  
  // THIS IS FIRED WHEN USER CHANGES STEP FORWARDS & BACKWARDS
  const stepCompletedCallback = (step) => {    

  
    
  }

  const closeTaskInfoScheduleAndTaskInfoPopup =  useCallback(()=>{
    if(taskInfoScheduleFinishApiSuccessFlag){

          // trigger close popup from  here
          handlepopupclosefornoncontractordropoff();

    }
  });
  var props = {
      "StepValidators": {
          validateStep1: function () {

            let paramToValidate = {
              "stepOneWareHouseLocationCode":stepOneWareHouseLocationCode,
              "stepOneAtp":stepOneAtp,
              "stepOneForkliftRequired":stepOneForkliftRequired,
            };            

           let chkv1 = triggerValidateStep1(paramToValidate,{});
           let errStateObj = {
            "validateFlagProvided":chkv1.validateFlagProvided,
            "validateFlagErrMessagesProvided":chkv1.validateFlagErrMessagesProvided
          }

          dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepOneValidateFlagWithFeErMsg(errStateObj));

          //dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepOneValidateFlag(validateStepOneData));
    

           if(chkv1.validateFlagProvided){
            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeableDataSetFlag(false));
           }
           
            return {
                  isValid: chkv1.validateFlagProvided,
                  validationResult: {
                      message: "One of these fields has not been provided: Warehouse, ATP, Forklift",
                      title: "Test",
                      display: "toast"
                  }
              }
          },
          validateStep2: function () {

            let paramToValidate = {
              "stepTwoCarrierName":stepTwoCarrierName,
              "stepTwoCannote":stepTwoCannote,
              "stepTwoPickupWindowDate":stepTwoPickupWindowDate,"stepTwoPickupWindowTimeslot":stepTwoPickupWindowTimeslot,
              "stepTwolabelFiles":stepTwolabelFiles
            };
            
            let chkv2 = triggerValidateStep2(paramToValidate,{});
            
            let errStateObj = {
              "validateFlagProvided":chkv2.validateFlagProvided,
              "validateFlagErrMessagesProvided":chkv2.validateFlagErrMessagesProvided
            }

            dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupTwoStepStepTwoValidateFlagWithFeErMsg(errStateObj));
           
              return {
                  isValid: chkv2.validateFlagProvided,
                  validationResult: {
                      message: "One of these fields has not been provided: Carrier, Connote, Pickup window date, Pickup timeslot, Label",
                      title: "Test",
                      display: "toast"
                  }
              }
          },         

      },
      "consignmentLocationCode": consignmentLocationCode,
      "taskId":taskId,
      "closeTaskInfoScheduleAndTaskInfoPopup": closeTaskInfoScheduleAndTaskInfoPopup
  };

  const Step1Content = (props) => {
      return (<ShipmentDetails {...props} />)
  };

  const Step2Content = (props) => {
      return (<ConsignmentDetails {...props} />)
  };

  const steps = [
      {
        Title: "Shipment Details",
        StepName: "Shipment Details",
        Description: "",
        Content: <Step1Content {...props}  />,
        StepValidator: props.StepValidators.validateStep1,
        Completed: false,
        
    },
    {
        Title: "Consignment Details",
        StepName: "Consignment Details",
        Description: "",
        Content: <Step2Content {...props}  />,
        StepValidator: props.StepValidators.validateStep2,
        Completed: false,
        
    },
  ]


  return(
    <Box
        
    >
      <SkbStepper 
        stepCompletedCallback={stepCompletedCallback}
        Steps={steps} 
        WizardInfo={{
          DisplayText:  <Typography variant="h6" >Task ID: {taskId}</Typography>,  Description: returnStockUtils.buildAddressInOneLine(fromLocationAddressLine1, 
            fromLocationAddressLine2,
            fromLocationSuburb,
            fromLocationState,
            fromLocationPostcode) 
        }}
        
        NextButtonNameAtLastStep="Finish"
      />
    </Box>
  );
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
  
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);   

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '#stepper_footer.MuiGrid-root': {
      position: 'relative',
    },    
  },
})(() => null);




const  TaskDetailsSchedulePopup = React.forwardRef ((propsData,refObj) =>  {
  const [open, setOpen] = React.useState(false);
  const [shouldOpenConfirmForCloseTaskInfoSchedPopup, setShouldOpenConfirmForCloseTaskInfoSchedPopup] = useState(false);
 

  const {taskinfoschhedulepropdata,handletaskinfoclosefromschedulepopup} = propsData;
  const taskId = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.taskId ) ? taskinfoschhedulepropdata.taskId: "";
  const taskInfoConsignmentType = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.taskInfoConsignmentType ) ? taskinfoschhedulepropdata.taskInfoConsignmentType: "";
  {/* VARIABLE FOR CONSIGNMENT METHOD  */}
  const taskInfoConsignmentMethod = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.taskInfoConsignmentMethod ) ? taskinfoschhedulepropdata.taskInfoConsignmentMethod: "";

  const dispatch = useDispatch();
  /*
  
  const { enqueueSnackbar } = useSnackbar(); 

  const {} = useSelector((state) => ({
  }));

  */

 const {scheduleFlowLoaderFlag,activityDoneByUserInTaskInfoSched} = useSelector((state) =>( {  
  //return state.returnsDashboardTaskInfoSchedulePopupChangedData;  
  "scheduleFlowLoaderFlag": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.scheduleFlowLoaderFlag!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.scheduleFlowLoaderFlag:false,
  "activityDoneByUserInTaskInfoSched": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.dataChangeActivityDoneByUser!==undefined?state.returnsDashboardTaskInfoSchedulePopupChangedData.dataChangeActivityDoneByUser:false,

      })
  );

  const handleOkConfirmClose = () =>{
    triggerCloseTaskInfoSchedPopup();    
  }

 
  const handleClickOpen = () => {
    setOpen(true);
     
      
    };
  const handleClose = () => {

        if(activityDoneByUserInTaskInfoSched===true){
          setShouldOpenConfirmForCloseTaskInfoSchedPopup(true);
        }
        else{
          triggerCloseTaskInfoSchedPopup();
        }
      
    };

    const triggerCloseTaskInfoSchedPopup = () =>{
      setOpen(false);
    }

 const unsetReduxStateRelattedToTaskInfoSched = () => {
   if(taskInfoConsignmentMethod !== "Contractor drop-off"){
    dispatch(returnsDashboardTaskInfoActions.setWarehouseAddrBasedOnLocCodeTIPSTwoStepsUnset());
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeableFullUnset());
    dispatch(returnsDashboardTaskInfoActions.setPickupWindowTimeListUnset());
    
    dispatch(returnsDashboardTaskInfoActions.setLogisticCarrierListUnset());
   }else{
    dispatch(returnsDashboardTaskInfoActions.setTaskInfoSchedulePopupDataChangeableFullUnset());
    dispatch(returnsDashboardTaskInfoActions.setDropOffWindowTimeListUnset());
   }
  
 }

 const dialogOnEnterFunc = async () =>{
      // unset these redux state related to task info sched popup flow
    unsetReduxStateRelattedToTaskInfoSched();
     
  }

  const dialogOnEnteredFunc = async () =>{
      // This function is trigerred when popup opens


  } 

  const dialogOnExitedTrackFunc = () =>{
    // unset these redux state related to task info sched popup flow
    unsetReduxStateRelattedToTaskInfoSched();

     
  }

  const handlePopupCloseNonContractorDropoff = useCallback(()=>{
    triggerCloseTaskInfoSchedPopup();
    handletaskinfoclosefromschedulepopup();
  });

  const handlePopupCloseContractorDropOff = useCallback(()=>{
     triggerCloseTaskInfoSchedPopup();
     handletaskinfoclosefromschedulepopup();
    
  })

  useEffect(()=>{
    
  
    return (()=>{
     

  });
},[]); 



  return (
    <div>    

      <Link style={{"display":"none"}} className={"taskInfoScheduleBtnCLass"} ref={refObj} id={`btn-for-taskinfoschedule-${taskId}`}  onClick={handleClickOpen}  > link to open task info schedule popup 
      </Link>
      <Dialog fullWidth={true} maxWidth={"md"} onEnter={dialogOnEnterFunc}  onEntered={dialogOnEnteredFunc} onExited={dialogOnExitedTrackFunc} aria-labelledby="customized-dialog-title" open={open}>

      { scheduleFlowLoaderFlag && (<Loading />)}  
      
      {open && (<> 
          <DialogTitle id="customized-dialog-title" onClose={handleClose}   >           
              <SkbIcon  icon={DashboardIcon}/> {taskInfoConsignmentType}  
        </DialogTitle>
        <DialogContent dividers={true} >         
          <div className={classes.root}  >  
             
             {
               taskInfoConsignmentMethod 
               && taskInfoConsignmentMethod === "Skybridge courier"
               && <StepperComponent taskinfoschhedulepropdata={taskinfoschhedulepropdata} handlepopupclosefornoncontractordropoff={handlePopupCloseNonContractorDropoff} />
             }

             {
               taskInfoConsignmentMethod
               && taskInfoConsignmentMethod === "Contractor drop-off"
               && <ShipmentDetailsContractorDropOff 
                    taskinfoschhedulepropdata={taskinfoschhedulepropdata} 
                    handlePopupCloseContractorDropOff={handlePopupCloseContractorDropOff}
                  />
             }
             
             {/* <ShipmentDetails /> */}
             {/* <ConsignmentDetails />  */}
          
          </div>
         
        </DialogContent>
        <DialogActions>  

          
                <>
                  {/* <Box  textAlign="right" >
                    <SkbButton id="task-info-schedule1"  primary size="medium" text="Finish" onClick={()=>{}}  />
                  </Box> */}
                 
                </>

                  
                            
         
        </DialogActions>
        <SkbConfirmDialog
            id="confirm-taskinfoschedule-popup-close"
            title={"Abandon Changes?"}
            message={"If you continue you will lose changes that you have made. Are you sure you wish to continue and lose changes?"}
            okButtonIsFirst={false}
            okLabel="Yes"
            cancelLabel="No"
            open={shouldOpenConfirmForCloseTaskInfoSchedPopup}
            openConfirm={setShouldOpenConfirmForCloseTaskInfoSchedPopup}
            okHandler={handleOkConfirmClose}
            
            />
     
        <GlobalCss />
        
        </>


        )}
       
      </Dialog>
     

    </div>
  );
}
);

export default TaskDetailsSchedulePopup;