import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { SkbLogger } from '../../../services';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import InputIcon from '@material-ui/icons/Input';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import clsx from 'clsx';
import SkbButton from '../../../skb_controls/components/SkbButton';
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as misc from '../../../utils/misc';
import * as returnStockUtils from '../../../utils/returnstock/misc';
// import TaskDetails from './TaskDetails';



import * as taskActions from '../../../Redux/actions/tasks';
import Tasks from './stocktake/Tasks';
// const taskConfig = require('./taskConfig.json');
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(0),
        '&>div.MuiGrid-item': {
            alignSelf: 'center',
        }
    },

    taskheader: {

    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end',
    },


    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },

    cardContentRoot: {
        display: "flex",
        alignItems: "center"

    },
    cardContentDetails: {
        flex: '1 0 auto',
    },
    cardContentMedia: {

        width: 150,
        height: 150,
        margin: theme.spacing(1),
        borderRadius: "2px",
        flexGrow: 0,
        [theme.breakpoints.down("xs")]: {
            width: 50,
            height: 50,
        }
    },
    cardContentContent: {
        flexGrow: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),

        }
    },

    cardExpander: {
        display: "flex",
        alignItems: "center",
        // '& button': {
        //     paddingTop: 0
        // }
    },

    headerRoot: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },

    taskStatus: {
        textAlign: "right",
        marginTop: theme.spacing(-3),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(0),
        }
    },

    label: {
        display: 'inline-block',
        width: 60,
    },

    notstarted: {
        color: theme.palette.error.main,
    },
    inprogress: {
        color: theme.palette.warning.main,
    },
    done: {
        color: theme.palette.success.main
    },

    indicator: {
        flexBasis: 0,
        display: "inline-block",
        marginRight: theme.spacing(0.5),
    }
}));

// const ButtonText = {
//     Open: "Open",
//     Reopen: "Reopen",
//     Start: "Start"
// }

// const SubTaskStatus = ({ Status }) => {
//     const classes = useStyles();
//     const statusStyle = clsx({
//         [classes.notstarted]: Status === taskActions.Status.NotStarted,
//         [classes.inprogress]: Status === taskActions.Status.InProgress,
//         [classes.done]: Status === taskActions.Status.Done

//     });

//     return (
//         <Grid container className={statusStyle} justify="flex-start" alignItems="center" direction="row">
//             <Grid item xs={6} className={classes.indicator}>
//                 <RadioButtonUncheckedIcon />
//             </Grid>
//             <Grid item xs={6}>
//                 {Status}
//             </Grid>
//         </Grid>
//     )
// }

export function TaskDetailsReturnStock(props) {
    const classes = useStyles();
    // const history = useHistory();
    // const { enqueueSnackbar } = useSnackbar();
    // const [openConfirm, setOpenConfirm] = React.useState(false);
    // const [openDetailForm, setOpenDetailForm] = React.useState(false);
    // const [confirmMsg, setConfirmMsg] = useState("");
    // const [confirmTitle, setConfirmTitle] = useState("");


    const {TaskData,         User, Task, } = props;

    return (
        <div>
            <Grid container className={classes.root} id={TaskData.SubTaskId}>
            <Grid item xs={12} sm={12} >
            <Typography variant="h6" className={classes.centeralign} >Task details</Typography>
                </Grid>
                <Grid item xs={12} sm={12} >
                      &nbsp;
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Grid item>
                        <Typography variant="h6">Delivery Method</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                    
                    <SkbIcon  icon={returnStockUtils.getDeliveryMethodIcon(TaskData.DeliveryMethod)} /> {TaskData.DeliveryMethod} 
                    </Grid>
                    {TaskData.DeliveryMethod ==="Skybridge courier" && (TaskData.PickupWindowDate||TaskData.PickupDueday) 
                                        && <Grid item >  <Typography variant="h6"> Pickup date</Typography>
                    </Grid>}
                
                    {TaskData.DeliveryMethod ==="Skybridge courier" && (TaskData.PickupWindowDate||TaskData.PickupDueday) &&<Grid item >
                    {misc.dateAndEtaToOneString((TaskData.PickupWindowDate||TaskData.PickupDueday),TaskData.PickupWindowETA) }
                    </Grid>}
                    {TaskData.DeliveryMethod ==="Contractor drop-off" && (TaskData.DropOffWindowDate||TaskData.ReturnDueDate) 
                                    && <Grid item >  <Typography variant="h6"> Drop-off date</Typography>
                    </Grid>}
                
                    {TaskData.DeliveryMethod ==="Contractor drop-off" && (TaskData.DropOffWindowDate||TaskData.ReturnDueDate)  &&<Grid item >
                    {misc.dateAndEtaToOneString((TaskData.DropOffWindowDate||TaskData.ReturnDueDate),TaskData.DropOffWindowETA) }
                    </Grid>}
                    

                    <Grid item> 
                   <Typography variant="h6"> Skybridge notes </Typography>
                    </Grid>
                   <Grid item> 
                   {TaskData.SkbNotes}
                    </Grid>
                    <Grid item>
                      &nbsp;
                    </Grid>

                </Grid>
                <Grid item xs={6} sm={6}>
                   <Grid item >
                       <Typography variant="h6">  Delivery destination</Typography> 
                    </Grid>
                    <Grid item >
                    {TaskData.DeliveryDestination}
                    </Grid>
                    <Grid item> 
                     {TaskData.DeliveryAddressLine1}
                    </Grid> 
                    <Grid item> 
                     {TaskData.DeliveryAddressLine2}
                    </Grid>
                    <Grid item> 
                    {TaskData.DeliveryAddressSuburb}  {TaskData.DeliveryAddressState}  {TaskData.DeliveryAddressPostcode}
                    </Grid>
                    <Grid item>
                      &nbsp;
                    </Grid>

                    <Grid item > 
                   Modified:                     {misc.datetimeStringInUTCToLocaldatetimeString(TaskData.ModifiedDate, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)  }
                    </Grid>

                    <Grid item > 
                    Created: {misc.datetimeStringInUTCToLocaldatetimeString(TaskData.DateCreated, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)}
                    </Grid>
                    <Grid item > 

                    <Typography variant='body2'>Modified By: {TaskData.ModifiedBy}</Typography>
                   
                    </Grid>
                </Grid>
                
                {/* <Grid item xs={6} sm={6}>
                <Typography variant="h6">Delivery Method</Typography>
                    </Grid>
                <Grid item xs={6} sm={6}>
                <Typography variant="h6">  Delivery destination</Typography> 
                    </Grid>
                
                <Grid item xs={6} sm={6}>
                    
                    <SkbIcon  icon={returnStockUtils.getDeliveryMethodIcon(TaskData.DeliveryMethod)} /> {TaskData.DeliveryMethod} 
                    </Grid>
                <Grid item xs={6} sm={6}>
                    {TaskData.DeliveryDestination}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                    </Grid> 
                    <Grid item xs={6} sm={6}> 
                     {TaskData.DeliveryAddressLine1}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                    </Grid> 
                    <Grid item xs={6} sm={6}> 
                     {TaskData.DeliveryAddressLine2}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                    </Grid> 
                    <Grid item xs={6} sm={6}> 
                    {TaskData.DeliveryAddressSuburb}  {TaskData.DeliveryAddressState}  {TaskData.DeliveryAddressPostcode}
                    </Grid>
                    <Grid item xs={6} sm={6}>   <Typography variant="h6"> Pickup date</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                    </Grid> 
                    
                    <Grid item xs={6} sm={6}>
                    {misc.datetimeStringInUTCToLocaldatetimeString(TaskData.PickupDueDay, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)  }
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                    </Grid> 
                    
                    <Grid item xs={6} sm={6}> 
                   <Typography variant="h6"> Skybridge notes </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                   </Grid> 
                   <Grid item xs={6} sm={6}> 
                   {TaskData.SkbNotes}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                   
                   </Grid> 


                    <Grid item xs={6} sm={6}> 
                   Modified:                     {misc.datetimeStringInUTCToLocaldatetimeString(TaskData.ModifiedDate, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)  }
                    </Grid>

                    <Grid item xs={6} sm={6}> 
                    Created: {misc.datetimeStringInUTCToLocaldatetimeString(TaskData.DateCreated, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)}
                    </Grid>
                    <Grid item xs={6} sm={6}> 
                    
                    Modified By: {TaskData.ModifiedBy}
                    </Grid> */}
            </Grid>


        </div>
    );
}

