import axios from "axios";
import { config } from '../utils/config';
import { SkbLogger,SeverityLevel } from '../services';
import * as misc from '../utils/misc';
import { crypts } from '../utils/crypts';
import { BlobServiceClient , Blob} from "@azure/storage-blob";

/*
this service needs to talk to Metis service return the data align with contractor redux store
*/
export async function  loadStockLookup(idToken){
    //not required
    return [];
}
/**
 * 
 * @param {*} idToken 
 * @param {*} representativeId 
 * @returns [ {contractor 1}, {contractor 2}, ... ]
 */

export async function loadPendingConsignment(idToken,representativeId=null){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    const apiURL=stockURL+`consignment/${representativeId}/pending`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','GET '+apiURL,{RepresentativeId:representativeId});
    const resp= await axios.get(apiURL);
    if(!resp || !resp.status || !resp.data){
        throw("NO response from API loadPendingConsignment.");
        return []
    }else if(!/^20/.test(resp.status)){
        throw("loadPendingConsignment API Error: "+resp.status+" "+resp.data.error);
        return [];
    }else{
       return resp.data;
    }
}

/**
 * 
 * @param {*} idToken 
 * @param {*} companyId 
 * @returns [ {contractor 1}, {contractor 2}, ... ]
 */
export async function  loadContractors(idToken, companyId = null){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    var apiURL = stockURL + `onhandstock`;
    if(companyId || companyId==''){
        apiURL = stockURL + `onhandstock?detail=true&company=${companyId}`;
    }
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','GET '+apiURL,{companyId:companyId});
    const resp = await axios.get(apiURL);
    if(!resp || !resp.status || !resp.data){
        throw("NO response from API loadContractors.");
        return [];
    }else if(resp.status!=200 && resp.status!=201 && resp.status!=202 && resp.status!=203 && resp.status!=204){
        throw("loadContractors API Error: "+resp.status+" "+resp.data.error);
        return [];
    }else{
        var retArray=[];
        for (let index = 0; index < resp.data.length; index++) {
            const oneCompany = resp.data[index];
            let oneContractorToBe = {};
            oneContractorToBe.Contractor=oneCompany.CompanyName;
            oneContractorToBe.Representative=oneCompany.RepName;
            oneContractorToBe.CompanyId=oneCompany.CompanyId;
            oneContractorToBe.RepresentativeId=oneCompany.RepUserId;
            oneContractorToBe.NumberOfLocations=oneCompany.LocationCount;
            oneContractorToBe.QtyGood=oneCompany.GoodQty;
            oneContractorToBe.QtyFaulty=oneCompany.FaultyQty;
            retArray.push(oneContractorToBe);
        }
        return retArray;
    }
    
}

/**
 * 
 * @param {*} idToken 
 * @param {*} companyId 
 * @returns [ {location 1}, {location 2}, ... ]
 */
export async function  loadContractorLocations(idToken, companyId){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    var apiURL = stockURL + `onhandstock?detail=true&company=${companyId}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','GET '+apiURL,{companyId:companyId});
    const resp = await axios.get(apiURL);
    if(!resp || !resp.status || !resp.data){
        throw("NO response from API loadContractorLocations.");
        return [];
    }else if(resp.status!=200 && resp.status!=201 && resp.status!=202 && resp.status!=203 && resp.status!=204){
        throw("loadContractorLocations API Error: "+resp.status+" "+resp.data.error);
        return [];
    }else{
        var retArray=[];
        //in the case of multiple companies, push location info into the same array
        for (let i = 0; i < resp.data.length; i++) {
            const oneCompany = resp.data[i];
            if(oneCompany && oneCompany.Locations){
                for (let j = 0; j < oneCompany.Locations.length; j++) {
                    const oneLocation = oneCompany.Locations[j];
                    let oneLocationToBe = {};
                    oneLocationToBe.LocationCode=oneLocation.LocationCode;
                    oneLocationToBe.Name=oneLocation.LocationDescription;
                    oneLocationToBe.Address=oneLocation.LocationAddress;
                    oneLocationToBe.QtyofGood=oneLocation.GoodQty;
                    oneLocationToBe.QtyofFaulty=oneLocation.FaultyQty;
                    retArray.push(oneLocationToBe);
                }
            }
        }
        return retArray;
    }
}

/**
 * 
 * @param {*} idToken 
 * @param {*} companyId 
 * @param {*} locationCode 
 * @returns { SerialisedItems:[{},{}, ...], NonserialisedItems:[{}, {}, ...] }
 */
export async function  loadContractorLocationStock(idToken, companyId, locationCode){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    var apiURL = stockURL + `location/${locationCode}/onhandstock?detail=true`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','GET '+apiURL,{companyId:companyId});
    const resp = await axios.get(apiURL);
    if(!resp || !resp.status || !resp.data ){
        throw("NO response from API loadContractorLocationStock.");
        return {};
    }else if(resp.status!=200 && resp.status!=201 && resp.status!=202 && resp.status!=203 && resp.status!=204){
        throw("loadContractorLocationStock API Error: "+resp.status+" "+resp.data.error);
        return {};
    }else{
        var retObj={};
        const theLocation=resp.data;
        retObj.LocationCode=theLocation.LocationCode;
        retObj.Name=theLocation.LocationDescription;
        retObj.Address=theLocation.LocationAddress;
        retObj.QtyofGood=theLocation.GoodQty;
        retObj.QtyofFaulty=theLocation.FaultyQty;
        retObj.SerialisedItems=[];
        if(theLocation.SerialisedItems){
            for (let i = 0; i < theLocation.SerialisedItems.length; i++) {
                const oneItem = theLocation.SerialisedItems[i];
                let oneItemToBe = {};
                oneItemToBe.StockCategoryCode=oneItem.StockCode;
                oneItemToBe.SerialNumber=oneItem.SerialNumber;
                oneItemToBe.Unit=oneItem.UnitType;
                oneItemToBe.Condition=oneItem.Condition;
                oneItemToBe.Description=oneItem.StockDescription;
                oneItemToBe.Client=oneItem.ClientName;
                retObj.SerialisedItems.push(oneItemToBe);
            }
        }
        retObj.NonserialisedItems=[];
        var AllNonserialisedItemsByStockCode=[];  //for easier access (by stock code) to Non-Serialised Items 
        if(theLocation.NonserialisedItems){
            //To group into QtyGood and QtyFaulty, generate stockcode list first
            for (let i = 0; i < theLocation.NonserialisedItems.length; i++) {
                const oneItem = theLocation.NonserialisedItems[i];
                //find NonserialisedItems
                var isAdded = false;
                for (let j = 0; j < retObj.NonserialisedItems.length; j++) {
                    const oneAddedItem = retObj.NonserialisedItems[j];
                    if(oneAddedItem.StockCategoryCode==oneItem.StockCode){
                        isAdded=true;
                        break;
                    }
                }
                //if added, skip (so that we can merge QtyGood and QtyFaulty together)
                if(isAdded){
                    continue;
                }else{
                    //only adding when not added
                    let oneItemToBe = {};
                    oneItemToBe.StockCategoryCode=oneItem.StockCode;
                    oneItemToBe.Unit=oneItem.UnitType;
                    oneItemToBe.Condition=oneItem.Condition;
                    oneItemToBe.Description=oneItem.StockDescription;
                    oneItemToBe.Client=oneItem.ClientName;
                    oneItemToBe.QtyGood=0;
                    oneItemToBe.QtyFaulty=0;
                    AllNonserialisedItemsByStockCode[oneItem.StockCode]=oneItemToBe;
                    retObj.NonserialisedItems.push(oneItemToBe);
                }
            }
            //another loop to count QtyGood and QtyFaulty
            for (let i = 0; i < theLocation.NonserialisedItems.length; i++) {
                const oneItem = theLocation.NonserialisedItems[i];
                if(oneItem.Condition && oneItem.Condition.toUpperCase()=='GOOD'){
                    AllNonserialisedItemsByStockCode[oneItem.StockCode].QtyGood
                            = AllNonserialisedItemsByStockCode[oneItem.StockCode].QtyGood + oneItem.Qty;
                }else{
                    AllNonserialisedItemsByStockCode[oneItem.StockCode].QtyFaulty
                            = AllNonserialisedItemsByStockCode[oneItem.StockCode].QtyFaulty + oneItem.Qty;
                }
            }
        }

        return retObj;
    }
}

/**
 * 
 * @param {*} idToken 
 * @param {*} consignmentData one element of ReturnConsignmentsCreation as per design doc
 *                                  {CompanyId, 
 *                                   Consignments:[ { Type, FromLocationCode, ToLocationCode, ReturnMethod, ConsignmentType, ConsignmentStatus  } ]}
 */
export async function  submitConsignment(idToken, consignmentData){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    if(!consignmentData){
        throw("consignmentData is required");
    }
    else if(!consignmentData.CompanyId){
        throw("consignmentData.CompanyId is required");
    }
    else if(!consignmentData.Consignments || consignmentData.Consignments.length<=0){
        throw("consignmentData.Consignments is required");
    }
    else if(consignmentData.Consignments.length>1){
        throw("Multiple consignmentData.Consignments is not supported yet.");
    }
    // //???: why we need location code here
    // else if(!consignmentData.Consignments[0] || !consignmentData.Consignments[0].LocationCode){
    //     throw("consignmentData.Consignments[0].LocationCode is required.");
    // }
    else if(!consignmentData.Consignments[0].FromLocationCode){
        throw("consignmentData.Consignments[0].FromLocationCode is required.");
    }else if(!consignmentData.Consignments[0].ToLocationCode){
        throw("consignmentData.Consignments[0].ToLocationCode is required.");
    }

    var locationCode = `${consignmentData.Consignments[0].FromLocationCode}-${misc.newUUID().substring(0, 12)}`;

    //consignmentData.Consignments[0].LocationCode = locationCode;
    var apiURL = stockURL + `consignment/${locationCode}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    
    //construct new data
    //const inputData = consignmentData.Consignments[0];
    var dataToPost={};
    dataToPost.CompanyId=consignmentData.CompanyId;
    dataToPost.Status=consignmentData.Status;
    dataToPost.CompleteDateTime=consignmentData.CompleteDateTime;
    var oneConsignment = {};
    oneConsignment.LocationCode = locationCode;
    oneConsignment.Type=consignmentData.Consignments[0].Type;
    oneConsignment.Name=consignmentData.Consignments[0].Name;
    oneConsignment.FromLocationCode=consignmentData.Consignments[0].FromLocationCode;
    oneConsignment.ToLocationCode=consignmentData.Consignments[0].ToLocationCode;
    oneConsignment.ConnotNumber=consignmentData.Consignments[0].ConsignmentNumber;
    oneConsignment.ConsignmentMethod=consignmentData.Consignments[0].ReturnMethod;
    oneConsignment.ConsignmentType=consignmentData.Consignments[0].ConsignmentType;
    oneConsignment.ConsignmentStatus=consignmentData.Consignments[0].ConsignmentStatus;
    oneConsignment.SpecialDeliveryInstruction=consignmentData.Consignments[0].SpecialDeliveryInstruction;
    oneConsignment.PlannedReturnDate=consignmentData.Consignments[0].PlannedReturnedByDate;
    oneConsignment.PlannedReturnPickupDate=consignmentData.Consignments[0].PlannedPickedUpByDate;
    oneConsignment.ATL=consignmentData.Consignments[0].ATL;
    oneConsignment.ATP=consignmentData.Consignments[0].ATP;
    oneConsignment.TransferredDate=consignmentData.Consignments[0].TransferredDate;
    oneConsignment.WarehouseExportDate=consignmentData.Consignments[0].WarehouseExportDate;
    dataToPost.Consignments=[oneConsignment];
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','POST '+apiURL,dataToPost);
    //
    const resp = await axios.post(apiURL, dataToPost);
    return resp;
}

/**
 * 
 * @param {*} idToken 
 * @returns [ {warehouse 1}, {warehouse 2}, ... ]
 */
export async function loadWarehouseLocations(idToken){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    var apiURL = stockURL + `warehouse`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','GET '+apiURL,{});
    const resp = await axios.get(apiURL);
    if(!resp || !resp.status || !resp.data){
        throw("NO response from API loadWarehouseLocations.");
        return [];
    }else if(resp.status!=200 && resp.status!=201 && resp.status!=202 && resp.status!=203 && resp.status!=204){
        throw("loadWarehouseLocations API Error: "+resp.status+" "+resp.data.error);
        return [];
    }else{
        var retArray=[];
        
        for (let j = 0; j < resp.data.length; j++) {
            const oneLocation = resp.data[j];
            let oneLocationToBe = {};
            oneLocationToBe.LocationCode=oneLocation.LocationCode;
            oneLocationToBe.Name=oneLocation.Name;
            oneLocationToBe.Address=oneLocation.Address;
            retArray.push(oneLocationToBe);
        }
        return retArray;
    }
}

var crcTableForPhotoSync = [];

function makeCRCTableForPhotoSync(){
    var c;
    for(var n =0; n < 256; n++){
        c = n;
        for(var k =0; k < 8; k++){
            c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTableForPhotoSync[n] = c;
    }
    return crcTableForPhotoSync;
}

function crc32ForPhotoSync(str) {
    var crcTableForPhotoSync = crcTableForPhotoSync || (crcTableForPhotoSync = makeCRCTableForPhotoSync());
    var crc = 0 ^ (-1);

    for (var i = 0; i < str.length; i++ ) {
        crc = (crc >>> 8) ^ crcTableForPhotoSync[(crc ^ str.charCodeAt(i)) & 0xFF];
    }

    return (crc ^ (-1)) >>> 0;
};

/**
 * 
 * @param {*} consignmentLocationCode 
 * @param {*} base64Content 
 * @param {*} contentType 
 * @returns LabelFilePath (without base url or token)
 */
export async function uploadOneConsignmentLabelFileToBlob(consignmentLocationCode, base64Content, contentType='pdf'){
    try{
        const sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
        SkbLogger.applicationTraceSub('Stock Service',SeverityLevel.Error,'Upload File','uploadOneConsignmentLabelFileToBlob',
                                        '{Consignment} file is being uploaded',
                                        {Consignment:consignmentLocationCode,apiResponse:uploadBlobResponse});

        var blobServiceClient = new BlobServiceClient(
            `https://${process.env.REACT_APP_AZURE_STORAGE_NAME}.blob.core.windows.net${sas_azureStorageKey}`
            ); 
            var byteCharacters = atob(base64Content);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const fileName=crc32ForPhotoSync(base64Content);
            var newBlobName = `consignment/${consignmentLocationCode}/${fileName}.${contentType}`;
            //console.log('blob file name from CRC32',newBlobName);

            var containerClient = blobServiceClient.getContainerClient(`${process.env.REACT_APP_AZURE_CONTAINER_NAME}`);
            if(!containerClient){
                return '';
            }
            var blockBlobClient = containerClient.getBlockBlobClient(newBlobName);
            var byteArray = new Uint8Array(byteNumbers);

            
            var uploadBlobResponse = await blockBlobClient.upload(byteArray, byteArray.length);
            //console.log('bobl upload response ',uploadBlobResponse._response.status);

            if(uploadBlobResponse._response.status ==201){
                return newBlobName;
                
            }else{
                SkbLogger.applicationTraceSub('Stock Service',SeverityLevel.Error,'Upload File','uploadOneConsignmentLabelFileToBlob',
                                        '{Consignment} {fileName} uploading failed. Blob response:{apiResponse}',
                                        {Consignment:consignmentLocationCode,fileName:newBlobName,apiResponse:uploadBlobResponse});
                return '';
            }
    
        
    }catch(error) {
        SkbLogger.applicationTraceSub('Stock Service',SeverityLevel.Error,'Upload File','uploadOneConsignmentLabelFileToBlob',
                                        '{Consignment} file uploading failed with {error}',
                                        {Consignment:consignmentLocationCode,error:error});
        return '';

    }
    
}


/**
 * 
 * @param {*} idToken 
 * @param {*} consignmentLocationCode 
 * @returns Consignment Basic Info, Locations (From/To), Stock Summary(SerialisedItems, NonserialisedItems), Packages and Labels 
 *              Labels element contains LabelFileUrlWithToken property to access blob
 * 
 * 
 */
 export async function  loadOneConsignmentInfo(idToken, consignmentLocationCode){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    var apiURL = stockURL + `consignment/${consignmentLocationCode}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    SkbLogger.applicationTrace('Stock Service',SeverityLevel.Verbose,'Call API','GET '+apiURL,{consignmentLocationCode:consignmentLocationCode});
    const resp = await axios.get(apiURL);
    if(!resp || !resp.status || !resp.data ){
        throw("NO response from API loadOneConsignmentInfo.");
        return {};
    }else if(resp.status!=200 && resp.status!=201 && resp.status!=202 && resp.status!=203 && resp.status!=204){
        throw("loadOneConsignmentInfo API Error: "+resp.status+" "+resp.data.error);
        return {};
    }else{
        var retObj={};
        const theConsignment=resp.data;
        retObj.ConsignmentLocationCode=theConsignment.ConsignmentLocationCode;
        retObj.Name=theConsignment.Name;
        retObj.ConnoteNumber=theConsignment.ConnoteNumber;
        retObj.ATL=theConsignment.ATL;
        retObj.ATP=theConsignment.ATP;
        retObj.SpecialDeliveryInstruction=theConsignment.SpecialDeliveryInstruction;
        retObj.ForkliftRequired=theConsignment.ForkliftRequired;
        retObj.PlannedReturnDate=theConsignment.PlannedReturnDate;
        retObj.DateCreated=theConsignment.DateCreated;
        retObj.DateModified=theConsignment.DateModified;
        retObj.ConsignmentType=theConsignment.ConsignmentType;
        retObj.ConsignmentMethod=theConsignment.ConsignmentMethod;
        retObj.Type=theConsignment.Type;
        if(theConsignment.CompanySubTask){
            retObj.CompanySubTask={};
            retObj.CompanySubTask.Id=theConsignment.CompanySubTask.Id;
            retObj.CompanySubTask.Status=theConsignment.CompanySubTask.Status;
        }
        if(theConsignment.CompanyTask){
            retObj.CompanyTask={};
            retObj.CompanyTask.Id=theConsignment.CompanyTask.Id;
            retObj.CompanyTask.Status=theConsignment.CompanyTask.Status;
        }
        if(theConsignment.CreatedByUser){
            retObj.CreatedByUser={};
            retObj.CreatedByUser.Id=theConsignment.CreatedByUser.Id;
            retObj.CreatedByUser.Name=theConsignment.CreatedByUser.Name;
        }
        if(theConsignment.FromLocation){
            retObj.FromLocation={};
            retObj.FromLocation.LocationCode=theConsignment.FromLocation.LocationCode;
            retObj.FromLocation.Name=theConsignment.FromLocation.Name;
            retObj.FromLocation.AddressLine1=theConsignment.FromLocation.AddressLine1;
            retObj.FromLocation.Suburb=theConsignment.FromLocation.Suburb;
            retObj.FromLocation.State=theConsignment.FromLocation.State;
            retObj.FromLocation.Postcode=theConsignment.FromLocation.Postcode;
        }
        if(theConsignment.ToLocation){
            retObj.ToLocation={};
            retObj.ToLocation.LocationCode=theConsignment.ToLocation.LocationCode;
            retObj.ToLocation.Name=theConsignment.ToLocation.Name;
            retObj.ToLocation.AddressLine1=theConsignment.ToLocation.AddressLine1;
            retObj.ToLocation.Suburb=theConsignment.ToLocation.Suburb;
            retObj.ToLocation.State=theConsignment.ToLocation.State;
            retObj.ToLocation.Postcode=theConsignment.ToLocation.Postcode;
        }
        if(theConsignment.Company){
            retObj.Company={};
            retObj.Company.Id=theConsignment.Company.Id;
            retObj.Company.ABN=theConsignment.Company.ABN;
            retObj.Company.CreditorCode=theConsignment.Company.CreditorCode;
            retObj.Company.Name=theConsignment.Company.Name;
            retObj.Company.RepresentativeId=theConsignment.Company.RepresentativeId;
            retObj.Company.Representative=theConsignment.Company.Representative;
            retObj.Company.ContractorRepresentativePhone=theConsignment.Company.ContractorRepresentativePhone;
            retObj.Company.RepresentativeEmail=theConsignment.Company.RepresentativeEmail;
        }
        retObj.SerialisedItems=[];
        if(theConsignment.SerialisedItems){
            for (let i = 0; i < theConsignment.SerialisedItems.length; i++) {
                const oneItem = theConsignment.SerialisedItems[i];
                let oneItemToBe = {};
                oneItemToBe.StockCategoryCode=oneItem.StockCategoryCode;
                oneItemToBe.Description=oneItem.StockDescription;
                oneItemToBe.Unit=oneItem.UnitOfMeasurement;
                oneItemToBe.Condition=oneItem.Condition;
                oneItemToBe.QtyGood=oneItem.QtyGood;
                oneItemToBe.QtyFaulty=oneItem.QtyFaulty
                oneItemToBe.Client=oneItem.ClientName;
                oneItemToBe.CurrentQtyGoodAtFromLocation=oneItem.CurrentQtyGoodAtFromLocation;
                oneItemToBe.CurrentQtyFaultyAtFromLocation=oneItem.CurrentQtyFaultyAtFromLocation;
                retObj.SerialisedItems.push(oneItemToBe);
            }
        }
        retObj.NonserialisedItems=[];
        if(theConsignment.NonserialisedItems){
            for (let i = 0; i < theConsignment.NonserialisedItems.length; i++) {
                const oneItem = theConsignment.NonserialisedItems[i];
                let oneItemToBe = {};
                oneItemToBe.StockCategoryCode=oneItem.StockCategoryCode;
                oneItemToBe.Description=oneItem.StockDescription;
                oneItemToBe.Unit=oneItem.UnitOfMeasurement;
                oneItemToBe.Condition=oneItem.Condition;
                oneItemToBe.QtyGood=oneItem.QtyGood;
                oneItemToBe.QtyFaulty=oneItem.QtyFaulty
                oneItemToBe.Client=oneItem.ClientName;
                oneItemToBe.CurrentQtyGoodAtFromLocation=oneItem.CurrentQtyGoodAtFromLocation;
                oneItemToBe.CurrentQtyFaultyAtFromLocation=oneItem.CurrentQtyFaultyAtFromLocation;
                retObj.NonserialisedItems.push(oneItemToBe);
            }
        }
        retObj.Packages=[];
        if(theConsignment.Packages){
            for (let i = 0; i < theConsignment.Packages.length; i++) {
                const oneItem = theConsignment.Packages[i];
                let oneItemToBe = {};
                oneItemToBe.PackageType=oneItem.PackageType;
                oneItemToBe.Id=oneItem.Id;
                oneItemToBe.Qty=oneItem.Qty;
                oneItemToBe.Length=oneItem.LengthCm;
                oneItemToBe.Width=oneItem.WidthCm;
                oneItemToBe.Height=oneItem.HeightCm
                oneItemToBe.Weight=oneItem.WeightKg;
                retObj.Packages.push(oneItemToBe);
            }
        }
        retObj.Labels=[];
        if(theConsignment.Labels){
            
            for (let i = 0; i < theConsignment.Labels.length; i++) {
                const oneItem = theConsignment.Labels[i];
                let oneItemToBe = {};
                oneItemToBe.ConsignmentPackageId=oneItem.ConsignmentPackageId;
                oneItemToBe.LabelName=oneItem.LabelName;
                oneItemToBe.LabelFilePath=oneItem.LabelFilePath;
                oneItemToBe.LabelFileUrlWithToken=misc.constructBlobUrl(oneItem.LabelFilePath);
                retObj.Labels.push(oneItemToBe);
            }
        }

        return retObj;
    }
}


/**
 * 
 * @param {*} idToken 
 * @param {* consignmentLocationCode 
 * @param {Array} LabelFileList array as [{LabelName:"",LabelFilePath:""}]
 *                          LabelFilePath is from uploadOneConsignmentLabelFileToBlob()
 *                          optional property ConsignmentPackageId is also accepted
 */
export async function  overwriteLabelFileListForConsignment(idToken, consignmentLocationCode, LabelFileList){
    const stockURL = process.env.REACT_APP_API_URL + `stock/`;
    if(!LabelFileList){
        throw("LabelFileList is required");
    }
    else if(!Array.isArray(LabelFileList)){
        throw("LabelFileList needs to be an array.");
    }

    var apiURL = stockURL + `consignment/${consignmentLocationCode}/label`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=config.REACT_APP_API_SECRET;
    
    const resp = await axios.put(apiURL, LabelFileList);
    if(!resp || !resp.data){
        throw(`No return from consignment label API.`);
    }else if(resp && resp.status && resp.status!==200 && resp.status!==201){
        throw('Error Status from consignment label API: '+resp.status);
    }else if(resp && resp.data && resp.data.error){
        throw('Error from consignment label API: '+resp.data.error);
    }
    return resp;
}