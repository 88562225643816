import axios from "axios";

import {crypts} from '../../utils/crypts';
import { config } from '../../utils/config';

function service() {

    // task info api
    const getTaskConsignmentInfo = async (param) =>{
  
        try {

                //console.log("********getTaskConsignmentInfo====>>",param);
                const paramObj = param && param.paramObj!==undefined?param.paramObj:{};
                const locationCode = (paramObj && paramObj.locationCode!==undefined)?paramObj.locationCode:"";
                const apiEndPoint =  `${process.env.REACT_APP_API_URL}stock/consignment/${locationCode}`;

                const idToken = crypts.decrypt(localStorage.getItem('id_token'));
                const authorization = config.REACT_APP_API_SECRET;
                const headerOptions = {
                    "content-type": "application/json",
                    "authorization": authorization,  
                    "X-CustomHeader": idToken
                };                
                

                const resp =   axios({
                    method: 'GET', //you can set what request you want to be
                    url: apiEndPoint,
                    data: null,
                    headers: headerOptions
                });
                return resp;
            }catch (error) {
            console.error(error);
            }
    }; 

  
    return {getTaskConsignmentInfo};
  
  }
  
  export const getTaskConsignmentInfoService = service(); 
