import React, { useEffect,useState, Suspense } from 'react';
import {connect,useDispatch, useSelector} from 'react-redux';
import {SkbSelect} from '../../../../skb_controls/components/SkbSelect';  
import SkbTable from '../../../../skb_controls/components/SkbTable';  
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import SkbButton from '../../../../skb_controls/components/SkbButton';

import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';


import Link from '@material-ui/core/Link';
// import { Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';

import Loading from '../../../Loading';

import {useSnackbar } from 'notistack';

import * as  returnsDashboardListActions from '../../../../Redux/actions/returnsdashboard';

import {getReturnDashboardUtility} from  "./returnDashboardUtility";

const classes = {
  root: {
    flexGrow:1
  },
  paper: {
    padding:20,
    textAlign: "center",
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);




const  ReturnsDashBoardListCancelPopup = React.forwardRef (({canceleligiblerowdata, allselectedrowswithbotheligibleandnoneligiblestatus},refObj) =>  {
  const [open, setOpen] = React.useState(false);
  const [cancelRelatedRowDataForPopup, setCancelRelatedRowDataForPopup] = React.useState([]);
 

  //console.log(" HEYY=>>dataRow=>>>"," ==canceleligiblerowdata==>>",canceleligiblerowdata);  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar(); 

  const getCancelReasonOptionsDisplayData = (resp) => {

    if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
      let carrierNameDrpDwnArr = [{"value":"SELECTAREASON","text":"Select a reason"}];
      resp.map((e,i)=>{
        carrierNameDrpDwnArr.push({"value":e.variableName,"text":e.variableName});
      });

      return carrierNameDrpDwnArr;


    }

    return [];
  }

  const {cancelReasonOptionList , cancelReasonOptionListLoadStatus} = useSelector((state) => ({
    //return state.returnDashListCancelReasonOptions;
    "cancelReasonOptionList": (state.returnDashListCancelReasonOptions &&  state.returnDashListCancelReasonOptions.cancelReasonOptionList)? getCancelReasonOptionsDisplayData(state.returnDashListCancelReasonOptions.cancelReasonOptionList):[],
     "cancelReasonOptionListLoadStatus": (state.returnDashListCancelReasonOptions &&  state.returnDashListCancelReasonOptions.cancelReasonOptionListLoadStatus)? state.returnDashListCancelReasonOptions.cancelReasonOptionListLoadStatus:false,
    
  }));

  const {cancelApiSuccessReceiveStatus , cancelTaskSubmitLoaderStatus} = useSelector((state) => ({
    //return state.returnsDashboardList;    
     "cancelApiSuccessReceiveStatus": (state.returnsDashboardList &&  state.returnsDashboardList.cancelApiSuccessReceiveStatus)? state.returnsDashboardList.cancelApiSuccessReceiveStatus:false,
    "cancelTaskSubmitLoaderStatus": (state.returnsDashboardList &&  state.returnsDashboardList.cancelTaskSubmitLoaderStatus)? state.returnsDashboardList.cancelTaskSubmitLoaderStatus:false
    
  }));

  
 
  const handleClickOpen = () => {

    setOpen(true);
     
      
    };
  const handleClose = () => {
      setOpen(false);
    };

  const dialogOnEnterFunc = async () =>{
      // if needed     
  }

  const dialogOnEnteredFunc = async () =>{
   
    if(canceleligiblerowdata){
      setCancelRelatedRowDataForPopup([...canceleligiblerowdata]);
    }

    dispatch(returnsDashboardListActions.callAsyncFuncToFetchCancelReasonOptionsList());


  } 

  const dialogOnExitedTrackFunc = () =>{
    // unset list data
    setCancelRelatedRowDataForPopup([]);      
  }

  const constructDataToCancel =  (rows) =>{       
        
    let dataToCancel = [];    

    if(rows!==undefined && Array.isArray(rows) && rows.length>0){
        rows.map((e,i)=>{

            dataToCancel.push(
                {
                    "LocationCode":e.locationCode,
                    "ConsignmentStatus":"Cancelled",
                    "ActivityType":"Cancel",
                    "CancellationReason" : e.cancellationReason                     
                
                }
                
                );          

        });

    }

    return ({"Consignments":dataToCancel});


}

  const confirmClick = (evt) => {
    evt.preventDefault();
   
    if(Array.isArray(cancelRelatedRowDataForPopup) && cancelRelatedRowDataForPopup.length>0){
     let chkAnyEmptyPresence =  getReturnDashboardUtility.findFirstMatchedKeyValueRowFromArrObj(cancelRelatedRowDataForPopup,"cancellationReason","");
     //console.log("-- chkAnyEmptyPresence--->",chkAnyEmptyPresence);
     if(chkAnyEmptyPresence){
      enqueueSnackbar(`Please provide cancellation reason for all tasks.`, { variant: 'warning',onClose:()=>{

      }, autoHideDuration: 2500 });  

      return false;

     }
     else{
       // execuute submit api script

       let paramToPassToApi =  constructDataToCancel(cancelRelatedRowDataForPopup);
       let bodyParamCancelApi = {"bodyParam":paramToPassToApi}
       //console.log("--HEYYY paramToPassToApi->>>",bodyParamCancelApi);
       dispatch(returnsDashboardListActions.callAsyncFuncToCancelEligibleTasks(bodyParamCancelApi));
     }

    }
  }

  const getUpdateStatusOfSomeRowAfterStatusChange = (originalListData=[],rowToUpdt, cancellationReason="") => {
    
    let updatedRowList = [];
    if(Array.isArray(originalListData) && originalListData.length>0){

      originalListData.map((ee,ii)=>{

        if(rowToUpdt.locationCode === ee.locationCode){
          let rowToChange = {...ee};
          rowToChange.cancellationReason = cancellationReason;
          updatedRowList.push(rowToChange);

        }
        else{
          updatedRowList.push(ee);
        }

        
      });


    }


    return updatedRowList;  

  }

  const reasonSelected = (selectedOptn, rowDataObj) => {
    // update the cancel eligible row with reason data and get the uupdated list  
    let updtdList =  getUpdateStatusOfSomeRowAfterStatusChange(cancelRelatedRowDataForPopup,rowDataObj,(selectedOptn==="SELECTAREASON"?"":selectedOptn));
    if(Array.isArray(updtdList) && updtdList.length>0){
      // now update the list
      setCancelRelatedRowDataForPopup(updtdList);
    }  

  }

  let taskInfoItemsToAddColumns = [     
        {title: 'Location Code', field: 'locationCode', type: 'hidden-id' }, //column with hidden-id will not display
        {title:"Task Id", field:"taskId", type: 'read-only-long-text' },
        {title:"Contracting Entity", field:"contractingEntity", type: 'read-only-long-text'},
        {title:"Contractor Representative", field:"contractorRepresentative", type: 'read-only-long-text'},
        {title:"Location", field:"stockLocation", type: 'read-only-long-text'},
        {title:"Reason of cancellation", field:"reason", type: 'custom',render: rowData => (
          <SkbSelect  Title=""  id={`can-drop-down-${rowData.taskId}`} value={'SELECTAREASON'} items={cancelReasonOptionList} valueChanged={(e)=>{reasonSelected(e,rowData)}} />
          )},
         
         
  ] ;
  
 

  const taskInfoItemsToAddFilterConfig =  {visible:false,
    individualFiltering:true, 
    individualFilteringSettings:[
        
        {column:"taskId",filteringType:"disabled"},
        {column:"contractingEntity",filteringType:"disabled"},
        {column:"contractorRepresentative",filteringType:"disabled"},
        {column:"stockLocation",filteringType:"disabled"},
        {column:"reason",filteringType:"disabled"},
    ]
      };
    

  const showSuccessMessage = () => {
    handleClose();

    enqueueSnackbar(`Successfully cancelled ${cancelRelatedRowDataForPopup.length} return stock tasks.`, { variant: 'success',onClose:()=>{       
    }, autoHideDuration: 3500 });  

    if(allselectedrowswithbotheligibleandnoneligiblestatus.length!==cancelRelatedRowDataForPopup.length){
      enqueueSnackbar(`Please note that 1 or more tasks were not cancelled as they are not in the correct state to be cancelled.`, { variant: 'success',onClose:()=>{       
      }, autoHideDuration: 3500 });  

    }       
    

  }

  

  useEffect(()=>{

      if(cancelApiSuccessReceiveStatus){
            showSuccessMessage();

      }
  
    return (()=>{
     

  });
},[cancelApiSuccessReceiveStatus]); 



  return (
    <div>    

      <Link style={{display:'none'}} ref={refObj} id={`btn-for-retdashbrdcancelbtnpopup`}   onClick={handleClickOpen}>Cancel</Link>
      <Dialog fullWidth={true} maxWidth={"md"} onEnter={dialogOnEnterFunc}  onEntered={dialogOnEnteredFunc} onExited={dialogOnExitedTrackFunc} aria-labelledby="customized-dialog-title" open={open}>
        {open && (<> 
          <DialogTitle id="customized-dialog-title" onClose={handleClose}  >
        {/* <SkbIcon  icon={DashboardIcon}/>  */} 
        <Box  textAlign="center" > Task Cancellation  </Box>
        </DialogTitle>
        <DialogContent dividers={true} >
         
          <div className={classes.root}  >   
             <Box mt={2} textAlign="left"  > 
              {`Confirm your cancellation task by selecting a cancellation reason.`} 
             </Box>         

            <Box mt={2}  >
                { (cancelReasonOptionListLoadStatus || cancelTaskSubmitLoaderStatus) && (<Loading />) }
                <Paper className={classes.paper} variant="outlined" >
                  {cancelRelatedRowDataForPopup.length===0 && (

                        <SkbTable 
                        id={"retdashlistrowforcancelid"}
                        columns={taskInfoItemsToAddColumns} 
                        dataSource={cancelRelatedRowDataForPopup} 
                         
                        filter={taskInfoItemsToAddFilterConfig} 
                        options={{
                          emptyDataSourceMessage:'There are no items to cancel!',                                          

                        }
                        }



                        />
                  ) }

                   {cancelRelatedRowDataForPopup.length>0 && (

                        <SkbTable 
                        id={"retdashlistrowforcancelid"}
                        columns={taskInfoItemsToAddColumns} 
                        dataSource={cancelRelatedRowDataForPopup} 
                       
                        filter={taskInfoItemsToAddFilterConfig} 
                        options={{
                          emptyDataSourceMessage:'There are no items to cancel!',                                          

                        }
                        }



                        />
                  ) }
                
                 
                </Paper>
                            
            </Box>

                
          
          </div>
         
        </DialogContent>
        <DialogActions>  

          {cancelRelatedRowDataForPopup && cancelRelatedRowDataForPopup.length>0 && (
                <>                
                  <Box  textAlign="right" >
                    <SkbButton id="task-info-schedule" primary size="medium" disabled={cancelTaskSubmitLoaderStatus} text="Confirm" onClick={confirmClick}  />
                  </Box>
                </>

          )}           
                            
         
        </DialogActions>
     
        
        
        </>


        )}
       
      </Dialog>
     

    </div>
  );
}
);

export default ReturnsDashBoardListCancelPopup;