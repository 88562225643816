import * as dataSync from '../../services/dataSaverAndTracker';
import * as changesSync from '../../services/changesSync';
// import { SeverityLevel } from '@microsoft/applicationinsights-web';

import PropTypes from 'prop-types';
import { SkbLogger, ApiService, SeverityLevel } from '../../services';
import _ from 'lodash';
import * as misc from '../../utils/misc';
import { crypts } from '../../utils/crypts';
const taskConfig = require('../../components/modules/tasks_components/taskConfig.json');

/**
 * Actions define all action types used in task module
 */
export const Actions = {
    TASK_REFRESHED: "TASK_REFRESHED",
    TASKSTATUS_UPDATE: "TASKSTATUS_UPDATE",
    ADD_SN_ITEM: "ADD_SN_ITEM",
    UPDATE_SN_ITEM: "UPDATE_SN_ITEM",
    DEL_SN_ITEM: "DEL_SN_ITEM",
    ADD_NONSN_ITEM: "ADD_NONSN_ITEM",
    UPDATE_NONSN_ITEM: "UPDATE_NONSN_ITEM",
    DEL_NONSN_ITEM: "DEL_NONSN_ITEM",
    UPDATE_SIGNOFF_SIG: "UPDATE_SIGNOFF_SIG",
    REQUEST_SENT: "REQUEST_SENT",
    REQUEST_RESPONDED: "REQUEST_RESPONDED",
    STEP_UPDATE: "STEP_UPDATE",
    TASK_CHANGE: "TASK_CHANGE",
    SUBTASK_CHANGE: "SUBTASK_CHANGE",
    UPDATE_ERROR: "UPDATE_ERROR",
    LOCKEDBY_UPDATE: "LOCKEDBY_UPDATE",
    STEP_CHANGE: "STEP_CHANGE",
    SHOW_TASKDETAILS: "SHOW_TASKDETAILS",
    UDPATE_LASTSELECTEDSTOCKCODE: "UDPATE_LASTSELECTEDSTOCKCODE",
    CURRENTTASK_STATUS_REFRESHEDFROMSYNC: "CURRENTTASK_STATUS_REFRESHEDFROMSYNC",
    STOCKTAKESTATUS_UPDATE: "STOCKTAKESTATUS_UPDATE",
    SUBTASK_REOPEN: "SUBTASK_REOPEN",

    SUBTASK_STEPS_LOADED: "SUBTASK_STEPS_LOADED",
    SUBTASK_PREVIEW_LOADED: "SUBTASK_PREVIEW_LOADED",
    SUBTASK_SNITEMS_LOADED: "SUBTASK_SNITEMS_LOADED",
    SUBTASK_NOSNITEMS_LOADED: "SUBTASK_NOSNITEMS_LOADED",
    SUBTASK_SIG_LOADED: "SUBTASK_SIG_LOADED",
    SUBTASK_AVAILABLESN_LOADED: "SUBTASK_AVAILABLESN_LOADED",
    SUBTASK_CURRENTSTEP_LOADED: "SUBTASK_CURRENTSTEP_LOADED",

    TASK_STATUSCOMPLETIONDATERESOURCEID_UPDATE: "TASK_STATUSCOMPLETIONDATERESOURCEID_UPDATE",

}

/**
 * Status: define possible status for task status and step status
 */
export const Status = {
    NotStarted: "Not Started",
    InProgress: "In Progress",
    Done: "Done",
    Completed: "Completed",
    Cancelled: "Cancelled",
}

export const DataType = {
    METADATA: "METADATA",
    PHOTO: "PHOTO"
}
/**
 * ModuleInfo: define task module infor used for log
 */
export const ModuleInfo = {
    moduleName: "MinervaTask",
    category: "Task Store Actions"

}

//generic information for sn item in sync table
export const sngeneric = {
    key: "SN",
    type: DataType.METADATA,
    description: "serialised Item"
}

//generic information for nonsn item in sync table
export const nonsngeneric = {
    key: "NonSN",
    type: DataType.METADATA,
    description: "Non-serialised Item"
}

/** this function is used in dispatch funciton, the getState is redux getState along with dispatch
 * it will more accurate to get redux store instead of getting it from UI component due to the delay
 * defaultState will be used in case getState doesn't include tasks store
 */
function getTaskStateWhenAvailable(getState, defaultState) {
    try {
        return getState().tasks || defaultState;
    } catch (error) {
        return defaultState;
    }
}
function getSeconLevelArrayKeyByExistingOne(arraryKey) {
    return (arraryKey || '').split(',')[0] + ',1';
}

function processSnWithPhoto(snPhotoItems, idx, snMetaData) {
    //it's format string, i.e. EROD001|Outdoor Unit (ODU)|133434325|Faulty|-37.23|125.134
    let sn = snMetaData.value.split('|');
    //get meta data
    let snData = {
        StockCode: sn[0],

        Description: sn[1],
        SerialNumber: sn[2],
        ItemStatus: sn[3],
        Latitude: sn[4],
        Longitude: sn[5],
        sequenceId: idx + 1,
        arrayIndex: snMetaData.arrayIndex,
        resourceID: snMetaData.resourceID,
        key: snMetaData.key,
        type: snMetaData.type,
        description: snMetaData.description,
        PhotoResourceId: null,
        Photo: null,
    };

    //get photo data 
    //get meta data array index, i.e. 1,0, so the phot item index expect 1,1
    if (snMetaData.arrayIndex) {

        let photoArrayIndex = getSeconLevelArrayKeyByExistingOne(snMetaData.arrayIndex);
        let metadataTimestamp = new Date(snMetaData.timestamp).getTime();
        // there are possible duplicate arrayindex if more than one users are working on same location task. the photo data shall be the one has least timestamp gap with metadata 
        let photos = snPhotoItems.filter(ph => ph.type === DataType.PHOTO && ph.arrayIndex === photoArrayIndex).sort((f, n) => {
            try {
                let fTimestampVariance = (new Date(f.timestamp).getTime()) - metadataTimestamp;
                let nTimestampVariance = (new Date(n.timestamp).getTime()) - metadataTimestamp;
                if (fTimestampVariance < 0 && nTimestampVariance > 0) return 1; // the photo timestamp shall be alwasy great than metadata

                if (fTimestampVariance < nTimestampVariance) return -1;
                else if (fTimestampVariance > nTimestampVariance) return 1;
                else return 0
            } catch (error) {
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "processSnWithPhoto->sort sn photo items", error);
                return 0;
            }

        });

        if (photos.length > 0) {
            let photo = photos[0];
            let pData = null;
            //SkbLogger.logDebugInfo('photo', photo.localArtefactContent);

            try {
                //pData = crypts.decrypt(photo.localArtefactContent); //sync module is responsible for decrypt
                pData = photo.localArtefactContent;
            } catch (error) {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "processSnWithPhoto - descrypt photo localArtefactContent failed", photo);

            }
            snData.PhotoResourceId = photo.resourceID;
            snData.Photo = pData ? true : false;
        }
    }
    return snData;

}

/**
 * @function getCurrentMaxArrayIndex get max of first demension of arrayIndex in a array, the each of object in array shall contain arrayIndex property, the value looks loke 1,1( separate by comma ','), the first part is index of first demension
 * @param {Array} items 
 */
function getCurrentMaxArrayIndex(items) {
    if (!Array.isArray(items) || items.length === 0) return 0;
    let firstArrayIndex = items.map(i => {
        if (!i.arrayIndex) return 0;
        return parseInt((i.arrayIndex || "0").split(',')[0]) || 0;
    });
    return Math.max(...firstArrayIndex);
}

/**
 * @function getNextAvailableArrayIndex loop through array, return max of first demension of array index + 1
 * @param {Array} items
 */
function getNextAvailableArrayIndex(items) {
    return getCurrentMaxArrayIndex(items) + 1;
}

/** @function loadTaskStoreDataFromSync load task data from sync module and normalise  store task 
 * @param {boolean} loadDataFromServer when true, it will trigger sync module to refresh data from server when network is available 
*/
//var _loadFromServer = true;
var _timer = null;
async function loadTaskStoreDataFromSync(loadDataFromServer, initialLoad,syncIsOn=false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync: parameters", {loadDataFromServer:loadDataFromServer,initialLoad:initialLoad,syncIsOn:syncIsOn});
                
  /*   if (loadDataFromServer && !initialLoad) {
        //loadDataFromServer has been set to true and initialLoad set to false by pull down to refresh
    } else {
        //otherwise (initialLoad is true or loadDataFromServer is false): page opening
        loadDataFromServer = false;
        //only do initial load if it hasn't in the past 15 minutes
        var lastLoadTime = localStorage.getItem('last_load_from_server_timestamp');
        //console.log('loadTaskStoreDataFromSync lastLoadTime',lastLoadTime);
        if (!lastLoadTime || Date.now() - lastLoadTime > 15 * 60 * 1000) {
            loadDataFromServer = true;
        }
    } */
    //clear initial loading flag
    //if (initialLoad) {
    //    _loadFromServer = false;
    //}
    
    var lastLoadTime = localStorage.getItem('last_load_from_server_timestamp');
    var skipFrontEndSyncDueToNoRecentLoading = false;
    if (!lastLoadTime || Date.now() - lastLoadTime > 4 * 60 * 60 * 1000) {  //4 hours
        skipFrontEndSyncDueToNoRecentLoading = true;
    }

    //try to use Frontend Sync to load
    //if initialLoad is false, that means it's from Pull Down to Refresh. Frontend Sync should be skipped (relying on full refresh below).
    if (loadDataFromServer && initialLoad) {
        if(navigator.onLine && !syncIsOn) {
            //avoid using FrontEnd Sync when there is almost no data (relying on Full Refresh)
            //reason 1: Frontend Sync is less efficient than Full Refresh in this case
            //reason 2: Frontend Sync may be interrupted by the "page flashing" for installing service worker
            if(await dataSync.isReasonableAmountOfDataInLocal()){
                SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync: calling frontendSync", {loadDataFromServer:loadDataFromServer});
                await changesSync.frontendSync();// new function to sync data in front end
                //only disable full loading when some data has actually been downloaded
                if(await dataSync.isReasonableAmountOfDataInLocal()){
                    loadDataFromServer=false;
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync set loadDataFromServer to false after front end sync", {loadDataFromServer:loadDataFromServer});
                }
                localStorage.setItem('last_load_from_server_timestamp', Date.now());
            }else{
                SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync front end sync skipped due to isReasonableAmountOfDataInLocal", "");
            }
        }else{
            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync front end sync skipped due to", {syncIsOn:syncIsOn});
        }

        /*  await dataSync.loadServerDataClustersForEntity("STOCKTAKE");
        await dataSync.loadServerDataClustersForEntity("Location");  */
        //console.log('loadTaskStoreDataFromSync set timestamp',Date.now());

    } 
    else if(loadDataFromServer && !initialLoad){
        if(navigator.onLine && !syncIsOn) {
            await dataSync.loadServerDataClustersForEntity(""); //use blank entity parameter to load all data
        }
    }
    else {
        SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync front end sync skipped due to", {loadDataFromServer:loadDataFromServer,initialLoad:initialLoad});
    }
    
    var clusterData = await dataSync.queryDataClustersForEntity("STOCKTAKE");
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync->STOCKTAKE cluster", clusterData);

    if (!Array.isArray(clusterData)) return [];
    //Promise all is required since the map using async
    var tasks = await Promise.all(clusterData.map(async (cluster, idx) => {
        //SkbLogger.logDebugInfo("cluster", cluster);
        let taskStoreData = new Object();
        taskStoreData.TaskId = cluster.referenceID;
        taskStoreData.TaskType = cluster.referenceEntity;
        taskStoreData.resourceID = cluster.resourceID;
        taskStoreData.SubTasks = [];
        taskStoreData.Programs = [];
        SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync: is loadDataFromServer still needed?", loadDataFromServer);
        let itemData = await dataSync.queryDataItemsForCluster(taskStoreData.resourceID, loadDataFromServer);
        if(loadDataFromServer){
            localStorage.setItem('last_load_from_server_timestamp', Date.now());
        }
        SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync->task item", cluster, clusterData);
        if (itemData && Array.isArray(clusterData)) {

            let stocktakeStatus = itemData.find(item => item.key.toLowerCase() === "stocktakestatus");
            //stocktakeStatus
            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync: stocktakeStatus", stocktakeStatus);
        
            if (stocktakeStatus) {
                taskStoreData.StocktakeSatus = {
                    resourceID: stocktakeStatus.resourceID,
                    key: stocktakeStatus.key,
                    type: "METADATA",
                    description: stocktakeStatus.description,
                    Status: stocktakeStatus.value,// parse failed, give it a default value 0
                }
            } else {
                taskStoreData.StocktakeSatus = {
                    resourceID: null,//misc.newUUID(), // Leave resourceID as null,  updateStocktakeStatus() will check whether is created by others to eliminate duplication 
                    key: "StocktakeStatus",
                    type: "METADATA",
                    description: "Stocktake Overall Status",
                    Status: Status.NotStarted,
                }
            }

            if (taskStoreData.StocktakeSatus.Status !== Status.Completed && taskStoreData.StocktakeSatus.Status !== Status.Cancelled) {
                //load completed date when it's available
                let completedDate = itemData.find(item => item.key.toLowerCase() === "completeddate");
                if (completedDate) {
                    taskStoreData.CompletedDate = {
                        resourceID: completedDate.resourceID,
                        key: completedDate.key,
                        type: "METADATA",
                        description: completedDate.description,
                        CompletedDate: completedDate.value,// parse failed, give it a default value 0
                    }
                } else {
                    taskStoreData.CompletedDate = {
                        resourceID: null, //misc.newUUID(),//misc.newUUID(), // Leave resourceID as null,  updateStocktakeStatus() will check whether is created by others to eliminate duplication 
                        key: "CompletedDate",
                        type: "METADATA",
                        description: "Task completed date",
                        CompletedDate: '',
                    }
                }

                //itemData.sort((a,b)=>a.arrayIndex<b.arrayIndex?-1:0);
                await Promise.all(itemData.map(async (i, index) => {
                    if (i.key.toLowerCase() === "companyname") {
                        taskStoreData.CompanyName = i.value;
                    } else if (i.key.toLowerCase() === "starttime") {
                        taskStoreData.StartTime = i.value;
                    } else if (i.key.toLowerCase() === "endtime") {
                        taskStoreData.EndTime = i.value;
                    } else if (i.key.toLowerCase() === "program") { //  get get program data 

                        let pdata = i.value.split('|');
                        taskStoreData.Programs.push({
                            ProgramName: pdata[0],
                            PhoneNumber: pdata[1],
                        });
                    } else if (i.key.toLowerCase() === "locationlist") {
                        //getDataClusterByResourceID
                        let locationItems = [];
                        let locationResourceId = i.value;
                        /* ============ NOT TO GET ALL LOCATION ITEMS
                        if (isNaN(i.value)) { //if value is not a number, it shall be location resource ID, get it's datatime straighaway 
                            await dataSync.queryDataClustersForEntity("Location");
                            locationItems = await dataSync.queryDataItemsForCluster(i.value, loadDataFromServer);
                            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync->Location DataItem", i, locationItems);

                        }
                        else {//the value is locationid, get location cluster ID by referenceID first (in cluster table, the refereneID is location id)
                            let location = (await dataSync.queryDataClustersForEntity("Location", i.value))[0];//expect only one matched
                            locationResourceId = location.resourceID

                            // console.log("location", location.resourceID);
                            //next to  get location itmes;
                            locationItems = await dataSync.queryDataItemsForCluster(location.resourceID, loadDataFromServer);
                            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTaskStoreDataFromSync->Location DataItem", location, locationItems);

                        }
                        */

                        let subTask = new Object();
                        subTask.sequenceId = taskStoreData.SubTasks.length + 1;//sequence id start from 1 
                        subTask.resourceID = locationResourceId;// it's subtask cluster id 

                        //get location address
                        if (loadDataFromServer) await dataSync.queryDataItemsByKeysForCluster(i.value, "Address,Status,LockedBy", loadDataFromServer);

                        let address = await dataSync.queryDataItemsByKey("Address", i.value);
                        if (address.length > 0) subTask.Address = address[0].value;
                        //get location status
                        let taskStatus = await dataSync.queryDataItemsByKey("Status", i.value);
                        if (taskStatus.length > 0) {
                            subTask.TaskStatus = {
                                resourceID: taskStatus[0].resourceID,
                                key: taskStatus[0].key,
                                type: "METADATA",
                                description: taskStatus[0].description,
                                Status: taskStatus[0].value,
                            };
                        } else {
                            subTask.TaskStatus = {
                                resourceID: misc.newUUID(),
                                key: "Status",
                                type: "METADATA",
                                description: "Status",
                                Status: Status.NotStarted,
                            };
                        }
                        //get location locked by
                        let lockedBy = await dataSync.queryDataItemsByKey("LockedBy", i.value);
                        if (lockedBy.length > 0) {
                            subTask.LockedBy = {
                                resourceID: lockedBy[0].resourceID,
                                key: lockedBy[0].key,
                                type: "METADATA",
                                description: lockedBy[0].description,
                                LockedBy: (parseInt(lockedBy[0].value) || 0),// parse failed, give it a default value 0
                            };
                        } else {
                            subTask.LockedBy = {
                                resourceID: null,
                                key: "LockedBy",
                                type: "METADATA",
                                description: "Locked by",
                                LockedBy: 0,
                            }
                        }

                        //load task to ui when address is available
                        if (subTask.Address) taskStoreData.SubTasks.push(subTask);
                        /* === REST LOCATION DATA WILL BE LOADED INDIVIDUALLY 
                        if (locationItems.length > 0) {
                            
                            let subTask = new Object();
                            subTask.sequenceId = taskStoreData.SubTasks.length + 1;//sequence id start from 1 
                            subTask.resourceID = locationResourceId;// it's subtask cluster id 

                            subTask.Address = locationItems.find(item => item.key.toLowerCase() === "address").value;
                            //if task status not in sync data, create and set it as NOTStarted
                            let taskStatus = locationItems.find(item => item.key.toLowerCase() === "status") || {
                                resourceID: misc.newUUID(),
                                key: "Status",
                                type: "METADATA",
                                description: "Status",
                                value: Status.NotStarted,
                            };
                            subTask.TaskStatus = {
                                resourceID: taskStatus.resourceID,
                                key: taskStatus.key,
                                type: "METADATA",
                                description: taskStatus.description,
                                Status: taskStatus.value,
                            }

                            let lockedBy = locationItems.find(item => item.key.toLowerCase() === "lockedby");
                            if (lockedBy) {
                                subTask.LockedBy = {
                                    resourceID: lockedBy.resourceID,
                                    key: lockedBy.key,
                                    type: "METADATA",
                                    description: lockedBy.description,
                                    LockedBy: (parseInt(lockedBy.value) || 0),// parse failed, give it a default value 0
                                }
                            } else {
                                subTask.LockedBy = {
                                    resourceID: null,
                                    key: "LockedBy",
                                    type: "METADATA",
                                    description: "Locked by",
                                    LockedBy: 0,
                                }
                            }


                            
                            //current step
                            subTask.CurrentStep = getSubtaskCurrentStep(locationItems);
                            //get preview info
                            let previewInfo = getSubtaskPreviewInfo(locationItems);

                            subTask.PreviewInfo = previewInfo;
                            //get existing available SNs
                            subTask.ExistingAvailableSNs = getExistingSnItems(locationItems);

                            //get non-sn items
                            subTask.NonSerialisedItems = getSubtaskNonSnItems(locationItems);
                            
                            //get serialise Items
                            subTask.SerialisedItems = getSubtaskSnItems(locationItems);

                            //get Steps Items
                            subTask.Steps = getSubtaskSteps(locationItems, cluster.referenceEntity);



                            subTask.SignoffSig = getSubtaskSignature(locationItems);

                            taskStoreData.SubTasks.push(subTask);
                        }
                    */
                    }
                }));
                //console.log(itemData);
            }
            taskStoreData.SubTasks.sort((a, b) => a.Address < b.Address ? -1 : 0);
            return taskStoreData;
        } else {
            return null; // needs to eliminate the null task
        }

    }));
    return tasks.filter(task => task != null && task.StocktakeSatus.Status !== Status.Completed && task.StocktakeSatus.Status !== Status.Cancelled); //Hide task is completed or cancelled
}

//the preview info are shared by all locations
async function loadTaskPreviewInfo(taskResourceId, loadDataFromServer) {
    let locations = await dataSync.queryDataItemsByKey("LocationList", taskResourceId);
    let previewItems = [];
    // await Promise.all(locations.map(async location=>{
    //     let items = await dataSync.queryDataItemsByKey("Preview", location.value)
    //     let pInfo = getSubtaskPreviewInfo(items);
    //     previewItems.concat(pInfo);
    // }));
    for (let location of locations) {
        let items = await dataSync.queryDataItemsByKeysForCluster(location.value,"Preview", loadDataFromServer)
        let pInfo = getSubtaskPreviewInfo(items);
        previewItems = previewItems.concat(pInfo);
    }

    return previewItems;
}

async function loadSubTaskPreviewInfo(subtaskResourceId, loadDataFromServer) {

    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadSubTaskPreviewInfo", subtaskResourceId);

    let items = await dataSync.queryDataItemsByKeysForCluster(subtaskResourceId,"Preview", loadDataFromServer)
    let pInfo = getSubtaskPreviewInfo(items);

    return pInfo;
}

//the existingSN info are shared by all locations
async function loadTaskExistingSnInfo(taskResourceId, loadDataFromServer) {
    let locations = await dataSync.queryDataItemsByKey("LocationList", taskResourceId);
    let existingItems = [];
    for (let location of locations) {
        let items = await dataSync.queryDataItemsByKeysForCluster(location.value, "Available_SN", loadDataFromServer);
        let existingSn = getSubtaskExistingSnItems(items);
        existingItems = existingItems.concat(existingSn);
    };

    return existingItems;
}


function getSubtaskCurrentStep(locationItems) {
    let subtaskCurrentSteps = {};
    let currentStep = locationItems.find(item => item.key.toLowerCase() === "currentstep");
    if (currentStep) {
        subtaskCurrentSteps = {
            resourceID: currentStep.resourceID,
            key: currentStep.key,
            type: "METADATA",
            description: currentStep.description,
            StepId: (parseInt(currentStep.value) || 1),
        };
    } else {
        subtaskCurrentSteps = {
            resourceID: null,
            key: "CurrentStep",
            type: "METADATA",
            description: "current step",
            StepId: 1,
        };
    }
    return subtaskCurrentSteps;
}

function getSubtaskPreviewInfo(locationItems) {
    return locationItems.filter(item => item.key.toLowerCase() === "preview").reduce((result, p) => {
        //it's format string, i.e. "'EROD001|Outdoor Unit (ODU)|Serialised'"
        try {
            let previeItems = p.value.split('|');
            result.push({
                StockCode: previeItems[0],
                Description: previeItems[1],
                StockType: previeItems[2],
                Qty: parseInt(previeItems[3]) || 0,
                QtyUnit: previeItems[4] || ""
            });
        } catch (error) {
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync -> get preview item failed", error, p);

        }
        return result;
    }, []);
}

function getSubtaskExistingSnItems(locationItems) {
    return locationItems.filter(item => item.key.toLowerCase() === "available_sn").reduce((result, s) => {
        //it's format string, i.e. EROD001|Outdoor Unit (ODU)|133434324
        try {
            let sn = s.value.split('|');
            result.push({
                SerialNumber: sn[2],
                StockCode: sn[0],
                Description: sn[1]
            });

        } catch (error) {
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync -> get available_sn item failed", error, s);
        }

        return result;
    }, []);
}

function getSubtaskNonSnItems(locationItems) {
    return locationItems.filter(item => item.key.toLowerCase() === "nonsn").reduce((result, nonsnItem) => {
        //it's format string, i.e. SKB002|cable2|Meter|0|0
        try {
            let nonsn = nonsnItem.value.split('|');
            result.push({
                StockCode: nonsn[0],
                Description: nonsn[1],
                QtyUnit: nonsn[2],
                GoodQty: parseInt(nonsn[3]) || 0,
                FaultyQty: parseInt(nonsn[4]) || 0,
                resourceID: nonsnItem.resourceID,
                key: nonsnItem.key,
                type: "METADATA",
                description: nonsnItem.description
            });

        } catch (error) {
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync -> get nonsn item failed", error, nonsnItem);
        }
        return result;
    }, []);
}

function getSubtaskSnItems(locationItems) {
    let snItems = locationItems.filter(item => item.key.toLowerCase() === "sn");
    let snMetaItems = snItems.filter(snm => snm.type === DataType.METADATA);
    let snPhotoItems = snItems.filter(snp => snp.type === DataType.PHOTO);
    let combinedSnItems = snMetaItems.reduce((result, snItem, idx) => {
        //it's format string, i.e. EROD001|Outdoor Unit (ODU)|133434325|Faulty|-37.23|125.134
        try {
            result.push(processSnWithPhoto(snPhotoItems, idx, snItem));


        } catch (error) {
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync -> get sn item failed", error, snItem);
        }
        return result;
    }, []);
    return combinedSnItems;
}

function getSubtaskSteps(locationItems, taskType) {
    let steps = locationItems.filter(item => item.key.toLowerCase() === "step").reduce((result, step, idx) => {
        //it's format string, i.e. 4|Review|Not Started
        try {

            let sp = step.value.split('|');
            result.push({
                StepId: parseInt(sp[0]),

                StepDescription: sp[1],
                Status: sp.length > 2 ? sp[2] : Status.NotStarted,
                resourceID: step.resourceID,
                key: step.key,
                type: "METADATA",
                description: step.description
            });

        } catch (error) {
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync -> get step item failed", error, step);

        }
        return result;
    }, []).sort((a, b) => a.StepId < b.StepId ? -1 : 0);

    // const taskSetting = taskConfig[taskType];
    // if(taskSetting.TaskDetails.Steps.length != steps.length)
    //     return [];
    //steps = consolidateTaskStepsWithConfig(taskType, steps); //the step data will not create in Minvera, the task intialising script MUST create it in advance


    return steps;
}

function getSubtaskSignature(locationItems) {
    let signoffSig = {};
    let signoffMetaData = locationItems.find(item => item.key.toLowerCase() === "signoffsig" && item.type === DataType.METADATA);
    if (signoffMetaData) {
        signoffSig.resourceID = signoffMetaData.resourceID;
        signoffSig.arrayIndex = signoffMetaData.arrayIndex;

        signoffSig.key = signoffMetaData.key;
        signoffSig.type = signoffMetaData.type;
        signoffSig.description = signoffMetaData.description;
        signoffSig.SignoffBy = signoffMetaData.value;
        let signOffPhotoData = locationItems.find(items => items.key.toLowerCase() === "signoffsig" && items.type === DataType.PHOTO);
        if (signOffPhotoData) {
            let sData = null;
            try {
                sData = crypts.decrypt(signOffPhotoData.localArtefactContent);
            } catch (error) {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "process sig data - descrypt photo localArtefactContent failed", signOffPhotoData);

            }

            signoffSig.SigResourceId = signOffPhotoData.resourceID;
            signoffSig.Sig = sData ? "data:image/png;base64," + sData : null;
        }

    }
    return signoffSig;
}

/** @function loadTaskStatusFromSync load task status and lockedby from sync  by task cluster resourceid
 * @param {*} taskClusterResourceId task resource id
 * @summary it will query data key "LockedBy" and "Status" for the given tsk cluster resource id
*/
async function loadTaskStatusFromSync(taskClusterResourceId) {
    let statusSyncData = await dataSync.queryDataItemsByKeysForCluster(taskClusterResourceId, "Status", true); //
    let status = Status.NotStarted;
    if (statusSyncData && Array.isArray(statusSyncData) && statusSyncData.length > 0) status = statusSyncData[0].value;

    let lockedBySyncData = await dataSync.queryDataItemsByKeysForCluster(taskClusterResourceId,"LockedBy", true); //
    let lockedBy = 0;
    if (lockedBySyncData && Array.isArray(lockedBySyncData) && lockedBySyncData.length > 0) lockedBy = parseInt(lockedBySyncData[0].value) || 0;

    return {
        status: status,
        lockedBy: lockedBy
    }
}

/** @function loadTaskStepsFromSync load task status and lockedby from sync  by task cluster resourceid
 * @param {*} taskClusterResourceId task resource id
 * @summary it will query data key "LockedBy" and "Status" for the given tsk cluster resource id
*/
export async function loadTaskStepsFromSync(taskClusterResourceId) {
    let steps = await dataSync.queryDataItemsByKeysForCluster(taskClusterResourceId, "Step", false); //
    return steps
}


const refreshStore = (tasks) => {

    return {
        type: Actions.TASK_REFRESHED,
        taskData: tasks,
    };

}

export const updateError = (err) => {

    return {
        type: Actions.UPDATE_ERROR,
        err
    }
}

/** @function getTaskStatusAndLockedbyFromSync get task status and lockedby from sync  by task cluster resourceid
 * @param {*} taskClusterResourceId task resource id
 * @returns an object 
 * {
 *  status, -- one of Actions.Status
 * lockedBy, -- integer
 * }
 * @summary compenent can call this function to get taskstatus of specified task by task cluster resource id
*/
export async function getTaskStatusAndLockedbyFromSync(taskClusterResourceId) {
    return await loadTaskStatusFromSync(taskClusterResourceId);
}

/**
 * @function loadTasksFromSync load task list from sync modules,this action needs to be call when entering task modules
 */
export function loadTasksFromSync(loadDataFromServer, initialLoad,syncIsOn=false) {
    return (dispatch) => {
        dispatch(requestHasSent());
        setTimeout(() => {
            dispatch(requestHasResponded());
            
        }, 1000*60*2); // to hide loading indicator if task is still loading after 2 minutes
        
        try {
            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTasksFromSync", "loadTasksFromSync starting", {loadDataFromServer:loadDataFromServer, initialLoad:initialLoad,syncIsOn:syncIsOn});
            return loadTaskStoreDataFromSync(loadDataFromServer, initialLoad,syncIsOn).then((tasks) => {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadTasksFromSync", "load task from sync successfully",tasks);
                dispatch(refreshStore(tasks));
                dispatch(requestHasResponded());
                dispatch(updateError(""));
            }).catch(err => {
                dispatch(updateError(err));
                dispatch(requestHasResponded());
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync", err);
            });

        } catch (error) {

            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadTasksFromSync", error);
            
        }

    };

}


export function loadCurrentTaskSteps(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskSteps", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentTask = getCurrentTask(allTasks);
        var currentSubTask = getCurrentSubTask(allTasks);

        //in queryDataItemsByKey, the key is case-sensitive 
        return dataSync.queryDataItemsByKeysForCluster(currentSubTask.resourceID, "Step", loadDataFromServer).then((items) => {
            let data = getSubtaskSteps(items, currentTask.TaskType);
            
            //the step data will not create in Minvera, the task intialising script MUST create it in advance
            const taskSetting = taskConfig[currentTask.TaskType];
            if(taskSetting.TaskDetails.Steps.length !== data.length){
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskSteps", "Task steps have not been loaded completely!");
            } else{
            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskSteps ", d);
                return {
                    type: Actions.SUBTASK_STEPS_LOADED,
                    data: d
                }
            })(data));
        }
            dispatch(requestHasResponded());

            //dispatch(changeStep(allTasks, currentTask.CurrentStep.StepId));

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskSteps", err);
        });


    }
}

export function loadCurrentTaskPreviewInfo(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskPreviewInfo", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentTask = getCurrentTask(allTasks);
        var currentSubTask = getCurrentSubTask(allTasks);

        SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskPreviewInfo", "tasks", {all:allTasks,currentTask:currentTask,subtask:currentSubTask});


        //in queryDataItemsByKey, the key is case-sensitive 
        //loadTaskPreviewInfo vs loadSubTaskPreviewInfo
        //return loadTaskPreviewInfo(currentTask.resourceID, loadDataFromServer).then((items) => {
        return loadSubTaskPreviewInfo(currentSubTask.resourceID, loadDataFromServer).then((items) => {
            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskPreviewInfo ", d);
                return {
                    type: Actions.SUBTASK_PREVIEW_LOADED,
                    data: d
                }
            })(items));
            dispatch(requestHasResponded());

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskPreviewInfo", err);
        });


    }
}
export function loadCurrentTaskSnItems(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskSnItems", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentTask = getCurrentTask(allTasks);
        var currentSubTask = getCurrentSubTask(allTasks);

        //in queryDataItemsByKey, the key is case-sensitive 

        return dataSync.queryDataItemsByKeysForCluster(currentSubTask.resourceID, "SN", loadDataFromServer).then((items) => {
            let data = getSubtaskSnItems(items);
            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskSnItems ", d);
                return {
                    type: Actions.SUBTASK_SNITEMS_LOADED,
                    data: d
                }
            })(data));
            dispatch(requestHasResponded());
            dispatch(loadCurrentTaskAvailableSn(loadDataFromServer)); //load avaialbe sn for validation purpose

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskSnItems", err);
        });


    }
}
export function loadCurrentTaskNonSnItems(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskNonSnItems", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentTask = getCurrentTask(allTasks);
        var currentSubTask = getCurrentSubTask(allTasks);

        //in queryDataItemsByKey, the key is case-sensitive 

        return dataSync.queryDataItemsByKeysForCluster(currentSubTask.resourceID, "NonSN", loadDataFromServer).then((items) => {
            let data = getSubtaskNonSnItems(items);
            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskNonSnItems ", d);
                return {
                    type: Actions.SUBTASK_NOSNITEMS_LOADED,
                    data: d
                }
            })(data));
            dispatch(requestHasResponded());

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskNonSnItems", err);
        });


    }
}

export function loadCurrentTaskSignoffSig(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskSignoffSig", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentSubTask = getCurrentSubTask(allTasks);

        //in queryDataItemsByKey, the key is case-sensitive 

        return dataSync.queryDataItemsByKeysForCluster(currentSubTask.resourceID, "SignoffSig").then((items) => {
            let data = getSubtaskSignature(items);
            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskSignoffSig ", d);
                return {
                    type: Actions.SUBTASK_SIG_LOADED,
                    data: d
                }
            })(data));
            dispatch(requestHasResponded());

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskSignoffSig", err);
        });


    }
}

export function loadCurrentTaskAvailableSn(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskAvailableSn", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentTask = getCurrentTask(allTasks);
        var currentSubTask = getCurrentSubTask(allTasks);

        //in queryDataItemsByKey, the key is case-sensitive 

        return loadTaskExistingSnInfo(currentTask.resourceID, loadDataFromServer).then((items) => {

            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskAvailableSn ", d);
                return {
                    type: Actions.SUBTASK_AVAILABLESN_LOADED,
                    data: d
                }
            })(items));
            dispatch(requestHasResponded());

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskAvailableSn", err);
        });


    }
}

export function loadCurrentTaskCurrentStep(loadDataFromServer = false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "loadCurrentTaskCurrentStep", "loadDataFromServer", loadDataFromServer);

    return (dispatch, getState) => {
        dispatch(requestHasSent());

        var allTasks = getTaskStateWhenAvailable(getState);
        var currentSubTask = getCurrentSubTask(allTasks);

        //in queryDataItemsByKey, the key is case-sensitive 

        return dataSync.queryDataItemsByKeysForCluster(currentSubTask.resourceID, "CurrentStep", true).then((items) => {
            let data = getSubtaskCurrentStep(items);
            dispatch(((d) => {

                SkbLogger.logDebugInfo("loadCurrentTaskCurrentStep ", d);
                return {
                    type: Actions.SUBTASK_CURRENTSTEP_LOADED,
                    data: d
                }
            })(data));
            dispatch(requestHasResponded());

        }).catch(err => {

            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadCurrentTaskCurrentStep", err);
        });


    }
}
export const getCurrentTask = allTasks => {
    if (!allTasks.CurrentTaskResourceId) return allTasks.Tasks[0];
    return allTasks.Tasks.find(t => t.resourceID === allTasks.CurrentTaskResourceId);
}

export const getCurrentSubTask = allTasks => {
    // console.log(taskState,taskState.Tasks);
    //if (!taskState.CurrentSubTask) return (taskState.CurrentTaskResourceId ? taskState.CurrentTaskResourceId.SubTasks[0] : taskState.Tasks[0].SubTasks[0]);
    var currentTask = getCurrentTask(allTasks);
    if (!allTasks.CurrentSubTaskResourceId) return currentTask.SubTasks[0];
    return currentTask.SubTasks.find(s => s.resourceID === allTasks.CurrentSubTaskResourceId);
}

export const getStepFromCurrentSubTaskByStepId = (allTasks, stepId) => {
    var currentTask = getCurrentSubTask(allTasks);
    return currentTask.Steps.filter(s => s.StepId === stepId)[0];
};

export const getPreviewItemsForAllSubTasksOfCurrentTask = allTasks => {

    var currentTask = getCurrentTask(allTasks);

    var fullListOfPreviewItems = [];

    currentTask.SubTasks.forEach(st => fullListOfPreviewItems = fullListOfPreviewItems.concat(st.PreviewInfo));

    return fullListOfPreviewItems;

}

export const getExistingSerialNosForAllSubTasksOfCurrentTask = allTasks => {

    var currentTask = getCurrentTask(allTasks);

    var fullListOfSerialNumbers = [];

    currentTask.SubTasks.forEach(st => fullListOfSerialNumbers = fullListOfSerialNumbers.concat(st.ExistingAvailableSNs));

    return fullListOfSerialNumbers;

}

/**
 * @function updateTaskStatus
 * @param {*} allTasks, it's task redux store state
 * @param {*} taskStatus: new task status, options:"Not Started", "In Progress", "Done", the value shall be calcualted by steps status, if all status of steps are "Not Started", it would be "Not Started", all "Done", it would be "DONE", otherwise "In Progress", the called will be responsisble to calculate the task status
 */
export function updateTaskStatus(allTasks, taskStatus) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateTaskStatus", "tasks status update to", taskStatus);

    return (dispatch, getState) => {
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));


        if (taskStatus === Status.Done) { // release lock if task status is change to done
            let lockedBy = { ...currentSubTask.LockedBy, LockedBy: 0 };
            dispatch(updateLockedby(allTasks, lockedBy));
        }

        //update taskstatus before save into index db for it's slow 
        dispatch(((taskStatus) => {

            SkbLogger.logDebugInfo("TaskStatus Update", taskStatus);
            return {
                type: Actions.TASKSTATUS_UPDATE,
                taskStatus: taskStatus
            }
        })(taskStatus));

        return dataSync.saveDataItem(currentSubTask.TaskStatus.resourceID, currentSubTask.resourceID, currentSubTask.TaskStatus.key, taskStatus, currentSubTask.TaskStatus.type, currentSubTask.TaskStatus.description).then(() => {
            // dispatch(((taskStatus) => {

            //     SkbLogger.logDebugInfo("TaskStatus Update", taskStatus);
            //     return {
            //         type: Actions.TASKSTATUS_UPDATE,
            //         taskStatus: taskStatus
            //     }
            // })(taskStatus))
        }).catch(err => {
            SkbLogger.logDebugInfo("TaskStatus Update error:", err);

            dispatch(updateError(err));

            SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.taskList, misc.LogResult.fail,
                `{stockLocationObject} task status is failed to changed to "${taskStatus}".`
                , {
                    stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                });

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateTaskStatus", err);
        });


    }
}

/**
 * @function updateTaskStep
 * @param {*} allTasks, it's task redux store state
 * @param stepId it is current step ID
 * @param {*} status: new task status, options:"Not Started", "In Progress", "Done",
 * @param {*} timestamp: it's optional, when status is 'Done", the timestamp is UTC time string when the step is makred as Done, i.e "Thu, 10 Sep 2020 04:37:30 GMT" using (new Date().toUTCString())
 * @param {*} latlong: it's optional, when status is 'Done', it's lat,long format string using misc.getLocationObject(), i.e "-38.23,123.034"
 * @summary the timestamp and latlong will not saved in redux store
 */
export function updateTaskStep(allTasks, stepId, status, timestamp = '', latlong = '') {
    return (dispatch, getState) => {

        //push dataitem to server for previous user action (if any)
        const lastResourceID = localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
        if (lastResourceID && lastResourceID != '') {
            dataSync.pushDataItemToServer(lastResourceID).then(() => {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, ModuleInfo.category, "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server', '');
            }).catch(err => {
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "Failed to push dataItem to Server", err);
            });
        }

        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        var step = getStepFromCurrentSubTaskByStepId(allTasks, stepId);
        //in sync db, status is included in step using format string stepId|stepdescription|status
        //saving to index db is much slower, so updte store first
        dispatch(((status) => {
            return {
                type: Actions.STEP_UPDATE,
                step: {
                    stepId: stepId,
                    status: status
                }
            }
        })(status));

        return dataSync.saveDataItem(step.resourceID, currentSubTask.resourceID, step.key,
            `${stepId}|${step.StepDescription}|${status}|${timestamp || ""}|${latlong || ""}`,
            step.type, step.description).then(() => {
                // dispatch(((status) => {
                //     return {
                //         type: Actions.STEP_UPDATE,
                //         step: {
                //             stepId: stepId,
                //             status: status
                //         }
                //     }
                // })(status));
                try {
                    dispatch(updateError(""));
                } catch (error) {

                }


            }).catch(err => {
                dispatch(updateError(err));

                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.taskList, misc.LogResult.fail,
                    `{stockLocationObject} Sub task (stepId:${stepId}) status is failed to changed to "${status}".`
                    , {
                        stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                    });

                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateTaskStep", err);
            });;

    }
}

/**
 * @function addSnItem add a new sn under current subtask item to store and update sync
 * @param {*} allTasks, it's task redux store state
 * @param sn new sn item to add, sample data: {
            StockCode: "EROD001",
            SerialNumber: "133434325",
            Description: "Outdoor Unit (ODU)",
            ItemStatus: 'Faulty',
            Photo: 'photo path',
            Latitude: "-37.23",
            Longitude: "125.134",
 }
 */
export function addSnItem(allTasks, sn) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        var resoureceId = misc.newUUID();
        var photoResourceId = misc.newUUID();

        var availableArrayIndex = getNextAvailableArrayIndex(currentSubTask.SerialisedItems);
        var arrayIndex = `${availableArrayIndex},0`; //meta data array index
        //at this stage, not handle photo
        //sn item in sync table is using format string, "StockCode|Description|SN|status|lat|long", ie: EROD001|Outdoor Unit (ODU)|133434324|Good|-37.23|125.134
        dispatch(((sn) => {
            return {
                type: Actions.ADD_SN_ITEM,
                sn: {
                    ...sn,
                    resourceID: resoureceId,
                    arrayIndex: arrayIndex,
                    PhotoResourceId: photoResourceId,
                    ...sngeneric,
                    sequenceId: 0
                }
            }
        })(sn));
        dispatch(requestHasResponded());

        return dataSync.saveDataItemWithIndex(resoureceId, currentSubTask.resourceID, sngeneric.key, `${sn.StockCode}|${sn.Description}|${sn.SerialNumber}|${sn.ItemStatus}|${sn.Latitude}|${sn.Longitude}`, sngeneric.type, arrayIndex, sngeneric.description).then(() => {
            // dispatch(((sn) => {
            //     return {
            //         type: Actions.ADD_SN_ITEM,
            //         sn: {
            //             ...sn,
            //             resourceID: resoureceId,
            //             arrayIndex: arrayIndex,
            //             PhotoResourceId: photoResourceId,
            //             ...sngeneric,
            //             sequenceId: 0
            //         }
            //     }
            // })(sn));

            //only save photo for UNKNOW SN code
            if (sn.Photo) {
                try {
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "addSnItem", "save barcode photo", sn);

                    if (sn.StockCode === "UNKNOWN") {
                        dataSync.saveDataItemWithIndex(photoResourceId, currentSubTask.resourceID, sngeneric.key, `${(sn.Photo || "").replace("data:image/jpeg;base64,", "")}`, DataType.PHOTO, `${availableArrayIndex},1`, sn.description).then(() => { }).catch(perror => {
                            dispatch(updateError(perror));
                            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addSnItem - barcode photo", perror);
                        });

                    } else {
                        dataSync.saveLocalPhoto(photoResourceId, currentSubTask.resourceID, sngeneric.key, `${(sn.Photo || "").replace("data:image/jpeg;base64,", "")}`, DataType.PHOTO, `${availableArrayIndex},1`, sn.description).then(() => { }).catch(perror => {
                            dispatch(updateError(perror));
                            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addSnItem - barcode photo", perror);
                        });
                    }
                } catch (error) {
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "addSnItem", "save barcode photo failed", sn, error);

                }
            }

            dispatch(updateError(""));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());

            SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.serialisedStock, misc.LogResult.fail,
                `{userObject} has failed to add {stockObject} at {locationObject} while {networkObject}.`
                , {
                    userObject: misc.getUserObject(),
                    stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                    networkObject: misc.getNetworkObject(),
                    stockObject: {
                        "Stock Type": "Serialised",
                        "Stock Code": sn.StockCode,
                        "Description": sn.Description
                    }
                });

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addSnItem", err);
        });

    }
}

/**
 * @function updateSnItem update a new sn item under current subtasks to store and update sync
 * @param {*} allTasks, it's task redux store state
 * @param sn sn item to update, sample data: {
            StockCode: "EROD001",
            SerialNumber: "133434325",
            Description: "Outdoor Unit (ODU)",
            ItemStatus: 'Faulty',
            Photo: 'photo path',
            Latitude: "-37.23",
            Longitude: "125.134",
            description: "serialised Item",
            key: "SN",
            sequenceId: 0,
            type: "PHOTO",
            resourceID: "1234567890",
            PhotoResourceId: "xxxxxxx",
            arrayIndex: "1,1"
 }
 */
export function updateSnItem(allTasks, sn) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        //sn item in sync table is using format string, sample: EROD001|Outdoor Unit (ODU)|133434324|Good|-37.23|125.134
        //at this stage, not handle photo
        dispatch(((sn) => {
            return {
                type: Actions.UPDATE_SN_ITEM,
                sn
            }
        })(sn));

        dispatch(requestHasResponded());
        return dataSync.saveDataItemWithIndex(sn.resourceID, currentSubTask.resourceID, sngeneric.key, `${sn.StockCode}|${sn.Description}|${sn.SerialNumber}|${sn.ItemStatus}|${sn.Latitude}|${sn.Longitude}`, sngeneric.type, sn.arrayIndex, sngeneric.description).then(() => {


            //only update photo for UNKNOW SN code
            if (sn.Photo) {
                try {
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateSnItem", "update barcode photo", sn);

                    let photoArrayIndex = (sn.arrayIndex || "").replace(/\d+$/ig, "1");

                    if (sn.StockCode === "UNKNOWN") {
                        dataSync.saveDataItemWithIndex(sn.PhotoResourceId, currentSubTask.resourceID, sngeneric.key, `${(sn.Photo || "").replace("data:image/jpeg;base64,", "")}`, DataType.PHOTO, photoArrayIndex, sn.description).then(() => { }).catch(perror => {
                            dispatch(updateError(perror));
                            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateSnItem - barcode photo", perror);
                        });

                    } else {
                        dataSync.saveLocalPhoto(sn.PhotoResourceId, currentSubTask.resourceID, sngeneric.key, `${(sn.Photo || "").replace("data:image/jpeg;base64,", "")}`, DataType.PHOTO, photoArrayIndex, sn.description).then(() => { }).catch(perror => {
                            dispatch(updateError(perror));
                            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateSnItem - barcode photo", perror);
                        });
                    }


                } catch (error) {
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateSnItem", "update barcode photo failed", sn, error);

                }
            }

            dispatch(updateError(""));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());

            SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.serialisedStock, misc.LogResult.fail,
                `{userObject} has failed to update {stockObject} at {locationObject} while {networkObject}.`
                , {
                    userObject: misc.getUserObject(),
                    stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                    networkObject: misc.getNetworkObject(),
                    stockObject: {
                        "Stock Type": "Serialised",
                        "Stock Code": sn.StockCode,
                        "Description": sn.Description
                    }
                });

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateSnItem", err);
        });
    }
}

/**
 * @function deleteSnItem delete a sn item under current subtasks to store and update sync
 * @param {*} allTasks, it's task redux store state
 * @param sn sn item to delete, sample data: {
            StockCode: "EROD001",
            SerialNumber: "133434325",
            Description: "Outdoor Unit (ODU)",
            ItemStatus: 'Faulty',
            Photo: 'photo path',
            Latitude: "-37.23",
            Longitude: "125.134",
            description: "serialised Item",
            key: "SN",
            sequenceId: 0,
            type: "PHOTO",
            resourceID: "1234567890",
            PhotoResourceId: "xxxxxxx",
            arrayIndex: "1,1"
 }
 */
export function deleteSnItem(allTasks, sn) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        //they shall use delete method
        //at this stage, not handle photo
        dispatch(((sn) => {
            return {
                type: Actions.DEL_SN_ITEM,
                sn
            }
        })(sn));
        dispatch(requestHasResponded());

        return dataSync.deleteDataItem(sn.resourceID).then(() => {


            //del barcode photo
            if (sn.PhotoResourceId) {
                try {
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "deleteSnItem", "delete barcode photo", sn);
                    if (sn.StockCode === 'UNKNOWN')
                        dataSync.deleteDataItem(sn.PhotoResourceId);
                    else
                        dataSync.deleteLocalPhoto(sn.PhotoResourceId); // delete photo resource

                } catch (error) {
                    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateSnItem", "delete barcode photo failed", sn, error);

                }
            }
            dispatch(updateError(""));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());

            SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.serialisedStock, misc.LogResult.fail,
                `{userObject} has failed to delete {stockObject} at {locationObject} while {networkObject}.`
                , {
                    userObject: misc.getUserObject(),
                    stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                    networkObject: misc.getNetworkObject(),
                    stockObject: {
                        "Stock Type": "Serialised",
                        "Stock Code": sn.StockCode,
                        "Description": sn.Description
                    }
                });

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "deleteSnItem", err);
        });
    }
}

/**
 * @function addNonSnItem add a new non-sn under current subtask item to store and update sync
 * @param {*} allTasks, it's task redux store state
 * @param nonsnItem non sn item to add, sample data: {
            StockCode: "SKB001",
            Description: "cable",
            QtyUnit: "Meter",
            GoodQty: 0,
            FaultyQty: 0,
 }
 */
export function addNonSnItem(allTasks, nonsnItem) {

    return (dispatch, getState) => {
        //save to redux store state first and not wait for saving completed to improve UI repsonsivness 
        dispatch(requestHasSent());
        nonsnItem.resourceID = nonsnItem.resourceID || misc.newUUID();
        dispatch(((nonsnItem) => {
            return {
                type: Actions.ADD_NONSN_ITEM,
                nonsnItem: {
                    ...nonsnItem,
                    
                    type: nonsngeneric.type,
                    description: nonsngeneric.description,
                    key: nonsngeneric.key
                }
            }
        })(nonsnItem));
        dispatch(updateError(""));
        dispatch(requestHasResponded());

        //push dataitem to server for previous user action (if any)
        const lastResourceID = localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
        if (lastResourceID && lastResourceID != '') {
            dataSync.pushDataItemToServer(lastResourceID).then(() => {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, ModuleInfo.category, "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server', '');
            }).catch(err => {
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "Failed to push dataItem to Server", err);
            });
        }
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        //non-sn item in sync table is using format string "StockCode|Description|UnitType|QtyGood|QtyFault", ie: SKB001|cable|Meter|0|0
        return dataSync.saveDataItem(nonsnItem.resourceID, currentSubTask.resourceID, nonsngeneric.key, `${nonsnItem.StockCode}|${nonsnItem.Description}|${nonsnItem.QtyUnit}|${nonsnItem.GoodQty}|${nonsnItem.FaultyQty}`, nonsngeneric.type, nonsngeneric.description).then(() => {

            // dispatch(((nonsnItem) => {
            //     return {
            //         type: Actions.ADD_NONSN_ITEM,
            //         nonsnItem: {
            //             ...nonsnItem,
            //             resourceID: misc.newUUID(),
            //             type: nonsngeneric.type,
            //             description: nonsngeneric.description,
            //             key: nonsngeneric.key
            //         }
            //     }
            // })(nonsnItem));
            // dispatch(updateError(""));
            // dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());

            SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.nonSerialisedStock, misc.LogResult.fail,
                `{userObject} has failed to enter {stockObject} at {locationObject} while {networkObject}.`
                , {
                    userObject: misc.getUserObject(),
                    stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                    networkObject: misc.getNetworkObject(),
                    stockObject: {
                        "Stock Type": "Non-Serialised",
                        "Stock Code": nonsnItem.StockCode,
                        "Description": nonsnItem.Description
                    }
                });
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addNonSnItem", err);
        });

    }
}

/**
 * @function updateNonSnItem update a new non-sn under current subtask item to store and update sync
 * @param {*} allTasks, it's task redux store state
 * @param nonsnItem non sn item to update, sample data: {
            StockCode: "SKB001",
            Description: "cable",
            QtyUnit: "Meter",
            GoodQty: 0,
            FaultyQty: 0,
            resourceID: "1a14c15e806fb31d83b36355d144",
            key: "NonSN",
            type: "METADATA",
            description: "Non-serialised Item"
 }
 */
export function updateNonSnItem(allTasks, nonsnItem) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        //save to redux store state first and not wait for saving completed to improve UI repsonsivness 
        dispatch(((nonsnItem) => {
            return {
                type: Actions.UPDATE_NONSN_ITEM,
                nonsnItem
            }
        })(nonsnItem));
        dispatch(updateError(""));
        dispatch(requestHasResponded());

        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));

        //only push dataitem to server when user works on different resourceid
        const lastResourceID = localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
        if (lastResourceID && nonsnItem && nonsnItem.resourceID && lastResourceID != '' && lastResourceID != nonsnItem.resourceID) {
            dataSync.pushDataItemToServer(lastResourceID).then(() => {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, ModuleInfo.category, "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server', '');
            }).catch(err => {
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "Failed to push dataItem to Server", err);
            });
        }

        //non-sn item in sync table is using format string "StockCode|Description|UnitType|QtyGood|QtyFault", ie: SKB001|cable|Meter|0|0
        //set toTryToSaveToServer as false in saveDataItem for performance tuning
        return dataSync.saveDataItem(nonsnItem.resourceID, currentSubTask.resourceID, nonsngeneric.key,
            `${nonsnItem.StockCode}|${nonsnItem.Description}|${nonsnItem.QtyUnit}|${nonsnItem.GoodQty}|${nonsnItem.FaultyQty}`,
            nonsngeneric.type, nonsngeneric.description, true, false)
            .then(() => {
                // dispatch(((nonsnItem) => {
                //     return {
                //         type: Actions.UPDATE_NONSN_ITEM,
                //         nonsnItem
                //     }
                // })(nonsnItem));
                // dispatch(updateError(""));
                // dispatch(requestHasResponded());
                localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server', nonsnItem.resourceID);

            }).catch(err => {
                dispatch(updateError(err));
                dispatch(requestHasResponded());

                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.nonSerialisedStock, misc.LogResult.fail,
                    `{userObject} has failed to update {stockObject} at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                        networkObject: misc.getNetworkObject(),
                        stockObject: {
                            "Stock Type": "Non-Serialised",
                            "Stock Code": nonsnItem.StockCode,
                            "Description": nonsnItem.Description
                        }
                    });

                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateNonSnItem", err);
            });

    }
}


/**
 * @function deleteNonSnItem delete a new non-sn under current subtask item to store and update sync
 * @param {*} allTasks, it's task redux store state
 * @param nonsnItem non sn item to delete, sample data: {
            StockCode: "SKB001",
            Description: "cable",
            QtyUnit: "Meter",
            GoodQty: 0,
            FaultyQty: 0,
            resourceID: "1a14c15e806fb31d83b36355d144",
            key: "NonSN",
            type: "METADATA",
            description: "Non-serialised Item"
 }
 */
export function deleteNonSnItem(allTasks, nonsnItem) {
    return (dispatch, getState) => {
        dispatch(requestHasSent());
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        //push dataitem to server for previous user action (if any)
        const lastResourceID = localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
        if (lastResourceID && nonsnItem && nonsnItem.resourceID && lastResourceID != '' && lastResourceID != nonsnItem.resourceID) {
            dataSync.pushDataItemToServer(lastResourceID).then(() => {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, ModuleInfo.category, "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server', '');
            }).catch(err => {
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "Failed to push dataItem to Server", err);
            });
        }
        return dataSync.deleteDataItem(nonsnItem.resourceID).then(() => {
            dispatch(((nonsnItem) => {
                return {
                    type: Actions.DEL_NONSN_ITEM,
                    nonsnItem
                }
            })(nonsnItem));
            dispatch(updateError(""));
            dispatch(requestHasResponded());

        }).catch(err => {
            dispatch(updateError(err));
            SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.nonSerialisedStock, misc.LogResult.fail,
                `{userObject} has failed to delete {stockObject} at {locationObject} while {networkObject}.`
                , {
                    userObject: misc.getUserObject(),
                    stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
                    networkObject: misc.getNetworkObject(),
                    stockObject: {
                        "Stock Type": "Non-Serialised",
                        "Stock Code": nonsnItem.StockCode,
                        "Description": nonsnItem.Description
                    }
                });
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "deleteNonSnItem", err);
        });
    }
}

/** @function requestHasSent the action to call when an async request is sent, isloading state needs to be set to true */
export function requestHasSent() {
    return {
        type: Actions.REQUEST_SENT
    }
}

/** @function requestHasResponded the action to call when an async request has responded, isloading state needs to be set to false */
export function requestHasResponded() {
    return {
        type: Actions.REQUEST_RESPONDED
    }
}

/** @function changeCurrentTask the action to call when switch between task 
 * @param taskResourceIdToSwitchTo the task resoiurce ID to switch to
 */
export function changeCurrentTask(taskResourceIdToSwitchTo) {

    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "change current task", { taskResourceId: taskResourceIdToSwitchTo });
    return {
        type: Actions.TASK_CHANGE,
        taskResoureId: taskResourceIdToSwitchTo
    }
}

/** @function changeCurrentSubTask the action to call when switch between task 
 * @param taskResourceIDToSwitchTo the task resoiurce ID to switch to
 */
export function changeCurrentSubTask(taskResourceIdToSwitchTo) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "change current sub task", { subtaskResourceId: taskResourceIdToSwitchTo });
    return {
        type: Actions.SUBTASK_CHANGE,
        subTaskResourceId: taskResourceIdToSwitchTo
    }
}


/**
 * @function updateSignoffSig add or update sign off info include sig and who signed off
 * @param {*} allTasks, it's task redux store state
 * @param sig sig to update/add, sample data: {
            SignoffBy: "my name",
            Sig: "sign data",
            arrayIndex:'1,0',
            resourceID: "1a14c15e806fb31d83b36355d144",
            SigResourceId: '1a14c15e806fb31d83b36355d145'
            key: "SignoffSig",
            type: "METADATA",
            description: "task sign-off signature"
 }
 */
export function updateSignoffSig(allTasks, sig,syncIsOn=false) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateSignoffSig", "update Signoff Sig", sig);

    return (dispatch, getState) => {
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        if (!sig.resourceID) sig.resourceID = misc.newUUID();
        sig = {
            ...sig,
            key: "SignoffSig",
            arrayIndex: "1,0",
            type: DataType.METADATA,
            description: "task sign-off signature",
            SigResourceId: sig.SigResourceId || misc.newUUID()
        };

        dispatch(((sig) => {
            return {
                type: Actions.UPDATE_SIGNOFF_SIG,
                sig
            }
        })(sig));
 
        return dataSync.saveDataItemWithIndex(sig.resourceID, currentSubTask.resourceID, sig.key, `${sig.SignoffBy}`, sig.type, sig.arrayIndex, sig.description).then(() => {
            // dispatch(((sig) => {
            //     return {
            //         type: Actions.UPDATE_SIGNOFF_SIG,
            //         sig
            //     }
            // })(sig));
            loadTaskStoreDataFromSync(true,false,syncIsOn);
            //update sig
            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateSignoffSig", "update Signoff Sig photo", sig);

            dataSync.saveDataItemWithIndex(sig.SigResourceId, currentSubTask.resourceID, sig.key, `${sig.Sig.replace("data:image/png;base64,", "")}`, DataType.PHOTO, "1,1", sig.description).then(() => { }).catch(perror => {
                dispatch(updateError(perror));
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateSignoffSig - sign sig", perror);
            });
            dispatch(updateError(""));

        }).catch(err => {
            dispatch(updateError(err));
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateSignoffSig", err);
        });


    }
}

/**
 * @function updateLockedby update lockbe info
 * @param {*} allTasks, it's task redux store state
 * @param  lockedBy, id of who locks the task,, 0: means to release lock
 *          sample data: {
                resourceID: "1a14c15e806fb31d83b36355d144",
                key: "LockedBy",
                type: "METADATA",
                description: "Locked by",
                LockedBy: 0
 }
 */
export function updateLockedby(allTasks, lockedBy) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateLockedby", "updated locked by", lockedBy);

    return (dispatch, getState) => {
        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        if (!lockedBy.resourceID) lockedBy.resourceID = misc.newUUID();
        lockedBy = { ...lockedBy, key: "LockedBy", type: "METADATA", description: "Locked by" };

        return dataSync.saveDataItem(lockedBy.resourceID, currentSubTask.resourceID, lockedBy.key, `${lockedBy.LockedBy}`, lockedBy.type, lockedBy.description).then(() => {
            dispatch(((lockedBy) => {
                return {
                    type: Actions.LOCKEDBY_UPDATE,
                    lockedBy
                }
            })(lockedBy));
            dispatch(updateError(""));

        }).catch(err => {
            dispatch(updateError(err));
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateLockedby", err);
        });
    }
}


/**
 * @function changeStep add or update sign off info include sig and who signed off
 * @param {*} allTasks, it's task redux store state
 * @param stepId step to switch to. the id is from Steps[idx].StepId
 */
export function changeStep(allTasks, stepId) {
    return (dispatch, getState) => {

        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        var currentStep = currentSubTask.CurrentStep;

        //push dataitem to server for previous user action (if any)
        const lastResourceID = localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
        if (lastResourceID && lastResourceID != '') {
            dataSync.pushDataItemToServer(lastResourceID).then(() => {
                SkbLogger.applicationTrace(ModuleInfo.moduleName, ModuleInfo.category, "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server', '');
            }).catch(err => {
                SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "Failed to push dataItem to Server", err);
            });
        }

        if (!currentStep || !currentStep.resourceID) currentStep = {
            StepId: stepId,
            resourceID: misc.newUUID(),
            key: "CurrentStep",
            type: "METADATA",
            description: "current step",

        }
        else {
            currentStep = { ...currentStep, StepId: stepId };
            if (!currentStep.resourceID) currentStep.resourceID = misc.newUUID();
        }

        dispatch(((currentStep) => {
            return {
                type: Actions.STEP_CHANGE,
                step: currentStep
            }
        })(currentStep));

        return dataSync.saveDataItem(currentStep.resourceID, currentSubTask.resourceID, currentStep.key, currentStep.StepId.toString(), currentStep.type, currentStep.description).then(() => {
            // dispatch(((currentStep) => {
            //     return {
            //         type: Actions.STEP_CHANGE,
            //         step: currentStep
            //     }
            // })(currentStep));
            dispatch(updateError(""));

            // //when steps is changed and in online module,  refresh task from local DB (service worker will sync data from server periodically)
            // if(navigator.onLine) // 
            //     dispatch(loadTasksFromSync(false));

        }).catch(err => {
            dispatch(updateError(err));
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "changeStep", err);
        });
    }
}

/**
 * @function showTaskDetails to show or hide task details
 * @param {*} showDetails, boolean, true: show details, false: show tasks
 */
export function showTaskDetails(showDetails) {
    return {
        type: Actions.SHOW_TASKDETAILS,
        showDetails: showDetails
    }
}


export function updateLastSelectedStockCode(stockCode) {
    return {
        type: Actions.UDPATE_LASTSELECTEDSTOCKCODE,
        lastSelectedStockCode: stockCode
    }
}

export function refreshCurrentTaskStatusFromSync(taskResourceId) {
    return (dispatch) => {
        dispatch(requestHasSent());
        return loadTaskStatusFromSync(taskResourceId).then((status) => {
            SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "refreshCurrentTaskStatusFromSync", "load current task status from sync successfully", `Task cluster resource ID:${taskResourceId}`);

            dispatch(((status) => {
                return {
                    type: Actions.CURRENTTASK_STATUS_REFRESHEDFROMSYNC,
                    data: { ...status }
                }
            })(status));


            //dispatch(refreshStore(tasks));
            dispatch(requestHasResponded());
            dispatch(updateError(""));
        }).catch(err => {
            dispatch(updateError(err));
            dispatch(requestHasResponded());
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "refreshCurrentTaskStatusFromSync", `Task cluster resource ID:${taskResourceId}`, err);
        });

    };
}


/**
 * @function updateStocktakeStatus
 * @param {*} allTasks, it's task redux store state
 * @param {*} taskStatus: new task status, options:"Not Started", "In Progress", "Done"
 */
export function updateStocktakeStatus(allTasks, taskStatus) {
    SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "updateStocktakeStatus", "stocktake tasks status update to", taskStatus);

    return async(dispatch, getState) => {

        // var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));
        var currentTask = getCurrentTask(getTaskStateWhenAvailable(getState, allTasks));

        let taskCompleteTimeInMelbourneTimeZone = ''
        if (taskStatus === Status.Done) { //update task completion date
            taskCompleteTimeInMelbourneTimeZone = misc.currentTimeToUTCTimeInString(misc.StringFormat.ServerDateFormat);

        }
        var completionDateResourceId = currentTask.CompletedDate.resourceID;
        if(!completionDateResourceId){//when it's null, try to get it from server 
            var completionDataItem = await dataSync.queryDataItemsByKeysForCluster(currentTask.resourceID, currentTask.CompletedDate.key, true);
            if(!completionDataItem || completionDataItem.length == 0){
                completionDateResourceId = misc.newUUID();
            }else {
                completionDateResourceId = completionDataItem[0].resourceID;
            }
        }

        dataSync.saveDataItem(completionDateResourceId, currentTask.resourceID, currentTask.CompletedDate.key, taskCompleteTimeInMelbourneTimeZone, currentTask.CompletedDate.type, currentTask.CompletedDate.description).catch(error => {
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateStocktakeStatus -> save task completion time", error);
        });

        var taskStatusResourceId = currentTask.StocktakeSatus.resourceID;
        if(!taskStatusResourceId){//when it's null, try to get it from server 
            var statusDataItem = await dataSync.queryDataItemsByKeysForCluster(currentTask.resourceID, currentTask.StocktakeSatus.key, true);
            if(!statusDataItem || statusDataItem.length == 0){
                taskStatusResourceId = misc.newUUID();
            }else {
                taskStatusResourceId = statusDataItem[0].resourceID;
            }
    }

        //update taskstatus before save into index db for it's slow 
        dispatch(((taskStatus) => {

            SkbLogger.logDebugInfo("stocktake status Update", taskStatus);
            return {
                type: Actions.STOCKTAKESTATUS_UPDATE,
                stocktakeStatus: taskStatus
            }
        })(taskStatus));

        
        //update taskstatus before save into index db for it's slow 

        if(taskStatusResourceId != currentTask.StocktakeSatus.resourceID || completionDateResourceId != currentTask.CompletedDate.resourceID){
            dispatch(((completionDateResourceId, taskStatusResourceId) => {

                SkbLogger.logDebugInfo("stocktake status Update", taskStatus);
                return {
                    type: Actions.TASK_STATUSCOMPLETIONDATERESOURCEID_UPDATE,
                    data: {
                        completionDateResourceId,
                        taskStatusResourceId
                    }
                }
            })(completionDateResourceId, taskStatusResourceId));
       }
        return dataSync.saveDataItem(taskStatusResourceId, currentTask.resourceID, currentTask.StocktakeSatus.key, taskStatus, currentTask.StocktakeSatus.type, currentTask.StocktakeSatus.description).then(() => {
            // dispatch(((taskStatus) => {

            //     SkbLogger.logDebugInfo("TaskStatus Update", taskStatus);
            //     return {
            //         type: Actions.TASKSTATUS_UPDATE,
            //         taskStatus: taskStatus
            //     }
            // })(taskStatus))
        }).catch(err => {
            SkbLogger.logDebugInfo("stocktake status Update error:", err);

            dispatch(updateError(err));

            // SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.taskList, misc.LogResult.fail,
            //     `{stockLocationObject} stocktake status is failed to changed to "${taskStatus}".`
            //     , {
            //         stockLocationObject: misc.getTaskLocationObject(getCurrentTask(getTaskStateWhenAvailable(getState, allTasks)), currentSubTask),
            //     });

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateStocktakeStatus", err);
        });


    }
}




/**
 * @function reopenTask
 * @param {*} allTasks, it's task redux store state
 * @summary  repoen task will reset all status to "In Process"
 */
export function reopenTask(allTasks) {

    return (dispatch, getState) => {

        var currentSubTask = getCurrentSubTask(getTaskStateWhenAvailable(getState, allTasks));

        SkbLogger.applicationTrace(ModuleInfo.moduleName, SeverityLevel.Information, ModuleInfo.category, "reopenTask", currentSubTask);

        //update taskstatus before save into index db for it's slow 
        //update each of step to "IN PROCESS"
        currentSubTask.Steps.forEach(s => {
            dispatch(updateTaskStep(allTasks, s.StepId, Status.InProgress, "", ""));
        });

        //reset sign off sig
        let sig = { ...currentSubTask.SignoffSig, Sig: null, SignoffBy: "" };
        dispatch(updateSignoffSig(allTasks, sig));

        //reset current step to 
        dispatch(changeStep(allTasks, currentSubTask.Steps[0].StepId));
    }
}

/**
 * @function consolidateTaskStepsWithConfig consolidte with Steps from taskConfig.json 
 * @param {string} taskType the tasktype shall be in taskConfig file, the type is case-sensitive
 * @param {Array} stepsFromSync the steps have been loaded from sync modules
 * @returns {Array} task steps
 * @summary task steps used in UI are controled by setting in task config, if steps have not been available on server, the missing steps will be initialised using setting in taskConfig
 */
function consolidateTaskStepsWithConfig(taskType, stepsFromSync) {
    const taskSetting = taskConfig[taskType];

    if (!taskSetting) throw (`Task type ${taskType} has not been setup in task config`);
    try {
        let differences = _.differenceWith(taskSetting.TaskDetails.Steps, stepsFromSync, (a, b) => a.StepId === b.StepId);
        differences.forEach((step, idx) => {

            stepsFromSync.push({
                StepId: parseInt(step.StepId),

                StepDescription: step.StepName,
                Status: Status.NotStarted, // it will be always "NotStarted" when steps are initialised from task config
                resourceID: misc.newUUID(),
                key: "Step",
                type: "METADATA",
                description: step.StepName
            });

        });
        stepsFromSync.sort((a, b) => a.StepId < b.StepId ? -1 : 0);

        return stepsFromSync;

    } catch (error) {
        throw (`Invalid task step config for task type ${taskType}`);
    }

}

