import produce from 'immer'; // immer is used to manage immutaition tasks store
import { SkbLogger, ApiService } from '../../../services';


import { ActionTypes } from '../../actions/returnsdashboard';

/**
 * Reducers related to Returns Dashboard functionality
 */

const initialStateReturnsDashboardList = {
    
  "returnsList": [],
  "returnsListModified":  [],
  "returnsListSelectedTask": [],
  "returnsListLoadStatus": false,
  "errorApiFlag": false,
  "errorApiMessage": "",
  "approveApiSuccessReceiveStatus": false,
  "returnsListActiveFilterAr": [],

  "returnsListStatusActiveFilterAr":[],
  // for pie chart status related
  "pieChartRelStatusNameDataAr": [],
  "pieChartStatusNameGroupedCountDataAr":[],
  // for pie chart age of return related
  "pieChartRelAgeOfRetRangeDataAr":[],
  "pieChartAgeOfRetGroupedCountDataAr":[],
  "pieAgeOfRetRangeColorCodeAr":[],
  "pieChartAgeOfRetRangeWithCountDataGrouped":[],
  //for task cancel flow related
  "cancelApiSuccessReceiveStatus": false,
  "cancelTaskSubmitLoaderStatus":false



  }

export const returnsDashboardList =  (state = initialStateReturnsDashboardList, action) => {    
    switch (action.type) { 
      
      case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_LIST_LOAD:
         
          return Object.assign({}, state, {
            returnsList: action.payload
          }); 
          
     case ActionTypes.SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_MODIFIED: 

          return Object.assign({},state,{
            returnsListModified: action.payload
          });   
               
     case ActionTypes.SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_ON:
      
      return Object.assign({}, state, {          
        returnsListLoadStatus: true
      });

    case ActionTypes.SET_CONTRACTOR_STOCK_RETURN_TASK_LIST_LOADER_OFF:
      
        return Object.assign({}, state, {          
          returnsListLoadStatus: false
        });   
        
    case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_SELECT:
          return Object.assign({},state,{
            returnsListSelectedTask: action.payload
          });
    case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS:
      return Object.assign({},state,{
        approveApiSuccessReceiveStatus: true
      });
    case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_APPROVE_SUCCESS_UNSET:
      return Object.assign({},state,{
        approveApiSuccessReceiveStatus: false
      });

    case ActionTypes.PIE_CHART_ON_STATUS_RELATED_DATA_SET:
          return Object.assign({},state,{
            pieChartRelStatusNameDataAr: action.payload.pieChartRelStatusNameDataAr!==undefined?action.payload.pieChartRelStatusNameDataAr:[],
            pieChartStatusNameGroupedCountDataAr: action.payload.pieChartStatusNameGroupedCountDataAr!==undefined?action.payload.pieChartStatusNameGroupedCountDataAr:[],


          });  
    case ActionTypes.PIE_CHART_ON_AGEOFRETURN_RELATED_DATA_SET:
     
          return Object.assign({},state,{
            pieChartRelAgeOfRetRangeDataAr: action.payload.pieChartRelAgeOfRetRangeDataAr!==undefined?action.payload.pieChartRelAgeOfRetRangeDataAr:[],
            pieChartAgeOfRetGroupedCountDataAr: action.payload.pieChartAgeOfRetGroupedCountDataAr!==undefined?action.payload.pieChartAgeOfRetGroupedCountDataAr:[],
            pieAgeOfRetRangeColorCodeAr: action.payload.pieAgeOfRetRangeColorCodeAr!==undefined?action.payload.pieAgeOfRetRangeColorCodeAr:[],
            pieChartAgeOfRetRangeWithCountDataGrouped: action.payload.pieChartAgeOfRetRangeWithCountDataGrouped!==undefined?action.payload.pieChartAgeOfRetRangeWithCountDataGrouped:[],


          });
    case ActionTypes.SET_RETURN_LIST_STATUS_ACTIVE_FILTERS: 

          return Object.assign({},state,{
            returnsListStatusActiveFilterAr: action.payload
          });
    
    case ActionTypes.CANCEL_TASK_REASON_SUCCESS:
            return Object.assign({},state,{
              cancelApiSuccessReceiveStatus: true
            });
    case ActionTypes.CANCEL_TASK_REASON_SUCCESS_UNSET:
            return Object.assign({},state,{
              cancelApiSuccessReceiveStatus: false
            });
    case ActionTypes.CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_ON:
      
              return Object.assign({}, state, {          
                cancelTaskSubmitLoaderStatus: true
              });
      
    case ActionTypes.CANCEL_TASK_REASON_SUBMIT_LOAD_LOADER_OFF:
            
              return Object.assign({}, state, {          
                cancelTaskSubmitLoaderStatus: false
              });

      
    default:
        return state
    }
  }







const initialStateReturnStockSlaDays = {
    
    "slaDaysList": [],    
    "slaDaysListLoadStatus": false,
    "errorApiFlag": false,
    "errorApiMessage": ""
    }

export const returnStockSlaDays =  (state = initialStateReturnStockSlaDays, action) => {    
      switch (action.type) { 
        
        case ActionTypes.SET_RETURN_STOCK_SLA_DAYS:
           
            return Object.assign({}, state, {
              slaDaysList: action.payload
            });            
      
                 
       case ActionTypes.SET_RETURN_STOCK_SLA_DAYS_LOADER_ON:
        
        return Object.assign({}, state, {          
          slaDaysListLoadStatus: true
        });

      case ActionTypes.SET_RETURN_STOCK_SLA_DAYS_LOADER_OFF:
        
          return Object.assign({}, state, {          
            slaDaysListLoadStatus: false
          });
  
  
        
        default:
          return state
      }
    }

const initialStateCancelReasonOptns = {
    
      "cancelReasonOptionList": [],    
      "cancelReasonOptionListLoadStatus": false,
      "errorApiFlag": false,
      "errorApiMessage": ""
      }
  
export const returnDashListCancelReasonOptions =  (state = initialStateCancelReasonOptns, action) => {    
        switch (action.type) { 
          
          case ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD:
             
              return Object.assign({}, state, {
                cancelReasonOptionList: action.payload
              });            
        
                   
         case ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_ON:
          
          return Object.assign({}, state, {          
            cancelReasonOptionListLoadStatus: true
          });
  
        case ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD_LOADER_OFF:
          
            return Object.assign({}, state, {          
              cancelReasonOptionListLoadStatus: false
            });
        case ActionTypes.CANCEL_REASON_OPTIONS_LIST_LOAD_UNSET:
          
            return Object.assign({}, state, {          
              cancelReasonOptionList: []
            });
    
    
          
          default:
            return state
        }
     
     
      }