import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SkbButton from './SkbButton';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    }
}));

const titleBarStyles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth: 300
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
});

const DialogTitle = withStyles(titleBarStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

/**
 * 
 * @property {message}: message to show (can contain line breaks represented as \n)
 * @property {open}: true: show alert window, false: hide alert window
 * @property {openConfirm}: alert window open sate update handler;
 * @property {okHandler}: call back funciton when OK is clicked
 * @property {title}: title for the confirm window, if it is not set, default value "Please Confirm" is used 
 * @property {okLabel}: label for OK Button - default is "Ok". 
 * @property {cancelLabel}: label for Cancel Button - default is "Cancel".
 * @property {okButtonIsFirst}: OK button shown before Cancel button.  If false then the other way round.
 * @property {okButtonIsHighlighted}: OK button shown more prominently (for example, a brighter colour).  If false then Cancel shown more prominently.  
 * * @property {okButtonIsFocussed}: OK button has focus by default.  If false then Cancel has focus.  
 */
export default function SkbConfirmDialog({ message, open, openConfirm, okHandler, title, okLabel="Ok", cancelLabel="Cancel", cancelHandler,
                                                    okButtonIsFirst=true, okButtonIsHighlighted=true, okButtonIsFocussed=false }) {
    //const [open, setOpen] = React.useState(false);

    const handleClickOk = () => {
        openConfirm(false);
        if (okHandler) okHandler();
    };

    const handleClose = () => {
        // console.log("cancelled");
        openConfirm(false);
        if (cancelHandler) cancelHandler();
    };

    const theme = useTheme();
    const classes = useStyles();

    const okButton=<SkbButton id="ok-button" outlined={!okButtonIsHighlighted} primary size="medium" text={okLabel ||"Ok"} onClick={handleClickOk} autoFocus={okButtonIsFocussed}/>;
    const cancelButton=<SkbButton id="cancel-button" primary outlined={okButtonIsHighlighted} text={cancelLabel||"Cancel"} size="medium" onClick={handleClose} autoFocus={!okButtonIsFocussed} />;

    var formattedMessageText=""

    if (message!==null && message!==undefined)
    {
        formattedMessageText=message.split('\n').map(text => {
            return <Typography gutterBottom>{text}</Typography>
        });
    }

    return (
        <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.paper }}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title || "Please Confirm"}
                </DialogTitle>
            <DialogContent dividers>
                
                  {formattedMessageText}

            </DialogContent>
            
                {okButtonIsFirst?(
                <DialogActions>
                   {okButton}
                    {cancelButton}
                </DialogActions>
                )
                :
                <DialogActions>
                    {cancelButton}
                    {okButton}
                </DialogActions>
                }
            

        </Dialog>
    );
}
