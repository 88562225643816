import CourierIcon from '@material-ui/icons/LocalShipping';
import DropOffIcon from '@material-ui/icons/DirectionsWalk';

export const getDeliveryMethodIcon=(deliveryMethod)=>{
    if (deliveryMethod.toLowerCase()==="skybridge courier")
    {
        return CourierIcon;
    }
    else if (deliveryMethod.toLowerCase()==="contractor drop-off")
    {
        return DropOffIcon;
    }
    return CourierIcon;
        

}


export const buildAddressInOneLine=(addressLine1, addressLine2, suburb, state, postcode)=>{
    var result=addressLine1;
    if (addressLine2 && addressLine2!=="")
    {
        result=result+", "+ addressLine2;
    }
    
    result=result+", "+suburb+" "+state+" "+postcode;
    
    return  result;
}
