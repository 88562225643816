import { Actions } from '../actions/auth';
let initialStatus = { AuthUser: null, User: null, authenticated: false, isLoading: true, Modules: null, error:"",   idToken: null };
export function auth(state = initialStatus, action) {
    switch (action.type) {
        case Actions.SET_LOGIN_USER:
            return { ...state, AuthUser: action.authUser, authenticated: true };
        case Actions.GET_USERINFO_COMPLETED:
            return { ...state, User: action.user, isLoading: false };
        case Actions.CLEAR_LOCAL_USERTOKEN:
            return { ...initialStatus };
        case Actions.GET_USERMODULES_COMPLETED:
            return { ...state, Modules: action.modules }
        case Actions.SET_ID_TOKEN:
            if (state.idToken) return state; //if it's already been set, do not need to set it again
            return { ...state, idToken: action.IdToken }
        case Actions.REQUEST_BEGIN:
            return { ...state, isLoading: true, error:"" };
        case Actions.REQUEST_END:
            return { ...state, isLoading: false, error:action.error };
        default:
            return state;
    }
}