import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import * as auth from '../Redux/actions/auth';

//import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    },


    icon: {
        minWidth: theme.spacing(1),
        marginRight: 4,
        marginTop: 4
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1301, // the loading indicator needs to show on topmost window

    },
}));
function Loading(props) {
    const classes = useStyles();

    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        function tick() {
            // reset when reaching 100%
            setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <BodyEnd><Backdrop className={classes.backdrop} open={true} invisible={true}>
        {/* <CircularProgress id="primary-loading-icon" variant="determinate" value={progress} color="primary" /> */}
        <CircularProgress id="primary-loading-icon" color="primary" />

    </Backdrop></BodyEnd>);
}

/**
 * @class {BodyEnd} render component at html body level
 */
class BodyEnd extends React.Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        //this.el.style.display = 'contents';
    }
    
    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }
    
    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el,
        );
    }
}

const mapStateToProps = state => ({
    User: state.auth,
});
/**
 * Loading component is deprecated, please use SkbLoading instead  
 */
export default connect(mapStateToProps, null)(Loading);