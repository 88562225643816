import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as auth from '../../Redux/actions/auth';
import { SkbLogger } from '../../services';
export default function (ComposedComponent,auth0) {  //auth0 client from index.js

    class Authentication extends Component {

        componentWillMount() {

            // let iscallback = /code=/.test(window.location.href);
            
            // if (!this.props.authenticated && !iscallback) {
            //     this.props.history.push("/auth");

            // } 
            let iscallback = /code=/.test(window.location.href);
            SkbLogger.logDebugInfo('requireAuth.componentWillMount check',iscallback, this.props.authenticated, auth.isAuthenticated())

            if( !iscallback && !this.props.authenticated && !auth.isAuthenticated()) {
                
                this.props.history.push("/auth");
            }
        }

        componentWillUpdate(nextProps) {
            
            // let iscallback = /code=/.test(window.location.href);
            // if (!nextProps.authenticated & !iscallback) {
            //     this.props.history.push("/auth");
            // } 
            // let iscallback = /code=/.test(window.location.href);
            // console.log('dfdf', iscallback, this.props.authenticated, auth.isAuthenticated())

            // if( !iscallback && !this.props.authenticated && !auth.isAuthenticated()) this.props.history.push("/auth");

        }

        render() {
            return <ComposedComponent {...this.props} />
        }
    }

    function mapStateToProps(state) {
        return { authenticated: state.auth.authenticated, registered: state.auth.User };
    }


    return connect(mapStateToProps)(Authentication);
}
