import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SkbLogger, SeverityLevel } from '../../services';
import * as misc from '../../utils/misc';


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import SkbButton from '../../skb_controls/components/SkbButton';
import SkbSignature from '../../skb_controls/components/SkbSignature'
import * as taskActions from '../../Redux/actions/tasks';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },

}));


export function StepSignOff({ Tasks, CurrentSubtask, StepConfig, User, updateSig, updateTaskStep, loadCurrentTaskSignoffSig, loadCurrentTaskSnItems, loadCurrentTaskNonSnItems}) {
    const classes = useStyles();

    const [existingSigData, setExistingSigData] = useState();
    const saveSigCallback = (sigData) => { //callback function to pass to SkbSignature

        let sig = null;
        if (CurrentSubtask.SignoffSig) {
            sig = { ...CurrentSubtask.SignoffSig, SignoffBy: User.name, Sig: sigData };
        } else {
            sig = {
                Name: User.name, Sig: sigData
            }
        }

        SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.moduleName, misc.TaskLogInfo.category.signoff, misc.LogResult.fail,
            `{userObject} has signed the signature at {locationObject} while {networkObject}.`
            , {
                userObject: misc.getUserObject(),
                locationObject: misc.getLocationObject(),
                networkObject: misc.getNetworkObject()
            });
        //needs to upate step status to inprogress
        var msg = "User update signature after complete.  Status changing back to In Progress.";

        SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Info, "", msg, CurrentSubtask);

        updateTaskStep(Tasks, CurrentSubtask.CurrentStep.StepId, taskActions.Status.InProgress);

        updateSig(Tasks, sig);
    }

    useEffect(() => {
        loadCurrentTaskSignoffSig();
        if (!CurrentSubtask.SerialisedItems) loadCurrentTaskSnItems();
        if (!CurrentSubtask.NonSerialisedItems) loadCurrentTaskNonSnItems();

    }, []);

    useEffect(() => {
        if (CurrentSubtask.SignoffSig) setExistingSigData(CurrentSubtask.SignoffSig.Sig);
        else setExistingSigData(null);

    }, [CurrentSubtask.SignoffSig]);
    return (
        <Grid container className={classes.root}>


            <Grid item md={12} sm={12} xs={12} id="task_stepper_header">
                {/* <Typography variant="h5">Sign off placeholder: TODO</Typography> */}

                <SkbSignature sig={existingSigData} saveCallback={saveSigCallback} />

            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    Tasks: state.tasks,
    User: state.auth.User,
    CurrentTask: taskActions.getCurrentTask(state.tasks),
    CurrentSubtask: taskActions.getCurrentSubTask(state.tasks)
});


const mapDispatchToProps = (dispatch) => {
    return {
        //backToTaskList: () => { dispatch(taskActions.showTaskDetails(false)); },
        updateSig: (tasks, sig) => { dispatch(taskActions.updateSignoffSig(tasks, sig)); },
        updateTaskStep: (allTasks, stepId, status) => dispatch(taskActions.updateTaskStep(allTasks, stepId, status)),
        loadCurrentTaskSignoffSig: () => dispatch(taskActions.loadCurrentTaskSignoffSig(false)),
        loadCurrentTaskNonSnItems: () => dispatch(taskActions.loadCurrentTaskNonSnItems(false)),
        loadCurrentTaskSnItems: () => dispatch(taskActions.loadCurrentTaskSnItems(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepSignOff);