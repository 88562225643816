import React from "react";
//import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux';
import * as auth from '../../Redux/actions/auth'

const Logout = ({dispatch, auth0}) => { //auth0 client from index.js
  
     React.useEffect(() => {
       dispatch(auth.clearLocalUserToken());
        auth0.logout();
      
    });
  

    return (<div/>);
};

export default connect()(Logout);