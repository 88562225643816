import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SkbStepper from '../../../skb_controls/components/SkbStepper';
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';
import Loading from '../../Loading';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import Typography from '@material-ui/core/Typography';
import StepStockPreview from './StepStockPreview';
import StepSerialisedItems from './StepSerialisedItems';
import StepNonSerialisedItems from './StepNonSerialisedItems';
import StepStockReview from './StepStockReview';
import StepPackageDetails from './StepPackageDetails';
import BusinessIcon from '@material-ui/icons/Business';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import * as pickingAndPacking from '../../../utils/returnstock/pickingAndPacking';
import * as taskActions from '../../../Redux/actions/tasks';
import * as returnStockUtils from '../../../utils/returnstock/misc';

import {PickingAndPackingStepNames} from '../../../utils/returnstock/pickingAndPacking';

const useStyles = makeStyles((theme) => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
            '& >div.MuiGrid-item:not(:first-child)': {
                borderTop: `1px solid ${theme.palette.text.primary}`,
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                // '&:last-child':{
                //     borderBottom: `1px solid ${theme.palette.text.primary}`,

                // },
            },
        },
        title: {
            textAlign: "left",
        },
        safari: {
            height: '-webkit-fill-available',
        },
        avatarIcon: {

            display: 'inline-block',
            verticalAlign: 'middle',
            [theme.breakpoints.down('sm')]: {
               fontSize: 24
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: 30
            },
        },
        cancelIcon: {
            clear: "none",
            float: "right"
        },

    }
));

function checkMissingProperties(expectedProps, actualProps) {
    const missingProps = _.without(expectedProps, ...Object.keys(actualProps));

    if (_.isArray(missingProps) && missingProps.length > 0) return missingProps.toString();

    return "";
}

/**
 * 
 * @param {*} props HOC needs pass taskId, taskId props, either form HOC or from route like '/:taskId/:consignmentId', consignmentId is sub task id of task 
 * @returns 
 */
export function PickingAndPackingSteps(props) {

    console.log("PickingAndPackingSteps", props);
    const { tasks, dispatch, user } = props; //stockConsignment is the current selected consignment
    const params = useParams();
    let taskId = props.taskId;
    let consignmentId = props.consignmentId;
    if (!taskId || !consignmentId) {
        taskId = params.taskId;
        consignmentId = params.consignmentId;
    }
    const task = pickingAndPacking.getTask(tasks, taskId);
    const stockConsignment = pickingAndPacking.getConsignment(tasks, taskId, consignmentId);
    const wizardInfo = {
        DisplayText: `ID: ${stockConsignment.SubTaskId}`,
        Description: returnStockUtils.buildAddressInOneLine(stockConsignment.PickupAddressLine1, 
            stockConsignment.PickupAddressLine2,
            stockConsignment.PickupAddressSuburb,
            stockConsignment.PickupAddressState,
            stockConsignment.PickupAddressPostcode)
    };
    //     const { stepCompletedCallback, ReturnStockTitle } = props;

    //     const expectedProps = ["StepValidators", "taskObjectForLogging", "expectedItems", "loadExpectedItems", "previewInfo", "loadPreviewData", "goToStep", 
    //     "serialisedItems", "loadSerialisedItems", "knownSerialNumbers", "addSerialisedItem", "updateSerialisedItem", "deleteSerialisedItem", "serialisedChangeHandler",
    //     "nonSerialisedItems", "loadNonserialisedItems", "addNonSerialisedItem", "updateNonSerialisedItem", "nonSerialisedChangeHandler", "lastSelectedStockCode", 
    //     "setLastSelectedStockCode", "pickUpDeatails", "loadPickupDetails", "packageDetails", "loadPackageDetails", "addPackage", "updatePackage", "deletePackage",
    //     "updatePickupDetails", "stepCompletedCallback", "ReturnStockTitle", "IsLoading"
    // ];
    //     var missingProps = checkMissingProperties(expectedProps, props);
    //     if(missingProps) throw(`${missingProps} prop is missing`);

    //     if(props.StepValidators){
    //         missingProps = checkMissingProperties(["validateSerialised", "validateNonSerialised", "validateReview", "validatePackage"], props.StepValidators)
    //         if(missingProps) throw(`StepValidators.${missingProps} prop is missing`);
    //     }

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [lastSelectedStockCode, setLastSelectedStockCode] = useState("");
    const [openInfo, setOpenInfo] = React.useState(false);
    const [incompleteStepNames, setIncompleteStepNames] = React.useState([]);
    const [stepToActive, setStepToActive] = React.useState(-1);
    const returnRoute = props.returnRoute || '/home';
    const alterWindowHandler = (toClose) => {

        setOpenInfo(toClose);
        history.push(returnRoute); //naviage back to home window;
    }

    const closePickingAndPackingWindow = () => {      
        var newStatus={
            newStatus:"Awaiting picking",
            nextActionBy:"Contractor"
        }
        pickingAndPacking.updateConsignmentStatus(dispatch,task,stockConsignment,user.User,newStatus);
        //unlock the user from the task
        pickingAndPacking.updateTaskLockedBy(dispatch,task,stockConsignment,0);
        history.push(returnRoute);
    };

    useEffect(() => {
        if (props.Error) {
            enqueueSnackbar(props.Error, {
                variant: "error"
            });
        }
    }, [props.Error]);

    useEffect(()=>{
        if(!stockConsignment.StepsStatus)
            pickingAndPacking.loadTaskStepStatus(dispatch, task, stockConsignment);
    }, [stockConsignment.StepsStatus])
    const classes = useStyles();
    const stepProps = {
        IsLoading: props.IsLoading,
        StepValidators: {
            validateSerialised: () => {
                return pickingAndPacking.validateSerialised(stockConsignment);
            },
            validateNonSerialised: () => {
                return pickingAndPacking.validateNonSerialised(stockConsignment);
            },
            validateReview: () => {
                return pickingAndPacking.validateReview(stockConsignment);
            },
            validatePackage: () => {
                return pickingAndPacking.validatePackage(stockConsignment);
            },
        },
        taskObjectForLogging: stockConsignment,
        expectedItems: pickingAndPacking.getExpectedItems(stockConsignment),
        loadExpectedItems: () => { pickingAndPacking.loadExpectedItems(dispatch, task, stockConsignment); },
        loadPreviewData: () => { pickingAndPacking.loadPreviewData(dispatch, task, stockConsignment); },
        previewInfo: pickingAndPacking.getPreviewInfo(stockConsignment),

        goToStep: (stepId) => { setStepToActive(stepId); },
        serialisedItems: pickingAndPacking.getSerialisedItems(stockConsignment),
        loadSerialisedItems: () => { pickingAndPacking.loadSerialisedItems(dispatch, task, stockConsignment); },
        knownSerialNumbers: pickingAndPacking.getKnownSerialNumbers(stockConsignment),
        addSerialisedItem: (item) => { pickingAndPacking.addSerialisedItem(dispatch, task, stockConsignment, item); },
        updateSerialisedItem: (item) => { pickingAndPacking.updateSerialisedItem(dispatch, task, stockConsignment, item); },
        deleteSerialisedItem: (item) => { pickingAndPacking.deleteSerialisedItem(dispatch, task, stockConsignment, item); },
        serialisedChangeHandler: () => { 
            setIncompleteStepNames([PickingAndPackingStepNames.SERIALISED,PickingAndPackingStepNames.REVIEW]); 
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.SERIALISED, "InComplete");
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.REVIEW, "InComplete");

        },
        nonSerialisedItems: pickingAndPacking.getNonSerialisedItems(stockConsignment),
        loadNonSerialisedItems: () => { pickingAndPacking.loadNonSerialisedItems(dispatch, task, stockConsignment); },
        addNonSerialisedItem: (item) => { pickingAndPacking.addNonSerialisedItem(dispatch, task, stockConsignment, item); },
        updateNonSerialisedItem: (item) => { pickingAndPacking.updateNonSerialisedItem(dispatch, task, stockConsignment, item); },
        nonSerialisedChangeHandler: () => { 
            setIncompleteStepNames([PickingAndPackingStepNames.NON_SERIALISED,PickingAndPackingStepNames.REVIEW]); 
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.NON_SERIALISED, "InComplete");
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.REVIEW, "InComplete");

        },
        lastSelectedStockCode: lastSelectedStockCode,
        setLastSelectedStockCode: setLastSelectedStockCode,

        pickUpDeatails: pickingAndPacking.getPickUpDeatails(stockConsignment),
        loadPickupDetails: () => { pickingAndPacking.loadPickupDetails(dispatch, task, stockConsignment); },
        packageDetails: pickingAndPacking.getPackageDetails(stockConsignment),
        loadPackageDetails: () => { pickingAndPacking.loadPackageDetails(dispatch, task, stockConsignment); },
        addPackage: (pack) => {
            setIncompleteStepNames([PickingAndPackingStepNames.PACKAGE]);  
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.PACKAGE, "InComplete");
            pickingAndPacking.addPackage(dispatch, task, stockConsignment, pack); 
        },
        updatePackage: (pack) => {
            setIncompleteStepNames([PickingAndPackingStepNames.PACKAGE]); 
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.PACKAGE, "InComplete"); 
            pickingAndPacking.updatePackage(dispatch, task, stockConsignment, pack); 
        },
        deletePackage: (pack) => {
            setIncompleteStepNames([PickingAndPackingStepNames.PACKAGE]);  
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.PACKAGE, "InComplete");
            pickingAndPacking.deletePackage(dispatch, task, stockConsignment, pack); },
        updatePickupDetails: (pickupDetails) => {
            setIncompleteStepNames([PickingAndPackingStepNames.PACKAGE]);  
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.PACKAGE, "InComplete");
            pickingAndPacking.updatePickupDetails(dispatch, task, stockConsignment, pickupDetails); },

        consignmentMethod: stockConsignment.DeliveryMethod,
        stepsStatus: stockConsignment.StepsStatus||{},

        dropOffDetails: pickingAndPacking.getDropOffDetails(stockConsignment),
        loadDropOffDetails : () => { pickingAndPacking.loadDropOffDetails(dispatch, task, stockConsignment); },
        updateDropOffDetails: (dropOffDetails) => {
            setIncompleteStepNames([PickingAndPackingStepNames.PACKAGE]);  
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, PickingAndPackingStepNames.PACKAGE, "InComplete");
            pickingAndPacking.updateDropOffDetails(dispatch, task, stockConsignment, dropOffDetails); 
        },
        deliveryDestination: pickingAndPacking.getDeliveryDestination(stockConsignment),
        defaultPickupDate: pickingAndPacking.getDefaultPickupDate(stockConsignment),
        defaultDeliveryDate: pickingAndPacking.getDefaultDeliveryDate(stockConsignment),
    }


    const steps = getPickingAndPackingSteps(stepProps);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = React.useState("");
    const [confirmTitle, setConfirmTitle] = React.useState("");

    function showConfirmDialog(title, message) {
        setConfirmTitle(title);
        setConfirmMsg(message);

        // setOkHandler(okHandler); //Ideally would pass in the callback function for processing an OK response.
        setOpenConfirm(true);

    }

    const stepCompletedCallback = (step, isCompleted, toConfirm) => {


        if(step.StepName === PickingAndPackingStepNames.NON_SERIALISED){
            pickingAndPacking.commitConsignmentNonSnItems(dispatch, task, stockConsignment);
        }
        else if(step.StepName === PickingAndPackingStepNames.SERIALISED){
            pickingAndPacking.commitConsignmentSnItems(dispatch, task, stockConsignment);
        }
        else if(step.StepName === PickingAndPackingStepNames.PACKAGE){
            pickingAndPacking.commitConsignmentPackages(dispatch, task, stockConsignment);

        }

        if(isCompleted){ //save step status as Complete
            pickingAndPacking.updateTaskStepStatus(dispatch, task, stockConsignment, step.StepName, "Complete");
        }

        if (step.StepName === PickingAndPackingStepNames.PACKAGE && isCompleted && toConfirm) {// it's last step, when it is completed, submit the consignment
            //debugger;

            pickingAndPacking.submitConsignments(dispatch,task, stockConsignment, user.User);
            setOpenInfo(true);//show information dialog;


        }
    }

    return (
        <>
            <Grid container className={classes.root} id="picking_packing_steps">
                <Grid item xs={12} id="picking_packing_title" className={classes.title}>
                    <Typography variant="h5"><SkbIcon classes={{ iconStyle: classes.avatarIcon }} icon={BusinessIcon} />{stockConsignment.ReturnType}
                        <div className={classes.cancelIcon}><SkbIcon onClick={() => { showConfirmDialog('Please Confirm', 'You haven\'t completed picking and packing.  Are you sure you want to close?') }} classes={{ iconStyle: classes.avatarIcon }} icon={CancelOutlinedIcon} /></div>
                    </Typography>

                </Grid>
                <Grid item xs={12} id="picking_packing_steppers" className={classes.safari}>
                    <SkbStepper WizardInfo={wizardInfo} Steps={steps} stepToActive={stepToActive} 
                    stepCompletedCallback={stepCompletedCallback} Mode="NonLinear" incompletedStepNames={incompleteStepNames}
                     />
                </Grid>
            </Grid>

            <SkbConfirmDialog id="Confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm}
                okHandler={closePickingAndPackingWindow}
                okLabel="Yes" cancelLabel="No" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />
            {props.IsLoading && <Loading />}
            <SkbAlertDialog id="info-dialog" title={"Information"} message={"Successfully submitted your return stock task for scheduling."} open={openInfo} openAlert={alterWindowHandler} />
        </>
    );
}

function getPickingAndPackingSteps(props) {
    //shared properties
    const { StepValidators, taskObjectForLogging, expectedItems, loadExpectedItems } = props;
    const IsLoading = false; // showing loading indicator is done in this component directly

    const moduleName = 'PickingAndPacking';

    // properties used in StepStockPreview
    const { previewInfo, loadPreviewData, goToStep } = props;
    const previewColumnHeader = ['Description', 'UOM', 'Expected Status', '# To Be Returned'];
    const emptyDataMessage = 'No items for return!';

    // properties used in StepSerialisedItems
    const { serialisedItems, loadSerialisedItems, knownSerialNumbers, addSerialisedItem, updateSerialisedItem, deleteSerialisedItem, serialisedChangeHandler } = props;

    // properties used in StepNonSerialisedItems
    const { nonSerialisedItems, loadNonSerialisedItems, addNonSerialisedItem, updateNonSerialisedItem,
        nonSerialisedChangeHandler, lastSelectedStockCode, setLastSelectedStockCode } = props;

    // properties used in StepStockReview
    const stepReviewColumnHeaders = ['Description', 'UOM', '# Good', '# Faulty'];

    // propertie used in StepPackageDetails

    const { pickUpDeatails, loadPickupDetails, packageDetails, loadPackageDetails, addPackage, updatePackage, deletePackage, updatePickupDetails, consignmentMethod, dropOffDetails, loadDropOffDetails, updateDropOffDetails, deliveryDestination, defaultPickupDate, defaultDeliveryDate} = props;

    // properties for step validation
    const { validateSerialised, validateNonSerialised, validateReview, validatePackage } = StepValidators;

    //stepsStatus prop
    const {stepsStatus} = props;
    const isStepComplete = (stepName) =>stepsStatus[stepName] === "Complete" ;

    return [
        {
            Title: "Preview",
            StepName: PickingAndPackingStepNames.PREVIEW,
            Completed: isStepComplete(PickingAndPackingStepNames.PREVIEW),
            Description: "Please return all your stock for this location. Below is a list of stock items UGL believes you have:",
            Content: <StepStockPreview isLoading={IsLoading} previewInfo={previewInfo} loadPreviewData={loadPreviewData}
                columnHeaders={previewColumnHeader}
                emptyDataMessage={emptyDataMessage}
                goToStep={goToStep}
                serialisedStepId={1}
                nonSerialisedStepId={2}
            />,
            StepValidator: () => { return { isValid: true }; }
        },
        {
            Title: "Pick serialised stock",
            StepName: PickingAndPackingStepNames.SERIALISED,
            Completed: isStepComplete(PickingAndPackingStepNames.SERIALISED),
            Description: "Please pick and pack serialised stock, scanning the barcodes as you conduct this activity. Please add any entries that do not scan correctly.",
            Content: <StepSerialisedItems
                serialisedItems={serialisedItems}
                isLoading={IsLoading}
                loadSerialisedItems={loadSerialisedItems}
                knownSerialNumbers={knownSerialNumbers}
                addSerialisedItem={addSerialisedItem}
                updateSerialisedItem={updateSerialisedItem}
                deleteSerialisedItem={deleteSerialisedItem}
                onChange={serialisedChangeHandler}
                taskObjectForLogging={taskObjectForLogging}
                moduleName={moduleName}
                stepName={PickingAndPackingStepNames.SERIALISED}
            />,
            StepValidator: validateSerialised
        },
        {
            Title: "Pick Non-Serialised Stock",
            StepName: PickingAndPackingStepNames.NON_SERIALISED,
            Completed: isStepComplete(PickingAndPackingStepNames.NON_SERIALISED),
            Description: "Please pick and pack non-serialised stock and enter in the quantities of items you have packed. Please add any non-serialised stock codes of items that are not listed below.",
            Content: <StepNonSerialisedItems
                expectedItems={expectedItems}
                isLoading={IsLoading}
                loadExpectedItems={loadExpectedItems}
                nonSerialisedItems={nonSerialisedItems}
                loadNonSerialisedItems={loadNonSerialisedItems}
                addNonSerialisedItem={addNonSerialisedItem}
                updateNonSerialisedItem={updateNonSerialisedItem}
                onChange={nonSerialisedChangeHandler}
                lastSelectedStockCode={lastSelectedStockCode}
                setLastSelectedStockCode={setLastSelectedStockCode}
                emptyDataMessage={"No items have been picked!"}
                taskObjectForLogging={taskObjectForLogging}
                moduleName={moduleName}
                stepName={PickingAndPackingStepNames.NON_SERIALISED}
            />,
            StepValidator: validateNonSerialised
        },
        {
            Title: "Review",
            StepName: PickingAndPackingStepNames.REVIEW,
            Completed: isStepComplete(PickingAndPackingStepNames.REVIEW),
            Description: "Please review any variations in what was picked and packed.",
            Content: <StepStockReview
                isLoading={IsLoading}
                serialisedItems={serialisedItems}
                nonSerialisedItems={nonSerialisedItems}
                expectedItems={expectedItems}
                loadSerialisedItems={loadSerialisedItems}
                loadNonSerialisedItems={loadNonSerialisedItems}
                loadExpectedItems={loadExpectedItems}
                columnHeaders={stepReviewColumnHeaders}
                goToStep={goToStep}
                serialisedStepId={1}
                nonSerialisedStepId={2}
                emptyDataMessage={"No items have been picked!"}
            />,
            StepValidator: validateReview
        },
        {
            Title: "Package details",
            StepName: PickingAndPackingStepNames.PACKAGE,
            Completed: isStepComplete(PickingAndPackingStepNames.PACKAGE),
            Description: "Please specify how you packaged your stock.",
            Content: <StepPackageDetails
                isLoading={IsLoading}
                pickupDetails={pickUpDeatails}
                loadPickupDetails={loadPickupDetails}
                packageDetails={packageDetails}
                loadPackageDetails={loadPackageDetails}
                addPackage={addPackage}
                updatePackage={updatePackage}
                deletePackage={deletePackage}
                updatePickupDetails={updatePickupDetails}
                consignmentMethod={consignmentMethod}
                dropOffDetails = {dropOffDetails}
                loadDropOffDetails = {loadDropOffDetails}
                updateDropOffDetails = {updateDropOffDetails}
                deliveryDestination = {deliveryDestination}
                defaultPickupDate = {defaultPickupDate}
                defaultDeliveryDate = {defaultDeliveryDate}                
            />,
            StepValidator: validatePackage
            
        }
    ];
}


const mapStateToProps = state => ({
    user: state.auth,
    //stockConsignment: taskActions.getCurrentTask(state.tasks),
    tasks: state.tasksV2,
    IsLoading: state.tasksV2.isLoading || false,
    Error: state.tasksV2.Error
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PickingAndPackingSteps);
