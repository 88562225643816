import types from './types';
import data_consignmentTasksList from '../../DummyData/data_consignmentTasksList';

// import data_stocktasks from '../../DummyData/data_stockTasks';
const demoColumns_link = [
    { title: 'Hidden Col', field: 'col1', type: 'hidden-id'},
    { title: 'Description', field: 'col2', type: 'link'},
    { title: 'Serial #', field: 'col3', type: 'text' , onClickLink:console.log("Col3 link")},
    { title: 'UOM', field: 'col4', type: 'text', onChange:console.log("col 4 link")},
    { title: 'Qty', field: 'col5', type: 'text', onChange:console.log("col 4 link")},
    { title: 'Status', field: 'col6', type: 'text', onChange:console.log("col 4 link")},
    {title : 'type' , field : "col7" , type : "hidden-id"}
];

const INITIAL_STATE = {

    loading : false,
    consignment_tasks : data_consignmentTasksList,
    filtered_tasks : null,
    tasks_error : null,
    sort_order_value : "Create Date",
    filter_type_value : "All Consignment Tasks",
    all_stock_tasks : [
        
    ],
    stock_table_cols :  demoColumns_link,    
    selected_task_rows : [],
    column_to_change : "",
    searched_stock_tasks : [],
    unmarked_stock_tasks : [],
    marked_stock_tasks : [],

    consignment_list : null,
    fetching_consignment_list : false,
    consignment_list_error : null,

    consignment_details : null,
    fetching_consignment_details : false,
    consignment_details_error : null,

    consignment_info : null,
    fetching_consignment_info : false,
    consignment_info_error : null,

    filtered_consignment_list : null,
    fetching_filtered_consignment_list : false,
    filtered_consignment_list_error : null,

    serialised_items_table_data : null,
    non_serialised_items_table_data : null,

    overriddenConsignmentActual : null,
    loadingOverrideConsignmentActual : false,
    overrideConsignmentActualError : null,

    overriddenConsignmentRequested : null,
    loadingOverrideConsignmentRequested : false,
    overrideConsignmentRequestedError : null,

    searching_by_stock_code : false,
    search_by_stock_code_results : null,
    search_by_stock_code_error : null,

    searching_by_serial_number : false,
    search_by_serial_number_results : null,
    search_by_serial_number_error : null,

    searching_by_consignment_id : false,
    search_by_consignment_id_results : null,
    search_by_consignment_id_error : null,

    updating_consignment_status : false,
    update_consignment_success : false,
    update_consignment_error : null,

    search_stock_tasks_term : null,
}


const consignmentTasksReducer = (state=INITIAL_STATE,action) => {

    switch(action.type){

        case types.SEARCH_STOCK_ITEMS:
            return {
                ...state,
                search_stock_tasks_term : action.payload,
            }
        case types.UPDATE_CONSIGNMENT_STATUS_START:
            return {
                ...state,
                updating_consignment_status : true,
                update_consignment_success : false,
                update_consignment_error : null,
            }
        case types.UPDATE_CONSIGNMENT_STATUS_SUCCESS:
            return {
                ...state,
                updating_consignment_status : false,
                update_consignment_success : true,
            }
        case types.UPDATE_CONSIGNMENT_STATUS_ERROR:
            return {
                ...state,
                updating_consignment_status : false,
                update_consignment_error : action.payload,
            }
        case types.CLEAR_SEARCH_CONSIGNMENT_DATA:
            return {
                ...state,
                searching_by_stock_code : false,
                search_by_stock_code_results : null,
                search_by_stock_code_error : null,

                searching_by_serial_number : false,
                search_by_serial_number_results : null,
                search_by_serial_number_error : null,

                searching_by_consignment_id : false,
                search_by_consignment_id_results : null,
                search_by_consignment_id_error : null,
            }
        case types.SEARCH_BY_CONSIGNMENT_ID_START:
            return {
                ...state,
                searching_by_consignment_id : true,
                search_by_consignment_id_results : null,
                search_by_consignment_id_error : null,
            }
        case types.SEARCH_BY_CONSIGNMENT_ID_SUCCESS:
            return {
                ...state,
                searching_by_consignment_id : false,
                search_by_consignment_id_results : action.payload,
            }
        case types.SEARCH_BY_CONSIGNMENT_ID_ERROR:
            return {
                ...state,
                searching_by_consignment_id : false,
                search_by_consignment_id_error : action.payload,
            }
        case types.SEARCH_BY_SERIAL_NUMBER_START:
            return {
                ...state,
                searching_by_serial_number : true,
                search_by_serial_number_results : null,
                search_by_serial_number_error : null,
            }
        case types.SEARCH_BY_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                searching_by_serial_number : false,
                search_by_serial_number_results : action.payload,
            }
        case types.SEARCH_BY_SERIAL_NUMBER_ERROR:
            return {
                ...state,
                searching_by_serial_number : false,
                search_by_serial_number_error : action.payload,
            }
        case types.SEARCH_BY_STOCK_CODE_START:
            return {
                ...state,
                searching_by_stock_code : true,
                search_by_stock_code_results : null,
                search_by_stock_code_error : null,
            }
        case types.SEARCH_BY_STOCK_CODE_SUCCESS:
            return {
                ...state,
                searching_by_stock_code : false,
                search_by_stock_code_results : action.payload,
            }
        case types.SEARCH_BY_STOCK_CODE_ERROR:
            return {
                ...state,
                searching_by_stock_code : false,
                search_by_stock_code_error : action.payload,
            }
        case types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_START:
            return {
                ...state,
                overriddenConsignmentRequested : null,
                loadingOverrideConsignmentRequested : true,
                overrideConsignmentRequestedError : null,
            }
        case types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_SUCCESS:
            return {
                ...state,
                overriddenConsignmentRequested : action.payload,
                loadingOverrideConsignmentRequested : false,
            }
        case types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_REQUESTED_ERROR:
            return {
                ...state,
                loadingOverrideConsignmentRequested : false,
                overrideConsignmentRequestedError : action.payload,
            }
        case types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_START:
            return {
                ...state,
                overriddenConsignmentActual : null,
                loadingOverrideConsignmentActual : true,
                overrideConsignmentActualError : null,
            }
        case types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_SUCCESS:
            return {
                ...state,
                overriddenConsignmentActual : action.payload,
                loadingOverrideConsignmentActual : false,
            }
        case types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_ERROR:
            return {
                ...state,
                overrideConsignmentActualError : action.payload,
                loadingOverrideConsignmentActual : false,
            }

        case types.UPDATE_NON_SERIALISED_ITEM_QTY:
            const selectedItems = Object.assign([],state.selected_task_rows);
            const {row,value} = action.payload;
            
            for(let i =0 ; i < selectedItems.length ; i++){
                if(selectedItems[i].col1 === row.col1){
                    selectedItems[i].col5 = value;
                }
            }
            
            return {
                ...state,
                selected_task_rows : selectedItems,
            }
        case types.CLEAR_FILTER_STATUS:
            return {
                ...state,
                fetching_filtered_consignment_list : false,
                filtered_consignment_list : null,
                filtered_consignment_list_error : null,
            }
        case types.FILTER_BY_STATUS_START:
            return {
                ...state,
                fetching_filtered_consignment_list : true,
                filtered_consignment_list : null,
                filtered_consignment_list_error : null,
            }
        case types.FILTER_BY_STATUS_SUCCESS:
            return {
                ...state,
                fetching_filtered_consignment_list : false,
                filtered_consignment_list : action.payload,
            }
        case types.FILTER_BY_STATUS_ERROR:
            return {
                ...state,
                fetching_filtered_consignment_list : false,
                filtered_consignment_list_error : action.payload,
            }
        case types.FETCH_CONSIGNMENT_INFO_START:
            return {
                ...state,
                fetching_consignment_info : false,
                consignment_info : null,
                consignment_info_error : null,
            }
        case types.FETCH_CONSIGNMENT_INFO_SUCCESS:
            return {
                ...state,
                fetching_consignment_info : false,
                consignment_info : action.payload,
            }
        case types.FETCH_CONSIGNMENT_INFO_ERROR:
            return {
                ...state,
                fetching_consignment_info : false,
                consignment_info_error : action.payload,
            }
        case types.FETCH_CONSIGNMENT_DETAILS_START:
            return {
                ...state,
                fetching_consignment_details : true,
                consignment_details : null,
                consignment_details_error : null,
                update_consignment_success : false,
                update_consignment_error : null,
            }
        case types.FETCH_CONSIGNMENT_DETAILS_SUCCESS:
            console.clear();
            
            const rawSerializedItems = Object.assign([],action.payload?.SerialisedItems);
            const rawNonSerializedItems = Object.assign([],action.payload?.NonserialisedItems);
            
            const serialisedItemsTableData = [];
            const nonSerialisedItemsTableData = [];
            console.log("Raw Non Serialised" , rawNonSerializedItems);
            console.log("raw serialised items" , rawNonSerializedItems);
            for(let i=0; i< rawNonSerializedItems.length ; i++){
                const row = {
                    col1 : rawNonSerializedItems[i]?.StockCategoryCode,
                    col2 : rawNonSerializedItems[i]?.StockDescription,
                    col3 : "",
                    col4 : rawNonSerializedItems[i]?.UnitOfMeasurement,
                    col5 : rawNonSerializedItems[i]?.QtyGood,
                    col6 : rawNonSerializedItems[i]?.Status,
                    col7 : "non-serialised",
                }
                console.log("non-serialised row" , row)
                nonSerialisedItemsTableData.push(row);
            }
            for(let i=0; i<rawSerializedItems.length;i++){
                const row = {
                    col1 : rawSerializedItems[i]?.StockCategoryCode,
                    col2 : rawSerializedItems[i]?.StockDescription,
                    col3 : rawSerializedItems[i]?.SerialNumber,
                    col4 : rawSerializedItems[i]?.UnitOfMeasurement,
                    col5 : rawSerializedItems[i]?.QtyGood,
                    col6 : rawSerializedItems[i]?.StockSubStatus,
                    col7 : "serialised",
                }
                console.log("serialised row",row)
                serialisedItemsTableData.push(row);
            }
            console.log("serialised table data",serialisedItemsTableData)
            console.log("non serialised table data" , nonSerialisedItemsTableData)
            return {
                ...state,
                fetching_consignment_details : false,
                consignment_details : action.payload,
                serialised_items_table_data : serialisedItemsTableData,
                non_serialised_items_table_data : nonSerialisedItemsTableData,
                all_stock_tasks : [...nonSerialisedItemsTableData , ...serialisedItemsTableData],

            }
        case types.FETCH_CONSIGNMENT_DETAILS_ERROR:
            return {
                ...state,
                fetching_consignment_details : false,
                consignment_details_error : action.payload,
            }
        case types.FETCH_CONSIGNMENT_LIST_START:
            return {
                ...state,
                consignment_list : null,
                consignment_list_error : null,
                fetching_consignment_list : true,
                
            }

        case types.FETCH_CONSIGNMENT_LIST_SUCCESS:
            return {
                ...state,
                consignment_list : action.payload,
                fetching_consignment_list : false,
            }
        case types.FETCH_CONSIGNMENT_LIST_ERROR:
            return {
                ...state,
                consignment_list_error : action.payload,
                fetching_consignment_list : false,
            }
        
        case types.MARK_STOCK_ITEMS:
            const temp_selectedTaskRows = Object.assign([],state.selected_task_rows);
            const temp_unmarkedStockTasks = Object.assign([],state.unmarked_stock_tasks);
            //mark the tasks as payload stock tasks
            temp_selectedTaskRows.forEach(row=>row.col6 = action.payload);

            // now we have to remove the rows marked from the unmarked list
            temp_selectedTaskRows.forEach(row=>{
                const rowIndex = temp_unmarkedStockTasks.findIndex(i=>i.col1===row.col1);
                if(rowIndex > -1){
                    temp_unmarkedStockTasks.splice(rowIndex,1);
                }
            })

            return{
                ...state,
                marked_stock_tasks : [...state.marked_stock_tasks , ...temp_selectedTaskRows],
                unmarked_stock_tasks : temp_unmarkedStockTasks,
            }
        
        case types.SEARCH_STOCK_TASKS:
            const filteredTasks = state.all_stock_tasks.filter(_t=>_t.col2.includes(action.payload) || _t.col3.includes(action.payload));
            
            return {
                ...state,
                searched_stock_tasks : filteredTasks,
            }
        case types.CHANGE_STATUS_IN_STORE:
            const selected_task_rows_temp = state.selected_task_rows;
            const all_stock_tasks_temp = state.all_stock_tasks;
            for(let i=0;i<selected_task_rows_temp.length;i++){
                const rowIndex = all_stock_tasks_temp.findIndex(k=>k.col1===selected_task_rows_temp[i].col1)
                console.log("ROW INDEX" , rowIndex);
                if(rowIndex > -1){
                    selected_task_rows_temp[i].col6 = action.payload;
                    all_stock_tasks_temp[rowIndex].col6 = action.payload;
                }
            }
            return{
                ...state,
                selected_task_rows : selected_task_rows_temp,
                all_stock_tasks : all_stock_tasks_temp,
            }

        case types.SET_COLUMN_TO_CHANGE:
            return {
                ...state,
                column_to_change : action.payload,
            }
        case types.SET_SELECTED_TASKS_ROW:
            return {
                ...state,
                selected_task_rows : action.payload,
            }
        case types.CLEAR_SELECTED_TASKS_ROW:
            return {
                ...state,
                selected_task_rows : [],
            }

        case types.FILTER_TASKS_BY_STATUS:

            if(action.payload === "All Consignment Tasks")
                return {
                    ...state,
                    filtered_tasks : null,

                }

            const filteredArray = state.consignment_tasks.filter(task=>task?.SubTasks[0]?.TaskStatus===action.payload)

            return {
                ...state,
                filtered_tasks : filteredArray,
            }
        case types.TASKS_LIST_START:

            return {
                ...state,
                loading : true,
                consignment_tasks : null,
                tasks_error : null,
            }

        case types.TASK_LOADED:
            
            return {
                ...state,
                loading : false,
                consignment_tasks : action.payload,
            }
        
        case types.TASKS_ERROR:
            return {
                ...state,
                loading : false,
                tasks_error : action.payload,
            }

        default:
            return state;
    }

}


export default consignmentTasksReducer;