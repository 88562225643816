import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

import SkbTabMenu from '../../../skb_controls/navigation/SkbTabMenu';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import SkbCardsView from '../../../skb_controls/navigation/SkbCardsView';
import { default as StockIcon } from '@material-ui/icons/Business';

import * as navigationConfig from '../../../utils/navigationConfig.json';
import * as icons from '../../../utils/icons';
import StockModule from './StockModule'

const mapStateToProps = state => ({
    user: state.auth,

});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    }
}
export function StockModuleInternal(props) {
    const { user, dispatch } = props;
    const history = useHistory();

    const stockModuleConifg = navigationConfig.stockModuleInternal || [];
    //console.log(stockModuleConifg);
    //load contractor list informaiton 
    // useEffect(()=>{

    // }, [])
    return (
        <StockModule user={user} navigationConfig = {stockModuleConifg}/>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StockModuleInternal);
