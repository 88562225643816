import React, { useState, useEffect} from "react";
import SkbButton from '../../skb_controls/components/SkbButton';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import * as regi from '../../Redux/actions/register'
import * as assistanceNumber from '../../Redux/actions/callForAssistance'
import ListItemText from '@material-ui/core/ListItemText';
import { FormControl, InputLabel, Select, MenuItem,Checkbox,Container,Grid,FormControlLabel,RadioGroup,Radio,makeStyles } from '@material-ui/core';
import { SkbLogger } from "../../services";
import { useSnackbar } from 'notistack';
import {crypts} from '../../utils/crypts';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const useStyles = makeStyles((theme) => ({
    gridMargin:{
      marginBottom: theme.spacing(3),
    },
    header:{
      marginBottom: theme.spacing(3),
      textAlign:"center",
    },
    paper: {
        width: 400,
    },  
    formControl: {
      marginBottom: theme.spacing(1),
        maxWidth: 400,
        minWidth:200,
    },
    selectMenu: {
        backgroundColor: theme.palette.background.default,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 250,
    }
  }));
  




function UserTypeSelection(props){
  const classes = useStyles();
  const userData ={ "user": props.user.email } ;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() =>{
    window.scrollTo(0,0);
  },[]);
    
  useEffect(() => {
    if(props.register.allCountryphonePrefix.length ===0){
    props.updatePhone();
    }  
    if(props.register.allCountryphonePrefix.length !==0 && props.register.allTaxLabelList.length !==0 && props.register.SelectedCountry.length !== null){
      props.updateSelectedCountryTaxLabelList()
    }
    if(props.register.allCountryphonePrefix.length !==0){
      props.updateUserPhonePrefix() ;
    }    

    }, [props.register.SelectedUserType,props.register.SelectedCountry,props.register.allCountryphonePrefix]); 

  const handleNotifycation = (message, variant) => {
      enqueueSnackbar(message, { 
          variant: variant
      })};
 

  const validateAndGoNext = () =>{
    SkbLogger.applicationTrace('UserTypeSelection',0,'Self Registraction','User clicked the next button',userData);
    if(props.register.IsSKBUser && props.register.SelectedCountry.length ===0){
      handleNotifycation('Please select your country','error')
      }
    else if((!props.register.IsSKBUser) && ( props.register.SelectedUserType ===0 )){
      handleNotifycation('Please select user type','error')

    } 
    else{
      if(props.register.SelectPhonePrefix.length ===0){
        props.updateDefaultPhonePrefix();
      }
      props.updateAssistanceNumbers(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));
      props.goNextStep();

    }  
    
  }



  const updateUserTypeAndLog =(e) =>{
    props.updateUserTypes(e);
    SkbLogger.applicationTrace('UserTypeSelection',0,'Self Registraction',`User selected the user type ${e}`,userData);
  }

  const updateUserCountryAndLog = (e) =>{
    props.updateUserCountry(e);
    SkbLogger.applicationTrace('UserTypeSelection',0,'Self Registraction',`User selected the country ${e}`,userData);
  }

  const goPreStepAndLog = () =>{
    props.updateAssistanceNumbers([]);
    props.goPreStep();
    SkbLogger.applicationTrace('UserTypeSelection',0,'Self Registraction','User clicked the back button',userData);
  }

  return(
    <React.Fragment>
    <Container component="main" maxWidth="md"> 
      <Grid container justify="center" className={classes.header}>
        <Typography  id="ut-header" paragraph variant="h2">
            Registration Type
        </Typography>
      </Grid>
{/*       </Container>
      <Container component="main" maxWidth="sm">  */}
      <Grid container justify="center" className={classes.gridMargin}>
          <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">Countries</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="ut-select-country"
            multiple
            disabled            
            value= {props.register.SelectedCountryName}
            onChange={e => updateUserCountryAndLog(e.target.value)} 
            renderValue={selected => selected.join(', ')}
            MenuProps={{ classes: { paper: classes.selectMenu } }}
          >
            {props.register.countries.map((i, idx) => (
              <MenuItem key={idx} value={i["text"]}>
                <Checkbox checked={props.register.SelectedCountryName.indexOf(i["text"]) > -1} />
                <ListItemText primary={i["text"]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid container justify="center" className={classes.gridMargin}>
        <RadioGroup  id="ut-select-type" value={props.register.SelectedUserType}  aria-label="userType"  name="userType" onChange={e => updateUserTypeAndLog(e.target.value)}>
          <FormControlLabel disabled={props.register.IsSKBUser} value={1} control={<Radio color="primary" />} label="Skybridge Contractor" />
          <FormControlLabel disabled={true} value={2} control={<Radio color="primary" />} label="Skybridge Client" />
        </RadioGroup>
      </Grid>

      <Grid container className={classes.gridMargin}>
        <Grid item xs={6} sm={6} >
          <Grid container justify="center">
            {/*request to disable the back button for now in pho-358*/}
            <SkbButton disabled id="ut-back-button" name={"Small Primary"}  primary size="small" text="< Back" onClick={() => goPreStepAndLog()} />
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} >
          <Grid container justify="center">
            <SkbButton    id="ut-next-button" name={"Small Primary"}  primary size="small" text="Next >" onClick={() => validateAndGoNext()}/> {/* disabled={(props.register.RegisterStep ===1)} */}
          </Grid>
        </Grid>
      </Grid>
    </Container>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return { user: state.auth.AuthUser,
        register: state.register,
      assis: state.callForAssistance};
};

const mapDispatchToProps = (dispatch) =>{
  return {
    goNextStep : () => dispatch(regi.goNext()),
    goPreStep : () => dispatch(regi.goBack()),
    updateUserTypes : (e) => dispatch(regi.updateUserType(e)),
    updateUserCountry : (e) => dispatch(regi.updateCountry(e)),
    updatePhone : () => dispatch(regi.getPhoneConfig()),
    updateUserPhonePrefix : () => dispatch(regi.updateUserPhonePrefix()),
    updateSelectedCountryTaxLabelList : () => dispatch(regi.getUserSelectedTaxlabel()),
    updateAllCountryTaxLabelList : () => dispatch(regi.getATaxLabels()),
    updateDefaultPhonePrefix : () => dispatch(regi.updateDefaultPhonePrefix()),
    updateAssistanceNumbers : (e) => dispatch(assistanceNumber.updatePhoneNumber(e))
    
  }
} 



export default connect(mapStateToProps,mapDispatchToProps)(UserTypeSelection);

