const types = {
    SELECT_CSV_FILE  : "SELECT_CSV_FILE",
    DELETE_CSV_FILE : "DELETE_CSV_FILE",
    SET_SERIALIZED : "SET_SERIALIZED",
    VALIDATE_SERIALIZED_CSV : "VALIDATE_SERIALIZED_CSV",
    VALIDATE_NON_SERIALIZED_CSV : "VALIDATE_NON_SERIALIZED_CSV",

    READ_CSV_START : "READ_CSV_START",
    READ_CSV_SUCCESS : "READ_CSV_SUCCESS",
    READ_CSV_ERROR : "READ_CSV_ERROR",

    CLEAR_UPLOAD_FILE: "CLEAR_UPLOAD_FILE",
    SET_PROCESSING: "SET_PROCESSING",

    TOGGLE_VIEW_CSV_DATA : "TOGGLE_VIEW_CSV_DATA",

    THREE_PL_IMPORTATION_START : "THREE_PL_IMPORTATION_START",
    THREE_PL_IMPORTATION_SUCCESS : "THREE_PL_IMPORTATION_SUCCESS",
    THREE_PL_IMPORTATION_ERROR : "THREE_PL_IMPORTATION_ERROR",

    SET_NEW_REQUEST_ID : "SET_NEW_REQUEST_ID",
    CLEAR_REQUEST_ID : "CLEAR_REQUEST_ID",

    FETCH_OUTCOMES_START : "FETCH_OUTCOMES_START",
    FETCH_OUTCOMES_SUCCESS : "FETCH_OUTCOMES_SUCCESS",
    FETCH_OUTCOMES_ERROR : "FETCH_OUTCOMES_ERROR",
    CLEAR_UPLOAD_ERROR: "CLEAR_UPLOAD_ERROR",

    FETCH_LOCATIONS: "FETCH_LOCATIONS",
    FETCH_LOCATIONS_SUCCESS: "FETCH_LOCATIONS_SUCCESS",
    FETCH_LOCATIONS_ERROR: "FETCH_LOCATIONS_ERROR",
}

export default types;