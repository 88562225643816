import React, { useEffect } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import { PullDownContent, ReleaseContent, RefreshContent } from "react-js-pull-to-refresh";
import Loading from '../../../Loading';
import {crypts} from '../../../../utils/crypts';

import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import SkbPullToRefresh from '../../../../skb_controls/components/SkbPullToRefresh';
import { SkbLogger } from '../../../../services';
// import { sync } from '../../../Redux/reducers/sync';
import * as taskActions from '../../../../Redux/actions/tasks';

import { Grid } from '@material-ui/core';
import TaskSummary from './TaskSummary';

import { useSnackbar } from 'notistack';

import * as assistanceNumber from '../../../../Redux/actions/callForAssistance';


const useStyles = makeStyles((theme) => ({
    root: {

        marginTop: theme.spacing(2)
    },

    taskheader: {
        alignItems: 'center',
    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end',
    },
    tasksfooter: {
        textAlign: 'center',
        height: 200,
        cursor: 'default',
        paddingTop: 5, 
        overscrollBehavior: "contain",
    }
}));


const SortDirection = {
    Ascent: 1,
    Descent: 2
}
const SkbSortIcon = ({ sortDirection }) => {
    if (sortDirection === SortDirection.Descent) {
        return <div>
            <SkbIcon icon={ArrowDownwardSharpIcon} />
            <SkbIcon icon={SortIcon} />
        </div>
    } else if (sortDirection === SortDirection.Ascent) {
        return <div>
            <SkbIcon icon={ArrowUpwardSharpIcon} />
            <SkbIcon icon={SortIcon} />
        </div>
    }
    return null;
}


/** @function TextWithIcon to show text and icon together, icon will be in front of text 
 * @param icon a material icon
 * @param icon text display after the icon
 * @param className style class to apply 
*/
const TextWithIcon = ({ icon, text, className }) => {
    const classes = useStyles();
    const Icon = icon;
    return (

        <Grid item container direction={"row"} className={className}>
            <Grid item>
                <SkbIcon icon={Icon} />
            </Grid>
            <Grid>
                <Typography variant="body1">
                    {text}

                </Typography>
            </Grid>
        </Grid>
    );
}
const TaskHeader = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.taskheader} >
            <Grid xs={4} item>
                <TextWithIcon icon={FilterListIcon} text={"Stocktake"} className={classes.leftalign} />
            </Grid>
            <Grid xs={4} item >
                <Typography className={classes.centeralign} variant="h5">My Tasks</Typography>
            </Grid>
            <Grid xs={4} item>
                <TextWithIcon icon={() => <SkbSortIcon sortDirection={SortDirection.Descent} />} text={"Start Date"} className={classes.rightalign} />
            </Grid>
        </Grid>
    );
}

function Tasks(props) {
    const classes = useStyles();
    const { Tasks, loadTasks, updateAssistanceNumbers, CurrentTask,Sync } = props;
    const { enqueueSnackbar } = useSnackbar();
    var syncIsOn = false;
    //const [assistanceNumbers, setAssistanceNumbers] = React.useState([]);

    SkbLogger.logDebugInfo("tasks is called");
    useEffect(() => {
        //if (!Tasks.Tasks || Tasks.Tasks.length === 0) //load task only at first time
        if(Sync.isOnline && (Sync.isPhotoSyncing || Sync.isMetadataSyncing)) 
        {
            syncIsOn=true
        } else {
            syncIsOn=false;
        }
        //NOTE: to uncomment line below to load task from sync module
        loadTasks(true, true,syncIsOn); // load task from data sync, comment out in dev stage

        return () => {
            updateAssistanceNumbers(JSON.parse(crypts.decrypt(localStorage.getItem('skb_phoneNumbers'))));
        }
    }, []);

    useEffect(() => {
        if (Tasks.error) enqueueSnackbar(Tasks.error.toString(), {
            variant: 'error'
        });
        //loadTasks();
    }, [Tasks.error]);

    useEffect(() => {
        if (CurrentTask && CurrentTask.Programs) {
            updateAssistanceNumbers(CurrentTask.Programs.map((p) => {
                return {
                    phoneOwner: p.ProgramName,
                    phone: `tel:${p.PhoneNumber}`,
                    phoneText: p.PhoneNumber
                }
            }
            ));
        }
        //loadTasks();
    }, [CurrentTask]);



    return (
        <div>
            <Grid container className={classes.root} >
                <Grid item xs={12} data-testid="tasksheader">
                    <TaskHeader {...props} />
                </Grid>
                <Grid item xs={12} data-testid="taskscontent">

                    {Tasks.Tasks.map((t, idx) => {

                        return <TaskSummary 
                        Task={t} changeTask={props.changeTask} onStatusChange={props.onStatusChange} Alltasks={props.Tasks}
                        />
                    }

                    )

                    }
                </Grid>
                <Grid item xs={12} data-testid="tasksfooter" className={classes.tasksfooter}>
                    {!Tasks.isLoading && <SkbPullToRefresh //pull down to refresh task
                        pullDownContent={<PullDownContent />}
                        releaseContent={<ReleaseContent />}
                        refreshContent={<RefreshContent />}
                        pullDownThreshold={50}
                        onRefresh={() => {
                            return new Promise((resolve) => {
                                if(Sync.isOnline && (Sync.isPhotoSyncing || Sync.isMetadataSyncing)) 
                                {
                                    syncIsOn=true
                                } else {
                                    syncIsOn=false;
                                }
                                //this is not initial loading, so the second parameter is false
                                loadTasks(true,false,syncIsOn);//load server data when network is available
                                resolve();
                            });
                        }}
                        // triggerHeight={50}
                        triggerHeight="auto"
                        //   backgroundColor='white'
                        startInvisible={true}
                    >
                        <div className={classes.tasksfooter}>
                            <Typography variant="body2">
                                End of Task List
                    </Typography>
                            <Typography variant="body2">
                                <i>Pull down to refresh</i>
                            </Typography>
                        </div>
                    </SkbPullToRefresh>}
                </Grid>
            </Grid>
            {Tasks.isLoading && <Loading />}
        </div>
    );

}
const mapStateToProps = state => ({
    Tasks: state.tasks,
    Sync:state.sync,
    CurrentTask: taskActions.getCurrentTask(state.tasks)
});

const mapDispatchToProps = (dispatch) => {
    return {
        loadTasks: (loadDataFromServer, initialLoad, isSyncOn) => {
            dispatch(taskActions.loadTasksFromSync(loadDataFromServer, initialLoad, isSyncOn))},
        //showTaskList: () => dispatch(taskActions.showTaskDetails(false)),
        updateAssistanceNumbers: (e) => dispatch(assistanceNumber.updatePhoneNumber(e)),
        changeTask: (taskResourceId) => {
            dispatch(taskActions.changeCurrentTask(taskResourceId));
        },
        onStatusChange: (allTasks, status) => { dispatch(taskActions.updateStocktakeStatus(allTasks, status)); },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tasks)