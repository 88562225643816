import React, { useRef, useState,useEffect } from 'react';

import SkbTable from '../../../skb_controls/components/SkbTable';

import { makeStyles } from '@material-ui/core/styles';

import Loading from '../../Loading';
import _ from 'lodash';
//import classes from '*.module.css';
import * as misc from '../../../utils/misc';




/**
 * components: The ContractorSelector is for choosing contractors from a list based on their location and inventory information.  
 * @param {function} loadContractors: it's a callback function to refresh contractorListWithStockSummary. The HOC is responsible for the implementation.

 * @param {array}  contractorListWithStockSummary: array of data to show in the grid
 *      e.g.
[
          [{
            "Contractor": "Antennacom",
            "Representative": "Aaron Simpson",
            "RepresentativeId": 123, 
            "CompanyId":23,
            "NumberOfLocations": 1,
            "QtyGood": 10,
            "QtyFaulty": 20},
            {
              "Contractor": "TENDER IT! YOU GOT IT - WE'LL SELL IT PTY LTD",
              "Representative": "Jamie D Mitchell",
              "RepresentativeId": 456 , 
              "CompanyId":301,
              "NumberOfLocations": 3,
              "QtyGood": 1,
              "QtyFaulty": 150
          }]
 * @param {array} selectedContractorCompanyIds: array of selected contractor company Ids (int)
* @param {boolean} showGoodCounts: whether to display the QtyGood counts in the table for each contractor.
 * @param {boolean} showFaultyCounts: whether to display the QtyFaulty counts  in the table for each contractor.
 * 
 * @param {Function} handleSelectContractor: it's a callback function with one parameter selectedRows which will be called when a row is selected.  
 * @returns 
 */
export function ContractorSelector(props) {
  var {loadContractors,contractorListWithStockSummary,selectedContractorCompanyIds,showGoodCounts,showFaultyCounts,handleSelectContractor}=props;

  if (!selectedContractorCompanyIds)
  {
    selectedContractorCompanyIds=[]; //e.g. [456,999]
  }
  
  const [data, setData] = useState([]);
  
//  const { enqueueSnackbar } = useSnackbar();

  // const { user } = props;

  // const theme = useTheme();

//Create column list
//Get data

  //set up table columns

    var tableColumns = [
      { title: 'CompanyId', field: 'CompanyId', type: 'hidden-id' },
        { title: 'Contracting Entity', field: 'ContractorDisplayComponent', type:'read-only-text'},
        { title: 'Contractor Representative', field: 'Representative', type: 'read-only-text' },
        { title: '# of Stock Locations', field: 'NumberOfLocations', type: 'read-only-text' },
        { title: '# of Good Stock', field: 'QtyGood', type: 'read-only-text' },
        { title: '# of Faulty Stock', field: 'QtyFaulty', type: 'read-only-text'},

    ];

    if (!showFaultyCounts)
    {
      tableColumns.splice(tableColumns.length-1,1);  //take away the last column
    }
  
    if (!showGoodCounts)
    {
      tableColumns.splice(4,1); 
    }

   const actions_select=[{type:'select',onClick:handleSelectContractor}]; 

  //const [columns, setColumns] = useState(tableColumns);

  React.useEffect(() => {
    
    if (!_.isArray(props.contractorListWithStockSummary) || props.contractorListWithStockSummary.length === 0) 
      props.loadContractors();
  }, []);


  React.useEffect(() => {
   if (props.contractorListWithStockSummary) {

     var dataForTable=props.contractorListWithStockSummary.map(i => ({ ...i }));

  // var    dataForTable=[
  //         {
  //           "Contractor": "Antennacom",
  //           "Representative": "Aaron Simpson",
  //           "RepresentativeId": 123, 
  //           "NumberOfLocations": 1,
  //           "QtyGood": 10,
  //           "QtyFaulty": 20},
  //           {
  //             "Contractor": "TENDER IT! YOU GOT IT - WE'LL SELL IT PTY LTD",
  //             "Representative": "Jamie D Mitchell",
  //             "RepresentativeId": 456 , 
  //             "NumberOfLocations": 3,
  //             "QtyGood": 1,
  //             "QtyFaulty": 150
  //         },
  //             {
  //                 "Contractor": "Soon Technology",
  //                 "Representative": "Jason Hollingsworth",
  //                 "RepresentativeId": 999, 
  //                 "NumberOfLocations": 1,
  //                 "QtyGood": 45,
  //                 "QtyFaulty": 6
  //             }
  //         ].map(i => ({ ...i }));
      
            setData(dataForTable);
           

  }}, [props.contractorListWithStockSummary]);


  return (

    <div id="contractorSelectorTable" >
          <SkbTable columns={tableColumns} dataSource={data} 
          actions={actions_select}
          options={{selectedIdValues: selectedContractorCompanyIds, 
                  emptyDataSourceMessage:'There are no contractors matching your criteria!',
                 disableRowCondition: rowData=>{
                   if(rowData && rowData.ContractorDisplayName){
                    return rowData.ContractorDisplayName.includes('Task:'); //disable the rwo if there is a pending task
                   }
                   else {
                     return false;
                   }
                 }
                }}
          filter={{visible:true, 
                  text:"Contractor/Rep Search", 
                  filterColumns:"[Contractor,Representative]"}}/>

      {/* display loading indicator when data is being loaded */}
      {/* {isRefreshing && <Loading />} */}
    </div>
  );
}

