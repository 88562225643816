import React,{useState} from 'react';
import {connect} from 'react-redux';
import {
    Typography,
} from '@material-ui/core';
import {
    FilterList,
    FilterListOutlined,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import SkbIcon from '../../../skb_controls/components/SkbIcon';

import {
    setSelectedTasksRows,
} from '../../../Redux/ConsignmentTasks/actions';

const useStyles = makeStyles((theme)=>({
    noRows : {
        backgroundColor : 'red',
        color : "#ffff",
        textAlign : 'center',
    }
}))

const ItemsTable = ({
    
    // props
    data,

    // actions
    setRows,
}) =>{

    const classes = useStyles();
    const [filterActive,setFilterActive] = useState(false);
    
    
    const FilterToggle = () => (
        filterActive
        ? 
        <SkbIcon icon={FilterList} onClick={()=>setFilterActive(!filterActive)}/>
        :
        <SkbIcon icon={FilterListOutlined} onClick={()=>setFilterActive(!filterActive)} />
    );
    const ColumnHeading = ({text}) => (
        <Typography variant='h6'><FilterToggle /> {text}</Typography>
    );

    const columns = [
        {title : "StockCategoryCode" , field : "col1" , hidden:true},
        {title : <ColumnHeading text="Description" />,field : "col2" , filtering:false},
        {title : <ColumnHeading text="Serial #" /> , field : "col3" ,filterPlaceholder:"Filter by Serial #" ,emptyValue:()=><Typography variant="body2" style={{color:'red'}}>N/A</Typography>},
        {title : <ColumnHeading text="UOM" />,field:"col4",filterPlaceholder:"Filter by UOM"},
        {title : <ColumnHeading text="Quantity" />,field : "col5",filtering:false,emptyValue:()=><Typography variant="body2" style={{color:'red'}}>N/A</Typography> },
        {title : <ColumnHeading text="Status" />,field : "col6",filterPlaceholder:"Filter by status",emptyValue:()=><Typography variant="body2" style={{color:'red'}}>N/A</Typography> },
        {title : "Type",field : "col7",hidden:true},
    ]


    return(
        <MaterialTable 
            title={""}
            columns={columns}
            data={data}
            options={{
                search : false,
                filtering : filterActive,
                selection : true,
            }}
            onSelectionChange={(rows)=>{setRows(rows)}}
        />
    )
}

const mapState = state =>({})
const mapDispatch = dispatch =>({
    setRows : rows=>dispatch(setSelectedTasksRows(rows)),
})

export default connect(mapState,mapDispatch)(ItemsTable);