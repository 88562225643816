import React, { useState } from 'react';

import SkbTextField from '../../../skb_controls/components/SkbTextField';
import SkbButton from '../../../skb_controls/components/SkbButton';
import AddItemDialog from './DialogAddItemFromSearch';
import AddIcon from '@material-ui/icons/Add';
import { SkbLogger, SeverityLevel } from '../../../services';

import MaterialTable from 'material-table';

import { tableIcons } from '../_share/tableIcons';

import uuid from 'react-uuid';

import Loading from '../../Loading';

import * as misc from '../../../utils/misc';

import { TaskLogInfo } from '../../../utils/misc';
import _ from 'lodash';

import {useStocktakeTableStyles} from './stocktake/StepStockPreview';


/*
 * component: The Non-Serialised Items Step captures non-serialised items counted or added to the consignment by the contractor.  
 * @param {array}  expectedItems: the items we think they're going to (or could) include, for comparison.
 *              e.g.  [
 *  {
                  StockCode: "EROD0001",
                  Description: "Ericsson Wireless Outdoor Unit",
                  StockType: "Serialised",
                  Condition: "Good",
                  Qty: 20,
                  QtyUnit: "Each",
              },
              {
                  StockCode: "ERMI0001",
                  Description: "Ericsson Wireless Wall Plate Kit",
                  StockType: "Non-Serialised",
                  Condition: "Faulty",
                  Qty: 30,
                  QtyUnit: "Each",
              },
                    {
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                        StockType: "Serialised",
                        Qty: 100
                    },
                    {
                        StockCode: "SKB001",
                        Description: "Cable Type",
                        StockType: "Non-Serialised",
                        Qty: 200,
                    },...]
 * @param {function} loadExpectedItems: it's a callback function to load the data for expectedItems.
* @param {array}  NonSerialisedItems: the counted or picked non-serialised items
                    *              e.g. [
                                       {
                                           StockCode: "SKB001",
                                           Description: "cable",
                                           QtyUnit: "Metre",
                                           GoodQty: 45,
                                           FaultyQty: 20
                                       },...]
* @param {function} loadNonserialisedItems: a function to load the nonserialised items.
* @param {function} addNonSerialisedItem: a function to save an added item.
* @param {function} updateNonSerialisedItem: a function to save an updated item.
* @param {function} onChange: a function to trigger external actions when the user makes a change on this page.
* @param {text} lastSelectedStockCode: the last selected stock code.  Used to focus on that item.
* @param {function} setLastSelectedStockCode: a function to set the last selected stock code for use elsewhere.
 * @param {text} emptyDataMessage: text to display when there is no data.
 * @param {boolean} isLoading: Is data currently loading?  (used to show loading indicator.)
* @param {object} taskObjectForLogging: an object to be passed to the logger containing relevant task information.
 * @param {text} moduleName: to be used in logging.
 * * @param {text} stepName: to be used in logging.
 * @returns 
 */
export default function StepNonSerialisedItems(props) {

  const [data, setData] = useState([]);

  function updateStockItemRowGoodQty(oldRowData, newGoodQty, e) {

    if (newGoodQty < 0) {

      var msg = "User tried to enter a negative good quantity + " + newGoodQty + " for Stockcode " + oldRowData.StockCode + ".";
      SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Info, props.stepName, msg, props.taskObjectForLogging);

      e.target.value = 0;  //This sets the value in the UI.

      newGoodQty = 0;
    }

    const dataUpdate = [...data];
    const index = oldRowData.tableData.id;
    var updatedRowData = dataUpdate[index];

    var oldValue = 0;

    if (updatedRowData !== undefined && updatedRowData.GoodQty !== undefined) {
      oldValue = updatedRowData.GoodQty;

      msg = "Attempting to update good quantity from " + oldValue + " to " + newGoodQty + " for Stockcode " + oldRowData.StockCode + ".";
      SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Verbose, props.stepName, msg, props.taskObjectForLogging);

      updatedRowData.GoodQty = newGoodQty;
      updatedRowData.FaultyQty = oldRowData.FaultyQty;
      dataUpdate[index] = updatedRowData;

    }
    else {
      //This case is designed for when a new row is added.
      oldValue = oldRowData.GoodQty;

      msg = "Attempting to update good quantity from " + oldValue + " to " + newGoodQty + " for Stockcode " + oldRowData.StockCode + ".";
      SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Verbose, props.stepName, msg, props.taskObjectForLogging);

      updatedRowData = { ...oldRowData };
      updatedRowData.GoodQty = newGoodQty;
      updatedRowData.FaultyQty = oldRowData.FaultyQty;

    }
      props.updateNonSerialisedItem( updatedRowData);

    msg = '{userObject} has changed Good Qty from "+ oldValue + " to "+newGoodQty +" for {stockObject} at {locationObject} while {networkObject}.'

    SkbLogger.userAuditEvent(props.moduleName, misc.getCurrentUserEmail(), props.stepName, 'Change non-serialised good quantity', misc.LogResult.success,
      msg
      , {
        userObject: misc.getUserObject(),
        stockObject: misc.buildStockObjectForLog('Non-Serialised', oldRowData.StockCode, oldRowData.Description),
        locationObject: misc.getLocationObject(),
        networkObject: misc.getNetworkObject()
      });

    props.onChange();


  }

  function updateStockItemRowFaultyQty(oldRowData, newFaultyQty, e) {
    //debugger;
    if (newFaultyQty < 0) {
      var msg = "User tried to enter a negative faulty quantity + " + newFaultyQty + " for Stockcode " + oldRowData.StockCode + ".";
      SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Info, props.stepName, msg, props.taskObjectForLogging);

      e.target.value = 0;

      newFaultyQty = 0;
    }


    const dataUpdate = [...data];
    const index = oldRowData.tableData.id;
    var updatedRowData = dataUpdate[index];

    var oldValue = 0;

    if (updatedRowData !== undefined && updatedRowData.FaultyQty !== undefined) {
      oldValue = updatedRowData.FaultyQty;

      msg = "Attempting to update faulty quantity from " + oldValue + " to " + newFaultyQty + " for Stockcode " + oldRowData.StockCode + ".";
      SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Verbose, props.stepName, msg, props.taskObjectForLogging);

      updatedRowData.FaultyQty = newFaultyQty;
      updatedRowData.GoodQty = oldRowData.GoodQty;
      dataUpdate[index] = updatedRowData;
       

    }
    else {
      //This case is designed for when a new row is added.
      oldValue = oldRowData.FaultyQty;

      updatedRowData = { ...oldRowData };
      updatedRowData.FaultyQty = newFaultyQty;
      updatedRowData.GoodQty = oldRowData.GoodQty;

      msg = "Attempting to update faulty quantity from " + oldValue + " to " + newFaultyQty + " for Stockcode " + oldRowData.StockCode + ".";
      SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Verbose, props.stepName, msg, props.taskObjectForLogging);

    }

      props.updateNonSerialisedItem( updatedRowData);

    msg = '{userObject} has changed Faulty Qty from "+ oldValue + " to "+newFaultyQty +" for {stockObject} at {locationObject} while {networkObject}.'

    SkbLogger.userAuditEvent(props.moduleName, misc.getCurrentUserEmail(), props.stepName, 'Change non-serialised faulty quantity', misc.LogResult.success,
      msg
      , {
        userObject: misc.getUserObject(),
        stockObject: misc.buildStockObjectForLog('Non-Serialised', oldRowData.StockCode, oldRowData.Description),
        locationObject: misc.getLocationObject(),
        networkObject: misc.getNetworkObject()
      });

    props.onChange();
  }


  function processAdd(newItem) {

    var copyOfNewItem = { ...newItem };

    copyOfNewItem.resourceID = uuid().replace(/-/ig, '');
    copyOfNewItem.GoodQty = 0;
    copyOfNewItem.FaultyQty = 0;
    if (copyOfNewItem.QtyUnit === undefined) {
      copyOfNewItem.QtyUnit = 'Each';
    }

    var msg = "Attempting to add a new line for Stockcode " + copyOfNewItem.StockCode + ".";
    SkbLogger.applicationTrace(props.moduleName, SeverityLevel.Info, props.stepName, msg, props.taskObjectForLogging);

    props.addNonSerialisedItem( copyOfNewItem);

    SkbLogger.userAuditEvent(props.moduleName, misc.getCurrentUserEmail(), props.stepName, 'Add non-serialised item', misc.LogResult.success,
      '{userObject} has added {stockObject} at {locationObject} while {networkObject}.'
      , {
        userObject: misc.getUserObject(),
        stockObject: misc.buildStockObjectForLog('Non-Serialised', copyOfNewItem.StockCode, copyOfNewItem.Description),
        locationObject: misc.getLocationObject(),
        networkObject: misc.getNetworkObject()
      });

    props.setLastSelectedStockCode(copyOfNewItem.StockCode);

    props.onChange();
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStocktakeTableStyles();

  let selectedStockCode = props.lastSelectedStockCode;

  if (data.length === 0) {
    selectedStockCode = '';
  }
  else {
    if (!props.lastSelectedStockCode        //No Last Selected Stock Code
      || !data.some(d => d.StockCode === selectedStockCode))  //Or the last selected stock code is somehow not in the list
    {
      selectedStockCode = data[0].StockCode;               //Autofocus the first item.  
    }
  }

  //At some point the autofocus on selectedStockCode has stopped working.  Just found it now.  

  //Get the search items for the Add dialog.
  const [searchItemsFiltered, setSearchItemsFiltered] = useState([]);


  const numberInputProps = { step: 'any' };  //This is the step size of the number stepper (up-down arrow).  By default it is 1, which means that decimals are regarded as invalid.

  //set up table columns
  const nonSerialisedTableColumns = [
    { title: 'StockCode', field: 'StockCode', hidden: true, editable: 'never' },
    { title: 'Description', field: 'Description', hidden: false, editable: 'never', width: '40%', align: 'left' },
    { title: 'UOM', field: 'QtyUnit', hidden: false, editable: 'never', width: '5%', align: 'left' },
    {
      title: '# Good', field: 'GoodQty', type: 'numeric', initialEditValue: 0, hidden: false, width: '8%', align: 'center',
      render: rowData => (

        <SkbTextField id="standard-small" type="number" inputProps={numberInputProps} size="small"
          value={rowData.GoodQty} autoFocus={rowData.StockCode === selectedStockCode} autoselect={true}
          margin="dense"
          className={classes.centredTableColumn}
          onChange={e => updateStockItemRowGoodQty(rowData, e.target.value, e)}
          onBlur={(e) => { e.target.value = parseFloat(e.target.value) }}

        />
      )
    }
    ,
    {
      title: '# Faulty', field: 'FaultyQty', type: 'numeric', initialEditValue: 0, hidden: false, width: '8%', align: 'center',
      render: rowData => (
        <SkbTextField id="standard-small" type="number" inputProps={numberInputProps} size="small"
          value={rowData.FaultyQty}
          autoselect={true}
          margin="dense"
          className={classes.centredTableColumn}
          onChange={e => updateStockItemRowFaultyQty(rowData, e.target.value, e)}
          onBlur={(e) => { e.target.value = parseFloat(e.target.value) }}
        />

      )
    }
  ];
  const [columns, setColumns] = useState(nonSerialisedTableColumns);
  const tableRef = React.useRef(null);

  React.useEffect(() => {

    //Compare the two arrays (NonSerialisedItems and the current grid data) using the provided comparison function 
    //(comparing with StockCode, not resourceId as StockCode is there even for a brand new item and is also not duplicated in the list).
    let newAdded = _.differenceWith(props.nonSerialisedItems, data, (a, b) => a.StockCode=== b.StockCode);

    let itemsInDisplayOrder = data.reduce((result, i, idx) => {
      let item = props.nonSerialisedItems.find(nonsnItem => nonsnItem.StockCode=== i.StockCode);
      if (item) {
        result.push({ ...item });
      }
      return result;
    }, []);

    newAdded.forEach(item => {
      itemsInDisplayOrder.push({ ...item });
    });
    
    setData(itemsInDisplayOrder);

  }, [props.nonSerialisedItems]);// monitor nonserialiseditems, update table and keep item in exisitng order.

  React.useEffect(() => {

    //setData(props.currentSubTask.NonSerialisedItems.map(d => ({ ...d })));
    setColumns(nonSerialisedTableColumns); //update columns binding for adding new item need to refresh data

  }, [data])

  React.useEffect(() => {

    props.loadNonSerialisedItems();

    if (!props.expectedItems) //in case the previewInfo has not been loaded
      props.loadExpectedItems();
  }, []);

  React.useEffect(() => {
    if (props.expectedItems) {
      let previewItems = [...props.expectedItems];
      let searchItems = previewItems.filter(i => i.StockType === "Non-Serialised"); //Only the non-serialised items.
      let listItems = searchItems.filter(i => !data.some(d => d.StockCode === i.StockCode));  //The ones we haven't counted already.  
      setSearchItemsFiltered(listItems);
    }
  }, [props.expectedItems, data]);
  return (

    <div id="nonSerialisedItemsTable" className={classes.table} >

      <MaterialTable
        icons={tableIcons}
        showTitle={false}
        searchable={false}
        toolbar={false}
        columns={columns}
        data={data}
        padding={'dense'}
        tableRef={tableRef}
        options={{
          showTitle: false,
          toolbar: false,
          search: false,
          padding: 'dense',
          paging: false,
          headerStyle:
          {
            fontWeight: "bolder",
            fontSize: "1.2em"

          }
        }
        }
        localization={{ body: { emptyDataSourceMessage: props.emptyDataMessage } }}

      />

      <AddItemDialog id="add-item-dialog" title="Add Stock Item" message="" open={open} openConfirm={setOpen} isLoading={props.isLoading} okHandler={processAdd} searchItems={searchItemsFiltered}
        noOptionsText='There are no more available items in scope to add.' />

      <div className={classes.middle}>
        <SkbButton id="open-dialog-button" text="Add" primary onClick={handleClickOpen} startIcon={<AddIcon />} />
      </div>

      {/* display loading indication when store state is updating  */}
      {props.isLoading && <Loading />}
    </div>
  );
}

