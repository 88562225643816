/** @function isRowValidated it's validation helper of MaterialTable, and validate all cells value in a row, this funciton SHALL be used in ROW editing event, such as onRowAdd/onRowUpdate/onRowDelete, if it returns false, the event handler shall call reject()
 * @param columns the columns setting of a materal table, this helper check against the validate property of columns setting
 * @param rowData the actuall row data from MaterialTable
 * @summary sample:
 *  onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if (isRowValidated(columns, newData)) {
                                    //.....
                                    resolve();
                                }
                                else resolve();

                            }, 1000)
                        }),
 */
export function isRowValidated(columns, rowData) {
    return true;
    var ret = true;
    console.log(rowData);
    columns.forEach(c => {

        if (ret && typeof (c.validate) === 'function') {
            console.log(c);
            let vResult = c.validate(rowData);
            if (typeof (vResult) === 'string' && vResult !== "") ret = false;
            else if (typeof (vResult) === 'boolean' && !vResult) ret = false;
            else if (typeof (vResult) === 'object' && !vResult["isValid"]) ret = false;
        }


    });
    return ret;
}

/** @function cellValidate it's vialidation helper of MaterialTable component, it validates in particular cell, this funciton SHALL be used in MaterialTable columns setting rather than called programitically  
 * @param cellvalue the value of cell, 
 * @param reg a regular expression for validating the cellvalue, i.e.  /^[^\s].{0,100}$/ig, NOTE, it's NOT a string 
 * @param errorMsg the error message showing to user when validation is failed, i.e. "Invalid Acronym"
 * @summary sample:
 *    const [columns, setColumns] = useState([
        { title: 'Division Name', field: 'variableName', validate: rowData => cellValidate(rowData.variableName, /^[^\s].{0,100}$/ig, 'Invalid Division Name') },
        ....
*/
export function cellValidate(cellValue, reg, errorMsg){
    return (typeof(cellValue) === 'undefined' || !reg.test(cellValue))? {helperText:errorMsg, isValid: false} :true;
}