import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LinearProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import * as syncActions from '../../Redux/actions/sync';
import { sync } from '../../Redux/reducers/sync';
import { SkbLogger } from '../../services';
import {crypts} from '../../utils/crypts';
import uuid from 'react-uuid';
import * as dataSyn from '../../services/dataSaverAndTracker';
import * as serviceWorker from '../../serviceWorker';

 
const useStyles = makeStyles(theme => ({
    syncbarContainer: {
        height: 8,
        width: '100vw'
    },
    syncbarOfflineText:{
        height: 4,
        width: '100vw',
        borderTop: `2px dashed ${theme.palette.secondary.main}`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    syncbarSyncText:{
        height: 6,
        width: '100vw',
        borderTop: `6px dashed ${theme.palette.primary.main}`,
    }
}));

 export function getTempDeviceID(){
    const deviceIDInLS=localStorage.getItem('temp_device_id');
    if(deviceIDInLS){
        const tempDeviceID = crypts.decrypt(deviceIDInLS);
        return tempDeviceID;
    }else{
        //first part of device id is timestamp
        //to allow us to know when users clear the localStorage
        const newDeviceID = ''+((new Date()).getTime())+'-'+(uuid().split('-'))[0];
        const encryptedDeviceID = crypts.encrypt(newDeviceID);
        localStorage.setItem('temp_device_id',encryptedDeviceID);
        const tempDeviceID = crypts.decrypt(encryptedDeviceID);
        return tempDeviceID;
    }

}

export async function setupCommunicationWithServiceWorker(sync,dispatch, hasRetried=false){

    const authUserInLS=localStorage.getItem('auth_user');
    const idTokenInLS=localStorage.getItem('id_token');
    const storageSASLS=localStorage.getItem('sas_azureStorageEncrypted');
    const photoDownloadSpeedLS=localStorage.getItem('photoDownloadSpeedSetting');
    const authUser=JSON.parse(crypts.decrypt(authUserInLS));

    const currentDevice = {emailAddress:authUser.email,
                            screenResolution:'' + window.screen.width + 'x' + window.screen.height,
                            deviceID:getTempDeviceID()
                    };
    SkbLogger.logDebugInfo('currentDevice',currentDevice);

    //listen to reponse message from SW
    const swMessageHandler = (event)=>{
        SkbLogger.logDebugInfo('ui received from sw',''+event.data.type);
        
        if(event.data.type=='FROM_SW_METADATA_SYNC_TRUE'){
            console.log('FROM_SW_METADATA_SYNC_TRUE');
        SkbLogger.applicationTrace("Syncbar", 1, "Syncbar", "FROM_SW_METADATA_SYNC_TRUE", {message: "syncing metadata"});

            dispatch(syncActions.setIsMetadataSyncing(true));
        }else if(event.data.type=='FROM_SW_METADATA_SYNC_FALSE'){
            SkbLogger.applicationTrace("Syncbar", 1, "Syncbar", "FROM_SW_METADATA_SYNC_FALSE", {message: "syncing metadata finish"});
            dispatch(syncActions.setIsMetadataSyncing(false));
        }else if(event.data.type=='FROM_SW_PHOTO_SYNC_TRUE'){
            console.log('FROM_SW_PHOTO_SYNC_TRUE');
            SkbLogger.applicationTrace("Syncbar", 1, "Syncbar", "FROM_SW_PHOTO_SYNC_TRUE", {message: "syncing photo data"});
            dispatch(syncActions.setIsPhotoSyncing(true));
        }else if(event.data.type=='FROM_SW_PHOTO_SYNC_FALSE'){
            SkbLogger.applicationTrace("Syncbar", 1, "Syncbar", "FROM_SW_PHOTO_SYNC_FALSE", {message: "syncing photo data finish"});
            dispatch(syncActions.setIsPhotoSyncing(false));
        }else if(event.data.type=='FROM_SW_PHOTO_SYNC_SERVICE_AWAKE'){
            SkbLogger.applicationTrace('Data Sync',1,'Connect Server','{deviceObject} is awake for photo sync.',{deviceObject:currentDevice});
        }else if(event.data.type=='FROM_SW_METADATA_SYNC_SERVICE_AWAKE'){
            SkbLogger.applicationTrace('Data Sync',1,'Connect Server','{deviceObject} is awake for metadata sync.',{deviceObject:currentDevice});
        }else if(event.data.type=='FROM_SW_SYNC_LOG_TRACE_REQUEST'){
            SkbLogger.applicationTraceSub('Data Sync',1,'Data Synchronisation'
                                            ,event.data.logCategory,event.data.logActivity,event.data.logObj);  //use category as subCategory
        }else if(event.data.type=='FROM_SW_SYNC_LOG_ERROR_REQUEST'){
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation'
                                            ,event.data.logCategory,event.data.logActivity,event.data.logObj); //use category as subCategory
        }else if(event.data.type=='FROM_SW_SYNC_LOG_DEBUG_REQUEST'){
            SkbLogger.logDebugInfo(event.data.logActivity,event.data.logObj); 
        }
        
    };  

    //construct message data to pass all neccesary info to service worker
    const ls={auth_user:authUserInLS,
              id_token:idTokenInLS,
              storage_sas:storageSASLS,
              photoDownloadSpeed:photoDownloadSpeedLS
            };
    const env={REACT_APP_ENV_TYPE:process.env.REACT_APP_ENV_TYPE,
               REACT_APP_API_URL:process.env.REACT_APP_API_URL,
               REACT_APP_API_SECRET_ENCRYPTED:process.env.REACT_APP_API_SECRET_ENCRYPTED,
               REACT_APP_APPINSIGHTS_IKEY_ENCRYPTED:process.env.REACT_APP_APPINSIGHTS_IKEY_ENCRYPTED,
               REACT_APP_AZURE_CONTAINER_NAME:process.env.REACT_APP_AZURE_CONTAINER_NAME,
               REACT_APP_AZURE_STORAGE_NAME:process.env.REACT_APP_AZURE_STORAGE_NAME,
               currentDevice:currentDevice
            };

    const wakeupSyncMessage = {type:'TO_SW_WAKEUP_SYNC',
                                localStorageItems:ls,
                                envItems:env
                              };

    var DELAY_SEC_1 = 3;
    var DELAY_SEC_2 = 8;
    SkbLogger.applicationTrace('Data Sync',0,'Starting','Trying to post init/wakeup message in {sec} seconds.',{sec:DELAY_SEC_1});        
    setTimeout(() => {
        
        if (!('serviceWorker' in navigator)) {
            SkbLogger.applicationException('Data Sync','Starting','Browser does not support Service Worker.',{currentDevice:currentDevice});
            return;        
        }

        SkbLogger.applicationTrace('Syncbar',1,'Syncbar','navigator.serviceWorker.controller.',{message: navigator.serviceWorker.controller?"controller is not null":"controller is null"});

        if(navigator.serviceWorker.controller){
            //response channel for two-way communication with serviceWorker
            //port 1: for UI 
            //port 2: for Service Worker
            //ref https://felixgerschau.com/how-to-communicate-with-service-workers/
            //ref https://googlechrome.github.io/samples/service-worker/post-message/
            var respMessageChannel = new MessageChannel(); 
            SkbLogger.logDebugInfo('new MessageChannel()',respMessageChannel); 
            //listening response from swervice worker
            respMessageChannel.port1.onmessage = swMessageHandler;
            const lastWakeupMessage=localStorage.getItem('last_wakeup_message');
            SkbLogger.applicationTrace('Data Sync',0,'Starting','lastWakeupMessage in localStorage.',lastWakeupMessage);
            var intLastWakeupMessage = 0;
            if(lastWakeupMessage){
                        intLastWakeupMessage=parseInt(lastWakeupMessage);
            }
            console.log('intLastWakeupMessage',intLastWakeupMessage);
            var checkpoint1=(new Date()).getTime()-60*1000;
            if(intLastWakeupMessage<checkpoint1){
                SkbLogger.applicationTrace('Data Sync',0,'Starting','Posting Init/Wakeup Message to Sync Service Worker.',{message:wakeupSyncMessage});
                //SkbLogger.logDebugInfo('posting init message to sw');
                navigator.serviceWorker.controller
                        .postMessage(wakeupSyncMessage,[respMessageChannel.port2]);
                localStorage.setItem('last_wakeup_message',''+((new Date()).getTime()));
            }else{
                SkbLogger.applicationTrace('Data Sync',0,'Starting','Skipping Posting due to lastMessage.',{lastMessage:lastWakeupMessage,checkpoint:checkpoint1});
            }
        }else{
            SkbLogger.applicationTrace('Data Sync',1,'Starting','Sync Service Worker not ready while App Header loaded. Retry in {sec} seconds...',{sec:DELAY_SEC_2});
            SkbLogger.logDebugInfo("serviceWorker not ready");

            if(!navigator.serviceWorker.controller && navigator.onLine){
                SkbLogger.applicationTrace('Syncbar',0,'Data Sync','re-register sevice worker');        
                serviceWorker.reRegisterServiceWorker();
            }

            setTimeout( ()=>{
                /*
                if(!hasRetried){
                    setupCommunicationWithServiceWorker(sync,dispatch,true);
                }
                */

               SkbLogger.applicationTrace('Data Sync',1,'Starting','Re-trying to trigger service worker.',{sw:navigator.serviceWorker});

               if (!('serviceWorker' in navigator)) {
                    SkbLogger.applicationException('Data Sync','Starting','Browser does not support Service Worker.',{currentDevice:currentDevice});
                    return;        
                }
                
                if(navigator.serviceWorker.controller){
                    var respMessageChannel = new MessageChannel(); 
                    SkbLogger.logDebugInfo('new MessageChannel()',respMessageChannel); 
                    //listening response from swervice worker
                    respMessageChannel.port1.onmessage = swMessageHandler;
                    const lastWakeupMessage=localStorage.getItem('last_wakeup_message');
                    SkbLogger.applicationTrace('Data Sync',0,'Starting','lastWakeupMessage in localStorage.',lastWakeupMessage);
                    var intLastWakeupMessage = 0;
                    if(lastWakeupMessage){
                        intLastWakeupMessage=parseInt(lastWakeupMessage);
                    }
                    console.log('intLastWakeupMessage',intLastWakeupMessage);
                    var checkpoint = (new Date()).getTime()-60*1000;
                    if(intLastWakeupMessage<checkpoint){
                        SkbLogger.applicationTrace('Data Sync',0,'Starting','Re-Posting Init/Wakeup Message to Sync Service Worker.',{message:wakeupSyncMessage});
                        //SkbLogger.logDebugInfo('posting init message to sw');
                        navigator.serviceWorker.controller
                            .postMessage(wakeupSyncMessage,[respMessageChannel.port2]);
                        localStorage.setItem('last_wakeup_message',''+((new Date()).getTime()));
                    }else{
                        SkbLogger.applicationTrace('Data Sync',0,'Starting','Skipping Re-Posting due to lastMessage.',{lastMessage:lastWakeupMessage,checkpoint:checkpoint});
                    }
                }else{
                    SkbLogger.applicationException('Data Sync','Starting','Sync Service Worker not ready for re-posting while App Header loaded.',{ready:navigator.serviceWorker.ready});
                    if(!navigator.serviceWorker.controller && navigator.onLine){
                        SkbLogger.applicationTrace('Syncbar',0,'Data Sync','re-register sevice worker');        
                        serviceWorker.reRegisterServiceWorker();
                    }
                }
                
                
            },DELAY_SEC_2*1000); //try again in 8 seconds
        }
     },DELAY_SEC_1*1000); //delay 3 seconds for sw activation 

    //sending message to service worker when online/offline
    const onlineHandler = () =>{
        if (!('serviceWorker' in navigator)) {
            SkbLogger.applicationException('Data Sync','Starting','Browser does not support Service Worker.',{currentDevice:currentDevice});        
            dispatch(syncActions.setIsOnline(true));
            return;
        }

        if(navigator.serviceWorker.controller){
            //response channel for two-way communication with serviceWorker
            //port 1: for UI 
            //port 2: for Service Worker
            //ref https://felixgerschau.com/how-to-communicate-with-service-workers/
            //ref https://googlechrome.github.io/samples/service-worker/post-message/
            var respMessageChannel = new MessageChannel(); 
            SkbLogger.logDebugInfo('new MessageChannel()',respMessageChannel); 
            //listening response from swervice worker
            respMessageChannel.port1.onmessage = swMessageHandler;
            const lastWakeupMessage=localStorage.getItem('last_wakeup_message');
            SkbLogger.applicationTrace('Data Sync',0,'Starting','lastWakeupMessage in localStorage.',lastWakeupMessage);
            var intLastWakeupMessage = 0;
            if(lastWakeupMessage){
                        intLastWakeupMessage=parseInt(lastWakeupMessage);
            }
            console.log('intLastWakeupMessage',intLastWakeupMessage);
            var checkpoint2 = (new Date()).getTime()-60*1000;
            if(intLastWakeupMessage<checkpoint2){
                SkbLogger.applicationTrace('Data Sync',0,'Starting','Posting Online/Wakeup Message to Sync Service Worker. (online event)',{message:wakeupSyncMessage});
                //SkbLogger.logDebugInfo('posting online message to sw');
                navigator.serviceWorker.controller
                        .postMessage(wakeupSyncMessage,[respMessageChannel.port2]);
                localStorage.setItem('last_wakeup_message',''+((new Date()).getTime()));
            }else{
                SkbLogger.applicationTrace('Data Sync',0,'Starting','Skipping Posting due to lastMessage. (online event)',{lastMessage:lastWakeupMessage,checkpoint:checkpoint2});
            }
        }else{
            //SkbLogger.logDebugInfo("serviceWorker not ready");
            SkbLogger.applicationTrace('Data Sync',1,'Starting','Sync Service Worker not ready while App back online.',{hasRetried:hasRetried});
            setTimeout( ()=>{
                /*
                if(!hasRetried){
                    setupCommunicationWithServiceWorker(sync,dispatch,true);
                }
                */

               if (!('serviceWorker' in navigator)) {
                    SkbLogger.applicationException('Data Sync','Starting','Browser does not support Service Worker.',{currentDevice:currentDevice});
                    return;        
                }
                
                if(navigator.serviceWorker.controller){
                    var respMessageChannel = new MessageChannel(); 
                    SkbLogger.logDebugInfo('new MessageChannel()',respMessageChannel); 
                    //listening response from swervice worker
                    respMessageChannel.port1.onmessage = swMessageHandler;
                    const lastWakeupMessage=localStorage.getItem('last_wakeup_message');
                    SkbLogger.applicationTrace('Data Sync',0,'Starting','lastWakeupMessage in localStorage.',lastWakeupMessage);
                    var intLastWakeupMessage = 0;
                    if(lastWakeupMessage){
                        intLastWakeupMessage=parseInt(lastWakeupMessage);
                    }
                    console.log('intLastWakeupMessage',intLastWakeupMessage);
                    var checkpoint3 = (new Date()).getTime()-60*1000;
                    if(intLastWakeupMessage<checkpoint3){
                        SkbLogger.applicationTrace('Data Sync',0,'Starting','Re-Posting Online/Wakeup Message to Sync Service Worker.',{message:wakeupSyncMessage});
                        //SkbLogger.logDebugInfo('posting online message to sw');
                        navigator.serviceWorker.controller
                            .postMessage(wakeupSyncMessage,[respMessageChannel.port2]);
                        localStorage.setItem('last_wakeup_message',''+((new Date()).getTime()));
                    }else{
                        SkbLogger.applicationTrace('Data Sync',0,'Starting','Skipping Re-Posting due to lastMessage. (online event) ',{lastMessage:lastWakeupMessage,checkpoint:checkpoint3});
                    }
                    
                }else{
                    SkbLogger.applicationException('Data Sync','Starting','Sync Service Worker not ready for re-posting while App back online.',{ready:navigator.serviceWorker.ready});
                    navigator.serviceWorker.getRegistration().then(function(registration) {
                        if(registration){
                            SkbLogger.applicationTrace('Data Sync','Starting',1,'Sync Service Worker Registration Object.',{registration:JSON.stringify(registration)});
                        }else{
                            SkbLogger.applicationTrace('Data Sync','Starting',1,'Sync Service Worker has no Registration Object.');
                        }
                      }).catch(err => {
                        SkbLogger.applicationTrace('Data Sync','Starting',1,'Sync Service Worker failed to get Registration Object.',{error:err});
                      });
            
                }
                
                
            },5000); //try again in 5 seconds
            
        }
        dispatch(syncActions.setIsOnline(true));
        //console.log('after setIsOnline(true), sync.isOnline = ',sync.isOnline);
    };

    const offlineHandler = () =>{
        
        dispatch(syncActions.setIsOnline(false)); //show offline bar
        //console.log('after setIsOnline(false), sync.isOnline = ',sync.isOnline);
    };

    //listening online/offline events of navigator
    try {
        window.removeEventListener('online', onlineHandler);
    } catch (error) { //supressed error 
    }

    try {
        window.removeEventListener('offline', offlineHandler);
    } catch (error) { //suppressed error
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);
    SkbLogger.applicationTrace('Data Sync',0,'Starting','add listeners for online/offline events.');

    //below controllerchange is for refreshing webpage when there is a new version of service worker
    var refreshing;
    const controllerchangeHandler = () =>{
        if (refreshing) return;
            refreshing = true;
            SkbLogger.applicationTrace('Data Sync',0,'Starting','Refreshing Web Pages when Service Worker updated');
            window.location.reload();
    };

    try {
        navigator.serviceWorker.removeEventListener('controllerchange', controllerchangeHandler);
    } catch (error) { //supressed error 
    }

    navigator.serviceWorker.addEventListener('controllerchange',controllerchangeHandler);
    SkbLogger.applicationTrace('Data Sync',0,'Starting','add listeners for controllerchange events.');

}
 
function  Syncbar(props) {
    const classes = useStyles();
    const {sync,dispatch}=props;

    React.useEffect( async () =>{
        //init the offline bar
        SkbLogger.logDebugInfo('init online = ',navigator.onLine);
        dispatch(syncActions.setIsOnline(navigator.onLine));
        //init listerners in setupCommunicationWithServiceWorker while page reloading
        const authUserInLS=localStorage.getItem('auth_user');
        const idTokenInLS=localStorage.getItem('id_token');
        if(authUserInLS && idTokenInLS  ){ 
            //SkbLogger.logDebugInfo('setupCommunicationWithServiceWorker');
            await setupCommunicationWithServiceWorker(sync,dispatch);
        }
    },[]);


    //checking heartbeats (recent 15 minutes) to setupCommunicationWithServiceWorker again
    try {
        const authUserInLS=localStorage.getItem('auth_user');
        const idTokenInLS=localStorage.getItem('id_token');
        const lastLoadingHeartBeat=localStorage.getItem('last_loading_heartbeats');
        SkbLogger.applicationTrace('Data Sync',0,'Starting','lastLoadingHeartBeat in localStorage.',{lastLoadingInLS:lastLoadingHeartBeat});
        var intLastLoadingHeartbeat = 0;
        if(lastLoadingHeartBeat){
            intLastLoadingHeartbeat=parseInt(lastLoadingHeartBeat);
        }
        console.log('intLastLoadingHeartbeat',intLastLoadingHeartbeat);
        var checktime=(new Date()).getTime()-1*1000;  //this time should not be too long (like 60 sec), to avoid missing the chance to start sw
        if(intLastLoadingHeartbeat<checktime){
            if(authUserInLS && idTokenInLS  ){ 
                console.log('checking heartbeat in syncbar for lastHeartbeatTimestamp ');
                dataSyn.loadRecentHeartbeats(15).then( async (heartbeats) => {
                    //logging heartbeats
                    localStorage.setItem('last_loading_heartbeats',''+((new Date()).getTime()));
                    console.log('got heartbeat in syncbar for lastHeartbeatTimestamp');
                    SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','Sync SW Heartbeats {dataObject} in the past 15 minutes.',{dataObject:heartbeats, swController:navigator.serviceWorker.controller});
                    //get lastHeartbeatTimestamp
                    var lastHeartbeatTimestamp=0;
                    for (let index = 0; index < heartbeats.length; index++) {
                        const oneHeartBeat = heartbeats[index];
                        if(lastHeartbeatTimestamp<oneHeartBeat.timestamp) lastHeartbeatTimestamp=oneHeartBeat.timestamp;
                    }
                    console.log('lastHeartbeatTimestamp',lastHeartbeatTimestamp);
                    //start(restart) sw if lastHeartbeatTimestamp too old
                    var checktimeHeartbeat=(new Date()).getTime()-60*1000;
                    if(lastHeartbeatTimestamp<checktimeHeartbeat ){
                        SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','lastHeartbeatTimestamp triggered setupCommunicationWithServiceWorker',lastHeartbeatTimestamp);
                        console.log('setupCommunicationWithServiceWorker for lastHeartbeatTimestamp');
                        //SkbLogger.logDebugInfo('setupCommunicationWithServiceWorker');
                        await setupCommunicationWithServiceWorker(sync,dispatch);
                        //clear sync bar
                        dispatch(syncActions.setIsMetadataSyncing(false));
                        dispatch(syncActions.setIsPhotoSyncing(false));
                    }else{
                        SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','lastHeartbeatTimestamp Skipped triggering setupCommunicationWithServiceWorker due to lastHeartbeat',{lastHeartbeat:lastHeartbeatTimestamp, checktime:checktimeHeartbeat});
                    }
                }).catch( (error)=>{
                        SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','Heartbeats sending error (in Promise)',{error:error});
                });
            } else{
                SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','lastHeartbeatTimestamp Skipped triggering setupCommunicationWithServiceWorker due to auth',{auth:authUserInLS});
            } 
        }else{
            SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','lastHeartbeatTimestamp Skipped triggering setupCommunicationWithServiceWorker due to lastLoading',{lastLoading:intLastLoadingHeartbeat, checktime:checktime});
                    
        }

    } catch (error) {
        SkbLogger.applicationTrace('Data Sync',0,'Heartbeat','Heartbeats sending error',{error:error});
    }


    if(sync.isOnline && (sync.isPhotoSyncing || sync.isMetadataSyncing)){
        SkbLogger.applicationTrace("Syncbar", 1, "Syncbar", "show syncing sync bar", {message: {isPhotoSyncing: sync.isPhotoSyncing,isMetadataSyncing: sync.isMetadataSyncing }});

        return (
            <div className={classes.syncbarContainer} id='syncbar'>
                <div>
                    <LinearProgress id='syncbar-syncing'
                        color='primary' 
                        variant="indeterminate"  
                    />
                    <Typography id="syncbar-syncing-text" 
                        className={classes.syncbarSyncText}
                        variant="body2"
                        color="primary"
                        align='center'>
                        Synchronising... {`     `}
                    </Typography>
                </div>
            </div>
        );
    }else if(!sync.isOnline){
        return (
            <div className={classes.syncbarContainer} id='syncbar'>
                <div>
                    <LinearProgress id='synbar-offline'
                        color='secondary' 
                        variant="determinate"  
                        value="100"
                    />
                    <Typography id="syncbar-syncing-text" 
                        className={classes.syncbarOfflineText}
                        gutterBottom="false" variant="body2"
                        color="secondary"
                        align='center'>
                        Offline Mode {`     `}
                    </Typography>
                </div>
            </div>
        );
    }else{
        SkbLogger.applicationTrace("Syncbar", 1, "Syncbar", "show syncing sync bar", {message: "Sync bar is hiding"});
        return (<div className={classes.syncbarContainer} id='syncbar'></div>);
    };
    
}

const mapStateToProps = state => ({
    sync:state.sync
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Syncbar);