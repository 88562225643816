import { LocationCityOutlined } from '@material-ui/icons';
import produce from 'immer';
import { SkbLogger, ApiService } from '../../services';
export const contractorStock = produce((draft, action) => {
    switch (action.type) {
        case Actions.CONTRACTORSTOCK_REFRESH_BEGIN:
            draft.isLoading = true;
            break;
        case Actions.CONTRACTORSTOCK_REFRESH_END:
            draft.isLoading = false;
            break;
        case Actions.CONTRACTORSTOCK_UPDATE_ERROR:
            draft.Error = action.data;
            if(action.data && action.data!=''){
                SkbLogger.applicationException("Stock","Contractor Stock","CONTRACTORSTOCK_UPDATE_ERROR {error}",{error:draft.Error});
            }
            break;
        case Actions.CONTRACTORSTOCK_STOCKLOOKUP_LOADED:
            draft.StockLookup = action.data;
            break;
        case Actions.CONTRACTORSTOCK_CONTRACTOR_LOADED:
            draft.StockOnHand = action.data;
            break;
        case Actions.CONTRACTORSTOCK_CONTRACTORLOCATION_LOADED:
            if (draft.StockOnHand && Array.isArray(draft.StockOnHand)) {
                let contractor = draft.StockOnHand.find(c => c.CompanyId === action.data.CompanyId);
                if (contractor) contractor.Locations = [...action.data.Locations];

            }
            break;
        case Actions.CONTRACTORSTOCK_CONTRACTOR_PENDING_CONSIGNMENT_LOADED:           
                draft.PendingConsignments =action.data;
                break;
        case Actions.CONTRACTORSTOCK_CONTRACTORLOCATIONSTOCK_LOADED:
            if (draft.StockOnHand && Array.isArray(draft.StockOnHand)) {
                let contractor = draft.StockOnHand.find(c => c.CompanyId === action.data.CompanyId);
                if (contractor) {
                    var location = contractor.Locations.find(l=>l.LocationCode === action.data.LocationCode);
                    
                    //console.log(location);
                    if(location){
                        //location = {...location, ...action.data.StockData};
                        location.SerialisedItems = action.data.StockData.SerialisedItems;
                        location.NonserialisedItems = action.data.StockData.NonserialisedItems;
                    }
                }

            }

            


            break;
        default:
            break;
    };
}, { //initial store data
    isLoading: false,
    Error: ''
});

export const Actions = {
    CONTRACTORSTOCK_REFRESH_BEGIN: "CONTRACTORSTOCK_REFRESH_BEGIN",
    CONTRACTORSTOCK_REFRESH_END: "CONTRACTORSTOCK_REFRESH_END",
    CONTRACTORSTOCK_UPDATE_ERROR: "CONTRACTORSTOCK_UPDATE_ERROR",
    CONTRACTORSTOCK_STOCKLOOKUP_LOADED: "CONTRACTORSTOCK_STOCKLOOKUP_LOADED",
    CONTRACTORSTOCK_CONTRACTOR_LOADED: "CONTRACTORSTOCK_CONTRACTOR_LOADED",
    CONTRACTORSTOCK_CONTRACTORLOCATION_LOADED: "CONTRACTORSTOCK_CONTRACTORLOCATION_LOADED",
    CONTRACTORSTOCK_CONTRACTORLOCATIONSTOCK_LOADED: "CONTRACTORSTOCK_CONTRACTORLOCATIONSTOCK_LOADED",
    CONTRACTORSTOCK_CONTRACTOR_PENDING_CONSIGNMENT_LOADED: "CONTRACTORSTOCK_CONTRACTOR_PENDING_CONSIGNMENT_LOADED"
}