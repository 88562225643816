import React, { useEffect, useState, forwardRef } from 'react';

import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { connect } from 'react-redux';
import * as variablesActions from '../../../Redux/actions/variables'
import MaterialTable, { MTableToolbar, MTableEditField,MTableEditRow } from 'material-table';

import Loading from '../../Loading';
import { SkbLogger } from '../../../services';
import { tableIcons } from '../_share/tableIcons';
import uuid from 'react-uuid'
import { isRowValidated, cellValidate } from '../_share/tablevalidation';



const useStyles = makeStyles((theme) => ({

    paper: {
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 16px)',
            margin: theme.spacing(0),


        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 200px)',
            marginBottom: theme.spacing(2),
        }
    },
    section: {
        marginTop: theme.spacing(2),
    },
    text: {
        paddingLeft: theme.spacing(1) / 2,
    }

}));

function Divisions({ user, Divisions, updateDivision, delDivision, addDivision }) {

    //set up table columns
    const [columns, setColumns] = useState([
        { title: 'Id', field: 'id', hidden: true },
        { title: 'resourceID', field: 'resourceID', hidden: true },
        { title: 'variableGroup', field: 'variableGroup', hidden: true },
        { title: 'parentVariableID', field: 'parentVariableID', hidden: true },

        {
            title: 'Division Name', field: 'variableName', validate: rowData => cellValidate(rowData.variableName, /^[^\s].{0,100}$/ig, 'Invalid Division Name'), editComponent: props => (
                <MTableEditField
                    autoFocus
                    {...props}
                />
            )
        },
        { title: 'Acronym', field: 'variableValue', validate: rowData => cellValidate(rowData.variableValue, /^[^\s].{0,50}$/ig, 'Invalid Acronym') },
        {
            title: 'Active', field: 'active', type: 'numeric', initialEditValue: 1,
            lookup: { 1: 'Yes', 0: 'No' }
        },
    ]);

    const [data, setData] = useState([...Divisions]);
    const toolbarRef = React.useRef(null);// generate toobar ref in order that to manipulate view position after page size is changed 
    return (
        <div id="divisiontable">
            <MaterialTable
                icons={tableIcons}
                showTitle={false}
                searchable={false}
                toolbar={false}
                columns={columns}
                data={data}
                padding={'dense'}
                components={{
                    Toolbar: props => (
                        <div ref={toolbarRef}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    EditRow: props => <MTableEditRow
                    onKeyDown = {
                        e=>{
                           //override the default behaviour 
                        }
                    }

                        {...props}
                    />
                }}
                onChangeRowsPerPage={() => setTimeout(() => { try { toolbarRef.current.scrollIntoViewIfNeeded(); } catch (e) { } }, 500)}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if (isRowValidated(columns, newData)) {
                                    newData.resourceID = uuid().replace(/-/ig, '');
                                    setData([...data, newData]);
                                    addDivision({ Id: null, variableGroup: "Divisions", parentVariableID: null, variableName: newData.variableName, variableValue: newData.variableValue, active: newData.active, resourceID: newData.resourceID }, user);
                                    resolve();
                                }
                                else resolve();

                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>

                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                updateDivision(newData, user);
                                resolve();
                            }, 1000)
                        }),
                    //**********currently delete funciton is disabled due to server will NOT actaully detele record */
                    //   onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         const dataDelete = [...data];
                    //         const index = oldData.tableData.id;
                    //         delDivision.splice(index, 1);
                    //         delCountry({Id:oldData.id, variableGroup:oldData.variableGroup, parentVariableID:oldData.variableGroup, variableName:oldData.variableName, variableValue:oldData.variableValue, active: oldData.active, resourceID:oldData.resourceID});
                    //         setData([...dataDelete]);

                    //         resolve()
                    //       }, 1000)
                    //     }),
                }}
                options={{ showTitle: false, search: false, actionsColumnIndex: -1 }}
            />
        </div>
    )
}

function Programs({ user, Divisions, Programs, addProgram, updateProgram, deleteProgram }) {

    let divisionLookupList = new Object();
    Divisions.forEach(d => {
        if (d.active == 1) divisionLookupList[d.id] = d.variableValue;
    });
    divisionLookupList[0] = "";

    SkbLogger.logDebugInfo("Associated Division divisionLookupList", divisionLookupList);

    var initColumns = [
        { title: 'Id', field: 'id', hidden: true },
        { title: 'resourceID', field: 'resourceID', hidden: true },
        {
            title: 'Program Name', field: 'name', hidden: false, validate: rowData => cellValidate(rowData.name, /^[^\s].{0,100}$/ig, 'Invalid Program Name'),
            editComponent: props => (
                <MTableEditField
                    autoFocus
                    {...props}
                />
            )
        },

        { title: 'Program Acronym', field: 'acronym', hidden: false, validate: rowData => cellValidate(rowData.acronym, /^[^\s].{0,100}$/ig, 'Invalid Program Acronym') },
        {
            title: 'Associated Division', field: 'divisionVariableID', hidden: false, initialEditValue: 0,
            lookup: { ...divisionLookupList }, validate: rowData =>
                rowData.divisionVariableID != 0 ? true : { helperText: 'Invalid Associated Division', isValid: false }
        },
        {
            title: 'Active', field: 'active', type: 'numeric', initialEditValue: 1,
            lookup: { 1: 'Yes', 0: 'No' }
        },
    ];
    const [columns, setColumns] = useState(initColumns);
    const toolbarRef = React.useRef(null);// generate toobar ref in order that to manipulate view position after page size is changed 

    const [data, setData] = useState([...Programs]);
    React.useEffect(() => {
        setColumns(initColumns);
    }, [Divisions])
    return (
        <div id="programstable">
            <MaterialTable
                components={{
                    Toolbar: props => (
                        <div ref={toolbarRef}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    EditRow: props => <MTableEditRow
                    onKeyDown = {
                        e=>{
                           //override the default behaviour 
                        }
                    }

                        {...props}
                    />
                }}
                padding={'dense'}
                icons={tableIcons}
                showTitle={false}
                searchable={false}
                toolbar={false}
                columns={columns}
                data={data}
                onChangeRowsPerPage={() => setTimeout(() => { try { toolbarRef.current.scrollIntoViewIfNeeded(); } catch (e) { } }, 500)}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if (isRowValidated(columns, newData)) {
                                    newData.resourceID = uuid().replace(/-/ig, '');
                                    setData([...data, newData]);
                                    addProgram({ Id: null, parentVariableID: null, name: newData.name, acronym: newData.acronym, divisionVariableID: newData.divisionVariableID, resourceID: newData.resourceID, active: newData.active }, user);
                                    resolve();
                                }
                                else reject();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                updateProgram(newData, user);
                                resolve();
                            }, 1000)
                        }),
                    //**********currently delete funciton is disabled due to server will NOT actaully detele record */
                    //   onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         const dataDelete = [...data];
                    //         const index = oldData.tableData.id;
                    //         dataDelete.splice(index, 1);
                    //         deleteProgram({Id:oldData.id, variableGroup:oldData.variableGroup, parentVariableID:oldData.variableGroup, variableName:oldData.variableName, variableValue:oldData.variableValue, active: oldData.active}, user);
                    //         setData([...dataDelete]);

                    //         resolve()
                    //       }, 1000)
                    //     }),
                }}
                options={{ showTitle: false, search: false, actionsColumnIndex: -1 }}
            />
        </div>
    )
}

function DivisionVariables(props) {

    const { variables, getDivisions, user, updateDivision, addDivision, delDivision, addProgram, updateProgram, deleteProgram } = props;
    const classes = useStyles();
    useEffect(() => {
        if (!variables.Divisions || variables.Divisions.length == 0)
            getDivisions();
    }, []);

    if (variables.isLoading && (variables.Divisions.length == 0 || variables.Programs.length == 0)) {
        return (<Loading />);
    }
    else {
        return (
            <div>
                <Paper className={classes.paper}>
                    <Typography className={classes.text} variant="h3">Division/Program Variables</Typography>
                    <div className={classes.section}>
                        <Typography className={classes.text} variant="h4"> Divisions</Typography>
                        <Typography className={classes.text} variant="body1"> List of divisions within the business that require access to the system.</Typography>
                        <Divisions user={user} Divisions={variables.Divisions} updateDivision={updateDivision} addDivision={addDivision} delDivision={delDivision} />
                    </div>
                    <div className={classes.section}>
                        <Typography className={classes.text} variant="h4"> Programs</Typography>
                        <Typography className={classes.text} variant="body1">List of programs and their mapping back to divisions.</Typography>
                        <Programs user={user} Divisions={variables.Divisions} Programs={variables.Programs} addProgram={addProgram} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                    </div>

                </Paper>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    user: state.auth,
    register: state.register,
    variables: state.variables
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDivisions: () => dispatch(variablesActions.getDivisions()),
        updateDivision: (c, auth) => dispatch(variablesActions.updateDivision(c, auth)),
        addDivision: (c, auth) => dispatch(variablesActions.addDivision(c, auth)),
        delDivision: (c, auth) => dispatch(variablesActions.delDivision(c, auth)),
        addProgram: (c, auth) => dispatch(variablesActions.addProgram(c, auth)),
        updateProgram: (c, auth) => dispatch(variablesActions.updateProgram(c, auth)),
        deleteProgram: (c, auth) => dispatch(variablesActions.deleteProgram(c, auth)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DivisionVariables);
