import Dexie, { DBCoreRangeType } from 'dexie';
import { SeverityLevel,SkbLogger } from '../services';
import * as localDB from '../utils/localDB';
import uuid from 'react-uuid';
import {crypts} from '../utils/crypts';
import * as syncAPI from './DataSyncWebAPI';
import { config } from '../utils/config';
import axios from "axios";
import { BlobServiceClient} from "@azure/storage-blob";
import { SignalCellularNullOutlined } from '@material-ui/icons';

var localDbInitialised = 0;
var auth0UserID='';
var idToken='';
var apiKey='';
var sas_azureStorageKey='';
var crcTableForPhotoSync = [];

function initLocalDBIfNotYet(){
    //stop re-init
    if(localDbInitialised) return;

    //localDB is supposed to be initialised after login
    idToken = crypts.decrypt(localStorage.getItem('id_token'));
    apiKey= config.REACT_APP_API_SECRET;
    const authUser = JSON.parse(crypts.decrypt(localStorage.getItem('auth_user')));
    sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
    if(authUser) auth0UserID = authUser.sub;

    if(authUser && auth0UserID){
        SkbLogger.logDebugInfo("init DB...",auth0UserID);
        localDB.initForUser(auth0UserID);
        localDbInitialised=1;
    }
}

/*
function uuidv4() {
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 6 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(6);
    });
  }
  */

function removeDataWithOperationD(fromArray){
    if(!fromArray) return null;
    var retArray=[];
    for (let i = 0; i < fromArray.length; i++) {
        const oneData = fromArray[i];
        if(oneData.operation=='D') continue;
        if(oneData.type && oneData.type=='CONFLICT' ) continue;
        if(oneData.key && oneData.key=='DELETED' ) continue;
        retArray.push(oneData);
    }
    SkbLogger.logDebugInfo("removeDataWithOperationD returning",retArray);
    return retArray;
}

function hasLocalChange(data){
    //non-blank operation
    return (data && data.operation && (data.operation=='I' || data.operation=='U' || data.operation=='D') );
}

export async function loadRecentHeartbeats(minutes){
    initLocalDBIfNotYet();
    return await localDB.loadRecentHeartbeats(minutes);
}

////////////////////// DATACLUSTER ///////////////////////////

/**
 * 
 * @param {*} resourceID new or existing uuid 
 * @param {*} name cluster name
 * @param {*} referenceEntity "JOB", "STOCKTAKE", "TASK" or so
 * @param {*} referenceID ID of referenceEntity
 * @param {*} scope which level the cluster is shared. "User", "Company" or "Global". default "User"
 * @param {*} toTrytoSaveToServer set it false only if you want to push it to server later
 * @param {*} parentResourceID NULL for root cluster or no change
 */
export async function saveDataCluster(resourceID, name, referenceEntity, referenceID, scope='User',toTrytoSaveToServer=true, parentResourceID=null){
    if(!resourceID) throw new Error('No ResourceID');
    initLocalDBIfNotYet();
    //local DB
    var operation='I';
    const existingCluster = await localDB.getDataClusterByResourceID(resourceID);
    var existingVersion=0;
    var existingParent=null;
    if(existingCluster) {
        operation='U';
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Save and Track',
        '{dataObject} (dataCluster) is being updated.',{dataObject:existingCluster});
        existingVersion=(existingCluster.version||0);
        existingParent=(existingCluster.parentResourceID||null);
    }
    else {
        operation='I';
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Save and Track',
        '{dataObject} (dataCluster) is being inserted.',{dataObject:resourceID});
    };
    const thedata={resourceID:resourceID,
        name:name,
        scope: scope,
        referenceEntity:referenceEntity,
        referenceID:referenceID,
        timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
        parentResourceID:parentResourceID||existingParent, 
        version:existingVersion,
        operation:operation
        };
    await localDB.writeDataClusterAsync(thedata);
    //to server-side
    if(toTrytoSaveToServer && (auth0UserID.startsWith('dummy') || navigator.onLine)){
        try {
            const resp=await syncAPI.saveDataCluster(auth0UserID,idToken,apiKey,thedata.resourceID,thedata);
            SkbLogger.logDebugInfo("saveDataCluster resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299){ //&& resp.data){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Upload Data','{dataObject} (dataCluster) has been uploaded through realtime API.',resp.data);
                //only update operation, not version or value
                //let clusterToSave=resp.data; 
                thedata.operation='S'; //S for partial/realtime sync
                await localDB.writeDataClusterAsync(thedata);
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data'
                            ,'{dataObject} (dataCluster) failed to be uploaded through realtime API.'
                            ,{dataObject:thedata,error:error});
        }
    }
}

/**
 * 
 * @param {*} resourceID the uuid used to create the DataCluster
 * @returns object of DataCluster (ONLY FROM LOCAL DB)
 * sample return
 *      {
 *           resourceID: 'f305824132e3ac1ffd6c66c311ae25',
 *           name: 'Stocktake Data 1234 Part 1',
 *           scope: 'User',
 *           referenceEntity: 'STOCKTAKE',
 *           referenceID: 1234,
 *           timestamp: '2020-08-21T04:29:00.612Z',
 *           version: 0,
 *           operation: 'I'
 *       }
 * 
 */
export async function getDataCluster(resourceID){
    initLocalDBIfNotYet();    
    const ret = await localDB.getDataClusterByResourceID(resourceID);
    if(ret && ret.operation=='D'){
        return null;
    }
    if(ret && ret.referenceEntity=='DELETED'){
        return null;
    }
    return ret;
}
//copy the back end sync to the front end. referenceEntity should be removed. leave it just for test for now.
/* export async function SyncDataWithServer(referenceEntity){
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        try {
            //SkbLogger.logDebugInfo("queryDataClustersForEntity to-server started");
            const resp=await syncAPI.queryDataClusters(auth0UserID,idToken,apiKey,referenceEntity,0);
            SkbLogger.logDebugInfo("queryDataClusters resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299){
                const clusterArray=resp.data;
                //SkbLogger.logDebugInfo("queryDataClustersForEntity loop started");
                for (let i = 0; i < clusterArray.length; i++) {
                    let oneCluster = clusterArray[i];
                    //SkbLogger.logDebugInfo("checking cluster",i,oneCluster.resourceID);
                    const existingCluster=await localDB.getDataClusterByResourceID(oneCluster.resourceID);
                    if(hasLocalChange(existingCluster)){
                        //cluster has local change, not to overwrite
                        SkbLogger.logDebugInfo("not to overwrite local changes");
                    }else{
                        SkbLogger.applicationTraceSub('Data Sync',0,'Data Synchronisation','Download Data',
                                            '{dataObject} (dataCluster) has been downloaded through realtime API.'
                                            ,{dataObject:oneCluster});
                        oneCluster.operation='S'; //S for partially/realtime sync
                        await localDB.writeDataClusterAsync(oneCluster);
                    }
                }
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data'
                            ,'{dataObject} (dataCluster) failed to be downloaded through realtime API.'
                            ,{dataObject:{referenceEntity:referenceEntity},error:error} );
        }
    }
} */

export async function isReasonableAmountOfDataInLocal(){
    const clusterCount=await localDB.getAllLocalClusterCount();
    const itemCount=await localDB.getAllLocalItemCount();
    if(clusterCount>=2 && itemCount>=10){
        SkbLogger.logDebugInfo("isReasonableAmountOfDataInLocal true. ",clusterCount,itemCount);
        return true;
    }else{
        SkbLogger.logDebugInfo("isReasonableAmountOfDataInLocal false. ",clusterCount,itemCount);
        return false;
    }
}

/**
 * when UI wants a force refresh
 * ONLY for ROOT dataclusters within date range (according to sync setting)
 */
export async function loadServerDataClustersForEntity(referenceEntity, referenceID=0){
        if(auth0UserID.startsWith('dummy') || navigator.onLine){
            try {
                var downloadDaysSettingForChangesSync = 3; //default
                var pastDaysSettingForChangesSync = 1; //default
            
                const settingClusterArray=await localDB.getDataClusterForEntityName('SETTING');
                if(settingClusterArray && Array.isArray(settingClusterArray) && settingClusterArray.length>0){
                    const downloadDaysArray = await localDB.getDataItemsByKeysForCluster(settingClusterArray[0].resourceID,'DownloadMetadataInDays');
                    //console.log('getDataItemsByKeyInCluster DownloadMetadataInDays',downloadDaysArray);
                    if(downloadDaysArray && Array.isArray(downloadDaysArray) && downloadDaysArray.length>0){
                        const downloadDaysValue=parseInt(downloadDaysArray[0].value);
                        if(downloadDaysValue && !isNaN(downloadDaysValue) && downloadDaysValue>0){
                            //console.log('set downloadDaysSettingForChangesSync',downloadDaysValue);
                            downloadDaysSettingForChangesSync=downloadDaysValue;
                            pastDaysSettingForChangesSync=downloadDaysValue / 3;
                        }
                    }
                }

                var startDate=new Date();
                startDate.setDate(new Date().getDate() - pastDaysSettingForChangesSync);
                var endDate=new Date()
                endDate.setDate(new Date().getDate() + downloadDaysSettingForChangesSync);
                const startDateStr=startDate.toISOString();
                const endDateStr=endDate.toISOString();
                //console.log('startDateStr',startDateStr);
                const startYyyymmdd = startDateStr.substring(0,10);
                const endYyyymmdd = endDateStr.substring(0,10);
                //console.log('startYyyymmdd',startYyyymmdd);

                //SkbLogger.logDebugInfo("queryDataClustersForEntity to-server started");
                const resp=await syncAPI.queryDataClusters(auth0UserID,idToken,apiKey,referenceEntity,referenceID,startYyyymmdd,endYyyymmdd);
                SkbLogger.logDebugInfo("queryDataClusters resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){
                    const clusterArray=resp.data;
                    //SkbLogger.logDebugInfo("queryDataClustersForEntity loop started");
                    for (let i = 0; i < clusterArray.length; i++) {
                        let oneCluster = clusterArray[i];
                        //SkbLogger.logDebugInfo("checking cluster",i,oneCluster.resourceID);
                        const existingCluster=await localDB.getDataClusterByResourceID(oneCluster.resourceID);
                        if(hasLocalChange(existingCluster)){
                            //cluster has local change, not to overwrite
                            SkbLogger.logDebugInfo("not to overwrite local changes");
                        }else{
                            SkbLogger.applicationTraceSub('Data Sync',0,'Data Synchronisation','Download Data',
                                                '{dataObject} (dataCluster) has been downloaded through realtime API.'
                                                ,{dataObject:oneCluster});
                            oneCluster.operation='S'; //S for partially/realtime sync
                            await localDB.writeDataClusterAsync(oneCluster);
                        }
                    }
                }
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data'
                                ,'{dataObject} (dataCluster) failed to be downloaded through realtime API.'
                                ,{dataObject:{referenceEntity:referenceEntity},error:error} );
            }
        }
        
    
}

/**
 * 
 * @param {*} referenceEntity "SETTING", "STOCKTAKE" or so, '' means all referenceEntity
 * @param {*} referenceID ID of referenceEntity. 0 means all ROOT dataCluster. default 0. 
 * @returns array of DataClusters (AFTER LOADING DATA FROM SERVER SIDE)
 *  return sample
 *      [
 *           {
 *               resourceID: '66c311ae25f305824132e3ac1ffd6c',
 *               name: 'Stocktake Data 1234 Part 1',
 *               scope: 'User',
 *               referenceEntity: 'STOCKTAKE',
 *               referenceID: 1234,
 *               timestamp: '2020-08-21T04:29:00.612Z',
 *               version: 0,
 *               operation: 'I'
 *           },
 *           {
 *               resourceID: 'f305824132e3ac1ffd6c66c311ae25',
 *               name: 'Stocktake Data 1234 part 2',
 *               scope: 'User',
 *               referenceEntity: 'STOCKTAKE',
 *               referenceID: 1234,
 *               timestamp: '2020-08-21T04:29:00.612Z',
 *               version: 0,
 *               operation: 'I'
 *           }
 *       ]
 * 
 */
export async function queryDataClustersForEntity(referenceEntity, referenceID=0){
    //SkbLogger.logDebugInfo("queryDataClustersForEntity started");
    initLocalDBIfNotYet();

    const curretTimestamp=new Date().getTime();
    const lastTimestamp = localStorage.getItem('last-queryDataClustersForEntity-'+referenceEntity+'-'+referenceID+'-time');
    if((lastTimestamp && lastTimestamp>curretTimestamp-2*60*1000) || (!navigator.onLine && !auth0UserID.startsWith('dummy') )){ // 2 minutes
        //check localDB
        var ret;
        //SkbLogger.logDebugInfo("queryDataClustersForEntity localDB started");
        if(referenceID==0){
            ret = await localDB.getDataClusterForEntityName(referenceEntity);
        }else{
            ret = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceID);
        }
        SkbLogger.logDebugInfo("queryDataClustersForEntity returning before filtering",ret);
        return removeDataWithOperationD(ret);
    }else{
        //when there is no local data
        //check server side to refresh dataClusters
        //if(auth0UserID.startsWith('dummy') || navigator.onLine){
            try {
                var downloadDaysSettingForChangesSync = 3; //default
                var pastDaysSettingForChangesSync = 1; //default
            
                const settingClusterArray=await localDB.getDataClusterForEntityName('SETTING');
                if(settingClusterArray && Array.isArray(settingClusterArray) && settingClusterArray.length>0){
                    const downloadDaysArray = await localDB.getDataItemsByKeysForCluster(settingClusterArray[0].resourceID,'DownloadMetadataInDays');
                    //console.log('getDataItemsByKeyInCluster DownloadMetadataInDays',downloadDaysArray);
                    if(downloadDaysArray && Array.isArray(downloadDaysArray) && downloadDaysArray.length>0){
                        const downloadDaysValue=parseInt(downloadDaysArray[0].value);
                        if(downloadDaysValue && !isNaN(downloadDaysValue) && downloadDaysValue>0){
                            //console.log('set downloadDaysSettingForChangesSync',downloadDaysValue);
                            downloadDaysSettingForChangesSync=downloadDaysValue;
                            pastDaysSettingForChangesSync=downloadDaysValue / 3;
                        }
                    }
                }

                var startDate=new Date();
                startDate.setDate(new Date().getDate() - pastDaysSettingForChangesSync);
                var endDate=new Date()
                endDate.setDate(new Date().getDate() + downloadDaysSettingForChangesSync);
                const startDateStr=startDate.toISOString();
                const endDateStr=endDate.toISOString();
                //console.log('startDateStr',startDateStr);
                const startYyyymmdd = startDateStr.substring(0,10);
                const endYyyymmdd = endDateStr.substring(0,10);
                //console.log('startYyyymmdd',startYyyymmdd);

                //SkbLogger.logDebugInfo("queryDataClustersForEntity to-server started");
                const resp=await syncAPI.queryDataClusters(auth0UserID,idToken,apiKey,referenceEntity,referenceID,startYyyymmdd,endYyyymmdd);
                SkbLogger.logDebugInfo("queryDataClusters resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){
                    const clusterArray=resp.data;
                    localStorage.setItem('last-queryDataClustersForEntity-'+referenceEntity+'-'+referenceID+'-time',new Date().getTime());
                    //SkbLogger.logDebugInfo("queryDataClustersForEntity loop started");
                    for (let i = 0; i < clusterArray.length; i++) {
                        let oneCluster = clusterArray[i];
                        //SkbLogger.logDebugInfo("checking cluster",i,oneCluster.resourceID);
                        const existingCluster=await localDB.getDataClusterByResourceID(oneCluster.resourceID);
                        if(hasLocalChange(existingCluster)){
                            //cluster has local change, not to overwrite
                            SkbLogger.logDebugInfo("not to overwrite local changes");
                        }else{
                            SkbLogger.applicationTraceSub('Data Sync',0,'Data Synchronisation','Download Data',
                                                '{dataObject} (dataCluster) has been downloaded through realtime API.'
                                                ,{dataObject:oneCluster});
                            oneCluster.operation='S'; //S for partially/realtime sync
                            await localDB.writeDataClusterAsync(oneCluster);
                        }
                    }
                    //SkbLogger.logDebugInfo("queryDataClustersForEntity loop ended");
                    //read from local db again
                    if(referenceID==0){
                        ret = await localDB.getDataClusterForEntityName(referenceEntity);
                    }else{
                        ret = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceID);
                    }
                    SkbLogger.logDebugInfo("queryDataClustersForEntity returning before filtering",ret);
                    return removeDataWithOperationD(ret);
                }
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data'
                                ,'{dataObject} (dataCluster) failed to be downloaded through realtime API.'
                                ,{dataObject:{referenceEntity:referenceEntity,referenceID:referenceID},error:error} );
            }
        //}
        //no local data. no data from server (or no network, or network error)
        return null;
    }
        
    
}

/**
 * 
 * @param {*} resourceID cluster resourceID
 */
export async function fakeDeleteDataClusterAndItsDataItems(resourceID){
    //use referenceEntity = 'DELETED' 
    initLocalDBIfNotYet();
    const existingCluster = await localDB.getDataClusterByResourceID(resourceID);
    if(!existingCluster) return;
    const thedata={resourceID:resourceID,
        name:existingCluster.name,
        scope: existingCluster.scope,
        referenceEntity:'DELETED',
        referenceID:existingCluster.referenceID||0,
        timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
        version:existingCluster.version||0,
        operation:'U'
        };
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Save and Track',
        'marking dataCluster as deleted',thedata);
    await localDB.writeDataClusterAsync(thedata);
    const itsDataItems = await queryDataItemsForCluster(resourceID);
    SkbLogger.logDebugInfo('marking dataItems as deleted',itsDataItems);
    if(!itsDataItems) return;
    if(!Array.isArray(itsDataItems)) return;
    for (let i = 0; i < itsDataItems.length; i++) {
        const oneItem = itsDataItems[i];
        await deleteDataItem(oneItem.resourceID);
    }
    //to server-side for dataCluster
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        try {
            const resp=await syncAPI.saveDataCluster(auth0UserID,idToken,apiKey,resourceID,thedata);
            SkbLogger.logDebugInfo("deleteDataCluster resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299 ){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Call API','{dataObject} (dataCluster) has been deleted successfully through realtime API.',{dataObject:resp});
                thedata.operation='S';
                await localDB.writeDataClusterAsync(thedata);
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Delete Data','{dataObject} (cluster) has been deleted successfully in local DB',{dataObject:existingCluster});
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Call API','{dataObject} (dataCluster) failed to be deleted.',{dataObject:resourceID, error:error});
        }
    }
    
}


/**
 * 
 * @param {*} resourceID cluster resourceID
 */
export async function deleteDataClusterAndItsDataItems(resourceID){
    initLocalDBIfNotYet();
    const existingCluster = await localDB.getDataClusterByResourceID(resourceID);
    if(!existingCluster) return;
    const thedata={resourceID:resourceID,
        name:existingCluster.name,
        scope: existingCluster.scope,
        referenceEntity:existingCluster.referenceEntity||'',
        referenceID:existingCluster.referenceID||0,
        timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
        version:existingCluster.version||0,
        operation:'D'
        };
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Save and Track',
        'marking dataCluster as deleted',thedata);
    await localDB.writeDataClusterAsync(thedata);
    const itsDataItems = await queryDataItemsForCluster(resourceID);
    SkbLogger.logDebugInfo('marking dataItems as deleted',itsDataItems);
    if(!itsDataItems) return;
    if(!Array.isArray(itsDataItems)) return;
    for (let i = 0; i < itsDataItems.length; i++) {
        const oneItem = itsDataItems[i];
        await deleteDataItem(oneItem.resourceID);
    }
    //to server-side for dataCluster
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        try {
            const resp=await syncAPI.deleteDataCluster(auth0UserID,idToken,apiKey,resourceID);
            SkbLogger.logDebugInfo("deleteDataCluster resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299 ){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Call API','{dataObject} (dataCluster) has been deleted successfully through realtime API.',{dataObject:resp});
                await localDB.deleteDataClusterRow(existingCluster.resourceID);
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Delete Data','{dataObject} (cluster) has been deleted successfully in local DB',{dataObject:existingCluster});
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Call API','{dataObject} (dataCluster) failed to be deleted.',{dataObject:resourceID, error:error});
        }
    }
    
}


//////////////////// DATAITEM //////////////////////

/**
 * call this separately if you set toTrytoSaveToServer to false when you call saveDataItem or saveDataItemWithIndex
 * @param {*} resourceID dataItem resourceID that has been saved
 */
export async function pushDataItemToServer(resourceID){
    if(!resourceID) throw new Error('No ResourceID');
    //the dataitem
    var theDataItem = await getDataItem(resourceID);
    if(theDataItem && (auth0UserID.startsWith('dummy') || navigator.onLine)){
        //data item
        try {
            const resp=await syncAPI.saveDataItem(auth0UserID,idToken,apiKey,theDataItem.clusterResourceID,theDataItem.resourceID,theDataItem);
            SkbLogger.logDebugInfo("saveDataItem resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299 ){ // && resp.data){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Upload Data',
                                        '{dataObject} (dataItem) has been uploaded through realtime API.'
                                        ,{dataObject:resp.data});
                
                //let itemToSave=resp.data;  //server not returning data any more
                //itemToSave.operation='S'; //S for partial/realtime sync
                /*
                if(itemToSave.type && itemToSave.type=='PHOTO'){
                    //be careful about localArtefactContent in PHOTO
                    if(theDataItem && theDataItem.localArtefactContent){
                        //itemToSave.value is the path from server (jitterbit server may not update blank value)
                        if(!itemToSave.value || itemToSave.value=='' || itemToSave.value=='AWAIT_UPLOADING' || theDataItem.value==itemToSave.value ){
                            //same path, do not overwrite localArtefactContent
                            console.log('keep localArtefactContent for the same path/value from server.',itemToSave.value);
                            itemToSave.localArtefactContent=theDataItem.localArtefactContent;
                        }else if(!theDataItem.value || theDataItem.value=='' || theDataItem.value=='AWAIT_UPLOADING' ){
                            //same path, do not overwrite localArtefactContent
                            console.log('keep localArtefactContent and local path for blank local path/value.',theDataItem.value);
                            itemToSave.localArtefactContent=theDataItem.localArtefactContent;
                            itemToSave.value=theDataItem.value;
                        }else{
                            console.log('clear localArtefactContent for different path/value from server.',theDataItem.value,itemToSave.value,);
                            itemToSave.localArtefactContent='';
                        }
                    }else{
                        console.log('no need to deal with localArtefactContent',theDataItem);
                    }
                }
                */
               //only update operation, not version or value
                theDataItem.operation='S';
                await localDB.writeDataItemAsync(theDataItem);
                
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data'
                            ,'{dataObject} (dataItem) failed to be uploaded through realtime API.'
                            ,{dataObject:theDataItem,error:error} );
            
            //deleting local for 404
            try {
                if(error.message && typeof error.message == 'string'  && error.message.includes("status code 404")){
                    await localDB.deleteDataItemRow(resourceID);
                    const clusterResourceID=theDataItem.clusterResourceID;
                    const localDataItems=await localDB.getDataItemsForCluster(clusterResourceID);
                    if(!localDataItems || !localDataItems.length){
                        await localDB.deleteDataClusterRow(clusterResourceID);
                    }
                }
            } catch (err) {
            }
            
        }
        //cluster
        var theDataCluster = getDataCluster(theDataItem.clusterResourceID);
        if(!theDataCluster.resourceID) throw new Error('No cluster ResourceID');
        try {
            const resp=await syncAPI.saveDataCluster(auth0UserID,idToken,apiKey,theDataCluster.resourceID,theDataCluster);
            SkbLogger.logDebugInfo("saveDataCluster resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299){ //&& resp.data){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Upload Data','{dataObject} (dataCluster) has been uploaded through realtime API.',resp.data);
                //let clusterToSave=resp.data; //server not returning data any more
                //only update operation, not version or value
                theDataCluster.operation='S'; //S for partial/realtime sync
                await localDB.writeDataClusterAsync(theDataCluster);
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data'
                            ,'{dataObject} (dataCluster) failed to be uploaded through realtime API.'
                            ,{dataObject:theDataCluster,error:error});
        }
        
    }
}

/**
 * save photo locally only (without sending to server or sync)
 * @param {*} resourceID new or existing uuid 
 * @param {*} clusterResourceID which cluster the DataItem belongs to
 * @param {*} key the key name of dataitem key/value pair, to be unique within cluster.
 * @param {*} value the value of dataitem key/value pair
 * @param {*} type this parameter is not in use default "PHOTO"
 * @param {*} arrayIndex multi-dimention index for grouping. use '0.0' if no grouping
 * @param {*} description dataitem display text if required
 */
export async function saveLocalPhoto(resourceID, clusterResourceID, key, value, type='PHOTO', arrayIndex='0.0' ,description=''){
    initLocalDBIfNotYet();
    SkbLogger.logDebugInfo('saveLocalPhoto key value',key,value);
    
    const thedata={ resourceID:resourceID,
                    clusterResourceID:clusterResourceID,
                    type:type||'PHOTO',
                    key:key,
                    arrayIndex:arrayIndex,
                    value:value,
                    description:description,
                    timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z','')
                    };
    await localDB.writeLocalPhotoAsync(thedata);
}

/**
 * 
 * @param {*} resourceID new or existing uuid 
 * @param {*} clusterResourceID which cluster the DataItem belongs to
 * @param {*} key the key name of dataitem key/value pair, to be unique within cluster.
 * @param {*} value the value of dataitem key/value pair
 * @param {*} type "METADATA" or "PHOTO". default "METADATA"
 * @param {*} arrayIndex multi-dimention index for grouping. use '0.0' if no grouping
 * @param {*} description dataitem display text if required
 * @param {*} toMarkDataCluster set it false only if you want to update dataCluster.timestamp later or have done it already
 * @param {*} toTrytoSaveToServer set it false only if you want to push it to server later
 */
export async function saveDataItemWithIndex(resourceID, clusterResourceID, key, value, type='METADATA', arrayIndex='0.0' ,description='', toMarkDataCluster=true, toTrytoSaveToServer=true){
    if(!resourceID) throw new Error('No ResourceID');
    initLocalDBIfNotYet();
    SkbLogger.logDebugInfo('saveDataItemWithIndex key value',key,value);
    var operation='I';
    const existingItem = await localDB.getDataItemByResourceID(resourceID);
    var existingVersion=0;
    if(existingItem) {
        operation='U';
        SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Data Synchronisation','Save and Track',
        '{dataObject} (dataItem) is being updated.',{dataObject:existingItem});
        existingVersion=existingItem.version;
    }
    else {
        operation='I';
        SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Data Synchronisation','Save and Track',
        '{dataObject} (dataItem) is being inserted.',{dataObject:resourceID});
    };
    var valueToBe;
    var contentToBe;
    var photoName;
    var newBlobName;
    if(type && type=='PHOTO'){
        if(value=='AWAIT_UPLOADING' && key=='DELETED'){
            contentToBe='';
            valueToBe='AWAIT_UPLOADING'; //clear value/path as new value comes
        }else{
            contentToBe=value;
            valueToBe='AWAIT_UPLOADING'; //clear value/path as new value comes

            if((value != null || value != '') && navigator.onLine)
            {
                try{
                    sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Upload Data',
                                            '{dataObject} (dataItem) has started upload photo to Azure Blob',
                                            {dataObject:resourceID});

                    var blobServiceClient = new BlobServiceClient(
                        `https://${process.env.REACT_APP_AZURE_STORAGE_NAME}.blob.core.windows.net${sas_azureStorageKey}`
                        ); 
                        var byteCharacters = atob(value);
                        var contentType = 'image/png';
                        var byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        /*
                        photoName=uuidv4();
                        photoName=photoName.replace(/-/ig, '');
                        */
                        photoName=crc32ForPhotoSync(contentToBe);
                        newBlobName = `${clusterResourceID}/${resourceID}/${photoName}.jpg`;
                        //console.log('blob file name from CRC32',newBlobName);

                        var containerClient = blobServiceClient.getContainerClient(`${process.env.REACT_APP_AZURE_CONTAINER_NAME}`);
                        var blockBlobClient = containerClient.getBlockBlobClient(newBlobName);
                        var byteArray = new Uint8Array(byteNumbers);

                        
                        var uploadBlobResponse = await blockBlobClient.upload(byteArray, byteArray.length);
                        //console.log('bobl upload response ',uploadBlobResponse._response.status);

                        if(uploadBlobResponse._response.status ==201){
                            valueToBe=newBlobName;
                            operation='U';
                            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Upload Data',
                                                    '{dataObject} (dataItem) has successfully uploaded',
                                                    {dataObject:resourceID});
                        }else{
                            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Error,'Data Synchronisation','Upload Data',
                                                    '{dataObject} (dataItem) upload failed. Blob response:{apiResponse}',
                                                    {dataObject:resourceID,apiResponse:uploadBlobResponse});
                        }
                
                    
                }catch(error) {
                    SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data'
                                    ,'{dataObject} (dataItem) failed to be uploaded through realtime API.'
                                    ,{dataObject:resourceID,error:error} );
                    //deleting local for 404
                    try {
                        if(error.message && typeof error.message == 'string'  && error.message.includes("status code 404")){
                            await localDB.deleteDataItemRow(resourceID);
                            const localDataItems=await localDB.getDataItemsForCluster(clusterResourceID);
                            if(!localDataItems || !localDataItems.length){
                                await localDB.deleteDataClusterRow(clusterResourceID);
                            }
                        }
                    } catch (err) {
                    }

                }
                
            }
        }
        
    }else{
        contentToBe='';
        valueToBe=value;
        
    }
    SkbLogger.logDebugInfo('type contentToBe valueToBe',type,contentToBe,valueToBe);
    const thedata={ resourceID:resourceID,
                    clusterResourceID:clusterResourceID,
                    type:type||'METADATA',
                    key:key,
                    arrayIndex:arrayIndex,
                    value:valueToBe,
                    description:description,
                    localArtefactContent: contentToBe,
                    timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
                    version:existingVersion,
                    operation:operation
                    };
    await localDB.writeDataItemAsync(thedata);

    //mark change (U) in dataCluster
    var theCluster = await localDB.getDataClusterByResourceID(clusterResourceID);
    if(theCluster && toMarkDataCluster){
        await saveDataCluster(  clusterResourceID,
            theCluster.name,
            theCluster.referenceEntity,
            theCluster.referenceID,
            theCluster.scope,
            toTrytoSaveToServer);
    }else{
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Upload Data',
                                        '{dataObject} (dataItem) has no need to update the linked dataCluster.'
                                        ,{dataObject:thedata });
    }
    
    
    //to server-side
    if(toTrytoSaveToServer && (auth0UserID.startsWith('dummy') || navigator.onLine)){
        //item
        try {
            const resp=await syncAPI.saveDataItem(auth0UserID,idToken,apiKey,thedata.clusterResourceID,thedata.resourceID,thedata);
            SkbLogger.logDebugInfo("saveDataItem resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299){ // && resp.data){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Upload Data',
                                        '{dataObject} (dataItem) has been uploaded through realtime API.'
                                        ,{dataObject:resp.data});
                /*
                let itemToSave=resp.data;
                itemToSave.operation='S'; //S for partial/realtime sync
                if(itemToSave.type && itemToSave.type=='PHOTO'){
                    //be careful about localArtefactContent in PHOTO
                    if(thedata && thedata.localArtefactContent){
                        //itemToSave.value is the path from server (jitterbit server may not update blank value)
                        if(!itemToSave.value || itemToSave.value=='' || itemToSave.value=='AWAIT_UPLOADING' || thedata.value==itemToSave.value ){
                            //same path, do not overwrite localArtefactContent
                            console.log('keep localArtefactContent for the same path/value from server.',itemToSave.value);
                            itemToSave.localArtefactContent=thedata.localArtefactContent;
                        }else if(!thedata.value || thedata.value=='' || thedata.value=='AWAIT_UPLOADING' ){
                            //same path, do not overwrite localArtefactContent
                            console.log('keep localArtefactContent and local path for blank local path/value.',thedata.value);
                            itemToSave.localArtefactContent=thedata.localArtefactContent;
                            itemToSave.value=thedata.value;
                        }else{
                            console.log('clear localArtefactContent for different path/value from server.',thedata.value,itemToSave.value,);
                            itemToSave.localArtefactContent='';
                        }
                    }else{
                        console.log('no need to deal with localArtefactContent',thedata);
                    }
                }
                await localDB.writeDataItemAsync(itemToSave);
                */
                //only update operation, not version or value
                thedata.operation='S';
                await localDB.writeDataItemAsync(thedata);
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Upload Data'
                            ,'{dataObject} (dataItem) failed to be uploaded through realtime API.'
                            ,{dataObject:thedata,error:error} );
            //deleting local for 404
            try {
                if(error.message && typeof error.message == 'string'  && error.message.includes("status code 404")){
                    await localDB.deleteDataItemRow(resourceID);
                    const localDataItems=await localDB.getDataItemsForCluster(clusterResourceID);
                    if(!localDataItems || !localDataItems.length){
                        await localDB.deleteDataClusterRow(clusterResourceID);
                    }
                }
            } catch (err) {
            }
        }
        
    }
    
}


/**
 * 
 * @param {*} resourceID new or existing uuid 
 * @param {*} clusterResourceID which cluster the DataItem belongs to
 * @param {*} key the key name of dataitem key/value pair, to be unique within cluster.
 * @param {*} value the value of dataitem key/value pair
 * @param {*} type "METADATA" or "PHOTO". default "METADATA"
 * @param {*} description dataitem display text if required
 * @param {*} toMarkDataCluster set it false only if you want to update dataCluster.timestamp later or have done it already
 * @param {*} toTrytoSaveToServer set it false only if you want to push it to server later
 */
export async function saveDataItem(resourceID, clusterResourceID, key, value, type='METADATA', description='', toMarkDataCluster=true, toTrytoSaveToServer=true){
    await saveDataItemWithIndex(resourceID, clusterResourceID, key, value, type,'0.0', description, toMarkDataCluster, toTrytoSaveToServer);
    
}

/**
 * 
 * @param {*} clusterResourceID uuid of the cluster which dataItem belongs to
 * @returns array of DataItems. (AFTER LOADING DATA FROM SERVER SIDE)
 * sample return:
 *  [
 *       {
 *           resourceID: 'b36355d1441a14c15e806fb31d83',
 *           clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *           key: 'ItemKeyName1',
 *           arrayIndex: '0.0',
 *           description: 'the description 1',
 *           value: 'the value 1',
 *           timestamp: '2020-08-21T03:52:15.621Z',
 *           version:0,
 *           operation:'I'
 *       },
 *       {
 *           resourceID: '1a14c15e806fb31d83b36355d144',
 *           clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *           key: 'ItemKeyName2',
 *           arrayIndex: '0.0',
 *           description: 'the description',
 *           value: 'the value 2',
 *           timestamp: '2020-08-21T03:52:15.621Z',
 *           version:0,
 *           operation:'I'
 *       }
 *   ]
 */
export async function queryDataItemsForCluster(clusterResourceID, forceRefreshFromServer=false){
    initLocalDBIfNotYet();

    const curretTimestamp=new Date().getTime();
    const lastTimestamp = localStorage.getItem('last-queryDataItemsForCluster-'+clusterResourceID+'-time');
       
    //on offline mode, load data from local storage regardles forceRefreshFromServer
    if((forceRefreshFromServer==false && lastTimestamp && lastTimestamp>curretTimestamp-2*60*1000) || (!navigator.onLine && !auth0UserID.startsWith('dummy') )){ // 2 minutes
        //return local data only
        var ret = await localDB.getDataItemsForCluster(clusterResourceID);
        return removeDataWithOperationD(ret);
    }
    else{
        //check with server when there are no local data
        //assuming UI has always called queryDataClustersForEntity before this
        //so, no need to check service side to refresh dataCluster again
        //check server side for refreshing data items
        //if(auth0UserID.startsWith('dummy') || navigator.onLine){
            try {
                //SkbLogger.logDebugInfo("queryDataItemsForCluster to-server started");
                const resp=await syncAPI.getDataItemsInCluster(auth0UserID,idToken,apiKey,clusterResourceID);
                SkbLogger.logDebugInfo("getDataItemsInCluster resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){
                    localStorage.setItem('last-queryDataItemsForCluster-'+clusterResourceID+'-time',(new Date().getTime()));
                    const itemArray=resp.data;
                    //SkbLogger.logDebugInfo("queryDataItemsForCluster loop started");
                    var allItems=[];
                    for (let i = 0; i < itemArray.length; i++) {
                        let oneItem = itemArray[i];
                        //SkbLogger.logDebugInfo("checking oneItem",i,oneItem.resourceID);
                        const existingItem=await localDB.getDataItemByResourceID(oneItem.resourceID);
                        //console.log('existingItem',existingItem.resourceID,existingItem);
                        if(hasLocalChange(existingItem)){
                            //it has local change, not to overwrite
                            SkbLogger.logDebugInfo("not to overwrite local changes");
                        }else{
                            SkbLogger.applicationTraceSub('Data Sync',0,'Data Synchronisation','Download Data',
                                                '{dataObject} (dataItem) has been downloaded through realtime API.'
                                                ,{dataObject:oneItem});
                            oneItem.operation='S'; //S for partially/realtime sync
                            //TODO: do not change blank operation to be 'S' if value/version not changed 
                            if(existingItem && existingItem.operation && existingItem.operation=='' 
                                && existingItem.version && oneItem.version && oneItem.version==existingItem.version
                                && existingItem.value && oneItem.value && oneItem.value==existingItem.value){
                                    oneItem.operation='';
                                }
                            //even if it's photo, do not use value as localArtefactContent as this is from server
                            //also, we do not overwrite (we need to keep) localArtefactContent
                            
                            if(existingItem && oneItem.type && oneItem.type=='PHOTO'){
                                //be careful about localArtefactContent in PHOTO
                                if(existingItem && existingItem.localArtefactContent){
                                    //oneItem.value is the path from server (jitterbit server may not update blank value)
                                    if(!oneItem.value || oneItem.value=='' || oneItem.value=='AWAIT_UPLOADING' || oneItem.value==existingItem.value ){
                                        //same path, do not overwrite localArtefactContent
                                        //console.log('keep localArtefactContent for the same path/value from server.',oneItem.resourceID);
                                        oneItem.localArtefactContent=existingItem.localArtefactContent;
                                    }else{
                                        //console.log('overwrite localArtefactContent due to path',oneItem.resourceID, oneItem.value, existingItem.value );
                                    }
                                }else{
                                    //console.log('overwrite localArtefactContent as no existing item',oneItem.resourceID);
                                }
                            }

                            //await localDB.writeDataItemAsync(oneItem);
                            allItems.push(oneItem);
                        }
                    }
                    //SkbLogger.logDebugInfo("queryDataItemsForCluster loop ended");
                    await localDB.writeMultiDataItemsAsync(allItems);
                }
                //read from local again
                ret = await localDB.getDataItemsForCluster(clusterResourceID);
                //SkbLogger.logDebugInfo("queryDataItemsForCluster removing unwanted");
                return removeDataWithOperationD(ret);
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data'
                                ,'{dataObject} (dataItem) failed to be downloaded through realtime API.'
                                ,{dataObject:{clusterResourceID:clusterResourceID},error:error} );
            }
        //}
        return null; //no data in local, or server (or no network or network error)
    }
}

/**
 * queryDataItemsByKeysForCluster query data items for cluster by keys
 * @param {*} clusterResourceID:  cluster resource ID
 * @param {*} keys: multiple keys need to be separate by comma (",")
 * @param {*} forceRefreshFromServer, if true, try load from server when it's online, otherwise from local indexed db, default is false
 */
export async function queryDataItemsByKeysForCluster(clusterResourceID, keys, forceRefreshFromServer=false){
    //SkbLogger.logDebugInfo("queryDataItemsForCluster started");
    initLocalDBIfNotYet();
    //check local db
    //SkbLogger.logDebugInfo("queryDataItemsForCluster localDB started");
    var ret = await localDB.getDataItemsByKeysForCluster(clusterResourceID, keys, false);
    //SkbLogger.logDebugInfo("queryDataItemsForCluster removing unwanted");

    //get max timestamp
    // var maxTimestamp;
    // const curretTimestamp=new Date().getTime();
    // var olderTimestampObj = new Date();
    // olderTimestampObj.setTime(curretTimestamp-30*60*1000);  // 30 minutes
    // var oldTimestamp=''+olderTimestampObj.toISOString().replace('T',' ').replace('Z','');
    // SkbLogger.logDebugInfo('Threshold Timestamp for realtime refreshing',oldTimestamp);
    // if(ret && Array.isArray(ret) && ret.length>0){
    //     for (let index = 0; index < ret.length; index++) {
    //         const oneItem = ret[index];
    //         if(!maxTimestamp) maxTimestamp=oneItem.timestamp
    //         else if(oneItem.timestamp>maxTimestamp) maxTimestamp=oneItem.timestamp;
    //     }
    //     SkbLogger.logDebugInfo('comparing dataItem timestamp for realtime refreshing',maxTimestamp,oldTimestamp);
    // }
    // SkbLogger.logDebugInfo('maxTimestamp of dataItems for realtime refreshing',maxTimestamp);
    //on offline mode, load data from local storage regardles forceRefreshFromServer
    if( !navigator.onLine || (forceRefreshFromServer==false && ret && Array.isArray(ret) && ret.length>0 //&& maxTimestamp && maxTimestamp>oldTimestamp
    )){
        //return local data only
        return removeDataWithOperationD(ret);
    }
    else{
        //check with server when there are no local data
        //assuming UI has always called queryDataClustersForEntity before this
        //so, no need to check service side to refresh dataCluster again
        //check server side for refreshing data items
        if(auth0UserID.startsWith('dummy') || navigator.onLine){
            try {
                //SkbLogger.logDebugInfo("queryDataItemsForCluster to-server started");
                const resp=await syncAPI.getDataItemsInClusterByKeys(auth0UserID,idToken,apiKey,clusterResourceID, keys);
                SkbLogger.logDebugInfo("getDataItemsInClusterByKeys resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){
                    const itemArray=resp.data;
                    //SkbLogger.logDebugInfo("queryDataItemsForCluster loop started");
                    var allItems=[];
                    for (let i = 0; i < itemArray.length; i++) {
                        let oneItem = itemArray[i];
                        //SkbLogger.logDebugInfo("checking oneItem",i,oneItem.resourceID);
                        const existingItem=await localDB.getDataItemByResourceID(oneItem.resourceID);
                        //console.log('existingItem',existingItem.resourceID,existingItem);
                        if(hasLocalChange(existingItem)){
                            //it has local change, not to overwrite
                            SkbLogger.logDebugInfo("not to overwrite local changes");
                        }else{
                            SkbLogger.applicationTraceSub('Data Sync',0,'Data Synchronisation','Download Data',
                                                '{dataObject} (dataItem) has been downloaded through realtime API.'
                                                ,{dataObject:oneItem});
                            oneItem.operation='S'; //S for partially/realtime sync
                            //TODO: do not change blank operation to be 'S' if value/version not changed
                            if(existingItem && existingItem.operation && existingItem.operation=='' 
                                && existingItem.version && oneItem.version && oneItem.version==existingItem.version
                                && existingItem.value && oneItem.value && oneItem.value==existingItem.value){
                                    oneItem.operation='';
                                }

                            //even if it's photo, do not use value as localArtefactContent as this is from server
                            //also, we do not overwrite (we need to keep) localArtefactContent
                            
                            if(existingItem && oneItem.type && oneItem.type=='PHOTO'){
                                //be careful about localArtefactContent in PHOTO
                                if(existingItem && existingItem.localArtefactContent){
                                    //oneItem.value is the path from server (jitterbit server may not update blank value)
                                    if(!oneItem.value || oneItem.value=='' || oneItem.value=='AWAIT_UPLOADING' || oneItem.value==existingItem.value ){
                                        //same path, do not overwrite localArtefactContent
                                        //console.log('keep localArtefactContent for the same path/value from server.',oneItem.resourceID);
                                        oneItem.localArtefactContent=existingItem.localArtefactContent;
                                    }else{
                                        //console.log('overwrite localArtefactContent due to path',oneItem.resourceID, oneItem.value, existingItem.value );
                                    }
                                }else{
                                    //console.log('overwrite localArtefactContent as no existing item',oneItem.resourceID);
                                }
                            }

                            //await localDB.writeDataItemAsync(oneItem);
                            allItems.push(oneItem);
                        }
                    }
                    //SkbLogger.logDebugInfo("queryDataItemsForCluster loop ended");
                    await localDB.writeMultiDataItemsAsync(allItems);
                }
                //read from local again
                ret = await localDB.getDataItemsByKeysForCluster(clusterResourceID, keys, false);
                //SkbLogger.logDebugInfo("queryDataItemsForCluster removing unwanted");
                    return removeDataWithOperationD(ret);
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data'
                                ,'{dataObject} (dataItem) failed to be downloaded through realtime API.'
                                ,{dataObject:{clusterResourceID:clusterResourceID},error:error} );
            }
        }
        return null; //no data in local, or server (or no network or network error)
    }
}

/**
 * 
 * @param {*} key dataItem key
 * @param {*} clusterResourceID uuid of the cluster which dataItem belongs to
 * @returns array of dataItems (ONLY FROM LOCAL DB)
 * sample return:
 *  [
 *       {
 *           resourceID: 'b36355d1441a14c15e806fb31d83',
 *           clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *           key: 'ItemKeyName1',
 *           arrayIndex: '0.0',
 *           description: 'the description 1',
 *           value: 'the value 1',
 *           timestamp: '2020-08-21T03:52:15.621Z',
 *           version:0,
 *           operation:'I'
 *       }
 *   ]
 */
export async function queryDataItemsByKey(key, clusterResourceID){
    initLocalDBIfNotYet();
    var ret = await localDB.getDataItemByKey(key, clusterResourceID);
    return removeDataWithOperationD(ret);
}

/**
 * @param {*} arrayIndex multi-dimention index for grouping
 * @param {*} key dataItem key
 * @param {*} clusterResourceID uuid of the cluster which dataItem belongs to
 * @returns array of dataItems (ONLY FROM LOCAL DB)
 * sample return:
 *  [
 *       {
 *           resourceID: 'b36355d1441a14c15e806fb31d83',
 *           clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *           key: 'ItemKeyName1',
 *           arrayIndex: '0.0',
 *           description: 'the description 1',
 *           value: 'the value 1',
 *           timestamp: '2020-08-21T03:52:15.621Z',
 *           version:0,
 *           operation:'I'
 *       }
 *   ]
 */
export async function queryDataItemsByKeyAndIndex(arrayIndex, key, clusterResourceID){
    initLocalDBIfNotYet();
    var rawResult = await localDB.getDataItemByKey(key, clusterResourceID);
    if(!rawResult) return null;
    var retArray=[];
    for (let i = 0; i < rawResult.length; i++) {
        const oneData = rawResult[i];
        if(oneData.arrayIndex==arrayIndex) {
            retArray.push(oneData);
        }
    }
    return removeDataWithOperationD(retArray);
}

/**
 * 
 * @param {*} resourceID the uuid used to create the DataItem
 * @returns object of DataItem (ONLY FROM LOCAL DB)
 * 
 * {
 *     resourceID: '1a14c15e806fb31d83b36355d144',
 *     clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *     key: 'ItemKeyName',
 *     arrayIndex: '0.0',
 *     description: 'the description',
 *     value: 'the value',
 *     timestamp: '2020-08-21T03:52:15.621Z',
 *     version: 0,
 *     operation: 'I'
 * }
 */
export async function getDataItem(resourceID){
    initLocalDBIfNotYet();
    const ret = await localDB.getDataItemByResourceID(resourceID);
    if(ret && (ret.operation=='D' || ret.type=='CONFLICT' || ret.key=='DELETED')) return null;
    return ret;
}

/**
 * 
 * @param {*} clusterResourceID uuid of the cluster which the whole dataItem group belongs to
 * @param {*} key for the whole dataItem group, to be unique within cluster for the group (not for the dataItem).
 * @param {*} listOfPartialItems 
 *      an array. each element contains resourceID (new or existing), value, description (default '') and type (default 'METADATA').
 *      it supports up to 2 levels of pure array. 
 *      but it will not support the mixture of arrays and objects.
 * sample 
 *      [
 *           {resourceID:theGroupItemUuid1,value:'group item 1',description:'item 1',type='METADATA'},
 *           [  {resourceID:theGroupItemUuid21,value:'group item 2',description:'item 2',type='METADATA'}, 
 *              {resourceID:theGroupItemUuid22,value:'photo-base64-string',description:'item 2 photo',type='PHOTO'}
 *           ],
 *           {resourceID:theGroupItemUuid3,value:'group item 3',description:'item 3',type='METADATA'},
 *       ]
 */
export async function saveDataItemGroup(clusterResourceID, key, listOfPartialItems, toMarkDataCluster=true){
    initLocalDBIfNotYet();

    //decimal array index to allow inserting item between two existing items (as a future feature).
    //like inserting index 1.5 between existing index 1.0 and index 2.0, without updating everything from index 2.0
    //but currently, this has not been implemented.
    //arrayIndex is like a vector to support multiple dimensions, sparated by comma ,
    //i call them X,Y,Z...
    //now, it only supports up to two dimensions
    var floatArrayIndexX = 1.0; 
    var floatArrayIndexY = 1.0; 
    var itemsToServer = [];
    //SkbLogger.logDebugInfo('adding group ...',listOfPartialItems);

    //somehow, i couldn't use the forEach to replace the traditional for loop, like below
    //await listOfPartialItems.forEach(async (oneItem) => {})
    //most likely it's because there is "await" inside
    for (let x = 0; x < listOfPartialItems.length; x++) {
        SkbLogger.logDebugInfo('oneItemX ',x);
        const oneItemX = listOfPartialItems[x];
        if(Array.isArray(oneItemX)){
            SkbLogger.logDebugInfo('oneItemX is array',x);
            floatArrayIndexY=1.0;
            for (let y=0; y<oneItemX.length;y++){
                SkbLogger.logDebugInfo('oneItemY',y);
                const oneItemY = oneItemX[y];

                let operation='I';
                let existingVersion=0;
                let existingItem = await localDB.getDataItemByResourceID(oneItemY.resourceID);
                if(existingItem) {
                    operation='U';
                    existingVersion=existingItem.version;
                }
                else {
                    operation='I';
                }
                var valueToBeY;
                var contentToBeY;
                if(oneItemY.type && oneItemY.type=='PHOTO'){
                    contentToBeY=oneItemY.value;
                    valueToBeY='';
                }else{
                    contentToBeY='';
                    valueToBeY=oneItemY.value;
                }
                let thedataY={ resourceID:oneItemY.resourceID,
                            clusterResourceID:clusterResourceID,
                            type:oneItemY.type||'METADATA',
                            key:key,
                            arrayIndex:''+floatArrayIndexX+','+floatArrayIndexY,
                            value:valueToBeY,
                            description:oneItemY.description||'',
                            localArtefactContent: contentToBeY,
                            timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
                            version:existingVersion,
                            operation:operation
                            };
                //SkbLogger.logDebugInfo('adding dataItem index',floatArrayIndex);
                SkbLogger.logDebugInfo('adding dataItem content',thedataY);
                await localDB.writeDataItemAsync(thedataY);  //it seems the await here does not really "wait" in array.forEach()
                SkbLogger.logDebugInfo('added dataItem',floatArrayIndexX,floatArrayIndexY);
                itemsToServer.push(thedataY);
                floatArrayIndexY=floatArrayIndexY+1;
            }
        }else{
            SkbLogger.logDebugInfo('oneItemX is NOT array',x);
            let operation='I';
            let existingVersion=0;
            let existingItem = await localDB.getDataItemByResourceID(oneItemX.resourceID);
            if(existingItem) {
                operation='U';
                existingVersion=existingItem.version;
            }
            else {
                operation='I';
            }
            var valueToBeX;
            var contentToBeX;
            if(oneItemX.type && oneItemX.type=='PHOTO'){
                    contentToBeX=oneItemX.value;
                    valueToBeX='AWAIT_UPLOADING';
            }else{
                    contentToBeX='';
                    valueToBeX=oneItemX.value;
            }
            let thedataX={ resourceID:oneItemX.resourceID,
                        clusterResourceID:clusterResourceID,
                        type:oneItemX.type||'METADATA',
                        key:key,
                        arrayIndex:''+floatArrayIndexX,
                        value:valueToBeX,
                        description:oneItemX.description||'',
                        localArtefactContent: contentToBeX,
                        timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
                        version:existingVersion,
                        operation:operation
                        };
            //SkbLogger.logDebugInfo('adding dataItem index',floatArrayIndex);
            SkbLogger.logDebugInfo('adding dataItem content',thedataX);
            await localDB.writeDataItemAsync(thedataX);  //it seems the await here does not really "wait" in array.forEach()
            itemsToServer.push(thedataX);
            SkbLogger.logDebugInfo('added dataItem',floatArrayIndexX);
        }
        
        floatArrayIndexX=floatArrayIndexX+1.0;
    }

    //mark change (U) in dataCluster
    var theCluster = await localDB.getDataClusterByResourceID(clusterResourceID);
    if(theCluster && toMarkDataCluster){
        await saveDataCluster(  clusterResourceID,
            theCluster.name,
            theCluster.referenceEntity,
            theCluster.referenceID,
            theCluster.scope);
    }


    //to server-side
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        for (let i = 0; i < itemsToServer.length; i++) {
            const thedata = itemsToServer[i];
            try {
                const resp=await syncAPI.saveDataItem(auth0UserID,idToken,apiKey,thedata.clusterResourceID,thedata.resourceID,thedata);
                SkbLogger.logDebugInfo("saveDataItem resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){ //&& resp.data){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Call API','{dataObject} (dataItem) has been saved through realtime API.',{dataObject:resp.data});
                    //let itemToSave=resp.data;
                    //only update operation, not version or value
                    thedata.operation='S'; //S for partial/realtime sync
                    await localDB.writeDataItemAsync(thedata);
                }
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Call API','{dataObject} (dataItem) failed to be saved through realtime API.',{dataObject:itemsToServer ,error:error});
            }
            
        }
    }
    

}

/**
 * 
 * @param {*} clusterResourceID uuid of the cluster which the whole dataItem group belongs to
 * @param {*} key all dataItems shared the same key
 * @returns array of the dataItems in the group (ONLY FROM LOCAL DB)
 * 
 * [
 *       {
 *           resourceID: 'b36355d1441a14c15e806fb31d83',
 *           clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *           key: 'TheGroupKey',
 *           arrayIndex: '1.0',
 *           description: 'the description 1',
 *           value: 'the value 1',
 *           timestamp: '2020-08-21T03:52:15.621Z',
 *           version: 0,
 *           operation: 'I'
 *       },
 *       [
 *           {
 *               resourceID: '6355d1441a14c15e806fb31d83b3',
 *               clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *               key: 'TheGroupKey',
 *               arrayIndex: '2.0,1.0',
 *               description: 'the description 2.1',
 *               value: 'the value 2.1',
 *               timestamp: '2020-08-21T03:52:15.621Z',
 *               version: 0,
 *               operation: 'I'
 *           },
 *           {
 *               resourceID: 'b36355d1441a14c15e806fb31d83',
 *               clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *               key: 'TheGroupKey',
 *               arrayIndex: '2.0,2.0',
 *               description: 'the description 2.2',
 *               value: 'the value 2.2',
 *               timestamp: '2020-08-21T03:52:15.621Z',
 *               version: 0,
 *               operation: 'I'
 *           }
 *      ],
 *      {
 *           resourceID: '55d1441a14c15e806fb31d83b363',
 *           clusterResourceID: '585465ca31063ce52563ed8c6786f77',
 *           key: 'TheGroupKey',
 *           arrayIndex: '3.0',
 *           description: 'the description 3',
 *           value: 'the value 3',
 *           timestamp: '2020-08-21T03:52:15.621Z',
 *           version: 0,
 *           operation: 'I'
 *       }
 *   ]
 * 
 * 
 */
export async function getDataItemGroup(clusterResourceID, key){
    initLocalDBIfNotYet();
    var dataitems= await localDB.getDataItemByKey(key, clusterResourceID, 1); //1 means sortingRequired
    var flatList = removeDataWithOperationD(dataitems);
    var ret1stD = [];
    var ret2ndD = [];
    var current2ndDIndex=-1.0;
    //to support 2D groups with index X,Y, it just needs to merge items with the same X into one array
    if(flatList && Array.isArray(flatList)){
        for (let i=0; i<flatList.length; i++){
            SkbLogger.logDebugInfo('item i',i);
            const oneItem=flatList[i];

            const indexVector=oneItem.arrayIndex.split(',');
            const x=parseFloat(indexVector[0]);

            if(x>current2ndDIndex){
                //add the previously-constructed 2nd Dimension data (if any) into 1st
                if(ret2ndD.length>0) {
                    SkbLogger.logDebugInfo('push previous 2ndD into 1stD',current2ndDIndex);
                    ret1stD.push(ret2ndD);
                }
                //start constructing the new 2nd dimension data
                ret2ndD=[];
                current2ndDIndex=x;
                SkbLogger.logDebugInfo('new 2ndD started',i,x,current2ndDIndex);
            }

            if(indexVector.length>1){
                //2nd dimension
                SkbLogger.logDebugInfo('item i is 2nd D',i);
                const y=parseFloat(indexVector[1]);  //assuming y is already sorted in order by getDataItemByKey 
                SkbLogger.logDebugInfo('push item i into 2ndD',current2ndDIndex);
                ret2ndD.push(oneItem);
            }else{
                //1st dimension
                SkbLogger.logDebugInfo('item i is 1st D',i);
                ret1stD.push(oneItem);
            }
        }
    }
    return ret1stD;
}




/**
 * 
 * @param {*} clusterResourceID 
 * @param {*} key 
 */
export async function fakeDeleteDataItemGroup(clusterResourceID, key){
    var dataitems= await localDB.getDataItemByKey(key, clusterResourceID, 0); //0 means sorting not required
    var actualDataitems = removeDataWithOperationD(dataitems);
    for (let i = 0; i < actualDataitems.length; i++) {
        SkbLogger.logDebugInfo('oneItem ',i);
        let oneItem = actualDataitems[i];
        oneItem.operation='U';
        oneItem.key='DELETED';
        SkbLogger.logDebugInfo('marking dataItem as deleted',oneItem);
        await localDB.writeDataItemAsync(oneItem);  //it seems the await here does not really "wait" in array.forEach()
    }
    //to server-side
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        for (let i = 0; i < actualDataitems.length; i++) {
            const thedata = actualDataitems[i];
            try {
                const resp=await syncAPI.saveDataItem(auth0UserID,idToken,apiKey,thedata.clusterResourceID,thedata.resourceID, thedata);
                SkbLogger.logDebugInfo("deleteDataItem resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Call API','{dataObject} (dataItem) has been deleted successfully through realtime API.',{dataObject:resp});
                    thedata.operation='S';
                    await localDB.writeDataItemAsync(thedata);
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Delete Data','{dataObject} (dataItem) has been deleted successfully in local DB.',{dataObject:thedata});
                }
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Call API','{dataObject} failed to be deleted.',{dataObject:actualDataitems,error:error});
            }
            
        }
    }
    
    
}


/**
 * 
 * @param {*} clusterResourceID 
 * @param {*} key 
 */
export async function deleteDataItemGroup(clusterResourceID, key){
    var dataitems= await localDB.getDataItemByKey(key, clusterResourceID, 0); //0 means sorting not required
    var actualDataitems = removeDataWithOperationD(dataitems);
    for (let i = 0; i < actualDataitems.length; i++) {
        SkbLogger.logDebugInfo('oneItem ',i);
        let oneItem = actualDataitems[i];
        oneItem.operation='D';
        SkbLogger.logDebugInfo('marking dataItem as deletedd',oneItem);
        await localDB.writeDataItemAsync(oneItem);  //it seems the await here does not really "wait" in array.forEach()
    }
    //to server-side
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        for (let i = 0; i < actualDataitems.length; i++) {
            const thedata = actualDataitems[i];
            try {
                const resp=await syncAPI.deleteDataItem(auth0UserID,idToken,apiKey,thedata.clusterResourceID,thedata.resourceID);
                SkbLogger.logDebugInfo("deleteDataItem resp",resp);
                if (resp && resp.status && resp.status>=200 && resp.status<=299){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Call API','{dataObject} (dataItem) has been deleted successfully through realtime API.',{dataObject:resp});
                    await localDB.deleteDataItemRow(thedata.resourceID);
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Delete Data','{dataObject} (dataItem) has been deleted successfully in local DB.',{dataObject:thedata});
                }
            } catch (error) {
                SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Call API','{dataObject} failed to be deleted.',{dataObject:actualDataitems,error:error});
            }
            
        }
    }
    
    
}

/**
 * 
 * @param {*} resourceID 
 */
export async function fakeDeleteDataItem(resourceID){
    //use key = 'DELETED' 

    initLocalDBIfNotYet();
    const existingItem = await localDB.getDataItemByResourceID(resourceID);
    if(!existingItem) return;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Save and Track',
                'marking dataItem as deleted',existingItem);
    await saveDataItemWithIndex(resourceID, existingItem.clusterResourceID, 
                                    'DELETED', existingItem.value, existingItem.type,
                                    existingItem.arrayIndex, 
                                    existingItem.description, true);
    
}

/**
 * 
 * @param {*} resourceID 
 */
export async function deleteDataItem(resourceID){
    initLocalDBIfNotYet();
    const existingItem = await localDB.getDataItemByResourceID(resourceID);
    if(!existingItem) return;
    const thedata={ resourceID:resourceID,
                    clusterResourceID:existingItem.clusterResourceID,
                    type:existingItem.type,
                    key:existingItem.key,
                    arrayIndex:existingItem.arrayIndex,
                    value:existingItem.value||'',
                    timestamp:''+(new Date()).toISOString().replace('T',' ').replace('Z',''),
                    version: existingItem.version || 0,
                    operation:'D'
                    };
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Save and Track',
                'marking dataItem as deleted',thedata);
    await localDB.writeDataItemAsync(thedata);
    //to server-side for dataItems
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        try {
            const resp=await syncAPI.deleteDataItem(auth0UserID,idToken,apiKey,existingItem.clusterResourceID,existingItem.resourceID);
            SkbLogger.logDebugInfo("deleteDataItem resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299 ){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation','Call API','{dataObject} (dataItem) has been deleted successfully through realtime API.',{dataObject:resp});
                await localDB.deleteDataItemRow(existingItem.resourceID);
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Information,'Data Synchronisation','Delete Data','{dataObject} (dataItem) has been deleted successfully in local DB',{dataObject:existingItem});
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Call API','{dataObject} (dataItem) failed to be deleted',{dataObject:thedata,error:error});
        }
    }
    
}


/**
 * 
 * @param {*} resourceID 
 */
export async function deleteLocalPhoto(resourceID){
    initLocalDBIfNotYet();
    await localDB.deleteLocalPhotoRow(resourceID);
}



export async function queryOneDataCluster(resourceID){
    initLocalDBIfNotYet();
    //check localDB
    var ret;
    
    if(auth0UserID.startsWith('dummy') || navigator.onLine){
        try {
            const resp=await syncAPI.getOneDataCluster(auth0UserID,idToken,apiKey,resourceID);
            SkbLogger.logDebugInfo("getOneDataCluster resp",resp);
            if (resp && resp.status && resp.status>=200 && resp.status<=299){
                const clusterObj=resp.data;
                const existingCluster=await localDB.getDataClusterByResourceID(clusterObj.resourceID);
                if(hasLocalChange(existingCluster)){
                    //cluster has local change, not to overwrite
                    SkbLogger.logDebugInfo("not to overwrite local changes");
                }else{
                    SkbLogger.applicationTraceSub('Data Sync',0,'Data Synchronisation','Download Data',
                                        '{dataObject} (one dataCluster) has been downloaded through realtime API.'
                                        ,{dataObject:clusterObj});
                    clusterObj.operation='S'; //S for partially/realtime sync
                    await localDB.writeDataClusterAsync(clusterObj);
                }
                ret = await localDB.getDataClusterByResourceID(resourceID);
                return ret;
            }
        } catch (error) {
            SkbLogger.applicationExceptionSub('Data Sync','Data Synchronisation','Download Data'
                            ,'{dataObject} (one dataCluster) failed to be downloaded through realtime API.'
                            ,{dataObject:{resourceID:resourceID},error:error} );
        }
    }
    ret = await localDB.getDataClusterByResourceID(resourceID);
    return ret;
    
}




////////// internal helper functions for easy-to-use functions ////////

//refer to photo-sync.js
function makeCRCTableForPhotoSync(){
    var c;
    for(var n =0; n < 256; n++){
        c = n;
        for(var k =0; k < 8; k++){
            c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTableForPhotoSync[n] = c;
    }
    return crcTableForPhotoSync;
}

//refer to photo-sync.js
function crc32ForPhotoSync(str) {
    var crcTableForPhotoSync = crcTableForPhotoSync || (crcTableForPhotoSync = makeCRCTableForPhotoSync());
    var crc = 0 ^ (-1);

    for (var i = 0; i < str.length; i++ ) {
        crc = (crc >>> 8) ^ crcTableForPhotoSync[(crc ^ str.charCodeAt(i)) & 0xFF];
    }

    return (crc ^ (-1)) >>> 0;
};

//refer to photo-sync.js
export function photoContentCRCMatchesFileName(pathIncludingFileName, photoContent){
    if(!pathIncludingFileName || !photoContent){
        return false;
    }
    const fileName=crc32ForPhotoSync(photoContent);
    if(pathIncludingFileName.includes(`/${fileName}.`)){
        return true;
    }else{
        return false;
    }
}

export function sameDataItemValuesIgnoringPHOTOProp(strValue1, strValue2){
    if(strValue1===strValue2) return true;
    if(typeof strValue1 !== 'string' || typeof strValue2 !== 'string') return false;
    try {
        var objValue1=JSON.parse(strValue1);
        var objValue2=JSON.parse(strValue2);
        objValue1._PHOTO=null;
        objValue2._PHOTO=null;
        const jsonValue1WithoutPHOTO = JSON.stringify(objValue1);
        const jsonValue2WithoutPHOTO = JSON.stringify(objValue2);
        if(jsonValue1WithoutPHOTO===jsonValue2WithoutPHOTO){
            return true;
        }
    } catch (error) {
        
    }
    return false;
}

export function generateNextIndex(dimension, currentMax){
    var next = parseFloat(currentMax) + 1;
    var ret = ""+next;
    for (let index = 1; index < dimension; index++) {
        ret = ret + ',0';
    }
    return ret;

}

/////////////////////////////////////////////////// easy to use functions ////////////////////////////////////////////////

//////////// DataItem Level //////////////


/**
 * THIS IS AN ADVANCED OR HALF-PRIVATE FUNCTION. Please do NOT use it unless you understand the data structure clearly.
 * 
 * This function replaces dataItem.value._PHOTO (if exists, assuming it's base64 content or blob path) with PHOTO type item's resourceID.
 * This function also saves (adds/updates) PHOTO type DataItem when necessary (without updating DataCluster)
 * The PHOTO type dataitem is supposed to be with the same arrayIndex and under the same dataCluster. It's key = dataItem.key + '_PHOTO'.
 * 
 * 
 * @param {*} resourceID 
 * @param {*} clusterResourceID 
 * @param {*} itemKey 
 * @param {*} arrayIndex 
 * @param {*} dataItemValueToBe potentially containning "_PHOTO"
 * @returns new dataItemValueToBe
 */
export async function easyReplacePhotoInItemWhenSaving(resourceID, clusterResourceID,itemKey,arrayIndex,dataItemValueToBe){
    if( !dataItemValueToBe || typeof dataItemValueToBe !== 'string' || !dataItemValueToBe.includes('_PHOTO')){
        //no valid _PHOTO
        return dataItemValueToBe;
    }else{
        var itemValueObj=null;
        try {
            itemValueObj=JSON.parse(dataItemValueToBe);
        } catch (error) {   }
        if(!itemValueObj || !itemValueObj._PHOTO || typeof itemValueObj._PHOTO !== 'string' 
            || itemValueObj._PHOTO.length<50 ){
                //_PHOTO does not look like a base64 content or blob path
                return dataItemValueToBe;
        }else{
            const stringToLog = ( dataItemValueToBe ? dataItemValueToBe.substring(0,(dataItemValueToBe.length>1000 ? 1000 : dataItemValueToBe.length )) : '');
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenSaving',
                                                    '_PHOTO property is detected in {dataItemValueToBe}.',{dataItemValueToBe:stringToLog});

            if(itemValueObj._PHOTO.startsWith('http')){
                //path: do nothing
                return dataItemValueToBe;
            }else{
                //base64 content:compare existing
                initLocalDBIfNotYet();
                const existingPhotoItems = await localDB.getDataItemsByKeysForCluster(clusterResourceID, itemKey+'_PHOTO');
                var existingPhotoFound=false;
                if(existingPhotoItems && existingPhotoItems.length){
                    for (let index = 0; index < existingPhotoItems.length; index++) {
                        const onePhotoItem = existingPhotoItems[index];
                        if(onePhotoItem.arrayIndex===arrayIndex){
                            existingPhotoFound=true;
                            //compare content
                            if(onePhotoItem.localArtefactContent===itemValueObj._PHOTO || 
                                photoContentCRCMatchesFileName(onePhotoItem.value, itemValueObj._PHOTO)){
                                //no change needed for PHOTO type item
                                //just replace with the resourceID
                                itemValueObj._PHOTO=onePhotoItem.resourceID;
                                dataItemValueToBe=JSON.stringify(itemValueObj);
                                return dataItemValueToBe;
                            }else{
                                //updating needed
                                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenSaving','PHOTO type {dataItem} is being updated with new localArtefactContent.',{resourceID:onePhotoItem.resourceID, localArtefactContent:itemValueObj._PHOTO});
                                await saveDataItemWithIndex(onePhotoItem.resourceID,clusterResourceID,itemKey+'_PHOTO',
                                                            itemValueObj._PHOTO,'PHOTO',arrayIndex,'',false,true);
                                itemValueObj._PHOTO=onePhotoItem.resourceID;
                                dataItemValueToBe=JSON.stringify(itemValueObj);
                                return dataItemValueToBe;
                            }
                            
                        }
                    }
                }
                if(!existingPhotoFound){
                    //no existing: add new
                    const newPhotoResourceID = ''+uuid().replace(/-/ig,'');
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenSaving','PHOTO type {dataItem} is being added with new localArtefactContent.',{resourceID:newPhotoResourceID, localArtefactContent:itemValueObj._PHOTO});
                    await saveDataItemWithIndex(newPhotoResourceID,clusterResourceID,itemKey+'_PHOTO',
                                                itemValueObj._PHOTO,'PHOTO',arrayIndex,'',false,true);
                    itemValueObj._PHOTO=newPhotoResourceID;
                    dataItemValueToBe=JSON.stringify(itemValueObj);
                    return dataItemValueToBe;
                }
            }
        } //end detectd
    } 
    
}



/**
 * This function saves a single dataItem or an array of dataItems into a dataCluster. 
 * It handles the resourceIDs internally so the caller does not need to worry about them.
 * It compares the array differences element-by-element and then only makes the neccesary changes, including deleting/updating/inserting.
 * It saves "_PHOTO" property (in base64 encoded, without "data:image/jpeg;base64," prefix) from an item or element json into a linked PHOTO type DataItem.
 * Then it tries to upload it/them to server with realtime API if online. Otherwise, wait for the backend data sync module to sync.
 * This function does NOT create cluster. consider using easyCreateCluster().
 * It returns nothing or throws errors.
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} referenceId cluster referenceId, usually an integer.
 * @param {*} clusterName cluster name. To avoid caller dealing with resourceId, 
 *                             all easy-to-use functions use referenceEntity/referenceId/clusterName to identify a cluster, 
 *                             assuming clusterName is unique in referenceEntity/referenceId. 
 * @param {*} itemOrArrayKey one itemKey for a single item or a group of items (array)
 * @param {*} itemOrArrayValue string type (plain or Json, e.g. 'some value', '{"someProp":"prop value"}' ) for a single item, 
 *                             or array type (each element is a string, e.g. ['some value', '{"someProp":"prop value"}'] ) for an array of dataItems (of which, all elements share the same itemKey).
 *                             For an array, by default (arrayOperationMode=AUTO), this function treats it as the FULL list of the array. any existing dataItems that are not in the new list will be deleted.
 *                             When arrayOperationMode=ACCUMULATION, this parameter should always be in array type.
 *                             If the single item json or an element json contains _PHOTO property (e.g. '{"someProp":"prop value", "_PHOTO":"/9j/4AAXxxxxxx"}'), this function will save the photo content into a linked PHOTO-type item.
 *                             This function will NOT delete existing PHOTO type item for the updated null/blank _PHOTO property in json.
 * @param {*} arrayOperationMode 'AUTO' or 'ACCUMULATION' for controlling array saving behaviour, especially for deleting. 
 *                               By default, it's 'AUTO', meaning "compare and overwrite". 
 *                                  In this mode, this function will compare existing items against itemOrArrayValue (the new full list) and make necessary inserting/deleting/updating to overwrite the list.
 *                               'ACCUMULATION' mode is for this function to only make inserting/updating, but not deleting. 
 *                                  So that the caller can place only new/updated items into itemOrArrayValue to have a better performance.
 *                               The new 'APPEND' mode for just simply appending the new things without comparing to improve performance.  
 * 
 */
export async function easySaveItemInCluster(referenceEntity, referenceId, clusterName, 
                                            itemOrArrayKey, itemOrArrayValue, arrayOperationMode='AUTO'){
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easySaveItemInCluster','{itemOrArrayKey} is being saved to {clusterName} of {referenceId} with {mode}.',{referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, mode:arrayOperationMode});
    SkbLogger.logDebugInfo('Easy to use easySaveItemInCluster: ',itemOrArrayValue);
    if(!itemOrArrayKey){
        throw "One of the mandatory parameters, itemOrArrayKey, is missing.";
        return;
    }

    if((arrayOperationMode==='APPEND' || arrayOperationMode==='ACCUMULATION') && !Array.isArray(itemOrArrayValue)){
        throw "ACCUMULATION mode requires itemOrArrayValue to be an array.";
        return;
    }

    initLocalDBIfNotYet();

    var theCluster=null;
    if(referenceEntity && referenceId && clusterName){
        var existingClusters = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceId);
        if(existingClusters){
            for (let index = 0; index < existingClusters.length; index++) {
                const oneCluster = existingClusters[index];
                if(oneCluster && oneCluster.name===clusterName){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easySaveItemInCluster',
                                            '{dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:oneCluster});
                    theCluster=oneCluster;
                }
            }
        }
    }
    if(!theCluster || !theCluster.resourceID){
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easySaveItemInCluster',
                                            '{dataCluster} is NOT found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:theCluster});
        throw "DataCluster does not exist.";
        return;
    }

    var existingDataItems= await localDB.getDataItemByKey(itemOrArrayKey, theCluster.resourceID, 0); //0 means sorting not required
    var deletingCount = 0;
    var insertingCount = 0;
    var updatingCount = 0;
    if(Array.isArray(itemOrArrayValue)){  
        //array
        //step 1: inserting and updating
        var valuesToInsert=[];
        var maxExistingIndexDimension=1;
        var maxExistingIndexInHighestDimension=0;
        for (let i = 0; i < itemOrArrayValue.length; i++) {
            const oneNewValue = itemOrArrayValue[i];
            var valueIsExisting = false;
            for (let j = 0; j < existingDataItems.length; j++) { //existingDataItems does not always have length > 0
                const oneExistingItem = existingDataItems[j];

                var allIndexInArray = [];
                if(oneExistingItem.arrayIndex) allIndexInArray=oneExistingItem.arrayIndex.split(',');
                if(allIndexInArray.length>maxExistingIndexDimension) maxExistingIndexDimension=allIndexInArray.length;
                if(allIndexInArray.length && parseFloat(allIndexInArray[0])>maxExistingIndexInHighestDimension) maxExistingIndexInHighestDimension=parseFloat(allIndexInArray[0]);

                if(arrayOperationMode!=='APPEND'){  //skip existence checking for append mode
                    if(oneNewValue===oneExistingItem.value){
                        //exactly the same
                        valueIsExisting=true;
                    }else if(sameDataItemValuesIgnoringPHOTOProp(oneNewValue, oneExistingItem.value)){
                        //same except _PHOTO
                        valueIsExisting=true;
                        const oneNewValueReplaced = await easyReplacePhotoInItemWhenSaving(oneExistingItem.resourceID,oneExistingItem.clusterResourceID,
                                                                                    itemOrArrayKey,oneExistingItem.arrayIndex,oneNewValue);
                        if(oneNewValueReplaced!==oneExistingItem.value){
                            await saveDataItemWithIndex(oneExistingItem.resourceID,oneExistingItem.clusterResourceID,itemOrArrayKey,oneNewValueReplaced,
                                'METADATA',oneExistingItem.arrayIndex,oneExistingItem.description,false,true);
                            updatingCount++;
                        }else{
                            //nochange. no need to update
                        }                                                            
                        
                    }
                }
                
            }
            if(!valueIsExisting || arrayOperationMode==='APPEND'){ //always insert for APPEND mode
                valuesToInsert.push(oneNewValue);
            }
        }
        for (let index = 0; index < valuesToInsert.length; index++) {
            const oneValueToInsert = valuesToInsert[index];
            const newResourceID=''+uuid().replace(/-/ig,'');
            const nextIndex  = generateNextIndex(maxExistingIndexDimension, maxExistingIndexInHighestDimension);
            insertingCount++;
            
            const newItemValue = await easyReplacePhotoInItemWhenSaving(newResourceID,theCluster.resourceID,
                itemOrArrayKey,nextIndex,oneValueToInsert);
            maxExistingIndexInHighestDimension = maxExistingIndexInHighestDimension + 1;
            await saveDataItemWithIndex(newResourceID,theCluster.resourceID,itemOrArrayKey,newItemValue,
                                            'METADATA',nextIndex,'',false,true);
        }
        //step 2: deleting
        if(arrayOperationMode!=='ACCUMULATION' && arrayOperationMode!=='APPEND'){
            var dataItemsToDelete=[];
            for (let i = 0; i < existingDataItems.length; i++) {
                const oneExistingItem = existingDataItems[i];
                var existingItemStillPresents = false;
                for (let j = 0; j < itemOrArrayValue.length; j++) {
                    const oneNewValue = itemOrArrayValue[j];
                    if(oneNewValue===oneExistingItem.value){
                        //exactly the same
                        existingItemStillPresents=true;
                    }else if(sameDataItemValuesIgnoringPHOTOProp(oneNewValue, oneExistingItem.value)){
                        //same except _PHOTO
                        existingItemStillPresents=true;
                    }
                }
                if(!existingItemStillPresents){
                    dataItemsToDelete.push(oneExistingItem);
                }
            }
            for (let index = 0; index < dataItemsToDelete.length; index++) {
                const oneItemToDelete = dataItemsToDelete[index];
                deletingCount++;
                await deleteDataItem(oneItemToDelete.resourceID);
                //deleting the linked photo??
                //TODO
            }
        }//end accumulation & append
        
        //step 3: marking changes in datacluster level (updating timestamp to notify other parties)
        if(insertingCount+deletingCount+updatingCount>0){
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easySaveItemInCluster',
                                            '{DataCluster} is being marked for timestamp.',
                                            {DataCluster:theCluster});
            //not always save timestamp to server (only save it if recently not done)
            //saving to server may take 3-5 seconds
            var needToSaveToServer=true;
            const DefinitionOfRecent=60 * 1000; //60 seconds
            const lastTimestamp = localStorage.getItem('last-easySaveItemInCluster-saveClusterToServer-time');
            if (lastTimestamp && ((new Date()).getTime() - lastTimestamp) < DefinitionOfRecent) {
                needToSaveToServer = false;
            }
            await saveDataCluster(theCluster.resourceID,theCluster.name,theCluster.referenceEntity,theCluster.referenceID,
                                theCluster.scope,needToSaveToServer,theCluster.parentResourceID);
            if(needToSaveToServer){
                localStorage.setItem('last-easySaveItemInCluster-saveClusterToServer-time', (new Date()).getTime());
            }
        }
    }else{
        //single
        if(existingDataItems && existingDataItems.length>1){
            //mismatch: refuse
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easySaveItemInCluster',
                                            'Existing array of DataItem with {itemKey} in {clusterResourceID} cannot be overwritten by a single DataItem',
                                            {itemKey:itemOrArrayKey,clusterResourceID:theCluster.resourceID});
        
            throw "There are an existing array of DataItems while a single DataItem is requested to save into the same key. Consider using ACCUMULATION arrayOperationMode if you just want to add one element on top of existing array.";
            return;
        }else if(existingDataItems && existingDataItems.length==1){
            //existing: overwrite
            const existingItem=existingDataItems[0];
            const newItemValue = await easyReplacePhotoInItemWhenSaving(existingItem.resourceID,existingItem.clusterResourceID,
                                                                        itemOrArrayKey,existingItem.arrayIndex,itemOrArrayValue);
            if(existingItem.value!==newItemValue){
                updatingCount++;
                await saveDataItemWithIndex(existingItem.resourceID,existingItem.clusterResourceID,itemOrArrayKey,newItemValue,
                                                'METADATA',existingItem.arrayIndex,existingItem.description,true,true);
            }else{
                //no need to update
            }
            
        }else{
            //no existing: new uuid
            const newResourceID=''+uuid().replace(/-/ig,'');
            const newItemValue = await easyReplacePhotoInItemWhenSaving(newResourceID,theCluster.resourceID,
                                                                        itemOrArrayKey,'0.0',itemOrArrayValue);
            insertingCount++;
            await saveDataItemWithIndex(newResourceID,theCluster.resourceID,itemOrArrayKey,newItemValue,
                                            'METADATA','0.0','',true,true);
        }
    }
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easySaveItemInCluster',
                                '{insertingCount}, {updatingCount} and {deletingCount} of dataItem have been inserted, updated and deleted, respectively. ',
                                {insertingCount:insertingCount, updatingCount:updatingCount,deletingCount:deletingCount});
    
}

/**
 * THIS IS AN ADVANCED OR HALF-PRIVATE FUNCTION. Please do NOT use it unless you understand the data structure clearly.
 * 
 * This function replaces dataItem.value._PHOTO (if exists, assuming it's PHOTO type dataitem's resourceID) with base64 content or blob path.
 * The PHOTO type dataitem is supposed to be with the same arrayIndex and under the same dataCluster. It's key = dataItem.key + '_PHOTO'.
 * 
 * @param {*} dataItemWithPhotoPropInValue 
 * @returns new dataItem
 */
export async function easyReplacePhotoInItemWhenRetrieving(dataItemWithPhotoPropInValue){
    if(!dataItemWithPhotoPropInValue || !dataItemWithPhotoPropInValue.value 
        || typeof dataItemWithPhotoPropInValue.value !== 'string' || !dataItemWithPhotoPropInValue.value.includes('_PHOTO')){
        //no valid _PHOTO
        return dataItemWithPhotoPropInValue;
    }else{
        var itemValueObj=null;
        try {
            itemValueObj=JSON.parse(dataItemWithPhotoPropInValue.value);
        } catch (error) {   }
        if(!itemValueObj || !itemValueObj._PHOTO || typeof itemValueObj._PHOTO !== 'string' 
            || itemValueObj._PHOTO.length<10 || itemValueObj._PHOTO.length>50 
            || itemValueObj._PHOTO.startsWith('http') ){
                //_PHOTO does not look like a resource ID
                //console.log('_PHOTO does not look like a resource ID',itemValueObj, dataItemWithPhotoPropInValue.value);
                return dataItemWithPhotoPropInValue;
        }else{
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenRetrieving','_PHOTO property is detected in {dataItem}.',{dataItem:dataItemWithPhotoPropInValue});

            initLocalDBIfNotYet();
            const photoItem = await localDB.getDataItemByResourceID(itemValueObj._PHOTO);
            if(photoItem && photoItem.localArtefactContent && photoItem.localArtefactContent.length>100){
                //replace with base64 content
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenRetrieving','_PHOTO property is being replaced with localArtefactContent of {photoItem}.',{photoItem:photoItem});
                itemValueObj._PHOTO=photoItem.localArtefactContent;
                dataItemWithPhotoPropInValue.value=JSON.stringify(itemValueObj);
                //console.log('_PHOTO to content',itemValueObj);
                return dataItemWithPhotoPropInValue
            }else if(photoItem && photoItem.value && photoItem.value.length>20 && photoItem.value.includes('/') ){
                //generate blob link
                const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_NAME;
                const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
                sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
                if(!sas_azureStorageKey) sas_azureStorageKey='';
                const blobUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${photoItem.value}${sas_azureStorageKey}`;
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenRetrieving','_PHOTO property is being replaced with {blobUrl}.',{blobUrl:blobUrl});
                itemValueObj._PHOTO=blobUrl;
                dataItemWithPhotoPropInValue.value=JSON.stringify(itemValueObj);
                return dataItemWithPhotoPropInValue;
            }else{
                //nothing found
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyReplacePhotoInItemWhenRetrieving','_PHOTO property is NOT being replaced due to invalid {photoItem}.',{photoItem:photoItem});
                //console.log('no change for photoItem',photoItem);
                return dataItemWithPhotoPropInValue;
            }
        }
    } 
    
}

/**
 * This function retrives a single dataItem or an array of dataItems from localDB (no API actions). 
 * It handles the resourceIDs internally so the caller does not need to worry about them.
 * It loads the related PHOTO type item value if a proper "_PHOTO" property presents in the item's or element's value as json.
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} referenceId cluster referenceId, usually an integer.
 * @param {*} clusterName cluster name. To avoid caller dealing with resourceId, 
 *                             all easy-to-use functions use referenceEntity/referenceId/clusterName to identify a cluster, 
 *                             assuming clusterName is unique in referenceEntity/referenceId. 
 * @param {*} itemOrArrayKey one itemKey for a single item or a group of items (array)
 * @param {*} arrayIndexIfNotAll optional. specifying the arrayIndex List (e.g. ["2", "3"] or ["2,1","3,0"] ) if you want to load only one item in an array. 
 *                               Default value is null, meaning to load all elements if the itemKey is for an array.
 *                               This parameter is expected to be an array. If there is only one index to retrieve, wrap it in an array.
 * @returns an object for a single item, or an array for a group of item, or null if cluster not found. 
 *          If _PHOTO property is contained, it may be base64 string content or blob link. 
 */
export async function easyRetrieveItemInCluster(referenceEntity, referenceId, clusterName, 
                                            itemOrArrayKey, arrayIndexIfNotAll=null){
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyLoadItemInCluster','{itemOrArrayKey} {arrayIndexIfNotAll} is being loaded from {clusterName} of {referenceId}.',{referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, arrayIndexIfNotAll:arrayIndexIfNotAll});

    initLocalDBIfNotYet();

    var theCluster=null;
    if(referenceEntity && referenceId && clusterName){
        var existingClusters = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceId);
        if(existingClusters){
            for (let index = 0; index < existingClusters.length; index++) {
                const oneCluster = existingClusters[index];
                if(oneCluster && oneCluster.name===clusterName){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyRetrieveItemInCluster',
                                            '{dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:oneCluster});
                    theCluster=oneCluster;
                }
            }
        }
    }

    if(!theCluster || !theCluster.resourceID){
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyRetrieveItemInCluster',
                                            '{dataCluster} is NOT found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:theCluster});
                    
        return null;
    }
    //console.log('theCluster',theCluster);
    var dataitems= await localDB.getDataItemByKey(itemOrArrayKey, theCluster.resourceID, 1); //1 means sorting required
    //console.log('easy to use easyRetrieveItemInCluster: dataitems',dataitems);
    dataitems=removeDataWithOperationD(dataitems);
    if(!dataitems || !dataitems.length){
        //nothing
        return {};
    }else if (dataitems.length===1 
                && dataitems[0] 
                && (!dataitems[0].arrayIndex || dataitems[0].arrayIndex==="0" || dataitems[0].arrayIndex==="0.0" || dataitems[0].arrayIndex==="")){
        //single item
        const retItem = await easyReplacePhotoInItemWhenRetrieving(dataitems[0]);
        return retItem;
    }else if (arrayIndexIfNotAll && Array.isArray(arrayIndexIfNotAll)){ 
        //array - some items
        var retItems = [];
        for (let index = 0; index < dataitems.length; index++) {
            const oneItem = dataitems[index];
            if(oneItem && oneItem.arrayIndex && arrayIndexIfNotAll.includes(oneItem.arrayIndex)){
                const oneItemToReturn = await easyReplacePhotoInItemWhenRetrieving(oneItem);
                //avoiding duplicate as per resourceID since UI does not care about resourceID
                var isResourceIDinRet=false;
                for (let k = 0; k < retItems.length; k++) {
                    const oneItemInRet = retItems[k];
                    if(oneItemInRet.resourceID===oneItemToReturn.resourceID){
                        isResourceIDinRet=true;
                        break;
                    }
                }
                if(!isResourceIDinRet) retItems.push(oneItemToReturn);
            }
        }
        return retItems;
    }else{
        //array - all items
        var retItems = [];
        for (let index = 0; index < dataitems.length; index++) {
            const oneItem = dataitems[index];
            const oneItemToReturn = await easyReplacePhotoInItemWhenRetrieving(oneItem);
            //avoiding duplicate as per resourceID since UI does not care about resourceID
            var isResourceIDinRet=false;
            for (let k = 0; k < retItems.length; k++) {
                const oneItemInRet = retItems[k];
                if(oneItemInRet.resourceID===oneItemToReturn.resourceID){
                    isResourceIDinRet=true;
                    break;
                }
            }
            if(!isResourceIDinRet) retItems.push(oneItemToReturn);
        }
        return retItems;
    }

}



/**
 * This function deletes a single item or a group of items (array) in localDB and from server-side (through realtime API).
 * This function does not delete the relevant PHOTO type items.
 * It returns nothing or throws errors.
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} referenceId cluster referenceId, usually an integer.
 * @param {*} clusterName cluster name. To avoid caller dealing with resourceId, 
 *                             all easy-to-use functions use referenceEntity/referenceId/clusterName to identify a cluster, 
 *                             assuming clusterName is unique in referenceEntity/referenceId. 
 * @param {*} itemOrArrayKey one itemKey for a single item or a group of items (array)
 * @param {*} itemValuesToBeDeleted optional. specifying the item list (e.g. ['2', '{"foo":"value 3"}'] ) if you want to delete only some of the items in an array. 
 *                               Default value is null, meaning to delete all elements if the itemKey is for an array.
 *                               When comparing the itemValuesToBeDeleted with the existing items, _PHOTO property will be ignored.
 *                               This parameter is expected to be an array. If there is only one index to delete, wrap it in an array.
 */
export async function easyDeleteItemInCluster(referenceEntity, referenceId, clusterName, 
                                                itemOrArrayKey, itemValuesToBeDeleted=null){
    
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster','{itemOrArrayKey} {itemValuesToBeDeleted} is being deleted from {clusterName} of {referenceId}.',{referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, itemValuesToBeDeleted:itemValuesToBeDeleted});
    
    if(itemValuesToBeDeleted && !Array.isArray(itemValuesToBeDeleted)){
        throw "The parameter itemValuesToBeDeleted is in wrong type.";
        return;
    }
    
    initLocalDBIfNotYet();

    var theCluster=null;
    if(referenceEntity && referenceId && clusterName){
        var existingClusters = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceId);
        if(existingClusters){
            for (let index = 0; index < existingClusters.length; index++) {
                const oneCluster = existingClusters[index];
                if(oneCluster && oneCluster.name===clusterName){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster',
                                            '{dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:oneCluster});
                    theCluster=oneCluster;
                }
            }
        }
    }
    if(!theCluster || !theCluster.resourceID){
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster',
                                            '{dataCluster} is NOT found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:theCluster});
        throw "DataCluster does not exist.";
        return;
    }
    var dataitems= await localDB.getDataItemByKey(itemOrArrayKey, theCluster.resourceID, 0); //0 means sorting not required
    dataitems=removeDataWithOperationD(dataitems);
    if(!dataitems || !dataitems.length){
        //nothing to delete
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster','Nothing is deleted for {itemOrArrayKey} {itemValuesToBeDeleted} from {clusterName} of {referenceId}.',{referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, itemValuesToBeDeleted:itemValuesToBeDeleted});
        return;
    }else if (dataitems.length===1){
        //single item
        await deleteDataItem(dataitems[0].resourceID);
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster','Single {resourceID} is deleted for {itemOrArrayKey} {itemValuesToBeDeleted} from {clusterName} of {referenceId}.',{resourceID:dataitems[0].resourceID,referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, itemValuesToBeDeleted:itemValuesToBeDeleted});
    }else if (itemValuesToBeDeleted && Array.isArray(itemValuesToBeDeleted)){ 
        //array - deleting some
        var itemCount = 0;
        for (let index = 0; index < dataitems.length; index++) {
            const oneItem = dataitems[index];
            //console.log('checking for deleting',oneItem);
            var currentItemShouldBeDeleted = false;
            if(oneItem){ 
                for (let j = 0; j < itemValuesToBeDeleted.length; j++) {
                    const oneItemValueToBeDeleted = itemValuesToBeDeleted[j];
                    //console.log('checking against',oneItemValueToBeDeleted);
                    if(sameDataItemValuesIgnoringPHOTOProp(oneItem.value,oneItemValueToBeDeleted)){
                        currentItemShouldBeDeleted=true;
                    }
                }
                
            }
            if(currentItemShouldBeDeleted){
                //console.log('deleting',oneItem.resourceID);
                await deleteDataItem(oneItem.resourceID);
                itemCount++;
            }
        }
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster','{itemCount} items in the array are deleted for {itemOrArrayKey} {itemValuesToBeDeleted} from {clusterName} of {referenceId}.',{itemCount:itemCount, referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, itemValuesToBeDeleted:itemValuesToBeDeleted});
    }else{
        //array - deleting all
        await deleteDataItemGroup(theCluster.resourceID, itemOrArrayKey);
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDeleteItemInCluster','Whole array is deleted for {itemOrArrayKey} {itemValuesToBeDeleted} from {clusterName} of {referenceId}.',{referenceId:referenceId, clusterName:clusterName, itemOrArrayKey:itemOrArrayKey, itemValuesToBeDeleted:itemValuesToBeDeleted});
    }

}


//////////// DataCluster Level //////////////

/**
 * This function downloads all "root" dataClusters related to the specified referenceEntity from server into localDB
 * and also downloads their dataItems from server into localDB. Although it does NOT return dataItems in the return value.
 * To load more non-root dataClusters, use easyDownloadChildrenClustersAndTheirItems() for each of the root clusters returned by this function.
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @returns root dataCluster list (without dataItems) in an array
 */
export async function easyDownloadRootClustersAndTheirItems(referenceEntity){
    return await easyDownloadReferenceEntityClustersAndTheirItems(referenceEntity,0);
}

/**
 * THIS IS AN ADVANCED OR HALF-PRIVATE FUNCTION. Try NOT to use it unless you understand the data structure clearly. 
 * This function downloads all dataClusters (root or non-root) related to the specified referenceEntity AND referenceID from server into localDB
 * and also downloads their dataItems from server into localDB. Although it does NOT return dataItems in the return value.
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} referenceId cluster referenceId. No default value but 0 means root datacluster only  
 * @param {*} nameFilter default is '', meaning no filter. otherwise, it specifies which DataCluster.Name will have its DataItems downloaded
 * @returns dataCluster list (without dataItems) in an array
 */
export async function easyDownloadReferenceEntityClustersAndTheirItems(referenceEntity, referenceId, nameFilter=''){
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDownloadReferenceEntityClustersAndTheirItems','DataClusters are being downloaded from server for {referenceEntity} {referenceId} {nameFilter}.',{referenceEntity:referenceEntity, referenceId:referenceId, nameFilter:nameFilter});
    const downloadedClusters = await queryDataClustersForEntity(referenceEntity, referenceId);
    //loop queryDataItemsForCluster
    var ret=[];
    if(downloadedClusters){
        for (let index = 0; index < downloadedClusters.length; index++) {
            const oneCluster = downloadedClusters[index];
            if(oneCluster && oneCluster.resourceID && (nameFilter==='' || nameFilter===oneCluster.name)){
                //console.log('queryDataItemsForCluster',oneCluster.resourceID);
                ret.push(oneCluster);
                await queryDataItemsForCluster(oneCluster.resourceID);
            }
            
        }
    }
    return ret;
}

/**
 * This function downloads all "child" dataClusters under the specified parent cluster from server into localDB
 * and also downloads their dataItems from server into localDB. Although it does NOT return dataItems in the return value.
 * The parent cluster info (parentReferenceEntity, parentReferenceId, parentClusterName) is from easyDownloadRootClustersAndTheirItems() or upper level of easyDownloadChildrenClustersAndTheirItems().
 * 
 * @param {*} parentReferenceEntity parent cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} parentReferenceId parent cluster referenceId.
 * @param {*} parentClusterName parent cluster name. To avoid caller dealing with resourceId, 
 *                               all easy-to-use functions use referenceEntity/referenceId/clusterName to identify a cluster, 
 *                               assuming clusterName is unique in referenceEntity/referenceId.
 * @returns child dataCluster list (without dataItems) in an array
 */
export async function easyDownloadChildrenClustersAndTheirItems(parentReferenceEntity, parentReferenceId, parentClusterName){
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDownloadChildrenClustersAndTheirItems','Children DataClusters are being downloaded from server under {parentClusterName} {parentReferenceId}.',{parentClusterName:parentClusterName, parentReferenceId:parentReferenceId});
    var parentCluster=null;
    initLocalDBIfNotYet();
    if(parentReferenceEntity && parentReferenceId && parentClusterName){
        var existingParentClusters = await localDB.getDataClusterForEntityNameAndID(parentReferenceEntity, parentReferenceId);
        if(existingParentClusters){
            for (let index = 0; index < existingParentClusters.length; index++) {
                const oneParentCluster = existingParentClusters[index];
                if(oneParentCluster && oneParentCluster.name===parentClusterName){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyCreateLongLifeClusterIfNone',
                                            'Existing parent {dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. ',
                                            {referenceId:parentReferenceId, referenceEntity:parentReferenceEntity,clusterName:parentClusterName, dataCluster:oneParentCluster});
                    parentCluster=oneParentCluster;
                }
            }
        }
        
    }
    if(!parentCluster || !parentCluster.childResourceIDsForCheck){
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDownloadChildrenClustersAndTheirItems','{parentCluster} or its child list is not found.',{parentCluster:parentCluster});
        throw "Parent DataCluster does not exist or does not include any Children.";
        return null;
    }
    //console.log('parentCluster',parentCluster);
    const childList=JSON.parse(parentCluster.childResourceIDsForCheck);
    var ret=[];
    if(childList && childList.children && Array.isArray(childList.children)){
        for (let index = 0; index < childList.children.length; index++) {
            const oneChildResourceId = childList.children[index].resourceID;
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyDownloadChildrenClustersAndTheirItems','One child {dataCluster} is being downloaded from server.',{dataCluster:{resourceID:oneChildResourceId}});
            if(oneChildResourceId){
                //console.log('oneChildResourceId',oneChildResourceId);
                const oneFullChild = await queryOneDataCluster(oneChildResourceId);
                //console.log('oneFullChild',oneFullChild);
                if(oneFullChild){
                    ret.push(oneFullChild);
                }
                await queryDataItemsForCluster(oneChildResourceId);
            }
            
            
        }
    }
    
    return ret;
} 

/**
 * This function creates new cluster in localDB.
 * It then uploads it to server through realtime API if online. Otherwise, backend sync module will upload it later. 
 * But regardless of realtime API or sync module, the new cluster does not specify lifecycle, meaning long life cluster.
 * It detects the existing cluster LOCALLY to avoid duplicates.
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} referenceId cluster referenceId. 
 * @param {*} clusterName cluster name. To avoid caller dealing with resourceId, 
 *                               all easy-to-use functions use referenceEntity/referenceId/clusterName to identify a cluster, 
 *                               assuming clusterName is unique in referenceEntity/referenceId.
 * @param {*} scope which level the cluster is shared. "User", "Company" or "Global". default "User"
 * @param {*} parentReferenceEntityIfNotRoot optional. specifying the parent cluster's referenceEntity. null/default is for root cluster.
 * @param {*} parentReferenceIdIfNotRoot optional. specifying the parent cluster's referenceId. null/default is for root cluster.
 * @param {*} parentClusterNameIfNotRoot optional. specifying the parent cluster's name. null/default is for root cluster.
 * 
 * @returns cluster resourceID
 */
export async function easyCreateLongLifeClusterIfNone(referenceEntity, referenceId, clusterName, scope='User',
                                        parentReferenceEntityIfNotRoot=null, parentReferenceIdIfNotRoot=null, parentClusterNameIfNotRoot=null){
    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyCreateLongLifeClusterIfNone','{clusterName} is being created for {referenceId} under {parentReferenceIdIfNotRoot}.',{referenceId:referenceId, clusterName:clusterName, parentReferenceIdIfNotRoot:parentReferenceIdIfNotRoot});
    initLocalDBIfNotYet();
    var existingClusters = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceId);
    if(existingClusters){
        for (let index = 0; index < existingClusters.length; index++) {
            const oneCluster = existingClusters[index];
            if(oneCluster && oneCluster.name===clusterName){
                SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyCreateLongLifeClusterIfNone','Existing {dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. Doing nothing.',{referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:oneCluster});
                return oneCluster.resourceID;
            }
        }
    }
    const newResourceId = ''+uuid().replace(/-/ig,'');
    var parentResourceId=null;
    if(parentReferenceEntityIfNotRoot && parentReferenceIdIfNotRoot && parentClusterNameIfNotRoot){
        var existingParentClusters = await localDB.getDataClusterForEntityNameAndID(parentReferenceEntityIfNotRoot, parentReferenceIdIfNotRoot);
        if(existingParentClusters){
            for (let index = 0; index < existingParentClusters.length; index++) {
                const oneParentCluster = existingParentClusters[index];
                if(oneParentCluster && oneParentCluster.name===parentClusterNameIfNotRoot){
                    SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyCreateLongLifeClusterIfNone','Existing parent {dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. ',{referenceId:parentReferenceIdIfNotRoot, referenceEntity:parentReferenceEntityIfNotRoot,clusterName:parentClusterNameIfNotRoot, dataCluster:oneParentCluster});
                    parentResourceId=oneParentCluster.resourceID;
                }
            }
        }
        
    }
    await saveDataCluster(newResourceId,clusterName,referenceEntity,referenceId,scope,true,parentResourceId);
    return newResourceId;

}


/**
 * This function retrives all "plain" dataItems of the cluster from localDB (no API actions) and then normalises (pivots) them into one object. 
 * It handles the resourceIDs internally so the caller does not need to worry about them.
 * "Plain Items" means non-json, non-array and non-PHOTO items
 * 
 * @param {*} referenceEntity cluster referenceEntity, e.g. 'SETTING', 'CompanySubTask'.
 * @param {*} referenceId cluster referenceId, usually an integer.
 * @param {*} clusterName cluster name. To avoid caller dealing with resourceId, 
 *                             all easy-to-use functions use referenceEntity/referenceId/clusterName to identify a cluster, 
 *                             assuming clusterName is unique in referenceEntity/referenceId. 
 * @returns a normalised object to prsent the dataCluster. 
 */
 export async function easyNormalisePlainItemsInCluster(referenceEntity, referenceId, clusterName){
        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyNormalisePlainItemsInCluster','normalised obj is being loaded from {clusterName} of {referenceId}.',{referenceId:referenceId, clusterName:clusterName});

        initLocalDBIfNotYet();

        var theCluster=null;
        if(referenceEntity && referenceId && clusterName){
            var existingClusters = await localDB.getDataClusterForEntityNameAndID(referenceEntity, referenceId);
            if(existingClusters){
                for (let index = 0; index < existingClusters.length; index++) {
                    const oneCluster = existingClusters[index];
                    if(oneCluster && oneCluster.name===clusterName){
                        SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyNormalisePlainItemsInCluster',
                            '{dataCluster} is found for {referenceEntity} {referenceId} {clusterName}. ',
                            {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:oneCluster});
                        theCluster=oneCluster;
                    }
                }
            }
        }

        if(!theCluster || !theCluster.resourceID){
            SkbLogger.applicationTraceSub('Data Sync',SeverityLevel.Verbose,'Data Synchronisation - Easy to use','easyNormalisePlainItemsInCluster',
                '{dataCluster} is NOT found for {referenceEntity} {referenceId} {clusterName}. ',
                {referenceId:referenceId, referenceEntity:referenceEntity,clusterName:clusterName, dataCluster:theCluster});

            return null;
        }
        //console.log('theCluster',theCluster);
        var dataitems= await localDB.getDataItemsForCluster(theCluster.resourceID); 
        //console.log('dataitems',dataitems);
        dataitems=removeDataWithOperationD(dataitems);
        //array - all items
        var retObj = {};
        for (let index = 0; index < dataitems.length; index++) {
            const oneItem = dataitems[index];
            if(oneItem.type==='METADATA'
                && (!oneItem.arrayIndex ||oneItem.arrayIndex==='0.0' || oneItem.arrayIndex==='0' || oneItem.arrayIndex==='')
                && isPlainString(oneItem.value) ){
                retObj[oneItem.key]=oneItem.value;
            }
        }
        return retObj;

}

export function isPlainString(str){
    var jsonObj=null;
    try {
        jsonObj=JSON.parse(str);
    } catch (error) {
        //console.log('error',error);
    }
    //console.log('jsonObj',jsonObj);
    //console.log('typeof jsonObj',(typeof jsonObj));
    if(jsonObj===null || jsonObj===undefined || typeof jsonObj === 'string' || typeof jsonObj === 'number' || typeof jsonObj === 'boolean') return true;
    return false;
}