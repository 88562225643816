import produce from 'immer'; // immer is used to manage immutaition tasks store
import _ from 'lodash';
//import { SkbLogger, ApiService } from '../../services';
import { taskData as sampleTaskStore } from '../actions/sampleTaskDataForPickingAndPacking';


//import { Actions, getCurrentTask, getCurrentSubTask, getStepFromCurrentSubTaskByStepId } from '../actions/tasks';

/**
 * @function tasks task module reducer 
 * @summary sample task store state: please refer to ..\src\Redux\actions\sampleTaskDataForPickingAndPacking.js
 */
const SortDirection = {
    Ascent: 1,
    Descent: 2
}

export const tasksV2 = produce((draft, action) => {
    var currentSubTaskId;
    var currentTaskId;
    switch (action.type) {
        case Actions.TASK_REQUEST_SENT:

            draft.isLoading = true;
            break;
        case Actions.OPEN_RESCHEDULE_PAGE:

            draft.openReschedule = action.data;
            break;
        case Actions.TASK_REQUEST_RESPONDED:

            draft.isLoading = false;
            break;
        case Actions.TASK_UPDATE_ERROR:

            draft.error = action.data;
            break;
        case Actions.TASK_UPDATE_TASKTYPEFILTER:
            draft.taskTypeFilter = action.data;
            break;
        case Actions.TASK_UPDATE_TASKSORTBY:
            draft.taskSortBy = action.data;
            break;
        case Actions.TASK_UPDATE_TASKSORTBYORDER:
            draft.taskSortByAscent = !draft.taskSortByAscent;
            break;
        case Actions.TASK_LOADED:

            return {
                ...draft,
                Tasks: action.data
            };
            //draft.Tasks = action.data; 
            break;
        case Actions.TASK_CONSIGNMENT_SUBMISSION:
        case Actions.TASK_CONSIGNMENT_MODIFIED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.TaskStatus = action.data.TaskStatus;
                subTask.ModifiedBy = action.data.ModifiedBy;
                subTask.ModifiedDate = action.data.ModifiedDate;
                subTask.NextActionBy = action.data.NextActionBy;
            }

            break;
        case Actions.TASK_STEP_CHANGE:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.CurrentStep = action.data.StepId;

            }

            break;
        case Actions.TASK_CONSIGNMENT_PREVIEW_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);

            if (subTask) {
                subTask.PreviewInfo = action.data.Items;

            }

            break;
        case Actions.TASK_CONSIGNMENT_AVAILABLESN_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.ExistingAvailableSNs = action.data.Items;

            }

            break;
        case Actions.TASK_CONSIGNMENT_NOSNITEMS_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.NonSerialisedItems = action.data.Items;

            }

            break;
        case Actions.TASK_CONSIGNMENT_SNITEMS_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.SerialisedItems = action.data.Items;

            }

            break;
        case Actions.TASK_CONSIGNMENT_ADD_NONSN_ITEM:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                if (!Array.isArray(subTask.NonSerialisedItems)) subTask.NonSerialisedItems = [action.data.Item];
                else subTask.NonSerialisedItems.push(action.data.Item);

            }

            break;
        case Actions.TASK_CONSIGNMENT_UPDATE_NONSN_ITEM:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.NonSerialisedItems)) {
                    var idx = subTask.NonSerialisedItems.findIndex(i => i.StockCode === action.data.Item.StockCode);
                    if (idx >= 0) subTask.NonSerialisedItems[idx] = { ...action.data.Item };
                }
            }
            break;
        case Actions.TASK_CONSIGNMENT_DEL_NONSN_ITEM:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.NonSerialisedItems)) {
                    var idx = subTask.NonSerialisedItems.findIndex(i => i.StockCode === action.data.Item.StockCode);
                    if (idx >= 0) subTask.NonSerialisedItems.splice(idx, 1);
                }
            }
            break;
        case Actions.TASK_CONSIGNMENT_ADD_SN_ITEM:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                if (!Array.isArray(subTask.SerialisedItems)) subTask.SerialisedItems = [action.data.Item];
                else subTask.SerialisedItems.push(action.data.Item);

            }

            break;
        case Actions.TASK_CONSIGNMENT_UPDATE_SN_ITEM:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.SerialisedItems)) {
                    var idx = subTask.SerialisedItems.findIndex(i => i.SerialNumber === action.data.Item.SerialNumber);
                    if (idx >= 0) subTask.SerialisedItems[idx] = { ...action.data.Item };
                }
            }
            break;
        case Actions.TASK_CONSIGNMENT_DEL_SN_ITEM:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.SerialisedItems)) {
                    var idx = subTask.SerialisedItems.findIndex(i => i.SerialNumber === action.data.Item.SerialNumber);
                    if (idx >= 0) subTask.SerialisedItems.splice(idx, 1);
                }
            }
            break;
        case Actions.TASK_CONSIGNMENT_UPDATE_PICKUPDETAILS:
        case Actions.TASK_CONSIGNMENT_PICKUPDETAILS_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                console.log('TASK_CONSIGNMENT_UPDATE_PICKUPDETAILS',action.data.Details)
                subTask.PickupDetails = action.data.Details;
                //task view is using below information
                if(action.data.Details.PickupWindow && action.data.Details.PickupWindow.Date){
                    subTask.PickupWindowDate=action.data.Details.PickupWindow.Date;
                }
                if(action.data.Details.PickupWindow && action.data.Details.PickupWindow.ETA){
                    subTask.PickupWindowETA=action.data.Details.PickupWindow.ETA;
                }
               
                
            }

            break;
        case Actions.TASK_CONSIGNMENT_DROPOFFDETAILS_LOADED:
        case Actions.TASK_CONSIGNMENT_UPDATE_DROPOFFDETAILS:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.DropOffDetails = action.data.Details;
                //when first time load it come like this structure
                if(action.data.Details.DropOffWindow && action.data.Details.DropOffWindow.Date){
                    subTask.DropOffWindowDate=action.data.Details.DropOffWindow.Date;
                }
                if(action.data.Details.DropOffWindow && action.data.Details.DropOffWindow.ETA){
                    subTask.DropOffWindowETA=action.data.Details.DropOffWindow.ETA;
                }
                //when date changed the structure is like this. Add this part handle this.
                if( action.data.Details.Date){
                    subTask.DropOffWindowDate=action.data.Details.Date;
                }
                if(action.data.Details.ETA){
                    subTask.DropOffWindowETA=action.data.Details.ETA;
                }
                
                
            }

            break;
        case Actions.TASK_CONSIGNMENT_PACKAGES_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);

            if (subTask) {
                subTask.PackageDetails = action.data.Items;

            }

            break;
        case Actions.TASK_CONSIGNMENT_ADD_PACKAGE:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                if (!Array.isArray(subTask.PackageDetails)) subTask.PackageDetails = [{...action.data.Item, RowId:0}]; //row id from 0 
                else {

                    subTask.PackageDetails.push({...action.data.Item, RowId: getNextRowId(subTask.PackageDetails)});
                }

            }

            break;
        case Actions.TASK_CONSIGNMENT_UPDATE_PACKAGE:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.PackageDetails)) {
                    // var idx = subTask.PackageDetails.findIndex(i => _.isEqual(i, action.data.Item));
                    var idx = subTask.PackageDetails.findIndex(i =>i.RowId == action.data.Item.RowId // ?? will it have more than one package for same type
                        /*&& i.Length == action.data.Item.Length
                        && i.Width == action.data.Item.Width
                        && i.Height == action.data.Item.Height
                        && i.Weight == action.data.Item.Weight
                        */
                        );
                    if (idx >= 0) subTask.PackageDetails[idx] = { ...action.data.Item };
                }
            }
            break;
        case Actions.TASK_CONSIGNMENT_DEL_PACKAGE:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.PackageDetails)) {
                    var idx = subTask.PackageDetails.findIndex(i =>i.RowId == action.data.Item.RowId // 
                    // && i.Length == action.data.Item.Length
                    // && i.Width == action.data.Item.Width
                    // && i.Height == action.data.Item.Height
                    // && i.Weight == action.data.Item.Weight
                    );
                    if (idx >= 0) subTask.PackageDetails.splice(idx, 1);
                }
            }
            break;
        case Actions.TASK_UPDATE_LOCKEDBY:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.LockedBy = action.data.LockedBy;

            }

            break;

        case Actions.TASK_CONSIGNMENT_LABELDETAILS_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);

            if (subTask) {
                subTask.LabelDetails = action.data.Items;

            }

            break;
        case Actions.TASK_CONSIGNMENT_ADD_LABEL:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                if (!Array.isArray(subTask.LabelDetails)) subTask.LabelDetails = [action.data.Item];
                else subTask.LabelDetails.push(action.data.Item);

            }

            break;
        case Actions.TASK_CONSIGNMENT_DEL_LABEL:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {

                if (Array.isArray(subTask.LabelDetails)) {
                    var idx = subTask.LabelDetails.findIndex(i => _.isEqual(i, action.data.Item));
                    if (idx >= 0) subTask.LabelDetails.splice(idx, 1);
                }
            }
            break;
        case Actions.TASK_UPDATE_STEP_STATUS:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                if (!subTask.StepsStatus) subTask.StepsStatus = new Object();
                subTask.StepsStatus[action.data.StepName] = action.data.Status;
            }
            break;
        case Actions.TASK_STEP_STATUS_LOADED:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);

            if (subTask) {
                subTask.StepsStatus = action.data.StepsStatus;

            }

            break;
        case Actions.TASK_UPDATE_CANCELLATIONREASON:
            var subTask = getSubTaskById(draft, action.data.TaskId, action.data.SubtaskId);
            if (subTask) {
                subTask.CancellationReason = action.data.Reason;

            }
            break;
        default:

            break;
    }
}, { //initial store data
    CurrentTaskResourceId: null,
    CurrentSubTaskResourceId: null,
    isLoading: false,
    error: "",
    Tasks: [],//sampleTaskStore.Tasks,
    showDetails: false,
    taskSortByAscent: true,
    taskSortBy: "Create Date",//"isLoading",
    taskTypeFilter: "All Stock Tasks"//,"Return All Stock"//"All Stock Tasks"//
});



export const Actions = {
    TASK_REQUEST_SENT: 'TASK_REQUEST_SENT',
    TASK_UPDATE_TASKTYPEFILTER: 'TASK_UPDATE_TASKTYPEFILTER',
    TASK_UPDATE_TASKSORTBYORDER: 'TASK_UPDATE_TASKSORTBYORDER',
    TASK_UPDATE_TASKSORTBY: 'TASK_UPDATE_TASKSORTBY',
    TASK_REQUEST_RESPONDED: 'TASK_REQUEST_RESPONDED',
    TASK_UPDATE_ERROR: 'TASK_UPDATE_ERROR',
    TASK_LOADED: 'TASK_LOADED',
    TASK_UPDATE_LOCKEDBY: 'TASK_UPDATE_LOCKEDBY',
    TASK_CONSIGNMENT_MODIFIED: 'TASK_CONSIGNMENT_MODIFIED',
    TASK_CONSIGNMENT_SUBMISSION: 'TASK_CONSIGNMENT_SUBMISSION',
    TASK_STEP_CHANGE: 'TASK_STEP_CHANGE',
    TASK_CONSIGNMENT_PREVIEW_LOADED: 'TASK_CONSIGNMENT_PREVIEW_LOADED',
    TASK_CONSIGNMENT_AVAILABLESN_LOADED: 'TASK_CONSIGNMENT_AVAILABLESN_LOADED',
    TASK_CONSIGNMENT_NOSNITEMS_LOADED: 'TASK_CONSIGNMENT_NOSNITEMS_LOADED',
    TASK_CONSIGNMENT_SNITEMS_LOADED: 'TASK_CONSIGNMENT_SNITEMS_LOADED',
    TASK_CONSIGNMENT_ADD_NONSN_ITEM: 'TASK_CONSIGNMENT_ADD_NONSN_ITEM',
    TASK_CONSIGNMENT_UPDATE_NONSN_ITEM: 'TASK_CONSIGNMENT_UPDATE_NONSN_ITEM',
    TASK_CONSIGNMENT_DEL_NONSN_ITEM: 'TASK_CONSIGNMENT_DEL_NONSN_ITEM',
    TASK_CONSIGNMENT_ADD_SN_ITEM: 'TASK_CONSIGNMENT_ADD_SN_ITEM',
    TASK_CONSIGNMENT_UPDATE_SN_ITEM: 'TASK_CONSIGNMENT_UPDATE_SN_ITEM',
    TASK_CONSIGNMENT_DEL_SN_ITEM: 'TASK_CONSIGNMENT_DEL_SN_ITEM',
    TASK_CONSIGNMENT_PICKUPDETAILS_LOADED: 'TASK_CONSIGNMENT_PICKUPDETAILS_LOADED',
    TASK_CONSIGNMENT_UPDATE_PICKUPDETAILS: 'TASK_CONSIGNMENT_UPDATE_PICKUPDETAILS',
    TASK_CONSIGNMENT_PACKAGES_LOADED: 'TASK_CONSIGNMENT_PACKAGES_LOADED',
    TASK_CONSIGNMENT_ADD_PACKAGE: 'TASK_CONSIGNMENT_ADD_PACKAGE',
    TASK_CONSIGNMENT_UPDATE_PACKAGE: 'TASK_CONSIGNMENT_UPDATE_PACKAGE',
    TASK_CONSIGNMENT_DEL_PACKAGE: 'TASK_CONSIGNMENT_DEL_PACKAGE',
    TASK_CONSIGNMENT_DROPOFFDETAILS_LOADED: 'TASK_CONSIGNMENT_DROPOFFDETAILS_LOADED',
    TASK_CONSIGNMENT_UPDATE_DROPOFFDETAILS: 'TASK_CONSIGNMENT_UPDATE_DROPOFFDETAILS',
    TASK_CONSIGNMENT_ADD_LABEL: "TASK_CONSIGNMENT_ADD_LABEL",
    TASK_CONSIGNMENT_DEL_LABEL: "TASK_CONSIGNMENT_DEL_LABEL",
    TASK_CONSIGNMENT_LABELDETAILS_LOADED: 'TASK_CONSIGNMENT_LABELDETAILS_LOADED',
    TASK_UPDATE_STEP_STATUS: 'TASK_UPDATE_STEP_STATUS',
    TASK_STEP_STATUS_LOADED: 'TASK_STEP_STATUS_LOADED',
    TASK_UPDATE_CANCELLATIONREASON: 'TASK_UPDATE_CANCELLATIONREASON',
    OPEN_RESCHEDULE_PAGE:'OPEN_RESCHEDULE_PAGE'
};

export const getTaskById = (taskState, taskId) => {
    return taskState.Tasks.find(t => t.TaskId == taskId);
}

export const getSubTaskById = (taskState, taskId, subtaskId) => {

    var task = getTaskById(taskState, taskId);
    if (task)
        return task.SubTasks.find(t => t.SubTaskId == subtaskId);

    return null;
}

export const getNextRowId = (arr) =>{
    //array rowid start from 0

    if(!Array.isArray(arr)) return 0;
    
    return arr.reduce((c, i)=>{
        return Math.max(c, i.RowId||0);
    }, 0) + 1;
}
