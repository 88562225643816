import { takeEvery, call, put, all, select, fork } from "redux-saga/effects";
import axios from "axios";
import { config } from "../../utils/config";
import { crypts } from "../../utils/crypts";
import endpoints from "../../api/endpoints";
import types from "./types";
import generateRequestID from "../../utils/ThreePLImportation/generateRequestID";
import { uploadOneThreePLImportationFileToBlob } from "../../services/ThreePLImportation/Upload3PLImportationService";
import { getReturnDashboardUtility } from "../../components/modules/stock_components/returns_dashboard/returnDashboardUtility";

import {
    readCSVSuccess,
    readCSVError,
    setProcessing,
    threePLImportationSuccess,
    threePLImportationError,
    fetchOutcomesSuccess,
    fetchOutcomesError,
    fetchLocationsSuccess,
    fetchLocationsError,
} from "./actions";

// importing services
import ProcessFile from "../../services/ThreePLImportation/ProcessFile";
const CSV_json_data = (state) => state.threePLImportation.CSV_json_data;

// ********** saga for reading & processing CSV file ***********
function* readCSVStartHandler(action) {
    console.log(action);
    const {
        payload: { file, warehouseLocation },
    } = action;
    const extention = file.name.split(".").pop();
    if (extention !== undefined && extention.toLowerCase() !== "csv") {
        yield put(
            readCSVError({
                formatError: `Invalid file .${extention}, upload a csv file`,
            }),
        );
    } else {
        if (extention !== undefined) {
            try {
                const jsonData = yield ProcessFile(file);
                const fileObj = file;
                const requestId = generateRequestID(5);
                // Upload file
                const fileUploadedPathInServer = yield call(readyForUpload, { fileObj, requestId, warehouseLocation });
                console.log(fileUploadedPathInServer);
                // Push data
                if (fileUploadedPathInServer) {
                    let dataForPush = [];
                    let obj = {
                        requestId,
                        fileName: file.name,
                        filePath: fileUploadedPathInServer,
                        status: "Ready to Process",
                        csvData: jsonData,
                        warehouseLocation: warehouseLocation,
                    };
                    dataForPush.push(obj);
                    let csvDataJson = yield select(CSV_json_data);
                    if (csvDataJson === null) {
                        yield put(readCSVSuccess(dataForPush));
                    } else {
                        csvDataJson.push(dataForPush[0]);
                        yield put(readCSVSuccess(csvDataJson));
                    }
                    console.log(yield select(CSV_json_data));
                } else {
                    yield put(readCSVError("Upload file failed!"));
                }
            } catch (e) {
                yield put(readCSVError(e));
            }
        }
    }
}

async function readyForUpload(obj) {
    let fileUploadedPathInServer = await makeItReadyForUpload(obj.fileObj, obj.requestId, obj.warehouseLocation);
    return fileUploadedPathInServer;
}

const makeItReadyForUpload = async (fileObj, requestId, warehouseLocation) => {
    try {
        const filetypeGrabbed = fileObj.type;
        const rawBaseSixtyFourCont = await getReturnDashboardUtility.toBase64(fileObj);
        const searchPart = `data:${filetypeGrabbed};base64,`;
        const okBaseSixtyFourCont = rawBaseSixtyFourCont.replace(searchPart, "");

        const nameData = warehouseLocation + "_" + fileObj.name;
        const nameSplit = nameData.split(".");
        const fileExt = nameSplit[nameSplit.length - 1];

        console.log(okBaseSixtyFourCont);
        const chkResp = await uploadOneThreePLImportationFileToBlob(okBaseSixtyFourCont, nameData, requestId);
        return chkResp;
    } catch (e) {
        console.log("=======> MAKE IT READY FOR UPLOAD FAILED <=============");
        // enqueueSnackbar(
        //     `Something went wrong please try again later.`,
        //     { variant: 'error',onClose:()=>{} }
        // );
    }
};

// ********** saga for delete CSV file ***********
function* deleteCSVFile(action) {
    let csvDataJson = yield select(CSV_json_data);
    const fileDetele = action.payload;
    const arrayAfterDelete = csvDataJson.filter((item) => item.requestId !== fileDetele.requestId);
    yield put(readCSVSuccess(arrayAfterDelete));
}

export function* deleteCSVFileWatcher() {
    yield takeEvery(types.DELETE_CSV_FILE, deleteCSVFile);
}

export function* readCSVStartWatcher() {
    yield takeEvery(types.READ_CSV_START, readCSVStartHandler);
}
// *************************************************************

// ************* API call for stock adjustment *****************
function* threePLImportationStartHandler(action) {
    const files = action.payload;
    console.log("🚀 ~ file: sagas.js:127 ~ function*threePLImportationStartHandler ~ files:", files)

    try {
        yield put(setProcessing(true));
        yield call(sendAllData, files);
        yield call(sendProcessRequest);
        yield put(threePLImportationSuccess(true));
    } catch (e) {
        yield put(threePLImportationError(e));
    }
}

function* sendAllData(files) {
    console.log("🚀 ~ file: sagas.js:139 ~ function*sendAllData ~ files:", files)
    yield all(files.map((file) => call(sendData, file)));
}
function* sendData(file) {
    console.log("🚀 ~ file: sagas.js:143 ~ function*sendData ~ file:", file)
    const apiConfig = {
        method: "POST",
        url: endpoints.THREEPL_IMPORT(file.warehouseLocation),
        headers: {
            "content-type": "application/json",
            authorization: config.REACT_APP_API_SECRET,
            "X-CustomHeader": crypts.decrypt(localStorage.getItem("id_token")),
        },
        data: file,
    };
    console.log("🚀 ~ file: sagas.js:156 ~ function*sendData ~ apiConfig:", apiConfig)
    yield fork(() => axios(apiConfig));
}
function* sendProcessRequest() {
    console.log("🚀 ~ file: sagas.js:158 ~ function*sendProcessRequest ~ sendProcessRequest")
    const apiConfig = {
        method: "POST",
        url: endpoints.THREEPL_IMPORT_PROCES,
        headers: {
            "content-type": "application/json",
            authorization: config.REACT_APP_API_SECRET,
            "X-CustomHeader": crypts.decrypt(localStorage.getItem("id_token")),
        },
        data: {},
    };
    console.log("🚀 ~ file: sagas.js:156 ~ function*sendProcessRequest ~ apiConfig:", apiConfig)
    yield call(() => axios(apiConfig));
}
export function* threePLImportationStartWatcher() {
    yield takeEvery(types.THREE_PL_IMPORTATION_START, threePLImportationStartHandler);
}
// *************************************************************

// *********** API CALL FOR GETTING OUTCOMES *******************
function* fetchOutcomesStartHandler(action) {
    const apiConfig = {
        method: "GET",
        url: endpoints.THREEPL_IMPORT_PROGRESS,
        headers: {
            "content-type": "application/json",
            authorization: config.REACT_APP_API_SECRET,
            "X-CustomHeader": crypts.decrypt(localStorage.getItem("id_token")),
        },
    };
    try {
        console.log("🚀 ~ file: sagas.js:193 ~ function*fetchOutcomesStartHandler ~ apiConfig:", apiConfig);
        const { data } = yield call(() => axios(apiConfig));
        console.log(data);
        yield put(fetchOutcomesSuccess(data));
    } catch (e) {
        yield put(fetchOutcomesError(e));
    }
}

export function* fetchOutcomesStartWatcher() {
    yield takeEvery(types.FETCH_OUTCOMES_START, fetchOutcomesStartHandler);
}
// *************************************************************

function* fetchLocationsHandler(action) {
    const apiConfig = {
        method: "GET",
        url: endpoints.GET_WMS_WAREHOUSE_LOCATIONS,
        headers: {
            "content-type": "application/json",
            authorization: config.REACT_APP_API_SECRET,
            "X-CustomHeader": crypts.decrypt(localStorage.getItem("id_token")),
        },
    };
    try {
        console.log("🚀 ~ file: sagas.js:193 ~ function*fetchLocationsHandler ~ apiConfig:", apiConfig);
        let { data = [] } = yield call(() => axios(apiConfig));
        if (data.length) {
            data = data.map((x) => ({
                value: x.id,
                text: x.name,
            }));
        }
        console.log(data);
        yield put(fetchLocationsSuccess(data));
    } catch (e) {
        yield put(fetchLocationsError(e));
    }
}

export function* fetchLocationsHandlerWatcher() {
    yield takeEvery(types.FETCH_LOCATIONS, fetchLocationsHandler);
}
