import { takeEvery,call,put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {config} from '../../utils/config';
import {crypts} from '../../utils/crypts';
import endpoints from '../../api/endpoints';
import types from './types';
import {
    fetchConsignmentListSuccess,
    fetchConsignmentListError,

    fetchConsignmentDetailsSuccess,
    fetchConsignmentDetailsError,

    fetchConsignmentInfoSuccess,
    fetchConsignmentInfoError,

    filterByStatusSuccess,
    filterByStatusError,

    searchByStockCodeSucess,
    searchByStockCodeError,

    searchBySerialNumberSuccess,
    searchBySerialNumberError,

    searchByConsignmentIdSuccess,
    searchByConsignmentIdError,

    updateConsignmentStatusSuccess,
    updateConsignmentStatusError,

    overrideConsignmentActualSuccess,
    overrideConsignmentActualError,


} from './actions';

//************* API CALL FOR FETCHING CONSIGNMENT LIST  *****************
export function* fetchConsignmentListHandler(){
    const apiConfig = {
        method : "GET",
        url : endpoints.CONSIGNMENT_LIST,
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try{
        const {data} = yield call(()=>axios(apiConfig));
        yield put(fetchConsignmentListSuccess(data));
    }
    catch(e){
        yield put(fetchConsignmentListError(e?.response));
    }
}

export function* fetchConsignmentListWatcher(){
    
    yield takeEvery(
        types.FETCH_CONSIGNMENT_LIST_START,
        fetchConsignmentListHandler,
    )
}
// ***********************************************************************



// ************ API CALL FOR FETCHING CONSIGNMENT DETAILS ****************
export function* fetchConsignmentDetailsHandler(action){
    const apiConfig = {
        method : "GET",
        url : endpoints.CONSIGNMENT_DETAILS(action?.id),
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }

    try{
        const {data} = yield call(()=>axios(apiConfig));
        yield put(fetchConsignmentDetailsSuccess(data));
    }catch(e){
        yield put(fetchConsignmentDetailsError(e));
    }
}

export function* fetchConsignmentDetailsStartWatcher(){
    yield takeEvery(
        types.FETCH_CONSIGNMENT_DETAILS_START,
        fetchConsignmentDetailsHandler,
    )
}
// ***********************************************************************


// ************ API CALL FOR FETCHING CONSIGNMENT INFO *******************
export function* fetchConsignmentInfoHandler(action){
    const apiConfig = {
        method : "GET",
        url : endpoints.CONSIGNMENT_INFO(action?.id),
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    console.log("authorization" , config.REACT_APP_API_SECRET)
    console.log("X-CustomHeader" , crypts.decrypt(localStorage.getItem('id_token')))
    try{
        const {data} = yield call(()=>axios(apiConfig));
        yield put(fetchConsignmentInfoSuccess(data));
    }catch(e){
        yield put(fetchConsignmentInfoError(e));
    }    
}
export function* fetchConsignmentInfoStartWatcher(){
    yield takeEvery(
        types.FETCH_CONSIGNMENT_INFO_START,
        fetchConsignmentInfoHandler,
    )
}
// ***********************************************************************



// ************ API CALL FOR FILTERING CONSIGNMENT BY STATUS *************
export function* filterConsignmentByStatusHandler(action){
    const apiConfig = {
        method : "GET",
        url : endpoints.FILTER_BY_STATUS(action?.status),
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try{
        const {data} = yield call(()=>axios(apiConfig));
        yield put(filterByStatusSuccess(data))
    }catch(e){
        yield put(filterByStatusError(e));
    }
}
export function* filterConsignmentByStatusStartWatcher(){
    yield takeEvery(
        types.FILTER_BY_STATUS_START,
        filterConsignmentByStatusHandler,
    )
}
// ***********************************************************************



// ************** API CALL FOR OVERRIDE CONSIGNMENT ACTUAL****************
export function* overrideConsignmentDetailsActualStartHandler(action){
    const tableItems = action?.payload;
    const rawData = [];
    for(let i =0 ; i < tableItems.length ; i++ ){
        if(tableItems[i].col7 === "serialised"){
            const row = {
                StockCategoryCode : tableItems[i].col1,
                ClientName : tableItems[i].col2,
                SerialNumber : tableItems[i].col3,
                Condition : tableItems[i].col6,
            }
            rawData.push(row);

        }else{
            const row = {
                StockCategoryCode: tableItems[i].col1,
                ClientName: tableItems[i].col2,
                QtyGood: tableItems[i].col5, 
                QtyFaulty: 0,
            }
            rawData.push(row);
        }
        
    }
    const apiConfig = {
        method : "PUT",
        url : endpoints.OVERWRITE_CONSIGNMENT_STOCK_DETAILS_ACTUAL(action?.id),
        data : rawData,
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try{
        
        const {data} = yield call(()=>axios(apiConfig));
        yield put(overrideConsignmentActualSuccess(data))
    }catch(e){
        yield put(overrideConsignmentActualError(e))
    }
}
export function* overrideConsignmentDetailsActualStartWatcher(){
    yield takeEvery(
        types.OVERRIDE_CONSIGNMENT_STOCK_DETAILS_ACTUAL_START,
        overrideConsignmentDetailsActualStartHandler,
    )
}
// ***********************************************************************



// ************** API CALL FOR SEARCH BY STOCK CODE **********************
export function* searchByStockCodeStartHandler(action){
    const apiConfig = {
        method : "GET",
        url : endpoints.SEARCH_BY_STOCK_CODE(action?.phrase),
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try {
        const {data} = yield call(()=>axios(apiConfig));
        yield put(searchByStockCodeSucess(data));
    }catch(e){
        yield put(searchByStockCodeError(e));
    }
    
}

export function* searchByStockCodeStartWatcher(){
    yield takeEvery(
        types.SEARCH_BY_STOCK_CODE_START,
        searchByStockCodeStartHandler,
    )
}
// ***********************************************************************


// *********** API CALL FOR SEARCH BY SERIAL NUMBER **********************
export function* searchBySerialNumberStartHandler(action){
    const apiConfig = {
        method : "GET",
        url : endpoints.SEARCH_BY_SERIAL_NUMBER(action?.phrase),
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try {
        const {data} = yield call(()=>axios(apiConfig));
        yield put(searchBySerialNumberSuccess(data));
    }catch(e){
        yield put(searchBySerialNumberError(e));
    }
    
}

export function* searchBySerialNumberStartWatcher(){
    yield takeEvery(
        types.SEARCH_BY_SERIAL_NUMBER_START,
        searchBySerialNumberStartHandler,
    )
}
// ***********************************************************************


// ************* API CALL FOR SEARCH BY CONSIGNMENT ID *******************
export function* searchByConsignmentIdStartHandler(action){
    const apiConfig = {
        method : "GET",
        url : endpoints.SEARCH_BY_CONSIGNMENT_ID(action?.phrase),
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try {
        const {data} = yield call(()=>axios(apiConfig));
        yield put(searchByConsignmentIdSuccess(data));
    }catch(e){
        yield put(searchByConsignmentIdError(e));
    }
    
}

export function* searchByConsignmentIdStartWatcher(){
    yield takeEvery(
        types.SEARCH_BY_CONSIGNMENT_ID_START,
        searchByConsignmentIdStartHandler,
    )
}
// ***********************************************************************

// ************** API CALL FOR UPDATE CONSIGNMENT STATUS *****************
export function* updateConsignmentStatusStartHandler(action){
    const apiConfig = {
        method : "PATCH",
        url : endpoints.UPDATE_CONSIGNMENT_STATUS,
        data : action.payload,
        headers : {
            'content-type' : 'application/json',
            'authorization' : config.REACT_APP_API_SECRET,
            'X-CustomHeader' : crypts.decrypt(localStorage.getItem('id_token'))
        }
    }
    try{
        const {data} = yield call(()=>axios(apiConfig));
        yield put(updateConsignmentStatusSuccess());
    }catch(e){
        yield put(updateConsignmentStatusError(e))
    }
}
export function* updateConsignmentStatusStartWatcher(){
    yield takeLatest(
        types.UPDATE_CONSIGNMENT_STATUS_START,
        updateConsignmentStatusStartHandler,
    )
}
// ***********************************************************************