import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE } from '../Themes'
import { ThemeProvider, Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CallForAssistance from '../../components/registration/CallForAssistance';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    footer: {
        // gridArea: "footer",
        height: LAYOUTCOMPONENT_SIZE.Footer.Height,
        zIndex: 1000,
        position: "fixed",
        textAlign: "right",
        verticalAlign: "text-bottom",

        bottom: 0,
        width: '100vw',
        backgroundColor: theme.palette.background.secondary,
        borderTop: `solid ${theme.palette.primary.main} 5px`,
        paddingTop: theme.spacing(1),
    },
    copyright: {
        position: "absolute",
        bottom: theme.spacing(0),
        right: theme.spacing(1) / 2,
    },

}));

function SkbFooter(props) {
    const classes = useStyles();
    const theme = useTheme();
    theme.breakpoints.down("sm")
    return (
        //<Hidden smDown implementation="css"  className={classes.footer}>

        <div className={classes.footer}>
            <CallForAssistance />

            {/* DO NOT SHOW COPY RIGHT to make it consistent */}
            {/*if there is no call for assistance, show copywrite footer, otherwise hide */}
            {/* {props.store && props.store.callForAssistance && Array.isArray(props.store.callForAssistance.phoneNumbers) && props.store.callForAssistance.phoneNumbers.length == 0 && 
            <Typography color="text" variant="caption" component="div" className={classes.copyright}>
                {(new Date()).getFullYear()} &copy; Skybridge Group Pty Ltd
            </Typography>} */}
        </div>
        //</Hidden>
    );
}


const mapStateToProps = state => {
    return { store: state };
};



export default connect(mapStateToProps, null)(SkbFooter);