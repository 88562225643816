import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, darken } from '@material-ui/core/styles';
import { red, grey, blue } from '@material-ui/core/colors';


const PrimarySwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.primary.dark,

    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.light,
    },
    display: 'flex',
    alignItems: 'center',

    // [theme.breakpoints.up('md')]: {
    //   paddingBottom: 2,
    // },
  },
  track: {
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 10,
    },
    [theme.breakpoints.up('md')]: {
      height: 12,
      width: 46,
    },
  },
  thumb: {
    [theme.breakpoints.down('sm')]: {
      height: 14,
      width: 14,
    },
    [theme.breakpoints.up('md')]: {
      height: 16,
      width: 16,
    },
  },
  checked: {},

}))(Switch);

const SecondarySwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.secondary.dark,
    '&$checked': {
      color: theme.palette.secondary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.secondary.light
    },
    display: 'flex',
    alignItems: 'center',
  },
  track: {
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 10,
    },
    [theme.breakpoints.up('md')]: {
      height: 14,
      width: 46,
    },
  },
  thumb: {
    [theme.breakpoints.down('sm')]: {
      height: 14,
      width: 14,
    },
    [theme.breakpoints.up('md')]: {
      height: 18,
      width: 18,
    },
  },
}))(Switch);

// const IOSSwitch = withStyles(theme => ({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: theme.spacing(1),
//   },
//   switchBase: {
//     padding: 1,
//     '&$checked': {
//       transform: 'translateX(16px)',
//       color: theme.palette.common.white,
//       '& + $track': {
//         backgroundColor: darken(theme.palette.primary.main, 0.3),
//         opacity: 1,
//         border: 'none',
//       },
//     },
//     '&$focusVisible $thumb': {
//       color: darken(theme.palette.primary.main, 0.3),
//       border: '6px solid #fff',
//     },
//   },
//   thumb: {
//     width: 24,
//     height: 24,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(['background-color', 'border']),
//   },
//   checked: {},
//   focusVisible: {},
// }))(({ classes, ...props }) => {
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//          switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       {...props}
//     />
//   );
// });


/**
 * 
 * @property {text}: label text for the switch
 * @property {primary}: true: render as primary switch, false secondary switch
 * @property {disabled}: true: switch is disabled
 * @property {val}: boolean, true: the switch is on, otherwise it's off
 * @property {setVal}: switch handler which will be called when switch is changed 
 * @property {showOnOffText}: Show On/Off labels next to the switch (default true)
 */
export function SkbSwitch({ text, primary, disabled, val, setVal, showOnOffText = true, ...other }) {

  const handleChange = event => {
    setVal(event.target.checked);
  };

  return (

    <Grid component="label" container alignItems="center" justify="flex-start" spacing={0}>
      {text && <Grid item>{text}</Grid>}
      {showOnOffText && <Grid item> Off </Grid>}
      <Grid item>{primary && <PrimarySwitch disabled={disabled} checked={val} onChange={handleChange} {...other} />}
        {!primary && <SecondarySwitch disabled={disabled} checked={val} onChange={handleChange}  {...other} />}</Grid>
      {showOnOffText && <Grid item>On</Grid>}

    </Grid>


  );
}
