import produce from 'immer'; // immer is used to manage immutaition tasks store
import { Switch } from 'react-router';
import { SkbLogger, ApiService } from '../../../services';


import { ActionTypes } from '../../actions/returnsdashboard';

/**
 * Reducers related to Returns Dashboard Task info popup and task info add stock screen and schedule screen related functionality
 */


const initialStateReturnsDashboardTaskInfo = {
    
  "taskInfo": {},
  "taskInfoItemList": [],
  "taskInfoItemsSelected": [],
  "taskInfoLoadStatus": false,
  "taskInfoPopupStructureShowFlag": false,

  "taskInfoNonListChangedData": {
    "consignmentMethod":"",
    "wareHouseLocationCode":"",    
  
  },
  "nonListChangeableDataSetFlag":false,
  "errorApiFlag":false, // task info api 
  "errorApiMessage":"", // task info api

  "taskInfoUpdateApiOneSuccessFlag":false, // task info api 1 update success flag
  "taskInfoUpdateApiOneSuccessMessage":"", // task info api 1 update success message
  "taskInfoUpdateApiOneErrorFlag":false, // task info api 1 update error flag
  "taskInfoUpdateApiOneErrorMessage":"", // task info api 1 update error Message

  "taskInfoUpdateApiTwoSuccessFlag":false, // task info api 1 update success flag
  "taskInfoUpdateApiTwoSuccessMessage":"", // task info api 2 update success message
  "taskInfoUpdateApiTwoErrorFlag":false, // task info api 2  update success flag
  "taskInfoUpdateApiTwoErrorMessage":"", // task info api 1 update error Message

  "taskInfoScheduleFinishApiSuccessFlag":false, // task Info Schedule Finish Api success flag
  "taskInfoScheduleFinishApiSuccessMsg":"", // task Info Schedule Finish Api success message
  "taskInfoScheduleFinishApiErrorFlag":false, // task Info Schedule Finish Api error flag
  "taskInfoScheduleFinishApiErrorMessage":"", // task Info Schedule Finish Api error Message

  //need to be removed
  // "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess":false, // task Info Schedule pre popup open api update success flag
  // "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccessMsg":"", // task Info Schedule pre popup open api update success message
  // "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorFlag":false, // task Info Schedule pre popup open api update error flag
  // "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorMsg":"", // task Info Schedule pre popup open api update Api error Message
  
  } 

 
export const returnsDashboardTaskInfo =  (state = initialStateReturnsDashboardTaskInfo, action) => {   
 
      switch (action.type) { 
        
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW:           
            return Object.assign({}, state, {
              taskInfo: action.payload
            });
                 
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_ON:        
        return Object.assign({}, state, {          
          taskInfoLoadStatus: true
        });

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_OFF:        
          return Object.assign({}, state, {          
            taskInfoLoadStatus: false
          });
        
        case ActionTypes.TASK_INFO_POPUP_STRUCTURE_SHOW_FLAG:        
        return Object.assign({}, state, {          
          taskInfoPopupStructureShowFlag: action.payload
        });
  
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_SET_API_ERROR:        
          return Object.assign({}, state, {          
              errorApiFlag: true,
              errorApiMessage: action.payload

            });

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_UNSET_API_ERROR:        
          return Object.assign({}, state, {          
            errorApiFlag: false,
            errorApiMessage: ""
          });

        case ActionTypes.TASK_INFO_NONLIST_CHANGEABLEDATA_UNSET:  { 
          
          let taskInfoNonListChangedData = {
            "consignmentMethod":"",
            "wareHouseLocationCode":"",    
          
          };
         
          initialStateReturnsDashboardTaskInfo["taskInfoNonListChangedData"] = taskInfoNonListChangedData;

          return Object.assign({}, initialStateReturnsDashboardTaskInfo);
        }      
           
        
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_SET:           
            return Object.assign({}, state, {
              taskInfoItemList: action.payload
            });
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UNSET:           
            return Object.assign({}, state, {
              taskInfoItemList: []
            });       
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ITEM_SELECT:           
            return Object.assign({}, state, {
              taskInfoItemsSelected: []
            });
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UPDATE:           
            return Object.assign({}, state, {
              taskInfoItemList: action.payload
            });
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ADD_ITEMSELECTEDFROMADDSTOCK:           
            return Object.assign({}, state, {
              taskInfoItemList: action.payload
            });

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS:
            return Object.assign({},state,{
              "taskInfoUpdateApiOneSuccessFlag": true,
              "taskInfoUpdateApiOneSuccessMessage": action.payload,

            });
          
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS_UNSET:
            return Object.assign({},state,{
              "taskInfoUpdateApiOneSuccessFlag": false,
              "taskInfoUpdateApiOneSuccessMessage": "",

            });
        

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR:
              return Object.assign({},state,{
                "taskInfoUpdateApiOneErrorFlag": true,
                "taskInfoUpdateApiOneErrorMessage": action.payload,
  
              });

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR_UNSET:
                return Object.assign({},state,{
                  "taskInfoUpdateApiOneErrorFlag": false,
                  "taskInfoUpdateApiOneErrorMessage": "",
    
                });
        
        

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS:
            return Object.assign({},state,{
              "taskInfoUpdateApiTwoSuccessFlag": true,
              "taskInfoUpdateApiTwoSuccessMessage": action.payload,

            });

        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS_UNSET:
              return Object.assign({},state,{
                "taskInfoUpdateApiTwoSuccessFlag": false,
                "taskInfoUpdateApiTwoSuccessMessage": "",
  
              });

        
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR:
              return Object.assign({},state,{
                "taskInfoUpdateApiTwoErrorFlag": true,
                "taskInfoUpdateApiTwoErrorMessage": action.payload,
  
              });
        case ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR_UNSET:
                return Object.assign({},state,{
                  "taskInfoUpdateApiTwoErrorFlag": false,
                  "taskInfoUpdateApiTwoErrorMessage": "",
    
                });
        case ActionTypes.TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_CONSIGNMENTMETHOD:
                  {
                    let consignmentMethodData = action.payload;
                    let stateCpy = {...state};
          
                    stateCpy["taskInfoNonListChangedData"]["consignmentMethod"] = consignmentMethodData;      
                    return Object.assign({},state,stateCpy);   
                }
        case ActionTypes.TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_WAREHOUSELOCCODE:
                  {
                    let wareHouseLocationCodeData = action.payload;
                    let stateCpy = {...state};
          
                    stateCpy["taskInfoNonListChangedData"]["wareHouseLocationCode"] = wareHouseLocationCodeData;      
                    return Object.assign({},state,stateCpy);   
                }
        case ActionTypes.TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_SET_FLAG:
                  {
                       
                    return Object.assign({},state,{"nonListChangeableDataSetFlag":action.payload?true:false});   
                }

        //
        case ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_SUCCESS:
          return Object.assign({},state,{
            "taskInfoScheduleFinishApiSuccessFlag": true,
            "taskInfoScheduleFinishApiSuccessMsg": action.payload,

          });
        
        case ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_SUCCESS_UNSET:
          return Object.assign({},state,{
            "taskInfoScheduleFinishApiSuccessFlag": false,
            "taskInfoScheduleFinishApiSuccessMsg": "",

          });
      

        case ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_ERROR_SET:
            return Object.assign({},state,{
              "taskInfoScheduleFinishApiErrorFlag": true,
              "taskInfoScheduleFinishApiErrorMessage": action.payload,

            });

        case ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_ERROR_UNSET:
              return Object.assign({},state,{
                "taskInfoScheduleFinishApiErrorFlag": false,
                "taskInfoScheduleFinishApiErrorMessage": "",
  
              });

        //  //need to be removed

        // case ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS:
        //   return Object.assign({},state,{
        //     "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess": true,
        //     "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccessMsg": action.payload,

        //   });
        
        // case ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS_UNSET:
        //   return Object.assign({},state,{
        //     "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccess": false,
        //     "statusChangeToAwaitingScheduleFromAwaitingLabelsApiSuccessMsg": "",

        //   });
      

        // case ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR:
        //     return Object.assign({},state,{
        //       "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorFlag": true,
        //       "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorMsg": action.payload,

        //     });

        // case ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR_UNSET:
        //       return Object.assign({},state,{
        //         "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorFlag": false,
        //         "statusChangeToAwaitingScheduleFromAwaitingLabelsApiErrorMsg": "",
  
        //       });
          

        
          

        default:
          return state;
      }
    }

const initialStateConsignmentMethod = {
    
  "consignmentMethodList": [],    
  "consignmentMethodListLoadStatus": false,
  "errorApiFlag":false,
  "errorApiMessage":"",
  
  }

export const returnsDashboardConsignmentMethod = (state = initialStateConsignmentMethod , action) => {

  switch(action.type){

    case ActionTypes.CONSIGNMENT_METHOD_LIST_LOAD:
        return Object.assign({},state,{
          consignmentMethodList: action.payload
        });    
    case ActionTypes.CONSIGNMENT_METHOD_LIST_LOAD_LOADER_ON:        
        return Object.assign({}, state, {          
          consignmentMethodListLoadStatus: true
        });
    case ActionTypes.CONSIGNMENT_METHOD_LIST_LOAD_LOADER_OFF:        
        return Object.assign({}, state, {          
            consignmentMethodListLoadStatus: false
          });
    case ActionTypes.CONSIGNMENT_METHOD_LIST_SET_API_ERROR:        
        return Object.assign({}, state, {          
              errorApiFlag: true,
              errorApiMessage: action.payload

            });
    case ActionTypes.CONSIGNMENT_METHOD_LIST_UNSET_API_ERROR:        
          return Object.assign({}, state, {          
            errorApiFlag: false,
            errorApiMessage: ""
          });

    case ActionTypes.CONSIGNMENT_METHOD_LIST_API_DATA_UNSET:        
          return Object.assign({}, initialStateConsignmentMethod);
    default:
      return state;



    
    }

  }

const initialStateWarehouseList = {
    
  "warehouseList": [],    
  "warehouseListLoadStatus": false,
  "errorApiFlag":false,
  "errorApiMessage":"",
  
  }
  
export const returnsDashboardWarehouseList = (state=initialStateWarehouseList, action) => {
  
    switch(action.type){
  
      case ActionTypes.WAREHOUSE_LIST_LOAD:
          return Object.assign({},state,{
            warehouseList: action.payload
          });    
      case ActionTypes.WAREHOUSE_LIST_LOAD_LOADER_ON:        
          return Object.assign({}, state, {          
            warehouseListLoadStatus: true
          });
      case ActionTypes.WAREHOUSE_LIST_LOAD_LOADER_OFF:        
          return Object.assign({}, state, {          
            warehouseListLoadStatus: false
            });
      case ActionTypes.WAREHOUSE_LIST_LOAD_SET_API_ERROR:        
          return Object.assign({}, state, {          
                errorApiFlag: true,
                errorApiMessage: action.payload
  
              });
      case ActionTypes.WAREHOUSE_LIST_LOAD_UNSET_API_ERROR:        
            return Object.assign({}, state, {          
              errorApiFlag: false,
              errorApiMessage: ""
            });
  
      case ActionTypes.WAREHOUSE_LIST_API_DATA_UNSET:        
            return Object.assign({}, initialStateWarehouseList);
      default:
        return state;
      
      }
  }

const initialStateWarehouseAddrBasedOnLocCode = {
    
  "warehouseAddressData": {},    
  "warehouseAddressDataLoadStatus": false,
  "errorApiFlag":false,
  "errorApiMessage":"",
  
  }
  
export const returnsDashboardWarehouseAddrBasedOnLocCode = (state=initialStateWarehouseAddrBasedOnLocCode, action) => {
  
    switch(action.type){
  
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE:
          return Object.assign({},state,{
            warehouseAddressData: action.payload
          });    
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_ON:        
          return Object.assign({}, state, {          
            warehouseAddressDataLoadStatus: true
          });
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_OFF:        
          return Object.assign({}, state, {          
            warehouseAddressDataLoadStatus: false
            });
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_SET_API_ERROR:        
          return Object.assign({}, state, {          
                errorApiFlag: true,
                errorApiMessage: action.payload
  
              });
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_UNSET_API_ERROR:        
            return Object.assign({}, state, {          
              errorApiFlag: false,
              errorApiMessage: ""
            });
  
      case ActionTypes.WAREHOUSE_ADDR_LOAD_API_DATA_UNSET:        
            return Object.assign({}, initialStateWarehouseAddrBasedOnLocCode);
      default:
        return state;
      
      }
  }


 
const initialStateTaskInfoAddStockItem = {
    
  "stockItemDetailFromLocation": null,  
  "stockItemDetailListToDisplay": [], 
  "stockItemDetailListElementSelected": [],
  "stockItemDetailFromLocationLoadStatus": false,
  "errorApiFlag":false,
  "errorApiMessage":"",
  
  }

 
export const returnsDashboardTaskInfoAddStockItem =  (state = initialStateTaskInfoAddStockItem, action) => {    
      switch (action.type) { 
        
        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD:           
            return Object.assign({}, state, {
              stockItemDetailFromLocation: action.payload
            });
                 
        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY:        
        return Object.assign({}, state, {          
          stockItemDetailListToDisplay: action.payload
        });

        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY_UPDATE:        
        return Object.assign({}, state, {          
          stockItemDetailListToDisplay: action.payload
        });

        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_ON:        
          return Object.assign({}, state, {          
            stockItemDetailFromLocationLoadStatus: true
          });

        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_OFF:
          {
           
            return Object.assign({}, state, {          
              stockItemDetailFromLocationLoadStatus: false
            });
          }        
         

          
        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_SET_API_ERROR:        
          return Object.assign({}, state, {          
              errorApiFlag: true,
              errorApiMessage: action.payload

            });

        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_UNSET_API_ERROR:        
          return Object.assign({}, state, {          
            errorApiFlag: false,
            errorApiMessage: ""
          });

        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_API_DATA_UNSET:        
            return Object.assign({}, initialStateTaskInfoAddStockItem);   
                  
        case ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_SELECTED:        
            return Object.assign({}, state, {          
              stockItemDetailListElementSelected: action.payload
            });

        default:
          return state;
      }
    }

  
const initialStateWarehouseAddrBasedOnLocCodeTipspTwoSteps = {
    
  "warehouseAddressData": {},    
  "warehouseAddressDataLoadStatus": false,
  "errorApiFlag":false,
  "errorApiMessage":"",
  
  }
  
export const returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps = (state=initialStateWarehouseAddrBasedOnLocCodeTipspTwoSteps, action) => {
  
    switch(action.type){
  
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS:
          return Object.assign({},state,{
            warehouseAddressData: action.payload
          });    
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_ON:        
          return Object.assign({}, state, {          
            warehouseAddressDataLoadStatus: true
          });
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_OFF:        
          return Object.assign({}, state, {          
            warehouseAddressDataLoadStatus: false
            });
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_SET_API_ERROR:        
          return Object.assign({}, state, {          
                errorApiFlag: true,
                errorApiMessage: action.payload
  
              });
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_UNSET_API_ERROR:        
            return Object.assign({}, state, {          
              errorApiFlag: false,
              errorApiMessage: ""
            });
  
      case ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_API_DATA_UNSET:        
            return Object.assign({}, initialStateWarehouseAddrBasedOnLocCode);
      default:
        return state;
      
      }
  }

  const initialStateReturnLogisticCarriers = {
    
    "logisticCarrierList": [],    
    "logisticCarrierListLoadStatus": false,
    "errorApiFlag": false,
    "errorApiMessage": ""
    }

  export const returnLogisticCarriers =  (state = initialStateReturnLogisticCarriers, action) => {    
      switch (action.type) { 
        
        case ActionTypes.LOGISTIC_CARRIER_LIST_LOAD:
           
            return Object.assign({}, state, {
              logisticCarrierList: action.payload
            });    
                
       case ActionTypes.LOGISTIC_CARRIER_LIST_LOAD_UNSET:
           
            return Object.assign({}, state, {
              logisticCarrierList: []
            });   
                 
       case ActionTypes.LOGISTIC_CARRIER_LIST_LOAD_LOADER_ON:
        
        return Object.assign({}, state, {          
          logisticCarrierListLoadStatus: true
        });

      case ActionTypes.LOGISTIC_CARRIER_LIST_LOAD_LOADER_OFF:
        
          return Object.assign({}, state, {          
            logisticCarrierListLoadStatus: false
          });
  
  
        
        default:
          return state
      }
    }

    const initialStatePickupWindTimeSlotOptns = {
    
    "timeslotOptionList": [],    
    "timeslotOptionListLoadStatus": false,
    "errorApiFlag": false,
    "errorApiMessage": ""
    }

    export const returnPickupWindowTimeSlotOptions =  (state = initialStatePickupWindTimeSlotOptns, action) => {    
      switch (action.type) { 
        
        case ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD:
           
            return Object.assign({}, state, {
              timeslotOptionList: action.payload
            });            
      
                 
       case ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_ON:
        
        return Object.assign({}, state, {          
          timeslotOptionListLoadStatus: true
        });

      case ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_OFF:
        
          return Object.assign({}, state, {          
            timeslotOptionListLoadStatus: false
          });
      case ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD_UNSET:
        
          return Object.assign({}, state, {          
            timeslotOptionList: []
          });
  
  
        
        default:
          return state
      }
   
   
    }
  
  // RETURN DROP OFF TIME SLOT RELATED REDUCER
  const initial__returnDrpOffwindowTimeSlotOptions = {
    "timeslotOptionList": [],    
    "timeslotOptionListLoadStatus": false,
    "errorApiFlag": false,
    "errorApiMessage": ""
  }

  export const returnDropOffwindowTimeSlotOptions = (state=initial__returnDrpOffwindowTimeSlotOptions,action)=>{

    switch(action.type){

      case ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD:
        return Object.assign({}, state, {
          timeslotOptionList: action.payload
        });            
      
                 
      case ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_ON:
        return Object.assign({}, state, {          
          timeslotOptionListLoadStatus: true
        });

      case ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_OFF:
        return Object.assign({}, state, {          
            timeslotOptionListLoadStatus: false
          });

      case ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET:{
        return Object.assign({},state,{
          timeslotOptionList : [],
        })
      }

      default:
        return state;
    }
  }
  

  const initialStateTaskInfoSchedPopupChangeableData = {

    "twoStepScenarioStepOneChangedDataSetFlag": false,
    "twoStepScenarioStepTwoChangedDataSetFlag": false,
    "scheduleFlowLoaderFlag": false,    

    // FOR NON CONTRACTOR DROPOFF
    "twoStepScenarioStepOneChangedData":{
      "wareHouseLocationCode":"",
      "atp": false,
      "forkLiftRequired": false,
      "validateFlag": false,
      "validateFlagErrMessages":{}
     
    },
    "twoStepScenarioStepTwoChangedData": {
        "carrierName":"",
        "carrierNameUrl":"",
        "cannote":"",
        "labelFiles":[],
        "pickupWindowDate":"",
        "pickupWindowTimeslot":"",
        "validateFlag": false,
        "validateFlagErrMessages":{}

    },

    // FOR CONTRACTOR DROP OFF
    "oneStepScenarioChangedData": {
      //same as before
      "wareHouseLocationCode" : "",
      "deliveryDate" : "",
      "deliveryWindowTimeSlot" : "",
      "packageDetails" : {
        "Type" : "",
        "Qty" : "",
      },
      "consignmentInfo" : false,
      "loadingConsignmentInfo" : false,
      "consignmentInfoFetchError" : false,
      "isAnyDataModified" : false,
      "isLoading" : false,
      "labelFiles" : [],
      "validateFlag": false,
      "validateFlagErrMessages":{},
    },

    "dataChangeActivityDoneByUser": false
  }

  export const returnsDashboardTaskInfoSchedulePopupChangedData = (state=initialStateTaskInfoSchedPopupChangeableData,action) => {

    switch(action.type){
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_WAREHOUSELOCATIONCODE:
        {
          let wareHouseLocationCode = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepOneChangedData"]["wareHouseLocationCode"] = wareHouseLocationCode;      
          return Object.assign({},state,stateCpy);   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_ATP:
        {
          let atpData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepOneChangedData"]["atp"] = atpData;      
          return Object.assign({},state,stateCpy);   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_FORKLIFTREQUIRED:
        {
          let forkLiftRequiredData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepOneChangedData"]["forkLiftRequired"] = forkLiftRequiredData;      
          return Object.assign({},state,stateCpy);   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWDATE:
        {
          let pickupWindowDateData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepTwoChangedData"]["pickupWindowDate"] = pickupWindowDateData;      
          return Object.assign({},state,stateCpy);   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWTIMESLOT:
        {
          let pickupWindowTimeslotData = action.payload;
          let stateCpy = {...state};
          console.log("SETTING TWO STEP PICKUP WINDOW TIME SLOT")
          stateCpy["twoStepScenarioStepTwoChangedData"]["pickupWindowTimeslot"] = pickupWindowTimeslotData;      
          return Object.assign({},state,stateCpy);   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME:
        {
          let carrierNameData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepTwoChangedData"]["carrierName"] = carrierNameData;      
          return Object.assign({},state,stateCpy);   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME_URL:
        {
          let carrierNameRelUrlData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepTwoChangedData"]["carrierNameUrl"] = carrierNameRelUrlData;      
          return Object.assign({},state,stateCpy);   
      }
       case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CANNOTE:
        {
          let cannoteData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepTwoChangedData"]["cannote"] = cannoteData;      
          return Object.assign({},state,stateCpy);   
      }

      case ActionTypes.TASK_INFO_SCHEDULE_POPUP_DATA_CHANGE_ACTIVITY_BY_USER:
        {
             
          return Object.assign({},state,{"dataChangeActivityDoneByUser":action.payload?true:false});   
      }

      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_CHANGEABLEDATA_SET_FLAG:
        {
             
          return Object.assign({},state,{"twoStepScenarioStepOneChangedDataSetFlag":action.payload?true:false});   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CHANGEABLEDATA_SET_FLAG:
        {
             
          return Object.assign({},state,{"twoStepScenarioStepTwoChangedDataSetFlag":action.payload?true:false});   
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG:
        {
             
          let validateFlagData = action.payload;
          let stateCpy = {...state};

          stateCpy["twoStepScenarioStepOneChangedData"]["validateFlag"] = validateFlagData;      
          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG_AND_FE_ERROR:
        {
             
          let {validateFlagProvided,validateFlagErrMessagesProvided} = {...action.payload};
          let {wareHouseLocationCodeErrMsg,atpErrMsg, forkliftRequiredErrMsg} = {...validateFlagErrMessagesProvided};
                   

          let stateCpy = {...state};
          let {twoStepScenarioStepTwoChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...twoStepScenarioStepTwoChangedData};
          
          validateFlagErrMessages["wareHouseLocationCodeErrMsg"] = wareHouseLocationCodeErrMsg;
          validateFlagErrMessages["atpErrMsg"] = atpErrMsg;
          validateFlagErrMessages["forkliftRequiredErrMsg"] = forkliftRequiredErrMsg;
                   

          stateCpy["twoStepScenarioStepOneChangedData"]["validateFlag"] = validateFlagProvided?validateFlagProvided:validateFlag;
          stateCpy["twoStepScenarioStepOneChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_UPDATE_SPECIFIC_MSG_FE_ERROR:
        {
             
          let {specificForKey,specificForKeyValue} = {...action.payload};

          let stateCpy = {...state};
          let {twoStepScenarioStepOneChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...twoStepScenarioStepOneChangedData};
          
          if(specificForKey==="wareHouseLocationCodeErrMsg"){
            validateFlagErrMessages["wareHouseLocationCodeErrMsg"] = specificForKeyValue;
          }            
          
          stateCpy["twoStepScenarioStepOneChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;          

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG:
        {
             
          let validateFlag = action.payload;         
          let stateCpy = {...state};
          stateCpy["twoStepScenarioStepTwoChangedData"]["validateFlag"] = validateFlag;
          

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG_AND_FE_ERROR:
        {
             
          let {validateFlagProvided,validateFlagErrMessagesProvided} = {...action.payload};
          let {carrierNameErrMsg,connoteErrMsg,pickupWindowDateErrMsg,pickupWindowTimeSlotErrMsg,labelsErrMsg} = {...validateFlagErrMessagesProvided};
                   

          let stateCpy = {...state};
          let {twoStepScenarioStepTwoChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...twoStepScenarioStepTwoChangedData};
          
          validateFlagErrMessages["carrierNameErrMsg"] = carrierNameErrMsg;
          validateFlagErrMessages["connoteErrMsg"] = connoteErrMsg;
          validateFlagErrMessages["pickupWindowDateErrMsg"] = pickupWindowDateErrMsg;
          validateFlagErrMessages["pickupWindowTimeSlotErrMsg"] = pickupWindowTimeSlotErrMsg;
          validateFlagErrMessages["labelsErrMsg"] = labelsErrMsg;         

          stateCpy["twoStepScenarioStepTwoChangedData"]["validateFlag"] = validateFlagProvided?validateFlagProvided:validateFlag;
          stateCpy["twoStepScenarioStepTwoChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPDATE_SPECIFIC_MSG_FE_ERROR:
        {
             
          let {specificForKey,specificForKeyValue} = {...action.payload};

          let stateCpy = {...state};
          let {twoStepScenarioStepTwoChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...twoStepScenarioStepTwoChangedData};
          
          if(specificForKey==="carrierNameErrMsg"){
            validateFlagErrMessages["carrierNameErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="connoteErrMsg"){
            validateFlagErrMessages["connoteErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="pickupWindowDateErrMsg"){
            validateFlagErrMessages["pickupWindowDateErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="pickupWindowTimeSlotErrMsg"){
            validateFlagErrMessages["pickupWindowTimeSlotErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="labelsErrMsg"){
            validateFlagErrMessages["labelsErrMsg"] = specificForKeyValue;
          }           
          
          stateCpy["twoStepScenarioStepTwoChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;
          

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_POPUP_SCHEDULE_CHANGEABLE_DATA_FULL_UNSET:
        {
          let subStepOneOfTwoStepperObj = {
            "wareHouseLocationCode":"",
            "atp": false,
            "forkLiftRequired": false,
            "validateFlag": false,
            "validateFlagErrMessages":{}
           
          };

          let subStepTwoOfTwoStepperObj = {
            "carrierName":"",
            "carrierNameUrl":"",
            "cannote":"",
            "labelFiles":[],
            "pickupWindowDate":"",
            "pickupWindowTimeslot":"",
            "validateFlag": false,
            "validateFlagErrMessages":{}
           
          };

          // write to code to clear one to clear state
          let subOneStepScenarioChangedData = {
            //same as before
            "wareHouseLocationCode" : "",
            "deliveryDate" : "",
            "deliveryWindowTimeSlot" : "",
            "packageDetails" : {
              "Type" : "",
              "Qty" : "",
            },
            "consignmentInfo" : false,
            "loadingConsignmentInfo" : false,
            "consignmentInfoFetchError" : false,
            "isAnyDataModified" : false,
            "isLoading" : false,
            "labelFiles" : [],
            "validateFlag": false,
            "validateFlagErrMessages":{}
          }
          
          initialStateTaskInfoSchedPopupChangeableData["twoStepScenarioStepOneChangedData"] = subStepOneOfTwoStepperObj;

          initialStateTaskInfoSchedPopupChangeableData["twoStepScenarioStepTwoChangedData"] = subStepTwoOfTwoStepperObj;

          initialStateTaskInfoSchedPopupChangeableData["oneStepScenarioChangedData"] = subOneStepScenarioChangedData;

             
          return Object.assign({},initialStateTaskInfoSchedPopupChangeableData);   
        }
        case ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPLOADED_LABEL_SET:
          {
            let labelFileEl = action.payload;
            let stateCpy = {...state};

            let labelFileAr = [...stateCpy["twoStepScenarioStepTwoChangedData"]["labelFiles"]];
            labelFileAr.push(labelFileEl);
  
            stateCpy["twoStepScenarioStepTwoChangedData"]["labelFiles"] = labelFileAr;      
            return Object.assign({},state,stateCpy);   
        } 
        case ActionTypes.TASK_INFO_SCHEDULE_GENERIC_LOADERFLAG:
          {
     
            return Object.assign({},state,{"scheduleFlowLoaderFlag":action.payload?true:false});   
        } 

      // add some cases here for Contractor drop off 
       case ActionTypes.TASK_INFO_SCHEDULE_CONTRDROPOFF_SCENARIO_VALIDATE_FLAG_AND_FE_ERROR:
        {
             
          let {validateFlagProvided,validateFlagErrMessagesProvided} = {...action.payload};
          let {oneStepWareHouseLocationCodeErrMsg,deliveryDateErrMsg, deliveryWindowTimeSlotErrMsg, labelFilesErrMsg} = {...validateFlagErrMessagesProvided};                   
         
          let stateCpy = {...state};
          let {oneStepScenarioChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...oneStepScenarioChangedData};
          
          validateFlagErrMessages["oneStepWareHouseLocationCodeErrMsg"] = oneStepWareHouseLocationCodeErrMsg;
          validateFlagErrMessages["deliveryDateErrMsg"] = deliveryDateErrMsg;
          validateFlagErrMessages["deliveryWindowTimeSlotErrMsg"] = deliveryWindowTimeSlotErrMsg;
          validateFlagErrMessages["labelFilesErrMsg"] = labelFilesErrMsg;
                   

          stateCpy["oneStepScenarioChangedData"]["validateFlag"] = validateFlagProvided?validateFlagProvided:validateFlag;
          stateCpy["oneStepScenarioChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_CONTRDROPOFF_SCENARIO_UPDATE_SPECIFIC_MSG_FE_ERROR:
        {
          
          let {specificForKey,specificForKeyValue} = {...action.payload};

          let stateCpy = {...state};
          let {oneStepScenarioChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...oneStepScenarioChangedData};
          
          if(specificForKey==="oneStepWareHouseLocationCodeErrMsg"){
            validateFlagErrMessages["oneStepWareHouseLocationCodeErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="deliveryDateErrMsg"){
            validateFlagErrMessages["deliveryDateErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="deliveryWindowTimeSlotErrMsg"){
            validateFlagErrMessages["deliveryWindowTimeSlotErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="labelFilesErrMsg"){
            validateFlagErrMessages["labelFilesErrMsg"] = specificForKeyValue;
          }
                  
          
          stateCpy["oneStepScenarioChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;
          

          return Object.assign({},state,stateCpy);  
      }
      case ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__WAREHOUSE_LOCATIONCODE:
      {
          let wareHouseLocationCode = action.payload;
          let stateCpy = {...state};

          stateCpy["oneStepScenarioChangedData"]["wareHouseLocationCode"] = wareHouseLocationCode;      
          return Object.assign({},state,stateCpy);
      }
      case ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_DATE: {
        let date = action.payload;
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["deliveryDate"] = date;
        return Object.assign({},state,stateCopy);

      }
      case ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_WINDOW_TIMESLOT: {
        let time = action.payload;
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["deliveryWindowTimeSlot"] = time;
        return Object.assign({},state,stateCopy);
      }
      case ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_PALLETS: {
          let pallets = action.payload;
          let stateCopy = {...state};
          stateCopy["oneStepScenarioChangedData"]["packageDetails"]["Type"] = "Pallets";
          stateCopy["oneStepScenarioChangedData"]["packageDetails"]["Qty"]  = pallets;
          return Object.assign({},state,stateCopy);
      }

      case ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_CARTONS: {
        let cartons = action.payload;
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["packageDetails"]["Type"] = "Cartons";
        stateCopy["oneStepScenarioChangedData"]["packageDetails"]["Qty"]  = cartons;
        return Object.assign({},state,stateCopy);
      }

      case ActionTypes.FETCH_CONSIGNMENT_DETAILS_START: {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["loadingConsignmentInfo"] = true;
        stateCopy["oneStepScenarioChangedData"]["consignmentInfoFetchError"] = false;
        return Object.assign({},state,stateCopy);
      }
      case ActionTypes.FETCH_CONSIGNMENT_DETAILS_SUCCESS: {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["loadingConsignmentInfo"] = false;
        stateCopy["oneStepScenarioChangedData"]["consignmentInfo"] = action.payload;
        return Object.assign({},state,stateCopy);
      }

      case ActionTypes.FETCH_CONSIGNMENT_DETAILS_ERROR: {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["loadingConsignmentInfo"] = false;
        stateCopy["oneStepScenarioChangedData"]["consignmentInfoFetchError"] = action.payload;
        return Object.assign({},state,stateCopy);
      }

      case ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_CHANGED : {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["isAnyDataModified"] = true;
        return Object.assign({},state,stateCopy);
      }

      case ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_RESET : {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"] = initialStateTaskInfoSchedPopupChangeableData["oneStepScenarioChangedData"];
        return Object.assign(
          {},
          state,
          stateCopy,
        );
      }
      case ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_START: {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["isLoading"] = true;
        return Object.assign({},state,stateCopy);
      }

      case ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_END: {
        let stateCopy = {...state};
        stateCopy["oneStepScenarioChangedData"]["isLoading"] = false;
        return Object.assign({},state,stateCopy);
      }

      case ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_SET_UPLOADED_LABELS:
      {
            let labelFileEl = action.payload;
            let stateCpy = {...state};

            let labelFileAr = [...stateCpy["oneStepScenarioChangedData"]["labelFiles"]];
            labelFileAr.push(labelFileEl);
            // console.log("ACTION ===> ",ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_SET_UPLOADED_LABELS);
            // console.log("PAYLOAD ====> ",labelFileEl);
            stateCpy["oneStepScenarioChangedData"]["labelFiles"] = labelFileAr;      
            return Object.assign({},state,stateCpy);   
      } 
      case ActionTypes.TASK_INFO_SCHEDULE_CONTRACTOR_DROPOFF_UPDATE_SPECIFIC_MSG_FE_ERROR:
      {
             
          let {specificForKey,specificForKeyValue} = {...action.payload};

          let stateCpy = {...state};
          let {oneStepScenarioChangedData} = {...stateCpy};
          // from state
          let {validateFlagErrMessages,validateFlag} = {...oneStepScenarioChangedData};
                    
          if(specificForKey==="oneStepWareHouseLocationCodeErrMsg"){
            validateFlagErrMessages["oneStepWareHouseLocationCodeErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="deliveryDateErrMsg"){
            validateFlagErrMessages["deliveryDateErrMsg"] = specificForKeyValue;
          }
          else if(specificForKey==="deliveryWindowTimeSlotErrMsg"){
            validateFlagErrMessages["deliveryWindowTimeSlotErrMsg"] = specificForKeyValue;
          }
           else if(specificForKey==="labelFilesErrMsg"){
            validateFlagErrMessages["labelFilesErrMsg"] = specificForKeyValue;
          }
          
          
          stateCpy["oneStepScenarioChangedData"]["validateFlagErrMessages"] = validateFlagErrMessages;          

          return Object.assign({},state,stateCpy);  
      }
      
      
      default:
        return state;

    }

  }  