import React, { useEffect, useState } from 'react';
import { SkbLogger,SeverityLevel } from '../../../services';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";

import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';

import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SkbButton from '../../../skb_controls/components/SkbButton';
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import SkbIcon from '../../../skb_controls/components/SkbIcon'

import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import * as misc from '../../../utils/misc';
import * as returnStockUtils from '../../../utils/returnstock/misc';
import { currentTimeToUTCTimeInString, StringFormat } from '../../../utils/misc';

import * as taskActions from '../../../Redux/actions/tasksv2';
import { TaskDetailsReturnStock } from './TaskDetailsReturnStock';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import ConsignmentInfographic from './ConsignmentInfographic';
const taskConfig = require('./taskConfig.json');

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },

    taskheader: {

    },
    leftalign: {
        justifyContent: 'flex-start',
    },
    centeralign: {
        textAlign: 'center',
    },
    rightalign: {
        justifyContent: 'flex-end',
    },

    root: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },

    cardContentRoot: {
        display: "flex",
        paddingLeft:'8px',
        /* alignItems: "center" */
        '& >div.MuiCardContent-root': {
            paddingTop: 0,

        },
        
    },
    cardContentDetails: {
        flex: '1 0 auto',
    },
    completeIcon:{
        width: 'auto',
        height: 'auto',
        borderRadius: "2px",
        flexGrow: 0,
        borderStyle:'solid',
        borderColor:theme.palette.text.primary,
        color:theme.palette.success.main
    },
    cancelIcon:{
        width: 'auto',
        height: 'auto',
        borderRadius: "2px",
        flexGrow: 0,
        borderStyle:'solid',
        borderColor:theme.palette.text.primary,
        color:theme.palette.error.main
    },
    cardContentMedia: {

        width: 150,
        height: 'auto',
        /* margin: theme.spacing(1), */
        /* borderRadius: "2px", */
       /*  paddingTop: theme.spacing(2), */
        paddingBottom: theme.spacing(1),
        flexGrow: 0,
        [theme.breakpoints.down("xs")]: {
            width: 50,
            height: 50,
        }
    },
    cardContentContent: {
        flexGrow: 1,
         /* paddingTop: theme.spacing(1),  */
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),

        }
    },
    avatarIcon: {
            width: 60,
            height: 60,
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    cardExpander: {
        display: "flex",
        alignItems: "center",
        // '& button': {
        //     paddingTop: 0
        // }
    },

    headerRoot: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    taskStatus: {
        textAlign: "right",
        marginTop: theme.spacing(-3),
        //paddingRight: theme.spacing(1),
        // [theme.breakpoints.down(1279)]: {
        //     marginTop: theme.spacing(-3),
        // },
        // [theme.breakpoints.down(959)]: {
        //     marginTop: theme.spacing(-3),
        // },
        // [theme.breakpoints.down(599)]: {
        //     marginTop: theme.spacing(-3),
        // }
    },

    label: {
        display: 'inline-block',
        width: 60,
    },

    redText: {
        color: theme.palette.error.main,
    },
    contractor:{
        color:theme.palette.success.main,
        variant:'body1'
    },
    skybridge: {
        color: theme.palette.primary.main,
        variant:'body1'
    },
    done: {
        color: theme.palette.success.main,
    },
    

    subtasks: {
        margin: theme.spacing(1),
        paddingRight: theme.spacing(2),
        '& >div.MuiGrid-item': {
            borderTop: `1px solid ${theme.palette.text.primary}`,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            '&:last-child': {
                borderBottom: `1px solid ${theme.palette.text.primary}`,

            },
        },

    }
}));


function TaskCard({ Task, TaskStatus, modifyConsignmentTask, Alltasks, user,lockedBy,loadTaskStatusAndLockedBy }) {

    const [openConfirm, setOpenConfirm] = React.useState(false);
    // const [openDetailForm, setOpenDetailForm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = useState("");
    const [openInfo, setOpenInfo] = React.useState(false);
    const [confirmTitle, setConfirmTitle] = useState("");
    const [btnName,setBtnName]=useState('Start');
    const { enqueueSnackbar } = useSnackbar();
    const taskSetting = taskConfig[Task.TaskType];
    const alterWindowHandler = (toClose) => {

        setOpenInfo(toClose);
    }
    // const startTime = moment.tz(Task.StartTime, "dd/MM/yy h:mmA", "Australia/Melbourne").format("dd/MM/YY h:mmA");
    // const startTime = misc.datetimeStringInUTCToLocaldatetimeString(Task.StartTime, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)
    // const endTime = misc.datetimeStringInUTCToLocaldatetimeString(Task.EndTime, misc.StringFormat.ServerDateFormat, misc.StringFormat.TaskDateFormat)

    const history = useHistory();

    const classes = useStyles();


    const [expanded, setExpanded] = React.useState(false);  //collapsed by default.

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    var subTask=Task.SubTasks[0];
    const btnClick = async () => {

           let taskInfo = await loadTaskStatusAndLockedBy(subTask.SubTaskId)
         if(taskInfo.status != subTask.TaskStatus || (taskInfo.lockedBy && taskInfo.lockedBy>0 && user.id !== parseInt(taskInfo.lockedBy))){
             enqueueSnackbar("Task has been changed by others.  Please refresh your task list first.", {
                 variant: 'error'
             });
             return;
         }
 
         if(taskInfo.status ==="Awaiting picking"){
         var taskData = {
            TaskStatus:"Picking", 
            ModifiedBy:  user.name , 
            ModifiedDate: currentTimeToUTCTimeInString(StringFormat.TaskDateFormat), 
            NextActionBy: "Contractor"
          }
          modifyConsignmentTask(Task.TaskId,subTask.SubTaskId,taskData);
        }

         if (!navigator.onLine ) {
        /*      if (buttonText === ButtonText.Reopen) {
                 setConfirmMsg(`Please remember to finish the task before ${taskEndTime}. Are you sure you wish to reopen it?`);
                 setConfirmTitle("Reopen Task?");
                 setOkButtonIsFirst(false);

             } else { */
                 setConfirmMsg("You are currently offline. Please note that if anyone else has started picking at this consignment you may lose your work. Do you wish to proceed?");
                 setConfirmTitle("Proceed Offline?");
             //}


             setOpenConfirm(true);

             return;
         }
         else {  
            openDetail();
            
         }


    }

    
    const statusStyle = clsx({
        [classes.contractor]: subTask.NextActionBy === "Contractor",
        [classes.skybridge]: subTask.NextActionBy === "Skybridge"

    });

    SkbLogger.logDebugInfo("statusStyle", statusStyle); 

const openDetail=()=>{
    lockedBy(Task.TaskId, subTask.SubTaskId, user.id);
    setOpenInfo(true);
    if(subTask.TaskStatus ==="Awaiting picking" || subTask.TaskStatus ==="Picking"){
        history.push('/pickingAndPacking/'+Task.TaskId+'/'+subTask.SubTaskId);
    }
    if(subTask.TaskStatus ==="Awaiting labels" ||subTask.TaskStatus ==="Awaiting pickup"||subTask.TaskStatus ==="Awaiting delivery"||
    subTask.TaskStatus ==="Awaiting drop-off"){
        history.push('/labelAndSend/'+Task.TaskId+'/'+subTask.SubTaskId);
        
    }
    
}

useEffect(()=>{
    if(subTask.TaskStatus ==="Picking"){
        setBtnName('Open')
    }
},[subTask.TaskStatus])

// useEffect(() => {
//     if(TaskData.Steps && TaskData.Steps.length>0 && openDetailForm){
//      const taskSetting = taskConfig[Task.TaskType];
//      if(taskSetting.TaskDetails.Steps.length == TaskData.Steps.length) //task details page can open only the steps has compled loaded (match with the setting)
//          history.push('/taskdetails');
//     }

//      //loadTasks();
//  }, [TaskData.Steps, openDetailForm]);

/*     React.useEffect(() => {
       console.log("Current stocktake status:" + Task.TaskStatus); 
       //if the status is not changed, do not update redux status and sync storage (which can cause data overwritten) 
       if(Task.TaskStatus && Task.TaskStatus !==TaskStatus)
         var taskData = {
            TaskStatus:TaskStatus, 
            ModifiedBy:  user.name , 
            ModifiedDate: currentTimeToUTCTimeInString(StringFormat.TaskDateFormat) 
          
        }
         modifyConsignmentTask(Task.TaskId,subTask.SubTaskId,taskData); 
            //  onStatusChange(Alltasks, TaskStatus);
       //SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "updateTaskStatus", "trying to update but commented out");

    }, [TaskStatus]); */

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.headerRoot}
                action={
                    <div className={classes.cardExpander}>
                        <Typography variant="h6">ID: {subTask.SubTaskId}</Typography>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            id={`expander_${Task.TaskId}`}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                }

title=
{
                      <div>
                        <Typography variant="h6" ><SkbIcon  icon={returnStockUtils.getDeliveryMethodIcon(subTask.DeliveryMethod)} />   {subTask.ReturnType}</Typography>
                       
                      </div>
                    }


            />
            <div className={classes.cardContentRoot}>
                 <CardMedia
                    //className={classes.cardContentMedia}
                     component={()=>(<ConsignmentInfographic subTaskStatus={subTask.TaskStatus} deliveryMethod={subTask.DeliveryMethod} 
                        pickUpDueday={subTask.PickupWindowDate||
                            subTask.PickupDueday}  
                            returnDueDay={subTask.DropOffWindowDate||subTask.ReturnDueDate}/>)} 
                                       
                /> 
 
                <CardContent className={classes.cardContentContent}>

                     <Grid container> 
                    <Grid item xs={12} md={12} lg={12} className={classes.taskStatus}>
                            <Typography variant="body3" className={classes.redText}>
                                {subTask.TaskStatus}
                            </Typography>
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                            <Typography paragraph>
                                <Typography variant="h6">
                                    {taskSetting.SummaryTitle1}
                                </Typography>

                                <Typography variant="body1">
                                    {subTask.CompanyName}
                                </Typography>
                            </Typography>
                            <Typography paragraph>
                                <Typography variant="h6">
                                    {taskSetting.SummaryTitle2}
                                </Typography>

                                <Typography variant="body1">
                                    {returnStockUtils.buildAddressInOneLine(subTask.PickupAddressLine1, 
                                    subTask.PickupAddressLine2,
                                        subTask.PickupAddressSuburb,
                                        subTask.PickupAddressState,
                                        subTask.PickupAddressPostcode)}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4} style={{textAlign:'right'}}>
                        
                            {subTask.NextActionBy &&
                            <Typography paragraph>
                                <Typography variant="h6">
                                Next Action By
                                </Typography>

                                <Typography className={statusStyle}>
                                {subTask.NextActionBy}
                                </Typography>
                                </Typography>}
                                
                            
                            <Typography paragraph>
                            {subTask.NextActionBy ==="Contractor" &&
                                <SkbButton size="small" text={btnName} primary id={`btn_${Task.TaskId}`}  onClick={btnClick} />}

                            </Typography>
                           {/*  <Typography>
                            <Typography  variant="h6">
                                Next Action By
                                   
                                </Typography>
                            <Typography  className={statusStyle}>{subTask.NextActionBy}</Typography>
                            </Typography>
                            <Typography>{subTask.NextActionBy ==="Contractor" &&
                                <SkbButton size="small" text="Start" primary id={`btn_${Task.TaskId}`}  onClick={btnClick} />}</Typography>   */}
                                
                        </Grid>
                        
                        <Grid item xs={3} sm={2}>
                 

                 <SkbConfirmDialog id="confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm} okHandler={() => openDetail()} 
             okLabel="Yes" cancelLabel="No" okButtonIsFirst={true} okButtonIsHighlighted={true} okButtonIsFocussed={true}/>
             </Grid>


                     </Grid>

                </CardContent>


            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>

                <Grid container className={classes.subtasks}>
                    {Task.SubTasks.map((t, idx) => <Grid item xs={12}>
                         <TaskDetailsReturnStock key={`${Task.TaskId}_${idx}`} TaskData={t} Task={Task} User={user} /> </Grid>)}
                </Grid>
            </Collapse>
        </Card>
    );
}

//Expected props: Task, changeTask, onStatusChange, Alltasks,user
export default function TaskSummaryReturnStock(props) {
    const classes = useStyles();
    const { Task,  changeTask, modifyConsignmentTask, Alltasks,lockedBy,loadTaskStatusAndLockedBy } = props;

    var TaskStatus=Task.TaskStatus;  //calTaskStatusBySubTaskStatus(Task);
    SkbLogger.logDebugInfo('TaskSummaryReturnStock...', Task);
    
    useEffect(() => {
        //loadTasks();
    }, []);

    return (
        <Grid container className={classes.root} >
            <Grid item xs={12} data-testid="taskheader">
            </Grid>
            <Grid item xs={12} data-testid="taskcontent" onClick={() => { changeTask(Task.resourceID) }}>
                <TaskCard Task={Task} TaskStatus={TaskStatus} Alltasks={Alltasks} modifyConsignmentTask={modifyConsignmentTask} lockedBy={lockedBy}
                user={props.user} loadTaskStatusAndLockedBy={props.loadTaskStatusAndLockedBy}/>
            </Grid>
            <Grid item xs={12} data-testid="taskfooter"></Grid>
        </Grid>
    );
}

// const calTaskStatusBySubTaskStatus = (task) => {
//     SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus based on", {overallStatus:task.StocktakeSatus ,subtasks:task.SubTasks});

//     if (task.SubTasks.every(s => s.TaskStatus.Status === taskActions.Status.NotStarted)){
//         SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus result", taskActions.Status.NotStarted);
//         return taskActions.Status.NotStarted;
//     }
//     if (task.SubTasks.every(s => s.TaskStatus.Status === taskActions.Status.Done)){
//         SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus result", taskActions.Status.Done);
//         return taskActions.Status.Done;
//     }
//     SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, taskActions.ModuleInfo.category, "calTaskStatusBySubTaskStatus", "calTaskStatusBySubTaskStatus result", taskActions.Status.InProgress);
//     return taskActions.Status.InProgress;
// };

