//import React from "react";
//import * as moment from 'moment-timezone';
import _ from 'lodash';
import axios from 'axios';
import {crypts} from '../../../utils/crypts';
import { config } from '../../../utils/config';

import {ActionTypes} from "./returnsDashboardActionTypes";
import {
          getTaskConsignmentInfoService,
          getTaskConsignmentMethodService, 
          getTaskStockWarehouseService, 
          getWarehouseAddrBasedOnLocCodeService,
          getStockLocationOnhandItemsDetailService,
          taskInfoUpdateApiOneService,
          taskInfoUpdateApiTwoService,
          getLogisticCarrierTrackingBaseURLService, 
          getPickupWindowOptionsService,
          getDropOffWindowOptionsService,
          taskInfoScheduleFinishApiService,

      } from "../../../services/returnsdashboard";
import {uploadOneConsignmentLabelFileToBlob, overwriteLabelFileListForConsignment} from "../../../services/StockService";

           
import {getUpdateStatusOfSomeRowAfterStatusChange, modifyStockReturnTaskListResponseForRender,setStockReturnTaskList, setStockReturnTaskListModifiedToRender, getUniqueStatusNameFromListToRenderData, setPieChartOnStatusRelatedDataSet } from "./returnsDashboardListActions";

/**
 * Actions related to Returns Dashboard list task info functionality
 */


  /*consignment taskinfo related start */

  export const getTaskInfoPopupItemListToRender = (resp,calledFrom) => {   

    let listAr = [];
    let nonserialisedItems = [];
    let serialisedItems = [];
    let nonSerlMod = [];
    let serlMod = [];
    
    if(resp && Object.keys(resp).length>0){     

      if(calledFrom=="callAsyncFuncToFetchTaskInfo"){
         nonserialisedItems = resp.NonserialisedItems!==undefined && resp.NonserialisedItems && Array.isArray(resp.NonserialisedItems)?resp.NonserialisedItems:[];

         serialisedItems = resp.SerialisedItems!==undefined && resp.SerialisedItems && Array.isArray(resp.SerialisedItems)?resp.SerialisedItems:[];

         nonserialisedItems.length>0 && nonserialisedItems.map((e,i)=>{

                let qtyFaulty = e.QtyFaulty!==undefined?e.QtyFaulty:0;
                let qtyGood = e.QtyGood!==undefined?e.QtyGood:0;
                let qtyFaultyQtyGood = parseInt(qtyFaulty) + parseInt(qtyGood);

                let rowToPush = {};
                rowToPush["stockCategoryCode"] = e.StockCategoryCode!==undefined?e.StockCategoryCode:"";
                rowToPush["stockDescription"] = e.StockDescription!==undefined?e.StockDescription:"";
                rowToPush["clientName"] = e.ClientName!==undefined?e.ClientName:"";
                rowToPush["unitOfMeasurement"] = e.UnitOfMeasurement!==undefined?e.UnitOfMeasurement:"";
                rowToPush["qtyFaultyQtyGood"] = qtyFaultyQtyGood;
                rowToPush["qtyFaulty"] = qtyFaulty;
                rowToPush["qtyGood"] = qtyGood;
                //rowToPush["fullRawRow"] = e;

                nonSerlMod.push(rowToPush);


         });

         serialisedItems.length>0 && serialisedItems.map((e,i)=>{

                let qtyFaulty = e.QtyFaulty!==undefined?e.QtyFaulty:0;
                let qtyGood = e.QtyGood!==undefined?e.QtyGood:0;
                let qtyFaultyQtyGood = parseInt(qtyFaulty) + parseInt(qtyGood);

                let rowToPush = {};
                rowToPush["stockCategoryCode"] = e.StockCategoryCode!==undefined?e.StockCategoryCode:"";
                rowToPush["stockDescription"] = e.StockDescription!==undefined?e.StockDescription:"";
                rowToPush["clientName"] = e.ClientName!==undefined?e.ClientName:"";
                rowToPush["unitOfMeasurement"] = e.UnitOfMeasurement!==undefined?e.UnitOfMeasurement:"";
                rowToPush["qtyFaultyQtyGood"] = qtyFaultyQtyGood;
                rowToPush["qtyFaulty"] = qtyFaulty;
                rowToPush["qtyGood"] = qtyGood;
                rowToPush["fullRawRow"] = e;

                serlMod.push(rowToPush);


         });

         listAr =[...nonSerlMod,...serlMod];

      }     
      
    }

    return listAr;
  }


  export const callAsyncFuncToFetchTaskInfo =   (otherOption={}) => {
  

    return async (dispatch) =>{
     
      
            dispatch(setTaskInfoLoaderOn());      
            await getTaskConsignmentInfoService.getTaskConsignmentInfo(otherOption).then((resp)=>{   

              //console.log("******** callAsyncFuncToFetchTaskInfo--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
              if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){ 
                
                    let consignmentMethod = (resp.data) && (resp.data.ConsignmentMethod!==undefined)?resp.data.ConsignmentMethod:"";
                    let wareHouseLocationCode = (resp.data) && (resp.data.ToLocation!==undefined && resp.data.ToLocation && resp.data.ToLocation.LocationCode!==undefined)?resp.data.ToLocation.LocationCode:""; 
                
                    let getUpdatedListData =  getTaskInfoPopupItemListToRender(resp.data,"callAsyncFuncToFetchTaskInfo");
                    
                    let updatedListDataWithoutZero = getUpdatedListData.filter(oneElement => oneElement.qtyFaultyQtyGood!==0)
                    console.log('updatedListDataWithoutZero',updatedListDataWithoutZero);

                    dispatch(setTaskInfo(resp.data));
                    dispatch(setTaskItemListInit(updatedListDataWithoutZero));   

                    dispatch(setTaskInfoPopupNonListChangeableDataConsMethod(consignmentMethod));
                    dispatch(setTaskInfoPopupNonListChangeableDataWarehouseLoccode(wareHouseLocationCode));
                    dispatch(setTaskInfoPopupStructureShowFlag(true));
                    
                                       

              }
          }).catch((er)=>{

          }).finally(()=>{
              dispatch(setTaskInfoLoaderOff());
          });


    }

  }  


  export const setTaskInfo = dataObj => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW,
      payload: dataObj
    };
  };

 export const setTaskInfoPopupNonListChangeableDataConsMethod = consignmentMethhod => {
    return {
      type: ActionTypes.TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_CONSIGNMENTMETHOD,
      payload: consignmentMethhod
    };
  };


  export const setTaskInfoPopupNonListChangeableDataWarehouseLoccode = wareHsLocCd => {
    return {
      type: ActionTypes.TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_WAREHOUSELOCCODE,
      payload: wareHsLocCd
    };
  };

  export const setTaskInfoPopupNonListChangeableDataSetFlag = flagData => {
    return {
      type: ActionTypes.TASK_INFO_POPUP_NONLIST_CHANGEABLE_DATA_SET_FLAG,
      payload: flagData
    };
  };

  export const setTaskInfoPopupStructureShowFlag = (showFlagData) => {
    return {
      type: ActionTypes.TASK_INFO_POPUP_STRUCTURE_SHOW_FLAG,
      payload: showFlagData
    }
  }

 

  export const setTaskInfoLoaderOn = () => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_ON
    }
  }

  export const setTaskInfoLoaderOff = () => {

    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_LOADER_OFF

    };

  }

  export const unsetTaskInfoApiStateData = () => {
    return {
      type: ActionTypes.TASK_INFO_NONLIST_CHANGEABLEDATA_UNSET
     
    };
  };

  export const setTaskInfoApiError = message => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_SET_API_ERROR,
      payload: message
    }
  }

  export const unsetTaskInfoApiError = message => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_DETAIL_VIEW_UNSET_API_ERROR
    }
  }

  /* consignment taskinfo related end */

  /*consignment  method list related start */
 
  export const callAsyncFuncToFetchConsignmentMethod =   (otherOption={}) => {
  

    return async (dispatch) =>{     

            dispatch(setConsignmentMethodListLoaderOn());      
            await getTaskConsignmentMethodService.getTaskConsignmentMethod(otherOption).then((resp)=>{ 
                
              //console.log("******** callAsyncFuncToFetchConsignmentMethod--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
              if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
                    dispatch(setConsignmentMethodList(resp.data)); 
                                     

              }
          }).catch((er)=>{

          }).finally(()=>{
              dispatch(setConsignmentMethodListLoaderOff());
          });

    }

}  


  export const setConsignmentMethodList = list => {
    return {
      type: ActionTypes.CONSIGNMENT_METHOD_LIST_LOAD,
      payload: list
    };
  }; 

  export const setConsignmentMethodListLoaderOn = () => {
    return {
      type: ActionTypes.CONSIGNMENT_METHOD_LIST_LOAD_LOADER_ON
    }
  }

  export const setConsignmentMethodListLoaderOff = () => {

    return {
      type: ActionTypes.CONSIGNMENT_METHOD_LIST_LOAD_LOADER_OFF

    };

  }
  
  /*consignment  method list related end */

  /*warehouse  list related start */

  export const callAsyncFuncToFetchWarehouseList =   (otherOption={}) => {
  

    return async (dispatch) =>{     

            dispatch(setWarehouseListLoaderOn());      
            await getTaskStockWarehouseService.getTaskStockWarehouse(otherOption).then((resp)=>{ 
                
              //console.log("******** callAsyncFuncToFetchConsignmentMethod--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
              if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
                    dispatch(setWarehouseList(resp.data)); 
                                     

              }
          }).catch((er)=>{

          }).finally(()=>{
              dispatch(setWarehouseListLoaderOff());
          });

    }

}  

  export const setWarehouseList = list => {
    return {
      type: ActionTypes.WAREHOUSE_LIST_LOAD,
      payload: list
    };
  };


  export const setWarehouseListLoaderOn = () => {
    return {
      type: ActionTypes.WAREHOUSE_LIST_LOAD_LOADER_ON
    }
  }

  export const setWarehouseListLoaderOff = () => {

    return {
      type: ActionTypes.WAREHOUSE_LIST_LOAD_LOADER_OFF

    };

  }
  /*warehouse  list related end */

  /*warehouse  address load based on  location code start */

  export const callAsyncFuncToFetchWarehouseAddrBasedOnLocCode =   (otherOption={}) => {  

      return async (dispatch) =>{     

              dispatch(setWarehouseLocCodeBasedAddressLoaderOn());      
              await getWarehouseAddrBasedOnLocCodeService.getWarehouseAddrBasedOnLocCode(otherOption).then((resp)=>{ 
                  
                //console.log("******** callAsyncFuncToFetchWarehouseAddrBasedOnLocCode--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
                if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
                      dispatch(setWarehouseLocCodeBasedAddressObject(resp.data)); 
                      dispatch(setTaskInfoPopupNonListChangeableDataWarehouseLoccode(otherOption.paramObj.locationCode));
                                      

                }
            }).catch((er)=>{

            }).finally(()=>{
              setTimeout(()=>{
                dispatch(setWarehouseLocCodeBasedAddressLoaderOff());
              },2500);
                
            });

      }

}  


  export const setWarehouseLocCodeBasedAddressObject = obj => {
    return {
      type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE,
      payload: obj
    };
  };

  export const setWarehouseLocCodeBasedAddressLoaderOn = () => {
    return {
      type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_ON
    }
  }

  export const setWarehouseLocCodeBasedAddressLoaderOff = () => {
    return {
      type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_LOADER_OFF
    }
  }

  /*warehouse  address load based on  location code end */

  /*task items start */
  
  export const removeRowFromRecordListBasedOnaKey = (mainRecordSet, recordsTobeRemoved, keyNameToCheck) =>{

    let filteredRec = [];
    if(Array.isArray(mainRecordSet) && Array.isArray(recordsTobeRemoved) ){

      filteredRec =  mainRecordSet.filter((r)=>{
               return !(recordsTobeRemoved.find((rr)=>{
                    return (r[keyNameToCheck]==rr[keyNameToCheck]);
               })); 
      });


    }

    return filteredRec;

  }


  export const setTaskItemListInit = list => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_SET,
      payload: list
    };
  };

   export const setTaskItemListUnset = () => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UNSET
    };
  };

  export const setTaskItemUpdatedList = (list) => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_UPDATE,
      payload: list
  }
}

export const setTaskItemElementSelected = (list) => {
  return {
    type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ITEM_SELECT,
    payload: list
  }
}
  
/*task items end */

/*  task info popup related add stock screen list related starts  */



export const extractGroupedStockAddItemDataData = (dataList) =>{

     let dataListItemsGroupExtract = [];
      // For Grouped data based on StockCode
     let dataListItemsGrouped =  _.chain(dataList)    
     .groupBy("StockCode")     
     .map((value, key) => ({ stockCode: key, stockCodeItems: value })).value();
     
     dataListItemsGrouped.map((ee,ii)=>{

       let dataItemListFurtherChkData = ee.stockCodeItems.reduce((result, currentV)=>{

         result["stockCategoryCode"]= ee.stockCode;
         
         let qdQty = 0; let flQty = 0;  let stockDescription = ""; 
         let qtyFaultyQtyGood = 0;      
         if(currentV.Condition=="Good"){
           if( result["qtyGood"]===undefined && currentV.Qty){
             qdQty=currentV.Qty;
             qtyFaultyQtyGood = qtyFaultyQtyGood + qdQty;
           }
           else if( result["qtyGood"]!==undefined && currentV.Qty){
             qdQty=result["qtyGood"] + currentV.Qty;
             qtyFaultyQtyGood = qtyFaultyQtyGood + qdQty;
           }            
         }
                    
         if(currentV.Condition=="Faulty"){
           if( result["qtyFaulty"]===undefined && currentV.Qty){
             flQty=currentV.Qty;
             qtyFaultyQtyGood = qtyFaultyQtyGood + flQty;
           }
           else if( result["qtyFaulty"]!==undefined && currentV.Qty){
             flQty=result["qtyFaulty"] + currentV.Qty;
             qtyFaultyQtyGood = qtyFaultyQtyGood + flQty;
           }            
         }  
         

         result["qtyGood"]= qdQty;
         result["qtyFaulty"]= flQty;
         result["stockDescription"]= result["stockDescription"]===undefined && currentV.StockDescription?currentV.StockDescription:result["stockDescription"];
         result["unitOfMeasurement"]= result["unitOfMeasurement"]===undefined && currentV.UnitType?currentV.UnitType:result["unitOfMeasurement"];
          result["clientName"]= result["clientName"]===undefined && currentV.ClientName?currentV.ClientName:result["clientName"];
         result["qtyFaultyQtyGood"]= qtyFaultyQtyGood;               
               

         return result;

       },{});

       dataListItemsGroupExtract.push(dataItemListFurtherChkData);

     });

     return dataListItemsGroupExtract;
            
}

export const getUniqueDataFromSerializedAndNonSerialzedStockList = (dataResp, taskInfoItemListAlreadyRenderred) => {

    
    
     let nonserialisedItemsGroupExtract = [];
     let serialisedItemsGroupExtract = [];
     let addStockRows = [];     

     if(dataResp && ( Object.keys(dataResp).length)>0){
        let nonserialisedItems = dataResp.NonserialisedItems!==undefined && (Array.isArray(dataResp.NonserialisedItems))?dataResp.NonserialisedItems:[];

        let serialisedItems = dataResp.SerialisedItems!==undefined && (Array.isArray(dataResp.SerialisedItems))?dataResp.SerialisedItems:[];

        nonserialisedItemsGroupExtract = extractGroupedStockAddItemDataData(nonserialisedItems);
        serialisedItemsGroupExtract = extractGroupedStockAddItemDataData(serialisedItems);

       
        addStockRows =  ([...nonserialisedItemsGroupExtract, ...serialisedItemsGroupExtract ]);        
        
        
        // Now remove any duplicate row data if it is available  in task info popup list items
        let filtRows = addStockRows.filter((r)=>{ return !(taskInfoItemListAlreadyRenderred).find((rr)=>{ return r.stockCategoryCode===rr.stockCategoryCode})});          
       
        return filtRows;
    
      }

     return [];
}

const modifyDataStockItemtoDispListForReturnFaultyStock = (getStockItemtoDispList) => {

  const getStockItemtoDispListCpy = [...getStockItemtoDispList];

  let rettAr = [];
  
  if(getStockItemtoDispListCpy && getStockItemtoDispListCpy.length>0){   
    getStockItemtoDispListCpy.map((dta,itr)=>{
     
      dta.qtyGood = 0;
      dta.qtyFaultyQtyGood = dta.qtyGood + dta.qtyFaulty;
      rettAr.push(dta);
      
    });
    
  } 

  return rettAr;
  
}



export const callAsyncFuncToFetchTaskItemAddStockList =   (otherOption={}) => {  

  return async (dispatch, getState) =>{

      const getStateObj = getState();      
      const taskInfoItemListRendered = (getStateObj && getStateObj.returnsDashboardTaskInfo!==undefined && getStateObj.returnsDashboardTaskInfo && getStateObj.returnsDashboardTaskInfo.taskInfoItemList!==undefined && Array.isArray(getStateObj.returnsDashboardTaskInfo.taskInfoItemList))? getStateObj.returnsDashboardTaskInfo.taskInfoItemList: [];  

     const taskInfoConsignmentType =  ( getStateObj && getStateObj.returnsDashboardTaskInfo &&  getStateObj.returnsDashboardTaskInfo.taskInfo  && getStateObj.returnsDashboardTaskInfo.taskInfo.ConsignmentType)?(getStateObj.returnsDashboardTaskInfo.taskInfo.ConsignmentType):""


   
    
          dispatch(setTaskItemListAddStockItemListLoaderOn());      
          await getStockLocationOnhandItemsDetailService.getStockLocationOnhandItemsDetail(otherOption).then((resp)=>{   

            //console.log("******** callAsyncFuncToFetchTaskItemAddStockList--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
            if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){  
              
                  let getStockItemtoDispList =  getUniqueDataFromSerializedAndNonSerialzedStockList(resp.data,taskInfoItemListRendered, taskInfoConsignmentType);

                  if(taskInfoConsignmentType=="Return Faulty Stock"){
                      // modify data further to make qtyGood key data to 0 and qtyFaultyQtyGood key data updation accoordingly

                      getStockItemtoDispList = modifyDataStockItemtoDispListForReturnFaultyStock(getStockItemtoDispList)

                  }
                  dispatch(setTaskItemListAddStockItemList(resp.data)); 
                  
                  dispatch(setTaskItemListAddStockItemListToDisplay(getStockItemtoDispList));                                     

            }
        }).catch((er)=>{

        }).finally(()=>{
          dispatch(setTaskItemListAddStockItemListLoaderOff());
        });


  }

}  



export const setTaskItemListAddStockItemList = list => {
  return {
    type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD,
    payload: list
  };
};

export const setTaskItemListAddStockItemListToDisplay = list => {
  return {
    type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY,
    payload: list
  };
};


export const setTaskItemListAddStockItemListUnset = () => {
  return {
    type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_API_DATA_UNSET
  };
};

export const setTaskItemListAddStockItemListLoaderOn = () => {
  return {
    type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_ON  }
}

export const setTaskItemListAddStockItemListLoaderOff = () => {
  return {
    type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LOAD_LOADER_OFF
  }
}



export const setTaskItemListAddStockItemListElementSelected = (list) => {
  return {
    type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_SELECTED,
    payload: list
  }
  }
 
export const updateTaskItemListAddStockItemList = (list) => {
    return {
      type: ActionTypes.TASK_LOCATION_ONHAND_STOCK_DETAIL_LIST_TO_DISPLAY_UPDATE,
      payload: list
    }
    }

export const setTaskItemListWihItemsSelectedFromAddStockItemList = (list) => {
return {
  type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_REL_ITEM_LIST_ADD_ITEMSELECTEDFROMADDSTOCK,
  payload: list
}
}



/*  task info popup related add stock screen list related ends  */


/* task info update by api one starts */

export const callAsyncFuncToForTaskInfoUpdateWithApiOne =   (otherOption={}) => {
  

  return async (dispatch) =>{   
    
          dispatch(setTaskInfoLoaderOn());      
          await taskInfoUpdateApiOneService.taskInfoUpdateApiOne(otherOption).then((resp)=>{   

            //console.log("******** callAsyncFuncToFetchTaskInfo--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
            if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){    
              
              let msgToDisp = `Successfully updated ${otherOption.forMsgDisp.taskId} for ${otherOption.forMsgDisp.contractorCompanyName}`;
                  
                  dispatch(setTaskInfoUpdateApiOneSuccess(msgToDisp));                        
                  // unset api one success state
                  dispatch(setTaskInfoUpdateApiOneSuccessUnset());

            }
        }).catch(err=>{          
          let errStatusCodeAr = [400,500]; // allowed error status code
          let erRespData = err.response.data;
          let erRespStatus =  err.response.status;
          //console.log(" erRespStatus=>>>",erRespStatus, errStatusCodeAr.indexOf(erRespStatus));
          if(errStatusCodeAr.indexOf(erRespStatus) !== -1){   
            dispatch(setTaskInfoUpdateApiOneError("Failed to save."));
            dispatch(setTaskInfoUpdateApiOneErrorUnset());

        }     
    
        }).finally(()=>{
            dispatch(setTaskInfoLoaderOff());
            
        });


  }

}

export const setTaskInfoUpdateApiOneSuccess = (successMsg) => {
  return {
    type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS,
    payload: successMsg
  }
  }

export const setTaskInfoUpdateApiOneSuccessUnset = () => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_SUCCESS_UNSET,
     
      }
  }

export const setTaskInfoUpdateApiOneError = (errMsg) => {
  return {
    type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR,
    payload: errMsg
  }
  }

export const setTaskInfoUpdateApiOneErrorUnset = () => {
    return {
      type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_ONE_UPDATE_ERROR_UNSET,
     
      }
  }

  /* task info update by api one ends */
  

/* task info update by api two starts */

export const callAsyncFuncToForTaskInfoUpdateWithApiTwo =   (otherOption={}) => {
  

  return async (dispatch) =>{   
    
          dispatch(setTaskInfoLoaderOn());      
          await taskInfoUpdateApiTwoService.taskInfoUpdateApiTwo(otherOption).then((resp)=>{   

            //console.log("******** callAsyncFuncToFetchTaskInfo--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
            if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){               
                  
                  let msgToDisp = `Successfully updated ${otherOption.forMsgDisp.taskId} for ${otherOption.forMsgDisp.contractorCompanyName}`;
                  dispatch(setTaskInfoUpdateApiTwoSuccess(msgToDisp));                        
                  // unset api one success state
                  dispatch(setTaskInfoUpdateApiTwoSuccessUnset());

            }
        }).catch(err=>{          
          let errStatusCodeAr = [400,500]; // allowed error status code
          let erRespData = err.response.data;
          let erRespStatus =  err.response.status;
          //console.log(" erRespStatus=>>>",erRespStatus, errStatusCodeAr.indexOf(erRespStatus));
          if(errStatusCodeAr.indexOf(erRespStatus) !== -1){   
            dispatch(setTaskInfoUpdateApiTwoError("Failed to save."));
            dispatch(setTaskInfoUpdateApiTwoErrorUnset());

        }     
    
        }).finally(()=>{
            dispatch(setTaskInfoLoaderOff());
            
        });


  }

}

export const setTaskInfoUpdateApiTwoSuccess = (successMsg) => {
return {
  type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS,
  payload: successMsg
}
}

export const setTaskInfoUpdateApiTwoSuccessUnset = () => {
  return {
    type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_SUCCESS_UNSET,
   
    }
}

export const setTaskInfoUpdateApiTwoError = (errMsg) => {
return {
  type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR,
  payload: errMsg
}
}

export const setTaskInfoUpdateApiTwoErrorUnset = () => {
  return {
    type: ActionTypes.CONTRACTOR_STOCK_RETURN_TASK_INFO_API_TWO_UPDATE_ERROR_UNSET,
   
    }
}

/* task info update by api two ends */

/* logistic carrier api relatte starts  */


export const callAsyncFuncToFetchLogisticCarrierList =   (otherOption={}) => {
  

  return async (dispatch) =>{   

    

        dispatch(setLogisticCarrierListLoaderOn());      
        await getLogisticCarrierTrackingBaseURLService.getLogisticCarrierTrackingBaseURL().then((resp)=>{ 
              
            //console.log("******** 
            if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
                  dispatch(setLogisticCarrierList(resp.data)); 

            }
        }).catch((er)=>{

        }).finally(()=>{
          dispatch(setLogisticCarrierListLoaderOff());
            
        });

   
  }

}  

export const setLogisticCarrierList = list => {
  return {
    type: ActionTypes.LOGISTIC_CARRIER_LIST_LOAD,
    payload: list
  };
};


export const setLogisticCarrierListUnset = () => {
  return {
    type: ActionTypes.LOGISTIC_CARRIER_LIST_LOAD_UNSET,    
  };
};


export const setLogisticCarrierListLoaderOn = () => {
  return {
    type: ActionTypes.LOGISTIC_CARRIER_LIST_LOAD_LOADER_ON
  }
}

export const setLogisticCarrierListLoaderOff = () => {

  return {
    type: ActionTypes.LOGISTIC_CARRIER_LIST_LOAD_LOADER_OFF

  };

}

/* logistic carrier api relatte ends  */



/* pickup window time api related starts  */
export const callAsyncFuncToFetchPickupWindowTimeList =   (otherOption={}) => {
  

  return async (dispatch) =>{   
    dispatch(setPickupWindowTimeListLoaderOn());      
    await getPickupWindowOptionsService.getPickupWindowOptions().then((resp)=>{ 
          
        //console.log("******** 
        if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
              dispatch(setPickupWindowTimeList(resp.data)); 

        }
    }).catch((er)=>{

    }).finally(()=>{
      dispatch(setPickupWindowTimeListLoaderOff());
        
    });
   
  }

}  

export const setPickupWindowTimeList = list => {
  return {
    type: ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD,
    payload: list
  };
};

export const setPickupWindowTimeListUnset = () => {
  return {
    type: ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD_UNSET,    
  };
};

export const setDropOffWindowTimeListUnset = () => ({
  type : ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD_UNSET,
})


export const setPickupWindowTimeListLoaderOn = () => {
  return {
    type: ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_ON
  }
}

export const setPickupWindowTimeListLoaderOff = () => {

  return {
    type: ActionTypes.PICKUP_WINDOW_TIME_LIST_LOAD_LOADER_OFF

  };

}





/* pickup window time api related starts  */

// DROP OFF WINDOW TIMESLOT RELATED ACTIONS
export const callAsyncFuncToFetchDropOffWindowTimeList =   (otherOption={}) => {
  

  return async (dispatch) =>{   
    dispatch(setDropOffWindowTimeListLoaderOn());      
    await getDropOffWindowOptionsService.getDropOffWindowOptions().then((resp)=>{ 
          
        //console.log("******** 
        if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
              dispatch(setDropOffWindowTimeList(resp.data)); 

        }
    }).catch((er)=>{

    }).finally(()=>{
      dispatch(setDropOffWindowTimeListLoaderOff());
        
    });
   
  }

}
export const setDropOffWindowTimeList = list => {
  return {
    type: ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD,
    payload: list
  };
};


export const setDropOffWindowTimeListLoaderOn = () => {
  return {
    type: ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_ON
  }
}

export const setDropOffWindowTimeListLoaderOff = () => {

  return {
    type: ActionTypes.DROPOFF_WINDOW_TIME_LIST_LOAD_LOADER_OFF,
  };

}

/* Before schedule popup opens update status scenario for Awaiting labels status starts*/

export const updateTaskInfoStateDataAndGetLatestState =  (taskInfoStateData, keyName, keyValueToChangeWith, otherData={}) =>{
  if(keyName==="CompanySubTask"){
    let copyOfTask = {...taskInfoStateData};    
    let {CompanySubTask}  = {...copyOfTask}; 
        CompanySubTask["Status"] = keyValueToChangeWith;
    copyOfTask["CompanySubTask"]  = CompanySubTask;
    return copyOfTask;

  }

  return null;


}

//need to be removed
// export const callAsyncFuncToUpdateToAwaitingScheduleFromAwaitingLabels =   (otherOption={}) => {
  
//   return async (dispatch,getState) =>{   

//     const getStateObj = getState();  

//     const taskInfoStateData = (getStateObj && getStateObj.returnsDashboardTaskInfo!==undefined && getStateObj.returnsDashboardTaskInfo && getStateObj.returnsDashboardTaskInfo.taskInfo!==undefined)? getStateObj.returnsDashboardTaskInfo.taskInfo: {};  

//     const returnsList = (getStateObj && getStateObj.returnsDashboardList!==undefined && getStateObj.returnsDashboardList && getStateObj.returnsDashboardList.returnsList!==undefined && Array.isArray(getStateObj.returnsDashboardList.returnsList))? getStateObj.returnsDashboardList.returnsList: [];    

//     const returnStockSlaDays = (getStateObj && getStateObj.returnStockSlaDays!==undefined && getStateObj.returnStockSlaDays && getStateObj.returnStockSlaDays.slaDaysList!==undefined && Array.isArray(getStateObj.returnStockSlaDays.slaDaysList))? getStateObj.returnStockSlaDays.slaDaysList: [];
    
//     dispatch(setTaskInfoLoaderOn()); 
//     await taskInfoScheduleFinishApiService.taskInfoScheduleFinish(otherOption).then((resp)=>{   

//           if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){    

//             // Now update state data of key taskInfo  of  returnsDashboardTaskInfo
//             let updatedTaskInfoData  = updateTaskInfoStateDataAndGetLatestState(taskInfoStateData,"CompanySubTask", "Awaiting schedule");
            
//             if(updatedTaskInfoData){
//               // set data of taskInfo state key of returnsDashboardTaskInfo 
//               dispatch(setTaskInfo(updatedTaskInfoData));
//             }
        
//             let msgToDisp = "Success";
            
//             dispatch(setAwaitingScheduleFromAwaitingLabelsSuccess(msgToDisp));                        
//             // unset api one success state
//             dispatch(setAwaitingScheduleFromAwaitingLabelsSuccessUnset());

            


//             // Now update the return dashboard list data
//             let locCodeArObj = otherOption.bodyParam.Consignments;
//             let getUpdatedListData =  getUpdateStatusOfSomeRowAfterStatusChange(returnsList,locCodeArObj, "Awaiting schedule");
//             let getUpdatedListDataToDisp =  modifyStockReturnTaskListResponseForRender(getUpdatedListData,returnStockSlaDays);
            
//             dispatch(setStockReturnTaskList(getUpdatedListData));
//             dispatch(setStockReturnTaskListModifiedToRender(getUpdatedListDataToDisp)); 

//             const pieStatusReldataObj = getUniqueStatusNameFromListToRenderData(getUpdatedListDataToDisp);
//             dispatch(setPieChartOnStatusRelatedDataSet(pieStatusReldataObj)); 

//       }
//   }).catch(err=>{          
//     let errStatusCodeAr = [400,500]; // allowed error status code
//     //let erRespData = err.response.data;
//     console.log("-----err----=>",err);
//     let erRespStatus =  err.response!==undefined && err.response &&  err.response.status!==undefined?err.response.status:"";
//     //console.log(" erRespStatus=>>>",erRespStatus, errStatusCodeAr.indexOf(erRespStatus));
//     if(errStatusCodeAr.indexOf(erRespStatus) !== -1){   
//       dispatch(setAwaitingScheduleFromAwaitingLabelsApiError("Failed to update."));
//       dispatch(setAwaitingScheduleFromAwaitingLabelsApiErrorUnset());

//   }     

//   }).finally(()=>{
//     dispatch(setTaskInfoLoaderOff()); 
//   });


//   }

// } 


// export const setAwaitingScheduleFromAwaitingLabelsSuccess = (successMsg) => {
//   return {
//     type: ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS,
//     payload: successMsg
//   }
//   }

// export const setAwaitingScheduleFromAwaitingLabelsSuccessUnset = () => {
//     return {
//       type: ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_SUCCESS_UNSET,
     
//       }
//   }

// export const setAwaitingScheduleFromAwaitingLabelsApiError = (errMsg) => {
//   return {
//     type: ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR,
//     payload: errMsg
//   }
//   }

// export const setAwaitingScheduleFromAwaitingLabelsApiErrorUnset = () => {
//     return {
//       type: ActionTypes.TASK_INFO_STATUS_TO_AWAITING_SCHEDULE_FROM_AWAITING_LABELS_API_ERROR_UNSET,
     
//       }
//   }

/* Before schedule popup opens update status scenario for Awaiting labels status ends*/



/* schedule finish button related api starts  */


export const callAsyncFuncToUpdateForScheduleFinish =   (otherOption={}) => {
  
    return async (dispatch,getState) =>{   

      const getStateObj = getState();  

      const returnsList = (getStateObj && getStateObj.returnsDashboardList!==undefined && getStateObj.returnsDashboardList && getStateObj.returnsDashboardList.returnsList!==undefined && Array.isArray(getStateObj.returnsDashboardList.returnsList))? getStateObj.returnsDashboardList.returnsList: [];    

      const returnStockSlaDays = (getStateObj && getStateObj.returnStockSlaDays!==undefined && getStateObj.returnStockSlaDays && getStateObj.returnStockSlaDays.slaDaysList!==undefined && Array.isArray(getStateObj.returnStockSlaDays.slaDaysList))? getStateObj.returnStockSlaDays.slaDaysList: [];
      
      dispatch(setTaskInfoScheduleFlowLoaderFlag(true));
      await taskInfoScheduleFinishApiService.taskInfoScheduleFinish(otherOption).then((resp)=>{   

        //console.log("******** callAsyncFuncToFetchTaskInfo--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
        if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){    
          
          let msgToDisp = otherOption.successMsgParam.message;//`Successfully scheduled!!`;
              
              dispatch(setScheduleFinisSuccess(msgToDisp));                        
              // unset api one success state
              dispatch(setScheduleFinishSuccessUnset());

              // Now update the return dashboard list data
              let locCodeArObj = otherOption.bodyParam.Consignments;
              let getUpdatedListData =  getUpdateStatusOfSomeRowAfterStatusChange(returnsList,locCodeArObj, "Awaiting labels");
              let getUpdatedListDataToDisp =  modifyStockReturnTaskListResponseForRender(getUpdatedListData,returnStockSlaDays);
              
              dispatch(setStockReturnTaskList(getUpdatedListData));
              dispatch(setStockReturnTaskListModifiedToRender(getUpdatedListDataToDisp)); 

              const pieStatusReldataObj = getUniqueStatusNameFromListToRenderData(getUpdatedListDataToDisp);
              dispatch(setPieChartOnStatusRelatedDataSet(pieStatusReldataObj)); 

        }
    }).catch(err=>{          
      let errStatusCodeAr = [400,500]; // allowed error status code
      //let erRespData = err.response.data;
      console.log("-----err----=>",err);
      let erRespStatus =  err.response!==undefined && err.response &&  err.response.status!==undefined?err.response.status:"";
      //console.log(" erRespStatus=>>>",erRespStatus, errStatusCodeAr.indexOf(erRespStatus));
      if(errStatusCodeAr.indexOf(erRespStatus) !== -1){   
        dispatch(setScheduleFinishError("Failed to schedule."));
        dispatch(setScheduleFinishErrorUnset());

    }     

    }).finally(()=>{
      dispatch(setTaskInfoScheduleFlowLoaderFlag(false));
        
    });


  }

}  

export const setScheduleFinisSuccess = (successMsg) => {
  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_SUCCESS,
    payload: successMsg
  }
  }

export const setScheduleFinishSuccessUnset = () => {
    return {
      type: ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_SUCCESS_UNSET,
     
      }
  }

export const setScheduleFinishError = (errMsg) => {
  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_ERROR_SET,
    payload: errMsg
  }
  }

export const setScheduleFinishErrorUnset = () => {
    return {
      type: ActionTypes.TASK_INFO_SCHEDULE_FINISH_API_ERROR_UNSET,
     
      }
  }


  export const callAsyncFuncToUpdateForScheduleFinishContractorDropOff =   (otherOption={}) => {
  
    return async (dispatch,getState) =>{   

      const getStateObj = getState();  

      const returnsList = (getStateObj && getStateObj.returnsDashboardList!==undefined && getStateObj.returnsDashboardList && getStateObj.returnsDashboardList.returnsList!==undefined && Array.isArray(getStateObj.returnsDashboardList.returnsList))? getStateObj.returnsDashboardList.returnsList: [];    

      const returnStockSlaDays = (getStateObj && getStateObj.returnStockSlaDays!==undefined && getStateObj.returnStockSlaDays && getStateObj.returnStockSlaDays.slaDaysList!==undefined && Array.isArray(getStateObj.returnStockSlaDays.slaDaysList))? getStateObj.returnStockSlaDays.slaDaysList: [];
      
      dispatch(setTaskInfoScheduleFlowLoaderFlag(true));
      await taskInfoScheduleFinishApiService.taskInfoScheduleFinish(otherOption).then((resp)=>{   

        //console.log("******** callAsyncFuncToFetchTaskInfo--------------FETCHED==>>>>>","--- status=>>", resp.status, " data=>>>",resp.data);
        if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){    
          
          let msgToDisp = otherOption.successMsgParam.message;//`Successfully scheduled!!`;
            
              
              dispatch(setScheduleFinisSuccess(msgToDisp));                        
              // unset api one success state
              dispatch(setScheduleFinishSuccessUnset());

              // Now update the return dashboard list data
              let locCodeArObj = otherOption.bodyParam.Consignments;
              let getUpdatedListData =  getUpdateStatusOfSomeRowAfterStatusChange(returnsList,locCodeArObj, "Awaiting labels");
              let getUpdatedListDataToDisp =  modifyStockReturnTaskListResponseForRender(getUpdatedListData,returnStockSlaDays);
              
              dispatch(setStockReturnTaskList(getUpdatedListData));
              dispatch(setStockReturnTaskListModifiedToRender(getUpdatedListDataToDisp)); 

              
              const pieStatusReldataObj = getUniqueStatusNameFromListToRenderData(getUpdatedListDataToDisp);
              dispatch(setPieChartOnStatusRelatedDataSet(pieStatusReldataObj)); 

        }
    }).catch(err=>{          
      let errStatusCodeAr = [400,500]; // allowed error status code
      //let erRespData = err.response.data;
      console.log("-----err----=>",err);
      let erRespStatus =  err.response!==undefined && err.response &&  err.response.status!==undefined?err.response.status:"";
      //console.log(" erRespStatus=>>>",erRespStatus, errStatusCodeAr.indexOf(erRespStatus));
      if(errStatusCodeAr.indexOf(erRespStatus) !== -1){   
        dispatch(setScheduleFinishError("Failed to schedule."));
        dispatch(setScheduleFinishErrorUnset());

    }     

    }).finally(()=>{
      dispatch(setTaskInfoScheduleFlowLoaderFlag(false));
        
    });


  }

} 

/* schedule finish button related api ends  */


/*warehouse  address load based on  location code from  task info schedule TWO  stepper condition start */

export const callAsyncFuncToFetchWarehouseAddrBasedOnLocCodeTIPSTwoSteps =   (otherOption={}) => {  

  return async (dispatch) =>{     

          dispatch(setWarehouseAddrBasedOnLocCodeTIPSTwoStepsLoaderOn());      
          await getWarehouseAddrBasedOnLocCodeService.getWarehouseAddrBasedOnLocCode(otherOption).then((resp)=>{ 
              
            //console.log("******** 
            if(resp && resp.status!==undefined && ([200]).indexOf(resp.status)>-1){                    
                  dispatch(setWarehouseLocCodeBasedAddressObjectTIPSTwoSteps(resp.data));
                  dispatch(setTaskInfoSchedulePopupTwoStepRelStepOneWarehouseLocChange(otherOption.paramObj.locationCode));
                  
                                  

            }
        }).catch((er)=>{

        }).finally(()=>{
          setTimeout(()=>{
            dispatch(setWarehouseAddrBasedOnLocCodeTIPSTwoStepsLoaderOff());
          },2500);
            
        });

  }

}  

export const setWarehouseLocCodeBasedAddressObjectTIPSTwoSteps = obj => {
return {
  type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS,
  payload: obj
};
};

export const setWarehouseAddrBasedOnLocCodeTIPSTwoStepsLoaderOn = () => {
return {
  type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_ON
}
}

export const setWarehouseAddrBasedOnLocCodeTIPSTwoStepsLoaderOff = () => {
return {
  type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_TWOSTEPS_LOADER_OFF
}
}

export const setWarehouseAddrBasedOnLocCodeTIPSTwoStepsUnset = () => {
return {
  type: ActionTypes.WAREHOUSE_ADDR_LOAD_BASED_ON_LOC_CODE_TIPSP_API_DATA_UNSET
}
}


/*warehouse  address load based on  location code from  task info schedule TWO  stepper condition ends */



/* task info schedule popup two step related step one change data starts */  

export const setTaskInfoSchedulePopupTwoStepRelStepOneWarehouseLocChange = (warehouseLocCode) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_WAREHOUSELOCATIONCODE,
    payload: warehouseLocCode
  }

}


export const setTaskInfoSchedulePopupTwoStepRelStepOneAtpChange = (atp) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_ATP,
    payload: atp
  }

}

export const setTaskInfoSchedulePopupTwoStepRelStepOneForkliftreq = (forkliftreq) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_FORKLIFTREQUIRED,
    payload: forkliftreq
  }

}

export const setTaskInfoSchedulePopupTwoStepRelStepTwoPickupwindowDate = (dateData) => {
    return {
        type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWDATE,
        payload: dateData
    }
}

export const setTaskInfoSchedulePopupTwoStepRelStepTwoPickupwindowTimeslot = (timeslotData) => {
    return {
        type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_PICKUPWINDOWTIMESLOT,
        payload: timeslotData
    }
}

export const setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierName = (carrierName) => {
    return {
        type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME,
        payload: carrierName
    }
}

export const setTaskInfoSchedulePopupTwoStepRelStepTwoCarrierNameUrl = (carrierNameUrl) => {
    return {
        type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CARRIERNAME_URL,
        payload: carrierNameUrl
    }
}

export const setTaskInfoSchedulePopupTwoStepRelStepTwocannote = (cannoteData) => {
    return {
        type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CANNOTE,
        payload: cannoteData
    }
}

export const setTaskInfoSchedulePopupDataChangeActivityByUser = (flagData) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_POPUP_DATA_CHANGE_ACTIVITY_BY_USER,
    payload: flagData
  }

}

export const setTaskInfoSchedulePopupTwoStepRelStepTwoLabel= (labelObj) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPLOADED_LABEL_SET,
    payload: labelObj
  }

}



export const setTaskInfoScheduleFlowLoaderFlag= (flagdata) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_GENERIC_LOADERFLAG,
    payload: flagdata
  }

}

export const setTaskInfoSchedulePopupTwoStepStepOneDataChangeableDataSetFlag= (flagData) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_CHANGEABLEDATA_SET_FLAG,
    payload: flagData
  }

}

export const setTaskInfoSchedulePopupTwoStepStepOneValidateFlag= (flagData) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG,
    payload: flagData
  }

}
export const setTaskInfoSchedulePopupTwoStepStepOneToUpdateSpecificFeErMsg= (flagDataWithErrMsgObj) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_UPDATE_SPECIFIC_MSG_FE_ERROR,
    payload: flagDataWithErrMsgObj
  }

}


export const setTaskInfoSchedulePopupTwoStepStepOneValidateFlagWithFeErMsg= (flagDataWithErrMsgObj) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPONE_VALIDATE_FLAG_AND_FE_ERROR,
    payload: flagDataWithErrMsgObj
  }

}

export const setTaskInfoSchedulePopupTwoStepStepTwoValidateFlagWithFeErMsg= (flagDataWithErrMsgObj) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG_AND_FE_ERROR,
    payload: flagDataWithErrMsgObj
  }

}

export const setTaskInfoSchedulePopupTwoStepStepTwoToUpdateSpecificFeErMsg= (dataTuUpdSpeciErKeyMsg) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_UPDATE_SPECIFIC_MSG_FE_ERROR,
    payload: dataTuUpdSpeciErKeyMsg
  }

}

export const setTaskInfoSchedulePopupTwoStepStepTwoValidateFlag= (flagData) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_VALIDATE_FLAG,
    payload: flagData
  }

}



export const setTaskInfoSchedulePopupDataChangeableFullUnset= () =>{

  return {
    type: ActionTypes.TASK_INFO_POPUP_SCHEDULE_CHANGEABLE_DATA_FULL_UNSET
  }

}


// TASK INFO SHEDULE POPUP CONTRACTOR DROP OFF RELATED ACTIONS

export const setTaskInfoSchedulePopupContractorDropOffToUpdateSpecificFeErMsg= (flagDataWithErrMsgObj) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_CONTRACTOR_DROPOFF_UPDATE_SPECIFIC_MSG_FE_ERROR,
    payload: flagDataWithErrMsgObj
  }

}

export const setTaskInfoScheduleContractorDropOffValidateFlagWithFeErMsg = (flagDataWithErrMsgObj) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_CONTRDROPOFF_SCENARIO_VALIDATE_FLAG_AND_FE_ERROR,
    payload: flagDataWithErrMsgObj
  }

}



export const setTaskInfoSchedulePopupContractor__pallets = pallets =>({
  type : ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_PALLETS,
  payload : pallets,
});

export const setTaskInfoSchedulePopupContractor__cartons = cartons =>({
  type : ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__SET_CARTONS,
  payload : cartons,
});

export const setTaskInfoSchedulePopupContractor__deliveryDate = date => ({
  type : ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_DATE,
  payload : date,
});

export const setTaskInfoSchedulePopupContractor__deliveryTimeslot = time => ({
  type : ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__DELIVERY_WINDOW_TIMESLOT,
  payload : time,
});

export const setTaskInfoSchedulePopupContractor__warehouseLocationCode = warehouseLocationCode => ({
  type : ActionTypes.TASK_INFO_SCHEDULE_POPUP_CONTRACTOR_DROP_OFF__WAREHOUSE_LOCATIONCODE,
  payload : warehouseLocationCode,
})

const fetchConsignmentInfoStart = () =>({
  type : ActionTypes.FETCH_CONSIGNMENT_DETAILS_START,
});
const fetchConsignmentInfoSuccess = consignmentInfo =>({
  type : ActionTypes.FETCH_CONSIGNMENT_DETAILS_SUCCESS,
  payload : consignmentInfo,
});
const fetchConsignmentInfoError = error =>({
  type : ActionTypes.FETCH_CONSIGNMENT_DETAILS_ERROR,
  payload : error,
});
export const fetchConsignmentInfo = consignmentLocationCode =>{
  return dispatch => {
    dispatch(fetchConsignmentInfoStart());
    const apiEndPoint = `${process.env.REACT_APP_API_URL}stock/consignment/${consignmentLocationCode}`;
    const requestHeaders = {
      "content-type" : "application/json",
      "authorization" : config.REACT_APP_API_SECRET,
      "X-CustomHeader": crypts.decrypt(localStorage.getItem('id_token')),
    }
    axios.get(apiEndPoint,requestHeaders)
          .then(({data})=>dispatch(fetchConsignmentInfoSuccess(data)))
          .catch(error=>dispatch(fetchConsignmentInfoError(error)));
  }
}

export const taskInfoPopupContractorOptionDataChanged = ()=>({
  type : ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_CHANGED,
});
export const taskINfoPopupContractorOptionDataReset = () =>({
  type : ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_DATA_RESET,
})

export const taskInfoPopupContractorOptionLoadingStart = ()=>({
  type : ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_START,
})
export const taskInfoPopupContractorOptionLoadingEnd = ()=>({
  type : ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_LOADING_END,
})
export const taskInfoPopupContractorOptionSetUploadedLabels= (labelObj) =>{

  return {
    type: ActionTypes.TASK_INFO_POPUP_CONTRACTOR_OPTION_SET_UPLOADED_LABELS,
    payload: labelObj
  }

}
/* task info schedule popup two step related step two change data starts */ 

export const setTaskInfoSchedulePopupDataChangeableDataSetFlag= (flagData) =>{

  return {
    type: ActionTypes.TASK_INFO_SCHEDULE_TWOSTEP_STEPTWO_CHANGEABLEDATA_SET_FLAG,
    payload: flagData
  }

}
/* task info schedule popup two step related step two change data ends */ 
