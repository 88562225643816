import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SignatureCanvas from 'react-signature-canvas'
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';

import SkbButton from './SkbButton'


const useStyles = makeStyles((theme) => ({
    padRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    padContainer: {
        backgroundColor: theme.palette.background.secondary,
        width: '100%',
        height: 100,
        maxWidth: 400,


    },
    sigPad: {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.background.default}`,
    },
    padControl: {
        marginLeft: theme.spacing(1), 
        display: 'flex',
        flexDirection: 'column',
        "&:first-child":{
            marginBottom:  theme.spacing(0.5),
        }
    },
    padControltwobutton:{
        display: 'flex',
        flexDirection: 'column',
        "&:first-child":{
            marginBottom:  theme.spacing(0.5),
        }
    }
}));

/**
 * @name SkbSignature a signature component using html canvas
 * @param {*} sig existing sig
 * @param {*} saveCallback a callback function to save new captured signature
 */
export default function SkbSignature({ sig, saveCallback }) {
    const classes = useStyles();
    const [sigPad, setSigPad] = useState({});
    const [sigData, setSigData] = useState(null);
    const [enableSave, setEnableSave] = useState(false);
    const saveSig = saveCallback || (() => { }) //if savecallback is not set, do nothing when click saving button
    const theme = useTheme();
    const saveClick = () => {
        let data = sigPad.getCanvas()
        .toDataURL('image/png');
        setSigData(data); // update component state, an img will show the signature
        saveSig(data); //call callback if it has been set
    }
    const resignClick = () => {//resign button click handler
        
        setEnableSave(false); //disable the save button
        setSigData(null);// clear sign pad
        saveSig(null);
    }
    useEffect(() => {
        
        setSigData(sig);
    }, [sig]); //monitor signature from store state, and show to the signature pad
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <div className={classes.padRoot}>
                    <div className={classes.padContainer}>
                        {sigData? <img className={classes.sigPad}
          src={sigData} />:
                        <SignatureCanvas canvasProps={{ className: classes.sigPad }} onEnd={()=>{setEnableSave(true);}}
                            ref={(ref) => { setSigPad(ref) }}/>}

                    </div>
                    <div className={classes.padControl}>
                        {sigData? (<SkbButton style={{marginBottom:4}} primary text={"Re-sign"} id={"btn_sign_sign"} onClick={resignClick} />): (<div className={classes.padControltwobutton}>
                            <SkbButton style={{marginBottom:4}} primary text={"Clear"} id={"btn_sign_clear"} onClick={() => sigPad.clear()} />
                        <SkbButton primary text={"Save"} disabled={!enableSave} id={"btn_sign_save"} onClick={() => saveClick()} />
                        </div>)}
                        
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>



            </Grid>
        </Grid>
    )
}