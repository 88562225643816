import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
    Grid,
    Typography,
    Link,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    DatePicker,
    TimePicker,
    DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TableContainer from '@material-ui/core/TableContainer';

// importing skb components
import SkbButton from '../../../../skb_controls/components/SkbButton';
import SkbTable from '../../../../skb_controls/components/SkbTable';
import { SkbSwitch } from '../../../../skb_controls/components/SkbSwitch';
import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';
import Loading from '../../../Loading';
import SkbTextField from '../../../../skb_controls/components/SkbTextField';



import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


var _isProblemDevice;
function isProblemDevice() {
    //take account of performance, only check once
    if (_isProblemDevice !== undefined) return _isProblemDevice;

    const toMatch = [
        /iPhone OS 12/i
    ];

    return toMatch.some((toMatchItem) => {
        _isProblemDevice = navigator.userAgent.match(toMatchItem);
        return _isProblemDevice;
    });
}

var demoOptions_multiple = { selectedIdValues: ['TheCode2'] };
var demoOptions_single = { selectedIdValues: ['TheCode5'] };

const styles = makeStyles({
    root: {
        //width: 'calc(100% - 4px)!important',
    },
    heading: {
        margin: '0 auto',
    },
    content: {
        
        // margin: '1em auto',

        // padding: '1em',
    },
    packageId: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
});



const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
    },
    formControl: {
        margin: theme.spacing(3),
    },
    radioGroup: {
        flexDirection: 'row',
    },
    atp:{
        textDecoration: "underline",
        color: '#13bbff',
    }
}));


const AddNewPackage = (props) => {

    const {
        addPackagePopUp,
        openAddPackagePopUp,
        addPackage,
        updatePackage
        

    } = props;

    let packageForEdit=props.packageForEdit;
    
//     if (packageForEdit===undefined)
//     {
//    //    alert('packageForEdit is undefined!');
//     }
//     else
//     {
//         alert(packageForEdit.rowId);
//     }
    
    // if (!packageForEdit)
    // {
    //     //i.e. adding a new empty package:
    //     packageForEdit = {
    //         packageType: "Pallet", 
    //         quantity: 0,
    //         length: 0, 
    //         width: 0, 
    //         height: 0, 
    //         weight: 0
    //     };
    //     alert('setting packageForEdit!')
    // }


    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();
    const [packageType, setPackageType] = useState("Pallet");
    const [quantity, setQuantity] = useState(0);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [rowId, setRowId] = useState(-1);


    const [mode, setMode] = useState('add');

    // const [packageType, setPackageType] = useState(packageForEdit.packageType);
    // const [quantity, setQuantity] = useState(packageForEdit.quantity);
    // const [length, setLength] = useState(packageForEdit.length);
    // const [width, setWidth] = useState(packageForEdit.width);
    // const [height, setHeight] = useState(packageForEdit.height);
    // const [weight, setWeight] = useState(packageForEdit.weight);

    const submitNewPackage = () => {
        if ((packageType.toLocaleLowerCase()==="pallet") && (!quantity || !length || !width || !height)) {
            enqueueSnackbar("Error adding "+packageType.toLocaleLowerCase()+" - missing values in some or all of the fields.", {
                variant: "error"
            });
        }
        else if ((packageType.toLocaleLowerCase()==="carton") && (!quantity || !length || !width || !height||!weight)) {
            enqueueSnackbar("Error adding "+packageType.toLocaleLowerCase()+" - missing values in some or all of the fields.", {
                variant: "error"
            });
        }
         else {
            if (mode==='add')
            {
                addPackage(
                    {
                        RowId:-1,
                        Type: packageType,
                        Qty: quantity,
                        Length: length,
                        Width: width,
                        Height: height,
                        Weight: weight,
                    }
                );

                enqueueSnackbar("Successfully added "+ packageType+"(s).", {
                    variant: "success"
                });
            }
            else
            {
                updatePackage(
                    {
                        RowId: rowId,
                        Type: packageType,
                        Qty: quantity,
                        Length: length,
                        Width: width,
                        Height: height,
                        Weight: weight,
                    }
                );

                enqueueSnackbar("Successfully updated "+ packageType+"(s).", {
                    variant: "success"
                });

            }
            openAddPackagePopUp(false);
        }
    }

 const getNumberLabel=(packageType)=>{
     return "Number of "+packageType.toLocaleLowerCase()+"s"; 
 }

 const getPopupTitle=(mode)=>{
     if (mode==='update')
     {
        return 'Update Package';
     }
     else
    {
         return 'Add New Package';
     }
     
}

    useEffect(() => {
        if (!addPackagePopUp) { //reset states

            setPackageType("Pallet");
            setQuantity(0);
            setLength(0);
            setWidth(0);
            setHeight(0);
            setWeight(0);
           
        }
        else
        {
            if (packageForEdit) {
                setRowId(packageForEdit.rowId);
                setPackageType(packageForEdit.packageType);
                setQuantity(packageForEdit.quantity);
                setLength(packageForEdit.length);
                setWidth(packageForEdit.width);
                setHeight(packageForEdit.height);
                setWeight(packageForEdit.weight);
                setMode('update');
            }
            else
            {
                setMode('add');
            }
        }
    }, [addPackagePopUp]);
    return (
        <Dialog aria-labelledby="customised-dialog-title" open={addPackagePopUp} maxWidth="sm" fullWidth>
            <MuiDialogContent>
                <Grid container direction="column"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexFlow: 'column',
                    }}

                >
                    <Grid item>

                        <Typography variant="h6">{getPopupTitle(mode)}</Typography>
                    </Grid>
                    <Grid direction="column">
                        <Grid direction="row">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Choose Package Type</FormLabel>
                                <RadioGroup id="primary-radio-group" aria-label="gender" className={classes.radioGroup} name="gender1" value={packageType} onChange={e => setPackageType(e.target.value)}>
                                    <FormControlLabel value="Pallet" control={<Radio id="option-pallets" color="primary" />} label="Pallet" />
                                    <FormControlLabel value="Carton" control={<Radio id="option-cartons" color="primary" />} label="Carton" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "space-between",
                    }}>
     
                        <Grid item>
                            <SkbTextField id="package-number" label={getNumberLabel(packageType)}
                                type="number"
                                value={quantity}
                                onChange={e => setQuantity(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "space-between",
                    }}>
                        <Grid item>
                   
                            <SkbTextField id="package-length"  label="L(cm)"
                                type="number"
                                value={length}
                                onChange={e => setLength(e.target.value)}
                                style={{ width: '6em' }}
                            />
                        </Grid>
                        <Grid item>
                         
                            <SkbTextField id="package-height" label="H(cm)"
                                type="number"
                                value={height}
                                onChange={e => setHeight(e.target.value)}
                                style={{ width: '6em' }}
                            />
                        </Grid>
                        <Grid item>
                           
                            <SkbTextField id="package-width" label="W(cm)"
                                type="number"
                                value={width}
                                onChange={e => setWidth(e.target.value)}
                                style={{ width: '6em' }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item style={{ margin: '0 auto' }}>
                            
                            <SkbTextField id="package-weight" label="Weight(kg)"
                                type="number"
                                value={weight}
                                onChange={e => setWeight(e.target.value)}

                            />
                        </Grid>
                    </Grid>
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions>
               
                <SkbButton id="back-button" text={"Back"} primary outlined onClick={e => openAddPackagePopUp(false)} />
                <SkbButton id="add-button" text={mode} primary onClick={submitNewPackage} />
            </MuiDialogActions>
        </Dialog>
    );
}


const PickupDetails = (props) => {
    const {
        eta,
        setEta,
        pickupDate,
        setPickupDate,
        ATP,
        setAtp,
        forkLiftRequired,
        setForkLiftRequired,
        pickupDetails,
        updatePickupDetails,

    } = props;

    const classes = useStyles();

    const pickupWindowOptions=JSON.parse(localStorage.getItem('REUSABLE-SETTINGS-PickupWindowOptions'));

    return (
        <Grid
            container
            direction="column"
            style={{
                marginTop: '1em',
                marginBottom: '0.5em',
            }}
        >
            <Grid item>
                <Typography variant="h6">Pickup Details</Typography>
            </Grid>
            <Grid
                item
                style={{
                    border: '1px solid lightgray',
                    padding: '1em'
                }}
            >

                <Grid container justify="space-between" alignContent="center">
                    <Grid item>
                        <Tooltip title="I authorise the courier to pickup in my absence." enterTouchDelay={100} arrow={true} placement={"top-start"} >
                            <Typography variant="body1" className={classes.atp} >ATP</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <SkbSwitch
                            val={ATP}
                            setVal={value => {
                                setAtp(value);
                                updatePickupDetails({
                                    IsATPAuthorised: value,
                                    IsForkliftRequired: forkLiftRequired,
                                    PickupWindow: {
                                        Date: pickupDate,
                                        ETA: eta,
                                    }
                                })
                            }}
                            primary
                        />
                    </Grid>
                </Grid>
                <Grid container justify="space-between" alignContent="center">
                    <Grid item>
                        <Typography variant="body1">Forklift required for pickup</Typography>
                    </Grid>
                    <Grid item>
                        <SkbSwitch
                            val={forkLiftRequired}
                            setVal={value => {
                                setForkLiftRequired(value);
                                updatePickupDetails({
                                    IsATPAuthorised: ATP,
                                    IsForkliftRequired: value,
                                    PickupWindow: {
                                        Date: pickupDate,
                                        ETA: eta,
                                    }
                                })
                            }}
                            primary
                        />
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="body1">
                            Pickup window
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Grid item style={{marginTop:'4px'}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    value={pickupDate}
                                    onChange={date => {
                                        setPickupDate(date)
                                        updatePickupDetails({
                                            IsATPAuthorised: ATP,
                                            IsForkliftRequired: forkLiftRequired,
                                            PickupWindow: {
                                                Date: date,
                                                ETA: eta,
                                            }
                                        })
                                    }}
                                    placeholder="22/10/2020"
                                    leftArrowIcon={<ArrowBackIosIcon className={classes.root} />}

                                    rightArrowIcon={<ArrowForwardIosIcon className={classes.root} />}
                                    keyboardIcon={<EventIcon className={classes.root} />}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        'id': 'date-picker-inline-changedatebtn'
                                    }}
                                    leftArrowButtonProps={
                                        {
                                            'id': 'date-picker-inline-leftbtn'
                                        }
                                    }
                                    rightArrowButtonProps={
                                        {
                                            'id': 'date-picker-inline-righbtn'
                                        }
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <SkbSelect
                                value={eta}
                                items= {pickupWindowOptions}//{['7am - 12pm','12pm - 3pm']}
                                valueChanged={value => {
                                    setEta(value);
                                    updatePickupDetails({
                                        IsATPAuthorised: ATP,
                                        IsForkliftRequired: forkLiftRequired,
                                        PickupWindow: {
                                            Date: pickupDate,
                                            ETA: value,
                                        }
                                    })

                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>




        </Grid>
    );
}

const SkybridgeCourierOption = (props) => {
    const {
        root,
        heading,
        content,
        packageId,

    } = styles();
    const {
        isLoading,
        addPackage,
        updatePackage,
        deletePackage,
        updatePickupDetails,
        packageDetails,
        pickupDetails,
        defaultPickupDate,

    } = props;

    console.log("PIckup Details : ", pickupDetails);

    const defaulDialogOpen = false;
    const defaulDialogMessage = 'OK';
    const [dialogOpen, setDialogOpen] = React.useState(defaulDialogOpen);
    const [dialogMessage, setDialogMessage] = React.useState(defaulDialogMessage);

    const { enqueueSnackbar } = useSnackbar();

    const [singleOption, setSingleOption] = useState(demoOptions_single);
    const [multiOption, setMultiOption] = useState(demoOptions_multiple)


    const [addPackagePopUp, openAddPackagePopUp] = useState(false);
    const [pallets, setPallets] = useState(0);
    const [cartons, setCartons] = useState(0);

    const [packageForEdit, setPackageForEdit]= useState(undefined);


    const [forkLiftRequired, setForkLiftRequired] = useState((pickupDetails && pickupDetails.IsForkliftRequired) || false);
    const [ATP, setAtp] = useState((pickupDetails && pickupDetails.IsATPAuthorised) || false);

    const [eta, setEta] = useState((pickupDetails && pickupDetails.PickupWindow.ETA) || 0);
    const [pickupDate, setPickupDate] = useState((pickupDetails && new Date(pickupDetails.PickupWindow.Date || defaultPickupDate)));

    useEffect(() => {
        if (pickupDetails) {
            setPickupDate((pickupDetails && new Date(pickupDetails.PickupWindow.Date|| defaultPickupDate)));
            setEta((pickupDetails && pickupDetails.PickupWindow.ETA));
            setForkLiftRequired((pickupDetails && pickupDetails.IsForkliftRequired) || false);
            setAtp((pickupDetails && pickupDetails.IsATPAuthorised) || false);
        }
    }, [pickupDetails])
    const submit = e => {
        if (!eta || !pickupDate) {
            enqueueSnackbar("Error submitting packaging details:  Please enter the package details of the cartons and/or pallets you have packed and specify a date and time.", {
                variant: "error"
            });
        } else {
            updatePickupDetails({
                IsATPAuthorised: ATP,
                IsForkliftRequired: forkLiftRequired,
                PickupWindow: {
                    Date: pickupDate,
                    ETA: eta,
                }
            })
        }
    }

    // const handleSave = (e, row) => {
    //     setDialogMessage('You did' + e.type + ' Save on ' + row.col1);
    //     setDialogOpen(true);
    // };

    const handleEdit = (e, row) => {


        setPackageForEdit(   {
            rowId:row.RowId,
            packageType: row.Type, 
            quantity:  row.Qty,
            length: row.Length, 
            width: row.Width, 
            height: row.Height, 
            weight: row.Weight
        });



        openAddPackagePopUp(true);

    };

    const handleDelete = (e, row) => {

        deletePackage(row);

    };

    const handleAdd= (e)=>{
        setPackageForEdit(undefined);
        openAddPackagePopUp(true);

    }

    // const handleSelect = (rows, currentRow) => {
    //     let opr = 'being selected';
    //     if (!currentRow.tableData.checked) opr = 'being de-selected';
    //     setDialogMessage('You selected ' + rows.length + ' rows. Currently ' + opr + ': ' + currentRow.col1 + '.');
    //     setDialogOpen(true);
    //     //alert('You selected '+rows.length+' rows. Currently '+opr+': '+currentRow.col1+'.');

    //     //saving the selections and pass them in for next round
    //     if (currentRow.tableData && currentRow.tableData.checked) {
    //         //selecting
    //         demoOptions_multiple.selectedIdValues.push(currentRow.col1);
    //     } else {
    //         //de-selecting
    //         demoOptions_multiple.selectedIdValues.pop(currentRow.col1);
    //     }
    //     console.log('demoOptions_multiple', demoOptions_multiple);
    //     setMultiOption(demoOptions_multiple);

    // };

    // const handleChange = (e, row, value) => {
    //     setDialogMessage('You did ' + e.type + ' value ' + value + ' in ' + row.col1);
    //     setDialogOpen(true);
    // };

    // const handleLink = (e, id) => {
    //     setDialogMessage('You did ' + e.type + ' link on ' + id);
    //     setDialogOpen(true);
    // };

    const demoColumns_link = [
        { title: 'Hidden Col', field: 'RowId', type: 'hidden-id' },

        { title: 'Package', field: 'Qty', type: 'custom', 
        
        render: rowData => (
            (rowData.Qty === "1" ?
                (<span>
                    {rowData.Qty} {rowData.Type} </span>)
                :
                (<span>
                    {rowData.Qty} {rowData.Type}s </span>)
            ))
      
    },
        
        { title: 'L x W x H(cm)', field: 'Height', type: 'custom', 
        
        render: rowData => (
            <span>
{rowData.Length} x {rowData.Width} x {rowData.Height}</span>

          )
      
    },
        { title: 'Weight(kg)', field: 'Weight', type: 'read-only-text' },
    ];


    //   data from packageDetails to table format
    const tableData = [];
     packageDetails.forEach(i => {
        tableData.push({
            RowId: i.RowId,
            Type: i.Type,
            Qty: i.Qty,
            Length: i.Length,
            Width: i.Width,
            Height: i.Height,
            Weight: i.Weight,
        })
    }) 




    const demoActions_select = [
        { type: 'edit', onClick: handleEdit },
        { type: 'delete', onClick: handleDelete }
    ];


    const demoFilter_simple = { visible: false };


    return (
        <Grid container className={root} direction="column">

            <Grid className={content} direction="column" container>

                <Grid
                    container
                    direction="row"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',


                    }}

                >

                    <Grid item>
                        <SkbButton primary size="medium" text={"+ Package"} onClick={e => handleAdd(e)} />
                    </Grid>
                </Grid>
                <div>
                    <TableContainer
                        style={{ width: '100%' }}
                    >
                        <SkbTable actions={demoActions_select}
                            columns={demoColumns_link}
                            dataSource={tableData}
                            filter={demoFilter_simple}
                            options={multiOption}

                        />
                    </TableContainer>
                </div>
                <PickupDetails
                    isLoading={isLoading}
                    eta={eta}
                    setEta={setEta}
                    pickupDate={pickupDate}
                    setPickupDate={setPickupDate}
                    ATP={ATP}
                    setAtp={setAtp}
                    forkLiftRequired={forkLiftRequired}
                    setForkLiftRequired={setForkLiftRequired}
                    pickupDetails={pickupDetails}
                    updatePickupDetails={updatePickupDetails}

                />
                {/* <Grid 
                    container 
                    justify="flex-end"
                    style={{
                        
                    }}    
                >
                    <Grid item>
                        <SkbButton secondary size="medium" text={" < Back"} />
                    </Grid>
                    <Grid item>
                        <SkbButton disabled size="medium" text={"Next > "} />
                    </Grid>
                    <Grid item>
                        <SkbButton primary size="medium" text={"Submit"} onClick={submit} />
                    </Grid>
                </Grid> */}
            </Grid>
            {isLoading && <Loading />}

            <AddNewPackage
                addPackagePopUp={addPackagePopUp}
                openAddPackagePopUp={openAddPackagePopUp}
                addPackage={addPackage}
                updatePackage={updatePackage}
                packageForEdit={packageForEdit}
            />


        </Grid>
    )
}

export default SkybridgeCourierOption;