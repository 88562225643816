import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import SkbButton from './SkbButton';
import SkbConfirmDialog from './SkbConfirmDialog';

//tooltip
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Zoom from '@material-ui/core/Zoom';

//snackbark
import { useSnackbar } from 'notistack';
import { set } from 'lodash';

var _isProblemDevice_;
function isProblemDevice() {
    //take account of performance, only check once
    if (_isProblemDevice_ !== undefined) return _isProblemDevice_;

    const toMatch = [
        /iPhone OS 12/i
    ];

    return toMatch.some((toMatchItem) => {
        _isProblemDevice_ = navigator.userAgent.match(toMatchItem);
        return _isProblemDevice_;
    });
}

export function getCompletedStepByIncompletedStepName(completed, steps, incompleteStepNames) {
    if (!Array.isArray(incompleteStepNames)) return;

    const currentCompletedSteps = [...completed];
    const updatedCompletedSteps = currentCompletedSteps.filter((stepId) => {
        var stepName = steps[stepId].StepName;
        return !incompleteStepNames.includes(stepName);
    });

    return updatedCompletedSteps;
}

const useStyles = makeStyles((theme) => ({
    root: {

        marginTop: theme.spacing(0),
        '& >div.MuiGrid-item:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.text.disabled}`,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            // '&:last-child':{
            //     borderBottom: `1px solid ${theme.palette.text.primary}`,

            // },
        },
    },

    stepper: {
        backgroundColor: 'inherit',
        paddingTop: theme.spacing(1),
        overflowY: "hidden",
        //paddingBottom: 0,
    },
    stepperbuttons: {
        textAlign: 'right',
        borderBottom: `1px solid ${theme.palette.text.disabled}`,
        paddingBottom: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    headerId: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        textDecoration: 'underline',
        cursor: "default",
        color: theme.palette.primary.main,
        // fontSize:'0.8em',

    },
    headerFirstRow: {
        "&>div.MuiGrid-item:last-child": {
            textAlign: 'right',
        }
    },

    stepContent: {

        overflow: "overlay",
        paddingBottom: "80px!important",
        [theme.breakpoints.up('md')]: {
            maxHeight: `calc(100vh - 350px)`,
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: `calc(100vh - 350px)`,

            // },
            // [theme.breakpoints.down('xs')]: {
            //     maxHeight: `calc(100vh - 400px)`,
            //     maxWidth:  `calc(100vw - 400px)`
        },
        "& > div": {
            padding: '4% 2% 4% 2%',
            [theme.breakpoints.down('md')]: {
                width: 'calc(100vw - 16px)',
                margin: theme.spacing(0),

            },
            [theme.breakpoints.up('md')]: {
                width: 'calc(100vw - 208px)',
                marginBottom: theme.spacing(2),
            },


            [theme.breakpoints.down('xs')]: {
                width: 'calc(100vw - 8px)',
                marginBottom: theme.spacing(0),
            },


            [theme.breakpoints.down(376)]:  //376px wide or less
                isProblemDevice() ? {      //and one of the known problem devices
                    width: 'calc(90vw - 8px)'   //Add a gap at the side to allow for scrolling.
                }
                    : {},


        }
    },
    stepFooter: {
        position: 'fixed',
        bottom: 30,
        right: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 192px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 16px)',
        }
    },

    stepLabel: {
        '& span.MuiStepLabel-alternativeLabel': {
            marginTop: 0,
        }
    },
    title: {
        textAlign: "center",
    },
    safari: {
        height: '-webkit-fill-available',
    }

}));

const StepHeader = ({ Step, StepId, WizardInfo }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <Grid container>
            <Grid item md={12} sm={12} xs={12}>
                <Grid container className={classes.headerFirstRow}>
                    <Grid item md={10} sm={9} xs={9}>
                        <Typography variant="h6" className={classes.instructions}>Step {StepId}: {Step.Title}</Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={3}>
                        {WizardInfo && <ClickAwayListener onClickAway={handleTooltipClose} id="pulldowntorefresh">
                            <div>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    arrow={false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={WizardInfo.Description}
                                    TransitionComponent={Zoom}
                                    placement="bottom"
                                >

                                    <Typography variant="h6" onClick={handleTooltipOpen} className={classes.headerId}>{WizardInfo.DisplayText}</Typography>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                        }

                        <>
                            {WizardInfo && WizardInfo.SubTaskStatusContent}
                        </>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Typography variant="body1" className={classes.instructions}>{Step.Description}</Typography>

            </Grid>
        </Grid>

    );
}

const StepContent = ({ Step }) => {

    var stepComponent = Step.Content;


    return (
        <>
            {stepComponent}
        </>
    );

}
/**
 * SkbStepper
 * @param {Array} Steps: Mandatory fields, array of step:
 *        {
            Title: "Contractor",
            StepName: "Contractor",
            Description: "Please select the contractor(s) who is required to return stock.",
            Content: <step1Content />,
            Completed: false,
            StepValidator: ():{isValid:bool, validationResult:{message:string, title:string, display:'dialog'|'toast',
        severity:'warning'|'error'}}=>{}
        }
 * @param {string|component} Title: optional  it shows on the top 
 * @param {function} stepCompletedCallback: optional, called when stepper step switch to another. 
 *  singature: (step, isCompleted, toConfirm), isCompleted is optional, step is the current step, toConfirm is optional, when it's true, the confirm button is hit, the stepper isCompleted is used to mark the step is completed or not, if it's the last step,the stepCompletedCallback is responsible to close the step wizard
 * @param {object} WizardInfo: optional,  the information shows on right top of screen, it includes:
 *             DisplayText: string|component, the display text show on screen
 *             Description: tooltip prompt when the mouse is hovering on the display text
 * @param {string} NextButtonNameAtLastStep: optional,  default is "Confirm"
 * @param {string} Mode: NonLinear|Linear, optional, if not provided or neither "NonLinear" or "Linear", treat as "Linear"
 * @param {number} stepToActive: the step id to navigate to, which allow step navigation from outside 
 * @param {Array} incompletedStepNames : steps are incomplete, which allows HOC change complete status of a step, it's optional , i.e. ["Serialised", "Preview"]
 * @returns 
 */
export default function SkbStepper(props) {

    const { Steps, stepCompletedCallback, Title, WizardInfo, NextButtonNameAtLastStep, Mode, stepToActive, incompletedStepNames } = props;
    const classes = useStyles();

    const isModeNonLinear = (Mode || "").toLowerCase() === "nonlinear";
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    //For validation dialog box:
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = useState("");
    const [confirmTitle, setConfirmTitle] = useState("");

    const { enqueueSnackbar } = useSnackbar();
    const initCompletedStep = () => {
        //debugger;
        if (Steps.some(i => !Object.keys(i).includes("Completed"))) return; // not all steps contain "Completed" prop, do nothing.
        const hasCompleted = new Set();
        Steps.forEach((s, idx) => {

            if (s.Completed)
                //markCompletedStep(s.StepId);
                hasCompleted.add(idx);
        });

        setCompleted(hasCompleted);
    }

    const updateCompletedSteps = (incompleteSteps) => {
        if (!Array.isArray(incompleteSteps)) return;

        // const currentCompletedSteps = [...completed];
        // const updatedCompletedSteps = currentCompletedSteps.filter((stepId)=>{
        //     var stepName = Steps[stepId].StepName;
        //     return !incompleteSteps.includes(stepName);
        // });
        const updatedCompletedSteps = getCompletedStepByIncompletedStepName(completed, Steps, incompleteSteps);
        setCompleted(new Set(updatedCompletedSteps));
    }

    const totalSteps = () => {
        return Steps.length;
    };
    const completedSteps = () => {
        return completed.size;
    };

    const isCurrentStepComplete = () => {
        return Steps[activeStep].Completed;
    }

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const displayToastMessageIfCurrentStepNotCompleted = (noToastMessage) => {
        if (noToastMessage) return;
        if (!isCurrentStepComplete()) {
            enqueueSnackbar(`Please remember to complete the ${Steps[activeStep].StepName} step.`, {
                variant: "warning"
            });
        }

    }

    const stepCompletedHandler = typeof (stepCompletedCallback) === 'function' ? stepCompletedCallback : () => { };
    //Next button click event handler
    const handleNext = (e, noToastMessage) => {

        //debugger;
        e.stopPropagation();
        e.preventDefault();
        try {
            if (isLastStep()) {//last step, call confirmHanler()
                if (!isModeNonLinear)
                    confirmHandler();
                return;
            }
            //displayToastMessageIfCurrentStepNotCompleted(noToastMessage);
            if (!isModeNonLinear && !validateCurrrentStep()) return; //step validation is failed, DO NOT navigate next step
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed
                    // find the first step that has not been completed
                    Steps.findIndex((step, i) => !completed.has(i))
                    : activeStep + 1;


            if (isModeNonLinear) {
                setActiveStep(newActiveStep);
                return;
            }
            stepCompletedHandler(Steps[activeStep], true);

            setActiveStep(newActiveStep);


        } catch (error) {


            console.log("SkbStepper->handleNext()", error);
            enqueueSnackbar(error.toString(), {
                variant: "error"
            });
        }
    };
    const completeHandler = (e, noToastMessage) => {

        //debugger;
        e.stopPropagation();
        e.preventDefault();
        try {
            if (isLastStep()) {//last step, call confirmHanler()
                confirmHandler();
                return;
            }
            //displayToastMessageIfCurrentStepNotCompleted(noToastMessage);
            if (!validateCurrrentStep()) return; //step validation is failed, DO NOT navigate next step
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed
                    // find the first step that has not been completed
                    Steps.findIndex((step, i) => !completed.has(i))
                    : activeStep + 1;


            //            stepCompletedHandler(Steps[activeStep], true);

            setActiveStep(newActiveStep);


        } catch (error) {


            console.log("SkbStepper->handleNext()", error);
            enqueueSnackbar(error.toString(), {
                variant: "error"
            });
        }
    };

    const handleBack = () => {
        try {
            if (!isModeNonLinear)
                stepCompletedHandler(Steps[activeStep === 0 ? activeStep : activeStep - 1]);


            //displayToastMessageIfCurrentStepNotCompleted(false);

            setActiveStep(activeStep === 0 ? 0 : activeStep - 1);
        } catch (error) {
            console.log("SkbStepper->handleBack()", error);
            enqueueSnackbar(error, {
                variant: "error"
            });
        }
    };

    //step click event handler
    const handleStep = (step) => {

        //console.log("handelStep:", activeStep, step);

        try {
            if (activeStep === step) return;
            if (isModeNonLinear) {
                setActiveStep(step);
                return;
            }
            //debugger;
            if (step > activeStep && !validatePreviousStep(step)) return;// validating previous step when navigating to a step. i.e current is step 1, and validation is passed, user is trying to go to step 3, it's needs to pass step 2 validation

            if ((step - activeStep) > 1 && !validateCurrrentStep()) return; //do not validate if either navigate previous step or the step is just next step (validatePreviousStep has already done the validation), 
            //displayToastMessageIfCurrentStepNotCompleted(false);

            setActiveStep(step);

            if (step > activeStep) stepCompletedHandler(Steps[step]); //do not call the completeedhandler if naviage to previous step
        } catch (error) {
            console.log("SkbStepper->handleStep()", error);
            enqueueSnackbar(error, {
                variant: "error"
            });
        }
    };

    //Complete Step button click event handler
    const handleComplete = () => {
        //debugger;
        // setCanUpdateTaskStatus(true);
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);

        //the step has completed, update step status
        stepCompletedHandler(Steps[activeStep], true, isLastStep() && isModeNonLinear);

        enqueueSnackbar(`${Steps[activeStep].StepName} step has been completed.`, {
            variant: "success"
        });

        setCompleted(newCompleted);


        // if (newCompleted.size !== totalSteps()) {
        //     handleNext(null, true);
        // }

    };
    const handleCompleteFromConfirmWindow = () => {
        handleComplete();
        if (isModeNonLinear) {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed
                    // find the first step that has not been completed
                    Steps.findIndex((step, i) => !completed.has(i))
                    : activeStep + 1;


            if (isModeNonLinear) {
                setActiveStep(newActiveStep);
                return;
            }
        }
    }
    //check whether a step has been completed or not
    function isStepComplete(step) {
        return completed.has(step);
    }

    const validateStep = (stepIndex) => {
        var step = Steps[stepIndex];
        if (typeof (step.StepValidator) === 'function') {
            let result = step.StepValidator();
            if (!result) throw "Invalid step validator, the valiator MUST return type '{isValid:bool, validationResult:{display:dialog|toast, title:string, message:string, severity:warning|error}}'";
            if (result.isValid) {
                if (stepIndex === activeStep) handleComplete(); // if the step is current step, call handleComplete
                return true;
            }
            else {
                var validationResult = result.validationResult;
                //It is expected that there will later be 'toast' display types, and 'error' severity messages to handle.  
                if (validationResult.display === 'dialog')  //&& validationResult.severity==='warning'
                {
                    setConfirmMsg(validationResult.message);
                    setConfirmTitle(validationResult.title);
                    setOpenConfirm(true);
                    //At the moment it isn't passing onSuccessfulValidation to the dialogue box.  It probably should.  
                } else {//toast message
                    enqueueSnackbar(validationResult.message, {
                        variant: validationResult.severity || "error"
                    });
                }

                if (validationResult.severity === 'warning' && validationResult.display !== 'dialog') {
                    if (stepIndex === activeStep) handleComplete(); // if the step is current step, call handleComplete
                    return true;
                }
                return false;
            }
        } else {
            if (stepIndex === activeStep) handleComplete(); // if the step is current step, call handleComplete
            return true;
        }


    }
    const validateCurrrentStep = () => {
        return validateStep(activeStep);
    }

    const validatePreviousStep = (toStep) => {
        if (toStep === 0) return true; // current step is first step, there is no previous step

        return validateStep(toStep - 1);
    }

    //the handler of clicking "Confirm" button (in last the next, the "Next" button is change to "Confirm")
    const confirmHandler = () => {
        try {

            if (validateCurrrentStep()) {
                stepCompletedHandler(Steps[activeStep], true, true);//it's the last step, the stepChangeHandler perhaps needs to close the steps ward
            };

        } catch (error) {
            console.log("SkbStepper->clickComplete()", error);
            enqueueSnackbar(error, {
                variant: "error"
            });
        }
    };

    useEffect(() => {
        initCompletedStep();

    }, [Steps]);

    useEffect(() => {
        if (stepToActive >= 0 && stepToActive < Steps.length) {
            handleStep(stepToActive);
        }
    }, [stepToActive]);

    useEffect(() => {
        if (Array.isArray(incompletedStepNames)){
            updateCompletedSteps(incompletedStepNames);
        }
    }, [incompletedStepNames]);

    return (

        <div>
            <Grid container className={classes.root}>
                {Title && <Grid item xs={12} id="stepper_title" className={classes.title}>
                    <Typography variant="h5">{Title}</Typography>
                </Grid>
                }
                <Grid item xs={12} className={classes.safari}>
                    <Grid container className={classes.root}>


                        <Grid item md={12} sm={12} xs={12} id="stepper_header">
                            {/* <StepHeader StepConfig={stepConfig} ActiveStep={activeStep} CurrentTask={CurrentTask} CurrentSubtask={CurrentSubtask} /> */}
                            <StepHeader Step={Steps[activeStep]} StepId={activeStep + 1} WizardInfo={WizardInfo} />
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} id="stepper_content" className={classes.stepContent}>
                            {/* <Typography className={classes.instructions}> show step view here</Typography> */}
                            <StepContent Step={Steps[activeStep]} />

                        </Grid>
                        <Grid item md={12} sm={12} xs={12} id="stepper_footer" className={classes.stepFooter}>
                            <Grid container>
                                <Grid item md={12} sm={12} xs={12} id="stepper_buttons" className={classes.stepperbuttons}>
                                    <SkbButton primary size="small" id="btn_back" onClick={handleBack} className={classes.button} text={"Back"}
                                        disabled={activeStep === 0} />

                                    <SkbButton
                                        primary
                                        onClick={handleNext}
                                        size="small"
                                        className={classes.button}
                                        disabled={isLastStep() && isModeNonLinear}
                                        text={(!isLastStep() || isModeNonLinear) ? "Next" : (NextButtonNameAtLastStep || "Confirm")}
                                        id="btn_next"
                                    />
                                    {isModeNonLinear && activeStep !== Steps.length &&
                                        (completed.has(activeStep) ? (
                                            <Typography variant="caption" className={classes.completed}>
                                                {Steps[activeStep].StepName} already completed
                                            </Typography>
                                        ) : (
                                            <SkbButton primary size="small" id="btn_complete" onClick={completeHandler} text={completedSteps() === totalSteps() - 1 ? 'Submit' : 'Complete Step'} />

                                        ))}

                                </Grid>
                                <Grid item md={12} sm={12} xs={12} id="stepper">
                                    <Stepper alternativeLabel nonLinear activeStep={activeStep} className={classes.stepper}>
                                        {(Steps || []).map((step, index) => {

                                            return (
                                                <Step key={step.StepName} classes={{ alternativeLabel: classes.stepLabel }}>
                                                    <StepButton
                                                        id={`step_${index}`}
                                                        onClick={()=>handleStep(index)}
                                                        completed={isStepComplete(index)}
                                                    >
                                                        {step.StepName}
                                                    </StepButton>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SkbConfirmDialog id="confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm} okHandler={() => handleCompleteFromConfirmWindow()}
                okLabel="Yes" cancelLabel="No" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />

        </div>

    );
}