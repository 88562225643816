import Dexie from 'dexie';
import { SeverityLevel,SkbLogger } from '../services';
import {crypts} from './crypts';
var indexedDB = require("fake-indexeddb");
var IDBKeyRange = require("fake-indexeddb/lib/FDBKeyRange");

var db; 

//leave options as default, it's only for mock during unit testing
export function initForUser(auth0UserID){
    const userSuffix=auth0UserID.replace('|','');
    if(auth0UserID.startsWith('dummy')){
        //mock DOM environment for indexedDB
        db= new Dexie("MinervaLocal_"+userSuffix,{indexedDB: indexedDB, IDBKeyRange: IDBKeyRange});
    }else{
        db= new Dexie("MinervaLocal_"+userSuffix);
    }
    
    db.version(1).stores({
        //resourceID is the primary key
        heartbeat: 'timestamp,sound',
        localPhoto: 'resourceID,clusterResourceID,key,arrayIndex,description,value,artefactType,timestamp',
        dataCluster: 'resourceID,name,scope,referenceEntity,referenceID,timestamp,parentResourceID,childResourceIDsForCheck,version,operation',
        dataItem: 'resourceID,clusterResourceID,type,key,arrayIndex,description,value,localArtefactContent,artefactType,timestamp,version,operation'
    });
}

export async function loadRecentHeartbeats(minutes){
    SkbLogger.logDebugInfo('loadHeartbeats',minutes);
    const startTimestamp = new Date().getTime() - minutes*60*1000; //last m minutes
    var resultHeartbeats = await db.heartbeat.where('timestamp').above(startTimestamp).toArray();
    SkbLogger.logDebugInfo('loadHeartbeats result ',resultHeartbeats);

    return resultHeartbeats;
}

//////////////dataCluster//////////////////

function encryptDataCluster(plainCluster){
    //encrypting the name and referenceEntity
    SkbLogger.logDebugInfo('encrypting datacluster ',plainCluster);
    var encryptedCluster={...plainCluster}; //clone
    if(plainCluster.name){
        const encryptedName=crypts.encrypt(plainCluster.name);
        if(!encryptedName){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataCluster.name) failed to be encrypted.'
                                        ,{dataObject:plainCluster});
        }
        encryptedCluster.name=encryptedName;
    }
    if(plainCluster.scope){
        const encryptedScope=crypts.encrypt(plainCluster.scope);
        if(!encryptedScope){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataCluster.scope) failed to be encrypted.'
                                        ,{dataObject:plainCluster});
        }
        encryptedCluster.scope=encryptedScope;
    }
    if(plainCluster.referenceEntity){
        const encryptedEntity=crypts.encrypt(plainCluster.referenceEntity);
        if(!encryptedEntity){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataCluster.referenceEntity) failed to be encrypted.'
                                        ,{dataObject:plainCluster});
        }
        encryptedCluster.referenceEntity=encryptedEntity;
    }
    SkbLogger.logDebugInfo('dataCluster encrypting result ',encryptedCluster);
    return encryptedCluster;

}

function decryptDataCluster(encryptedCluster){
    if(!encryptedCluster) return;
    //decrypting the name and referenceEntity
    //SkbLogger.logDebugInfo('decrypting datacluster ',encryptedCluster);
    var decryptedCluster={...encryptedCluster}; //clone
    if(encryptedCluster.name){
        const decryptedName=crypts.decrypt(encryptedCluster.name);
        if(!decryptedName){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataCluster.name) failed to be decrypted.'
                                        ,{dataObject:encryptedCluster});
        }
        decryptedCluster.name=decryptedName;
    }
    if(encryptedCluster.scope){
        const decryptedScope=crypts.decrypt(encryptedCluster.scope);
        if(!decryptedScope){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataCluster.scope) failed to be decrypted.'
                                        ,{dataObject:encryptedCluster});
        }
        decryptedCluster.scope=decryptedScope;
    }
    if(encryptedCluster.referenceEntity){
        const decryptedEntity=crypts.decrypt(encryptedCluster.referenceEntity);
        if(!decryptedEntity){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataCluster.referenceEntity) failed to be decrypted.'
                                        ,{dataObject:encryptedCluster});
        }
        decryptedCluster.referenceEntity=decryptedEntity;
    }
    //SkbLogger.logDebugInfo('dataCluster decrypting result ',decryptedCluster);
    return decryptedCluster;

}

export async function writeDataClusterAsync(clusterToAdd){
    //SkbLogger.logDebugInfo('adding dataCluster',clusterToAdd);
    //validate resource ID
    if(!clusterToAdd.resourceID){
        SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                        '{dataObject} (dataCluster) cannot be stored without resourceID.',clusterToAdd);
        throw "No resourceID";
    }
    //encrypt name and referenceEntityName
    const newCluster=encryptDataCluster(clusterToAdd);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
    //                                     '{dataObject} (dataCluster) is successfully encrypted.'
    //                                     ,{dataObject:clusterToAdd});
    var ret = await db.dataCluster.put(newCluster);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
    //                                     '{dataObject} (dataCluster) is successfully stored in local DB.'
    //                                     ,{dataObject:newCluster});
}

export async function deleteDataClusterRow(resourceID){
    SkbLogger.logDebugInfo('deleting dataCluster',resourceID);
    //await db.dataCluster.where('resourceID').equals(resourceID).delete();
    await db.dataCluster.delete(resourceID);
    SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Delete data',
                                        '{dataObject} (dataCluster) is deleted.'
                                        ,{dataObject:resourceID});
}

export async function getAllLocalClusterCount(){
    if(db){
        try {
            var clusterCount = await db.dataCluster.count();
            console.log('clusterCount',clusterCount);
            if(!clusterCount) return 0;
            return clusterCount;
        } catch (error) {   
            console.log('getAllLocalClusterCount error',error);
        }
    }
    return 0;
}

export async function getAllLocalItemCount(){
    if(db){
        try {
            var itemCount = await db.dataItem.count();
            console.log('itemCount',itemCount);
            if(!itemCount) return 0;
            return itemCount;
        } catch (error) {   
            console.log('getAllLocalClusterCount error',error);
        }
    }
    return 0;
}

export async function getLastestUnchangedCluster(){
    if(db){
        try {
            var unchangedClusters = await db.dataCluster.where('operation').equals('')
                                                    .toArray();
            console.log('unchangedClusters',unchangedClusters);
            var maxVersion=0;
            for (let i = 0; i < unchangedClusters.length; i++) {
                const oneCluster = unchangedClusters[i];
                if(oneCluster && oneCluster.version && maxVersion<oneCluster.version){
                    maxVersion=oneCluster.version;
                }
            }
            console.log("getLastestUnchangedCluster",maxVersion);
            return maxVersion;
        } catch (error) {   
            console.log('getLastestUnchangedCluster error',error);
        }
    }
    return 0;
}

export function checkFurtherLocalChange(localData, serverData){
    if (localData && localData.operation 
        && localData.operation=='U') {
        if(serverData.value && localData.value && localData.value!=serverData.value && localData.timestamp>serverData.timestamp ){
            return true;
        }    
    }
    return false;
}


export async function getLastestUnchangedItemInCluster(clusterResourceID){
    if(!clusterResourceID){
        return 0;
    }
    if(db){
        console.log('getLastestUnchangedItemInCluster clusterResourceID',clusterResourceID);
        try {
            var unchangedItems = await db.dataItem.where('clusterResourceID').equals(clusterResourceID)
                                                            .and( (item) =>{
                                                                return item.operation=='';
                                                            })
                                                            .toArray();
            console.log('getLastestUnchangedItemInCluster: unchangedItems',unchangedItems);
            var maxVersion=0;
            for (let i = 0; i < unchangedItems.length; i++) {
                const oneItem = unchangedItems[i];
                if(oneItem && oneItem.version && maxVersion<oneItem.version){
                    maxVersion=oneItem.version;
                }
            }                                                
            console.log("getLastestUnchangedItemInCluster max:",maxVersion);
            return maxVersion;
        } catch (error) {
            console.log('getLastestUnchangedItemInCluster error',error);
        }
    }
    return 0;
}

export async function getLocalDBForChangesInCluster(clusterResourceID){
    if(db){
        var resultItems = await db.dataItem.where('clusterResourceID').equals(clusterResourceID)
                                            .and( item => {
                                                    return ( item.operation=='I' 
                                                                || item.operation=='U' 
                                                                || item.operation=='D'
                                                                //|| item.operation=='S'  //only for testing
                                                            ); 
                                                } )
                                            .toArray();
        //console.log("DataItem Query Result",resultItems);
        //decrypting
        var decryptedItems = [];
        resultItems.forEach(oneItem => {
            oneItem.localArtefactContent=null; //avoid handling long string unnessesarily 
            decryptedItems.push(decryptedItems(oneItem));//decryptedItems.push(decryptDataItemInSW(oneItem));
        });
        //console.log("DataItem decrypting Result",decryptedItems);
        //sorting
        decryptedItems.sort( (a,b) => {
            if(a.timestamp>b.timestamp) return -1
            else if(a.timestamp<b.timestamp) return 1
            else return 0;
        });
        return decryptedItems;
    }
}

export async function getLocalChangedDataClusters(){
    //SkbLogger.logDebugInfo('getLocalChangedDataClusters','');
    var resultClusters = await db.dataCluster.where('version').equals(0).toArray();
    //SkbLogger.logDebugInfo('getLocalChangedDataClusters result ',resultClusters);
    //decrypting
    var decryptedClusters = [];
    resultClusters.forEach(oneCluster => {
        decryptedClusters.push(decryptDataCluster(oneCluster));
    });
    //SkbLogger.logDebugInfo('decrypted result ',decryptedClusters);
    return decryptedClusters;
}

/*
export async function getAllLocalChangedDataItemPhotos(){
    //SkbLogger.logDebugInfo('getLocalChangedDataClusters','');
    //var resultClusters = await db.dataItem.where('type').equals(0).toArray();
    var resultItems = await db.dataItem.where('type').equalsIgnoreCase('PHOTO') //AWAIT_UPLOADING
                                                    .and( (item) => {
                                                        return (item.localArtefactContent && item.localArtefactContent !='' 
                                                                    && (!item.value || item.value =='' || item.value =='AWAIT_UPLOADING')
                                                            );
                                                    }
                                                )

    SkbLogger.logDebugInfo('getAllLocalChangedDataItemPhotos result ',resultItems);
    //decrypting
    var decryptedItems = [];
    resultItems.forEach(oneItem => {
        decryptedItems.push(decryptDataItem(oneItem));
    });
    //SkbLogger.logDebugInfo('decrypted result ',decryptedClusters);
    return decryptedItems;
}
*/

export async function getDataClusterByName(name){
    //SkbLogger.logDebugInfo('getDataClusterByName',name);
    //encrypt name for the query
    const encryptedName = crypts.encrypt(name);
    var resultClusters = await db.dataCluster.where('name').equalsIgnoreCase(encryptedName).toArray();
    //SkbLogger.logDebugInfo('getDataClusterByName result ',resultClusters);
    //decrypting
    var decryptedClusters = [];
    resultClusters.forEach(oneCluster => {
        decryptedClusters.push(decryptDataCluster(oneCluster));
    });
    //SkbLogger.logDebugInfo('decrypted result ',decryptedClusters);
    return decryptedClusters;
}

export async function getDataClusterForEntityName(referenceEntity){
    SkbLogger.logDebugInfo('getDataClusterForEntityName',referenceEntity);
    //encrypt for the query
    const encryptedEntity = crypts.encrypt(referenceEntity);
    var resultClusters = await db.dataCluster.where('referenceEntity').equalsIgnoreCase(encryptedEntity).toArray();
    SkbLogger.logDebugInfo('getDataClusterForEntityName result ',resultClusters);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                                     '{dataObject} (dataCluster) is successfully retrieved.'
    //                                     ,{dataObject:resultClusters});
    //decrypting
    var decryptedClusters = [];
    resultClusters.forEach(oneCluster => {
        decryptedClusters.push(decryptDataCluster(oneCluster));
    });
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                                     '{dataObject} (dataCluster) is successfully decrypted.'
    //                                     ,{dataObject:decryptedClusters});
    return decryptedClusters;
}

export async function getDataClusterForEntityNameAndID(referenceEntity, referenceID){
    //SkbLogger.logDebugInfo('getDataClusterForEntityNameAndID',referenceEntity,referenceID);
    //encrypt for the query
    const encryptedEntity = crypts.encrypt(referenceEntity);
    var resultClusters = await db.dataCluster.where('referenceEntity').equalsIgnoreCase(encryptedEntity)
                                            .and( (item) => {
                                                   return item.referenceID==referenceID
                                                }
                                            )
                                            .toArray();
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                                     '{dataObject} (dataCluster) is successfully retrieved.'
    //                                     ,{dataObject:resultClusters});
    
    //decrypting
    var decryptedClusters = [];
    resultClusters.forEach(oneCluster => {
        decryptedClusters.push(decryptDataCluster(oneCluster));
    });
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataCluster) is successfully decrypted.',
    //                             {dataObject:decryptedClusters});
    
    return decryptedClusters;
}

export async function getDataClusterByResourceID(resourceID){
    if(!resourceID) return null;
    SkbLogger.logDebugInfo('getDataClusterByResourceID',resourceID);
    //this simple "get" may take around 30ms if resourceID does not exist
    var resultCluster = await db.dataCluster.get(resourceID);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataCluster) is successfully retrieved.',
    //                             {dataObject:resultCluster});
    
    //decrypting
    var ret=decryptDataCluster(resultCluster);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataCluster) is successfully decrypted.',
    //                             {dataObject:resultCluster});
    return ret;
}

//////////////////dataItem//////////////////


function encryptLocalPhoto(plainItem){
    if(!plainItem) return;
    //encrypting the key, description and value
    SkbLogger.logDebugInfo('encrypting localPhoto ',plainItem);
    var encryptedItem={...plainItem}; //clone
    if(plainItem.key){
        const encryptedKey=crypts.encrypt(plainItem.key);
        if(!encryptedKey){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (localPhoto.key) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.key=encryptedKey;
    }
    if(plainItem.description){
        const encryptedDescription=crypts.encrypt(plainItem.description);
        if(!encryptedDescription){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (localPhoto.description) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.description=encryptedDescription;
    }
    if(plainItem.value){
        const encryptedValue=crypts.encrypt(plainItem.value);
        if(!encryptedValue){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (localPhoto.value) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.value=encryptedValue;
    }
    SkbLogger.logDebugInfo('localPhoto encrypting result ',encryptedItem);
    return encryptedItem;

}


function encryptDataItem(plainItem){
    if(!plainItem) return;
    //encrypting the key, description and value
    SkbLogger.logDebugInfo('encrypting dataItem ',plainItem);
    var encryptedItem={...plainItem}; //clone
    if(plainItem.key){
        const encryptedKey=crypts.encrypt(plainItem.key);
        if(!encryptedKey){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataItem.key) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.key=encryptedKey;
    }
    if(plainItem.description){
        const encryptedDescription=crypts.encrypt(plainItem.description);
        if(!encryptedDescription){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataItem.description) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.description=encryptedDescription;
    }
    if(plainItem.value){
        const encryptedValue=crypts.encrypt(plainItem.value);
        if(!encryptedValue){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataItem.value) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.value=encryptedValue;
    }
    if(plainItem.localArtefactContent){
        const encryptedlocalArtefactContent=crypts.encrypt(plainItem.localArtefactContent);
        if(!encryptedlocalArtefactContent){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                                        '{dataObject} (dataItem.localArtefactContent) failed to be encrypted.'
                                        ,{dataObject:plainItem});
        }
        encryptedItem.localArtefactContent=encryptedlocalArtefactContent;
    }
    SkbLogger.logDebugInfo('dataItem encrypting result ',encryptedItem);
    return encryptedItem;

}

function decryptDataItem(encryptedItem){
    if(!encryptedItem) return;
    //decrypting the key, description and value
    //console.log('decrypting dataItem '+encryptedItem.resourceID,encryptedItem);
    var decryptedItem={...encryptedItem}; //clone
    if(encryptedItem.key){
        const decryptedKey=crypts.decrypt(encryptedItem.key);
        if(!decryptedKey){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataItem.key) failed to be decrypted.'
                                        ,{dataObject:encryptedItem});
        }
        decryptedItem.key=decryptedKey;
    }
    if(encryptedItem.description){
        const decryptedDescription=crypts.decrypt(encryptedItem.description);
        if(!decryptedDescription){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataItem.description) failed to be decrypted.'
                                        ,{dataObject:encryptedItem});
        }
        decryptedItem.description=decryptedDescription;
    }
    if(encryptedItem.value){
        const decryptedValue=crypts.decrypt(encryptedItem.value);
        if(!decryptedValue){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataItem.value) failed to be decrypted.'
                                        ,{dataObject:encryptedItem});
        }
        decryptedItem.value=decryptedValue;
    }
    if(encryptedItem.localArtefactContent){
        const decryptedlocalArtefactContent=crypts.decrypt(encryptedItem.localArtefactContent);
        if(!decryptedlocalArtefactContent){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Retrieve Data',
                                        '{dataObject} (dataItem.localArtefactContent) failed to be decrypted.'
                                        ,{dataObject:encryptedItem});
        }
        decryptedItem.localArtefactContent=decryptedlocalArtefactContent;
    }
    //SkbLogger.logDebugInfo('dataItem decrypting result ',decryptedItem);
    return decryptedItem;

}


export async function writeLocalPhotoAsync(itemToAdd){
    //SkbLogger.logDebugInfo('adding dataItem',itemToAdd);
    //validate resource ID
    if(!itemToAdd.resourceID){
        SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                        '{dataObject} (localPhoto) cannot be saved without resourceID.',
                        {dataObject:itemToAdd});
        throw "No resourceID";
    }
    //encrypt name and referenceEntityName
    const newItem=encryptLocalPhoto(itemToAdd);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
    //                                     '{dataObject} (localPhoto) is successfully encrypted',
    //                                     {dataObject:itemToAdd});
    var ret = await db.localPhoto.put(newItem);
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
    //                                     '{dataObject} (localPhoto) is successfully stored in local DB',
    //                                     {dataObject:itemToAdd});
}

export async function deleteLocalPhotoRow(resourceID){
    //SkbLogger.logDebugInfo('deleting localPhoto',resourceID);
    //await db.localPhoto.where('resourceID').equals(resourceID).delete();
    await db.localPhoto.delete(resourceID);
    SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Delete Data',
                                        '{dataObject} (localPhoto) is deleted.',
                                        {dataObject:resourceID});
}

export async function writeMultiDataItemsAsync(itemsToAdd){
    //SkbLogger.logDebugInfo('adding dataItem',itemToAdd);
    //validate resource ID
    var itemArray=[];
    for (let index = 0; index < itemsToAdd.length; index++) {
        const itemToAdd = itemsToAdd[index];
        if(!itemToAdd.resourceID){
            SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                            '{dataObject} (dataItem) cannot be saved without resourceID.',
                            {dataObject:itemToAdd});
            throw "No resourceID";
        }
        const newItem=encryptDataItem(itemToAdd);
        itemArray.push(newItem);
    }
    var ret = await db.dataItem.bulkPut(itemArray);
    SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
                                        '{dataObject} (multi dataItems) are successfully stored in local DB',
                                        {dataObject:itemArray});
										
}

export async function writeDataItemAsync(itemToAdd){
    //SkbLogger.logDebugInfo('adding dataItem',itemToAdd);
    //validate resource ID
    if(!itemToAdd.resourceID){
        SkbLogger.applicationExceptionSub('Local DB','Local DB','Store Data',
                        '{dataObject} (dataItem) cannot be saved without resourceID.',
                        {dataObject:itemToAdd});
        throw "No resourceID";
    }
    //encrypt name and referenceEntityName
    const newItem=encryptDataItem(itemToAdd);
    SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
                                        '{dataObject} (dataItem) is successfully encrypted',
                                        {dataObject:itemToAdd});
    var ret = await db.dataItem.put(newItem);
    SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Store Data',
                                        '{dataObject} (dataItem) is successfully stored in local DB',
                                        {dataObject:itemToAdd});
}

export async function deleteDataItemRow(resourceID){
    //SkbLogger.logDebugInfo('deleting dataItem',resourceID);
    //await db.dataItem.where('resourceID').equals(resourceID).delete();
    await db.dataItem.delete(resourceID);
    SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Information,'Local DB','Delete Data',
                                        '{dataObject} (dataItem) is deleted.',
                                        {dataObject:resourceID});
}

export async function getDataItemByKey(key, clusterResourceID, sortingRequired=0){
    //SkbLogger.logDebugInfo('getDataItemByKey',key, clusterResourceID);
    //encrypt key for the query
    const encryptedKey = crypts.encrypt(key);
    //SkbLogger.logDebugInfo('encryptedKey',encryptedKey);
    var resultItems = await db.dataItem.where('clusterResourceID').equalsIgnoreCase(clusterResourceID)
                                    .and((item) => {
                                        return item.key==encryptedKey;
                                    })
                                    .toArray();
    //SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                            '{dataObject} (dataItem) is successfully retrieved.',
    //                            {dataObject:resultItems});
    //decrypting
    var decryptedItems = [];
    resultItems.forEach(oneItem => {
        decryptedItems.push(decryptDataItem(oneItem));
    });
    //SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                            '{dataObject} (dataItem) is successfully decrypted.',
    //                            {dataObject:decryptedItems});
    //sorting by arrayIndex
    if(sortingRequired){
        decryptedItems.sort( (a,b) => {
            //only for one-dimension or two-dimension arrayIndex, separating by comma 
            //i'm calling the dimesions in arraIndex as X,Y,Z... (like a vector)
            //TODO to do more to handle three-dimension or more arrayIndex, 
            var aIndexVector=a.arrayIndex.split(',');
            var bIndexVector=b.arrayIndex.split(',');

            var aIndexX=aIndexVector[0];
            var aIndexY='';
            if(aIndexVector.length>1) aIndexY=aIndexVector[1];

            var bIndexX=bIndexVector[0];
            var bIndexY='';
            if(bIndexVector.length>1) bIndexY=bIndexVector[1];

            if( parseFloat(aIndexX) < parseFloat(bIndexX) ) return -1;
            else if( parseFloat(bIndexX) < parseFloat(aIndexX) ) return 1;
            else if (aIndexVector.length>1 && bIndexVector.length>1){
                if( parseFloat(aIndexY) < parseFloat(bIndexY) ) return -1;
                else if( parseFloat(bIndexY) < parseFloat(aIndexY) ) return 1; 
                else return 0;
            }else return 0;
         }
        );
        //SkbLogger.logDebugInfo('sorted result ',decryptedItems);
    }
    return decryptedItems;
}


export async function getDataItemByResourceID(resourceID){
    if(!resourceID) return null;
    //SkbLogger.logDebugInfo('getDataItemByResourceID',resourceID);
    var resultItem = await db.dataItem.get(resourceID);
    //console.log('getDataItemByResourceID result '+resultItem.resourceID,resultItem);
    //SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                            '{dataObject} (dataItem) is successfully retrieved.',
    //                            {dataObject:resultItem});
    //decrypting
    var ret=decryptDataItem(resultItem);
    //SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                            '{dataObject} (dataItem) is successfully decypted.',
    //                            {dataObject:resultItem});
    //console.log('getDataItemByResourceID returning',resourceID,ret);
    return ret;
}

export async function getDataItemsForCluster(clusterResourceID){
    //SkbLogger.logDebugInfo('getDataItemsForCluster','');
    var resultItems = await db.dataItem
                            .where('clusterResourceID').equals(clusterResourceID)
                            .toArray();
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataItem) is successfully retrieved.',
    //                             {dataObject:resultItems});
    //decrypting
    var decryptedItems = [];
    resultItems.forEach(oneItem => {
        decryptedItems.push(decryptDataItem(oneItem));
    });
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataItem) is successfully decrypted.',
    //                             {dataObject:decryptedItems});
    return decryptedItems;
}

export async function getDataItemsByKeysForCluster(clusterResourceID, keys, noDecrypt=false){
    //SkbLogger.logDebugInfo('getDataItemsForCluster','');
    var regsString = `^${(keys||".*").replace(/,/g, "|")}$`;
    // var regs =  new RegExp(regsString, 'ig');
    var resultItems = await db.dataItem
                            .where('clusterResourceID').equals(clusterResourceID)
                            //.and(item=>item.key == "Wx5MoKxvAVK6FYanBaUDNrFfJ1g=")
                            .and(item=> {
                                if(!keys) return true;

                                let k = crypts.decrypt(item.key);
                                // console.log(item.key, k);
                                let m = (new RegExp(regsString, 'ig')).test(k);
                                // if(item.clusterResourceID == 'E5AB031AB2454F86B24AAC5EA016AADC' && item.key == 'Wx5MoKxvAVK6FYanBaUDNrFfJ1g=')
                                // console.log(item, m, k);
                                return m;
                            }
                            )
                            .toArray();
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataItem) is successfully retrieved.',
    //                             {dataObject:resultItems});
    if(noDecrypt) return resultItems;
    //decrypting
    var decryptedItems = [];
    resultItems.forEach(oneItem => {
        decryptedItems.push(decryptDataItem(oneItem));
    });
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataItem) is successfully decrypted.',
    //                             {dataObject:decryptedItems});
    return decryptedItems;
}

export async function getLocalChangedDataItemsForCluster(clusterResourceID){
    //SkbLogger.logDebugInfo('getLocalChangedDataItems','');
    var resultItems = await db.dataItem
                            .where('clusterResourceID').equals(clusterResourceID)
                            .and( item => {
                                    return item.version==0; 
                                } )
                            .toArray();
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataItem) is successfully retrieved.',
    //                             {dataObject:resultItems});
    //decrypting
    var decryptedItems = [];
    resultItems.forEach(oneItem => {
        decryptedItems.push(decryptDataItem(oneItem));
    });
    // SkbLogger.applicationTraceSub('Local DB',SeverityLevel.Verbose,'Local DB','Retrieve Data',
    //                             '{dataObject} (dataItem) is successfully decrypted.',
    //                             {dataObject:decryptedItems});
    return decryptedItems;
}
