
import uuid from 'react-uuid'
import { SkbLogger, ApiService } from '../../services';


export const Actions = {
    REQUTEST_SEND: "REQUTEST_SEND",
    REQUEST_END: "REQUEST_END",
    COUNTRY_RECIEVED: "COUNTRY_RECIEVED",
    COUNTRY_ADD: "COUNTRY_ADD",
    COUNTRY_UPDATE: "COUNTRY_UPDATE",
    COUNTRY_DELETE: "COUNTRY_DELETE",

    COUNTRYPHONE_UPDATE: "COUNTRYPHONE_UPDATE",
    COUNTRYPHONE_RECIEVED: "COUNTRYPHONE_RECIEVED",

    EMPLOYEEDOMAIN_RECIEVED: "EMPLOYEEDOMAIN_RECIEVED",
    EMPLOYEEDOMAIN_ADD: "EMPLOYEEDOMAIN_ADD",
    EMPLOYEEDOMAIN_UPDATE: "EMPLOYEEDOMAIN_UPDATE",
    EMPLOYEEDOMAIN_DELETE: "EMPLOYEEDOMAIN_DELETE",

    DIVISIONS_RECIEVED: "DIVISIONS_RECIEVED",
    DIVISION_UPDATE: "DIVISION_UPDATE",
    DIVISION_ADD: "DIVISION_ADD",

    PROGRAMS_RECIEVED: "PROGRAMS_RECIEVED",
    PROGRAM_ADD: "PROGRAM_ADD",
    PROGRAM_UPDATE: "PROGRAM_UPDATE",

}

export const remoteRequestBegin = () => { return { type: Actions.REQUTEST_SEND }; }
export const remoteRequestFinished = () => { return { type: Actions.REQUEST_END }; }

export function getCountries() {
    return (dispatch) => {
        dispatch(remoteRequestBegin());

        ApiService.getCountries().then(function (res) {
            dispatch(getCountriesSucess(res.data))
            SkbLogger.applicationTrace('ManageVariables', 1, 'Get countries', 'Get countries from API successful', res);
            dispatch(getCountryPhones());

        })
            .catch(function (error) {
                SkbLogger.logDebugInfo('sendGetCountriesRequest: error Response', error)
                SkbLogger.applicationException('ManageVariables', 'Get countries', 'Exception from get countries API', error);
                // dispatch(verifyCodeFailed())
                dispatch(remoteRequestFinished());
            });
    }
}
export function getEmployeeDomains() {
    return (dispatch) => {
        dispatch(remoteRequestBegin());

        ApiService.getEmployeeDomains().then(function (res) {
            // SkbLogger.logDebugInfo("getCountries", res.data);
            dispatch(getEmployeeDomainsSucess(res.data))
            SkbLogger.applicationTrace('ManageVariables', 1, 'Get employee domains', 'Get employee domains from API successful', res);
        })
            .catch(function (error) {
                SkbLogger.applicationException('ManageVariables', 'Get employee domains', 'Exception from get employee domains API', error);
                // dispatch(verifyCodeFailed())
                dispatch(remoteRequestFinished());
            });
    }
}

export function getCountryPhoneSucess(phone) {
    return {
        type: Actions.COUNTRYPHONE_RECIEVED,
        Phones: phone
    }
}

export function getEmployeeDomainsSucess(domain) {
    return {
        type: Actions.EMPLOYEEDOMAIN_RECIEVED,
        Domains: domain
    }
}

export function getCountryPhones() {
    return (dispatch) => {
        dispatch(remoteRequestBegin());

        ApiService.getCountryPhones().then(function (res) {

            //from server, the phone setting in variableValue is a formation stringion, it include phPrefix, landlinelenght, mobilelenght and mobileprefix in sequence and separated by '|'
            let phoneConfig = [];
            SkbLogger.logDebugInfo('sendGetCountryPhoneResponse process before:', res);
            res.data.forEach((setting) => {
                let settingInArray = ['', '', '', ''];
                if (setting.variableValue)
                    settingInArray = setting.variableValue.split('|');

                let settingInObj = {
                    id: setting.id,
                    variableGroup: setting.variableGroup,
                    parentVariableID: setting.parentVariableID,
                    country: setting.parentVariable.variableName,
                    resourceID: setting.resourceID,
                    phPrefix: settingInArray[0],
                    landlineLength: settingInArray[1],
                    mobileLength: settingInArray[2],
                    mobilePrefix: settingInArray[3]
                };


                phoneConfig.push(settingInObj);
            });


            dispatch(getCountryPhoneSucess(phoneConfig));
            SkbLogger.applicationTrace('ManageVariables', 1, 'Get country phone', 'Get country phone from API successful', phoneConfig);
        }).catch(function (error) {
            SkbLogger.logDebugInfo('sendGetCountryPhoneRequest: error Response', error)
            SkbLogger.applicationException('ManageVariables', 'Get country phone', 'Exception from get country phone API', error)

            dispatch(remoteRequestFinished());
        });

    }
}

export function getCountriesSucess(countries) {
    return {
        type: Actions.COUNTRY_RECIEVED,
        Countries: countries
    }
}

export function addCountry(country, auth) {
    //c = { ...c, resourceID: uuid().replace(/-/ig, '') };
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked add country ${JSON.stringify(country)}`, userData);
    return (dispatch) => {
        ApiService.addCountry(country, auth).then((res) => {
            SkbLogger.logDebugInfo("add country to server Sucess", res);

            SkbLogger.logDebugInfo("refresh country phone from server when new country is added", country);
            dispatch(getCountryPhones());//refresh country phone list in store
           // SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Add country', `New country ${country.variableName} added`, userData);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'New country added', res);
            dispatch((() => {
                SkbLogger.logDebugInfo("refresh country store", country);

                return {
                    type: Actions.COUNTRY_ADD,
                    Country: country
                }
            })()
            );

        }).catch((error) => {
            SkbLogger.logDebugInfo("update country to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Add country', 'Exception from addCountry API', error);

        });

    }

}

export function addEmployeeDomain(domain, auth) {
    //c = { ...c, resourceID: uuid().replace(/-/ig, '') };
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked add employee domain ${JSON.stringify(domain)}`, userData);
    return (dispatch) => {
        ApiService.addEmployeeDomain(domain, auth).then((res) => {
            SkbLogger.logDebugInfo("add EMPLOYEEDOMAIN to server Sucess", res);
            //SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Add Employee Domain', `New employee ${domain.variableName} added`, userData);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'New employee domain added', res);
            dispatch((() => {
                SkbLogger.logDebugInfo("refresh country store", domain);

                return {
                    type: Actions.EMPLOYEEDOMAIN_ADD,
                    Domain: domain
                }
            })()
            );

        }).catch((error) => {
            SkbLogger.logDebugInfo("add EMPLOYEEDOMAIN to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Add Employee Domain', 'Exception from addEmployeeDomain API', error);
        });

    }

}

export function delCountry(c) {

    //current it's not doing anything
    return {
        type: Actions.COUNTRY_DELETE,
        Country: c
    }
}

export function delEmployeeDomain(d) {

    //current it's not doing anything
    return {
        type: Actions.EMPLOYEEDOMAIN_DELETE,
        Domain: d
    }
}

export function updateCountry(country, auth) {
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked update country ${JSON.stringify(country)}`, userData);
    return (dispatch) => {
        ApiService.updateCountry(country, auth).then((res) => {
            SkbLogger.logDebugInfo("update country to server Sucess", res);
           // SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Update country', `Country ${country.variableName} updated`, userData);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Country updated', res);
            dispatch((() => {
                return {
                    type: Actions.COUNTRY_UPDATE,
                    Country: country
                }
            })()
            );
        }).catch((error) => {
            SkbLogger.logDebugInfo("update country to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update country', 'Exception from updateCountry API', error);
        });

    }

}
export function updateEmployeeDomain(domain, auth) {

    // //todo: call web service to update
    // // SkbLogger.logDebugInfo("updateCountry", c);
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked update employee domain ${JSON.stringify(domain)}`, userData);
    return (dispatch) => {
        ApiService.updateEmployeeDomain(domain, auth).then((res) => {
            SkbLogger.logDebugInfo("update employee domain to server Sucess", res);
           // SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Update employee domain', `Employee domain ${domain.variableName} updated`, userData);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Update employee domain', res);
            dispatch((() => {
                return {
                    type: Actions.EMPLOYEEDOMAIN_UPDATE,
                    Domain: domain
                }
            })()
            );
        }).catch((error) => {
            SkbLogger.logDebugInfo("update employee domain to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update employee domain', 'Exception from updateEmployeeDomain API', error);
        });

    }

}
export function updateCountryPhone(phone, auth) {
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked update update country phone ${JSON.stringify(phone)}`, userData);
    return (dispatch) => {
        ApiService.updateCountryPhone(phone, auth).then((res) => {
            SkbLogger.logDebugInfo("update country phone to server Sucess", res);
            //SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Update country phone', `Country phone ${phone.country} updated`, userData);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Update country phone successful', res);
            dispatch((() => {
                return {
                    type: Actions.COUNTRYPHONE_UPDATE,
                    Phone: phone
                }
            })()
            );
        }).catch((error) => {
            SkbLogger.logDebugInfo("update country phone to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update country phone', 'Exception from updateEmployeeDomain API', error);
        });

    }

}
//**********DIVSIONS & Programs*************************
export function getDivisionsSuccess(division) {
    //current it's not doing anything
    return {
        type: Actions.DIVISIONS_RECIEVED,
        Divisions: division
    }
}
export function getDivisions() {
    return (dispatch) => {
        dispatch(remoteRequestBegin());

        ApiService.getDivisions().then(function (res) {
            SkbLogger.logDebugInfo("getDivisions", res.data);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Get divisions successful', res);
            dispatch(getDivisionsSuccess(res.data))
            dispatch(getPrograms());

        })
            .catch(function (error) {
                SkbLogger.logDebugInfo('getDivisions: error Response', error);
                SkbLogger.applicationException('ManageVariables', 'Update variable', 'Exception from getDivisions API', error);
                // dispatch(verifyCodeFailed())
                dispatch(remoteRequestFinished());
            });
    }
}

export function addDivisionSuccess(division) {
    SkbLogger.logDebugInfo("addDivisionSuccess", division);
    return {
        type: Actions.DIVISION_ADD,
        Division: division
    };
}
export function addDivision(division, auth) {
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked add division ${JSON.stringify(division)}`, userData);
    //c = { ...c, resourceID: uuid().replace(/-/ig, '') };
    return (dispatch) => {
        ApiService.addDivision(division, auth).then((res) => {
            SkbLogger.logDebugInfo("ADD Division to server Success", res);
           // SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Add division', `New division ${division.variableName} added`, division);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Add division successful', res);

            dispatch(getDivisions());

        }).catch((error) => {
            SkbLogger.logDebugInfo("add Division to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update variable', 'Exception from addDivision API', error);
        });

    }

}

export function updateDivision(division, auth) {
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked update division ${JSON.stringify(division)}`, userData);
    return (dispatch) => {
        ApiService.updateDivision(division, auth).then((res) => {
            SkbLogger.logDebugInfo("update Division to server Success", res);
            //SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Update division', `New division ${division.variableName} added`, division);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Add division successful', res);
            dispatch((() => {
                return {
                    type: Actions.DIVISION_UPDATE,
                    Division: division
                }
            })()
            );
        }).catch((error) => {
            SkbLogger.logDebugInfo("update Division to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update variable', 'Exception from updateDivision API', error);
        });

    }
}

export function delDivision() {

}

export function getProgramsSuccess(program) {
    return {
        type: Actions.PROGRAMS_RECIEVED,
        Programs: program
    }
}
export function getPrograms() {
    return (dispatch) => {
        dispatch(remoteRequestBegin());

        ApiService.getPrograms().then(function (res) {
            SkbLogger.logDebugInfo("getPrograms", res.data);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Get programs successful', res);
            dispatch(getProgramsSuccess(res.data));
            //dispatch(getPrograms());

        })
            .catch(function (error) {
                SkbLogger.logDebugInfo('getPrograms: error Response', error)
                SkbLogger.applicationException('ManageVariables', 'Update variable', 'Exception from getPrograms API', error);
                // dispatch(verifyCodeFailed())
                dispatch(remoteRequestFinished());
            });
    }

}

export function addProgram(program, auth) {
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked add program ${JSON.stringify(program)}`, userData);
    //c = { ...c, resourceID: uuid().replace(/-/ig, '') };
    return (dispatch) => {
        ApiService.addProgram(program, auth).then((res) => {
            SkbLogger.logDebugInfo("ADD Program to server Success", res);
           // SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Add program', `New program ${program.name} added`, program);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Add program successful', res);
            dispatch((() => {
                return {
                    type: Actions.PROGRAM_ADD,
                    Program: program
                }
            })()
            );
        }).catch((error) => {
            SkbLogger.logDebugInfo("add Program to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update variable', 'Exception from addProgram API', error);
        });

    }
}

export function updateProgram(program, auth) {
    const userData = { "user": auth.AuthUser.email }
    SkbLogger.applicationTrace('ManageVariables', 0, 'Update variable', `User clicked update division ${JSON.stringify(program)}`, userData);
    return (dispatch) => {
        ApiService.updateProgram(program, auth).then((res) => {
            SkbLogger.logDebugInfo("update Program to server Success", res);
           // SkbLogger.userAuditEvent('ManageVariables', auth.AuthUser.email, 'Update variable', 'Update program', `Program ${program.variableName} updated`, program);
            SkbLogger.applicationTrace('ManageVariables', 1, 'Update variable', 'Update program successful', res);
            dispatch((() => {
                return {
                    type: Actions.PROGRAM_UPDATE,
                    Program: program
                }
            })()
            );
        }).catch((error) => {
            SkbLogger.logDebugInfo("update Program to server faile", error);
            SkbLogger.applicationException('ManageVariables', 'Update variable', 'Exception from updateProgram API', error);
        });

    }
}
export function deleteProgram() { } {
    //current it's not doing anything
}
//***************END DIVISION & Programs*********************************** */

