import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
} from '@material-ui/core';
import {

    KeyboardDatePicker,
    MuiPickersUtilsProvider,

} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';


// importing components
import SkbTextField from '../../../../skb_controls/components/SkbTextField';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import Loading from '../../../Loading';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';
import { useSnackbar } from 'notistack';
import { deletePackage } from '../../../../utils/returnstock/pickingAndPacking';
import * as returnStockUtils from '../../../../utils/returnstock/misc';
import * as api from '../../../../services/ApiService';

const styles = makeStyles((theme) => ({
    root: {
        width: '90%',
        // textAlign: 'center',
    },
    packagingDetails: {
        // width: '90%',
        // margin: '1em auto',
        // height: '40em',
        // border: '1px solid black',
        //padding: '1em',
    },
    packageId: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '1em auto',
    },
    palletsCatrons: {
        textAlign: "left",
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        margin: '0.2em 0',
        "& p:first-child":{
            width: "120px",
        }
    },
    quantity: {
        margin: '1em auto',
        padding: '1em',
        border: '1px solid lightgray',
    },
    link: {
        textDecoration: 'underline',
        color: 'blue',
    },
    borderBox: {
        padding: '1em',
        border: '1px solid lightgray',
    }
}));



const ContractorDropOffOption = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const showErrorNotification = (message, variant) => {
        enqueueSnackbar(message, {
            variant: variant
        });
    }
    const {
        root,
        packagingDetails,
        packageId,
        palletsCatrons,
        flexRow,
        quantity,
        link,
        borderBox,

    } = styles();

    const {
        isLoading,
        //pickupDetails,
        packageDetails,
        updateDropOffDetails,
        updatePackage,
        defaultDeliveryDate,
        dropOffDetails,
        addPackage,
        deletePackage,
        deliveryDestination

    } = props;

    const dropOffWindowOptions=JSON.parse(localStorage.getItem('REUSABLE-SETTINGS-DropOffWindowOptions'));

    const [cartons, setCartons] = useState(0);
    const [pallets, setPallets] = useState(0);
    const [date, setDate] = useState(new Date(defaultDeliveryDate));
    const [eta, setEta] = useState(dropOffWindowOptions[0]);//useState('7am - 12pm');



    useEffect(() => {

        if(!dropOffDetails || !dropOffDetails.DropOffWindow) return;

        setDate((dropOffDetails && new Date(dropOffDetails.DropOffWindow.Date)) || new Date(defaultDeliveryDate));
        setEta((dropOffDetails && dropOffDetails.DropOffWindow.ETA) || 0);

        if(!dropOffDetails.DropOffWindow.Date && defaultDeliveryDate) { //set default dropoffwindow 
            updateDropOffDetails({
                Date: defaultDeliveryDate,
                ETA: dropOffWindowOptions[0] //'7am - 12pm'
            }
        );
        }

    }, [dropOffDetails]);

    const getPackageQtyByType = (packages, type) => {
        if (!Array.isArray(packages)) return 0;

        return packages.filter(p => p.Type === type).reduce((qty, i) => qty + i.Qty, 0);
    }

    const findPackageExistingByType = (packages, type) => {
        if (!Array.isArray(packages)) return false;

        return packages.find(p => p.Type === type);
    }
    useEffect(() => {

        if (Array.isArray(packageDetails)) {
            let qtyOfCarton = getPackageQtyByType(packageDetails, "Carton");
            if (qtyOfCarton !== cartons) setCartons(qtyOfCarton);
            let qtyOfPallet = getPackageQtyByType(packageDetails, "Pallet");
            if (qtyOfPallet !== pallets) setPallets(qtyOfPallet);
        }
    }, [packageDetails]);


    const upatePackageByType = (e, type)=>{
        var existingPack = findPackageExistingByType(packageDetails, type);
        if (existingPack) {
            if (parseInt(e.target.value) === 0) {
                deletePackage(existingPack);
            }
            else {
                updatePackage({
                    ...existingPack,
                    Qty: parseInt(e.target.value)
                });
            }
        }else if (parseInt(e.target.value) >= 0) {
            addPackage({
                Type: type,
                Qty: parseInt(e.target.value)
            });
        }
    }
    const changePalletsHandler = (e) => {
        //e.target.value
        //setPallets(e.target.value);
        upatePackageByType(e, "Pallet");
    };
    const changeCartonsHandler = (e) => {
        //e.target.value
        //setCartons(e.target.value);
        upatePackageByType(e, "Carton");
    };

    const changeDropOffWindowHandler = (e, field)=>{
        let dropOffDate = date;
        let dropOffEta = eta;
        if(field === 'DATE'){
            setDate(e);
            dropOffDate = e;
        }else if(field === 'ETA'){
            setEta(e);
            dropOffEta = e;
        };

        updateDropOffDetails({
                Date: dropOffDate,
                ETA: dropOffEta
            }
        );
    }
    const submit = () => {
        if (!cartons || !pallets || !date || !eta) {
            showErrorNotification("Error submitting packaging details:  Please enter the package details of the cartons and/or pallets you have packed and specify a date and time.", "error")
        } else {

        }
    }


    return (
        <div className={root}>
            <div className={packagingDetails}>
                {/* <Grid container className={packageId}>
                    <Grid item><Typography variant="h5">Package Details</Typography></Grid>
                    <Grid item className={link}></Grid>
                </Grid> */}
                <div className={palletsCatrons}>
                    <Typography variant="h6">Packaging Details</Typography>
                    <Typography variant="body2">Please specify how you packaged your stock.</Typography>
                    <Grid container direction="column" className={quantity}>
                        <Grid item className={flexRow}>
                           
                            <SkbTextField label="# of Pallets"
                                type="number"
                                value={pallets}
                                onChange={changePalletsHandler}
                            />
                        </Grid>
                        <Grid item className={flexRow}>
                         
                            <SkbTextField label="# of Cartons"
                                type="number"
                                value={cartons}
                                onChange={changeCartonsHandler}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Grid
                    container
                    direction="column"
                    style={{ textAlign: 'left' }}
                >


                    <Grid item><Typography variant="h6">Drop Off Details</Typography></Grid>
                    <Grid container className={borderBox} direction="column">
                        <Grid item><Typography variant="body1">Stock is returning to <b>{deliveryDestination.DeliveryDestination}</b></Typography></Grid>
                        <Grid item><Typography variant="body2">Address :
                         {returnStockUtils.buildAddressInOneLine(deliveryDestination.DeliveryAddressLine1,
                            deliveryDestination.DeliveryAddressLine2,
                            deliveryDestination.DeliveryAddressSuburb,
                            deliveryDestination.DeliveryAddressState,
                            deliveryDestination.DeliveryAddressPostcode)}
                             </Typography></Grid>
                        <Grid container direction="column" style={{ marginTop: '1em' }}>
                            <Grid item>
                                <Typography variant="body2"><b>Drop-off window</b></Typography>
                            </Grid>
                        <Grid container
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >

                                <Grid item style={{marginTop:'4px'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            value={date}
                                            onChange={e => changeDropOffWindowHandler(e, "DATE")}
                                            placeholder="22/10/2020"
                                            leftArrowIcon={<ArrowBackIosIcon />}

                                            rightArrowIcon={<ArrowForwardIosIcon />}
                                            keyboardIcon={<EventIcon />}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                                'id': 'date-picker-inline-changedatebtn'
                                            }}
                                            leftArrowButtonProps={
                                                {
                                                    'id': 'date-picker-inline-leftbtn'
                                                }
                                            }
                                            rightArrowButtonProps={
                                                {
                                                    'id': 'date-picker-inline-righbtn'
                                                }
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <SkbSelect 
                                        value={eta}
                                        items={dropOffWindowOptions} //{['7am - 12pm','12pm - 3pm',]}
                                        valueChanged={e => changeDropOffWindowHandler(e, "ETA")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'1em'}}>
                    <Grid item style={{marginRight:'1em'}}><SkbButton id="medium-secondary-button" size="medium" text={"< Back"} /></Grid>
                    <Grid item><SkbButton  id="medium-primary-button" primary size="medium" text={"Submit"} /></Grid>
                    
                </Grid> */}
            </div>
            {isLoading && <Loading />}

        </div>
    );
}


export default ContractorDropOffOption;