import axios from "axios";

import {crypts} from '../../utils/crypts';
import { config } from '../../utils/config';

function service() {
     

    // pickup window time  slot data to be  used in task info schedule noncontractor dropoff scenario step 2 section to be taken  from  this api
    const getPickupWindowOptions = async (paramObj) =>{
  
        try {
                const apiEndPoint =  `${process.env.REACT_APP_API_URL}managedvariables?group=PickupWindowOptions`;

                const idToken = crypts.decrypt(localStorage.getItem('id_token'));
                const authorization = config.REACT_APP_API_SECRET;
                
                const headerOptions = {
                    "content-type": "application/json",
                    "authorization": authorization,  
                    "X-CustomHeader": idToken
                };      
               
                const resp =  await axios({
                    method: 'GET', //you can set what request you want to be
                    url: apiEndPoint,
                    data: null,
                    headers: headerOptions
                });
                return resp;
            }catch (error) {
            console.error(error);
            }
    };  

    return {getPickupWindowOptions};
  
  }
  
  export const getPickupWindowOptionsService = service(); 
