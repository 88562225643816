/**
 *
 * @param {string} data
 * @returns
 */
const toJSONData = (data) => {
    const rows = data.split(/(\r\n)|(\r)|(\n)/gu).filter((x) => x);
    const HEADER = rows[0].split(",").filter((x) => x);
    const ROWS = rows.slice(1);
    if (!HEADER || !HEADER.length || HEADER.length != 4) {
        return {
            status: "error",
            message: "File is invalid",
        };
    }
    if (!HEADER[0].trim().toLowerCase().replace(/\s/gu) === "LOCATION") {
        console.log("Missing Location column");
        return {
            status: "error",
            message: "File is invalid",
        };
    }
    if (!HEADER[1].trim().toLowerCase().replace(/\s/gu) === "STOCKCODE") {
        console.log("Missing Stock Code column");
        return {
            status: "error",
            message: "File is invalid",
        };
    }
    if (!HEADER[2].trim().toLowerCase().replace(/\s/gu) === "SERIALNUMBER") {
        console.log("Missing Serial Number column");
        return {
            status: "error",
            message: "File is invalid",
        };
    }
    if (!HEADER[3].trim().toLowerCase().replace(/\s/gu) === "QTY") {
        console.log("Missing Location column");
        return {
            status: "error",
            message: "File is invalid",
        };
    }
    if (ROWS.length > 1) {
        const JSON_DATA = [];

        // converting into JSON
        for (let i = 1; i < ROWS.length; i++) {
            const columns = ROWS[i].split(",");
            const obj = {};

            Object.assign(obj, { [HEADER[0]]: columns[0] });
            Object.assign(obj, { [HEADER[1]]: columns[1] });
            Object.assign(obj, { [HEADER[2]]: columns[2] });
            Object.assign(obj, { [HEADER[3]]: columns[3] });

            JSON_DATA.push(obj);
        }

        return JSON_DATA;
    } else {
        return {
            status: "error",
            message: "Must contain at least 1 row of data with comma separated values",
        };
    }
};

const ProcessFile = (path) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const jsonData = toJSONData(event.target.result);

            if (jsonData.status === "error") reject(jsonData);

            resolve(jsonData);
        };
        reader.readAsText(path);
        reader.onerror = function (error) {
            reject(error);
        };
    });
};

export default ProcessFile;
