import axios from "axios";
import {config} from '../../utils/config';
import {SkbLogger, ApiService, SeverityLevel} from '../../services';

export const Actions = {
    SEND_VERIFICATIONCODE_REQUEST: "SEND_VERIFICATIONCODE_REQUEST",
    SEND_VERIFICATIONCODE_SUCCESS: "SEND_VERIFICATIONCODE_SUCCESS",
    SEND_VERIFICATIONCODE_FAILED: "SEND_VERIFICATIONCODE_FAILED",
    VERIFY_CODE_SUCCESS: "VERIFY_CODE_SUCCESS",
    VERIFY_CODE_FAILED: "VERIFY_CODE_FAILED",
    GONEXTSTEP: "GONEXTSTEP",
    GOTOVERIFYDETAILS: "GOTOVERIFYDETAILS",
    GOBACK: "GOBACK",
    CHANGE_VERIFICATION_CODE: "CHANGE_VERIFICATION_CODE",
    UPDATE_USER_TYPE:"UPDATE_USER_TYPE",
    GET_COUNTRIES:"GET_COUNTRIES",
    CHECK_IS_SKBUSER:"CHECK_IS_SKBUSER",
    UPDATE_COUNTRY:"UPDATE_COUNTRY",
    UPDATE_PHONE:"UPDATE_PHONE",
    UPDATE_USERNAME:"UPDATE_USERNAME",
    UPDATE_COMPANYNAME:"UPDATE_COMPANYNAME",
    UPDATE_TAXNUMBER:"UPDATE_TAXNUMBER",
    UPDATE_STREETADDRESS:"UPDATE_STREETADDRESS",
    UPDATE_POSTALADDRESS:"UPDATE_POSTALADDRESS",
    GET_PHONEPREFIX:"GET_PHONEPREFIX",
    UPDATE_USERPHONEPREFIX:"UPDATE_USERPHONEPREFIX",
    GET_USERSELECTEDPHONEPREFIX:"GET_USERSELECTEDPHONEPREFIX",
    GET_TAXLABELLIST:"GET_TAXLABELLIST",
    GET_SELECTEDCOUNTRYTAXLABELLIST:"GET_SELECTEDCOUNTRYTAXLABELLIST",
    SUBMIT_REGISTER:"SUBMIT_REGISTER",
    UPDATE_TAXLABEL:"UPDATE_TAXLABEL",
    UPDATE_SUBMITT_ERROR:"UPDATE_SUBMITT_ERROR",
    SUBMITTING_REGISTER:"SUBMITTING_REGISTER",
    GETSKBDOMAINS:"GETSKBDOMAINS",
    UPDATE_DEFAULT_PHONEPREFIX:"UPDATE_DEFAULT_PHONEPREFIX",
    GOTOVERIFICATIONPAGE:"GOTOVERIFICATIONPAGE",
    UPDATE_POADD_SAMEASSTREET:"UPDATE_POADD_SAMEASSTREET"
};

export function getUserSelectedTaxlabel() {
    return{
        type:Actions.GET_SELECTEDCOUNTRYTAXLABELLIST
    }
}

export function updatePoaddressSwitch(s) {
    SkbLogger.logDebugInfo('updatePoaddressSwitch ',s)
    return{
        type:Actions.UPDATE_POADD_SAMEASSTREET,
        payload:s
    }
}

export function updateSubmitError() {
    return{
        type:Actions.UPDATE_SUBMITT_ERROR
    }
}


export function updateSelectedTaxLabel(e) {
    SkbLogger.logDebugInfo("get select phone prefix",e);
    return{
        type:Actions.GET_SELECTEDCOUNTRYTAXLABELLIST,
        payload:e
    }
}

export function updateUserTaxNumber(t) {
    SkbLogger.logDebugInfo("from updateUserTaxNumber",t);
    return{
        type:Actions.UPDATE_TAXNUMBER,
        payload:t
    }
}

export function getUserSelectedPhonePrefix(e) {
    SkbLogger.logDebugInfo("get select phone prefix",e);
    return{
        type:Actions.GET_USERSELECTEDPHONEPREFIX,
        payload:e
    }
}

export function updateUserPhonePrefix() {
    return{
        type:Actions.UPDATE_USERPHONEPREFIX
    }
}

export function updateStreetAddress(streetAddress) {
    return{
        type:Actions.UPDATE_STREETADDRESS,
        payload:streetAddress
    }
}
export function updatePostalAddress(postalAddress) {
    return{
        type:Actions.UPDATE_POSTALADDRESS,
        payload:postalAddress
    }
}

export function updateCompanyName(name) {
    return{
        type:Actions.UPDATE_COMPANYNAME,
        payload:name
    }
}

export function updateUserPhone(phone) {
    return{
        type:Actions.UPDATE_PHONE,
        payload:phone
    }
}

export function updateUserName(userName){
    return {
        type:Actions.UPDATE_USERNAME,
        userName
    }
}
export function checkIsSKBUser(email) {
    return{
        type:Actions.CHECK_IS_SKBUSER,
        payload:email
    }
}
export function updateCountry(e) {
    return{
        type:Actions.UPDATE_COUNTRY,
        payload:e
    }
}

//getSkbDomainList

export function getSkbDomainList(e) {
    return{
        type:Actions.GETSKBDOMAINS,
        payload:e
    }
}




export function getCountriesSucess(c) {
    return{
        type:Actions.GET_COUNTRIES,
        payload:c
    }
}

export function verifyCode() {
    return{
        type:Actions.SEND_VERIFICATIONCODE_REQUEST
    }
}

export function submittingRegister() {
    return{
        type:Actions.SUBMITTING_REGISTER
    }
}

export function updateDefaultPhonePrefix(){
    return{
        type:Actions.UPDATE_DEFAULT_PHONEPREFIX
    }
}

export function updateUserType(t) {
    return{
        type:Actions.UPDATE_USER_TYPE,
        payload:t
    }
}

export function verifyCodeSucess(){
    return{
        type:Actions.VERIFY_CODE_SUCCESS
    }
}

export function verifyCodeFailed(){
    return{
        type:Actions.VERIFY_CODE_FAILED
    }
}


export function sendSucess(){
    return{
        type:Actions.SEND_VERIFICATIONCODE_SUCCESS
    }
}

export function sendFailer(){
    return{
        type:Actions.SEND_VERIFICATIONCODE_FAILED
    }
}

export function goNext() {
    return{
        type:Actions.GONEXTSTEP
    }
}

export function goToVerifyDetailsPage() {
    return{
        type:Actions.GOTOVERIFYDETAILS
    }
}

export function goToVerificationPage() {
    return{
        type:Actions.GOTOVERIFICATIONPAGE
    }
}



export function goBack() {
    return{
        type:Actions.GOBACK
    }
}

export function getPhoneConfiguration(p) {
    return{
        type:Actions.GET_PHONEPREFIX,
        payload:p
    }
}

export function getTaxLabelList(p) {
    return{
        type:Actions.GET_TAXLABELLIST,
        payload:p
    }
}

export function getATaxLabels(){
    return(dispatch) =>{
        ApiService.getTaxLabels().then(function (res){
            SkbLogger.logDebugInfo('getTaxLabels: success Response',res)
            SkbLogger.applicationTrace('UserTypeSelection',1,'Self registration','Get tax labels from API successful',res)
            dispatch(getTaxLabelList(res.data))
        })
        .catch(function (error) {
            SkbLogger.logDebugInfo('getTaxLabels: error Response',error)
            SkbLogger.applicationException('UserTypeSelection','Self registration','Exception from get tax labels API',error)
        });
    }
}


export function getPhoneConfig(){
    return(dispatch) =>{
        ApiService.getCountryPhones().then(function (res){
            SkbLogger.logDebugInfo('sendGetPhoneConfigRequest: success Response',res)
            SkbLogger.applicationTrace('UserTypeSelection',1,'Self registration','Get phone config from API successful',res)
            dispatch(getPhoneConfiguration(res.data))
        })
        .catch(function (error) {
            SkbLogger.logDebugInfo('sendGetCountriesRequest: error Response',error)
            SkbLogger.applicationException('UserTypeSelection','Self registration','Exception from get phone config API',error)
        });
    }
}

export function getCountries(){
    return(dispatch) =>{
        ApiService.getCountries().then(function (res){
            SkbLogger.applicationTrace('TFACodeVerification',1,'Self registration','Get countries from API successful',res)
            dispatch(getCountriesSucess(res.data))
        })
        .catch(function (error) {
            SkbLogger.logDebugInfo('sendGetCountriesRequest: error Response',error)
            SkbLogger.applicationException('TFACodeVerification','Self registration','Exception from get countries API',error)
        });
    }
} 



export function getSkbDomains(){
    return(dispatch) =>{
        ApiService.getEmployeeDomains().then(function (res){
            SkbLogger.logDebugInfo('getSkbDomains: secuss Response',res)
            SkbLogger.applicationTrace('register',1,'Self registration','Get SKB email domains from API successful',res)
            dispatch(getSkbDomainList(res.data))
        })
        .catch(function (error) {
            SkbLogger.logDebugInfo('getSkbDomains: error Response',error)
            SkbLogger.applicationException('register','Self registration','Exception from get SKB email domains API',error)
        });
    }
}

export const submitRegister = (registerInfo,auth) => async (dispatch) =>{

    const userCountry=[];
    registerInfo.SelectedCountry.map((i) =>(
        userCountry.push({
            "countryVariableID":i
        })
    ));
    var postalAd=registerInfo.UserPostalAddress;
    if(registerInfo.PoAddressSameAsSt){
        postalAd=registerInfo.UserAddress;
    }
   
    var taxN = registerInfo.UserTaxNumber.replace(/[^0-9]/g, '');

    var identityType=''
    if(/^auth0\|/ig.test(auth.AuthUser.sub)){
        identityType = 'Non-Federated'
    }else if(/^google-oauth2\|/ig.test(auth.AuthUser.sub)){
        identityType = 'Google'
    }else{
        identityType = 'Microsoft-Live '
    }

    const userObject= {
        "Full Name"	:registerInfo.UserName,
        "Email Address":auth.AuthUser.email,
        "Identity Type": identityType ,
        "Address 1":registerInfo.UserAddress,
        "Phone Number":	registerInfo.SelectPhonePrefix[0].prefix + registerInfo.UserPhone
    } 

    const registrationObject= {
        "Full Name"	:registerInfo.UserName,
        "Email Address":auth.AuthUser.email,
        "Identity Type": identityType ,
        "Address 1":registerInfo.UserAddress,
        "Phone Number":	registerInfo.SelectPhonePrefix[0].prefix + registerInfo.UserPhone
    } 

    const bussinessObject= {
        "Business Name":registerInfo.UserCompanyName,
        "Business Number":taxN
    }

    const registrationObjectAndbussinessObject ={
        registrationObject,
        bussinessObject
    }

    const userObjectAndBussinessObject ={ 
        userObject ,
        registrationObject
    } ;


    try{
        dispatch(submittingRegister());
       var repsonse = await axios({
            method: 'PUT', 
            url: process.env.REACT_APP_API_URL+'users/'+ auth.AuthUser.sub,
            data: {
              auth0UserID:auth.AuthUser.sub,
              name: registerInfo.UserName,
              userLoginName: auth.AuthUser.email,
              company:{
                "name":registerInfo.UserCompanyName,
                "taxNumber":taxN,
                "streetAddress":registerInfo.UserAddress,
                "postalAddress":postalAd
              },
              userType:registerInfo.SelectedUserType,
              phone: registerInfo.SelectPhonePrefix[0].prefix + registerInfo.UserPhone,
              email: auth.AuthUser.email,
              userCountries:userCountry,
            },
            headers: {
              'content-type':'application/json',// ,'text/plain'
              'authorization': config.REACT_APP_API_SECRET,
              'X-CustomHeader' :auth.idToken
            }
        })
        if(repsonse.status === 202){
            SkbLogger.userSecurityEvent('VerifyContractorDetail',auth.AuthUser.email,'User security','User Registration','Success',
            '{RegistrationObject} has successfully linked to {BusinessObject}.',registrationObjectAndbussinessObject);

            SkbLogger.userSecurityEvent('VerifyContractorDetail',auth.AuthUser.email,'User security','User Registration','Success',
            '{RegistrationObject} has successfully completed the registration process.{UserObject} is created from {RegistrationObject}. ',userObjectAndBussinessObject);
            return true
        }else{
            
            SkbLogger.userSecurityEvent('VerifyContractorDetail',auth.AuthUser.email,'User security','User Registration','Fail',
            '{RegistrationObject} has failed to linked to {BusinessObject}.',registrationObjectAndbussinessObject);

            SkbLogger.userSecurityEvent('VerifyContractorDetail',auth.AuthUser.email,'User security','User Registration','Fail',
            '{RegistrationObject} has failed to complete the registration process.',registrationObject);

            return false
        }
    }catch(error){
        SkbLogger.applicationException('VerifyContractorDetail','Self registration','exception from submit self registration API',error)

        SkbLogger.userSecurityEvent('VerifyContractorDetail',auth.AuthUser.email,'User security','User Registration','Fail',
            '{RegistrationObject} has failed to linked to {BusinessObject}.',registrationObjectAndbussinessObject);


        SkbLogger.userSecurityEvent('VerifyContractorDetail',auth.AuthUser.email,'User security','User Registration','Fail',
        '{RegistrationObject} has failed to complete the registration process.',registrationObject);
        
        dispatch(updateSubmitError())
        return false
    }
 
    
}  

 /* export function submitRegister(registerInfo,auth){

    const userCountry=[];
    registerInfo.SelectedCountry.map((i) =>(
        userCountry.push({
            "countryVariableID":i
        })
    ));
    var postalAd=registerInfo.UserPostalAddress;
    if(registerInfo.PoAddressSameAsSt){
        postalAd=registerInfo.UserAddress;
    }
    const userData ={ "user": auth.AuthUser.email  } ;
    var taxN = registerInfo.UserTaxNumber.replace(/[^0-9]/g, '');

    SkbLogger.logDebugInfo("from submitRegister redux",registerInfo,auth,userCountry)
    return(dispatch) =>{
        dispatch(submittingRegister());
        axios({
            method: 'PUT', //you can set what request you want to be
            url: process.env.REACT_APP_API_URL+'users/'+ auth.AuthUser.sub,
            data: {
              auth0UserID:auth.AuthUser.sub,
              name: registerInfo.UserName,
              userLoginName: auth.AuthUser.email,
              company:{
                "name":registerInfo.UserCompanyName,
                "taxNumber":taxN,
                "streetAddress":registerInfo.UserAddress,
                "postalAddress":postalAd
              },
              userType:parseInt(registerInfo.SelectedUserType),
              phone: registerInfo.SelectPhonePrefix[0].prefix + registerInfo.UserPhone,
              email: auth.AuthUser.email,
              userCountries:userCountry,
            },
            headers: {
              'content-type':'application/json',// ,'text/plain'
              'authorization': config.REACT_APP_API_SECRET,
              'X-CustomHeader' :auth.idToken
            }
        }).then(function (res){
            dispatch(goNext())
            localStorage.clear();
            SkbLogger.userAuditEvent('VerifyContractorDetail',auth.AuthUser.email,'Self registration','Submit self registration','User submitted self registration successful',
            userData)
            SkbLogger.applicationTrace('VerifyContractorDetail',1,'Self registration','Submit self registration successful',res)
        })
        .catch(function (error) {
            SkbLogger.logDebugInfo('VerifyContractorDetail: error Response',error)
            SkbLogger.applicationException('VerifyContractorDetail','Self registration','exception from submit self registration API',error)
              dispatch(updateSubmitError())
        });
    }
}   */


export const verifyVerificationCode= (authProf,usertoken) => async (dispatch) =>{
        const tokenData = [ { "tokenName": "VerificationCode","tokenValue":usertoken } ];


        
    try {
        var response = await axios({
            method: 'PUT', 
            url: process.env.REACT_APP_API_URL+'users/'+ authProf.AuthUser.sub,
            data: {
              auth0UserID:authProf.AuthUser.sub,
              userTokens:tokenData
            },
            headers: {
              'content-type':'application/json',// ,'text/plain'
              'authorization': config.REACT_APP_API_SECRET,
              'X-CustomHeader' :authProf.idToken
            }
        });
        //202 updated successful
        if(response.status === 202){
            dispatch(verifyCodeSucess());
            SkbLogger.userSecurityEventWithRegistrationObject('VerifyContractorDetail','User security','User Registration','Success','{RegistrationObject} has successfully verified their email account.')
            return true;
        }else{
            dispatch(verifyCodeFailed());
            SkbLogger.userSecurityEventWithRegistrationObject('VerifyContractorDetail','User security','User Registration','Fail','{RegistrationObject} has failed to verify their email account.')
            return false;
        }
    } catch (error) {
        SkbLogger.userSecurityEventWithRegistrationObject('VerifyContractorDetail','User security','User Registration','Fail','{RegistrationObject} has failed to verify their email account.')
        dispatch(verifyCodeFailed());
        SkbLogger.applicationException('VerifyContractorDetail','Self registration','exception from verify verification code API',error)
        return false;
    }

}

export function  sendEmailVerificationCode(authProf){
    const userData ={ "user": authProf.AuthUser.email }  ;
    return(dispatch) =>{
        dispatch(getSkbDomains());       
        axios({
            method: 'POST', //you can set what request you want to be
            url: process.env.REACT_APP_API_URL+'users/'+ authProf.AuthUser.sub,
            data: {
              auth0UserID:authProf.AuthUser.sub,
              name:authProf.AuthUser.name,
              userLoginName:authProf.AuthUser.email, 
              company:'',
              userType:0,
              email:authProf.AuthUser.email,
              emailVerified:0,
              phone:'',
              managerName:''
              //countryVariableID:0
            },
            headers: {
              'content-type': 'application/json',
              'authorization': config.REACT_APP_API_SECRET,
              'X-CustomHeader' :authProf.idToken
            }
        }).then(function (res){
            SkbLogger.logDebugInfo('sendEmailVerificationCode: Response',res)
            SkbLogger.applicationTrace('TFACodeVerification',1,'Self registration','Send verification code successful',res);
           // SkbLogger.userSecurityEvent('TFACodeVerification',authProf.AuthUser.email,'Account created','Create new account in Minerva','User account created in Minerva',userData);
            dispatch(checkIsSKBUser(authProf.AuthUser.email));
            dispatch(sendSucess())
        })
        .catch(function (error) {
            SkbLogger.logDebugInfo('TFACodeVerification: error Response',error)
            SkbLogger.applicationException('TFACodeVerification','Self registration','exception from send verification code API',error)
            dispatch(checkIsSKBUser(authProf.AuthUser.email));
              dispatch(sendFailer())
        });
    }
}