import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Alert from '@material-ui/lab/Alert';
// import SkbIcon from '../../../skb_controls/components/SkbIcon';


// import SkbButton from '../../../skb_controls/components/SkbButton';
// import MessageIcon from '@material-ui/icons/Message';
// import Typography from '@material-ui/core/Typography';

// import { Grid } from '@material-ui/core';

// import { SnackbarProvider } from 'notistack';



import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    "&.MuiTypography-h1":{
      // marginTop:25,
      // color:theme.palette.accent.main,
      // verticalAlign: 'text-top',
      // display: 'inline'
  }
  },
}));

export function NotificationInvalidBarcode() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbar("Invalid barcode, please try again.", { 
      transitionDuration: { enter: 0, appear: 0,  exit: 0 },
      variant: "warning",
      autoHideDuration : 1000,
      preventDuplicate : true 
  })

  

  return (
    <div />
  );
}
