import axios from "axios";
import { SkbLogger,SeverityLevel } from ".";

/////////// realtime api ///////////////
/**
 * 
 * @param {*} auth0UserID 
 * @param {*} idToken 
 * @param {*} apiKey 
 * @param {*} referenceEntity example value 'SETTING'. blank value '' means all types of referenceEntity
 * @param {*} referenceID example value '123'. zero value '0' means all ROOT clusters
 * @param {*} startDate format yyyy-mm-dd
 * @param {*} endDate format yyyy-mm-dd
 * @returns 
 */
export async function queryDataClusters(auth0UserID,idToken,apiKey,referenceEntity, referenceID=0, startDate, endDate) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters?referenceEntity=${referenceEntity}&referenceID=${referenceID}&startdate=${startDate}&enddate=${endDate}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Verbose,'Call API','GET '+apiURL,{referenceEntity:referenceEntity,referenceID:referenceID});
    return await axios.get(apiURL);
    /*
    return await axios({
        method: 'GET', 
        url: apiURL,
        headers: {
            'content-type': 'application/json',
            'authorization': config.REACT_APP_API_SECRET,
            'X-CustomHeader' :idToken
        }
    });
    */
}

export async function getDataItemsInCluster(auth0UserID,idToken,apiKey,clusterResourceID) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${clusterResourceID}/dataitems`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Verbose,'Call API','GET '+apiURL,clusterResourceID);
    return await axios.get(apiURL);

}

export async function getDataItemsInClusterByKeys(auth0UserID,idToken,apiKey,clusterResourceID, keys) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${clusterResourceID}/dataitems/keys/${keys||"null"}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Verbose,'Call API','GET '+apiURL,clusterResourceID);
    return await axios.get(apiURL);

}

export async function getOneDataCluster(auth0UserID,idToken,apiKey,resourceID) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${resourceID}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Verbose,'Call API','GET '+apiURL,resourceID);
    return await axios.get(apiURL);

}

export async function getOneDataItem(auth0UserID,idToken,apiKey,clusterResourceID,itemResourceID) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${clusterResourceID}/dataitems/${itemResourceID}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Verbose,'Call API','GET '+apiURL,{clusterResourceID:clusterResourceID,itemResourceID:itemResourceID});
    return await axios.get(apiURL);

}

export async function saveDataCluster(auth0UserID,idToken,apiKey,resourceID,clusterData) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${resourceID}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Call API','POST '+apiURL,clusterData);
    return await axios.post(apiURL,clusterData);

}

export async function saveDataItem(auth0UserID,idToken,apiKey,clusterResourceID,itemResourceID,itemData) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${clusterResourceID}/dataitems/${itemResourceID}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    /* SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Call API','POST '+apiURL,itemData);
    return await axios.post(apiURL,itemData); */
    var itemDataWithLocalContent=JSON.parse(JSON.stringify(itemData));    
    itemDataWithLocalContent.localArtefactContent='';    
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Call API','POST '+apiURL,itemDataWithLocalContent);    
    return await axios.post(apiURL,itemDataWithLocalContent);
 
}

export async function deleteDataCluster(auth0UserID,idToken,apiKey,resourceID) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${resourceID}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Call API','DELETE '+apiURL,resourceID);
    return await axios.delete(apiURL);

}

export async function deleteDataItem(auth0UserID,idToken,apiKey,clusterResourceID,itemResourceID) {
    const userURL = process.env.REACT_APP_API_URL + `users/${auth0UserID}/`;
    const apiURL = userURL + `dataclusters/${clusterResourceID}/dataitems/${itemResourceID}`;
    axios.defaults.headers.common['content-type']='application/json';
    axios.defaults.headers.common['X-CustomHeader']=idToken;
    axios.defaults.headers.common['authorization']=apiKey;
    SkbLogger.applicationTrace('Data Sync',SeverityLevel.Information,'Call API','DELETE '+apiURL,itemResourceID);
    return await axios.delete(apiURL);

}




////////// change sync api ////////////



