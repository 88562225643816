import { SkbLogger, ApiService } from '../../services';

export const Actions = {
    START_PHOTO_SYNC: "START_PHOTO_SYNC",
    FINISH_PHOTO_SYNC: "FINISH_PHOTO_SYNC",
    START_METADATA_SYNC: "START_METADATA_SYNC",
    FINISH_METADATA_SYNC: "FINISH_METADATA_SYNC",
    GO_ONLINE: "GO_ONLINE",
    GO_OFFLINE: "GO_OFFLINE",
};

export const photoSyncStart = () => { return { type: Actions.START_PHOTO_SYNC }; }
export const photoSyncFinish = () => { return { type: Actions.FINISH_PHOTO_SYNC }; }
export const metadataSyncStart = () => { return { type: Actions.START_METADATA_SYNC }; }
export const metadataSyncFinish = () => { return { type: Actions.FINISH_METADATA_SYNC }; }
export const changeToOnline = () => { return { type: Actions.GO_ONLINE }; }
export const changeToOffline = () => { return { type: Actions.GO_OFFLINE }; }

export function setIsOnline(trueOrFalse) {
    return (dispatch) => {
        SkbLogger.logDebugInfo('dispatching setIsOnline '+trueOrFalse);
        if(trueOrFalse==true) dispatch(changeToOnline());
        else if(trueOrFalse==false) dispatch(changeToOffline());
    };
}

export function setIsPhotoSyncing(trueOrFalse) {
    return (dispatch) => {
        SkbLogger.logDebugInfo('dispatching setIsPhotoSyncing '+trueOrFalse);
        if(trueOrFalse==true){
            dispatch(photoSyncStart());
            setTimeout(()=>{
                dispatch(photoSyncFinish());
            },40000); //to be slightly less than the ms in sync service worker (sw-custom.js POLLING_TIMESUP_SEC_PHOTO_SYNC)
        }
        else if(trueOrFalse==false) dispatch(photoSyncFinish());
    };
}

export function setIsMetadataSyncing(trueOrFalse) {
    return (dispatch) => {
        SkbLogger.logDebugInfo('dispatching setIsMetadataSyncing '+trueOrFalse);
        if(trueOrFalse==true) {
            dispatch(metadataSyncStart());
            setTimeout(()=>{
                dispatch(metadataSyncFinish());
            },40000); //to be slightly less than match the ms in sync service worker (sw-custom.js POLLING_TIMESUP_SEC_CHANGES_SYNC)
        
        }
        else if(trueOrFalse==false) dispatch(metadataSyncFinish());
    };
}