import React, { useState, useEffect } from "react";
import SkbButton from '../../skb_controls/components/SkbButton';
import SkbTextField from '../../skb_controls/components/SkbTextField';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as regi from '../../Redux/actions/register'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import PhoneIcon from '@material-ui/icons/Phone';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import SkbIcon from '../../skb_controls/components/SkbIcon';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { Select, MenuItem, Divider, Hidden } from '@material-ui/core';
import { SkbLogger } from "../../services";
import { useSnackbar } from 'notistack';
import { isAddressedValid } from '../../utils/googleapi';
import * as assistanceNumber from '../../Redux/actions/callForAssistance';
import { ABNValidation } from '../../utils/validateABNNumber';
import Script from 'react-load-script';
import { config } from '../../utils/config';
import { SkbSwitch } from '../../skb_controls/components/SkbSwitch';
import FullpageLoader from '../FullpageLoader'
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles((theme) => ({
    /*     paper: {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }, */
    header: {
        paddingBottom: theme.spacing(1),
        textAlign: "center",
    },
    gridMargin: {
        paddingBottom: theme.spacing(1),
        /*    marginBottom: theme.spacing(1), */
        alignSelf: "center"

    },
    labelStyle: {
        alignSelf: "center"
    },
    addressStyle: {
        addingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        alignSelf: "center"
    },
    searchStyle: {
        width: "100%"
    },
    icons:
    {
        [theme.breakpoints.down('sm')]: {
            // marginTop: "6px",
            height: 17,
            width: 17
        },
        [theme.breakpoints.up('md')]: {
            // marginTop:"12px",
            height: 25,
            width: 25
        },
    },
    searchInput: {
        width: "100%",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: 18,

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
    phonecountryselector: {
        marginRight: theme.spacing(1)
    },
    icon: {
        width: 48,
        marginRight: 4,
        color: theme.palette.primary.main,
    },
    subHeader: {
        marginTop: theme.spacing(1)
    },
    input: {
        width: `calc(100% - 52px)`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: 18,

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
    searchBck: {
        backgroundColor: theme.palette.background.default,
    },
    inputlabel: {
        [theme.breakpoints.between('xs', 'sm')]: {
            textAlign: "left",

        },
        [theme.breakpoints.up('sm')]: {
            textAlign: "right",
        },
        alignSelf: "center",
        paddingRight: theme.spacing(1),

    }
}));



function VerifyContractorDetail(props) {
    const classes = useStyles();
    const [aBNErrorMsg, setABNErrorMsg] = useState('');
    const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
    const [showPhoneNumberError, SetPhoneNumberError] = useState(false);
    const [showABNError, SetABNError] = useState(false);
    const [disableSubmitButton, SetDisableSubmitButton] = useState(true);
    const [disableBackButton, SetDisableBackButton] = useState(false);
    const userData = { "user": props.user.AuthUser.email };
    const [stAddIsValid, setStAddIsValid] = useState(true);
    const [showLoader, setshowLoader] = useState(false);
    const [userName, setUserName] = useState(props.register.UserName || (props.isFederatedUser ? props.user.AuthUser.name : ""));
    const [userNameErrorMsg, setUserNameErrorMsg] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const notifyError = (message, variant) => {
        enqueueSnackbar(message, {
            variant: variant
        })
    };


    useEffect(() => {

        if (props.isFederatedUser && !props.register.UserName) props.updateUserName(props.user.AuthUser.name);

        if (props.register.UserPhone.length !== 0) {
            props.register.SelectPhonePrefix.map((i) => {
                if (i.landlineLength === props.register.UserPhone.length || props.register.UserPhone.length === i.mobileLength) {
                    SetPhoneNumberError(false)
                    setPhoneErrorMsg('');
                } else {
                    SetPhoneNumberError(true)
                    setPhoneErrorMsg('Invalid Australian phone number.');

                    //setPhoneErrorMsg(`Invalid ${props.register.SelectPhonePrefix[0].countryName} phone number.`); dropdown is not required at this stage
                }
            })
        } else {
            SetPhoneNumberError(false)
            setPhoneErrorMsg('');
        }
        //UserTaxNumber
        if (props.register.UserTaxNumber === '') {
            SetABNError(false)
        }
        //DON'T need validate address for PHO-759
        if (props.register.UserPhone.length === 0 || !stAddIsValid ||
            //props.register.UserAddress === '' ||
            showPhoneNumberError
            /*  || props.register.UserTaxNumber === '' || showABNError  || props.register.UserCompanyName === '' */
            //|| (props.register.UserPostalAddress === '' && !props.register.PoAddressSameAsSt)
            || !/^[A-Za-zÀ-ž'\-]{2,}(\s+[A-Za-zÀ-ž'\-]{2,}){1,}\s*$/ig.test(userName || "")) {
            SetDisableSubmitButton(true)
        }
        else {
            SetDisableSubmitButton(false)
        }
        if (props.register.SelectPhonePrefix.length === 0) {
            SetPhoneNumberError(true)
            setPhoneErrorMsg('');
        }

        /*      if (props.register.submittingRegister) {
                 SetDisableSubmitButton(true)
             } */
        if (props.register.submitError !== '') {
            notifyError(props.register.submitError, 'error');
        }

    }, [props.register, aBNErrorMsg, phoneErrorMsg, stAddIsValid, userName])



    const validateStAdd = () => {
        setTimeout(function () {
            var st = document.getElementById('vc-input-streetAd').value;

            if (st !== '') {
                console.log(st);
                isAddressedValid(st, true).then(
                    function (response) {
                        if (response) {
                            setStAddIsValid(true);
                        } else {
                            console.log('error from address', response);
                            setStAddIsValid(false);
                            //SetDisableBackButton(false);
                            notifyError('Please select valid address from search result. If search results do not include your address, select closest match and contact Skybridge.', 'error');
                        }

                    });
            } else {
                setStAddIsValid(true);
            }
        }, 1000);

    }

    const validateAndGoNext = () => {
        setshowLoader(true);
        //SetDisableBackButton(true);
        setTimeout(function () {
            var st = document.getElementById('vc-input-streetAd').value;
            if (stAddIsValid) {
                isAddressedValid(st, true).then(
                    function (response) {
                        if (response) {
                            props.submitRegisterInfor(props.register, props.user).then((response) => {
                                if (response) {
                                    props.goNextStep();
                                } else {
                                    notifyError('Submit failed. If you continue to see this message, please contact the Skybridge Team using the Call For Assistance button below.', 'error');
                                    setshowLoader(false);
                                }
                            })
                        } else {

                            var identityType = ''
                            if (/^auth0\|/ig.test(props.user.AuthUser.sub)) {
                                identityType = 'Non-Federated'
                            } else if (/^google-oauth2\|/ig.test(props.user.AuthUser.sub)) {
                                identityType = 'Google'
                            } else {
                                identityType = 'Microsoft-Live '
                            }

                            let registrationObject = {
                                "Full Name": userName,
                                "Email Address": props.user.AuthUser.email,
                                "Identity Type": identityType,
                                "Address 1": props.register.UserAddress,
                                "Phone Number": props.register.SelectPhonePrefix[0].prefix + props.register.UserPhone
                            }
                            let taxN = props.register.UserTaxNumber.replace(/[^0-9]/g, '');
                            let bussinessObject = {
                                "Business Name": props.register.UserCompanyName,
                                "Business Number": taxN
                            }

                            let registrationObjectAndbussinessObject = {
                                registrationObject,
                                bussinessObject
                            }

                            /*      SkbLogger.logDebugInfo('From verify page...',registrationObjectAndbussinessObject)*/
                            /*   SkbLogger.logDebugInfo('From verify page...',{registrationObject})  */

                            SkbLogger.userSecurityEvent('VerifyContractorDetail', props.user.AuthUser.email, 'User security', 'User Registration', 'Fail',
                                '{RegistrationObject} has failed to linked to {BusinessObject}.', registrationObjectAndbussinessObject);


                            SkbLogger.userSecurityEvent('VerifyContractorDetail', props.user.AuthUser.email, 'User security', 'User Registration', 'Fail',
                                '{RegistrationObject} has failed to complete the registration process.', { registrationObject });

                            setshowLoader(false);
                        }
                    });
            }
        }, 2000);
    }


    const updateSelectedPrefixAndLog = (e) => {
        props.updateSelectedPrefix(e);
        SkbLogger.applicationTrace('VerifyContractorDetail', 0, 'Self Registraction', `User selected the phone prefix for country Id ${e}`, userData);
    }
    const validateAndUpdatePhone = (e) => {
        if (isNaN(Number(e))) {
            SetPhoneNumberError(true);
            setPhoneErrorMsg(`Invalid ${props.register.SelectPhonePrefix[0].countryName} phone number.`);
        } else {
            setPhoneErrorMsg('');
            props.updatePhone(e);
        }
    }
    const validateAndUpdateUserName = (userName) => {
        setUserName(userName);
        props.updateUserName(userName);
        if(!/^[A-Za-zÀ-ž'\-]{2,}(\s+[A-Za-zÀ-ž'\-]{2,}){1,}\s*$/ig.test(userName || "")){
            setUserNameErrorMsg("Please provide a valid name");
        }else{
            setUserNameErrorMsg("");
            //setUserName(userName);
            //props.updateUserName(userName);
        }
    }

    const validateAndUpdateTaxNumber = (e) => {
        var res = true;
        props.updateTaxNumber(e);
        if (e !== "") {
            res = ABNValidation(e);
        }
        if (!res) {
            SetABNError(true)
            setABNErrorMsg('Invalid ABN.');
        } else {
            SetABNError(false)
            setABNErrorMsg('');
        }
    }

    const goPreStepAndLog = () => {
        if (props.register.countries.length > 1) {
            props.updateAssistanceNumbers([]);
            props.goPreStep();
        } else {
            props.updateAssistanceNumbers([]);
            props.goToVerificationCode();
        }

        SkbLogger.applicationTrace('VerifyContractorDetail', 0, 'Self Registraction', 'User clicked go back button', userData);
    }

    var autocomplete = null;


    const handleScriptLoad = () => {
        const options = {
            types: ['address'],
            //if props.register.SelectedCountryAcronym []
            //update following codes with componentRestrictions: {country: props.register.SelectedCountryAcronym },
            componentRestrictions: { country: ['au'] },
        };

        /*global google*/ // To disable any eslint 'google not defined' errors
        autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('vc-input-streetAd'),
            options,
        );

        autocomplete.setFields(['address_components', 'formatted_address']);
        autocomplete.addListener('place_changed', () => {
            const addressObject = autocomplete.getPlace();
            const address = addressObject.address_components;
            // Check if address is valid
            if (address) {
                props.updateSAddress(addressObject.formatted_address);
            }

        });
    }

    return (
        <React.Fragment>
            {showLoader && <FullpageLoader />}
            <Container component="main" maxWidth="md" >
                <Grid container justify="center" className={classes.header}>
                    <Typography id="vc-header" paragraph variant="h2" >
                        Verify Personnel Details
                    </Typography>
                </Grid>
                <Grid container className={classes.gridMargin}>

                    <Grid item sm={1} xs={1} md={3} className={classes.labelStyle}> </Grid>
                    <Grid item sm={11} xs={11} md={6} className={classes.gridMargin}>
                        <SkbTextField autoComplete="off" error={userNameErrorMsg} helperText={userNameErrorMsg} required  id="vc-text-name" value={userName} fullWidth autoFocus
                            onChange={e => validateAndUpdateUserName(e.target.value)} label="Full Name"
                            inputProps={{ style: { textAlign: 'left' } }} />
                    </Grid>
                    <Grid item sm={0} xs={0} md={3} ></Grid>
                    <Grid item sm={1} xs={1} md={3} className={classes.labelStyle}>
                        <Typography variant="body1" className={classes.inputlabel}>
                            <SkbIcon className={classes.icons} icon={PhoneIcon} />
                        </Typography>
                        {/*user request to hide the prefix for now*/}
                        {1 === 2 && <Select
                            className={classes.phonecountryselector}
                            id="vc-select-prefix"
                            disabled
                            autoWidth={true}
                            value={props.register.SelectPhonePrefix[0].countryId}
                            onChange={e => updateSelectedPrefixAndLog(e.target.value)}
                            MenuProps={{ classes: { paper: classes.selectMenu } }}
                        >
                            {props.register.PhonePrefixList.map((i, idx) => {
                                return (<MenuItem key={idx} value={i["countryId"]}>{i["prefix"]}</MenuItem>);

                            })}
                        </Select>}
                    </Grid>
                    <Grid item sm={11} xs={11} md={6} className={classes.gridMargin}>
                        <SkbTextField autoComplete="off" error={showPhoneNumberError} helperText={phoneErrorMsg} id="vc-text-phone" required
                            value={props.register.UserPhone} inputProps={{ style: { textAlign: 'left' } }} fullWidth label="Mobile"
                            onChange={e => validateAndUpdatePhone(e.target.value)} type="number" />
                    </Grid>
                    <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid>
                    <Grid item sm={1} xs={1} md={3} className={classes.labelStyle}>
                        <Typography variant="body1" className={classes.inputlabel}>
                            <SkbIcon className={classes.icons} icon={EmailIcon} />
                        </Typography>
                    </Grid>
                    <Grid item sm={11} xs={11} md={6} className={classes.gridMargin}>
                        <SkbTextField autoComplete="off" required lable="work email" id="vc-text-name" value={props.user.AuthUser.email} fullWidth
                            label="Email"
                            inputProps={{ style: { textAlign: 'left' }, disabled: true }} />
                    </Grid>

                    <div style={{visibility:"hidden", display:"none"}}> {/* PHO-759: Remove address in identity management */}
                        <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid>
                        <Grid item sm={1} xs={1} md={3} >
                        </Grid>
                        <Grid item sm={11} xs={11} md={6} >
                            <Typography paragraph variant="h3" className={classes.subHeader}>
                                Based At Location
                        </Typography>
                        </Grid>
                        <Grid item sm={0} xs={0} md={3} ></Grid>

                        {/*                    <Grid item sm={1} xs={1} md={3} className={classes.gridMargin}>
                    <Typography  variant="body1" className={classes.inputlabel}>
                        <SkbIcon className={classes.icons}  icon={BusinessIcon} />
                        </Typography>
                    </Grid>
                    <Grid item sm={11} xs={11} md={6} className={classes.gridMargin}>



                            <IconButton className={classes.icon} aria-label="search"  >
                                <SearchIcon />
                            </IconButton> 
                         <Paper className={classes.searchBck} >
                            <Script url={`https://maps.googleapis.com/maps/api/js?key=${config.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&language=en-us`}
                                onLoad={handleScriptLoad}
                            />
                            <IconButton className={classes.icon} aria-label="search"  >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                autoComplete="off"
                                type="search"
                                 className={classes.input} 
                                value={props.register.UserAddress}
                                onChange={e => props.updateSAddress(e.target.value)}
                                id="vc-input-streetAd2"
                                onBlur={validateStAdd}
                                placeholder="Search address"
                                inputProps={{ 'aria-label': 'search icons' ,
                                classes: {
                                    input: classes.input,
                                } }}
                            />

                        </Paper> 
                    </Grid>
                    <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid> */}
                        <Grid item sm={1} xs={1} md={3} className={classes.labelStyle}>
                            <Typography variant="body1" className={classes.inputlabel}>
                                <SkbIcon className={classes.icons} icon={BusinessIcon} />
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={11} md={6} className={classes.addressStyle}>
                            <Script url={`https://maps.googleapis.com/maps/api/js?key=${config.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&language=en-us`}
                                onLoad={handleScriptLoad}
                            />
                            <FormControl className={classes.searchStyle}>
                                <Input
                                    className={classes.searchInput}
                                    autoComplete="off"
                                    value={props.register.UserAddress}
                                    onChange={e => props.updateSAddress(e.target.value)}
                                    id="vc-input-streetAd"
                                    onBlur={validateStAdd}
                                    placeholder="Search address"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SkbIcon className={classes.icons} icon={SearchIcon} />
                                        </InputAdornment>
                                    }
                                /* inputProps={{ 
                                        classes: {
                                            input: classes.input,
                                        },
                                        endAdornment:(
                                            <InputAdornment position="end">
                                                <SkbIcon className={classes.icons} icon={SearchIcon} />
                                            </InputAdornment>
                                         ) 
                                        }}
                                 */
                                />
                            </FormControl>

                        </Grid>
                        <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid>


                        {!stAddIsValid &&
                            <React.Fragment>
                                <Grid item sm={1} xs={1} md={3} className={classes.gridMargin}>

                                </Grid>
                                <Grid item sm={11} xs={11} md={6} className={classes.gridMargin}>
                                    <Typography style={{ color: "red" }} id="vc-text-error" display={false} paragraph display="block" variant="caption">Invalid Address</Typography>
                                </Grid>
                                <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid>
                            </React.Fragment>}
                        <Grid item sm={3} xs={3} md={3} className={classes.gridMargin}>
                            <Typography paragraph variant="body2" >
                                Postal address same as above
                        </Typography>

                        </Grid>
                        <Grid item sm={9} xs={9} md={6} className={classes.gridMargin}>
                            <SkbSwitch id="primary-switch" primary val={props.register.PoAddressSameAsSt} setVal={props.updatePoSwitch} showOnOffText={false} />
                        </Grid>
                        <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid>
                        {!props.register.PoAddressSameAsSt &&
                            <React.Fragment>
                                <Grid item sm={1} xs={1} md={3} className={classes.labelStyle}>
                                    <Typography variant="body1" className={classes.inputlabel}>
                                        <SkbIcon className={classes.icons} icon={MarkunreadMailboxIcon} />
                                    </Typography>
                                </Grid>
                                <Grid item sm={11} xs={11} md={6} className={classes.gridMargin}>
                                    <SkbTextField autoComplete="off" required lable="postalAddress" id="vc-input-postal" value={props.register.UserPostalAddress} fullWidth autoFocus
                                        onChange={e => props.updatePAddress(e.target.value)} label="Postal address"
                                        inputProps={{ style: { textAlign: 'left' } }} />
                                </Grid>
                                <Grid item sm={0} xs={0} md={3} className={classes.gridMargin}></Grid>

                            </React.Fragment>}

                    </div>
                </Grid>

                <Grid container className={classes.gridMargin}>
                    <Grid item xs={6} sm={6} >
                        <Grid container justify="center">
                            <SkbButton disabled={disableBackButton} id="ut-back-button" name={"Small Primary"} primary size="small" text="< Back" onClick={() => goPreStepAndLog()} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} >
                        <Grid container justify="center">
                            <SkbButton disabled={disableSubmitButton} id="ut-next-button" name={"Small Primary"} primary size="small" text="Submit" onClick={() => validateAndGoNext()} /> {/* disabled={(props.register.RegisterStep ===1)} */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        user: state.auth,
        register: state.register,
        isFederatedUser: !/^auth0\|/ig.test(state.auth.AuthUser.sub)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updatePhone: p => dispatch(regi.updateUserPhone(p)),
        updateUserName: userName => dispatch(regi.updateUserName(userName)),
        updateCompany: p => dispatch(regi.updateCompanyName(p)),
        updateSAddress: p => dispatch(regi.updateStreetAddress(p)),
        updatePAddress: p => dispatch(regi.updatePostalAddress(p)),
        goNextStep: () => dispatch(regi.goNext()),
        goPreStep: () => dispatch(regi.goBack()),
        updateSelectedPrefix: e => dispatch(regi.getUserSelectedPhonePrefix(e)),
        updateTaxNumber: e => dispatch(regi.updateUserTaxNumber(e)),
        submitRegisterInfor: (a, e) => dispatch(regi.submitRegister(a, e)),
        updateSelecteTaxLabel: e => dispatch(regi.updateUserTaxNumber(e)),
        updateAssistanceNumbers: (e) => dispatch(assistanceNumber.updatePhoneNumber(e)),
        goToVerificationCode: () => dispatch(regi.goToVerificationPage()),
        updatePoSwitch: (e) => dispatch(regi.updatePoaddressSwitch(e))


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VerifyContractorDetail);



