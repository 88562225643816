import axios from "axios";
import { config } from './config';

export async function isAddressedValid(addr, bypassValidation=false) {

    if(bypassValidation) return true;//DON'T need validate address for PHO-759
    try {
        let key = config.REACT_APP_GOOGLE_MAP_KEY;
        if(process.env.NODE_ENV === 'test') key = 'AIzaSyCqK1gDtEQhphO8lZHlitvBJ4cdQara5QM';

        let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(addr)}&language=en-us&key=${key}`;
        // console.log("url", url);
        var response = await axios({
            method: 'GET', //you can set what request you want to be
            url: url,
            headers: {
                'content-type': 'application/json',
                'Referer': window.origin || 'localhost',
            }
        });


        let data = null; 
        if(response.data && response.data.results && Array.isArray(response.data.results)) data = response.data.results[0];
        // console.log("response", data);     
        if (data &&  data.formatted_address && data.formatted_address.replace(/\s/ig, '').startsWith(addr.replace(/\s/ig, '')))
            return true;
        else return false;
    } catch (error) {
        // console.log("response", error);

        return false;
    }

}