import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import { Paper , Typography} from '@material-ui/core';
import SingleConsignmentTask from './SingleConsignmentTask';
import { makeStyles } from '@material-ui/core/styles';
import {
   
    selectSearchByStockCodeResults,
    selectSearchByStockCodeError,

    
    selectSearchBySerialNumberResults,
    selectSearchBySerialNumberError,


    selectSearchByConsignmentIdResults,
    selectSearchByConsignmentIdError,

} from '../../../Redux/ConsignmentTasks/selectors';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme=>({
    root : {
        marginBottom : "1em",
    },
    resultHeading : {
        textAlign : "center",
        padding : "2em auto",
        borderBottom : `1px solid ${theme.palette.primary.main}`,
        color : theme.palette.primary.main,
    },
    emptyMessage : {
        textAlign : "center",
        padding : "2em auto",
        color : "#d00000",
    }
}))

const SearchConsignmentResults = ({
    // selectors
    stockCodeResults,
    serialNumberResults,
    consignmentIdResults,

    stockCodeError,
    serialNumberError,
    consignmentIdError,


}) =>{
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(stockCodeError)
            enqueueSnackbar(
                `Search by stock code : ${stockCodeError?.message}`,
                {variant : "error"}    
            )
    // eslint-disable-next-line
    },[stockCodeError])
    useEffect(()=>{
        if(serialNumberError)
            enqueueSnackbar(
                `Search by serial number : ${serialNumberError?.message}`,
                {variant : "error"}
            )
    // eslint-disable-next-line
    },[serialNumberError])
    useEffect(()=>{
        if(consignmentIdError)
            enqueueSnackbar(
                `Search by serial number : ${consignmentIdError?.message}`,
                {variant : "error"}
            )
    // eslint-disable-next-line
    },[consignmentIdError])



    useEffect(()=>{
        if(stockCodeResults && stockCodeResults.length === 0)
            enqueueSnackbar(
                `No Match found by Stock Code`,
                {variant : "warning"}
            )
    // eslint-disable-next-line
    },[stockCodeResults])
    useEffect(()=>{
        if(serialNumberResults && serialNumberResults.length === 0)
            enqueueSnackbar(
                "No match found by serial number",
                {variant : "warning"}
            )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serialNumberResults])
    useEffect(()=>{
        if(consignmentIdResults && consignmentIdResults.length === 0)
            enqueueSnackbar(
                "No match found by consignment ID",
                {variant : "warning"}
            )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[consignmentIdResults])

    return(
        <Paper
            className={classes.root}
        >
            {
                stockCodeResults
                &&
                (
                    stockCodeResults.length > 0
                    &&
                    <div>
                        <Typography variant="h6" className={classes.resultHeading}>
                            Found match by stock code
                        </Typography>
                        {
                            stockCodeResults.map((_t,i)=>(<SingleConsignmentTask _t={_t} key={i} />))
                        }
                    </div>
                )
            }
            {
                serialNumberResults
                &&
                (
                    serialNumberResults.length > 0
                    &&
                    <div>
                        <Typography variant="h6" className={classes.resultHeading}>
                            Found match by serial number
                        </Typography>
                        {
                            serialNumberResults.map((_t,i)=>(<SingleConsignmentTask _t={_t} key={i} />))
                        }
                    </div>
                    
                )
            }
            {
                consignmentIdResults
                &&
                (
                    consignmentIdResults.length > 0
                    &&
                    <div>
                        <Typography variant="h6" className={classes.resultHeading}>
                            Found match by consignment id
                        </Typography>
                        {
                            consignmentIdResults.map((_t,i)=>(<SingleConsignmentTask _t={_t} key={i} />))
                        }
                    </div>
                )
            }
        </Paper>
    );
}

const mapState = state =>({
    stockCodeResults : selectSearchByStockCodeResults(state),
    serialNumberResults : selectSearchBySerialNumberResults(state),
    consignmentIdResults : selectSearchByConsignmentIdResults(state),

    stockCodeError : selectSearchByStockCodeError(state),
    serialNumberError : selectSearchBySerialNumberError(state),
    consignmentIdError : selectSearchByConsignmentIdError(state),
});
const mapDispatch = dispatch =>({

})

export default connect(
    mapState,
    mapDispatch,
)(SearchConsignmentResults);