import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title, FullscreenExit } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            maxWidth: 200,
        },

        // '& .MuiSelect-select':
        // {width:400},

        display: "inline",
        clear: "none",
    },
    paper: {
        width: 400,
    },
    formControl: {
        margin: theme.spacing(1),
        maxWidth: 400,
        minWidth:200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    // iconButton: {
    //     color: theme.palette.buttonPrimary.main,
    // },
    selectMenu: {
        backgroundColor: theme.palette.background.default,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
       // maxWidth: 'auto',
    }
}));



/**
 * 
 * @property {Title}: title of the select
 * @property {value}: selected value of select
 * @property {items}: the options of selected items, it can be a string arrary or array of object having properties 'value' and 'text' 
 * @property {multiple}: ture: multiple selected
 */
export function SkbSelect({ Title, value, items, multiple , valueChanged,disabled=false, error=false, helperText="", ...other }) {
    const classes = useStyles();

   
    
    const [val, setVal] = React.useState(multiple?[value]:value);
    useEffect(()=>{
        if(!multiple && val === value) return;
        
        setVal(multiple?[value]:value)
    }, [value])
    const handleChange = event => {
        setVal(event.target.value);
        if(valueChanged && typeof(valueChanged) === 'function')
        {
            valueChanged(event.target.value)
        }
      };
    const id = (Title || new Date().getTime().toString()).replace(/\s/ig, '');
    if (!multiple)
        return (
            <FormControl className={classes.formControl} disabled={disabled} error={error}>
                <InputLabel className={classes.root} id={`select-${id}-label`}>{Title}</InputLabel>
                <Select
                    labelId={`select-${id}-label`}
                    id={`select-${id}`}
                    //autoWidth = {true}
                    value={val}
                     onChange={handleChange}
                    MenuProps={{ classes: { paper: classes.selectMenu } }}                   

                >
                    {items.map((i, idx) => {
                        if (typeof i === "string")
                            return (<MenuItem key={idx} value={i}>{i}</MenuItem>);
                        else
                            return (<MenuItem key={idx} value={i["value"]}>{i["text"]}</MenuItem>);

                    })}

                </Select>
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        );
    else
        return (
            <FormControl className={classes.formControl} disabled={disabled} error={error} >
                <InputLabel className={classes.root} id={`select-${id}-label`}>{Title}</InputLabel>
                <Select
                    labelId={`select-${id}-label`}
                    id={`select-${id}`}
                    //autoWidth = {true}
                    multiple
                    value={val}
                    onChange={handleChange}
                    renderValue={selected => selected.join(', ')}
                    MenuProps={{ classes: { paper: classes.selectMenu } }}

                    {...other}
                >
                    {items.map((i, idx) => {
                        if (typeof i === "string")
                            return (<MenuItem key={idx} value={i}>
                                <Checkbox color='primary' checked={val.indexOf(i) > -1} />
                                <ListItemText primary={i} />
                            </MenuItem>);
                        else
                        return (<MenuItem key={idx} value={i["value"]}>
                            <Checkbox color='primary' checked={val.indexOf(i["value"]) > -1} />
                            <ListItemText primary={i["text"]} />
                        </MenuItem>);
                           

                    })}

                </Select>
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        );
}