import { SkbLogger, StockService, SeverityLevel } from '../../services';
import _ from 'lodash';
import * as misc from '../../utils/misc';
import { crypts } from '../../utils/crypts';
import { Actions } from '../reducers/contractorStock';
/**
 * ModuleInfo: define task module infor used for log
 */
export const ModuleInfo = {
    moduleName: "MinervaContractorStore",
    category: "Contractor stock  Store Actions"

}

export function refreshStockLookup(idToken) {
    return async (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONTRACTORSTOCK_REFRESH_BEGIN
            }
        })());


        try {
            var stockLookupData = await StockService.loadStockLookup(idToken);

            dispatch((() => {
                return {
                    type: Actions.CONTRACTORSTOCK_STOCKLOOKUP_LOADED,
                    data: stockLookupData
                }
            })(stockLookupData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "refreshStockLookup", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONTRACTORSTOCK_REFRESH_END
                }
            })());
        }

    };
}

function dispatchError(dispatch, error) {
    dispatch((() => {
        return {
            type: Actions.CONTRACTORSTOCK_UPDATE_ERROR,
            data: error
        };
    })(error));
}

/**
 * loadContractors load contractor list into contractor stock store
 * @param {*} companyId: if it is null, load all contractors (companys), otherwise to load contractor (company) by the specific company id, for external user to use, it shall pass the id, and null for internal user
 */
export function loadContractors(idToken, companyId = null) {
    return async (dispatch) => {
        
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONTRACTORSTOCK_REFRESH_BEGIN
            }
        })());


        try {
            var contractorData = await StockService.loadContractors(idToken, companyId);

            dispatch((() => {
                return {
                    type: Actions.CONTRACTORSTOCK_CONTRACTOR_LOADED,
                    data: contractorData
                }
            })(contractorData));

            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadContractors", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONTRACTORSTOCK_REFRESH_END
                }
            })());
        }

    };
}


export function loadContractorLocations(idToken, companyId) {
    return async (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONTRACTORSTOCK_REFRESH_BEGIN
            }
        })());


        try {
            var locationData = await StockService.loadContractorLocations(idToken, companyId);

            dispatch(((locationData) => {
                return {
                    type: Actions.CONTRACTORSTOCK_CONTRACTORLOCATION_LOADED,
                    data: {
                        CompanyId: companyId,
                        Locations: locationData
                    }
                }
            })(locationData));

            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadContractorLocations", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONTRACTORSTOCK_REFRESH_END
                }
            })());
        }

    };
}

export function loadContractorLocationStock(idToken, companyId, locationCode) {
    return async (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONTRACTORSTOCK_REFRESH_BEGIN
            }
        })());


        try {
            var stockData = await StockService.loadContractorLocationStock(idToken, companyId, locationCode);

            dispatch(((stockData) => {
                return {
                    type: Actions.CONTRACTORSTOCK_CONTRACTORLOCATIONSTOCK_LOADED,
                    data: {
                        LocationCode: locationCode,
                        CompanyId: companyId,
                        StockData: stockData
                    }
                }
            })(stockData));

            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "loadContractorLocations", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONTRACTORSTOCK_REFRESH_END
                }
            })());
        }

    };
}

export function loadPendingConsignment(idToken,representativeId=null){
    
    return async (dispatch)=>{
        dispatch((()=>{
            return{
                type:Actions.CONTRACTORSTOCK_REFRESH_BEGIN
            }
        })());

        try{
            let resp=await StockService.loadPendingConsignment(idToken,representativeId);

            dispatch(((resp)=>{
                //console.log('loadPendingConsignment resp',resp);
                return {
                    
                    type: Actions.CONTRACTORSTOCK_CONTRACTOR_PENDING_CONSIGNMENT_LOADED,
                    data:resp
                }
            })(resp));
            //reset error 
            dispatchError(dispatch, "");

        }catch(error){
            dispatchError(dispatch,error);
        }
        finally{
            dispatch((()=>{
                return{
                    type:Actions.CONTRACTORSTOCK_REFRESH_END
                }
            })());
        }
    }
}