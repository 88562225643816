import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor: theme.palette.background.default,
    },
    icon: {
        width: 48,
        marginRight: 4,
        color: theme.palette.primary.main,
    },
    input:{
        width: `calc(100% - 52px)`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
    },

}));

/**
 * search textbox
 * @property {className}: style apply to the search textbox 
 * @property {onSearch}: search handler, a funtion with signature (vlaueTosesarch) => {....}
 * @property {id}: id for field, the text box id is "{id}"", the serch icon id will be "{id}_search"
 * @property {autoFocus}: when true, the textbox will be focused when the page is loaded
 */
export function SkbSearchTextField({className, onSearch, id, autoFocus, ...other}) {
    const classes = useStyles();

    const [searchCriteria, setSearchCriteria] = useState('');
    const changedHandler = (e)=>{
        setSearchCriteria(e.target.value);
    }
    return (
        <Paper className={clsx(className, classes.root)} > 
            {/* <TextField id="standard-search" label="Search field" type="search" /> */}
            <IconButton className={classes.icon} aria-label="search" id={`${id}_search`} onClick={(e)=>{e.preventDefault(); if(onSearch) onSearch(searchCriteria);}} >      
              <SearchIcon />
            </IconButton>
            <InputBase
                autoFocus = {autoFocus?true:false}
                type="search"
                className={classes.input}
                value={searchCriteria}
                onChange={changedHandler}
                id={id}
                placeholder="Search …"
                
                inputProps={{ 'aria-label': 'search icons' }}
                {...other}
            /></Paper>
    );
}