const calculateInfoGraphicDays = (date , eta) => {
    if(!date || !eta) return "N/A";
    console.log("DATE" , date);
    const year = parseInt(date.split("-")[0]);
    const month = parseInt(date.split("-")[1]);
    const day   = parseInt(date.split("-")[2]);

    const dateToday = new Date();

    let daysDuration = 0;

    if(year !== dateToday.getFullYear()){
        daysDuration += (Math.abs(year-dateToday.getFullYear())*365);
    }
    if(month > dateToday.getMonth()){
        daysDuration += (month - dateToday.getMonth()) * 30 ;
    }
    if(month < dateToday.getMonth()){
        daysDuration -= (month - dateToday.getMonth()) * 30 ;
    }
    if(day > dateToday.getDay()){
        daysDuration += (day - dateToday.getDay());
    }
    if(day < dateToday.getDay()){
        daysDuration -= (day - dateToday.getDay());
    }

    return daysDuration;
}


export default calculateInfoGraphicDays;