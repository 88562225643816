import {createSelector} from 'reselect';

const selectConsignmentTasks = state => state?.consignmentTasks;

export const selectConsignmentTasksLoading = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.loading,
)
export const selectConsignmentTaskList = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_tasks,        
)
export const selectSortOrderValue = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.sort_order_value,
)

export const selectFilterTypeValue = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.filter_type_value,
)

export const selectFilteredTasks = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.filtered_tasks,
)
export const selectChosenTasksRowCount = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.selected_task_rows.length,
);
export const selectAllStockTasks = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.all_stock_tasks,
)
export const selectStockTableCols = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.stock_table_cols,
)
export const selectColumnTitleList = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => {
        const cols = consignmentTasks?.stock_table_cols;
        const titleList = [];
        for(let i=0;i<cols.length;i++){
            titleList.push(cols[i]?.title);
        }
        return titleList;
    }
)
export const selectColumnToChange = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.column_to_change,
)
export const selectSearchedStocks = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks.searched_stock_tasks,
)



export const selectChoosenTaskRows = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.selected_task_rows,
)


export const selectUnMarkedStockTasks = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.unmarked_stock_tasks,
);
export const selectMarkedStockTasks = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.marked_stock_tasks,
)


export const selectConsignmentList = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_list,
);
export const selectConsignmentListError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_list_error,
);
export const selectFetchingConsignmentList = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.fetching_consignment_list,
)


export const selectConsignmentDetails = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_details,
);
export const selectFetchingConsignmentDetails = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.fetching_consignment_details,
);
export const selectConsignmentDetailsError  = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_details_error,
);



export const selectSerialisedItemsTableData_2 = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => {
        if(consignmentTasks?.consignment_details){
            const rawSerialisedItems  = Object.assign([],consignmentTasks?.consignment_details?.SerialisedItems);
            const result = [];
            for(let i=0; i < rawSerialisedItems.length ; i++){
                const row = {  
                    col1 : rawSerialisedItems[i]?.StockCategoryCode,
                    col2 : rawSerialisedItems[i]?.StockDescription,
                    col3 : rawSerialisedItems[i]?.SerialNumber,
                    col4 : rawSerialisedItems[i]?.UnitOfMeasurement,
                    col5 : rawSerialisedItems[i]?.QtyGood,
                    col6 : rawSerialisedItems[i]?.Status,
                    col7 : "serialised",

                }
                result.push(row);
            }
            return result;
        }
        return [];
    }
)
export const selectNonSerialisedItemsTableData_2 = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => {
        if(consignmentTasks?.consignment_details){
            const rawNonSerialisedItems = Object.assign([],consignmentTasks?.consignment_details?.NonserialisedItems);
            const result = [];
            for(let i=0 ; i < rawNonSerialisedItems.length ; i++){
                const row = {
                    col1 : rawNonSerialisedItems[i]?.StockCategoryCode,
                    col2 : rawNonSerialisedItems[i]?.StockDescription,
                    col3 : rawNonSerialisedItems[i]?.SerialNumber,
                    col4 : rawNonSerialisedItems[i]?.UnitOfMeasurement,
                    col5 : rawNonSerialisedItems[i]?.QtyGood,
                    col6 : rawNonSerialisedItems[i]?.Status,
                    col7 : "non-serialised",
                }
                result.push(row);
            }
            return result;
        }   
        return [];
    }
)



export const selectConsignmentInfo  = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_info,
);
export const selectFetchingConsignmentInfo = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.fetching_consignment_info,
);
export const selectConsignmentInfoError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.consignment_info_error,
)


export const selectFilteredConsignmentList = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.filtered_consignment_list,
)
export const selectFetchingFilteredConsignmentList = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.fetching_filtered_consignment_list,
)
export const selectFilteredConsignmentListError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.filtered_consignment_list_error,
)


export const selectSerialisedItemsTableData = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.serialised_items_table_data,
)
export const selectNonSerialisedItemsTableData = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.non_serialised_items_table_data,
)


export const selectChoosenNonSerialisedItems = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => {
        const taskRows = Object.assign([],consignmentTasks?.selected_task_rows);
        const nonSerialisedItems = taskRows.filter(i=>i?.col7 === "non-serialised");
        const nonSerialisedItemsTableData = [];
        for(let i=0;i<nonSerialisedItems.length ; i++){
            const row = {
                col1 : nonSerialisedItems[i].col1,
                col2 : nonSerialisedItems[i].col2,
                col3 : nonSerialisedItems[i].col4,
                col4 : nonSerialisedItems[i].col5,

            }
            nonSerialisedItemsTableData.push(row);
        }
        return nonSerialisedItemsTableData;
    }
)



/*
    STORE VARIABLES
    searching_by_stock_code : false,
    search_by_stock_code_results : null,
    search_by_stock_code_error : null,
*/
export const selectSearchingByStockCode = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.searching_by_stock_code,
)
export const selectSearchByStockCodeResults = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.search_by_stock_code_results,
)
export const selectSearchByStockCodeError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.search_by_stock_code_error,
)


// searching_by_serial_number : false,
// search_by_serial_number_results : null,
// search_by_serial_number_error : null,
export const selectSearchingBySerialNumber = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.searching_by_serial_number,
)
export const selectSearchBySerialNumberResults = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.search_by_serial_number_results,
)
export const selectSearchBySerialNumberError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.search_by_serial_number_error,
)


// searching_by_consignment_id : false,
// search_by_consignment_id_results : null,
// search_by_consignment_id_error : null,
export const selectSearchingByConsignmentId = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.searching_by_consignment_id,
)
export const selectSearchByConsignmentIdResults = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.search_by_consignment_id_results,
)
export const selectSearchByConsignmentIdError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.search_by_consignment_id_error,
)

// updating_consignment_status : false,
// update_consignment_success : false,
// update_consignment_error : null,

export const selectUpdatingConsignmentStatus = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.updating_consignment_status,
)
export const selectUpdateConsignmentStatusSuccess = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.update_consignment_success,
)
export const selectUpdateConsignmentStatusError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.update_consignment_error,
)



// overriddenConsignmentRequested : null,
// loadingOverrideConsignmentRequested : true,
// overrideConsignmentRequestedError : null,
export const selectOverriddenConsignmentRequested = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.overriddenConsignmentRequested,
)
export const selectLoadingOverrideConsignmentRequested = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.loadingOverrideConsignmentRequested,
)
export const selectOverrideConsignmentRequestedError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.overrideConsignmentRequestedError,
)


/*
overriddenConsignmentActual : null,
loadingOverrideConsignmentActual : false,
overrideConsignmentActualError : null,
*/
export const selectOverriddenConsignmentActual = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.overriddenConsignmentActual,
)
export const selectLoadingOverrideConsignmentActual = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.loadingOverrideConsignmentActual,
)
export const selectOverrideConsignmentActualError = createSelector(
    [selectConsignmentTasks],
    consignmentTasks => consignmentTasks?.overrideConsignmentActualError,
)