import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { connect } from 'react-redux';
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SkbLogger } from '../../../../services';


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import SkbConfirmDialog from '../../../../skb_controls/components/SkbConfirmDialog';

import StepNonSerialisedItems from '../../stock_components/StepNonSerialisedItemsHusk';  //This is the default export, not necessarily the one named StepNonSerialisedItems.  
//Most often will be the connect-wrapped one.
import StepSerialisedItems from '../../stock_components/StepSerialisedItemsHusk';
import StepStockPreview from '../../stock_components/stocktake/StepStockPreviewHusk';
import StepStockReview from '../../stock_components/stocktake/StepStockReviewHusk';

import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Zoom from '@material-ui/core/Zoom';

import StepSignOff from '../../StepSignOff';

import * as taskActions from '../../../../Redux/actions/tasks';
import { SystemUpdate } from '@material-ui/icons';
import * as misc from '../../../../utils/misc';

import validateStep from '../../../../utils/validation/stocktake.js';

import { StocktakeSteps } from '../../Stocktake';
import { useSnackbar } from 'notistack';
import * as dataSync from '../../../../services/dataSaverAndTracker';


const taskConfig = require('../taskConfig.json');

const stepComponents = {
    StepStockPreview,
    StepSerialisedItems,
    StepNonSerialisedItems,
    StepStockReview,
    StepSignOff,
    default: StepStockReview
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

    stepper: {
        backgroundColor: 'inherit',
        paddingTop: theme.spacing(1),
        //paddingBottom: 0,
    },
    stepperbuttons: {
        textAlign: 'right',
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        paddingBottom: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    headerId: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        textDecoration: 'underline',
        cursor: "default",
        color: theme.palette.primary.main,

    },
    headerFirstRow: {
        "&>div.MuiGrid-item:last-child": {
            textAlign: 'right',
        }
    },

    stepContent: {

        overflow: "auto",
        [theme.breakpoints.up('md')]: {
            maxHeight: `calc(100vh - 350px)`,
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: `calc(100vh - 350px)`,

        // },
        // [theme.breakpoints.down('xs')]: {
        //     maxHeight: `calc(100vh - 400px)`,
        //     maxWidth:  `calc(100vw - 400px)`
        }
    },
    stepFooter: {
        position: 'fixed',
        bottom: 30,
        right: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 192px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 16px)',
        }
    },

    stepLabel: {
        '& span.MuiStepLabel-alternativeLabel': {
            marginTop: 0,
        }
    }

}));

const StepHeader = ({ CurrentTask, StepConfig, ActiveStep, CurrentSubtask }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <Grid container>
            <Grid item md={12} sm={12} xs={12}>
                <Grid container className={classes.headerFirstRow}>
                    <Grid item md={10} sm={9} xs={9}>
                        <Typography variant="h6" className={classes.instructions}>Step {StepConfig[ActiveStep].StepId}: {StepConfig[ActiveStep].StepName}</Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={3}>
                        <ClickAwayListener onClickAway={handleTooltipClose} id="pulldowntorefresh">
                            <div>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    arrow={false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={CurrentSubtask.Address}
                                    TransitionComponent={Zoom}
                                    placement="bottom"
                                >

                                    <Typography variant="h6" onClick={handleTooltipOpen} className={classes.headerId}>ID: {CurrentTask.TaskId}</Typography>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>


                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Typography variant="body1" className={classes.instructions}>{StepConfig[ActiveStep].StepDescription}</Typography>
                {StepConfig[ActiveStep].StepName === "Preview" &&
                    <Typography variant="body1">{CurrentSubtask.Address}</Typography>
                }
            </Grid>
        </Grid>

    );
}

const StepContent = ({ StepConfig, ActiveStep }) => {

    var StepComponent = stepComponents[StepConfig[ActiveStep].Component] ?? stepComponents.default;

    return (<StepComponent />)

}

export function TaskStepper({ CurrentTask, CurrentSubtask, StepConfig, Tasks, updateTaskStep, updateTaskStatus, changeStep }) {
    const classes = useStyles();
    const history = useHistory();

    const taskSetting = taskConfig[CurrentTask.TaskType];
    const stepConfig = StepConfig ? StepConfig : taskSetting.TaskDetails.Steps;
    const { enqueueSnackbar } = useSnackbar();

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    // const [canUpdateTaskStatus, setCanUpdateTaskStatus] = React.useState(false);

    //For validation dialog box:
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = useState("");
    const [confirmTitle, setConfirmTitle] = useState("");
    //    const [okHandler, setOkHandler] = useState(()=>{});

    const steps = stepConfig.map(i => i.StepName);

    //mark step is completed when task details page is loaded.
    const initCompletedStep = () => {
        const hasCompleted = new Set();
        CurrentSubtask.Steps.forEach(s => {

            if (s.Status === taskActions.Status.Done)
                //markCompletedStep(s.StepId);
                hasCompleted.add(s.StepId - 1);
        });

        setCompleted(hasCompleted);
    }


    useEffect(() => {
        if(CurrentSubtask.Steps)
            initCompletedStep();
    }, [CurrentSubtask.Steps]);//this will be called only once when the component is mounted (NOT updated)

    //monitor current step and change the view
    useEffect(() => {
        if (CurrentSubtask.CurrentStep)
            setActiveStep(CurrentSubtask.CurrentStep.StepId - 1);
    }, [CurrentSubtask.CurrentStep])

    const totalSteps = () => {
        return steps.length;
    };


    //cacluate total # of complted steps
    const completedSteps = () => {
        return completed.size;
    };

    const isCurrentStepComplete = () => {
        return CurrentSubtask.Steps[activeStep].Status === taskActions.Status.Done;
    }

    const displayToastMessageIfCurrentStepNotCompleted = (noToastMessage) => {
        if (noToastMessage) return;
        if (!isCurrentStepComplete()) {
            enqueueSnackbar(`Please remember to complete the ${stepConfig[activeStep].StepName} step.`, {
                variant: "warning"
            });
        }

    }

    //check whether all steps have been complted
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    //check whether current step is last step or not
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const updateStatus = (newCompleted) => {
        let status = taskActions.Status.NotStarted;
        if (totalSteps() > 0) {
            if (newCompleted.size == totalSteps()) status = taskActions.Status.Done;
            else if (CurrentSubtask.Steps.some(s=>s.Status === taskActions.Status.InProgress || s.Status === taskActions.Status.Done)) status = taskActions.Status.InProgress;
        }

        if (CurrentSubtask.TaskStatus.Status !== status) {
            SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, SeverityLevel.Information, "updateTaskStatus", CurrentSubtask, status);
            if (status === taskActions.Status.Done) {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.moduleName, misc.TaskLogInfo.category.signoff, misc.LogResult.success,
                    `{userObject} has completed stocktake task at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        locationObject: misc.getLocationObject(),
                        networkObject: misc.getNetworkObject()
                    });

                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.moduleName, misc.TaskLogInfo.category.signoff, misc.LogResult.success,
                    `{stockLocationObject} task status is changed from "In Progress" to "Done".`
                    , {

                        stockLocationObject: misc.getTaskLocationObject(CurrentTask, CurrentSubtask),
                    });
            }
            else if (status === taskActions.Status.NotStarted) {
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.moduleName, misc.TaskLogInfo.category.preview, misc.LogResult.success,
                    `{stockLocationObject} task status is changed from "In Progress" to "Not Started".`
                    , {
                        stockLocationObject: misc.getTaskLocationObject(CurrentTask, CurrentSubtask),

                    });
            }
            updateTaskStatus(Tasks, status);
        }

    }

    //Next button click event handler
    const handleNext = (e, noToastMessage) => {
        try {
            //push dataitem to server for previous user action (if any)
            const lastResourceID=localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
            if(lastResourceID && lastResourceID!=''){
                dataSync.pushDataItemToServer(lastResourceID).then( ()=>{
                    SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, "TaskStepper", "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                    localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server','');
                } ).catch( err => {
                    SkbLogger.applicationException(taskActions.ModuleInfo.moduleName, "TaskStepper", "Failed to push dataItem to Server", err);
                }); 
            }
        } catch (error) {
        }

        try {
            displayToastMessageIfCurrentStepNotCompleted(noToastMessage);
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !completed.has(i))
                    : activeStep + 1;

            setActiveStep(newActiveStep);
            changeStep(null, stepConfig[newActiveStep].StepId);
        } catch (error) {
            SkbLogger.applicationException(taskActions.ModuleInfo.moduleName, "TaskStepper", "check task step failed", error, CurrentSubtask.Steps);

            enqueueSnackbar("An error has occurred. Please call Skybridge, quoting Error Code: TS001.", {
                variant: "error"
            });
        }
    };

    //back button  click event handler
    const handleBack = () => {
        
        try {
            //push dataitem to server for previous user action (if any)
            const lastResourceID=localStorage.getItem('last_dataitem_resourceid_saved_without_pushing_to_server');
            if(lastResourceID && lastResourceID!=''){
                dataSync.pushDataItemToServer(lastResourceID).then( ()=>{
                    SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, "TaskStepper", "Clear last_dataitem_resourceid_saved_without_pushing_to_server after successfully pushing it to server.", lastResourceID);
                    localStorage.setItem('last_dataitem_resourceid_saved_without_pushing_to_server','');
                } ).catch( err => {
                    SkbLogger.applicationException(taskActions.ModuleInfo.moduleName, "TaskStepper", "Failed to push dataItem to Server", err);
                });
            }
        } catch (error) {
        }

        try {
            changeStep(null, stepConfig[activeStep === 0?activeStep:activeStep-1].StepId);

            displayToastMessageIfCurrentStepNotCompleted(false);
            if (activeStep === 0) { // go back to the task list window
                updateStatus(completed);
                //log history
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.moduleName, stepConfig[activeStep].Component, misc.LogResult.success,
                    `{userObject} has exited task for {stockLocationObject}`
                    , {
                        userObject: misc.getUserObject(),
                        stockLocationObject: misc.getTaskLocationObject(CurrentTask, CurrentSubtask),

                    });

                history.push('/stocktake');
            }
            else {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        } catch (error) {
            SkbLogger.applicationException(taskActions.ModuleInfo.moduleName, "TaskStepper", "check task step failed", error, CurrentSubtask.Steps);

            enqueueSnackbar("An error has occurred. Please call Skybridge, quoting Error Code: TS002.", {
                variant: "error"
            });
        }
    };

    //step click event handler
    const handleStep = (step) => () => {
        try {
            if(activeStep === step) return ;
            displayToastMessageIfCurrentStepNotCompleted(false);
            setActiveStep(step);
            changeStep(null, stepConfig[step].StepId);
        } catch (error) {
            SkbLogger.applicationException(taskActions.ModuleInfo.moduleName, "TaskStepper", "check task step failed", error, CurrentSubtask.Steps);

            enqueueSnackbar("An error has occurred. Please call Skybridge, quoting Error Code: TS003.", {
                variant: "error"
            });
        }
    };

    const validate = (onSuccessfulValidation) => {

        const validationResult = validateStep(stepConfig[activeStep].StepId, CurrentSubtask);

        if (validationResult.isValid) {
            onSuccessfulValidation();
        }
        else {
            var userObject= misc.getUserObject();
            SkbLogger.applicationTrace(taskActions.ModuleInfo.moduleName, "TaskStepper", "Validation error.", {user:userObject,validationError:validationResult});
                   
            //It is expected that there will later be 'toast' display types, and 'error' severity messages to handle.  
            if (validationResult.display === 'dialog')  //&& validationResult.severity==='warning'
            {
                setConfirmMsg(validationResult.message);
                setConfirmTitle(validationResult.title);
                setOpenConfirm(true);
                //At the moment it isn't passing onSuccessfulValidation to the dialogue box.  It probably should.  
            } else {//toast message
                enqueueSnackbar(validationResult.message, {
                    variant: validationResult.severity
                });
            }
        }

    }

    //Complete Step button click event handler
    const handleComplete = () => {
        // setCanUpdateTaskStatus(true);
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);

        //the step has completed, update step status
        updateTaskStep(Tasks, stepConfig[activeStep], taskActions.Status.Done);

        enqueueSnackbar(`${stepConfig[activeStep].StepName} step has been completed.`, {
            variant: "success"
        });

        setCompleted(newCompleted);

        updateStatus(newCompleted);
        if (newCompleted.size !== totalSteps()) {
            handleNext(null, true);
        }
        else {// all steps has been complted, go back to the task list window
            history.push('/stocktake');
        }
    };

    //check whether a step has been completed or not
    function isStepComplete(step) {
        return completed.has(step);
    }

    const clickComplete = () => {
        try {
            validate(handleComplete);

        } catch (error) {
            SkbLogger.applicationException(taskActions.ModuleInfo.moduleName, "TaskStepper", "check task step failed", error, CurrentSubtask.Steps);

            enqueueSnackbar("An error has occurred. Please call Skybridge, quoting Error Code: TS004.", {
                variant: "error"
            });
        }
    };
    //monitor complete task to update task status
    // useEffect(() => {
    //     if (canUpdateTaskStatus) //update task status only when complet button is clicked
    //         updateStatus();
    // }, [completed])
    return (
        <div>
            <Grid container className={classes.root}>


                <Grid item md={12} sm={12} xs={12} id="task_stepper_header">
                    <StepHeader StepConfig={stepConfig} ActiveStep={activeStep} CurrentTask={CurrentTask} CurrentSubtask={CurrentSubtask} />

                </Grid>
                <Grid item md={12} sm={12} xs={12} id="task_stepper_content" className={classes.stepContent}>
                    {/* <Typography className={classes.instructions}> show step view here</Typography> */}
                    <StepContent StepConfig={stepConfig} ActiveStep={activeStep} CurrentTask={CurrentTask} />
                </Grid>
                <Grid item md={12} sm={12} xs={12} id="task_stepper_footer" className={classes.stepFooter}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12} id="task_stepper_buttons" className={classes.stepperbuttons}>
                            <SkbButton primary size="small" id="btn_back" onClick={handleBack} className={classes.button} text={"Back"} />

                            <SkbButton
                                primary
                                onClick={handleNext}
                                size="small"
                                className={classes.button}
                                disabled={isLastStep()}
                                text={"Next"}
                                id="btn_next"
                            />

                            {activeStep !== steps.length &&
                                (completed.has(activeStep) ? (
                                    <Typography variant="caption" className={classes.completed}>
                                        {steps[activeStep]} already completed
                                    </Typography>
                                ) : (
                                        <SkbButton primary size="small" id="btn_complete" onClick={clickComplete} text={completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'} />

                                    ))}
                        </Grid>


                        <Grid item md={12} sm={12} xs={12} id="task_stepper">
                            <Stepper alternativeLabel nonLinear activeStep={activeStep} className={classes.stepper}>
                                {steps.map((label, index) => {

                                    return (
                                        <Step key={label} classes={{ alternativeLabel: classes.stepLabel }}>
                                            <StepButton
                                                id={`step_${index}`}
                                                onClick={handleStep(index)}
                                                completed={isStepComplete(index)}
                                            >
                                                {label}
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <SkbConfirmDialog id="confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm} okHandler={() => handleComplete()}
                okLabel="No" cancelLabel="Yes" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />

        </div>
    );
}

const mapStateToProps = (state) => ({
    Tasks: state.tasks,
    User: state.auth.User,
    CurrentTask: taskActions.getCurrentTask(state.tasks),
    CurrentSubtask: taskActions.getCurrentSubTask(state.tasks)
});


const mapDispatchToProps = (dispatch) => {
    return {
        updateTaskStep: (allTasks, step, status) => {
            let timestamp = '';
            let latlong = ''; misc.getLocationObject()
            //if status changed to Done, save timestamp and latlong info
            if (status === taskActions.Status.Done) {
                timestamp = (new Date).toUTCString();//timestamp will be UTC string

                let locationObject = misc.getLocationObject();
                latlong = `${locationObject.Latitude},${locationObject.Longitude}`;

                //log history
                SkbLogger.userAuditEvent(misc.TaskLogInfo.moduleName, misc.getCurrentUserEmail(), misc.TaskLogInfo.category.moduleName, step.Component, misc.LogResult.success,
                    `{userObject} has completed the task step "${step.StepName}" at {locationObject} while {networkObject}.`
                    , {
                        userObject: misc.getUserObject(),
                        locationObject: locationObject,
                        networkObject: misc.getNetworkObject()
                    });
            }

            dispatch(taskActions.updateTaskStep(allTasks, step.StepId, status, timestamp, latlong));
        },
        updateTaskStatus: (allTasks, status) => { dispatch(taskActions.updateTaskStatus(allTasks, status)); },
        changeStep: (allTasks, stepId) => dispatch(taskActions.changeStep(allTasks, stepId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskStepper);