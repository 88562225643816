/*
This "husk" is designed as a holder for a component which was previously connected to Redux and now is not.  The husk provides all the Redux functionality that the
component previously had, as a replacement for previous uses of the component (maintaining the functionality), as an example for future use and as a test harness 
for the disconnected component.

When other pages start using the disconnected component this husk can initially be an example of how to use it.  I expect the husk will be phased out once the disconnected
component is in real use.  

Note that in the case of StepStockReview, there is a husk in the stocktake folder (for the older stocktake implementation of the component) and one in stock_components, for 
the newer version of the component.  

*/

import React from 'react';

import { connect } from 'react-redux';

import * as taskActions from '../../../../Redux/actions/tasks';
import { getCurrentSubTask } from '../../../../Redux/actions/tasks';
import { StocktakeSteps } from '../../Stocktake';

import { SkbLogger, SeverityLevel } from '../../../../services';

import _ from 'lodash';

import { TaskLogInfo } from '../../../../utils/misc';

import StepStockReview from './StepStockReview';

//Some constants for logging:
const moduleName = TaskLogInfo.moduleName;
const stepName = TaskLogInfo.category.review;

  export function StepStockReviewHusk(props) {

  function goToStep(stepId, selectedStockCode) {
    var msg = "User attempted to navigate to step + " + stepId + " for Stockcode " + selectedStockCode + ".";
    SkbLogger.applicationTrace(moduleName, SeverityLevel.Verbose, stepName, msg, props.currentSubTask);

    props.setLastSelectedStockCode(selectedStockCode);
    props.goToStep(props.allTasks, stepId);

  }
  
  //   var previewData= [
                
  //                 {
  //                   StockCode: "EROD0001",
  //                   Description: "Ericsson Wireless Outdoor Unit",
  //                   StockType: "Serialised",
  //                   Condition: "Good",
  //                   Qty: 20,
  //                   QtyUnit: "Each",
  //               },
  //               {
  //                   StockCode: "ERMI0001",
  //                   Description: "Ericsson Wireless Wall Plate Kit",
  //                   StockType: "Non-Serialised",
  //                   Condition: "Faulty",
  //                   Qty: 30,
  //                   QtyUnit: "Each",
  //               },
  //               {
  //                   StockCode: "ERMI0002",
  //                   Description: "Ericsson Wireless Cat5e Outdoor Cable",
  //                   StockType: "Non-Serialised",
  //                   Condition: "Good",
  //                   Qty: 14,
  //                   QtyUnit: "Metre",
  //               },
  //               {
  //                   StockCode: "ERDH0001",
  //                   Description: "Ericsson LTSS 80cm Dish - 10017252",
  //                   StockType: "Non-Serialised",
  //                   Condition: "Good",
  //                   Qty: 0,
  //                   QtyUnit: "Each",
  //               },
  //               {
  //                   StockCode: "ERMT0006",
  //                   Description: "Ericsson LTSS 900mm Gutter Mount - 10022841",
  //                   StockType: "Non-Serialised",
  //                   Condition: "Good",
  //                   Qty: 0,
  //                   QtyUnit: "Each",
  //               },
  //           ]
  // ;
  
  //  const currentTask=getCurrentTask(props.allTasks);
  
   // const columnHeaders= ['Description','UOM','Expected Status','# To Be Returned'];
  
    //return (<StepStockPreview PreviewInfo={previewData} CurrentTask={currentTask} loadPreviewData={props.loadPreviewData} goToStep={goToStep} 
    return (<StepStockReview serialisedItems={props.currentSubTask.SerialisedItems} nonSerialisedItems={props.currentSubTask.NonSerialisedItems}  
      loadSerialisedItems={props.loadCurrentTaskSnItems} loadNonSerialisedItems={props.loadCurrentTaskNonSnItems}
      //loadPreviewData={props.loadPreviewData} 
      goToStep={goToStep} 
          serialisedStepId={StocktakeSteps.SERIALISED} nonSerialisedStepId={StocktakeSteps.NON_SERIALISED}
         // columnHeaders={columnHeaders}
       
          isLoading={props.allTasks.isLoading}
    />);
  }
  

const mapStateToProps = state => ({
  user: state.auth,
  allTasks: state.tasks,
  currentSubTask: getCurrentSubTask(state.tasks),
});

const mapDispatchToProps = (dispatch) => {
  return {

    goToStep: (taskState, stepId) => dispatch(taskActions.changeStep(taskState, stepId)),
    setLastSelectedStockCode: (stockCode) => dispatch(taskActions.updateLastSelectedStockCode(stockCode)),
    loadCurrentTaskNonSnItems: () => dispatch(taskActions.loadCurrentTaskNonSnItems(false)),
    loadCurrentTaskSnItems: () => dispatch(taskActions.loadCurrentTaskSnItems(false)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepStockReviewHusk);
