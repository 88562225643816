import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { SkbLoading } from '../../../skb_controls/components/SkbLoading';
import SkbTable from '../../../skb_controls/components/SkbTable';
import Grid from '@material-ui/core/Grid';
//import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { red, grey, blue } from '@material-ui/core/colors';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
const useStyles = makeStyles((theme) => (
    {
        root: {
            width: '100%', 
        },
        pickerRoot:{
            color: theme.palette.primary.main,
        },
        textMargin:{

            "& .MuiInputBase-root.MuiInput-root":
            {
               marginTop:'25px'
            }
           
          },
        dateMargin:{
          marginTop:'40px'
        }
    }
));

 function MethodDatePicker({onChange,value,label,format,id}) {
    const classes = useStyles();
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} id={id}>
          <Grid item>
            <KeyboardDatePicker
            className={classes.textMargin}
              disableToolbar
              variant="inline"
              format={format}
              margin="normal"
              id="date-picker-inline"
              label={label}
              value={value}
              onChange={onChange}
              leftArrowIcon={<ArrowBackIosIcon className={classes.pickerRoot}/>}
              rightArrowIcon={<ArrowForwardIosIcon className={classes.pickerRoot}/>}
              keyboardIcon={<EventIcon className={classes.pickerRoot}/>}
              autoOk={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                'id': 'date-picker-inline-changedatebtn'
              }}
              leftArrowButtonProps = {
                {
                  'id': 'date-picker-inline-leftbtn'
                }
              }
              rightArrowButtonProps = {
                {
                  'id': 'date-picker-inline-righbtn'
                }
              }
            />
          </Grid>
    </MuiPickersUtilsProvider>
    )
}

/**
 * components: MethodSelector is to choose method from method list and select the return date and pick date
 * @param {boolean} IsLoading: to indicate the LocationList is refreshing or not
 * @param {array} DisplayColumns: to define the columns of the grid , and how column is binding with the method list
 *          i.e.  [
 *           { title: '', field: 'MethodCode', type: 'hidden-id' }, //column with hidden-id will not display
 *           {title:"", field:"Method", type: 'text'},
 *       ]
 * @param {array}  MethodList: array of data to show in the grid
 *       i.e 
 *       [
 *           {
 *               Method: 'By the carrier arranged by Skybridge',
 *               MethodCode:'BCS'
 *           },
 *          {
 *               Method: 'By the carrier arranged by contractor',
 *               MethodCode:'BCC'
 *           }
 *       ]
 * @param {string} SelectedMethod: arrary of selected method. Should be one element in array
 *      i.e.
 *      [
 *          {   'BCS' -- it's vaule of MethodCode in DisplayColumns }
 *      ]
 *        
 * @param {function} selectReturnDate : it's a callback function, and called when the return date selected
 * or removed.
 * @param {string} SelectedReturnDate: selected return date
 * 
 * @param {function} selectPickupDate : it's a callback function, and called when the pick up date selected
 * or removed.
 * 
 * @param {string} SelectedPickupDate: selected return date
 * @param {Function} selectMethod: it's a callback function, and called when selecting or un-selecting a row
 *      the signature of the callback is fn(event, rowdata)
 *      event: it's DOM element envent 
 *      rowdata: it data of current selected/un-selected row, the extra property tableData:checked indicates the selecte status
 * @returns 
 */

export default function MethodSelector( {IsLoading,DisplayColumns,MethodList,selectMethod,
SelectedMethod,SelectedPickupDate,selectPickupDate,SelectedReturnDate,selectReturnDate} 
){
    const classes = useStyles();
     if(typeof(IsLoading) === 'undefined') throw("IsLoading prop is missing");

    if(typeof(MethodList) === 'undefined') throw("MethodList prop is missing");
    if(typeof(SelectedMethod) === 'undefined') throw("SelectedMethod prop is missing");
    if(typeof(DisplayColumns) === 'undefined') throw("DisplayColumns prop is missing");
    if(typeof(selectMethod) === 'undefined') throw("selectMethod prop is missing");
    if(typeof(SelectedPickupDate)==='undefined') throw("SelectedPickupDate prop is missing");
    if(typeof(selectPickupDate)==='undefined') throw("selectPickupDate prop is missing");
    if(typeof(SelectedReturnDate)==='undefined') throw("SelectedReturnDate prop is missing");
    if(typeof(selectReturnDate)==='undefined') throw("SelectReturnDate prop is missing"); 

    const handlePickupDateChange =(date)=>{
        selectPickupDate(date)
    }
    const handleReturnDateChange =(date)=>{
        selectReturnDate(date)
    }
    if(IsLoading){
        return (<SkbLoading/>)
    }else return(
        <Grid container justify="center" className={classes.root} id="stock_methodSelector">
          <Grid item lg={4}></Grid>
            <Grid item lg={4} sm={12} xs={12}>
                <SkbTable columns={DisplayColumns} dataSource={MethodList} id="stock_methodselector_datagrid"
                actions={[
                    {
                        type:'single-select',
                        onClick:selectMethod
                    }
                ]}
                options={{selectedIdValues:SelectedMethod}} /> 
            </Grid>
            <Grid item lg={4}></Grid>
             <Grid item  className={classes.dateMargin}>
                 <MethodDatePicker id="stock_returnDate"  onChange={handleReturnDateChange} value={SelectedReturnDate? new Date(SelectedReturnDate):null}
                            label="Return by date"
                            format="dd/MM/yyyy" />
             </Grid>
            <Grid sm={12} xs={12}></Grid>
            <Grid item >
                <MethodDatePicker id="stock_pickupDate"  onChange={handlePickupDateChange} value={SelectedPickupDate? new Date(SelectedPickupDate):null}
                            label="Pick-up date"
                            format="dd/MM/yyyy" />
            </Grid> 
        </Grid>
    )
}