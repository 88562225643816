import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Box,
    Typography,

} from '@material-ui/core';
import SkbButton from '../../../../../skb_controls/components/SkbButton';
import SkbIcon from '../../../../../skb_controls/components/SkbIcon';
import {makeStyles} from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// importing selectors
import {
    selectFilename,
    selectCSVTableData,
    selectCSVJsonData,

    selectThreePLImportationSuccess,
    selectProcessingThreePLImportation,
    selectThreePLImportationsError,

    selectRequestId,
    selectOutcomes,
    selectLoadingOutcomes,
    selectOutcomesError,

} from '../../../../../Redux/ThreePLImportation/selectors';
// importing actions
import {
    deleteCSVFile,
    toggleViewCsvData,
    threePLImportationStart,
    readCSVStart,
    setNewRequestId,

} from '../../../../../Redux/ThreePLImportation/actions';
import SkbTable from '../../../../../skb_controls/components/SkbTable';
import RenderCsvData from '../RenderCsvData';
import Loading from '../../../../Loading';
import { useSnackbar } from 'notistack';
import ImportationResult from '../ImportationResult';




const useStyles = makeStyles(theme=>({
    file_name : {
        backgroundColor : "#d0e562",
        padding : "0.5em 1em",
        borderRadius : "5px",
        margin : "0.2em auto",
    },
    reupload_flex : {
        display : "flex",
        alignItems : "center",
        justifyContent : "space-between",
        [theme.breakpoints.down(600)] : {
            flexFlow : "column",
            alignItems : "center",
            justifyContent : "center",
        }
    } 
}))

const SubmitCSVForProcessing = ({

    // selectors
    fileName,
    fileData,
    JSONData,

    importationSuccess,
    importationError,
    processing,

    requestUUID,

    // actions
    deleteFile,
    togglePopup,
    submitForProcessing,
    readCSV,
    createUUID,


}) => {

    const demoActions_select=[
        {type:'select',onClick:(rowData)=>console.log(rowData)},                        
        {type:'delete',onClick:()=>deleteFile()},
        // {type:'approve',onClick:()=>console.log("approve")},
        // {type:'cancel',onClick:()=>console.log("cancel")}
    ];

    const { enqueueSnackbar } = useSnackbar();


    const columns_processingFileTable = [
        { title: 'Hidden Col', field: 'col1', type: 'hidden-id'},
        { title: 'File #', field: 'col2', type: 'text'},
        { title: 'File Name', field: 'col3', type: 'link' , onClickLink:()=>togglePopup()},
        { title: 'Status', field: 'col4', type: 'text', onChange:()=>{}},
        { title: 'Outcome', field: 'col5', type: 'text', onChange:()=>{}},
    ]
    
    const classes = useStyles();

    useEffect(()=>{
        createUUID();

    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(importationError)
            enqueueSnackbar(importationError?.message , {variant:"error"});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[importationError])

    return(
        <Box>
            <Typography variant="body2">Uploaded CSV File</Typography>
            {
                fileName
                ?   <Grid container className={classes.reupload_flex}>
                        <Grid item>
                            <Typography variant="body1" className={classes.file_name}>{fileName.name}</Typography>
                        </Grid>
                        <Grid item>
                            <input 
                                type="file"
                                id="file-input"
                                style={{display : "none"}}
                                onChange={e=>{
                                    readCSV(e.target.files[0])
                                }}
                            />
                            <SkbButton 
                                onClick={()=>document.querySelector("#file-input").click()}
                                primary
                                text={
                                    <Typography variant="body2"><SkbIcon color="white" icon={CloudUploadIcon} /> ReUpload File</Typography>
                                }
                            />
                            <SkbButton 
                                onClick={deleteFile}
                                text={
                                    <Typography variant="body2"><SkbIcon color="white" icon={HighlightOffIcon} /> Delete</Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                :   <Typography style={{color : "red"}} variant="h6">No file chosen</Typography>
            }
            <br />
            <SkbTable 
                actions={demoActions_select} 
                columns={columns_processingFileTable} 
                dataSource={[fileData]}
                options={true}
            />
            <br/>
            <div
                style={{display:"flex",justifyContent:"space-between"}}
            >
                <SkbButton 
                    text={
                        <Typography variant="body2"><SkbIcon icon={CloudDownloadIcon} color ="#ffff" /> <a style={{color: '#000', textDecoration: 'none'}} href="/3PL Importation - Template.zip">Template</a> </Typography>
                    }
                    style={{
                        width : "fit-content"
                    }}
                />
                {
                    JSONData
                    &&
                    <SkbButton 
                        primary
                        text={
                            <Typography body2>Submit &rarr;</Typography>
                        }
                        style={{
                            width : "fit-content"
                        }}
                        onClick={()=>submitForProcessing({
                            requestId : requestUUID,
                            csvData : JSONData.data,
                        })}
                    />
                }
            </div>
            <RenderCsvData />
            <ImportationResult />
            { processing && <Loading /> }
        </Box>    
    );

}
const mapState = state => ({
    fileName : selectFilename(state),
    fileData : selectCSVTableData(state),
    JSONData : selectCSVJsonData(state),

    importationSuccess : selectThreePLImportationSuccess(state),
    importationError : selectThreePLImportationsError(state),
    processing : selectProcessingThreePLImportation(state),

    outcomes : selectOutcomes(state),
    loadingOutcomes : selectLoadingOutcomes(state),
    outcomesError : selectOutcomesError(state),

    requestUUID : selectRequestId(state),

})
const mapDispatch = dispatch => ({
    submitForProcessing : data => dispatch(threePLImportationStart(data)),
    deleteFile : () => dispatch(deleteCSVFile()),
    togglePopup : () => dispatch(toggleViewCsvData()),
    createUUID : () => dispatch(setNewRequestId()),
    readCSV : path => dispatch(readCSVStart(path)),
})
export default connect(mapState,mapDispatch)(SubmitCSVForProcessing);