import { Provider } from 'react-redux';
import React from 'react';
import { combineReducers, createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { auth } from './reducers/auth';
import { variables } from './reducers/variables';
import * as loginActions from './actions/auth';
//import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { register } from './reducers/register';
import { callForAssistance } from './reducers/callForAssistance';
import { sync } from './reducers/sync';
import { tasks } from './reducers/tasks';
import { tasksV2 } from './reducers/tasksV2';
import {syncConfig} from './reducers/syncConfig'
import {contractorStock} from './reducers/contractorStock';
import {stockConsignment} from './reducers/stockConsignment';
import  {returnsDashboardList, returnStockSlaDays,returnsDashboardTaskInfo, returnsDashboardConsignmentMethod, returnsDashboardWarehouseList,returnsDashboardWarehouseAddrBasedOnLocCode,returnsDashboardTaskInfoAddStockItem, returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps, returnsDashboardTaskInfoSchedulePopupChangedData, returnLogisticCarriers,returnPickupWindowTimeSlotOptions,returnDropOffwindowTimeSlotOptions, returnDashListCancelReasonOptions}  from './reducers/returnsdashboard';
import consignmentTasksReducer from './ConsignmentTasks/reducers';
import threePLImportationReducer from './ThreePLImportation/reducers';

const sagaMiddleWare = createSagaMiddleware();
const producers = combineReducers({ 
    auth, 
    register, 
    variables,
    callForAssistance,
    sync,
    tasks,
    syncConfig,
    contractorStock ,
    stockConsignment, 
    tasksV2, 
    returnsDashboardList, 
    returnStockSlaDays,  
    returnsDashboardTaskInfo,  
    returnsDashboardConsignmentMethod, 
    returnsDashboardWarehouseList,
    returnsDashboardWarehouseAddrBasedOnLocCode,
    returnsDashboardTaskInfoAddStockItem,  
    returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps,
    returnsDashboardTaskInfoSchedulePopupChangedData, 
    returnLogisticCarriers, 
    returnPickupWindowTimeSlotOptions,
    returnDropOffwindowTimeSlotOptions,
    returnDashListCancelReasonOptions,
    consignmentTasks : consignmentTasksReducer,
    threePLImportation : threePLImportationReducer,
});
const composeEnhancers = (process.env.REACT_APP_ENV_TYPE === "local"? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

//const middlewares = [logger,thunk,sagaMiddleWare];
const middlewares = [thunk,sagaMiddleWare];   //removed redux-logger
const store = createStore(producers, composeEnhancers(applyMiddleware(...middlewares)));
store.dispatch(loginActions.loadTokenfromLocalStorage());
sagaMiddleWare.run(rootSaga);

export function StoreProvider({ children }) {
    return (<Provider store={store}>
        {children}
    </Provider>);
}