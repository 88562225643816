
import _ from 'lodash';
import { SkbLogger } from '../../services';

import * as contractorStockActions from '../../Redux/actions/contractorStock';
import * as stockConsignmentActions from '../../Redux/actions/stockConsignment';
import { LocalTaxiTwoTone } from '@material-ui/icons';
export const reviewDisplayColumns =
    [
        { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
        { title: "", field: "CompanyId", type: 'hidden-text' },
        { title: "Contractor Entity", field: "Contractor", type: 'read-only-long-text' },
        { title: "Stock Location", field: "LocationName", type: 'read-only-long-text' },
        { title: "# of Stock", field: "StockQty", type: 'read-only-long-text' },
        { title: "Destination", field: "Destination", type: 'read-only-long-text' },
        { title: "Method", field: "Method", type: 'read-only-long-text' },

    ];

export const faultyReviewDisplayColumns =
[
    { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
    { title: "", field: "CompanyId", type: 'hidden-text' },
    { title: "Contractor Entity", field: "Contractor", type: 'read-only-long-text' },
    { title: "Stock Location", field: "LocationName", type: 'read-only-long-text' },
    { title: "# of Faulty Stock", field: "StockQty", type: 'read-only-long-text' },
    { title: "Destination", field: "Destination", type: 'read-only-long-text' },
    { title: "Method", field: "Method", type: 'read-only-long-text' },

];


export const reviewDisplaySubColumns =
    [
        { title: "Description", field: "StockDescription", type: 'read-only-long-text' },
        { title: "UOM", field: "UnitOfMeasure", type: 'read-only-long-text' },
        { title: "Qty", field: "Qty", type: 'read-only-long-text' },

    ];

export const faultyReviewDisplaySubColumns =
[
    { title: "Description", field: "StockDescription", type: 'read-only-long-text' },
    { title: "UOM", field: "UnitOfMeasure", type: 'read-only-long-text' },
    { title: "Condition", field: "Condition", type: 'read-only-long-text' },
    { title: "Qty", field: "Qty", type: 'read-only-long-text' },

];    


export const loadRevievData = (dispatch, idToken, stockConsignment) => {
    var returnConsignmentsCreation = stockConsignment.ReturnConsignmentsCreation;


    var ret = [];
    returnConsignmentsCreation.forEach((c) => {
        if (_.isArray(c.Consignments)) {
            c.Consignments.forEach(s => {
                //load contractor avaialble stock
                dispatch(contractorStockActions.loadContractorLocationStock(idToken, c.CompanyId, s.FromLocationCode));
            });
        }
    })
    return ret;
}

export const getReviewData = (contractorStock, stockConsignment,cosingmentType) => {
    var returnConsignmentsCreation = stockConsignment.ReturnConsignmentsCreation;


    var ret = [];
    if(!_.isArray(returnConsignmentsCreation)) return ret;

    var onHandStock = contractorStock.StockOnHand;
    returnConsignmentsCreation.forEach((c) => {
        if (_.isArray(c.Consignments)) {
            c.Consignments.forEach(s => {
                //load contractor avaialble stock
                //dispatch(contractorStockActions.loadContractorLocationStock(c.CompanyId, s.FromLocationCode));

                var cs = _.find(onHandStock, (cm) => c.CompanyId === cm.CompanyId);
                if (cs) {
                    let l = _.find(cs.Locations, (c) => c.LocationCode === s.FromLocationCode);
                    if (l) {
                        var stockQty;
                        if(cosingmentType==="Return All Stock"){
                            stockQty=(l.QtyGood || l.QtyofGood) + (l.QtyFaulty || l.QtyofFaulty)
                        }else if(cosingmentType==="Return Faulty Stock"){
                            stockQty= l.QtyFaulty || l.QtyofFaulty
                        }else{
                            stockQty= (l.QtyGood || l.QtyofGood) + (l.QtyFaulty || l.QtyofFaulty)
                        }
                    
                        ret.push({
                            LocationCode: l.LocationCode,
                            CompanyId: cs.CompanyId,
                            Contractor: cs.Contractor,
                            LocationName: l.Name,
                            StockQty:stockQty,
                            Destination: s.ToLocationName,
                            Method: s.ReturnMethod

                        });
                    }
                }
            });
        }
    });
    return ret;
}

export const loadFaultyReviewRowDetails = (contractorStock, tableRowData) => {
    var companyId = tableRowData.CompanyId;
    var locationCode = tableRowData.LocationCode;
    var onHandStock = contractorStock.StockOnHand;

    var cs = _.find(onHandStock, (cm) => companyId === cm.CompanyId && cm.QtyFaulty >0);
console.log('loadFaultyReviewRowDetails cs',cs)
    var ret = [];
    if(!cs) return ret;
    //TODO:
    var location = _.find(cs.Locations, (l) => l.LocationCode === locationCode);

    var snItems = _.groupBy(location.SerialisedItems, (i) => `${i.Description}||${i.Unit}||${i.Condition}`);
    Object.keys(snItems).forEach((key) => {
        console.log('snItems key...',key)
        var keyparts = key.split("||");
        if(keyparts[2] ==="Faulty"){
            ret.push({
            StockDescription: keyparts[0],
            UnitOfMeasure: keyparts[1],
            Condition:keyparts[2],
            Qty: snItems[key].length
            });
        }
        
    });
  /*   var faultyNonSn = _.find(location.NonserialisedItems, (i) =>i.QtyFaulty >0);
    console.log('loadFaultyReviewRowDetails faultyNonSn',faultyNonSn)
    var nonSnItems = _.groupBy(faultyNonSn, (i) => `${i.Description}||${i.Unit}||${i.QtyFaulty}`);
    console.log('loadFaultyReviewRowDetails nonSnItems',nonSnItems) */

    var nonSnItems = _.groupBy(location.NonserialisedItems, (i) => `${i.Description}||${i.Unit}`);

    Object.keys(nonSnItems).forEach((key) => {
        var keyparts = key.split("||");
        var faulty=_.find(nonSnItems[key],(i)=> i.QtyFaulty>0)
        if(faulty){
            if(faulty.QtyFaulty>0){
                ret.push({
                    StockDescription: keyparts[0],
                    UnitOfMeasure: keyparts[1],
                    Qty:  faulty.QtyFaulty
                });
            }
        }
        
        
    });

    return ret;
}

export const loadReviewRowDetails = (contractorStock, tableRowData) => {
    var companyId = tableRowData.CompanyId;
    var locationCode = tableRowData.LocationCode;
    var onHandStock = contractorStock.StockOnHand;

    var cs = _.find(onHandStock, (cm) => companyId === cm.CompanyId);

    var ret = [];
    if(!cs) return ret;
    //TODO:
    var location = _.find(cs.Locations, (l) => l.LocationCode === locationCode);
    var snItems = _.groupBy(location.SerialisedItems, (i) => `${i.Description}||${i.Unit}`);
    Object.keys(snItems).forEach((key) => {
        var keyparts = key.split("||");
        ret.push({
            StockDescription: keyparts[0],
            UnitOfMeasure: keyparts[1],
            Qty: snItems[key].length
        });
    });

    var nonSnItems = _.groupBy(location.NonserialisedItems, (i) => `${i.Description}||${i.Unit}`);

    Object.keys(nonSnItems).forEach((key) => {
        var keyparts = key.split("||");
        ret.push({
            StockDescription: keyparts[0],
            UnitOfMeasure: keyparts[1],
            Qty: _.sumBy(nonSnItems[key],(i)=>i.QtyGood + i.QtyFaulty)
        });
    });
    return ret;
}

export const submitConsignments = (dispatch,idToken, returnConsignmentsCreation) =>{
    dispatch(stockConsignmentActions.submitConsignment(idToken, returnConsignmentsCreation));
    
    // //remove the consignment from store
    // setTimeout(() => {
    //     cancelConsignment(dispatch);
    // }, 500);

    //refresh pending info in contractor name list
    setTimeout(() => {
        dispatch(contractorStockActions.loadPendingConsignment(idToken));
    }, 1000);
    
}

export const cancelConsignment = (dispatch) =>
{
    dispatch(stockConsignmentActions.cancelCreation({
        ConsignmentDataType:"ReturnConsignmentsCreation"
    }));
}