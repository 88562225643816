import React,{useCallback,useEffect,useState} from 'react';
import {
    useDispatch,
    useSelector,
    connect,
} from 'react-redux';

import {useSnackbar } from 'notistack';
import {
    Grid,
    Typography,
    Link,
    Box,

} from '@material-ui/core';
import {
    makeStyles,
} from '@material-ui/core/styles';

import DomainIcon from '@material-ui/icons/Domain';
import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,

  } from '@material-ui/pickers';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Input} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';

import { SkbSelect } from '../../../../skb_controls/components/SkbSelect';
import SkbIcon from '../../../../skb_controls/components/SkbIcon';
import SkbTextField from '../../../../skb_controls/components/SkbTextField';
import DragAndDropFileUpload from './DragAndDropFileUpload';
import SkbButton from '../../../../skb_controls/components/SkbButton';
import Loading from '../../../Loading';

import ContractorRepresentativePhoneEmailStructure  from "./ContractorRepresentativePhoneEmailStructure";
import {getReturnDashboardUtility} from "./returnDashboardUtility"; 
import {uploadOneConsignmentLabelFileToBlob,overwriteLabelFileListForConsignment} from "../../../../services/StockService";
import {crypts} from '../../../../utils/crypts';
import filesize from "filesize";
import {

    // CONTROL RELATED ACTIONS
    setTaskInfoSchedulePopupContractor__warehouseLocationCode,
    setTaskInfoSchedulePopupContractor__deliveryDate,
    setTaskInfoSchedulePopupContractor__deliveryTimeslot,
    taskInfoPopupContractorOptionLoadingStart,
    taskInfoPopupContractorOptionLoadingEnd,

    setTaskInfoSchedulePopupDataChangeActivityByUser,
    setTaskInfoSchedulePopupDataChangeableDataSetFlag,

    taskInfoPopupContractorOptionDataChanged,

    callTaskInfoSchedulePopupLabelUplDataSave,
    setTaskInfoScheduleFlowLoaderFlag,

    // API CALL ACTIONS
    callAsyncFuncToFetchWarehouseAddrBasedOnLocCodeTIPSTwoSteps,
    callAsyncFuncToFetchPickupWindowTimeList,
    callAsyncFuncToFetchDropOffWindowTimeList,
    taskInfoPopupContractorOptionSetUploadedLabels,
    callAsyncFuncToUpdateForScheduleFinishContractorDropOff, 
    
    // Validate related
    setTaskInfoScheduleContractorDropOffValidateFlagWithFeErMsg,
    setTaskInfoSchedulePopupContractorDropOffToUpdateSpecificFeErMsg

} from '../../../../Redux/actions/returnsdashboard';


const styles = makeStyles({
    borderBox : {
        border : '1px solid lightgray',
        borderRadius : '5px',
        padding : '0 0.5em',
    },
    oneRow : {
        display : 'flex',
        
        justifyContent : 'space-between',
        alignItems : 'center',
    },
    marginRight : {
        marginRight : '0.5em',
    },
    rowCenter : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
    },
    labelErrorStruct : {
        marginTop : '1em',
        color: "red",
        textAlign: "center"
    },
    
});


export const validateDeliWindowDate = (dateObj)=>{        

    const todaysDateObj = new   Date();
    const chkWhetherSselDateIsFutureDate = getReturnDashboardUtility.isTheDateGreaterThanTheOtherOne(dateObj,todaysDateObj);
    return chkWhetherSselDateIsFutureDate;
  
  }

export const triggerValidateContractorFropOffScenarioSchedule = ( {oneStepWareHouseLocationCode,deliveryDate,deliveryWindowTimeSlot,labelFiles},otherParam={}) =>{
    let validateStepTwoData =  true;
    const {specificFor, getValidateFlag} = {...otherParam};
    let validateFlagErrMessages =  {};
    let retValidResp = {};
  
    if(specificFor){
    
  
      if(specificFor==="oneStepWareHouseLocationCode"){
  
        if(!(oneStepWareHouseLocationCode)){  
          validateStepTwoData = false;
          validateFlagErrMessages["oneStepWareHouseLocationCodeErrMsg"] = "Warehouse location code is required";
        }
  
        // if we want to update the validateFlag of this step too otherwise only update the message
        if(getValidateFlag){
          retValidResp["validateFlagProvided"]  = validateStepTwoData;         
        }
        retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
        
        return retValidResp;
      }
  
  
      if(specificFor==="deliveryDate"){
  
        if(!(deliveryDate)){
          validateStepTwoData = false;
          validateFlagErrMessages["deliveryDateErrMsg"] = "Date is required";
          
        }
        else{
  
          let chkValidateWindDeliDate = validateDeliWindowDate
          (deliveryDate);      
      
          if(!(chkValidateWindDeliDate)){   
              validateStepTwoData = false;
              validateFlagErrMessages["deliveryDateErrMsg"] = "Future date is required.";  
          }
          
        }
  
        // if we want to update the validateFlag of this step too otherwise only update the message
        if(getValidateFlag){
          retValidResp["validateFlagProvided"]  = validateStepTwoData;         
        }
        retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
        return retValidResp;
  
      }
  
      if(specificFor==="deliveryWindowTimeSlot"){
        if(!(deliveryWindowTimeSlot)){
          validateStepTwoData = false;
          validateFlagErrMessages["deliveryWindowTimeSlotErrMsg"] = "Time slot is required";
          
        }   
  
        // if we want to update the validateFlag of this step too otherwise only update the message
        if(getValidateFlag){
          retValidResp["validateFlagProvided"]  = validateStepTwoData;         
        }
        retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
        return retValidResp;
  
      }
  
      if(specificFor==="labelFiles"){
        if(!(labelFiles)||(labelFiles && labelFiles.length===0)){
          validateStepTwoData = false;
          validateFlagErrMessages["labelFilesErrMsg"] = "Label is required";
  
        
          
        }   
  
        // if we want to update the validateFlag of this step too otherwise only update the message
        if(getValidateFlag){
            retValidResp["validateFlagProvided"]  = validateStepTwoData;         
        }
        retValidResp["validateFlagErrMessagesProvided"]  = validateFlagErrMessages; 
        return retValidResp;
  
      }
  
    }
    else{
  
    
  
      if(!(oneStepWareHouseLocationCode)){
        validateStepTwoData = false;
        validateFlagErrMessages["oneStepWareHouseLocationCodeErrMsg"] = "Warehouse location code is required.";
      }
     
  
      
      if(!(deliveryDate)){
        validateStepTwoData = false;
        validateFlagErrMessages["deliveryDateErrMsg"] = "Date is required.";
      }
      else{
       let chkValidateWindDeliDate = validateDeliWindowDate
        (deliveryDate);      
  
        if(!(chkValidateWindDeliDate)){
          validateStepTwoData = false;
          validateFlagErrMessages["deliveryDateErrMsg"] = "Future date is required.";
        }
      }
  
       if(!(deliveryWindowTimeSlot)){
        validateStepTwoData = false;
        validateFlagErrMessages["deliveryWindowTimeSlotErrMsg"] = "Time slot is required.";
      }
  
       if(!(labelFiles)||(labelFiles && labelFiles.length===0)){
        validateStepTwoData = false;
        validateFlagErrMessages["labelFilesErrMsg"] = "Label is required.";
      }  
  
    }
  
    let flagDataWithErrMsgObj = {
      "validateFlagProvided":validateStepTwoData,
      "validateFlagErrMessagesProvided":validateFlagErrMessages
    }
  
   
  
   return flagDataWithErrMsgObj;
  }


const ShipmentDetailsContractorDropOff = ({taskinfoschhedulepropdata,  // function related to closing popups after save
    handlePopupCloseContractorDropOff,

    // to check if user changed any data or not
    dataChangeActivityDoneByUser,

    // for calling the finish schedule popup
    finish,
}) => {

    // {
    //     taskinfoschhedulepropdata,
    //     dropOffTimeSlotList,
    //     packages,
    //     palletsAndCartons,
    //     consignmentLocationCode,
    //     loading,
    //     labelFiles,

    //     // actions
    //     dataChanged,
    //     startLoading,
    //     endLoading,
    //     setUploadedLabels,

    //     // function related to closing popups after save
    //     handlePopupCloseContractorDropOff,

    //     // to check if user changed any data or not
    //     dataChangeActivityDoneByUser,

    //     // for calling the finish schedule popup
    //     finish,

    // } 

   
    // LOCAL STATE
    const [taskInfoScheduleApiSuccessLocalFlag, setTaskInfoScheduleApiSuccessLocalFlag] = React.useState(false); 
    
    const dispatch = useDispatch();
    const {
        borderBox,
        oneRow,
        marginRight,
        rowCenter,
        labelErrorStruct

    } = styles();

    const setUploadedLabels = labels =>  dispatch(taskInfoPopupContractorOptionSetUploadedLabels(labels));


    // SOME UTILITY FUNCTIONS
    const getWareHouseDisplayData = (resp) => {

        if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
          let wareHouseListDrpDwnArr = [];
          resp.map((e,i)=>{
            wareHouseListDrpDwnArr.push({"value":e.LocationCode,"text":e.Name});
          });
    
          return wareHouseListDrpDwnArr;    
    
        }
    
        return [];
    
    }
    const getDeliveryWindowtimeSlotDisplayData = (resp) => {
        if(resp && resp!==undefined && Array.isArray(resp) && resp.length>0){
            let drpDwnWindTimSltArr = [];
            resp.map((e,i)=>{
              drpDwnWindTimSltArr.push({"value":e.variableName,"text":e.variableName});
            });
      
            return drpDwnWindTimSltArr;
      
      
          }
      
          return [];
    }
    

    // ************************************** GETTING THE NEEDED DATA FROM STATE *************************************************************
    const {  taskInfoScheduleFinishApiSuccessFlag,taskInfoScheduleFinishApiSuccessMsg , taskInfoScheduleFinishApiErrorFlag, taskInfoScheduleFinishApiErrorMessage  } = useSelector((state) => ({
        //return state.returnsDashboardTaskInfo;
        
        "taskInfoScheduleFinishApiSuccessFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessFlag)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessFlag):false,
        "taskInfoScheduleFinishApiSuccessMsg": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessMsg)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiSuccessMsg):"",
        "taskInfoScheduleFinishApiErrorFlag": (state.returnsDashboardTaskInfo && state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorFlag)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorFlag):false,
        "taskInfoScheduleFinishApiErrorMessage": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorMessage)?(state.returnsDashboardTaskInfo.taskInfoScheduleFinishApiErrorMessage):"",
        


    }));
    
    const { 
            warehouseAddressDataLoadStatus, 
            toLocationAddressLine1AfterSel, 
            toLocationAddressLine2AfterSel, 
            toLocationStateAfterSel, 
            toLocationSuburbAfterSel,
            toLocationPostcodeAfterSel, 
            wareHouseLocationCodeAfterSel 
        } = useSelector((state) => ({

            //return state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps;
            "warehouseAddressDataLoadStatus": state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressDataLoadStatus,
            "wareHouseLocationCodeAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.LocationCode)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.LocationCode):"",
            "toLocationAddressLine1AfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine1)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine1):"",
            "toLocationAddressLine2AfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine2)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.AddressLine2):"",
            "toLocationStateAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.State)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.State):"",
            "toLocationSuburbAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Suburb)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Suburb):"",
            "toLocationPostcodeAfterSel": (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps &&  state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData && state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Postcode)? (state.returnsDashboardWarehouseAddrBasedOnLocCodeTipspTwoSteps.warehouseAddressData.Postcode):"",
    
    
        })
    );

    const selectTaskInfoPopupPalletsAndCartons = packageData => {

        let respObj = {
                "pallets":0,
                "cartons":0,

        };
            

        if(Array.isArray(packageData) && packageData.length>0){
                let cartonsCnt = 0; let palletsCnt = 0;
                packageData.map((e)=>{
                        if(e.PackageType!==undefined && e.PackageType==="Carton" ){
                            let cartonsQty = e.Qty!==undefined?parseInt(e.Qty):0;                           
                            cartonsCnt = cartonsCnt + cartonsQty;
                        }else if(e.PackageType!==undefined && e.PackageType==="Pallet"){
                            let palletQty = e.Qty!==undefined?parseInt(e.Qty):0;                           
                            palletsCnt = palletsCnt + palletQty;
                        }

                });

                respObj["cartons"]=cartonsCnt;
                respObj["pallets"]=palletsCnt;

            }

            return respObj;
    };

    const {
            taskInfo,
            contractorCompanyName, 
            wareHouseLocationCode, 
            toLocationAddressLine1, 
            toLocationAddressLine2, 
            toLocationState, 
            toLocationSuburb,
            toLocationPostcode,
             
            pickupWindowDate,
            pickupWindowTimeslot, 
            palletsAndCartons
              
        } = useSelector((state) => ({

            //return state.returnsDashboardTaskInfo;
            "taskInfo"              :   state.returnsDashboardTaskInfo.taskInfo,
            "contractorCompanyName" :   (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo &&  state.returnsDashboardTaskInfo.taskInfo.Company!==undefined && state.returnsDashboardTaskInfo.taskInfo.Company && state.returnsDashboardTaskInfo.taskInfo.Company.Name!==undefined )?state.returnsDashboardTaskInfo.taskInfo.Company.Name: "",
            "pickupWindowDate"      :   (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Date!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Date: "",
            "pickupWindowTimeslot"  :   (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.PickupWindow && state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Timeslot!==undefined)?state.returnsDashboardTaskInfo.taskInfo.PickupWindow.Timeslot: "",

            "wareHouseLocationCode" : (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.LocationCode)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.LocationCode):"",
            "toLocationAddressLine1": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine1)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine1):"",
            "toLocationAddressLine2": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine2)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.AddressLine2):"",
            "toLocationState"       : (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.State)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.State):"",
            "toLocationSuburb"      : (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.Suburb)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.Suburb):"",
            "toLocationPostcode"    : (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.ToLocation && state.returnsDashboardTaskInfo.taskInfo.ToLocation.Postcode)? (state.returnsDashboardTaskInfo.taskInfo.ToLocation.Postcode):"",
            "palletsAndCartons": (state.returnsDashboardTaskInfo &&  state.returnsDashboardTaskInfo.taskInfo && state.returnsDashboardTaskInfo.taskInfo.Packages!==undefined)?selectTaskInfoPopupPalletsAndCartons(state.returnsDashboardTaskInfo.taskInfo.Packages):{"pallets":0,"cartons":0}
            
            
        })
    );

    



    const {
        oneStepWareHouseLocationCode,
        deliveryDate,
        deliveryWindowTimeSlot,
        packageDetails,
        labelFiles,
        loading,
        contrDroppOffScenarioValidateFlagErrMessages


    } = useSelector(state =>({
        "oneStepWareHouseLocationCode" :    state.returnsDashboardTaskInfoSchedulePopupChangedData && 
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.wareHouseLocationCode!==undefined
                                                ?   state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.wareHouseLocationCode
                                                : "",
        "deliveryDate"                  :   state.returnsDashboardTaskInfoSchedulePopupChangedData && 
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.deliveryDate!==undefined
                                                ?   state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.deliveryDate
                                                : "",
        "packageDetails"                   :   state.returnsDashboardTaskInfoSchedulePopupChangedData && 
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.packageDetails!==undefined
                                                ?   state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.packageDetails
                                                : "",
        "deliveryWindowTimeSlot"        :   state.returnsDashboardTaskInfoSchedulePopupChangedData && 
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
                                            state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.deliveryWindowTimeSlot!==undefined
                                                ?   state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.deliveryWindowTimeSlot
                                                : "",
        "labelFiles":   state.returnsDashboardTaskInfoSchedulePopupChangedData && 
        state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
        state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.labelFiles!==undefined?  state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.labelFiles:[],
        "loading":  state.returnsDashboardTaskInfoSchedulePopupChangedData && 
        state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
        state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.isLoading!==undefined? state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.isLoading:  false,
        "contrDroppOffScenarioValidateFlagErrMessages": state.returnsDashboardTaskInfoSchedulePopupChangedData && state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData &&
        state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.validateFlagErrMessages!==undefined?
        state.returnsDashboardTaskInfoSchedulePopupChangedData.oneStepScenarioChangedData.validateFlagErrMessages:null,
    }));

    const {
            wareHouseListLoadStatus,
            wareHouseList, 
            wareHouseListToDisplay

        } = useSelector((state) =>( {  
            //return state.returnsDashboardWarehouseList;  
            "wareHouseListLoadStatus"   : state.returnsDashboardWarehouseList.wareHouseListLoadStatus,
            "wareHouseList"             : state.returnsDashboardWarehouseList.wareHouseList,             
            "wareHouseListToDisplay"    :  getWareHouseDisplayData(state.returnsDashboardWarehouseList.warehouseList),

            }
        )      
    );
    const {
        timeSlotOptionList, 
            timeslotOptionListLoadStatus,

        } = useSelector((state) =>              
            ({
                //return state.returnDropOffwindowTimeSlotOptions;             
                "timeSlotOptionList" : state.returnDropOffwindowTimeSlotOptions && state.returnDropOffwindowTimeSlotOptions.timeslotOptionList !==undefined ?getDeliveryWindowtimeSlotDisplayData(state.returnDropOffwindowTimeSlotOptions.timeslotOptionList): [],
                "timeSlotOptionListLoadStatus": state.returnDropOffwindowTimeSlotOptions && state.returnDropOffwindowTimeSlotOptions.timeslotOptionListLoadStatus !==undefined?state.returnDropOffwindowTimeSlotOptions.timeslotOptionListLoadStatus:false,
            })
        
    );
    
    // ************************************************************************************************************************************************

    // ==========================> FILE UPLOAD RELATED FUNCTIONS <==============================
    const makeItReadyForUpload = async (fileObj,consignmentLocationCode,calledFrom) => {

        try{
                dispatch(taskInfoPopupContractorOptionLoadingStart())
                const idToken = crypts.decrypt(localStorage.getItem('id_token'));
                const filetypeGrabbed = fileObj.type;
                const rawBaseSixtyFourCont =  await getReturnDashboardUtility.toBase64(fileObj);
                const searchPart = `data:${filetypeGrabbed};base64,`;
                const okBaseSixtyFourCont = rawBaseSixtyFourCont.replace(searchPart,"");  
                
    
                const nameData = fileObj.name;    
                const nameSplit = nameData.split(".");
                const fileExt  = nameSplit[nameSplit.length-1];
    
    
                const chkResp = await uploadOneConsignmentLabelFileToBlob(consignmentLocationCode,okBaseSixtyFourCont,fileExt); 
                return chkResp;           
            
    
        }
        catch(e){
            dispatch(taskInfoPopupContractorOptionLoadingEnd());
            //console.log("=======> MAKE IT READY FOR UPLOAD FAILED <=============");
            enqueueSnackbar(
                `Something went wrong please try again later.`, 
                { variant: 'error',onClose:()=>{} }
            );  
            
    
        }
       
    }

    const processFileUpload = async(files, calledFrom) =>{

        for(let i=0; i<files.length; i++){
            let fileObj = files[i]; 
    
            // same for me
            let chkValid   = await getReturnDashboardUtility.checkfileTypeIsAllowed(fileObj);
           
            if(!(chkValid.fileTypeOkStatus)||!(chkValid.fileSizeOkStatus)){
                    // toast error message
                    if(!(chkValid.fileTypeOkStatus)){
                        enqueueSnackbar(`Invalid file type upload attempted.`, { variant: 'error',onClose:()=>{
      
                        } }); 
                    }
                    else if(!(chkValid.fileSizeOkStatus)){
                        enqueueSnackbar(`Maximum 10 MB size of file can be uploaded. `, { variant: 'error',onClose:()=>{
      
                        } });  
                    }
                
    
            }else{
                    // upload  file               
                    const locFileNameData = fileObj.name;
                    const locFileSizeData = fileObj.size;                 
                   
    
                    let fileUploadedPathInServer = await makeItReadyForUpload(fileObj, consignmentLocationCode, calledFrom);
    
                    if(fileUploadedPathInServer){
    
                        let labelDataObj = {"localFileName":locFileNameData,"localFileSize":locFileSizeData,"fileUploadedPathInServer":fileUploadedPathInServer};
                        
                        setUploadedLabels(labelDataObj);
                        dispatch(taskInfoPopupContractorOptionLoadingEnd());
                        dispatch(setTaskInfoSchedulePopupDataChangeActivityByUser(true));
                        // let passPrm  = [];
                        // passPrm.push(labelDataObj);
                        // updateDataOnUploadedLabels(passPrm);

                        enqueueSnackbar(`Successfully uploaded ${locFileNameData} `, { variant: 'success', autoHideDuration: 1500, onClose:()=>{

                            setTimeout(()=>{
                                timeToRemoveGenericErrMsgForLabel();
                            },1000);
                            
                        } });  
                       
    
    
                    }
    
            }
        }
    
    }

    const callTaskInfoSchedulePopupLabelUplDataSave = async (otherOption) => {

        const idToken = otherOption.idToken?otherOption.idToken:"";
          const consignmentLocationCode = otherOption.idToken?otherOption.consignmentLocationCode:"";
          const labelFileList = otherOption.LabelFileList?otherOption.LabelFileList:[];
          const respChk = await overwriteLabelFileListForConsignment(idToken, consignmentLocationCode, labelFileList).then((datResp)=>{
            
                    // call action to update schedule ddata
                    taskInfoScheduleContractorOptionUpdateApiCallAction();
      
          }).catch((er)=>{
            dispatch(setTaskInfoScheduleFlowLoaderFlag(false));
            enqueueSnackbar(`Something went wrong please try again!!`, { variant: 'error',onClose:()=>{
      
            } });  
          }).finally(()=>{
            
            
      
          });
      
    }

    const updateDataOnUploadedLabels = function(labfileArList){

        //console.log(" --- Upfdate thhem  =>>>",labfileArList);
        const  idToken = crypts.decrypt(localStorage.getItem('id_token')); 

        if(Array.isArray(labfileArList) && labfileArList.length>0){
            let paramObj = {"idToken":"","consignmentLocationCode":"","labelFileList":[]};

            let labelsAr = [];
            labfileArList.map((e,i)=>{
                labelsAr.push({"LabelName":e.localFileName,"LabelFilePath":e.fileUploadedPathInServer});


            });

            paramObj["labelFileList"] =  labelsAr;
            paramObj["consignmentLocationCode"] =  consignmentLocationCode;
            paramObj["idToken"] =  idToken;

            // console.log(" =========DEAR=>>>>>>",paramObj);
            dispatch(taskInfoPopupContractorOptionLoadingEnd());
            callTaskInfoSchedulePopupLabelUplDataSave(paramObj);
            // dispatch(callTaskInfoSchedulePopupLabelUplDataSave(paramObj));
            
        }

       
    }



    // =========================================================================================

    // ======================> SAVING CONSIGNMENT DETAILS RELATED FUNCTIONS ===================>
    const taskInfoScheduleLabelSaveRelatedObjConstruct = () => {
        // change it to false do that  in use effect it doen't gets called as infinite
                
        dispatch(setTaskInfoScheduleFlowLoaderFlag(true));   

       // script to save uploaded labels
       let passPrm  = [];
       if(labelFiles && labelFiles.length>0){
        labelFiles.map((e)=>{
                   let labelDataObj = {"localFileName":e.localFileName,"localFileSize":e.localFileSize,"fileUploadedPathInServer":e.fileUploadedPathInServer};
                   passPrm.push(labelDataObj);
               });

               if(passPrm.length>0){
                   //console.log("---label save related passPrm=>>",passPrm);
                   updateDataOnUploadedLabels(passPrm);
               } 
           }

   }
    const taskInfoScheduleContractorOptionUpdateApiCallAction = () =>{
        // change it to false do that  in use effect it doen't gets called as infinite
        
        
        dispatch(setTaskInfoScheduleFlowLoaderFlag(true));   
    
        const paramObjApi1 = {
                "Consignments":[
                    {
                            "LocationCode":consignmentLocationCode, // consignmentLocationCode
                            "ConsignmentStatus":"Awaiting labels", // static and fixed                       
                            "ToLocationCode":wareHouseLocationCode, // warehouse loc code
                            
                            "DropOffWindow":{
                                "Date":deliveryDate,
                                "Timeslot":deliveryWindowTimeSlot,
                                },                        
                        
                            "ActivityType":"Schedule" // static and fixed
                            }
    
                  ]
                };
        const successMsgParam = {"message":`Successfully scheduled the return stock task ${taskId}.`};

        // api to update screen 1 and screen 2 related data for non contractor drop off scenario
        // dispatch(returnsDashboardTaskInfoActions.callAsyncFuncToUpdateForScheduleFinish({"bodyParam":paramObjApi1}));
        dispatch(callAsyncFuncToUpdateForScheduleFinishContractorDropOff({"bodyParam":paramObjApi1,"successMsgParam":successMsgParam}));
    }





    // ==========================================================================================





    //console.log("DROP OFF TIME SLOT LIST FROM SELECTOR ====> ",dropOffTimeSlotList);
    const { enqueueSnackbar } = useSnackbar(); 

    const {taskId} = taskinfoschhedulepropdata;
    const consignmentLocationCode = ( taskinfoschhedulepropdata && taskinfoschhedulepropdata.dataConsignmentLocationCode ) ? taskinfoschhedulepropdata.dataConsignmentLocationCode: "";

    
    

    // THIS FUNCTION FILLS IN THE INTIAL DATA FOR THE CONTROLS
    const fillInitialDataOfControls = () => {
        
        dispatch(setTaskInfoSchedulePopupDataChangeableDataSetFlag(true));
        dispatch(setTaskInfoSchedulePopupContractor__warehouseLocationCode(wareHouseLocationCode));
        dispatch(setTaskInfoSchedulePopupContractor__deliveryDate(pickupWindowDate));
        dispatch(callAsyncFuncToFetchDropOffWindowTimeList());
    }

    
    const timeToRemoveGenericErrMsgForLabel = function(){
       
        // for specific error message update        
        let errStateObj = {   
        "specificForKey":"labelFilesErrMsg" ,        
        "specificForKeyValue":undefined
        } ; 

        dispatch(setTaskInfoSchedulePopupContractorDropOffToUpdateSpecificFeErMsg(errStateObj));
    }


    const onDrop = useCallback(files => {        
        
        let calledFrom = "onDrop";
        //console.log("----- calledFrom=>>>>>>>",calledFrom);
        processFileUpload(files,calledFrom);


    }, []);
    const fileUploadBtnOnChange = async ({target:{files}}) => {  
        let calledFrom = "fileUploadBtnOnChange";
         //console.log("----- calledFrom=>>>>>>>",calledFrom);
        processFileUpload(files,calledFrom);        
       
    }


    // CHANGE HANDELING FUNCTIONS
    const warehouseLocationChangeHandler = warehouse => {
        // this fires when user changes the warehouse dropdown
        dispatch(setTaskInfoSchedulePopupDataChangeActivityByUser(true));
        const locationObjectParameterObj = {
            "paramObj" : {
                "locationCode" : warehouse,
            }
        };       
        dispatch(callAsyncFuncToFetchWarehouseAddrBasedOnLocCodeTIPSTwoSteps(locationObjectParameterObj));

        // for specific error message update        
        let paramToValidate = {             
            "oneStepWareHouseLocationCode":warehouse,
            };
        let chkv2 = triggerValidateContractorFropOffScenarioSchedule(paramToValidate,{"specificFor":"oneStepWareHouseLocationCode","getValidateFlag":false});        
        let {validateFlagErrMessagesProvided} = {...chkv2};
        
        let {oneStepWareHouseLocationCodeErrMsg} = {...validateFlagErrMessagesProvided};

        let errStateObj = {   
            "specificForKey":"oneStepWareHouseLocationCodeErrMsg" ,        
            "specificForKeyValue":oneStepWareHouseLocationCodeErrMsg
            } ; 

        dispatch(setTaskInfoSchedulePopupContractorDropOffToUpdateSpecificFeErMsg(errStateObj));


    }
    

    const deliveryTimeSlotChangeHandler = time => {
        // fires when user changes the delivery time
        dispatch(setTaskInfoSchedulePopupDataChangeActivityByUser(true));
        dispatch(setTaskInfoSchedulePopupContractor__deliveryTimeslot(time));

          // for specific error message update
          let paramToValidate = {             
            "deliveryWindowTimeSlot":time,
            };
        let chkv2 = triggerValidateContractorFropOffScenarioSchedule(paramToValidate,{"specificFor":"deliveryWindowTimeSlot","getValidateFlag":false});        
        let {validateFlagErrMessagesProvided} = {...chkv2};
        
        let {deliveryWindowTimeSlotErrMsg} = {...validateFlagErrMessagesProvided};

        let errStateObj = {   
            "specificForKey":"deliveryWindowTimeSlotErrMsg" ,        
            "specificForKeyValue":deliveryWindowTimeSlotErrMsg
            } ; 

        dispatch(setTaskInfoSchedulePopupContractorDropOffToUpdateSpecificFeErMsg(errStateObj));


    }
    const getDeliveryWindowDate = (dateObj)=>{        

        const todaysDateObj = new   Date();
        dispatch(setTaskInfoSchedulePopupDataChangeActivityByUser(true));

        const pickupWindDateSel = getReturnDashboardUtility.grabDateStringInFormat(dateObj,"YYYY-MM-DD");
        dispatch(setTaskInfoSchedulePopupContractor__deliveryDate(pickupWindDateSel));

         // for specific error message update 
         let paramToValidate = {             
            "deliveryDate":dateObj,
            };
        let chkv2 = triggerValidateContractorFropOffScenarioSchedule(paramToValidate,{"specificFor":"deliveryDate","getValidateFlag":false});        
        let {validateFlagErrMessagesProvided} = {...chkv2};
        
        let {deliveryDateErrMsg} = {...validateFlagErrMessagesProvided};

        let errStateObj = {   
            "specificForKey":"deliveryDateErrMsg" ,        
            "specificForKeyValue":deliveryDateErrMsg
            } ; 

        dispatch(setTaskInfoSchedulePopupContractorDropOffToUpdateSpecificFeErMsg(errStateObj));
    



        // const chkWhetherSselDateIsFutureDate = getReturnDashboardUtility.isTheDateGreaterThanTheOtherOne(dateObj,todaysDateObj);
 
        // if(chkWhetherSselDateIsFutureDate){
        //      const pickupWindDateSel = getReturnDashboardUtility.grabDateStringInFormat(dateObj,"YYYY-MM-DD");
        //      dispatch(setTaskInfoSchedulePopupContractor__deliveryDate(pickupWindDateSel));
        // }
        // else{
        //  enqueueSnackbar(`Please select a future date.`, { variant: 'error',onClose:()=>{
       
        //  } });  
        // }
    }

    const deliveryDateChangeHandler = date => {
        // fires when user changes the delivery date
        getDeliveryWindowDate(date)
    }

    const finishButtonClickHandler = event => {
        // HERE VALIDATE CODE

        let paramToValidate = {
            "oneStepWareHouseLocationCode":oneStepWareHouseLocationCode, 
            "deliveryDate" : deliveryDate,
            "deliveryWindowTimeSlot": deliveryWindowTimeSlot,
            "labelFiles": labelFiles
          };

         
          
        let chkv = triggerValidateContractorFropOffScenarioSchedule(paramToValidate,{});
          
        let errStateObj = {
            "validateFlagProvided":chkv.validateFlagProvided,
            "validateFlagErrMessagesProvided":chkv.validateFlagErrMessagesProvided
          };

          dispatch(setTaskInfoScheduleContractorDropOffValidateFlagWithFeErMsg(errStateObj));

          if(chkv.validateFlagProvided){

            taskInfoScheduleLabelSaveRelatedObjConstruct();
          }else{
            enqueueSnackbar(`Please enter the proper data or upload labels`, { variant: 'error',onClose:()=>{
       
            } }); 
          }

        
        
    }

    

    useEffect(()=>{
        fillInitialDataOfControls();
    },[])

    useEffect(()=>{
        if(taskInfoScheduleFinishApiSuccessFlag===true && !(taskInfoScheduleApiSuccessLocalFlag)){
            setTaskInfoScheduleApiSuccessLocalFlag(true);
            enqueueSnackbar(`${taskInfoScheduleFinishApiSuccessMsg}`, { variant: 'success' ,onClose:()=>{        
                // trigger task schedule popup close 
                // from  here
                handlePopupCloseContractorDropOff();
            }, autoHideDuration: 4000 });
            
          }   
      
           
          if( taskInfoScheduleFinishApiErrorFlag===true ){
            enqueueSnackbar(`${taskInfoScheduleFinishApiErrorMessage}`, { variant: 'error', autoHideDuration: 2500 });
          }
    },[taskInfoScheduleFinishApiErrorFlag, taskInfoScheduleFinishApiSuccessFlag])
    return(
        <Grid container direction="column">
            {(timeslotOptionListLoadStatus || loading ) && <Loading/>}
            {/* HEADING */}
            <Grid item direction="row" className={oneRow}>
                <Grid item>
                    <Typography variant="h5">Shipment Details</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">Task ID : <Link style={{"cursor":"default", "text-decoration":"none"}}>{taskId}</Link></Typography>
                </Grid>
            </Grid>
            {/* <DestinationDetails /> */}
            <Grid item>
                <Typography variant="h6">Destination Details</Typography>
            </Grid>
            <Grid 
                container  
                direction="column"
                className={[borderBox,]}    
            >
                
                <SkbSelect
                    value={oneStepWareHouseLocationCode}
                    items={wareHouseListToDisplay}
                    valueChanged={warehouseLocationChangeHandler}
                    error={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.oneStepWareHouseLocationCodeErrMsg!==undefined?true:false}
                    helperText={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.oneStepWareHouseLocationCodeErrMsg!==undefined?contrDroppOffScenarioValidateFlagErrMessages.oneStepWareHouseLocationCodeErrMsg:""}
                />

                {/* WAREHOUSE ADDRESS DEPENDING ON TWO CASES 1.WHEN OPENING POPUP, 2.WHEN CHANGING DROPDOWN VALUE */}
                {/* WHEN POPUP OPENS */}
                {
                    !(wareHouseLocationCodeAfterSel) && 
                    (
                        <Box textAlign="left">
                            <Typography variant="body2" noWrap={false}>
                                {toLocationAddressLine1==""?"":toLocationAddressLine1}
                            </Typography>
                            <Typography variant="body2" noWrap={false}>
                                {toLocationAddressLine2==""?"":toLocationAddressLine2}
                            </Typography>
                            <Typography variant="body2" noWrap={false}>
                                {toLocationSuburb==""?"":toLocationSuburb}
                                {toLocationState==""?"":" "+toLocationState}
                                {toLocationPostcode==""?"":" "+toLocationPostcode}            
                            </Typography>

                        </Box>
                    )
                }
                {/* WHEN USER CHANGES THE WAREHOUSE */}
                {
                    (wareHouseLocationCodeAfterSel) && (
                        <Box textAlign="left">
                            <Typography variant="body2" noWrap={false}>
                                {toLocationAddressLine1AfterSel==""?"":toLocationAddressLine1AfterSel}
                            </Typography>
                            <Typography noWrap={false}>
                                {toLocationAddressLine2AfterSel==""?"":toLocationAddressLine2AfterSel}
                            </Typography>
                            <Typography noWrap={false}>
                                {toLocationSuburbAfterSel==""?"":toLocationSuburbAfterSel}
                                {toLocationStateAfterSel==""?"":" "+toLocationStateAfterSel}
                                {toLocationPostcodeAfterSel==""?"":" "+toLocationPostcodeAfterSel}
                            </Typography>
                        </Box>
                    )
                }


            </Grid>
            {/* LOADER SHOWS WHEN WAREHOUSE STUFF IS LOADING */}
            { (warehouseAddressDataLoadStatus) && (<Loading />) }
            <br/>
            {/* <Pickup Details /> */}
            <Grid item>
                <Typography variant="h6">Delivery Details</Typography>
            </Grid>
            <div 
                className={borderBox}
                style={{
                    padding : '1em'
                }}
            >
                <Grid item direction="row" className={[oneRow]}>
                    <Grid item >
                        <Typography variant="body1">
                            {/* 1st line => FromLocation.AddressLine1 */}
                            
                            {(taskInfo && taskInfo.FromLocation!==undefined && taskInfo.FromLocation && taskInfo.FromLocation.AddressLine1!==undefined )?taskInfo.FromLocation.AddressLine1: ""}  
                        </Typography>
                        <Typography variant="body1">
                            {/* 2nd line => FromLocation.Suburb FromLocation.State FromLocation.Postcode */}
                            {(taskInfo && taskInfo.FromLocation!==undefined && taskInfo.FromLocation && taskInfo.FromLocation.Suburb!==undefined )?taskInfo.FromLocation.Suburb: ""}
                            {(taskInfo && taskInfo.FromLocation!==undefined && taskInfo.FromLocation && taskInfo.FromLocation.State!==undefined )?"  "+taskInfo.FromLocation.State: ""}
                            {(taskInfo && taskInfo.FromLocation!==undefined && taskInfo.FromLocation && taskInfo.FromLocation.Postcode!==undefined )?"  "+taskInfo.FromLocation.Postcode: ""}
                        </Typography>
                        
                    </Grid>
                    <Grid item direction="column">                           
                        <Grid item container direction="row">
                            <Typography variant="body2">
                                <Link  
                                // style={{"cursor":"pointer"}}
                                 >
                                    <SkbIcon  icon={DomainIcon}/>
                                    
                                </Link> &nbsp;
                                {contractorCompanyName?contractorCompanyName:""}
                            </Typography>
                        </Grid>
                        <ContractorRepresentativePhoneEmailStructure  
                            dataContractorDetails={{
                                "representative":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.Representative!==undefined )?taskInfo.Company.Representative: "",
                                "contractorRepresentativePhone":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.ContractorRepresentativePhone!==undefined )?taskInfo.Company.ContractorRepresentativePhone: "",
                                "taskId":taskId,
                                "representativeEmail":(taskInfo && taskInfo.Company!==undefined && taskInfo.Company && taskInfo.Company.RepresentativeEmail!==undefined )?taskInfo.Company.RepresentativeEmail: "",
                                "mailSubject":`${`Skybridge stock return task ${taskId} query`}`,"implementedIn":"TASK_INFO_SCHEDULE"
                            }} 
                        />
                          
                    </Grid>
                
                </Grid>
                <br/>
                <Grid item direction="row" className={oneRow}>
                    <Grid item>
                        <Typography variant="body2">Delivery Window</Typography>
                    </Grid>
                    <Grid item  className={oneRow}>
                        
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                
                                value={deliveryDate}
                                placeholder=""
                                leftArrowIcon={<ArrowBackIosIcon />}
                                onChange={getDeliveryWindowDate}
                                rightArrowIcon={<ArrowForwardIosIcon />}
                                keyboardIcon={<EventIcon />}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                'id': 'date-picker-inline-changedatebtn'
                                }}
                                leftArrowButtonProps = {
                                {
                                    'id': 'date-picker-inline-leftbtn'
                                }
                                }
                                rightArrowButtonProps = {
                                {
                                    'id': 'date-picker-inline-righbtn'
                                }
                                }

                                error={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.deliveryDateErrMsg!==undefined?true:false}
                                helperText={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.deliveryDateErrMsg!==undefined?contrDroppOffScenarioValidateFlagErrMessages.deliveryDateErrMsg:""}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                
                    
                    <Grid item>                          
                          
                           {
                            deliveryWindowTimeSlot
                            &&
                            <SkbSelect 
                                id="drpofWindtimeSlotlist" 
                                value={deliveryWindowTimeSlot}
                                // items={getDeliveryWindowtimeSlotDisplayData(dropOffTimeSlotList)}
                                items={timeSlotOptionList}
                                valueChanged={deliveryTimeSlotChangeHandler}
                                error={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.deliveryWindowTimeSlotErrMsg!==undefined?true:false}
                             helperText={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.deliveryWindowTimeSlotErrMsg!==undefined?contrDroppOffScenarioValidateFlagErrMessages.deliveryWindowTimeSlotErrMsg:""}
                            />
                        }
                        {
                            !deliveryWindowTimeSlot
                            &&
                            <SkbSelect
                            id="drpofWindtimeSlotlist" 
                                value={deliveryWindowTimeSlot}
                                // items={getDeliveryWindowtimeSlotDisplayData(dropOffTimeSlotList)}
                                items={timeSlotOptionList}
                                valueChanged={deliveryTimeSlotChangeHandler}
                                error={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.deliveryWindowTimeSlotErrMsg!==undefined?true:false}
                             helperText={contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.deliveryWindowTimeSlotErrMsg!==undefined?contrDroppOffScenarioValidateFlagErrMessages.deliveryWindowTimeSlotErrMsg:""}
                            />
                        }
                    </Grid>
                </Grid>
            </div>
            <br />
            
            {/* Pakaging Details */}
            <Grid item>
                <Typography variant="h6">Packaging Details</Typography>
            </Grid>
            <Grid 
                item 
                direction="row"
                className={borderBox}
                style={{
                    padding : '1em',
                }}
            >
                <Grid item className={oneRow}>
                    <Typography variant="body2">
                        # of Pallets
                    </Typography>
                    <SkbTextField 
                        value={palletsAndCartons.pallets}
                        disabled
                    />
                </Grid>
                <br />
                <Grid item className={oneRow}>
                    <Typography variant="body2">
                        # of Cartons
                    </Typography>
                    <SkbTextField 
                        value={palletsAndCartons.cartons}
                        disabled
                    />
                </Grid>

            </Grid>
            <br />
            <Grid item container>
                <Typography variant="h6">
                    Consignment Labels
                </Typography>
            </Grid>
            <Grid item container direction="row" className={rowCenter}>
                <Grid item className={marginRight}>
                    <DragAndDropFileUpload 
                        onDrop={onDrop}
                    />
                </Grid>
                <Grid item className={marginRight}>
                    <Typography>Or</Typography>
                </Grid>
                <Grid item>
                    <Input 
                        type="file"
                        placeholder="Upload Labels"
                        style={{
                            display : 'none'
                        }}
                        onChange={fileUploadBtnOnChange}
                        id="upload-contractor-shipment-btn"
                        inputProps={{ multiple: true }} 
                    />
                    <SkbButton 
                        primary 
                        size="medium" 
                        text={
                            "Upload Labels"
                        } 
                        onClick={e=>{
                            document.querySelector("#upload-contractor-shipment-btn").click();
                        }}
                    />
                </Grid>
                
            </Grid>

            <Grid item container className={labelErrorStruct} >
                <Typography variant="h10">
                {contrDroppOffScenarioValidateFlagErrMessages && contrDroppOffScenarioValidateFlagErrMessages.labelFilesErrMsg!==undefined?contrDroppOffScenarioValidateFlagErrMessages.labelFilesErrMsg:""}
                </Typography>               
               
            </Grid>
            {
                labelFiles.length > 0 
                &&
                <Grid 
                    item 
                    direction="column"
                    style = {{
                        border : '1px dotted grey',
                        padding : '0.5em',
                        marginTop : '1.5em',
                        fontSize : '0.8em',
                    }}
                >
                    {
                        labelFiles.map((f,i)=>{
                            return(
                                <Grid 
                                    item 
                                    className={oneRow}
                                    style={{
                                        
                                        margin : '0.2em auto',
                                        padding : '0.1em 0.2em',
                                    }}   
                                    key={i} 
                                >
                                    <Typography 
                                        variant="body1"
                                        style={{
                                            marginRight : '1em'
                                        }}    
                                    >
                                       {f.localFileName}
                                        
                                    </Typography>
                                    <Typography 
                                        variant="body2"
                                    >
                                        {filesize(f.localFileSize)}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
            <Grid 
                style={{
                    display : 'flex',
                    alignItems : 'center',
                    justifyContent : 'flex-end',
                    marginTop : '0.5em',
                }}
            >
                <SkbButton 
                    primary 
                    outlined 
                    text={"Finish"} 
                    onClick={finishButtonClickHandler}
                />
            </Grid>
            
        </Grid>
    );

}

export default ShipmentDetailsContractorDropOff;