import { SkbLogger,SeverityLevel } from '..';
import * as misc from '../../utils/misc';
import { crypts } from '../../utils/crypts';
import { BlobServiceClient , Blob} from "@azure/storage-blob";

var crcTableForPhotoSync = [];

function makeCRCTableForPhotoSync(){
    var c;
    for(var n =0; n < 256; n++){
        c = n;
        for(var k =0; k < 8; k++){
            c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTableForPhotoSync[n] = c;
    }
    return crcTableForPhotoSync;
}

function crc32ForPhotoSync(str) {
    var crcTableForPhotoSync = crcTableForPhotoSync || (crcTableForPhotoSync = makeCRCTableForPhotoSync());
    var crc = 0 ^ (-1);

    for (var i = 0; i < str.length; i++ ) {
        crc = (crc >>> 8) ^ crcTableForPhotoSync[(crc ^ str.charCodeAt(i)) & 0xFF];
    }

    return (crc ^ (-1)) >>> 0;
};


/**
 * 
 * @param {*} consignmentLocationCode 
 * @param {*} base64Content 
 * @param {*} contentType 
 * @returns LabelFilePath (without base url or token)
 */
 export async function uploadOneThreePLImportationFileToBlob(base64Content, fileName, requestId){
    try{
        const sas_azureStorageKey = crypts.decrypt(localStorage.getItem('sas_azureStorageEncrypted'));
        // SkbLogger.applicationTraceSub('Stock Service',SeverityLevel.Error,'Upload File','uploadOneConsignmentLabelFileToBlob',
        //                                 '{Consignment} file is being uploaded',
        //                                 {Consignment:apiResponse:uploadBlobResponse});

        var blobServiceClient = new BlobServiceClient(
            `https://${process.env.REACT_APP_AZURE_STORAGE_NAME}.blob.core.windows.net${sas_azureStorageKey}`
            ); 
            var byteCharacters = atob(base64Content);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            // const fileName=crc32ForPhotoSync(base64Content);
            var newBlobName = `${requestId}/${fileName}`;
            //console.log('blob file name from CRC32',newBlobName);

            var containerClient = blobServiceClient.getContainerClient(`${process.env.REACT_APP_AZURE_CONTAINER_NAME}`);
            if(!containerClient){
                return '';
            }
            var blockBlobClient = containerClient.getBlockBlobClient(newBlobName);
            var byteArray = new Uint8Array(byteNumbers);

            
            var uploadBlobResponse = await blockBlobClient.upload(byteArray, byteArray.length);
            //console.log('bobl upload response ',uploadBlobResponse._response.status);

            if(uploadBlobResponse._response.status === 201){
                return newBlobName;
                
            }else{
                // SkbLogger.applicationTraceSub('Stock Service',SeverityLevel.Error,'Upload File','uploadOneConsignmentLabelFileToBlob',
                //                         '{Consignment} {fileName} uploading failed. Blob response:{apiResponse}',
                //                         {Consignment:consignmentLocationCode,fileName:newBlobName,apiResponse:uploadBlobResponse});
                return '';
            }
    
        
    }catch(error) {
        // SkbLogger.applicationTraceSub('Stock Service',SeverityLevel.Error,'Upload File','uploadOneConsignmentLabelFileToBlob',
        //                                 '{Consignment} file uploading failed with {error}',
        //                                 {Consignment:consignmentLocationCode,error:error});
        return '';

    }
    
}