/*
To use the log functions, just simplly import the functions required
import {logUserLogIn,logUserInput,logSysCallAPI,logError} from './services/SkbLogger'
*/

import React, { useEffect } from 'react';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { crypts } from '../utils/crypts';
//import { globalHistory } from "@reach/router";
import { config } from '../utils/config'
import moduleName from '../services/TelemetryService'
import { SkbLogger } from '.';
const reactPlugin = new ReactPlugin();


const ai = new ApplicationInsights({
    config: {
        instrumentationKey: config.REACT_APP_APPINSIGHTS_IKEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        //   extensionConfig: {
        //      [reactPlugin.identifier]: {
        //          history: browserHistory
        //      }
        //  } 
    }
});
ai.loadAppInsights();


export default Component => withAITracking(reactPlugin, Component);
export const appInsightsSC = ai.appInsights;
export { reactPlugin };





export function logUserLogIn(uerObj) {
    var now_utc = new Date().toISOString();
    const userData = { "user": uerObj.email };

    if (navigator.onLine)
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Security Activity',
                source: 'Login',
                who: uerObj.email,
                timestamp: now_utc,
                category: 'Login attempt',
                sub_category: 'User logged in to Minerva',
                activity: 'User logged in to Minerva',
                object: userData
            }
        });

}
/* var validatedId = authId.replace(/[,;=| ]+/g, "_");
appInsightsSC.setAuthenticatedUserContext(validatedId) */
//appInsightsSC.setAuthenticatedUserContext(authId);
/* const prefix='[Device/User/Component]';
console.log(Date.now()+ ' ' +prefix + ' ' + str); */


export function logUserLogOut() {
    //appInsightsSC.clearAuthenticatedUserContext();
    if (navigator.onLine) {
        var now_utc = new Date().toISOString();
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Security Activity',
                source: 'Logout',
                who: '',
                timestamp: now_utc,
                category: 'Logout attempt',
                sub_category: 'User logged out from Minerva',
                activity: 'User logged out from Minerva',
                object: ''
            }
        });
    }
}

export function logUserSignUp(str) {
    const prefix = '[Device/User/Component]';
    console.log(Date.now() + ' ' + prefix + ' ' + str);
}

export function logUserStartActivity(str, activityRef) {
    const prefix = '[Device/User/Component]';
    console.log(Date.now() + ' ' + prefix + ' ' + str + ' ', activityRef);
}


// this function predined the registeration object. So if the log only request the registeration object then should use this function.
// registeration object data is from local storage. Message should have a place holder {UserObject}. 
export function userSecurityEventWithRegistrationObject(moduleName, category, subCategory, result, message, userName = null) {
    var now_utc = new Date().toISOString();

    let authUser = crypts.decrypt(localStorage.getItem('auth_user'));
    authUser = JSON.parse(authUser)


    var identityType = ''
    if (/^auth0\|/ig.test(authUser.sub)) {
        identityType = 'Non-Federated'
    } else if (/^google-oauth2\|/ig.test(authUser.sub)) {
        identityType = 'Google'
    } else {
        identityType = 'Microsoft-Live'
    }
    SkbLogger.logDebugInfo('from userSecurityEventWithRegistrationObject....', identityType, authUser)
    let registrationObject = {
        "Full Name": (userName || (identityType === 'Non-Federated' ? '' : authUser.name)),
        "Email Address": authUser.email,
        "Identity Type": identityType,
        "Address 1": '',
        "Phone Number": ''
    }

    let obj = [{
        registrationObject
    }]

    if (navigator.onLine) {
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Security Activity',
                source: moduleName,
                who: authUser.email,
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                result: result,
                message: message,
                object: obj
            }
        });
    }

}


// this function predined the user object. So if the log only request the user object then should use this function.
// user object data is from local storage. Message should have a place holder {UserObject}. 
export function userSecurityEventWithUserObject(moduleName, category, subCategory, result, message) {
    var now_utc = new Date().toISOString();

    let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
    skbUser = JSON.parse(skbUser)
    let authuser = crypts.decrypt(localStorage.getItem('auth_user'));
    authuser = JSON.parse(authuser)
    var identityType = ''
    if (/^auth0\|/ig.test(authuser.sub)) {
        identityType = 'Non-Federated'
    } else if (/^google-oauth2\|/ig.test(authuser.sub)) {
        identityType = 'Google'
    } else {
        identityType = 'Microsoft-Live '
    }

    let userObject = {
        "Full Name": skbUser.name,
        "Email Address": skbUser.email,
        "Identity Type": identityType,
        "Address 1": skbUser.company.streetAddress,
        "Phone Number": skbUser.phone
    }
    let obj = [{
        userObject
    }]

    if (navigator.onLine)
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Security Activity',
                source: moduleName,
                who: skbUser.userLoginName,
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                result: result,
                message: message,
                object: obj
            }
        });

}


// this function predined the user object. So if the log only request the user object then should use this function.
// user object data is from local storage. Message should have a place holder {UserObject}. 
export function userAuditEventWithUserObject(moduleName, category, subCategory, result, message) {
    var now_utc = new Date().toISOString();

    let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
    skbUser = JSON.parse(skbUser)
    var identityType = ''
    if (/^auth0\|/ig.test(skbUser.userLoginName)) {
        identityType = 'Non-Federated'
    } else if (/^google-oauth2\|/ig.test(skbUser.userLoginName)) {
        identityType = 'Google'
    } else {
        identityType = 'Microsoft-Live '
    }

    let UserObject = {
        "Full Name": skbUser.name,
        "Email Address": skbUser.userLoginName,
        "Identity Type": identityType,
        "Address 1": skbUser.company.streetAddress,
        "Phone Number": skbUser.phone
    }


    if (navigator.onLine)
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Audit Log',
                source: moduleName,
                who: skbUser.userLoginName,
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                result: result,
                message: message,
                object: UserObject
            }
        });

}


export function userSecurityEventWithRegistrationObjAndBussinessObj(moduleName, category, subCategory, result, message) {
    var now_utc = new Date().toISOString();

    let skbUser = crypts.decrypt(localStorage.getItem('skb_user'));
    let authuser = crypts.decrypt(localStorage.getItem('auth_user'));
    skbUser = JSON.parse(skbUser);

    var identityType = ''
    if (/^auth0\|/ig.test(authuser.sub)) {
        identityType = 'Non-Federated'
    } else if (/^google-oauth2\|/ig.test(authuser.sub)) {
        identityType = 'Google'
    } else {
        identityType = 'Microsoft-Live '
    }

    const registrationObject = {
        "Full Name": skbUser.name,
        "Email Address": skbUser.email,
        "Identity Type": identityType,
        "Address 1": skbUser.company.streetAddress,
        "Phone Number": skbUser.phone
    }
    const bussinessObject = {
        "Business Name": skbUser.company.name,
        "Business Number": skbUser.company.taxNumber
    }

    const registrationObjectAndBussinessObject = [{
        registrationObject,
        bussinessObject
    }];

    if (navigator.onLine)
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Audit Log',
                source: moduleName,
                who: skbUser.userLoginName,
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                result: result,
                message: message,
                object: registrationObjectAndBussinessObject
            }
        });

}


//obj should be object arrary
export function userSecurityEvent(moduleName, loginEmail, category, subCategory, result, message, ...obj) {
    var now_utc = new Date().toISOString();

    if (navigator.onLine)
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Security Activity',
                source: moduleName,
                who: loginEmail,
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                result: result,
                message: message,
                object: obj
            }
        });

}

//obj should be object arrary
export function userAuditEvent(moduleName, loginEmail, category, subCategory, result, message, ...obj) {
    var now_utc = new Date().toISOString();
    if (navigator.onLine)
        appInsightsSC.trackEvent({
            name: process.env.REACT_APP_ENV_TYPE,
            properties: {
                type: 'User Audit Log',
                source: moduleName,
                who: loginEmail,
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                result: result,
                message: message,
                object: obj
            }
        });

}


export function applicationTraceSub(moduleName, severityLevel, category, subCategory, activity, ...obj) {
    var now_utc = new Date().toISOString();

    if (navigator.onLine)
        appInsightsSC.trackTrace({
            message: process.env.REACT_APP_ENV_TYPE,
            severityLevel: severityLevel,
            properties: {
                type: 'Application Running Information',
                source: moduleName,
                who: 'Minerva App',
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                activity: activity,
                object: obj
            }
        });

    if (process.env.REACT_APP_ENV_TYPE == 'prod') return; //ON production, do not print
    const prefix = '[TRACE]';
    console.log(Date.now() + ' ' + category + ' ' + activity, obj);

}

export function applicationExceptionSub(moduleName, category, subCategory, activity, ...obj) {
    var now_utc = new Date().toISOString();

    if (navigator.onLine)
        appInsightsSC.trackException({
            exception: new Error("Internal Error"),
            name: process.env.REACT_APP_ENV_TYPE,
            severityLevel: SeverityLevel.Warning,
            properties: {
                type: 'Application Running Information',
                source: moduleName,
                who: 'Minerva App',
                timestamp: now_utc,
                category: category,
                sub_category: subCategory,
                activity: activity,
                object: obj
            }
        });

    if (process.env.REACT_APP_ENV_TYPE == 'prod') return; //ON production, do not print
    const prefix = '[EXP]';
    console.log(Date.now() + ' ' + category + ' ' + activity, obj);
}

export function applicationTrace(moduleName, severityLevel, category, activity, ...obj) {
    var now_utc = new Date().toISOString();

    if (navigator.onLine)
        appInsightsSC.trackTrace({
            message: process.env.REACT_APP_ENV_TYPE,
            severityLevel: severityLevel,
            properties: {
                type: 'Application Running Information',
                source: moduleName,
                who: 'Minerva App',
                timestamp: now_utc,
                category: category,
                activity: activity,
                object: obj
            }
        });

    if (process.env.REACT_APP_ENV_TYPE == 'prod') return; //ON production, do not print
    const prefix = '[TRACE]';
    console.log(Date.now() + ' ' + category + ' ' + activity, obj);

}

export function applicationException(moduleName, category, activity, ...obj) {
    var now_utc = new Date().toISOString();

    if (navigator.onLine)
        appInsightsSC.trackException({
            exception: new Error("Internal Error"),
            name: process.env.REACT_APP_ENV_TYPE,
            severityLevel: SeverityLevel.Warning,
            properties: {
                type: 'Application Running Information',
                source: moduleName,
                who: 'Minerva App',
                timestamp: now_utc,
                category: category,
                activity: activity,
                object: obj
            }
        });

    if (process.env.REACT_APP_ENV_TYPE == 'prod') return; //ON production, do not print
    const prefix = '[EXP]';
    console.log(Date.now() + ' ' + category + ' ' + activity, obj);
}

export function logDebugInfo(str, ...moreInfos) {
    if (process.env.REACT_APP_ENV_TYPE == 'prod') return; //ON production, do not print
    const prefix = '[DEBUG]';
    console.log(Date.now() + ' ' + prefix + ' ' + str, moreInfos);
}

