import React,{useState,useEffect} from 'react';
import {
    Grid,
    Typography,
    Link,
    Tooltip

} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import {
    makeStyles,
} from '@material-ui/styles';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PrintIcon from '@material-ui/icons/Print';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import EventIcon from '@material-ui/icons/Event';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelorderButton from '../CancelOrderButton';
import SkbIcon from '../../../../skb_controls/components/SkbIcon'
import SkbButton from '../../../../skb_controls/components/SkbButton';
import * as returnStockUtils from '../../../../utils/returnstock/misc';
import {SkbSelect} from '../../../../skb_controls/components/SkbSelect';
import {SkbSwitch} from '../../../../skb_controls/components/SkbSwitch';
import ConsignmentInfographic from '../../tasks_components/ConsignmentInfographic';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    
  } from '@material-ui/pickers';
  import { useHistory } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import * as utils from '../../../../utils/misc';
import {getTaskNextActionBy} from '../../../../services/TaskService';
import RescheduleConsignment from '../RescheduleConsignment';
import Loading from '../../../Loading';


/* import { useSnackbar } from 'notistack'; */

const styles = makeStyles((theme)=>(
    {
        root : {
            border : '1px solid lightgray',
            padding : '1em',
            margin : '1em auto',

        },
        row : {
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'space-between',
            margin : '0.2em 0',
            
        },
        leftRow : {
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'flex-start',
            wordBreak:'break-word'
        },
        atpStyle:{
            textDecoration: "underline",
            color: '#13bbff',
            flexBasis:'18rem'
        },
        forkStyle:{
            flexBasis:'18rem',
           
        },
        
        timeAndEta:{
            flexBasis:"13em",
            [theme.breakpoints.down('sm')]: {
                flexBasis:"auto",
                '& > div > div.MuiInputBase-adornedEnd':{
                    width:'15rem',
                    margin:'0'
                },
                '& > div.MuiFormControl-root':{
                    width:'15rem',
                    margin:'0'
                }
            },
        },
        pickDetails:{
            display:'flex',
            '& > div > div.MuiTextField-root': {
                marginTop:'24px'
            },
            
            [theme.breakpoints.down('sm')]: {
                flexDirection:'column'
            },
        } 
    }
))

//https://www.google.com/maps/search/?api=AIzaSyCqK1gDtEQhphO8lZHlitvBJ4cdQara5QM&query=centurylink+field
/* const { enqueueSnackbar } = useSnackbar(); */
const Order = (props) => {


    var consignment=props.props;
    var onelineAddress=returnStockUtils.buildAddressInOneLine(consignment.PickupAddressLine1, 
                            consignment.PickupAddressLine2,
                            consignment.PickupAddressSuburb,
                            consignment.PickupAddressState,
                            consignment.PickupAddressPostcode)
    var onelineDeliveryAddress=  returnStockUtils.buildAddressInOneLine(consignment.DeliveryAddressLine1, 
                            consignment.DeliveryAddressLine2,
                            consignment.DeliveryAddressSuburb,
                            consignment.DeliveryAddressState,
                            consignment.DeliveryAddressPostcode)                     
    var googleSearchaddressLine = 'https://www.google.com/maps/search/?api=1&query='+ onelineDeliveryAddress.replace(' ', '+')
    const  {
        row,
        leftRow,
    } = styles();
    return (
        <Grid container alignContent="stretch" spacing={2}>
            <Grid item >
            <ConsignmentInfographic subTaskStatus={consignment.TaskStatus} deliveryMethod={consignment.DeliveryMethod} 
                        pickUpDueday={consignment.PickupWindowDate||consignment.PickupDueday} returnDueDay={consignment.DropOffWindowDate||consignment.ReturnDueDate}/>
            </Grid>
            <Grid item direction="column">
                <Grid item className={leftRow}>
                    {consignment.DeliveryMethod ==="Skybridge courier" ?
                    <Typography variant="h6"><SkbIcon  icon={returnStockUtils.getDeliveryMethodIcon(consignment.DeliveryMethod)} />{consignment.CarrierName}</Typography>
                    : <Typography variant="h6"><SkbIcon  icon={returnStockUtils.getDeliveryMethodIcon(consignment.DeliveryMethod)} />Contractor drop-off</Typography>}
                </Grid>
                <Grid item className={leftRow}>
                    {/* <LocationOnIcon /> */}
                    <SkbIcon icon={LocationOnIcon}/>
                    {consignment.DeliveryMethod ==="Skybridge courier" ?
                    
                    <Link href={consignment.ConsignmentTrackingLink} target="__blank">Consignment #:{consignment.ConnoteNumber}</Link>:
                    <Link href={googleSearchaddressLine} target="__blank">{onelineDeliveryAddress}{consignment.ConnoteNumber}</Link>}
                </Grid>
                <Grid item>
                    <Typography 
                        variant="body1"
                        style={{
                            fontWeight : 'bold',
                            marginTop : '0.5em',
                        }}    
                    >Pickup Location</Typography>
                    <Typography variant="body2">
                    {onelineAddress}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}


const PickupOrDropOffWindow = ({props,setOpenReschedule}) => {
 /*    var consignment=props.props; */
    const  {
        row,
        leftRow,
        pickDetails,
        timeAndEta
    } = styles();


    const handleOpenReschedule=()=>{
        setOpenReschedule(true);
    }
    
    
    const dropOffWindowOptions=JSON.parse(localStorage.getItem('REUSABLE-SETTINGS-DropOffWindowOptions'));
    const pickupWindowOptions=JSON.parse(localStorage.getItem('REUSABLE-SETTINGS-PickupWindowOptions'));


    return(
        <Grid container direction="column">
            <Grid item>
                <Typography 
                    variant="body1"
                    style={{
                        marginBottom : '-1em',
                        marginTop : '1em',
                        fontWeight : 'bold',
                    }}    
                >{props.DeliveryMethod==="Skybridge courier"? "Pickup window":"Drop-off window"}</Typography>
            </Grid>
            <Grid item
                // style={{
                //     display : 'flex',
                //     alignItems : 'center',
                //     justifyContent : 'flex-start'
                // }}
            >
                <div  className={pickDetails}>
                    <div  className={timeAndEta}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            disabled={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            // margin="normal"
                            // marginTop="24px"
                            value={props.DeliveryMethod==="Skybridge courier"?props.PickupWindowDate:props.DropOffWindowDate}
                            
                            leftArrowIcon={<ArrowBackIosIcon/>}

                            rightArrowIcon={<ArrowForwardIosIcon />}
                            keyboardIcon={<EventIcon />}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            'id': 'date-picker-inline-changedatebtn'
                            }}
                            leftArrowButtonProps = {
                            {
                                'id': 'date-picker-inline-leftbtn'
                            }
                            }
                            rightArrowButtonProps = {
                            {
                                'id': 'date-picker-inline-righbtn'
                            }
                            }
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className={timeAndEta}>
                <SkbSelect 
                    disabled={true}
                    value={props.DeliveryMethod==="Skybridge courier"?props.PickupWindowETA:props.DropOffWindowETA}
                    items={props.DeliveryMethod==="Skybridge courier"?pickupWindowOptions:dropOffWindowOptions} //{["7am - 12pm", "12pm - 3pm"]} 
                    style={{
                        marginTop : '-13px'
                    }}
                />
                </div>
                </div>
            </Grid>
            <Grid item style={{
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                margin : '1em 0',
            }}>
                <SkbButton onClick={handleOpenReschedule} primary size="medium" text={<div className={row}><CalendarTodayIcon/> <Typography variant="body2">Reschedule</Typography> </div>} />
              
            </Grid>
        </Grid>
    )
}


const PickupDetails = ({props}) => {

    var Propatp;
    var Propfkr;
    if(!props.IsATPAuthorised || props.IsATPAuthorised ==='false'){
        Propatp=false;
    }else{
        Propatp=true;
    }
    if(!props.IsForkliftRequired || props.IsForkliftRequired ==='false'){
        Propfkr=false;
    }else{
        Propfkr=true;
    }

    const [atp, setAtp] = React.useState(Propatp);
    const [forklift, setForklift] = React.useState(Propfkr);

    const {
        row,
        atpStyle,
        forkStyle
    } = styles();
    return(
        <Grid container direction="column">
            <Grid item><Typography variant="h6">Pickup Details</Typography></Grid>
            <Grid 
                item
                direction="column"
                
            >
                <Grid item
                    className={row}
                >
                        <Tooltip title="I authorise the courier to pickup in my absence." enterTouchDelay={100} arrow={true} placement={"top-start"} >
                            <Typography variant="body1" className={atpStyle} >ATP</Typography>
                        </Tooltip>
                    <SkbSwitch disabled={true} primary val={atp} setVal={setAtp} showOnOffText={false}/>
                </Grid>
                <Grid item
                    className={row}
                >
                    <Link  className={forkStyle}>Forklift required for pickup ?</Link>
                    <SkbSwitch disabled={true} primary val={forklift} setVal={setForklift} showOnOffText={false}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

const printAllLabels=(labelDetails)=>
{
    //alert(utils.constructBlobUrl('consignment/NEWTestXH-007/3845152286.pdf'));
    labelDetails.forEach(element=> {
            printLabel(element.FileURL);
        })

    // foreach(label)
    // {
        //get labelUrl
            //printLabel(utils.constructBlobUrl('consignment/NEWTestXH-007/3845152286.pdf'));
        // }

}

function closePrint () {
    document.body.removeChild(this.__container__);
  }

function setPrint () {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
    this.contentWindow.focus(); // Required for IE
    this.contentWindow.print();
  }
  
  function printLabel(sURL) {
    var oHideFrame = document.createElement("iframe");
    oHideFrame.onload = setPrint;
    oHideFrame.style.position = "fixed";
    oHideFrame.style.right = "0";
    oHideFrame.style.bottom = "0";
    oHideFrame.style.width = "0";
    oHideFrame.style.height = "0";
    oHideFrame.style.border = "0";
    oHideFrame.src = sURL;
    document.body.appendChild(oHideFrame);
  }



const Options = ({props,updateStatus}) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    function updateConsignmentStatus(newStatus){
        return new Promise((resolve,reject)=>{
            setTimeout(() => {
                updateStatus(newStatus);
                resolve();
            }, 1000);
        }) 
    }

    function updateStatusAndPrintLabel(newStatus,subTaskId,labelDetails){
         var newStatus={
            newStatus: newStatus,
            nextActionBy:  getTaskNextActionBy(newStatus)
        }   
    updateConsignmentStatus(newStatus)
    .then(enqueueSnackbar(`Successfully printed your labels for return stock task ${subTaskId}`, { variant: "success" }))
        // updateStatus(newStatus);
        // enqueueSnackbar(`Successfully printed your labels for return stock task ${subTaskId}`, { variant: "success" });

        if(Array.isArray(labelDetails) && labelDetails.length>0){
            printAllLabels(labelDetails);
          
        }
        /* else{
            enqueueSnackbar('No available labels', { variant: "error" })
        } */
        

    }




    function updateStatusToNewStatus(newStatus,subTaskId){

        var newStatus={
            newStatus: newStatus,
            nextActionBy: getTaskNextActionBy(newStatus)
        }
        // updateConsignmentStatus(newStatus).then(history.push('/stocktasks'))
        updateConsignmentStatus(newStatus)
        .then(enqueueSnackbar(`Successfully marked return stock task ${subTaskId} as on board for delivery.`, { variant: "success" }))
        .then(history.push('/stocktasks'))
        // updateStatus(newStatus);
        // history.push('/stocktasks')
        //history.push(props.returnRoute || '/home');
    }

    

    const {row} = styles();
    return(
        <Grid container 
            style={{
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'flex-end',
                marginTop : '1em',
            }}
            spacing={2}
        >
            <Grid item>
            {props.DeliveryMethod ==="Skybridge courier"?
                <SkbButton primary text={<div className={row} 
                    onClick={()=>updateStatusAndPrintLabel("Awaiting pickup",props.SubTaskId,props.LabelDetails)}><PrintIcon /> 
                <Typography variant="body2">{props.TaskStatus==="Awaiting pickup"?"Re-print Labels":"Print Labels"}</Typography></div>} />
            :<SkbButton primary text={<div className={row} 
            onClick={()=>updateStatusAndPrintLabel("Awaiting drop-off",props.SubTaskId,props.LabelDetails)}><PrintIcon /> 
            <Typography variant="body2">{props.TaskStatus==="Awaiting drop-off"?"Re-print Labels":"Print Labels"}</Typography></div>} />}
            </Grid>
            <Grid item>
                {props.DeliveryMethod ==="Skybridge courier"?
                <SkbButton onClick={()=>updateStatusToNewStatus("Awaiting delivery",props.SubTaskId)} disabled={props.TaskStatus!=="Awaiting pickup"} primary text={<div className={row}><LocalMallIcon /> 
                <Typography variant="body2">Package Sent</Typography></div>} />
                :<SkbButton onClick={()=>updateStatusToNewStatus("Delivered",props.SubTaskId)} disabled={props.TaskStatus!=="Awaiting drop-off"} primary text={<div className={row}><LocalMallIcon /> 
                <Typography variant="body2">Package Delivered</Typography></div>} />
                }
                
            </Grid>
        </Grid>
    )
} 

export default function StepConsignmentLabels(props) {
   console.log('StepConsignmentLabelsStepConsignmentLabelsStepConsignmentLabels',props)
    const {
        row, root,

    } = styles();
    const {isLoading,loadPickupDetails,updatePickupDetails,consignment,loadDropOffDetails,updateDropOffDetails,loadLabelDetails,
            updateConsignmentStatus,setOpenReschedule,openReschedule,loadPackageDetails, updatePackage,
            addPackage, deletePackage}=props;
      
    return (
        <Grid container direction="column" className={root}>
            <Grid item direction="row">
                 
                {/*  phase 1 not require this cancel button
                <Grid item style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '2em',
                }}>
                     <CancelorderButton enabled={true} handleCancel={()=>{}} displayTex="Cancel Order"  />
                </Grid>  */}

                <Order props={consignment}/>
                <PickupOrDropOffWindow props={consignment} setOpenReschedule={setOpenReschedule} />
                {consignment.DeliveryMethod ==="Skybridge courier" &&
                <PickupDetails props={consignment}/>}
                <Options props={consignment} updateStatus={updateConsignmentStatus} />
               {openReschedule && 
               <RescheduleConsignment 
                isLoading={isLoading} 
                openReschedule={openReschedule} 
                setOpenReschedule={setOpenReschedule}
                consignmentDetails={consignment} 
                loadDropOffDetails={loadDropOffDetails}
                loadPickupDetails={loadPickupDetails}
                loadPackageDetails={loadPackageDetails}
                updatePickupDetails={updatePickupDetails}
                updateDropOffDetails={updateDropOffDetails}
                updatePackage={updatePackage}
                addPackage={addPackage} 
                deletePackage={deletePackage}
                updateConsignmentStatus={updateConsignmentStatus}
                /> } 
                {isLoading && <Loading />}
            </Grid>
        </Grid>
    );
}
