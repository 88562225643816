import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';
import HomeIcon from '@material-ui/icons/Home';
import InputIcon from '@material-ui/icons/Input';
import BusinessIcon from '@material-ui/icons/Business';
import StoreIcon from '@material-ui/icons/Store';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditLocationIcon from '@material-ui/icons/EditLocation';

export function makeIcon(iconName) {
    console.log('from makeIcon', iconName)
    switch (iconName) {
        case 'Assignment':
            return AssignmentIcon;
        case 'Build':
            return BuildIcon;
        case 'Create':
            return CreateIcon;
        case 'Home':
            return HomeIcon;
        case 'Input':
            return InputIcon;
        case 'Settings':
            return SettingsIcon;
        case 'Business':
            return BusinessIcon;
        case "Order":
            return StoreIcon;
        case "Return":
            return Rotate90DegreesCcwIcon;
        case "Dispose":
            return DeleteSweepIcon;
        case "Transfer":
            return SwapHorizontalCircleIcon;        
        case "MyStock":
            return LibraryBooksIcon;      
        case "MyLocations":
            return EditLocationIcon;      
        default:
            return AssignmentIcon;

    }
}