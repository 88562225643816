
import * as dataSyn from './dataSaverAndTracker';
import { config } from '../utils/config';
import { SkbLogger, SeverityLevel } from '../services';
import { currentTimeToUTCTimeInString, StringFormat,constructBlobUrl } from '../utils/misc';
import { Delete } from '@material-ui/icons';


/**
 * SEARCH CONSIGNMENT SERVICE
 * SEARCH CONSIGNMENT ENDPOINT NOT AVAILABLE
 * This service will load chunks of consignment data and un the search 
 */

export const searchConsignmentTasks = async ()=> {
    
}


/**
 * This function will be similar to loadTaskList
 * @param {*} taskType : "CONSIGNMENT"
 * @returns : this method will always return “CONSIGNMENT” type of tasks with filtering by given stock code, consignment number or serial number
 */

export const loadConsignmentTaskList = async (taskType="STOCKRETURN") => {
    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadConsignmentTaskList', 'Service is being called with {taskType}', { taskType: taskType });
    var result = [];
    const allCompanyTaskClusters = await dataSyn.easyDownloadRootClustersAndTheirItems('CompanyTask');
    for(let i=0;i<allCompanyTaskClusters.length ; i++){
        let companyTaskMatched = false;
        const oneCompanyTaskCluster = allCompanyTaskClusters[i];
        const typeItem = await dataSyn.easyRetrieveItemInCluster(
            oneCompanyTaskCluster.referenceEntity, 
            oneCompanyTaskCluster.referenceID, 
            oneCompanyTaskCluster.name, 
            'TaskType'
        );
        console.log("Task Service : load consignment Tasks list" ,typeItem)
        if (typeItem.value === taskType) {
            companyTaskMatched= true;
        } 

        if (companyTaskMatched) {
            SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadConsignmentTaskList', '{oneCompanyTaskCluster} is found and type matched.', { oneCompanyTaskCluster: oneCompanyTaskCluster });

            var oneCompanyTask = await dataSyn.easyNormalisePlainItemsInCluster(
                oneCompanyTaskCluster.referenceEntity, oneCompanyTaskCluster.referenceID, oneCompanyTaskCluster.name
            );
            const allCompanySubTaskClusters = await dataSyn.easyDownloadChildrenClustersAndTheirItems(
                oneCompanyTaskCluster.referenceEntity, oneCompanyTaskCluster.referenceID, oneCompanyTaskCluster.name
            );
            oneCompanyTask.SubTasks = [];
            for (let j = 0; j < allCompanySubTaskClusters.length; j++) {
                const oneCompanySubTaskCluster = allCompanySubTaskClusters[j];
                var oneCompanySubTask = await dataSyn.easyNormalisePlainItemsInCluster(
                    oneCompanySubTaskCluster.referenceEntity, oneCompanySubTaskCluster.referenceID, oneCompanySubTaskCluster.name
                );
                try {
                    const theConsignmentOrLocationOrOtherClusters = await dataSyn.easyDownloadChildrenClustersAndTheirItems(
                        oneCompanySubTaskCluster.referenceEntity, oneCompanySubTaskCluster.referenceID, oneCompanySubTaskCluster.name
                    );
    
                    //assuming theConsignmentOrLocationOrOtherClusters contains only one element
                    if (Array.isArray(theConsignmentOrLocationOrOtherClusters) && theConsignmentOrLocationOrOtherClusters.length) {
                        const oneConsignmentOrLocationOrOtherCluster = theConsignmentOrLocationOrOtherClusters[0];
                        const oneConsignmentOrLocationOrOtherObj = await dataSyn.easyNormalisePlainItemsInCluster(
                            oneConsignmentOrLocationOrOtherCluster.referenceEntity, oneConsignmentOrLocationOrOtherCluster.referenceID, oneConsignmentOrLocationOrOtherCluster.name
                        );
                        //place the Consignment/Location info in the same SubTask object
                        oneCompanySubTask = {...oneCompanySubTask, ...oneConsignmentOrLocationOrOtherObj };
                    }
                } catch (error) {
                    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadConsignmentTaskList', 'No Consignment or Location or other specific CompanySubTask Clusters.', { error: error });
                }
                

                oneCompanyTask.SubTasks.push(oneCompanySubTask);
            }
            result.push(oneCompanyTask);
        }
    }
    return result;
}




// ==============================================================================================================

/**
 * This function dowloads all CompanyTask and returns all types of tasks unless taskType is specified.
 * @param {*} taskType : optional. e.g. 'STOCKRETURN'. By default, it's '', meaning all taskType.  
 * @returns: array for Tasks (name as per Redux Store but it's actually CompanyTask table in DB). The element object contains SubTasks property usually 
 */
export async function loadTaskList(taskType = '') {
    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadTaskList', 'Service is being called with {taskType}', { taskType: taskType });

    var ret = [];
    const allCompanyTaskClusters = await dataSyn.easyDownloadRootClustersAndTheirItems('CompanyTask');
    for (let i = 0; i < allCompanyTaskClusters.length; i++) {
        const oneCompanyTaskCluster = allCompanyTaskClusters[i];

        var companyTaskMatched = false;
        if (taskType === '') {
            companyTaskMatched = true;
        } else {
            const typeItem = await dataSyn.easyRetrieveItemInCluster(
                oneCompanyTaskCluster.referenceEntity, oneCompanyTaskCluster.referenceID, oneCompanyTaskCluster.name, 'TaskType'
            );
            if (typeItem.value === taskType) {
                companyTaskMatched = true;
            }
        }

        if (companyTaskMatched) {
            SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadTaskList', '{oneCompanyTaskCluster} is found and type matched.', { oneCompanyTaskCluster: oneCompanyTaskCluster });

            var oneCompanyTask = await dataSyn.easyNormalisePlainItemsInCluster(
                oneCompanyTaskCluster.referenceEntity, oneCompanyTaskCluster.referenceID, oneCompanyTaskCluster.name
            );
            const allCompanySubTaskClusters = await dataSyn.easyDownloadChildrenClustersAndTheirItems(
                oneCompanyTaskCluster.referenceEntity, oneCompanyTaskCluster.referenceID, oneCompanyTaskCluster.name
            );
            oneCompanyTask.SubTasks = [];
            for (let j = 0; j < allCompanySubTaskClusters.length; j++) {
                const oneCompanySubTaskCluster = allCompanySubTaskClusters[j];
                var oneCompanySubTask = await dataSyn.easyNormalisePlainItemsInCluster(
                    oneCompanySubTaskCluster.referenceEntity, oneCompanySubTaskCluster.referenceID, oneCompanySubTaskCluster.name
                );
                try {
                    const theConsignmentOrLocationOrOtherClusters = await dataSyn.easyDownloadChildrenClustersAndTheirItems(
                        oneCompanySubTaskCluster.referenceEntity, oneCompanySubTaskCluster.referenceID, oneCompanySubTaskCluster.name
                    );
    
                    //assuming theConsignmentOrLocationOrOtherClusters contains only one element
                    if (Array.isArray(theConsignmentOrLocationOrOtherClusters) && theConsignmentOrLocationOrOtherClusters.length) {
                        const oneConsignmentOrLocationOrOtherCluster = theConsignmentOrLocationOrOtherClusters[0];
                        const oneConsignmentOrLocationOrOtherObj = await dataSyn.easyNormalisePlainItemsInCluster(
                            oneConsignmentOrLocationOrOtherCluster.referenceEntity, oneConsignmentOrLocationOrOtherCluster.referenceID, oneConsignmentOrLocationOrOtherCluster.name
                        );
                        //place the Consignment/Location info in the same SubTask object
                        oneCompanySubTask = {...oneCompanySubTask, ...oneConsignmentOrLocationOrOtherObj };
                    }
                } catch (error) {
                    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadTaskList', 'No Consignment or Location or other specific CompanySubTask Clusters.', { error: error });
                }
                

                oneCompanyTask.SubTasks.push(oneCompanySubTask);
            }
            ret.push(oneCompanyTask);
        }
    }
    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'loadTaskList', 'Service is returning {result}', { result: ret });
    return ret;
}

// /**
//  * This function returns the first level cluster below the ComapnySubTask. that is usually the basic info for the type-specific details. 
//  * it's assumed that loadTaskList has just been called.
//  * @param {*} taskId CompanyTask.Id in DB or Tasks.TaskId in Redux Store
//  * @returns array for SubTasks (name as per Redux Store but it's actually CompanySubTask table and its ReferenceEntity table, like Consignment/Location)
//  */
// export async function loadTaskBasicInfo(taskId) {

//     const allCompanySubTaskClusters = await dataSyn.easyDownloadChildrenClustersAndTheirItems(
//         'CompanyTask', taskId, 'Company Task'
//     );
//     var ret = [];
//     for (let j = 0; j < allCompanySubTaskClusters.length; j++) {
//         var oneRet;
//         const oneCompanySubTaskCluster = allCompanySubTaskClusters[j];
//         const oneCompanySubTaskObj = await dataSyn.easyNormalisePlainItemsInCluster(
//             oneCompanySubTaskCluster.referenceEntity, oneCompanySubTaskCluster.referenceID, oneCompanySubTaskCluster.name
//         );
//         oneRet = { ...oneCompanySubTaskObj };
//         const theConsignmentOrLocationOrOtherClusters = await dataSyn.easyDownloadChildrenClustersAndTheirItems(
//             oneCompanySubTaskCluster.referenceEntity, oneCompanySubTaskCluster.referenceID, oneCompanySubTaskCluster.name
//         );
//         //assuming theConsignmentOrLocationOrOtherClusters contains only one element
//         if (Array.isArray(theConsignmentOrLocationOrOtherClusters) && theConsignmentOrLocationOrOtherClusters.length) {
//             const oneConsignmentOrLocationOrOtherCluster = theConsignmentOrLocationOrOtherClusters[0];
//             const oneConsignmentOrLocationOrOtherObj = await dataSyn.easyNormalisePlainItemsInCluster(
//                 oneConsignmentOrLocationOrOtherCluster.referenceEntity, oneConsignmentOrLocationOrOtherCluster.referenceID, oneConsignmentOrLocationOrOtherCluster.name
//             );
//             //place the Consignment/Location info in the same SubTask object
//             oneRet = { ...oneRet, ...oneConsignmentOrLocationOrOtherObj };
//         }

//         ret.push(oneRet);
//     }

//     return ret;
// }


/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.
 * @returns: sample:
 *  [
                {
                    SerialNumber: "133434324",
                    StockCode: "EROD0001",
                    Description: "Ericsson Wireless Outdoor Unit",
                }
            ]

 */
export async function loadConsignmentAvailableSns(consignmentId) {
    var ret = [];
    const theConsignmentAvailableSnClusters = await dataSyn.easyDownloadReferenceEntityClustersAndTheirItems(
        'Consignment', consignmentId, 'ExistingAvailableSNs'
    );
    //assuming there is only one element in theConsignmentAvailableSnClusters
    if (Array.isArray(theConsignmentAvailableSnClusters) && theConsignmentAvailableSnClusters.length) {

        const oneConsignmentAvailableSnCluster = theConsignmentAvailableSnClusters[0];
        const availableSnDataItemArray = await dataSyn.easyRetrieveItemInCluster(
            oneConsignmentAvailableSnCluster.referenceEntity, oneConsignmentAvailableSnCluster.referenceID, oneConsignmentAvailableSnCluster.name,
            'Available_SN'
        );
        if (availableSnDataItemArray) {
            for (let index = 0; index < availableSnDataItemArray.length; index++) {
                const oneDataItem = availableSnDataItemArray[index];
                var oneSnStockObj = oneDataItem.value;
                try {
                    oneSnStockObj = JSON.parse(oneDataItem.value)
                } catch (error) { }
                ret.push(oneSnStockObj)
            }
        }
    }

    return ret;
}


/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.  
 * @returns sample: 
 * [
 * {
        StockCode: "ERMI0002",
        Description: "Ericsson Wireless Cat5e Outdoor Cable",
        StockType: "Non-Serialised",
        Condition: "Good",
        Qty: 14,
        QtyUnit: "Metre",
    }

 * ]
 */
export async function loadConsignmentPreviewItems(consignmentId) {
    var ret = [];
    const theConsignmentPreviewItemsClusters = await dataSyn.easyDownloadReferenceEntityClustersAndTheirItems(
        'Consignment', consignmentId, 'PreviewInfo'
    );
    //assuming there is only one element in theConsignmentPreviewItemsClusters
    if (Array.isArray(theConsignmentPreviewItemsClusters) && theConsignmentPreviewItemsClusters.length) {

        const oneConsignmentAvailableSnCluster = theConsignmentPreviewItemsClusters[0];
        const previewItemsDataItemArray = await dataSyn.easyRetrieveItemInCluster(
            oneConsignmentAvailableSnCluster.referenceEntity, oneConsignmentAvailableSnCluster.referenceID, oneConsignmentAvailableSnCluster.name,
            'Preview'
        );
        if (previewItemsDataItemArray) {
            for (let index = 0; index < previewItemsDataItemArray.length; index++) {
                const oneDataItem = previewItemsDataItemArray[index];
                var oneSnStockObj = oneDataItem.value;
                try {
                    oneSnStockObj = JSON.parse(oneDataItem.value)
                } catch (error) { }
                ret.push(oneSnStockObj)
            }
        }
    }

    return ret;
}


/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table. 
 * @returns sample 
 * [
        {
            StockCode: "EROD0001",
            SerialNumber: "133434399",
            Description: "Ericsson Wireless Outdoor Unit",
            ItemStatus: "Faulty",
            _PHOTO: "photo path or base64 content",
            Latitude: "-37.23",
            Longitude: "125.134",
        }
    ],

 */
export async function loadConsignmentSnItems(consignmentId) {
    var ret = [];
    const theConsignmentSnItemsClusters = await dataSyn.easyDownloadReferenceEntityClustersAndTheirItems(
        'Consignment', consignmentId, 'SerialisedItems'
    );
    //assuming there is only one element in theConsignmentAvailableSnClusters
    if (Array.isArray(theConsignmentSnItemsClusters) && theConsignmentSnItemsClusters.length) {

        const oneConsignmentSnItemsCluster = theConsignmentSnItemsClusters[0];
        const consignmentSnItemsArray = await dataSyn.easyRetrieveItemInCluster(
            oneConsignmentSnItemsCluster.referenceEntity, oneConsignmentSnItemsCluster.referenceID, oneConsignmentSnItemsCluster.name,
            'SN'
        );
        if (consignmentSnItemsArray) {
            for (let index = 0; index < consignmentSnItemsArray.length; index++) {
                const oneDataItem = consignmentSnItemsArray[index];
                var oneSnStockObj = oneDataItem.value;
                try {
                    oneSnStockObj = JSON.parse(oneDataItem.value)
                } catch (error) { }
                ret.push(oneSnStockObj)
            }
        }
    }

    return ret;
}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} item sample:
 * {
        StockCode: "EROD0001",
        SerialNumber: "133434399",
        Description: "Ericsson Wireless Outdoor Unit",
        ItemStatus: "Faulty",
        _PHOTO: "photo path or base64 content",
        Latitude: "-37.23",
        Longitude: "125.134",
    },
 */
export async function addSnItemForConsignment(consignmentId, item) {
    const strItemValue = JSON.stringify(item);
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'SerialisedItems', 'SN', [strItemValue], 'APPEND'
    );
}



/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} wholeItemList  sample:
 * [
 * {
 *       StockCode: "EROD0001",
 *       SerialNumber: "133434399",
 *       Description: "Ericsson Wireless Outdoor Unit",
 *       ItemStatus: "Faulty",
 *       _PHOTO: "photo path or base64 content",
 *       Latitude: "-37.23",
 *       Longitude: "125.134",
 *   },
 *   {
 *       StockCode: "EROD0001",
 *       SerialNumber: "3566785",
 *       Description: "Ericsson Wireless Outdoor Unit",
 *       ItemStatus: "Good",
 *       _PHOTO: "photo path or base64 content",
 *       Latitude: "-37.23",
 *       Longitude: "125.134",
 *   }
 *]
 * @param {*} disableTrafficControl Optional. This paramter is introduced to address the performance concern on overwriting the whole array/list.
 *                                  It's false by default. That means this function will discard the calling (without throw error) if this function is being called too frequently.
 *                                  Set it to true if the caller wants to force it processing the data (e.g. when user presses Next/Complete for the Step), 
 *
 */
export async function overwriteSnItemListForConsignment(consignmentId, wholeItemList, disableTrafficControl = false) {
    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'overwriteSnItemListForConsignment', 'overwriteSnItemListForConsignment is called for {consignmentId}', { consignmentId: consignmentId });
    var calledRecently = false;
    const lastTimestamp = localStorage.getItem('last-overwriteSnItemListForConsignment-time');
    const DefinitionOfRecent = 5 * 1000; //5 seconds
    if (lastTimestamp && ((new Date()).getTime() - lastTimestamp) < DefinitionOfRecent) {
        calledRecently = true;
    }
    if (disableTrafficControl || !calledRecently) {
        localStorage.setItem('last-overwriteSnItemListForConsignment-time', (new Date()).getTime());
        var newValueList = [];
        for (let index = 0; index < wholeItemList.length; index++) {
            const oneItem = wholeItemList[index];
            const strItemValue = JSON.stringify(oneItem);
            newValueList.push(strItemValue);
        }
        await dataSyn.easySaveItemInCluster('Consignment', consignmentId, 'SerialisedItems', 'SN', newValueList);
    }else{
        SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'overwriteSnItemListForConsignment', 'Traffic Control has blocked the calling due to {lastTimestamp}.', { lastTimestamp: lastTimestamp });
    }

}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} item  sample:
 * {
        StockCode: "EROD0001",
        SerialNumber: "133434399",
        Description: "Ericsson Wireless Outdoor Unit",
        ItemStatus: "Faulty",
        _PHOTO: "photo path or base64 content",
        Latitude: "-37.23",
        Longitude: "125.134",
    },
 */
export async function deleteSnItemForConsignment(consignmentId, item) {
    const strItemValue = JSON.stringify(item);
    await dataSyn.easyDeleteItemInCluster(
        'Consignment', consignmentId, 'SerialisedItems', 'SN', [strItemValue]
    );
}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @returns sample:
 * [
    {
        StockCode: "ERMI0002",
        Description: "Ericsson Wireless Cat5e Outdoor Cable",
        QtyUnit: "Metre",
        GoodQty: 0,
        FaultyQty: 0,
    }
 * ]
 */
export async function loadConsignmentNonSnItems(consignmentId) {
    var ret = [];
    const theConsignmentNonSnItemsClusters = await dataSyn.easyDownloadReferenceEntityClustersAndTheirItems(
        'Consignment', consignmentId, 'NonSerialisedItems'
    );
    //assuming there is only one element in theConsignmentAvailableSnClusters
    if (Array.isArray(theConsignmentNonSnItemsClusters) && theConsignmentNonSnItemsClusters.length) {

        const oneConsignmentNonSnItemsCluster = theConsignmentNonSnItemsClusters[0];
        const consignmentNonSnItemsArray = await dataSyn.easyRetrieveItemInCluster(
            oneConsignmentNonSnItemsCluster.referenceEntity, oneConsignmentNonSnItemsCluster.referenceID, oneConsignmentNonSnItemsCluster.name,
            'NonSN'
        );
        if (consignmentNonSnItemsArray) {
            for (let index = 0; index < consignmentNonSnItemsArray.length; index++) {
                const oneDataItem = consignmentNonSnItemsArray[index];
                var oneSnStockObj = oneDataItem.value;
                try {
                    oneSnStockObj = JSON.parse(oneDataItem.value)
                } catch (error) { }
                ret.push(oneSnStockObj)
            }
        }
    }

    return ret;
}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} item sample:
 *     {
        StockCode: "ERMI0002",
        Description: "Ericsson Wireless Cat5e Outdoor Cable",
        QtyUnit: "Metre",
        GoodQty: 0,
        FaultyQty: 0,
    }

 */
export async function addNonSnItemForConsignment(consignmentId, item) {
    //TODO similar to addSnItemForConsignment
    const strItemValue = JSON.stringify(item);
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'NonSerialisedItems', 'NonSN', [strItemValue], 'ACCUMULATION'
    );
}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} itemList sample:
 * [
 *     {
        StockCode: "ERMI0002",
        Description: "Ericsson Wireless Cat5e Outdoor Cable",
        QtyUnit: "Metre",
        GoodQty: 0,
        FaultyQty: 0,
    }
    ]
* @param {*} disableTrafficControl Optional. This paramter is introduced to address the performance concern on overwriting the whole array/list.
 *                                  It's false by default. That means this function will discard the calling (without throw error) if this function is being called too frequently.
 *                                  Set it to true if the caller wants to force it processing the data (e.g. when user presses Next/Complete for the Step), 
 *
 */
export async function overwriteNonSnItemListForConsignment(consignmentId, wholeItemList, disableTrafficControl = false) {
    //TODO similar to overwriteSnItemListForConsignment
    var calledRecently = false;
    const lastTimestamp = localStorage.getItem('last-overwriteNonSnItemListForConsignment-time');
    const DefinitionOfRecent = 5 * 1000; //5 seconds
    if (lastTimestamp && ((new Date()).getTime() - lastTimestamp) < DefinitionOfRecent) {
        calledRecently = true;
    }
    if (disableTrafficControl || !calledRecently) {
        localStorage.setItem('last-overwriteNonSnItemListForConsignment-time', (new Date()).getTime());
        var newValueList = [];
        for (let index = 0; index < wholeItemList.length; index++) {
            const oneItem = wholeItemList[index];
            const strItemValue = JSON.stringify(oneItem);
            newValueList.push(strItemValue);
        }
        await dataSyn.easySaveItemInCluster('Consignment', consignmentId, 'NonSerialisedItems', 'NonSN', newValueList);
    }else{
        SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'overwriteNonSnItemListForConsignment', 'Traffic Control has blocked the calling due to {lastTimestamp}.', { lastTimestamp: lastTimestamp });
    }
}

/**
 * 
 * @param {*} consignmentId SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.
 * @param {*} item 
 */
export async function deleteNonSnItemForConsignment(consignmentId, item) {
    //TODO similar to deleteSnItemForConsignment(consignmentId, item) 
    const strItemValue = JSON.stringify(item);
    await dataSyn.easyDeleteItemInCluster(
        'Consignment', consignmentId, 'NonSerialisedItems', 'NonSN', [strItemValue]
    );
}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @returns sample:
 * [
 *  {
        Type: "Pallet", // Pallet or Carton
        Qty: 2,
        Length: 100,
        Width: 100,
        Height: 100,
        Weight: 30,
    }

    * ]
    */
export async function loadConsignmentPackageDetails(consignmentId) {
    //TODO similar to loadConsignmentAvailableSns
    var ret = [];
    const theConsignmentPackageDetailsClusters = await dataSyn.easyDownloadReferenceEntityClustersAndTheirItems(
        'Consignment', consignmentId, 'PackageDetails'
    );
    //assuming there is only one element in theConsignmentAvailableSnClusters
    if (Array.isArray(theConsignmentPackageDetailsClusters) && theConsignmentPackageDetailsClusters.length) {

        const oneConsignmentPackageDetailsClusters = theConsignmentPackageDetailsClusters[0];
        const consignmentPackageDetailsArray = await dataSyn.easyRetrieveItemInCluster(
            oneConsignmentPackageDetailsClusters.referenceEntity, oneConsignmentPackageDetailsClusters.referenceID, oneConsignmentPackageDetailsClusters.name,
            'PackageDetails'
        );
        if (consignmentPackageDetailsArray) {
            for (let index = 0; index < consignmentPackageDetailsArray.length; index++) {
                const oneDataItem = consignmentPackageDetailsArray[index];
                var onePackagekObj = oneDataItem.value;
                try {
                    onePackagekObj = JSON.parse(oneDataItem.value);
                    onePackagekObj['RowId']=index;
                } catch (error) { }
                ret.push(onePackagekObj)
            }
        }
    }

    //return dummy 
    // if(ret.length === 0) 
    //     return [
    //         {
    //              Type: "Pallet", // Pallet or Carton
    //              Qty: 2,
    //              Length: 100,
    //              Width: 100,
    //              Height: 100,
    //              Weight: 30,
    //          },
    //      ];


    return ret;
}

/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @returns sample:
 *  {
        Type: "Pallet", // Pallet or Carton
        Qty: 2,
        Length: 100,
        Width: 100,
        Height: 100,
        Weight: 30,
    }
* @param {*} disableTrafficControl Optional. This paramter is introduced to address the performance concern on overwriting the whole array/list.
 *                                  It's false by default. That means this function will discard the calling (without throw error) if this function is being called too frequently.
 *                                  Set it to true if the caller wants to force it processing the data (e.g. when user presses Next/Complete for the Step), 
 *
*/
export async function overwritePackageForConsignment(consignmentId, pack, disableTrafficControl = false) {
    //TODO similar to overwriteSnItemListForConsignment but the DefinitionOfRecent can be shorter as we don't expect there are too many Package
    var calledRecently = false;
    const lastTimestamp = localStorage.getItem('last-overwritePackageForConsignment-time');
    const DefinitionOfRecent = 1 * 1000; //1 second
    if (lastTimestamp && ((new Date()).getTime() - lastTimestamp) < DefinitionOfRecent) {
        calledRecently = true;
    }
    if (disableTrafficControl || !calledRecently) {
        localStorage.setItem('last-overwritePackageForConsignment-time', (new Date()).getTime());
        var newValueList = [];
        for (let index = 0; index < pack.length; index++) {
            let oneItem = {...pack[index]};
            
            if(Object.keys(oneItem).includes('RowId')){
                delete oneItem['RowId'];
            }
            
            Object.keys(oneItem).forEach(key => {
                if (oneItem[key] === undefined) {
                  delete oneItem[key];
                }
            });
            const strItemValue = JSON.stringify(oneItem);
            newValueList.push(strItemValue);
        }
        await dataSyn.easySaveItemInCluster('Consignment', consignmentId, 'PackageDetails', 'PackageDetails', newValueList);
    }else{
        SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'overwritePackageForConsignment', 'Traffic Control has blocked the calling due to {lastTimestamp}.', { lastTimestamp: lastTimestamp });
    }

}


/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @returns sample:
 * {
        IsATPAuthorised: true,
        IsForkliftRequired: true,
        PickupWindow: {
            Date: "", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
            ETA: "7am - 1pm",
        },
    }
 */
export async function loadConsignmentPickupDetails(consignmentId) {
    // similar to loadConsignmentAvailableSns but two differences:
    //1. the data is in Return Stock Task cluster, not in Pickup cluster (removed)
    //2. use easyNormalisePlainItemsInCluster (sample in loadTaskList), instead of easySaveItemInCluster (which is better for array)

    const pickupCLusterObj =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'Return Stock Task');
    /*     const isATPAuthorised =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'IsATPAuthorised');
        const isForkliftRequired =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'IsForkliftRequired');
        const pickupWindowDate =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'PickupWindowDate');
        const pickupWindowETA =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'PickupWindowETA'); */
        
        var ret={
            IsATPAuthorised: JSON.parse(pickupCLusterObj.IsATPAuthorised || false),
            IsForkliftRequired: JSON.parse(pickupCLusterObj.IsForkliftRequired || false),
            PickupWindow: {
                Date: pickupCLusterObj.PickupWindowDate, 
                ETA: pickupCLusterObj.PickupWindowETA,
            },
        }

    //return dummy data
    // if(typeof(ret.IsATPAuthorised) === 'undefined' || ret.IsATPAuthorised === null  || !ret.PickupWindow.ETA) 
    //  return {
    //     IsATPAuthorised: true,
    //     IsForkliftRequired: true,
    //     PickupWindow: {
    //         Date: "2021-07-22T12:10:10.124", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
    //         ETA: "7am - 1pm",
    //     }
    // }

    return ret;
}


/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} pickupDetails sample:
 *  {
        IsATPAuthorised: true,
        IsForkliftRequired: true,
        PickupWindow: {
            Date: "", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
            ETA: "7am - 1pm",
        },
    }
*/
export async function updatePickupDetailsForConsignment(consignmentId, pickupDetails) {
    //TODO calling easySaveItemInCluster() 4 times to update the four dataItems. 
    //usage of easySaveItemInCluster can be seen in addSnItemsForConsignment, but pass non-array itemValue one-by-one with AUTO mode 
    //BTW PickupDetails dataItems are in first leve cluster (Return Stock Task)
    console.log('updatePickupDetailsForConsignment',consignmentId, pickupDetails)
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'Return Stock Task', 'IsATPAuthorised', pickupDetails.IsATPAuthorised
    );
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'Return Stock Task', 'IsForkliftRequired', pickupDetails.IsForkliftRequired
    );
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'Return Stock Task', 'PickupWindowDate', pickupDetails.PickupWindow.Date
    );
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'Return Stock Task', 'PickupWindowETA', pickupDetails.PickupWindow.ETA
    );


}

/**
 * 
 * @param {*} subTaskId 
 * @param {*} newStatus e.g. 'Awaiting schedule'
 * @param {string} modifiedBy  it's user name
 */
export async function modifySubTaskStatus(subTaskId, newStatus, modifiedBy) {
    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'modifySubTaskStatus', 'CompanySubTask {subTaskId} Status is being changed to {newStatus} by {modifiedBy}.', { subTaskId:subTaskId, newStatus:newStatus, modifiedBy:modifiedBy });
    //similar to updatePickupDetailsForConsignment
    const consignmentClusterArray= await dataSyn.easyDownloadChildrenClustersAndTheirItems('CompanySubTask',subTaskId,'Company Sub Task');
    const oneComsignmentCluster=consignmentClusterArray[0];
    const consignmentId =oneComsignmentCluster.referenceID;
    await dataSyn.easySaveItemInCluster('Consignment',consignmentId,'Return Stock Task','TaskStatus',newStatus);
    var newActionBy=getTaskNextActionBy(newStatus);
    await dataSyn.easySaveItemInCluster('Consignment',consignmentId,'Return Stock Task','NextActionBy',newActionBy);
    await dataSyn.easySaveItemInCluster('Consignment',consignmentId,'Return Stock Task','ModifiedBy',modifiedBy);
    const modifyDate= currentTimeToUTCTimeInString(StringFormat.ServerDateFormat);
    await dataSyn.easySaveItemInCluster('Consignment',consignmentId,'Return Stock Task','ModifiedDate',modifyDate); 
}

/**
 * 
 * @param {string} newStatus: it's new sub task status
 */
export function getTaskNextActionBy(newStatus){
    //when time permits, the below lists could be retrieved from DB through API (suggest expanding the existing https://skbmetis-02.azurewebsites.net/stock/lookup?type=consignmentMethod  )
    var nextActionBySkybridge=["Awaiting approval","Awaiting schedule","Awaiting delivery","On Order"];
    var nextActionByContractor=["Not Started","Awaiting picking","Picking","Awaiting labels","Awaiting pickup","Awaiting drop-off","Ready for Pickup","Awaiting Acknowledgement"];
    var nextActionByCourier=["Ready for Dispatch","In Transit"];

  /*   if(deliveryMethod==="Contractor drop-off" && newStatus==="Awaiting delivery"){
        return "Contractor"
    }
    if(deliveryMethod==="Skybridge courier" && newStatus==="Awaiting delivery"){
        return "Skybridge"
    } */

    if(nextActionByContractor.indexOf(newStatus)>=0){
        return "Contractor"
    }else if(nextActionBySkybridge.indexOf(newStatus)>=0){
        return "Skybridge"
    }else if(nextActionByCourier.indexOf(newStatus)>=0){
        return "Courier"
    }else{
        return null;
    }
}

/**
 * 
 * @param {number} subTaskId: it's companysubtask.id
 * @param {number} lockedBy :  user id who locked the task or 0 for releasing the lock,
 */
export async function updateTaskLockedBy(subTaskId, lockedBy) {
    SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'updateTaskLockedBy', 'CompanySubTask {subTaskId} is being on-site locked by {lockedBy}.', { subTaskId:subTaskId, lockedBy:lockedBy });
    
    //userId is saved into item key = 'LockedBy'
    const consignmentClusterArray= await dataSyn.easyDownloadChildrenClustersAndTheirItems('CompanySubTask',subTaskId,'Company Sub Task');
    const oneComsignmentCluster=consignmentClusterArray[0];
    const consignmentId =oneComsignmentCluster.referenceID;
    await dataSyn.easySaveItemInCluster('Consignment',consignmentId,'Return Stock Task','LockedBy',lockedBy);
}

export async function loadTaskStatusAndLockedBy(subTaskId){
    const consignmentClusterArray= await dataSyn.easyDownloadChildrenClustersAndTheirItems('CompanySubTask',subTaskId,'Company Sub Task');
    const oneComsignmentCluster=consignmentClusterArray[0];
    const consignmentId =oneComsignmentCluster.referenceID;
    const pickupCLusterObj =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'Return Stock Task');
/*     const status=await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'TaskStatus');
    const lockedBy=await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'LockedBy'); */
    return {
        status: pickupCLusterObj.TaskStatus,
        lockedBy: pickupCLusterObj.LockedBy? pickupCLusterObj.LockedBy:null
    }
}



/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @returns sample:
 * {
        PickupWindow: {
            Date: "", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
            ETA: "7am - 1pm",
        },
    }
 */
    export async function loadConsignmentDropOffDetails(consignmentId) {
        // similar to loadConsignmentAvailableSns but two differences:
        //1. the data is in Return Stock Task cluster, not in Pickup cluster (removed)
        //2. use easyNormalisePlainItemsInCluster (sample in loadTaskList), instead of easySaveItemInCluster (which is better for array)
    
        const dropoffClusterObj =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'Return Stock Task');
        /*     const isATPAuthorised =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'IsATPAuthorised');
            const isForkliftRequired =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'IsForkliftRequired');
            const pickupWindowDate =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'PickupWindowDate');
            const pickupWindowETA =await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'PickupWindowETA'); */
            
            var ret={
               
                DropOffWindow: {
                    Date: dropoffClusterObj.DropOffWindowDate, 
                    ETA: dropoffClusterObj.DropOffWindowETA,
                },
            }
    
        // //return dummy data
        // if(ret.DropOffWindowDate === null  || !ret.DropOffWindowDate.ETA) 
        //  return {
        //     DropOffWindow: {
        //         Date: "2021-07-22T12:10:10.124", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
        //         ETA: "7am - 1pm",
        //     }
        // }
    
        return ret;
    }


    /**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} dropoffDetails sample:
 *  {

        DropOffWindow: {
            Date: "", //format: "YYYY-MM-DDTHH:mm:ss.SSS", generating using misc.currentTimeToUTCTimeInString
            ETA: "7am - 1pm",
        },
    }
*/
export async function updateDropOffDetailsForConsignment(consignmentId, dropOffDetails) {
console.log('updateDropOffDetailsForConsignment',dropOffDetails,consignmentId)
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'Return Stock Task', 'DropOffWindowDate', dropOffDetails.Date
    );
    await dataSyn.easySaveItemInCluster(
        'Consignment', consignmentId, 'Return Stock Task', 'DropOffWindowETA', dropOffDetails.ETA
    );


}


/**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @returns sample:
 * [
 *  {
                           Name: "", // reserved, it may need in the furture
                           FileURL: "",
                           ConsignmentPackageId: null//reserved, it may need in the furture
                       }

    * ]
    */
    export async function loadConsignmentLabelDetails(consignmentId) {
        //TODO similar to loadConsignmentAvailableSns
        var ret = [];
        const theConsignmentPackageDetailsClusters = await dataSyn.easyDownloadReferenceEntityClustersAndTheirItems(
            'Consignment', consignmentId, 'LabelDetails'
        );
        //assuming there is only one element in theConsignmentAvailableSnClusters
        if (Array.isArray(theConsignmentPackageDetailsClusters) && theConsignmentPackageDetailsClusters.length) {
    
            const oneConsignmentPackageDetailsClusters = theConsignmentPackageDetailsClusters[0];
            const consignmentPackageDetailsArray = await dataSyn.easyRetrieveItemInCluster(
                oneConsignmentPackageDetailsClusters.referenceEntity, oneConsignmentPackageDetailsClusters.referenceID, oneConsignmentPackageDetailsClusters.name,
                'LabelDetails'
            );
            if (consignmentPackageDetailsArray) {
                for (let index = 0; index < consignmentPackageDetailsArray.length; index++) {
                    const oneDataItem = consignmentPackageDetailsArray[index];
                    var labelObj;
                    var oneSnStockObj = JSON.parse(oneDataItem.value);
                    try {
                        labelObj = {
                            Name:oneSnStockObj.LabelName,
                            FileURL:constructBlobUrl(oneSnStockObj.LabelFilePath),
                            ConsignmentPackageId:oneSnStockObj.ConsignmentPackageId
                        }
                    } catch (error) { }
                    ret.push(labelObj)
                }
            }
        }
        return ret;
    }

    /**
 * 
 * @param {*} consignmentId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {array} labels:
 * [
 *  {
                           Name: "", // reserved, it may need in the furture
                           FileURL: "",
                           ConsignmentPackageId: null//reserved, it may need in the furture
                       }

    * ]
* @param {*} disableTrafficControl Optional. This paramter is introduced to address the performance concern on overwriting the whole array/list.
 *                                  It's false by default. That means this function will discard the calling (without throw error) if this function is being called too frequently.
 *                                  Set it to true if the caller wants to force it processing the data (e.g. when user presses Next/Complete for the Step), 
 *
*/
export async function overwriteLabelsForConsignment(consignmentId, labels, disableTrafficControl = false) {
    //TODO similar to overwriteSnItemListForConsignment but the DefinitionOfRecent can be shorter as we don't expect there are too many Package
    var calledRecently = false;
    const lastTimestamp = localStorage.getItem('last-overwritelabelsForConsignment-time');
    const DefinitionOfRecent = 1 * 1000; //1 second
    if (lastTimestamp && ((new Date()).getTime() - lastTimestamp) < DefinitionOfRecent) {
        calledRecently = true;
    }
    if (disableTrafficControl || !calledRecently) {
        localStorage.setItem('last-overwritelabelsForConsignment-time', (new Date()).getTime());
        var newValueList = [];
        for (let index = 0; index < labels.length; index++) {
            const oneItem = labels[index];
            const strItemValue = JSON.stringify(oneItem);
            newValueList.push(strItemValue);
        }
        await dataSyn.easySaveItemInCluster('Consignment', consignmentId, 'LabelDetails', 'LabelDetails', newValueList);
    }else{
        SkbLogger.applicationTrace('Task Service', SeverityLevel.Verbose, 'overwriteLabelsForConsignment', 'Traffic Control has blocked the calling due to {lastTimestamp}.', { lastTimestamp: lastTimestamp });
    }

}


    /**
 * 
 * @param {*} subTaskId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} stepsStatus sample:
 *  { // it's key-value (StepName-CompleteStatus) object, if given a step name doesn't exist, treat status as "InComplete"
                        "Package details": "Complete" // possible value: Complete|Incomplete
                    }
*/
export async function updateTaskStepsStatus(subTaskId, stepsStatus) {

    await dataSyn.easySaveItemInCluster(
        'Consignment', subTaskId, 'Return Stock Task', 'StepsStatus', JSON.stringify(stepsStatus)
    );


}

export async function loadTaskStepsStatus(subTaskId){
    // const consignmentClusterArray= await dataSyn.easyDownloadChildrenClustersAndTheirItems('CompanySubTask',subTaskId,'Company Sub Task');
    // const oneComsignmentCluster=consignmentClusterArray[0];
    // const consignmentId =oneComsignmentCluster.referenceID;
    const stepStatusInJson =await dataSyn.easyRetrieveItemInCluster('Consignment',subTaskId, 'Return Stock Task', "StepsStatus");
/*     const status=await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'TaskStatus');
    const lockedBy=await dataSyn.easyNormalisePlainItemsInCluster('Consignment',consignmentId, 'LockedBy'); */
    return JSON.parse(stepStatusInJson.value || "{}");
}

    /**
 * 
 * @param {*} subTaskId  SubTasks.ReferenceID in Redux Store (same level as SubTaskId) or Consignment.Id in DB table.   
 * @param {*} stepsStatus sample:
 *  { // it's key-value (StepName-CompleteStatus) object, if given a step name doesn't exist, treat status as "InComplete"
                        "Package details": "Complete" // possible value: Complete|Incomplete
                    }
*/
export async function updateTaskCancellationReason(subTaskId, reason) {

    await dataSyn.easySaveItemInCluster(
        'Consignment', subTaskId, 'Return Stock Task', 'CancellationReason', JSON.stringify(reason)
    );


}
