import React from 'react';
import Icon from '@material-ui/core/Icon';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import {Mail,AccountBox, AccountCircle, SupervisorAccount} from '@material-ui/icons'
const Icons = {
    "Mail": Mail,
    "AccountBox": AccountBox,
    "AccountCircle": AccountCircle,
    "SupervisorAccount":SupervisorAccount

}
export function SkbBadge({badgeContent, icon, size, max, className, children}){
    const Icon = Icons[icon];
    return (
       
        <Badge badgeContent={badgeContent} color="secondary" className={className} fontSize={size} max={max} >
           <Icon color="primary"  fontSize={size} className={className}></Icon>
           {/* {children} */}
        </Badge>
    );
}