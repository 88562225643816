import React from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Paper,
    Typography,

} from '@material-ui/core';
import {makeStyles}  from '@material-ui/styles';
import {
    selectThreePLImportationSuccess,    
} from '../../../../Redux/ThreePLImportation/selectors';

const useStyles = makeStyles(theme=>({
    root : {
        
    }
}))

const Rendererror = (_r, index) => {
    if(Object.keys(_r.item.processRequestMessage).length === 0) {
        return <Typography variant="body1" key={index}><b>{_r.item?.["Serial Number"]}</b> - Processing failed</Typography>
    } else {
        return <Typography variant="body1" key={index}><b>{_r.item?.StockCode}{_r.item?.["Serial Number"]}</b> - {_r.item?.processRequestMessage}</Typography>
    } 
}

const ImportationResult = ({

    // selectors
    importationResponse,


}) => {

    const classes = useStyles();

    if(!importationResponse) return "";
    const { SerialisedSuccessData , SerialisedFailData } = importationResponse;
    return(
        <Box className={classes.root}>
            <Paper style={{padding:"1em"}}>
                {
                    SerialisedSuccessData
                    &&
                    SerialisedSuccessData.length > 0
                    &&
                    <>
                        <Typography variant="h6" style={{color:"green"}}>Success</Typography>
                        {
                            SerialisedSuccessData.map((_r,index)=>(
                                <Typography variant="body1" key={index}><b>{_r?.StockCode}{_r?.SerialNumber}</b> - {_r?.processRequestMessage}</Typography>
                            ))
                        }
                    </>
                }
            </Paper>
            <Paper style={{padding:"1em"}}>
                {
                    SerialisedFailData
                    &&
                    SerialisedFailData.length > 0
                    &&
                    <>
                        <Typography variant="h6" style={{color:"red"}}>Fail</Typography>
                        {
                            SerialisedFailData.map((_r,index)=>(
                                <Rendererror item={_r} index={index} />
                                
                            ))
                        }
                    </>
                }
            </Paper>
        </Box>
    )

}

const mapState = state =>({
    importationResponse : selectThreePLImportationSuccess(state),
});
const mapDispatch = dispatch =>({

})

export default connect(mapState,mapDispatch)(ImportationResult);