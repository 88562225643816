import produce from 'immer'; // immer is used to manage immutaition tasks store
import { SkbLogger, ApiService } from '../../services';


import { Actions, getCurrentTask, getCurrentSubTask, getStepFromCurrentSubTaskByStepId } from '../actions/tasks';

const mockTaskData = [{
    TaskId: 1234,
    resourceID: "42E63AD35B2C4047A166C057058E6664",
    TaskType: "STOCKTAKE",
    CompanyName: "Test Company",
    StartTime: "21/08/2020 08:00 AM",
    EndTime: "23/08/2020 05:00 PM",
    StocktakeSatus: {
        resourceID: "42E63AD35B2C4047A166C057058E6564",
        key: "StocktakeStatus",
        type: "METADATA",
        description: "Stocktake Overall Status",
        Status: "Not Started",
    },
    CompletedDate: {
        resourceID: "42E63AD35B2C4047A166C057058E6562",
        key: "CompletedDate",
        type: "METADATA",
        description: "Task completed date",
        Status: "",
    },

    Programs: [
        {
            ProgramName: "Remote",
            PhoneNumber: "11111112",
        },
        {
            ProgramName: "Energy",
            PhoneNumber: "11111111",
        }
    ],
    SubTasks: [
        {
            sequenceId: 1, // which is location sequence id, sample, Location_1_Preview_1, it shall extract the first 1. 
            resourceID: "42E63AD35B2C4047A166C057058E6665",
            Address: "101-105 Keilor Park Dr",
            //canCurrentUserOpenTheTask: false,
            TaskStatus: {
                resourceID: "1a14c15e806fb31d83b36355d144",
                key: "Status",
                type: "METADATA",
                description: "Status",
                Status: "Not Started"
            },
            LockedBy: {
                resourceID: "1a14c15e806fb31d83b36355d144",
                key: "LockedBy",
                type: "METADATA",
                description: "Locked by",
                LockedBy: 0,

            },
            CurrentStep: {
                resourceID: "1a14c15e806fb31d83b36355d123",
                key: "CurrentStep",
                type: "METADATA",
                description: "current step",
                StepId: 1,

            },
            PreviewInfo: [
                {
                    StockCode: "EROD0001",
                    Description: "Ericsson Wireless Outdoor Unit",
                    StockType: "Serialised",
                    Qty: 20,
                    QtyUnit: "Each"
                },
                {
                    StockCode: "ERMI0001",
                    Description: "Ericsson Wireless Wall Plate Kit",
                    StockType: "Non-Serialised",
                    Qty: 30,
                    QtyUnit: "Each"
                },
                {
                    StockCode: "ERMI0002",
                    Description: "Ericsson Wireless Cat5e Outdoor Cable",
                    StockType: "Non-Serialised",
                    Qty: 14,
                    QtyUnit: "Metre"
                },
                {
                    StockCode: "ERDH0001",
                    Description: "Ericsson LTSS 80cm Dish - 10017252",
                    StockType: "Non-Serialised",
                    Qty: 0,
                    QtyUnit: "Each"
                },
                {
                    StockCode: "ERMT0006",
                    Description: "Ericsson LTSS 900mm Gutter Mount - 10022841",
                    StockType: "Non-Serialised",
                    Qty: 0,
                    QtyUnit: "Each"
                }
            ],
            ExistingAvailableSNs: [
                {
                    SerialNumber: "133434324",
                    StockCode: "EROD0001",
                    Description: "Ericsson Wireless Outdoor Unit",
                },
                {
                    SerialNumber: "133434325",
                    StockCode: "EROD0001",
                    Description: "Ericsson Wireless Outdoor Unit",
                },
            ],
            NonSerialisedItems: [
                {
                    StockCode: "ERMI0001",
                    Description: "Ericsson Wireless Wall Plate Kit",
                    QtyUnit: "Each",
                    GoodQty: 0,
                    FaultyQty: 0,
                    resourceID: "1a14c15e806fb31d83b36355d888",
                    key: "NonSN",
                    type: "METADATA",
                    description: "Non-serialised Item"
                },
                {
                    StockCode: "ERMI0002",
                    Description: "Ericsson Wireless Cat5e Outdoor Cable",
                    QtyUnit: "Metre",
                    GoodQty: 0,
                    FaultyQty: 0,
                    resourceID: "1a14c15e806fb31d83b36355d144", //for update back
                    key: "NonSN",
                    type: "METADATA",
                    description: "Non-serialised Item"     // for update back
                }
            ],
            SerialisedItems: [
                {
                    StockCode: "EROD0001",
                    SerialNumber: "133434399",
                    Description: "Ericsson Wireless Outdoor Unit",
                    ItemStatus: 'Faulty',
                    Photo: 'photo path',
                    Latitude: "-37.23",
                    Longitude: "125.134",
                    resourceID: "1a14c15e806fb31d83b36355d999",
                    type: "PHOTO",
                    description: "serialised Item",
                    key: "SN",
                    sequenceId: 1
                },
                {
                    StockCode: "EROD0001",
                    SerialNumber: "133434324",
                    Description: "Ericsson Wireless Outdoor Unit",
                    ItemStatus: 'Good',
                    Photo: 'photo path2',
                    Latitude: "-37.23",
                    Longitude: "125.134",
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    description: "serialised Item",
                    key: "SN",
                    type: "PHOTO",
                    sequenceId: 2
                }
            ],
            Steps: [
                {
                    StepId: 1,
                    StepDescription: "Preview",
                    Status: "Not Started",
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    description: "Preview",
                    key: "Step",
                    type: "METADATA",
                },
                {
                    StepId: 2,
                    StepDescription: "Serialised",
                    Status: "Not Started",
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    description: "Serialised",
                    key: "Step",
                    type: "METADATA",
                },
                {
                    StepId: 3,
                    StepDescription: "Non-Serialised",
                    Status: "Not Started",
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    description: "Non-Serialised",
                    key: "Step",
                    type: "METADATA",
                },
                {
                    StepId: 4,
                    StepDescription: "Review",
                    Status: "Not Started",
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    description: "Review",
                    key: "Step",
                    type: "METADATA",
                },
                {
                    StepId: 5,
                    StepDescription: "Signoff",
                    Status: "Not Started",
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    description: "Signoff",
                    key: "Step",
                    type: "METADATA",
                },
            ]
        }
    ]
}];
/**
 * @function tasks task module reducer 
 * @summary sample task store state:
 * {
        CurrentTaskResourceId: null,
        CurrentSubTaskResourceId: null,
        isLoading: false,
        Tasks: [{
        TaskId: 1234,
        resourceID: "42E63AD35B2C4047A166C057058E6664",
        TaskType: "STOCKTAKE",
        CompanyName: "Test Company",
        StartTime: "21/08/2020 08:00 AM",
        EndTime: "23/08/2020 05:00 PM",
        SubTasks: [
            {
                sequenceId: 1, // which is location sequence id, sample, Location_1_Preview_1, it shall extract the first 1. 
                resourceID: "42E63AD35B2C4047A166C057058E6665",
                Address: "101-105 Keilor Park Dr",
                TaskStatus: {
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    key: "Status",
                    type: "METADATA",
                    description: "Status",
                    Status: "Not Started"
                },
                LockedBy: {
                    resourceID: "1a14c15e806fb31d83b36355d144",
                    key: "LockedBy",
                    type: "METADATA",
                    description: "Locked by",
                    LockedBy: 0,

                },
                PreviewInfo: [
                    {
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                        StockType: "Serialised",
                    },
                    {
                        StockCode: "SKB001",
                        Description: "Cable Type",
                        StockType: "Non-Serialised",
                    },
                ],
                ExistingAvailableSNs: [
                    {
                        SerialNumber: "133434324",
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                    },
                    {
                        SerialNumber: "133434325",
                        StockCode: "EROD001",
                        Description: "Outdoor Unit (ODU)",
                    },
                ],
                NonSerialisedItems: [
                    {
                        StockCode: "SKB001",
                        Description: "cable",
                        QtyUnit: "Meter",
                        GoodQty: 0,
                        FaultyQty: 0,
                        resourceID: "1a14c15e806fb31d83b36355d888",
                        key: "NonSN",
                        type: "METADATA",
                        description: "Non-serialised Item"
                    },
                    {
                        StockCode: "SKB002",
                        Description: "cable2",
                        QtyUnit: "Meter",
                        GoodQty: 0,
                        FaultyQty: 0,
                        resourceID: "1a14c15e806fb31d83b36355d144", //for update back
                        key: "NonSN",
                        type: "METADATA",
                        description: "Non-serialised Item"     // for update back
                    }
                ],
                SerialisedItems: [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134",
                        resourceID: "1a14c15e806fb31d83b36355d999",
                        type: "PHOTO",
                        description: "serialised Item",
                        key: "SN",
                        sequenceId: 1
                    },
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434324",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Good',
                        Photo: 'photo path2',
                        Latitude: "-37.23",
                        Longitude: "125.134",
                        resourceID: "1a14c15e806fb31d83b36355d144",
                        description: "serialised Item",
                        key: "SN",
                        type: "PHOTO",
                        sequenceId: 2
                    }
                ],
                Steps: [
                    {
                        StepId: 1,
                        StepDescription: "Preview",
                        Status: "Not Started",
                        resourceID: "1a14c15e806fb31d83b36355d144",
                        description: "Preview",
                        key: "Step",
                        type: "METADATA",
                    },
                    {
                        StepId: 2,
                        StepDescription: "Serialised",
                        Status: "Not Started",
                        resourceID: "1a14c15e806fb31d83b36355d144",
                        description: "Serialised",
                        key: "Step",
                        type: "METADATA",
                    },
                    {
                        StepId: 3,
                        StepDescription: "Non-Serialised",
                        Status: "Not Started",
                        resourceID: "1a14c15e806fb31d83b36355d144",
                        description: "Non-Serialised",
                        key: "Step",
                        type: "METADATA",
                    },
                    {
                        StepId: 4,
                        StepDescription: "Review",
                        Status: "Not Started",
                        resourceID: "1a14c15e806fb31d83b36355d144",
                        description: "Review",
                        key: "Step",
                        type: "METADATA",
                    },
                    {
                        StepId: 5,
                        StepDescription: "Signoff",
                        Status: "Not Started",
                        resourceID: "1a14c15e806fb31d83b36355d144",
                        description: "Signoff",
                        key: "Step",
                        type: "METADATA",
                    },
                ]
            }
        ]
    }]
    };
 */
export const tasks = produce((draft, action) => {
    var currentSubTask;
    var currentTask;
    switch (action.type) {
        case Actions.TASK_REFRESHED:

            return {
                ...draft,
                Tasks: action.taskData,
            };
            break;
        case Actions.TASKSTATUS_UPDATE:
            SkbLogger.logDebugInfo("task reducer, TASKSTATUS_UPDATE:", action.taskStatus)
            currentSubTask = getCurrentSubTask(draft);
            currentSubTask.TaskStatus.Status = action.taskStatus;
            //draft.CurrentSubTaskResourceId = currentTask;

            break;
        case Actions.ADD_SN_ITEM:
            currentSubTask = getCurrentSubTask(draft);
            if (!currentSubTask.SerialisedItems || !Array.isArray(currentSubTask.SerialisedItems)) currentSubTask.SerialisedItems = [action.sn];
            else currentSubTask.SerialisedItems.push(action.sn);
            break;
        case Actions.UPDATE_SN_ITEM:
            currentSubTask = getCurrentSubTask(draft);
            if (currentSubTask.SerialisedItems && Array.isArray(currentSubTask.SerialisedItems)) {
                var idx = currentSubTask.SerialisedItems.findIndex(i => i.resourceID === action.sn.resourceID);
                if (idx >= 0) currentSubTask.SerialisedItems[idx] = { ...action.sn };
            }
            break;
        case Actions.DEL_SN_ITEM:
            currentSubTask = getCurrentSubTask(draft);

            if (currentSubTask.SerialisedItems && Array.isArray(currentSubTask.SerialisedItems)) {
                var idx = currentSubTask.SerialisedItems.findIndex(i => i.resourceID === action.sn.resourceID);
                if (idx >= 0) currentSubTask.SerialisedItems.splice(idx, 1);
            }
            break;
        case Actions.ADD_NONSN_ITEM:
            currentSubTask = getCurrentSubTask(draft);

            if (!currentSubTask.NonSerialisedItems || !Array.isArray(currentSubTask.NonSerialisedItems)) currentSubTask.NonSerialisedItems = [action.nonsnItem];
            else currentSubTask.NonSerialisedItems.push(action.nonsnItem);
            break;
        case Actions.UPDATE_NONSN_ITEM:
            currentSubTask = getCurrentSubTask(draft);

            if (currentSubTask.NonSerialisedItems && Array.isArray(currentSubTask.NonSerialisedItems)) {
                var idx = currentSubTask.NonSerialisedItems.findIndex(i => i.resourceID === action.nonsnItem.resourceID);
                if (idx >= 0) currentSubTask.NonSerialisedItems[idx] = { ...action.nonsnItem };
            }
            break;
        case Actions.DEL_NONSN_ITEM:
            currentSubTask = getCurrentSubTask(draft);

            if (currentSubTask.NonSerialisedItems && Array.isArray(currentSubTask.NonSerialisedItems)) {
                var idx = currentSubTask.NonSerialisedItems.findIndex(i => i.resourceID === action.nonsnItem.resourceID);
                if (idx >= 0) currentSubTask.NonSerialisedItems.splice(idx, 1);
            }
            break;
        case Actions.UPDATE_SIGNOFF_SIG:
            currentSubTask = getCurrentSubTask(draft);

            currentSubTask.SignoffSig = action.sig;
            break;
        case Actions.REQUEST_SENT:
            draft.isLoading = true;
            break;
        case Actions.REQUEST_RESPONDED:
            draft.isLoading = false;
            break;
        case Actions.STEP_UPDATE:
            currentSubTask = getCurrentSubTask(draft);


            var idx = currentSubTask.Steps.findIndex(i => i.StepId === action.step.stepId);

            if (idx >= 0) currentSubTask.Steps[idx] = { ...currentSubTask.Steps[idx], Status: action.step.status };
            break;
        case Actions.TASK_CHANGE:

            // draft.CurrentTaskResourceId = draft.Tasks.find(t => t.resourceID === action.taskResoureId);
            draft.CurrentTaskResourceId = action.taskResoureId;

            break;
        case Actions.SUBTASK_CHANGE:
            //if (!draft.CurrentTaskResourceId) draft.CurrentTaskResourceId = getCurrentTask(draft);
            //draft.CurrentSubTask = draft.CurrentTaskResourceId.SubTasks.find(t => t.resourceID === action.subTaskResourceId);
            SkbLogger.logDebugInfo("SUBTASK_CHANGE", draft.CurrentSubTaskResourceId, action.subTaskResourceId);
            draft.CurrentSubTaskResourceId = action.subTaskResourceId;
            break;
        case Actions.LOCKEDBY_UPDATE:
            //if (!draft.CurrentSubTask) draft.CurrentSubTask = getCurrentSubTask(draft);
            currentSubTask = getCurrentSubTask(draft);
            currentSubTask.LockedBy = action.lockedBy;
            break;
        case Actions.STEP_CHANGE:
            currentSubTask = getCurrentSubTask(draft);

            currentSubTask.CurrentStep = action.step;
            break;
        case Actions.UPDATE_ERROR:

            draft.error = action.err;

            break;
        case Actions.SHOW_TASKDETAILS:
            draft.showDetails = action.showDetails;
            break;
        case Actions.UDPATE_LASTSELECTEDSTOCKCODE:
            currentSubTask = getCurrentSubTask(draft);
            currentSubTask.LastSelectedStockCode = action.lastSelectedStockCode;
            break;
        case Actions.CURRENTTASK_STATUS_REFRESHEDFROMSYNC:
            currentSubTask = getCurrentSubTask(draft);
            currentSubTask.TaskStatus.Status = action.data.status;
            currentSubTask.LockedBy.LockedBy = action.data.lockedBy;
            break;
        case Actions.STOCKTAKESTATUS_UPDATE:
            currentTask = getCurrentTask(draft);
            currentTask.StocktakeSatus.Status = action.stocktakeStatus;
            break;
        case Actions.TASK_STATUSCOMPLETIONDATERESOURCEID_UPDATE:
            currentTask = getCurrentTask(draft);
            currentTask.StocktakeSatus.resourceID = action.data.taskStatusResourceId;
            currentTask.CompletedDate.resourceID = action.data.completionDateResourceId;
            break;
        case Actions.SUBTASK_STEPS_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.Steps = action.data;
            break;
        case Actions.SUBTASK_PREVIEW_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.PreviewInfo = action.data;
            break;
        case Actions.SUBTASK_SNITEMS_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.SerialisedItems = action.data;
            break;
        case Actions.SUBTASK_NOSNITEMS_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.NonSerialisedItems = action.data;
            break;
        case Actions.SUBTASK_SIG_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.SignoffSig = action.data;
            break;
        case Actions.SUBTASK_AVAILABLESN_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.ExistingAvailableSNs = action.data;
            break;
        case Actions.SUBTASK_CURRENTSTEP_LOADED:
            currentTask = getCurrentSubTask(draft);
            currentTask.CurrentStep = action.data;
            break;
        default:

            break;
    }
}, { //initial store data
    CurrentTaskResourceId: null,
    CurrentSubTaskResourceId: null,
    isLoading: false,
    error: "",
    Tasks: [],//mockTaskData,// mockup tasks
    showDetails: false,
});