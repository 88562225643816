import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';

import * as validator from '../../../utils/returnstock/validator';
import * as contractorSelector from '../../../utils/returnstock/contractorSelector';
import * as locationSelector from '../../../utils/returnstock/locationSelector';
import * as destinationSelector from '../../../utils/returnstock/destinationSelector';
import * as methodSelector from '../../../utils/returnstock/methodSelector';
import SkbConfirmDialog from '../../../skb_controls/components/SkbConfirmDialog';
import * as stockReview from '../../../utils/returnstock/stockReview';
import * as stockConsignmentActions from '../../../Redux/actions/stockConsignment';
import _ from 'lodash';
import { ReturnStockWizardInternal } from './ReturnStockWizardInternal';
import SkbLogger from '../../../services/SkbLogger';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {otherInProgressReturnCreation} from '../../../utils/returnstock/validator';
const mapStateToProps = state => ({
    user: state.auth,
    contractorStock: state.contractorStock,
    stockConsignment: state.stockConsignment,
    IsLoading: state.contractorStock.isLoading || state.stockConsignment.IsLoading || false,
    //SelectedDestination: selectedDestination(state.stockConsignment), 
    contractorListWithStockSummary: contractorSelector.getContractorListWithFaultyStockSummary(state.contractorStock), //return the list used in contractor selector
    selectedContractorCompanyIds: contractorSelector.getSelectedCompanyIds(state.stockConsignment),
    locationList: locationSelector.getFaultyStockLocationList(state.contractorStock, state.stockConsignment),
    selectedLocations: locationSelector.getSelectedLocations(state.stockConsignment),
    reviewData: stockReview.getReviewData(state.contractorStock, state.stockConsignment,"Return Faulty Stock"),
    selectedDestination: destinationSelector.getSelectedStockReturnDestination(state.stockConsignment),
    selectedMethod: methodSelector.getSelectedStockReturnMethod(state.stockConsignment),
    selectedPickupDate: methodSelector.getPlannedPickedUpByDate(state.stockConsignment),
    selectedReturnDate: methodSelector.getPlannedReturnDate(state.stockConsignment),
    Error: state.contractorStock.Error || state.stockConsignment.Error
    // register: state.register,
    // variables: state.variables
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        cancelAllReturnConsignment:()=>{stockReview.cancelConsignment(dispatch)},
        //loadContractors: () => {contractorSelector.loadContractors(dispatch);},
        handleSelectContractor: (rows, selectedRow) => {contractorSelector.handleSelectContractor(dispatch, selectedRow);},
        // updateCountry: (c, auth) => dispatch(variablesActions.updateCountry(c, auth)),
        // addCountry: (c, auth) => dispatch(variablesActions.addCountry(c, auth)),
        // delCountry: (c, auth) => dispatch(variablesActions.delCountry(c, auth)),
        // updateCountryPhone: (p, auth) => dispatch(variablesActions.updateCountryPhone(p, auth)),
        //selectDestination: (destCode)=>{selectDestination(dispatch, destcode)}
    }
}

const useStyles = makeStyles((theme) => (
    {
        avatarIcon: {

            display: 'inline-block',
            verticalAlign: 'middle',
        },

    }
));

export function ReturnFaultyStockWizardInternal(props) {
    const { user, contractorStock, stockConsignment, dispatch } = props;
    const history = useHistory();
    const [openInfo, setOpenInfo] = React.useState(false);
    const returnRoute = props.returnRoute || '/home';
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmMsg, setConfirmMsg] = React.useState("");
    const [confirmTitle, setConfirmTitle] = React.useState("");
    const [incompleteStepNames, setIncompleteStepNames] = React.useState([]);

    function showConfirmDialog(title, message)
    {
        setConfirmTitle(title);
        setConfirmMsg(message);
   
     // setOkHandler(okHandler); //Ideally would pass in the callback function for processing an OK response.
     setOpenConfirm(true);

    }
    const alterWindowHandler = (toClose) => {

        setOpenInfo(toClose);
        history.push(returnRoute); //naviage back to home window;
    }
    const notCancelCreation = () =>{
        history.push(props.returnRoute || '/home');
    };
    console.log(contractorStock, stockConsignment);
    const wizardProps = {
        IsLoading: props.IsLoading,
        Error: props.Error,
        cancelCreationHandler: () => { stockReview.cancelConsignment(dispatch); },
        StepValidators: {
            
            validateContractor: () => {

                return validator.validateContractor(stockConsignment);
            },
            validateLocation: () => {
                return validator.validateLocation(stockConsignment);
            },
            validateDestination: () => {
                return validator.validateDestination(stockConsignment);
            },
            validateMethod: () => {
                return validator.validateMethod(stockConsignment);
            }
        },
        stepCompletedCallback: (step, isCompleted, toConfirm) => {
            if(isCompleted){
                dispatch(stockConsignmentActions.updateStepStatus(step.StepName, "Complete"));

            }
            
            console.log('stepCompletedCallback',step);
            dispatch(stockConsignmentActions.saveInprogressConsignmentToLocalStorage(stockConsignment));


            if (step.StepName === "Review" && isCompleted && toConfirm) {// it's last step, when it is completed, submit the consignment
               //debugger;
               let consginementData={
                ConsignmentDataType:"ReturnConsignmentsCreation",
                ReturnConsignmentsCreation:stockConsignment.ReturnConsignmentsCreation
               }
                stockReview.submitConsignments(dispatch, user.idToken, consginementData);
                setOpenInfo(true);//show information dialog;

                
            }
        },

        loadContractors: ()=>{ contractorSelector.loadContractors(dispatch, user.idToken);},
        userToken:user.idToken,
        contractorListWithStockSummary: props.contractorListWithStockSummary,
        selectedContractorCompanyIds: props.selectedContractorCompanyIds,
        showGoodCounts: false,
        showFaultyCounts: true,
        handleSelectContractor: (rows, selectedRow)=>{
            setIncompleteStepNames(["Contractor"]); 
            contractorSelector.handleSelectContractor(dispatch, selectedRow, user.idToken,"FAULTY");
        },

        LocationDisplayColumns: locationSelector.faultyLocationDisplayColumns,
        loadLocationData: () => {locationSelector.loadLocationFaultyData(dispatch, user.idToken, contractorStock, props.selectedContractorCompanyIds); },
        LocationList: props.locationList,
        SelectedLocations: props.selectedLocations,
        selectLocation: (selectedRows, currentRow) => {
            setIncompleteStepNames(["Location"]);
             locationSelector.selectLocation(dispatch, selectedRows, currentRow,"Return Faulty Stock") 
            },

        loadDestinationData: () => { destinationSelector.loadWarehouseLocationsFromServer(user.idToken); },
        //DestinationList: [],
        SelectedDestination: props.selectedDestination || [],
        selectDestination: ( selectedRow) => { 
            setIncompleteStepNames(["Destination"]);
            destinationSelector.handleReturnSelectDestination(dispatch, selectedRow) },

        //loadMethodData: function () { },
        MethodDisplayColumns: methodSelector.methodDisplayColumns,
        MethodList: methodSelector.methodList,
        selectMethod: (selectedRow) => {
            setIncompleteStepNames(["Method"]);
            methodSelector.handleReturnSelectMethod(dispatch, selectedRow);
        },
        SelectedMethod: props.selectedMethod || [],
        SelectedPickupDate: props.selectedPickupDate || null,
        selectPickupDate: (planedPickedUpDate) => { 
            setIncompleteStepNames(["Method"]);
            methodSelector.handleReturnPickedUpDate(dispatch, planedPickedUpDate); },
        SelectedReturnDate: props.selectedReturnDate || null,
        selectReturnDate: (returnDate) => { 
            setIncompleteStepNames(["Method"]);
            methodSelector.handleReturnDate(dispatch, returnDate); },

        loadRevievData: () => { stockReview.loadRevievData(dispatch, user.idToken, stockConsignment); },
        ReviewDisplayColumns: stockReview.faultyReviewDisplayColumns,
        ReviewData: props.reviewData,
        loadReviewRowDetails: (tableRowData) => { return stockReview.loadFaultyReviewRowDetails(contractorStock, tableRowData); },
        ReviewDisplaySubColumns: stockReview.faultyReviewDisplaySubColumns,
        ReturnStockTitle: "Return Faulty Stock",
        StepsStatus: stockConsignment.StepsStatus,
        incompletedStepNames: incompleteStepNames


    };

    //load from localStorage (if any)
    useEffect(()=>{

        dispatch(stockConsignmentActions.loadInprogressConsignmentFromLocalStorage());
        }, []);

        useEffect(()=>{
            var anyOtherInProgressReturn = otherInProgressReturnCreation(stockConsignment,"Faulty");
            console.log('anyOtherInProgressReturn...',anyOtherInProgressReturn)
            if(anyOtherInProgressReturn){
                showConfirmDialog('Please Confirm',`Your previous ${anyOtherInProgressReturn} stock return is still in progress. Do you want to cancel it?`)
            }          
        },[stockConsignment])

    return (
        <>
            <ReturnStockWizardInternal {...wizardProps} returnRoute={"/stockmodule"}/>
            <SkbConfirmDialog id="Confirm-dialog" message={confirmMsg} title={confirmTitle} open={openConfirm} openConfirm={setOpenConfirm} 
            okHandler={props.cancelAllReturnConsignment} cancelHandler={notCancelCreation}
                okLabel="Yes" cancelLabel="No" okButtonIsFirst={false} okButtonIsHighlighted={false} okButtonIsFocussed={false} />
            <SkbAlertDialog id="info-dialog" title={"Information"} message={"The return stock consignment has been submitted."} open={openInfo} openAlert={alterWindowHandler}  />
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnFaultyStockWizardInternal);
