
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme, darken } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { LightTheme, DarkTheme, LAYOUTCOMPONENT_SIZE} from '../../skb_controls/Themes';

import logoDark from '../../skb_controls/Themes/logo-dark.png';
import logoLight from '../../skb_controls/Themes/logo-white.png';

import Tooltip from '@material-ui/core/Tooltip';
import {ProfileMenu} from '../../skb_controls/components';
import {SkbSwitch} from '../../skb_controls/components/SkbSwitch';

import SkbIcon from '../../skb_controls/components/SkbIcon';

import WbSunnyIcon from '@material-ui/icons/WbSunny';

import Brightness3Icon from '@material-ui/icons/Brightness3';

const useStyles = makeStyles(theme => ({
    appBarContent: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
    },
    appBar: {
        position: "fixed",
        top:0,
        [theme.breakpoints.up('md')]: {
            //   width: `calc(100% - ${drawerWidth}px)`,
            width: `calc(100% )`,
            marginLeft: 0,
            top: LAYOUTCOMPONENT_SIZE.Header.Height,

        },
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.primary,
        // zIndex:-1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            height: 17, 
            width:17,
            '& > .MuiIconButton-label':{
                height:0,

            }
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },

    },
  
    nightDayIcon:
    {
        display:"block",
        
        [theme.breakpoints.down('sm')]: {
            // marginTop: "6px",
            height: 17, 
            width:17
        },
        [theme.breakpoints.up('md')]: {
            // marginTop:"12px",
            height: 25, 
            width: 25
        },
    },

    logoSizing:
    {
        [theme.breakpoints.between('xs','sm')]: {
            height: 17, 
            width:45
        },
        [theme.breakpoints.up('sm')]: {
            height: 34, 
            width: 90
        },
        [theme.breakpoints.up('md')]: {
            height: 44, 
            width:169
        },
    },

    themeswitch:{
        alignItems:"center",
        justifyContent:"flex-end",
    },
    textcenter:{
        textAlign: 'center',
    }
}));

export default function SkbRegistrationAppBar(props) {
    const classes = useStyles();
    const theme = useTheme();
    // const changeTheme = ()=>{
    //     console.log('triggered');
    //     props.changeTheme('darkTheme');
    // }
    const { state } = props;

    const logo=state.darkTheme?logoLight:logoDark;

    return (

        <AppBar className={classes.appBar}>
            <Toolbar>
                <Grid container justify="space-between" spacing={3} className={classes.appBarContent}>
                    <Grid item lg={2} md={4} sm={4}>
                        <img src={logo} alt='logo' className={classes.logoSizing}></img>
                       </Grid>
                       <Grid item lg={8} md={5} sm={4} className={classes.textcenter} >
                        <Typography variant="h3">
                            {/* Project Phoenix */}
                            </Typography>
                       </Grid>
                    <Grid item lg={2} md={3} sm={4}  >
                        <div style={{ display: "flex", alignItems:"center"}}>

                            <Grid container alignItems="center" className={classes.themeswitch}>
                                <Grid item> <SkbIcon className={classes.nightDayIcon} icon={WbSunnyIcon} />  </Grid> 
                                  <Grid item style={{width:48, marginTop:4}}><SkbSwitch id="light-dark-toggle"  val={state.darkTheme} setVal={ props.changeTheme('darkTheme')} showOnOffText={false} primary /></Grid> 
                                  <Grid item><SkbIcon className={classes.nightDayIcon} icon={Brightness3Icon} /></Grid> 
                            </Grid>
                        </div></Grid>
                </Grid>


            </Toolbar>
        </AppBar>
        
    );
}