/*
This "husk" is designed as a holder for a component which was previously connected to Redux and now is not.  The husk provides all the Redux functionality that the
component previously had, as a replacement for previous uses of the component (maintaining the functionality), as an example for future use and as a test harness 
for the disconnected component.

When other pages start using the disconnected component this husk can initially be an example of how to use it.  I expect the husk will be phased out once the disconnected
component is in real use.  

Note that in the case of StepStockPreview, there is a husk in the stocktake folder (for the older stocktake implementation of the component) and one in stock_components, for 
the newer version of the component.  

*/


import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import * as taskActions from '../../../../Redux/actions/tasks';

import StepStockPreview from './StepStockPreview';
import { SkbLogger, SeverityLevel } from '../../../../services';
import {getCurrentSubTask, getCurrentTask}  from '../../../../Redux/actions/tasks';  //Why is this function in this file?  It's not an action.  

import { StocktakeSteps } from '../../Stocktake';

//Some constants for logging:

import { TaskLogInfo } from '../../../../utils/misc';
const moduleName = TaskLogInfo.moduleName;
const stepName = TaskLogInfo.category.preview;

  //Comment: At the moment all the contractor's stock holdings are put against their default location because we don't know where they actually are.
  //Therefore, for any other locations we need to check the stock in the other location sub-tasks as well to compile the list.
  //In the future, this may need to change when we have more accurate tracking of stock location within the contractor's different locations.  
  
export function StepStockPreviewHusk(props)
{

  function goToStep(stepId, selectedStockCode) {
    var msg = "User attempted to navigate to step + " + stepId + " for Stockcode " + selectedStockCode + ".";
    SkbLogger.applicationTrace(moduleName, SeverityLevel.Verbose, stepName, msg, props.currentSubTask);

    props.setLastSelectedStockCode(selectedStockCode);
    props.goToStep(props.allTasks, stepId);

  }

  // var previewData=[];
  // var loadPreviewData=()=>{};

  const currentTask=getCurrentTask(props.allTasks);

  return (<StepStockPreview previewInfo={props.CurrentSubTask.PreviewInfo} CurrentTask={currentTask} loadPreviewData={props.loadPreviewData} goToStep={goToStep} 
        serialisedStepId={StocktakeSteps.SERIALISED} nonSerialisedStepId={StocktakeSteps.NON_SERIALISED}
        emptyDataMessage={'No previous stock items found!'}
        isLoading={props.allTasks.isLoading}
  />);
}

const mapStateToProps = state => ({
  allTasks: state.tasks,
  CurrentSubTask: taskActions.getCurrentSubTask(state.tasks),

});

const mapDispatchToProps = (dispatch) => {
  return {

    goToStep: (allTasks, stepId) => dispatch(taskActions.changeStep(allTasks, stepId)),
    setLastSelectedStockCode: (stockCode) => dispatch(taskActions.updateLastSelectedStockCode(stockCode)),
    loadPreviewData: () => dispatch(taskActions.loadCurrentTaskPreviewInfo(false)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepStockPreviewHusk);
