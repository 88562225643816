import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
//import Loading from '../../../components/Loading';

import clsx from 'clsx';

import { useTheme } from '@material-ui/core';
import SkbButton from '../../../skb_controls/components/SkbButton';

import SkbTextField from '../../../skb_controls/components/SkbTextField';

import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    icon: {
        display:'inline-block',
        width: 48,
        marginRight: 4,
        color: theme.palette.primary.main,
    },
    input:{
        display:'inline-block',
        width: `calc(100% - 52px)`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
    }
}));

const titleBarStyles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth: 300
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
});

const DialogTitle = withStyles(titleBarStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

/**
 * 
 * @property {message}: message to show  (can contain line breaks represented as \n)
 * @property {open}: true: show dialog, false: hide dialog
 * @property {openConfirm}: dialog open state update handler;
 * @property {okHandler}: call back function when OK is clicked
 * @property {searchItems}: the list of items for the search
 * @property {noOptionsText}: the text to show when there are no items passed in to search
 * @property {className}: css class name

 */
export default function DialogAddItemFromSearch({ title, message, open, openConfirm, okHandler, searchItems, noOptionsText, className}) {
    
    const [selectedValue, setSelectedValue] = React.useState('');
    const [listItems, setListItems] = React.useState([]);
    const handleClickOk = () => {
        //following line has been commented out to keep adding window open
        //openConfirm(false);

        if (okHandler) 
        {
            okHandler(selectedValue);
        }
    };

    const handleClose = () => {
        // console.log("cancelled");
        openConfirm(false);
    };

    const handleKeyPress = (event)=>{
        if (event.charCode === 13) {
            handleClickOk();
        }

    }

    const theme = useTheme();
    const classes = useStyles();

    const  formattedMessageText=message.split('\n').map(text => {
        return <Typography gutterBottom>{text}</Typography>
    });

    React.useEffect(()=>{
        if(Array.isArray(searchItems)){
                setListItems(searchItems);
        }
        setSelectedValue('');
    }, [searchItems]);

    return (
        <div>
        <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.paper }}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
                </DialogTitle>
            <DialogContent dividers>
                {formattedMessageText}
             
                <Paper className={clsx(className, classes.root)} > 
            <IconButton className={classes.icon} aria-label="search"  onClick={(e)=>{e.preventDefault(); }} >      
              <SearchIcon />
            </IconButton>
      <Autocomplete 
        id="add-item-search"
        className={classes.input}
        autoComplete={true}
        autoHighlight={true}
        options={searchItems}
        getOptionLabel={(option) => option.Description}
        onChange={(event, newValue) => {
            
            setSelectedValue(newValue);
          }}
        onKeyPress={(event)=>{handleKeyPress(event);}}
        noOptionsText={noOptionsText}
        value={selectedValue}
        renderInput={(params) => (

            
            <SkbTextField   {...params} id="search-item-input" type="text" size="small" outlined  autoFocus={true}/>
        

        )}
      />
      </Paper>
            </DialogContent>
            <DialogActions>
                <SkbButton id="back-button" primary outlined text="Back" size="medium" onClick={handleClose}  />
                <SkbButton id="add-button" primary size="medium" text="Add" onClick={handleClickOk} disabled={selectedValue===''} />

            </DialogActions>

        </Dialog>
        </div>
    );
}
