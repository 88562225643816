import React, { useEffect, useRef, useState } from 'react';
import { lighten, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import SkbAlertDialog from '../../../skb_controls/components/SkbAlertDialog';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

import SkbTabMenu from '../../../skb_controls/navigation/SkbTabMenu';
import SkbIcon from '../../../skb_controls/components/SkbIcon';
import SkbCardsView from '../../../skb_controls/navigation/SkbCardsView';
import { default as StockIcon } from '@material-ui/icons/Business';

//import * as navigationConfig from '../../../utils/navigationConfig.json';
import * as icons from '../../../utils/icons';

// const mapStateToProps = state => ({
//     user: state.auth,

// });

// const mapDispatchToProps = (dispatch) => {
//     return {
//         dispatch: dispatch,
//     }
// }

const SubMenuViews = ({config, grantedModules}) => {

    var cardsInfo = [];
    var modules = grantedModules || [];

    //alert(config.length);

    config.forEach(c => {

        var module = modules.find(m=>m.uRLSuffix.toLocaleLowerCase() === c.RouteSuffix.toLocaleLowerCase());
        
        if(module){
       //     alert(module.name);

            cardsInfo.push({
                Name: module.name, 
                Description: module.description,
                ImagePath: `/images/${module.imagePath}`,
                Icon : icons.makeIcon(module.iconName),
                NavigationRoute: `/${module.uRLSuffix}`
            });
        }
    });
    //debugger;
    if(cardsInfo.length === 0) return (<></>)
    else {
        return (<SkbCardsView CardsInfo = {cardsInfo}/>);
    }
}
export default function StockModule(props) {
    const { user, navigationConfig } = props;
    //const history = useHistory();

    const stockModuleConifg = navigationConfig || [];

    //console.log(stockModuleConifg);
    //load contractor list informaiton 
    // useEffect(()=>{

    // }, [])
    return (
        <SkbTabMenu header={<Typography id="form-page-heading" variant="h1"><Grid container><SkbIcon icon={StockIcon} />Stock Module</Grid></Typography>}>
            {
                stockModuleConifg.map((item, idx) => {
                    //console.log(item.subMenuName);
                    return (
                    <skbtab label={item.subMenuName}>
                        <SubMenuViews config={item.subModules} grantedModules={user.Modules}/>
                    </skbtab>
                )})
            }



        </SkbTabMenu>
    );
}

// export default connect(mapStateToProps, mapDispatchToProps)(StockModule);
