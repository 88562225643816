import * as moment from 'moment-timezone';

function customUtilityFunc() {

    /**
     * checkfileTypeIsAllowed
     * fileObj : File object
     * otherParam: For future usage , not used so far. If used please change tthe comment here
     *
     * 
     * returns an object 
     * 
     */
    const checkfileTypeIsAllowed = async (fileObj,otherParam) =>{
  
        try {

            const validateExtentionSizeResp = {"fileTypeOkStatus":false,"fileSizeOkStatus":false, "fileName": ""};
            const allowedFileType = ["png","jpg","jpeg","pdf"];
            const allowedMaxSize = 10 * 1024 * 1024; // 10 
            
            //console.log("--- typeof myObject=>>>",(typeof fileObj),fileObj);

            if((typeof fileObj) === 'object') {           
                       
                const nameData = fileObj.name!==undefined?fileObj.name:"";
                const fileSizeData =  fileObj.size!==undefined? fileObj.size:0;
    
                const nameSplit = nameData? nameData.split("."):[];
                const fileExt  = Array.isArray(nameSplit) && nameSplit.length>1? nameSplit[nameSplit.length-1]:"";

                validateExtentionSizeResp["fileName"] = nameData;  
            
                // file type check
                const chkType =  (allowedFileType.indexOf((fileExt.toLowerCase()))>-1)?true:false;
                //console.log(" -- chkType=>>>",chkType);
                if(chkType){
                    validateExtentionSizeResp["fileTypeOkStatus"] = true;    
                }
        
                // file size  check    
                const chkSize = fileSizeData<=allowedMaxSize?true:  false;
                //console.log(" -- chkSize=>>>",chkSize);
                if(chkSize){       
                    validateExtentionSizeResp["fileSizeOkStatus"] = true;
                }
                //console.log(" -- validateExtentionSizeResp=>>>",validateExtentionSizeResp);
                return validateExtentionSizeResp;   

            }
    
            
            return validateExtentionSizeResp;   
               
            }catch (error) {
            console.error(error);
            }
    };

    /**
     * toBase64
     * file : File object
     *
     * 
     * returns raw base64 string data
     * 
     */
    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });


     /**
      * findFirstMatchedKeyValueRowFromArrObj
     * arrList : array of object
     * keyToSearch: key name within the array to search
     * keyValToSearch: key name related value to serach within the array of objects
     * 
     * returns First matched row with thhe specified key name and its value within the array of object Or Null if not found
     * 
     */
 

    const findFirstMatchedKeyValueRowFromArrObj = (arrList,keyToSearch,keyValToSearch) => {

        const chkFind = arrList.find((e)=>{                  
                   
                    return e[`${keyToSearch}`]===keyValToSearch;
        });
       
        let sendResl = chkFind!==undefined? chkFind: null;
        return sendResl;

    }

    /**
     * isTheDateGreaterThanTheOtherOne
     * dateToCompare : Date object or Date string
     * dateToCompareWith: Date object or Date string
     * 
     * returns true/false
     * 
     */
 

    const isTheDateGreaterThanTheOtherOne = (dateToCompare, dateToCompareWith) => {
        let dateToCompareObj =  moment.utc(dateToCompare).tz(moment.tz.guess());

        let dateToCompareWithObj =  moment.utc(dateToCompareWith).tz(moment.tz.guess());
        let cmpDtResult = dateToCompareObj.isAfter(dateToCompareWithObj);
        return cmpDtResult;
    }

    /**
     * grabDateStringInFormat
     * dateString : Date object or Date string
     * dateFormat: Date format 
     * 
     * returns formated date in  string
     * 
     */
 

    const grabDateStringInFormat = (dateString, dateFormat="YYYY-MM-DD") => {
       
        let dateStrResp =  moment.utc(dateString).tz(moment.tz.guess()).format(dateFormat);
        return dateStrResp;    
    }
 
  
    return {checkfileTypeIsAllowed, toBase64, findFirstMatchedKeyValueRowFromArrObj, grabDateStringInFormat,isTheDateGreaterThanTheOtherOne
    };
  
  }
  
  export const getReturnDashboardUtility = customUtilityFunc(); 
