
import _ from 'lodash';
import { SkbLogger } from '../../services';

import * as contractorStockActions from '../../Redux/actions/contractorStock';
import * as stockConsignmentActions from '../../Redux/actions/stockConsignment';
export const locationDisplayColumns =
    [
        { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
        { title: "", field: "CompanyId", type: 'hidden-text' },
        { title: "Contractor Entity", field: "Contractor", type: 'read-only-long-text' },
        { title: "Stock Location", field: "LocationName", type: 'read-only-long-text' },
        { title: "Address", field: "Address", type: 'read-only-long-text' },

    ];

export const faultyLocationDisplayColumns =
[
    { title: '', field: 'LocationCode', type: 'hidden-id' }, //column with hidden-id will not display
    { title: "", field: "CompanyId", type: 'hidden-text' },
    { title: "Contractor Entity", field: "Contractor", type: 'read-only-long-text' },
    { title: "Stock Location", field: "LocationName", type: 'read-only-long-text' },
    { title: "Address", field: "Address", type: 'read-only-long-text' },
    { title: "# of Faulty Stock", field: "FaultyStockQty", type: 'read-only-short-text' },

];    

export const loadLocationData = (dispatch, idToken, contractorStock, selectedContractorRepresentativeIds) => {
    selectedContractorRepresentativeIds.forEach(companyId => {
        //let companyId = contractor.CompanyId;
        let found = _.find(contractorStock.StockOnHand, (c) => c.CompanyId === companyId);
        if (found) {
            if (!(_.isArray(found.Locations) && found.Locations.length > 0)) {
                //company location is not loaded into redux store, load them here, otherwise, do not load, this approach will missed the new added location on serer end after it's loaded, but it shall be rare case
                SkbLogger.logDebugInfo("loadLocationData for company ", companyId);
                dispatch(contractorStockActions.loadContractorLocations(idToken, companyId));
            }
        } else {
            throw `loadLocationData(): company (id=${companyId}) doesn't exist!`;
        }

    });
}

export const loadLocationFaultyData = (dispatch, idToken, contractorStock, selectedContractorRepresentativeIds) => {
    selectedContractorRepresentativeIds.forEach(companyId => {
        //let companyId = contractor.CompanyId;
        console.log('loadLocationFaultyData contractorStock',contractorStock.StockOnHand)
        let found = _.find(contractorStock.StockOnHand, (c) => c.CompanyId === companyId ); //&& c.QtyFaulty >0 removed for BUG-1633 and BUG-1591
        if (found) {
            if (!(_.isArray(found.Locations) && found.Locations.length > 0)) {
                //company location is not loaded into redux store, load them here, otherwise, do not load, this approach will missed the new added location on serer end after it's loaded, but it shall be rare case
                SkbLogger.logDebugInfo("loadLocationFaultyData for company ", companyId);
                dispatch(contractorStockActions.loadContractorLocations(idToken, companyId));
            }
        } else {
            throw `loadLocationFaultyData(): company (id=${companyId}) doesn't exist!`;
        }

    });
}

const getSelectedCompanyIds = (stockConsignment) => {
    if (!stockConsignment|| !stockConsignment.ReturnConsignmentsCreation)
    {
       return [];
    }  
  else {
      var selectedIds=stockConsignment.ReturnConsignmentsCreation.map(c=>c.CompanyId);
      return selectedIds;
  }
}

export const getFaultyStockLocationList = (contractorStock, stockConsignment) => {
    var selectedContractorCompanyIds=getSelectedCompanyIds(stockConsignment);

    var stockOnHand = contractorStock.StockOnHand;
    var locations = _.filter(stockOnHand, (c) => _.isArray(c.Locations) && c.Locations.length > 0
                        && selectedContractorCompanyIds.includes(c.CompanyId)
                         );

    var ret = [];

    locations.forEach(c => {
        c.Locations
        //.filter(loc=>loc.QtyofFaulty>0)
        .forEach(l => {
            ret.push({
                LocationCode: l.LocationCode,
                CompanyId: c.CompanyId,
                Contractor: c.Contractor,
                LocationName: l.Name,
                Address: l.Address,
                FaultyStockQty:l.QtyofFaulty
            })
        });
    });

    return ret;
}

export const getLocationList = (contractorStock, stockConsignment) => {
    var selectedContractorCompanyIds=getSelectedCompanyIds(stockConsignment);

    var stockOnHand = contractorStock.StockOnHand;
    var locations = _.filter(stockOnHand, (c) => _.isArray(c.Locations) && c.Locations.length > 0
                        && selectedContractorCompanyIds.includes(c.CompanyId)
                         );

    var ret = [];

    locations.forEach(c => {
        c.Locations
        //.filter(loc=>loc.QtyofGood+loc.QtyofFaulty>0)
        .forEach(l => {
            ret.push({
                LocationCode: l.LocationCode,
                CompanyId: c.CompanyId,
                Contractor: c.Contractor,
                LocationName: l.Name,
                Address: l.Address
            })
        });
    });

    return ret;
}

export const getSelectedLocations = (stockConsignment) => {
    var returnConsignmentsCreation = stockConsignment.ReturnConsignmentsCreation;


    var ret = [];
    if(!_.isArray(returnConsignmentsCreation)) return ret;
    returnConsignmentsCreation.forEach((c) => {
        if (_.isArray(c.Consignments)) {
            c.Consignments.forEach(s => {
                ret.push(s.FromLocationCode);
            });
        }
    })
    return ret;
}

export const selectLocation = (dispatch, selectedRows, currentRow,consignmentType) => {
    const { tableData } = currentRow;
    const rowData = currentRow;
    const {checked} = tableData;


    if (checked) {// add location
        var locationToAdd =
        {
            ConsignmentDataType: "ReturnConsignmentsCreation",
            ConsignmentsData: [{
                CompanyId: rowData.CompanyId,
                Consignments: [{
                    FromLocationCode: rowData.LocationCode,
                    FromLocationName: rowData.LocationName,
                    FromLocationAddress: rowData.Address,
                    ConsignmentType: consignmentType//"Return All Stock"
                }]
            }]
        };
        dispatch(stockConsignmentActions.addConsignment(locationToAdd));
    } else { // delete location
        var locationToRemove =
        {
            ConsignmentDataType: "ReturnConsignmentsCreation",
            ConsignmentsData: [{
                CompanyId: rowData.CompanyId,
                Consignments: [{
                    FromLocationCode: rowData.LocationCode
                }]
            }]
        };
        dispatch(stockConsignmentActions.delConsignment(locationToRemove));
    }

}