import { SkbLogger, StockService, SeverityLevel } from '../../services';
import _ from 'lodash';
import { Actions } from '../reducers/stockConsignment';
import { crypts } from '../../utils/crypts';

export const ModuleInfo = {
    moduleName: "MinervaConsignmentStore",
    category: "Consignment Store Actions"

}

function dispatchError(dispatch, error) {
    dispatch((() => {
        return {
            type: Actions.CONSIGNMENT_UPDATE_ERROR,
            data: error
        };
    })(error));
}

export function addContractorToConsignment( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_CONTRACTOR_ADDED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addContractor", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function delContractorInConsignment( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_CONTRACTOR_DELETED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "delContractor", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function addConsignment( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_ADDED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addConsignment", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function delConsignment( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_DELETED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "delConsignment", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateDestination( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_DESTINATION_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateDestination", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateConsignmentMethod( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_METHOD_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateConsignmentMethod", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateConsignmentType( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_TYPE_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateConsignmentType", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateConsignmentReturnDate( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_RETURNEDBYDATE_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateConsignmentReturnDate", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateConsignmentPickUpDate( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_PICKEDUPBYDATE_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateConsignmentPickUpDate", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function addSn( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_SERIALISEDITEMS_ADDED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addSn", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateSn( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_SERIALISEDITEMS_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateSn", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function delSn( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_SERIALISEDITEMS_DELETED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "delSn", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}
export function cancelCreation(consignmentData){
    return(dispatch)=>{
        dispatch((()=>{
            return {
                type:Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try{
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_CANCEL_STOCKRETURN,
                    data: consignmentData
                }
            })(consignmentData));
            dispatchError(dispatch, "");
            dispatch(deleteInprogressConsignmentInLocalStorage());
        }catch (error) {
            dispatchError( dispatch,error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "cancelCreation", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    }
}
export function addNonSn( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_NONSERIALISEDITEMS_ADDED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError( dispatch,error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "addNonSn", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function updateNonSn( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_NONSERIALISEDITEMS_UPDATED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError( dispatch,"");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "updateNonSn", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}

export function delNonSn( consignmentData) {
    return  (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());
        try {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_NONSERIALISEDITEMS_DELETED,
                    data: consignmentData
                }
            })(consignmentData));
            //reset error 
            dispatchError(dispatch, "");

        } catch (error) {
            dispatchError(dispatch, error);
            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "delNonSn", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }
    };
}
export function submitConsignment(idToken,consignmentData) {
    return async (dispatch) => {
        //refresh begin
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_REFRESH_BEGIN
            }
        })());


        try {
            
            var res;
            for(const c of consignmentData.ReturnConsignmentsCreation){
                if(c.Consignments && c.Consignments.length>0){
                    if(c.Consignments.length >1){
                        let companyId = c.CompanyId;
                        let contractor=c.Contractor;
                        let representative=c.Representative;

                        await Promise.all((c.Consignments).map(async (d) =>{
                            let oneLocationData={
                                CompanyId:companyId,
                                Contractor:contractor,
                                Representative:representative,
                                Consignments:[d]
                            }
                            res = await StockService.submitConsignment(idToken,oneLocationData);
                        }))
                    }else{
                        res = await StockService.submitConsignment(idToken,c);
                    }
                }
            };
          
            if(res && res.status===200){
     
                dispatch((() => {
                    return {
                        type: Actions.CONSIGNMENT_SUBUMITTED,
                        data: consignmentData 
                    }
                })());
                //reset error 
                dispatchError(dispatch, "");

                dispatch(deleteInprogressConsignmentInLocalStorage());
            }
        } catch (error) {
            dispatchError( dispatch,error.toString());

            SkbLogger.applicationException(ModuleInfo.moduleName, ModuleInfo.category, "submitConsignment", error);
        }
        finally {
            dispatch((() => {
                return {
                    type: Actions.CONSIGNMENT_REFRESH_END
                }
            })());
        }

    };
}

const LOCALSTORAGE_KEY_CONSIGNMENT_DRAFT = "consignment-inprogress-draft";

export function loadInprogressConsignmentFromLocalStorage() {
    SkbLogger.logDebugInfo('loadInprogressConsignmentFromLocalStorage');
    return (dispatch) => {
        //load from localstorage into action.data
        //then dispatch action.data (from localstorage) to reducer
        //expecting reducer to copy action.data into draft/store
        const loadedDraftJson = localStorage.getItem(LOCALSTORAGE_KEY_CONSIGNMENT_DRAFT);
        SkbLogger.logDebugInfo('loadInprogressConsignmentFromLocalStorage loading', loadedDraftJson);
        if(loadedDraftJson && loadedDraftJson.length){
            const loadedDraft = JSON.parse(crypts.decrypt(loadedDraftJson));
            SkbLogger.logDebugInfo('loadInprogressConsignmentFromLocalStorage decrypting', loadedDraft);
            if(loadedDraft && loadedDraftJson.length){
                SkbLogger.logDebugInfo('loadInprogressConsignmentFromLocalStorage result', loadedDraft);
                dispatch((() => {
                    return {
                        type: Actions.CONSIGNMENT_INPROGRESS_LOADED,
                        data:loadedDraft
                    }
                })());
            }
        }
        
    }
} 

/**
 * 
 * @param {*} draft draft is the object containing isLoading and error. for Return Stock, it also contains ReturnConsignmentsCreation
 */
export function saveInprogressConsignmentToLocalStorage(draft) { // we ignore the param here for it's value is out-of-date
    //SkbLogger.logDebugInfo('saveInprogressConsignmentToLocalStorage',draft);
    return (dispatch, getState) => {

       let consignmentState = getState().stockConsignment || draft; 
        if(consignmentState){
            const encryptedDraftJson = crypts.encrypt(JSON.stringify(consignmentState));
            SkbLogger.logDebugInfo('saveInprogressConsignmentToLocalStorage saving', encryptedDraftJson);
            localStorage.setItem(LOCALSTORAGE_KEY_CONSIGNMENT_DRAFT, encryptedDraftJson);
        }
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_INPROGRESS_SAVE
            }
        })());
    }
} 

export function deleteInprogressConsignmentInLocalStorage() {
    SkbLogger.logDebugInfo('deleteInprogressConsignmentInLocalStorage');
    return (dispatch) => {
        localStorage.setItem(LOCALSTORAGE_KEY_CONSIGNMENT_DRAFT, "");
        dispatch((() => {
            return {
                type: Actions.CONSIGNMENT_INPROGRESS_DELETED
            }
        })());
    }

} 


export function updateStepStatus(stepName, status) {
    return (dispatch, getState) => {
        dispatch(((stepName, status) => {
            return {
                type: Actions.STEP_STATUS_UPDATE,
                data: {
                    StepName: stepName,
                    Status: status
                }
            };
        })(stepName, status));

        // var stepsStatus = {...(stockConsignment.StepsStatus||{})};
        // stepsStatus[stepName] = status;
    }
}