import React, { useEffect, useState } from 'react';

import MaterialTable from 'material-table';

import { tableIcons } from '../../_share/tableIcons';

import { Link } from '@material-ui/core';

import _ from 'lodash';

import {useStocktakeTableStyles} from './StepStockPreview';

//This function groups the serialised items into names and counts to match the list of non-serialised items.
export function groupSerialisedItems(serialisedItems) {
  let goodItems = _.filter(serialisedItems, { ItemStatus: "Good" });
  let badItems = _.filter(serialisedItems, { ItemStatus: "Faulty" });

  let goodGroupedItems = _.groupBy(goodItems, 'StockCode');
  let badGroupedItems = _.groupBy(badItems, 'StockCode');

  //Group the good items:
  let goodSerialisedItemsCounts = Object.keys(goodGroupedItems).map(k => {
    return {
      StockCode: k,
      Description: goodGroupedItems[k][0].Description,
      //  StockType: goodGroupedItems[k][0].StockType, 
      QtyUnit: goodGroupedItems[k][0].QtyUnit,
      IsSerialised: goodGroupedItems[k][0].IsSerialised ?? 1,
      GoodQty: goodGroupedItems[k].length,
      FaultyQty: badGroupedItems[k] == null ? 0 : badGroupedItems[k].length ?? 0
    };
  });

  //Add any stockitems that only had bad items:
  let badSerialisedItemsCounts = Object.keys(badGroupedItems).filter(x => !Object.keys(goodGroupedItems).includes(x)).map(k => {
    return {
      StockCode: k,
      Description: badGroupedItems[k][0].Description,
      //StockType: badGroupedItems[k][0].StockType, 
      IsSerialised: badGroupedItems[k][0].IsSerialised ?? 1,
      QtyUnit: badGroupedItems[k][0].QtyUnit,
      GoodQty: 0,
      FaultyQty: badGroupedItems[k].length
    };
  });

  let serialisedItemsCounts = goodSerialisedItemsCounts.concat(badSerialisedItemsCounts);

  return serialisedItemsCounts.sort(alphabeticalSort);
}

export function alphabeticalSort(a, b) {
  if (a.Description.toUpperCase() < b.Description.toUpperCase()) {
    return -1;
  }
  else {
    return 1;
  }
}


/**
 * component:The Stock Review Step shows a summary of the counted/picked items from previous steps for the contractor to review and correct.  
* @param {array}  serialisedItems: the counted or scanned serialised items
 *              e.g.  [
                    {
                        StockCode: "EROD001",
                        SerialNumber: "133434399",
                        Description: "Outdoor Unit (ODU)",
                        ItemStatus: 'Faulty',
                        Photo: 'photo path',
                        Latitude: "-37.23",
                        Longitude: "125.134"
                    },...]
 * * @param {array}  nonSerialisedItems: the counted or picked non-serialised items
 *              e.g. [
                    {
                        StockCode: "SKB001",
                        Description: "cable",
                        QtyUnit: "Metre",
                        GoodQty: 45,
                        FaultyQty: 20
                    },...]
 * @param {function} loadSerialisedItems: a function to load the serialised items.
 * * @param {function} loadNonserialisedItems: a function to load the nonserialised items.
* @param {array}  columnHeaders: the columnHeaders in order ['Description','UOM','Expected Status','# To Be Returned']

 * @param {function} goToStep: A function to navigate between this step and the related serialised and non-serialised steps in the same process.
 *                              Parameters stepId, selectedStockCode (to be highlighted on arrival at the new step).
 *                  OPTIONAL: If not supplied, there will be no hyperlink for the stock description.  It will just display as plain text.  
 * @param {number} serialisedStepId: The Id of the serialised step, to be used for cross-navigation.  Required only if goToStep provided.
 * @param {number} nonSerialisedStepId: The Id of the non-serialised step, to be used for cross-navigation.  Required only if goToStep provided.
 * @param {text} emptyDataMessage: text to display when there is no data.

 * @returns 
 */
export default function StepStockReview(props) {
  //default values:
  var noDataMessage=props.emptyDataMessage;

  if(!noDataMessage)
  {
      noDataMessage='No results have been recorded so far!';
  }
  var columnNames=props.columnHeaders;

  if (!columnNames)
  {
    columnNames= ['Description','UOM','# Good','# Faulty'];
  }
  //end default values.


  useEffect(() => {
    if (!props.serialisedItems) props.loadSerialisedItems();
    if (!props.nonSerialisedItems) props.loadNonSerialisedItems();
  }, []);

  useEffect(() => {
    if (props.serialisedItems && props.nonSerialisedItems) {
      //Making deep copies of the serialised/non-serialised items from the state:
      let serialisedItems = props.serialisedItems.map(i => ({ ...i }));

      let nonSerialisedItemCounts = props.nonSerialisedItems.map(i => ({ ...i }));

      //Adding some default properties that don't exist.
      for (var i = 0; i < serialisedItems.length; i++) {
        serialisedItems[i].IsSerialised = 1;
        serialisedItems[i].QtyUnit = 'Each';
      }

      for (var j = 0; j < nonSerialisedItemCounts.length; j++) {
        nonSerialisedItemCounts[j].IsSerialised = 0;
      }

      
  //Group the serialised items then combine the two lists:
      let serialisedItemsCounts = groupSerialisedItems(serialisedItems);

      let groupedItems = nonSerialisedItemCounts.concat(serialisedItemsCounts);
      let groupedItemsSorted = groupedItems.sort(alphabeticalSort);
      setData(groupedItemsSorted);
    }
  }, [props.serialisedItems, props.nonSerialisedItems]);

  //const theme = useTheme();

  const [data, setData] = useState([]);
  
  //set up table columns

  const [columns, setColumns] = useState([
    { title: 'StockCode', field: 'StockCode', hidden: true, editable: 'never' },
    {
      title: columnNames[0], field: 'Description', hidden: false, editable: 'never', width: '40%', align: 'left',
      render: rowData => (
        props.goToStep===undefined?
        rowData.Description
        :
        (rowData.IsSerialised === 1 ?
          <Link id={`serialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.serialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
          :
          <Link id={`nonserialised-page-link-${rowData.StockCode}`} color="primary" onClick={() => { props.goToStep(props.nonSerialisedStepId, rowData.StockCode); }}>{rowData.Description}</Link>
          )
      )

    },

    {
      title: columnNames[1], field: 'QtyUnit', hidden: false, editable: 'never', width: '5%', align: 'right'

    },
    {
      title: columnNames[2], field: 'GoodQty', hidden: false, editable: 'never', width: '10%', align: 'center'

    },
    {
      title: columnNames[3], field: 'FaultyQty', hidden: false, editable: 'never', width: '10%', align: 'center'

    }

  ]);

  const classes = useStocktakeTableStyles();

  return (

    <div id="review-table" className={classes.table} >

      <MaterialTable
        icons={tableIcons}
        showTitle={false}
        searchable={false}
        toolbar={false}
        columns={columns}
        data={data}
        padding={'dense'}

        options={{
          toolbar: false,
          showTitle: false,
          search: false,
          padding: 'dense',
          paging: false,
          headerStyle:
          {
            fontWeight: "bolder",
            fontSize: "1.2em"

          },

        }

        }
        localization={{ body: { emptyDataSourceMessage: noDataMessage } }}
      />

    </div>


  );
}


