const baseUrl = process.env.REACT_APP_API_URL;
console.log("BASE url" , baseUrl);
const endpoints = {
    CONSIGNMENT_LIST : `${baseUrl}stock/consignment?type=CONSIGNMENT`,
    CONSIGNMENT_DETAILS : id => `${baseUrl}stock/consignment/${id}/stock?detail=true`,
    CONSIGNMENT_INFO : id => `${baseUrl}stock/consignment/${id}/`,


    // filter
    FILTER_BY_STATUS : status => `${baseUrl}stock/consignment?type=CONSIGNMENT&status=${status}`,

    // search
    SEARCH_BY_SERIAL_NUMBER : serial => `${baseUrl}stock/consignment?type=CONSIGNMENT&detail=true&serialNumber=${serial}`,
    SEARCH_BY_STOCK_CODE  : code => `${baseUrl}stock/consignment?type=CONSIGNMENT&detail=true&stockCode=${code}`,
    SEARCH_BY_CONSIGNMENT_ID : id => `${baseUrl}stock/consignment?type=CONSIGNMENT&detail=true&companySubTaskId=${id}`, 


    UPDATE_CONSIGNMENT_STATUS : `${baseUrl}stock/consignment`,

    OVERWRITE_CONSIGNMENT_STOCK_DETAILS_ACTUAL :id => `${baseUrl}stock/consignment/${id}/actual-stock`,
    OVERWRITE_CONSIGNMENT_STOCK_DETAILS_REQUESTED :id => `${baseUrl}stock/consignment/${id}/requested-stock`,
    
    THREEPL_IMPORT: (warehouseLocation) => `${baseUrl}stock/3pl/${warehouseLocation}/pslip`,
    THREEPL_IMPORT_PROGRESS: `${baseUrl}stock/3pl/progress?filter=thisweek`,
    THREEPL_IMPORT_PROGRESS_ID: id => `${baseUrl}stock/3pl/progress/${id}`,
    THREEPL_IMPORT_PROCES: `${baseUrl}stock/3pl/process`,
    
    GET_WMS_WAREHOUSE_LOCATIONS: `${baseUrl}stock/warehouse`
}


export default endpoints;